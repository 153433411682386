import { APP_NAME } from '../../constants';
import { getAppSpecificData } from '../../helpers/misc';
import { serviceTypes, modulesConfig } from '../config';

export const cacheVersion = '0.2';
export const moduleId = 'Order';
export const menuModuleId = 'Menu';
export const modulePath = serviceTypes[modulesConfig.Order.serviceType].path;
export const menuModulePath = serviceTypes[modulesConfig.Menu.serviceType].path;
export const orderMenuPrefix = modulePath + '/menus/';
export const redirectMenuPrefix = modulePath + '/redirect/';
export const viewableRedirectMenuPrefix = menuModulePath + '/redirect/';
export const waitBetweenScansInMs = 2000;

export const pagePaths = {
  Module: modulePath,
  MenuModule: menuModulePath,
  MenuRedirection: redirectMenuPrefix + ':menuId/:date',
  ViewableMenuRedirection: viewableRedirectMenuPrefix + ':menuId/:date',

  ProductsList: modulePath + '/:facilityId/:menuId/:date/products',
  MenuProductsList: menuModulePath + '/:facilityId/:menuId/:date/products',
  ProductDetails: modulePath + '/product/:facilityId/:menuId/:date/:id',
  MenuProductDetails: menuModulePath + '/product/:facilityId/:menuId/:date/:id',
  OrderHistory: modulePath + '/history',
};

export const selectedMenuFiltersCacheKey = `${moduleId}_menuSelector_filters`;
export const selectedProductListFiltersCacheKey = `${moduleId}_productList_filters`;
export const menuVisitCacheKey = `menu_visits`;

export const orderConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      isDateFilteringEnabled: true,
      isNavbarEnabled: true,
    },
    default: {
      isDateFilteringEnabled: true,
      isNavbarEnabled: true,
    },
  };

  return getAppSpecificData(configuration);
};
