const labels = {
  StaysList: {
    'Ref: Page title': 'Oleskelu',
    'Ref: Past': 'Menneisyys',
    'Current and future stays': 'Nykyiset ja tulevat oleskelut',
    'filter label: period': 'Ajanjakso',
    'Ref: Search': 'Hae',
  },
  StayDetails: {
    'Ref: Page title': 'Vierailusi tiedot',
    'Arrival date': 'Saapumispäivä',
    'Departure date': 'Lähtöpäivä',
    'Room number': 'Huone',
    'Reservation number': 'Varausnumero',
    CheckIn: 'Ilmoittaudu',
    CheckOut:
      'Kirjaudu ulos {lodgeName} varausnumero {resNumber} nyt? Huoneen avain on deaktivoitu.',
    CheckIn_1: 'Tarkista {lodgeName}, saapuvat tänään? (Varausnumero {resNumber})',
    CheckedIn_1: 'Olet kirjautunut {lodgeName}, huone {roomNumber}.',
    CheckOut_1:
      'Kirjaudu ulos {lodgeName} varausnumero {resNumber} nyt? Huoneen avain on deaktivoitu.',
    CheckedOut_1: '{lodgeName} Varausnumero {resNumber} on kirjattu ulos.',
  },
  StaysSuccess: {
    'Ref: Title': 'Kiitos oleskelustasi!',
    'Ref: Body': 'Kassaasi on käsitelty onnistuneesti.',
    'Ref: Go to home': 'Mene kotiin',
    'Ref: Provide a feedback': 'Antaa palautetta',
  },
};
export default labels;
