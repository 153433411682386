import { act, render, screen } from '@testing-library/react';

import { addProviders } from '../../../../../helpers/tests/addProviders';
import * as hooks from '../../../api';
import Widget from '../Widget';

const facilities = [
  {
    title: "Akshay's Facility",
    name: "Akshay's Facility - FMG - FMG Hamilton",
    id: '764c9169-465c-ea11-b698-0003ff4ca494',
  },
  {
    title: "Akshay's Facility 2",
    name: "Akshay's Facility 2 - FMG - FMG Hamilton",
    id: '0e110a8e-465c-ea11-b698-0003ff4ca494',
  },
];

const menus = [
  {
    id: '42982bcf-6ae1-ec11-b5cf-0003ff857a05',
    name: 'Brockman 4 Concept + Crib',
    dayparts: [
      {
        name: 'Lunch',
        sections: [
          {
            id: '',
            name: 'Created by You (MYO)',
            items: [
              {
                id: 'c83be8e8-6ae1-ec11-bb3d-00224892f217',
                name: 'Roast Pork Mustard and Coleslaw Roll',
                description: '',
                price: '',
                displayPrice: '',
                priceInText: '',
                category: 'Created by You (MYO)',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uOMId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Created for You (Grab and Go)',
            items: [
              {
                id: '7c83e0ee-6ae1-ec11-bb3d-00224892f217',
                name: 'Created Vietnamese Noodle Bowl',
                description: '',
                price: '',
                displayPrice: '',
                priceInText: '',
                category: 'Created for You (Grab and Go)',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uOMId: 0,
              },
            ],
          },
        ],
        date: '2022-06-20T00:00:00Z',
      },
      {
        name: 'Dinner',
        sections: [
          {
            id: '',
            name: 'Created by You (MYO)',
            items: [
              {
                id: '673be8e8-6ae1-ec11-bb3d-00224892f217',
                name: 'Mediterranean Veg Cheese and Pesto Focaccia',
                description: 'Mediterranean description',
                price: '6.00',
                displayPrice: '',
                priceInText: '',
                category: 'Created by You (MYO)',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uOMId: 0,
              },
            ],
          },
        ],
      },
    ],
    pickupspotreferenceid: 'a06ad89f-bdd2-e811-8199-e0071b67ccf1',
  },
];

jest.mock('../../../api/api', () => ({
  useGetMenusQuery: () => ({
    data: { menus },
    isLoading: false,
  }),
  useGetFoodFacilitiesQuery: () => ({
    data: { facilities },
    isLoading: false,
  }),
}));

jest.mock('../../../api', () => ({
  useGetMenusQuery: jest.fn(),
  useGetFoodFacilitiesQuery: () => ({
    data: { facilities },
    isLoading: false,
  }),
}));

describe('Menus Widget', () => {
  describe('Initial render', () => {
    let facility: HTMLElement, menuItem: HTMLElement;

    beforeEach(async () => {
      (hooks.useGetMenusQuery as jest.Mock).mockReturnValue({
        data: { menus },
        isFetching: false,
      });

      await act(async () => {
        render(addProviders(<Widget />));
      });
    });

    it('should show selected facility and its menu', () => {
      facility = screen.getByText(/Akshay's Facility/i);
      menuItem = screen.getByText(/Roast Pork Mustard/i);

      expect(facility).toBeTruthy();
      expect(menuItem).toBeTruthy();
    });
  });

  describe('on changing facilities', () => {
    describe('when is fetching', () => {
      let skeletonPlaceholder: HTMLElement[];

      beforeEach(async () => {
        (hooks.useGetMenusQuery as jest.Mock).mockReturnValue({
          data: { menus: [] },
          isFetching: true,
        });

        await act(async () => {
          render(addProviders(<Widget />));
        });
      });

      beforeEach(() => {
        skeletonPlaceholder = screen.getAllByTestId('skeleton-placeholder');
      });

      it('should show skeleton placeholder', () => {
        expect(skeletonPlaceholder.length).toEqual(3);
      });
    });
  });
});
