import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MenuIllustration } from '../../../../assets/illustrations';
import { FacilityType } from '../../../../components/ecosystems/Facility/facility.types';
import { Tile, TileSkeleton } from '../../../../components/molecules/Tile';
import Column from '../../../../components/organisms/Column';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import useToggle from '../../../../helpers/hooks/useToggle';
import useSite from '../../../Core/hooks/useSite';
import { useGetFoodFacilitiesQuery } from '../../api/index';
import { fetchFacilityImages } from '../../helpers/menus.helpers';
import { useMenusTranslation } from '../../hooks/useMenusTranslation';
import FacilityDetails from '../FacilityDetails/FacilityDetails';

import { convertFacilitiesToList } from './FoodFacilitiesList.helper';
import { FoodFacility } from './FoodFacilitiesList.types';

const FoodFacilitiesList = () => {
  const { label } = useMenusTranslation(__filename);
  const site = useSite();

  const [facilityImages, setFacilityImages] = useState<{ key: string; image: string }[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<FacilityType>();

  const { data, isFetching: isLoading } = useGetFoodFacilitiesQuery({
    siteId: site?.id || '',
  });
  const { facilities: foodFacilities } = data || { facilities: [] };

  const history = useHistory();

  const {
    state: isFacilityModalOpen,
    toggleOn: openFacilityModal,
    toggleOff: closeFacilityModal,
  } = useToggle(false);

  const fetchImages = useCallback(async () => {
    const result = await fetchFacilityImages(foodFacilities);
    setFacilityImages(result);
  }, [foodFacilities]);

  useEffect(() => {
    if (foodFacilities?.length > 0) fetchImages();
  }, [foodFacilities, fetchImages]);

  const viewFacilityDetails = (facility: FoodFacility) => {
    setSelectedFacility(facility);
    openFacilityModal();
  };

  const listItems = convertFacilitiesToList({
    facilities: foodFacilities,
    facilityImages,
    label,
    viewFacilityDetails,
    history,
  });

  const search = {
    searchableKeys: ['title'],
  };

  return (
    <ListPage
      data-testid="food-facilities-list"
      hasBackLink={false}
      title={label('Ref: Menu Page title')}
      items={listItems}
      itemRenderer={(item) => (
        <Tile
          key={item.key || item.id}
          {...item}
          secondTitleLine={item.secondTitleLine ? item.secondTitleLine : undefined}
        />
      )}
      hideFilterTitle={true}
      search={search}
      isLoading={isLoading}
      aside={
        <Column.Complementary>
          <MenuIllustration />
        </Column.Complementary>
      }
      listTopContent={
        selectedFacility && (
          <FacilityDetails
            label={label}
            isFacilityModalOpen={isFacilityModalOpen}
            onFacilityModalDismiss={closeFacilityModal}
            facility={selectedFacility}
          />
        )
      }
    >
      {isLoading &&
        Array.apply(null, Array(5)).map((_, i) => <TileSkeleton key={i} withoutActions />)}
    </ListPage>
  );
};

export default FoodFacilitiesList;
