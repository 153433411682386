import classNames from 'classnames';
import React, { useState, useRef } from 'react';

import { DownloadIcon, DocumentIcon } from '../../../assets/icons';
import { downloadBase64asFile, isImage } from '../../../helpers/file';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Attachment, { AttachmentInfo } from '../../atoms/Attachment/Attachment';
import Button, { BUTTON_LOOK } from '../../atoms/Button';
import Notification, { NOTIFICATION_LOOK } from '../../molecules/Notification';
import FileUploader from '../FileUploader/FileUploader';
import Modal from '../Modal';

import { AttachmentData, AttachmentsProps } from './Attachments.types';

import styles from './Attachments.module.css';

const defaultMaxFilesNum = 1;
const defaultMaxFileSize = 1024 * 1024 * 10; //10MB
const defaultMaxFileNameLength = 100;

const Attachments = (props: AttachmentsProps) => {
  const { label } = useCustomTranslation();
  const [previewContent, setPreviewContent] = useState<AttachmentInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>();

  const closePreview = (): void => setPreviewContent(null);

  const downloadAttachment = () => {
    const content = previewContent?.fileData?.split(',')[1].trim()!;
    const filename = previewContent?.title ?? 'file';
    downloadBase64asFile(content, filename);
  };

  const updateAttachments = (file: File, operation: 'add' | 'remove'): void => {
    const { attachments, attach } = props;

    let newAttachments: AttachmentData[] = [];

    if (operation === 'add') {
      newAttachments = attachments?.length ? [...attachments] : [];
      var reader = new FileReader();
      reader.onload = (e) => {
        if (!newAttachments.find((el) => el.file.name === file.name)) {
          newAttachments.push({ file, data: e.target?.result?.toString() || '' });
        }
        errorMessages.length > 0 && setErrorMessages([]);
        attach && attach(newAttachments);
      };
      reader.readAsDataURL(file);
    }

    if (operation === 'remove') {
      if (!attachments) {
        return;
      }
      attachments.forEach((attachment) => {
        if (file.name !== attachment.file.name) newAttachments.push(attachment);
      });
      errorMessages.length > 0 && setErrorMessages([]);
      attach && attach(newAttachments);
    }
  };

  const onAttachmentClick = async (attachmentInfo: AttachmentInfo) => {
    if (props.handleAttachmentClick) return props.handleAttachmentClick(attachmentInfo);

    const { getImage } = props;
    const { id, fileData } = attachmentInfo;

    if (!fileData && getImage) {
      setIsLoading(true);
      const imgSrc = await getImage(id);
      setPreviewContent({ ...attachmentInfo, fileData: imgSrc });
      setIsLoading(false);
      return;
    }

    setPreviewContent(attachmentInfo);
  };

  const {
    maxFilesNum,
    maxFileSize,
    maxFileNameLength,
    maxTotalFileSize,
    attachments,
    readOnly,
    closeButtonNotVisible = false,
    displaySize = true,
    children,
    'data-testid': testId = 'attachments-page',
    imageOptimizationOptions,
    fileUploaderConfig,
  } = props;
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  let applicableMaxFilesNum = maxFilesNum ? maxFilesNum : defaultMaxFilesNum;
  const canUpload = !readOnly && (!attachments || attachments.length < applicableMaxFilesNum);
  const fileUploadeRef = useRef<HTMLInputElement>(null);

  const removeFileCreator = (file: File) => () => {
    if (!readOnly) {
      updateAttachments(file, 'remove');
      //Reset fileUpload value so we can trigger onChange again if load same file
      if (fileUploadeRef.current) {
        fileUploadeRef.current.value = '';
      }
    }
  };

  const removeErrorMessageByIndex =
    (index: number): React.MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.preventDefault();
      setErrorMessages(errorMessages.filter((errorMessage, i) => i !== index));
    };

  return (
    <div data-testid={testId} className={classNames(styles.container)}>
      {canUpload && (
        <FileUploader
          fileInputRef={fileUploadeRef}
          onFileSelectSuccess={(file) => updateAttachments(file, 'add')}
          onFileSelectError={({ error }) => setErrorMessages([error])}
          maxFileSize={maxFileSize ? maxFileSize : defaultMaxFileSize}
          totalFilesSize={
            attachments
              ? attachments.reduce(
                  (accumulator, currentValue) => accumulator + currentValue.file.size,
                  0
                )
              : 0
          }
          maxTotalFileSize={maxTotalFileSize}
          imageOptimizationOptions={imageOptimizationOptions}
          config={fileUploaderConfig}
        >
          {children}
        </FileUploader>
      )}
      <div className={classNames(styles.attachmentsContainer)}>
        {attachments.map((attachment) => (
          <Attachment
            id={attachment.id}
            isLoading={isLoading}
            label={label}
            displaySize={displaySize}
            key={attachment.file.name}
            file={attachment.file}
            fileData={attachment.data}
            title={attachment.file.name}
            imagePlaceholder={props?.imagePlaceholder}
            maxFileNameLength={maxFileNameLength ? maxFileNameLength : defaultMaxFileNameLength}
            onAttachmentClick={onAttachmentClick}
            data-testid={`${testId}-attachments`}
            {...(!closeButtonNotVisible && { removeFile: removeFileCreator(attachment.file) })}
          />
        ))}
      </div>
      <div className={classNames(styles.errorMessageContainer)}>
        {!readOnly && !canUpload && (
          <Notification
            look={NOTIFICATION_LOOK.ERROR}
            title={label('You can attach a maximum of {max files number} files.', {
              replace: { 'max files number': applicableMaxFilesNum.toString() },
            })}
          />
        )}
        {errorMessages.map((errorMessage, index) => (
          <Notification
            look={NOTIFICATION_LOOK.ERROR}
            key={index}
            onDismiss={removeErrorMessageByIndex(index)}
            title={errorMessage}
          />
        ))}
      </div>
      <Modal
        isOpen={previewContent !== null}
        onDismiss={closePreview}
        title={previewContent?.previewTitle}
        contentClassName={styles.modalContent}
        footer={
          <Button
            contentCenterAlign
            look={BUTTON_LOOK.SECONDARY}
            className={styles.downloadButton}
            onClick={downloadAttachment}
            affix={() => <DownloadIcon />}
            data-testid="button-attachment-download"
          >
            {label('Download')}
          </Button>
        }
      >
        {previewContent && isImage(previewContent.file) ? (
          <div
            data-testid={`${testId}_preview_file_content_${previewContent?.file?.name}`}
            onClick={closePreview}
            onKeyDown={(e) => e.key === 'Enter' && closePreview()}
            tabIndex={0}
            role="button"
          >
            <img
              data-testid={`${testId}_preview_file_content_${previewContent?.file?.name}_img`}
              className={classNames(styles.previewImage)}
              src={previewContent?.fileData}
              alt={previewContent?.title}
            />
          </div>
        ) : (
          <div className={classNames(styles.iconFileWrapper)}>
            <DocumentIcon width={100} height={135} />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Attachments;
