import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Filters from '../../atoms/Filters/Filters';
import { TITLE_SIZE, TITLE_TAG } from '../../atoms/Title';
import Title from '../../atoms/Title/Title';
import { ContentList } from '../../molecules/ContentList';
import Column from '../../organisms/Column';
import Container from '../../organisms/Container';
import SimpleFormPage from '../SimpleFormPage/SimpleFormPage';

import { ButtonFilterListPage } from './ButtonFilterListPage';
import { ListPageProps } from './ListPage.types';
import { SearchBarListPage } from './SearchBarListPage';
import { useFiltering } from './useFiltering';

import styles from './ListPage.module.css';

import { ItemWithTestingProps } from 'src/types';

const ListPage = <T extends ItemWithTestingProps>({
  search,
  filter,
  items,
  config,
  noResults,
  noResultsClasses,
  hasBackLink,
  title,
  sections,
  tilesStyle,
  actions,
  children,
  customListRenderer,
  hideFilterTitle = false,
  twoTilesColumns,
  listHeader,
  description,
  contentRef,
  listTopContent,
  longTileTextVisible,
  aside,
  asideFirst,
  itemRenderer,
  tileVariant,
  hideAllWidgets,
  isLoading,
  mainColumnClassName,
  'data-testid': testId,
  withNavBar = true,
  errorWithFetchingData = false,
  ...rest
}: ListPageProps<T>) => {
  const { label } = useCustomTranslation();

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const {
    filterItems,
    hasFilters,
    selectedNum,
    hasAtLeastOneFilterInModal,
    hasAtLeastOneFilterVisible,
    searchString,
    filtering,
    handleSearchChange,
    handleFilteringChange,
  } = useFiltering({ items, filter, search });

  const filterButton = useMemo(
    () =>
      hasAtLeastOneFilterInModal ? (
        <ButtonFilterListPage
          label={label('Filters')}
          srOnlyLabel={label('{selectedNum} active filters', {
            replace: { selectedNum: selectedNum.toString() },
          })}
          selectedNum={selectedNum}
          onClick={() => setIsFilterModalOpen(true)}
          data-testid={testId}
        />
      ) : null,
    [hasAtLeastOneFilterInModal, label, selectedNum, testId]
  );

  const filtersAndSearchBar = useMemo(
    () =>
      search || filter?.filters ? (
        <SearchBarListPage
          search={search}
          hasAtLeastOneFilterVisible={hasAtLeastOneFilterVisible}
          searchString={searchString}
          handleSearchChange={handleSearchChange}
          filterButton={filterButton}
          data-testid={`${testId}-search-bar-list-page`}
        >
          {hasFilters ? (
            <Filters
              filters={filter?.filters || []}
              filtering={filtering}
              hideTitle={hideFilterTitle}
              handleChange={handleFilteringChange}
              setModalState={setIsFilterModalOpen}
              isModalOpen={isFilterModalOpen}
              data-testid={`${testId}-search-bar-list-page-filters`}
            />
          ) : null}
        </SearchBarListPage>
      ) : null,
    [
      filter?.filters,
      filterButton,
      filtering,
      handleFilteringChange,
      handleSearchChange,
      hasAtLeastOneFilterVisible,
      hasFilters,
      hideFilterTitle,
      isFilterModalOpen,
      search,
      searchString,
      testId,
    ]
  );

  const listTitle = useMemo(
    () =>
      listHeader ? (
        <Title
          tag={TITLE_TAG.H2}
          size={TITLE_SIZE.SUBTITLES}
          className={classNames(styles.listTitle)}
        >
          {listHeader}
        </Title>
      ) : null,
    [listHeader]
  );

  const listDescription = useMemo(
    () => (description ? <div className={classNames('bodyMDefault')}>{description}</div> : null),
    [description]
  );

  return (
    <SimpleFormPage
      hasBackLink={hasBackLink}
      contentRef={contentRef}
      title={title}
      hideAllWidgets={hideAllWidgets}
      withNavBar={withNavBar}
      {...rest}
    >
      <Container>
        {asideFirst && aside ? aside : null}
        <Column.Main className={classNames(mainColumnClassName)}>
          {listTopContent}
          {filtersAndSearchBar}
          {listTitle}
          {listDescription}
          <ContentList
            items={filterItems}
            isLoading={isLoading}
            config={config}
            sections={sections}
            noResults={
              errorWithFetchingData
                ? { title: label('Something went wrong, data not loaded') }
                : noResults || { title: label('Your selection did not return any result.') }
            }
            noResultsClasses={noResultsClasses}
            itemRenderer={itemRenderer}
            tileVariant={tileVariant}
            customListRenderer={customListRenderer}
            longTileTextVisible={longTileTextVisible}
            twoTilesColumns={twoTilesColumns}
            tilesStyle={tilesStyle}
            data-testid={`${testId}-content-list`}
          />
          {children}
        </Column.Main>
        {!asideFirst && aside ? aside : null}
        {actions ? actions : null}
      </Container>
    </SimpleFormPage>
  );
};

export default ListPage;
