import classNames from 'classnames';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';

import DropdownIndicator from './DropdownIndicator';
import { customStyles, useSelectOptionSwitch } from './helpers';
import IndicatorSeparator from './IndicatorSeparator';
import Option from './Option';
import { SelectOption, SelectProps } from './Select.types';
import SelectWrapper from './SelectWrapper';

const StaticSelect = ({
  label,
  options,
  className,
  onChange,
  value,
  required,
  message,
  formatOptionLabel,
  onInputChange,
  placeholder,
  noOptionsMessage,
  disabled,
  menuPortalTarget,
  filterOption,
  'data-testid': testId,
  openMenuOnFocus,
  ...rest
}: SelectProps) => {
  const { selectedOption } = useSelectOptionSwitch(options, value);

  const handleOnChange = async (option: MultiValue<SelectOption> | SingleValue<SelectOption>) => {
    // TODO: Handle multiple selections
    await onChange(option as SelectOption);
  };

  return (
    <SelectWrapper
      data-cy={rest['data-cy']}
      data-testid={testId}
      label={label}
      message={message}
      required={required}
    >
      <ReactSelect
        className={classNames(className)}
        value={selectedOption || null}
        onChange={handleOnChange}
        options={options}
        placeholder={placeholder}
        components={{ DropdownIndicator, IndicatorSeparator, Option }}
        classNamePrefix="reactSelect"
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        onInputChange={onInputChange}
        isOptionDisabled={(option) => option.disabled || false}
        data-cy={rest['data-cy']}
        data-testid={testId}
        aria-label={label}
        noOptionsMessage={noOptionsMessage}
        isDisabled={disabled}
        menuPortalTarget={menuPortalTarget || null}
        filterOption={filterOption}
        openMenuOnFocus={openMenuOnFocus ?? true}
      />
    </SelectWrapper>
  );
};

export default StaticSelect;
