import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

const useAppListener = () => {
  const history = useHistory();
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const { url } = event;
      if (url) {
        const urlPath = new URL(url);
        const startsWithAppName = new RegExp(`^/${process.env.REACT_APP_APP_NAME}`);
        const slug = urlPath.pathname.replace(startsWithAppName, '/').replace('//', '/');
        const searchQuery = urlPath?.search || '';
        if (slug) {
          history.push(slug + searchQuery);
        }
      }
    });

    App.addListener('backButton', () => {
      history.goBack();
    });

    return () => {
      App.removeAllListeners();
    };
  }, [history]);

  return null;
};

export default useAppListener;
