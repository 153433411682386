const labels = {
  All: {
    dailyMenu: 'לא ידוע',
    anytime: 'בכל עת',
    breakfast: 'ארוחת בוקר',
    morningBreak: 'הפסקת בוקר',
    lunch: 'ארוחת צהריים',
    afternoonBreak: 'אחר הצהריים',
    dinner: 'ארוחת ערב',
    supper: 'ארוחת ערב',
    nightMeal: 'ארוחת לילה',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'תפריטים',
    'Ref: View details': 'הצג פרטים',
    'Ref: Facility details': 'פרטי המתקן',
  },
  OpeningHours: { everyday: 'כל יום', 'open all day': 'פָּתוּחַ כָּל הַיוֹם' },
  ProductsList: {
    'Ref: Page title': 'תַפרִיט',
    'Ref: Facility details': 'פרטי המתקן',
    'Ref: Search products list': 'חיפוש פריטים.',
    'Ref: filter label: date': 'תַאֲרִיך',
    'Ref: filter label: menus': 'תַפרִיט',
    'Ref: filter label: moment': 'רֶגַע',
    'Ref: filter label: category': 'קטגוריה',
    'Ref: filter label: dishes': 'כלי אוכל',
    'Ref: filter label: highlight': 'הדגש או הסתר מסננים עם כלים',
    All: 'את כל',
  },
  ProductDetails: { 'Ref: Page title': 'פרטי מוצר' },
  Widget: {
    'Ref: Carousel title': 'תפריטים',
    'No info yet, come back later': 'אין עדיין מידע, חזור אחר כך 😉',
    'Ref: menus': 'תפריטים',
    'Ref: see all': 'ראה הכל',
  },
};
export default labels;
