const labels = {
  All: {
    Region: 'Região ou país',
    'Ref: Geography': 'Selecione seu país ou região',
    'Ref: Country': 'País',
    'Ref: Country tooltip': 'A localização corresponde ao país do local.',
    'Ref: Please select your country': 'por favor selecione seu país',
    'First name': 'Primeiro nome',
    'Last name': 'Sobrenome',
    'Ref: Full name': 'Nome completo',
    'Mobile number': 'Número de celular',
    'Mobile phone': 'Celular',
    Email: 'Endereço de email',
    Password: 'Senha',
    logout: 'Sair',
    'Not a new user? Log in': 'Não é um novo usuário? Conecte-se',
    'do not register, login': 'Não é um novo usuário? Conecte-se',
    'Ref: I agree': 'Concordo',
    Register: 'Registro',
    'Ref: My language': 'Minha língua',
    'Ref: Kiosk Fail Title': 'Opa! Algo deu errado!',
    'Ref: Kiosk Fail Description':
      'Infelizmente, seu pedido não foi bem -sucedido. Tente novamente ou peça a um membro da equipe para ajudá -lo.',
    'Ref: Kiosk Fail Back': 'Voltar para a página anterior',
    'Ref: Kiosk Fail Action': 'Limpe a sessão e encomende novamente',
    Validate: 'Validar',
    'Ref: error, incorrect credentials':
      'Essas credenciais não correspondem na geografia escolhida.',
    'Ref: Forgot password?': 'Esqueceu sua senha?',
    'Ref: Hello': 'Olá {wave_emoji}, faça o login para continuar',
    'Ref: Form title': 'Por favor, inscreva -se para continuar',
    'Already have an account? Login': 'já tem uma conta? Conecte-se',
  },
  AccountPage: {
    'Ref: My Information': 'Minha informação',
    'Ref: Settings': 'Definições',
    'Ref: Email': 'E-mail',
    'Ref: Current site': 'Site atual',
    'Ref: Choose site': 'Escolha um site',
    'Ref: Personal interests': 'Interesses pessoais',
    'Ref: Food preferences': 'Preferências alimentares',
    'Ref: Password': 'Senha',
    'Ref: Page title': 'Conta',
    'Ref: Language': 'Linguagem',
    'Ref: Communication preferences': 'Preferências de Comunicação',
    'Ref: Terms & conditions': 'Termos e Condições',
    'Ref: Privacy policy': 'Política de Privacidade',
    'Ref: Delete my account': 'Deletar minha conta',
    'Ref: Confirm deletion': 'Tem certeza de que deseja excluir sua conta?',
    'Ref: Request deletion': 'Tem certeza de que deseja solicitar a exclusão da sua conta?',
    'Ref: Deletion description': 'Sua solicitação será tratada de acordo com o regulamento local.',
    Placeholder: 'Escreva "Delete"',
    'Ref: Delete description': 'Seus dados serão excluídos. Esta ação é irreversível.',
    delete: 'excluir',
    'Ref: Terms of sale': 'Termos de venda',
    'Ref: Imprint': 'Imprimir',
    'Ref: Cookie Settings': 'Configurações de cookies',
    'Ref: Support': 'Apoiar',
    'Ref: Register or login': 'Registro ou login',
    'Ref: Filtering Preferences': 'Preferências de filtragem',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Você tem certeza de excluir sua conta?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Escreva "{word}" no campo de entrada e confirme com a parte inferior abaixo. Esta ação é irreversível.',
    'Ref: Write "{word}"': 'Escreva "{word}"',
    'Ref: Delete my account': 'Deletar minha conta',
    'Ref: Words are not matching': 'Palavras não estão combinando',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Escolha sua localização para começar',
    'Ref: Choose location':
      'Encontre rapidamente um local ativando a localização, digitalizando um código QR ou pesquisando pelo nome',
    'Ref: next step': 'Vamos',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'cadastre-se',
    'Ref: identify your location': 'Por favor, identifique sua localização.',
    'Ref: option 1 - scan QR': 'Opção 1: Digitalize o código QR de registro fornecido',
    'Ref: option 2 - enter code': 'Opção 2: Digite manualmente seu código de local',
    'Ref: button to open qr code scanner modal': 'Eu tenho um QR: Vamos digitalizá-lo',
    'Ref: button to open site code input modal': 'Selecione Site com código',
    'Ref: getting help with site identifier - support desk':
      'Se você não estiver claro sobre como prosseguir, entre em contato com sua mesa de suporte.',
    'Ref: getting help with site identifier - support form':
      'Você também pode nos enviar uma nota.',
    'Location code': 'Código de localização',
    'Ref: location code field placeholder': 'Seu código de localização aqui',
    'Ref: Scanner header': 'Digitalize um código QR',
    'Ref: site code input header': 'Adicionar site com código',
    'Ref: not valid code': 'Este código não é válido na geografia selecionada',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Cadastro',
    'Ref: collected info': 'Resumo',
    'Ref: additional info': 'Sua informação',
    'Confirm password': '*Confirmar senha',
    company: 'Quem é o seu empregador?',
    location: 'Localização',
    username: 'nome do usuário',
    register: 'cadastre-se',
    'Ref: password strength':
      'Sua senha deve ter pelo menos 8 caracteres, conter pelo menos um número, uma maiúscula, uma minúscula e um caractere especial.',
    'passwords do not match': 'As senhas não correspondem.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Preferências de Comunicação',
    'Ref: Things happen while you are away': 'As coisas acontecem enquanto você está fora',
    'Ref: Would you like to receive update about offers and promotions?':
      'Você gostaria de receber atualizações sobre ofertas e promoções?',
    'Ref: Would you like to hear about content we think you may like?':
      'Você gostaria de ouvir sobre o conteúdo que pensamos que você pode gostar?',
    Yes: 'Sim',
    No: 'Não',
    apply: 'Aplique',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Detalhes da conta' },
  DeletionFailure: {
    'Ref: Body':
      'Ocorreu um erro e sua solicitação de exclusão da conta não foi enviada. Se esse erro persistir, entre em contato com o seu central de Ajuda.',
    'Go to account': 'Vá para a conta',
  },
  GenericFailurePage: {
    'Ref: Title': 'Algo deu errado',
    'Ref: Body':
      'Desculpe, algo deu errado. Se esse problema persistir, entre em contato com seu suporte no local.',
  },
  HomePage: {
    notifications: 'notificações',
    basket: 'Carrinho',
    'Ref: Tabtitle': 'Inicio',
    'contact us': 'Contate-Nos',
    'Ref: You are logged in as a guest user': 'Você está conectado como usuário convidado',
    'Ref: Good morning': 'Bom Dia',
    'Ref: Good afternoon': 'Boa tarde',
    'Ref: Good evening before midnight': 'Boa noite',
    'Ref: Good evening after midnight': 'Boa noite',
    'Ref: Search': 'Procurar',
    'Ref: products button aria': 'Encontrado {results_form} dentro dos produtos',
    'Ref: menus button aria': 'Encontrado {results_form} dentro dos menus',
    'Ref: facilities button aria': 'Encontrado {results_form} dentro das instalações',
    'Ref: result': 'resultado',
    'Ref: results': 'resultados',
    'Ref: Menus': 'Menus',
    'Ref: No results title': 'Opa!',
    'Ref: No results body': 'Nenhum resultado corresponde à sua pesquisa',
    'Ref: Products': 'Produtos',
    'Ref: Facilities': 'Instalações',
    'Ref: See more results': 'Veja mais resultados de pesquisa',
    'Ref: Accessibility instructions':
      'Para começar a usar a pesquisa, você deve clicar nele ou pressionar Enter enquanto o focou.',
    'Ref: See more': 'Ver mais',
    'Ref: singular form of products': 'produtos',
    'Ref: singular form of menus': 'cardápio',
    'Ref: singular form of facilities': 'instalação',
    'Ref: Search results for': 'Resultados da busca por',
  },
  LanguagePage: {
    apply: 'Aplique',
    'My language': 'Minha língua',
    'Select in which language you would like to read our app':
      'Selecione em qual idioma você gostaria de ler nosso aplicativo',
    'Ref: Page title': 'Idioma',
  },
  LegalDocPage: {
    Acknowledge: 'Reconhecer',
    'Ref: Legal changed notification title': 'Os "{legal_doc_type}" foram modificados',
    'Ref: Legal changed notification content':
      'Por favor, revise -os antes de continuar usando o aplicativo.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Termos e Condições',
    'Read privacy policy': 'Leia a política de privacidade',
    'Ref: Acknowledge legal docs': 'Eu aceito estes termos e condições.',
    'Ref: Read and understood': 'Eu li e entendi ',
    'Ref: No legal docs available - notice title': 'Em breve',
    'Ref: No legal docs available - notice body':
      'Desculpe, parece que esta aplicação não está disponível atualmente em sua geografia.',
    'Ref: The privacy Policy': 'a política de privacidade',
    'Ref: Legal changed notification title': 'Os "{legal_doc_type}" foram modificados',
    'Ref: Legal changed notification content':
      'Por favor, revise -os antes de continuar usando o aplicativo.',
  },
  LoginPage: {
    'Ref: Page title': 'Bem-vindo {app name}  App',
    'Ref: Page subtitle': 'Entre para continuar',
    'Ref: Email field placeholder': 'Seu email',
    'Ref: Email field note': 'Nós nunca vamos compartilhar seu email com ninguém.',
    'Ref: Password field title': 'Sua senha',
    register: 'cadastre-se',
    'I forgot my password.': 'Esqueceu a senha?',
    'I have trouble logging in': 'Está com problemas para fazer login?',
    'start registration': 'Iniciar registro',
    'Ref: Register': 'Registro',
    'Ref: Login': 'Conecte-se',
    'Ref: Connection issue?': 'Problema de conexão?',
    'Ref: Connection issue message':
      'Se você tiver algum problema para se registrar ou fazer login, entre em contato com a equipe usual no local ou com o central de ajuda.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Olá e bem -vindo a {site_name}', 'Ref: Begin': 'Começar' },
  NotificationsList: {
    'Ref: No notifications': 'Você não tem notificações por um momento',
    'Ref: This week': 'Essa semana',
    'Ref: Earlier': 'Mais cedo',
    'Ref: Page title': 'Notificações',
    'Ref: See more': 'Ver mais',
    'Ref: Mark all read': 'Marcar todas como lidas',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Veja todas as notificações' },
  ServicesMenuPage: { 'Ref: Page title': 'Serviços', navigate: 'navegar' },
  DeletionSuccess: {
    'Ref: Title': 'A exclusão da sua conta foi solicitada',
    'Ref: Content':
      'Vamos lidar com isso em breve. Você pode perder seu acesso a qualquer momento.',
    'Log out': 'Sair',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Nós entraremos em contato com você',
    'Ref: Body':
      'Lamentamos, você está enfrentando problemas. Entraremos em contato muito em breve.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Está com problemas para fazer login?',
    'Ref: Email field placeholder': 'Teu e-mail',
    'Ref: Mobile phone field placeholder': 'Número de telefone',
    Company: 'Companhia',
    'Ref: Company field placeholder': 'Nome da empresa',
    'Ref: Company field note':
      'Se você é um consultor, esta é a empresa para a qual você está trabalhando atualmente, não seu empregador.',
    Location: 'Cidade ou localização',
    'Ref: Location field placeholder': 'Localização do seu local de trabalho',
    'Ref: Location field note':
      'Se fora de uma área habitada, sinta-se à vontade para usar o nome pelo qual a área é comumente referida.',
    Comment: 'Comentário',
    'Ref: Comment field placeholder': 'O que aconteceu?',
    'Ref: Who is your employer?': 'Quem é seu empregador?',
    'Ref: What village are you staying at?': 'Em que vila você está hospedado?',
    'Ref: Comment field note': 'Qualquer informação que possa nos ajudar a ajudá-lo!',
    'Ref: Feedback Content - geography': 'Geografia',
    'Ref: Feedback Content - fullName': 'Nome',
    'Ref: Feedback Content - email': 'E-mail',
    'Ref: Feedback Content - phone': 'Telefone',
    'Ref: Feedback Content - company': 'Companhia',
    'Ref: Feedback Content - city': 'Nome da cidade / localização',
    'Ref: Feedback Content - whatVillage': 'Vila',
    'Ref: Feedback Content - employer': 'Empregador',
    'Ref: Feedback Content - comment': 'O que deu errado',
  },
  AccountStartPage: {
    'Ref: Header': 'Escolha sua localização para começar',
    'Ref: Body':
      'Encontre rapidamente um local ativando a localização, digitalizando um código QR ou pesquisando pelo nome',
    'Ref: LetsGo button': 'Vamos',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Cadastro' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Selecione Site com QR Scanner',
    'Ref: Page title': 'Escolha a sua localização',
    'Ref: Select site with code': 'Selecione Site com código',
    'Ref: Enter site code': 'Digite o código do site',
    'Ref: Scanner error header': 'Site não encontrado',
    'Ref: Not found': 'Este código não é válido na geografia selecionada',
    'Ref: Generic error': 'Algo deu errado',
    'All other sites': 'Todos os outros sites.',
    'Sites in your country': 'Sites em seu país',
    'Ref: Unauthorized error': 'Solicitação não autorizada devido ao nível de restrição do local',
    'Ref: Forbidden':
      'Desculpe, você não tem as permissões certas para acessar este site. Para organizar o acesso, entre em contato com o Pilbara Service Center em 1800 992 777',
    "Ref: We didn't find the site.": 'Não encontramos o site.',
    'Ref: We recommend some solutions:': 'Recomendamos algumas soluções:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Tente adicionar seu site com o scanner QR ou com a ajuda de botões abaixo',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Faça logon e faça o login selecionando o país correto',
    'Ref: The currently selected country is:': 'O país atualmente selecionado é:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Cadastro',
    'Confirm password': 'Confirme a Senha',
    'Email is not valid': 'E-mail não é válido',
    'passwords do not match': 'As senhas não correspondem.',
    'Ref: Password strength':
      'Sua senha deve ter pelo menos 8 caracteres, conter pelo menos um número, uma maiúscula, uma minúscula e um caractere especial.',
    'Sign up': 'Inscrever-se',
    'Continue as guest': 'Continue como convidado',
    'Already have an account? Login': 'já tem uma conta? Conecte-se',
    'Ref: Registration success title': 'Obrigado por se registrar',
    'Ref: Registration success content':
      'Sua conta será criada em breve. Você receberá um e -mail confirmando a criação.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Faça login/inscreva -se',
    'Ref: Password strength':
      'Sua senha deve ter pelo menos 8 caracteres, conter pelo menos um número, uma maiúscula, uma minúscula e um caractere especial.',
    Stamps: 'Selos',
    'Order history': 'Histórico de pedidos',
    'to earn points': 'para ganhar pontos',
    'to see order history': 'Para ver a história do pedido',
    'to place an order': 'Para fazer um pedido',
    'Sign up': 'Inscrever-se',
    Login: 'Conecte-se',
    'Email is not valid': 'E-mail não é válido',
    'passwords do not match': 'As senhas não correspondem.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Obrigada',
    'Ref: Body':
      'Você está quase pronto! Em breve, você receberá um e -mail de verificação no endereço fornecido. Abra este email e clique no link para ativar sua conta.',
    'Ref: Enter': 'Continuar',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Ocorreu um erro',
    'Ref: Generic Error Body':
      'Por favor, tente novamente mais tarde ou entre em contato com o seu central de ajuda.',
    'Ref: Missing IndexDb support':
      'Desculpe, você não pode acessar o aplicativo no modo Incognito ou sem suporte de indexdb. Por favor, tente novamente com outro navegador.',
    'Ref: Resource not found': 'O conteúdo que você está tentando acessar não existe.',
    'Ref: Permission denied - SITE_HIDDEN':
      'O conteúdo que você está tentando acessar pertence a um site privado.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'O conteúdo que você está tentando acessar não está autorizado para sua conta.',
    'Ref: TabTitle': 'Erro',
    'Ref: Site link invalid':
      'Este link é inválido, selecione outro site ou entre em contato com o central de ajuda se o problema persistir.',
    'Ref: Access Denied': 'Acesso negado. Permissões de usuário insuficientes.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Opa! {robot_emoji}',
    'Ref: Error title': 'Algo deu errado!',
    'Ref: Error body':
      'Tente atualizar esta página ou entrar em contato com o central de ajuda se o problema persistir.',
    'Ref: Error ID:': 'ID do erro: {errorId}',
    'Ref: Back to home': 'De volta para casa',
  },
  Child: {
    'Ref: Route Not Found':
      'O serviço que você está procurando não está disponível neste site. Por favor, tente novamente mais tarde ou entre em contato com o seu central de ajuda.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Parabéns, sua nova senha foi salva com sucesso, agora você pode acessar todos os nossos serviços em sua conta.',
    Welcome: 'Bem-vindo',
    'Ref: Welcome message':
      'Agora você se registrou com sucesso no novo aplicativo de mordida e pode continuar descobrindo e aproveitando todos os nossos serviços.',
  },
  PasswordChange: {
    'Change Password': 'Mudar senha',
    Welcome: 'Bem-vindo',
    'Ref: Password message': 'Sua senha precisa ser alterada porque não é segura o suficiente',
  },
  AccountPasswordChange: {
    'Change your password here': 'Altere sua senha aqui',
    'Your old password': 'Sua senha antiga',
    'Type in your new password': 'Digite sua nova senha',
    'Re-type in your new password': 'Rytype em sua nova senha',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'Sua senha foi mudada' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Ocorreu um erro, portanto, sua mudança não foi salva. Por favor, tente novamente.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Redefinir senha',
    'Ref: Provide email':
      'Forneça o endereço de e -mail usado ao se registrar em {app_name}. Um email será enviado a você para redefinir sua senha.',
    'Ref: Something happened': 'Algo aconteceu. Por favor, tente novamente mais tarde.',
  },
  ResetEmailSent: {
    'Ref: New email sent': 'Enviamos um novo e -mail. Aguarde 30 segundos para reenviar novamente.',
    'Go to login page': 'Vá para a página de login',
    'Resend email': 'Reenviar email',
    'Ref: Email sent title': 'Um e -mail de redefinição de senha foi enviado para você',
    'Ref: TabTitle': 'Redefinir senha',
    'Ref: Email sent msg':
      'O link de redefinição foi enviado para o seu endereço {email_address}. Aguarde 30 segundos antes de tentar reenviar.',
  },
  ResetPassword: { 'Enter your new password': 'Digite sua nova senha' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Sua senha foi alterada com sucesso',
    'Go to login page': 'Vá para a página de login',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Redefinição de senha falha',
    'Ref: Error': 'Ocorreu um erro, tente novamente',
    'Reset your password': 'Redefina sua senha',
    'Go to login page': 'Vá para a página de login',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'política de Privacidade',
    'Ref: Privacy Policy Body':
      'Ao acessar esta plataforma, a Sodexo SA e a entidade local da Sodexo processarão seus dados pessoais para pelo menos gerenciar sua conta e fornecer os serviços e ofertas disponíveis na plataforma. Se você deseja mais informações sobre o processamento de seus dados pessoais, quem os acessará e como exercer os direitos que você tem em seus dados pessoais, consulte a política de privacidade abaixo e disponível a qualquer momento em sua conta.',
    'Ref: Continue': 'Continuar',
    'Ref: Read Privacy Policy': 'Leia a Política de Privacidade',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Vá para a loja' },
  SharePage: { 'Ref: Select a site': 'Selecione um site' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Para visualizar a política de privacidade, selecione seu país.',
  },
};
export default labels;
