const mockResponse = {
  getIdentityContext: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        client: 'Sodexo',
        type: 'SAML',
        // * you can leave provider empty to switch off sso if needed
        // provider: '',
        // provider: 'G@tes',
        tokenUrlV2:
          'https://gatespreprod.sodexonet.com/adfs/ls/idpinitiatedsignon.aspx?RelayState=RPID=test.mobile.ifm',
      }),
  },
  getUserContext: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        contactId: '30363be0-7609-e911-a972-000d3a2bcd97',
        firstName: 'Antoine',
        lastName: 'Hubert',
        email: 'apptest1@apptest1.com',
        mobile: '1234567',
        qrCode: '',
        dayOfBirth: '2000-01-18',
        contract: {
          id: '2d99f82e-23c6-e811-a96e-000d3a2bc5c1',
          code: 'FBRKM',
          name: 'Fabrikam',
        },
        site: {
          currency: {
            currencyName: 'US Dollar',
            currencySymbol: '$',
            isoCode: 'USD',
            exchangeRate: 1.0,
            precision: 2,
          },
          id: '4aa09563-a78d-e911-a978-000d3a2bcac2',
          code: 'Test',
          name: 'Amsterdam Headquarter',
          isActive: true,
        },
        preferredLanguage: {
          id: '4bba9048-623f-4d2f-aa81-64da7fac16a4',
          name: 'English',
          displayName: 'English',
          systemLanguage: 1033,
          languageCode: 'en-US',
          lcid: 1033,
        },
        completedAppSetup: false,
        allowContent: '224960000',
        allowOffers: '224960001',
        theme: {
          id: 'a678a88f-dd49-eb11-8fed-0003ff4cb7fc',
          name: 'Shell',
          lastModifiedOn: '2020-12-29T14:10:36Z',
        },
        preferredLocation: {
          id: '4aa09563-a78d-e911-a978-000d3a2bcac2',
          name: 'FMG Hamilton - J - Ground Floor',
          title: 'FMG Hamilton - J - Ground Floor, Jabiru',
          site: {
            id: '4aa09563-a78d-e911-a978-000d3a2bcac2',
            name: 'FMG Hamilton',
          },
        },
      }),
  },
  updateUserContext: {
    ok: true,
    status: 202,
    json: () => Promise.resolve(),
  },
  checkEmailExists: {
    ok: true,
    status: 200,
    json: () => Promise.resolve(),
  },
  sendSupportEmail: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
  setUserRegionCode: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
  login: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        access_token:
          'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiU2FtYW50aGEgRmVybmFuZG8iLCJzdWIiOiJhcHB0ZXN0MUBhcHB0ZXN0MS5jb20iLCJjaWQiOiIzMDM2M2JlMC03NjA5LWU5MTEtYTk3Mi0wMDBkM2EyYmNkOTciLCJjY2lkIjoiMmQ5OWY4MmUtMjNjNi1lODExLWE5NmUtMDAwZDNhMmJjNWMxIiwicm9sZSI6WyJDb25zdW1lciBBcHAgVXNlciIsIkNvbnN1bWVyIEFwcCBBZG1pbiIsImlGTSAtIENvbnN1bWVyIl0sIm5iZiI6MTU4OTMxNjU4NiwiZXhwIjoxNTg5MzIwMTg2LCJpc3MiOiJodHRwczovL29hdXRoLnBvcnRhbC5zb2RleG8uY29tIiwiYXVkIjoiMjQxNWYzYzktZmVhMC00OTg1LTk3NDctZDI1NTZhMTA2ZmNmIn0.WpH0AQvYQjd-v2l-CJLaa6oRV_oo3YO1kfpd-J5q2Y1ZBO1z_q306UIlm_UeafDwsjjs0pTzd-PXnfLpwOpcAQ',
        token_type: 'bearer',
        expires_in: 3599,
        refresh_token: 'MThmOTk2OTliNDQxNGU0OWFjZmViOTFmOTdlYzBmMWY',
      }),
  },
};

export default mockResponse;
