import { useMemo } from 'react';
import { useHistory } from 'react-router';

import withSite from '../../../../_deprecated/context/withSite';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import { Tile, TILE_VARIANT } from '../../../../components/molecules/Tile';
import { pagePaths } from '../../config';
import { getPrice } from '../../helpers/order.helper';
import { buildSticker } from '../../helpers/productTile.helpers';
import { ProductTileProps } from '../../types/productList.types';
import { getProductIllustration } from '../ProductsList/productList.helper';

import useLanguage from '@/modules/Core/hooks/useLanguage';

const ProductTile = ({
  id,
  menuItemId,
  genericCategory,
  title,
  description,
  price,
  productCategory,
  date,
  menu,
  site,
  dark,
  variant = TILE_VARIANT.BIG,
  oneLine,
  focusOnRender,
}: ProductTileProps) => {
  const history = useHistory();

  const image = (
    <ImageWithFallback
      data-testid="product-tile"
      imgElement={getProductIllustration(genericCategory as string)}
    />
  );

  const sticker = buildSticker({
    productCategory,
  });

  const isoCode = site?.currency?.isoCode ?? '';

  const { currentLanguageCode: languageCode } = useLanguage();
  const priceFormatted = getPrice(price, languageCode, isoCode ?? '');

  const menuItemPath = useMemo(
    () =>
      pagePaths.MenuProductDetails.replace(':id', menuItemId.toString())
        .replace(':facilityId', menu?.facilityId)
        .replace(':menuId', menu?.id.toString())
        .replace(':date', date),
    [menuItemId, menu?.facilityId, menu?.id, date]
  );

  return (
    <Tile
      variant={variant}
      oneLineTile={oneLine}
      oneLineDescription={oneLine}
      dark={dark}
      id={id}
      title={title}
      description={description}
      image={image}
      focusOnRender={focusOnRender}
      childText={priceFormatted}
      children={<></>}
      stickers={sticker ? [sticker] : []}
      onClick={() => history?.push(menuItemPath)}
      data-testid={`ProductTile-${id}`}
      preventBubblingForCorner
    />
  );
};

export default withSite(ProductTile);
