const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Solicitud de alojamiento',
    'Ref: Describe your request': 'Describe tu solicitud',
    'Ref: Confirm your request': 'Confirme su solicitud',
    'Ref: Your request': 'Tu petición',
    'Ref: Summary': 'Resumen',
    'Ref: Next': 'Próximo',
    'Ref: Submit': 'Entregar',
    'Ref: Me': 'A mí',
    'Ref: Arrival date': 'Fecha de llegada',
    'Ref: Arrival time': 'Hora de llegada',
    'Ref: Departure date': 'Fecha de salida',
    'Ref: Departure time': 'Hora de salida',
    'Ref: request raised for': 'La persona afectada por esta solicitud',
    'Ref: phoneNumber': 'Número de teléfono',
    'Ref: arrivalDate': 'Llegada',
    'Ref: departureDate': 'Partida',
    'Ref: description': 'Comentario',
    'Ref: open': 'Abierto',
    'Ref: pending': 'Pendiente',
    'Ref: refused': 'Rechazado',
    'Ref: closed': 'Cerrado',
    'Ref: all': 'Todo',
    'Ref: Arrival': 'Llegada',
    'Ref: Validate': 'Validar',
    'Ref: site': 'Ubicación del sitio',
    'Ref: Update us': 'Actualizarnos',
    'Ref: email of guest': 'Correo electrónico del huésped',
    'Ref: GuestAccommodationRequestTitle': 'Solicitud de invitado',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Nueva solicitud',
    'Ref: Status': 'Estado',
    'Ref: Date': 'Fecha',
    'Ref: Coming arrivals': 'Llegados que vienen',
    'Ref: Past arrivals': 'Llegados pasados',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Número de teléfono de invitados',
    'Ref: Your phone number': 'Su número de teléfono',
    'Ref: The phone number': 'El numero de telefono',
    'Ref: PhoneNumber error msg': 'El número de teléfono no es válido',
    'Ref: Comment error msg': 'El número máximo de caracteres es 250',
    'Ref: Guest first name': 'Nombre de invitado',
    'Ref: Guest last name': 'Apellido invitado',
    'Ref: request for': '¿Para quién es esta solicitud?',
    'Ref: request for me': 'A mí',
    'Ref: request for guest': 'Alguien más',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Detalles de la solicitud de alojamiento',
    'Ref: Timeline': 'Línea de tiempo',
    'Ref: No updates placeholder': 'No hay actualizaciones para esta solicitud',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Gracias',
    'Ref: Success text':
      'Hemos recibido su solicitud y la estamos investigando ahora. Nos pondremos en contacto con usted pronto.',
    'Ref: Go to home': 'Ir a casa',
    'Ref: Create another request': 'Crea otra solicitud',
  },
  CreateError: {
    'Ref: Create error title': '¡Ups!',
    'Ref: Create error text': 'Ha ocurrido un error. Por favor intente otra vez.',
    'Ref: Retry': 'Intentar otra vez',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Actualenos con cualquier elemento nuevo sobre su solicitud para que podamos apoyarlo mejor.',
  },
};
export default labels;
