const labels = {
  All: {
    'starts at': 'börjar kl',
    'ends at': 'slutar vid',
    location: 'Plats',
    'coming date': 'kommande datum',
    'Ref: Currently event not preferred. Click to make preferred':
      'För närvarande inte föredraget. Klicka för att göra föredraget',
    'Ref: Currently event preferred. Click to make not preferred':
      'För närvarande föredraget händelse. Klicka för att inte föredra',
  },
  EventDetails: {
    details: 'Information',
    'Ref: Page title': 'Evenemangsdetaljer',
    'Event dates': 'Evenemangsdatum',
    'See online:': 'Se online:',
    'See all dates': 'Se alla datum',
    'Remove from my selection': 'Ta bort från mitt val',
    'Add to my selection': 'Lägg till i mina favoriter',
    'Ref: explain calendar usage':
      'Händelsedatum markeras i kalendern nedan. Missa inte de händelser du gillar! Klicka på ett händelsedatum i kalendern för att ladda ner en inbjudan. Öppna sedan den nedladdade filen för att lägga till evenemanget i din förberedda kalender.',
    'Ref: Favorite': 'Favorit',
    'Ref:Feedback': 'Feedback',
    'Ref: Event site different title': 'Denna händelse tillhör inte din nuvarande webbplats',
    'Ref: Event site different description':
      'Du tittar på ett evenemang som inte tillhör din nuvarande webbplats. Du kommer inte att kunna se det i listan över händelser.',
    'Ref: See all dates': 'Se alla datum',
    'Ref: From': 'Från',
    'Ref: to': 'till',
    'Ref: Coming date': 'Kommande datum',
    'Ref: Add to calendar': 'Lägg till kalendern',
  },
  EventsList: {
    'Ref: Page title': 'evenemang',
    'All dates': 'Alla datum',
    'All events': 'Alla evenemang',
    'Ref: Search': 'Sök',
    'My selection': 'Mina favoriter',
    'filter label: date': 'Datum',
    'filter label: coming events': 'kommande evenemang',
    'filter label: past events': 'tidigare händelser',
    'filter label: interest': 'preferens',
  },
  Widget: {
    'Ref: Carousel title': 'evenemang',
    'No info yet, come back later': 'Ingen info ännu, kom tillbaka senare 😉',
  },
};
export default labels;
