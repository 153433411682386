const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'בקשתך',
    'Ref: Summary': 'סיכום',
    'Ref: Request category': 'בקשת קטגוריה',
    'Ref: Person affected': 'האדם שנפגע מבקשה זו',
    'Ref: Your phone number': 'מספר טלפון',
    'Ref: Description': 'תיאור',
    'Ref: Service Requests': 'בקשות שירות',
    'Ref: Request Service': 'בקשה שירות',
    'Ref: Success title': 'בקשתך נוצרה',
    'Ref: Error title': 'אופס!',
    'Ref: Error subtitle': 'אירעה שגיאה. אנא נסו שוב.',
    'Ref: Try again': 'נסה שוב',
    'Ref: you pronoun': '(אתה)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'זוהי רכזת התמיכה במקום העבודה שלך',
    'Ref: Tutorial: tabname': 'בקשות',
    'Ref: Tutorial: body': 'אנחנו כאן כדי לעזור לך. אנא רשום בקשה אחת לכל ערך.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.': 'אתה מבקש שירות באתר {siteName}.',
    'Ref: Building': 'בִּניָן',
    'Ref: Room': 'חֶדֶר',
    'Ref: Description help text': 'ככל שאתה מפורט יותר, אנו יכולים לתמוך בך טוב יותר.',
    'Ref: Next': 'הַבָּא',
    'Ref: Your request': 'בקשתך',
    'Ref: PhoneNumber error msg': 'מספר הטלפון אינו תקף',
    'Ref: Type at least 3 characters to search': 'הקלד לפחות 3 תווים לחיפוש',
    'Ref: No affected person results': 'אף אדם לא תואם קריטריונים מבוקשים',
    'Ref: Required': '* נדרש',
  },
  Summary: {
    'Ref: Location': 'מקום',
    'Ref: Attachment': 'קבצים מצורפים',
    'Ref: Submit': 'שלח',
    'Ref: No affected person results': 'אף אדם לא תואם קריטריונים מבוקשים',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'בקשת שירות',
    'Ref: createdOn': 'נוצר ב',
    'Ref: summary': 'סיכום',
    'Ref: category': 'בקשת קטגוריה',
    'Ref: request raised for': 'האדם שנפגע מבקשה זו',
    'Ref: phoneNumber': 'מספר טלפון',
    'Ref: location': 'מספר טלפון',
    'Ref: description': 'תיאור',
    'Ref: attachments': 'קבצים מצורפים',
  },
};
export default labels;
