import classNames from 'classnames';
import React, { useEffect } from 'react';
import nl2br from 'react-nl2br';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useCreateServiceRequestMutation, useGetServiceRequestsTypesQuery } from '../../api/index';
import { getServiceRequestConfigurations } from '../../config';
import { renderAffectedPersonName } from '../../helpers/helpers';
import { useServiceRequestSetupOption } from '../../hooks/useServiceRequestSetupOption';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';

import { ConfirmProps } from './Confirm.types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Checkbox from '@/components/atoms/Checkbox';
import Stepper from '@/components/atoms/Stepper';
import { TITLE_TAG, TITLE_SIZE } from '@/components/atoms/Title/Title.types';
import Card from '@/components/molecules/Card/Card';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import List from '@/components/organisms/List/List';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import useToggle from '@/helpers/hooks/useToggle';
import { UpdateUserContextArgs } from '@/modules/Core/api/account/accountApi.types';
import { useUpdateUserContextMutation } from '@/modules/Core/api/account/userContextApi';
import { isMyVillage } from '@/modules/Core/helpers/helpers';
import { pagePaths } from '@/modules/ServiceRequest/config';
import { GridItem } from '@/types/common.types';
import { State } from '@/types/state.types';

import './Confirm.css';

const Confirm = ({
  selectedSiteId,
  myLocation,
  title,
  category,
  description,
  additionalFields,
  raisedFor,
  templateId,
  attachments,
  goToNext,
  phoneNumber,
  isPreferredLocationCheckboxChecked,
  roomText,
  buildingText,
  withNavBar,
  pathToLocate,
  pathToDescribe,
}: ConfirmProps) => {
  const { label } = useServiceRequestTranslation(__filename);
  const history = useHistory();
  const [create] = useCreateServiceRequestMutation();
  const [updateUserContext] = useUpdateUserContextMutation();
  const siteId = useSelector((state: State) => state.Core.context.site?.id || '');
  const currentUser = useSelector((state: State) => state.Core?.user);
  const { requestTypesFromApi } = getServiceRequestConfigurations();
  const { data: requestTypes = [] } = useGetServiceRequestsTypesQuery(
    { siteId: siteId ?? '' },
    {
      skip: !(requestTypesFromApi && siteId),
    }
  );

  const {
    state: submitting,
    toggleOn: startSubmiting,
    toggleOff: stopSubmiting,
  } = useToggle(false);

  const { state: saveAsPreferredLocation, toggle: toggleSaveAsPreferredLocation } = useToggle(
    roomText ? false : isPreferredLocationCheckboxChecked
  );

  const displayCategorizationOption = useServiceRequestSetupOption('DisplayCategorizationField');
  const displayCategory = isMyVillage() || displayCategorizationOption;

  const enableAffectedPerson = useServiceRequestSetupOption('enableAffectedPerson');
  const hidePhoneNumber = useServiceRequestSetupOption('hidePhoneNumber');

  useEffect(() => {
    //if previous step was not completed, redirect
    if (!title) {
      history.replace(pathToDescribe);
    }
  }, [title, pathToDescribe, history]);

  //otherwise post and display confirmation page
  const translatedCategory =
    requestTypes?.length > 0
      ? label(requestTypes.find(({ id }) => id === category)?.name || '')
      : label(category);

  const images = attachments.map((attachment) => ({
    id: '',
    fileName: attachment.file.name,
    content: attachment.data?.substring(attachment.data?.indexOf('base64,') + 'base64,'.length),
  }));
  const nextAction = async () => {
    startSubmiting();
    const referenceNumber = 'request_submission_in_progress';

    const descriptionAdditionals = [];
    if (displayCategory) {
      descriptionAdditionals.push(
        `${label('Ref: Request category', { end: 'colon' })}${translatedCategory}`
      );
    }

    const descriptionWithAdditionals =
      `${descriptionAdditionals.length > 0 ? descriptionAdditionals.join('\n') + '\n' : ''}` +
      description;

    const urlTemplate = `${process.env.REACT_APP_HOST}${pagePaths.Details}`;

    try {
      const result = await create({
        siteId: selectedSiteId || siteId,
        location: myLocation,
        referenceNumber,
        title,
        description: descriptionWithAdditionals,
        templateId,
        raisedForId: raisedFor.value,
        images,
        roomText: myLocation?.id ? '' : roomText,
        buildingText,
        category,
        consumerAppUrlTemplate: urlTemplate,
      });

      const updateUserContextData = {
        mobile: phoneNumber,
      } as UpdateUserContextArgs;

      if (saveAsPreferredLocation) {
        updateUserContextData.preferredLocationId = myLocation.id;
        updateUserContextData.preferredLocation = {
          ...myLocation,
          title: myLocation.title ?? '',
          site: {
            id: siteId,
          },
        };
      }

      updateUserContext(updateUserContextData);

      if ('error' in result) {
        goToNext(false);
        return;
      }

      if (result) {
        goToNext(true);
      } else {
        console.log('FAILED TO SUBMIT SR: 413');
      }
    } finally {
      stopSubmiting();
    }
  };

  const items: GridItem[] = [
    {
      id: 'location',
      label: label('Location'),
      value: myLocation?.title || roomText,
      path: isMyVillage() ? pathToDescribe : pathToLocate,
      'data-testid': 'location',
    },
  ];

  items.push({
    id: 'title',
    label: label('Ref: Title'),
    value: title,
    path: pathToDescribe,
    'data-testid': 'title',
  });

  if (displayCategory) {
    items.splice(2, 0, {
      id: 'category',
      label: label('Ref: Request category'),
      value: translatedCategory,
      path: pathToDescribe,
      'data-testid': 'category',
    });
  }

  items.push({
    id: 'description',
    label: label('Ref: description field label'),
    value: nl2br(description).toString(),
    path: pathToDescribe,
    'data-testid': 'description',
  });

  if (attachments.length > 0) {
    const attachmentsItem = {
      id: 'attachments',
      label: label('Attachments'),
      value: (
        <Attachments
          readOnly
          attachments={attachments}
          closeButtonNotVisible
          data-testid={`service-request-confirm-${title}`}
        />
      ),
      path: pathToDescribe,
      wrapLine: true,
      'data-testid': 'attachments',
    };
    items.push(attachmentsItem);
  }

  if (enableAffectedPerson) {
    items.push({
      id: 'raisedFor',
      label: label('Ref: SR raised for'),
      value:
        raisedFor.label ||
        renderAffectedPersonName(
          currentUser.firstName || '',
          currentUser.lastName || '',
          currentUser.email || '',
          label,
          true
        ),
      path: pathToDescribe,
      'data-testid': 'raised-for',
    });
  }

  if (phoneNumber && !hidePhoneNumber) {
    items.push({
      id: 'phoneNumber',
      label: label('phone_number'),
      value: phoneNumber,
      path: pathToDescribe,
      'data-testid': 'phone',
    });
  }

  if (!title) return <LoadingPage />;

  return (
    <SimpleFormPage withNavBar={withNavBar} title={label('Ref: Page title')}>
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              tag: TITLE_TAG.H2,
              children: label('summary'),
              size: TITLE_SIZE.HEADLINES,
            }}
          >
            <List data-testid="service-request-confirm-summary-list" items={items} />
            {additionalFields?.map((fieldInfo) => (
              <React.Fragment key={Math.random()}>
                <h3>{fieldInfo.label}</h3>
                <div>{fieldInfo.value}</div>
                <br />
              </React.Fragment>
            ))}
          </Card>
          {pathToLocate && myLocation && (
            <div className={classNames('checkbox')}>
              <Checkbox
                id="checkbox-save-as"
                checked={saveAsPreferredLocation}
                onClick={toggleSaveAsPreferredLocation}
                inputLabel={`${label('Ref: Save as my preferred location')} "${myLocation.title}"`}
                data-testid="service-requests-save-as-preffered-location-checkbox"
              />
            </div>
          )}
        </Column.Main>
        <Column.Side>
          <Stepper />
        </Column.Side>
        <ActionsBar>
          <Button
            data-testid="service-request-next-action"
            look={BUTTON_LOOK.PRIMARY}
            loading={submitting}
            onClick={nextAction}
            disabled={submitting}
          >
            {label('Ref: next', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default Confirm;
