import { FACILITY_TYPE } from '../../Facilities/types/types';
import { useGetMenusQuery } from '../api/menuApi';
import { FacilityMenuItem, FacilityType } from '../types/menuSelector.types';

import useSite from '@/modules/Core/hooks/useSite';
import { useGetFacilitiesListQuery } from '@/modules/Facilities/api/api';

const useFacilityMenuList = (
  menuDate: string
): { facilityMenus: FacilityMenuItem[]; isLoading: boolean } => {
  const { id: siteId } = useSite({ throwWhenNoActiveSite: true })!;

  const { data: menus, isFetching: menusLoading } = useGetMenusQuery({ siteId, menuDate });
  const { data: facilitiesResponse, isFetching: facilitiesLoading } = useGetFacilitiesListQuery({
    siteId,
  });

  const facilities = facilitiesResponse?.facilities
    .map((fac) => {
      return {
        id: fac.id,
        title: fac.title,
        name: fac.name,
        sequence: fac.sequence,
        image: fac.imageInfo,
        type: FACILITY_TYPE[fac?.facilityType?.name] || FacilityType.Undefined,
      };
    })
    .filter(
      (fac) =>
        fac.type === FacilityType.FoodNonRetail ||
        fac.type === FacilityType.FoodRetail ||
        fac.type === FacilityType.ConferenceCatering
    );

  const facilityMenus =
    menus
      ?.map((menu) => {
        const facility = facilities?.find((fac) => fac.id === menu.facilityId);

        if (facility) {
          return {
            id: facility.id,
            title: facility.title || '',
            name: facility.name || '',
            sequence: facility.sequence || 0,
            image: facility.image,
            type: facility.type || FacilityType.Undefined,
            menu,
          };
        }

        return undefined;
      })
      .filter((facilityMenu) => !!facilityMenu) || [];

  return {
    facilityMenus,
    isLoading: menusLoading || facilitiesLoading,
  };
};

export default useFacilityMenuList;
