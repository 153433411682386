import React from 'react';

import { MAIN_WIDTH } from './Layout';

export type ColumnProps = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export enum LayoutBehaviorType {
  SuccessPage,
  DetailWithImagePage,
  CenteredPage,
  DoubleColumnCenteredBottomBarAndSideImageOnMobile,
}

export type InheritStyleProps = {
  sideImage?: string;
};

export type LayoutProps = {
  mainWidth?: MAIN_WIDTH;
  sideImage?: React.ReactChild;
  layoutBehaviorType?: LayoutBehaviorType;
  topContent?: React.ReactNode;
  // TODO once bottom bar object is properly typed
  bottomBar?: any;
  contentRef?: React.RefObject<HTMLDivElement>;
  topBarRef?: React.RefObject<HTMLDivElement>;
  children: React.ReactNode;
  secondColumn?: React.ReactNode;
  withStepper?: boolean;
  inheritStyle?: InheritStyleProps;
};
