const labels = {
  All: {
    'Ref: See item': 'ดูรายการ',
    'Ref: Print': 'พิมพ์ใบเสร็จของคุณ',
    'Ref: Email': 'รับใบเสร็จรับเงินของคุณทางอีเมล',
    'Ref: Receipt Modal message': 'คุณต้องการรับใบเสร็จของคุณอย่างไร?',
    'Ref: Loyalty Reward': 'รางวัลความภักดี',
    'Ref: Equation for stamps per item': '1 ซื้อ = 1 แสตมป์',
    'Ref: Paid by employer': 'จำนวนเงินที่นายจ้างจ่าย',
    'Ref: Paid by user': 'จำนวนเงินที่คุณจ่าย',
  },
  ProductsList: {
    'Ref: Page title': 'รายการสินค้า',
    'Ref: Facility details': 'รายละเอียดสิ่งอำนวยความสะดวก',
    'Ref: Search products list': 'รายการค้นหา',
    'Ref: filter label: menus': 'เมนู',
    'Ref: filter label: moment': 'ช่วงเวลา',
    'Ref: filter label: category': 'หมวดหมู่',
    'Ref: all': 'ทั้งหมด',
    'Ref: today': 'ในวันนี้',
    'Ref: tomorrow': 'วันพรุ่งนี้',
    'Ref: see more': 'ดูเพิ่มเติม',
    'Ref: see less': 'ดูน้อยลง',
    'Ref: reset': 'รีเซ็ต',
    'Ref: View in cart': 'ดูในรถเข็น',
    'Ref: You have changed location': 'คุณเปลี่ยนตำแหน่ง',
    'Ref: You are now at': 'ตอนนี้คุณอยู่ที่',
    'Ref: Menu card': 'การ์ดเมนู',
    'Ref: Table': 'หมายเลขตาราง',
    'Service unavailable': 'ไม่สามารถให้บริการได้',
    'Ref: Ordering unavailable':
      'ระมัดระวัง! การสั่งซื้อที่ไซต์นี้ไม่สามารถใช้งานได้ในขณะนี้โปรดลองในภายหลัง',
    'Ref: Filters': 'ตัวกรอง (สารก่อภูมิแพ้และอื่น ๆ )',
  },
  Order: { 'Ref: Page title': 'คำสั่ง' },
  MenuSelector: {
    'Ref: Menu Page title': 'เมนู',
    'Ref: today': 'ในวันนี้',
    'Ref: tomorrow': 'วันพรุ่งนี้',
    'Ref: Search products list': 'รายการค้นหา',
    'Ref: You have changed location': 'คุณเปลี่ยนตำแหน่ง',
    'Ref: You are now at': 'ตอนนี้คุณอยู่ที่',
  },
  ProductDetails: {
    'Ref: Page title': 'รายละเอียดสินค้า',
    'Ref: Serving Size': 'ขนาดที่ให้บริการ',
    'Ref: Nutrition': 'ข้อมูลทางโภชนาการ',
    'Ref: Nutrition adults': 'ผู้ใหญ่ต้องการเวลาประมาณ 2,000 kcal ต่อวัน',
    'Ref: Nutrition Calories': 'แคลอรี่',
    'Ref: Nutrition Calcium': 'แคลเซียม',
    'Ref: Nutrition Fat': 'อ้วน',
    'Ref: Nutrition Saturated Fat': 'ไขมันอิ่มตัว',
    'Ref: Nutrition Carbohydrates': 'คาร์โบไฮเดรต',
    'Ref: Nutrition Sugar': 'น้ำตาล',
    'Ref: Nutrition Protein': 'โปรตีน',
    'Ref: Nutrition Sodium': 'โซเดียม',
    'Ref: Nutrition cholesterol': 'คอเลสเตอรอล',
    'Ref: Nutrition dietaryFiber': 'เส้นใยอาหาร',
    'Ref: Nutrition iron': 'เหล็ก',
    'Ref: Nutrition potassium': 'โพแทสเซียม',
    'Ref: Nutrition vitaminA': 'วิตามินเอ',
    'Ref: Nutrition vitaminC': 'วิตามินซี',
    'Ref: Additives': 'สารเติมแต่ง',
    'Ref: Emission': 'การปล่อย CO2',
    'Ref: This product generates': 'ผลิตภัณฑ์นี้สร้างขึ้น',
    'Ref: of CO2': 'ของ co2',
    'Ref: emissions, which equals a': 'การปล่อยมลพิษซึ่งเท่ากับ',
    'Ref: car trip': 'รถเดินทาง',
    'Ref: Ingredients': 'สูตรส่วนผสม',
    'Ref: Allergens': 'ภูมิแพ้',
    'Ref: Always there': 'ที่นั่นเสมอ',
    'Ref: Allergens selected': 'สารก่อภูมิแพ้ที่เลือกในตัวกรอง:',
    'Ref: minimum required': 'ต้องการขั้นต่ำ',
    'Ref: you have to add at least': 'คุณต้องเพิ่มอย่างน้อย',
    'Ref: ingredients': 'ส่วนผสม',
    'Ref: Ingredient': 'วัตถุดิบ',
    'Ref: Free': 'ฟรี',
    'Ref: Based on customization': 'ขึ้นอยู่กับการปรับแต่งของคุณ',
    'Ref: Additives eggs': 'ไข่',
    'Ref: Additives fish': 'ปลา',
    'Ref: Additives milk': 'นม',
    'Ref: Additives nuts': 'ถั่ว',
    'Ref: Additives wheat': 'ข้าวสาลี',
    'Ref: Additives peanuts': 'ถั่ว',
    'Ref: Additives soy beans': 'ถั่วเหลือง',
    'Ref: Additives sesame seeds': 'เมล็ดงา',
    'Ref: Review': 'ทบทวน',
    'Ref: Per portion': 'ต่อส่วน',
    'Ref: Per 100g': 'ต่อ 100 กรัม',
    'Ref: Allergen selected': 'สารก่อภูมิแพ้ที่เลือกบนตัวกรองการค้นหา',
    'Ref: Fitbit Log': 'บันทึกเพื่อ Fitbit',
    'Ref: age verification required on delivery': 'การตรวจสอบอายุที่จำเป็นในการจัดส่ง',
    'Ref: alcohol content notification':
      'โดยการวางและสั่งซื้อหนึ่งในรายการเหล่านี้คุณประกาศว่าคุณมีอายุ 18 ปีขึ้นไป การใช้แอลกอฮอล์ในทางที่ผิดเป็นอันตรายต่อสุขภาพของคุณ',
    'Ref: default': 'ค่าเริ่มต้น',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ได้รับถ้าคุณสั่งซื้อผลิตภัณฑ์นี้',
    'Ref: Loyalty Card Description':
      '{stamp_form} จะถูกเพิ่มลงในความภักดีต่อไปนี้ {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'โปรแกรมแสตมป์ความภักดีช่วยให้คุณสามารถรวบรวมแสตมป์เพื่อรับรางวัล',
    'Ref: Loyalty Modal Description':
      'เมื่อคุณซื้อ <b> {product_name}, 1 แสตมป์ </b> จะถูกเพิ่มลงใน <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'และ',
    'Ref: Stamp': 'ประทับ',
    'Ref: Stamps': 'แสตมป์',
    'Ref: Program': 'โปรแกรม',
    'Ref: Programs': 'โปรแกรม',
    'Ref: Loyalty Stamps': 'แสตมป์ความภักดี',
    'Ref: See Loyalty Stamps': 'ดูแสตมป์ความภักดี',
    Favorite: 'ที่ชื่นชอบ',
    'Ref: In favorites': 'ผลิตภัณฑ์อยู่ในรายการโปรดแล้ว คลิกเพื่อลบออก',
    'Ref: Not in favorites': 'ผลิตภัณฑ์ยังไม่ได้อยู่ในรายการโปรด คลิกเพื่อเพิ่ม',
    'Ref: Added to favorites': 'ผลิตภัณฑ์ได้รับการเพิ่มในรายการโปรด',
    'Ref: Removed from favorites': 'ผลิตภัณฑ์ถูกลบออกจากรายการโปรด',
    'Ref: From': 'จาก',
  },
  OrderHistory: { 'Ref: Orders': 'คำสั่งซื้อ', 'Ref: Page Title': 'คำสั่งซื้อของฉัน' },
  MenuTile: { 'Ref: Scan&Go': 'สแกนและไป', 'Ref: View only': 'สำหรับดูเท่านั้น' },
  ProductTile: {
    'Ref: Allergens': 'สารก่อภูมิแพ้',
    'Ref: Presence of allergen': 'การปรากฏตัวของสารก่อภูมิแพ้',
    'Ref: Scan': 'สแกน',
    'Ref: From': 'จาก',
    'Ref: Already in product favorite list. Click to remove it.':
      'อยู่ในรายการผลิตภัณฑ์โปรดของผลิตภัณฑ์แล้ว คลิกเพื่อลบออก',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'ปัจจุบันยังไม่ได้เพิ่มในรายการผลิตภัณฑ์โปรดของผลิตภัณฑ์ คลิกเพื่อเพิ่ม',
    'Ref: Free': 'ฟรี',
  },
  MenusWidget: { 'Ref: no menu': 'ไม่มีเมนูในขณะนี้', 'Ref: Modal title': 'เมนูสำหรับวัน' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'ไปรับ',
    'Ref: Eat-in': 'กิน',
    'Ref: Scan&Go': 'สแกนและไป',
    'Ref: Delivery': 'จัดส่ง',
    'Ref: Total': 'ทั้งหมด',
    'Ref: How was facility name service': 'บริการ {facility_name} เป็นอย่างไร?',
    'Ref: How do you rate the service': 'คุณให้คะแนนบริการอย่างไร?',
  },
};
export default labels;
