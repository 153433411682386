const labels = {
  SiteDetails: {
    'Select site': 'Selecteer deze site',
    'Ref: Page title': 'Sitegegevens',
    'Ref: Skip map': 'Sla over en ga naar hoofdinhoud',
    'Ref: Unavailable site': 'Site Preview niet beschikbaar voor uw gebruiker',
    'Ref: Sorry, no description yet': 'Sorry, we hebben nog geen beschrijving.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Site -coördinaten niet gevonden',
    'Ref: Page title': 'Sites',
    'Recently visited': 'Onlangs bezocht',
    'All other sites': 'Alle andere sites',
    'Sites in your country': 'Sites in uw land',
    'Search results': 'Zoekresultaten',
    'Select site': 'Selecteer Site',
    'Ref: Select site with QR Scanner': 'Selecteer Site met QR -scanner',
    'Ref: Select site with code': 'Selecteer Site met code',
    'Ref: Validate': 'Bevestigen',
    'Ref: Enter site code': 'Voer de sitecode in',
    'Ref: Scanner error header': 'Site niet gevonden',
    'Ref: Generic error': 'Er is iets fout gegaan',
    'Ref: Change site': 'Verander site',
    'Ref: Change site description':
      'Er zijn items in de winkelkar! Het wijzigen van de site zal resulteren in het wissen van de winkelkar.',
    'Ref: Cancel': 'Annuleren',
    'Ref: Not found': 'Deze QR -code is niet herkend',
    'Ref: Unauthorized error': 'Ongeautoriseerd verzoek vanwege sitebeperkingsniveau',
    'Ref: Forbidden':
      'Sorry, u hebt niet de juiste machtigingen om toegang te krijgen tot deze site. Neem contact op met het Pilbara Service Center op 1800 992 777 om toegang te regelen',
    "Ref: We didn't find the site.": 'We hebben de site niet gevonden.',
    'Ref: We recommend some solutions:': 'We raden enkele oplossingen aan:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Probeer uw site toe te voegen met de QR -scanner of met behulp van onderstaande knoppen',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Log uit en log weer in en selecter het juiste land',
    'Ref: The currently selected country is:': 'Het momenteel geselecteerde land is:',
  },
  SwitchSite: { 'Not on {site name}?': 'Niet op {site name}?' },
  SiteTile: { Details: 'Details', Select: 'Selecteren' },
};
export default labels;
