const labels = {
  All: {
    'Ref: See item': 'Lihat item',
    'Ref: Print': 'Cetak kwitansi Anda',
    'Ref: Email': 'Terima kwitansi Anda melalui email',
    'Ref: Receipt Modal message': 'Bagaimana Anda ingin mendapatkan tanda terima?',
    'Ref: Loyalty Reward': 'Hadiah loyalitas',
    'Ref: Equation for stamps per item': '1 pembelian = 1 stempel',
    'Ref: Paid by employer': 'Jumlah yang dibayarkan oleh majikan Anda',
    'Ref: Paid by user': 'Jumlah yang dibayarkan oleh Anda',
  },
  ProductsList: {
    'Ref: Page title': 'Daftar Produk',
    'Ref: Facility details': 'Detail Fasilitas',
    'Ref: Search products list': 'Cari barang',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Momen',
    'Ref: filter label: category': 'Kategori',
    'Ref: all': 'Semua',
    'Ref: today': 'Hari ini',
    'Ref: tomorrow': 'Besok',
    'Ref: see more': 'Lihat lebih',
    'Ref: see less': 'Lihat lebih sedikit',
    'Ref: reset': 'Mengatur ulang',
    'Ref: View in cart': 'Lihat di CART',
    'Ref: You have changed location': 'Anda telah mengubah lokasi',
    'Ref: You are now at': 'Anda sekarang berada di',
    'Ref: Menu card': 'Kartu menu',
    'Ref: Table': 'Nomor Tabel',
    'Service unavailable': 'Layanan tidak tersedia',
    'Ref: Ordering unavailable':
      'Hati-hati! Memesan di situs ini tidak tersedia saat ini, silakan coba nanti.',
    'Ref: Filters': 'Filter (alergen dan banyak lagi)',
  },
  Order: { 'Ref: Page title': 'Memesan' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menu',
    'Ref: today': 'Hari ini',
    'Ref: tomorrow': 'Besok',
    'Ref: Search products list': 'Cari barang',
    'Ref: You have changed location': 'Anda telah mengubah lokasi',
    'Ref: You are now at': 'Anda sekarang berada di',
  },
  ProductDetails: {
    'Ref: Page title': 'Rincian Produk',
    'Ref: Serving Size': 'Ukuran porsi',
    'Ref: Nutrition': 'Informasi gizi',
    'Ref: Nutrition adults': 'Orang dewasa membutuhkan sekitar 2000 kkal sehari',
    'Ref: Nutrition Calories': 'Kalori',
    'Ref: Nutrition Calcium': 'Kalsium',
    'Ref: Nutrition Fat': 'Lemak',
    'Ref: Nutrition Saturated Fat': 'Lemak jenuh',
    'Ref: Nutrition Carbohydrates': 'Karbohidrat',
    'Ref: Nutrition Sugar': 'Gula',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Serat makanan',
    'Ref: Nutrition iron': 'Besi',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C.',
    'Ref: Additives': 'Aditif',
    'Ref: Emission': 'emisi CO2',
    'Ref: This product generates': 'Produk ini menghasilkan',
    'Ref: of CO2': 'dari CO2.',
    'Ref: emissions, which equals a': 'emisi, yang sama dengan a',
    'Ref: car trip': 'Perjalanan mobil',
    'Ref: Ingredients': 'Bahan resep.',
    'Ref: Allergens': 'Alergen',
    'Ref: Always there': 'Selalu ada',
    'Ref: Allergens selected': 'Alergen dipilih pada filter:',
    'Ref: minimum required': 'Minimum diperlukan',
    'Ref: you have to add at least': 'Anda harus menambahkan setidaknya',
    'Ref: ingredients': 'bahan',
    'Ref: Ingredient': 'bahan',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Berdasarkan kustomisasi Anda',
    'Ref: Additives eggs': 'telur',
    'Ref: Additives fish': 'ikan',
    'Ref: Additives milk': 'susu',
    'Ref: Additives nuts': 'gila',
    'Ref: Additives wheat': 'gandum',
    'Ref: Additives peanuts': 'kacang kacangan',
    'Ref: Additives soy beans': 'Kacang kedelai',
    'Ref: Additives sesame seeds': 'biji wijen',
    'Ref: Review': 'Tinjauan',
    'Ref: Per portion': 'Per bagian',
    'Ref: Per 100g': 'Per 100g',
    'Ref: Allergen selected': 'Alergen dipilih pada filter pencarian',
    'Ref: Fitbit Log': 'Log ke Fitbit',
    'Ref: age verification required on delivery': 'Verifikasi usia diperlukan saat persalinan',
    'Ref: alcohol content notification':
      'Dengan menempatkan dan memesan untuk salah satu barang ini, Anda menyatakan bahwa Anda berusia 18 atau lebih. Penyalahgunaan alkohol berbahaya bagi kesehatan Anda.',
    'Ref: default': 'Bawaan',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} diperoleh jika Anda memesan produk ini',
    'Ref: Loyalty Card Description':
      '{stamp_form} akan ditambahkan ke loyalitas berikut {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program Perangko Loyalitas memungkinkan Anda mengumpulkan perangko untuk hadiah.',
    'Ref: Loyalty Modal Description':
      'Saat Anda membeli <b> {product_name}, 1 cap </b> akan ditambahkan ke <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'Dan',
    'Ref: Stamp': 'Stempel',
    'Ref: Stamps': 'Perangko',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Program',
    'Ref: Loyalty Stamps': 'Perangko loyalitas',
    'Ref: See Loyalty Stamps': 'Lihat perangko loyalitas',
    Favorite: 'Favorit',
    'Ref: In favorites': 'Produk sudah ada dalam daftar favorit. Klik untuk menghapusnya.',
    'Ref: Not in favorites': 'Produk belum ada dalam daftar favorit. Klik untuk menambahkannya.',
    'Ref: Added to favorites': 'Produk telah ditambahkan ke daftar favorit.',
    'Ref: Removed from favorites': 'Produk telah dihapus dari daftar favorit.',
    'Ref: From': 'Dari',
  },
  OrderHistory: { 'Ref: Orders': 'Pesanan', 'Ref: Page Title': 'pesananku' },
  MenuTile: { 'Ref: Scan&Go': 'Pindai & Pergi', 'Ref: View only': 'Lihat saja' },
  ProductTile: {
    'Ref: Allergens': 'Alergen',
    'Ref: Presence of allergen': 'Kehadiran alergen',
    'Ref: Scan': 'Pindai',
    'Ref: From': 'Dari',
    'Ref: Already in product favorite list. Click to remove it.':
      'Sudah dalam daftar favorit produk. Klik untuk menghapusnya.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Saat ini tidak ditambahkan ke daftar favorit produk. Klik untuk menambahkannya.',
    'Ref: Free': 'Bebas',
  },
  MenusWidget: {
    'Ref: no menu': 'Tidak ada menu untuk saat ini',
    'Ref: Modal title': 'Menu untuk Hari Ini',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Ambil',
    'Ref: Eat-in': 'Makan-dalam',
    'Ref: Scan&Go': 'Pindai & Pergi',
    'Ref: Delivery': 'Pengiriman',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'Bagaimana {facility_name} layanan?',
    'Ref: How do you rate the service': 'Bagaimana Anda menilai layanan?',
  },
};
export default labels;
