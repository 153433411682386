const labels = {
  StaysList: {
    'Ref: Page title': 'Zůstává',
    'Ref: Past': 'Minulost',
    'Current and future stays': 'Současné a budoucí pobyty',
    'filter label: period': 'Doba',
    'Ref: Search': 'Vyhledávání',
  },
  StayDetails: {
    'Ref: Page title': 'Podrobnosti o pobytu',
    'Arrival date': 'Den příjezdu',
    'Departure date': 'Datum odletu',
    'Room number': 'Pokoj, místnost',
    'Reservation number': 'Číslo rezervace',
    CheckIn: 'Zkontrolujte, zda',
    CheckOut: 'Překontrolovat',
    CheckIn_1: 'Zkontrolujte dnes do {lodgeName}, přijíždí dnes? (Rezervační číslo {resNumber})',
    CheckedIn_1: 'Jste zkontrolován do {lodgeName}, místnosti {roomNumber}.',
    CheckOut_1:
      'Podívejte se na {lodgeName} Rezervační číslo {resNumber} teď? Klíč pokoj bude deaktivován.',
    CheckedOut_1: '{lodgeName} Rezervační číslo {resNumber} se zkontroloval.',
  },
  StaysSuccess: {
    'Ref: Title': 'Děkujeme za pobyt!',
    'Ref: Body': 'Vaše pokladna byla úspěšně zpracována.',
    'Ref: Go to home': 'Jít domů',
    'Ref: Provide a feedback': 'Poskytněte zpětnou vazbu',
  },
};
export default labels;
