import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ToastContainer } from 'react-toastify';
import configureMockStore from 'redux-mock-store';
import thunk from 'redux-thunk';

import { AlertPortal } from '../../components/molecules/Alert';
import { defaultState as coreDefaultState } from '../../modules/Core/reducers/coreReducer';
import { defaultState as sharedDefaultState } from '../../reducers/sharedReducer';

const middlewares = [thunk];
const mockStore = configureMockStore(middlewares);

const initialState = {
  Shared: sharedDefaultState,
  Core: coreDefaultState,
  api: {},
};

export function addProviders(element: any, opts?: any) {
  const { state = {}, history } = opts || {
    state: {},
    history: createBrowserHistory(),
  };

  return (
    <Provider store={mockStore({ ...initialState, ...state })}>
      <ToastContainer />
      <Router history={history || createBrowserHistory()}>{element}</Router>
      <AlertPortal />
    </Provider>
  );
}
