import { dspApiPath, apiPath } from '../../config';
import { APP_NAME } from '../../constants';
import { getAppSpecificData } from '../../helpers/misc';
import { serviceTypes, modulesConfig } from '../config';
import { pagePaths as menusPagePaths } from '../Menus/config';
import { pagePaths as menuPagePaths } from '../Order/config';
import { pagePaths as orderPagePaths } from '../Order/config';

import { FacilitiesConfigType } from './types/types';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

export const moduleId = 'Facilities';
export const cacheVersion = '1.1';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  FacilityDetails: modulePath + '/:id/details',
};

export const redirectFromFacilities = (facilityId: string) => {
  const path = {
    [APP_NAME.MYVILLAGE]: menusPagePaths.ProductsList.replace(':facilityId', facilityId),
    default: orderPagePaths.Module,
  };
  return getAppSpecificData(path);
};

export const redirectToMenu = (facilityId: string) => {
  const path = {
    [APP_NAME.MYVILLAGE]: menusPagePaths.ProductsList.replace(':facilityId', facilityId),
    default: menuPagePaths.MenuModule,
  };
  return getAppSpecificData(path);
};

export const getApiEndpoints = () => {
  const configurations = {
    [APP_NAME.MYVILLAGE]: {
      api: baseApi,
      baseUrl: apiPath,
      getAllFacilities: '/v2/facility/list',
      getOneFacility: '/v2/facility',
      getFacilityParam: (id: string) => ({ id }),
    },
    default: {
      api: dspApi,
      baseUrl: dspApiPath,
      getAllFacilities: '/v1/facilities',
      getOneFacility: '/v1/facilities/:id',
      getFacilityParam: (_id: string) => undefined,
    },
  };

  return getAppSpecificData<FacilitiesConfigType>(configurations);
};
