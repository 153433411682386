const labels = {
  All: {
    Feedback: 'Feedback',
    'Ref: Switch site component intro sentence':
      'Your feedback will be linked to the site {site name}.',
    Category: 'Category',
    Subject: 'Subject',
    Comment: 'Comment',
    'Date of your experience': 'Date of your experience *',
    'Ref: Hint': 'Do you have an issue or job that needs fixing?',
    'Ref: Log Request': 'Log a request',
    'Ref: Describe issue': 'Please tell us about your experience',
    Date: 'Date',
    'Ref: Share your opinion header': 'Share your opinion about your experience at work',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurant / Café',
    'Desk / workstation area': 'Desk / Workstation area',
    'Meeting room facilities': 'Meeting room facilities',
    'Coffee/tea facilities (pantry)': 'Coffee/tea facilities (pantry)',
    'Restrooms / washrooms': 'Restrooms / Washrooms',
    'Reception / Security': 'Reception / Security',
    'Conference center': 'Conference center',
    'Fitness center': 'Fitness center',
    Mailroom: 'Mailroom',
    'Bicycle room': 'Bicycle room',
    'Shuttle services': 'Shuttle services',
    Lockers: 'Lockers',
    Helpdesk: 'Helpdesk',
  },
  Create: {
    'Tell us!': 'Tell us!',
    'Ref: All Ears': 'We are all ears!',
    'Ref: Provide Description': 'Please provide a description',
    'Ref: Fill-in-the-blank sentence': 'I want to provide a {0} in regards to {1}',
    'Ref: Link text to service request module': 'A request or an issue? Log it here!',
    'Complaint type': 'Complaint {emoji}',
    'Feedback type': 'Feedback {emoji}',
    'Compliment type': 'Compliment {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'An error occured and your feedback was not submitted. If this error persists, please contact your helpdesk.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Your feedback was submitted. Our workplace support team will read it carefully. If appropriate, we will come back to you.',
    'New feedback': 'New feedback',
  },
  CreateFlow: {
    Description: 'Description',
    'Ref: Provide Description': 'Please provide a date and description',
    'Ref: I would like to provide a': 'I would like to provide a',
    'Ref: in regards to': 'in regards to',
    'Ref: &': '&',
    'Ref: at': 'at',
    'Ref: on the': 'on the',
  },
};
export default labels;
