import { useController } from 'react-hook-form';

import RadioGroup from './RadioGroup';
import { ControlledRadioGroupProps, RadioGroupWrapperProps } from './RadioGroup.types';

const RadioGroupWrapper = ({ control, rules = {}, ...props }: RadioGroupWrapperProps) =>
  control ? (
    <ControlledRadioGroup control={control} rules={rules} {...props} />
  ) : (
    <RadioGroup {...props} />
  );

const ControlledRadioGroup = ({ control, rules, ...props }: ControlledRadioGroupProps) => {
  const {
    field: { value, onChange },
  } = useController({ control, name: props.name, rules, defaultValue: props.value });

  return <RadioGroup {...props} value={value} onClick={onChange} />;
};

export default RadioGroupWrapper;
