import { useHistory } from 'react-router-dom';

import useContactsTranslation from '../../hooks/useContactsTranslation';

import NoticePage from '@/components/templates/NoticePage/NoticePage';
import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const FormSubmissionSuccess = () => {
  const history = useHistory();
  const { label } = useContactsTranslation(__filename);

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('thank you')}
      content={label('Ref: submitted success')}
      actions={[
        {
          label: label('to home (as in navigate to the homepage)', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
      ]}
    />
  );
};

export default FormSubmissionSuccess;
