const labels = {
  SiteDetails: {
    'Select site': 'Vyberte tento web',
    'Ref: Page title': 'Podrobnosti o webu',
    'Ref: Skip map': 'Přeskočit na hlavní obsah',
    'Ref: Unavailable site': 'Náhled webu nejistý pro vašeho uživatele',
    'Ref: Sorry, no description yet': 'Omlouvám se, zatím nemáme popis.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Souřadnice webu nebyly nalezeny',
    'Ref: Page title': 'Weby',
    'Recently visited': 'Nedávno navštívil',
    'All other sites': 'Všechny ostatní stránky',
    'Sites in your country': 'Weby ve vaší zemi',
    'Search results': 'Výsledky vyhledávání',
    'Select site': 'Vyberte stránku',
    'Ref: Select site with QR Scanner': 'Vyberte web se skenerem QR',
    'Ref: Select site with code': 'Vyberte web s kódem',
    'Ref: Validate': 'Ověřit',
    'Ref: Enter site code': 'Zadejte kód webu',
    'Ref: Scanner error header': 'Web nebyl nalezen',
    'Ref: Generic error': 'Něco se pokazilo',
    'Ref: Change site': 'Změnit web',
    'Ref: Change site description': 'V košíku jsou položky! Změna webu povede k vyčištění vozíku.',
    'Ref: Cancel': 'zrušení',
    'Ref: Not found': 'Tento QR kód nebyl rozpoznán',
    'Ref: Unauthorized error': 'Neoprávněný požadavek z důvodu úrovně omezení webu',
    'Ref: Forbidden':
      'Je nám líto, nemáte správná oprávnění k přístupu k tomuto webu. Chcete -li zajistit přístup, kontaktujte prosím Service Center Pilbara na 1800 992 777',
    "Ref: We didn't find the site.": 'Web jsme nenašli.',
    'Ref: We recommend some solutions:': 'Doporučujeme některá řešení:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Zkuste přidat svůj web se skenerem QR nebo pomocí tlačítek níže',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Odhlaste se a přihlaste se zpět výběrem správné země',
    'Ref: The currently selected country is:': 'Aktuálně vybraná země je:',
  },
  SwitchSite: { 'Not on {site name}?': 'Ne na {site name}?' },
  SiteTile: { Details: 'Podrobnosti', Select: 'Vybrat' },
};
export default labels;
