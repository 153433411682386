const labels = {
  ContactForm: {
    'Ref: Page title': 'Kontaktujte nás',
    'Ref: Category field value': 'Kontaktujte nás',
  },
  ContactList: {
    'Ref: Page title': 'Kontaktujte nás',
    'call (action to place a call)': 'volání',
    'write (action to write a message)': 'napsat',
    'write (action to write an e-mail)': 'e-mailem',
    'Ref: Hint message':
      'Uvědomte si, že některé stránky nemusí mít příjem. Pokud tomu tak je, najděte prosím jeden z pevných telefonů dostupných na webu.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Došlo k chybě a zpráva nebyla odeslána. Pokud tato chyba přetrvává, obraťte se prosím na podporu.',
  },
};
export default labels;
