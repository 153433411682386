const labels = {
  All: {
    Feedback: 'Komentarze',
    'Ref: Switch site component intro sentence':
      'Twoja opinia zostanie połączona z lokalizacją {site name}.',
    Category: 'Kategoria',
    Subject: 'Temat',
    Comment: 'Komentarz',
    'Date of your experience': 'Data twojego doświadczenia *',
    'Ref: Hint': 'Czy masz problem lub pracę, która wymaga naprawy?',
    'Ref: Log Request': 'Zarejestruj żądanie',
    'Ref: Describe issue': 'Opowiedz nam o swoim doświadczeniu',
    Date: 'Data',
    'Ref: Share your opinion header':
      'Podziel się swoją opinią na temat swojego doświadczenia w pracy',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restauracja / kawiarnia',
    'Desk / workstation area': 'Obszar biurka / stacji roboczej',
    'Meeting room facilities': 'Obiekty sali konferencyjnej',
    'Coffee/tea facilities (pantry)': 'Obiekty kawy/herbaty (spiżarnia)',
    'Restrooms / washrooms': 'Toalety / toalety',
    'Reception / Security': 'Recepcja / bezpieczeństwo',
    'Conference center': 'Centrum konferencyjne',
    'Fitness center': 'Centrum fitness',
    Mailroom: 'Poczta',
    'Bicycle room': 'Pokój rowerowy',
    'Shuttle services': 'Usługi wahadłowe',
    Lockers: 'Szafki',
    Helpdesk: 'Pomocy technicznej',
  },
  Create: {
    'Tell us!': 'Powiedz nam!',
    'Ref: All Ears': 'Zamieniamy się w słuch!',
    'Ref: Provide Description': 'Proszę podać opis',
    'Ref: Fill-in-the-blank sentence': 'Chcę dostarczyć {0} w odniesieniu do {1}',
    'Ref: Link text to service request module': 'Żądanie lub problem? Zaloguj się tutaj!',
    'Complaint type': 'Skarga {emoji}',
    'Feedback type': 'Informacje zwrotne {emoji}',
    'Compliment type': 'Komplement {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Wystąpił błąd i Twoja opinia nie została przesłana. Jeśli ten błąd nadal występuje, skontaktuj się z działem pomocy technicznej.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Twoja opinia została przesłana. Nasz zespół ds. wsparcia miejsca pracy uważnie ją przeczyta. W razie potrzeby skontaktujemy się z Tobą.',
    'New feedback': 'Nowa opinia',
  },
  CreateFlow: {
    Description: 'Opis',
    'Ref: Provide Description': 'Proszę podać datę i opis',
    'Ref: I would like to provide a': 'Chciałbym dostarczyć',
    'Ref: in regards to': 'w odniesieniu do',
    'Ref: &': 'I',
    'Ref: at': 'w',
    'Ref: on the': 'na',
  },
};
export default labels;
