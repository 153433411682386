import classNames from 'classnames';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';

import { LoaderProps } from './Loader.types';

import styles from './Loader.module.css';

const Loader = ({ 'data-testid': dataTestId }: LoaderProps) => {
  const { label } = useCustomTranslation();
  return (
    <span data-testid={`${dataTestId}-loader`} className={classNames(styles.loader)}>
      {label('Loading...')}
    </span>
  );
};

export default Loader;
