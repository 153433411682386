import { useCallback } from 'react';

import useStateWithProps from './useStateWithProps';

const useToggle = (initialState = false) => {
  const [state, setState] = useStateWithProps(initialState);

  const toggle = useCallback(() => setState((prevState) => !prevState), [setState]);
  const toggleOff = useCallback(() => setState(false), [setState]);
  const toggleOn = useCallback(() => setState(true), [setState]);

  return { state, toggle, toggleOff, toggleOn };
};

export default useToggle;
