const labels = {
  Success: {
    'Ref: Body': 'アンケートへのご協力ありがとうございます',
    'Ref: Tabtitle': '調査が完了しました',
    'Take another survey': '別のアンケートをご覧ください',
  },
  Survey: {
    'Ref: Page title': '調査の詳細',
    'Please answer all mandatory questions as identify by an asterisk': '"*"は必須の質問です。',
    'Ref: Take another survey': '別のアンケートをご覧ください',
    'Ref: Go to Home': 'ホームに戻る',
  },
  SurveysList: {
    'Ref: Page title': 'アンケートへのご協力ありがとうございます',
    'Ref: Available until': 'まで利用可能',
    'Ref: You are currently viewing surveys for the site {site name}.':
      '現在{site name}のアンケートを表示しています',
  },
};
export default labels;
