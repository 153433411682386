// Helper function for working with module setupOptions

// TODO transform to hook once rid of all class components
// TODO Once setupOptions is moved to react Context, remove setupOptions prop and utilise context

import { ServiceDefinitionSetupOptions } from '../modules/defaultServices';
import { setupOptionReturnType } from '../types/common.types';

/**
 * @deprecated The method should not be used
 */
export const getCustomSetupOption = (
  option: string,
  setupOptions: ServiceDefinitionSetupOptions[]
): setupOptionReturnType => {
  const caseInSensitiveOption = option.toUpperCase();

  const defaultReturn = {
    isActive: false,
    value: undefined,
    option: undefined,
  };
  const setupOption = setupOptions?.find(
    (opt) => opt.name?.toUpperCase() === caseInSensitiveOption
  );

  if (setupOption) {
    return {
      isActive: !(setupOption.value === 'false' || setupOption.value === null),
      value: setupOption.value,
      option: setupOption,
    };
  }

  return defaultReturn;
};
