import classNames from 'classnames';
import { FC, useState } from 'react';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import ReactionButton from '../../atoms/ReactionButton';
import ReactionOptions from '../../molecules/ReactionOptions';

import { ReactionsProps, ReactionWrapperProps } from './Reactions.types';

import styles from './Reactions.module.css';

const Reactions: FC<ReactionWrapperProps> = ({
  children,
  reactions,
  className,
  handleReactionClick,
  tooltipId,
  alignRight,
  'data-testid': dataTestId = 'reactions',
}) => {
  const [showReactionsOptions, setShowReactionsOptions] = useState(false);

  return (
    <div
      className={classNames(styles.wrapper, className)}
      onMouseEnter={() => setShowReactionsOptions(true)}
      onMouseLeave={() => setShowReactionsOptions(false)}
      data-testid={dataTestId}
      tabIndex={-1}
      role="button"
      aria-expanded={showReactionsOptions}
      onKeyUp={(e) => {
        if (e.key === 'Escape') {
          setShowReactionsOptions(false);
        }
      }}
    >
      <div
        aria-hidden="true"
        role="button"
        className={classNames(styles.childrenContainer)}
        onClick={() => setShowReactionsOptions(true)}
      >
        {children}
      </div>
      <div
        className={classNames(styles.options, alignRight ? styles.right : styles.left)}
        aria-expanded={showReactionsOptions}
        style={{
          visibility: showReactionsOptions ? 'visible' : 'hidden',
          pointerEvents: !showReactionsOptions ? 'none' : undefined,
        }}
      >
        <ReactionOptions
          tooltipId={tooltipId}
          reactions={reactions}
          handleReactionClick={handleReactionClick}
        />
      </div>
    </div>
  );
};
Reactions.displayName = 'Reactions';

const DetailReactions: FC<ReactionsProps> = (props) => {
  const { label } = useCustomTranslation();
  const ariaLabel = props.userReaction
    ? `${props.userReaction} ${label('Ref: reaction. Change reaction')}`
    : `${label('Ref: No reaction. React')}`;

  return (
    <Reactions {...props}>
      <ReactionButton
        userReaction={props.userReaction}
        mainButtonValues={props.mainButtonValues}
        data-testid={`${props['data-testid']}-detail-reaction`}
        aria-label={ariaLabel}
        total={
          props.userReaction
            ? props.reactions.find((reaction) => reaction.type === props.userReaction)?.count
            : undefined
        }
      />
    </Reactions>
  );
};
DetailReactions.displayName = 'Reactions.Detail';

const ListReactions: FC<ReactionsProps> = (props) => {
  const { label } = useCustomTranslation();
  const ariaLabel = props.userReaction
    ? `${props.userReaction} ${label('Ref: reaction. Change reaction')}`
    : `${label('Ref: No reaction. React')}`;

  return (
    <Reactions {...props} className={classNames(props.className, styles.inList)}>
      <ReactionButton
        userReaction={props.userReaction}
        mainButtonValues={props.mainButtonValues}
        data-testid={`${props['data-testid']}-list-reaction`}
        aria-label={ariaLabel}
        hideLabel
      />
    </Reactions>
  );
};
ListReactions.displayName = 'Reactions.List';

export default Object.assign(Reactions, {
  Detail: DetailReactions,
  List: ListReactions,
});
