import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { POSITION } from '../../../../constants';
import { TitleBarIconProps, TitleBarTwoIconsLinkProps } from '../TitleBar.types';
import TitleBarIcon from '../TitleBarIcon';

import styles from './TitleBarTwoIconsLink.module.css';

const TitleBarTwoIconsLink: FC<TitleBarTwoIconsLinkProps> = ({
  textLink,
  textLinkKey,
  icons,
  linkTo,
  filled,
  'data-testid': dataTestId = 'titlebar-navigation-two-icons-link',
}: TitleBarTwoIconsLinkProps) => {
  return (
    <div className={styles.TitleBarTwoIconsLink} data-testid={dataTestId}>
      <Link to={linkTo}>
        <TitleBarIcon
          position={POSITION.LEFT}
          filled={filled}
          keyText={textLinkKey}
          icon={icons.find((i: TitleBarIconProps) => i.position === POSITION.LEFT)!.icon}
        />
        <span
          className={classNames(styles.text, styles.titleText)}
          data-cy={'title-bar-text-' + textLinkKey}
        >
          {textLink}
        </span>
        <TitleBarIcon
          position={POSITION.RIGHT}
          filled={filled}
          keyText={textLinkKey}
          icon={icons.find((i: TitleBarIconProps) => i.position === POSITION.RIGHT)!.icon}
        />
      </Link>
    </div>
  );
};

export default TitleBarTwoIconsLink;
