import { cleanup, screen } from '@testing-library/react';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import { SERVICE } from '../../../../config';
import Details from '../Details';

import renderComponent from '@/helpers/tests/renderComponent';
import { ServiceRequestProps } from '@/modules/ServiceRequest/types/types';

const mockStore = {
  Core: {
    user: { username: 'apptest@myway.com', mobile: '333333333' },
    notifications: {
      unreadNotificationCount: 0,
      items: [],
    },
    services: {
      list: [
        {
          name: SERVICE.SERVICE_REQUEST,
          setupOptions: [
            {
              name: 'enableAffectedPerson',
              value: 'true',
              code: 'enableAffectedPerson',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'enableCategories',
              value: 'true',
              code: 'enableCategories',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
        },
      ],
    },
    access: { shouldSelectSiteContext: false },
  },
  Shared: {
    language: {
      currentLanguageCode: 'en',
    },
  },
};

const mock: { data: ServiceRequestProps[]; isLoading: boolean } = {
  data: [],
  isLoading: false,
};

const locationData = {
  building: { id: '1', name: 'Accommodation Block A' },
  floor: { id: '1', name: 'Camp - A - External Area' },
  wing: { id: '1', name: 'Right wing' },
  room: { id: '1', name: '26.' },
  property: { id: '1', name: 'FMG Camp 145 Property' },
  site: { id: '1', name: 'FMG Camp 145' },
};

const mockDetail: { data: ServiceRequestProps; isLoading: boolean } = {
  data: {
    id: '5db3bec8-461d-ed11-b83d-000d3a25cd87',
    createdOn: '2023-11-12 12:00:00',
    modifiedOn: '2023-11-12 12:00:00',
    displayTitle: 'Test title',
    description: 'This is test description',
    currentStatus: 'In progress',
    title: 'AAA',
    status: '',
    raisedFor: {
      contactId: '699ae152-fa57-ec11-8f8f-000d3a49640f',
      firstName: 'John',
      lastName: 'Wick',
      value: '',
      label: '',
    },
    referenceNumber: 'SR-13388-Y0C5W3',
    building: locationData.building,
    floor: locationData.floor,
    wing: locationData.wing,
    room: locationData.room,
    property: locationData.property,
    site: locationData.site,
  },
  isLoading: false,
};

const mockDetailResponse = jest.fn();

jest.mock('../../../api/index', () => ({
  useGetServiceRequestsQuery: () => {
    return mock;
  },
  useGetServiceRequestQuery: () => mockDetailResponse(),
}));

describe('Details', () => {
  const env = global.process.env;

  describe('when is MyWay', () => {
    beforeAll(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
    });

    afterAll(() => cleanup());

    beforeEach(() => mockDetailResponse.mockReturnValue(mockDetail));

    describe('Detail fields are displayed', () => {
      let locationTitle: any, locationValue: any, button: any;

      renderComponent(Details, {}, mockStore);

      beforeEach(() => {
        locationTitle = screen.getByText('Location');
        locationValue = screen.getByText(
          `${locationData.building.name} / ${locationData.floor.name} / ${locationData.wing.name} / ${locationData.room.name} / ${locationData.property.name} / ${locationData.site.name}`
        );
        button = screen.getByTestId('button-action-primary');
      });

      it('should render Location list item data', async () => {
        expect(screen.getByText('Reference')).toBeTruthy();
        expect(screen.getByText('SR-13388-Y0C5W3')).toBeTruthy();
        expect(screen.getByText('Created On')).toBeTruthy();
        expect(screen.getByText('Phone number')).toBeTruthy();
        expect(screen.getByText('333333333')).toBeTruthy();
        expect(screen.getByText('Description')).toBeTruthy();
        expect(screen.getByText('This is test description')).toBeTruthy();
        expect(screen.queryByText('Target start date')).toBeFalsy();
        expect(screen.queryByText('Target completion date')).toBeFalsy();
        expect(locationTitle).toBeTruthy();
        expect(locationValue).toBeTruthy();
        expect(button).toBeTruthy();
      });
    });

    describe('When enableAffectedPerson is disabled - default state', () => {
      renderComponent(
        Details,
        {},
        {
          ...mockStore,
          Core: {
            ...mockStore.Core,
            services: {
              ...mockStore.Core.services,
              list: [
                {
                  name: SERVICE.SERVICE_REQUEST,
                  setupOptions: [
                    {
                      name: 'enableAffectedPerson',
                      value: 'false',
                      code: 'enableAffectedPerson',
                      isServiceType: true,
                      isServicePointer: false,
                    },
                    {
                      name: 'enableCategories',
                      value: 'true',
                      code: 'enableCategories',
                      isServiceType: true,
                      isServicePointer: false,
                    },
                  ],
                },
              ],
            },
          },
        }
      );

      it('should not display affected person', () => {
        expect(screen.queryByText('Person affected by this request')).toBeFalsy();
      });
    });

    describe('When enableAffectedPerson is enabled', () => {
      renderComponent(
        Details,
        {},
        {
          ...mockStore,
          Core: {
            ...mockStore.Core,
            services: {
              ...mockStore.Core.services,
              list: [
                {
                  name: SERVICE.SERVICE_REQUEST,
                  setupOptions: [
                    {
                      name: 'enableAffectedPerson',
                      value: 'true',
                      code: 'enableAffectedPerson',
                      isServiceType: true,
                      isServicePointer: false,
                    },
                    {
                      name: 'enableCategories',
                      value: 'true',
                      code: 'enableCategories',
                      isServiceType: true,
                      isServicePointer: false,
                    },
                  ],
                },
              ],
            },
          },
        }
      );

      beforeEach(() => {});

      it('should display affected person', () => {
        expect(screen.queryByText('Person affected by this request')).toBeTruthy();
      });
    });

    describe('When hidePhoneNumber setupOption is not set - default state', () => {
      renderComponent(Details, {}, mockStore);

      it('it should display phone number field', () => {
        expect(screen.queryByText('Phone number')).toBeTruthy();
      });
    });

    describe('When hidePhoneNumber setupOption is set', () => {
      renderComponent(
        Details,
        {},
        {
          ...mockStore,
          Core: {
            ...mockStore.Core,
            services: {
              ...mockStore.Core.services,
              list: [
                {
                  name: SERVICE.SERVICE_REQUEST,
                  setupOptions: [
                    {
                      name: 'enableAffectedPerson',
                      value: 'true',
                      code: 'enableAffectedPerson',
                      isServiceType: true,
                      isServicePointer: false,
                    },
                    {
                      name: 'enableCategories',
                      value: 'true',
                      code: 'enableCategories',
                      isServiceType: true,
                      isServicePointer: false,
                    },
                    {
                      name: 'hidePhoneNumber',
                      value: 'true',
                      code: 'hidePhoneNumber',
                      isServiceType: true,
                      isServicePointer: false,
                    },
                  ],
                },
              ],
            },
          },
        }
      );

      it('it should not display phone number field', () => {
        expect(screen.queryByText('Phone number')).toBeFalsy();
      });
    });

    describe('When targetStartDateDate is present', () => {
      beforeEach(() =>
        mockDetailResponse.mockReturnValue({
          ...mockDetail,
          data: {
            ...mockDetail.data,
            targetStartDateDate: '2024-04-25T20:37:24',
          },
        })
      );
      renderComponent(Details);

      it('it should display target start date', () => {
        expect(screen.queryByText('Target start date')).toBeTruthy();
      });
    });

    describe('When targetEndDateDate is present', () => {
      beforeEach(() =>
        mockDetailResponse.mockReturnValue({
          ...mockDetail,
          data: {
            ...mockDetail.data,
            targetEndDateDate: '2024-04-25T20:37:24',
          },
        })
      );
      renderComponent(Details);

      it('it should display target completion date', () => {
        expect(screen.queryByText('Target completion date')).toBeTruthy();
      });
    });
  });
});
