const labels = {
  All: {
    Region: '地區或國家',
    'Ref: Geography': '請選擇您的國家或地區',
    'Ref: Country': '國家',
    'Ref: Country tooltip': '該位置對應於現場國家。',
    'Ref: Please select your country': '請選擇你的國家',
    'First name': '名',
    'Last name': '姓',
    'Ref: Full name': '全名',
    'Mobile number': '手機號碼',
    'Mobile phone': '手機',
    Email: '電子郵件地址',
    Password: '密碼',
    logout: '登出',
    'Not a new user? Log in': '不是新用戶嗎？登錄',
    'do not register, login': '不是新用戶嗎？登錄',
    'Ref: I agree': '我同意',
    Register: '註冊',
    'Ref: My language': '我的語言',
    'Ref: Kiosk Fail Title': '哎呀！出了些問題！',
    'Ref: Kiosk Fail Description': '不幸的是，您的訂單沒有成功。請重試或要求工作人員為您提供幫助。',
    'Ref: Kiosk Fail Back': '返回上一頁',
    'Ref: Kiosk Fail Action': '清除會話並再次訂購',
    Validate: '確認',
    'Ref: error, incorrect credentials': '這些憑據與所選位置不匹配。',
    'Ref: Forgot password?': '忘記密碼？',
    'Ref: Hello': '你好{wave_emoji}，請登錄以繼續',
    'Ref: Form title': '請註冊以繼續',
    'Already have an account? Login': '已經有一個帳戶？登錄',
  },
  AccountPage: {
    'Ref: My Information': '我的信息',
    'Ref: Settings': '設置',
    'Ref: Email': '電子郵件',
    'Ref: Current site': '當前地點',
    'Ref: Choose site': '選擇一個地點',
    'Ref: Personal interests': '個人興趣',
    'Ref: Food preferences': '食物偏好',
    'Ref: Password': '密碼',
    'Ref: Page title': '帳戶',
    'Ref: Language': '語言',
    'Ref: Communication preferences': '溝通偏好',
    'Ref: Terms & conditions': '條款和條件',
    'Ref: Privacy policy': '隱私政策',
    'Ref: Delete my account': '刪除我的賬戶',
    'Ref: Confirm deletion': '您確定要刪除帳戶嗎？',
    'Ref: Request deletion': '您確定要請求刪除帳戶嗎？',
    'Ref: Deletion description': '您的請求將根據您的本地法規處理。',
    Placeholder: '请输入“删除”',
    'Ref: Delete description': '您的數據將被刪除。此動作是不可逆轉的。',
    delete: '刪除',
    'Ref: Terms of sale': '銷售條款',
    'Ref: Imprint': '版本說明',
    'Ref: Cookie Settings': 'cookie設置',
    'Ref: Support': '支持',
    'Ref: Register or login': '註冊或登錄',
    'Ref: Filtering Preferences': '過濾首選項',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': '您確認要刪除您的帳戶嗎？',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      '在輸入字段中寫入“ {word}”，並在下面的底部確認。此動作是不可逆轉的。',
    'Ref: Write "{word}"': '寫“ {word}”',
    'Ref: Delete my account': '刪除我的賬戶',
    'Ref: Words are not matching': '單詞不匹配',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': '選擇您的位置以繼續',
    'Ref: Choose location': '通過定位、掃描二維碼或查詢名稱來快速找到位置',
    'Ref: next step': '開始',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': '註冊',
    'Ref: identify your location': '請確定您的位置。',
    'Ref: option 1 - scan QR': '選項1：掃描提供的註冊QR碼',
    'Ref: option 2 - enter code': '選項2：手動輸入您的位置代碼',
    'Ref: button to open qr code scanner modal': '我有一個QR碼：讓我們掃描它',
    'Ref: button to open site code input modal': '選擇代碼的站點',
    'Ref: getting help with site identifier - support desk':
      '如果您不清楚如何繼續，請聯繫您的支持台。',
    'Ref: getting help with site identifier - support form': '您也可以向我們發送一個消息。',
    'Location code': '位置代碼',
    'Ref: location code field placeholder': '您的位置代碼在這裡',
    'Ref: Scanner header': '掃描二維碼',
    'Ref: site code input header': '用代碼添加站點',
    'Ref: not valid code': '此代碼在選定的位置無效',
  },
  AccountRegistrationForm: {
    'Ref: Page title': '註冊',
    'Ref: collected info': '確認你的請求',
    'Ref: additional info': '您的資料',
    'Confirm password': '*確認密碼',
    company: '你的雇主是？',
    location: '位置',
    username: '用戶名',
    register: '註冊',
    'Ref: password strength':
      '您的密碼必須至少8個字元長，至少包含一個數位，一個大寫字母，一個小寫字母和一個特殊字元。',
    'passwords do not match': '密碼不匹配。',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': '溝通偏好',
    'Ref: Things happen while you are away': '你不在時發生的事情',
    'Ref: Would you like to receive update about offers and promotions?':
      '您想收到有關報價和促銷的最新信息嗎？',
    'Ref: Would you like to hear about content we think you may like?':
      '您想了解我們認為您可能喜歡的內容嗎？',
    Yes: '是的',
    No: '不',
    apply: '應用',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': '帳戶詳細資料' },
  DeletionFailure: {
    'Ref: Body': '發生了錯誤，您的帳戶刪除請求尚未提交。如果此錯誤持續存在，請聯繫您的幫助台。',
    'Go to account': '轉到帳戶',
  },
  GenericFailurePage: {
    'Ref: Title': '出問題了',
    'Ref: Body': '抱歉，出了一些問題。如果此問題仍然存在，請聯繫您的現場支持。',
  },
  HomePage: {
    notifications: '通知',
    basket: '購物車',
    'Ref: Tabtitle': '首頁',
    'contact us': '聯繫我們',
    'Ref: You are logged in as a guest user': '您當前以訪客身份登錄',
    'Ref: Good morning': '早上好',
    'Ref: Good afternoon': '下午好',
    'Ref: Good evening before midnight': '晚上好',
    'Ref: Good evening after midnight': '晚上好',
    'Ref: Search': '搜尋',
    'Ref: products button aria': '在產品中找到{results_form}。',
    'Ref: menus button aria': '在菜單中找到{results_form}。',
    'Ref: facilities button aria': '在設施中找到{result_form}',
    'Ref: result': '結果',
    'Ref: results': '結果',
    'Ref: Menus': '菜單',
    'Ref: No results title': '哎呀！',
    'Ref: No results body': '沒有匹配您的搜索的結果',
    'Ref: Products': '產品',
    'Ref: Facilities': '設施',
    'Ref: See more results': '查看更多搜索結果',
    'Ref: Accessibility instructions': '要開始使用搜索，您必須單擊它或在將其集中在Enter時按ENTER。',
    'Ref: See more': '查看更多',
    'Ref: singular form of products': '產品',
    'Ref: singular form of menus': '菜單',
    'Ref: singular form of facilities': '設施',
    'Ref: Search results for': '為。。。。尋找結果',
  },
  LanguagePage: {
    apply: '應用',
    'My language': '我的語言',
    'Select in which language you would like to read our app': '選擇應用程序顯示的語言',
    'Ref: Page title': '語言',
  },
  LegalDocPage: {
    Acknowledge: '確認',
    'Ref: Legal changed notification title': '“ {legal_doc_type}”已修改',
    'Ref: Legal changed notification content': '在繼續使用該應用程序之前，請查看它們。',
  },
  LegalDocsPage: {
    'Ref: Page title': '條款及細則',
    'Read privacy policy': '閱讀隱私政策',
    'Ref: Acknowledge legal docs': '我接受這些條款和條件。',
    'Ref: Read and understood': '我已經閱讀和理解 ',
    'Ref: No legal docs available - notice title': '馬上就來',
    'Ref: No legal docs available - notice body': '對不起，似乎此申請目前無法在您的地理中使用。',
    'Ref: The privacy Policy': '隱私政策',
    'Ref: Legal changed notification title': '“ {legal_doc_type}”已修改',
    'Ref: Legal changed notification content': '在繼續使用該應用程序之前，請查看它們。',
  },
  LoginPage: {
    'Ref: Page title': '歡迎來到{app name} ',
    'Ref: Page subtitle': '請登錄以繼續',
    'Ref: Email field placeholder': '你的郵件',
    'Ref: Email field note': '我們永遠不會與任何人分享您的電子郵件。',
    'Ref: Password field title': '你的密碼',
    register: '註冊',
    'I forgot my password.': '忘記密碼？',
    'I have trouble logging in': '登錄時遇到問題？',
    'start registration': '開始註冊',
    'Ref: Register': '註冊',
    'Ref: Login': '登錄',
    'Ref: Connection issue?': '連接問題？',
    'Ref: Connection issue message':
      '如果您在註冊或登錄時遇到任何問題，請聯繫現場工作人員或服務台。',
  },
  WelcomePage: { 'Ref: Welcome to': '您好，歡迎來到{site_name}', 'Ref: Begin': '開始' },
  NotificationsList: {
    'Ref: No notifications': '您目前尚無通知',
    'Ref: This week': '本星期',
    'Ref: Earlier': '較早',
    'Ref: Page title': '通知',
    'Ref: See more': '查看更多',
    'Ref: Mark all read': '標記所有閱讀',
  },
  NotificationsWidget: { 'Ref: See all notifications': '查看所有通知' },
  ServicesMenuPage: { 'Ref: Page title': '服務', navigate: '導航' },
  DeletionSuccess: {
    'Ref: Title': '請求刪除您的帳戶',
    'Ref: Content': '我們很快就會處理。您可能隨時失去訪問權限。',
    'Log out': '登出',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': '我們會儘快給您回復',
    'Ref: Body': '很抱歉您遇到問題。 我們會盡快與您聯繫。',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': '登錄時遇到問題？',
    'Ref: Email field placeholder': '您的電子郵寄地址',
    'Ref: Mobile phone field placeholder': '電話號碼',
    Company: '公司',
    'Ref: Company field placeholder': '公司名',
    'Ref: Company field note': '如果您是第三方顧問，這是您目前正在工作的公司，而不是您的雇主。',
    Location: '城市或地點',
    'Ref: Location field placeholder': '你的工作場所的位置',
    'Ref: Location field note': '如果在居住區域之外，請隨時使用該區域通常被提及的名稱。',
    Comment: '評論',
    'Ref: Comment field placeholder': '發生了什麼?',
    'Ref: Who is your employer?': '您的雇主是誰？',
    'Ref: What village are you staying at?': '您住在哪個村莊？',
    'Ref: Comment field note': '任何可以幫助我們幫助您的信息！',
    'Ref: Feedback Content - geography': '地理',
    'Ref: Feedback Content - fullName': '姓名',
    'Ref: Feedback Content - email': '電子郵件',
    'Ref: Feedback Content - phone': '電話',
    'Ref: Feedback Content - company': '公司',
    'Ref: Feedback Content - city': '城市 /位置名稱',
    'Ref: Feedback Content - whatVillage': '村莊',
    'Ref: Feedback Content - employer': '雇主',
    'Ref: Feedback Content - comment': '什麼地方出了錯',
  },
  AccountStartPage: {
    'Ref: Header': '選擇您的位置開始',
    'Ref: Body': '通過激活本地化，掃描QR碼或按名稱搜索來快速找到位置',
    'Ref: LetsGo button': '我們走吧',
  },
  AccountOnboardingInformat: { 'Ref: Page title': '註冊' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': '選擇QR掃描儀的站點',
    'Ref: Page title': '選擇您的位置',
    'Ref: Select site with code': '通過代碼選擇地點',
    'Ref: Enter site code': '輸入站點代碼',
    'Ref: Scanner error header': '未找到地點',
    'Ref: Not found': '此代碼在選定的地點無效',
    'Ref: Generic error': '出問題了',
    'All other sites': '所有其它地點',
    'Sites in your country': '您所在國家的地點',
    'Ref: Unauthorized error': '由於站點限制級別而未經授權的請求',
    'Ref: Forbidden':
      '抱歉，您沒有正確的權限可以訪問此網站。要安排訪問，請致電1800 992 777與Pilbara服務中心聯繫',
    "Ref: We didn't find the site.": '我們沒有找到該地點。',
    'Ref: We recommend some solutions:': '我們建議：',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1.嘗試掃描場所的二維碼或點擊下面的按鈕關聯您的地點',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2.登錄並通過選擇正確的國家來登錄',
    'Ref: The currently selected country is:': '當前選擇的國家是：',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': '註冊',
    'Confirm password': '確認密碼',
    'Email is not valid': '電子郵件無效',
    'passwords do not match': '密碼不匹配。',
    'Ref: Password strength':
      '您的密碼必須至少8個字符長，至少包含一個數字，一個大寫，一個小寫和一個特殊字符。',
    'Sign up': '註冊',
    'Continue as guest': '以訪客身份繼續',
    'Already have an account? Login': '已經有一個帳戶？登錄',
    'Ref: Registration success title': '謝謝您的註冊',
    'Ref: Registration success content': '您的帳戶很快就會建立。您將收到一封確認建立的電子郵件。',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': '請登錄/註冊',
    'Ref: Password strength':
      '您的密碼必須至少8個字元長，至少包含一個數位，一個大寫字母，一個小寫字母和一個特殊字元。',
    Stamps: '郵票',
    'Order history': '訂單歷史',
    'to earn points': '賺取積分',
    'to see order history': '查看訂單歷史記錄',
    'to place an order': '下單',
    'Sign up': '註冊',
    Login: '登錄',
    'Email is not valid': '電子郵件無效',
    'passwords do not match': '密碼不匹配。',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': '謝謝你',
    'Ref: Body':
      '你幾乎已經完成！您很快將收到有關提供的地址的驗證電子郵件。打開此電子郵件，然後單擊鏈接以激活您的帳戶。',
    'Ref: Enter': '繼續',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': '發生了錯誤',
    'Ref: Generic Error Body': '請稍後再試或聯繫您的服務台。',
    'Ref: Missing IndexDb support':
      '抱歉，您無法以隱身模式或沒有IndexDB支持訪問該應用程序。請與另一個瀏覽器重試。',
    'Ref: Resource not found': '您嘗試訪問的內容不存在。',
    'Ref: Permission denied - SITE_HIDDEN': '您嘗試訪問的內容屬於非公開站點。',
    'Ref: Permission denied - CONTRACT_DIFFERENT': '您要訪問的內容未授權您的帳戶。',
    'Ref: TabTitle': '錯誤',
    'Ref: Site link invalid': '此鏈接無效，如果問題持續存在，請選擇另一個站點或聯繫幫助台。',
    'Ref: Access Denied': '拒絕訪問。使用者權限不足。',
  },
  DefaultErrorPage: {
    'Ref: Oops!': '哎呀！ {robot_emoji}',
    'Ref: Error title': '出了些問題！',
    'Ref: Error body': '如果問題持續存在，請嘗試刷新此頁面或聯繫幫助台。',
    'Ref: Error ID:': '錯誤ID：{errorID}',
    'Ref: Back to home': '返回主頁',
  },
  Child: { 'Ref: Route Not Found': '您要查找的服務在此地點不可用。請稍後再試或聯繫您的服務台。' },
  AccountValidation: {
    'Ref: Password changed message':
      '恭喜，您的新密碼已成功保存，您現在可以從您的帳戶訪問我們的所有服務。',
    Welcome: '歡迎',
    'Ref: Welcome message':
      '您現在已經成功地註冊了新的Bite應用程序，並可以繼續發現和享受我們所有的服務。',
  },
  PasswordChange: {
    'Change Password': '更改密碼',
    Welcome: '歡迎',
    'Ref: Password message': '您的密碼需要更改，因為它不夠安全',
  },
  AccountPasswordChange: {
    'Change your password here': '在這裡更改密碼',
    'Your old password': '您的舊密碼',
    'Type in your new password': '輸入新密碼',
    'Re-type in your new password': '重新輸入您的新密碼',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': '您的密碼已被更改' },
  AccountPasswordChangeFailure: {
    'Ref: Error message': '發生錯誤，因此您的更改沒有保存。請再試一次。',
  },
  PasswordForgotten: {
    'Ref: Page title': '重設密碼',
    'Ref: Provide email':
      '請在{app_name}上註冊時提供使用的電子郵件地址。電子郵件將發送給您以重置密碼。',
    'Ref: Something happened': '發生了一些事。請稍後再試。',
  },
  ResetEmailSent: {
    'Ref: New email sent': '我們發送了一封新電子郵件。請等待30秒再次重新安排。',
    'Go to login page': '轉到登錄頁面',
    'Resend email': '重發電子郵件',
    'Ref: Email sent title': '密碼重置電子郵件已發送給您',
    'Ref: TabTitle': '重設密碼',
    'Ref: Email sent msg':
      '重置鏈接已發送到您的地址{email_address}。請等待30秒，然後再嘗試重新發送。',
  },
  ResetPassword: { 'Enter your new password': '輸入新密碼' },
  ResetPasswordSuccess: {
    'Ref: Password changed': '你的密碼已成功更改',
    'Go to login page': '轉到登錄頁面',
  },
  ResetPasswordFailure: {
    'Password reset fail': '密碼重置失敗',
    'Ref: Error': '發生錯誤，請重試',
    'Reset your password': '請重置您的密碼',
    'Go to login page': '轉到登錄頁面',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': '隱私政策',
    'Ref: Privacy Policy Body':
      '通過訪問此平台，Sodexo SA和本地Sodexo實體將處理您的個人數據，以至少管理您的帳戶並提供通過平台提供的服務和優惠。如果您想要有關處理個人數據，將訪問誰的更多信息以及如何行使您對個人數據的權利，請在下面諮詢下面的隱私政策，並在您的帳戶中隨時可用。',
    'Ref: Continue': '繼續',
    'Ref: Read Privacy Policy': '閱讀隱私政策',
  },
  AppUpdatePage: { 'Ref: GoToStore': '轉到商店' },
  SharePage: { 'Ref: Select a site': '選擇一個地點' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.': '要查看隱私政策，請選擇您的國家。',
  },
};
export default labels;
