import { Capacitor } from '@capacitor/core';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import momentjs from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getWeekStartByLocale } from 'weekstart';

import { breakPointsInPixels } from '../../../../../config';
import { SETUP_OPTIONS } from '../../../../../constants';
import {
  GetNotificationsResponse,
  Notification as ApiNotification,
} from '../../../../Core/api/notifications/notificationsApi.types';
import { pagePaths } from '../../../config';
import { useCoreTranslation } from '../../../hooks/useCoreTranslation';
import { SharedState } from '../../../types/State.types';

import { BellIcon20 } from '@/assets/index';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import NotificationTile from '@/components/molecules/Notification/NotificationTile';
import Popover from '@/components/organisms/Popover';
import TitleBar from '@/components/organisms/TitleBar';
import { LinkProps } from '@/components/organisms/TitleBar/TitleBar.types';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import useToggle from '@/helpers/hooks/useToggle';
import useWindowSize from '@/helpers/hooks/useWindowSize';
import { SERVICE } from '@/modules/config';
import { useLazyGetNotificationsQuery } from '@/modules/Core/api/notifications/notificationsApi';

import styles from './NotificationsWidget.module.css';

const MAX_ITEMS = 3;

const NotificationsWidget = ({ hide }: LinkProps) => {
  const { width } = useWindowSize();
  const { label } = useCoreTranslation(__filename);

  const { state: isPopoverOpen, toggleOn: showPopover, toggleOff: hidePopover } = useToggle(false);
  const timeoutRef = useRef<TimeoutId | null>(null);
  const [notifications, setNotifications] = useState<GetNotificationsResponse>({
    unreadNotificationCount: 0,
    items: [],
    pageIndex: 0,
    pageSize: 0,
    total: 0,
  });
  const [getNotifications] = useLazyGetNotificationsQuery();
  const { unreadNotificationCount, items } = notifications ?? {
    unreadNotificationCount: 0,
    items: [],
  };

  const disableNotifications = useIsSetupOptionEnabled(
    SETUP_OPTIONS.MY_ACCOUNT.disableNotifications,
    SERVICE?.MY_ACCOUNT
  );

  useEffect(() => {
    async function refreshNotifications() {
      const res = await getNotifications({ pageIndex: 0 });
      if (res.data) setNotifications(res.data);
    }
    if (!disableNotifications) {
      refreshNotifications();
      // Refresh interval 5 minutes
      timeoutRef.current = setInterval(refreshNotifications, 5 * 60 * 1000);
    }

    return () => {
      if (timeoutRef.current && !disableNotifications) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [getNotifications, disableNotifications]);

  const languageCode = useSelector(
    (state: { Shared: SharedState }) => state.Shared.language.currentLanguageCode
  );

  const startWeekDay = getWeekStartByLocale(languageCode);
  momentjs.locale(languageCode);
  momentjs.updateLocale(languageCode, { week: { dow: startWeekDay } });
  const startOfWeek = momentjs().startOf('week');

  const history = useHistory();

  let highlightText = undefined;
  if (unreadNotificationCount > 99) {
    highlightText = '99+';
  } else if (unreadNotificationCount > 0) {
    highlightText = unreadNotificationCount.toString();
  }

  const notificationstoDisplay = useMemo(
    () =>
      items
        .filter((notif: ApiNotification) =>
          momentjs(notif.notificationDate).isSameOrAfter(startOfWeek, 'day')
        )
        .slice(0, MAX_ITEMS),
    [items, startOfWeek]
  );

  const handleNotificationHover = () => {
    if (!Capacitor.isNativePlatform() && notificationstoDisplay.length > 0) {
      showPopover();
    }
  };

  const goToNotificationsScreen = () => {
    history.push(pagePaths['Notifications']);
  };

  if (hide) {
    return <></>;
  }

  const displayPopover = isPopoverOpen && width >= breakPointsInPixels.L;

  return (
    <div onMouseEnter={handleNotificationHover} onMouseLeave={hidePopover}>
      <Popover
        isOpen={displayPopover}
        content={
          <div className={styles.notificationTilesWrapper}>
            <div className={styles.notificationItemsWrapper}>
              {notificationstoDisplay.map((notification: ApiNotification, index: number) => {
                return (
                  <div key={index} className={styles.notificationItemWrapper}>
                    <NotificationTile data-testid="notification-widget-tile" {...notification} />
                  </div>
                );
              })}
            </div>
            <div className={styles.popoverFooter}>
              <Button
                contentCenterAlign
                look={BUTTON_LOOK.SECONDARY}
                className={styles.seeAllNotifButton}
                onClick={goToNotificationsScreen}
                data-testid="see-all-notifications-button"
              >
                {label('Ref: See all notifications')}
              </Button>
            </div>
          </div>
        }
        onClickOutside={hidePopover}
      >
        <div>
          <TitleBar.Widget
            label={label}
            labelKey="notifications"
            Icon={BellIcon20}
            linkTo={pagePaths['Notifications']}
            highlightText={highlightText}
            iconOnTheLeft
          />
        </div>
      </Popover>
    </div>
  );
};

export default NotificationsWidget;
