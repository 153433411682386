const labels = {
  SiteDetails: {
    'Select site': 'Välj den här webbplatsen',
    'Ref: Page title': 'Webbplatsinformation',
    'Ref: Skip map': 'Hoppa till huvudinnehållet',
    'Ref: Unavailable site': 'Webbplatsförhandsgranskning är oundviklig för din användare',
    'Ref: Sorry, no description yet': 'Tyvärr har vi inte beskrivning än.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Koordinater hittades inte',
    'Ref: Page title': 'Plats',
    'Recently visited': 'Nyligen besökt',
    'All other sites': 'Alla andra webbplatser',
    'Sites in your country': 'Webbplatser i ditt land',
    'Search results': 'Sökresultat',
    'Select site': 'Välj webbplats',
    'Ref: Select site with QR Scanner': 'Välj webbplats med QR -skanner',
    'Ref: Select site with code': 'Välj webbplats med kod',
    'Ref: Validate': 'Bekräfta',
    'Ref: Enter site code': 'Ange anläggningskod',
    'Ref: Scanner error header': 'Anläggning hittades inte',
    'Ref: Generic error': 'Något gick fel',
    'Ref: Change site': 'Byte webbplats',
    'Ref: Change site description':
      'Det finns föremål i vagnen! Att ändra webbplatsen kommer att resultera i att rensa vagnen.',
    'Ref: Cancel': 'Annullera',
    'Ref: Not found': 'Denna QR -kod känns inte igen',
    'Ref: Unauthorized error': 'Obehörig begäran på grund av anläggningsbehörighet',
    'Ref: Forbidden':
      'Tyvärr har du inte rätt behörighet att komma åt den här webbplatsen. För att ordna åtkomst, vänligen kontakta Pilbara Service Center på 1800 992 777',
    "Ref: We didn't find the site.": 'Vi hittade inte webbplatsen.',
    'Ref: We recommend some solutions:': 'Vi rekommenderar några lösningar:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Försök att lägga till din webbplats med QR -skanner eller med hjälp av knapparna nedan',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Logga ut och logga in igen genom att välja rätt land',
    'Ref: The currently selected country is:': 'Det för närvarande valda landet är:',
  },
  SwitchSite: { 'Not on {site name}?': 'Inte på {site name}?' },
  SiteTile: { Details: 'Information', Select: 'Välj' },
};
export default labels;
