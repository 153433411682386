const mockData = {
  getMenus: {
    //to be done
    ok: true,
    status: 200,
    json: () => {
      return new Promise((resolve) =>
        setTimeout(
          () =>
            resolve([
              {
                id: 'menu1',
                name: 'name1',
                date: '2024-07-23T00:00:00',
                facilityId: 'facility1',
                menuItems: [
                  {
                    menuItemId: 'id',
                    mealName: 'section',
                    name: 'name',
                    description: 'description',
                    category: 'category',
                    subCategory: null,
                    priceWithTax: 12,
                    genericCategory: 'Tea',
                  },
                ],
              },
              {
                id: 'menu2',
                name: 'name2',
                date: '2024-07-23T00:00:00',
                facilityId: 'facility2',
                menuItems: [
                  {
                    menuItemId: 'id',
                    mealName: 'section',
                    name: 'name',
                    description: 'description',
                    category: 'category',
                    subCategory: null,
                    priceWithTax: 12,
                    genericCategory: 'Tea',
                  },
                  {
                    menuItemId: 'id',
                    mealName: 'section',
                    name: 'name',
                    description: 'description',
                    category: 'category',
                    subCategory: null,
                    priceWithTax: 12,
                    genericCategory: 'Tea',
                  },
                ],
              },
            ]),
          3000
        )
      );
    },
  },
};

export default mockData;
