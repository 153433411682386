import classNames from 'classnames';
import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import { ArrowDown } from '../../../assets/icons';

import styles from './Select.module.css';

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDown className={classNames(styles.dropdownIcon, 'icon_primary')} />
    </components.DropdownIndicator>
  );
};
export default DropdownIndicator;
