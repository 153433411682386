import classNames from 'classnames';

import { SkeletonRowProps } from './Table.types';

import { Skeleton } from '@/components/atoms/Skeleton';

import styles from './Table.module.css';

const SKELETON_ROWS = 4;

const TableSkeletonRow: React.FC<SkeletonRowProps> = ({ columnsCount }) => {
  return (
    <>
      {Array.from({ length: SKELETON_ROWS }).map((_, index) => (
        <tr key={`${index}-rerender-skeleton-col`} className={classNames(styles.skeletonRow)}>
          {Array.from({ length: columnsCount }, (_, index) => (
            <td key={index} className={styles.skeletonCell}>
              <Skeleton key={index} className={styles.skeleton} width="60%" />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableSkeletonRow;
