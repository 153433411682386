const labels = {
  TimeTable: {
    'leave at': 'Jättää jhk',
    'arrive at': 'Saavuttaa',
    at: 'at',
    'Ref: list placeholder': 'Täytä lomake nähdäksesi tunteja',
    'Ref: Page title': 'Linja -autot',
    'Ref: Departure': 'Lähtö',
    'Ref: Arrivals': 'Saapumiset',
    'Ref: minutes': 'pöytäkirja',
    'Ref: no items placeholder': 'Valitettavasti tällä hetkellä ei ole saatavilla linja -autoja.',
  },
};
export default labels;
