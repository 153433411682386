import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Calendar2Icon, ClockIcon } from '../../../assets/icons';
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants';
import { checkIf24HourClockForLanguage, formatDate } from '../../../helpers/dateTime';
import useToggle from '../../../helpers/hooks/useToggle';
import TimePicker from '../../atoms/TimePicker';
import Modal from '../../organisms/Modal';
import Calendar from '../Calendar';

import { DateTimeProps, PICKER_TYPE } from './DateTime.types';

import { State } from '@/types/state.types';

import styles from './DateTime.module.css';

const DateTime = ({
  formattedValue,
  value: valueProp,
  type = PICKER_TYPE.DATE,
  placeholder,
  format,
  className,
  minDate,
  close,
  onChange,
  onDismiss,
  id,
  'data-testid': dataTestId = 'date-time',
  modalTitle,
  footer,
}: DateTimeProps) => {
  const initialValue = getValue(valueProp, type);
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);

  const [value, setValue] = useState<Date | string | undefined>(initialValue);
  const { state: modalOpen, toggleOn: openModal, toggleOff: closeModal } = useToggle(false);

  const localizedDate = formattedValue && formatDate(value as Date, currentLanguageCode);
  const inputFormat = format || (type === PICKER_TYPE.DATE ? DATE_FORMAT : TIME_FORMAT);
  const inputPlaceholder = placeholder || inputFormat;

  const isNativePlatform = Capacitor.isNativePlatform();

  useEffect(() => {
    const newValue = getValue(valueProp, type);
    if (value !== newValue) {
      setValue(newValue);
    }
  }, [valueProp, type, value]);

  useEffect(() => {
    if (close) {
      onCloseModal();
    }
  });

  const onCloseModal = useCallback(() => {
    closeModal();
    if (onDismiss) onDismiss(value);
  }, [closeModal, onDismiss, value]);

  const onChangeDate = useCallback(
    (date: Date | string) => {
      setValue(date);
      onCloseModal();

      if (onChange) onChange(date);
    },
    [onChange, onCloseModal]
  );

  const onChangeTime = debounce(
    useCallback(
      (time: string) => {
        setValue(time);
        if (onChange) onChange(time);
      },
      [onChange]
    ),
    500
  );

  const handleOpenModal = () => {
    if (type === PICKER_TYPE.DATE || (!isNativePlatform && type === PICKER_TYPE.TIME)) {
      openModal();
    }
  };

  const formattedDate = () => {
    if (type === PICKER_TYPE.DATE) {
      if (value && !formattedValue) {
        return moment(value).format(inputFormat);
      }
      if (value && formattedValue) {
        return localizedDate;
      } else return inputPlaceholder;
    }

    if (type === PICKER_TYPE.TIME) {
      const is24HourFormat = checkIf24HourClockForLanguage(currentLanguageCode);
      const displayTimeFormat = is24HourFormat ? 'HH:mm' : 'hh:mm A';
      return value
        ? moment(value as string, TIME_FORMAT).format(displayTimeFormat)
        : inputPlaceholder;
    }
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <button
        type="button"
        className={classNames(styles.button)}
        onClick={handleOpenModal}
        data-testid={dataTestId}
      >
        <span className={classNames(styles.label)}>{formattedDate()}</span>
        <div className={classNames(styles.icon)}>
          <>
            {type === PICKER_TYPE.DATE && <Calendar2Icon width={20} height={20} />}
            {type === PICKER_TYPE.TIME && <ClockIcon width={20} height={20} />}
          </>
        </div>
      </button>

      {isNativePlatform && type === PICKER_TYPE.TIME && (
        <input
          className={classNames(styles.invisibleInput)}
          type="time"
          data-testid="invisible-time-input"
          onChange={(e) => onChangeTime(e.target.value)}
        />
      )}
      <Modal
        isOpen={modalOpen}
        contentClassName={classNames(styles.modalContent)}
        onDismiss={onCloseModal}
        data-testid={`${dataTestId}-modal`}
        title={modalTitle}
        footer={footer}
      >
        {type === PICKER_TYPE.TIME && (
          <TimePicker
            id={`${id}-timepicker`}
            value={value as string}
            onChange={onChangeTime}
            currentLanguageCode={currentLanguageCode}
            {...(minDate && { minTime: moment(minDate).format(TIME_FORMAT) })}
          />
        )}
        {type === PICKER_TYPE.DATE && (
          <Calendar onChange={onChangeDate} minDate={minDate} value={value as Date} />
        )}
      </Modal>
    </div>
  );
};

const getValue = (value?: Date, type?: PICKER_TYPE): Date | string | undefined => {
  if (!value) return;

  switch (type) {
    case PICKER_TYPE.TIME:
      return moment(value).format(TIME_FORMAT);
    case PICKER_TYPE.DATE:
    default:
      return value;
  }
};

export default DateTime;
