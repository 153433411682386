const labels = {
  Success: {
    'Ref: Body':
      'Thanks for completing this survey, your time is valuable as these surveys help to improve our services.',
    'Ref: Tabtitle': 'Survey completed',
    'Take another survey': 'Take another survey',
  },
  Survey: {
    'Ref: Page title': 'Survey Details',
    'Please answer all mandatory questions as identify by an asterisk':
      'Survey/Please answer all mandatory questions as identify by an asterisk',
    'Ref: Take another survey': 'Take another survey',
    'Ref: Go to Home': 'Go to Home',
  },
  SurveysList: {
    'Ref: Page title': 'Surveys',
    'Ref: Available until': 'Available until',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'You are currently viewing surveys for the site {site name}.',
  },
};
export default labels;
