const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Audits',
    'Ref: status0': 'Ouvrir',
    'Ref: status1': 'Complété',
    'Ref: status2': 'Annulé',
    'Ref: connected': 'Connecté',
    'Ref: not connected': 'Pas connecté',
    'Ref: synchronise data': 'Synchroniser les données',
    'Ref: sync success': 'Données synchronisées avec succès',
    'Ref: Create new': 'Créer un nouveau',
    'Ref: Audit unsynchronised': 'Non synchronisé',
    'Ref: Audit synchronised': 'Synchronisé',
    'Ref: Unsynced error title': 'Une erreur est survenue',
    'Ref: Unsynced error description':
      "Il semble que ce soit la première fois que vous accédez au module Audits. Veuillez vous connecter au réseau et «synchroniser» pour commencer l'audit.",
    'Ref: Search all audits': 'Tous les audits',
    'Ref: Search active audits': 'Ouvrir',
    'Ref: Search completed audits': 'Complété',
    'Ref: sync error': 'La synchronisation a échoué',
    'Ref: sync error details': 'Réessayer à reconnecter',
    'Ref: no search audit results':
      "Malheureusement, il n'y a pas d'audits correspondant à votre recherche",
    'Ref: no audit results':
      "Vous n'avez pas d'audits disponibles, créez de nouveau pour commencer",
    'Ref: Search canceled audits': 'Annulé',
    'Ref: Audit cancelled successfully': 'Audit annulé avec succès',
    'Ref: Cancel audit invite': 'Annuler',
    'Ref: Error audit cancel': "L'audit n'a pas été annulé",
    'Ref: Cancel audit confirmation': "Êtes-vous sûr que vous souhaitez annuler l'audit?",
    'Ref: Confirm': 'Confirmer',
    'Ref: Cancel audit': "Annuler l'audit",
    'Ref: Change the location': "Changer l'emplacement",
  },
  AuditResponseForm: {
    'Ref: Page title': "Détails d'audit",
    'Ref: questions': 'des questions',
    'Ref: question': 'question',
    'Ref: Additional Comments': 'La description',
    'Ref: Not found title': 'Oops!',
    'Ref: Not found text': "L'audit auquel vous essayez d'accéder n'existe pas.",
    'Ref: Back to Audit list': "Retour à la liste d'audit",
    'Ref: Success title': 'Merci!',
    'Ref: Success text': "Vous avez terminé l'audit",
    'Ref: submit': 'Soumettre',
    'Ref: add attachment': 'Ajouter une pièce jointe',
    'Ref: Unsupported elements':
      "Désolé, il semble que cet audit contient du contenu que l'application ne prend pas encore en charge. Nous y travaillerons.",
    'Ref: form error': "Toutes les questions n'ont pas été pleinement répondues",
    'Ref: form complete': 'Terminé depuis',
    'Ref: attachment preview error': 'Vous devez être en ligne pour voir cette pièce jointe',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Créer un nouveau',
    'Ref: Location': 'Emplacement',
    'Ref: Audit Stream Type': 'Flux',
    'Ref: Audit': 'Audit',
    'Ref: Asset': 'Actif',
    'Ref: Asset Name': "Nom de l'actif",
    'Ref: Is joint audit?': 'Est un audit conjoint?',
    'Ref: Who was present?': 'Qui était présent?',
    'Ref: submit': 'Soumettre',
    'Ref: Select the Location': "Sélectionnez l'emplacement",
    'Ref: Select the Audit Stream Type': "Sélectionnez le type de flux d'audit",
    'Ref: Select the Audit': "Sélectionnez l'audit",
    'Ref: Select the Asset': "Sélectionnez l'actif",
    'Ref: form error': "Tous les champs requis n'ont pas été entièrement entrés",
    'Ref: Audit error': 'Audit ci-joint',
    'Ref: Joint Audit Participants error': "Participants à l'audit conjoint",
    'Ref: Audit created': 'Audit créé',
    'Ref: Audit field length error': 'La longueur ne doit pas dépasser 100 caractères',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': "Changer l'emplacement",
    'Ref: Location changed': "L'emplacement modifié",
    'Ref: Location': 'Emplacement',
    'Ref: Select the Location': "Sélectionnez l'emplacement",
    'Ref: Asset': 'Actif',
    'Ref: Select the Asset': "Sélectionnez l'actif",
    'Ref: Asset Name': "Nom de l'actif",
    'Ref: submit': 'Soumettre',
    'Ref: Audit field length error': 'La longueur ne doit pas dépasser 100 caractères',
  },
};
export default labels;
