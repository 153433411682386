import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import Details from './Detail';

import TooltipWrapper from '@/components/molecules/TooltipWrapper';

const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path={pagePaths.Details}
        children={
          <>
            <Details />
            <TooltipWrapper />
          </>
        }
      />
    </Switch>
  );
};

export default Routes;
