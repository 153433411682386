import classNames from 'classnames';
import React, { FC } from 'react';

import useKeyboardInfo from '../../../helpers/hooks/useKeyboardInfo';
import { BUTTON_LOOK } from '../../atoms/Button';
import { ButtonLook } from '../../atoms/Button/Button.types';
import Card from '../../molecules/Card/Card';

import { buttonLook } from './ActionsBar.helpers';
import { ActionsBarProps } from './ActionsBar.types';

import styles from './ActionsBar.module.css';

const ActionsBar: FC<ActionsBarProps> = ({
  children,
  className,
  inMainColumn,
  'data-testid': dataTestId = 'actions-bar',
}) => {
  const isKeyboardOpen = useKeyboardInfo();

  // TODO maybe? move this to separate component like ButtonGroup or something to be used from top level component
  let buttonIndex = 0;
  const newChildren = React.Children.map(children, (child: any) => {
    const displayName = child?.type?.displayName;

    // Automatically add look to Buttons if no look is provided
    if (displayName === 'Button') {
      buttonIndex++;
      const look: BUTTON_LOOK | ButtonLook = child?.props?.look || buttonLook(buttonIndex);

      return React.cloneElement(child, {
        look,
        className: classNames('d-block'),
        'data-testid': `button-action-${look}`,
      });
    }

    return child;
  });
  return (
    <Card
      className={classNames(styles.actionsBar, className, {
        [styles.static]: isKeyboardOpen,
        [styles.inMainColumn]: inMainColumn,
      })}
      data-testid={dataTestId}
    >
      {newChildren}
    </Card>
  );
};
ActionsBar.displayName = 'ActionsBar';

const ActionsBarStatic: FC<ActionsBarProps> = (props) => (
  <ActionsBar {...props} className={classNames(props.className, styles.static)} />
);
ActionsBarStatic.displayName = 'ActionsBar.Static';

export default Object.assign(ActionsBar, {
  Static: ActionsBarStatic,
});
