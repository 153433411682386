const withChildrenPages = [
  {
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    contentImageId: '5a94021c-92df-ed11-a7c6-6045bd8c58fb',
    id: 'ee8a16c8-ccb9-ee11-a81c-6045bd9d1928',
    name: 'Test children page 2',
  },
  {
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla convallis, justo et aliquet tincidunt',
    contentImageId: '5a94021c-92df-ed11-a7c6-6045bd8c58fb',
    id: 'ee8a16c8-ccb9-ee11-a82c-6045bd9d1928',
    name: 'Test children page',
  },
];

const mockResponse = {
  withoutChildrenPages: {
    ok: true,
    status: 200,
    json: () => Promise.resolve([]),
  },
  withChildrenPages: {
    ok: true,
    status: 200,
    json: () => Promise.resolve(withChildrenPages),
  },
  notFound: {
    ok: true,
    status: 400,
    json: () => Promise.resolve({}),
  },
};

export default mockResponse;
