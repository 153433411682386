const labels = {
  All: {
    Region: 'Región o país',
    'Ref: Geography': 'Seleccione su país o región',
    'Ref: Country': 'País',
    'Ref: Country tooltip': 'La ubicación corresponde al país del sitio.',
    'Ref: Please select your country': 'por favor seleccione su país',
    'First name': 'Nombre',
    'Last name': 'Apellido',
    'Ref: Full name': 'Nombre completo',
    'Mobile number': 'Número de teléfono móvil',
    'Mobile phone': 'Teléfono móvil',
    Email: 'Dirección de correo electrónico',
    Password: 'Contraseña',
    logout: 'Cerrar sesión',
    'Not a new user? Log in': '¿No es un nuevo usuario? Acceda',
    'do not register, login': '¿No es un nuevo usuario? Acceda',
    'Ref: I agree': 'Estoy de acuerdo',
    Register: 'Registro',
    'Ref: My language': 'Mi idioma',
    'Ref: Kiosk Fail Title': '¡Ups! ¡Algo salió mal!',
    'Ref: Kiosk Fail Description':
      'Desafortunadamente, su pedido no fue exitoso. Vuelva a intentarlo o solicite a un miembro del personal que lo ayude.',
    'Ref: Kiosk Fail Back': 'Volver a la página anterior',
    'Ref: Kiosk Fail Action': 'Borrar la sesión y ordenar de nuevo',
    Validate: 'Validar',
    'Ref: error, incorrect credentials': 'Estas credenciales no coinciden en la geografía elegida.',
    'Ref: Forgot password?': '¿Has olvidado tu contraseña?',
    'Ref: Hello': 'Hola {wave_emoji}, por favor inicie sesión para continuar',
    'Ref: Form title': 'Por favor regístrese para continuar',
    'Already have an account? Login': '¿Ya tiene una cuenta? Acceda',
  },
  AccountPage: {
    'Ref: My Information': 'Mi información',
    'Ref: Settings': 'Ajustes',
    'Ref: Email': 'Correo electrónico',
    'Ref: Current site': 'Centro actual',
    'Ref: Choose site': 'Elija un centro',
    'Ref: Personal interests': 'Intereses personales',
    'Ref: Food preferences': 'Preferencias de comida',
    'Ref: Password': 'Contraseña',
    'Ref: Page title': 'Cuenta',
    'Ref: Language': 'Idioma',
    'Ref: Communication preferences': 'Preferencias de comunicación',
    'Ref: Terms & conditions': 'Términos y condiciones',
    'Ref: Privacy policy': 'Política de privacidad',
    'Ref: Delete my account': 'Borrar mi cuenta',
    'Ref: Confirm deletion': '¿Estás seguro de que quieres eliminar tu cuenta?',
    'Ref: Request deletion': '¿Está seguro de que desea solicitar la eliminación de su cuenta?',
    'Ref: Deletion description': 'Su solicitud se manejará de acuerdo con su regulación local.',
    Placeholder: 'Escribe "Eliminar"',
    'Ref: Delete description': 'Sus datos serán eliminados. Esta acción es irreversible.',
    delete: 'Eliminar',
    'Ref: Terms of sale': 'Condiciones de venta',
    'Ref: Imprint': 'Imprimir',
    'Ref: Cookie Settings': 'Configuración de cookies',
    'Ref: Support': 'Apoyo',
    'Ref: Register or login': 'Registrarse o iniciar sesión',
    'Ref: Filtering Preferences': 'Preferencias de filtrado',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': '¿Está seguro de eliminar su cuenta?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Escriba "{word}" en el campo de entrada y confirme con la parte inferior a continuación. Esta acción es irreversible.',
    'Ref: Write "{word}"': 'Escribir "{word}"',
    'Ref: Delete my account': 'Borrar mi cuenta',
    'Ref: Words are not matching': 'Las palabras no coinciden',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Elija su ubicación para empezar',
    'Ref: Choose location':
      'Encuentre rápidamente una ubicación, escaneando un código QR o buscando por nombre de la ubicación',
    'Ref: next step': 'Vamos',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Regístrese',
    'Ref: identify your location': 'Por favor identifique su ubicación.',
    'Ref: option 1 - scan QR': 'Opción 1: Escanee el código QR de registro proporcionado',
    'Ref: option 2 - enter code': 'Opción 2: Ingrese manualmente su código de ubicación',
    'Ref: button to open qr code scanner modal': 'Tengo un QR: vamos a escanearlo',
    'Ref: button to open site code input modal': 'Seleccione el centro con el código',
    'Ref: getting help with site identifier - support desk':
      'Si no tiene claro cómo continuar, comuníquese con el mesa de ayuda',
    'Ref: getting help with site identifier - support form': 'También puede enviarnos una nota.',
    'Location code': 'Código de localización',
    'Ref: location code field placeholder': 'Su código de ubicación aquí',
    'Ref: Scanner header': 'Escanear un código QR',
    'Ref: site code input header': 'Agregar centro con el código',
    'Ref: not valid code': 'Este código no es válido en la geografía seleccionada.',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Registro',
    'Ref: collected info': 'Resumen',
    'Ref: additional info': 'Su información',
    'Confirm password': '*Confirmar contraseña',
    company: '¿Quién es su empleador?',
    location: 'Ubicación',
    username: 'nombre de usuario',
    register: 'Regístrese',
    'Ref: password strength':
      'Su contraseña debe tener al menos 8 caracteres, contener al menos un número, una mayúscula, una minúscula y un caracter especial.',
    'passwords do not match': 'Las contraseñas no coinciden.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Preferencias de comunicación',
    'Ref: Things happen while you are away': 'Las cosas suceden mientras está fuera',
    'Ref: Would you like to receive update about offers and promotions?':
      '¿Le gustaría recibir actualizaciones sobre ofertas y promociones?',
    'Ref: Would you like to hear about content we think you may like?':
      '¿Le gustaría recibir contenido que creemos que le puede gustar?',
    Yes: 'Sí',
    No: 'No',
    apply: 'aplicar',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Detalles de la cuenta' },
  DeletionFailure: {
    'Ref: Body':
      'Se ha producido un error y su solicitud para eliminar la cuenta no se envió. Si este error persiste, comuníquese con el mesa de ayuda',
    'Go to account': 'Ir a la cuenta',
  },
  GenericFailurePage: {
    'Ref: Title': 'Algo salió mal',
    'Ref: Body':
      'Perdón, algo salió mal. Si este problema persiste, comuníquese con su soporte en el centro.',
  },
  HomePage: {
    notifications: 'notificaciones',
    basket: 'Carrito',
    'Ref: Tabtitle': 'Inicio',
    'contact us': 'Contacte con nosotros',
    'Ref: You are logged in as a guest user': 'Está iniciado sesión como usuario invitado',
    'Ref: Good morning': 'Buenos días',
    'Ref: Good afternoon': 'Buenas tardes',
    'Ref: Good evening before midnight': 'Buenas noches',
    'Ref: Good evening after midnight': 'Buenas noches',
    'Ref: Search': 'Buscar',
    'Ref: products button aria': 'Encontrado {results_form} dentro de los productos',
    'Ref: menus button aria': 'Encontrado {results_form} dentro de los menús',
    'Ref: facilities button aria': 'Encontrado {results_form} dentro de las instalaciones',
    'Ref: result': 'resultado',
    'Ref: results': 'resultados',
    'Ref: Menus': 'Menús',
    'Ref: No results title': '¡Ups!',
    'Ref: No results body': 'No hay resultados que coincidan con su búsqueda',
    'Ref: Products': 'Productos',
    'Ref: Facilities': 'Instalaciones',
    'Ref: See more results': 'Ver más resultados de búsqueda',
    'Ref: Accessibility instructions':
      'Para comenzar a usar la búsqueda, debe hacer clic en ella o presionar ENTER mientras se enfoca.',
    'Ref: See more': 'Ver más',
    'Ref: singular form of products': 'producto',
    'Ref: singular form of menus': 'menú',
    'Ref: singular form of facilities': 'instalación',
    'Ref: Search results for': 'Resultados de búsqueda para',
  },
  LanguagePage: {
    apply: 'aplicar',
    'My language': 'Mi idioma',
    'Select in which language you would like to read our app':
      'Seleccione en qué idioma le gustaría leer nuestra aplicación',
    'Ref: Page title': 'Idioma',
  },
  LegalDocPage: {
    Acknowledge: 'Reconocer',
    'Ref: Legal changed notification title': 'El "{legal_doc_type}" se han modificado',
    'Ref: Legal changed notification content':
      'Por favor, revíselo antes de continuar usando la aplicación.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Términos y condiciones',
    'Read privacy policy': 'Lea la Política de Privacidad',
    'Ref: Acknowledge legal docs': 'Acepto estos términos y condiciones.',
    'Ref: Read and understood': 'He leído y entendido ',
    'Ref: No legal docs available - notice title': 'Próximamente',
    'Ref: No legal docs available - notice body':
      'Lo sentimos, parece que esta aplicación no está disponible actualmente en su geografía.',
    'Ref: The privacy Policy': 'la política de privacidad',
    'Ref: Legal changed notification title': 'El "{legal_doc_type}" se han modificado',
    'Ref: Legal changed notification content':
      'Por favor, revíselo antes de continuar usando la aplicación.',
  },
  LoginPage: {
    'Ref: Page title': 'Bienvenido a {app name}  ',
    'Ref: Page subtitle': 'Inicie sesión para continuar',
    'Ref: Email field placeholder': 'Su correo electrónico',
    'Ref: Email field note': 'Nunca compartiremos su correo electrónico con nadie.',
    'Ref: Password field title': 'Tu contraseña',
    register: 'Regístrese',
    'I forgot my password.': '¿Ha olvidado su contraseña?',
    'I have trouble logging in': '¿Tiene problemas para iniciar sesión?',
    'start registration': 'Iniciar registro',
    'Ref: Register': 'Registro',
    'Ref: Login': 'Acceder',
    'Ref: Connection issue?': '¿Problema de conexión?',
    'Ref: Connection issue message':
      'Si tiene algún problema para registrarse o iniciar sesión, comuníquese con el personal habitual en el sitio o el servicio de asistencia.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Hola y bienvenido a {site_name}', 'Ref: Begin': 'Comenzar' },
  NotificationsList: {
    'Ref: No notifications': 'No tienes notificaciones por el momento',
    'Ref: This week': 'Esta semana',
    'Ref: Earlier': 'Más temprano',
    'Ref: Page title': 'Notificaciones',
    'Ref: See more': 'Ver más',
    'Ref: Mark all read': 'marcar todo como leido',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Ver todas las notificaciones' },
  ServicesMenuPage: { 'Ref: Page title': 'Servicios', navigate: 'navegar' },
  DeletionSuccess: {
    'Ref: Title': 'Se solicitó la eliminación de su cuenta',
    'Ref: Content': 'Lo gestionaremos en breve. Puede perder su acceso en cualquier momento.',
    'Log out': 'Cerrar sesión',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Nos pondremos en contacto con usted',
    'Ref: Body':
      'Lamentamos que esté teniendo problemas. Nos pondremos en contacto con usted muy pronto.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': '¿Tiene problemas para iniciar sesión?',
    'Ref: Email field placeholder': 'Su correo electrónico',
    'Ref: Mobile phone field placeholder': 'Número de teléfono',
    Company: 'Empresa',
    'Ref: Company field placeholder': 'Nombre de empresa',
    'Ref: Company field note':
      'Si usted es consultor, esta es la compañía para la que trabaja actualmente, no su empleador.',
    Location: 'Ciudad o ubicación',
    'Ref: Location field placeholder': 'Ubicación de su lugar de trabajo',
    'Ref: Location field note':
      'Si está fuera de un área habitada, siéntase libre de usar el nombre por el cual se hace referencia al área.',
    Comment: 'Comentario',
    'Ref: Comment field placeholder': '¿Qué ocurrió?',
    'Ref: Who is your employer?': '¿Quién es tu empleador?',
    'Ref: What village are you staying at?': '¿En qué ciudad está?',
    'Ref: Comment field note': 'Cualquier información que pueda ayudarnos a ayudarle!',
    'Ref: Feedback Content - geography': 'Geografía',
    'Ref: Feedback Content - fullName': 'Nombre',
    'Ref: Feedback Content - email': 'Correo electrónico',
    'Ref: Feedback Content - phone': 'Teléfono',
    'Ref: Feedback Content - company': 'Empresa',
    'Ref: Feedback Content - city': 'Nombre de la ciudad / ubicación',
    'Ref: Feedback Content - whatVillage': 'Pueblo',
    'Ref: Feedback Content - employer': 'Empleador',
    'Ref: Feedback Content - comment': 'Qué salió mal',
  },
  AccountStartPage: {
    'Ref: Header': 'Elija su ubicación para empezar',
    'Ref: Body':
      'Encuentre rápidamente una ubicación, escaneando un código QR o buscando por nombre',
    'Ref: LetsGo button': 'Vamos',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Registrarse' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Seleccione el sitio con el escáner QR',
    'Ref: Page title': 'Seleccione su ubicación',
    'Ref: Select site with code': 'Seleccione Sitio con código',
    'Ref: Enter site code': 'Introduzca el código del centro',
    'Ref: Scanner error header': 'Centro no encontrado',
    'Ref: Not found': 'Este código no es válido en la geografía seleccionada.',
    'Ref: Generic error': 'Algo salió mal',
    'All other sites': 'Todos los demás centros',
    'Sites in your country': 'Sitios de tu país',
    'Ref: Unauthorized error': 'Solicitud no autorizada debido al nivel de restricción del centro',
    'Ref: Forbidden':
      'Lo siento, no tiene los permisos adecuados para acceder a este sitio. Para organizar el acceso, comuníquese con el Centro de Servicio de Pilbara al 1800 992 777',
    "Ref: We didn't find the site.": 'No encontramos el sitio.',
    'Ref: We recommend some solutions:': 'Recomendamos algunas soluciones:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Intente agregar su sitio con el escáner QR o con la ayuda de los botones a continuación',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Iniciar sesión y volver a iniciar sesión seleccionando el país correcto',
    'Ref: The currently selected country is:': 'El país seleccionado actualmente es:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Registro',
    'Confirm password': 'Confirmar contraseña',
    'Email is not valid': 'El correo no es válido',
    'passwords do not match': 'Las contraseñas no coinciden.',
    'Ref: Password strength':
      'Su contraseña debe tener al menos 8 caracteres, contener al menos un número, una mayúscula, una minúscula y un caracter especial.',
    'Sign up': 'Registrarse',
    'Continue as guest': 'Continuar como invitado',
    'Already have an account? Login': '¿Ya tiene una cuenta? Acceda',
    'Ref: Registration success title': 'Gracias por registrarse',
    'Ref: Registration success content':
      'Su cuenta se creará pronto. Recibirá un correo electrónico confirmando la creación.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Inicie sesión/Regístrese',
    'Ref: Password strength':
      'Su contraseña debe tener al menos 8 caracteres, contener al menos un número, una mayúscula, una minúscula y un caracter especial.',
    Stamps: 'Sellos',
    'Order history': 'Historial de pedidos',
    'to earn points': 'para ganar puntos',
    'to see order history': 'Para ver el historial de pedidos',
    'to place an order': 'encargar',
    'Sign up': 'Registrarse',
    Login: 'Acceder',
    'Email is not valid': 'El correo no es válido',
    'passwords do not match': 'Las contraseñas no coinciden.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Gracias',
    'Ref: Body':
      '¡Ya casi ha terminado! Pronto recibirá un correo electrónico de verificación en la dirección proporcionada. Abra este correo electrónico y haga clic en el enlace para activar su cuenta.',
    'Ref: Enter': 'Continuar',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Ha ocurrido un error',
    'Ref: Generic Error Body':
      'Vuelva a intentarlo más tarde o comuníquese con su servicio de asistencia.',
    'Ref: Missing IndexDb support':
      'Lo sentimos, no puede acceder a la aplicación en modo de incógnito o sin soporte IndexDB. Vuelva a intentar con otro navegador.',
    'Ref: Resource not found': 'El contenido al que está intentando acceder no existe.',
    'Ref: Permission denied - SITE_HIDDEN':
      'El contenido al que está intentando acceder pertenece a un sitio privado.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'El contenido al que está intentando acceder no está autorizado para su cuenta.',
    'Ref: TabTitle': 'Error',
    'Ref: Site link invalid':
      'Este enlace no es válido, seleccione otro sitio o comuníquese con el servicio de ayuda si el problema persiste.',
    'Ref: Access Denied': 'Acceso denegado. Permisos de usuario insuficientes.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': '¡Ups! {robot_emoji}',
    'Ref: Error title': '¡Algo salió mal!',
    'Ref: Error body':
      'Intente actualizar esta página o comunicarse con mesa de ayuda si el problema persiste.',
    'Ref: Error ID:': 'ID de error: {errorId}',
    'Ref: Back to home': 'De vuelta a casa',
  },
  Child: {
    'Ref: Route Not Found':
      'El servicio que está buscando no está disponible en este sitio. Vuelva a intentarlo más tarde o comuníquese con su servicio de asistencia.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Felicidades, su nueva contraseña se ha guardado con éxito, ahora puede acceder a todos nuestros servicios desde su cuenta.',
    Welcome: 'Bienvenido',
    'Ref: Welcome message':
      'Ahora se ha registrado con éxito en la nueva aplicación, y puede continuar descubriendo y disfrutando de todos nuestros servicios.',
  },
  PasswordChange: {
    'Change Password': 'Cambie la contraseña',
    Welcome: 'Bienvenido',
    'Ref: Password message': 'Su contraseña debe cambiarse porque no es lo suficientemente segura',
  },
  AccountPasswordChange: {
    'Change your password here': 'Cambie su contraseña aquí',
    'Your old password': 'Su antigua contraseña',
    'Type in your new password': 'Escriba su nueva contraseña',
    'Re-type in your new password': 'Repita su nueva contraseña',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'Su contraseña ha sido cambiada',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Se produjo un error, por lo tanto, su cambio no se guardó. Inténtelo de nuevo.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Restablecer la contraseña',
    'Ref: Provide email':
      'Proporcione la dirección de correo electrónico utilizada al registrarse en {app_name}. Se le enviará un correo electrónico para restablecer su contraseña.',
    'Ref: Something happened': 'Algo pasó. Por favor, inténtelo de nuevo más tarde.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Hemos enviado un nuevo correo electrónico. Espere 30 segundos para reenviar nuevamente.',
    'Go to login page': 'Ir a la página de inicio de sesión',
    'Resend email': 'Reenviar correo electrónico',
    'Ref: Email sent title':
      'Se le ha enviado un correo electrónico de restablecimiento de contraseña',
    'Ref: TabTitle': 'Restablecer la contraseña',
    'Ref: Email sent msg':
      'El enlace de reinicio se envió a su dirección {email_address}. Espere 30 segundos antes de intentar reenviar.',
  },
  ResetPassword: { 'Enter your new password': 'Introduzca su nueva contraseña' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'su contraseña ha sido cambiada con éxito',
    'Go to login page': 'Ir a la página de inicio de sesión',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'El restablecimiento de contraseña falló',
    'Ref: Error': 'Se produjo un error',
    'Reset your password': 'Restablecer su contraseña',
    'Go to login page': 'Ir a la página de inicio de sesión',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'política de privacidad',
    'Ref: Privacy Policy Body':
      'Al acceder a esta plataforma, Sodexo SA y la entidad Sodexo local procesarán sus datos personales para al menos administrar su cuenta y proporcionar los servicios y ofertas disponibles a través de la plataforma. Si desea más información sobre el procesamiento de sus datos personales, quién accederá a él y cómo ejercer los derechos que tiene en sus datos personales, consulte la Política de privacidad a continuación y esté disponible en cualquier momento de su cuenta.',
    'Ref: Continue': 'Continuar',
    'Ref: Read Privacy Policy': 'Leer Política de privacidad',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Ir a la tienda' },
  SharePage: { 'Ref: Select a site': 'Seleccione un sitio' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Para ver la política de privacidad, seleccione su país.',
  },
};
export default labels;
