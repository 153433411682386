import classNames from 'classnames';

import { StatusProps } from './Status.types';

import styles from './Status.module.css';

const Status = ({
  icon,
  status,
  inheritStyle,
  'data-testid': testId = 'display-status',
  'data-cy': testCy = 'display-status',
}: StatusProps) => {
  return (
    <div className={classNames(styles.wrapper, inheritStyle)} data-testid={testId} data-cy={testCy}>
      {icon}
      {status}
    </div>
  );
};

export default Status;
