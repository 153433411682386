import classNames from 'classnames';

import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

import { PrivacyPolicyIntroductionPageProps } from './PrivacyPolicyIntroductionPage.types';

import { TermsAndConditionsIllustration } from '@/assets/illustrations';

import styles from './../GenericErrorPage/DefaultErrorPage.module.css';
import ownStyles from './PrivacyPolicyIntroductionPage.module.css';

const PrivacyPolicyIntroductionPage = ({
  showLegalDocsPage,
  acknowledge,
}: PrivacyPolicyIntroductionPageProps) => {
  const { label } = useCoreTranslation(__filename);

  return (
    <SimpleFormPage hideAllWidgets withNavBar={false} tabTitle={label('Ref: Privacy Policy Title')}>
      <Container.Centered>
        <Column.Main className={classNames(styles.mainColumn, ownStyles.mainPrivacyPolicyColumn)}>
          <div className={classNames(styles.mainContainer, ownStyles.mainPrivacyPolicyContainer)}>
            <Title data-testid={'privacy-policy-page-title'} size={TITLE_SIZE.HEADLINES}>
              {label('Ref: Privacy Policy Title')}
            </Title>
            <Card
              data-testid={'privacy-policy-page-body'}
              className={ownStyles.mainPrivacyPolicyCard}
            >
              {label('Ref: Privacy Policy Body')}
            </Card>
            <ActionsBar className={styles.actionButton}>
              <Button
                data-testid="privacy-policy-ackowledge"
                look={BUTTON_LOOK.PRIMARY}
                onClick={() => acknowledge()}
              >
                {label('Ref: Continue')}
              </Button>
              <Button
                data-testid="privacy-policy-show-legal-docs"
                look={BUTTON_LOOK.TERTIARY}
                onClick={() => showLegalDocsPage()}
              >
                {label('Ref: Read Privacy Policy')}
              </Button>
            </ActionsBar>
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <TermsAndConditionsIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};
export default PrivacyPolicyIntroductionPage;
