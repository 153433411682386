const labels = {
  All: {
    dailyMenu: 'Tidak dikenal',
    anytime: 'Kapan saja',
    breakfast: 'Sarapan',
    morningBreak: 'Break pagi',
    lunch: 'Makan siang',
    afternoonBreak: 'Break sore',
    dinner: 'Makan malam',
    supper: 'Makan malam',
    nightMeal: 'Makan malam',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menu',
    'Ref: View details': 'Melihat rincian',
    'Ref: Facility details': 'Detail Fasilitas',
  },
  OpeningHours: { everyday: 'setiap hari', 'open all day': 'Buka Nonstop' },
  ProductsList: {
    'Ref: Page title': 'Menu',
    'Ref: Facility details': 'Detail Fasilitas',
    'Ref: Search products list': 'Cari barang',
    'Ref: filter label: date': 'Tanggal',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Momen',
    'Ref: filter label: category': 'Kategori',
    'Ref: filter label: dishes': 'Cucian piring',
    'Ref: filter label: highlight': 'Sorot atau sembunyikan filter dengan hidangan',
    All: 'Semua',
  },
  ProductDetails: { 'Ref: Page title': 'Rincian Produk' },
  Widget: {
    'Ref: Carousel title': 'Menu',
    'No info yet, come back later': 'Belum ada info, kembali lagi 😉',
    'Ref: menus': 'Menu',
    'Ref: see all': 'Lihat semua',
  },
};
export default labels;
