const labels = {
  All: {
    Feedback: 'Visszajelzés',
    'Ref: Switch site component intro sentence':
      'A visszajelzést a {site name} webhelyhez kapcsoljuk.',
    Category: 'Kategória',
    Subject: 'Tárgy',
    Comment: 'Megjegyzés',
    'Date of your experience': 'Tapasztalatainak dátuma *',
    'Ref: Hint': 'Van olyan probléma, amelynek szüksége van rá rögzítésre?',
    'Ref: Log Request': 'Kérelem feladása',
    'Ref: Describe issue': 'Kérjük, mondja el nekünk a tapasztalatait',
    Date: 'Dátum',
    'Ref: Share your opinion header': 'Ossza meg véleményét a munkahelyi tapasztalatairól',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Étterem / kávézó',
    'Desk / workstation area': 'Íróasztal / munkaállomás területe',
    'Meeting room facilities': 'Tárgyalóterem létesítményei',
    'Coffee/tea facilities (pantry)': 'Kávé/tea létesítmények (kamra)',
    'Restrooms / washrooms': 'Mellékhelyiségek / mosdók',
    'Reception / Security': 'Fogadás / biztonság',
    'Conference center': 'Konferencia Központ',
    'Fitness center': 'Edzőterem',
    Mailroom: 'Postaterem',
    'Bicycle room': 'Kerékpáros szoba',
    'Shuttle services': 'Transzferszolgáltatások',
    Lockers: 'Szekrények',
    Helpdesk: 'Ügyfélszolgálat',
  },
  Create: {
    'Tell us!': 'Mondd el nekünk!',
    'Ref: All Ears': 'Mindannyian fülünk vagyunk!',
    'Ref: Provide Description': 'Kérjük, adja meg a leírást',
    'Ref: Fill-in-the-blank sentence': 'Szeretnék {0} -ot biztosítani a {1} tekintetében',
    'Ref: Link text to service request module': 'Kérés vagy probléma? Jelentkezzen ide!',
    'Complaint type': 'Panasz {Emoji}',
    'Feedback type': 'Visszajelzés {Emoji}',
    'Compliment type': 'Bók {Emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Hiba történt, és a visszajelzést nem nyújtották be. Ha ez a hiba továbbra is fennáll, forduljon a ügyfélszolgálat.',
  },
  CreateSuccess: {
    'Ref: Body':
      'A visszajelzést benyújtották. Munkahelyi támogató csapatunk óvatosan olvassa el. Adott esetben visszatérünk hozzánk.',
    'New feedback': 'Új visszajelzés',
  },
  CreateFlow: {
    Description: 'Leírás',
    'Ref: Provide Description': 'Kérjük, adjon meg egy dátumot és leírást',
    'Ref: I would like to provide a': 'Szeretnék biztosítani a',
    'Ref: in regards to': 'annak tekintetében, hogy',
    'Ref: &': 'És',
    'Ref: at': 'nál nél',
    'Ref: on the': 'a',
  },
};
export default labels;
