const labels = {
  All: {
    'status: all': 'status: all',
    'status: open': 'status: open',
    'status: pending': 'status: pending',
    'status: closed': 'status: closed',
    Location: 'Location',
    Subject: 'Subject',
    Description: 'Description',
    'Ref: description field label': 'Ref: description field label',
    'Ref: Title': 'Ref: Title',
    phone_number: 'phone_number',
    request_category: 'request_category',
    Attachments: 'Attachments',
    'request service': 'request service',
    'New service request': 'New service request',
    'Go to home': 'Go to home',
    'Back to this service request': 'Back to this service request',
    'Back to service requests': 'Back to service requests',
    Feedback: 'Feedback',
    'Ref: Request category': 'Ref: Request category',
    Cleaning: 'Cleaning',
    'Laundry services': 'Laundry services',
    'Grounds maintenance': 'Grounds maintenance',
    'Soft services': 'Soft services',
    'Hard services': 'Hard services',
    'Pest control services': 'Pest control services',
    'Waste management': 'Waste management',
    'Asset Lifecycle Services': 'Asset Lifecycle Services',
    'There are no updates for this request': 'There are no updates for this request',
    'Transportation Services': 'Transportation Services',
    'Ref: you pronoun': 'Ref: you pronoun',
    'Ref: SR raised for': 'Ref: SR raised for',
    'Ref: Select a location': 'Ref: Select a location',
    'Ref: Your request': 'Ref: Your request',
    'Ref: Summary': 'Ref: Summary',
    'Ref: site': 'Ref: site',
  },
  Comment: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: please update us with details': 'Ref: please update us with details',
  },
  Confirm: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: next': 'Ref: next',
    summary: 'summary',
    'Ref: Save as my preferred location': 'Ref: Save as my preferred location',
  },
  Describe: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Required fields: header': 'Ref: Required fields: header',
    'Ref: Required fields': 'Ref: Required fields',
    'Ref: Required field: title': 'Ref: Required field: title',
    'Ref: Required field: description': 'Ref: Required field: description',
    'Ref: Required field: phone': 'Ref: Required field: phone',
    'Ref: Required field: category': 'Ref: Required field: category',
    'Ref: Invalid field: category': 'Ref: Invalid field: category',
    'Ref: Request template field label': 'Ref: Request template field label',
    'Ref: Request template field placeholder': 'Ref: Request template field placeholder',
    your_request: 'your_request',
    'Ref: Description message': 'Ref: Description message',
    phone_number: 'phone_number',
    'Ref: Affected person': 'Ref: Affected person',
    'Ref: No affected person results': 'Ref: No affected person results',
    'Ref: Notification title': 'Ref: Notification title',
    'Ref: Notification info': 'Ref: Notification info',
    'Ref: Add photos': 'Ref: Add photos',
    'Ref: Your location': 'Ref: Your location',
  },
  Details: {
    'Ref: Page title': 'Ref: Page title',
    Details: 'Details',
    Updates: 'Updates',
    'Update us': 'Update us',
    'Ref: timeline': 'Ref: timeline',
    'Ref: attachments': 'Ref: attachments',
    'Ref: location': 'Ref: location',
    'Ref: createdOn': 'Ref: createdOn',
    'Ref: reference': 'Ref: reference',
    'Feedback was submitted': 'Feedback was submitted',
    'Ref: sent': 'Ref: sent',
    'Ref: received': 'Ref: received',
    'Ref: inProgress': 'Ref: inProgress',
    'Ref: completed': 'Ref: completed',
    'Ref: conversation': 'Ref: conversation',
    'Ref: Priority': 'Ref: Priority',
    'Ref: Target start date': 'Ref: Target start date',
    'Ref: Target completion date': 'Ref: Target completion date',
    'Ref: Priority fields placeholder message': 'Ref: Priority fields placeholder message',
  },
  Failed: { 'Ref: Body': 'Ref: Body', 'Ref: Tabtitle': 'Ref: Tabtitle' },
  Home: { 'Ref: Page title': 'Ref: Page title' },
  RequestFeedback: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Feedback headline': 'Ref: Feedback headline',
    'Ref: Feedback title': 'Ref: Feedback title',
    'Ref: Question: general sentiment': 'Ref: Question: general sentiment',
    'Ref: Question: answer quality': 'Ref: Question: answer quality',
    'Ref: Question: staff interaction': 'Ref: Question: staff interaction',
    'Ref: Question: efficiency': 'Ref: Question: efficiency',
    'Ref: Question: open': 'Ref: Question: open',
    'Ref: Open question placeholder': 'Ref: Open question placeholder',
    no_comment: 'no_comment',
  },
  FeedbackSuccess: { 'Ref: Body': 'Ref: Body' },
  Locate: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Top bar title': 'Ref: Top bar title',
    'Ref: Select this location': 'Ref: Select this location',
    drill: 'drill',
    'Ref: Scanner button': 'Ref: Scanner button',
    'Ref: Scanner header': 'Ref: Scanner header',
    'Sorry, this location could not be found.': 'Sorry, this location could not be found.',
    'Close scanner': 'Close scanner',
    'Ref: Switch site component intro sentence': 'Ref: Switch site component intro sentence',
    'Ref: search location by keywords': 'Ref: search location by keywords',
    'Ref: Enter Location name': 'Ref: Enter Location name',
    'Ref: Enter manual location': 'Ref: Enter manual location',
    'Ref: Location name': 'Ref: Location name',
    'Ref: No results': 'Ref: No results',
    'Ref: Up one level': 'Ref: Up one level',
    'Ref: Navigate': 'Ref: Navigate',
    'Ref: Select': 'Ref: Select',
    'Ref: Selected Location': 'Ref: Selected Location',
    'Ref: Preferred Location': 'Ref: Preferred Location',
    'Ref: Building': 'Ref: Building',
    'Ref: Floor': 'Ref: Floor',
    'Ref: Aisle': 'Ref: Aisle',
    'Ref: Room': 'Ref: Room',
    'Ref: Notification title': 'Ref: Notification title',
    'Ref: Notification info': 'Ref: Notification info',
    'Ref: Scanner error header': 'Ref: Scanner error header',
    'Ref: Scan QR Code': 'Ref: Scan QR Code',
    'Ref: Error body': 'Ref: Error body',
    'Ref: Loading Locations Warning': 'Ref: Loading Locations Warning',
    'Ref: Search Result is limited to:': 'Ref: Search Result is limited to:',
    'Ref: Open the location search field': 'Ref: Open the location search field',
  },
  Success: {
    'Ref: Body': 'Ref: Body',
    'Ref: Tabtitle': 'Ref: Tabtitle',
    'Ref: Download app title': 'Ref: Download app title',
    'Ref: Download app subtitle': 'Ref: Download app subtitle',
    'Ref: Download app button': 'Ref: Download app button',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Ref: Tutorial: title',
    'Ref: Tutorial: tabname': 'Ref: Tutorial: tabname',
    'Ref: Tutorial: body': 'Ref: Tutorial: body',
    "What's new: title": "What's new: title",
    "What's new: body": "What's new: body",
  },
  Widget: {
    'Ref: Service request': 'Ref: Service request',
    'Ref: Placeholder body': 'Ref: Placeholder body',
    'Ref: New request button': 'Ref: New request button',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Ref: Notification title',
    'Ref: Notification info': 'Ref: Notification info',
    'Ref: Welcome': 'Ref: Welcome',
    'Ref: Create service request text': 'Ref: Create service request text',
    'Create a service request': 'Create a service request',
    'Ref: Share feedback': 'Ref: Share feedback',
    'Ref: Share text': 'Ref: Share text',
    'Go to home': 'Go to home',
    'Ref: Error body': 'Ref: Error body',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Ref: Error title',
    'Ref: Error body': 'Ref: Error body',
    'Go to home': 'Go to home',
  },
  ManualDescribe: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Required fields: header': 'Ref: Required fields: header',
    'Ref: Required fields': 'Ref: Required fields',
    'Ref: Required field: title': 'Ref: Required field: title',
    'Ref: Required field: description': 'Ref: Required field: description',
    'Ref: Required field: category': 'Ref: Required field: category',
    'Ref: Required field: buildingText': 'Ref: Required field: buildingText',
    'Ref: Invalid field: category': 'Ref: Invalid field: category',
    'Ref: Your request': 'Ref: Your request',
    'Ref: Description message': 'Ref: Description message',
    'Ref: Do you have a location QR code': 'Ref: Do you have a location QR code',
    'Ref: Scan QR Code': 'Ref: Scan QR Code',
    'Ref: Please select category': 'Ref: Please select category',
    'Ref: Please select site': 'Ref: Please select site',
    'Ref: building': 'Ref: building',
    'Ref: room': 'Ref: room',
    'Ref: Scan error': 'Ref: Scan error',
    'Ref: The QR code is not valid': 'Ref: The QR code is not valid',
    'Ref: Add location with QR Scanner': 'Ref: Add location with QR Scanner',
    'Ref: Description': 'Ref: Description',
    'Ref: Forbidden error': 'Ref: Forbidden error',
    'Ref: Invalid error': 'Ref: Invalid error',
  },
};
export default labels;
