const mockResponse = {
  getServiceRequestsSites: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        sites: [
          {
            id: '50754440-a7d2-e911-a812-000d3a6aa0b3',
            name: 'Wintamarra Village',
          },
        ],
      }),
  },
  getAssetInformation: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        contractId: '1694f64a-3f18-e711-80f8-c4346bc4be78',
        siteId: '50754440-a7d2-e911-a812-000d3a6aa0b3',
        roomName: 'A001-A004 - Room 001',
        buildingName: 'Wintamarra - Block A',
      }),
  },
  getAllServiceRequests: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        serviceRequests: [
          {
            id: '6d40935f-5d9b-ea11-a812-000d3aa800d0',
            referenceNumber: 'SR-07523-Q7S4V3',
            title: 'Mobile - SR_CB21052020',
            displayTitle: 'SR_CB2105202',
            currentStatus: 'In Progress',
            createdOn: '2020-05-21T12:19:57Z',
            modifiedOn: '2020-05-21T12:20:33Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
            building: {
              name: 'Brolga 1',
              level: 1,
              id: '6b6dfba9-f57b-e911-a989-000d3a2bccc5',
            },
            floor: {
              name: 'FMG Hamilton - A - 1st Floor',
              level: 2,
              id: '826dfba9-f57b-e911-a989-000d3a2bccc5',
            },
          },
          {
            id: 'fb6ea6a6-6c9a-ea11-a811-000d3a25caae',
            referenceNumber: 'SR-07495-H1W2C0',
            title: 'Feedback - Mobile - 55 - UI Automation',
            displayTitle: '55 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-05-20T07:36:50Z',
            modifiedOn: '2020-05-20T08:40:56Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
            building: {
              name: 'Brolga 2',
              level: 1,
              id: '6b6dfba9-f57b-e911-a989-000d3a2bccc5',
            },
            floor: {
              name: 'FMG Hamilton - B - Ground Floor',
              level: 2,
              id: '826dfba9-f57b-e911-a989-000d3a2bccc5',
            },
          },
          {
            id: '89a05f48-6a9a-ea11-8b71-0003ff4cdb48',
            referenceNumber: 'SR-07494-C1V9B1',
            title: 'I need sth',
            displayTitle: 'I need sth',
            currentStatus: 'Completed',
            createdOn: '2020-05-20T07:19:47Z',
            modifiedOn: '2020-05-20T07:27:00Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'ddfc568f-2794-ea11-99e5-0003ff4cdb10',
            referenceNumber: 'SR-07475-X1D9D5',
            title: 'Test SR for building ADP 11475 Great Oaks Way',
            displayTitle: 'Test SR for building ADP 11475 Great Oaks Way',
            currentStatus: 'In Progress',
            createdOn: '2020-05-12T08:07:02Z',
            modifiedOn: '2020-05-12T08:07:15Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'd86d8a75-968e-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07436-D4Q0W9',
            title: 'Mobile - Test2',
            displayTitle: 'Test2',
            currentStatus: 'In Progress',
            createdOn: '2020-05-05T06:05:52Z',
            modifiedOn: '2020-05-05T06:06:14Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'a22746ea-958e-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07435-N5X5Z9',
            title: 'Mobile - Test',
            displayTitle: 'Test',
            currentStatus: 'In Progress',
            createdOn: '2020-05-05T06:01:55Z',
            modifiedOn: '2020-05-05T06:02:41Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '47151297-f689-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07421-B8N5B2',
            title: 'Mobile - SR android',
            displayTitle: 'SR android',
            currentStatus: 'In Progress',
            createdOn: '2020-04-29T08:51:23Z',
            modifiedOn: '2020-04-29T08:51:47Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '407db540-c883-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07413-J3P4W2',
            title: 'Mobile - test',
            displayTitle: 'test',
            currentStatus: 'In Progress',
            createdOn: '2020-04-21T12:04:35Z',
            modifiedOn: '2020-04-21T12:04:52Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '568c166c-3d83-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07407-H1F2T9',
            title: 'Mobile - Test21',
            displayTitle: 'Test21',
            currentStatus: 'In Progress',
            createdOn: '2020-04-20T19:30:44Z',
            modifiedOn: '2020-04-20T19:31:56Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '4da47d44-ee82-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07406-G0R3T7',
            title: 'Mobile - Android Service Request 2004',
            displayTitle: 'Android Service Request 2004',
            currentStatus: 'In Progress',
            createdOn: '2020-04-20T10:04:10Z',
            modifiedOn: '2020-04-20T10:04:22Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'afe6982b-ea82-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07405-Z7B8K7',
            title: 'Mobile - iOS Service Request 2004',
            displayTitle: 'iOS Service Request 2004',
            currentStatus: 'In Progress',
            createdOn: '2020-04-20T09:34:46Z',
            modifiedOn: '2020-04-20T09:35:05Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '92e8f9da-c382-ea11-a811-000d3aa800d0',
            referenceNumber: 'SR-07404-S9F9Z8',
            title: 'Mobile - Monday on iOS',
            displayTitle: 'Monday on iOS',
            currentStatus: 'In Progress',
            createdOn: '2020-04-20T05:00:35Z',
            modifiedOn: '2020-04-20T05:00:48Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'c408c34f-c282-ea11-a811-000d3aa800d0',
            referenceNumber: 'SR-07403-W2M7C6',
            title: 'Mobile - Monday 2',
            displayTitle: 'Monday 2',
            currentStatus: 'In Progress',
            createdOn: '2020-04-20T04:49:34Z',
            modifiedOn: '2020-04-20T04:49:47Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'f971747a-bf82-ea11-a811-000d3aa800d0',
            referenceNumber: 'SR-07402-Z3K3X6',
            title: 'Mobile - Monday Test',
            displayTitle: 'Monday Test',
            currentStatus: 'In Progress',
            createdOn: '2020-04-20T04:29:18Z',
            modifiedOn: '2020-04-20T04:29:32Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'fda49fde-ae81-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07401-C9Q5J4',
            title: 'Mobile - Test Location',
            displayTitle: 'Test Location',
            currentStatus: 'In Progress',
            createdOn: '2020-04-18T19:57:45Z',
            modifiedOn: '2020-04-18T19:57:53Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'cb2f2d89-ae81-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07400-V3L2C5',
            title: 'Mobile - Test',
            displayTitle: 'Test',
            currentStatus: 'In Progress',
            createdOn: '2020-04-18T19:55:22Z',
            modifiedOn: '2020-04-18T19:56:10Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '115f5c2f-9e80-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07399-F3B5F8',
            title: 'Mobile - Phoenix',
            displayTitle: 'Phoenix',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T11:25:53Z',
            modifiedOn: '2020-04-17T11:26:10Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '30da1124-8780-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07398-Z5C7Q3',
            title: 'Mobile - Location',
            displayTitle: 'Location',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T08:40:51Z',
            modifiedOn: '2020-04-17T08:41:09Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'c0de45ed-8180-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07397-W9R1F1',
            title: 'Mobile - Android SR Test',
            displayTitle: 'Android SR Test',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T08:03:41Z',
            modifiedOn: '2020-04-17T08:03:47Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '864782a4-8180-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07396-Z5F6Q3',
            title: 'Mobile - Test iOS',
            displayTitle: 'Test iOS',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T08:01:33Z',
            modifiedOn: '2020-04-17T08:01:42Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'd456823a-8080-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07395-L2K6G5',
            title: 'Mobile - Avanade testing',
            displayTitle: 'Avanade testing',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T07:51:27Z',
            modifiedOn: '2020-04-17T07:51:40Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '34205116-8080-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07394-W8J7H0',
            title: 'Mobile - Test iOS',
            displayTitle: 'Test iOS',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T07:50:29Z',
            modifiedOn: '2020-04-17T07:50:45Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '26968220-7b80-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07393-G3W8D0',
            title: 'Mobile - SR Android Test',
            displayTitle: 'SR Android Test',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T07:14:51Z',
            modifiedOn: '2020-04-17T07:15:04Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '558ec2dc-7980-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07392-F6D1B6',
            title: 'Mobile - Android SR 1704',
            displayTitle: 'Android SR 1704',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T07:05:48Z',
            modifiedOn: '2020-04-17T07:05:57Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'cb40be93-7980-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07391-T3K3B4',
            title: 'Mobile - IOS SR 1704',
            displayTitle: 'IOS SR 1704',
            currentStatus: 'Completed',
            createdOn: '2020-04-17T07:03:47Z',
            modifiedOn: '2020-04-17T07:42:03Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '40cacb86-7880-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07390-Z9H3L8',
            title: 'Mobile - SR 170420',
            displayTitle: 'SR 170420',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T06:56:19Z',
            modifiedOn: '2020-04-17T06:56:22Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '45f9373e-7880-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07389-V7W4R4',
            title: 'Mobile - Service Request 1704',
            displayTitle: 'Service Request 1704',
            currentStatus: 'In Progress',
            createdOn: '2020-04-17T06:54:18Z',
            modifiedOn: '2020-04-17T06:54:34Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'ff2c2c53-b37f-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07386-H5Z8K0',
            title: 'Mobile - 46 - UI Automation',
            displayTitle: '46 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-16T07:24:38Z',
            modifiedOn: '2020-04-16T07:24:45Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '453594bc-b27f-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07385-G4N3Y1',
            title: 'Mobile - 45 - UI Automation',
            displayTitle: '45 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-16T07:20:24Z',
            modifiedOn: '2020-04-16T07:20:36Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '74e57ca1-a07f-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07384-C7B8T4',
            title: 'Mobile - 1 - UI Automation',
            displayTitle: '1 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-16T05:10:51Z',
            modifiedOn: '2020-04-16T05:11:09Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '0d1ab7e2-4e7f-ea11-a811-000d3aa800d0',
            referenceNumber: 'SR-07383-G0S2N5',
            title: 'Mobile - 75 - UI Automation',
            displayTitle: '75 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-15T19:25:43Z',
            modifiedOn: '2020-04-15T19:25:51Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '69bfc12e-4e7f-ea11-a811-000d3aa800d0',
            referenceNumber: 'SR-07382-G6J8L2',
            title: 'Mobile - 81 - UI Automation',
            displayTitle: '81 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-15T19:20:41Z',
            modifiedOn: '2020-04-15T19:20:48Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '0086d39e-4d7f-ea11-a811-000d3aa800d0',
            referenceNumber: 'SR-07381-L6K9X1',
            title: 'Mobile - 38 - UI Automation',
            displayTitle: '38 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-15T19:16:40Z',
            modifiedOn: '2020-04-15T19:16:50Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'fd00fe39-2e7f-ea11-a811-000d3aa800d0',
            referenceNumber: 'SR-07380-J9P8M0',
            title: 'Mobile - 2 - UI Automation',
            displayTitle: '2 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-15T15:31:52Z',
            modifiedOn: '2020-04-15T15:32:10Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '072cef6b-4a7e-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07379-D5G9C0',
            title: 'Mobile - 29 - UI Automation',
            displayTitle: '29 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-14T12:21:14Z',
            modifiedOn: '2020-04-14T12:21:32Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '178666d3-497e-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07378-V2P9L2',
            title: 'Mobile - 51 - UI Automation',
            displayTitle: '51 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-14T12:17:03Z',
            modifiedOn: '2020-04-14T12:17:17Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '255d183e-bb7d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07374-F1B6Q8',
            title: 'Mobile - 39 - UI Automation',
            displayTitle: '39 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T19:16:15Z',
            modifiedOn: '2020-04-13T19:16:38Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'bfda37c8-b97d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07373-T6R0B4',
            title: 'Mobile - 58 - UI Automation',
            displayTitle: '58 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T19:05:57Z',
            modifiedOn: '2020-04-13T19:06:03Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'af2d0541-b87d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07372-P3N5D9',
            title: 'Mobile - 9 - UI Automation',
            displayTitle: '9 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T18:54:56Z',
            modifiedOn: '2020-04-13T18:55:02Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'ff73831e-b77d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07371-N7P2N6',
            title: 'Mobile - 76 - UI Automation',
            displayTitle: '76 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T18:46:46Z',
            modifiedOn: '2020-04-13T18:47:01Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '22a00f19-b67d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07370-R0S7Z0',
            title: 'Mobile - 29 - UI Automation',
            displayTitle: '29 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T18:39:25Z',
            modifiedOn: '2020-04-13T18:39:37Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '1d9e25c1-b07d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07369-N8F5W7',
            title: 'Mobile - 34 - UI Automation',
            displayTitle: '34 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T18:01:12Z',
            modifiedOn: '2020-04-13T18:01:20Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '0322edc1-af7d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07368-W3S4Q2',
            title: 'Mobile - 66 - UI Automation',
            displayTitle: '66 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T17:54:10Z',
            modifiedOn: '2020-04-13T17:54:15Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '21257d77-ae7d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07367-D6Z2N5',
            title: 'Mobile - 98 - UI Automation',
            displayTitle: '98 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T17:44:57Z',
            modifiedOn: '2020-04-13T17:45:03Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '13d50380-ad7d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07366-T0B6T6',
            title: 'Mobile - 65 - UI Automation',
            displayTitle: '65 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T17:37:54Z',
            modifiedOn: '2020-04-13T17:38:01Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'f4cd5782-ac7d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07365-Z3X2S4',
            title: 'Mobile - 97 - UI Automation',
            displayTitle: '97 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T17:30:52Z',
            modifiedOn: '2020-04-13T17:31:08Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '018aa395-847d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07364-D2H5T1',
            title: 'Mobile - 28 - UI Automation',
            displayTitle: '28 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T12:45:03Z',
            modifiedOn: '2020-04-13T12:45:09Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'fc87d4df-837d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07363-P0K2C1',
            title: 'Mobile - 15 - UI Automation',
            displayTitle: '15 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T12:40:01Z',
            modifiedOn: '2020-04-13T12:40:07Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'cd430b9d-827d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07362-S1J4J0',
            title: 'Mobile - 55 - UI Automation',
            displayTitle: '55 - UI Automation',
            currentStatus: 'Completed',
            createdOn: '2020-04-13T12:30:58Z',
            modifiedOn: '2020-04-29T12:15:23Z',
            isFeedbackSubmitted: true,
            isComplaintSubmitted: false,
          },
          {
            id: '588afe91-7a7d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07361-N7V3L8',
            title: 'Mobile - 17 - UI Automation',
            displayTitle: '17 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T11:33:25Z',
            modifiedOn: '2020-04-13T11:33:33Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'a0a4aef7-797d-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07360-Y9Y3Y8',
            title: 'Mobile - 36 - UI Automation',
            displayTitle: '36 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-13T11:29:01Z',
            modifiedOn: '2020-04-13T11:29:14Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '81dfd825-1e7b-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07359-Y6X4R2',
            title: 'Mobile - 60 - UI Automation',
            displayTitle: '60 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-10T11:26:51Z',
            modifiedOn: '2020-04-10T11:27:00Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '453a8d9d-f97a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07358-P3Z1R4',
            title: 'Mobile - 2 - UI Automation',
            displayTitle: '2 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-10T07:05:15Z',
            modifiedOn: '2020-04-10T07:05:23Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'f8abed0e-f97a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07357-Z6C7F3',
            title: 'Mobile - 33 - UI Automation',
            displayTitle: '33 - UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-10T07:01:14Z',
            modifiedOn: '2020-04-10T07:01:23Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'a7dfc16e-e17a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07356-K6C3W4',
            title: 'Mobile - 04/10/2020 08:11- UI Automation',
            displayTitle: '04/10/2020 08:11- UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-10T04:12:09Z',
            modifiedOn: '2020-04-10T04:12:16Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'f14a6720-e17a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07355-S3T7K5',
            title: 'Mobile - 04/10/2020 08:09- UI Automation',
            displayTitle: '04/10/2020 08:09- UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-10T04:09:58Z',
            modifiedOn: '2020-04-10T04:10:08Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'b7803e3f-e07a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07354-Z1H1K4',
            title: 'Mobile - 04/10/2020 08:03- UI Automation',
            displayTitle: '04/10/2020 08:03- UI Automation',
            currentStatus: 'In Progress',
            createdOn: '2020-04-10T04:03:43Z',
            modifiedOn: '2020-04-10T04:04:09Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'd769a8ac-527a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07353-B0G2Q2',
            title: 'Mobile - 3:09 PM - UI Test Automation - App Center',
            displayTitle: '3:09 PM - UI Test Automation - App Center',
            currentStatus: 'In Progress',
            createdOn: '2020-04-09T11:10:17Z',
            modifiedOn: '2020-04-09T11:10:24Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'eb843ff5-517a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07352-M7S1M7',
            title: 'Mobile - 3:04 PM - UI Test Automation - App Center',
            displayTitle: '3:04 PM - UI Test Automation - App Center',
            currentStatus: 'In Progress',
            createdOn: '2020-04-09T11:05:11Z',
            modifiedOn: '2020-04-09T11:05:19Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'be19bca4-507a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07351-F7L4J9',
            title: 'Mobile - Xamarin UI Test - Automation - App Center - 4/9/2020 2:55:10 PM',
            displayTitle: 'Xamarin UI Test - Automation - App Center - 4/9/2020 2:55:10 PM',
            currentStatus: 'In Progress',
            createdOn: '2020-04-09T10:55:37Z',
            modifiedOn: '2020-04-09T10:55:43Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'd8817c31-507a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07350-N7W9K7',
            title: 'Mobile - test1',
            displayTitle: 'test1',
            currentStatus: 'In Progress',
            createdOn: '2020-04-09T10:52:30Z',
            modifiedOn: '2020-04-09T10:52:45Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'aadf086a-407a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07349-M8F3Z7',
            title: 'Mobile - UI Test - Automation - Yuvan',
            displayTitle: 'UI Test - Automation - Yuvan',
            currentStatus: 'In Progress',
            createdOn: '2020-04-09T08:59:30Z',
            modifiedOn: '2020-04-09T08:59:44Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '1ff0ab95-3c7a-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07348-R0P7H0',
            title: 'Mobile - UI Test - Automation - Yuvan',
            displayTitle: 'UI Test - Automation - Yuvan',
            currentStatus: 'In Progress',
            createdOn: '2020-04-09T08:32:06Z',
            modifiedOn: '2020-04-09T08:32:18Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '81b37450-a179-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07346-M3T2M6',
            title: 'Mobile - UI Test - Automation - Yuvan',
            displayTitle: 'UI Test - Automation - Yuvan',
            currentStatus: 'In Progress',
            createdOn: '2020-04-08T14:00:36Z',
            modifiedOn: '2020-04-08T14:00:52Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '8422a26f-7f79-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07345-T9G1S9',
            title: 'Mobile - UI Test - Automation - Yuvan',
            displayTitle: 'UI Test - Automation - Yuvan',
            currentStatus: 'In Progress',
            createdOn: '2020-04-08T09:58:13Z',
            modifiedOn: '2020-04-08T09:58:23Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '9ecd57a4-6e79-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07342-R7H3K5',
            title: 'Mobile - AVA - Test - 08/04/2020 at 11:56',
            displayTitle: 'AVA - Test - 08/04/2020 at 11:56',
            currentStatus: 'In Progress',
            createdOn: '2020-04-08T07:58:00Z',
            modifiedOn: '2020-04-08T07:58:15Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '804036dc-9f75-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07336-W4N1Y4',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(03-Apr-2020 (15h39 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(03-Apr-2020 (15h39 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-03T11:40:05Z',
            modifiedOn: '2020-04-03T11:40:23Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'c40808b5-9c75-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07335-Y4S8V2',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(03-Apr-2020 (15h17 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(03-Apr-2020 (15h17 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-03T11:17:36Z',
            modifiedOn: '2020-04-03T11:17:43Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '94de9528-9c75-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07334-J9K3Z5',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(03-Apr-2020 (15h13 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(03-Apr-2020 (15h13 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-03T11:13:35Z',
            modifiedOn: '2020-04-03T11:13:43Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '83a35cb5-8975-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07332-L4B8D5',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(03-Apr-2020 (13h01 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(03-Apr-2020 (13h01 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-03T09:01:32Z',
            modifiedOn: '2020-04-03T09:01:45Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'a2aea749-8975-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07331-H9T2M3',
            title: 'Mobile - avanade 2',
            displayTitle: 'avanade 2',
            currentStatus: 'In Progress',
            createdOn: '2020-04-03T08:58:30Z',
            modifiedOn: '2020-04-03T08:58:35Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '522f94af-8875-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07330-G2J3N3',
            title: 'Mobile - Avanade',
            displayTitle: 'Avanade',
            currentStatus: 'In Progress',
            createdOn: '2020-04-03T08:54:12Z',
            modifiedOn: '2020-04-03T08:54:25Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '37dcc8a1-f474-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07319-R8G4S4',
            title: 'Mobile - Akshay TESTING - IFM API TEST(02-Apr-2020 (19h14 GST +04))',
            displayTitle: 'Akshay TESTING - IFM API TEST(02-Apr-2020 (19h14 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T15:14:25Z',
            modifiedOn: '2020-04-02T15:14:32Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'e0c9d800-f474-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07318-M5J1H1',
            title: 'Mobile - Akshay TESTING - IFM API TEST(02-Apr-2020 (19h09 GST +04))',
            displayTitle: 'Akshay TESTING - IFM API TEST(02-Apr-2020 (19h09 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T15:09:56Z',
            modifiedOn: '2020-04-02T15:09:59Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '7f29a3f4-f374-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07317-P6F4T4',
            title: 'Mobile - Akshay TESTING - IFM API TEST(02-Apr-2020 (19h09 GST +04))',
            displayTitle: 'Akshay TESTING - IFM API TEST(02-Apr-2020 (19h09 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T15:09:42Z',
            modifiedOn: '2020-04-02T15:09:51Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '1f8e39c2-f374-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07316-Y7M9G7',
            title: 'Mobile - Akshay TESTING - IFM API TEST(02-Apr-2020 (19h08 GST +04))',
            displayTitle: 'Akshay TESTING - IFM API TEST(02-Apr-2020 (19h08 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T15:08:13Z',
            modifiedOn: '2020-04-02T15:08:23Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '4815cf95-f374-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07315-B5Q4V2',
            title: 'Mobile - Akshay TESTING - IFM API TEST(02-Apr-2020 (19h06 GST +04))',
            displayTitle: 'Akshay TESTING - IFM API TEST(02-Apr-2020 (19h06 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T15:06:54Z',
            modifiedOn: '2020-04-02T15:07:14Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '9321d46a-f374-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07314-X4B5T1',
            title: 'Mobile - Akshay TESTING - IFM API TEST(02-Apr-2020 (19h05 GST +04))',
            displayTitle: 'Akshay TESTING - IFM API TEST(02-Apr-2020 (19h05 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T15:05:41Z',
            modifiedOn: '2020-04-02T15:06:14Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'dd47904e-ec74-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07312-F1T2P5',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (18h14 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (18h14 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T14:14:48Z',
            modifiedOn: '2020-04-02T14:14:50Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '8ab54900-ec74-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07311-K0G1G6',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (18h12 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (18h12 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T14:12:45Z',
            modifiedOn: '2020-04-02T14:12:50Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '7dfe987c-e974-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07310-X9G6N5',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h54 GST +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h54 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T13:54:40Z',
            modifiedOn: '2020-04-02T13:54:47Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '772f10c2-e874-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07309-D6Y9D4',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h49 GST +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h49 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T13:49:31Z',
            modifiedOn: '2020-04-02T13:49:42Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '4816d4fe-e374-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07308-D9J8P8',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h15 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h15 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T13:15:18Z',
            modifiedOn: '2020-04-02T13:15:28Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'a8edcff8-e374-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07307-J9Q4N4',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h15 GST +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h15 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T13:15:12Z',
            modifiedOn: '2020-04-02T13:15:18Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '28e1a6c3-e374-ea11-a811-000d3a288f7b',
            referenceNumber: 'SR-07306-D9G0R9',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h13 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(02-Apr-2020 (17h13 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T13:13:47Z',
            modifiedOn: '2020-04-02T13:13:58Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'c79a768a-df74-ea11-a811-000d3aa800d0',
            referenceNumber: 'SR-07305-Y4Q2G3',
            title: 'Mobile - Title',
            displayTitle: 'Title',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T12:43:27Z',
            modifiedOn: '2020-04-02T12:43:52Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '111b7c4e-c274-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07303-Y9T9N9',
            title: 'Mobile - My Request 0204',
            displayTitle: 'My Request 0204',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T09:14:11Z',
            modifiedOn: '2020-04-02T09:14:15Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '6447bf75-c174-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07302-C0W5J1',
            title: 'Mobile - SR020420',
            displayTitle: 'SR020420',
            currentStatus: 'Completed',
            createdOn: '2020-04-02T09:08:11Z',
            modifiedOn: '2020-04-02T09:12:48Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'a3a31386-bd74-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07301-Z1H8Z9',
            title: 'Mobile - Prashant Test',
            displayTitle: 'Prashant Test',
            currentStatus: 'In Progress',
            createdOn: '2020-04-02T08:39:59Z',
            modifiedOn: '2020-04-02T08:40:14Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'cfb1a48a-5674-ea11-a811-000d3a288edb',
            referenceNumber: 'SR-07300-M5P5Z2',
            title: 'Mobile - Test',
            displayTitle: 'Test',
            currentStatus: 'In Progress',
            createdOn: '2020-04-01T20:22:44Z',
            modifiedOn: '2020-04-01T20:23:04Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '00ae9453-b562-ea11-a811-000d3a25c7c2',
            referenceNumber: 'SR-07268-X9L8L3',
            title: 'Mobile - test',
            displayTitle: 'test',
            currentStatus: 'In Progress',
            createdOn: '2020-03-10T09:55:58Z',
            modifiedOn: '2020-03-10T09:56:04Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '5c57e233-b462-ea11-a811-000d3a25c7c2',
            referenceNumber: 'SR-07267-T4K0S0',
            title: 'Mobile - demo claire',
            displayTitle: 'demo claire',
            currentStatus: 'In Progress',
            createdOn: '2020-03-10T09:47:53Z',
            modifiedOn: '2020-03-10T09:48:17Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '5878e7ab-975f-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07244-Z7R7Z1',
            title: 'Mobile - testtest 2 lul',
            displayTitle: 'testtest 2 lul',
            currentStatus: 'In Progress',
            createdOn: '2020-03-06T10:46:11Z',
            modifiedOn: '2020-03-06T10:46:24Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '2bf52264-975f-ea11-a811-000d3a25c5d6',
            referenceNumber: 'SR-07243-G0G5M9',
            title: 'Mobile - Request test',
            displayTitle: 'Request test',
            currentStatus: 'In Progress',
            createdOn: '2020-03-06T10:44:10Z',
            modifiedOn: '2020-03-06T10:44:32Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '0b0bf901-895c-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07220-H6Y3S4',
            title: 'Mobile - SR UI test 2',
            displayTitle: 'SR UI test 2',
            currentStatus: 'In Progress',
            createdOn: '2020-03-02T13:23:39Z',
            modifiedOn: '2020-03-02T13:23:49Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'c70ce1da-835c-ea11-a811-000d3a25c8e0',
            referenceNumber: 'SR-07213-M1F1L0',
            title: 'Mobile - SR UI test',
            displayTitle: 'SR UI test',
            currentStatus: 'In Progress',
            createdOn: '2020-03-02T12:46:41Z',
            modifiedOn: '2020-03-02T12:46:50Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '1e3e4644-6959-ea11-a811-000d3a25ccfa',
            referenceNumber: 'SR-07183-K7X5P6',
            title: 'Mobile - TEST SR DATE FIX',
            displayTitle: 'TEST SR DATE FIX',
            currentStatus: 'In Progress',
            createdOn: '2020-02-27T13:58:46Z',
            modifiedOn: '2020-02-27T13:58:55Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'a3b1047c-6558-ea11-a811-000d3a25c7c6',
            referenceNumber: 'SR-07179-J6W4J1',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(26-Feb-2020 (10h59 GST +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(26-Feb-2020 (10h59 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-26T06:59:13Z',
            modifiedOn: '2020-02-26T06:59:25Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'e04cfae9-6458-ea11-a811-000d3a25c7c6',
            referenceNumber: 'SR-07178-H3W1T4',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(26-Feb-2020 (10h55 GST +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(26-Feb-2020 (10h55 GST +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-26T06:55:09Z',
            modifiedOn: '2020-02-26T06:55:23Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '4466f4ac-6058-ea11-a811-000d3a25c7c6',
            referenceNumber: 'SR-07177-Q7N8P9',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(26-Feb-2020 (10h24 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(26-Feb-2020 (10h24 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-26T06:24:47Z',
            modifiedOn: '2020-02-26T06:25:05Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '649753da-b453-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07144-R3C4Z0',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(20-Feb-2020 (11h44 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(20-Feb-2020 (11h44 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-20T07:44:44Z',
            modifiedOn: '2020-02-20T07:44:50Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '01bd4f86-b453-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07143-V1P6Q5',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(20-Feb-2020 (11h42 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(20-Feb-2020 (11h42 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-20T07:42:30Z',
            modifiedOn: '2020-02-20T07:42:43Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '94b24cda-ed52-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07123-B9M5N5',
            title: 'Mobile - AVA - 11:40 - 19/02',
            displayTitle: 'AVA - 11:40 - 19/02',
            currentStatus: 'In Progress',
            createdOn: '2020-02-19T08:00:15Z',
            modifiedOn: '2020-02-19T08:00:20Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '26f35b2a-de52-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07118-J1V9D8',
            title: 'Mobile - AVA - 10:08 - 19/02',
            displayTitle: 'AVA - 10:08 - 19/02',
            currentStatus: 'In Progress',
            createdOn: '2020-02-19T06:07:55Z',
            modifiedOn: '2020-02-19T06:08:12Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'e8577310-dc52-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07117-S1D6S2',
            title: 'Mobile - AVA - 19/02 - 09:53',
            displayTitle: 'AVA - 19/02 - 09:53',
            currentStatus: 'In Progress',
            createdOn: '2020-02-19T05:52:52Z',
            modifiedOn: '2020-02-19T05:53:08Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '1f5ad3bf-d352-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07116-G7G2Q6',
            title: 'Mobile - AVA - SR - 19/02 - 08:54',
            displayTitle: 'AVA - SR - 19/02 - 08:54',
            currentStatus: 'In Progress',
            createdOn: '2020-02-19T04:53:30Z',
            modifiedOn: '2020-02-19T04:53:42Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '5ae4190a-3e52-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07112-W9V7G6',
            title: 'Mobile - SR Test 15:02',
            displayTitle: 'SR Test 15:02',
            currentStatus: 'In Progress',
            createdOn: '2020-02-18T11:01:48Z',
            modifiedOn: '2020-02-18T11:02:04Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '3b89a879-2552-ea11-a813-000d3a25c833',
            referenceNumber: 'SR-07109-W5P9H8',
            title: 'Feedback - Mobile - title',
            displayTitle: 'Mobile - title',
            currentStatus: 'In Progress',
            createdOn: '2020-02-18T08:05:55Z',
            modifiedOn: '2020-02-18T08:06:00Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'c538a76d-1652-ea11-a813-000d3a25c833',
            referenceNumber: 'SR-07105-M1K7C9',
            title: 'Feedback - Mobile - Air con defect',
            displayTitle: 'Air con defect',
            currentStatus: 'In Progress',
            createdOn: '2020-02-18T06:18:13Z',
            modifiedOn: '2020-02-18T06:18:27Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '95df4268-c151-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07100-Z9R0R2',
            title: 'Mobile - Testing Location',
            displayTitle: 'Testing Location',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T20:09:41Z',
            modifiedOn: '2020-02-17T20:10:09Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'fcc058da-6e51-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07095-K7J2N6',
            title: 'Mobile - SR Test - 14:18',
            displayTitle: 'SR Test - 14:18',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T10:18:38Z',
            modifiedOn: '2020-02-17T10:18:44Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '6e6a1191-6d51-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07094-T2H5R6',
            title: 'Mobile - AVA - TEST - 17 Jan - 14:10',
            displayTitle: 'AVA - TEST - 17 Jan - 14:10',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T10:09:23Z',
            modifiedOn: '2020-02-17T10:09:41Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '7dc29aef-6c51-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07092-Q9D1B2',
            title: 'Mobile - SR Test 14:05',
            displayTitle: 'SR Test 14:05',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T10:04:55Z',
            modifiedOn: '2020-02-17T10:05:05Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '5bdfb4cf-6851-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07090-K2B5M4',
            title: 'Mobile - SR Test 13:35',
            displayTitle: 'SR Test 13:35',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T09:35:28Z',
            modifiedOn: '2020-02-17T09:35:43Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '99fdb2a3-5b51-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07087-F6B4S4',
            title: 'Mobile - Test 17/02',
            displayTitle: 'Test 17/02',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T08:01:12Z',
            modifiedOn: '2020-02-17T08:01:29Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '674271c5-4351-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07082-S6F0B5',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(17-Feb-2020 (9h09 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(17-Feb-2020 (9h09 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T05:10:16Z',
            modifiedOn: '2020-02-17T05:10:25Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '8268c435-4351-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07081-V1T1W0',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(17-Feb-2020 (9h06 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(17-Feb-2020 (9h06 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T05:06:13Z',
            modifiedOn: '2020-02-17T05:06:30Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '8f539b5d-4251-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07080-B7P2X4',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(17-Feb-2020 (8h59 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(17-Feb-2020 (8h59 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T05:00:10Z',
            modifiedOn: '2020-02-17T05:00:39Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'efe99d04-3f51-ea11-a812-000d3a25c5d6',
            referenceNumber: 'SR-07079-F1R1Z3',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(17-Feb-2020 (8h35 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(17-Feb-2020 (8h35 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-17T04:36:16Z',
            modifiedOn: '2020-02-17T04:36:34Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '29bf2ee1-254f-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07078-S8T1X5',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(14-Feb-2020 (16h30 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(14-Feb-2020 (16h30 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-14T12:31:14Z',
            modifiedOn: '2020-02-14T12:31:31Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '3d1a1c09-174f-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07077-P3V6V0',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(14-Feb-2020 (14h44 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(14-Feb-2020 (14h44 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-14T10:44:57Z',
            modifiedOn: '2020-02-14T10:45:04Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '7e5333f7-164f-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07076-Q8B6W8',
            title: 'Mobile - AUTOMATED SR CREATION - IFM API TEST(14-Feb-2020 (14h37 MUT +04))',
            displayTitle: 'AUTOMATED SR CREATION - IFM API TEST(14-Feb-2020 (14h37 MUT +04))',
            currentStatus: 'In Progress',
            createdOn: '2020-02-14T10:44:32Z',
            modifiedOn: '2020-02-14T10:44:59Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: 'c437407b-374e-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07073-C0R7V0',
            title: 'Mobile - TestC',
            displayTitle: 'TestC',
            currentStatus: 'In Progress',
            createdOn: '2020-02-13T08:04:49Z',
            modifiedOn: '2020-02-13T08:04:52Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
          {
            id: '39a62b0f-374e-ea11-a812-000d3a25c942',
            referenceNumber: 'SR-07072-S4V6X2',
            title: 'Mobile - TestB',
            displayTitle: 'TestB',
            currentStatus: 'In Progress',
            createdOn: '2020-02-13T08:01:46Z',
            modifiedOn: '2020-02-13T08:02:04Z',
            isFeedbackSubmitted: false,
            isComplaintSubmitted: false,
          },
        ],
      }),
  },
  getOneServiceRequest: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        serviceRequest: {
          referenceNumber: 'SR-06121-F1M2V4',
          title: 'Mobile - Ava test',
          status: 'In Progress',
          createdOn: '2019-11-15T10:25:35Z',
          modifiedOn: '0001-01-01T00:00:00',
          description: 'Building: Test\nAva test',
          building: {
            name: 'Jabiru',
            level: 1,
            id: '8f8e27a9-f57b-e911-a97d-000d3a2bc547',
          },
          floor: {
            name: 'FMG Hamilton - J - Ground Floor',
            level: 2,
            id: 'd68e27a9-f57b-e911-a97d-000d3a2bc547',
          },
          wing: {
            name: 'Left',
            level: 3,
            id: 'd68e27a9-f57b-e911-a97d-000d3a2bc545',
          },
          room: {
            name: '12345',
            level: 4,
            id: 'd68e27a9-f57b-e911-a97d-000d3a2bc544',
          },
          property: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2bcd97',
            name: 'Hamilton Property',
          },
          site: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
            name: 'FMG Hamilton site',
          },
          messages: [
            {
              createdBy: 'Samantha Fernando',
              isCreatedByUser: true,
              createdOn: '2019-11-15T11:45:02Z',
            },
            {
              createdBy: 'Samantha Fernando',
              isCreatedByUser: true,
              createdOn: '2019-11-15T10:28:17Z',
            },
            {
              createdBy: 'Samantha Fernando',
              isCreatedByUser: true,
              createdOn: '2019-11-15T10:27:53Z',
            },
            {
              description:
                'I forgot to tell you sth. I forgot to tell you sth. I forgot to tell you sth. I forgot to tell you sth. I forgot to tell you sth. I forgot to tell you sth. I forgot to tell you sth. ',
              createdBy: 'Samantha Fernando',
              isCreatedByUser: true,
              createdOn: '2019-11-15T10:26:52Z',
              attachments: [
                {
                  id: 'b6c18471-9207-ea11-a811-000d3a25c8e0',
                  fileName: 'image 0.jpg',
                },
                {
                  id: 'b6c18471-9207-ea11-a811-000d3a25c8e1',
                  fileName: 'image 1.jpg',
                },
              ],
            },
            {
              description: 'Noted, thanks. We will be coming back to you soon',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2019-11-15T10:34:16Z',
            },
            {
              description: "We're on it, will be completed within 48h.",
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2019-11-16T10:34:16Z',
            },
          ],
          images: [
            {
              id: '65eef4b8-09c6-ec11-a7b6-000d3a2ba659',
              fileName: 'photo1.jpg',
            },
            {
              id: '65eef4b8-09c6-ec11-a7b6-000d3a2ba650',
              fileName: 'photo2.png',
            },
          ],
        },
      }),
  },
  searchLocations: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          title: 'Block A - Room 5, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 5, Camp - A - Ground Floor, Accommodation Block A',
          id: '8f1642ad-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 5',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - Storage',
          searchableTitle: 'Camp - Storage',
          id: '08b64fb1-be7c-e911-a989-000d3a2bccc5',
          name: 'Camp - Storage',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 13, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 13, Camp - B - Ground Floor, Accommodation Block B',
          id: 'c7cc0aaf-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 13',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 17, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 17, Camp - B - Ground Floor, Accommodation Block B',
          id: '871642ad-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 17',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 19, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 19, Camp - B - Ground Floor, Accommodation Block B',
          id: 'fdb829ad-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 19',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 18, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 18, Camp - B - Ground Floor, Accommodation Block B',
          id: '03b64fb1-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 18',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 16, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 16, Camp - B - Ground Floor, Accommodation Block B',
          id: 'b7cc0aaf-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 16',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 21, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 21, Camp - B - Ground Floor, Accommodation Block B',
          id: 'ddb829ad-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 21',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 12, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 12, Camp - B - Ground Floor, Accommodation Block B',
          id: 'e0b829ad-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 12',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 14, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 14, Camp - B - Ground Floor, Accommodation Block B',
          id: 'adcc0aaf-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 14',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 15, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 15, Camp - B - Ground Floor, Accommodation Block B',
          id: 'b0cc0aaf-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 15',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 11, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 11, Camp - B - Ground Floor, Accommodation Block B',
          id: '5b1642ad-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 11',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 9, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 9, Camp - B - Ground Floor, Accommodation Block B',
          id: 'd2b829ad-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 9',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 10, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 10, Camp - B - Ground Floor, Accommodation Block B',
          id: '17164fab-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 10',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 22, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 22, Camp - B - Ground Floor, Accommodation Block B',
          id: '501642ad-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 22',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 8, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 8, Camp - B - Ground Floor, Accommodation Block B',
          id: '571642ad-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 8',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 20, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 20, Camp - B - Ground Floor, Accommodation Block B',
          id: 'cdb829ad-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 20',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 7, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 7, Camp - B - Ground Floor, Accommodation Block B',
          id: '0e164fab-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 7',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 30, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 30, Camp - B - Ground Floor, Accommodation Block B',
          id: 'bab829ad-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 30',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 5, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 5, Camp - B - Ground Floor, Accommodation Block B',
          id: 'c6b829ad-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 5',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 4, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 4, Camp - A - Ground Floor, Accommodation Block A',
          id: '3ccf17ad-be7c-e911-a977-000d3a2bcac2',
          name: 'Block A - Room 4',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 2, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 2, Camp - A - Ground Floor, Accommodation Block A',
          id: 'e2154fab-be7c-e911-a989-000d3a2bccc5',
          name: 'Block A - Room 2',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 1, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 1, Camp - C - Ground Floor, Accommodation Block C',
          id: 'b5a6f3a8-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 1',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 29, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 29, Camp - B - Ground Floor, Accommodation Block B',
          id: '28cf17ad-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 29',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 32, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 32, Camp - B - Ground Floor, Accommodation Block B',
          id: 'acb829ad-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 32',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 31, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 31, Camp - B - Ground Floor, Accommodation Block B',
          id: 'a5a6f3a8-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 31',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 28, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 28, Camp - B - Ground Floor, Accommodation Block B',
          id: '791d0ca7-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 28',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 23, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 23, Camp - B - Ground Floor, Accommodation Block B',
          id: '500f1ba7-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 23',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 27, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 27, Camp - B - Ground Floor, Accommodation Block B',
          id: '6aa2d1a6-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 27',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 26, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 26, Camp - B - Ground Floor, Accommodation Block B',
          id: '440f1ba7-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 26',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 25, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 25, Camp - B - Ground Floor, Accommodation Block B',
          id: '63a2d1a6-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 25',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 24, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 24, Camp - B - Ground Floor, Accommodation Block B',
          id: '661d0ca7-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 24',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 6, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 6, Camp - B - Ground Floor, Accommodation Block B',
          id: '280f1ba7-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 6',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 4, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 4, Camp - B - Ground Floor, Accommodation Block B',
          id: 'c9154fab-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 4',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 4, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 4, Camp - C - Ground Floor, Accommodation Block C',
          id: '190f1ba7-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 4',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 20, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 20, Camp - A - Ground Floor, Accommodation Block A',
          id: 'ca3e54a5-be7c-e911-a989-000d3a2bccc5',
          name: 'Block A - Room 20',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 18, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 18, Camp - A - Ground Floor, Accommodation Block A',
          id: '50a2d1a6-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block A - Room 18',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 17, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 17, Camp - A - Ground Floor, Accommodation Block A',
          id: 'c03e54a5-be7c-e911-a989-000d3a2bccc5',
          name: 'Block A - Room 17',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 13, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 13, Camp - A - Ground Floor, Accommodation Block A',
          id: '471d0ca7-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 13',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 14, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 14, Camp - A - Ground Floor, Accommodation Block A',
          id: 'b73e54a5-be7c-e911-a989-000d3a2bccc5',
          name: 'Block A - Room 14',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 15, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 15, Camp - A - Ground Floor, Accommodation Block A',
          id: '7ba6f3a8-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block A - Room 15',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 16, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 16, Camp - A - Ground Floor, Accommodation Block A',
          id: '7fa6f3a8-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block A - Room 16',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 12, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 12, Camp - A - Ground Floor, Accommodation Block A',
          id: '451d0ca7-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 12',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 10, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 10, Camp - A - Ground Floor, Accommodation Block A',
          id: 'f40e1ba7-be7c-e911-a977-000d3a2bcac2',
          name: 'Block A - Room 10',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 11, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 11, Camp - A - Ground Floor, Accommodation Block A',
          id: '73a6f3a8-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block A - Room 11',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 6, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 6, Camp - A - Ground Floor, Accommodation Block A',
          id: '3a1d0ca7-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 6',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 7, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 7, Camp - A - Ground Floor, Accommodation Block A',
          id: '3f1d0ca7-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 7',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 8, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 8, Camp - A - Ground Floor, Accommodation Block A',
          id: 'ec0e1ba7-be7c-e911-a977-000d3a2bcac2',
          name: 'Block A - Room 8',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 9, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 9, Camp - A - Ground Floor, Accommodation Block A',
          id: 'fa59f7a2-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block A - Room 9',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 21, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 21, Camp - A - Ground Floor, Accommodation Block A',
          id: '351d0ca7-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 21',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 19, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 19, Camp - A - Ground Floor, Accommodation Block A',
          id: 'ae3e54a5-be7c-e911-a989-000d3a2bccc5',
          name: 'Block A - Room 19',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 3, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 3, Camp - B - Ground Floor, Accommodation Block B',
          id: '301d0ca7-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 3',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 22, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 22, Camp - A - Ground Floor, Accommodation Block A',
          id: '604a85a0-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block A - Room 22',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 2, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 2, Camp - B - Ground Floor, Accommodation Block B',
          id: '2b68379f-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 2',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 1, Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Block B - Room 1, Camp - B - Ground Floor, Accommodation Block B',
          id: '495efca0-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 1',
          parent: {
            name: 'Camp - B - Ground Floor',
            level: 2,
            id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 3, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 3, Camp - A - Ground Floor, Accommodation Block A',
          id: 'd559f7a2-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block A - Room 3',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 31, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 31, Camp - A - Ground Floor, Accommodation Block A',
          id: '405efca0-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 31',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 32, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 32, Camp - A - Ground Floor, Accommodation Block A',
          id: '445efca0-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 32',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 30, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 30, Camp - A - Ground Floor, Accommodation Block A',
          id: '395efca0-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 30',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 29, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 29, Camp - A - Ground Floor, Accommodation Block A',
          id: 'a7d04fa0-be7c-e911-a977-000d3a2bcac2',
          name: 'Block A - Room 29',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 28, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 28, Camp - A - Ground Floor, Accommodation Block A',
          id: '325efca0-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 28',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 27, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 27, Camp - A - Ground Floor, Accommodation Block A',
          id: 'a46cbb9c-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block A - Room 27',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 23, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 23, Camp - A - Ground Floor, Accommodation Block A',
          id: '93d04fa0-be7c-e911-a977-000d3a2bcac2',
          name: 'Block A - Room 23',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 24, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 24, Camp - A - Ground Floor, Accommodation Block A',
          id: '96d04fa0-be7c-e911-a977-000d3a2bcac2',
          name: 'Block A - Room 24',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 25, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 25, Camp - A - Ground Floor, Accommodation Block A',
          id: '98d04fa0-be7c-e911-a977-000d3a2bcac2',
          name: 'Block A - Room 25',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 26, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 26, Camp - A - Ground Floor, Accommodation Block A',
          id: '0568379f-be7c-e911-a989-000d3a2bccc5',
          name: 'Block A - Room 26',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 2, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 2, Camp - C - Ground Floor, Accommodation Block C',
          id: '135efca0-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 2',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 5, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 5, Camp - C - Ground Floor, Accommodation Block C',
          id: 'ea67379f-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 5',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 3, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 3, Camp - C - Ground Floor, Accommodation Block C',
          id: '926cbb9c-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 3',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 10, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 10, Camp - D - Ground Floor, Accommodation Block D',
          id: '274a85a0-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block D - Room 10',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 31, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 31, Camp - C - Ground Floor, Accommodation Block C',
          id: '8d6cbb9c-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 31',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 8, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 8, Camp - D - Ground Floor, Accommodation Block D',
          id: '65654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block D - Room 8',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 9, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 9, Camp - D - Ground Floor, Accommodation Block D',
          id: '79d04fa0-be7c-e911-a977-000d3a2bcac2',
          name: 'Block D - Room 9',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 6, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 6, Camp - D - Ground Floor, Accommodation Block D',
          id: '846cbb9c-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block D - Room 6',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 7, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 7, Camp - D - Ground Floor, Accommodation Block D',
          id: '886cbb9c-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block D - Room 7',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 4, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 4, Camp - D - Ground Floor, Accommodation Block D',
          id: '2220039b-be7c-e911-a97d-000d3a2bc547',
          name: 'Block D - Room 4',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 5, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 5, Camp - D - Ground Floor, Accommodation Block D',
          id: '2420039b-be7c-e911-a97d-000d3a2bc547',
          name: 'Block D - Room 5',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 3, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 3, Camp - D - Ground Floor, Accommodation Block D',
          id: '7d6cbb9c-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block D - Room 3',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block A - Room 1, Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Block A - Room 1, Camp - A - Ground Floor, Accommodation Block A',
          id: '1920039b-be7c-e911-a97d-000d3a2bc547',
          name: 'Block A - Room 1',
          parent: {
            name: 'Camp - A - Ground Floor',
            level: 2,
            id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 1, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 1, Camp - D - Ground Floor, Accommodation Block D',
          id: '1d20039b-be7c-e911-a97d-000d3a2bc547',
          name: 'Block D - Room 1',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 6, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 6, Camp - C - Ground Floor, Accommodation Block C',
          id: '5a654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 6',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 13, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 13, Camp - D - Ground Floor, Accommodation Block D',
          id: '1420039b-be7c-e911-a97d-000d3a2bc547',
          name: 'Block D - Room 13',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 11, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 11, Camp - D - Ground Floor, Accommodation Block D',
          id: '57654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block D - Room 11',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 12, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 12, Camp - D - Ground Floor, Accommodation Block D',
          id: '746cbb9c-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block D - Room 12',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 23, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 23, Camp - D - Ground Floor, Accommodation Block D',
          id: '43654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block D - Room 23',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 24, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 24, Camp - D - Ground Floor, Accommodation Block D',
          id: '915d479a-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block D - Room 24',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 20, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 20, Camp - D - Ground Floor, Accommodation Block D',
          id: '726cbb9c-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block D - Room 20',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 19, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 19, Camp - D - Ground Floor, Accommodation Block D',
          id: '3c654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block D - Room 19',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 21, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 21, Camp - D - Ground Floor, Accommodation Block D',
          id: '3eeb0699-be7c-e911-a989-000d3a2bccc5',
          name: 'Block D - Room 21',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 22, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 22, Camp - D - Ground Floor, Accommodation Block D',
          id: '40eb0699-be7c-e911-a989-000d3a2bccc5',
          name: 'Block D - Room 22',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 17, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 17, Camp - D - Ground Floor, Accommodation Block D',
          id: '35654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block D - Room 17',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 18, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 18, Camp - D - Ground Floor, Accommodation Block D',
          id: '38654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block D - Room 18',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 14, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 14, Camp - D - Ground Floor, Accommodation Block D',
          id: '3a654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block D - Room 14',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 32, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 32, Camp - C - Ground Floor, Accommodation Block C',
          id: '0220039b-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 32',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 15, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 15, Camp - D - Ground Floor, Accommodation Block D',
          id: '785d479a-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block D - Room 15',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 2, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 2, Camp - D - Ground Floor, Accommodation Block D',
          id: '2ceb0699-be7c-e911-a989-000d3a2bccc5',
          name: 'Block D - Room 2',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D - Room 16, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Block D - Room 16, Camp - D - Ground Floor, Accommodation Block D',
          id: '36eb0699-be7c-e911-a989-000d3a2bccc5',
          name: 'Block D - Room 16',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 19, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 19, Camp - C - Ground Floor, Accommodation Block C',
          id: '29654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 19',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 30, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 30, Camp - C - Ground Floor, Accommodation Block C',
          id: '29eb0699-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 30',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 13, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 13, Camp - C - Ground Floor, Accommodation Block C',
          id: '8ead9e96-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 13',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 18, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 18, Camp - C - Ground Floor, Accommodation Block C',
          id: '20eb0699-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 18',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 12, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 12, Camp - C - Ground Floor, Accommodation Block C',
          id: '8310e594-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 12',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 15, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 15, Camp - C - Ground Floor, Accommodation Block C',
          id: '25654f9a-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 15',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 17, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 17, Camp - C - Ground Floor, Accommodation Block C',
          id: '1ceb0699-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 17',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 14, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 14, Camp - C - Ground Floor, Accommodation Block C',
          id: '84ad9e96-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 14',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 11, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 11, Camp - C - Ground Floor, Accommodation Block C',
          id: '792b5494-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 11',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 21, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 21, Camp - C - Ground Floor, Accommodation Block C',
          id: '802b5494-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 21',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 10, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 10, Camp - C - Ground Floor, Accommodation Block C',
          id: 'ae0d3894-be7c-e911-a97d-000d3a2bcc69',
          name: 'Block B - Room 10',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 8, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 8, Camp - C - Ground Floor, Accommodation Block C',
          id: '2f8a0b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 8',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 9, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 9, Camp - C - Ground Floor, Accommodation Block C',
          id: '7bad9e96-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 9',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 16, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 16, Camp - C - Ground Floor, Accommodation Block C',
          id: '7d10e594-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 16',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 22, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 22, Camp - C - Ground Floor, Accommodation Block C',
          id: '208a0b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 22',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 20, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 20, Camp - C - Ground Floor, Accommodation Block C',
          id: '258a0b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 20',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 7, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 7, Camp - C - Ground Floor, Accommodation Block C',
          id: '78ad9e96-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 7',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 29, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 29, Camp - C - Ground Floor, Accommodation Block C',
          id: '6ead9e96-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 29',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 24, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 24, Camp - C - Ground Floor, Accommodation Block C',
          id: '70ad9e96-be7c-e911-a97e-000d3a2bcd97',
          name: 'Block B - Room 24',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 25, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 25, Camp - C - Ground Floor, Accommodation Block C',
          id: '6b10e594-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 25',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 26, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 26, Camp - C - Ground Floor, Accommodation Block C',
          id: '148a0b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 26',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 28, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 28, Camp - C - Ground Floor, Accommodation Block C',
          id: '1a8a0b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Block B - Room 28',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - C - Roof, Accommodation Block C',
          searchableTitle: 'Camp - C - Roof, Accommodation Block C',
          id: '6010e594-be7c-e911-a97d-000d3a2bc547',
          name: 'Camp - C - Roof',
          parent: {
            name: 'Accommodation Block C',
            level: 1,
            id: 'd7bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 27, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 27, Camp - C - Ground Floor, Accommodation Block C',
          id: '6810e594-be7c-e911-a97d-000d3a2bc547',
          name: 'Block B - Room 27',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block B - Room 23, Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Block B - Room 23, Camp - C - Ground Floor, Accommodation Block C',
          id: '582b5494-be7c-e911-a977-000d3a2bcac2',
          name: 'Block B - Room 23',
          parent: {
            name: 'Camp - C - Ground Floor',
            level: 2,
            id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - B - Roof, Accommodation Block B',
          searchableTitle: 'Camp - B - Roof, Accommodation Block B',
          id: '5a10e594-be7c-e911-a97d-000d3a2bc547',
          name: 'Camp - B - Roof',
          parent: {
            name: 'Accommodation Block B',
            level: 1,
            id: 'e1bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - D - Roof, Accommodation Block D',
          searchableTitle: 'Camp - D - Roof, Accommodation Block D',
          id: '5d10e594-be7c-e911-a97d-000d3a2bc547',
          name: 'Camp - D - Roof',
          parent: {
            name: 'Accommodation Block D',
            level: 1,
            id: 'd1bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - A - Roof, Accommodation Block A',
          searchableTitle: 'Camp - A - Roof, Accommodation Block A',
          id: '088a0b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Camp - A - Roof',
          parent: {
            name: 'Accommodation Block A',
            level: 1,
            id: '7f09378e-be7c-e911-a97d-000d3a2bcc69',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - Administration Reception',
          searchableTitle: 'Camp - Administration Reception',
          id: '8d0d3894-be7c-e911-a97d-000d3a2bcc69',
          name: 'Camp - Administration Reception',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Block D Laundry, Accommodation Block D',
          searchableTitle: 'Block D Laundry, Accommodation Block D',
          id: 'fd890b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Block D Laundry',
          parent: {
            name: 'Accommodation Block D',
            level: 1,
            id: 'd1bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Dining Kitchen',
          searchableTitle: 'Dining Kitchen',
          id: '4f10e594-be7c-e911-a97d-000d3a2bc547',
          name: 'Dining Kitchen',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Gym',
          searchableTitle: 'Gym',
          id: '392b5494-be7c-e911-a977-000d3a2bcac2',
          name: 'Gym',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - Laundry',
          searchableTitle: 'Camp - Laundry',
          id: '492b5494-be7c-e911-a977-000d3a2bcac2',
          name: 'Camp - Laundry',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - A - Ground Floor, Accommodation Block A',
          searchableTitle: 'Camp - A - Ground Floor, Accommodation Block A',
          id: '362b5494-be7c-e911-a977-000d3a2bcac2',
          name: 'Camp - A - Ground Floor',
          parent: {
            name: 'Accommodation Block A',
            level: 1,
            id: '7f09378e-be7c-e911-a97d-000d3a2bcc69',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: true,
        },
        {
          title: 'Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Camp - D - Ground Floor, Accommodation Block D',
          id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          name: 'Camp - D - Ground Floor',
          parent: {
            name: 'Accommodation Block D',
            level: 1,
            id: 'd1bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: true,
        },
        {
          title: 'Camp - B - Ground Floor, Accommodation Block B',
          searchableTitle: 'Camp - B - Ground Floor, Accommodation Block B',
          id: 'de890b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Camp - B - Ground Floor',
          parent: {
            name: 'Accommodation Block B',
            level: 1,
            id: 'e1bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: true,
        },
        {
          title: 'Camp - C - Ground Floor, Accommodation Block C',
          searchableTitle: 'Camp - C - Ground Floor, Accommodation Block C',
          id: 'e8890b93-be7c-e911-a989-000d3a2bccc5',
          name: 'Camp - C - Ground Floor',
          parent: {
            name: 'Accommodation Block C',
            level: 1,
            id: 'd7bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: true,
        },
        {
          title: 'Camp - A - External Area, Accommodation Block A',
          searchableTitle: 'Camp - A - External Area, Accommodation Block A',
          id: '664bd48e-be7c-e911-a97d-000d3a2bc547',
          name: 'Camp - A - External Area',
          parent: {
            name: 'Accommodation Block A',
            level: 1,
            id: '7f09378e-be7c-e911-a97d-000d3a2bcc69',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - First Aid',
          searchableTitle: 'Camp - First Aid',
          id: '8d09378e-be7c-e911-a97d-000d3a2bcc69',
          name: 'Camp - First Aid',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - D - External Area, Accommodation Block D',
          searchableTitle: 'Camp - D - External Area, Accommodation Block D',
          id: '75667a90-be7c-e911-a97e-000d3a2bcd97',
          name: 'Camp - D - External Area',
          parent: {
            name: 'Accommodation Block D',
            level: 1,
            id: 'd1bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - B - External Area, Accommodation Block B',
          searchableTitle: 'Camp - B - External Area, Accommodation Block B',
          id: 'ef65598e-be7c-e911-a977-000d3a2bcac2',
          name: 'Camp - B - External Area',
          parent: {
            name: 'Accommodation Block B',
            level: 1,
            id: 'e1bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Camp - C - External Area, Accommodation Block C',
          searchableTitle: 'Camp - C - External Area, Accommodation Block C',
          id: '7609378e-be7c-e911-a97d-000d3a2bcc69',
          name: 'Camp - C - External Area',
          parent: {
            name: 'Accommodation Block C',
            level: 1,
            id: 'd7bccb8c-be7c-e911-a989-000d3a2bccc5',
          },
          level: {
            name: 'Asset Level 2',
            level: 2,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Accommodation Block A',
          searchableTitle: 'Accommodation Block A',
          id: '7f09378e-be7c-e911-a97d-000d3a2bcc69',
          name: 'Accommodation Block A',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: true,
        },
        {
          title: 'Camp - Generator',
          searchableTitle: 'Camp - Generator',
          id: 'd6bccb8c-be7c-e911-a989-000d3a2bccc5',
          name: 'Camp - Generator',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Accommodation Block C',
          searchableTitle: 'Accommodation Block C',
          id: 'd7bccb8c-be7c-e911-a989-000d3a2bccc5',
          name: 'Accommodation Block C',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: true,
        },
        {
          title: 'Accommodation Block B',
          searchableTitle: 'Accommodation Block B',
          id: 'e1bccb8c-be7c-e911-a989-000d3a2bccc5',
          name: 'Accommodation Block B',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: true,
        },
        {
          title: 'Camp - Ice Room, Camp - D - Ground Floor, Accommodation Block D',
          searchableTitle: 'Camp - Ice Room, Camp - D - Ground Floor, Accommodation Block D',
          id: '5b4bd48e-be7c-e911-a97d-000d3a2bc547',
          name: 'Camp - Ice Room',
          parent: {
            name: 'Camp - D - Ground Floor',
            level: 2,
            id: '81667a90-be7c-e911-a97e-000d3a2bcd97',
          },
          level: {
            name: 'Asset Level 4',
            level: 4,
            id: null,
          },
          hasChild: false,
        },
        {
          title: 'Accommodation Block D',
          searchableTitle: 'Accommodation Block D',
          id: 'd1bccb8c-be7c-e911-a989-000d3a2bccc5',
          name: 'Accommodation Block D',
          parent: null,
          level: {
            name: 'Asset Level 1',
            level: 1,
            id: null,
          },
          hasChild: true,
        },
      ]),
  },
  createServiceRequest: {
    ok: true,
    status: 200,
    json: () => Promise.resolve(),
  },
  createUpdateMessage: {
    ok: true,
    status: 204,
    json: () => Promise.resolve(),
  },
  createComment: {
    ok: true,
    status: 204,
    json: () => Promise.resolve(),
  },
  createFeedback: {
    ok: true,
    status: 202,
    json: () => Promise.resolve(),
  },
  createComplaint: {
    ok: true,
    status: 202,
    json: () => Promise.resolve(),
  },
  getTemplates: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: 'myTemplateId',
          name: 'Architecture CAD drawing printing',
          title: 'CAD drawing printing',
          description:
            'Please print the attached drawing.\nPaper size*: Please check the paper sizes available with your print shop.',
        },
      ]),
  },
  getSiteContacts: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        data: [
          {
            contactid: '6913a943-6ea5-ea11-8b71-0003ff4cdb48',
            firstname: 'Maxwell',
            lastname: 'Tuba',
            emailaddress1: 'MaxwellTuba@myway.com',
          },
          {
            contactid: '226e4273-b8a9-ea11-8b71-0003ff4cdb48',
            firstname: 'Urszula',
            lastname: 'Frode',
            emailaddress1: 'UrszulaFrode@myway.com',
          },
          {
            contactid: '43bb39db-b9a9-ea11-8b71-0003ff4cdb48',
            firstname: 'Reetta',
            lastname: 'Iraklis',
            emailaddress1: 'ReettaIraklis@sdx.test',
          },
          {
            contactid: 'ade2568d-bca9-ea11-8b71-0003ff4cdb48',
            firstname: 'Customer Care',
            lastname: 'Customer Care',
            emailaddress1: 'custcare@sdx.test',
          },
          {
            contactid: 'bd5ce8e7-ee2b-ec11-b76a-0003ff4ccf48',
            firstname: 'Kęstas',
            lastname: 'Lyyti',
            emailaddress1: 'KęstasLyyti@gmail.com',
          },
          {
            contactid: '06f19c42-a0cd-ea11-bf22-0003ff4ccf43',
            firstname: 'Su',
            lastname: 'Giselmund',
            emailaddress1: 'SuGiselmund@myservice.com',
          },
          {
            contactid: '26e8c82d-a5bb-ec11-bea1-0003ff4cba28',
            firstname: 'Oleksandr',
            lastname: 'Tancred',
            emailaddress1: 'OleksandrTancred@myway.com',
          },
          {
            contactid: 'c56de9b6-ee2b-ec11-b76a-0003ff4ccf48',
            firstname: 'Nanaya',
            lastname: 'Sharifah',
            emailaddress1: 'Nanaya.Sharifah@sodexo.com',
          },
          {
            contactid: '03229940-b9a9-ea11-8b71-0003ff4cdb48',
            firstname: 'Elina',
            lastname: 'Abe',
            emailaddress1: 'ElinaAbe@sdx.test',
          },
          {
            contactid: '0b9d8180-acbb-ec11-bea1-0003ff4cba28',
            firstname: 'Aeson',
            lastname: 'Benedicte',
            emailaddress1: 'AesonBenedicte@myway.com',
          },
          {
            contactid: '76f5a5a5-387f-ec11-b820-0003ff4ccf43',
            firstname: 'Guro',
            lastname: 'Sif',
            emailaddress1: 'GuroSif@gmail.com',
          },
        ],
      }),
  },
  checkQrInfo: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({ site: { id: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547' } }),
  },
  getServiceRequestTypes: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: '123',
          name: 'something',
        },
        {
          id: '456',
          name: 'something else',
        },
      ]),
  },
  getServiceRequestSites: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        sites: [
          {
            id: '4aa09563-a78d-e911-a978-000d3a2bcac2',
            name: 'Amsterdam Headquarter',
            locationtype: 'Site',
            additionalInfo: {
              markdown: '#TEST\n##Test\nTESTING',
              html: '<h1 id="h1-test"><a name="TEST" class="reference-link"></a><span class="header-link octicon octicon-link"></span>TEST</h1><h2 id="h2-test"><a name="Test" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Test</h2><p>TESTING</p>',
            },
            address: {
              line1: 'a',
              line2: '',
              line3: '',
              city: '',
              postalCode: '',
              stateOrProvince: '',
              country: '',
            },
            geoCoordinates: null,
            images: [],
            siteMapImage: '1681df4a-4359-ea11-a811-000d3a25c5d6',
            selfRegistrationDuration: 4,
            currency: {
              currencyName: 'US Dollar',
              currencySymbol: '$',
              isoCode: 'USD',
              exchangeRate: 1.0,
              precision: 2,
            },
          },
          {
            id: '62b9d04a-23c6-e811-a966-000d3a2bcac2',
            name: 'Budapest Office',
            locationtype: 'Site',
            additionalInfo: {
              markdown:
                '# Welcome\nWe\'d like to welcome you to our new office in Budapest. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.\n\n## Getting to the office\nWe\'d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.\n\n**Tramway**\nNext tramway stop of line 6 is "stock exchange". From there the office is in sight.. 2 min walk time.\n\n**Bus**\nNext bus stop of line A55 is "stock exchange road". 5 minutes walk time.\n\n**Car**\nIn case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.\n\n## *Hotels*\nWe have agreed rates with several hotels in Budapest. We would recommend the following ones:\n\nhttps://boutiquehotelbudapest.com/\n\nhttps://www.accorhotels.com/en/hotel-3560-novotel-budapest-centrum/index.shtml',
              html: '<h1 id="h1-welcome"><a name="Welcome" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Welcome</h1><p>We’d like to welcome you to our new office in Budapest. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.</p>\n<h2 id="h2-getting-to-the-office"><a name="Getting to the office" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Getting to the office</h2><p>We’d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.</p>\n<p><strong>Tramway</strong><br>Next tramway stop of line 6 is “stock exchange”. From there the office is in sight.. 2 min walk time.</p>\n<p><strong>Bus</strong><br>Next bus stop of line A55 is “stock exchange road”. 5 minutes walk time.</p>\n<p><strong>Car</strong><br>In case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.</p>\n<h2 id="h2--em-hotels-em-"><a name="<em>Hotels</em>" class="reference-link"></a><span class="header-link octicon octicon-link"></span><em>Hotels</em></h2><p>We have agreed rates with several hotels in Budapest. We would recommend the following ones:</p>\n<p><a href="https://boutiquehotelbudapest.com/">https://boutiquehotelbudapest.com/</a></p>\n<p><a href="https://www.accorhotels.com/en/hotel-3560-novotel-budapest-centrum/index.shtml">https://www.accorhotels.com/en/hotel-3560-novotel-budapest-centrum/index.shtml</a></p>',
            },
            address: {
              line1: '',
              line2: 'Graphisoft park 3',
              line3: '',
              city: 'Budapest',
              postalCode: '1031',
              stateOrProvince: 'Budapest',
              country: 'Hungary',
            },
            geoCoordinates: {
              longitude: 19.04614,
              latitude: 47.57145,
            },
            images: ['d8bb4f73-23e0-e911-a812-000d3a25c7c6'],
            siteMapImage: 'f500bc84-e5b9-ea11-a812-000d3a25caae',
            selfRegistrationDuration: 4,
            currency: {
              currencyName: 'US Dollar',
              currencySymbol: '$',
              isoCode: 'USD',
              exchangeRate: 1.0,
              precision: 2,
            },
          },
          {
            additionalInfo: {
              html: '<h1 id="h1-welcome"><a name="Welcome" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Welcome</h1><p>We’d like to welcome you to our new office in FMG Hamilton. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.</p>\n<h2 id="h2-getting-to-the-office"><a name="Getting to the office" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Getting to the office</h2><p>We’d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.</p>\n<p><strong>Tramway</strong><br>Next tramway stop of line 6 is “stock exchange”. From there the office is in sight within 2 min walk time.</p>\n<p><strong>Bus</strong><br>Next bus stop of line A55 is “stock exchange road”. 5 minutes walk time.</p>\n<blockquote>\n<blockquote>\n<p><strong>Car</strong><br>In case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.</p>\n</blockquote>\n</blockquote>',
              markdown:
                '# Welcome\nWe\'d like to welcome you to our new office in FMG Hamilton.It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.\n\n## Getting to the office\nWe\'d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.\n\n**Tramway**\nNext tramway stop of line 6 is "stock exchange". From there the office is in sight within 2 min walk time.\n\n**Bus**\nNext bus stop of line A55 is "stock exchange road". 5 minutes walk time.\n> > \n**Car**\nIn case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.',
            },
            address: {
              city: 'Hamilton City',
              country: 'Canada',
              line1: '128 Seddon Road',
              line2: '',
              line3: '',
              postalCode: '128',
              stateOrProvince: 'Hamilton',
            },
            currency: {
              currencyName: 'Euro',
              currencySymbol: '€',
              exchangeRate: 1,
              isoCode: 'EUR',
              precision: 2,
            },
            geoCoordinates: { longitude: 175.267548, latitude: -37.780777 },
            id: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
            images: [],
            locationtype: 'Site',
            name: 'FMG Hamilton',
            selfRegistrationDuration: 200,
            siteMapImage: '',
          },
        ],
      }),
  },
  getServiceRequestPriorityFields: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          priority: 'High',
          targetStartDate: '2019-11-15T10:28:17Z',
          targetCompletionDate: '2019-11-16T10:28:17Z',
        },
      ]),
  },
};

export default mockResponse;
