import serviceRequestApi from './api';
import dspServiceRequestApi from './dspApi';
import gatewayServiceRequestApi from './gatewayApi';

export * from './api';
export * from './dspApi';
export * from './gatewayApi';
const apis = {
  ...serviceRequestApi,
  ...dspServiceRequestApi,
  ...gatewayServiceRequestApi,
};
export default apis;
