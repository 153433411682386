const labels = {
  All: {
    dailyMenu: 'Päivittäinen valikko',
    anytime: 'Milloin vain',
    breakfast: 'Aamiainen',
    morningBreak: 'aamutauko',
    lunch: 'Lounas',
    afternoonBreak: 'iltapäivän tauko',
    dinner: 'Illallinen',
    supper: 'Illallinen',
    nightMeal: 'Yön ateria',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Valikot',
    'Ref: View details': 'Tarkemmat tiedot',
    'Ref: Facility details': 'Laitteiden tiedot',
  },
  OpeningHours: { everyday: 'joka päivä', 'open all day': 'Avaa koko päivän' },
  ProductsList: {
    'Ref: Page title': 'Valikko',
    'Ref: Facility details': 'Laitteiden tiedot',
    'Ref: Search products list': 'Etsi kohdetta',
    'Ref: filter label: date': 'Päivämäärä',
    'Ref: filter label: menus': 'Valikko',
    'Ref: filter label: moment': 'Hetki',
    'Ref: filter label: category': 'Kategoria',
    'Ref: filter label: dishes': 'Astiat',
    'Ref: filter label: highlight': 'Korosta tai piilota suodattimia astialla',
    All: 'Kaikki',
  },
  ProductDetails: { 'Ref: Page title': 'tuotteen yksityiskohdat' },
  Widget: {
    'Ref: Carousel title': 'Valikot',
    'No info yet, come back later': 'Ei vielä tietoja, tule takaisin myöhemmin 😉',
    'Ref: menus': 'Valikot',
    'Ref: see all': 'Näytä kaikki',
  },
};
export default labels;
