import { MenuVisits } from '../../types/menu.types';
import { MenuSelectorListItem } from '../../types/menuSelector.types';

const sortMenusVisitsDescending = (menusVisits: MenuVisits[]) => {
  const sortedMenusVisits = [...menusVisits].sort((m1, m2) => {
    if (m1.visits < m2.visits) {
      return 1;
    } else if (m1.visits > m2.visits) {
      return -1;
    } else {
      return 0;
    }
  });

  return sortedMenusVisits;
};

const sortFacilitiesByMenusVisits = (
  unorderedFacilities: MenuSelectorListItem[],
  sortedMenusVisits: MenuVisits[]
) => {
  const facilitiesSortedByMenuVisits = [...unorderedFacilities].sort((facilityA, facilityB) => {
    const visitedA = sortedMenusVisits.findIndex((menu) => menu.menuId === facilityA.menu.id);
    const visitedB = sortedMenusVisits.findIndex((menu) => menu.menuId === facilityB.menu.id);
    if (visitedA === -1 && visitedB === -1) {
      return 0;
    }
    if (visitedA === -1) {
      return 1;
    }
    if (visitedB === -1) {
      return -1;
    }
    return visitedA - visitedB;
  });
  return facilitiesSortedByMenuVisits;
};

export const sortFacilitiesByMenuTypeAndVisits = (
  unorderedFacilities: MenuSelectorListItem[],
  menusVisits: MenuVisits[]
) => {
  if (!!menusVisits.length) {
    const sortedMenusVisits = sortMenusVisitsDescending(menusVisits);
    return sortFacilitiesByMenusVisits(unorderedFacilities, sortedMenusVisits);
  }

  return unorderedFacilities;
};
