import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';

import { useGetServiceRequestLightListQuery } from '../../api';
import { ServiceRequestLightListResponse } from '../../api/api.types';
import { pagePaths } from '../../config';
import { useServiceRequestLightTranslation } from '../../hooks/useServiceRequestLightTranslation';
import { FormFields } from '../../types';

import { ServiceRequestLightListItemProps } from './List.types';

import { NavArrowRightIcon } from '@/assets/icons';
import { ServiceRequestIllustration } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import { Tile, TileSkeleton } from '@/components/molecules/Tile';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import ListPage from '@/components/templates/ListPage/ListPage';
import { formatDate, formatTime } from '@/helpers/dateTime';
import {
  getTutorial,
  removeServiceRequestLight,
} from '@/modules/ServiceRequestLight/helpers/helpers';
import { State } from '@/types/state.types';

const ServiceRequestLightList = () => {
  const { label } = useServiceRequestLightTranslation(__filename);
  const history = useHistory();
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);
  const [searchString, setSearchString] = useState<string>('');
  const { data = [], isLoading } = useGetServiceRequestLightListQuery({});
  const { reset } = useFormContext<FormFields>();
  const tutorials = useMemo(() => getTutorial(), []);
  const whatsNewLastUpdate = useMemo(() => new Date('2022-04-20T10:00:00Z'), []);

  useEffect(() => {
    if (!tutorials?.lastDisplayed || tutorials.lastDisplayed < whatsNewLastUpdate) {
      history.push(pagePaths.Tutorials + '?redirectTo=' + pagePaths.Module);
    }
  }, [tutorials, history, whatsNewLastUpdate]);

  const items = data
    ? data.map((i: ServiceRequestLightListResponse) => {
        return {
          ...i,
          'data-testid': `service-request-light-list-item-${i.id}`,
          linkPath: pagePaths['Details'].replace(':id', i.id),
          searchableTitle: i.title.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        };
      })
    : [];

  const serviceRequestLightTile = (item: ServiceRequestLightListItemProps) => (
    <Tile
      id={item.id}
      key={`ServiceRequestLightTile-${item.id}`}
      title={`${item.serviceRequestNumber} - ${item.title}`}
      description={`${
        item.createDateTime
          ? `${formatDate(new Date(item.createDateTime), currentLanguageCode)} -
        ${formatTime(new Date(item.createDateTime))}`
          : ''
      }`}
      onClick={() => history?.push(item.linkPath)}
      data-testid={`ServiceRequestLightTile-${item.id}`}
      actions={[
        {
          name: `Navigate to the detail of the request ${item.title}`,
          icon: <NavArrowRightIcon />,
          onClick: () => history?.push(item.linkPath),
          'data-testid': `service-request-light-tile-${item.id}-right`,
        },
      ]}
    />
  );

  const search = {
    searchableKeys: [
      'title',
      'searchableTitle',
      'description',
      'affectedPersonFullName',
      'roomText',
      'buildingText',
      'serviceRequestNumber',
    ],
    searchString: searchString.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
    handleSearchChange: setSearchString,
  };

  const handleClick = () => {
    reset();
    removeServiceRequestLight();
    history.push(pagePaths.Create);
  };

  return (
    <ListPage
      data-testid="service-request-light-list"
      hasBackLink={false}
      title={label('Ref: Service Requests')}
      items={items}
      isLoading={isLoading}
      search={search}
      asideFirst
      aside={
        <Column.Complementary>
          <ServiceRequestIllustration />
        </Column.Complementary>
      }
      actions={
        <ActionsBar>
          <Button onClick={handleClick} data-testid="service-request-light-create-button">
            {label('Ref: Request Service')}
          </Button>
        </ActionsBar>
      }
      itemRenderer={(item: ServiceRequestLightListItemProps) => serviceRequestLightTile(item)}
    >
      {isLoading &&
        Array.apply(null, Array(10)).map(() => (
          <TileSkeleton key={`${uuid()}-skeleton`} withoutActions withoutImage />
        ))}
    </ListPage>
  );
};

export default ServiceRequestLightList;
