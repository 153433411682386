import { Camera } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import prettyBites from 'pretty-bytes';
import React, { useCallback, useEffect, useState } from 'react';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Button from '../../atoms/Button/Button';
import List from '../List/List';
import Modal from '../Modal';

import { FileUploaderProps } from './FileUploader.types';

import { UploadIcon, ChevronRightIcon } from '@/assets/icons';
import toast from '@/components/atoms/ModalToast';
import useToggle from '@/helpers/hooks/useToggle';
import ImageOptimizationService from '@/services/ImageOptimizationService';

import styles from './FileUploader.module.css';

const FileUploader = ({
  fileInputRef,
  onFileSelectSuccess,
  onFileSelectError,
  maxFileSize,
  maxTotalFileSize,
  totalFilesSize,
  children,
  imageOptimizationOptions,
  config,
}: FileUploaderProps) => {
  const { label } = useCustomTranslation();
  const { state: isModalOpen, toggleOff: closeModal, toggleOn: openModal } = useToggle();
  const [mediaType, setMediaType] = useState<string>('');

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      e.currentTarget.click();
    }
  };

  const validateFile = async (file: File) => {
    if (imageOptimizationOptions && file.size > maxFileSize && file.type.startsWith('image/')) {
      try {
        const optimizedFile = await new ImageOptimizationService(
          imageOptimizationOptions
        ).applyMaxFileSize(file, maxFileSize, imageOptimizationOptions.maxWidthOrHeight);
        onFileSelectSuccess(optimizedFile);
      } catch (error) {
        onFileSelectError({
          error: label('File size cannot exceed {max file size}', {
            replace: { 'max file size': prettyBites(maxFileSize) },
          }),
        });
      }
    } else {
      if (file.size > maxFileSize) {
        onFileSelectError({
          error: label('File size cannot exceed {max file size}', {
            replace: { 'max file size': prettyBites(maxFileSize) },
          }),
        });
      } else {
        //tmp multilanguage
        onFileSelectSuccess(file);
      }
    }
  };

  const handleFileInput: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (!e.target?.files) {
      return;
    }
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (maxTotalFileSize && totalFilesSize) {
      const totalSizeAfterUpload = totalFilesSize + file.size;
      if (totalSizeAfterUpload > maxTotalFileSize) {
        onFileSelectError({
          error: label('Ref: Total file size msg', {
            replace: { 'max file size': prettyBites(maxTotalFileSize) },
          }),
        });
      } else {
        await validateFile(file);
      }
    } else {
      await validateFile(file);
    }

    closeModal();
  };

  const handleOpenCamera = async () => {
    const permissions = await Camera.requestPermissions({ permissions: ['camera'] });

    if (permissions.camera === 'denied') {
      toast.error(label('Access to camera was denied. Please allow access and try again'), {
        toastId: 'denied-camera-error-toast',
      });
      return;
    }

    setMediaType('photo');
  };

  const handleUploadFile = useCallback(() => {
    if (['web', 'ios'].includes(Capacitor.getPlatform())) {
      fileInputRef.current?.click();
      return;
    }
    openModal();
  }, [fileInputRef, openModal]);

  useEffect(() => {
    if (mediaType) {
      fileInputRef.current?.click();

      const timeoutId = setTimeout(() => {
        setMediaType('');
      });

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [fileInputRef, mediaType]);

  return (
    <>
      <div className="fileUploaderWrapper">
        <input
          data-testid="file-input"
          ref={fileInputRef}
          type="file"
          onChange={handleFileInput}
          style={{ display: 'none' }}
          {...(mediaType === 'photo' && {
            accept: 'image/*',
            capture: 'camera' as any,
          })}
        />

        {children ? (
          <div onClick={handleUploadFile} role="button" tabIndex={0} onKeyDown={handleKeyDown}>
            {children}
          </div>
        ) : (
          <Button
            look="tertiary"
            onClick={handleUploadFile}
            suffix={config?.addButonIcon ?? UploadIcon}
            data-testid="add-attachment-button"
          >
            {config?.addButtonLabel ?? label('Add attachment')}
          </Button>
        )}
      </div>
      <Modal
        contentClassName={styles.modal}
        isOpen={isModalOpen}
        onDismiss={closeModal}
        title={label('Add an attachment')}
        data-testid="add-attachment-modal"
      >
        <List
          data-testid="file-uploader-list"
          items={[
            {
              id: 'photo',
              label: label('Take a picture'),
              'data-testid': 'take-a-picture-item',
              icons: [
                {
                  icon: ChevronRightIcon,
                  endIcon: true,
                },
              ],
              action: handleOpenCamera,
            },
            {
              id: 'file',
              'data-testid': 'add-a-file-from-your-device-item',
              label: label('Add a file from your device'),
              icons: [
                {
                  icon: ChevronRightIcon,
                  endIcon: true,
                },
              ],
              action: () => {
                setMediaType('file');
              },
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default FileUploader;
