import { childrenPageDetailPath } from '../config';
import { ChildrenPageResponseItem } from '../types';

const getChildrenPageUrl = (
  navItem: string,
  childrenPage: Omit<ChildrenPageResponseItem, 'contentImageId' | 'description'>
) => {
  const { name, id } = childrenPage;
  const encodedName = encodeURI(name);

  return childrenPageDetailPath
    .replace(':navItemName', navItem)
    .replace(':title', encodedName)
    .replace(':id', id);
};

export default getChildrenPageUrl;
