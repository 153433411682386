const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Ellenőrzések',
    'Ref: status0': 'Nyisd ki',
    'Ref: status1': 'Befejezett',
    'Ref: status2': 'Törölve',
    'Ref: connected': 'csatlakoztatva',
    'Ref: not connected': 'Nem kapcsolódik',
    'Ref: synchronise data': 'Az adatok szinkronizálása',
    'Ref: sync success': 'Az adatok szinkronizáltak sikeresen',
    'Ref: Create new': 'Újat készíteni',
    'Ref: Audit unsynchronised': 'Szinkronizálatlan',
    'Ref: Audit synchronised': 'Szinkronizált',
    'Ref: Unsynced error title': 'Hiba történt',
    'Ref: Unsynced error description':
      'Úgy tűnik, hogy ez az első alkalom, hogy hozzáfér az ellenőrzési modulhoz. Kérjük, csatlakozzon a hálózathoz és a „szinkronizáláshoz” az ellenőrzés megkezdéséhez.',
    'Ref: Search all audits': 'Minden ellenőrzés',
    'Ref: Search active audits': 'Nyisd ki',
    'Ref: Search completed audits': 'Befejezett',
    'Ref: sync error': 'A szinkronizálás sikertelen',
    'Ref: sync error details': 'Újra újracsatlakozáshoz',
    'Ref: no search audit results': 'Sajnos nincsenek a keresésnek megfelelő ellenőrzések',
    'Ref: no audit results': 'Nincs elérhető ellenőrzés, új új létrehozáshoz az induláshoz',
    'Ref: Search canceled audits': 'Törölve',
    'Ref: Audit cancelled successfully': 'Az ellenőrzés sikeresen lemondott',
    'Ref: Cancel audit invite': 'Megszünteti',
    'Ref: Error audit cancel': 'Az ellenőrzést nem sikerült törölni',
    'Ref: Cancel audit confirmation': 'Biztosan meg akarja törölni az ellenőrzést?',
    'Ref: Confirm': 'megerősít',
    'Ref: Cancel audit': 'Az ellenőrzés törlése',
    'Ref: Change the location': 'Változtassa meg a helyet',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Ellenőrzési részletek',
    'Ref: questions': 'kérdés',
    'Ref: question': 'kérdés',
    'Ref: Additional Comments': 'Leírás',
    'Ref: Not found title': 'Hoppá!',
    'Ref: Not found text': 'A hozzáférésű ellenőrzés nem létezik.',
    'Ref: Back to Audit list': 'Vissza az ellenőrzési listához',
    'Ref: Success title': 'Köszönöm!',
    'Ref: Success text': 'Befejezte az ellenőrzést',
    'Ref: submit': 'Beküldés',
    'Ref: add attachment': 'Adjon hozzá egy mellékletet',
    'Ref: Unsupported elements':
      'Sajnálom, úgy tűnik, hogy ennek az ellenőrzésnek tartalma van, amelyet az alkalmazás még nem támogat. Dolgozunk rajta.',
    'Ref: form error': 'Nem minden kérdésre válaszoltak teljesen',
    'Ref: form complete': 'Azóta befejeződött',
    'Ref: attachment preview error': 'Online kell lennie, hogy megnézze ezt a mellékletet',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Újat készíteni',
    'Ref: Location': 'Elhelyezkedés',
    'Ref: Audit Stream Type': 'Folyam',
    'Ref: Audit': 'Könyvvizsgálat',
    'Ref: Asset': 'Eszköz',
    'Ref: Asset Name': 'Eszköznév',
    'Ref: Is joint audit?': 'A közös ellenőrzés?',
    'Ref: Who was present?': 'Ki volt jelen?',
    'Ref: submit': 'Beküldés',
    'Ref: Select the Location': 'Válassza ki a helyet',
    'Ref: Select the Audit Stream Type': 'Válassza ki az ellenőrzési stream típust',
    'Ref: Select the Audit': 'Válassza ki az ellenőrzést',
    'Ref: Select the Asset': 'Válassza ki az eszközt',
    'Ref: form error': 'Nem minden szükséges mezőt adtak meg teljesen',
    'Ref: Audit error': 'Csatolt ellenőrzés',
    'Ref: Joint Audit Participants error': 'Közös ellenőrzés résztvevői',
    'Ref: Audit created': 'A létrehozott ellenőrzés',
    'Ref: Audit field length error': 'A hosszúság nem haladhatja meg a 100 karaktert',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Változtassa meg a helyet',
    'Ref: Location changed': 'A hely megváltozott',
    'Ref: Location': 'Elhelyezkedés',
    'Ref: Select the Location': 'Válassza ki a helyet',
    'Ref: Asset': 'Eszköz',
    'Ref: Select the Asset': 'Válassza ki az eszközt',
    'Ref: Asset Name': 'Eszköznév',
    'Ref: submit': 'Beküldés',
    'Ref: Audit field length error': 'A hosszúság nem haladhatja meg a 100 karaktert',
  },
};
export default labels;
