const labels = {
  All: {
    dailyMenu: 'Daglig meny',
    anytime: 'När som helst',
    breakfast: 'Frukost',
    morningBreak: 'morgonrast',
    lunch: 'Lunch',
    afternoonBreak: 'eftermiddagspaus',
    dinner: 'Middag',
    supper: 'Kvällsmat',
    nightMeal: 'Nattmåltid',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menyer',
    'Ref: View details': 'Visa detaljer',
    'Ref: Facility details': 'Anläggningsinformation',
  },
  OpeningHours: { everyday: 'varje dag', 'open all day': 'öppna hela dagen' },
  ProductsList: {
    'Ref: Page title': 'Meny',
    'Ref: Facility details': 'Anläggningsinformation',
    'Ref: Search products list': 'Sökartiklar',
    'Ref: filter label: date': 'Datum',
    'Ref: filter label: menus': 'Meny',
    'Ref: filter label: moment': 'Ögonblick',
    'Ref: filter label: category': 'Kategori',
    'Ref: filter label: dishes': 'Maträtter',
    'Ref: filter label: highlight': 'Markera eller dölj filter med diskar',
    All: 'Allt',
  },
  ProductDetails: { 'Ref: Page title': 'Produktinformation' },
  Widget: {
    'Ref: Carousel title': 'Menyer',
    'No info yet, come back later': 'Ingen info ännu, kom tillbaka senare 😉',
    'Ref: menus': 'Menyer',
    'Ref: see all': 'Se allt',
  },
};
export default labels;
