import { FC, MouseEvent, useState } from 'react';

import { StarActionProps } from './StarAction.types';

import { StarFilledIcon, StarOutlinedIcon } from '@/assets/index';

import styles from './StarAction.module.css';

const StarAction: FC<StarActionProps> = ({ isSelected, title, onClick, 'data-testid': testId }) => {
  const [liveMessage, setLiveMessage] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (onClick) {
      onClick()
    }
    setLiveMessage(title);
    setHasChanged(true);
  };

  const handleFocus = () => {
    setLiveMessage(title);
  };

  return (
    <>
      <button
        onClick={handleClick}
        onFocus={handleFocus}
        data-testid={testId}
        className={styles.button}
        aria-label={title}
        aria-pressed={isSelected ? 'true' : 'false'}
      >
        {isSelected ? (
          <StarFilledIcon
            className={styles.icon}
            data-testid={`${testId}-filled-icon`}
          />
        ) : (
          <StarOutlinedIcon
            className={styles.icon}
            data-testid={`${testId}-icon`}
          />
        )}
      </button>
      {!hasChanged && (
        <div aria-live="polite" className="sr-only"  aria-pressed={isSelected ? 'true' : 'false'}>
          {liveMessage}
        </div>
      )}
    </>
  );
};

export default StarAction;
