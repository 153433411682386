const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Аудиты',
    'Ref: status0': 'Открыть',
    'Ref: status1': 'Завершенный',
    'Ref: status2': 'Отменен',
    'Ref: connected': 'Связанный',
    'Ref: not connected': 'Не подключен',
    'Ref: synchronise data': 'Синхронизировать данные',
    'Ref: sync success': 'Данные синхронизированы успешно',
    'Ref: Create new': 'Создавать новое',
    'Ref: Audit unsynchronised': 'Унсинночено',
    'Ref: Audit synchronised': 'Синхронизированный',
    'Ref: Unsynced error title': 'произошла ошибка',
    'Ref: Unsynced error description':
      'Похоже, что это ваш первый раз, когда доступ к модулю аудитов. Пожалуйста, подключитесь к сети и «синхронизировать», чтобы начать аудит.',
    'Ref: Search all audits': 'Все аудиты',
    'Ref: Search active audits': 'Открыть',
    'Ref: Search completed audits': 'Завершенный',
    'Ref: sync error': 'Синхронизация не удалась',
    'Ref: sync error details': 'Повторно воссоединить',
    'Ref: no search audit results': 'К сожалению, нет аудитов, соответствующих вашему поиску',
    'Ref: no audit results': 'У вас нет доступных аудитов, создайте новое, чтобы начать работу',
    'Ref: Search canceled audits': 'Отменен',
    'Ref: Audit cancelled successfully': 'Аудит успешно отменен',
    'Ref: Cancel audit invite': 'Отмена',
    'Ref: Error audit cancel': 'Аудит не удалось отменить',
    'Ref: Cancel audit confirmation': 'Вы уверены, что хотите отменить аудит?',
    'Ref: Confirm': 'Подтверждать',
    'Ref: Cancel audit': 'Отменить аудит',
    'Ref: Change the location': 'Изменить местоположение',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Данные аудита',
    'Ref: questions': 'вопросы',
    'Ref: question': 'вопрос',
    'Ref: Additional Comments': 'Описание',
    'Ref: Not found title': 'Уп!',
    'Ref: Not found text': 'Аудит, к которому вы пытаетесь получить доступ, не существует.',
    'Ref: Back to Audit list': 'Вернуться в список аудита',
    'Ref: Success title': 'Спасибо!',
    'Ref: Success text': 'Вы завершили аудит',
    'Ref: submit': 'Представлять на рассмотрение',
    'Ref: add attachment': 'Добавьте вложение',
    'Ref: Unsupported elements':
      'Извините, кажется, что у этого аудита есть контент, который приложение еще не поддерживает. Мы будем работать над этим.',
    'Ref: form error': 'Не все вопросы были полностью отвечены',
    'Ref: form complete': 'Завершено с тех пор',
    'Ref: attachment preview error': 'Вы должны быть в сети, чтобы увидеть это вложение',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Создавать новое',
    'Ref: Location': 'Расположение',
    'Ref: Audit Stream Type': 'Транслировать',
    'Ref: Audit': 'Аудитор',
    'Ref: Asset': 'Объект',
    'Ref: Asset Name': 'Название актива',
    'Ref: Is joint audit?': 'Совместный аудит?',
    'Ref: Who was present?': 'Кто присутствовал?',
    'Ref: submit': 'Представлять на рассмотрение',
    'Ref: Select the Location': 'Выберите местоположение',
    'Ref: Select the Audit Stream Type': 'Выберите тип потока аудита',
    'Ref: Select the Audit': 'Выберите аудит',
    'Ref: Select the Asset': 'Выберите актив',
    'Ref: form error': 'Не все необходимые поля были полностью введены',
    'Ref: Audit error': 'Прилагаемый аудит',
    'Ref: Joint Audit Participants error': 'Участники совместного аудита',
    'Ref: Audit created': 'Аудит создан',
    'Ref: Audit field length error': 'Длина не должна превышать 100 символов',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Изменить местоположение',
    'Ref: Location changed': 'Расположение изменилось',
    'Ref: Location': 'Расположение',
    'Ref: Select the Location': 'Выберите местоположение',
    'Ref: Asset': 'Объект',
    'Ref: Select the Asset': 'Выберите актив',
    'Ref: Asset Name': 'Название актива',
    'Ref: submit': 'Представлять на рассмотрение',
    'Ref: Audit field length error': 'Длина не должна превышать 100 символов',
  },
};
export default labels;
