import classNames from 'classnames';

import { HintProps } from './Hint.types';

import styles from './Hint.module.css';

const Hint = ({
  text,
  className,
  'data-cy': dataCy = 'hint-message',
  'data-testid': dataTestId,
}: HintProps) => {
  return (
    <div className={classNames(styles.hint, className)} data-testid={`${dataTestId}-hint-message`} data-cy={dataCy}>
      {text}
    </div>
  );
};

export default Hint;
