import { getImagesSrcFromIds } from '../../../helpers/images/images.helper';
import { FoodFacility } from '../components/FoodFacilitiesList';

export const fetchFacilityImages = async (facilityItems: FoodFacility[]) => {
  const imageIds = facilityItems.filter((item) => !!item.images?.[0]).map((item) => item.images[0]);
  const images = await getImagesSrcFromIds(imageIds);
  const imagesResult = facilityItems
    .map((item) => {
      const image = images.find((img) => img.id === item.images?.[0]);
      return {
        facilityId: item.id,
        image: image?.content,
      };
    })
    .filter((imageInfo) => !!imageInfo.image)
    .map((imageInfo) => ({ key: imageInfo.facilityId, image: imageInfo.image! }));

  return imagesResult;
};
