const labels = {
  All: {
    Feedback: 'משוב',
    'Ref: Switch site component intro sentence': 'המשוב שלך יהיה מקושר לאתר {site name}.',
    Category: 'קטגוריה',
    Subject: 'נושא',
    Comment: 'תגובה',
    'Date of your experience': 'תאריך החוויה שלך *',
    'Ref: Hint': 'האם יש לך בעיה או עבודה שצריך לתקן?',
    'Ref: Log Request': 'רשום בקשה',
    'Ref: Describe issue': 'אנא ספר לנו על החוויה שלך',
    Date: 'תַאֲרִיך',
    'Ref: Share your opinion header': 'שתף את דעתך על הניסיון שלך בעבודה',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'מסעדה / בית קפה',
    'Desk / workstation area': 'אזור שולחן עבודה / תחנת עבודה',
    'Meeting room facilities': 'מתקני חדר ישיבות',
    'Coffee/tea facilities (pantry)': 'מתקני קפה/תה (מזווה)',
    'Restrooms / washrooms': 'שירותים / חדרי רחצה',
    'Reception / Security': 'קבלת פנים / אבטחה',
    'Conference center': 'מרכז כנסים',
    'Fitness center': 'חדר כושר',
    Mailroom: 'חדר דואר',
    'Bicycle room': 'חדר אופניים',
    'Shuttle services': 'שירותי הסעות',
    Lockers: 'ארונות',
    Helpdesk: 'דלפק העזרה',
  },
  Create: {
    'Tell us!': 'תגיד לנו!',
    'Ref: All Ears': 'כולנו אוזניים!',
    'Ref: Provide Description': 'אנא ספק תיאור',
    'Ref: Fill-in-the-blank sentence': 'אני רוצה לספק {0} לגבי {1}',
    'Ref: Link text to service request module': 'בקשה או בעיה? התחבר אותו כאן!',
    'Complaint type': "תלונה {אמוג'י}",
    'Feedback type': 'משוב {emoji}',
    'Compliment type': 'מחמאה {emoji}',
  },
  CreateFailure: {
    'Ref: Body': 'אירעה שגיאה והמשוב שלך לא הוגש. אם שגיאה זו נמשכת, צור קשר עם התמיכה שלך.',
  },
  CreateSuccess: {
    'Ref: Body':
      'המשוב שלך הוגש. צוות התמיכה שלנו במקום העבודה יקרא אותו בזהירות. אם מתאים, נחזור אליך.',
    'New feedback': 'משוב חדש',
  },
  CreateFlow: {
    Description: 'תיאור',
    'Ref: Provide Description': 'אנא ספק תאריך ותיאור',
    'Ref: I would like to provide a': 'ברצוני לספק א',
    'Ref: in regards to': 'ביחס ל',
    'Ref: &': '& &',
    'Ref: at': 'בְּ-',
    'Ref: on the': 'על',
  },
};
export default labels;
