import { Html5Qrcode } from 'html5-qrcode';
import { CameraDevice } from 'html5-qrcode/camera/core';
import { Html5QrcodeError, Html5QrcodeResult } from 'html5-qrcode/esm/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Modal from '../../organisms/Modal';

import { selectPreferedCamera } from './helpers/camera.helper';
import { QRCodeReaderResponse } from './reader.types';

import styles from './QrCodeReader.module.css';

const ID_SCANNER = 'html5qr-code-full-region';

const useOnScreen = () => {
  const [isOnScreen, setIsOnScreen] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsOnScreen(entry.isIntersecting);
      }),
    []
  );

  const actualRef = useRef<HTMLDivElement>();
  const refOnScreen = useCallback(
    (node: HTMLDivElement) => {
      if (actualRef.current) {
        observer.unobserve(actualRef.current);
      }

      if (node !== null) {
        observer.observe(node);
      }

      actualRef.current = node;
    },
    [observer]
  );

  return { refOnScreen, isOnScreen };
};

type BrowserQrCodeProps = {
  title: string;
  isModalOpen: boolean;
  hideModal: () => void;
  onQrCodeScan: (response: QRCodeReaderResponse) => void;
  preventCloseAfterScan?: boolean;
  actions: React.ReactNode[];
  timeBetweenScans?: number;
  children?: React.ReactNode;
};

let scanner: Html5Qrcode | null = null;

const BrowserQrCode = ({
  title,
  isModalOpen,
  hideModal,
  onQrCodeScan,
  actions,
  preventCloseAfterScan,
  timeBetweenScans,
  children,
}: BrowserQrCodeProps) => {
  const scanRef = useRef(false);
  const lastScanTime = useRef(new Date(1900, 1, 1));
  const scannerStarted = useRef(false);

  const { refOnScreen, isOnScreen } = useOnScreen();
  const [isScannerStarted, setScannerStarted] = useState(false);

  const cleanScanner = useCallback(async () => {
    try {
      setScannerStarted(false);
      await scanner?.stop();
      scannerStarted.current = false;
      scanner = null;
    } catch (error) {}
  }, []);

  const onErrorScan = (errorMessage: string, error: Html5QrcodeError) => {};

  const onSuccesScan = useCallback(
    (decodedText: string, decodedResult: Html5QrcodeResult) => {
      const timeDiffBetweenScans = new Date().getTime() - lastScanTime.current.getTime();
      const isNextScanReady =
        (timeBetweenScans && timeDiffBetweenScans > timeBetweenScans) || !timeBetweenScans;

      if (Boolean(decodedText) && isNextScanReady) {
        if (!scanRef.current) onQrCodeScan({ response: decodedText });
        lastScanTime.current = new Date();

        if (!preventCloseAfterScan) {
          hideModal();
          setScannerStarted(false);
          scanner?.clear();
        }
      }
    },
    [hideModal, onQrCodeScan, preventCloseAfterScan, timeBetweenScans]
  );

  useEffect(() => {
    if (isModalOpen && isOnScreen && !scanner?.getState()) {
      const setScanner = async () => {
        let devices: CameraDevice[] = [];

        try {
          devices = await Html5Qrcode.getCameras();
        } catch (error) {
          return;
        }

        if (devices && devices.length > 0 && !scannerStarted.current) {
          scanner = new Html5Qrcode(ID_SCANNER);
          scannerStarted.current = true;
          const backCamera = selectPreferedCamera(devices);

          const selectedCamera = backCamera ?? devices[0];
          await scanner.start(
            selectedCamera.id,
            {
              fps: 3,
            },
            onSuccesScan,
            onErrorScan
          );
          setScannerStarted(true);
        }
      };

      setScanner();
    }
  }, [isModalOpen, isOnScreen, onSuccesScan, cleanScanner]);

  useEffect(() => {
    return () => {
      cleanScanner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      cleanScanner();
    }
  }, [isModalOpen, cleanScanner, isScannerStarted]);

  return (
    <Modal
      title={title}
      isOpen={isModalOpen}
      onDismiss={hideModal}
      footer={
        actions.length > 0 ? <div className={styles.actionsContainerBrowser}>{actions}</div> : null
      }
    >
      <div className={styles.containerScanner} data-testid="browser-qr-code-container">
        <div id={ID_SCANNER} className={styles.browserScanner} ref={refOnScreen} />
        {children && <div>{children}</div>}
      </div>
    </Modal>
  );
};

export default BrowserQrCode;
