import { TranslationProps } from '../../../../localization/localization.types';

export type FacilityContact = {
  phone?: string;
  email?: string;
};

export type FacilityContactProps = {
  facility: FacilityContact;
} & TranslationProps;

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
