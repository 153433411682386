import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { useEffect, useState, useRef, useCallback } from 'react';

import { useCoreTranslation } from '../hooks/useCoreTranslation';

import toast from '@/components/atoms/ModalToast';

const useNetworkStatusListener = () => {
  const toastId = useRef<number | string | null>(null);
  const [connected, setConnected] = useState<boolean>(true);
  const [prevConnected, setPrevConnected] = useState<boolean>(true);

  const { label } = useCoreTranslation(__filename);

  const networkDisconnected = (errorLabel: string) =>
    (toastId.current = toast.error(errorLabel, {
      position: 'top-center',
      autoClose: false,
      closeOnClick: true,
      draggable: false,
      closeButton: false,
    }));

  const networkReconnected = (successLabel: string) =>
    toast.success(successLabel, {
      position: 'top-center',
      closeOnClick: true,
      draggable: false,
      closeButton: false,
    });

  const addListener = useCallback(
    () =>
      Network.addListener('networkStatusChange', (status) => {
        setConnected(status.connected);
      }),
    []
  );

  const removeListener = useCallback(
    () =>
      Network.removeAllListeners().catch((err) =>
        console.error('Network remove listener err:', err)
      ),
    []
  );

  const visibilityStatusChangeHandler = useCallback(() => {
    if (document.hidden) {
      removeListener();
    } else {
      addListener();
    }
  }, [addListener, removeListener]);

  useEffect(() => {
    addListener();

    if (Capacitor.getPlatform() === 'web') {
      document.addEventListener('visibilitychange', visibilityStatusChangeHandler);
    }

    return () => {
      if (Capacitor.getPlatform() === 'web') {
        document.removeEventListener('visibilitychange', visibilityStatusChangeHandler);
      }
      removeListener();
    };
  }, [addListener, removeListener, visibilityStatusChangeHandler]);

  useEffect(() => {
    if (prevConnected !== connected) {
      if (toastId?.current) toast.dismiss(toastId.current);
      if (connected) {
        networkReconnected(label('ConnectionRestored'));
      } else {
        networkDisconnected(label('ConnectionLost'));
      }
      setPrevConnected(connected);
    }
  }, [connected, prevConnected, label]);

  return null;
};

export default useNetworkStatusListener;
