const labels = {
  ContactForm: {
    'Ref: Page title': 'Kontaktieren Sie uns',
    'Ref: Category field value': 'Kontaktieren Sie uns',
  },
  ContactList: {
    'Ref: Page title': 'Kontakte',
    'call (action to place a call)': 'Anruf',
    'write (action to write a message)': 'Schreiben',
    'write (action to write an e-mail)': 'Email',
    'Ref: Hint message':
      'Bitte beachten Sie, dass einige Standorte über keine Rezeption verfügen. Sollte dies der Fall sein, nutzen Sie bitte eines der Festnetztelefone, die auf dem Gelände zur Verfügung stehen.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Es ist ein Fehler aufgetreten und Ihre Nachricht wurde nicht übermittelt. Wenn dieser Fehler weiterhin besteht, wenden Sie sich bitte an Ihren Beratungsstelle.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success':
      'Ihre Nachricht wurde übermittelt. Vielen Dank, dass Sie mit uns Kontakt aufgenommen haben.',
  },
};
export default labels;
