const labels = {
  All: {
    'starts at': '시작',
    'ends at': '끝까지 끝납니다',
    location: '위치',
    'coming date': '오는 날짜',
    'Ref: Currently event not preferred. Click to make preferred':
      '현재 이벤트가 선호되지 않습니다. 선호하려면 클릭하십시오',
    'Ref: Currently event preferred. Click to make not preferred':
      '현재 이벤트가 선호됩니다. 선호하지 않으려면 클릭하십시오',
  },
  EventDetails: {
    details: '세부',
    'Ref: Page title': '이벤트 세부 사항',
    'Event dates': '이벤트 날짜',
    'See online:': '온라인보기 :',
    'See all dates': '모든 날짜를보십시오',
    'Remove from my selection': '내 선택에서 제거하십시오',
    'Add to my selection': '내 선호하는 것에 추가하십시오',
    'Ref: explain calendar usage':
      '이벤트 날짜는 아래 캘린더에서 강조 표시됩니다. 당신이 좋아하는 사건을 놓치지 마십시오! 일정에서 이벤트 날짜를 클릭하여 초대장을 다운로드하십시오. 그런 다음 다운로드 한 파일을 열어 이벤트를 Preffered 캘린더에 추가하십시오.',
    'Ref: Favorite': '가장 좋아하는',
    'Ref:Feedback': '피드백',
    'Ref: Event site different title': '이 이벤트는 현재 사이트에 속하지 않습니다',
    'Ref: Event site different description':
      '현재 사이트에 속하지 않는 이벤트를보고 있습니다. 이벤트 목록에서 볼 수 없습니다.',
    'Ref: See all dates': '모든 날짜를 참조하십시오',
    'Ref: From': '에서',
    'Ref: to': '에게',
    'Ref: Coming date': '다가오는 날짜',
    'Ref: Add to calendar': '달력에 추가하십시오',
  },
  EventsList: {
    'Ref: Page title': '이벤트',
    'All dates': '모든 날짜',
    'All events': '모든 이벤트',
    'Ref: Search': '검색',
    'My selection': '내 선호',
    'filter label: date': '날짜',
    'filter label: coming events': '다가오는 이벤트',
    'filter label: past events': '과거 사건들',
    'filter label: interest': '선호',
  },
  Widget: {
    'Ref: Carousel title': '이벤트',
    'No info yet, come back later': '아직 정보가없고 나중에 다시 오십시오 😉',
  },
};
export default labels;
