const labels = {
  All: {
    'status: all': 'ทั้งหมด',
    'status: open': 'เปิด',
    'status: pending': 'รอดำเนินการ',
    'status: closed': 'ปิด',
    Location: 'สถานที่ตั้ง',
    Subject: 'ชื่อเรื่อง',
    Description: 'อธิบายรายการคำขอของคุณ',
    'Ref: description field label': 'คำอธิบาย',
    'Ref: Title': 'ชื่อ',
    phone_number: 'หมายเลขโทรศัพท์ของคุณ',
    request_category: 'หมวดหมู่คำขอ',
    Attachments: 'สิ่งที่แนบมา',
    'request service': 'คำขอบริการ',
    'New service request': 'สร้างคำขอบริการ',
    'Go to home': 'กลับไปที่หน้าแรก',
    'Back to this service request': 'กลับไปที่คำขอบริการนี้',
    'Back to service requests': 'กลับไปที่หน้าแรก',
    Feedback: 'ความคิดเห็น',
    'Ref: Request category': 'หมวดหมู่',
    Cleaning: 'การทำความสะอาด',
    'Laundry services': 'บริการซักรีด',
    'Grounds maintenance': 'การบำรุงรักษาพื้นที่',
    'Soft services': 'งานซอฟท์เซอร์วิส',
    'Hard services': 'งานฮาร์ดเซอร์วิส',
    'Pest control services': 'บริการควบคุมศัตรูพืช',
    'Waste management': 'การจัดการของเสีย',
    'Asset Lifecycle Services': 'การจัดการวงจรชีวิตของสินทรัพย์',
    'There are no updates for this request': 'ไม่มีการอัปเดตสำหรับคำขอนี้',
    'Transportation Services': 'บริการขนส่ง',
    'Ref: you pronoun': '(คุณ)',
    'Ref: SR raised for': 'ผู้ได้รับผลกระทบจากคำขอนี้',
    'Ref: Select a location': 'เลือกตำแหน่ง',
    'Ref: Your request': 'คำขอร้องของคุณ',
    'Ref: Summary': 'สรุป',
  },
  Comment: {
    'Ref: Page title': 'หัวข้อหลัก',
    'Ref: please update us with details': 'กรุณาเพิ่มเติมรายละเอียด',
  },
  Confirm: {
    'Ref: Page title': 'หัวข้อหลัก',
    'Ref: next': 'ส่ง',
    summary: 'ตรวจสอบคำขอของคุณ',
    'Ref: Save as my preferred location': 'บันทึกเป็นตำแหน่งที่ฉันต้องการ',
  },
  Describe: {
    'Ref: Page title': 'อธิบายคำขอของคุณ',
    'Ref: Required fields: header': 'โปรดให้ข้อมูลเพิ่มเติม',
    'Ref: Required fields': 'ข้อมูลต่อไปนี้ควรให้ภายในฟิลด์รายละเอียด:',
    'Ref: Required field: title': 'ต้องระบุชื่อ',
    'Ref: Required field: description': 'โปรดระบุรายละเอียด',
    'Ref: Required field: phone': 'โปรดระบุหมายเลขโทรศัพท์',
    'Ref: Required field: category': 'โปรดระบุหมวดหมู่',
    'Ref: Invalid field: category': 'หมวดหมู่ที่ให้ไม่ถูกต้อง',
    'Ref: Request template field label': 'คำขอยอดนิยม',
    'Ref: Request template field placeholder': 'แผ่นงานเปล่า',
    your_request: 'คำขอร้องของคุณ',
    'Ref: Description message':
      'ยิ่งคุณมีรายละเอียดมากเท่าไหร่เราก็ยิ่งสามารถสนับสนุนคุณได้ดีขึ้นเท่านั้น',
    phone_number: 'หมายเลขโทรศัพท์ของคุณ (โปรดใช้โทรศัพท์มืออาชีพของคุณหากคุณมี)',
    'Ref: Affected person': 'ผู้ได้รับผลกระทบจากคำขอนี้',
    'Ref: No affected person results': 'ไม่ตรงกับเกณฑ์ที่ต้องการ',
    'Ref: Notification title': 'คุณได้เปลี่ยนสถานที่',
    'Ref: Notification info': 'ตอนนี้คุณอยู่ที่ {ชื่อไซต์}',
    'Ref: Add photos': 'เพิ่มรูปถ่าย',
    'Ref: Your location': 'ตำแหน่งของคุณ',
  },
  Details: {
    'Ref: Page title': 'ขอรายละเอียด',
    Details: 'รายละเอียด',
    Updates: 'การอัปเดต',
    'Update us': 'อัพเดทเรา',
    'Ref: timeline': 'ระยะเวลา',
    'Ref: attachments': 'สิ่งที่แนบมา',
    'Ref: location': 'สถานที่ตั้ง',
    'Ref: createdOn': 'สร้าง',
    'Ref: reference': 'อ้างอิง',
    'Feedback was submitted': 'ส่งการประเมินผลแล้ว',
    'Ref: sent': 'ส่งแล้ว',
    'Ref: received': 'ได้รับ',
    'Ref: inProgress': 'กำลังดำเนินการ',
    'Ref: completed': 'สมบูรณ์',
    'Ref: conversation': 'การสนทนา',
    'Ref: Priority': 'ลำดับความสำคัญ',
    'Ref: Target start date': 'วันที่เริ่มต้นเป้าหมาย',
    'Ref: Target completion date': 'วันที่เสร็จสิ้นเป้าหมาย',
    'Ref: Priority fields placeholder message':
      'มันจะถูกเติมเมื่อ ผู้ช่วยเหลือ ได้รับการตรวจสอบแล้ว',
  },
  Failed: {
    'Ref: Body':
      'เกิดข้อผิดพลาดและไม่ได้สร้างคำขอบริการของคุณ หากข้อผิดพลาดนี้ยังคงอยู่ โปรดติดต่อฝ่ายช่วยเหลือของคุณ',
    'Ref: Tabtitle': 'คำขอบริการไม่สามารถสร้างได้',
  },
  Home: { 'Ref: Page title': 'การร้องขอ' },
  RequestFeedback: {
    'Ref: Page title': 'ความคิดเห็น',
    'Ref: Feedback headline': 'โปรดแบ่งปันความคิดเห็นของคุณ',
    'Ref: Feedback title': 'ข้อเสนอแนะเกี่ยวกับ SR # {Ref}',
    'Ref: Question: general sentiment':
      'โดยรวมแล้ว คุณพอใจกับวิธีที่โซเด็กซ์โซ่จัดการกับคำขอบริการของคุณมากน้อยเพียงใด',
    'Ref: Question: answer quality': 'คุณจะให้คะแนนคุณภาพของบริการที่คุณได้รับมากแค่ไหน?',
    'Ref: Question: staff interaction': 'คุณจะให้คะแนนการสื่อสารของคุณกับพนักงานของเราอย่างไร',
    'Ref: Question: efficiency': 'คุณจะให้คะแนนประสิทธิภาพของเราอย่างไร',
    'Ref: Question: open': 'ความคิดเห็น',
    'Ref: Open question placeholder': 'อะไรคือสิ่งที่ทำได้ดี  อะไรที่ต้องปรับปรุง',
    no_comment: 'ไม่มีความเห็น',
  },
  FeedbackSuccess: {
    'Ref: Body': 'ขอบคุณที่สละเวลาแสดงความคิดเห็น ความคิดเห็นของคุณเป็นสิ่งสำคัญสำหรับเรา ',
  },
  Locate: {
    'Ref: Page title': 'ค้นหาคำขอของคุณ',
    'Ref: Top bar title': 'ค้นหาคำขอของคุณ',
    'Ref: Select this location': 'เลือกตำแหน่งสถานที่นี้',
    drill: 'เรียกดู',
    'Ref: Scanner button': 'รหัสคิวอาร์โค้ดสถานที่ ? สแกนคิวอาร์โค้ด',
    'Ref: Scanner header': 'กรุณาสแกนคิวอาร์โค้ด ตำแหน่งสถานที่ของคุณ ',
    'Sorry, this location could not be found.': 'ขออภัยไม่พบสถานที่นี้',
    'Close scanner': 'สแกนเนอร์ปิด',
    'Ref: Switch site component intro sentence': 'คุณกำลังร้องขอบริการบนเว็บไซต์ {site name}.',
    'Ref: search location by keywords': 'ค้นหาสถานที่',
    'Ref: Enter Location name': 'ป้อนตำแหน่งสถานที่',
    'Ref: Enter manual location': 'ป้อนตำแหน่งสถานที่ด้วยตนเอง',
    'Ref: Location name': 'ชื่อสถานที่',
    'Ref: No results': 'เราหาตำแหน่งของคุณไม่เจอ คุณอาจต้องใส่ข้อมูลด้วยตนเอง',
    'Ref: Up one level': 'ขึ้นหนึ่งระดับ',
    'Ref: Navigate': 'นำทาง',
    'Ref: Select': 'เลือก',
    'Ref: Selected Location': 'เลือกสถานที่',
    'Ref: Preferred Location': 'สถานที่ที่ต้องการ',
    'Ref: Building': 'อาคาร',
    'Ref: Floor': 'พื้น',
    'Ref: Aisle': 'ทางเดิน',
    'Ref: Room': 'ห้อง',
    'Ref: Notification title': 'คุณได้เปลี่ยนสถานที่',
    'Ref: Notification info': 'ตอนนี้คุณอยู่ที่ {ชื่อไซต์}',
    'Ref: Scanner error header': 'ข้อผิดพลาดในการสแกน',
    'Ref: Scan QR Code': 'สแกนคิวอาร์โค้ด',
    'Ref: Error body': 'รหัสคิวอาร์โค้ดนี้ไม่ถูกต้อง',
    'Ref: Loading Locations Warning':
      'โปรดรอในขณะที่เรากำลังเตรียมประสบการณ์ของคุณสำหรับคำขอแรกที่เว็บไซต์นี้',
    'Ref: Search Result is limited to:':
      'ผลการค้นหาถูก จำกัด ไว้ที่ {limit} โปรดปรับแต่งเกณฑ์การค้นหาของคุณ',
    'Ref: Open the location search field': 'เปิดช่องค้นหาตำแหน่ง',
  },
  Success: {
    'Ref: Body':
      'ส่งคำขอบริการของคุณแล้วและกำลังดำเนินการ มันจะแสดงในแอปพลิเคชันนี้ในไม่ช้า คุณจะได้รับบันทึกเมื่อได้รับการแก้ไข ในระหว่างนี้ คุณสามารถติดตามสถานะและเพิ่มความคิดเห็นผ่านแอปพลิเคชันนี้ได้',
    'Ref: Tabtitle': 'คำขอบริการที่สร้างขึ้น',
    'Ref: Download app title': 'ลดความซับซ้อนของการร้องขอบริการของคุณ',
    'Ref: Download app subtitle': 'ดาวน์โหลดแอปของเราและยกระดับประสบการณ์ของคุณ',
    'Ref: Download app button': 'ดาวน์โหลดแอป',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'นี่คือศูนย์ช่วยเหลือในที่ทำงานของคุณ',
    'Ref: Tutorial: tabname': 'การร้องขอ',
    'Ref: Tutorial: body': 'เราอยู่ที่นี่เพื่อช่วยคุณ โปรดบันทึกหนึ่งคำขอต่อรายการ',
    "What's new: title": 'ค้นหาตำแหน่งของคุณได้อย่างง่ายดาย',
    "What's new: body":
      'ตอนนี้คุณสามารถสแกนคิวอาร์โค้ดค้นหาสถานที่ , เจาะลึกระดับตำแหน่งสถานที่ หรือเพียงแค่เลือกจากคำแนะนำ',
  },
  Widget: {
    'Ref: Service request': 'คำขอบริการ',
    'Ref: Placeholder body': 'ไม่มีคำขอบริการในขณะนี้',
    'Ref: New request button': 'คำขอใหม่',
  },
  QrLandingPage: {
    'Ref: Notification title': 'คุณได้เปลี่ยนสถานที่',
    'Ref: Notification info': 'ตอนนี้คุณอยู่ที่ {ชื่อไซต์}',
    'Welcome back': 'ยินดีต้อนรับ',
    'Create a service request': 'สร้างคำขอบริการ',
    'Ref: Share feedback': 'แบ่งปันข้อเสนอแนะ',
    'Go to home': 'กลับไปที่หน้าแรก',
    'Ref: Error body':
      'รหัส QR ที่คุณสแกนไม่ทำงานอีกต่อไปหรือไม่ถูกต้อง กรุณาลองใหม่อีกครั้งในภายหลัง.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'เกิดข้อผิดพลาด',
    'Ref: Error body': 'รหัสคิวอาร์โค้ดที่คุณสแกนใช้งานไม่ได้ โปรดลองอีกครั้งในภายหลัง.',
    'Go to home': 'กลับไปที่หน้าแรก',
  },
  ManualDescribe: {
    'Ref: Page title': 'อธิบายคำขอของคุณ',
    'Ref: Required fields: header': 'โปรดให้ข้อมูลเพิ่มเติม',
    'Ref: Required fields': 'ควรให้ข้อมูลต่อไปนี้ภายในฟิลด์รายละเอียด:',
    'Ref: Required field: title': 'จำเป็นต้องมีการสรุป',
    'Ref: Required field: description': 'จำเป็นต้องมีคำอธิบาย',
    'Ref: Required field: category': 'จำเป็นต้องมีหมวดหมู่',
    'Ref: Required field: buildingText': 'จำเป็นต้องมีอาคาร',
    'Ref: Invalid field: category': 'หมวดหมู่ที่ให้นั้นไม่ถูกต้อง',
    'Ref: Your request': 'คำขอร้องของคุณ',
    'Ref: Description message':
      'ยิ่งคุณมีรายละเอียดมากเท่าไหร่เราก็ยิ่งสามารถสนับสนุนคุณได้ดีขึ้นเท่านั้น',
    'Ref: Do you have a location QR code': 'คุณมีรหัสคิวอาร์โค้ตตำแหน่งสถานที่หรือไม่?',
    'Ref: Scan QR Code': 'สแกนคิวอาร์โค้ด',
    'Ref: Please select category': 'กรุณาเลือกหมวดหมู่',
    'Ref: Please select site': 'กรุณาเลือกไซต์',
    'Ref: site': 'เว็บไซต์',
    'Ref: building': 'อาคาร',
    'Ref: room': 'ห้อง',
    'Ref: Scan error': 'ข้อผิดพลาดในการสแกน',
    'Ref: The QR code is not valid': 'รหัสคิวอาร์โค้ดไม่ถูกต้อง',
    'Ref: Add location with QR Scanner': 'เพิ่มตำแหน่งด้วยคิวอาร์โค้ด',
    'Ref: Description': 'คำอธิบาย',
    'Ref: Forbidden error': 'ขออภัยรหัส QR นี้ไม่ตรงกับตำแหน่งที่ลงทะเบียนของคุณ',
    'Ref: Invalid error': 'ขออภัยดูเหมือนว่ารหัส QR นี้จะไม่ถูกต้องอีกต่อไป',
  },
};
export default labels;
