import { APP_NAME } from '../../constants';

import { getAppSpecificData } from '@/helpers/misc';

export const getApiEndpoints = () => {
  const configurations = {
    [APP_NAME.MYVILLAGE]: {
      getSiteContacts: '/v2/site/contacts?siteId=:siteId',
    },
    default: {
      getSiteContacts: '/v1/sites/:siteId/contacts',
    },
  };

  return getAppSpecificData(configurations);
};
