const labels = {
  StaysList: {
    'Ref: Page title': 'Lieka',
    'Ref: Past': 'Praeitis',
    'Current and future stays': 'Dabartiniai ir ateities viešnagės',
    'filter label: period': 'Laikotarpis',
    'Ref: Search': 'Paieška',
  },
  StayDetails: {
    'Ref: Page title': 'Savo viešnagės detales',
    'Arrival date': 'Atvykimo data',
    'Departure date': 'Išvykimo data',
    'Room number': 'Kambarys',
    'Reservation number': 'Rezervacijos numeris',
    CheckIn: 'Įsiregistruoti',
    CheckOut: 'Patikrinkite',
    CheckIn_1: 'Patikrinkite {lodgeName}, atvykstant šiandien? (Rezervavimo numeris {resNumber})',
    CheckedIn_1: 'Jūs esate tikrinami į {lodgeName}, kambarį {roomNumber}.',
    CheckOut_1:
      'Patikrinkite {lodgeName} rezervavimo numerį {desunumber} dabar? Jūsų kambario raktas bus išjungtas.',
    CheckedOut_1: '{lodgeName} rezervavimo numeris {desunumber} patikrino.',
  },
  StaysSuccess: {
    'Ref: Title': 'Dėkojame už buvimą!',
    'Ref: Body': 'Jūsų kasa buvo sėkmingai apdorota.',
    'Ref: Go to home': 'Eiti namo',
    'Ref: Provide a feedback': 'Pateikite atsiliepimą',
  },
};
export default labels;
