import { FC } from 'react';

import Title from '../../../../components/atoms/Title';
import NotificationTile from '../../../../components/molecules/Notification/NotificationTile';

import { NotificationsSectionProps } from './NotificationsList.types';

import styles from './NotificationsSection.module.css';

const NotificationsSection: FC<NotificationsSectionProps> = ({ title, items }) => {
  if (items.length < 1) {
    return null;
  }

  return (
    <div>
      <Title className={styles.titleWrapper} data-testid="notification-section-title">
        {title}
      </Title>
      {items.map((item) => (
        <div key={item.id} className={styles.wrapper}>
          <NotificationTile {...item} data-testid={`notification-tile-${item.id}`} />
        </div>
      ))}
    </div>
  );
};

export default NotificationsSection;
