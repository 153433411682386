const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Richiesta di servizio - Helpdesk',
    'Ref: Forbidden heading': 'Vietato',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Stai amministrando le richieste di servizio sul sito {siteName}.',
    'Ref: Dates': 'Date',
    'Ref: SRL list refresh': 'Aggiorna la lista',
    'Ref: Export': 'esportare',
    'Ref: Empty/not filled': 'Vuoto / non pieno',
    'Ref: Name': 'Nome',
    'Ref: Date': 'Data',
    'Ref: Category': 'Categoria',
    'Ref: User': 'Utente',
    'Ref: Location': 'Posizione',
  },
};
export default labels;
