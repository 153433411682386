import { screen, fireEvent, cleanup } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import QrLandingPage from '../QrLandingPage';

import renderComponent from '@/helpers/tests/renderComponent';

const mockClearSiteIdBeforeChange = jest.fn();
const mockDispatch = jest.fn();
const mockLocation = jest.fn();
const mockParams = jest.fn();
const mockPush = jest.fn();

const mockHistory = createMemoryHistory();
mockHistory.push = mockPush;

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockDispatch,
}));

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
  useLocation: () => mockLocation(),
  useParams: () => mockParams(),
}));

describe('QrLandingPage success scenarios', () => {
  beforeEach(() => {
    mockParams.mockReturnValue({
      locationId: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
      siteId: 'b1e6d6a4-f57b-e911-a97e-000d3a2bcd97',
    });
    mockLocation.mockReturnValue({
      search: `?surveyLink=aHR0cHM6Ly9zb2RleG8tZGl5LXN1cnZleXMubWN4cGxhdGZvcm0uZGUvc3VydmV5cy9GRFhFS0Yv`,
      pathname: '',
    });
  });
  describe('initial render - valid QR', () => {
    renderComponent(QrLandingPage, {
      clearSiteIdBeforeChange: mockClearSiteIdBeforeChange,
    });
    afterAll(() => cleanup());
    it('render all components on page', () => {
      expect(screen.getByTestId('go-to-home-action')).toBeTruthy();
      expect(screen.getByTestId('create-service-request')).toBeTruthy();
      expect(screen.getByTestId('share-feedback')).toBeTruthy();
      expect(screen.getByText('Go to home')).toBeTruthy();
    });
  });

  describe('redirect to home page - from success page', () => {
    let homepageButton: HTMLElement;
    renderComponent(QrLandingPage, {
      clearSiteIdBeforeChange: mockClearSiteIdBeforeChange,
    });
    beforeEach(() => {
      homepageButton = screen.getByText('Go to home');
      expect(homepageButton).toBeTruthy();
    });
    afterAll(() => cleanup());
    it('should click on the "Go to the homepage" button , then redirect to the home page', () => {
      const history = createMemoryHistory();
      fireEvent.click(homepageButton);
      expect(history.location.pathname).toBe('/');
    });
  });

  describe('redirect to SR creation', () => {
    const history = createMemoryHistory();
    renderComponent(
      QrLandingPage,
      {
        clearSiteIdBeforeChange: mockClearSiteIdBeforeChange,
      },
      history
    );
    beforeEach(() => {
      expect(screen.getByTestId('create-service-request')).toBeTruthy();
    });
    afterAll(() => cleanup());
    it('should click on the "Create a service request" button , then redirect to the first step of SR tunnel', () => {
      const history = createMemoryHistory();
      history.push(
        '/service_request/qrcreate/cba4f2b6-f47b-e911-a97d-000d3a2bc547/c3c54327-f67b-e911-a97d-000d3a2bcc69/describe'
      );
      fireEvent.click(screen.getByTestId('create-service-request'));
      expect(history.location.pathname).toBe(
        '/service_request/qrcreate/cba4f2b6-f47b-e911-a97d-000d3a2bc547/c3c54327-f67b-e911-a97d-000d3a2bcc69/describe'
      );
    });
  });
});

describe('QrLandingPage error scenarios', () => {
  beforeEach(() => {
    mockParams.mockReturnValue({
      locationId: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
      siteId: 'b1e6d6a4-f57b-e911-a97e-000d3a2bcd97',
    });
    mockLocation.mockReturnValue({
      search: `?surveyLink=aHR0cHM6Ly9zb2RleG8tZGl5Lm1jeHBsYXRmb3JtLmRlL3N1cnZleXMvRkRYRUtGLw==`,
      pathname: '',
    });
  });
  describe('error page - invalid QR', () => {
    renderComponent(QrLandingPage, {});
    afterAll(() => cleanup());
    it('redirect to error page', () => {
      expect(mockPush).toBeCalledTimes(1);
    });
  });
});
