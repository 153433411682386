const labels = {
  StaysList: {
    'Ref: Page title': 'Остается',
    'Ref: Past': 'прошлое',
    'Current and future stays': 'Текущий и будущий пребывание',
    'filter label: period': 'Период',
    'Ref: Search': 'Поиск',
  },
  StayDetails: {
    'Ref: Page title': 'Ваши детали пребывания',
    'Arrival date': 'Дата прибытия',
    'Departure date': 'Дата отбытия',
    'Room number': 'Комната',
    'Reservation number': 'Номер брони',
    CheckIn: 'Регистрироваться',
    CheckOut: 'Проверить',
    CheckIn_1: 'Проверьте на {lodgeName}, прибытие сегодня? (Номер бронирования {resnutom})',
    CheckedIn_1: 'Вы зарегистрированы в {lodgeName}, комнату {· oombernumber}.',
    CheckOut_1:
      'Проверьте номер бронирования {lodgeName} {Resnoble} сейчас? Ваш ключ в номере будет деактивирован.',
    CheckedOut_1: '{lodgeName} номер бронирования {resNumber} проверил.',
  },
  StaysSuccess: {
    'Ref: Title': 'Спасибо за ваше пребывание!',
    'Ref: Body': 'Ваш оформление заказа была успешно обработана.',
    'Ref: Go to home': 'Иди домой',
    'Ref: Provide a feedback': 'Обеспечить обратную связь',
  },
};
export default labels;
