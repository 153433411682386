import { ionFireEvent as fireEvent } from '@ionic/react-test-utils';
import { screen, cleanup, act, within } from '@testing-library/react';
import { createMemoryHistory } from 'history';
import moment from 'moment';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import StaysList from '../StaysList';

import { useGetStaysQuery } from '@/modules/Accomodation/api';

const EMPTY_INFO_MESSAGE = 'Your selection did not return any result';

const props = {
  stays: [],
  parentFilter: {
    filtering: {},
    handleFilteringChange: jest.fn(),
  },
  lock: false,
  getStays: jest.fn(),
  parentSearch: {
    searchString: '',
    handleSearchChange: jest.fn(),
  },
  currentLanguageCode: 'en-GB',
};

jest.mock('@/modules/Accomodation/api', () => ({
  useGetStaysQuery: jest.fn(),
}));

describe('StaysList', () => {
  afterAll(() => cleanup());

  describe('when rendering data', () => {
    beforeEach(() => {
      (useGetStaysQuery as jest.Mock).mockReturnValue({ data: [] });
    });

    renderComponent(StaysList, props);

    it('should to render the component', async () => {
      expect(screen.queryByTestId('default-page')).not.toBeNull();
    });

    it('should to display an empty list message', async () => {
      const infoMessage = screen.queryByTestId('stays-list-page-content-list-tile-list');
      expect(infoMessage).toHaveTextContent(EMPTY_INFO_MESSAGE);
    });
  });

  describe('when rendering data with items', () => {
    const futureDate = moment().add(10, 'days').format();
    const pastDate = moment().subtract(15, 'days').format();

    const stays = [
      {
        datearrivalDateLocal: futureDate,
        departureDateLocal: futureDate,
        id: 'af4d38d5-7dda-4a0f-be67-0082990a0dd7',
        reservationNumber: '103438',
        siteId: '1fb04838-744d-4584-bf8b-db8b8eb8398e',
        siteName: 'Mt. Milligan',
        status: 'CheckedOut',
      },
      {
        datearrivalDateLocal: futureDate,
        departureDateLocal: futureDate,
        id: 'zf4d38d5-7dda-4a0f-be67-0082990a0dd8',
        reservationNumber: '103438',
        siteId: '0fb04838-744d-4584-bf8b-db8b8eb8398d',
        siteName: 'Mt. Milligan',
        status: 'CheckedOut',
      },
      {
        datearrivalDateLocal: pastDate,
        departureDateLocal: pastDate,
        id: 'vf4d38d5-7dda-4a0f-be67-0082990a0ds2',
        reservationNumber: '103438',
        siteId: '23b04838-744d-4584-bf8b-db8b8eb83975',
        siteName: 'Mt. Milligan',
        status: 'CheckedOut',
      },
    ];

    beforeEach(() => {
      (useGetStaysQuery as jest.Mock).mockReturnValue({ data: stays });
    });

    const history = createMemoryHistory();
    renderComponent(
      StaysList,
      {
        ...props,
        stays,
      },
      {},
      history
    );

    it('should to display a list', async () => {
      const infoMessage = screen.queryByTestId('info-message');
      expect(infoMessage).toBeNull();
    });

    it('should to display only the past stays', async () => {
      const pastFilterButton = await screen.findByTestId(
        'stays-list-page-search-bar-list-page-filters-filters_period-option-filters_period-past'
      );
      await act(async () => {
        fireEvent.click(pastFilterButton);
      });
      const items = screen.queryAllByTestId('stays-list-item');
      expect(items.length).toBe(1);
    });

    it('should to display only current or future stays', async () => {
      const pastFilterButton = await screen.findByTestId(
        'stays-list-page-search-bar-list-page-filters-filters_period-option-filters_period-current_future'
      );
      await act(async () => {
        fireEvent.click(pastFilterButton);
      });
      const items = screen.queryAllByTestId('stays-list-item');
      expect(items.length).toBe(2);
    });

    it('when click on a list item', async () => {
      const items = screen.queryAllByTestId(/^stays-list-item/);
      const anchor = within(items[0]).findByTestId('corner_action');

      await act(async () => {
        fireEvent.click(await anchor);
      });

      expect(history.location.pathname).toBe(`/accomodation/${stays[0].id}/details`);
    });
  });
});
