//WARNING: THIS SERVICE IS OBSOLETE AND REMOVED PIECE BY PIECE, NO NEW CODE RELYING ON IT

import { serviceTypes, modulesConfig } from '../modules/config';
import { ServiceDefinition } from '../modules/defaultServices';
import { store } from '../store';

type ModulesKeys = keyof typeof modulesConfig;

class Module<TApiType> {
  public id: string;
  public serviceType: string;
  public api: TApiType;
  public initialize: (() => boolean) | undefined;

  constructor({
    id,
    serviceType,
    initialize,
    api,
  }: {
    id: string;
    serviceType: string;
    initialize: (() => boolean) | undefined;
    api: TApiType;
  }) {
    this.id = id;
    this.serviceType = serviceType;
    if (initialize) this.initialize = initialize.bind(this);
    this.api = api;
  }
}

class LoadedModulesInfo {
  static modules: Record<string, Module<unknown>> = {};
  static activeModulesId: Record<string, string> = {};
}

function ModulesManager<TApiType>() {
  const getActiveModuleByServiceType = (serviceType: string): Module<TApiType> =>
    (LoadedModulesInfo.modules as Record<string, Module<TApiType>>)[
      LoadedModulesInfo.activeModulesId[serviceType]
    ];

  const getAllActiveModuleApi = (serviceType: string) =>
    getActiveModuleByServiceType(serviceType).api || {};

  const getDefaultModuleApi = (serviceType: string, funcName: keyof TApiType) => {
    const defaultModuleName = serviceTypes['serviceType']
      ? serviceTypes['serviceType'].defaultModule
      : false;

    if (!LoadedModulesInfo.modules[defaultModuleName as string]) {
      return () => undefined;
    }

    return (LoadedModulesInfo.modules as Record<string, Module<TApiType>>)[
      defaultModuleName as string
    ].api[funcName];
  };

  const getApi = (
    serviceType: string,
    funcName: keyof TApiType,
    shouldFallbackOntoDefaultModule: boolean = false
  ) => {
    const module = getActiveModuleByServiceType(serviceType);
    if (!module) {
      if (shouldFallbackOntoDefaultModule) return getDefaultModuleApi(serviceType, funcName);

      return () => undefined;
    }

    const api = module.api;
    if (!api) return () => undefined;

    return api[funcName];
  };

  const getModuleById = (id: string) => LoadedModulesInfo.modules[id];

  const init = () => {
    // add core module
    const coreModuleInfo = {
      id: 'Core',
      serviceType: 'Core',
      api: require('../modules/Core/api').default,
      initialize: undefined,
    };

    LoadedModulesInfo.modules['Core'] = new Module(coreModuleInfo);
    LoadedModulesInfo.activeModulesId['Core'] = 'Core';
  };

  const activate = (module: Module<TApiType>) => {
    const activeModuleForThisServiceType = getActiveModuleByServiceType(module.serviceType);
    if (activeModuleForThisServiceType) inactivate(activeModuleForThisServiceType);

    LoadedModulesInfo.activeModulesId[module.serviceType] = module.id;
  };

  const inactivate = (module: Module<TApiType>) => {
    delete LoadedModulesInfo.activeModulesId[
      Object.values(LoadedModulesInfo.activeModulesId).findIndex(
        (moduleId) => moduleId === module.id
      )
    ];
  };

  const add = (moduleId: ModulesKeys, shouldActivate = true) => {
    let thisModuleConfig;
    try {
      thisModuleConfig = require('../modules/' + moduleId + '/config');
    } catch (e) {
      console.log(
        'No config file for module: ' +
          moduleId +
          '. Make sure config.js is available at the root of your module.'
      );
    }

    let moduleApi;
    try {
      moduleApi = require('../modules/' + moduleId + '/api');
    } catch (e) {
      console.log(
        'No api file for module: ' +
          moduleId +
          '. Make sure an api.js file at the root of your module exports an object by default.'
      );
    }

    let moduleInfo = {
      id: moduleId,
      version: thisModuleConfig.cacheVersion,
      serviceType: modulesConfig[moduleId].serviceType,
      initialize: thisModuleConfig.initialize ? thisModuleConfig.initialize : undefined,
      api: moduleApi ? moduleApi.default : undefined,
    };

    let module = new Module(moduleInfo);
    if (!module.initialize || module.initialize()) {
      //if the module need to be initialized, initialization must succeed
      LoadedModulesInfo.modules[moduleId] = module;
      if (shouldActivate) {
        activate(module);
      }
      return true;
    } else {
      return false;
    }
  };

  const setupFromServices = async (services: ServiceDefinition[]) => {
    // always add the default site module (may be overridden later)
    add('Sites');

    //add all the modules as per the services received
    for (let service of services) {
      // exclude the service that are built into the core
      const includedInCore = ['MyAccount'];
      if (includedInCore.find((el) => el === service.name)) continue;

      // heck if a module setup option defines a specific module for this service
      const moduleSetupOption = service.setupOptions
        ? service.setupOptions.find((setupOption) => setupOption.name === 'module')
        : false;

      let moduleId: ModulesKeys | undefined = undefined;
      // if a "module" setup option was passed, try to find the module
      if (moduleSetupOption) {
        const modulesNames = Object.keys(modulesConfig) as Array<ModulesKeys>;
        moduleId = modulesNames.find(
          (candidateModuleName) =>
            moduleSetupOption.value === candidateModuleName &&
            modulesConfig[candidateModuleName].serviceType === service.name
        );
      }

      // if we don't have a module yet, fall back onto the default module for this service type
      if (!moduleId && serviceTypes[service.name]) {
        moduleId = serviceTypes[service.name].defaultModule as ModulesKeys;
      }

      if (moduleId && modulesConfig[moduleId]) {
        add(moduleId);
      }

      await store.injectReducerFromModuleId(moduleId!);
    }
  };

  return {
    getActiveModuleByServiceType,
    getAllActiveModuleApi,
    getApi,
    getModuleById,
    init,
    activate,
    inactivate,
    setupFromServices,
  };
}

export default ModulesManager;
