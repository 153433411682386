import moment from 'moment';

import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { getImagesSrcFromIds } from '../../../helpers/images/images.helper';
import { getImageSrcFromInfo } from '../../../helpers/misc';
import { ReactionsAPIType } from '../../../types/reactions.types';
import { contentConfig } from '../config';
import mockResponse from '../mocks/mockResponse';
import { IContent, IContentType } from '../types';

import { GetHomeArgs, GetContentArgs, ReactionMutationData } from './api.types';

const {
  api,
  baseUrl,
  gethomeUrl,
  contentsUrl,
  reactionUrl,
  getHomeParams,
  getContentParams,
  updateReactionParams,
  deleteContentParams,
} = contentConfig();

const contentApi = api.enhanceEndpoints({ addTagTypes: ['content'] }).injectEndpoints({
  endpoints: (build) => ({
    getHome: build.query<IContent[], GetHomeArgs>({
      queryFn: async ({ useErrorBoundary, ...args }: GetHomeArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl: baseUrl });
        const response = await baseQuery({
          url: gethomeUrl,
          method: 'get',
          params: getHomeParams(
            moment().add(-60, 'days').format('YYYY-MM-DD'),
            moment().add(60, 'days').format('YYYY-MM-DD'),
            args.siteId,
            args.languageCode
          ),
          opts: { useErrorBoundary },
          mockData: mockResponse.getHome,
        });

        const { data: { contents } = {} } = response;

        const filteredContent = contents.filter(
          (contentPiece: IContent) => contentPiece.type !== IContentType.INMOMENT_SURVEY
        );

        const imageIds: string[] = filteredContent
          .filter((content: IContent) => !!content.images?.[0])
          .map((content: IContent) => content.images[0] as string);

        const images = await getImagesSrcFromIds(imageIds);

        return {
          data: filteredContent.map((content: IContent) => {
            const imageId = content.images?.[0] as string;
            const image = images.find((img) => img.id === imageId);

            return {
              ...content,
              imgInfo: image ? { src: image.content, alt: content.title } : undefined,
            };
          }),
        };
      },
      providesTags: ['content'],
    }),
    getContent: build.query<IContent, GetContentArgs>({
      queryFn: async ({ id, siteId }: GetContentArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl: baseUrl });

        const response = await baseQuery({
          url: contentsUrl(id),
          method: 'get',
          params: getContentParams(id, siteId),
        });

        return {
          data: response.data
            ? {
                ...response.data,
                mainImage: response.data?.images?.length
                  ? getImageSrcFromInfo(response.data?.images[0])
                  : undefined,
              }
            : undefined,
          error: response.error,
        };
      },
      providesTags: (result) => [{ type: 'content', id: result?.id }],
    }),
    updateContentReaction: build.mutation<ReactionsAPIType, ReactionMutationData>({
      query: ({ id, reactionType }) => ({
        url: reactionUrl(id),
        method: 'post',
        data: updateReactionParams(id, reactionType),
      }),
      async onQueryStarted({ id, languageCode, siteId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contentApi.util.updateQueryData('getHome', { languageCode, siteId }, (draft) => {
            return draft.map((content: IContent) =>
              content.id === id
                ? { ...content, reactions: { ...content.reactions, me: patch.reactionType } }
                : content
            );
          })
        );
        const patchDetailResult = dispatch(
          contentApi.util.updateQueryData('getContent', { id, siteId }, (draft) => {
            return draft
              ? { ...draft, reactions: { ...draft.reactions, me: patch.reactionType } }
              : draft;
          })
        );
        queryFulfilled.catch(patchResult.undo);
        queryFulfilled.catch(patchDetailResult.undo);
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'content', id }, { type: 'content' }],
    }),
    deleteContentReaction: build.mutation<ReactionsAPIType, ReactionMutationData>({
      query: ({ id }) => ({
        url: reactionUrl(id),
        method: 'delete',
        data: deleteContentParams(id),
      }),
      async onQueryStarted({ languageCode, siteId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          contentApi.util.updateQueryData('getHome', { languageCode, siteId }, (draft) => {
            return draft.map((content: IContent) =>
              content.id === patch.id
                ? {
                    ...content,
                    reactions: {
                      ...content.reactions,
                      me: null,
                    },
                  }
                : content
            );
          })
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'content', id }, { type: 'content' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetHomeQuery,
  useGetContentQuery,
  useUpdateContentReactionMutation,
  useDeleteContentReactionMutation,
} = contentApi;
export default contentApi;
