import { useCallback, useState } from 'react';

import { apiPath } from '../../../config';
import { CacheService } from '../../../services/CacheService';
import { OfflineQueueService } from '../../../services/OfflineQueueService';
import {
  endpoints,
  useCreateAndRespondAuditMutation,
  useCreateAuditMutation,
  useCreateAuditSurveyResponseMutation,
  useLazyGetAssetsQuery,
  useLazyGetAuditInvitesQuery,
  useLazyGetAuditsQuery,
  useLazyGetAuditLocationsQuery,
  useLazyGetStreamsQuery,
  useUpdateAuditInviteStateMutation,
} from '../api';

import toast from '@/components/atoms/ModalToast';

const useSync = ({
  onSuccess,
  successLabel,
  errorLabel,
}: {
  successLabel: string;
  errorLabel: JSX.Element | string;
  onSuccess?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [getAuditInvites] = useLazyGetAuditInvitesQuery();
  const [getLocations] = useLazyGetAuditLocationsQuery();
  const [getStreams] = useLazyGetStreamsQuery();
  const [getAudits] = useLazyGetAuditsQuery();
  const [getAssets] = useLazyGetAssetsQuery();

  const [createAudit] = useCreateAuditMutation();
  const [createAuditSurveyResponse] = useCreateAuditSurveyResponseMutation();
  const [createAndRespondAudit] = useCreateAndRespondAuditMutation();
  const [updateAuditInviteState] = useUpdateAuditInviteStateMutation();

  const checkSyncStatus = useCallback(async () => {
    const cache = CacheService.getInstance();

    let fetch = true;
    let post = true;

    const offlineQueueController = OfflineQueueService.getInstance();
    const queue = await offlineQueueController.getAll();

    if (queue.length > 0) {
      post = false;
    }

    const audits = await cache.getValue(apiPath + endpoints.getAudits);
    const locations = await cache.getValue(apiPath + endpoints.getLocations);
    const streams = await cache.getValue(apiPath + endpoints.getStreams);
    const assets = await cache.getValue(apiPath + endpoints.getAssets);

    const fetchedData = [audits, locations, streams, assets];

    if (fetchedData.some((data) => !data)) {
      fetch = false;
    }

    return { fetch, post };
  }, []);

  const onFetchData = useCallback(async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const offlineQueueController = OfflineQueueService.getInstance();
      const queue = await offlineQueueController.getAll();

      /**
       * Organizes queued requests into groups based on their type
       */
      const groupedRequests = queue.reduce((acc, { k, v }) => {
        const isCreate = v.method === 'post' && v.url.includes(endpoints.createAudit);
        const isRespond =
          v.method === 'post' && v.url.includes(endpoints.createAuditSurveyResponse);
        const isUpdate =
          v.method === 'patch' && v.url.includes(endpoints.updateAuditInvite.slice(0, -3));

        if (isCreate) {
          return { ...acc, [k]: { ...(acc[k] || {}), create: { data: v.data, key: k } } };
        }
        if (isRespond) {
          const key = v.data.inviteId;
          return { ...acc, [key]: { ...(acc[key] || {}), respond: { data: v.data, key: k } } };
        }
        if (isUpdate) {
          return { ...acc, [k]: { ...(acc[k] || {}), update: { data: v.data, key: k } } };
        }

        return acc;
      }, {} as { [k: string]: { create?: { key: string; data: any }; respond?: { key: string; data: any }; update?: { key: string; data: any } } });

      /**
       * `commands` is an array constructed from the previously grouped requests. Its purpose is to identify interdependencies
       * between Create and Respond requests and find the suitable method to handle them:
       * - If a `Respond request` corresponds to a queued `Create request`, they are merged and processed using `createAndRespondAudit`.
       * - Independent `Create requests` are processed using `createAudit`.
       * - Independent `Respond requests` are processed using `createAuditSurveyResponse`.
       * - `Update requests` are processed using `updateAuditInviteState`. These requests are meant to handle:
       *    - Changing audits state.
       *    - Changing audits location.
       */
      const commands = Object.values(groupedRequests).reduce((acc, { create, respond, update }) => {
        // Create & Respond
        if (create && respond) {
          return [
            ...acc,
            {
              keys: [create.key, respond.key],
              method: createAndRespondAudit,
              data: {
                locationId: create.data.locationId,
                auditId: create.data.auditId,
                auditName: respond.data.auditName,
                assetId: create.data.assetId,
                assetName: create.data.assetName,
                isJointAudit: create.data.isJointAudit,
                jointAuditParticipants: create.data.jointAuditParticipants,
                responses: respond.data.responses,
                dueDate: create.data.dueDate,
              },
            },
          ];
        }

        // Create only
        if (create && !respond) {
          return [...acc, { keys: [create.key], method: createAudit, data: create.data }];
        }

        // Update only
        if (update) {
          return [
            ...acc,
            {
              keys: [update.key],
              method: updateAuditInviteState,
              data: { ...update.data, id: update.key },
            },
          ];
        }

        // Respond only
        if (respond && !create) {
          return [
            ...acc,
            { keys: [respond.key], method: createAuditSurveyResponse, data: respond.data },
          ];
        }

        return acc;
      }, [] as { keys: string[]; method: Function; data: any }[]);

      let responses = await Promise.all([
        // Fetch data
        getLocations(),
        getStreams(),
        getAudits(null),
        getAssets(null),
        // Send data
        ...commands.map(({ keys, method, data }: { keys: string[]; method: Function; data: any }) =>
          method(data).then(async (response: any) => {
            if (response.error) return response;
            await Promise.all(keys.map((key) => offlineQueueController.remove(key)));
            return response;
          })
        ),
      ]);

      // Audit Invites synchronized after synchronizing new audit invites created offline
      const getAuditInvitesResponse = await getAuditInvites({
        includeResponses: true,
      });

      responses = [...responses, getAuditInvitesResponse];
      if (responses.some(({ isError, error }) => isError || error)) throw responses;

      setIsLoading(false);

      toast.success(successLabel, {
        position: 'top-center',
        closeOnClick: true,
        draggable: false,
        closeButton: false,
      });

      if (onSuccess) onSuccess();
    } catch (error) {
      setIsLoading(false);
      console.error(error);

      toast.error(errorLabel, {
        position: 'top-center',
        closeOnClick: true,
        draggable: false,
        closeButton: false,
      });
    }
  }, [
    isLoading,
    getLocations,
    getStreams,
    getAudits,
    getAssets,
    getAuditInvites,
    successLabel,
    onSuccess,
    createAndRespondAudit,
    createAudit,
    createAuditSurveyResponse,
    updateAuditInviteState,
    errorLabel,
  ]);

  return { checkSyncStatus, onFetchData, isLoading };
};

export default useSync;
