import classNames from 'classnames';
import ReactCalendar, { CalendarProps, FormatterCallback } from 'react-calendar';

import { CalendarNextLabelIcon, CalendarPreviousLabelIcon } from '../../../assets';

import styles from './Calendar.module.css';

// This function uses the standard short format for days, with the current locale
// and takes the first letter of that string, to comply to the designs
const formatShortWeekday: FormatterCallback = (locale, date) =>
  new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date).substr(0, 1).toUpperCase();

const defaultProps: Partial<CalendarProps> = {
  formatShortWeekday,
  nextLabel: <CalendarNextLabelIcon />,
  prevLabel: <CalendarPreviousLabelIcon />,
  next2Label: null,
  prev2Label: null,
};

const Calendar = (props: CalendarProps) => {
  const finalProps = { ...defaultProps, ...props };
  return <ReactCalendar {...finalProps} className={classNames(styles.container)} />;
};

export default Calendar;
