const labels = {
  ContactForm: {
    'Ref: Page title': 'Nous contacter',
    'Ref: Category field value': 'Nous contacter',
  },
  ContactList: {
    'Ref: Page title': 'Contacts',
    'call (action to place a call)': 'appeler',
    'write (action to write a message)': 'écrire',
    'write (action to write an e-mail)': 'e-mail',
    'Ref: Hint message':
      "Veuillez noter que certains sites peuvent ne pas avoir de réception. Si tel est le cas, veuillez utiliser l'un des téléphones fixes disponibles sur le site.",
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      "Une erreur s'est produite et votre message n'a pas été soumis. Si cette erreur persiste, veuillez contacter votre service support.",
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': "Votre message a été soumis. Merci d'avoir pris contact avec nous.",
  },
};
export default labels;
