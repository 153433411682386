const labels = {
  All: {
    Region: 'Regione o paese',
    'Ref: Geography': 'Seleziona il tuo paese o regione',
    'Ref: Country': 'Paese',
    'Ref: Country tooltip': 'La posizione corrisponde al paese del sito.',
    'Ref: Please select your country': 'Selezionare il proprio paese',
    'First name': 'Nome di battesimo',
    'Last name': 'Cognome',
    'Ref: Full name': 'Nome e cognome',
    'Mobile number': 'Numero di cellulare',
    'Mobile phone': 'Cellulare',
    Email: 'Indirizzo e-mail',
    Password: 'Password',
    logout: 'Disconnettersi',
    'Not a new user? Log in': 'Non sei un nuovo utente? Accedi',
    'do not register, login': 'Non sei un nuovo utente? Accedi',
    'Ref: I agree': "sono d'accordo",
    Register: 'Registrati',
    'Ref: My language': 'Mio linguaggio',
    'Ref: Kiosk Fail Title': 'Oops! Qualcosa è andato storto!',
    'Ref: Kiosk Fail Description':
      'Sfortunatamente, il tuo ordine non ha avuto successo. Riprova o chiedi a un membro dello staff di aiutarti.',
    'Ref: Kiosk Fail Back': 'torna alla PAGINA precedente',
    'Ref: Kiosk Fail Action': 'Cancella la sessione e ordina di nuovo',
    Validate: 'Convalidare',
    'Ref: error, incorrect credentials':
      'Queste credenziali non corrispondono alla geografia scelta.',
    'Ref: Forgot password?': 'Ha dimenticato la password?',
    'Ref: Hello': 'Ciao {wave_emoji}, si prega di accedere per continuare',
    'Ref: Form title': 'Si prega di iscriverti per continuare',
    'Already have an account? Login': 'Hai già un account? Accedi',
  },
  AccountPage: {
    'Ref: My Information': 'Le mie informazioni',
    'Ref: Settings': 'Impostazioni',
    'Ref: Email': 'E-mail',
    'Ref: Current site': 'Sito attuale',
    'Ref: Choose site': 'Scegli un sito',
    'Ref: Personal interests': 'Interessi personali',
    'Ref: Food preferences': 'Preferenze alimentari',
    'Ref: Password': 'Password',
    'Ref: Page title': 'Account',
    'Ref: Language': 'Lingua',
    'Ref: Communication preferences': 'Preferenze di comunicazione',
    'Ref: Terms & conditions': 'Termini & Condizioni',
    'Ref: Privacy policy': 'Politica sulla riservatezza',
    'Ref: Delete my account': 'Cancella il mio account',
    'Ref: Confirm deletion': 'Sei sicuro di voler eliminare il tuo account?',
    'Ref: Request deletion': 'Sei sicuro di voler richiedere la cancellazione del tuo account?',
    'Ref: Deletion description': 'La tua richiesta verrà gestita in base al regolamento locale.',
    Placeholder: 'Scrivi "Elimina"',
    'Ref: Delete description': 'I tuoi dati verranno eliminati. Questa azione è irreversibile.',
    delete: 'Elimina',
    'Ref: Terms of sale': 'Condizioni di vendita',
    'Ref: Imprint': 'Impronta',
    'Ref: Cookie Settings': 'Impostazioni dei cookie',
    'Ref: Support': 'Supporto',
    'Ref: Register or login': 'Registrati o accedi',
    'Ref: Filtering Preferences': 'Preferenze di filtraggio',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Sei sicuro di eliminare il tuo account?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Scrivi "{word}" nel campo di input e conferma con il basso in basso. Questa azione è irreversibile.',
    'Ref: Write "{word}"': 'Scrivi "{word}"',
    'Ref: Delete my account': 'Cancella il mio account',
    'Ref: Words are not matching': 'Le parole non corrispondono',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Scegli la tua posizione per iniziare',
    'Ref: Choose location':
      'Trova rapidamente una posizione attivando la localizzazione, scansionando un codice QR o cercando per nome',
    'Ref: next step': 'Andiamo',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Iscriviti',
    'Ref: identify your location': 'Si prega di identificare la tua posizione.',
    'Ref: option 1 - scan QR': 'Opzione 1: Scansione del codice QR di registrazione fornito',
    'Ref: option 2 - enter code': 'Opzione 2: Immettere manualmente il tuo codice di posizione',
    'Ref: button to open qr code scanner modal': 'Ho un QR: Effettuare scansione',
    'Ref: button to open site code input modal': 'Seleziona il sito con codice',
    'Ref: getting help with site identifier - support desk':
      'Se non ti è chiaro su come procedere, contattare il tuo servizio di supporto.',
    'Ref: getting help with site identifier - support form': 'Puoi anche inviarci una nota.',
    'Location code': 'Codice di posizione',
    'Ref: location code field placeholder': 'Il tuo codice di posizione qui',
    'Ref: Scanner header': 'Scansionare un codice QR',
    'Ref: site code input header': 'Aggiungi sito con codice',
    'Ref: not valid code': "Questo codice non è valido nell'area geografica selezionata",
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Registrazione',
    'Ref: collected info': 'Sommario',
    'Ref: additional info': 'Le tue informazioni',
    'Confirm password': '*Conferma password',
    company: 'Chi è il tuo datore di lavoro?',
    location: 'Posizione',
    username: 'nome utente',
    register: 'Iscriviti',
    'Ref: password strength':
      'La lunghezza della password deve essere lunga almeno 8 caratteri, contenere almeno un numero, uno maiuscola, una minuscola e un carattere speciale.',
    'passwords do not match': 'Le passwords non corrispondono.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Preferenze di comunicazione',
    'Ref: Things happen while you are away': 'Le cose accadute in tua assenza',
    'Ref: Would you like to receive update about offers and promotions?':
      'Vorresti ricevere aggiornamenti su offerte e promozioni?',
    'Ref: Would you like to hear about content we think you may like?':
      'Ti piacerebbe sentire parlare di contenuti che pensiamo che ti possano interessare?',
    Yes: 'sì',
    No: 'No',
    apply: 'applica',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Dettagli account' },
  DeletionFailure: {
    'Ref: Body':
      "Si è verificato un errore e la richiesta di eliminazione dell'account non è stata inviata. Se questo errore persiste, si prega di contattare il tuo sportello di assistenza.",
    'Go to account': 'Vai a Conto',
  },
  GenericFailurePage: {
    'Ref: Title': 'Qualcosa è andato storto',
    'Ref: Body':
      'Scusa, qualcosa è andato storto. Se questo problema persiste, si prega di contattare il supporto locale.',
  },
  HomePage: {
    notifications: 'notifiche',
    basket: 'Cestino',
    'Ref: Tabtitle': 'Casa',
    'contact us': 'Contattaci',
    'Ref: You are logged in as a guest user': "Hai effettuato l'accesso come ospite",
    'Ref: Good morning': 'Buon giorno',
    'Ref: Good afternoon': 'Buon pomeriggio',
    'Ref: Good evening before midnight': 'Buona serata',
    'Ref: Good evening after midnight': 'Buona serata',
    'Ref: Search': 'Ricerca',
    'Ref: products button aria': "Trovato {results_form} all'interno dei prodotti",
    'Ref: menus button aria': 'Trovato {results_form} nei menu',
    'Ref: facilities button aria': "Trovato {results_form} all'interno delle strutture",
    'Ref: result': 'risultato',
    'Ref: results': 'Risultati',
    'Ref: Menus': 'Menu',
    'Ref: No results title': 'Oops!',
    'Ref: No results body': 'Nessun risultato che corrisponde alla tua ricerca',
    'Ref: Products': 'Prodotti',
    'Ref: Facilities': 'Strutture',
    'Ref: See more results': 'Vedi altri risultati di ricerca',
    'Ref: Accessibility instructions':
      'Per iniziare a utilizzare la ricerca, è necessario fare clic su di essa o premere Invio mentre si concentra.',
    'Ref: See more': 'Vedi altro',
    'Ref: singular form of products': 'Prodotto',
    'Ref: singular form of menus': 'menù',
    'Ref: singular form of facilities': 'facilità',
    'Ref: Search results for': 'cerca risultati per',
  },
  LanguagePage: {
    apply: 'applicare',
    'My language': 'La mia lingua',
    'Select in which language you would like to read our app':
      'Seleziona in quale lingua desideri leggere la nostra app',
    'Ref: Page title': 'Lingua',
  },
  LegalDocPage: {
    Acknowledge: 'Riconoscere',
    'Ref: Legal changed notification title': '"{legal_doc_type}" sono stati modificati',
    'Ref: Legal changed notification content':
      "Si prega di rivederli prima di continuare a utilizzare l'app.",
  },
  LegalDocsPage: {
    'Ref: Page title': 'Termini & Condizioni',
    'Read privacy policy': "Leggi l'informativa sulla privacy",
    'Ref: Acknowledge legal docs': 'Accetto questi termini e condizioni.',
    'Ref: Read and understood': 'Ho letto e capito ',
    'Ref: No legal docs available - notice title': 'Prossimamente',
    'Ref: No legal docs available - notice body':
      'Scusa, sembra che questa applicazione non sia attualmente disponibile nella tua geografia.',
    'Ref: The privacy Policy': 'la politica sulla privacy',
    'Ref: Legal changed notification title': '"{legal_doc_type}" sono stati modificati',
    'Ref: Legal changed notification content':
      "Si prega di rivederli prima di continuare a utilizzare l'app.",
  },
  LoginPage: {
    'Ref: Page title': 'Benvenuti a {app name} ',
    'Ref: Page subtitle': 'Accedi per continuare',
    'Ref: Email field placeholder': 'La tua email',
    'Ref: Email field note': 'Non condivideremo mai la tua email con nessuno.',
    'Ref: Password field title': 'La tua password',
    register: 'Iscriviti',
    'I forgot my password.': 'Ha dimenticato la password?',
    'I have trouble logging in': 'Avendo la registrazione problemi?',
    'start registration': 'Iniziare la registrazione',
    'Ref: Register': 'Registrati',
    'Ref: Login': 'Login',
    'Ref: Connection issue?': 'Problema di connessione?',
    'Ref: Connection issue message':
      'In caso di problemi di registrazione o accedere, contattare il personale in loco o sportello di assistenza.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Ciao e benvenuto a {site_name}', 'Ref: Begin': 'Inizio' },
  NotificationsList: {
    'Ref: No notifications': 'Non hai notifiche per il momento',
    'Ref: This week': 'Questa settimana',
    'Ref: Earlier': 'Prima',
    'Ref: Page title': 'Notifiche',
    'Ref: See more': 'Vedi altro',
    'Ref: Mark all read': 'segna tutto come letto',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Vedi tutte le notifiche' },
  ServicesMenuPage: { 'Ref: Page title': 'Servizi', navigate: 'navigare' },
  DeletionSuccess: {
    'Ref: Title': 'È stata richiesta la cancellazione del tuo account',
    'Ref: Content': "Lo gestiremo a breve. Potresti perdere l'accesso in qualsiasi momento.",
    'Log out': 'Disconnettersi',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Otterremo di nuovo voi',
    'Ref: Body': 'Siamo spiacenti che tu stia affrontando problemi. Ti contatteremo molto presto.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Avendo la registrazione problemi?',
    'Ref: Email field placeholder': 'Il tuo indirizzo di posta elettronica',
    'Ref: Mobile phone field placeholder': 'Numero di cellulare',
    Company: 'Azienda',
    'Ref: Company field placeholder': 'Nome della ditta',
    'Ref: Company field note':
      'Se sei un consulente, questa è la società per la quale stai lavorando attualmente, non il tuo datore di lavoro.',
    Location: 'Città o posizione',
    'Ref: Location field placeholder': 'Posizione del tuo posto di lavoro',
    'Ref: Location field note':
      "Se al di fuori di un'area abitata, sentiti liberi di usare il nome con cui si intende comunemente l'area.",
    Comment: 'Commento',
    'Ref: Comment field placeholder': 'Che cosa è andato storto?',
    'Ref: Who is your employer?': 'Chi è il tuo datore di lavoro?',
    'Ref: What village are you staying at?': 'In che villaggio stai?',
    'Ref: Comment field note': 'Qualche informazione che può aiutarci ad aiutarti!',
    'Ref: Feedback Content - geography': 'Geografia',
    'Ref: Feedback Content - fullName': 'Nome',
    'Ref: Feedback Content - email': 'E-mail',
    'Ref: Feedback Content - phone': 'Telefono',
    'Ref: Feedback Content - company': 'Azienda',
    'Ref: Feedback Content - city': 'Nome città / posizione',
    'Ref: Feedback Content - whatVillage': 'Villaggio',
    'Ref: Feedback Content - employer': 'Datore di lavoro',
    'Ref: Feedback Content - comment': 'Che cosa è andato storto',
  },
  AccountStartPage: {
    'Ref: Header': 'Scegli la tua posizione per iniziare',
    'Ref: Body':
      'Trova rapidamente una posizione attivando la localizzazione, scansionando un codice QR o cercando per nome',
    'Ref: LetsGo button': 'Andiamo',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Registrazione' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Seleziona il sito con scanner QR',
    'Ref: Page title': 'Scegli la tua posizione',
    'Ref: Select site with code': 'Seleziona il sito con codice',
    'Ref: Enter site code': 'Immettere il codice del sito',
    'Ref: Scanner error header': 'Sito non trovato',
    'Ref: Not found': "Questo codice non è valido nell'area geografica selezionata",
    'Ref: Generic error': 'Qualcosa è andato storto',
    'All other sites': 'Tutti gli altri siti.',
    'Sites in your country': 'Siti nel tuo paese',
    'Ref: Unauthorized error':
      'Richiesta non autorizzata a causa del livello di restrizione del sito',
    'Ref: Forbidden':
      "Mi dispiace, non hai le autorizzazioni giuste per accedere a questo sito. Per organizzare l'accesso, contattare il Pilbara Service Center il 1800 992 777",
    "Ref: We didn't find the site.": 'Non abbiamo trovato il sito.',
    'Ref: We recommend some solutions:': 'Raccomandiamo alcune soluzioni:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      "1. Prova ad aggiungere il tuo sito con scanner QR o con l'aiuto di pulsanti sotto",
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Accedi e accedere selezionando il paese corretto',
    'Ref: The currently selected country is:': 'Il paese attualmente selezionato è:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Registrazione',
    'Confirm password': 'Conferma password',
    'Email is not valid': "L'email non è valida",
    'passwords do not match': 'Le passwords non corrispondono.',
    'Ref: Password strength':
      'La tua password deve essere lunga almeno 8 caratteri, contenere almeno un numero, una maiuscola, un minuscolo e un carattere speciale.',
    'Sign up': 'Iscrizione',
    'Continue as guest': 'Continua come ospite',
    'Already have an account? Login': 'Hai già un account? Accedi',
    'Ref: Registration success title': 'grazie per la registrazione',
    'Ref: Registration success content':
      "Il tuo account verrà creato presto. Riceverai un'e -mail che conferma la creazione.",
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Accedi/registrati',
    'Ref: Password strength':
      'La tua password deve essere lunga almeno 8 caratteri, contenere almeno un numero, una maiuscola, un minuscolo e un carattere speciale.',
    Stamps: 'Francobolli',
    'Order history': 'Cronologia ordini',
    'to earn points': 'per guadagnare punti',
    'to see order history': 'Per vedere la storia degli ordini',
    'to place an order': 'fare un ordine',
    'Sign up': 'Iscrizione',
    Login: 'Login',
    'Email is not valid': "L'email non è valida",
    'passwords do not match': 'Le passwords non corrispondono.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Grazie',
    'Ref: Body':
      "Hai quasi finito! Presto riceverai un'e -mail di verifica sull'indirizzo fornito. Apri questa e -mail e fai clic sul link per attivare il tuo account.",
    'Ref: Enter': 'Continua',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': "C'è stato un errore",
    'Ref: Generic Error Body': 'Riprova più tardi o contatta il tuo sportello di assistenza.',
    'Ref: Missing IndexDb support':
      "Spiacenti, non puoi accedere all'applicazione in modalità Incognito o senza supporto IndexDB. Si prega di riprovare con un altro browser.",
    'Ref: Resource not found': 'Il contenuto a cui stai cercando di accedere non esiste.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Il contenuto a cui stai cercando di accedere appartiene a un sito privato.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Il contenuto a cui stai cercando di accedere non è autorizzato per il tuo account.',
    'Ref: TabTitle': 'Errore',
    'Ref: Site link invalid':
      'Questo link non è valido, selezionare un altro sito o contattare sportello di assistenza se il problema persiste.',
    'Ref: Access Denied': 'Accesso negato. Autorizzazioni utente insufficienti.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Oops! {robot_emoji}',
    'Ref: Error title': 'Qualcosa è andato storto!',
    'Ref: Error body':
      'Prova ad aggiornare questa pagina o a contattare sportello di assistenza se il problema persiste.',
    'Ref: Error ID:': 'ID errore: {errorId}',
    'Ref: Back to home': 'Tornare a casa',
  },
  Child: {
    'Ref: Route Not Found':
      'Il servizio che stai cercando non è disponibile su questo sito. Riprova più tardi o contatta il tuo sportello di assistenza.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Congratulazioni, la tua nuova password è stata salvata con successo, ora puoi accedere a tutti i nostri servizi dal tuo account.',
    Welcome: 'Ben arrivato',
    'Ref: Welcome message':
      'Ora ti sei registrato con successo nella nuova applicazione e puoi continuare a scoprire e godere di tutti i nostri servizi.',
  },
  PasswordChange: {
    'Change Password': 'Cambia la password',
    Welcome: 'Ben arrivato',
    'Ref: Password message':
      'La tua password deve essere modificata perché non è abbastanza sicura',
  },
  AccountPasswordChange: {
    'Change your password here': 'Cambia la tua password qui',
    'Your old password': 'La tua vecchia password',
    'Type in your new password': 'Digita la tua nuova password',
    'Re-type in your new password': 'RIPETI la tua nuova password',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'La tua password è stata modificata',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Si è verificato un errore, quindi la modifica non è stata salvata. Per favore riprova.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Resetta la password',
    'Ref: Provide email':
      "Fornire l'indirizzo e -mail utilizzato quando si registra su {app_name}. Verrà inviata un'e -mail per reimpostare la password.",
    'Ref: Something happened': 'È accaduto qualcosa. Per favore riprova più tardi.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Abbiamo inviato una nuova e -mail. Si prega di attendere 30 secondi per nuovo per nuovo.',
    'Go to login page': 'Vai alla pagina di accesso',
    'Resend email': "Rinvia l'e-mail",
    'Ref: Email sent title': "È stata inviata un'e -mail di reimpostazione della password",
    'Ref: TabTitle': 'Resetta la password',
    'Ref: Email sent msg':
      'Il collegamento di ripristino è stato inviato al tuo indirizzo {email_address}. Si prega di attendere 30 secondi prima di provare a ricollegare.',
  },
  ResetPassword: { 'Enter your new password': 'Inserisci la tua nuova password' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'la tua password è stata cambiata con successo',
    'Go to login page': 'Vai alla pagina di accesso',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Reimpostazione della password non riuscire',
    'Ref: Error': 'Si è verificato un errore, riprova',
    'Reset your password': 'reimposta la tua password',
    'Go to login page': 'Vai alla pagina di accesso',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'politica sulla riservatezza',
    'Ref: Privacy Policy Body':
      "Accedendo a questa piattaforma, Sodexo SA e l'entità di Sodexo locale elaboreranno i tuoi dati personali al fine di gestire almeno il tuo account e fornire i servizi e le offerte disponibili attraverso la piattaforma. Se si desidera maggiori informazioni sull'elaborazione dei tuoi dati personali, chi gli accede e come esercitare i diritti che hai sui tuoi dati personali, consultare l'informativa sulla privacy di seguito e disponibile in qualsiasi momento del tuo account.",
    'Ref: Continue': 'Continua',
    'Ref: Read Privacy Policy': "Leggi l'informativa sulla privacy",
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Andare al negozio' },
  SharePage: { 'Ref: Select a site': 'Seleziona un sito' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      "Per visualizzare l'informativa sulla privacy, selezionare il tuo paese.",
  },
};
export default labels;
