const labels = {
  Success: {
    'Ref: Body':
      'Díky za dokončení tohoto průzkumu je váš čas cenný, protože tyto průzkumy pomáhají zlepšit naše služby.',
    'Ref: Tabtitle': 'Průzkum dokončil',
    'Take another survey': 'Vezměte další průzkum',
  },
  Survey: {
    'Ref: Page title': 'Podrobnosti o průzkumu',
    'Please answer all mandatory questions as identify by an asterisk':
      'Odpovězte prosím na všechny povinné otázky jako identifikaci hvězdičkou: *',
    'Ref: Take another survey': 'Vezměte další průzkum',
    'Ref: Go to Home': 'Jít domů',
  },
  SurveysList: {
    'Ref: Page title': 'Průzkumy',
    'Ref: Available until': 'K dispozici do',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Momentálně prohlížíte průzkumy pro web {site name}.',
  },
};
export default labels;
