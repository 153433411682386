import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import { sharedCacheVersion, getApiEndpoints } from '../../config';
import mockResponse from '../../mocks/geolocationsApi';

import { GetGeographiesResponse } from './geolocationsApi.types';

import { isMyVillage } from '@/modules/Core/helpers/helpers';
import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';
import { store } from '@/store/index';

const featuredApi = isMyVillage() ? baseApi : dspApi;
const apiEndpoints = getApiEndpoints();

const persistLocallyNonUserSpecific = {
  moduleId: 'Shared',
  cacheVersion: sharedCacheVersion,
  userSpecific: false,
};

const geolocationsApi = featuredApi
  .enhanceEndpoints({ addTagTypes: ['geolocations'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getGeographies: build.query<GetGeographiesResponse, void>({
        query: () => ({
          url: apiEndpoints.getGeographies,
          method: 'GET',
          headers: {
            RegionCode: 'EU',
          },
          mockData: mockResponse.getGeographies,
        }),
        transformResponse: (response: GetGeographiesResponse) => {
          if (response !== undefined) {
            if (response.geolocationCode) {
              const currentGeography = response.geolocations.find(
                (el) => el.code === response.geolocationCode
              );
              localStorage.setItem(
                LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE,
                currentGeography?.regionCode || ''
              );
            }

            store.dispatch({
              type: 'GEOGRAPHIES_RETURNED',
              defaultGeoCode: response.geolocationCode,
              geographiesList: response.geolocations,
              persistLocally: persistLocallyNonUserSpecific,
            });
          }

          return response;
        },

        providesTags: ['geolocations'],
      }),
    }),
    overrideExisting: true,
  });

export const { useGetGeographiesQuery, useLazyGetGeographiesQuery } = geolocationsApi;
export default geolocationsApi;
