import { render } from '@testing-library/react';
import { act } from 'react';

import '../../localization/i18n';

import { addProviders } from './addProviders';

function renderComponent(
  WrappedComponent: React.ComponentType<any>,
  props?: any,
  state?: any,
  history?: any
) {
  beforeEach(async () => {
    await act(async () => {
      renderedComponent(WrappedComponent, props, state, history);
    });
  });
}

export const renderedComponent = (
  WrappedComponent: React.ComponentType<any>,
  props?: any,
  state?: any,
  history?: any
) =>
  render(
    addProviders(<WrappedComponent {...props} />, {
      state,
      history,
    })
  );

export default renderComponent;
