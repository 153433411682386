import React from 'react';
import { ReactReduxContext } from 'react-redux';

/**
 * @deprecated The function should not be used, use useSite hook instead
 */
const withSite = (WrappedComponent) => {
  return (props) => {
    return (
      <ReactReduxContext.Consumer>
        {({ store }) => {
          return <WrappedComponent site={store.getState().Core?.context?.site} {...props} />;
        }}
      </ReactReduxContext.Consumer>
    );
  };
};

export default withSite;
