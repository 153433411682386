const labels = {
  TimeTable: {
    'leave at': '離開',
    'arrive at': '到達',
    at: '在',
    'Ref: list placeholder': '填寫表格以查看小時',
    'Ref: Page title': '公共汽車',
    'Ref: Departure': '離開',
    'Ref: Arrivals': '到達',
    'Ref: minutes': '分鐘',
    'Ref: no items placeholder': '抱歉，目前沒有公共汽車可用。',
  },
};
export default labels;
