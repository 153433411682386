import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useEffect } from 'react';

import { TrackContentViewsProps } from '../types';

const useTrackContentViews = (props: TrackContentViewsProps) => {
  const { title, site, contract, language, moduleId } = props;
  const appInsights = useAppInsightsContext();

  const trackContentViews = useCallback(() => {
    if (title) {
      let eventLog = {
        properties: {
          siteId: site?.id,
          siteName: site?.name,
          contentTitle: title,
          contractId: contract?.id,
          contractName: contract?.name,
          language: language,
          appName: process.env.REACT_APP_APP_NAME,
          appVersion: process.env.REACT_APP_BUILD_NUMBER,
          appPackage: process.env.REACT_APP_MOBILE_PACKAGE_NAME,
          module: moduleId,
        },
      };
      appInsights.trackPageView(eventLog);
    }
  }, [appInsights, contract, language, moduleId, site?.id, site?.name, title]);

  useEffect(() => {
    trackContentViews();
  }, [trackContentViews]);
};
export default useTrackContentViews;
