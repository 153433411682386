import classNames from 'classnames';

import { ServiceRequestStatusProps, SERVICE_REQUEST_STATUS } from './Status.types';

import { CheckIcon, RefreshIcon } from '@/assets/icons';
import Status from '@/components/molecules/Status';

import styles from './Status.module.css';

const statusStyling = {
  [SERVICE_REQUEST_STATUS.CLOSED]: {
    icon: <CheckIcon />,
    className: classNames(styles.service_request_status_closed, styles.statusIcon),
  },
  [SERVICE_REQUEST_STATUS.OPEN]: {
    icon: <RefreshIcon />,
    className: classNames(styles.service_request_status_open, styles.statusIcon),
  },
  [SERVICE_REQUEST_STATUS.PENDING]: {
    icon: <RefreshIcon />,
    className: classNames(styles.service_request_status_pending, styles.statusIcon),
  },
};

const ServiceRequestStatus = ({ status, displayStatus }: ServiceRequestStatusProps) => {
  if (!status) return null;
  const { icon, className } = statusStyling[status];
  return (
    <Status
      icon={icon}
      status={displayStatus}
      inheritStyle={className}
      data-testid="service-request-status"
      data-cy="service-request-status"
    />
  );
};

export default ServiceRequestStatus;
