const labels = {
  ContentDetails: {
    'Ref: Page title': 'Détails du contenu',
    'See the event': "Voir l'événement",
    'Take the survey': "Participer à l'enquête",
    Date: 'Date',
    Category: 'Catégorie',
    'Ref: Content site different title': "Ce contenu n'appartient pas à votre site actuel",
    'Ref: Content site different description':
      "Vous consultez un contenu qui n'appartient pas à votre site actuel. Vous ne pourrez pas le voir dans la liste du contenu.",
  },
  ContentList: {
    'Ref: Page title': "Fil d'actualité",
    'featured (as in featured content)': 'A la une',
    'Ref: category - all': 'Tout',
    'Ref: category - promotional': 'Promotionnel',
    'Ref: category - featured': 'Mis en exergue',
    'Ref: category - other': 'Autre',
  },
  Widget: {
    'Ref: Carousel title': 'Articles',
    'Ref: Carousel title promo': 'Actualité à la Une',
    'No info yet, come back later':
      'Aucune information disponible pour le moment, revenez plus tard 😉',
    'Ref: Content': 'Actualités',
    'Ref: Widget Title': "Fil d'actualité",
    'Ref: MyVillage Carousel title promo': 'Promotions',
  },
};
export default labels;
