import classNames from 'classnames';

import { FormProps } from './Form.types';

import styles from './Form.module.css';

const Form = ({
  children,
  onSubmit,
  noValidate = true,
  'data-cy': dataCy = 'form',
  'data-testid': dataTestId = 'form',
}: FormProps) => {
  return (
    <form
      onSubmit={onSubmit}
      className={classNames(styles.form)}
      data-cy={dataCy}
      data-testid={dataTestId}
      noValidate={noValidate}
    >
      {children}
      {/* This is here to easily enable form submission by pressing enter key */}
      <input
        type="submit"
        className={classNames(styles.submitButton)}
        data-testid={`${dataTestId}-submit`}
      />
    </form>
  );
};

export default Form;
