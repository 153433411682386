const labels = {
  All: {
    'starts at': 'kezdődik',
    'ends at': 'véget ér',
    location: 'Helyszin',
    'coming date': 'eljövendő dátum',
    'Ref: Currently event not preferred. Click to make preferred':
      'Jelenleg az esemény nem részesül előnyben. Kattintson a kattintáshoz',
    'Ref: Currently event preferred. Click to make not preferred':
      'Jelenleg az esemény előnyben részesített. Kattintson, hogy ne részesítse előnyben',
  },
  EventDetails: {
    details: 'Részletek',
    'Ref: Page title': 'esemény részletei',
    'Event dates': 'Esemény dátumok',
    'See online:': 'Lásd az online:',
    'See all dates': 'Lásd az összes dátumot',
    'Remove from my selection': 'Távolítsa el a választékomat',
    'Add to my selection': 'Add hozzá a preferált',
    'Ref: explain calendar usage':
      'Az esemény dátumait az alábbi naptárban kiemelik. Ne hagyja ki az eseményeket, amiket szeretsz! A meghívás letöltéséhez kattintson egy eseménynapra a naptárban. Ezután nyissa meg a letöltött fájlt, hogy hozzáadja az eseményt az előkészített naptárhoz.',
    'Ref: Favorite': 'Kedvenc',
    'Ref:Feedback': 'Visszacsatolás',
    'Ref: Event site different title': 'Ez az esemény nem tartozik az Ön jelenlegi webhelyéhez',
    'Ref: Event site different description':
      'Olyan eseményt néz ki, amely nem tartozik a jelenlegi webhelyéhez. Nem fogja látni az események listáján.',
    'Ref: See all dates': 'Lásd az összes dátumot',
    'Ref: From': 'Tól től',
    'Ref: to': 'nak nek',
    'Ref: Coming date': 'Eljövendő dátum',
    'Ref: Add to calendar': 'Adja hozzá a naptárhoz',
  },
  EventsList: {
    'Ref: Page title': 'Események',
    'All dates': 'Minden dátum',
    'All events': 'Minden esemény',
    'Ref: Search': 'Keresés',
    'My selection': 'Az én előnyben részesítettem',
    'filter label: date': 'Dátum',
    'filter label: coming events': 'Jövő események',
    'filter label: past events': 'múltbéli események',
    'filter label: interest': 'preferencia',
  },
  Widget: {
    'Ref: Carousel title': 'Események',
    'No info yet, come back later': 'Még nincs információ, gyere vissza később 😉',
  },
};
export default labels;
