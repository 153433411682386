import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
import { get } from 'lodash';

import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { ISite } from '../../Sites/types/sites.types';
import { eventsConfig } from '../config';
import { EventPreference, EventType } from '../types';

import {
  GetEventArgs,
  GetEventResponse,
  GetEventsArgs,
  PreferenceMutationData,
  ReactionMutationData,
} from './api.types';

import { getImagesSrcFromIds } from '@/helpers/images/images.helper';
import { getImageSrcFromInfo } from '@/helpers/misc';
import { ReactionsAPIType } from '@/types/reactions.types';

const {
  api,
  baseUrl,
  getEventDetailsUrl,
  getEventDetailsParams,
  getEventsListUrl,
  getEventsListParams,
  reactionsUrl,
  updateReactionParams,
  deleteReactionParams,
} = eventsConfig();
const eventsApi = api.enhanceEndpoints({ addTagTypes: ['events'] }).injectEndpoints({
  endpoints: (build) => ({
    getEvent: build.query<GetEventResponse, GetEventArgs>({
      queryFn: async ({ id, siteId }: GetEventArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl });

        const response = await baseQuery({
          url: getEventDetailsUrl(id),
          method: 'get',
          params: getEventDetailsParams(id),
        });

        if (response.data?.images?.[0]) {
          response.data.mainImage = getImageSrcFromInfo(response.data?.images[0]);
        }

        const result = response.data || {};

        const sites = get(result, 'sites', []) ?? [];

        const onDifferentSite =
          sites.length > 0 && sites[0].id
            ? !sites.find((site: ISite) => site.id === siteId)
            : typeof sites[0] == 'string' && !sites.find((site: String) => site === siteId);

        return {
          data: {
            eventDetails: result,
            notFound: response?.error?.status === 400,
            onDifferentSite: onDifferentSite,
            permissionDenied: result?.error?.code || response?.error?.status === 403,
          },
        };
      },
      providesTags: (result) => [{ type: 'events', id: result?.eventDetails.id }],
    }),
    getEvents: build.query<EventType[], GetEventsArgs>({
      queryFn: async ({ useErrorBoundary, ...rest }: GetEventsArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl });

        const eventsResponse = await baseQuery({
          url: getEventsListUrl,
          method: 'get',
          params: getEventsListParams(rest),
          opts: { useErrorBoundary },
        });
        if (eventsResponse.error) return { error: eventsResponse.error };
        const { data: { events } = {} } = eventsResponse;

        if (!events) return { data: [] };

        const imageIds: string[] = events
          .filter((event: EventType) => !!event.images?.[0])
          .map((event: EventType) => event.images?.[0]);

        const images = await getImagesSrcFromIds(imageIds);

        return {
          data: events.map((event: EventType) => {
            const imageId = event.images?.[0] as string;
            const image = images.find((img) => img.id === imageId);

            return {
              ...event,
              mainImage: image?.content,
            };
          }),
        };
      },
      providesTags: ['events'],
    }),
    getEventsPreferences: build.query<EventPreference[], {}>({
      queryFn: async () => {
        const baseQuery = axiosBaseQuery({ baseUrl });

        const preferencesResponse = await baseQuery({
          url: '/v2/preferences',
          method: 'get',
          params: {},
        });

        const { data: { preferences } = {} } = preferencesResponse;

        return {
          data: preferences,
        };
      },
      providesTags: ['events'],
    }),
    updateReaction: build.mutation<ReactionsAPIType, ReactionMutationData>({
      query: ({ id, reactionType }) => ({
        url: reactionsUrl(id),
        method: 'post',
        data: updateReactionParams({ id, reactionType }),
      }),
      async onQueryStarted({ siteId, id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          eventsApi.util.updateQueryData('getEvents', { siteId }, (draft) => {
            return draft.map((event: EventType) =>
              event.id === id
                ? { ...event, reactions: { ...event.reactions, me: patch.reactionType } }
                : event
            );
          })
        );
        const patchDetailResult = dispatch(
          eventsApi.util.updateQueryData('getEvent', { id, siteId }, (draft) => {
            return draft
              ? {
                  ...draft,
                  eventDetails: {
                    ...draft.eventDetails,
                    reactions: { ...draft.eventDetails.reactions, me: patch.reactionType },
                  },
                }
              : draft;
          })
        );
        queryFulfilled.catch(patchResult.undo);
        queryFulfilled.catch(patchDetailResult.undo);
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'events', id }, { type: 'events' }],
    }),
    toggleInterest: build.mutation<ReactionsAPIType, PreferenceMutationData>({
      query: ({ eventId, isUserInterested }) => ({
        url: `/v1/events/${eventId}/user-interested`,
        method: 'post',
        data: {
          isUserInterested: !isUserInterested,
        },
      }),
      async onQueryStarted(
        { siteId, eventId, isUserInterested },
        { dispatch, queryFulfilled, getState }
      ) {
        const listPatches = [];
        for (const { endpointName, originalArgs } of eventsApi.util.selectInvalidatedBy(
          getState() as RootState<any, any, 'dspApi' | 'api'>,
          [{ type: 'events' }]
        )) {
          if (endpointName !== 'getEvents') continue;

          listPatches.push(
            dispatch(
              eventsApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
                return draft.map((event: EventType) =>
                  event.id === eventId ? { ...event, isUserInterested: !isUserInterested } : event
                );
              })
            )
          );
        }
        const patchDetailResult = dispatch(
          eventsApi.util.updateQueryData('getEvent', { id: eventId, siteId }, (draft) => {
            return draft
              ? {
                  ...draft,
                  eventDetails: {
                    ...draft.eventDetails,
                    isUserInterested: !isUserInterested,
                  },
                }
              : draft;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          listPatches.forEach((patch) => queryFulfilled.catch(patch.undo));
          queryFulfilled.catch(patchDetailResult.undo);
        }
      },
      invalidatesTags: (result, error, { eventId }) => [
        { type: 'events', eventId },
        { type: 'events' },
      ],
    }),
    deleteReaction: build.mutation<ReactionsAPIType, ReactionMutationData>({
      query: ({ id }) => ({
        url: reactionsUrl(id),
        method: 'delete',
        data: deleteReactionParams(id),
      }),
      async onQueryStarted({ id, siteId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          eventsApi.util.updateQueryData('getEvents', { siteId }, (draft) => {
            return draft.map((event: EventType) =>
              event.id === id ? { ...event, reactions: { ...event.reactions, me: null } } : event
            );
          })
        );
        const patchDetailResult = dispatch(
          eventsApi.util.updateQueryData('getEvent', { id, siteId }, (draft) => {
            return draft
              ? {
                  ...draft,
                  eventDetails: {
                    ...draft.eventDetails,
                    reactions: { ...draft.eventDetails.reactions, me: null },
                  },
                }
              : draft;
          })
        );
        queryFulfilled.catch(patchResult.undo);
        queryFulfilled.catch(patchDetailResult.undo);
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'events', id }, { type: 'events' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetEventQuery,
  useGetEventsQuery,
  useGetEventsPreferencesQuery,
  useUpdateReactionMutation,
  useDeleteReactionMutation,
  useToggleInterestMutation,
} = eventsApi;
export default eventsApi;
