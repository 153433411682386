import { ToastItem, toast } from 'react-toastify';

import { trackToasterDisplay } from '@/helpers/analytics';

toast.onChange((payload: ToastItem) => {
  if (payload.status === 'added') {
    trackToasterDisplay(
      payload.type?.toString() || 'undefined toast type',
      payload.content?.toString() || 'undefined toast content',
      window.location?.pathname
    );
  }
});

export default toast;
