import * as H from 'history';

import {
  ImageDefaultFoodNoRetailImg,
  ImageDefaultFoodRetailImg,
  ImageDefaultNoFoodNoRetailImg,
  ImageDefaultNoFoodRetailImg,
} from '../../../assets/images';
import { openUrl } from '../../../helpers/misc';
import { TranslationProps } from '../../../localization/localization.types';
import { SERVICE } from '../../config';
import { Service } from '../../Core/types/State.types';
import { modulePath as orderModulePath } from '../../Order/config';
import { modulePath as shopModulePath } from '../../Shop/config';
import { redirectToMenu } from '../config';
import { FACILITY_TYPE, FacilityDataItem, FacilityItem, FacilityType } from '../types/types';

export const getImageFromType = (type: FacilityType) => {
  switch (type) {
    case FacilityType.FoodRetail: {
      return <ImageDefaultFoodRetailImg />;
    }
    case FacilityType.FoodNonRetail: {
      return <ImageDefaultFoodNoRetailImg />;
    }
    case FacilityType.NonFoodNonRetail: {
      return <ImageDefaultNoFoodNoRetailImg />;
    }
    case FacilityType.NonFoodRetail: {
      return <ImageDefaultNoFoodRetailImg />;
    }
    default: {
      return <ImageDefaultNoFoodNoRetailImg />;
    }
  }
};

const getFacilityOfferingPath = (
  facilityId: string,
  isShopModuleAvailable: boolean,
  facilityType: FacilityType
) => {
  return facilityType === FacilityType.FoodNonRetail
    ? isShopModuleAvailable
      ? shopModulePath
      : redirectToMenu(facilityId)
    : orderModulePath;
};

export const getOfferingPathAndRedirect = ({
  facilityId,
  history,
  services,
  externalUrl,
  facilityType,
}: {
  facilityId: string;
  history: H.History<unknown>;
  services: Service[];
  externalUrl?: string | null;
  facilityType: FacilityType;
}) => {
  const isShopModuleAvailable = services?.some((x) => x.name === SERVICE.FOOD_MENUS);
  return externalUrl
    ? openUrl(externalUrl)
    : history.push(getFacilityOfferingPath(facilityId, isShopModuleAvailable, facilityType));
};

export const mapToFacilityItems = (
  facilities: FacilityDataItem[],
  label: TranslationProps['label']
): FacilityItem[] => {
  return facilities.map((facility) => {
    const facilityType = FACILITY_TYPE[facility?.facilityType?.name] ?? FacilityType.Undefined;

    return {
      id: facility.id,
      imgInfo:
        facility.images?.length > 0
          ? {
              src: facility.images[0],
              alt: facility.title,
            }
          : undefined,
      facilityType,
      title: facility.title,
      addendum: label('Ref: See details'),
      'data-testid': `facility-list-item-${facility.id}`,
      externalUrl: facility.externalUrl || undefined,
      viewCounts: facility.viewCounts,
    };
  });
};
