const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Apgyvendinimo prašymas',
    'Ref: Describe your request': 'Apibūdinkite savo prašymą',
    'Ref: Confirm your request': 'Patvirtinkite savo prašymą',
    'Ref: Your request': 'Jūsų užklausa',
    'Ref: Summary': 'Santrauka',
    'Ref: Next': 'Kitas',
    'Ref: Submit': 'Pateikti',
    'Ref: Me': 'Aš',
    'Ref: Arrival date': 'Atvykimo data',
    'Ref: Arrival time': 'Atvykimo laikas',
    'Ref: Departure date': 'Išvykimo data',
    'Ref: Departure time': 'Išvykimo laikas',
    'Ref: request raised for': 'Asmuo, paveiktas šio prašymo',
    'Ref: phoneNumber': 'Telefono numeris',
    'Ref: arrivalDate': 'Atvykimas',
    'Ref: departureDate': 'Išvykimas',
    'Ref: description': 'Komentaras',
    'Ref: open': 'Atviras',
    'Ref: pending': 'Kol',
    'Ref: refused': 'Atsisakė',
    'Ref: closed': 'Uždaryta',
    'Ref: all': 'Viskas',
    'Ref: Arrival': 'Atvykimas',
    'Ref: Validate': 'Patvirtinti',
    'Ref: site': 'Svetainės vieta',
    'Ref: Update us': 'Atnaujinkite mus',
    'Ref: email of guest': 'Svečio el',
    'Ref: GuestAccommodationRequestTitle': 'Svečio prašymas',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Naujas prašymas',
    'Ref: Status': 'Būsena',
    'Ref: Date': 'Data',
    'Ref: Coming arrivals': 'Atvykimas',
    'Ref: Past arrivals': 'Praeities atvykimai',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Telefono svečių numeris',
    'Ref: Your phone number': 'Jūsų telefono numeris',
    'Ref: The phone number': 'Telefono numeris',
    'Ref: PhoneNumber error msg': 'Telefono numeris negalioja',
    'Ref: Comment error msg': 'Maksimalus simbolių skaičius yra 250',
    'Ref: Guest first name': 'Svečio vardas',
    'Ref: Guest last name': 'Svečio pavardė',
    'Ref: request for': 'Kas yra šis prašymas?',
    'Ref: request for me': 'Aš',
    'Ref: request for guest': 'Kažkas kitas',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Apgyvendinimo prašymo informacija',
    'Ref: Timeline': 'Laiko juosta',
    'Ref: No updates placeholder': 'Šios užklausos atnaujinimų nėra',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Ačiū',
    'Ref: Success text': 'Gavome jūsų prašymą ir dabar ieškome jo. Netrukus susisieksime su jumis.',
    'Ref: Go to home': 'Eiti namo',
    'Ref: Create another request': 'Sukurkite kitą užklausą',
  },
  CreateError: {
    'Ref: Create error title': 'Oi!',
    'Ref: Create error text': 'Įvyko klaida, bandykite dar kartą.',
    'Ref: Retry': 'Bandyk iš naujo',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Atnaujinkite mus su bet kokiu nauju elementu apie jūsų užklausą, kad galėtume geriausiai jus palaikyti.',
  },
};
export default labels;
