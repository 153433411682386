import { dspApiPath, apiPath } from '../../../config';
import { APP_NAME } from '../../../constants';
import { getAppSpecificData } from '../../../helpers/misc';
import { serviceTypes, modulesConfig } from '../../config';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

export const moduleId = 'ContentPage';

export const cacheVersion = '0';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

// if ReactRouter is upgraded to version 6 - > this entire approach should change to use new hoo useRoutes
//https://reactrouter.com/en/main/hooks/use-routes
export const mainDetailPagePath = `${modulePath}/:navItemName`;
export const childrenPageDetailPath = `${modulePath}/:navItemName/:title-id:id`;

export const pagePaths = {
  Module: modulePath,
  Details: [mainDetailPagePath, childrenPageDetailPath],
};

export const contentPageConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      apiPath: (_id: string) => `/v2/site/contentpage`,
      apiParams: (id: string) => ({ id }),
      api: baseApi,
      baseUrl: apiPath,
      showChildrenPagesList: false,
    },
    default: {
      apiPath: (id: string) => `/v1/contentpages/${id}`,
      apiParams: (_id: string) => undefined,
      api: dspApi,
      baseUrl: dspApiPath,
      showChildrenPagesList: true,
    },
  };

  return getAppSpecificData<{
    apiPath: (id: string) => string;
    apiParams: (id: string) => object | undefined;
    api: typeof baseApi | typeof dspApi;
    baseUrl: string;
    showChildrenPagesList: boolean;
  }>(configuration);
};
