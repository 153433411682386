import { useHistory, useLocation } from 'react-router';

import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import { useCoreTranslation } from '../hooks/useCoreTranslation';
import { IUrlLocation } from '../types/guestRegistrationForm.types';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const GuestRegistrationSuccess = () => {
  const location: IUrlLocation = useLocation();
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);

  return (
    <NoticePage
      withNavBar={false}
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('Ref: Header', { textTransform: 'capitalize' })}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('Ref: Enter'),
          action: () => {
            history.push({ pathname: location?.state?.from.pathname || '/' });
          },
        },
      ]}
    />
  );
};

export default GuestRegistrationSuccess;
