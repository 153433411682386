import { FC } from 'react';

import { MiniWidgetContainerProps } from './MiniWidgetContainer.types';

import styles from './MiniWidgetContainer.module.css';

const MiniWidgetContainer: FC<MiniWidgetContainerProps> = ({
  id,
  children,
  'data-testid': dataTestId = 'miniWidgetContainer',
}) => {
  return (
    <div className={styles.container} data-testid={dataTestId} id={id}>
      {children}
    </div>
  );
};

export default MiniWidgetContainer;
