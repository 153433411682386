import React, { useMemo } from 'react';

import Button from '../../atoms/Button';

import { ButtonPopoverList } from './ButtonPopoverList';
import { TileActionsProps } from './Tile.types';

export const TileActions: React.FC<TileActionsProps> = ({
  actions,
  moreActionText,
  popoverPosition,
  actionsCollapseAfter = 3,
  'data-testid': dataTestId = 'tile-actions',
}) => {
  const { firstActionName, firstActionIcon, firstCustomAction, firstActionProps, restActions } =
    useMemo(() => {
      if (actions.length === 0) return {};

      const [firstAction, ...restActions] = actions;
      const {
        name: firstActionName,
        icon: firstActionIcon,
        customAction: firstCustomAction,
        ...firstActionProps
      } = firstAction;

      return { firstActionName, firstActionIcon, firstCustomAction, firstActionProps, restActions };
    }, [actions]);

  if (actions.length === 0) {
    return null;
  }

  if (actions.length < actionsCollapseAfter) {
    return (
      <>
        {actions.map(({ name, icon, customAction, ...actionProps }, i) => (
          <React.Fragment key={name}>
            {customAction ? (
              customAction
            ) : (
              <Button
                look="tertiary"
                srOnlyText={name}
                {...actionProps}
                data-testid={`${dataTestId}-${i}`}
              >
                {icon}
              </Button>
            )}
          </React.Fragment>
        ))}
      </>
    );
  }

  if (actionsCollapseAfter < 1) {
    return (
      <>
        <ButtonPopoverList
          popoverPosition={popoverPosition}
          list={actions}
          srOnlyText={moreActionText}
          data-testid={`${dataTestId}-tile-actions`}
        />
      </>
    );
  }

  return (
    <>
      <ButtonPopoverList
        popoverPosition={popoverPosition}
        list={restActions}
        srOnlyText={moreActionText}
        data-testid={`${dataTestId}-more`}
      />
      {firstCustomAction ? (
        firstCustomAction
      ) : (
        <Button
          key={firstActionName}
          look="tertiary"
          srOnlyText={firstActionName}
          data-testid={`${dataTestId}-first`}
          {...firstActionProps}
        >
          {firstActionIcon}
        </Button>
      )}
    </>
  );
};
