import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { pagePaths } from '../../config';
import { getImageFromType, getOfferingPathAndRedirect } from '../../helpers/facilities.helpers';
import { FacilityTileProps, FacilityType } from '../../types/types';

import { NavArrowRightIcon } from '@/assets/icons';
import { StoreIcon } from '@/assets/index';
import ImageWithFallback from '@/components/atoms/ImageWithFallback/ImageWithFallback';
import { TILE_VARIANT, Tile, TileAction } from '@/components/molecules/Tile';
import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { State } from '@/types/state.types';
import { UserSteps } from '@/types/userSteps.types';

const FacilityTile: React.FC<FacilityTileProps> = ({
  id,
  title,
  imgInfo,
  facilityType,
  label,
  externalUrl,
  focusOnRender = false,
  onTileClickCallback,
  showOfferingIcon = true,
}) => {
  const history = useHistory();
  const enabledServices = useSelector((state: State) => state.Core.services.list);
  const { logUserSteps } = useUserStepsInsightsLogging();

  const facilityDetailsPath = useMemo(() => pagePaths['FacilityDetails'].replace(':id', id), [id]);

  const onTileClick = () => {
    if (onTileClickCallback) {
      onTileClickCallback(id);
    } else {
      logUserSteps({ event: UserSteps.FacilityDetailsPageOpened });
      history.push(facilityDetailsPath);
    }
  };

  const actions = useMemo(() => {
    const result: TileAction[] = [];

    if (
      showOfferingIcon &&
      (externalUrl || (facilityType && facilityType !== FacilityType.NonFoodNonRetail))
    ) {
      result.push({
        name: label('see the offering'),
        icon: (
          <StoreIcon
            title={label('see the offering', { textTransform: 'capitalize' })}
            data-testid={`store-icon-${id}`}
          />
        ),
        onClick: () => {
          logUserSteps({ event: UserSteps.OrderPageRedirectionTriggered });
          getOfferingPathAndRedirect({
            facilityId: id,
            history,
            facilityType,
            externalUrl,
            services: enabledServices,
          });
        },
        'data-testid': `facility-tile-shot-action-${id}`,
      });
    }

    if (onTileClickCallback) {
      result.push({
        name: label('Ref: Navigation arrow - Continue'),
        icon: <NavArrowRightIcon />,
        onClick: () => onTileClickCallback(id),
        'data-testid': `facility-action-${id}`,
      });
    }

    return result;
  }, [
    enabledServices,
    externalUrl,
    facilityType,
    history,
    id,
    label,
    logUserSteps,
    onTileClickCallback,
    showOfferingIcon,
  ]);

  const fallbackImg = getImageFromType(facilityType);
  const image = (
    <ImageWithFallback data-testid="facility-tile" imgInfo={imgInfo} imgElement={fallbackImg} />
  );
  return (
    <Tile
      variant={TILE_VARIANT.STANDARD}
      id={id}
      title={title}
      onClick={onTileClick}
      image={image}
      actions={actions}
      data-testid={`facility-tile-${id}`}
      focusOnRender={focusOnRender}
    />
  );
};

export default FacilityTile;
