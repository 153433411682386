const labels = {
  SiteDetails: {
    'Select site': 'Seleccione este centro',
    'Ref: Page title': 'Detalles del sitio',
    'Ref: Skip map': 'Saltar al contenido principal',
    'Ref: Unavailable site': 'Vista previa del sitio no disponible para su usuario',
    'Ref: Sorry, no description yet': 'Lo siento, todavía no tenemos una descripción.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Coordenadas del centro no encontradas',
    'Ref: Page title': 'Sitios',
    'Recently visited': 'Centros visitados recientemente',
    'All other sites': 'Todos los demás centros',
    'Sites in your country': 'Sitios de tu país',
    'Search results': 'Resultados de la búsqueda',
    'Select site': 'Seleccione un centro',
    'Ref: Select site with QR Scanner': 'Seleccione el sitio con el escáner QR',
    'Ref: Select site with code': 'Seleccione Sitio con código',
    'Ref: Validate': 'Validar',
    'Ref: Enter site code': 'Introduzca el código del centro',
    'Ref: Scanner error header': 'Centro no encontrado',
    'Ref: Generic error': 'Algo salió mal',
    'Ref: Change site': 'Sitio de cambio',
    'Ref: Change site description':
      '¡Hay artículos en el carrito! Cambiar el sitio dará como resultado la limpieza del carrito.',
    'Ref: Cancel': 'Cancelar',
    'Ref: Not found': 'Este código QR no ha sido reconocido',
    'Ref: Unauthorized error': 'Solicitud no autorizada debido al nivel de restricción del centro',
    'Ref: Forbidden':
      'Lo siento, no tiene los permisos adecuados para acceder a este sitio. Para organizar el acceso, comuníquese con el Centro de Servicio de Pilbara al 1800 992 777',
    "Ref: We didn't find the site.": 'No encontramos el sitio.',
    'Ref: We recommend some solutions:': 'Recomendamos algunas soluciones:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Intente agregar su sitio con el escáner QR o con la ayuda de los botones a continuación',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Iniciar sesión y volver a iniciar sesión seleccionando el país correcto',
    'Ref: The currently selected country is:': 'El país seleccionado actualmente es:',
  },
  SwitchSite: { 'Not on {site name}?': '¿No está en {site name}?' },
  SiteTile: { Details: 'Detalles', Select: 'Seleccione' },
};
export default labels;
