const labels = {
  StaysList: {
    'Ref: Page title': '留下來',
    'Ref: Past': '過去的',
    'Current and future stays': '當前和未來的住宿',
    'filter label: period': '時期',
    'Ref: Search': '搜索',
  },
  StayDetails: {
    'Ref: Page title': '你的住宿詳情',
    'Arrival date': '到達日期',
    'Departure date': '出發日期',
    'Room number': '房間',
    'Reservation number': '預定編號',
    CheckIn: '辦理入住',
    CheckOut: '辦理退房',
    CheckIn_1: '調查{lodgeName}，今天到達？ （預留號碼{resNumber}）',
    CheckedIn_1: '您已入住{lodgeName}，房間{roomNumber}。',
    CheckOut_1: '{lodgeName}預定編號{resNumber}，現在退房？您的房間鑰匙將被停用。',
    CheckedOut_1: '{lodgeName}預定編號{resNumber}已退房。',
  },
  StaysSuccess: {
    'Ref: Title': '謝謝您的住宿！',
    'Ref: Body': '您的結帳已成功處理。',
    'Ref: Go to home': '回家',
    'Ref: Provide a feedback': '提供反饋',
  },
};
export default labels;
