const labels = {
  SiteDetails: {
    'Select site': 'Valitse tämä sivusto',
    'Ref: Page title': 'Sivustotiedot',
    'Ref: Skip map': 'Siirry pääsisältöön',
    'Ref: Unavailable site': 'Sivuston esikatselu ei ole käytettävä käyttäjällesi',
    'Ref: Sorry, no description yet': 'Valitettavasti meillä ei ole vielä kuvausta.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Sivuston koordinaatteja ei löydy',
    'Ref: Page title': 'Sivustot',
    'Recently visited': 'Viimeksi vieraillut',
    'All other sites': 'Kaikki muut sivustot',
    'Sites in your country': 'Sivustot maassasi',
    'Search results': 'Hakutulokset',
    'Select site': 'Valitse sivusto',
    'Ref: Select site with QR Scanner': 'Valitse sivusto QR -skannerilla',
    'Ref: Select site with code': 'Valitse sivusto koodilla',
    'Ref: Validate': 'Vahvistaa',
    'Ref: Enter site code': 'Kirjoita sivustokoodi',
    'Ref: Scanner error header': 'Sivustoa ei löydy',
    'Ref: Generic error': 'Jotain meni pieleen',
    'Ref: Change site': 'Muutospaikka',
    'Ref: Change site description':
      'Kärryssä on esineitä! Sivuston vaihtaminen johtaa kärryn tyhjentämiseen.',
    'Ref: Cancel': 'Peruuttaa',
    'Ref: Not found': 'Tätä QR -koodia ei tunnistettu',
    'Ref: Unauthorized error': 'Sivuston rajoittamisen takia luvaton pyyntö',
    'Ref: Forbidden':
      'Valitettavasti sinulla ei ole oikeita käyttöoikeuksia käyttää tätä sivustoa. Jos haluat järjestää pääsyn, ota yhteyttä Pilbara Service Centeriin numeroon 1800 992 777',
    "Ref: We didn't find the site.": 'Emme löytäneet sivustoa.',
    'Ref: We recommend some solutions:': 'Suosittelemme joitain ratkaisuja:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Yritä lisätä sivustosi QR -skannerilla tai alla olevien painikkeiden avulla',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Kirjaudu ulos ja kirjaudu sisään valitsemalla oikea maa',
    'Ref: The currently selected country is:': 'Nykyinen valittu maa on:',
  },
  SwitchSite: { 'Not on {site name}?': 'Ei {site name}?' },
  SiteTile: { Details: 'Yksityiskohdat', Select: 'Valitse' },
};
export default labels;
