const labels = {
  TimeTable: {
    'leave at': 'Tinggalkan di',
    'arrive at': 'Tiba di',
    at: 'pada',
    'Ref: list placeholder': 'Lengkapi formulir untuk melihat jam',
    'Ref: Page title': 'Bis-bis',
    'Ref: Departure': 'Keberangkatan',
    'Ref: Arrivals': 'Kedatangan',
    'Ref: minutes': 'menit',
    'Ref: no items placeholder': 'Maaf, tidak ada bus yang tersedia saat ini.',
  },
};
export default labels;
