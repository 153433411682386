const labels = {
  All: {
    'Ref: See item': 'Lásd',
    'Ref: Print': 'Nyomtassa ki a nyugtát',
    'Ref: Email': 'Kapja meg a nyugtát e-mailben',
    'Ref: Receipt Modal message': 'Hogyan szeretné megszerezni a nyugtát?',
    'Ref: Loyalty Reward': 'Hűség jutalom',
    'Ref: Equation for stamps per item': '1 vásárlás = 1 bélyegző',
    'Ref: Paid by employer': 'A munkáltató által fizetett összeg',
    'Ref: Paid by user': 'Az Ön által fizetett összeg',
  },
  ProductsList: {
    'Ref: Page title': 'Terméklista',
    'Ref: Facility details': 'Létesítmény részletei',
    'Ref: Search products list': 'Keresési elemek',
    'Ref: filter label: menus': 'Menü',
    'Ref: filter label: moment': 'Pillanat',
    'Ref: filter label: category': 'Kategória',
    'Ref: all': 'Összes',
    'Ref: today': 'Ma',
    'Ref: tomorrow': 'Holnap',
    'Ref: see more': 'Mutass többet',
    'Ref: see less': 'Kevesebbet lát',
    'Ref: reset': 'Visszaállítás',
    'Ref: View in cart': 'Kilátás a kosárban',
    'Ref: You have changed location': 'Megváltoztatta a helyét',
    'Ref: You are now at': 'Most vagy',
    'Ref: Menu card': 'Menütkártya',
    'Ref: Table': 'Táblázatszám',
    'Service unavailable': 'A szolgáltatás nem elérhető',
    'Ref: Ordering unavailable':
      'Óvatos! Jelenleg nem érhető el ezen a webhelyen megrendelés, kérjük, próbálja meg később.',
    'Ref: Filters': 'Szűrők (allergének és még sok más)',
  },
  Order: { 'Ref: Page title': 'Rendelés' },
  MenuSelector: {
    'Ref: Menu Page title': 'menük',
    'Ref: today': 'Ma',
    'Ref: tomorrow': 'Holnap',
    'Ref: Search products list': 'Keresési elemek',
    'Ref: You have changed location': 'Megváltoztatta a helyét',
    'Ref: You are now at': 'Most vagy',
  },
  ProductDetails: {
    'Ref: Page title': 'termék leírás',
    'Ref: Serving Size': 'Adag',
    'Ref: Nutrition': 'Tápanyag-információ',
    'Ref: Nutrition adults': 'A felnőtteknek naponta 2000 kcalra van szükségük',
    'Ref: Nutrition Calories': 'Kalóriák',
    'Ref: Nutrition Calcium': 'Kalcium',
    'Ref: Nutrition Fat': 'Zsír',
    'Ref: Nutrition Saturated Fat': 'Telített zsír',
    'Ref: Nutrition Carbohydrates': 'Szénhidrát',
    'Ref: Nutrition Sugar': 'Cukor',
    'Ref: Nutrition Protein': 'Fehérje',
    'Ref: Nutrition Sodium': 'Nátrium',
    'Ref: Nutrition cholesterol': 'Koleszterin',
    'Ref: Nutrition dietaryFiber': 'Élelmi rost',
    'Ref: Nutrition iron': 'Vas',
    'Ref: Nutrition potassium': 'Kálium',
    'Ref: Nutrition vitaminA': 'A-vitamin',
    'Ref: Nutrition vitaminC': 'C vitamin',
    'Ref: Additives': 'Adalékanyagok',
    'Ref: Emission': 'CO2-kibocsátás',
    'Ref: This product generates': 'Ez a termék generál',
    'Ref: of CO2': 'A CO2',
    'Ref: emissions, which equals a': 'kibocsátás, amely egyenlő a',
    'Ref: car trip': 'autóút',
    'Ref: Ingredients': 'Recept összetevő',
    'Ref: Allergens': 'Allergének',
    'Ref: Always there': 'Mindig ott van',
    'Ref: Allergens selected': 'A szűrőn kiválasztott allergének:',
    'Ref: minimum required': 'minimum szükséges',
    'Ref: you have to add at least': 'Legalább hozzá kell adnia',
    'Ref: ingredients': 'összetevők',
    'Ref: Ingredient': 'hozzávaló',
    'Ref: Free': 'Ingyenes',
    'Ref: Based on customization': 'A testreszabás alapján',
    'Ref: Additives eggs': 'tojás',
    'Ref: Additives fish': 'hal',
    'Ref: Additives milk': 'tej',
    'Ref: Additives nuts': 'diófélék',
    'Ref: Additives wheat': 'búza',
    'Ref: Additives peanuts': 'földimogyoró',
    'Ref: Additives soy beans': 'szójabab',
    'Ref: Additives sesame seeds': 'szezámmag',
    'Ref: Review': 'Felülvizsgálat',
    'Ref: Per portion': 'Részben',
    'Ref: Per 100g': '100 g',
    'Ref: Allergen selected': 'A keresési szűrőn kiválasztott allergén',
    'Ref: Fitbit Log': 'Jelentkezzen be a Fitbitbe',
    'Ref: age verification required on delivery': 'A kézbesítéskor szükséges életkor -ellenőrzés',
    'Ref: alcohol content notification':
      'Ha elhelyezi és megrendeli ezeket az elemeket, kijelenti, hogy 18 éves vagy annál idősebb. Az alkoholfogyasztás veszélyes az egészségére.',
    'Ref: default': 'Mulasztás',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} Megszerezve, ha megrendeli ezt a terméket',
    'Ref: Loyalty Card Description':
      'A {stamp_form} hozzáadódik a következő hűséghez {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'A Hűségbélyegek programja lehetővé teszi a bélyegek gyűjtését a jutalmakhoz.',
    'Ref: Loyalty Modal Description':
      'Amikor vásárol <B> {product_name}, 1 bélyeg </b> hozzáadódik a <b> {scheme_names} </b> {program_form} -hez',
    'Ref: and': 'és',
    'Ref: Stamp': 'Bélyeg',
    'Ref: Stamps': 'Bélyegek',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programok',
    'Ref: Loyalty Stamps': 'Hűségbélyegek',
    'Ref: See Loyalty Stamps': 'Lásd a hűségbélyegeket',
    Favorite: 'Kedvenc',
    'Ref: In favorites':
      'A termék már szerepel a kedvencek listájában. Kattintson a eltávolításhoz.',
    'Ref: Not in favorites':
      'A termék még nem szerepel a kedvencek listájában. Kattintson a hozzáadáshoz.',
    'Ref: Added to favorites': 'A terméket hozzáadták a Kedvencek listájához.',
    'Ref: Removed from favorites': 'A terméket eltávolították a Kedvencek listájáról.',
    'Ref: From': 'Tól től',
  },
  OrderHistory: { 'Ref: Orders': 'Megrendelés', 'Ref: Page Title': 'rendeléseim' },
  MenuTile: { 'Ref: Scan&Go': 'Szkennelj és menj', 'Ref: View only': 'Csak megtekintésre' },
  ProductTile: {
    'Ref: Allergens': 'Allergének',
    'Ref: Presence of allergen': 'Allergén jelenléte',
    'Ref: Scan': 'Letapogatás',
    'Ref: From': 'Tól től',
    'Ref: Already in product favorite list. Click to remove it.':
      'Már a termék kedvenc listájában. Kattintson a eltávolításhoz.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Jelenleg nincs hozzáadva a termék kedvenc listájához. Kattintson a hozzáadáshoz.',
    'Ref: Free': 'Ingyenes',
  },
  MenusWidget: { 'Ref: no menu': 'Jelenleg nincs menü', 'Ref: Modal title': 'Menü a napra' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Felvenni',
    'Ref: Eat-in': 'Étkezési',
    'Ref: Scan&Go': 'Szkennelj és menj',
    'Ref: Delivery': 'Kézbesítés',
    'Ref: Total': 'Teljes',
    'Ref: How was facility name service': 'Hogyan volt a {facility_name}} szolgáltatás?',
    'Ref: How do you rate the service': 'Hogyan értékeli a szolgáltatást?',
  },
};
export default labels;
