const labels = {
  All: {
    Feedback: 'Palaute',
    'Ref: Switch site component intro sentence': 'Palautteesi liitetään sivustoon {site name}.',
    Category: 'Kategoria',
    Subject: 'Aihe',
    Comment: 'Kommentti',
    'Date of your experience': 'Kokemuksesi päivämäärä *',
    'Ref: Hint': 'Onko sinulla ongelma tai työ, joka tarvitsee vahvistaa?',
    'Ref: Log Request': 'Kirjaudu pyyntöön',
    'Ref: Describe issue': 'Kerro meille kokemuksestasi',
    Date: 'Päivämäärä',
    'Ref: Share your opinion header': 'Jaa mielipiteesi kokemuksestasi työssä',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Ravintola / kahvila',
    'Desk / workstation area': 'Pöytä- / työasema -alue',
    'Meeting room facilities': 'Kokoushuonetilat',
    'Coffee/tea facilities (pantry)': 'Kahvi-/teetilat (ruokakomero)',
    'Restrooms / washrooms': 'WC: t / pesuhuoneet',
    'Reception / Security': 'Vastaanotto / turvallisuus',
    'Conference center': 'Konferenssikeskus',
    'Fitness center': 'Kuntokeskus',
    Mailroom: 'Postihuone',
    'Bicycle room': 'Polkupyörähuone',
    'Shuttle services': 'Kuljetuspalvelut',
    Lockers: 'Kaapit',
    Helpdesk: 'Tukipalvelu',
  },
  Create: {
    'Tell us!': 'Kerro meille!',
    'Ref: All Ears': 'Odotamme innolla palautettasi!',
    'Ref: Provide Description': 'Anna kuvaus',
    'Ref: Fill-in-the-blank sentence': 'Haluan antaa {0} suhteessa {1}',
    'Ref: Link text to service request module': 'Pyyntö tai ongelma? Kirjaudu täältä!',
    'Complaint type': 'Valitus {emoji}',
    'Feedback type': 'Palaute {emoji}',
    'Compliment type': 'Kohteliaisuus {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Tapahtui virhe ja palautetta ei lähetetty. Jos tämä virhe jatkuu, ota yhteyttä tukipalvelu.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Palautteesi toimitettiin. Työpaikan tukitiimi lukee sen huolellisesti. Tarvittaessa palaamme takaisin sinulle.',
    'New feedback': 'Uusi palaute',
  },
  CreateFlow: {
    Description: 'Kuvaus',
    'Ref: Provide Description': 'Anna päivämäärä ja kuvaus',
    'Ref: I would like to provide a': 'Haluaisin tarjota a',
    'Ref: in regards to': 'suhteen',
    'Ref: &': 'Ja',
    'Ref: at': 'at',
    'Ref: on the': 'päälle',
  },
};
export default labels;
