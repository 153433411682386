import { SERVICE } from '../../config';
import { Service } from '../../Core/types/State.types';
import { pagePaths } from '../config';
import { MenuType } from '../types/menuSelector.types';

import { hasNavigationEnabled } from '@/modules/Core/helpers/availableServices.helper';

export const getPrice = (
  price: number | undefined | null,
  languageCode: string,
  isoCode: string
) => {
  if (price === undefined || price === null) return '';

  const priceFormatter = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency: isoCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return priceFormatter.format(price);
};

export const getMenuType = (services: Service[], isNonOrderableUrl: boolean) => {
  const isOrderModuleAvailable = hasNavigationEnabled(services, SERVICE.FOOD_ORDER);
  const isMenuModuleAvailable = hasNavigationEnabled(services, SERVICE.FOOD_MENU);

  if (isMenuModuleAvailable && isOrderModuleAvailable) {
    if (isNonOrderableUrl) {
      return MenuType.NonOrderable;
    }
    return MenuType.Orderable;
  } else if (isMenuModuleAvailable && !isOrderModuleAvailable) {
    return MenuType.NonOrderable;
  } else {
    return MenuType.All;
  }
};

export const getFacilityMenuPath = (
  menuId: string,
  facilityId: string,
  selectedDate: string,
  isMenuModule: boolean
) => {
  if (isMenuModule) {
    return pagePaths.MenuProductsList.replace(':facilityId', facilityId)
      .replace(':menuId', menuId.toString())
      .replace(':date', selectedDate);
  } else {
    return pagePaths.ProductsList.replace(':facilityId', facilityId)
      .replace(':menuId', menuId.toString())
      .replace(':date', selectedDate);
  }
};
