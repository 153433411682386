import momentjs from 'moment';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { ContentDetailsHeaderProps } from '../../../components/organisms/ContentDetailsHeader';
import ContentDetailsPage from '../../../components/templates/ContentDetailsPage';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { useGetMenusQuery } from '../api/menuApi';
import { getMenuItemById } from '../helpers/productDetails.helper';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import { SiteCurrency } from '../types/productList.types';

import { MenuTitleViewable } from './MenuTitleViewable';
import { MenuDescription, MenuImage, getRedirectURL } from './ProductDetailsHelpers';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { UserSteps } from '@/types/userSteps.types';

const ProductDetails = () => {
  const history = useHistory();
  const params: {
    id: string;
    facilityId: string;
    date: string;
    menuId: string;
    cartItem: string;
  } = useParams();

  const site = useSite({ throwWhenNoActiveSite: true })!;
  const { id, facilityId, date, menuId } = params;

  const { data: menus, isFetching: isLoadingMenus } = useGetMenusQuery({
    siteId: site?.id,
    menuDate: date,
  });

  const menu = menus?.find((x) => x.id === menuId);
  const menuItem = menus ? getMenuItemById(id, menus) : undefined;

  const { currentLanguageCode: languageCode } = useLanguage();
  const { label } = useOrderTranslation(__filename);

  const { logUserSteps } = useUserStepsInsightsLogging();

  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById('main-content')?.scrollIntoView();
  }, [pathname]);

  useEffect(() => {
    if (!!facilityId) {
      logUserSteps({ event: UserSteps.ProductDetails, facilityId });
    }
  }, [facilityId, logUserSteps]);

  useEffect(() => {
    // Module prefix from share link
    const sharedModuleName = pathname.split('/')[1];
    // Date from shared link
    const sharedDate = pathname.split('/')[5];
    // Menu item id from shared link
    const sharedMenuItemId = pathname.split('/')[6];

    // Check for current menu to have the same date as shared menu
    const isMenuSameDate = sharedDate === momentjs(menu?.date).format('YYYY-MM-DD');

    // Only if dates are the same we can check for a valid id
    const isInMenu = isMenuSameDate
      ? menu?.menuItems.find((item) => item.menuItemId === sharedMenuItemId)
      : true;

    if (!isInMenu && menu) {
      const newURL = getRedirectURL(sharedModuleName, facilityId, menu.id, sharedDate);
      history.replace(newURL);
    }
  }, [facilityId, history, menu, menuItem, pathname]);

  if (isLoadingMenus) return <LoadingPage />;

  const header: ContentDetailsHeaderProps = {
    children: menu ? (
      <>
        <MenuTitleViewable
          menuItem={menuItem}
          languageCode={languageCode}
          site={{ id: site.id, currency: site.currency as SiteCurrency }}
          data-testid="menuItem-tile-viewable"
        />
      </>
    ) : null,
    imageNode: <MenuImage menuItem={menuItem} data-testid="menuItem-image" />,
  };

  const mainColumn = menu ? (
    <MenuDescription menuItem={menuItem} data-testid="menuItem-description" />
  ) : null;

  return (
    <ContentDetailsPage.WithAdditionalSections
      title={label('Ref: Page title')}
      header={header}
      tabTitle={menuItem?.name}
    >
      {mainColumn}
    </ContentDetailsPage.WithAdditionalSections>
  );
};

export default ProductDetails;
