import { useHistory } from 'react-router-dom';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

function TroubleLoggingInFormSuccessPage() {
  const history = useHistory();

  const { label } = useCoreTranslation(__filename);

  return (
    <NoticePage
      withNavBar={false}
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('Ref: Title')}
      content={label('Ref: Body')}
      hideAllWidgets
      actions={[
        {
          label: label('ok', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
      ]}
      tabTitle={label('Ref: Title')}
    />
  );
}

export default TroubleLoggingInFormSuccessPage;
