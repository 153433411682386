import { useState } from 'react';

import { UKFlag, USFlag, DEFlag, ESFlag, FRFlag } from '../../../assets';
import { NetworkIcon } from '../../../assets/icons';
import { getCurrentLanguageCode } from '../../../helpers/misc';
import { languages } from '../../../modules/Core/config';
import LanguageTile from '../../molecules/LanguageTile/LanguageTile';
import extendedFilteredWithDefault, {
  modalLanguages,
} from '../../molecules/LanguageTile/LanguageTile.helper';

import { LanguageTileListProps } from './LanguageTileList.types';

import styles from './LanguageTileList.module.css';

const LanguageTileList = ({ setLanguage, language }: LanguageTileListProps) => {
  const isLanguageFromModal = modalLanguages.some(
    (language) => language.code === getCurrentLanguageCode()
  );
  const currentLanguage = languages.find(({ code }) => code === language)?.name;
  const [selectedTile, setSelectedTile] = useState<string | undefined>(
    isLanguageFromModal ? 'Other' : currentLanguage
  );

  const handleChangeLanguage = (language: { value: string }) => {
    const code = language.value;
    const backLanguageId = languages.find((l) => l.code === code)?.backLanguageId;
    setLanguage({
      code,
      backLanguageId,
    });
  };

  const languageImages = [
    <UKFlag />,
    <USFlag />,
    <DEFlag />,
    <ESFlag />,
    <FRFlag />,
    <NetworkIcon height={32} width={40} />,
  ];

  const [otherModal, setOtherModal] = useState(false);

  const handleSelect = (code: string, lang: string) => {
    if (selectedTile === lang && !otherModal) {
      setSelectedTile('');
    } else {
      setSelectedTile(lang);
      handleChangeLanguage({ value: code });
    }
  };

  return (
    <div className={styles.container} data-testid="language-tile-list">
      {extendedFilteredWithDefault.map((tile, i) => {
        return (
          <LanguageTile
            open={otherModal}
            setModalOpen={setOtherModal}
            key={tile.name}
            image={languageImages[i]}
            lang={tile.name}
            code={tile.code}
            selected={selectedTile === tile.name}
            onChange={(code: string, name: string) => handleSelect(code, name)}
          />
        );
      })}
    </div>
  );
};

export default LanguageTileList;
