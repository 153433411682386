const labels = {
  All: {
    Region: 'Region oder Land',
    'Ref: Geography': 'Bitte wählen Sie Ihr Land oder Ihre Region aus',
    'Ref: Country': 'Land',
    'Ref: Country tooltip': 'Der Standort entspricht dem Standortland.',
    'Ref: Please select your country': 'Bitte wählen Sie Ihr Land',
    'First name': 'Vorname',
    'Last name': 'Nachname',
    'Ref: Full name': 'Vollständiger Name',
    'Mobile number': 'Handynummer',
    'Mobile phone': 'Handy',
    Email: 'E-Mail-Addresse',
    Password: 'Passwort',
    logout: 'Ausloggen',
    'Not a new user? Log in': 'Kein neuer Benutzer? Anmeldung',
    'do not register, login': 'Kein neuer Benutzer? Anmeldung',
    'Ref: I agree': 'Ich stimme zu',
    Register: 'Registrieren',
    'Ref: My language': 'Meine Sprache',
    'Ref: Kiosk Fail Title': 'Hoppla! Etwas ist schief gelaufen!',
    'Ref: Kiosk Fail Description':
      'Leider war Ihre Bestellung nicht erfolgreich. Bitte versuchen Sie es erneut oder bitten Sie einen Mitarbeiter, Ihnen zu helfen.',
    'Ref: Kiosk Fail Back': 'Zurück zur vorherigen Seite',
    'Ref: Kiosk Fail Action': 'Löschen Sie die Sitzung und bestellen Sie erneut',
    Validate: 'Bestätigen',
    'Ref: error, incorrect credentials':
      'Diese Anmeldeinformationen stimmen nicht mit der gewählten Region überein.',
    'Ref: Forgot password?': 'Passwort vergessen?',
    'Ref: Hello': 'Hallo {wave_emoji}, bitte einloggen um fortzufahren',
    'Ref: Form title': 'Bitte melden Sie sich an, um fortzufahren',
    'Already have an account? Login': 'Sie haben bereits ein Konto? Melden Sie sich an',
  },
  AccountPage: {
    'Ref: My Information': 'Meine Informationen',
    'Ref: Settings': 'Einstellungen',
    'Ref: Email': 'Email',
    'Ref: Current site': 'Standort',
    'Ref: Choose site': 'Wählen Sie eine Website',
    'Ref: Personal interests': 'Persönliche Interessen',
    'Ref: Food preferences': 'Lebensmittelvorlieben',
    'Ref: Password': 'Passwort',
    'Ref: Page title': 'Konto',
    'Ref: Language': 'Sprache',
    'Ref: Communication preferences': 'Werbeeinstellungen',
    'Ref: Terms & conditions': 'Geschäftsbedingungen',
    'Ref: Privacy policy': 'Datenschutzbestimmungen',
    'Ref: Delete my account': 'Mein Konto löschen',
    'Ref: Confirm deletion': 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?',
    'Ref: Request deletion':
      'Sind Sie sicher, dass Sie die Löschung Ihres Kontos anfordern möchten?',
    'Ref: Deletion description': 'Ihre Anfrage wird gemäß Ihrer örtlichen Verordnung bearbeitet.',
    Placeholder: 'Schreiben Sie "Löschen"',
    'Ref: Delete description': 'Ihre Daten werden gelöscht. Diese Aktion ist unwiderruflich.',
    delete: 'löschen',
    'Ref: Terms of sale': 'Verkaufsbedingungen',
    'Ref: Imprint': 'Impressum',
    'Ref: Cookie Settings': 'Cookie-Einstellungen',
    'Ref: Support': 'Die Unterstützung',
    'Ref: Register or login': 'Registrieren oder Anmelden',
    'Ref: Filtering Preferences': 'Filtereinstellungen',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?':
      'Sind Sie sicher, dass Sie ihr Konto löschen wollen?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Schreiben Sie "{word}" in das Eingabefeld und bestätigen Sie durch den „Mein Konto Löschen“-Button. Diese Aktion ist irreversibel.',
    'Ref: Write "{word}"': 'Schreiben Sie "{word}"',
    'Ref: Delete my account': 'Löschen Sie mein Konto',
    'Ref: Words are not matching': 'Wörter stimmen nicht überein',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Wählen Sie Ihren Standort um zu beginnen',
    'Ref: Choose location':
      'Finden Sie schnell einen Standort, indem Sie die Lokalisierung aktivieren, einen QR-Code scannen oder nach einem Namen suchen',
    'Ref: next step': "Los geht's",
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Anmelden',
    'Ref: identify your location': 'Bitte geben Sie Ihren Standort an.',
    'Ref: option 1 - scan QR':
      'Option 1: Scannen Sie den bereitgestellten QR-Code für die Registrierung',
    'Ref: option 2 - enter code': 'Option 2: Manuelle Eingabe Ihres Standortcodes',
    'Ref: button to open qr code scanner modal': 'Ich habe einen QR: Lasst es uns einscannen',
    'Ref: button to open site code input modal': 'Standort mit Code auswählen',
    'Ref: getting help with site identifier - support desk':
      'Wenn Sie sich nicht sicher sind, wie Sie vorgehen sollen, wenden Sie sich bitte an Ihren Support.',
    'Ref: getting help with site identifier - support form':
      'Sie können uns auch eine Notiz schicken.',
    'Location code': 'Standortcode',
    'Ref: location code field placeholder': 'Hier Ihr Standortcode',
    'Ref: Scanner header': 'Einen QR-Code scannen',
    'Ref: site code input header': 'Standort mit Code hinzufügen',
    'Ref: not valid code': 'Dieser Code ist in der ausgewählten Region nicht gültig',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Anmeldung',
    'Ref: collected info': 'Zusammenfassung',
    'Ref: additional info': 'Ihre Informationen',
    'Confirm password': '*Passwort bestätigen',
    company: 'Wer ist Ihr Arbeitgeber?',
    location: 'Ort',
    username: 'Nutzername',
    register: 'Anmelden',
    'Ref: password strength':
      'Ihr Passwort muss mindestens 8 Zeichen lang sein, mindestens eine Zahl, einen Großbuchstaben, einen Kleinbuchstaben und ein Sonderzeichen enthalten.',
    'passwords do not match': 'Passwörter stimmen nicht überein.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Kommunikationseinstellungen',
    'Ref: Things happen while you are away': 'Dinge passieren, während Sie weg sind',
    'Ref: Would you like to receive update about offers and promotions?':
      'Möchten Sie Updates über Angebote und Werbeaktionen erhalten?',
    'Ref: Would you like to hear about content we think you may like?':
      'Möchten Sie von Inhalten hören, von denen wir glauben, dass Sie diese mögen?',
    Yes: 'Ja',
    No: 'Nein',
    apply: 'anwenden',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Kontodetails' },
  DeletionFailure: {
    'Ref: Body':
      'Es ist ein Fehler aufgetreten und Ihr Antrag auf Kontolöschung wurde nicht übermittelt. Wenn dieser Fehler weiterhin besteht, wenden Sie sich bitte an Ihren Beratungsstelle.',
    'Go to account': 'Auf das Konto gehen',
  },
  GenericFailurePage: {
    'Ref: Title': 'Etwas ist schief gelaufen',
    'Ref: Body':
      'Entschuldigung, dass etwas schief gelaufen ist. Wenn dieses Problem weiterhin besteht, wenden Sie sich an Ihren Beratungsstelle vor Ort.',
  },
  HomePage: {
    notifications: 'Benachrichtigungen',
    basket: 'Warenkorb',
    'Ref: Tabtitle': 'Startseite',
    'contact us': 'Kontaktieren Sie uns',
    'Ref: You are logged in as a guest user': 'Sie sind als Gastbenutzer angemeldet',
    'Ref: Good morning': 'Guten Morgen',
    'Ref: Good afternoon': 'Guten Nachmittag',
    'Ref: Good evening before midnight': 'Guten Abend',
    'Ref: Good evening after midnight': 'Guten Abend',
    'Ref: Search': 'Suchen',
    'Ref: products button aria': 'Gefunden {results_form} innerhalb von Produkten',
    'Ref: menus button aria': 'Gefunden {results_form} innerhalb der Menüs',
    'Ref: facilities button aria': 'Gefunden {results_form} innerhalb der Einrichtungen',
    'Ref: result': 'Ergebnis',
    'Ref: results': 'Ergebnisse',
    'Ref: Menus': 'Menüs',
    'Ref: No results title': 'Hoppla!',
    'Ref: No results body': 'Keine Ergebnisse, die Ihrer Suche entsprechen',
    'Ref: Products': 'Produkte',
    'Ref: Facilities': 'Einrichtungen',
    'Ref: See more results': 'Weitere Suchergebnisse finden Sie in Suchergebnissen',
    'Ref: Accessibility instructions':
      'Um die Suche zu verwenden, müssen Sie darauf klicken oder die Eingabetaste drücken, während sie sich konzentrieren.',
    'Ref: See more': 'Mehr sehen',
    'Ref: singular form of products': 'Produkt',
    'Ref: singular form of menus': 'Speisekarte',
    'Ref: singular form of facilities': 'Einrichtung',
    'Ref: Search results for': 'Suchergebnisse für',
  },
  LanguagePage: {
    apply: 'anwenden',
    'My language': 'Meine Sprache',
    'Select in which language you would like to read our app':
      'Wählen Sie in welcher Sprache Sie unsere App lesen möchten',
    'Ref: Page title': 'Sprache',
  },
  LegalDocPage: {
    Acknowledge: 'Bestätigen',
    'Ref: Legal changed notification title': 'Die "{legal_doc_type}" wurde geändert',
    'Ref: Legal changed notification content':
      'Bitte überprüfen Sie diese, bevor Sie die App weiter nutzen.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Bedingungen und Konditionen',
    'Read privacy policy': 'Lesen Sie die Datenschutzbestimmungen',
    'Ref: Acknowledge legal docs': 'Ich akzeptiere diese Allgemeinen Geschäftsbedingungen.',
    'Ref: Read and understood': 'Ich habe gelesen und verstanden ',
    'Ref: No legal docs available - notice title': 'Demnächst verfügbar',
    'Ref: No legal docs available - notice body':
      'Es tut uns leid, aber es scheint, dass diese Anwendung derzeit in Ihrer Region nicht verfügbar ist.',
    'Ref: The privacy Policy': 'Die Datenschutzrichtlinie',
    'Ref: Legal changed notification title': 'Die "{legal_doc_type}" wurde geändert',
    'Ref: Legal changed notification content':
      'Bitte überprüfen Sie diese, bevor Sie die App weiter nutzen.',
  },
  LoginPage: {
    'Ref: Page title': 'Willkommen bei {app name} ',
    'Ref: Page subtitle': 'Anmelden zum Fortfahren',
    'Ref: Email field placeholder': 'Ihre E-Mail',
    'Ref: Email field note': 'Wir werden Ihre E-Mail niemals an Dritte weitergeben.',
    'Ref: Password field title': 'Ihr Passwort',
    register: 'Anmelden',
    'I forgot my password.': 'Passwort vergessen?',
    'I have trouble logging in': 'Ich habe Probleme beim Einloggen',
    'start registration': 'Anmeldung starten',
    'Ref: Register': 'Registrieren',
    'Ref: Login': 'Anmeldung',
    'Ref: Connection issue?': 'Verbindungsprobleme?',
    'Ref: Connection issue message':
      'Wenn Sie ein Problem zur Registrierung oder Anmeldung haben, wenden Sie sich bitte an das Personal vor Ort oder den Beratungsstelle.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Hallo und Willkommen bei {site_name}', 'Ref: Begin': 'Start' },
  NotificationsList: {
    'Ref: No notifications': 'Sie haben momentan keine Benachrichtigungen',
    'Ref: This week': 'Diese Woche',
    'Ref: Earlier': 'Früher',
    'Ref: Page title': 'Benachrichtigungen',
    'Ref: See more': 'Mehr sehen',
    'Ref: Mark all read': 'markiere alles Gelesene',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Sehen Sie alle Benachrichtigungen' },
  ServicesMenuPage: { 'Ref: Page title': 'Dienstleistungen', navigate: 'navigieren' },
  DeletionSuccess: {
    'Ref: Title': 'Die Löschung Ihres Kontos wurde beantragt.',
    'Ref: Content':
      'Wir werden dies in Kürze bearbeiten. Sie können Ihren Zugang jederzeit verlieren.',
    'Log out': 'Ausloggen',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Wir melden uns bei Ihnen',
    'Ref: Body':
      'Es tut uns leid, dass Sie mit Problemen konfrontiert sind. Wir werden Sie sehr bald kontaktieren.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Haben Sie Probleme beim Einloggen?',
    'Ref: Email field placeholder': 'Ihre E-Mail Adresse',
    'Ref: Mobile phone field placeholder': 'Handynummer',
    Company: 'Firma',
    'Ref: Company field placeholder': 'Name der Firma',
    'Ref: Company field note':
      'Wenn Sie ein Berater sind, ist dies das Unternehmen, für das Sie derzeit arbeiten, nicht Ihr Arbeitgeber.',
    Location: 'Stadt oder Ort',
    'Ref: Location field placeholder': 'Lage Ihres Arbeitsplatzes',
    'Ref: Location field note':
      'Wenn Sie sich außerhalb eines bewohnten Gebiets befinden, können Sie den Namen verwenden, mit dem das Gebiet üblicherweise bezeichnet wird.',
    Comment: 'Kommentar',
    'Ref: Comment field placeholder': 'Was lief schief?',
    'Ref: Who is your employer?': 'Wer ist dein Arbeitgeber?',
    'Ref: What village are you staying at?': 'In welchem Ort wohnen Sie?',
    'Ref: Comment field note': 'Jede Information, die uns helfen kann, Ihnen zu helfen!',
    'Ref: Feedback Content - geography': 'Region',
    'Ref: Feedback Content - fullName': 'Name',
    'Ref: Feedback Content - email': 'Email',
    'Ref: Feedback Content - phone': 'Telefon',
    'Ref: Feedback Content - company': 'Firma',
    'Ref: Feedback Content - city': 'Stadt- / Standortname',
    'Ref: Feedback Content - whatVillage': 'Ort',
    'Ref: Feedback Content - employer': 'Arbeitgeber',
    'Ref: Feedback Content - comment': 'Was schief gelaufen ist',
  },
  AccountStartPage: {
    'Ref: Header': 'Wählen Sie Ihren Standort für den Start aus',
    'Ref: Body':
      'Finden Sie schnell einen Ort, indem Sie die Lokalisierung aktivieren, einen QR-Code scannen oder nach einem Namen suchen',
    'Ref: LetsGo button': "Los geht's",
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Anmeldung' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Wählen Sie Site mit QR -Scanner aus',
    'Ref: Page title': 'Wähle deinen Standort',
    'Ref: Select site with code': 'Site mit Code auswählen',
    'Ref: Enter site code': 'Code des Standorts eingeben',
    'Ref: Scanner error header': 'Standort nicht gefunden',
    'Ref: Not found': 'Dieser Code ist in der ausgewählten Region nicht gültig',
    'Ref: Generic error': 'Etwas ist schief gelaufen',
    'All other sites': 'Alle anderen Standorte',
    'Sites in your country': 'Standorte in Ihrem Land',
    'Ref: Unauthorized error':
      'Nicht autorisierte Anfrage aufgrund der Einschränkung der Standortbeschränkung',
    'Ref: Forbidden':
      'Entschuldigung, Sie haben nicht die richtigen Berechtigungen, auf diese Website zuzugreifen. Um Zugang zu arrangieren, wenden Sie sich bitte an das Pilbara Service Center unter 1800 992 777',
    "Ref: We didn't find the site.": 'Wir haben die Seite nicht gefunden.',
    'Ref: We recommend some solutions:': 'Wir empfehlen einige Lösungen:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Versuchen Sie, Ihre Website mit QR -Scanner oder mit Hilfe von Schaltflächen unten hinzuzufügen',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Melden Sie sich an und melden Sie sich wieder an, indem Sie das richtige Land auswählen',
    'Ref: The currently selected country is:': 'Das derzeit ausgewählte Land ist:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Anmeldung',
    'Confirm password': 'Passwort bestätigen',
    'Email is not valid': 'Email ist ungültig',
    'passwords do not match': 'Passwörter stimmen nicht überein.',
    'Ref: Password strength':
      'Ihr Passwort muss mindestens 8 Zeichen lang sein, mindestens eine Zahl, einen Großbuchstaben, einen Kleinbuchstaben und ein Sonderzeichen enthalten.',
    'Sign up': 'Anmelden',
    'Continue as guest': 'Weiter als Gast',
    'Already have an account? Login': 'Sie haben bereits ein Konto? Melden Sie sich an',
    'Ref: Registration success title': 'Danke für die Registrierung',
    'Ref: Registration success content':
      'Ihr Konto wird bald erstellt. Sie erhalten eine E -Mail, in der die Erstellung bestätigt wird.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Bitte einloggen/anmelden',
    'Ref: Password strength':
      'Ihr Passwort mindestens 8 Zeichen lang sein müssen, mindestens ein Zahl, einen Großbuchstaben, einen Kleinbuchstaben und ein Sonderzeichen enthalten.',
    Stamps: 'Briefmarken',
    'Order history': 'Bestellverlauf',
    'to earn points': 'Punkte sammeln',
    'to see order history': 'um den Bestellverlauf zu sehen',
    'to place an order': 'eine Bestellung aufgeben',
    'Sign up': 'Anmelden',
    Login: 'Anmeldung',
    'Email is not valid': 'Email ist ungültig',
    'passwords do not match': 'Passwörter stimmen nicht überein.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Danke',
    'Ref: Body':
      'Du bist fast fertig! Sie erhalten bald eine E-Mail zur Überprüfung unter der angegebenen Mailadresse. Öffnen Sie diese E -Mail und klicken Sie auf den Link, um Ihr Konto zu aktivieren.',
    'Ref: Enter': 'Fortsetzen',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Ein Fehler ist aufgetreten',
    'Ref: Generic Error Body':
      'Bitte versuchen Sie es später erneut oder wenden Sie sich an Ihren Beratungsstelle.',
    'Ref: Missing IndexDb support':
      'Leider können Sie nicht im Inkognito-Modus oder ohne IndexDb-Unterstützung auf die Anwendung zugreifen. Bitte versuchen Sie es erneut mit einem anderen Browser.',
    'Ref: Resource not found': 'Inhalte, auf den Sie zugreifen möchten, existiert nicht.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Inhalte, auf den Sie zugreifen möchten, gehört zu einer privaten Website.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Inhalte, auf den Sie zugreifen möchten, ist nicht für Ihr Konto autorisiert.',
    'Ref: TabTitle': 'Fehler',
    'Ref: Site link invalid':
      'Dieser Link ist ungültig, wählen Sie eine andere Site oder wenden Sie sich an den Beratungsstelle, wenn das Problem bestehen bleibt.',
    'Ref: Access Denied': 'Zugriff abgelehnt. Unzureichende Benutzerberechtigungen.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Hoppla! {robot_emoji}',
    'Ref: Error title': 'Etwas ist schief gelaufen!',
    'Ref: Error body':
      'Versuchen Sie, diese Seite zu aktualisieren oder Beratungsstelle zu kontaktieren, wenn das Problem bestehen bleibt.',
    'Ref: Error ID:': 'Fehler -ID: {errorid}',
    'Ref: Back to home': 'Zurück nach Hause',
  },
  Child: {
    'Ref: Route Not Found':
      'Der Service, den Sie suchen, ist auf dieser Website nicht verfügbar. Bitte versuchen Sie es später erneut oder wenden Sie sich an Ihren Beratungsstelle.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Herzlichen Glückwunsch, Ihr neues Passwort wurde erfolgreich gespeichert. Sie können jetzt auf alle unsere Dienste aus Ihrem Konto zugreifen.',
    Welcome: 'Herzlich willkommen',
    'Ref: Welcome message':
      'Sie haben sich jetzt erfolgreich bei der neuen Bite -Anwendung registriert und können alle unsere Dienste weiterhin entdecken und genießen.',
  },
  PasswordChange: {
    'Change Password': 'Passwort ändern',
    Welcome: 'Herzlich willkommen',
    'Ref: Password message': 'Ihr Passwort muss geändert werden, da es nicht sicher genug ist',
  },
  AccountPasswordChange: {
    'Change your password here': 'Ändern Sie hier Ihr Passwort',
    'Your old password': 'Ihr altes Passwort',
    'Type in your new password': 'Geben Sie Ihr neues Passwort ein',
    'Re-type in your new password': 'Geben Sie Ihr neues Passwort erneut ein',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'Ihr Passwort wurde geändert' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Ein Fehler trat auf, daher wurde Ihre Änderung nicht gespeichert. Bitte versuche es erneut.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Passwort zurücksetzen',
    'Ref: Provide email':
      'Bitte geben Sie die E -Mail -Adresse an, die bei der Registrierung unter {app_name} verwendet wird. Eine E -Mail wird an Sie gesendet, um Ihr Passwort zurückzusetzen.',
    'Ref: Something happened': 'Irgendetwas ist passiert. Bitte versuchen Sie es später erneut.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Wir haben eine neue E -Mail gesendet. Bitte warten Sie 30 Sekunden, um erneut zu senden.',
    'Go to login page': 'Gehen Sie zur Anmeldeseite',
    'Resend email': 'E-Mail erneut senden',
    'Ref: Email sent title': 'Eine E -Mail mit Passwort Reset wurde an Sie gesendet',
    'Ref: TabTitle': 'Passwort zurücksetzen',
    'Ref: Email sent msg':
      'Der Reset-Link wurde an Ihre Adresse {email_address} gesendet. Bitte warten Sie 30 Sekunden, bevor Sie es erneut versuchen.',
  },
  ResetPassword: { 'Enter your new password': 'Gib dein neues Passwort ein' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Ihr Passwort wurde erfolgreich geändert',
    'Go to login page': 'Gehen Sie zur Anmeldeseite',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Zurücksetzten des Passworts fehlgeschlagen',
    'Ref: Error': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal',
    'Reset your password': 'Setze dein Passwort zurück',
    'Go to login page': 'Gehen Sie zur Anmeldeseite',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Datenschutz-Bestimmungen',
    'Ref: Privacy Policy Body':
      'Durch den Zugriff auf diese Plattform verarbeiten Sodexo SA und das lokale Sodexo -Unternehmen Ihre persönlichen Daten, um zumindest Ihr Konto zu verwalten und die Dienste und Angebote über die Plattform bereitzustellen. Wenn Sie weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten, zu darauf zugreifen und die Rechte an Ihren persönlichen Daten ausüben, wenden Sie sich bitte an die nachstehenden Datenschutzrichtlinien und können Sie jederzeit in Ihrem Konto verfügbar sind.',
    'Ref: Continue': 'Weitermachen',
    'Ref: Read Privacy Policy': 'Datenschutzrichtlinie lesen',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Zum Laden gehen' },
  SharePage: { 'Ref: Select a site': 'Wählen Sie eine Website aus' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Um Datenschutzrichtlinien anzuzeigen, wählen Sie bitte Ihr Land aus.',
  },
};
export default labels;
