import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { contentPageConfig } from '../config';
import childrenPagesMock from '../mocks/childrenPages';
import contentPageMock from '../mocks/contentPage';
import { ChildrenPageResponseItem } from '../types';

import { GetChildrenPagesResponse, GetContentPageArgs, GetContentPageResponse } from './api.types';

import { getImagesSrcFromIds } from '@/helpers/images/images.helper';

const { api, baseUrl, apiPath, apiParams } = contentPageConfig();

const contentPageApi = api
  .enhanceEndpoints({ addTagTypes: ['contentPage', 'contentPageChildren'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getContentPage: build.query<GetContentPageResponse, GetContentPageArgs>({
        queryFn: async ({ id }: GetContentPageArgs) => {
          const baseQuery = axiosBaseQuery({ baseUrl });

          const response = await baseQuery({
            url: apiPath(id),
            params: apiParams(id),
            method: 'get',
            mockData: contentPageMock.default,
          });

          const result = response.data || {};

          return {
            data: {
              contentPageDetail: result,
              notFound: response?.error?.status === (404 || 400),
            },
          };
        },
        providesTags: (_result, _error, args) => [{ type: 'contentPage', id: args.id }],
      }),
      getChildrenPages: build.query<GetChildrenPagesResponse, GetContentPageArgs>({
        query: ({ id }) => ({
          url: `${apiPath(id)}/children`,
          method: 'get',
          mockData: childrenPagesMock.withChildrenPages,
        }),
        transformResponse: async (response) => {
          if (!response) {
            return [];
          }

          const imageIds: string[] = response
            .filter((page: ChildrenPageResponseItem) => !!page.contentImageId)
            .map((page: ChildrenPageResponseItem) => page.contentImageId!);

          const images = await getImagesSrcFromIds(imageIds);

          const transformedData = response.map((page: ChildrenPageResponseItem) => {
            if (page.contentImageId) {
              const image = images.find((img) => img.id === page.contentImageId);
              page.contentImage = image?.content;
            }
            return page;
          });

          return transformedData as GetChildrenPagesResponse;
        },
        providesTags: (_result, _error, { id }) => [{ type: 'contentPageChildren', id }],
      }),
    }),
    overrideExisting: true,
  });

export const { useGetContentPageQuery, useGetChildrenPagesQuery } = contentPageApi;

export default contentPageApi;
