import { TestingPropsV2 } from '../../../../types';

export enum CONTENT_CATEGORIES {
  ALL = 'all',
  PROMOTIONAL = 'promotional',
  FEATURED = 'featured',
  OTHER = 'other',
}

export type ContentItem = { id: string; type: string; date: string } & TestingPropsV2;
