import { cleanup, fireEvent, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import UpdateUs from '../UpdateUs';

import renderComponent from '@/helpers/tests/renderComponent';
import { AccommodationRequestDetailsResponse } from '@/modules/AccommodationRequest/api/api.types';

const mockCommentMutation = jest.fn();

const mock: { data: AccommodationRequestDetailsResponse; isLoading: boolean } = {
  data: {
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37d',
    referenceNumber: '#0612152',
    title: 'Five Start Stay at Koksijde Air Base',
    status: 'In Progress',
    arrivalDateTime: '2023-12-31T10:25:35Z',
    departureDateTime: '2024-01-02T10:00:00',
    description: 'I need the 5 (Five) star room\nwith a view to the Launch Pad',
    phoneNumber: '+32 2 442 34 49',
    siteName: 'FMG Hamilton site',
    raisedForUserName: 'Michel Hofman',
    affectedPerson: 'Michel Hofman',
    serviceRequestId: 'b1fd459c-39e1-4f6d-bdd9-aaaaaaaaa',
  },
  isLoading: false,
};
jest.mock('../../../api', () => ({
  useGetAccommodationRequestDetailsQuery: () => ({
    data: mock,
  }),
  useCommentMutation: () => [mockCommentMutation, { isSuccess: false }],
}));

describe('UpdateUs', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    renderComponent(UpdateUs);
    let updateUsButton: HTMLElement;
    let updateUsTextArea: HTMLElement;

    beforeEach(() => {
      updateUsButton = screen.getByTestId('button-action-primary-wrapper');
      updateUsTextArea = screen.getByTestId('comment-body-text-area');
    });

    it('should render ', () => {
      expect(updateUsButton).toBeTruthy();
      expect(updateUsTextArea).toBeTruthy();
    });

    it('should submit form ', async () => {
      userEvent.type(screen.getByTestId('comment-body-text-area'), 'test comment').then(() => {
        expect(screen.getByTestId('comment-body-text-area')).toHaveValue('test comment');
        fireEvent.click(updateUsButton);
        expect(mockCommentMutation).toBeCalled();
      });
    });
  });
});
