const labels = {
  All: {
    'status: all': '全て',
    'status: open': '開く',
    'status: pending': '保留',
    'status: closed': '閉じる',
    Location: '場所',
    Subject: '件名',
    Description: 'あなたの詳細なリクエスト',
    'Ref: description field label': '詳細なリクエスト',
    'Ref: Title': 'タイトル',
    phone_number: '電話番号',
    request_category: 'カテゴリー',
    Attachments: '添付ファイル',
    'request service': 'リクエストする',
    'New service request': '別のリクエストを作成します',
    'Go to home': 'ホームに戻る',
    'Back to this service request': 'このサービスリクエストに戻る',
    'Back to service requests': 'サービスリクエストに戻る',
    Feedback: 'フィードバック',
    'Ref: Request category': 'カテゴリー',
    Cleaning: 'クリーニング',
    'Laundry services': 'ランドリーサービス',
    'Grounds maintenance': '敷地のメンテナンス',
    'Soft services': 'ソフトサービス',
    'Hard services': 'ハードサービス',
    'Pest control services': '害虫駆除サービス',
    'Waste management': '廃棄物管理',
    'Asset Lifecycle Services': '資産ライフサイクルサービス',
    'There are no updates for this request': 'このリクエストの更新はありません',
    'Transportation Services': '輸送サービス',
    'Ref: you pronoun': '（様）',
    'Ref: SR raised for': 'このリクエストによって影響を受けた人',
    'Ref: Select a location': '場所を選択します',
    'Ref: Your request': 'あなたの要求',
    'Ref: Summary': '概要',
    'Ref: site': 'サイト',
  },
  Comment: {
    'Ref: Page title': '更新',
    'Ref: please update us with details':
      'より詳細なリクエスト内容をお伝えいただくことで、よりよくサポートすることが出来ます',
  },
  Confirm: {
    'Ref: Page title': 'リクエストの確認',
    'Ref: next': '送信',
    summary: '詳細',
    'Ref: Save as my preferred location': '私の好みの場所として保存します',
  },
  Describe: {
    'Ref: Page title': 'リクエスト内容をご記入ください',
    'Ref: Required fields: header': '追加情報を入力してください',
    'Ref: Required fields': '以下の情報は「詳細」フィールド内に入力してください',
    'Ref: Required field: title': 'タイトルは必須です。',
    'Ref: Required field: description': '詳細が必要です',
    'Ref: Required field: phone': '電話番号が必要です',
    'Ref: Required field: category': 'カテゴリーが必要です',
    'Ref: Invalid field: category': '入力したカテゴリが無効です',
    'Ref: Request template field label': 'よく使われるリクエスト',
    'Ref: Request template field placeholder': '白紙',
    your_request: 'あなたのリクエスト',
    'Ref: Description message':
      'より詳細なリクエスト内容をお伝えいただくことで、よりよくサポートすることが出来ます',
    phone_number:
      'あなたの電話番号（あなたが持っている場合はあなたのプロの電話を使用してください）',
    'Ref: Affected person': 'このリクエストによって影響を受けた人',
    'Ref: No affected person results': '該当者がいません',
    'Ref: Notification title': '場所を変更しました',
    'Ref: Notification info': 'あなたは今{site name}にいます',
    'Ref: Add photos': '写真を追加する',
    'Ref: Your location': 'あなたの場所',
  },
  Details: {
    'Ref: Page title': '詳細をリクエストします',
    Details: '詳細情報',
    Updates: '更新',
    'Update us': '更新',
    'Ref: timeline': 'タイムライン',
    'Ref: attachments': '添付ファイル',
    'Ref: location': 'ロケーションを選択してください。',
    'Ref: createdOn': '作成日時',
    'Ref: reference': '参照',
    'Feedback was submitted': 'フィードバックを送信',
    'Ref: sent': '送信済',
    'Ref: received': '受信した',
    'Ref: inProgress': '進行中',
    'Ref: completed': '完了しました',
    'Ref: conversation': '会話',
    'Ref: Priority': '優先順位',
    'Ref: Target start date': 'ターゲット開始日',
    'Ref: Target completion date': '目標完了日',
    'Ref: Priority fields placeholder message': 'ヘルプデスクによって検証されると、入力されます',
  },
  Failed: {
    'Ref: Body':
      'エラーが発生し、サービスリクエストは作成されませんでした。エラーが解決しない場合、ヘルプデスクにご連絡ください',
    'Ref: Tabtitle': 'サービスリクエストは作成できませんでした',
  },
  Home: { 'Ref: Page title': 'リクエスト' },
  RequestFeedback: {
    'Ref: Page title': 'フィードバック',
    'Ref: Feedback headline': 'フィードバックを共有してください',
    'Ref: Feedback title': 'フィードバックSR＃{REF}',
    'Ref: Question: general sentiment':
      'Sodoxによる対応について、全体的にどの程度満足していますか？',
    'Ref: Question: answer quality': '受けたサービスの品質をどのように感じましたか？',
    'Ref: Question: staff interaction': 'スタッフとのやり取りはスムーズでしたか？',
    'Ref: Question: efficiency': '迅速な対応を受けられましたか？',
    'Ref: Question: open': 'コメント',
    'Ref: Open question placeholder':
      'スムーズに進めたこと、改善すべきところがあれば教えてください。',
    no_comment: 'コメント無し',
  },
  FeedbackSuccess: { 'Ref: Body': 'フィードバックのご協力ありがとうございます' },
  Locate: {
    'Ref: Page title': 'ロケーションを選択してください',
    'Ref: Top bar title': 'リクエストの検索',
    'Ref: Select this location': 'この場所を選択',
    drill: 'ブラウズ',
    'Ref: Scanner button': 'QRコードがあればそれをスキャンします',
    'Ref: Scanner header': 'あなたの場所をQRコードから読み込んでください',
    'Sorry, this location could not be found.':
      '申し訳ありませんが、この場所が見つかりませんでした',
    'Close scanner': 'スキャナーを閉じる',
    'Ref: Switch site component intro sentence': '{site name}にサービスを要求しています。',
    'Ref: search location by keywords': '場所を検索します',
    'Ref: Enter Location name': '場所の名前を入力してください',
    'Ref: Enter manual location': '手動で場所を入力してください',
    'Ref: Location name': '場所の名前',
    'Ref: No results': '場所が見つかりませんでした',
    'Ref: Up one level': 'レベルアップ',
    'Ref: Navigate': 'ナビゲートします',
    'Ref: Select': '選択する',
    'Ref: Selected Location': '選択した場所',
    'Ref: Preferred Location': 'よく使う場所',
    'Ref: Building': '建物',
    'Ref: Floor': 'フロア',
    'Ref: Aisle': '通路',
    'Ref: Room': '部屋',
    'Ref: Notification title': '場所を変更しました',
    'Ref: Notification info': 'あなたは今{site name}にいます',
    'Ref: Scanner error header': 'スキャンエラー',
    'Ref: Scan QR Code': 'QRコードをスキャンします',
    'Ref: Error body': 'このQRコードは有効なコードではありません',
    'Ref: Loading Locations Warning':
      'このサイトでの最初のリクエストのためにあなたの経験を準備している間、お待ちください。',
    'Ref: Search Result is limited to:':
      '検索結果は{limit}に制限されています。検索条件を改善してください。',
    'Ref: Open the location search field': '場所検索フィールドを開きます',
  },
  Success: {
    'Ref: Body':
      'サービスリクエストが送信されました。間もなくアプリケーション上で表示され、解決時にメモが表示されます。その間アプリケーションを通してコメントを追加することもできます',
    'Ref: Tabtitle': '作成されたサービスリクエスト',
    'Ref: Download app title': 'サービスリクエストを簡素化する',
    'Ref: Download app subtitle': 'アプリをダウンロードしてエクスペリエンスを向上させましょう',
    'Ref: Download app button': 'アプリをダウンロードする',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'サポートハブ',
    'Ref: Tutorial: tabname': 'リクエスト',
    'Ref: Tutorial: body':
      '私たちはあなたを助けるためにここにいます。エントリごとに1つのリクエストを記録してください。',
    "What's new: title": 'あなたの場所を探して',
    "What's new: body":
      'QRコードをスキャンし場所を選択するか、こちらの提案内容から選択することができます',
  },
  Widget: {
    'Ref: Service request': 'サービス要求',
    'Ref: Placeholder body': '現時点ではサービスリクエストはありません',
    'Ref: New request button': '新しいリクエスト',
  },
  QrLandingPage: {
    'Ref: Notification title': '場所を変更しました',
    'Ref: Notification info': 'あなたは今{site name}にいます',
    'Ref: Welcome': 'いらっしゃいませ！ {波の絵文字}',
    'Ref: Create service request text': '報告したいことに気づきましたか?',
    'Create a service request': 'サービスリクエストを作成します',
    'Ref: Share feedback': 'フィードバックを共有する',
    'Ref: Share text': '私たちと何かを共有したいですか?',
    'Go to home': 'ホームに戻る',
    'Ref: Error body':
      'スキャンした QR コードは機能しなくなります。後でもう一度試すか、サポートにお問い合わせください。',
  },
  QrLandingPageError: {
    'Ref: Error title': 'エラーが発生しました',
    'Ref: Error body':
      'スキャンした QR コードは機能しなくなります。後でもう一度試すか、サポートにお問い合わせください。',
    'Go to home': 'ホームに戻る',
  },
  ManualDescribe: {
    'Ref: Page title': 'サービスリクエストについて',
    'Ref: Required fields: header': '追加情報を入力してください',
    'Ref: Required fields': '「詳細」には以下の情報が必要です',
    'Ref: Required field: title': '要約が必要です。',
    'Ref: Required field: description': '説明が必要です',
    'Ref: Required field: category': 'カテゴリが必要です',
    'Ref: Required field: buildingText': '建物が必要です',
    'Ref: Invalid field: category': '入力したカテゴリは無効です',
    'Ref: Your request': 'あなたのサービスリクエスト',
    'Ref: Description message':
      'より詳細な内容をご入力いただくと、より適切なサポートをご提供できます',
    'Ref: Do you have a location QR code': 'ロケーションのQRコードはありますか？',
    'Ref: Scan QR Code': 'QRコードをスキャンします',
    'Ref: Please select category': 'カテゴリを選択してください',
    'Ref: Please select site': 'サイトを選択してください',
    'Ref: building': '建物',
    'Ref: room': '部屋',
    'Ref: Scan error': 'スキャンエラー',
    'Ref: The QR code is not valid': 'QRコードは無効です',
    'Ref: Add location with QR Scanner': 'QRスキャナーで場所を追加します',
    'Ref: Description': '説明',
    'Ref: Forbidden error': '申し訳ありませんが、このQRコードは登録場所と一致しません。',
    'Ref: Invalid error': '申し訳ありませんが、このQRコードはもはや有効ではないようです。',
  },
};
export default labels;
