const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': '服务请求 - 帮助台',
    'Ref: Forbidden heading': '禁止',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      '您正在网站{siteName}上管理服务请求。',
    'Ref: Dates': '日期',
    'Ref: SRL list refresh': '刷新列表',
    'Ref: Export': '出口',
    'Ref: Empty/not filled': '空/没有填充',
    'Ref: Name': '姓名',
    'Ref: Date': '日期',
    'Ref: Category': '类别',
    'Ref: User': '用户',
    'Ref: Location': '地点',
  },
};
export default labels;
