import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { applyStringOptions } from '../helpers/applyStringOptions';
import generateTranslationKeys from '../helpers/generateTranslationKeys';
import getFileName from '../helpers/getFileName';
import isPath from '../helpers/isPath';
import { LabelOptions } from '../localization.types';

export const defaultNamespace = 'languages';
const missingTranslationValue = '<<NOTFOUND">>';
export const noPrefix = '<<NOPREFIX>>';

export const useCustomTranslation = ({
  moduleId = defaultNamespace,
  keyPrefix = noPrefix,
}: {
  moduleId?: string;
  keyPrefix?: string;
} = {}) => {
  const { t } = useTranslation([moduleId, defaultNamespace]);

  const translationKeyPrefix = useMemo(
    () => (isPath(keyPrefix) ? getFileName(keyPrefix) : keyPrefix),
    [keyPrefix]
  );

  const label = useCallback(
    (translationKey: string, options?: LabelOptions) => {
      const translationKeyWithPrefixes = generateTranslationKeys(
        translationKeyPrefix,
        translationKey
      );

      const translated = t(translationKeyWithPrefixes, {
        replace: options?.replace,
        defaultValue: missingTranslationValue,
      });

      if (translated !== missingTranslationValue) {
        // built in formatting support described in docs (https://www.i18next.com/translation-function/formatting#adding-custom-format-function)
        // will not work on our case, because it assumes that formatting is defined in json, not as parameter to 't' function
        return applyStringOptions(translated, options);
      }

      return translationKey;
    },
    [translationKeyPrefix, t]
  );

  return {
    label,
  };
};
