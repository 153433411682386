import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { appInsights } from '../../../../appInsights';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import useLoginStatus from '../../../../helpers/hooks/useLoginStatus';
import { State } from '../../../../types/state.types';
import { pagePaths as orderPagePaths } from '../../../Order/config';
import { useLazyGetSiteByCodeQuery, useSelectSiteMutation } from '../../../Sites/api/api';
import { setGeography } from '../../actions';
import { useRegisterGuestUserMutation } from '../../api/guestUser/guestUserApi';

import {
  getOrderModuleRedirectionPath,
  getProductDetailsRedirectionPath,
} from './ModuleRedirectPage.helper';

import { useLazyGetGeographiesQuery } from '@/modules/Core/api/geolocations/geolocationsApi';

const ModuleRedirectPage = (): React.JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [registerGuestUser] = useRegisterGuestUserMutation();
  const { isLoggedIn } = useLoginStatus();

  const currentSiteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  const { geoCode, siteIdentifier, module } =
    useParams<{
      geoCode: string;
      siteIdentifier: string;
      module: string;
    }>();
  const [getGeographies] = useLazyGetGeographiesQuery();
  const queryParams = new URLSearchParams(location.search);
  const table = queryParams.get('table');
  const outletId = queryParams.get('outletId');
  const menuId = queryParams.get('menuId');

  const [selectSite] = useSelectSiteMutation();
  const [getSiteByCode] = useLazyGetSiteByCodeQuery();

  const isMounted = useRef(false);

  useEffect(() => {
    const handlePageLoad = async () => {
      if (!isLoggedIn) {
        const { data: responseData, isSuccess, status } = await getGeographies();

        if (!isSuccess) {
          appInsights?.trackTrace({
            message: `Get geographies request failed with status: ${status}`,
            severityLevel: SeverityLevel.Error,
          });

          history.push('/');
          return;
        }

        const geography = responseData?.geolocations?.find((geo: any) => geo.code === geoCode);
        if (!geography) {
          appInsights?.trackTrace({
            message: `Geography not found for geoCode: ${geoCode}`,
            severityLevel: SeverityLevel.Error,
          });

          history.push('/');
          return;
        }

        await dispatch(setGeography({ geoCode }));
        await registerGuestUser({ currentGeoCode: geoCode });
      }

      const { data: { site } = {}, error } = await getSiteByCode({ code: siteIdentifier });

      if (error) {
        const { status } = error as { status: number };

        appInsights?.trackTrace({
          message: `Site by code request failed with status: ${status}`,
          severityLevel: SeverityLevel.Error,
        });

        history.push('/');
        return;
      }

      if (!site?.id) {
        appInsights?.trackTrace({
          message: `Site by code request did not returned site id`,
          severityLevel: SeverityLevel.Error,
        });

        history.push('/');
        return;
      }

      if (currentSiteId !== site.id) {
        await selectSite({
          dispatch: dispatch,
          site: {
            id: site.id,
            name: site.name,
            locationtype: 'Site',
          },
        });
      }

      const modulePath = `/${module}`;
      const queryParams = new URLSearchParams(location.search);

      // Unique identifier available only for a product
      if (queryParams.get('id') && queryParams.get('id') !== 'no-menu-item') {
        const productDetailsPath = getProductDetailsRedirectionPath(modulePath, queryParams);
        history.replace(productDetailsPath);
      } else if (modulePath === orderPagePaths.Module) {
        const menuProductsListPath = getOrderModuleRedirectionPath(queryParams);
        history.push(menuProductsListPath);
      } else if (modulePath === orderPagePaths.MenuModule) {
        const menuProductsListPath = getOrderModuleRedirectionPath(queryParams, true);
        history.push(menuProductsListPath);
      } else {
        appInsights?.trackTrace({
          message: 'Not supported redirection',
          severityLevel: SeverityLevel.Error,
        });
        history.push('/');
      }
    };

    if (!isMounted.current) {
      isMounted.current = true;
      handlePageLoad();
    }
  }, [
    isLoggedIn,
    currentSiteId,
    siteIdentifier,
    history,
    location.search,
    module,
    geoCode,
    dispatch,
    getSiteByCode,
    getGeographies,
    selectSite,
    table,
    outletId,
    menuId,
    registerGuestUser,
  ]);

  return <LoadingPage />;
};

export default ModuleRedirectPage;
