import React from 'react';

type ConditionalWrapperProps = {
  condition: boolean;
  wrapperIfTrue: (children: JSX.Element) => React.ReactElement;
  wrapperIfFalse?: (children: JSX.Element) => React.ReactElement;
  children: JSX.Element;
};

const ConditionalWrapper = ({
  condition,
  wrapperIfTrue,
  wrapperIfFalse,
  children,
}: ConditionalWrapperProps) =>
  condition
    ? wrapperIfTrue(children)
    : wrapperIfFalse
    ? wrapperIfFalse(children)
    : children || null;

export default ConditionalWrapper;
