const labels = {
  TimeTable: {
    'leave at': 'Quitte à',
    'arrive at': 'Arriver à',
    at: 'à',
    'Ref: list placeholder': 'Remplissez le formulaire pour voir les heures',
    'Ref: Page title': 'Les autobus',
    'Ref: Departure': 'Départ',
    'Ref: Arrivals': 'Arrivées',
    'Ref: minutes': 'minutes',
    'Ref: no items placeholder': "Désolé, il n'y a pas de bus disponibles pour le moment.",
  },
};
export default labels;
