import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { useSelector } from 'react-redux';

import { appInsights } from '../../../appInsights';
import { State } from '../../../types/state.types';

const useSite = ({ throwWhenNoActiveSite }: { throwWhenNoActiveSite?: boolean } = {}) => {
  const site = useSelector((state: State) => state.Core.context.site);
  if (!site && throwWhenNoActiveSite) {
    appInsights?.trackException({
      severityLevel: SeverityLevel.Error,
      properties: {
        message: `Active site not set`,
      },
    });

    throw new Error('No active site');
  }

  return site;
};

export default useSite;
