const labels = {
  ContentDetails: {
    'Ref: Page title': 'Content Details',
    'See the event': 'See the event',
    'Take the survey': 'Take the survey',
    Date: 'Date',
    Category: 'Category',
    'Ref: Content site different title': 'This Content does not belong to your current site',
    'Ref: Content site different description':
      "You are viewing a content that does not belong to your current site. You won't be able to see it in the list of content.",
  },
  ContentList: {
    'Ref: Page title': 'Newsfeed',
    'featured (as in featured content)': 'Featured',
    'Ref: category - all': 'All',
    'Ref: category - promotional': 'Promotional',
    'Ref: category - featured': 'Featured',
    'Ref: category - other': 'Other',
  },
  Widget: {
    'Ref: Carousel title': 'News & Articles',
    'Ref: Carousel title promo': 'Top News',
    'No info yet, come back later': 'No info yet, come back later 😉',
    'Ref: Content': 'Content',
    'Ref: Widget Title': 'Newsfeed',
    'Ref: MyVillage Carousel title promo': 'Promotions',
  },
};
export default labels;
