import { serviceTypes, modulesConfig } from '../config';

export const moduleId = 'Accomodation';
export const cacheVersion = '0';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  StayDetails: modulePath + '/:id/details',
  Success: modulePath + '/success',
};
