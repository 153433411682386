import baseApi from '../../../../services/api/baseApi';
import mockResponse from '../../mocks/accountApi';

import isLoggedIn from '@/helpers/isLoggedIn';
import {
  Email,
  GetIdentityContextArgs,
  GetIdentityContextRes,
  IdentityContext,
  SendSupportEmailArgs,
  SetUserRegionCodeArgs,
} from '@/modules/Core/api/account/accountApi.types';

const accountApi = baseApi.enhanceEndpoints({ addTagTypes: ['account', 'email'] }).injectEndpoints({
  endpoints: (build) => ({
    getIdentityContext: build.query<IdentityContext, GetIdentityContextArgs>({
      query: (args) => ({
        url: `/v2/identity/context`,
        method: 'get',
        params: { ...args },
        mockData: mockResponse.getIdentityContext,
      }),
      transformResponse: (response: GetIdentityContextRes) => {
        return {
          client: response.client,
          type: response.type,
          provider: response.provider,
          tokenUrl: response.tokenUrlV2,
          useSystemBrowser: response.useSystemBrowser,
        };
      },
      providesTags: ['account'],
    }),
    deleteAccount: build.mutation<{ httpStatusCode: number | undefined }, {}>({
      query: () => ({
        url: `/v2/user/deleteuser`,
        method: 'delete',
      }),
      transformResponse: (response) => {
        return { httpStatusCode: response.status };
      },
      transformErrorResponse: (response) => {
        return { httpStatusCode: response.status };
      },
      invalidatesTags: ['account'],
    }),
    checkEmailExists: build.query<void, Email>({
      query: (args) => ({
        url: '/v3/registrations/email-exists',
        method: 'get',
        params: { ...args },
        mockData: mockResponse.checkEmailExists,
      }),
      providesTags: ['email'],
    }),
    sendSupportEmail: build.mutation<void, SendSupportEmailArgs>({
      query: (args) => ({
        url: '/v1/support/email',
        method: 'post',
        data: { ...args },
        mockData: mockResponse.sendSupportEmail,
        opts: { withoutAccessToken: !isLoggedIn() },
      }),
    }),
    setUserRegionCode: build.mutation<void, SetUserRegionCodeArgs>({
      query: (args) => ({
        url: '/v2/identity/userregioncode',
        method: 'post',
        data: { ...args },
        mockData: mockResponse.setUserRegionCode,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useDeleteAccountMutation,
  useLazyGetIdentityContextQuery,
  useGetIdentityContextQuery,
  useLazyCheckEmailExistsQuery,
  useCheckEmailExistsQuery,
  useSendSupportEmailMutation,
  useSetUserRegionCodeMutation,
} = accountApi;
export default accountApi;
