import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultFacilityMenu } from '../../__mocks/mock';

import MenuTile from './MenuTile';

let tilePropsMock: any;
jest.mock('../../../../components/molecules/Tile', () => {
  return {
    Tile: (props: any) => {
      tilePropsMock = props;
      return <>{props.image}</>;
    },
  };
});

let imageWithFallbackPropsMock: any;
jest.mock(
  '../../../../components/atoms/ImageWithFallback/ImageWithFallback',
  () => (props: any) => {
    imageWithFallbackPropsMock = props;
    return <></>;
  }
);

const props = {
  id: '123',
  facilityTitle: 'Facility title',
  menu: defaultFacilityMenu,
};

describe('MenuTile component', () => {
  describe('with menu', () => {
    renderComponent(MenuTile, props);

    it('should render title and description', () => {
      expect(tilePropsMock.title).toBe('Facility title');
      expect(tilePropsMock.title).toBe('Facility title');
    });
  });

  describe('with menu image', () => {
    renderComponent(MenuTile, {
      ...props,
      menuImageSource: 'imageContent',
    });

    it('should render it', () => {
      expect(imageWithFallbackPropsMock.imgInfo).toBeDefined();
      expect(imageWithFallbackPropsMock.imgInfo.src).toBe('imageContent');
      expect(imageWithFallbackPropsMock.imgInfo.alt).toBe('Facility title');
    });
  });

  describe('without menu image', () => {
    renderComponent(MenuTile, props);

    it('should render default image', () => {
      expect(imageWithFallbackPropsMock.imgInfo).toBeUndefined();
      expect(imageWithFallbackPropsMock.imgElement).toBeDefined();
    });
  });
});
