import classNames from 'classnames';
import { useState } from 'react';

import RatingStar from '../../../../../components/atoms/RatingStar';

import { StarRatingProps } from './StarRating.types';

import styles from './StarRating.module.css';

const StarRating = ({ id, value = 0, maximumRating, onChange, ...props }: StarRatingProps) => {
  const [rating, setRating] = useState(value);

  const handleChange = (v: number) => {
    setRating(v);
    if (onChange) onChange(v);
  };

  return (
    <div className={classNames(styles.wrapper)}>
      <RatingStar
        {...props}
        starsNum={maximumRating}
        value={rating}
        groupName={id}
        onChange={handleChange}
      />
    </div>
  );
};

export default StarRating;
