import { ISite } from '../types/sites.types';

export const defaultSite: ISite = {
  id: '1234',
  name: 'test site',
  locationtype: 'type',
  currency: {
    isoCode: 'USD',
    currencyName: 'Dolar',
    currencySymbol: 'USD',
    exchangeRate: 1,
    precision: 1,
  },
};
