const labels = {
  TimeTable: {
    'leave at': 'Leave at',
    'arrive at': 'Arrive at',
    at: 'at',
    'Ref: list placeholder': 'Complete the form to see the hours',
    'Ref: Page title': 'Buses',
    'Ref: Departure': 'Departure',
    'Ref: Arrivals': 'Arrivals',
    'Ref: minutes': 'minutes',
    'Ref: no items placeholder': 'Sorry, there are no buses available at this time.',
  },
};
export default labels;
