const labels = {
  TimeTable: {
    'leave at': 'Elindít',
    'arrive at': 'Megérkezik',
    at: 'nál nél',
    'Ref: list placeholder': 'Töltse ki az űrlapot az órák megtekintéséhez',
    'Ref: Page title': 'Buszok',
    'Ref: Departure': 'Indulás',
    'Ref: Arrivals': 'Érkezés',
    'Ref: minutes': 'percek',
    'Ref: no items placeholder': 'Sajnálom, jelenleg nincs busz.',
  },
};
export default labels;
