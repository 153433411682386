import classNames from 'classnames';

import { CheckIcon } from '../../../assets/icons';

import { TimelineProps } from './Timeline.types';

import styles from './Timeline.module.css';

const Timeline = ({ items = [] }: TimelineProps) => {
  return (
    <div>
      {items.map(({ name, date, isComplete }, i) => (
        <>
          <div key={name} className={classNames(styles.marker)}>
            <div
              className={classNames(styles.markerIcon, { [styles.markerIconComplete]: isComplete })}
            >
              <CheckIcon />
            </div>
            <div className={classNames(styles.markerName, 'bodySBold')}>{name}</div>
            <div className={classNames(styles.markerDate, 'bodySDefault')}>{date}</div>
          </div>
          {items.length > i + 1 && (
            <div className={classNames(styles.line)}>
              <div />
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default Timeline;
