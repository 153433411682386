import { screen, cleanup } from '@testing-library/react';

import { ServiceRequestLightDetailResponse } from '../../../api/api.types';
import Details from '../Details';

import renderComponent from '@/helpers/tests/renderComponent';
import { defaultState as coreDefaultState } from '@/modules/Core/reducers/coreReducer';

const mock: { data: ServiceRequestLightDetailResponse; isLoading: boolean } = {
  data: {
    affectedPersonFullName: 'test sanofi',
    attachments: [
      {
        fileName: '',
        content: '',
      },
    ],
    buildingText: 'building',
    category: 'cleaning',
    createDateTime: '2023-11-09T07:22:36.5792746Z',
    description: 'service request description block',
    id: '8224551a-534e-4b82-a983-f09b036906d5',
    phoneNumber: '000111222',
    roomText: 'room',
    serviceRequestNumber: 'SRL-265324786',
    siteName: 'Sanofi Great Valley',
    title: 'test title',
  },
  isLoading: false,
};
const mockedState = {
  Core: {
    ...coreDefaultState,
    services: {
      list: [
        {
          name: 'ServiceRequestsLight',
          setupOptions: [
            {
              name: 'enableAffectedPerson',
              value: 'true',
              code: 'enableAffectedPerson',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'enableCategories',
              value: 'true',
              code: 'enableCategories',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
        },
      ],
    },
  },
};

jest.mock('@/modules/ServiceRequestLight/api', () => ({
  useGetServiceRequestLightDetailQuery: () => ({
    data: mock.data,
    isFetching: false,
  }),
}));

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: () =>
    jest.fn().mockReturnValue({
      id: '646fe689-4eca-47a0-a272-24df7b64fba4',
    }),
}));

describe('Service request light Details', () => {
  afterAll(() => cleanup());

  describe('Initial render', () => {
    renderComponent(Details, {}, mockedState);
    it('should have all fields', async () => {
      expect(screen.getAllByText('Created on')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-createdOn-list-item-wrapper')
      ).toBeTruthy();

      expect(screen.getAllByText('Summary')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-summary-list-item-wrapper')
      ).toBeTruthy();

      expect(screen.getAllByText('Request category')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-category-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('The person affected by this request')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-raised-for-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Phone number')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-phone-number-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Location')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-location-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Description')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-description-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Attachments')).toBeTruthy();
    });
  });

  describe('Without category', () => {
    renderComponent(
      Details,
      {},
      {
        Core: {
          ...coreDefaultState,
          services: {
            list: [
              {
                name: 'ServiceRequestsLight',
                setupOptions: [
                  {
                    name: 'enableAffectedPerson',
                    value: 'true',
                    code: 'enableAffectedPerson',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                ],
              },
            ],
          },
        },
      }
    );
    it('Category is not displayed', async () => {
      expect(screen.getAllByText('Created on')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-createdOn-list-item-wrapper')
      ).toBeTruthy();

      expect(screen.getAllByText('Summary')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-summary-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.queryAllByText('Request category')).toHaveLength(0);
      expect(
        screen.queryByTestId('service-request-light-details-category-list-item-wrapper')
      ).toBeNull();
      expect(screen.getAllByText('The person affected by this request')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-raised-for-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Phone number')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-phone-number-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Location')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-location-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Description')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-description-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Attachments')).toBeTruthy();
    });
  });

  describe('Without affected person', () => {
    renderComponent(
      Details,
      {},
      {
        Core: {
          ...coreDefaultState,
          services: {
            list: [
              {
                name: 'ServiceRequestsLight',
                setupOptions: [
                  {
                    name: 'enableCategories',
                    value: 'true',
                    code: 'enableCategories',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                ],
              },
            ],
          },
        },
      }
    );
    it('Affected person is not displayed', async () => {
      expect(screen.getAllByText('Created on')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-createdOn-list-item-wrapper')
      ).toBeTruthy();

      expect(screen.getAllByText('Summary')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-summary-list-item-wrapper')
      ).toBeTruthy();

      expect(screen.getAllByText('Request category')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-category-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.queryAllByText('The person affected by this request')).toHaveLength(0);
      expect(
        screen.queryByTestId('service-request-light-details-raised-for-list-item-wrapper')
      ).toBeNull();
      expect(screen.getAllByText('Phone number')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-phone-number-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Location')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-location-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Description')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-description-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Attachments')).toBeTruthy();
    });
  });

  describe('Without setup options', () => {
    renderComponent(
      Details,
      {},
      {
        Core: {
          ...coreDefaultState,
          services: {
            list: [
              {
                name: 'ServiceRequestsLight',
                setupOptions: [
                  {
                    name: null,
                    value: null,
                    code: null,
                    isServiceType: true,
                    isServicePointer: false,
                  },
                ],
              },
            ],
          },
        },
      }
    );
    it('Setup options are not displayed', async () => {
      expect(screen.getAllByText('Created on')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-createdOn-list-item-wrapper')
      ).toBeTruthy();

      expect(screen.getAllByText('Summary')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-summary-list-item-wrapper')
      ).toBeTruthy();

      expect(screen.queryAllByText('Request category')).toHaveLength(0);
      expect(
        screen.queryByTestId('service-request-light-details-category-list-item-wrapper')
      ).toBeNull();
      expect(screen.queryAllByText('The person affected by this request')).toHaveLength(0);
      expect(
        screen.queryByTestId('service-request-light-details-raised-for-list-item-wrapper')
      ).toBeNull();
      expect(screen.getAllByText('Phone number')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-phone-number-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Location')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-location-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Description')).toBeTruthy();
      expect(
        screen.getByTestId('service-request-light-details-description-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Attachments')).toBeTruthy();
    });
  });
});
