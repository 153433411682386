import isEqual from 'lodash/isEqual';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

const useStateWithProps = <StateType>(
  propsValue: StateType
): [StateType, Dispatch<SetStateAction<StateType>>] => {
  const [value, setValue] = useState<StateType>(propsValue);
  const valueRef = useRef(propsValue);

  useEffect(() => {
    if (!isEqual(propsValue, valueRef.current)) {
      setValue(propsValue);
    }
    valueRef.current = propsValue;
  }, [propsValue, value]);

  return [value, setValue];
};

export default useStateWithProps;
