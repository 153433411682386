const labels = {
  All: {
    Region: 'אזור או מדינה',
    'Ref: Geography': 'אנא בחר את המדינה או האזור שלך',
    'Ref: Country': 'מדינה',
    'Ref: Country tooltip': 'המיקום תואם את מדינת האתר.',
    'Ref: Please select your country': 'בבקשה בחר את המדינה שלך',
    'First name': 'שם פרטי',
    'Last name': 'שם משפחה',
    'Ref: Full name': 'שם מלא',
    'Mobile number': 'מספר טלפון נייד',
    'Mobile phone': 'טלפון נייד',
    Email: 'כתובת דוא"ל',
    Password: 'סיסמה',
    logout: 'להתנתק',
    'Not a new user? Log in': 'לא משתמש חדש? התחברות',
    'do not register, login': 'לא משתמש חדש? התחברות',
    'Ref: I agree': 'אני מסכים',
    Register: 'הירשם',
    'Ref: My language': 'השפה שלי',
    'Ref: Kiosk Fail Title': 'אופס! משהו השתבש!',
    'Ref: Kiosk Fail Description':
      'למרבה הצער, ההזמנה שלך לא הייתה מוצלחת. אנא נסה שוב או בקש מאיש צוות שיעזור לך.',
    'Ref: Kiosk Fail Back': 'חזרה לדף הקודם',
    'Ref: Kiosk Fail Action': 'נקה את הפגישה והזמנה שוב',
    Validate: 'לְאַמֵת',
    'Ref: error, incorrect credentials': 'אישורים אלה אינם תואמים בגיאוגרפיה הנבחרת.',
    'Ref: Forgot password?': 'שכחת את הסיסמא?',
    'Ref: Hello': 'שלום {wave_emoji}, אנא התחבר כדי להמשיך',
    'Ref: Form title': 'אנא הירשם כדי להמשיך',
    'Already have an account? Login': 'כבר יש לך חשבון? התחברות',
  },
  AccountPage: {
    'Ref: My Information': 'המידע שלי',
    'Ref: Settings': 'הגדרות',
    'Ref: Email': 'אימייל',
    'Ref: Current site': 'האתר הנוכחי',
    'Ref: Choose site': 'בחר אתר',
    'Ref: Personal interests': 'תחומי עניין אישיים',
    'Ref: Food preferences': 'העדפות אוכל',
    'Ref: Password': 'סיסמה',
    'Ref: Page title': 'חֶשְׁבּוֹן',
    'Ref: Language': 'שפה',
    'Ref: Communication preferences': 'העדפות תקשורת',
    'Ref: Terms & conditions': 'תנאים והגבלות',
    'Ref: Privacy policy': 'מדיניות הפרטיות',
    'Ref: Delete my account': 'מחק את חשבוני',
    'Ref: Confirm deletion': 'האם אתה בטוח שאתה רוצה למחוק את חשבונך?',
    'Ref: Request deletion': 'האם אתה בטוח שאתה רוצה לבקש את מחיקת חשבונך?',
    'Ref: Deletion description': 'בקשתך תטופל על פי הרגולציה המקומית שלך.',
    Placeholder: 'כתוב "מחק"',
    'Ref: Delete description': 'הנתונים שלך יימחקו. פעולה זו בלתי הפיכה.',
    delete: 'לִמְחוֹק',
    'Ref: Terms of sale': 'תנאי מכירה',
    'Ref: Imprint': 'חוֹתָם',
    'Ref: Cookie Settings': 'הגדרות עוגיות',
    'Ref: Support': 'תמיכה',
    'Ref: Register or login': 'להירשם או להתחבר',
    'Ref: Filtering Preferences': 'העדפות סינון',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'האם אתה בטוח למחוק את חשבונך?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'כתוב "{word}" בשדה הקלט ואשר עם החלק התחתון למטה. פעולה זו בלתי הפיכה.',
    'Ref: Write "{word}"': 'כתוב "{word}"',
    'Ref: Delete my account': 'מחק את חשבוני',
    'Ref: Words are not matching': 'מילים אינן תואמות',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'בחר את המיקום שלך להתחלה',
    'Ref: Choose location':
      'מצא במהירות מיקום על ידי הפעלת הלוקליזציה, סריקת קוד QR או חיפוש לפי שם',
    'Ref: next step': 'בוא נלך',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'הירשם',
    'Ref: identify your location': 'יש לזהות את המיקום שלך.',
    'Ref: option 1 - scan QR': 'אפשרות 1: סרוק את קוד ההרשמה QR שסופק',
    'Ref: option 2 - enter code': 'אפשרות 2: הזן ידנית את קוד המיקום שלך',
    'Ref: button to open qr code scanner modal': 'יש לי QR: בואו לסרוק אותו',
    'Ref: button to open site code input modal': 'בחר אתר עם קוד',
    'Ref: getting help with site identifier - support desk':
      'אם אינך ברור כיצד להמשיך, פנה לשולחן התמיכה שלך.',
    'Ref: getting help with site identifier - support form': 'אתה יכול גם לשלוח לנו פתק.',
    'Location code': 'קוד מיקום',
    'Ref: location code field placeholder': 'קוד המיקום שלך כאן',
    'Ref: Scanner header': 'סריקת קוד QR',
    'Ref: site code input header': 'הוסף אתר עם קוד',
    'Ref: not valid code': 'קוד זה אינו תקף בגיאוגרפיה שנבחרה',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'הַרשָׁמָה',
    'Ref: collected info': 'סיכום',
    'Ref: additional info': 'הפרטים שלך',
    'Confirm password': '*אשר סיסמה',
    company: 'מיהו המעסיק שלך?',
    location: 'מקום',
    username: 'שם משתמש',
    register: 'הירשם',
    'Ref: password strength':
      'על הסיסמה שלך להיות באורך של 8 תווים לפחות, להכיל מספר אחד לפחות, אותיות גדולות, אותיות קטנות ותו מיוחד.',
    'passwords do not match': 'סיסמאות לא תואמות.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'העדפות תקשורת',
    'Ref: Things happen while you are away': 'דברים קורים בזמן שאתה לא נמצא',
    'Ref: Would you like to receive update about offers and promotions?':
      'האם תרצה לקבל עדכונים לגבי הצעות ומבצעים?',
    'Ref: Would you like to hear about content we think you may like?':
      'האם תרצה לשמוע על תוכן שאנחנו חושבים שאולי תרצה?',
    Yes: 'כן',
    No: 'לא',
    apply: 'להגיש מועמדות',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'פרטי חשבון' },
  DeletionFailure: {
    'Ref: Body':
      'אירעה שגיאה ובקשתך למחיקת חשבון לא הוגשה. אם שגיאה זו נמשכת, אנא צור קשר עם ה- דלפק העזרה שלך.',
    'Go to account': 'עבור לחשבון',
  },
  GenericFailurePage: {
    'Ref: Title': 'משהו השתבש',
    'Ref: Body': 'מצטערים, משהו השתבש. אם בעיה זו נמשכת, פנה לתמיכה באתר שלך.',
  },
  HomePage: {
    notifications: 'התראות',
    basket: 'עגלה',
    'Ref: Tabtitle': 'בית',
    'contact us': 'צור קשר',
    'Ref: You are logged in as a guest user': 'אתה מחובר כמשתמש אורח',
    'Ref: Good morning': 'בוקר טוב',
    'Ref: Good afternoon': 'אחר הצהריים טובים',
    'Ref: Good evening before midnight': 'ערב טוב',
    'Ref: Good evening after midnight': 'ערב טוב',
    'Ref: Search': 'לחפש',
    'Ref: products button aria': 'נמצא {results_form} בתוך מוצרים',
    'Ref: menus button aria': 'נמצא {results_form} בתפריטים',
    'Ref: facilities button aria': 'נמצא {results_form} בתוך מתקנים',
    'Ref: result': 'תוֹצָאָה',
    'Ref: results': 'תוצאות',
    'Ref: Menus': 'תפריטים',
    'Ref: No results title': 'אופס!',
    'Ref: No results body': 'אין תוצאות תואמות את החיפוש שלך',
    'Ref: Products': 'מוצרים',
    'Ref: Facilities': 'מתקנים',
    'Ref: See more results': 'ראה תוצאות חיפוש נוספות',
    'Ref: Accessibility instructions':
      'כדי להתחיל להשתמש בחיפוש, עליך ללחוץ עליו או לחץ על Enter תוך כדי ממוקד.',
    'Ref: See more': 'ראה עוד',
    'Ref: singular form of products': 'מוצר',
    'Ref: singular form of menus': 'תַפרִיט',
    'Ref: singular form of facilities': 'מִתקָן',
    'Ref: Search results for': 'תוצאות חיפוש עבור',
  },
  LanguagePage: {
    apply: 'להגיש מועמדות',
    'My language': 'השפה שלי',
    'Select in which language you would like to read our app':
      'בחר באיזו שפה תרצה לקרוא את האפליקציה שלנו',
    'Ref: Page title': 'שפה',
  },
  LegalDocPage: {
    Acknowledge: 'לְהוֹדוֹת',
    'Ref: Legal changed notification title': '"{legal_doc_type}" שונו',
    'Ref: Legal changed notification content': 'אנא עיין בהם לפני שתמשיך להשתמש באפליקציה.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'תנאים והגבלות',
    'Read privacy policy': 'קרא את מדיניות הפרטיות',
    'Ref: Acknowledge legal docs': 'אני מקבל את התנאים וההגבלות האלה.',
    'Ref: Read and understood': 'קראתי והבנתי ',
    'Ref: No legal docs available - notice title': 'בקרוב',
    'Ref: No legal docs available - notice body':
      'מצטערים, נראה כי יישום זה אינו זמין כעת בגיאוגרפיה שלך.',
    'Ref: The privacy Policy': 'מדיניות הפרטיות',
    'Ref: Legal changed notification title': '"{legal_doc_type}" שונו',
    'Ref: Legal changed notification content': 'אנא עיין בהם לפני שתמשיך להשתמש באפליקציה.',
  },
  LoginPage: {
    'Ref: Page title': 'ברוך הבא לאפליקציה {app name} ',
    'Ref: Page subtitle': 'היכנס כדי להמשיך',
    'Ref: Email field placeholder': 'האימייל שלך',
    'Ref: Email field note': 'לעולם לא נשתף את הדוא"ל שלך עם אף אחד.',
    'Ref: Password field title': 'הסיסמה שלך',
    register: 'הירשם',
    'I forgot my password.': 'שכחת את הסיסמא?',
    'I have trouble logging in': 'יש לך בעיה בלהיכנס?',
    'start registration': 'התחלה רישום',
    'Ref: Register': 'הירשם',
    'Ref: Login': 'התחברות',
    'Ref: Connection issue?': 'בעיות חיבור?',
    'Ref: Connection issue message':
      'אם יש לך בעיה להירשם או להתחברות, אנא צור קשר עם הצוות הרגיל באתר או ל- דלפק העזרה.',
  },
  WelcomePage: { 'Ref: Welcome to': 'שלום וברוך הבא ל {site_name}', 'Ref: Begin': 'התחל' },
  NotificationsList: {
    'Ref: No notifications': 'אין לך התראות כרגע',
    'Ref: This week': 'השבוע',
    'Ref: Earlier': 'מוקדם יותר',
    'Ref: Page title': 'התראות',
    'Ref: See more': 'ראה עוד',
    'Ref: Mark all read': 'סמן את כל הקריאה',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'ראה את כל ההודעות' },
  ServicesMenuPage: { 'Ref: Page title': 'שירותים', navigate: 'נווט' },
  DeletionSuccess: {
    'Ref: Title': ' אתה עלול לאבד את הגישה שלך בכל עת.',
    'Ref: Content': 'מחיקת חשבונך התבקשה. נטפל בזה בקרוב.',
    'Log out': 'להתנתק',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'אנו נחזור אליך',
    'Ref: Body': 'אנו מצטערים שאתה מתמודד עם בעיות. אנו ניצור איתך קשר בקרוב מאוד.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'יש לך בעיה בלהיכנס?',
    'Ref: Email field placeholder': 'כתובת הדוא"ל שלך',
    'Ref: Mobile phone field placeholder': 'מספר טלפון',
    Company: 'חֶברָה',
    'Ref: Company field placeholder': 'שם החברה',
    'Ref: Company field note': 'אם אתה יועץ, זוהי החברה שעבורו אתה עובד כרגע, לא המעסיק שלך.',
    Location: 'עיר או מיקום',
    'Ref: Location field placeholder': 'מיקום מקום העבודה שלך',
    'Ref: Location field note': 'אם מחוץ לאזור מיושב, אל תהסס להשתמש בשם שבו האזור נקרא בדרך כלל.',
    Comment: 'תגובה',
    'Ref: Comment field placeholder': 'מה השתבש?',
    'Ref: Who is your employer?': 'מיהו המעסיק שלך?',
    'Ref: What village are you staying at?': 'באיזה כפר אתה נשאר?',
    'Ref: Comment field note': 'כל מידע שיכול לעזור לנו לעזור לך!',
    'Ref: Feedback Content - geography': 'גֵאוֹגרַפיָה',
    'Ref: Feedback Content - fullName': 'שֵׁם',
    'Ref: Feedback Content - email': 'אימייל',
    'Ref: Feedback Content - phone': 'טלפון',
    'Ref: Feedback Content - company': 'חֶברָה',
    'Ref: Feedback Content - city': 'שם עיר / שם מיקום',
    'Ref: Feedback Content - whatVillage': 'כְּפָר',
    'Ref: Feedback Content - employer': 'מעסיק',
    'Ref: Feedback Content - comment': 'מה השתבש',
  },
  AccountStartPage: {
    'Ref: Header': 'בחר את המיקום שלך להתחלה',
    'Ref: Body': 'מצא במהירות מיקום על ידי הפעלת הלוקליזציה, סריקת קוד QR או חיפוש לפי שם',
    'Ref: LetsGo button': 'בוא נלך',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'הַרשָׁמָה' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'בחר אתר עם סורק QR',
    'Ref: Page title': 'בחר את המיקום שלך',
    'Ref: Select site with code': 'בחר אתר עם קוד',
    'Ref: Enter site code': 'הזן קוד אתר',
    'Ref: Scanner error header': 'האתר לא נמצא',
    'Ref: Not found': 'קוד זה אינו תקף בגיאוגרפיה שנבחרה',
    'Ref: Generic error': 'משהו השתבש',
    'All other sites': 'כל שאר האתרים',
    'Sites in your country': 'אתרים במדינה שלך',
    'Ref: Unauthorized error': 'בקשה לא מורשית עקב רמת הגבלת האתר',
    'Ref: Forbidden':
      'סליחה, אין לך הרשאות נכונות לגשת לאתר זה. לתיקון גישה, אנא צרו קשר עם מרכז השירות של פילבארה בטלפון 1800 992 777',
    "Ref: We didn't find the site.": 'לא מצאנו את האתר.',
    'Ref: We recommend some solutions:': 'אנו ממליצים על כמה פתרונות:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. נסה להוסיף את האתר שלך עם סורק QR או בעזרת הכפתורים למטה',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. התנתק והתחבר חזרה על ידי בחירת המדינה הנכונה',
    'Ref: The currently selected country is:': 'המדינה שנבחרה כיום היא:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'הַרשָׁמָה',
    'Confirm password': 'אשר סיסמה',
    'Email is not valid': 'הדוא"ל אינו תקף',
    'passwords do not match': 'סיסמאות לא תואמות.',
    'Ref: Password strength':
      'הסיסמה שלך חייבת להיות באורך של לפחות 8 תווים, להכיל לפחות מספר אחד, אחד גדול, אחד, אחד קטן ותו מיוחד אחד.',
    'Sign up': 'הירשם',
    'Continue as guest': 'המשך כאורח',
    'Already have an account? Login': 'כבר יש לך חשבון? התחברות',
    'Ref: Registration success title': 'תודה שנרשמתם',
    'Ref: Registration success content': 'חשבונך ייווצר בקרוב. תקבל דוא"ל המאשר את היצירה.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'אנא התחבר/הירשם',
    'Ref: Password strength':
      'על הסיסמה שלך להיות באורך של 8 תווים לפחות, להכיל מספר אחד לפחות, אותיות גדולות, אותיות קטנות ותו מיוחד.',
    Stamps: 'בולים',
    'Order history': 'היסטוריית הזמנות',
    'to earn points': 'להרוויח נקודות',
    'to see order history': 'לראות היסטוריית הזמנה',
    'to place an order': 'להזמין',
    'Sign up': 'הירשם',
    Login: 'התחברות',
    'Email is not valid': 'הדוא"ל אינו תקף',
    'passwords do not match': 'סיסמאות לא תואמות.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'תודה',
    'Ref: Body':
      'כמעט סיימת! בקרוב תקבל דוא"ל אימות בכתובת המסופקת. פתח דוא"ל זה ולחץ על הקישור כדי להפעיל את חשבונך.',
    'Ref: Enter': 'לְהַמשִׁיך',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'ארעה שגיאה',
    'Ref: Generic Error Body': 'אנא נסה שוב מאוחר יותר או צור קשר עם ה- דלפק העזרה שלך.',
    'Ref: Missing IndexDb support':
      'סליחה, אינך יכול לגשת ליישום במצב גווניטו או ללא תמיכה ב- IndexDB. אנא נסה שוב עם דפדפן אחר.',
    'Ref: Resource not found': 'תוכן אליו אתה מנסה לגשת לא קיים.',
    'Ref: Permission denied - SITE_HIDDEN': 'תוכן אליו אתה מנסה לגשת שייך לאתר פרטי.',
    'Ref: Permission denied - CONTRACT_DIFFERENT': 'תוכן אליו אתה מנסה לגשת אינו מורשה לחשבונך.',
    'Ref: TabTitle': 'שְׁגִיאָה',
    'Ref: Site link invalid':
      'קישור זה אינו חוקי, בחר אתר אחר או צור קשר עם ה- דלפק העזרה אם הבעיה נמשכת.',
    'Ref: Access Denied': 'גישה נדחתה. הרשאות משתמש לא מספקות.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'אופס! {robot_emoji}',
    'Ref: Error title': 'משהו השתבש!',
    'Ref: Error body': 'נסה לרענן דף זה או ליצור קשר עם דלפק העזרה אם הבעיה נמשכת.',
    'Ref: Error ID:': 'מזהה שגיאה: {errorId}',
    'Ref: Back to home': 'בחזרה לבית',
  },
  Child: {
    'Ref: Route Not Found':
      'השירות שאתה מחפש אינו זמין באתר זה. אנא נסה שוב מאוחר יותר או צור קשר עם ה- דלפק העזרה שלך.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'מזל טוב, הסיסמה החדשה שלך נשמרה בהצלחה, כעת תוכל לגשת לכל השירותים שלנו מחשבונך.',
    Welcome: 'ברוך הבא',
    'Ref: Welcome message':
      'כעת נרשמת בהצלחה ליישום BITE החדש ויכולה להמשיך לגלות וליהנות מכל השירותים שלנו.',
  },
  PasswordChange: {
    'Change Password': 'שנה סיסמא',
    Welcome: 'ברוך הבא',
    'Ref: Password message': 'יש לשנות את הסיסמה שלך מכיוון שהיא לא מספיק מאובטחת',
  },
  AccountPasswordChange: {
    'Change your password here': 'שנה את הסיסמה שלך כאן',
    'Your old password': 'הסיסמה הישנה שלך',
    'Type in your new password': 'הקלד את הסיסמה החדשה שלך',
    'Re-type in your new password': 'סוג מחדש בסיסמה החדשה שלך',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'סיסמתך שונתה' },
  AccountPasswordChangeFailure: {
    'Ref: Error message': 'אירעה שגיאה מכאן שהשינוי שלך לא נשמר. בבקשה נסה שוב.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'לאפס את הסיסמה',
    'Ref: Provide email':
      'אנא ספק את כתובת הדוא"ל המשמשת בעת ההרשמה ב- {app_name}. דוא"ל יישלח אליך לאפס את הסיסמה שלך.',
    'Ref: Something happened': 'משהו קרה. בבקשה נסה שוב מאוחר יותר.',
  },
  ResetEmailSent: {
    'Ref: New email sent': 'שלחנו דוא"ל חדש. אנא המתן 30 שניות כדי להימסר שוב.',
    'Go to login page': 'עבור לדף הכניסה',
    'Resend email': 'שלח אימייל מחדש',
    'Ref: Email sent title': 'דוא"ל לאיפוס סיסמה נשלח אליך',
    'Ref: TabTitle': 'לאפס את הסיסמה',
    'Ref: Email sent msg':
      'קישור האיפוס נשלח לכתובת שלך {email_address}. אנא המתן 30 שניות לפני שתנסה להיכנס שוב.',
  },
  ResetPassword: { 'Enter your new password': 'הכנס סיסמה חדשה' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'הסיסמה שלך שונתה בהצלחה',
    'Go to login page': 'עבור לדף הכניסה',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'איפוס סיסמא נכשל',
    'Ref: Error': 'קרתה שגיאה. אנא נסה שוב',
    'Reset your password': 'לאפס את הסיסמה שלך',
    'Go to login page': 'עבור לדף הכניסה',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'מדיניות הפרטיות',
    'Ref: Privacy Policy Body':
      'על ידי גישה לפלטפורמה זו, Sodexo SA וישות Sodexo המקומית יעבדו את הנתונים האישיים שלך על מנת לנהל לפחות את חשבונך ולספק את השירותים והצעות הזמינות דרך הפלטפורמה. אם ברצונך לקבל מידע נוסף על עיבוד הנתונים האישיים שלך, אליהם יגיעו אליהם וכיצד לממש את הזכויות שיש לך על הנתונים האישיים שלך, אנא עיין במדיניות הפרטיות למטה וזמין בכל עת בחשבונך.',
    'Ref: Continue': 'לְהַמשִׁיך',
    'Ref: Read Privacy Policy': 'קרא מדיניות פרטיות',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'לך לחנות' },
  SharePage: { 'Ref: Select a site': 'בחר אתר' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'לצפייה במדיניות פרטיות אנא בחר את המדינה שלך.',
  },
};
export default labels;
