import { screen, cleanup, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import renderComponent from '../../../../../helpers/tests/renderComponent';
import ContentList from '../ContentList';

const list = [
  {
    id: '1',
    title: 'Yoga',
    details: 'Description 1',
    type: 'Normal',
    publishedDate: '11/03/2022 2:21:00 PM',
    reactions: {
      me: null,
      reactions: [],
      total: 0,
    },
  },
  {
    id: '2',
    title: 'Agile Workshop',
    details: 'Description 2',
    type: 'Featured',
    publishedDate: '10/20/2022 1:07:00 PM',
    reactions: {
      me: null,
      reactions: [],
      total: 0,
    },
  },
  {
    id: '3',
    title: 'Relax and reflect',
    details: 'Description 3',
    type: 'Promotional',
    publishedDate: '10/31/2022 1:07:00 PM',
    reactions: {
      me: null,
      reactions: [],
      total: 0,
    },
  },
];

jest.setTimeout(100000);

jest.mock('../../../api/api', () => ({
  useGetHomeQuery: () => ({
    data: list,
    isLoading: false,
  }),
  useUpdateContentReactionMutation: () => [() => true],
  useDeleteContentReactionMutation: () => [() => true],
}));

describe('ContentList', () => {
  const env = global.process.env;
  afterAll(() => cleanup());

  describe('ContentList - MyWay&Wando', () => {
    describe('on initial render without filters', () => {
      let input: HTMLElement;
      let tiles: HTMLElement[];

      beforeEach(() => {
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
      });

      renderComponent(ContentList);

      beforeEach(() => {
        input = screen.getByTestId(
          'content-list-search-bar-list-page-search-filter-bar-input-field'
        );
        tiles = screen.getAllByTestId('tile');
      });

      it('should have search input', () => {
        expect(input).toBeTruthy();
      });

      it('should have the correct number of tiles listed', () => {
        expect(tiles.length).toEqual(3);
      });
    });
  });

  describe('ContentList - MyVillage', () => {
    describe('on initial render', () => {
      let allButton: HTMLElement;
      let promotionalButton: HTMLElement;
      let featuredButton: HTMLElement;
      let otherButton: HTMLElement;
      let input: HTMLElement;
      let tiles: HTMLElement[];

      beforeEach(() => {
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE };
      });

      renderComponent(ContentList);

      beforeEach(() => {
        allButton = screen.getByText('All');
        promotionalButton = screen.getByText('Promotional');
        featuredButton = screen.getByText('Featured');
        otherButton = screen.getByText('Other');
        input = screen.getByTestId(
          'content-list-search-bar-list-page-search-filter-bar-input-field'
        );
        tiles = screen.getAllByTestId('tile');
      });

      it('should have all 4 filter buttons', () => {
        expect(allButton).toBeTruthy();
        expect(promotionalButton).toBeTruthy();
        expect(featuredButton).toBeTruthy();
        expect(otherButton).toBeTruthy();
      });

      it('should have search input', () => {
        expect(input).toBeTruthy();
      });

      it('should have the correct number of tiles listed', () => {
        expect(tiles.length).toEqual(3);
      });
    });

    describe('on filtering promotional content', () => {
      let promotionalButton: HTMLElement;
      let tiles: HTMLElement[];
      const getHome = jest.fn();

      beforeEach(() => {
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE };
        getHome.mockReturnValue({ ok: true, responseData: { contents: list } });
      });

      renderComponent(ContentList);

      beforeEach(async () => {
        promotionalButton = screen.getByText('Promotional');
        await userEvent.click(promotionalButton);
      });

      it('should have the correct number of tiles listed', () => {
        tiles = screen.getAllByTestId('tile');
        expect(tiles.length).toEqual(1);
      });

      it('should filter the correct content', () => {
        expect(screen.getByText('Relax and reflect')).toBeTruthy();
      });
    });

    describe('on filtering other content', () => {
      let otherButton: HTMLElement;
      let tiles: HTMLElement[];
      const getHome = jest.fn();

      beforeEach(() => {
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE };
        getHome.mockReturnValue({ ok: true, responseData: { contents: list } });
      });

      renderComponent(ContentList);

      beforeEach(async () => {
        otherButton = screen.getByText('Other');
        await userEvent.click(otherButton);
      });

      it('should have the correct number of tiles listed', () => {
        tiles = screen.getAllByTestId('tile');
        expect(tiles.length).toEqual(1);
      });

      it('should filter the correct content', () => {
        expect(screen.getByText('Yoga')).toBeTruthy();
      });
    });
  });

  describe('on filtering with search bar', () => {
    let input: HTMLElement;
    let tiles: HTMLElement[];
    const getHome = jest.fn();

    beforeEach(() => {
      getHome.mockReturnValue({ ok: true, responseData: { contents: list } });
    });

    renderComponent(ContentList);

    beforeEach(async () => {
      input = screen.getByTestId('content-list-search-bar-list-page-search-filter-bar-input-field');

      await act(async () => {
        await userEvent.type(input, 'Relax');
        await new Promise((r) => setTimeout(r, 400));
      });
    });

    it('should have the correct number of tiles listed', () => {
      tiles = screen.getAllByTestId('tile');
      expect(tiles.length).toEqual(1);
    });

    it('should filter the correct content', () => {
      expect(screen.getByText('Relax and reflect')).toBeTruthy();
    });
  });

  describe('on tile click', () => {
    let yogaContent: HTMLElement;
    const getHome = jest.fn();
    const history = createMemoryHistory();

    beforeEach(() => {
      getHome.mockReturnValue({ ok: true, responseData: { contents: list } });
    });

    renderComponent(ContentList, null, undefined, history);

    beforeEach(() => {
      yogaContent = screen.getByText('Yoga');
    });

    it('should have redirected to content details', async () => {
      await userEvent.click(yogaContent);
      expect(history.location.pathname).toBe(`/content/${list[0].id}/details`);
    });
  });
});
