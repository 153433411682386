import SideBarItemSkeleton from '../../atoms/SideBarItem/SideBarItemSkeleton';
import { Skeleton } from '../../atoms/Skeleton';

import styles from './SideBarSection.module.css';
const SideBarSectionSkeleton = ({
  titleSkeleton,
  itemsCount,
  collapsed,
}: {
  titleSkeleton: boolean;
  itemsCount: number;
  collapsed?: boolean;
}) => {
  const createSkeletonItems = () => {
    const sideBarItems = [];
    for (let i = 0; i < itemsCount; i++) {
      sideBarItems.push(<SideBarItemSkeleton key={'SideBarSkeleton-' + i} collapsed={collapsed} />);
    }
    return sideBarItems;
  };

  return (
    <div className={styles.sideBarSection}>
      {titleSkeleton && !collapsed && (
        <div className={styles.title}>
          <Skeleton width={'10.625rem'} />
        </div>
      )}
      <>{createSkeletonItems()}</>
    </div>
  );
};

export default SideBarSectionSkeleton;
