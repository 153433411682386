import classNames from 'classnames';

import { InfoMessageProps } from './InfoMessage.types';

import styles from './InfoMessage.module.css';

const InfoMessage = ({
  icon,
  title,
  button,
  iconClasses,
  infoMessageClasses,
  titleClasses,
  'data-cy': dataCy = 'info-message',
  'data-testid': dataTestId = 'info-message',
}: InfoMessageProps) => {
  return (
    <div
      className={classNames('InfoMessage', styles.infoMessage, infoMessageClasses)}
      data-cy={dataCy}
      data-testid={dataTestId}
    >
      {icon && (
        <div className={classNames(styles.icon, iconClasses)} data-cy="info-message-icon">
          {icon}
        </div>
      )}
      <h6 className={classNames(titleClasses || styles.title)} data-cy="info-message-title">
        {title}
      </h6>
      {button && <div className={classNames(styles.buttonContainer)}>{button}</div>}
    </div>
  );
};

export default InfoMessage;
