import { TranslationProps } from '../localization/localization.types';
import { REACTIONS, ReactionType } from '../types/reactions.types';

const reactionsWithLabels = (
  label: TranslationProps['label'],
  reactionsData?: Required<ReactionType[]>
) =>
  (Object.keys(REACTIONS) as Array<keyof typeof REACTIONS>).map((reaction) => ({
    type: reaction as REACTIONS,
    label: label('Ref: Reaction - ' + reaction),
    count: reactionsData?.find((r) => r.type === (reaction as REACTIONS))?.count || 0,
  }));

export { reactionsWithLabels };
