const labels = {
  SiteDetails: {
    'Select site': 'Pilih situs ini',
    'Ref: Page title': 'Detail Situs',
    'Ref: Skip map': 'Lewati Konten Utama',
    'Ref: Unavailable site': 'Pratinjau situs tidak tersedia untuk pengguna Anda',
    'Ref: Sorry, no description yet': 'Maaf, kami belum memiliki deskripsi.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Koordinat situs tidak ditemukan',
    'Ref: Page title': 'Situs',
    'Recently visited': 'Baru-baru ini dikunjungi',
    'All other sites': 'Semua situs lainnya',
    'Sites in your country': 'Situs di negara Anda',
    'Search results': 'Hasil Pencarian',
    'Select site': 'Pilih Site',
    'Ref: Select site with QR Scanner': 'Pilih situs dengan pemindai QR',
    'Ref: Select site with code': 'Pilih Situs dengan Kode',
    'Ref: Validate': 'Mengesahkan',
    'Ref: Enter site code': 'Masukkan kode situs',
    'Ref: Scanner error header': 'Situs tidak ditemukan',
    'Ref: Generic error': 'Ada yang salah',
    'Ref: Change site': 'Ubah situs',
    'Ref: Change site description':
      'Ada barang -barang di kereta! Mengubah situs akan menghasilkan membersihkan gerobak.',
    'Ref: Cancel': 'Membatalkan',
    'Ref: Not found': 'Kode QR ini belum diakui',
    'Ref: Unauthorized error': 'Permintaan tidak sah karena tingkat pembatasan lokasi',
    'Ref: Forbidden':
      'Maaf, Anda tidak memiliki izin yang tepat untuk mengakses situs ini. Untuk mengatur akses, silakan hubungi Pilbara Service Center di 1800 992 777',
    "Ref: We didn't find the site.": 'Kami tidak menemukan situsnya.',
    'Ref: We recommend some solutions:': 'Kami merekomendasikan beberapa solusi:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Coba tambahkan situs Anda dengan pemindai QR atau dengan bantuan tombol di bawah ini',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Masuk dan masuk kembali dengan memilih negara yang benar',
    'Ref: The currently selected country is:': 'Negara yang saat ini dipilih adalah:',
  },
  SwitchSite: { 'Not on {site name}?': 'Tidak pada {site name}?' },
  SiteTile: { Details: 'Detail', Select: 'Pilih' },
};
export default labels;
