import moment from 'moment';

import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { getImagesSrcFromIds } from '../../../helpers/images/images.helper';
import { IContent, IContentType } from '../../Content/types';
import { surveysConfig } from '../config';
import mockResponse from '../mockResponse';
import { Survey as SurveyDetail } from '../types/survey.types';
import { Survey } from '../types/surveysList.types';

import { GetSurveyArgs, GetSurveysArgs, PostSurveyArgs } from './api.types';

import { contentConfig } from '@/modules/Content/config';
import { isMyVillage } from '@/modules/Core/helpers/helpers';

const { api, apiBaseUrl } = surveysConfig();

const { gethomeUrl, getHomeParams } = contentConfig();

const surveysApi = api.enhanceEndpoints({ addTagTypes: ['surveys'] }).injectEndpoints({
  endpoints: (build) => ({
    /**
     * GET /v2/content/homepage - get surveys from content
     * GET /v2/survey/list  - get custom surveys (myvillage only)
     */
    getSurveys: build.query<Survey[], GetSurveysArgs>({
      queryFn: async (args: GetSurveysArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl: apiBaseUrl });
        const contentBaseQuery = axiosBaseQuery({ baseUrl: apiBaseUrl });

        const contentResponse = await contentBaseQuery({
          url: gethomeUrl,
          method: 'get',
          params: getHomeParams(
            moment().add(-240, 'days').format('YYYY-MM-DD'),
            moment().add(240, 'days').format('YYYY-MM-DD'),
            args.siteId,
            args.languageCode,
            args.contractId
          ),
          mockData: mockResponse.getContents,
        });

        const { data: { contents } = {} } = contentResponse;

        const filteredContent = contents.filter(
          (contentPiece: IContent) => contentPiece.type === IContentType.INMOMENT_SURVEY
        );

        const imageIds: string[] = filteredContent
          .filter((content: IContent) => !!content.images?.[0])
          .map((content: IContent) => content.images[0] as string);

        const images = await getImagesSrcFromIds(imageIds);

        const surveysFromContent = filteredContent.map((content: IContent) => {
          const imageId = content.images?.[0] as string;
          const image = images.find((img) => img.id === imageId);

          return {
            description: content.details,
            expiryDate: content.expiryDate,
            id: content.id,
            image: content.images[0],
            publishedDate: content.publishedDate,
            name: content.title,
            inviteId: '',
            isInvited: false,
            type: IContentType.INMOMENT_SURVEY,
            url: content.link.url,
            imgInfo: image ? { src: image.content, alt: content.title } : undefined,
          };
        });

        if (!isMyVillage()) {
          return { data: surveysFromContent };
        }

        const surveyResponse = await baseQuery({
          url: '/v2/survey/list',
          method: 'get',
          params: {
            siteId: args.siteId,
          },
          mockData: mockResponse.getSurveys,
        });

        return {
          data: [...surveysFromContent, ...(surveyResponse.data || [])],
        };
      },
      providesTags: (result, error, { siteId, contractId }) => [
        { type: 'surveys', siteId, contractId },
      ],
    }),
    /**
     * GET /v2/survey (myvillage only)
     */
    getSurvey: build.query<SurveyDetail, GetSurveyArgs>({
      queryFn: async ({ id, siteId }: GetSurveyArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl: apiBaseUrl });

        const response = await baseQuery({
          url: '/v2/survey/list',
          method: 'get',
          params: { siteId },
          mockData: mockResponse.getSurvey,
        });

        return {
          data: response.data.find((s: SurveyDetail) => s.id === id),
        };
      },
      providesTags: (result) => [{ type: 'surveys', id: result?.id }],
    }),
    /**
     * POST /v2/survey (myvillage only)
     */
    postSurveyData: build.mutation<void, PostSurveyArgs>({
      queryFn: async ({ questionResponses, survey }: PostSurveyArgs) => {
        const baseQuery = axiosBaseQuery({ baseUrl: apiBaseUrl });
        const response = await baseQuery({
          url: '/v2/survey',
          method: 'post',
          data: { survey, questionResponses },
          mockData: mockResponse.submitSurvey,
        });

        return response;
      },
    }),
  }),
  overrideExisting: true,
});

export const { useGetSurveysQuery, useGetSurveyQuery, usePostSurveyDataMutation } = surveysApi;
export default surveysApi;
