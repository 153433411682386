import classnames from 'classnames';
import { decode } from 'html-entities';
import { FC, useCallback, useRef } from 'react';
import React from 'react';
import ReactSlider from 'react-slick';

import { ChevronRightIcon, ChevronLeftIcon } from '../../../assets/icons';
import { SIZE } from '../../../constants';
import Button, { BUTTON_LOOK } from '../../atoms/Button';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../atoms/Title';

import { ArrowProps, SliderProps } from './Slider.types';

import styles from './Slider.module.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const ArrowButton = ({
  onClick,
  className,
  extraCls,
  icon: Icon,
  dark,
  'data-testid': dataTestId = 'arrow',
  srOnlyText,
}: ArrowProps) => {
  const isDisabled = className?.includes('slick-disabled');
  return (
    <div className={classnames(extraCls, styles.arrowBtn)} data-testid={dataTestId}>
      <Button
        look={dark ? BUTTON_LOOK.SECONDARY_ON_PRIMARY : BUTTON_LOOK.SECONDARY}
        onClick={onClick}
        affix={Icon}
        size={SIZE.SMALL}
        disabled={isDisabled}
        srOnlyText={srOnlyText}
        data-testid={`${dataTestId}-button`}
      />
    </div>
  );
};

const Slider: FC<SliderProps> = ({
  title,
  id,
  slidesToScroll = 3,
  slidesToShow = 3,
  rows = 1,
  slidesPerRow = 1,
  swipe = false,
  onSwipe,
  dots = false,
  responsiveSettings,
  topCorner,
  dark = false,
  adaptiveHeight = false,
  titleSize = TITLE_SIZE.HEADLINEXS,
  children,
  'data-testid': dataTestId = 'slider',
}) => {
  let dragging = useRef(false);

  const handleBeforeChange = useCallback(() => {
    dragging.current = true;

    /* setTimeout is necessary so that the suggestion tiles don't freeze
    https://github.com/akiran/react-slick/issues/1262 */
    setTimeout(() => {
      dragging.current = false;
    }, 300);
  }, []);

  const handleAfterChange = useCallback(() => {
    dragging.current = false;
  }, [dragging]);

  const handleOnItemClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (dragging.current) e.stopPropagation();
    },
    [dragging]
  );

  let above;
  if (title) {
    above = (
      <Title tag={TITLE_TAG.H2} data-testid={`slider-above-title-${id}`} size={titleSize}>
        {decode(title)}
      </Title>
    );
    if (topCorner) {
      above = (
        <>
          {above}
          {topCorner}
        </>
      );
    }
  }

  // This is hack to handle https://github.com/akiran/react-slick/issues/1378 and https://github.com/akiran/react-slick/issues/848
  const mapChildrenToReactFragmet = () => {
    if (Array.isArray(children)) {
      return children.map((item, index) => (
        <React.Fragment key={`slider--${id}--${index}`}>
          <div onClickCapture={handleOnItemClick}>{item}</div>
        </React.Fragment>
      ));
    } else {
      return (
        <React.Fragment key={`slider--${id}--1`}>
          <div onClickCapture={handleOnItemClick}>{children}</div>
        </React.Fragment>
      );
    }
  };

  return (
    <div data-testid={dataTestId} className={classnames({ [styles.sliderContainerDark]: dark })}>
      {above && (
        <div
          data-testid={`slider-above-container-${dataTestId}`}
          className={classnames(styles.above)}
        >
          {above}
        </div>
      )}
      <ReactSlider
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        className={styles.slickList}
        infinite={false}
        dots={dots}
        dotsClass={`slick-dots ${classnames(styles.slickDots, {
          [styles.lightDots]: dark,
        })}`}
        speed={300}
        slidesToScroll={slidesToScroll}
        slidesToShow={slidesToShow}
        rows={rows}
        slidesPerRow={slidesPerRow}
        swipe={swipe}
        onSwipe={onSwipe}
        prevArrow={
          <ArrowButton
            dark={dark}
            icon={ChevronLeftIcon}
            extraCls={classnames(styles.PrevArrowButton)}
            data-testid={`${dataTestId}-prevArrow`}
            srOnlyText="Carousel previous button"
          />
        }
        nextArrow={
          <ArrowButton
            dark={dark}
            icon={ChevronRightIcon}
            extraCls={classnames(styles.NextArrowButton)}
            data-testid={`${dataTestId}-nextArrow`}
            srOnlyText="Carousel next button"
          />
        }
        data-testid={`slider--${dataTestId}`}
        responsive={responsiveSettings}
        adaptiveHeight={adaptiveHeight}
      >
        {mapChildrenToReactFragmet()}
      </ReactSlider>
    </div>
  );
};

export default Slider;
