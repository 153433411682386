import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import { getUserContext, setGeography, setIsSsoUser } from '../../actions';
import { getTokenPayload } from '../../helpers/tokensHelpers';

const WorkplaceLogin = () => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const login = useCallback(
    async (queryParams: URLSearchParams) => {
      await dispatch(setGeography({ geoCode: queryParams.get('geoCode')! }));

      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE, queryParams.get('regionCode')!);
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, queryParams.get('geoCode')!);
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, queryParams.get('token')!);

      const { userName, tokenValidTill, isSSO } = getTokenPayload();
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER_EMAIL, userName);
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_VALID_UNTIL, tokenValidTill.toString());

      await dispatch(setIsSsoUser({ isSSOUser: isSSO ?? false }));
      await dispatch(getUserContext());

      queryParams.has('module')
        ? history.replace(`/${queryParams.get('module')}`.toLowerCase())
        : history.replace('/home');
    },
    [dispatch, history]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    if (queryParams.has('regionCode') && queryParams.has('token') && queryParams.has('geoCode')) {
      login(queryParams);
    } else {
      history.replace('/access/login');
    }
  }, [search, login, history]);

  return <LoadingPage />;
};

export default WorkplaceLogin;
