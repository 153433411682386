import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSelectSiteMutation } from '../../../Sites/api/api';

export const useSwitchSite = () => {
  const [selectSite] = useSelectSiteMutation();
  const hookDispatch = useDispatch();

  const switchSite = useCallback(
    (siteId: string) => {
      selectSite({
        dispatch: hookDispatch,
        site: {
          id: siteId,
          name: '',
          locationtype: 'Site',
        },
      });
    },
    [hookDispatch, selectSite]
  );

  return {
    switchSite,
  };
};
