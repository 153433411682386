import { serviceTypes } from '@/modules/config';
import { pagePaths } from '@/modules/Core/config';

const getServiceName = (pathname: string) => {
  if (pathname?.startsWith('/home')) {
    return 'Home';
  }
  if (pathname?.startsWith('/services')) {
    return 'Services';
  }

  if (pathname?.startsWith(pagePaths.LetsGo)) {
    return 'Register';
  }

  const matchingService = Object.values(serviceTypes).find(
    (serviceType) => pathname.startsWith(serviceType.path) && serviceType.path !== ''
  );

  if (!!matchingService) {
    return matchingService.defaultModule;
  }

  return mapCoreFeatureToPianoServiceName(pathname);
};

const mapCoreFeatureToPianoServiceName = (pathname: string): string | undefined => {
  const coreServicePages: { [key: string]: string } = {
    '/access/legal': 'Legal',
    '/access': 'LoginPage',
    [pagePaths.Notifications]: 'NotificationsList',
    [pagePaths.Onboarding]: 'Onboarding',
    [pagePaths.GuestRegistration]: 'GuestRegistration',
  };

  return (
    Object.entries(coreServicePages).find(([key]) => pathname.startsWith(key))?.[1] ?? undefined
  );
};

export default getServiceName;
