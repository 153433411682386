import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';

import { pagePaths } from '../../config';
import { getTomorrowDate } from '../../helpers/helper';
import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';
import { AccommodationRequestFormFields } from '../../types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Title, { TITLE_SIZE } from '@/components/atoms/Title';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import SuccessPage from '@/components/templates/SuccessPage';
import useSite from '@/modules/Core/hooks/useSite';

import styles from './CreateThankYou.module.css';

const CreateThankYou = () => {
  const history = useHistory();
  const { label } = useAccommodationRequestTranslation(__filename);

  const site = useSite();
  const currentSiteId = site?.id || '';
  const { reset } = useFormContext<AccommodationRequestFormFields>();

  return (
    <SuccessPage withNavBar={true} hideAllWidgets={true}>
      <Title size={TITLE_SIZE.HEADLINEL} data-testid="accomodation-request-success-title">
        {label('Ref: Thank you')}
      </Title>
      <Title
        size={TITLE_SIZE.BODYSBOLD}
        className={styles.text}
        data-testid="accomodation-request-success-text"
      >
        {label('Ref: Success text')}
      </Title>
      <ActionsBar className={styles.actionButton}>
        <Button
          data-testid="button-action-primary"
          look={BUTTON_LOOK.PRIMARY}
          onClick={() => history.push('/')}
        >
          {label('Ref: Go to home')}
        </Button>
        <Button
          data-testid="button-action-secondary"
          look={BUTTON_LOOK.SECONDARY}
          onClick={() => {
            reset({
              phoneNumber: '',
              site: currentSiteId,
              isGuestRequest: false,
              guestFirstName: '',
              guestLastName: '',
              comment: '',
              dateFields: {
                arrivalDate: new Date(),
                departureDate: new Date(getTomorrowDate(new Date()).setHours(12, 0, 0)),
              },
            });
            history.push(pagePaths.CreateForm);
          }}
        >
          {label('Ref: Create another request')}
        </Button>
      </ActionsBar>
    </SuccessPage>
  );
};

export default CreateThankYou;
