import { serviceTypes, modulesConfig } from '../config';

export const moduleId = 'Shop';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  ProductsList: modulePath + '/products',
  ProductDetails: modulePath + '/:class/:id',
  Cart: modulePath + '/cart',
  Checkout: modulePath + '/checkout/:id/:callbackType',
  OrdersList: modulePath + '/orders',
  OrderDetails: modulePath + '/order/:id',
  OrderSuccess: modulePath + '/order/success',
  OrderFailed: modulePath + '/order/failed',
};
