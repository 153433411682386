const labels = {
  All: {
    Feedback: 'Geri bildirim',
    'Ref: Switch site component intro sentence':
      'Geri bildiriminiz {site name} sitesine bağlanacaktır.',
    Category: 'Kategori',
    Subject: 'Konu',
    Comment: 'Yorum',
    'Date of your experience': 'Deneyiminizin tarihi *',
    'Ref: Hint': 'Düzeltilmesi gereken bir sorununuz veya işiniz mi var?',
    'Ref: Log Request': 'Bir istek kaydedin',
    'Ref: Describe issue': 'Lütfen bize deneyiminizden bahsedin',
    Date: 'Tarih',
    'Ref: Share your opinion header': 'İşteki deneyiminiz hakkındaki fikrinizi paylaşın',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restoran / Kafe',
    'Desk / workstation area': 'Masa / İş İstasyonu alanı',
    'Meeting room facilities': 'Toplantı odası olanakları',
    'Coffee/tea facilities (pantry)': 'Kahve/çay olanakları (kiler)',
    'Restrooms / washrooms': 'Tuvaletler / Elyıkama Alanları',
    'Reception / Security': 'Resepsiyon / Güvenlik',
    'Conference center': 'Konferans merkezi',
    'Fitness center': 'Fitness merkezi',
    Mailroom: 'Posta Odası',
    'Bicycle room': 'Bisiklet odası',
    'Shuttle services': 'Servis hizmetleri',
    Lockers: 'Dolaplar',
    Helpdesk: 'Yardım Masası',
  },
  Create: {
    'Tell us!': 'Anlatın bize!',
    'Ref: All Ears': 'Hepimiz dinliyoruz!',
    'Ref: Provide Description': 'Lütfen bir açıklama yapın',
    'Ref: Fill-in-the-blank sentence': 'Ile ilgili olarak bir {0} sağlamak istiyorum {1}',
    'Ref: Link text to service request module': 'Bir istek mi yoksa bir sorun mu? Buraya kaydedin!',
    'Complaint type': 'Şikayet {emoji}',
    'Feedback type': 'Geri bildirim {emoji}',
    'Compliment type': 'İltifat {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Bir hata oluştu ve geri bildiriminiz gönderilmedi. Bu hata devam ederse, lütfen yardım masanızla iletişime geçin.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Geri bildiriminiz gönderildi. İşyeri destek ekibimiz bunu dikkatle okuyacaktır. Uygun olması halinde size geri dönüş yapacağız.',
    'New feedback': 'Yeni geri bildirim',
  },
  CreateFlow: {
    Description: 'Açıklama',
    'Ref: Provide Description': 'Lütfen tarih ve açıklama veriniz',
    'Ref: I would like to provide a': 'Bu konuda bir bilgi vermek istiyorum.',
    'Ref: in regards to': 'ile ilgili olarak',
    'Ref: &': '&',
    'Ref: at': 'de',
    'Ref: on the': 'üzerinde',
  },
};
export default labels;
