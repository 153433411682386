const labels = {
  ContactForm: { 'Ref: Page title': 'Contact Us', 'Ref: Category field value': 'Contact Us' },
  ContactList: {
    'Ref: Page title': 'Contacts',
    'call (action to place a call)': 'call',
    'write (action to write a message)': 'write',
    'write (action to write an e-mail)': 'e-mail',
    'Ref: Hint message':
      'Please be aware that some sites may not have reception. If this is the case please locate one of the landline phones available around the site.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'An error occured and your message was not submitted. If this error persists, please contact your helpdesk.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success':
      'Your message was submitted. Thanks you for getting in touch with us.',
  },
};
export default labels;
