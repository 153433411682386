import classNames from 'classnames';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { CheckCircleIcon, ErrorCircleIcon, EyeFillIcon, EyeSlashIcon } from '../../../assets/icons';
import { INPUT_TYPE, POSITION } from '../../../constants';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';

import { InputIconProps, InputProps } from './Input.types';
import InputField from './InputField/InputField';

import styles from './Input.module.css';

const InputIcon = ({ icon, position, className, 'data-testid': dataTestId }: InputIconProps) => {
  const Icon = icon;
  return (
    <Icon
      data-testid={`${dataTestId}-input-icon`}
      className={classNames(
        className === undefined ? 'icon_primary' : className,
        styles.inputIcon,
        { [styles.leftInputIcon]: position === POSITION.LEFT },
        { [styles.rightInputIcon]: position === POSITION.RIGHT }
      )}
    />
  );
};

const Input = ({
  inputLabel,
  id,
  autocomplete,
  inputType: type,
  placeholder,
  value,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  icons,
  disabled,
  isValid,
  hasError,
  inputMsg,
  isResetable,
  statusMessage,
  required,
  onInputChange,
  inputRef,
  onFocus,
  withBackground,
  ...rest
}: InputProps) => {
  const { label } = useCustomTranslation();
  const [inputType, setInputType] = useState<INPUT_TYPE | undefined>(type);

  const iconsLeft = icons ? icons.filter((i: InputIconProps) => i.position === POSITION.LEFT) : [];
  const iconsRight = icons
    ? icons.filter((i: InputIconProps) => i.position === POSITION.RIGHT)
    : [];

  const togglePasswordInputType = () => {
    const newInputType = inputType === INPUT_TYPE.TEXT ? INPUT_TYPE.PASSWORD : INPUT_TYPE.TEXT;
    setInputType(newInputType);
  };

  return (
    <>
      {inputLabel && (
        <label data-testid={`${dataTestId}-input-label`} htmlFor={id} className="formElementLabel">
          {inputLabel}
          {required && ' *'}
        </label>
      )}
      <div
        data-testid={`${dataTestId}-input-wrapper`}
        className={classNames(
          styles.inputWrapper,
          { [styles.validInputWrapper]: isValid },
          { [styles.errorInputWrapper]: hasError }
        )}
      >
        {iconsLeft.map((i: InputIconProps, _) => (
          <InputIcon key={_} {...i} />
        ))}
        <InputField
          id={id ?? uuidv4()}
          type={inputType}
          autocomplete={autocomplete}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          data-cy={dataCy}
          data-testid={`${dataTestId}-input-field`}
          clearInput={isResetable === undefined || isResetable === true}
          withBackground={withBackground}
          onInputChange={onInputChange}
          hasError={hasError}
          isValid={isValid}
          clearInputSrText={`${label('clear')} ${inputLabel || ''} ${label('input field')}`}
          inputLabel={inputLabel}
          onFocus={onFocus}
          className={classNames(
            styles.input,
            { [styles.validMsg]: isValid },
            { [styles.errorMsg]: hasError },
            { [styles.disabled]: disabled }
          )}
          title={placeholder ? placeholder : inputLabel}
          inputRef={inputRef}
          {...rest}
        >
          <span className="sr-only">{label('Wrong input icon')}</span>
        </InputField>
        {iconsRight.map((i: InputIconProps, _) => (
          <InputIcon key={_} {...i} />
        ))}
        {hasError && (
          <>
            <ErrorCircleIcon
              data-testid={`${dataTestId}-input-error-icon`}
              className={classNames('icon_danger_filled', styles.inputIcon, styles.rightInputIcon)}
            />
            <span className="sr-only">{label('Wrong input icon')}</span>
          </>
        )}
        {isValid && (
          <>
            <CheckCircleIcon
              data-testid={`${dataTestId}-input-valid-icon`}
              className={classNames('icon_success_filled', styles.inputIcon, styles.rightInputIcon)}
            />
            <span className="sr-only">{label('Correct input icon')}</span>
          </>
        )}
        {/* TODO move into standalone component, i.e. ShowPasswordToggle to handle logic with dynamic icon calling */}
        {value &&
          value.length > 0 &&
          type === INPUT_TYPE.PASSWORD &&
          (inputType === INPUT_TYPE.PASSWORD ? (
            <button
              data-testid={`${dataTestId}-input-eye-fill-button`}
              type="button"
              className={classNames(styles.passwordIcon)}
              onKeyDown={(e) => e.key === 'Enter' && togglePasswordInputType()}
            >
              <EyeFillIcon
                className={classNames(
                  styles.inputIcon,
                  styles.rightInputIcon,
                  'clickable',
                  'icon_primary_filled'
                )}
                onClick={() => togglePasswordInputType()}
              />
              <span className="sr-only">{label('Show input text icon')}</span>
            </button>
          ) : (
            <button
              data-testid={`${dataTestId}-input-eye-slash-button`}
              type="button"
              className={classNames(styles.passwordIcon)}
              onKeyDown={(e) => e.key === 'Enter' && togglePasswordInputType()}
            >
              <EyeSlashIcon
                className={classNames(
                  styles.inputIcon,
                  styles.rightInputIcon,
                  'clickable',
                  'icon_primary_filled'
                )}
                onClick={() => togglePasswordInputType()}
              />
              <span className="sr-only">{label('Hide input text icon')}</span>
            </button>
          ))}
      </div>
      {statusMessage && (
        <span
          data-testid={`${dataTestId}-input-status-message`}
          className={classNames(styles.msg, styles.statusMessage)}
        >
          {statusMessage}
        </span>
      )}
      {inputMsg && (
        <span data-testid={`${dataTestId}-input-message`} className={classNames(styles.msg)}>
          {inputMsg}
        </span>
      )}
    </>
  );
};

export default Input;
