import classNames from 'classnames';

import Title, { TITLE_SIZE, TITLE_TAG } from '../../atoms/Title';

import { ActionCardProps } from './ActionCard.types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Card from '@/components/molecules/Card/Card';

import styles from './ActionCard.module.css';

const ActionCard = ({
  onActionButtonClick,
  image,
  text,
  subText,
  buttonText,
  reversedLayout,
  'data-testid': testId,
}: ActionCardProps) => {
  return (
    <Card>
      <div className={styles.cardContent}>
        <div
          className={classNames(styles.actionCardContent, {
            [styles.rowReverse]: reversedLayout,
          })}
        >
          <div className={styles.actionCardText}>
            <Title tag={TITLE_TAG.SPAN} size={TITLE_SIZE.BODYMBOLD} data-testid="action-card-title">
              {text}
            </Title>
            {!!subText && (
              <Title
                tag={TITLE_TAG.SPAN}
                size={TITLE_SIZE.BODYSDEFAULT}
                data-testid="action-card-subtext"
              >
                {subText}
              </Title>
            )}
          </div>
          <div className={styles.actionCardPicture}>{image}</div>
        </div>

        <Button
          data-testid={testId}
          look={BUTTON_LOOK.PRIMARY}
          onClick={onActionButtonClick}
          fullWidth
        >
          {buttonText}
        </Button>
      </div>
    </Card>
  );
};

export default ActionCard;
