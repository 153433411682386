import classNames from 'classnames';

import { Skeleton } from '../../../../../components/atoms/Skeleton';
import Card from '../../../../../components/molecules/Card/Card';

import styles from './QuestionsSkeleton.module.css';

const QuestionsSkeleton = () => (
  <div>
    {Array.apply(null, Array(10)).map((_, i) => (
      <Card key={i} className={classNames('mb-L')} data-testid="skeleton-question">
        <div>
          <Skeleton className={classNames('mb-S')} width="60%" />
        </div>
        <div className={classNames(styles.skeletonOption)}>
          <Skeleton width="30%" />
        </div>
        <div className={classNames(styles.skeletonOption)}>
          <Skeleton width="30%" />
        </div>
        <div className={classNames(styles.skeletonOption)}>
          <Skeleton width="30%" />
        </div>
      </Card>
    ))}
  </div>
);
export default QuestionsSkeleton;
