import {
  AccommodationRequestIcon,
  UserIcon,
  Phone24Icon,
  ContentIcon,
  ContentPageIcon,
  EventIcon,
  FacilitiesIcon,
  FeedbackIcon,
  ReceiptIcon,
  ServiceRequestIcon,
  StoreIcon,
  LocationIcon,
  StaysIcon,
  SurveyIcon,
  RestaurantIcon,
  SuppliersIcon,
} from '../assets';
import {
  FaqIcon,
  OccupancyIcon,
  CoffeeIcon,
  SpeechBubbleIcon,
  FitbitIcon,
  UserIdentificationIcon,
  EditingListULIcon,
  BusTimetableIcon,
  Tool,
} from '../assets/icons';
import { APP_NAME } from '../constants';

type ServiceType = {
  path: string;
  defaultModule?: string;
  label: string;
  icon?: JSX.Element;
  excludeSubPaths?: string[];
};

type ServiceTypeDictionary = Record<string, ServiceType>;

export enum SERVICE {
  CONTACT = 'Contact',
  CONTENT = 'Content',
  CONTENT_PAGE = 'ContentPage',
  EVENT = 'Event',
  FACILITY = 'Facilities',
  FEEDBACK = 'Feedback',
  FOOD_MENUS = 'Food.Menus',
  FOOD_ORDERS = 'Food.Orders',
  AUDITS = 'Audits',
  JOURNEY_PLANNER = 'JourneyPlanner',
  LEGAL = 'Legal',
  MY_ACCOUNT = 'MyAccount',
  SERVICE_REQUEST = 'ServiceRequests',
  SERVICE_REQUEST_LIGHT = 'ServiceRequestsLight',
  SERVICE_REQUEST_LIGHT_HELPDESK = 'ServiceRequestsLightHelpdesk',
  SITE = 'Sites',
  STAY = 'Stay',
  SURVEY = 'Survey',
  FOOD_MENU = 'Food.Menu',
  FOOD_ORDER = 'Food.Order',
  SUPPLIER = 'Suppliers',
  REVIEW = 'Review',
  RETAIL = 'Retail',
  STAMP = 'Stamps',
  FAQ = 'Faq',
  MY_BUS = 'MyBus.QrCode',
  EXTERNAL_WEBSITE = 'ExternalWebsite',
  ACCOMMODATION_REQUEST = 'AccommodationRequest',
  // SERVICES LIST
  // RETIRED MODULES
  OCCUPANCY = 'Occupancy',
  FITBIT = 'Fitbit',
}

const appName = process.env.REACT_APP_APP_NAME?.toLowerCase();

export const serviceTypes: ServiceTypeDictionary = {
  [SERVICE.CONTACT]: {
    path: '/contacts',
    defaultModule: 'Contacts',
    label: 'Contacts',
    icon: <Phone24Icon className="service_icon" />,
  },
  [SERVICE.CONTENT]: {
    path: '/content',
    defaultModule: 'Content',
    label: 'Newsfeed',
    icon: <ContentIcon className="service_icon" />,
  },
  [SERVICE.CONTENT_PAGE]: {
    path: '/content_page',
    defaultModule: 'ContentPage',
    label: 'Content page',
    icon: <ContentPageIcon className="service_icon" />,
  },
  [SERVICE.EVENT]: {
    path: '/events',
    defaultModule: 'Events',
    label: 'Events',
    icon: <EventIcon className="service_icon" />,
  },
  [SERVICE.FACILITY]: {
    path: '/facilities',
    defaultModule: 'Facilities',
    label: 'Facilities',
    icon: <FacilitiesIcon className="service_icon" />,
  },
  [SERVICE.FEEDBACK]: {
    path: '/feedback',
    defaultModule: 'Feedback',
    label: 'Feedback',
    icon: <FeedbackIcon className="service_icon" />,
  },
  [SERVICE.FOOD_ORDERS]: {
    path: '/order/history',
    defaultModule: 'Order',
    label: 'MyOrders',
    icon: <ReceiptIcon className="service_icon" />,
  },
  [SERVICE.AUDITS]: {
    path: '/audits',
    defaultModule: 'Audits',
    label: 'Audits',
    icon: <EditingListULIcon className="service_icon" />,
  },
  [SERVICE.JOURNEY_PLANNER]: {
    path: '/buses',
    defaultModule: 'JourneyPlanner',
    label: 'Buses',
    icon: <BusTimetableIcon className="service_icon" />,
  },
  [SERVICE.LEGAL]: {
    path: '/legal',
    defaultModule: 'Legal',
    label: 'Acknowledgements',
  },
  [SERVICE.MY_ACCOUNT]: {
    path: '/account',
    defaultModule: 'Account',
    label: 'Account',
    icon: <UserIcon className="service_icon" />,
  },
  [SERVICE.SERVICE_REQUEST]: {
    path: '/service_request',
    defaultModule: 'ServiceRequest',
    label: 'Requests',
    icon: <ServiceRequestIcon className="service_icon" />,
  },
  [SERVICE.SERVICE_REQUEST_LIGHT]: {
    path: '/light_service_request',
    excludeSubPaths: ['light_service_request_helpdesk'],
    defaultModule: 'ServiceRequestLight',
    label: 'Requests',
    icon: <ServiceRequestIcon className="service_icon" />,
  },
  [SERVICE.SERVICE_REQUEST_LIGHT_HELPDESK]: {
    path: '/light_service_request_helpdesk',
    defaultModule: 'ServiceRequestLightHelpdesk',
    label: 'Request Helpdesk',
    icon: <Tool className="service_icon" />,
  },
  [SERVICE.SITE]: {
    path: '/sites',
    defaultModule: 'Sites',
    label: 'Location',
    icon: <LocationIcon className="service_icon" />,
  },
  [SERVICE.STAY]: {
    path: '/accomodation',
    defaultModule: 'Accomodation',
    label: 'Stays',
    icon: <StaysIcon className="service_icon" />,
  },
  [SERVICE.SURVEY]: {
    path: '/surveys',
    defaultModule: 'Surveys',
    label: 'Surveys',
    icon: <SurveyIcon className="service_icon" />,
  },
  [SERVICE.FOOD_MENU]: {
    path: '/menu',
    defaultModule: 'Order',
    label: 'Menu',
    icon: <RestaurantIcon className="service_icon" />,
  },
  [SERVICE.FOOD_ORDER]: {
    path: '/order',
    excludeSubPaths: ['/history'],
    defaultModule: 'Order',
    label: 'Order',
    icon: <StoreIcon className="service_icon" />,
  },
  [SERVICE.SUPPLIER]: {
    path: '/suppliers',
    defaultModule: 'Suppliers',
    label: 'Suppliers',
    icon: <SuppliersIcon className="service_icon" />,
  },
  [SERVICE.REVIEW]: {
    path: '/review',
    defaultModule: 'Review',
    label: 'Review',
    icon: <SpeechBubbleIcon className="service_icon" />,
  },
  [SERVICE.STAMP]: {
    path: '/stamps',
    defaultModule: 'Stamps',
    label: 'FreeCoffee',
    icon: <CoffeeIcon className="service_icon" />,
  },
  [SERVICE.FAQ]: {
    path: '/faq',
    defaultModule: 'Faq',
    label: 'FAQ',
    icon: <FaqIcon className="service_icon" />,
  },
  [SERVICE.MY_BUS]: {
    path: '/my_bus',
    defaultModule: 'MyBus',
    label: 'MyBus',
    icon: <UserIdentificationIcon className="service_icon" />,
  },
  [SERVICE.EXTERNAL_WEBSITE]: {
    path: '/external',
    defaultModule: 'ContentPage', // We don't need module for this service, used ContentPage
    label: 'ExternalWebsite',
    icon: <ContentPageIcon className="service_icon" />,
  },
  [SERVICE.ACCOMMODATION_REQUEST]: {
    path: '/accommodation_request',
    defaultModule: 'AccommodationRequest',
    label: 'Accommodation Request',
    icon: <AccommodationRequestIcon className="service_icon" />,
  },
  // SERVICES MODULE

  // RETIRED MODULES
  [SERVICE.FOOD_MENUS]: {
    path: '/shop',
    defaultModule: 'Shop',
    label: 'Menus',
    icon: <StoreIcon className="service_icon" />,
  },
  [SERVICE.FITBIT]: {
    path: '/fitbit',
    defaultModule: 'Fitbit',
    label: 'Fitbit',
    icon: <FitbitIcon className="service_icon" />,
  },
  [SERVICE.OCCUPANCY]: {
    path: '/occupancy',
    defaultModule: 'Occupancy',
    label: 'Occupancy',
    icon: <OccupancyIcon className="service_icon" />,
  },

  ...(appName === APP_NAME.MYVILLAGE && {
    [SERVICE.FOOD_MENUS]: {
      path: '/menus',
      defaultModule: 'Menus',
      label: 'Menus',
      icon: <StoreIcon className="service_icon" />,
    },
  }),
};

export const modulesConfig = {
  Accomodation: {
    serviceType: 'Stay',
  },
  Contacts: {
    serviceType: 'Contact',
  },
  Content: {
    serviceType: 'Content',
  },
  ContentPage: {
    serviceType: 'ContentPage',
  },
  Events: {
    serviceType: 'Event',
  },
  Facilities: {
    serviceType: 'Facilities',
  },
  Feedback: {
    serviceType: 'Feedback',
  },
  Audits: {
    serviceType: 'Audits',
  },
  JourneyPlanner: {
    serviceType: 'JourneyPlanner',
  },
  Order: {
    serviceType: 'Food.Order',
  },
  Orders: {
    serviceType: 'Food.Orders',
  },
  Menus: {
    serviceType: 'Food.Menus',
  },
  ServiceRequest: {
    serviceType: 'ServiceRequests',
  },
  ServiceRequestLight: {
    serviceType: 'ServiceRequestsLight',
  },
  ServiceRequestLightHelpdesk: {
    serviceType: 'ServiceRequestsLightHelpdesk',
  },
  Sites: {
    serviceType: 'Sites',
  },
  Surveys: {
    serviceType: 'Survey',
  },
  Menu: {
    serviceType: 'Food.Menu',
  },
  Suppliers: {
    serviceType: 'Suppliers',
  },
  Review: {
    serviceType: 'Review',
  },
  Stamps: {
    serviceType: 'Stamps',
  },
  Faq: {
    serviceType: 'Faq',
  },
  MyBus: {
    serviceType: 'MyBus.QrCode',
  },
  AccommodationRequest: {
    serviceType: 'AccommodationRequest',
  },
  // MODULES CONFIG
  // RETIRED MODULES
  Shop: {
    serviceType: 'Food.Menus',
  },
  Fitbit: {
    serviceType: 'Fitbit',
  },
  Occupancy: {
    serviceType: 'Occupancy',
  },
};

export const coreServices = [SERVICE.SITE, SERVICE.LEGAL, SERVICE.MY_ACCOUNT];

export const coreRoutes = ['home', 'sites', 'account', 'legal'];

export const servicesHiddenInNav = [SERVICE.MY_ACCOUNT];
