const labels = {
  SiteDetails: {
    'Select site': '选择此地点',
    'Ref: Page title': '地点详情',
    'Ref: Skip map': '跳至主要内容',
    'Ref: Unavailable site': '预览不可用',
    'Ref: Sorry, no description yet': '抱歉，当前地点的描述还未设置。',
  },
  SitesList: {
    'Ref: Site coordinates not found': '未找到当前地点坐标信息',
    'Ref: Page title': '地点',
    'Recently visited': '最近访问',
    'All other sites': '所有其它地点',
    'Sites in your country': '您所在国家的地点',
    'Search results': '搜索结果',
    'Select site': '选择一个地点',
    'Ref: Select site with QR Scanner': '通过二维码选择地点',
    'Ref: Select site with code': '通过代码选择地点',
    'Ref: Validate': '验证',
    'Ref: Enter site code': '输入地点代码',
    'Ref: Scanner error header': '未找到地点',
    'Ref: Generic error': '出错了',
    'Ref: Change site': '更改地点',
    'Ref: Change site description': '购物车中有项目！更改地点将导致清除购物车。',
    'Ref: Cancel': '取消',
    'Ref: Not found': '该二维码尚未被设置',
    'Ref: Unauthorized error': '由于站点限制级别而未经授权的请求',
    'Ref: Forbidden': '非常抱歉，您对当前地点的访问授权似乎有问题。请与服务中心联系。',
    "Ref: We didn't find the site.": '我们未找到此地点。',
    'Ref: We recommend some solutions:': '我们建议：',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1.尝试扫描地点二维码或点击下面的按钮添加您的地点',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2.注销并在重新登录时选择正确的国家',
    'Ref: The currently selected country is:': '当前选择的国家是：',
  },
  SwitchSite: { 'Not on {site name}?': '不是{site name}？' },
  SiteTile: { Details: '详情', Select: '选择' },
};
export default labels;
