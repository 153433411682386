const labels = {
  Success: {
    'Ref: Body': '感谢您完成此调查，这将有助于改善我们的服务。',
    'Ref: Tabtitle': '调查完成了',
    'Take another survey': '进行另一项调查',
  },
  Survey: {
    'Ref: Page title': '调查细节',
    'Please answer all mandatory questions as identify by an asterisk':
      '请回答所有带型号的必填问题：*',
    'Ref: Take another survey': '进行另一项调查',
    'Ref: Go to Home': '返回主页',
  },
  SurveysList: {
    'Ref: Page title': '调查',
    'Ref: Available until': '直到可用',
    'Ref: You are currently viewing surveys for the site {site name}.':
      '您当前正在查看{site name}的调查。',
  },
};
export default labels;
