const labels = {
  All: {
    'status: all': 'Tudo',
    'status: open': 'Aberto',
    'status: pending': 'Pendente',
    'status: closed': 'Fechado',
    Location: 'Localização',
    Subject: 'Tópico',
    Description: 'Seu pedido detalhado',
    'Ref: description field label': 'Descrição',
    'Ref: Title': 'Título',
    phone_number: 'Seu número de telefone',
    request_category: 'Anterior',
    Attachments: 'Anexos',
    'request service': 'Serviço de solicitação',
    'New service request': 'Criar outra solicitação',
    'Go to home': 'Vá para casa',
    'Back to this service request': 'Voltar a esta solicitação de serviço',
    'Back to service requests': 'Voltar para solicitações de serviço',
    Feedback: 'Comentários',
    'Ref: Request category': 'Categoria',
    Cleaning: 'Limpeza',
    'Laundry services': 'Serviços de lavanderia',
    'Grounds maintenance': 'Manutenção de motivos',
    'Soft services': 'Serviços Soft',
    'Hard services': 'Serviços difíceis',
    'Pest control services': 'Serviços de controle de pragas',
    'Waste management': 'Gestão de resíduos',
    'Asset Lifecycle Services': 'Serviços de ciclo de vida de ativos.',
    'There are no updates for this request': 'Não há atualizações para este pedido',
    'Transportation Services': 'Serviço de transporte',
    'Ref: you pronoun': '(tu)',
    'Ref: SR raised for': 'Pessoa afetada por este pedido',
    'Ref: Select a location': 'Selecione um local',
    'Ref: Your request': 'Seu pedido',
    'Ref: Summary': 'Resumo',
    'Ref: site': 'Local',
  },
  Comment: {
    'Ref: Page title': 'Atualize-nos',
    'Ref: please update us with details':
      'Mais informações para nos comunicar? Um comentário? Quanto mais soubermos sobre sua solicitação, melhor poderemos ajudá-lo.',
  },
  Confirm: {
    'Ref: Page title': 'Confirme o seu pedido',
    'Ref: next': 'Enviar',
    summary: 'Resumo',
    'Ref: Save as my preferred location': 'Salve como meu local preferido',
  },
  Describe: {
    'Ref: Page title': 'Descreva o seu pedido',
    'Ref: Required fields: header': 'Por favor, forneça informações adicionais',
    'Ref: Required fields':
      'As informações a seguir devem ser fornecidas dentro do campo Detalhes:',
    'Ref: Required field: title': 'Um título é obrigatório.',
    'Ref: Required field: description': 'É necessária uma descrição.',
    'Ref: Required field: phone': 'Um número de telefone é necessário.',
    'Ref: Required field: category': 'Uma categoria é necessária.',
    'Ref: Invalid field: category': 'A categoria fornecida é inválida.',
    'Ref: Request template field label': 'Pedidos populares',
    'Ref: Request template field placeholder': 'Folha em branco',
    your_request: 'Seu pedido',
    'Ref: Description message': 'Quanto mais detalhado você for, melhor poderá apoiá -lo.',
    phone_number: 'Seu número de telefone (use seu telefone profissional se tiver um)',
    'Ref: Affected person': 'Pessoa afetada por este pedido',
    'Ref: No affected person results': 'Nenhuma pessoa que corresponda aos critérios solicitados',
    'Ref: Notification title': 'Você mudou o local',
    'Ref: Notification info': 'Você está agora em {site name}',
    'Ref: Add photos': 'Adicionar fotos',
    'Ref: Your location': 'Sua localização',
  },
  Details: {
    'Ref: Page title': 'Pedir detalhes',
    Details: 'detalhes',
    Updates: 'Atualizações',
    'Update us': 'Atualize-nos',
    'Ref: timeline': 'Linha do tempo',
    'Ref: attachments': 'Anexos',
    'Ref: location': 'Localização',
    'Ref: createdOn': 'Criado em',
    'Ref: reference': 'Referência',
    'Feedback was submitted': 'avaliação enviada',
    'Ref: sent': 'Enviado',
    'Ref: received': 'Recebido',
    'Ref: inProgress': 'Em andamento',
    'Ref: completed': 'Concluído',
    'Ref: conversation': 'Conversação',
    'Ref: Priority': 'Prioridade',
    'Ref: Target start date': 'Data de início do alvo',
    'Ref: Target completion date': 'Data de Conclusão do Objetivo',
    'Ref: Priority fields placeholder message':
      'Será preenchido uma vez validado por central de ajuda',
  },
  Failed: {
    'Ref: Body':
      'Ocorreu um erro e sua solicitação de serviço não foi criada. Se este erro persistir, entre em contato com seu central de ajuda.',
    'Ref: Tabtitle': 'Solicitação de serviço falhou em criar',
  },
  Home: { 'Ref: Page title': 'solicitações de' },
  RequestFeedback: {
    'Ref: Page title': 'Comentários',
    'Ref: Feedback headline': 'Compartilhe seus feedbacks',
    'Ref: Feedback title': 'Feedback sobre sr # {ref}',
    'Ref: Question: general sentiment': 'Como você avaliaria nossa resposta geral?',
    'Ref: Question: answer quality': 'Como você julga a qualidade da resposta ao seu pedido?',
    'Ref: Question: staff interaction':
      'Comentário, você julga a qualidade das interações com nosso pessoal?',
    'Ref: Question: efficiency': 'Comentário, você julga nossa velocidade de execução?',
    'Ref: Question: open': 'Comentário',
    'Ref: Open question placeholder':
      'Quais são os pontos positivos? Quais são as melhorias necessárias?',
    no_comment: 'Sem comentários',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Obrigado por dedicar um tempo para deixar um comentário, seu feedback é importante para que possamos melhorar sua experiência.',
  },
  Locate: {
    'Ref: Page title': 'Localize o seu pedido',
    'Ref: Top bar title': 'Localize seu pedido',
    'Ref: Select this location': 'Selecione este local',
    drill: 'Squeaky toy',
    'Ref: Scanner button': 'Localização com um QR? Digitalize-o',
    'Ref: Scanner header': 'Por favor, digitalize sua localização QR',
    'Sorry, this location could not be found.': 'Desculpe, este local não pôde ser encontrado.',
    'Close scanner': 'Feche o scanner.',
    'Ref: Switch site component intro sentence':
      'Você está solicitando serviço no site {site name}.',
    'Ref: search location by keywords': 'Pesquise um local',
    'Ref: Enter Location name': 'Digite o nome do local',
    'Ref: Enter manual location': 'Digite a localização manualmente.',
    'Ref: Location name': 'Nome do local',
    'Ref: No results':
      'Não conseguimos encontrar sua localização. Você pode querer inseri-lo manualmente.',
    'Ref: Up one level': 'Subir um nível',
    'Ref: Navigate': 'Navegar',
    'Ref: Select': 'Selecione',
    'Ref: Selected Location': 'Localização selecionada',
    'Ref: Preferred Location': 'Local preferido',
    'Ref: Building': 'Prédio',
    'Ref: Floor': 'Piso',
    'Ref: Aisle': 'Corredor',
    'Ref: Room': 'Quarto',
    'Ref: Notification title': 'Você mudou o local',
    'Ref: Notification info': 'Você está agora em {site name}',
    'Ref: Scanner error header': 'Erro de digitalização',
    'Ref: Scan QR Code': 'Digitalize código QR',
    'Ref: Error body': 'Este código QR não é um código válido',
    'Ref: Loading Locations Warning':
      'Aguarde enquanto estamos preparando sua experiência para uma primeira solicitação neste site.',
    'Ref: Search Result is limited to:':
      'O resultado da pesquisa é limitado a {limit}. Refine seus critérios de pesquisa.',
    'Ref: Open the location search field': 'Abra o campo de pesquisa de localização',
  },
  Success: {
    'Ref: Body':
      'Sua solicitação de serviço foi enviada e está sendo processada. Ele será exibido nesta aplicação em breve. Você receberá uma nota quando for resolvida. Enquanto isso, você pode seguir seu status e adicionar comentários a ele através deste aplicativo.',
    'Ref: Tabtitle': 'Solicitação de serviço criada',
    'Ref: Download app title': 'Simplifique suas solicitações de serviço',
    'Ref: Download app subtitle': 'Baixe nosso aplicativo e eleve sua experiência',
    'Ref: Download app button': 'Baixe o aplicativo',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Este é o seu hub de suporte no local de trabalho',
    'Ref: Tutorial: tabname': 'solicitações de',
    'Ref: Tutorial: body':
      'Estamos aqui para ajudá-lo. Por favor, registre uma solicitação por entrada.',
    "What's new: title": 'Encontre sua localização facilmente',
    "What's new: body":
      'Agora você pode digitalizar um local QR, desativar os níveis de localização ou apenas selecionar em nossas sugestões inteligentes.',
  },
  Widget: {
    'Ref: Service request': 'Requisiçao de serviço',
    'Ref: Placeholder body': 'Nenhum pedido de serviço para o momento',
    'Ref: New request button': 'Novo pedido',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Você mudou o local',
    'Ref: Notification info': 'Você está agora em {site name}',
    'Ref: Welcome': 'Bem-vindo! {wave_emoji}',
    'Ref: Create service request text': 'Notou algo que deseja relatar?',
    'Create a service request': 'Crie um pedido de serviço',
    'Ref: Share feedback': 'Compartilhe um feedback',
    'Ref: Share text': 'Gostaria de compartilhar algo conosco?',
    'Go to home': 'Vá para casa',
    'Ref: Error body':
      'O código QR que você digitalizou não funciona mais. Tente novamente mais tarde ou entre em contato com o suporte.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Ocorreu um erro',
    'Ref: Error body':
      'O código QR que você digitalizou não funciona mais. Tente novamente mais tarde ou entre em contato com o suporte.',
    'Go to home': 'Vá para casa',
  },
  ManualDescribe: {
    'Ref: Page title': 'Descreva seu pedido',
    'Ref: Required fields: header': 'Forneça informações adicionais',
    'Ref: Required fields':
      'As informações a seguir devem ser fornecidas dentro do campo Detalhes:',
    'Ref: Required field: title': 'É necessário um resumo.',
    'Ref: Required field: description': 'Uma descrição é necessária.',
    'Ref: Required field: category': 'Uma categoria é necessária.',
    'Ref: Required field: buildingText': 'Um edifício é necessário.',
    'Ref: Invalid field: category': 'A categoria fornecida é inválida.',
    'Ref: Your request': 'Seu pedido',
    'Ref: Description message': 'Quanto mais detalhado você for, melhor poderá apoiá -lo.',
    'Ref: Do you have a location QR code': 'Você tem um código QR de localização?',
    'Ref: Scan QR Code': 'Digitalize código QR',
    'Ref: Please select category': 'Selecione a categoria',
    'Ref: Please select site': 'Selecione o site',
    'Ref: building': 'Prédio',
    'Ref: room': 'Quarto',
    'Ref: Scan error': 'Erro de digitalização',
    'Ref: The QR code is not valid': 'O código QR não é válido',
    'Ref: Add location with QR Scanner': 'Adicionar localização com o scanner QR',
    'Ref: Description': 'Descrição',
    'Ref: Forbidden error': 'Lamentamos, este código QR não corresponde ao seu local registrado.',
    'Ref: Invalid error': 'Lamentamos, parece que esse código QR não é mais válido.',
  },
};
export default labels;
