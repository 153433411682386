import classNames from 'classnames';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Title, { TITLE_SIZE } from '../Title';

import { StepperItemProps } from './Stepper.types';

import styles from './Stepper.module.css';

const StepperItem = ({
  current = false,
  path,
  title,
  index,
  completed,
  horizontalOnly,
}: StepperItemProps) => {
  const { label } = useCustomTranslation();
  const history = useHistory();
  const Tag: ((props: any) => any) | string = completed ? Link : 'span';
  const href = completed
    ? { to: { pathname: path, state: { from: history.location.pathname } } }
    : {};

  return (
    <li
      className={classNames(
        styles.item,
        { [styles.active]: current },
        { [styles.completed]: completed }
      )}
      aria-current={current ? 'step' : undefined}
      data-testid={`stepper-${index}`}
    >
      <Tag {...href} role={current ? 'button' : undefined} sr-only={label('Ref: Step')}>
        <div className={classNames(styles.counter)}>{index}</div>
        {title && !horizontalOnly && (
          <Title size={TITLE_SIZE.BODYSDEFAULT} className={classNames(styles.title)}>
            {title}
          </Title>
        )}
      </Tag>
    </li>
  );
};

export default StepperItem;
