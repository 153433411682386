import classNames from 'classnames';
import { FC, useMemo } from 'react';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Tooltip, { TOOLTIP_POSITION } from '../Tooltip';

import { ReactionIconProps } from './ReactionIcon.types';

import { TOOLTIP_VARIANTS } from '@/components/molecules/TooltipWrapper';
import { REACTIONS_STYLING } from '@/types/reactions.types';

import styles from './ReactionIcon.module.css';

const ReactionIcon: FC<ReactionIconProps> = ({
  reaction,
  content,
  className,
  'data-testid': testId,
  useCustomAriaLabel = false,
}) => {
  const Icon = useMemo(() => {
    const IconComponent = REACTIONS_STYLING[reaction].icon;
    return <IconComponent aria-label={reaction} />;
  }, [reaction]);
  const { label } = useCustomTranslation();
  return (
    <Tooltip
      place={TOOLTIP_POSITION.Top}
      tooltipId={TOOLTIP_VARIANTS.DEFAULT}
      noArrow
      content={content}
      data-testid={`${testId}-${reaction}`}
      className={classNames(styles.tooltip, className)}
      delayShow={500}
      aria-label={
        useCustomAriaLabel
          ? `${label('Ref: Reaction')} ${label('Ref: Reaction - ' + reaction)} ${content} ${label(
              'Ref: times'
            )}`
          : undefined
      }
    >
      {Icon}
    </Tooltip>
  );
};

export default ReactionIcon;
