import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { APP_ERRORS } from '../../../../constants';
import serviceRequestLightHelpdeskApi, {
  useGetServiceRequestLightHelpdeskListQuery,
} from '../../api';
import List from '../../components/List';
import { useServiceRequestLightHelpdeskTranslation } from '../../hooks/useServiceRequestLightHelpdeskTranslation';

import GenericErrorPage from '@/modules/Core/components/GenericErrorPage';
import { State } from '@/types/state.types';

const ListWrapper = () => {
  const siteId = useSelector((state: State) => state.Core.context.site?.id || '');
  const { label } = useServiceRequestLightHelpdeskTranslation(__filename);

  const location = useLocation();
  const dispatch = useDispatch();

  const { searchTerm, startDate, endDate } = Object.fromEntries(
    new URLSearchParams(location.search)
  );
  const [pageNumber, setPageNumber] = useState(1);

  const {
    data: { listData, forbidden } = {
      listData: {
        data: undefined,
        metadata: undefined,
      },
      forbidden: false,
    },
    isLoading,
    isFetching,
  } = useGetServiceRequestLightHelpdeskListQuery({
    siteId,
    pageNumber,
    searchTerm,
    startDate,
    endDate,
  });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onRefresh = async () => {
    setIsRefreshing(true);
    setPageNumber(1);
    dispatch(
      serviceRequestLightHelpdeskApi.util.invalidateTags(['service_request_light_helpdesk'])
    );
    setIsRefreshing(false);
  };

  useEffect(() => {
    setPageNumber(1);
  }, [searchTerm, startDate, endDate]);

  if (forbidden) {
    return (
      <GenericErrorPage title={label('Ref: Forbidden heading')} errors={[APP_ERRORS.FORBIDDEN]} />
    );
  }

  return (
    <List
      listData={listData}
      isLoading={isLoading || isFetching}
      isRefreshing={isRefreshing}
      isFetchingNextPage={pageNumber > 1 && isFetching}
      onRefresh={onRefresh}
      setPageNumber={setPageNumber}
    />
  );
};

export default ListWrapper;
