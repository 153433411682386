import classNames from 'classnames';
import React from 'react';

import { SIZE } from '../../../constants';
import useToggle from '../../../helpers/hooks/useToggle';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Button, { BUTTON_LOOK } from '../../atoms/Button';
import Divider from '../../atoms/Divider';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../atoms/Title';

import {
  NotificationProps,
  NOTIFICATION_LOOK,
  NotificationIconType,
  NotificationActionProps,
} from './Notification.types';

import { WarningIcon, ChevronDownIcon, InformationIcon } from '@/assets/icons';
import { ChevronUpIcon } from '@/assets/index';
import DismissButton from '@/components/atoms/DismissButton/DismissButton';

import styles from './Notification.module.css';

const LOOK_ICON_ASSOCIATION: { [K in NOTIFICATION_LOOK]?: React.ReactChild } = {
  [NOTIFICATION_LOOK.ERROR]: <WarningIcon />,
  [NOTIFICATION_LOOK.DETAILS]: <InformationIcon />,
};

const NotificationIcon = React.memo(({ look, icon }: NotificationIconType) => {
  const shouldRenderIcon = icon || look in LOOK_ICON_ASSOCIATION;

  if (shouldRenderIcon) {
    return (
      <span className={classNames(styles.icon)} data-cy="notification-icon">
        {LOOK_ICON_ASSOCIATION[look] ?? icon}
      </span>
    );
  }

  return null;
});

// TODO expand to use in Success and Error notifications
const NotificationAction = (props: NotificationActionProps) => {
  return <Button look="secondary_on_primary" size={SIZE.SMALL} {...props} />;
};

const Notification = ({
  look: notificationLook,
  title,
  icon,
  img,
  inheritStyle,
  children,
  actions,
  isCollapsable = false,
  dismissable = false,
  onDismiss,
  'data-cy': dataCy = 'notification',
  'data-testid': dataTestId = 'notification',
}: NotificationProps) => {
  const { label } = useCustomTranslation();
  const look = notificationLook ?? NOTIFICATION_LOOK.INFO;
  const titleSize =
    look === NOTIFICATION_LOOK.MESSAGE ? TITLE_SIZE.BODYMBOLD : TITLE_SIZE.BODYSBOLD;
  const { state: notificationIsVisible, toggleOff: hideNotification } = useToggle(true);
  const { state: collapsed, toggle: toggleCollapsed } = useToggle(false);

  if (!notificationIsVisible) {
    return null;
  }

  return (
    <div
      className={classNames(styles.notification, styles[look], inheritStyle)}
      aria-live="assertive"
      aria-atomic="true"
    >
      <div
        className={classNames(styles.topContent)}
        data-cy={dataCy}
        data-testid={`${dataTestId}-top-content`}
      >
        <div
          data-cy="notification-icon-wrapper"
          className={classNames({
            [styles.collapsableWrapper]: isCollapsable,
          })}
        >
          <NotificationIcon {...{ look, icon }} />
        </div>

        <div
          className={classNames(styles.contentWrapper, {
            [styles.collapsableContent]: isCollapsable,
          })}
        >
          <div
            className={classNames(styles.textWrapper, {
              [styles.collapsableWrapper]: isCollapsable,
            })}
          >
            <Title size={titleSize} data-cy="notification-title" tag={TITLE_TAG.PARAGRAPH}>
              {title}
            </Title>
            {children && !isCollapsable && (
              <p className={classNames(styles.content)} data-cy="notification-content">
                {children}
              </p>
            )}
          </div>
          {isCollapsable && (
            <div className={classNames(styles.actions)} data-cy="notification-actions">
              <NotificationAction
                data-cy={`notification-action-collapse-btn`}
                children={collapsed ? label('see more') : label('see less')}
                onClick={toggleCollapsed}
                suffix={collapsed ? ChevronDownIcon : ChevronUpIcon}
                look={BUTTON_LOOK.TERTIARY}
                data-testid={`${dataTestId}-collapse-btn`}
              />
            </div>
          )}
          {actions && (
            <div className={classNames(styles.actions)} data-cy="notification-actions">
              {actions.map((action, index) => (
                <NotificationAction
                  data-cy={`notification-action-${index}`}
                  key={index}
                  {...action}
                  data-testid={`${dataTestId}-action-${index}`}
                />
              ))}
            </div>
          )}
        </div>
        {img && <div className={classNames(styles.imgWrapper)}>{img}</div>}
        {dismissable && (
          <DismissButton
            collapsed={collapsed}
            hideNotification={hideNotification}
            onDismiss={onDismiss}
            data-testid="notification-dismiss-btn"
            label={label}
          />
        )}
      </div>

      {isCollapsable && !collapsed && (
        <div className={styles.collapsableContent}>
          <Divider data-testid={`${dataTestId}-divider`} />
          <div
            className={classNames(styles.content, styles.collapsableContainer)}
            data-cy="notification-collapsable-content"
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
