import { AuditQuestion } from '../../types/Audits';
import QuestionComponent from '../QuestionComponent';
import { QuestionsSkeleton } from '../Skeletons';

import { QuestionsProps } from './Questions.types';

const Questions = ({
  items,
  responses = [],
  isLoading,
  isComplete,
  control,
  label,
  errors = {},
}: QuestionsProps) => {
  if (isLoading) {
    return <QuestionsSkeleton />;
  }

  const sortedItems = [...items].sort((a, b) => a.order - b.order);
  return (
    <div>
      {sortedItems.map((item: AuditQuestion) => {
        const response = responses.find(({ questionId }) => questionId === item.id);

        return (
          <QuestionComponent
            questionResponse={response}
            isComplete={isComplete}
            isDisabled={isComplete}
            key={item.id}
            {...item}
            label={label}
            control={control}
            error={errors[item.id]}
            data-testid={`question-${item.id}`}
          />
        );
      })}
    </div>
  );
};

export default Questions;
