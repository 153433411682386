const labels = {
  ContactForm: { 'Ref: Page title': '联系我们', 'Ref: Category field value': '联系我们' },
  ContactList: {
    'Ref: Page title': '联系人',
    'call (action to place a call)': '电话',
    'write (action to write a message)': '留言',
    'write (action to write an e-mail)': '电子邮件',
    'Ref: Hint message':
      '请注意，某些地点可能没有前台。如果是这种情况，请查找周围是否有可用的座机。',
  },
  FormSubmissionFailure: {
    'Ref: Message body': '发生错误，您的邮件未提交。如果此错误仍然存​​在，请联系您的服务台。',
  },
  FormSubmissionSuccess: { 'Ref: submitted success': '您的留言已提交。感谢您与我们联系。' },
};
export default labels;
