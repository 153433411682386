import classNames from 'classnames';
import { useHistory } from 'react-router';

import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import SuccessPage from '../../../../components/templates/SuccessPage';
import { pagePaths } from '../../config';

import { SuccessProps } from './Success.types';

import styles from './Success.module.css';

const Success = ({ name, title, label }: SuccessProps) => {
  const history = useHistory();

  return (
    <SuccessPage title={title}>
      <h1 className={classNames(styles.title)} data-testid="success-title">
        {label('Ref: Success title')}
      </h1>
      <Title size={TITLE_SIZE.HEADLINEXS}>
        {label('Ref: Success text')}, {name}
      </Title>
      <ActionsBar className={styles.actionButton}>
        <Button
          data-testid="audit-success-secondary"
          look={BUTTON_LOOK.SECONDARY}
          onClick={() => history.push(pagePaths.Module)}
        >
          {label('Ref: Back to Audit list')}
        </Button>
      </ActionsBar>
    </SuccessPage>
  );
};

export default Success;
