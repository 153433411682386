const labels = {
  ContactForm: { 'Ref: Page title': 'ติดต่อเรา', 'Ref: Category field value': 'ติดต่อเรา' },
  ContactList: {
    'Ref: Page title': 'รายชื่อผู้ติดต่อ',
    'call (action to place a call)': 'เรียก',
    'write (action to write a message)': 'เขียน',
    'write (action to write an e-mail)': 'อีเมล',
    'Ref: Hint message':
      'โปรดทราบว่าบางไซต์อาจไม่มีแผนกต้อนรับ หากเป็นกรณีนี้โปรดค้นหาโทรศัพท์โทรศัพท์พื้นฐานที่มีอยู่รอบ ๆ ไซต์',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'ข้อผิดพลาดที่เกิดขึ้นและข้อความของคุณไม่ได้ส่ง หากข้อผิดพลาดนี้ยังคงมีอยู่โปรดติดต่อฝ่ายช่วยเหลือของคุณ',
  },
};
export default labels;
