import baseApi from '../../../services/api/baseApi';
import { FoodFacility } from '../components/FoodFacilitiesList';
import { Menu } from '../components/ProductsList/ProductsList.types';
import mockResponse from '../mockResponse';

const menusApi = baseApi.enhanceEndpoints({ addTagTypes: ['menus'] }).injectEndpoints({
  endpoints: (build) => ({
    getFoodFacilities: build.query<
      { facilities: FoodFacility[] },
      { siteId: string; useErrorBoundary?: boolean }
    >({
      query: ({ siteId, useErrorBoundary }) => ({
        url: '/v1/menu/facility/list',
        method: 'get',
        mockData: mockResponse.getFoodFacilities,
        params: { siteId },
        opts: { useErrorBoundary: useErrorBoundary },
      }),
    }),
    getMenus: build.query<
      { menus: Menu[] },
      { facilityId: string; date: string; useErrorBoundary?: boolean }
    >({
      query: ({ facilityId, date, useErrorBoundary }) => ({
        url: `/v3/facilities/${facilityId}/menu`,
        method: 'get',
        params: { menuDate: date },
        mockData: mockResponse.getMenus,
        opts: { useErrorBoundary: useErrorBoundary },
      }),
    }),
  }),
});

export const { useGetFoodFacilitiesQuery, useGetMenusQuery } = menusApi;
export default menusApi;
