const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Majoituspyyntö',
    'Ref: Describe your request': 'Kuvaile pyyntösi',
    'Ref: Confirm your request': 'Vahvista pyyntösi',
    'Ref: Your request': 'Sinun pyyntösi',
    'Ref: Summary': 'Yhteenveto',
    'Ref: Next': 'Seuraava',
    'Ref: Submit': 'Lähetä',
    'Ref: Me': 'Minä',
    'Ref: Arrival date': 'Saapumispäivä',
    'Ref: Arrival time': 'Saapumisaika',
    'Ref: Departure date': 'Lähtöpäivä',
    'Ref: Departure time': 'Lähtöaika',
    'Ref: request raised for': 'Henkilö, johon tämä pyyntö vaikuttaa',
    'Ref: phoneNumber': 'Puhelinnumero',
    'Ref: arrivalDate': 'Saapuminen',
    'Ref: departureDate': 'Lähtö',
    'Ref: description': 'Kommentti',
    'Ref: open': 'Avata',
    'Ref: pending': 'Odotettavissa oleva',
    'Ref: refused': 'Kieltäytynyt',
    'Ref: closed': 'Suljettu',
    'Ref: all': 'Kaikki',
    'Ref: Arrival': 'Saapuminen',
    'Ref: Validate': 'Validoida',
    'Ref: site': 'Sivuston sijainti',
    'Ref: Update us': 'Päivitä meidät',
    'Ref: email of guest': 'Vieraan sähköposti',
    'Ref: GuestAccommodationRequestTitle': 'Vieraspyyntö',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Uusi pyyntö',
    'Ref: Status': 'Tila',
    'Ref: Date': 'Päivämäärä',
    'Ref: Coming arrivals': 'Tulevat saapuvat',
    'Ref: Past arrivals': 'Aiemmat saapumiset',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Vieraan puhelinnumero',
    'Ref: Your phone number': 'Puhelinnumerosi',
    'Ref: The phone number': 'Puhelinnumero',
    'Ref: PhoneNumber error msg': 'Puhelinnumero ei ole kelvollinen',
    'Ref: Comment error msg': 'Merkkien enimmäismäärä on 250',
    'Ref: Guest first name': 'Vieraana etunimi',
    'Ref: Guest last name': 'Vieraana sukunimi',
    'Ref: request for': 'Kenelle tämä pyyntö on?',
    'Ref: request for me': 'Minä',
    'Ref: request for guest': 'Joku muu',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Majoituspyyntötiedot',
    'Ref: Timeline': 'Aikajana',
    'Ref: No updates placeholder': 'Tätä pyyntöä ei ole päivityksiä',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Kiitos',
    'Ref: Success text': 'Olemme saaneet pyyntösi ja tutkimme sitä nyt. Palataan sinuun pian.',
    'Ref: Go to home': 'Mene kotiin',
    'Ref: Create another request': 'Luo toinen pyyntö',
  },
  CreateError: {
    'Ref: Create error title': 'Oho!',
    'Ref: Create error text': 'Tapahtui virhe. Ole hyvä ja yritä uudelleen.',
    'Ref: Retry': 'Yritä uudelleen',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Päivitä meille kaikilla uusilla elementeillä pyynnöstäsi, jotta voimme parhaiten tukea sinua.',
  },
};
export default labels;
