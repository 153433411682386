const labels = {
  ContactForm: {
    'Ref: Page title': 'Liên hệ với chúng tôi',
    'Ref: Category field value': 'Liên hệ với chúng tôi',
  },
  ContactList: {
    'Ref: Page title': 'Liên lạc',
    'call (action to place a call)': 'gọi điện',
    'write (action to write a message)': 'viết',
    'write (action to write an e-mail)': 'e-mail',
    'Ref: Hint message':
      'Xin lưu ý rằng một số trang web có thể không có tiếp nhận. Nếu đây là trường hợp, vui lòng xác định một trong những điện thoại cố định có sẵn trên trang web.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Xảy ra lỗi và tin nhắn của bạn đã không được gửi. Nếu lỗi này vẫn còn, xin vui lòng liên hệ với bộ phận trợ giúp của bạn.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Tin nhắn của bạn đã được gửi. Cảm ơn bạn đã liên hệ với chúng tôi.',
  },
};
export default labels;
