import { cleanup, screen } from '@testing-library/react';

import StepperCreate from '../StepperCreate';

import renderComponent from '@/helpers/tests/renderComponent';

const props = {
  stepIndex: 0,
  label: jest.fn().mockReturnValue('some label'),
};

describe('StepperCreate', () => {
  afterEach(() => cleanup());
  renderComponent(StepperCreate, props);
  describe('Render', () => {
    it('should render', () => {
      const step1 = screen.queryByTestId('stepper-1');
      expect(step1).toBeTruthy();

      const step2 = screen.queryByTestId('stepper-2');
      expect(step2).toBeTruthy();

      const step3 = screen.queryByTestId('stepper-3');
      expect(step3).toBeFalsy();
    });
  });
});
