const labels = {
  ContactForm: { 'Ref: Page title': '문의하기', 'Ref: Category field value': '문의하기' },
  ContactList: {
    'Ref: Page title': '콘택트 렌즈',
    'call (action to place a call)': '전화',
    'write (action to write a message)': '쓰다',
    'write (action to write an e-mail)': '이메일',
    'Ref: Hint message':
      '일부 사이트에는 리셉션이 없을 수도 있습니다. 이 경우 사이트 주변에서 사용 가능한 유선 전화 중 하나를 찾으십시오.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      '오류가 발생했고 메시지가 제출되지 않았습니다. 이 오류가 계속되면 헬프 데스크에 문의하십시오.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': '귀하의 메시지가 제출되었습니다. 문의해 주셔서 감사합니다.',
  },
};
export default labels;
