import { dspApiPath, apiPath } from '../../config';
import { APP_NAME } from '../../constants';
import { getAppSpecificData } from '../../helpers/misc';
import { AppSpecificSettingsType } from '../../types';
import { serviceTypes, modulesConfig } from '../config';

import { LOCATION_LIST_TYPE } from './components/LocationListPage/LocationListPage.types';
import { SitesConfigType } from './types/sites.types';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

export const moduleId = 'Sites';
export const cacheVersion = '0.2';

export const modulePath = serviceTypes[modulesConfig[moduleId]?.serviceType]?.path;

export const pagePaths = {
  Module: modulePath,
  SiteDetails: modulePath + '/:id/details',
};

export const MAP_ZOOM_COVERAGE_IN_MILES = 4;
export const MAX_VISITED_SITES_TO_DISPLAY = 4;
export const DEFAULT_COORDINATES = {
  latitude: 51.513604524029695,
  longitude: -0.12426363599131027,
};

export const DEFAULT_DISPLAY_TYPE: AppSpecificSettingsType<LOCATION_LIST_TYPE> = {
  default: LOCATION_LIST_TYPE.LIST,
};

export const canEnableUserLocation = () => {
  const fields = {
    [APP_NAME.WANDO]: { enableUserLocation: false },
    default: { enableUserLocation: true },
  };

  return getAppSpecificData(fields);
};

export const canHidePublicSitesLocation = () => {
  const fields = {
    [APP_NAME.WANDO]: { hidePublicSitesLocation: true },
    [APP_NAME.MYWAY]: { hidePublicSitesLocation: true },
    default: { hidePublicSitesLocation: false },
  };

  return getAppSpecificData(fields);
};

export const getApiEndpoints = () => {
  const configurations = {
    [APP_NAME.MYVILLAGE]: {
      api: baseApi,
      baseUrl: apiPath,
      getAvailableSites: '/v4/sites/available-sites',
      getPublicSites: '/v3/public/available-sites',
      selectSite: '/v4/users/activesite',
      getSiteByCodeUrl: '/v4/registrations/codes',
      setInitialSiteAndContract: '/v4/users/activesite',
    },
    default: {
      api: dspApi,
      baseUrl: dspApiPath,
      getAvailableSites: '/v1/sites/user-available',
      getPublicSites: '/v1/sites/public',
      selectSite: '/v1/sites/switch/:siteId',
      getSiteByCodeUrl: '/v1/sites/by-code',
      setInitialSiteAndContract: '/v1/sites/switch/:siteId',
    },
  };

  return getAppSpecificData<SitesConfigType>(configurations);
};
