const replaceSizeWithViewBoxInSVG = (svg: string, maxSize: number = 32) => {
  const matchedWidth = svg.match(/<svg .*width="(?<width>\d*)".*>/);
  const width = matchedWidth?.groups?.width || '32';

  const matchedHeight = svg.match(/<svg .*height="(?<height>\d*)".*>/);
  const height = matchedHeight?.groups?.height || '32';

  if (Number(width) > maxSize || Number(height) > maxSize) {
    const svgWithoutSize = svg
      .replace(/(.*<svg .*)width="\d*"(.*>)/, '$1$2')
      .replace(/(.*<svg .*)height="\d*"(.*>)/, '$1$2');

    return svgWithoutSize.replace(
      /(.*)(<svg)(.*>)(.*)/,
      `$1<svg viewBox="0 0 ${width} ${height}" $3$4`
    );
  }
  return svg;
};

export { replaceSizeWithViewBoxInSVG };
