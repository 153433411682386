import React from 'react';

import ActionsBar from '../ActionsBar/ActionsBar';

import { BAR_TYPES, BottomBarType } from './BottomBar.types';

// TODO once bottom bar object is properly typed
const BottomBar = React.memo<BottomBarType>(({ type, actions, topContent, children }) => {
  if (type === BAR_TYPES.ACTIONS_BAR) {
    //TODO migrate ActionsBar to TS
    /* @ts-ignore */
    return <ActionsBar actions={actions} topContent={topContent} />;
  }

  if (type === BAR_TYPES.CUSTOM_BAR) return <>{children}</>;

  return null;
});

export default BottomBar;
