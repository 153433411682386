import { useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';

import { MapMarker } from '../Map.types';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '';

type useMapPropsType<T> = {
  markers?: MapMarker<T>[];
  fitMapToMarkers?: boolean;
  mapLibraries: string[];
};

const useMap = <T extends object>({
  markers,
  fitMapToMarkers,
  mapLibraries,
}: useMapPropsType<T>) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=${mapLibraries.join(',')}`,
    googleMapsApiKey: API_KEY,
  });

  const fitToBounds = useCallback(() => {
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach((marker) => {
      bounds.extend({ lat: marker.coordinates.latitude, lng: marker.coordinates.longitude });
    });
    map?.fitBounds(bounds);
  }, [map, markers]);

  useEffect(() => {
    if (isLoaded && fitMapToMarkers) {
      fitToBounds();
    }
  }, [fitToBounds, fitMapToMarkers, isLoaded]);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  return {
    map,
    onLoad,
    isLoaded,
  };
};

export default useMap;
