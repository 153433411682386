import { Capacitor } from '@capacitor/core';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { appInsights } from '../../../../src/appInsights';
import useAnalytics from '../Analytics/useAnalytics';

import useSite from '@/modules/Core/hooks/useSite';
import { State } from '@/types/state.types';
import { UserSteps } from '@/types/userSteps.types';

export const useUserStepsInsightsLogging = () => {
  const state = useSelector((state: State) => state);
  const [loggedStep, setLoggedStep] = useState('');
  const { trackPageViewEvent } = useAnalytics();
  const site = useSite();

  const logUserSteps = useCallback(
    ({
      event,
      facilityId,
      facilityName,
      params,
    }: {
      event: UserSteps;
      facilityId?: string;
      facilityName?: string;
      params?: { [key: string]: string | number | boolean | undefined };
    }) => {
      const siteId = site?.id;
      const siteName = site?.name;
      const facilityID = !!facilityId ? facilityId : '';
      const facilityTitle = !!facilityName ? facilityName : '';

      const currentStep = {
        name: event,
        properties: {
          siteId: siteId,
          siteName: siteName,
          facilityId: facilityID,
          facilityName: facilityTitle,
          isMobile: Capacitor.isNativePlatform(),
          ...params,
        },
      };

      const actionsThatMayRepeat: string[] = [UserSteps.FiltersApplied];

      if (
        JSON.stringify(currentStep) !== loggedStep ||
        actionsThatMayRepeat.includes(currentStep.name)
      ) {
        if (facilityID) trackPageViewEvent(window.location?.pathname || '', false, facilityID);

        appInsights?.trackEvent(currentStep);
        setLoggedStep(JSON.stringify(currentStep));
      }
    },
    [loggedStep, trackPageViewEvent, site]
  );

  const logError = useCallback(
    (error: Error, params?: { [key: string]: string | number | boolean | undefined }) => {
      const site = state.Core.context.site;
      const siteId = site?.id;
      const isMobile = Capacitor.isNativePlatform();

      appInsights?.trackException({
        exception: error,
        properties: {
          siteId: siteId,
          isMobile: isMobile,
          ...params,
        },
      });
    },
    [state.Core?.context.site]
  );

  return { logUserSteps, logError };
};

export default useUserStepsInsightsLogging;
