import React, { useCallback, useEffect } from 'react';

/* TODO: This needs to be reworked.
It's just a quick solution to the scrolling problem when adding to the cart */

const useScrollPosition = () => {
  const contentRef = React.useRef<HTMLIonContentElement>();
  const scroller = React.useRef<HTMLElement>();
  const scrollElement = React.useRef<HTMLElement>();
  const scrollPosition = React.useRef<number>();

  useEffect(() => {
    contentRef?.current?.getScrollElement()?.then((element: HTMLElement) => {
      scrollElement.current = element;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current]);

  const saveScrollPosition = useCallback(() => {
    scrollPosition.current = scrollElement?.current?.scrollTop;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scroller.current?.scrollTo(0, scrollPosition.current || 0);
    }, 0); // Hack to restore the scroll position
  }, []);

  useEffect(() => {
    contentRef.current?.getScrollElement().then((element: HTMLElement) => {
      scroller.current = element;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRef.current]); // Hack to make sure the scroll element is set

  return {
    contentRef,
    saveScrollPosition,
  };
};

export default useScrollPosition;
