const labels = {
  All: {
    'Ref: See item': '請參閱項目',
    'Ref: Print': '打印收據',
    'Ref: Email': '通過電子郵件收到收據',
    'Ref: Receipt Modal message': '您想如何獲得收據？',
    'Ref: Loyalty Reward': '忠誠度獎勵',
    'Ref: Equation for stamps per item': '1購買= 1郵票',
    'Ref: Paid by employer': '您的雇主支付的金額',
    'Ref: Paid by user': '您支付的金額',
  },
  ProductsList: {
    'Ref: Page title': '商品列表',
    'Ref: Facility details': '設施的詳細信息',
    'Ref: Search products list': '搜索項目',
    'Ref: filter label: menus': '菜單',
    'Ref: filter label: moment': '片刻',
    'Ref: filter label: category': '類別',
    'Ref: all': '所有',
    'Ref: today': '今天',
    'Ref: tomorrow': '明天',
    'Ref: see more': '查看詳情',
    'Ref: see less': '隱藏詳情',
    'Ref: reset': '重置',
    'Ref: View in cart': '在購物車中查看',
    'Ref: You have changed location': '您已經更改了位置',
    'Ref: You are now at': '你現在在',
    'Ref: Menu card': '菜單卡',
    'Ref: Table': '表號',
    'Service unavailable': '暫停服務',
    'Ref: Ordering unavailable': '小心！目前在此站點上訂購是不可用的，請稍後再試。',
    'Ref: Filters': '過濾器（過敏原等）',
  },
  Order: { 'Ref: Page title': '命令' },
  MenuSelector: {
    'Ref: Menu Page title': '菜單',
    'Ref: today': '今天',
    'Ref: tomorrow': '明天',
    'Ref: Search products list': '搜索項目',
    'Ref: You have changed location': '位置已更改',
    'Ref: You are now at': '你現在位於',
  },
  ProductDetails: {
    'Ref: Page title': '產品詳情',
    'Ref: Serving Size': '份量',
    'Ref: Nutrition': '營養信息',
    'Ref: Nutrition adults': '成年人每天需要大約2000千卡',
    'Ref: Nutrition Calories': '卡路里',
    'Ref: Nutrition Calcium': '鈣',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '飽和脂肪',
    'Ref: Nutrition Carbohydrates': '碳水化合物',
    'Ref: Nutrition Sugar': '糖',
    'Ref: Nutrition Protein': '蛋白',
    'Ref: Nutrition Sodium': '鈉',
    'Ref: Nutrition cholesterol': '膽固醇',
    'Ref: Nutrition dietaryFiber': '膳食纖維',
    'Ref: Nutrition iron': '鐵',
    'Ref: Nutrition potassium': '鉀',
    'Ref: Nutrition vitaminA': '維生素A',
    'Ref: Nutrition vitaminC': '維生素C',
    'Ref: Additives': '添加劑',
    'Ref: Emission': '二氧化碳排放量',
    'Ref: This product generates': '該產品產生',
    'Ref: of CO2': '二氧化碳',
    'Ref: emissions, which equals a': '排放，等於a',
    'Ref: car trip': '汽車旅行',
    'Ref: Ingredients': '食譜成分',
    'Ref: Allergens': '過敏原',
    'Ref: Always there': '總是在那裡',
    'Ref: Allergens selected': '在過濾器上選擇過敏原：',
    'Ref: minimum required': '最低要求',
    'Ref: you have to add at least': '您必須至少添加',
    'Ref: ingredients': '配料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '自由',
    'Ref: Based on customization': '根據您的自定義',
    'Ref: Additives eggs': '蛋',
    'Ref: Additives fish': '魚',
    'Ref: Additives milk': '牛奶',
    'Ref: Additives nuts': '堅果',
    'Ref: Additives wheat': '小麥',
    'Ref: Additives peanuts': '花生',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': '芝麻',
    'Ref: Review': '審查',
    'Ref: Per portion': '每部分',
    'Ref: Per 100g': '每100克',
    'Ref: Allergen selected': '在搜索過濾器上選擇的過敏原',
    'Ref: Fitbit Log': '登錄到Fitbit',
    'Ref: age verification required on delivery': '交貨需要的年齡驗證',
    'Ref: alcohol content notification':
      '通過為其中一個項目訂購併訂購，您聲明您年齡在18歲或以上。酗酒對您的健康是危險的。',
    'Ref: default': '默認',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}如果您訂購此產品',
    'Ref: Loyalty Card Description':
      '{stamp_form}將添加到以下忠誠度{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': '忠誠郵票程序使您可以收集郵票以獲得獎勵。',
    'Ref: Loyalty Modal Description':
      '當您購買<b> {product_name}時，將添加1張郵票</b>將添加到<b> {scheme_names} </b> {program_form}',
    'Ref: and': '和',
    'Ref: Stamp': '郵票',
    'Ref: Stamps': '郵票',
    'Ref: Program': '程序',
    'Ref: Programs': '程式',
    'Ref: Loyalty Stamps': '忠誠郵票',
    'Ref: See Loyalty Stamps': '查看忠誠郵票',
    Favorite: '最喜歡的',
    'Ref: In favorites': '該產品已經在收藏夾列表中。單擊以刪除它。',
    'Ref: Not in favorites': '該產品尚未在收藏夾列表中。單擊以添加。',
    'Ref: Added to favorites': '該產品已添加到收藏夾列表中。',
    'Ref: Removed from favorites': '該產品已從收藏夾列表中刪除。',
    'Ref: From': '從',
  },
  OrderHistory: { 'Ref: Orders': '命令', 'Ref: Page Title': '我的訂單' },
  MenuTile: { 'Ref: Scan&Go': '掃描和去', 'Ref: View only': '只讀' },
  ProductTile: {
    'Ref: Allergens': '過敏原',
    'Ref: Presence of allergen': '過敏原的存在',
    'Ref: Scan': '掃描',
    'Ref: From': '從',
    'Ref: Already in product favorite list. Click to remove it.':
      '已經在產品最喜歡的列表中了。單擊以刪除它。',
    'Ref: Currently not added to product favorite list. Click to add it.':
      '目前尚未添加到產品喜歡的列表中。單擊以添加。',
    'Ref: Free': '自由的',
  },
  MenusWidget: { 'Ref: no menu': '目前沒有菜單', 'Ref: Modal title': '一天的菜單' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': '打包',
    'Ref: Eat-in': '堂食',
    'Ref: Scan&Go': '掃描和去',
    'Ref: Delivery': '送貨',
    'Ref: Total': '全部的',
    'Ref: How was facility name service': '{facility_name}的服務如何？',
    'Ref: How do you rate the service': '您如何評價服務？',
  },
};
export default labels;
