const labels = {
  All: {
    'Ref: See item': '항목을 참조하십시오',
    'Ref: Print': '영수증을 인쇄하십시오',
    'Ref: Email': '이메일로 영수증을 받으십시오',
    'Ref: Receipt Modal message': '영수증을 어떻게 받고 싶습니까?',
    'Ref: Loyalty Reward': '충성도 보상',
    'Ref: Equation for stamps per item': '1 구매 = 1 스탬프',
    'Ref: Paid by employer': '고용주가 지불 한 금액',
    'Ref: Paid by user': '귀하가 지불 한 금액',
  },
  ProductsList: {
    'Ref: Page title': '상품 목록',
    'Ref: Facility details': '시설 세부 사항',
    'Ref: Search products list': '검색 항목 검색',
    'Ref: filter label: menus': '메뉴',
    'Ref: filter label: moment': '순간',
    'Ref: filter label: category': '범주',
    'Ref: all': '모두',
    'Ref: today': '오늘',
    'Ref: tomorrow': '내일',
    'Ref: see more': '더보기',
    'Ref: see less': '더 적은 것을 보아라',
    'Ref: reset': '초기화',
    'Ref: View in cart': '카트에서보기',
    'Ref: You have changed location': '위치가 변경되었습니다',
    'Ref: You are now at': '당신은 지금 있습니다',
    'Ref: Menu card': '메뉴 카드',
    'Ref: Table': '테이블 번호',
    'Service unavailable': '서비스를 사용할 수 없습니다',
    'Ref: Ordering unavailable':
      '주의 깊은! 이 사이트에서 주문하는 것은 현재 사용할 수 없습니다. 나중에 시도하십시오.',
    'Ref: Filters': '필터 (알레르겐 등)',
  },
  Order: { 'Ref: Page title': '주문하다' },
  MenuSelector: {
    'Ref: Menu Page title': '메뉴',
    'Ref: today': '오늘',
    'Ref: tomorrow': '내일',
    'Ref: Search products list': '검색 항목 검색',
    'Ref: You have changed location': '위치가 변경되었습니다',
    'Ref: You are now at': '당신은 지금 있습니다',
  },
  ProductDetails: {
    'Ref: Page title': '제품 세부 정보',
    'Ref: Serving Size': '서빙 사이즈',
    'Ref: Nutrition': '영양 정보',
    'Ref: Nutrition adults': '성인은 하루에 2000kcal이 필요합니다',
    'Ref: Nutrition Calories': '칼로리',
    'Ref: Nutrition Calcium': '칼슘',
    'Ref: Nutrition Fat': '지방',
    'Ref: Nutrition Saturated Fat': '포화 지방',
    'Ref: Nutrition Carbohydrates': '탄수화물',
    'Ref: Nutrition Sugar': '설탕',
    'Ref: Nutrition Protein': '단백질',
    'Ref: Nutrition Sodium': '나트륨',
    'Ref: Nutrition cholesterol': '콜레스테롤',
    'Ref: Nutrition dietaryFiber': '식이 섬유',
    'Ref: Nutrition iron': '철',
    'Ref: Nutrition potassium': '칼륨',
    'Ref: Nutrition vitaminA': '비타민 A.',
    'Ref: Nutrition vitaminC': '비타민 C',
    'Ref: Additives': '첨가제',
    'Ref: Emission': 'CO2 배출량',
    'Ref: This product generates': '이 제품은 생성됩니다',
    'Ref: of CO2': '이산화탄소의',
    'Ref: emissions, which equals a': 'A.와 같는 배출량',
    'Ref: car trip': '자동차 여행',
    'Ref: Ingredients': '조리법 성분',
    'Ref: Allergens': '알레르기 항원',
    'Ref: Always there': '언제나 그곳에',
    'Ref: Allergens selected': '필터에서 선택한 알레르겐 :',
    'Ref: minimum required': '최소 필수',
    'Ref: you have to add at least': '적어도 추가해야합니다',
    'Ref: ingredients': '재료',
    'Ref: Ingredient': '재료',
    'Ref: Free': '무료',
    'Ref: Based on customization': '사용자 정의에 따라',
    'Ref: Additives eggs': '달걀',
    'Ref: Additives fish': '생선',
    'Ref: Additives milk': '우유',
    'Ref: Additives nuts': '견과류',
    'Ref: Additives wheat': '밀',
    'Ref: Additives peanuts': '땅콩',
    'Ref: Additives soy beans': '대두 콩',
    'Ref: Additives sesame seeds': '참깨',
    'Ref: Review': '검토',
    'Ref: Per portion': '부분 당',
    'Ref: Per 100g': '100g 당',
    'Ref: Allergen selected': '검색 필터에서 선택된 알레르겐',
    'Ref: Fitbit Log': 'Fitbit에 로그인',
    'Ref: age verification required on delivery': '배달에 필요한 연령 검증',
    'Ref: alcohol content notification':
      '이 항목들 중 하나를 배치하고 주문함으로써, 당신은 당신이 18 세 이상이라고 선언합니다. 알코올 남용은 건강에 위험합니다.',
    'Ref: default': '기본',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}이 제품을 주문하면 획득',
    'Ref: Loyalty Card Description':
      '{stamp_form}는 다음 충성도 {program_form} : <b> {scheme_names} </b>에 추가됩니다.',
    'Ref: About Loyalty stamps':
      '로열티 스탬프 프로그램을 사용하면 보상을 위해 우표를 수집 할 수 있습니다.',
    'Ref: Loyalty Modal Description':
      '<b> {product_name}를 구입하면 1 Stamp </b>가 <b> {scheme_names} </b> {program_form}에 추가됩니다.',
    'Ref: and': '그리고',
    'Ref: Stamp': '우표',
    'Ref: Stamps': '우표',
    'Ref: Program': '프로그램',
    'Ref: Programs': '프로그램들',
    'Ref: Loyalty Stamps': '로열티 스탬프',
    'Ref: See Loyalty Stamps': '로열티 스탬프를 참조하십시오',
    Favorite: '가장 좋아하는',
    'Ref: In favorites': '제품은 이미 즐겨 찾기 목록에 있습니다. 제거하려면 클릭하십시오.',
    'Ref: Not in favorites': '이 제품은 아직 즐겨 찾기 목록에 없습니다. 추가하려면 클릭하십시오.',
    'Ref: Added to favorites': '제품이 즐겨 찾기 목록에 추가되었습니다.',
    'Ref: Removed from favorites': '제품은 즐겨 찾기 목록에서 제거되었습니다.',
    'Ref: From': '에서',
  },
  OrderHistory: { 'Ref: Orders': '명령', 'Ref: Page Title': '내 주문' },
  MenuTile: { 'Ref: Scan&Go': '스캔 및 이동', 'Ref: View only': '만보십시오' },
  ProductTile: {
    'Ref: Allergens': '알레르겐',
    'Ref: Presence of allergen': '알레르겐의 존재',
    'Ref: Scan': '주사',
    'Ref: From': '에서',
    'Ref: Already in product favorite list. Click to remove it.':
      '이미 제품이 좋아하는 목록에 있습니다. 제거하려면 클릭하십시오.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      '현재 제품이 좋아하는 목록에 추가되지 않았습니다. 추가하려면 클릭하십시오.',
    'Ref: Free': '무료',
  },
  MenusWidget: { 'Ref: no menu': '지금은 메뉴가 없습니다', 'Ref: Modal title': '오늘의 메뉴' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': '찾다',
    'Ref: Eat-in': '식사',
    'Ref: Scan&Go': '스캔 및 이동',
    'Ref: Delivery': '배달',
    'Ref: Total': '총',
    'Ref: How was facility name service': '{facility_name}의 서비스는 어땠습니까?',
    'Ref: How do you rate the service': '서비스를 어떻게 평가합니까?',
  },
};
export default labels;
