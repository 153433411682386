const labels = {
  All: {
    Region: 'Khu vực hoặc quốc gia',
    'Ref: Geography': 'Vui lòng chọn quốc gia hoặc khu vực của bạn',
    'Ref: Country': 'Quốc gia',
    'Ref: Country tooltip': 'Vị trí tương ứng với quốc gia trang web.',
    'Ref: Please select your country': 'vui lòng chọn đất nước của bạn',
    'First name': 'Họ',
    'Last name': 'Họ',
    'Ref: Full name': 'Họ và tên',
    'Mobile number': 'Số điện thoại',
    'Mobile phone': 'Điện thoại di động',
    Email: 'Địa chỉ email',
    Password: 'Mật khẩu',
    logout: 'Đăng xuất',
    'Not a new user? Log in': 'Không phải là người dùng mới? Đăng nhập',
    'do not register, login': 'Không phải là người dùng mới? Đăng nhập',
    'Ref: I agree': 'tôi đồng ý',
    Register: 'Đăng ký',
    'Ref: My language': 'Ngôn ngữ của tôi',
    'Ref: Kiosk Fail Title': 'Ối! Có gì đó đã sai!',
    'Ref: Kiosk Fail Description':
      'Thật không may, đơn đặt hàng của bạn đã không thành công. Hãy thử lại hoặc yêu cầu một nhân viên giúp bạn.',
    'Ref: Kiosk Fail Back': 'Quay lại trang trước',
    'Ref: Kiosk Fail Action': 'Xóa phiên và đặt hàng lại',
    Validate: 'Xác nhận',
    'Ref: error, incorrect credentials': 'Những thông tin này không khớp trong Địa lý đã chọn.',
    'Ref: Forgot password?': 'Quên mật khẩu?',
    'Ref: Hello': 'Xin chào {wave_emoji}, vui lòng đăng nhập để tiếp tục',
    'Ref: Form title': 'Vui lòng đăng ký để tiếp tục',
    'Already have an account? Login': 'Bạn co săn san để tạo một tai khoản? Đăng nhập',
  },
  AccountPage: {
    'Ref: My Information': 'Thông tin của tôi',
    'Ref: Settings': 'Cài đặt',
    'Ref: Email': 'E-mail',
    'Ref: Current site': 'Trang web hiện tại',
    'Ref: Choose site': 'Chọn một trang web',
    'Ref: Personal interests': 'Lợi ích cá nhân',
    'Ref: Food preferences': 'Sở thích ăn uống',
    'Ref: Password': 'Mật khẩu',
    'Ref: Page title': 'Tài khoản',
    'Ref: Language': 'Ngôn ngữ',
    'Ref: Communication preferences': 'Sở thích giao tiếp',
    'Ref: Terms & conditions': 'Điều khoản và điều kiện',
    'Ref: Privacy policy': 'Chính sách bảo mật',
    'Ref: Delete my account': 'Xóa tài khoản của tôi đi',
    'Ref: Confirm deletion': 'Bạn có chắc rằng bạn muốn xóa tài khoản của bạn?',
    'Ref: Request deletion': 'Bạn có chắc là bạn muốn yêu cầu xóa tài khoản của mình không?',
    'Ref: Deletion description': 'Yêu cầu của bạn sẽ được xử lý theo quy định địa phương của bạn.',
    Placeholder: 'Viết "Xóa"',
    'Ref: Delete description': 'Dữ liệu của bạn sẽ bị xóa. Hành động này là không thể đảo ngược.',
    delete: 'xóa bỏ',
    'Ref: Terms of sale': 'Điều khoản bán hàng',
    'Ref: Imprint': 'Dấu ấn',
    'Ref: Cookie Settings': 'Cài đặt cookie',
    'Ref: Support': 'Ủng hộ',
    'Ref: Register or login': 'Đăng ký hoặc đăng nhập',
    'Ref: Filtering Preferences': 'Lọc tùy chọn',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Bạn có chắc chắn xóa tài khoản của mình không?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Viết "{word}" trong trường nhập và xác nhận với phía dưới bên dưới. Hành động này là không thể đảo ngược.',
    'Ref: Write "{word}"': 'Viết "{word}"',
    'Ref: Delete my account': 'Xóa tài khoản của tôi đi',
    'Ref: Words are not matching': 'Các từ không phù hợp',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Chọn vị trí của bạn để bắt đầu',
    'Ref: Choose location':
      'Tìm nhanh một vị trí bằng cách kích hoạt bản địa hóa, quét mã QR hoặc tìm kiếm theo tên',
    'Ref: next step': 'Đi nào',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Đăng kí',
    'Ref: identify your location': 'Vui lòng xác định vị trí của bạn.',
    'Ref: option 1 - scan QR': 'Tùy chọn 1: Quét mã QR đăng ký được cung cấp',
    'Ref: option 2 - enter code': 'Tùy chọn 2: Nhập thủ công mã vị trí của bạn',
    'Ref: button to open qr code scanner modal': 'Tôi có một QR: Hãy quét nó',
    'Ref: button to open site code input modal': 'Chọn trang web có mã',
    'Ref: getting help with site identifier - support desk':
      'Nếu bạn không rõ về cách tiến hành, vui lòng liên hệ với bộ phận hỗ trợ của bạn.',
    'Ref: getting help with site identifier - support form':
      'Bạn cũng có thể gửi cho chúng tôi một ghi chú.',
    'Location code': 'Mã vị trí',
    'Ref: location code field placeholder': 'Mã vị trí của bạn ở đây',
    'Ref: Scanner header': 'Quét mã QR',
    'Ref: site code input header': 'Thêm trang web với mã',
    'Ref: not valid code': 'Mã này không hợp lệ trong địa lý đã chọn',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Sự đăng ký',
    'Ref: collected info': 'chủ đề',
    'Ref: additional info': 'Thông tin của bạn',
    'Confirm password': 'Xác nhận mật khẩu',
    company: 'Chủ của bạn là ai?',
    location: 'Vị trí',
    username: 'tên tài khoản',
    register: 'Đăng kí',
    'Ref: password strength':
      'Mật khẩu của bạn phải dài ít nhất 8 ký tự, chứa ít nhất một số, một chữ hoa, một chữ thường và một ký tự đặc biệt.',
    'passwords do not match': 'Mật khẩu của bạn không phù hợp ',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Sở thích giao tiếp',
    'Ref: Things happen while you are away': 'Mọi thứ xảy ra khi bạn đi vắng',
    'Ref: Would you like to receive update about offers and promotions?':
      'Bạn có muốn nhận cập nhật về các ưu đãi và khuyến mãi không?',
    'Ref: Would you like to hear about content we think you may like?':
      'Bạn có muốn nghe về nội dung mà chúng tôi nghĩ rằng bạn có thể thích?',
    Yes: 'Đúng',
    No: 'Không',
    apply: 'ứng dụng',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Chi tiết tài khoản' },
  DeletionFailure: {
    'Ref: Body':
      'Đã xảy ra lỗi và yêu cầu xóa tài khoản của bạn không được gửi. Nếu lỗi này vẫn còn, xin vui lòng liên hệ với trợ giúp của bạn.',
    'Go to account': 'Đi vào tài khoản',
  },
  GenericFailurePage: {
    'Ref: Title': 'Đã xảy ra lỗi',
    'Ref: Body':
      'Xin lỗi, có lỗi xảy ra. Nếu vấn đề này vẫn còn, xin vui lòng liên hệ với bộ phận hỗ trợ tại chỗ của bạn.',
  },
  HomePage: {
    notifications: 'thông báo.',
    basket: 'Giỏ hàng',
    'Ref: Tabtitle': 'trang chủ',
    'contact us': 'Liên hệ với chúng tôi',
    'Ref: You are logged in as a guest user': 'Bạn đã đăng nhập với tư cách là người dùng khách',
    'Ref: Good morning': 'Buổi sáng tốt lành',
    'Ref: Good afternoon': 'Chào buổi chiều',
    'Ref: Good evening before midnight': 'Chào buổi tối',
    'Ref: Good evening after midnight': 'Chào buổi tối',
    'Ref: Search': 'Tìm kiếm',
    'Ref: products button aria': 'Tìm thấy {results_form} trong các sản phẩm',
    'Ref: menus button aria': 'Tìm thấy {results_form} trong các menu',
    'Ref: facilities button aria': 'Đã tìm thấy {results_form} trong các cơ sở',
    'Ref: result': 'kết quả',
    'Ref: results': 'kết quả',
    'Ref: Menus': 'Thực đơn',
    'Ref: No results title': 'Ối!',
    'Ref: No results body': 'Không có kết quả phù hợp với tìm kiếm của bạn',
    'Ref: Products': 'Các sản phẩm',
    'Ref: Facilities': 'Cơ sở',
    'Ref: See more results': 'Xem thêm kết quả tìm kiếm',
    'Ref: Accessibility instructions':
      'Để bắt đầu sử dụng tìm kiếm, bạn phải nhấp vào nó hoặc nhấn Enter trong khi tập trung.',
    'Ref: See more': 'Xem thêm',
    'Ref: singular form of products': 'sản phẩm',
    'Ref: singular form of menus': 'thực đơn',
    'Ref: singular form of facilities': 'cơ sở',
    'Ref: Search results for': 'tìm kiếm kết quả cho',
  },
  LanguagePage: {
    apply: 'ứng dụng',
    'My language': 'Ngôn ngữ của tôi',
    'Select in which language you would like to read our app':
      'Chọn ngôn ngữ bạn muốn đọc ứng dụng của chúng tôi',
    'Ref: Page title': 'Ngôn ngữ',
  },
  LegalDocPage: {
    Acknowledge: 'Thừa nhận',
    'Ref: Legal changed notification title': '"{legal_doc_type}" đã được sửa đổi',
    'Ref: Legal changed notification content':
      'Vui lòng xem lại chúng trước khi tiếp tục sử dụng ứng dụng.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Điều khoản & điền kiện',
    'Read privacy policy': 'Đọc chính sách bảo mật',
    'Ref: Acknowledge legal docs': 'Tôi chấp nhận các điều khoản và điều kiện này.',
    'Ref: Read and understood': 'Tôi đã đọc và hiểu ',
    'Ref: No legal docs available - notice title': 'Sắp có',
    'Ref: No legal docs available - notice body':
      'Xin lỗi, có vẻ như ứng dụng này hiện không có sẵn trong địa lý của bạn.',
    'Ref: The privacy Policy': 'Chính sách bảo mật',
    'Ref: Legal changed notification title': '"{legal_doc_type}" đã được sửa đổi',
    'Ref: Legal changed notification content':
      'Vui lòng xem lại chúng trước khi tiếp tục sử dụng ứng dụng.',
  },
  LoginPage: {
    'Ref: Page title': 'Chào mừng bạn đến với ứng dụng {app name} ',
    'Ref: Page subtitle': 'Đăng nhập để tiếp tục',
    'Ref: Email field placeholder': 'Email của bạn',
    'Ref: Email field note': 'Chúng tôi sẽ không bao giờ chia sẻ email của bạn với bất cứ ai.',
    'Ref: Password field title': 'Mật khẩu của bạn',
    register: 'Đăng kí',
    'I forgot my password.': 'Quên mật khẩu?',
    'I have trouble logging in': 'Gặp sự cố khi đăng nhập?',
    'start registration': 'Bắt đầu đăng ký',
    'Ref: Register': 'Đăng ký',
    'Ref: Login': 'Đăng nhập',
    'Ref: Connection issue?': 'Vấn đề kết nối?',
    'Ref: Connection issue message':
      'Nếu bạn có bất kỳ vấn đề nào để đăng ký hoặc đăng nhập, vui lòng liên hệ với nhân viên tại chỗ thông thường hoặc bàn trợ giúp.',
  },
  WelcomePage: {
    'Ref: Welcome to': 'Xin chào và chào mừng bạn đến với {site_name}',
    'Ref: Begin': 'Bắt đầu',
  },
  NotificationsList: {
    'Ref: No notifications': 'Bạn không có thông báo cho thời điểm này',
    'Ref: This week': 'Tuần này',
    'Ref: Earlier': 'Sớm hơn',
    'Ref: Page title': 'Thông báo.',
    'Ref: See more': 'Xem thêm',
    'Ref: Mark all read': 'đánh dấu tất cả đã đọc',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Xem tất cả thông báo' },
  ServicesMenuPage: { 'Ref: Page title': 'Dịch vụ', navigate: 'điều hướng' },
  DeletionSuccess: {
    'Ref: Title': 'Việc xóa tài khoản của bạn đã được yêu cầu',
    'Ref: Content':
      'Chúng tôi sẽ xử lý nó trong thời gian ngắn. Bạn có thể mất quyền truy cập của bạn bất cứ lúc nào.',
    'Log out': 'Đăng xuất',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'chúng tôi sẽ quay lại với bạn',
    'Ref: Body':
      'Chúng tôi xin lỗi bạn đang đối mặt với các vấn đề. Chúng tôi sẽ liên lạc với bạn rất sớm.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Gặp sự cố khi đăng nhập?',
    'Ref: Email field placeholder': 'địa chỉ email của bạn',
    'Ref: Mobile phone field placeholder': 'Số điện thoại',
    Company: 'Công ty',
    'Ref: Company field placeholder': 'Tên công ty',
    'Ref: Company field note':
      'Nếu bạn là cố vấn, đây là công ty mà bạn hiện đang làm việc, không phải chủ nhân của bạn.',
    Location: 'Thành phố hoặc địa điểm',
    'Ref: Location field placeholder': 'Vị trí nơi làm việc của bạn',
    'Ref: Location field note':
      'Nếu bên ngoài một khu vực có người ở, hãy thoải mái sử dụng tên mà khu vực thường được đề cập.',
    Comment: 'Bình luận',
    'Ref: Comment field placeholder': 'Có chuyện gì?',
    'Ref: Who is your employer?': 'Chủ nhân của bạn là ai?',
    'Ref: What village are you staying at?': 'Bạn đang ở làng nào?',
    'Ref: Comment field note': 'Bất kỳ thông tin có thể giúp chúng tôi giúp bạn!',
    'Ref: Feedback Content - geography': 'Địa lý',
    'Ref: Feedback Content - fullName': 'Tên',
    'Ref: Feedback Content - email': 'E-mail',
    'Ref: Feedback Content - phone': 'Điện thoại',
    'Ref: Feedback Content - company': 'Công ty',
    'Ref: Feedback Content - city': 'Tên thành phố / vị trí',
    'Ref: Feedback Content - whatVillage': 'Làng quê',
    'Ref: Feedback Content - employer': 'Nhà tuyển dụng',
    'Ref: Feedback Content - comment': 'Có chuyện gì',
  },
  AccountStartPage: {
    'Ref: Header': 'Chọn vị trí của bạn để bắt đầu',
    'Ref: Body':
      'Tìm nhanh một vị trí bằng cách kích hoạt bản địa hóa, quét mã QR hoặc tìm kiếm theo tên',
    'Ref: LetsGo button': 'Đi nào',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Sự đăng ký' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Chọn Trang web với máy quét QR',
    'Ref: Page title': 'chọn vị trí của bạn',
    'Ref: Select site with code': 'Chọn trang web có mã',
    'Ref: Enter site code': 'Nhập mã trang web',
    'Ref: Scanner error header': 'Trang web không tìm thấy',
    'Ref: Not found': 'Mã này không hợp lệ trong địa lý đã chọn',
    'Ref: Generic error': 'Có gì đó đã sai',
    'All other sites': 'Tất cả các trang web khác',
    'Sites in your country': 'Các trang web ở quốc gia của bạn',
    'Ref: Unauthorized error': 'Yêu cầu trái phép do mức độ hạn chế trang web',
    'Ref: Forbidden':
      'Xin lỗi, bạn không có quyền đúng để truy cập trang web này. Để sắp xếp quyền truy cập, vui lòng liên hệ với Trung tâm dịch vụ Pilbara theo số 1800 992 777',
    "Ref: We didn't find the site.": 'Chúng tôi không tìm thấy trang web.',
    'Ref: We recommend some solutions:': 'Chúng tôi đề xuất một số giải pháp:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Cố gắng thêm trang web của bạn bằng máy quét QR hoặc với sự trợ giúp của các nút bên dưới',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Đăng xuất và đăng nhập lại bằng cách chọn đúng quốc gia',
    'Ref: The currently selected country is:': 'Quốc gia hiện được chọn là:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Sự đăng ký',
    'Confirm password': 'Xác nhận mật khẩu',
    'Email is not valid': 'Email không hợp lệ',
    'passwords do not match': 'Mật khẩu của bạn không phù hợp ',
    'Ref: Password strength':
      'Mật khẩu của bạn phải dài ít nhất 8 ký tự, chứa ít nhất một số, một chữ hoa, một chữ thường và một ký tự đặc biệt.',
    'Sign up': 'Đăng ký',
    'Continue as guest': 'Tiếp tục làm khách',
    'Already have an account? Login': 'Bạn co săn san để tạo một tai khoản? Đăng nhập',
    'Ref: Registration success title': 'Cảm ơn bạn đã đăng ký',
    'Ref: Registration success content':
      'Tài khoản của bạn sẽ sớm được tạo. Bạn sẽ nhận được một email xác nhận sáng tạo.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Vui lòng đăng nhập/đăng ký',
    'Ref: Password strength':
      'Mật khẩu của bạn phải dài ít nhất 8 ký tự, chứa ít nhất một số, một chữ hoa, một chữ thường và một ký tự đặc biệt.',
    Stamps: 'Tem',
    'Order history': 'Lịch sử đơn hàng',
    'to earn points': 'để kiếm điểm',
    'to see order history': 'Để xem lịch sử đặt hàng',
    'to place an order': 'đặt hàng',
    'Sign up': 'Đăng ký',
    Login: 'Đăng nhập',
    'Email is not valid': 'Email không hợp lệ',
    'passwords do not match': 'Mật khẩu của bạn không phù hợp ',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Cảm ơn',
    'Ref: Body':
      'Gân xong rôi! Bạn sẽ sớm nhận được một email xác minh trên địa chỉ được cung cấp. Mở email này và nhấp vào liên kết để kích hoạt tài khoản của bạn.',
    'Ref: Enter': 'Tiếp tục',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Một lỗi đã xảy ra',
    'Ref: Generic Error Body': 'Vui lòng thử lại sau hoặc liên hệ với trợ giúp của bạn.',
    'Ref: Missing IndexDb support':
      'Xin lỗi, bạn không thể truy cập ứng dụng ở chế độ ẩn danh hoặc không có hỗ trợ IndexDB. Hãy thử lại với một trình duyệt khác.',
    'Ref: Resource not found': 'Nội dung bạn đang cố gắng truy cập không tồn tại.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Nội dung bạn đang cố gắng truy cập thuộc về một trang web riêng.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Nội dung bạn đang cố gắng truy cập không được ủy quyền cho tài khoản của bạn.',
    'Ref: TabTitle': 'Lỗi',
    'Ref: Site link invalid':
      'Liên kết này không hợp lệ, chọn một trang web khác hoặc liên hệ với bàn trợ giúp nếu sự cố vẫn còn.',
    'Ref: Access Denied': 'Truy cập bị từ chối. Quyền của người dùng không đủ.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Ối! {robot_emoji}',
    'Ref: Error title': 'Có gì đó đã sai!',
    'Ref: Error body':
      'Cố gắng làm mới trang này hoặc liên hệ với bàn trợ giúp nếu vấn đề vẫn còn.',
    'Ref: Error ID:': 'ID lỗi: {errorId}',
    'Ref: Back to home': 'Trở về nhà',
  },
  Child: {
    'Ref: Route Not Found':
      'Dịch vụ bạn đang tìm kiếm không có sẵn trên trang web này. Vui lòng thử lại sau hoặc liên hệ với trợ giúp của bạn.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Xin chúc mừng, mật khẩu mới của bạn đã được lưu thành công, giờ đây bạn có thể truy cập tất cả các dịch vụ của chúng tôi từ tài khoản của mình.',
    Welcome: 'Chào mừng',
    'Ref: Welcome message':
      'Bây giờ bạn đã đăng ký thành công vào ứng dụng BITE mới và có thể tiếp tục khám phá và thưởng thức tất cả các dịch vụ của chúng tôi.',
  },
  PasswordChange: {
    'Change Password': 'Đổi mật khẩu',
    Welcome: 'Chào mừng',
    'Ref: Password message': 'Mật khẩu của bạn cần được thay đổi vì nó không đủ an toàn',
  },
  AccountPasswordChange: {
    'Change your password here': 'Thay đổi mật khẩu của bạn ở đây',
    'Your old password': 'Mật khẩu cũ của bạn',
    'Type in your new password': 'Nhập mật khẩu mới của bạn',
    'Re-type in your new password': 'Type trong Mật khẩu mới của bạn',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'Mật khẩu của bạn đã được thay đổi',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Một lỗi đã xảy ra do đó, thay đổi của bạn đã không được lưu. Vui lòng thử lại.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Đặt lại mật khẩu',
    'Ref: Provide email':
      'Vui lòng cung cấp địa chỉ email được sử dụng khi đăng ký trên {app_name}. Một email sẽ được gửi cho bạn để đặt lại mật khẩu của bạn.',
    'Ref: Something happened': 'Một cái gì đó đã xảy ra. Vui lòng thử lại sau.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Chúng tôi đã gửi một email mới. Vui lòng đợi 30 giây để bán lại lần nữa.',
    'Go to login page': 'Chuyển đến trang đăng nhập',
    'Resend email': 'Gửi lại email',
    'Ref: Email sent title': 'Email đặt lại mật khẩu đã được gửi cho bạn',
    'Ref: TabTitle': 'Đặt lại mật khẩu',
    'Ref: Email sent msg':
      'Liên kết đặt lại đã được gửi đến địa chỉ của bạn {email_address}. Vui lòng đợi 30 giây trước khi cố gắng từ chối.',
  },
  ResetPassword: { 'Enter your new password': 'Nhập mật khẩu mới của bạn' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Mật khẩu của bạn đã được thay đổi thành công',
    'Go to login page': 'Chuyển đến trang đăng nhập',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Đặt lại mật khẩu không thành công',
    'Ref: Error': 'Đã có lỗi xảy ra. Xin vui lòng thử lại',
    'Reset your password': 'Đặt lại mật khẩu của bạn',
    'Go to login page': 'Chuyển đến trang đăng nhập',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Chính sách bảo mật',
    'Ref: Privacy Policy Body':
      'Bằng cách truy cập nền tảng này, Sodexo SA và thực thể Sodexo cục bộ sẽ xử lý dữ liệu cá nhân của bạn để ít nhất quản lý tài khoản của bạn và cung cấp dịch vụ và ưu đãi có sẵn thông qua nền tảng. Nếu bạn muốn biết thêm thông tin về việc xử lý dữ liệu cá nhân của bạn, ai sẽ truy cập nó và cách thực hiện các quyền bạn có trên dữ liệu cá nhân của bạn, vui lòng tham khảo Chính sách bảo mật bên dưới và có sẵn bất cứ lúc nào trong tài khoản của bạn.',
    'Ref: Continue': 'Tiếp tục',
    'Ref: Read Privacy Policy': 'Đọc chính sách quyền riêng tư',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Đi đến cửa hàng' },
  SharePage: { 'Ref: Select a site': 'Chọn một trang web' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Để xem chính sách quyền riêng tư, vui lòng chọn quốc gia của bạn.',
  },
};
export default labels;
