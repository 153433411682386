import { TextareaChangeEventDetail } from '@ionic/core';
import classNames from 'classnames';
import momentjs from 'moment';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { Calendar2Icon } from '../../../../assets/icons';
import { FeedbackIllustration } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Hint from '../../../../components/atoms/Hint';
import Input from '../../../../components/atoms/Input/Input';
import Textarea from '../../../../components/atoms/Textarea/Textarea';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Calendar from '../../../../components/molecules/Calendar';
import Card from '../../../../components/molecules/Card/Card';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import FillInTheBlanks from '../../../../components/organisms/FillInTheBlanks/FillInTheBlanks';
import Modal from '../../../../components/organisms/Modal';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { validateForm, FormValidationAlert } from '../../../../components/validateForm';
import { SIZE } from '../../../../constants';
import { INPUT_TYPE } from '../../../../constants';
import { EMOJIS } from '../../../../constants';
import { formatDate } from '../../../../helpers/dateTime';
import MM from '../../../../services/ModulesManager';
import { SERVICE } from '../../../config';
import { ApiType } from '../../../ServiceRequest/api';
import { checkIfServiceAvailable } from '../../../services';
import { useCreateFeedbackMutation } from '../../api/api';
import { feedbackTypes, pagePaths } from '../../config';
import { useFeedbackTranslation } from '../../hooks/useFeedbackTranslation';

import useLoginStatus from '@/helpers/hooks/useLoginStatus';
import { useSetupOption } from '@/helpers/hooks/useSetupOption/useSetupOption';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import SwitchSite from '@/modules/Sites/components/SwitchSite';

import styles from './Create.module.css';

const getFormValidationRules = (subject: string, comment: string, feedbackDate: Date) => {
  return {
    Subject: () => !!subject?.length,
    Comment: () => !!comment?.length,
    Date: () => !!feedbackDate,
  };
};

const defaultCategories = ['default category example 1', 'default category example 2'];

const Create = () => {
  const { currentLanguageCode } = useLanguage();
  const history = useHistory();
  const { label } = useFeedbackTranslation(__filename);
  const { isGuest } = useLoginStatus();

  const [feedback, setFeedback] = useState({
    type: undefined,
    category: undefined,
    feedbackDate: new Date(),
    subject: '',
    comment: '',
  });

  const [showFormValidationAlert, setShowFormValidationAlert] = useState(false);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [createFeedback] = useCreateFeedbackMutation();
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const category = useSetupOption('categories', SERVICE.FEEDBACK);

  const categories = useMemo(
    () =>
      category?.isActive && category?.value
        ? category.value?.split(';').map((el: string) => el.trim())
        : defaultCategories,
    [category]
  );

  const getRequestCreationPath = MM<ApiType>().getApi(
    'ServiceRequests',
    'getRequestCreationPath'
  ) as ApiType['getRequestCreationPath'];
  const requestCreationPath = getRequestCreationPath();

  const { isValid: isFormValid, missingInfo: formMissingInfo } = validateForm(
    getFormValidationRules(feedback.subject, feedback.comment, feedback.feedbackDate)
  );

  const handleSentenceChange = (step: number, selectedOptionValue: string) => {
    let key: string;
    switch (step) {
      case 0:
        key = 'type';
        break;
      case 1:
        key = 'category';
        break;
      default:
        return;
    }

    setFeedback((prev) => ({ ...prev, [key]: selectedOptionValue }));
  };

  const handleDateChange = (date: Date) => {
    setFeedback((prev) => ({ ...prev, feedbackDate: date }));
    setIsCalendarVisible(false);
  };

  const handleSubjectChange = (val: string) => {
    setFeedback((prev) => ({ ...prev, subject: val }));
  };

  const handleCommentChange = (event: CustomEvent<TextareaChangeEventDetail>) => {
    setFeedback((prev) => ({ ...prev, comment: event.detail.value || '' }));
  };

  if (!site) return <LoadingPage />;

  const actions = [];
  const isServiceRequestServiceAvailable = checkIfServiceAvailable(SERVICE.SERVICE_REQUEST);

  /* On the first feedback screen we want to display the secondary button 
    which will redirect us to the service request page. 
    So if the path is defined, display the button. */
  if (requestCreationPath && isServiceRequestServiceAvailable && !isGuest) {
    actions.push(
      <>
        <Hint
          data-testid="feedback-request-creation"
          className={classNames(styles.hint, 'mb-S')}
          text={label('Ref: Hint')}
        />
        <Button
          data-testid="feedback-request-creation-path"
          onClick={() => history.push(requestCreationPath)}
          look={BUTTON_LOOK.SECONDARY}
        >
          {label('Ref: Log Request')}
        </Button>
      </>
    );
  }

  /* Type is chosen on the first feedback screen, so if it is defined,
    we know that we are on the second feedback screen.
    We don't want to display any button here. */
  if (feedback.type) {
    actions.pop();
  }

  /* Category is chosen on the second feedback screen, so if it is defined,
    we know that we are on the third feedback screen.
    We want to display submit button here. */
  if (feedback.category) {
    actions.push(
      <Button
        data-testid="feedback-submit"
        onClick={
          isFormValid
            ? async () => {
                setSubmitting(true);
                const title = feedback.category + ' - ' + feedback.subject;
                const result = await createFeedback({
                  siteId: site.id,
                  title,
                  comment: feedback.comment,
                  feedbackType: {
                    id: feedbackTypes[feedback.type!].toString(),
                    name: feedback.type!,
                  },
                  feedbackDate: feedback.feedbackDate,
                  withAccessToken: true,
                });
                setSubmitting(false);
                if (result && 'error' in result) {
                  history.push(pagePaths.Failure);
                } else {
                  history.push(pagePaths.Success);
                }
              }
            : () => setShowFormValidationAlert(true)
        }
        loading={submitting}
        disabled={!isFormValid}
      >
        {label('submit')}
      </Button>
    );
  }

  const getEmoji = (feedbackTypeName: string) => {
    switch (feedbackTypeName) {
      case 'Compliment type':
        return EMOJIS.party;
      case 'Feedback type':
        return EMOJIS.speech;
      case 'Complaint type':
        return EMOJIS.confused;
      default:
        return;
    }
  };

  const options = [
    Object.entries(feedbackTypes).map(([feedbackTypeName]) => ({
      value: feedbackTypeName,
      label: label(feedbackTypeName, {
        replace: { emoji: String.fromCodePoint(getEmoji(feedbackTypeName) || 0) },
      }),
    })),
    categories
      .map((item) => ({
        value: item,
        label: item,
      }))
      .filter((i) => i.label),
  ];

  const describeForm =
    site && feedback.type && feedback.category ? (
      <Card
        overTitle={{
          tag: TITLE_TAG.H2,
          size: TITLE_SIZE.HEADLINES,
          children: label('Ref: Provide Description'),
        }}
      >
        <div className={classNames('mb-M')}>
          <Title className={'mb-S'} size={TITLE_SIZE.BODYSBOLD}>
            {label('Date of your experience')}
          </Title>
          <div className={classNames(styles.calendarButton)}>
            <Button
              look={'primary'}
              data-testid={'filter-option-expanded-calendar'}
              data-cy={'filter-option-expanded-calendar'}
              onClick={() => setIsCalendarVisible(true)}
              size={SIZE.SMALL}
              // extraMargin={true}
              contentCenterAlign={true}
              affix={() => <Calendar2Icon />}
            >
              {feedback.feedbackDate
                ? formatDate(
                    momentjs(feedback.feedbackDate, 'DD/MM/YYYY').toDate(),
                    currentLanguageCode
                  )
                : label('calendar', { textTransform: 'capitalize' })}
            </Button>
            <Modal
              title={label('calendar', { textTransform: 'capitalize' })}
              isOpen={isCalendarVisible}
              id="calendar_modal"
              onDismiss={() => setIsCalendarVisible(false)}
            >
              <Calendar
                maxDate={new Date()}
                onChange={handleDateChange}
                value={feedback.feedbackDate}
                minDetail="decade"
              />
            </Modal>
          </div>
        </div>
        <div className={classNames('mb-M')}>
          <Input
            data-testid="create-feedback-subject"
            id="subject"
            inputLabel={label('Subject')}
            required
            inputType={INPUT_TYPE.TEXT}
            placeholder=""
            value={feedback.subject}
            onInputChange={handleSubjectChange}
          />
        </div>
        <Textarea
          label={label('Comment')}
          id="comment"
          onChange={handleCommentChange}
          value={feedback.comment}
          required
          placeholder={label('Ref: Describe issue')}
        />
        <FormValidationAlert
          missingInfo={formMissingInfo}
          show={showFormValidationAlert}
          label={label}
          msgCodes={{}}
          onDidDismiss={() => setShowFormValidationAlert(false)}
        />
      </Card>
    ) : undefined;

  const switchSite = (
    <SwitchSite
      sentence={label('Ref: Switch site component intro sentence')}
      redirectTo={pagePaths['Create']}
    />
  );

  const sentence = label('Ref: Fill-in-the-blank sentence');

  return (
    <SimpleFormPage hasBackLink={false} title={label('Feedback')}>
      <Container>
        <Column.Main>
          {switchSite}
          <Card
            overTitle={{
              tag: TITLE_TAG.H2,
              size: TITLE_SIZE.HEADLINES,
              children: label('Ref: Share your opinion header'),
            }}
          >
            <FillInTheBlanks
              sentence={sentence}
              options={options}
              handleChange={handleSentenceChange}
              selectedOptions={undefined}
            />
          </Card>
          {describeForm}
        </Column.Main>
        <Column.Complementary>
          <FeedbackIllustration />
        </Column.Complementary>
        {actions.length ? <ActionsBar>{actions}</ActionsBar> : null}
      </Container>
    </SimpleFormPage>
  );
};

export default Create;
