import classNames from 'classnames';

import { DividerProps } from './Divider.types';

import styles from './Divider.module.css';

const Divider = ({
  children,
  className,
  'data-cy': dataCy = 'divider',
  'data-testid': dataTestId = 'divider',
}: DividerProps) => {
  return (
    <div
      className={classNames(styles.divider, className)}
      data-cy={dataCy}
      data-testid={dataTestId}
    >
      {children && (
        <span
          className={classNames(styles.title)}
          data-cy={`${dataCy}-label`}
          data-testid={`${dataTestId}-label`}
        >
          {children}
        </span>
      )}
    </div>
  );
};

export default Divider;
