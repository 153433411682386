import { useSelector } from 'react-redux';

import { NavArrowRightIcon } from '../../../../assets/icons';
import { Woman2Illustration } from '../../../../assets/illustrations';
import { Tile } from '../../../../components/molecules/Tile';
import Column from '../../../../components/organisms/Column';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { addParamsToSurveyUrl, openUrl } from '../../../../helpers/misc';
import { State } from '../../../../types/state.types';
import { pagePaths } from '../../config';
import { useFeedbackTranslation } from '../../hooks/useFeedbackTranslation';

import { AlwaysOnSurveysProps, AlwaysOnItem } from './AlwaysOnSurveys.types';

import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import useSite from '@/modules/Core/hooks/useSite';
import SwitchSite from '@/modules/Sites/components/SwitchSite';

const AlwaysOnSurveys = ({ surveys, surveyReceiverEmail, campaignId }: AlwaysOnSurveysProps) => {
  const site = useSite()!;
  const user = useSelector((state: State) => state.Core.user);
  const { label } = useFeedbackTranslation(__filename);

  if (!site) return <LoadingPage />;

  const search = {
    searchableKeys: ['title'],
  };

  const items = surveys.map((survey, index) => {
    if (survey.value) {
      survey.value = survey.value
        .replace('{$0}', campaignId)
        .replace('{$1}', encodeURI(site.name))
        .replace('{$2}', surveyReceiverEmail || '');
    }
    const locationName = decodeURI(
      survey.value?.substring(
        survey.value.lastIndexOf('Zones=') + 'Zones='.length,
        survey.value.indexOf('&Loc')
      ) || ''
    );
    return {
      id: `${index}`,
      title: locationName,
      url: survey.value!,
      'data-testid': `survery-item-${index}`,
    } as AlwaysOnItem;
  });

  const handleOnClick = (item: AlwaysOnItem): void => {
    let url = item.url;
    if (item.url.includes('mcxplatform')) {
      url = addParamsToSurveyUrl(item.url, { site, user });
    }

    openUrl(url);
  };

  const switchSite = (
    <SwitchSite
      sentence={label('Ref: Switch site component intro sentence')}
      redirectTo={pagePaths['Create']}
    />
  );

  const topContent = (
    <>
      {switchSite}
      <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
        {label('Ref: Share your opinion header')}
      </Title>
    </>
  );

  return (
    <ListPage
      data-testid="always-on-surveys-list-page"
      listTopContent={topContent}
      hasBackLink={false}
      title={label('Feedback')}
      items={items}
      search={search}
      withNavBar={true}
      hideFilterTitle={true}
      aside={
        <Column.Complementary>
          <Woman2Illustration />
        </Column.Complementary>
      }
      itemRenderer={(item) => {
        return (
          <Tile
            key={`${item.id}`}
            title={label(item.title)}
            onClick={() => handleOnClick(item)}
            data-testid={`survey-${item.id}`}
            actions={[
              {
                name: 'NavArrowRightIcon',
                icon: <NavArrowRightIcon />,
                onClick: () => handleOnClick(item),
                'data-testid': `always-on-surveys-action-${item.id}`,
              },
            ]}
          />
        );
      }}
    />
  );
};

export default AlwaysOnSurveys;
