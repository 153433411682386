import React from 'react';

import { INPUT_TYPE } from '../../../constants';
import { CheckboxProps } from '../../atoms/Checkbox';
import { QuantitySetterProps } from '../../atoms/QuantitySetter/QuantitySetter.types';
import { RadioProps } from '../../atoms/Radio/Radio.types';

import { TestingProps, TestingPropsV2 } from 'src/types';

export enum CONTROL_TYPE {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  QUANTITY = 'quantity',
}
export type ListItemIconProps = {
  icon: React.ComponentType<{
    className?: string;
  }>;
  className?: string;
  afterLabel?: boolean;
  endIcon?: boolean;
};

export type ListItemOnClickProps = {
  value?: string;
  checked?: boolean;
  quantity?: number;
};

export type ListItemControlProps = {
  type: CONTROL_TYPE;
  props: CheckboxProps | RadioProps | QuantitySetterProps;
};

type EditItemProps = {
  inputType?: INPUT_TYPE;
  validate?: (data: any) => void;
  handleSubmit: (data?: string) => void;
};

export function isQuantitySetterProps(props: any): props is QuantitySetterProps {
  return 'min' in props;
}

export type ListItemWrapperProps = {
  path?: string;
  externalPath?: string;
  showAlert?: () => void;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  disabled?: boolean;
  role?: string;
  className?: string;
  children: React.ReactNode;
} & TestingProps;

export type ListItemProps = {
  id: string;
  name?: string;
  className?: string;
  label?: string | React.ReactNode;
  secondaryLabel?: string | React.ReactNode;
  value?: string | number | JSX.Element;
  quantity?: number;
  disabled?: boolean;
  onClick?: (id: string, props: ListItemOnClickProps) => void;
  control?: ListItemControlProps;
  meta?: any;
  icons?: ListItemIconProps[];
  path?: string;
  externalPath?: string;
  wrapLine?: boolean;
  labelClassName?: string;
  valueClassName?: string;
  edit?: EditItemProps;
  hide?: boolean | undefined;
  hasRole?: boolean;
  action?: () => void;
} & TestingPropsV2;

export type ListProps = {
  items: ListItemProps[];
  onChange?: (modifier: any[], id: string) => void;
  role?: string;
  twoColumn?: boolean;
  'aria-labelledby'?: string;
} & TestingPropsV2;

export type LabelWrapperProps = {
  children: React.ReactNode;
  hasLabel: boolean;
  id: string;
};
