const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Richiesta di alloggio',
    'Ref: Describe your request': 'Descrivi la tua richiesta',
    'Ref: Confirm your request': 'Conferma la tua richiesta',
    'Ref: Your request': 'La tua richiesta',
    'Ref: Summary': 'Riepilogo',
    'Ref: Next': 'Prossimo',
    'Ref: Submit': 'Invia',
    'Ref: Me': 'Me',
    'Ref: Arrival date': "Data d'arrivo",
    'Ref: Arrival time': 'Orario di arrivo',
    'Ref: Departure date': 'Data di partenza',
    'Ref: Departure time': 'Orario di partenza',
    'Ref: request raised for': 'La persona interessata da questa richiesta',
    'Ref: phoneNumber': 'Numero di telefono',
    'Ref: arrivalDate': 'Arrivo',
    'Ref: departureDate': 'Partenza',
    'Ref: description': 'Commento',
    'Ref: open': 'Aprire',
    'Ref: pending': 'In attesa di',
    'Ref: refused': 'Rifiutato',
    'Ref: closed': 'Chiuso',
    'Ref: all': 'Tutto',
    'Ref: Arrival': 'Arrivo',
    'Ref: Validate': 'Convalidare',
    'Ref: site': 'Posizione del luogo',
    'Ref: Update us': 'Aggiornaci',
    'Ref: email of guest': "E-mail dell'ospite",
    'Ref: GuestAccommodationRequestTitle': 'Richiesta ospite',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Nuova richiesta',
    'Ref: Status': 'Stato',
    'Ref: Date': 'Data',
    'Ref: Coming arrivals': 'Arrivi in ​​arrivo',
    'Ref: Past arrivals': 'Arrivi passati',
  },
  CreateForm: {
    'Ref: Guest phone number': "Numero di telefono dell'ospite",
    'Ref: Your phone number': 'Il tuo numero di telefono',
    'Ref: The phone number': 'Il numero di telefono',
    'Ref: PhoneNumber error msg': 'Il numero di telefono non è valido',
    'Ref: Comment error msg': 'Il numero massimo di caratteri è 250',
    'Ref: Guest first name': 'Nome ospite',
    'Ref: Guest last name': 'Cognome ospite',
    'Ref: request for': 'Per chi è questa richiesta?',
    'Ref: request for me': 'Me',
    'Ref: request for guest': 'Qualcun altro',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Dettagli della richiesta di alloggio',
    'Ref: Timeline': 'Sequenza temporale',
    'Ref: No updates placeholder': 'Non ci sono aggiornamenti per questa richiesta',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Grazie',
    'Ref: Success text':
      'Abbiamo ricevuto la tua richiesta e ci stiamo esaminando ora. Ti torneremo presto.',
    'Ref: Go to home': 'Vai a casa',
    'Ref: Create another request': "Crea un'altra richiesta",
  },
  CreateError: {
    'Ref: Create error title': 'Oops!',
    'Ref: Create error text': 'Si è verificato un errore. Per favore riprova.',
    'Ref: Retry': 'Riprova',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Si prega di aggiornici con qualsiasi nuovo elemento sulla tua richiesta in modo da poterti supportare al meglio.',
  },
};
export default labels;
