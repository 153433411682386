import classNames from 'classnames';
import moment from 'moment';

import { ClockIcon, StatusErrorIcon } from '../../../../assets/icons';
import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import { useGetSchedulesQuery } from '../../api';
import { scheduleTypeValues } from '../../types';
import SkeletonListItem from '../SkeletonListItem';

import { SchedulesListProps } from './SchedulesList.types';

import styles from './SchedulesList.module.css';

const SchedulesList = ({
  originId = '',
  destinationId = '',
  scheduleDate,
  scheduleType,
  label,
}: SchedulesListProps) => {
  const skip = !(originId && destinationId);
  const queryDate = moment(scheduleDate).format();

  const { data, isFetching } = useGetSchedulesQuery(
    {
      originStopId: originId,
      destinationStopId: destinationId,
      ...(scheduleType === scheduleTypeValues.leave
        ? { departureFrom: queryDate }
        : { arrivalUntil: queryDate }),
    },
    { skip }
  );

  if (skip) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <ClockIcon />
        </div>
        <Title size={TITLE_SIZE.BODYSBOLD}>{label('Ref: list placeholder')}</Title>
      </div>
    );
  }

  if (isFetching) {
    return (
      <div data-testid="loading-placeholder">
        {new Array(10).fill(0).map((_, i) => (
          <div key={i} className={classNames(styles.scheduleEntry)}>
            <SkeletonListItem />
          </div>
        ))}
      </div>
    );
  }

  if (!data?.length) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <StatusErrorIcon />
        </div>
        <Title size={TITLE_SIZE.BODYSBOLD}>{label('Ref: no items placeholder')}</Title>
      </div>
    );
  }

  return (
    <div>
      {data?.map(({ departure, arrival, durationMins, itinerary }, i) => (
        <div key={i} className={classNames(styles.scheduleEntry)} data-testid="schedule-entry">
          <Title size={TITLE_SIZE.BODYMBOLD}>
            {label('Ref: Departure')} : {moment(departure).format('HH:mm')}
          </Title>
          <Title size={TITLE_SIZE.BODYSDEFAULT} className={classNames(styles.arrivals)}>
            {label('Ref: Arrivals')} : {moment(arrival).format('DD/MM/YYYY - HH:mm')}
          </Title>
          <Title size={TITLE_SIZE.BODYSDEFAULT}>{itinerary}</Title>
          <div className={classNames(styles.duration)}>
            <div className={classNames(styles.durationIcon)}>
              <ClockIcon width={16} height={16} />
            </div>
            <span>
              {durationMins} {label('Ref: minutes')}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SchedulesList;
