import { act, cleanup, screen } from '@testing-library/react';

import { renderedComponent } from '../../../../../helpers/tests/renderComponent';
import Questions from '../Questions';

const props = {
  items: [
    {
      id: 'b6cf5d40-7911-ec11-b6e6-0022481131f6',
      text: 'Are drains free from debris and blockage?',
      questionType: 1,
      choices: ['Yes', 'No', 'N/A'],
      order: 900,
      isMultiline: false,
      isRequired: true,
      isCommentRequired: false,
      maximumRating: 0,
    },
    {
      id: '0f684f1b-7911-ec11-b6e6-002248113963',
      text: 'Are there control measures in place for pest, feral animals and diseases?',
      questionType: 1,
      choices: ['Yes', 'No', 'N/A'],
      order: 700,
      isRequired: true,
      isCommentRequired: false,
      isPhotoRequired: false,
      minimumRating: 0,
      maximumRating: 0,
    },
    {
      choices: [],
      id: '3cf9211e-ff61-ec11-8943-00224812933d',
      isCommentRequired: false,
      isPhotoRequired: false,
      isRequired: true,
      maximumRating: 0,
      minimumRating: 0,
      order: 1100,
      questionType: 3,
      text: 'Please make comment on any items of non conformance or areas for improvement.',
    },
  ],
  isLoading: false,
  label: jest.fn().mockReturnValue('some label'),
};

const responses = [
  { questionId: 'b6cf5d40-7911-ec11-b6e6-0022481131f6', response: 'Yes' },
  { questionId: '0f684f1b-7911-ec11-b6e6-002248113963', response: 'No' },
  { questionId: '3cf9211e-ff61-ec11-8943-00224812933d', response: 'This is a comment!' },
];

jest.mock('react-hook-form', () => ({
  ...jest.requireActual('react-hook-form'),
  useController: () => ({ field: {}, fieldState: {}, formState: {} }),
}));

describe('Questions', () => {
  afterAll(() => cleanup());

  describe('Render', () => {
    it('should render question components', async () => {
      await act(async () => {
        renderedComponent(Questions, props);
      });

      const radioGroups = screen.queryAllByTestId('radio-group-controller');
      const singleLineText = screen.queryAllByTestId('audit-question-component-single-line-text-hook-input-field');

      expect(radioGroups.length).toEqual(6);
      expect(singleLineText.length).toEqual(1);
    });

    it("should render skeleton when it's loading", () => {
      renderedComponent(Questions, { ...props, isLoading: true });

      const skeletonQuestions = screen.queryAllByTestId('skeleton-question');
      expect(skeletonQuestions.length).toEqual(10);
    });

    it('should render questions in the correct order', async () => {
      await act(async () => {
        renderedComponent(Questions, props);
      });

      const questions = screen.queryAllByTestId('question');

      expect(questions[0].innerHTML).toContain(props.items[1].text);
      expect(questions[questions.length - 1].innerHTML).toContain(props.items[2].text);
    });

    it('should have disabled questions when its complete', async () => {
      await act(async () => {
        renderedComponent(Questions, { ...props, isComplete: true, responses });
      });

      const questions = screen.queryAllByTestId('question');

      expect(questions[0].querySelector('input')).toBeDisabled();
      expect(questions[1].querySelector('input')).toBeDisabled();
      expect(questions[2].querySelector('input')).toBeDisabled();
    });
  });
});
