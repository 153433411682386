import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { cleanup } from '@testing-library/react';

import { appInsights } from '../../../../appInsights';
import renderComponent from '../../../../helpers/tests/renderComponent';

import ModuleRedirectPage from './ModuleRedirectPage';

const mockUseLoginStatus = jest.fn();
jest.mock('../../../../helpers/hooks/useLoginStatus', () => ({
  __esModule: true,
  default: () => mockUseLoginStatus(),
}));
const mockSelector = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
}));

const mockHistory = {
  push: jest.fn(),
};
const mockLocation = jest.fn();
const mockParams = jest.fn();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
  useLocation: () => mockLocation(),
  useParams: () => mockParams(),
}));

const mockSelectSiteMutation = jest.fn();
const mockGetSiteByCode = jest.fn();
jest.mock('../../../Sites/api/api', () => ({
  useSelectSiteMutation: () => [mockSelectSiteMutation],
  useLazyGetSiteByCodeQuery: () => [mockGetSiteByCode],
}));

const mockGetOrderModuleRedirectionPath = jest.fn();
jest.mock('./ModuleRedirectPage.helper', () => ({
  getOrderModuleRedirectionPath: () => mockGetOrderModuleRedirectionPath(),
}));

jest.mock('../../../../appInsights', () => ({
  appInsights: {
    trackTrace: jest.fn(),
  },
}));
const mockGetGeographies = jest.fn();
jest.mock('../../api/geolocations/geolocationsApi', () => ({
  useLazyGetGeographiesQuery: () => [mockGetGeographies],
}));

const mockRegisterGuestUser = jest.fn();
jest.mock('../../api/guestUser/guestUserApi', () => ({
  useRegisterGuestUserMutation: () => [mockRegisterGuestUser],
}));

const mockSetGeography = jest.fn();
jest.mock('../../actions', () => {
  return {
    setGeography: () => mockSetGeography,
  };
});

const currentSiteId = 'currentSiteId';
const newSiteId = 'newSiteId';

const state = {
  Core: {
    context: {
      site: {
        id: currentSiteId,
      },
    },
  },
  Shared: { language: 'en' },
};

describe('ModuleRedirectPage', () => {
  beforeEach(() => {
    mockParams.mockReturnValue({
      geoCode: 'PL',
      siteIdentifier: currentSiteId,
      module: 'order',
    });
    mockUseLoginStatus.mockReturnValue({
      isLoggedIn: true,
    });
    mockGetSiteByCode.mockResolvedValue({
      data: {
        site: {
          id: currentSiteId,
        },
      },
    });
    mockSelector.mockReturnValue(state);
    mockLocation.mockReturnValue({ search: '' });
    mockRegisterGuestUser.mockResolvedValue({});
  });

  describe('when user is logged', () => {
    afterAll(async () => cleanup());

    describe('get site by code returns non 200 response', () => {
      beforeEach(() => {
        mockGetSiteByCode.mockResolvedValue({
          error: { status: 400 },
        });
      });

      renderComponent(ModuleRedirectPage);

      it('should log error and redirect to root', () => {
        const trackTraceMock = appInsights!.trackTrace as jest.Mock;
        expect(trackTraceMock).toHaveBeenCalled();
        expect(trackTraceMock.mock.calls[0][0]).toEqual({
          message: 'Site by code request failed with status: 400',
          severityLevel: SeverityLevel.Error,
        });
        expect(mockHistory.push.mock.calls[0][0]).toBe('/');
      });
    });

    describe('get site by code do not returns siteId', () => {
      beforeEach(() => {
        mockGetSiteByCode.mockResolvedValue({
          data: { site: { id: undefined } },
        });
      });

      renderComponent(ModuleRedirectPage);

      it('should log error and redirect to root', () => {
        const trackTraceMock = appInsights!.trackTrace as jest.Mock;
        expect(trackTraceMock).toHaveBeenCalled();
        expect(trackTraceMock.mock.calls[0][0]).toEqual({
          message: 'Site by code request did not returned site id',
          severityLevel: SeverityLevel.Error,
        });
        expect(mockHistory.push.mock.calls[0][0]).toBe('/');
      });
    });

    describe('site match', () => {
      renderComponent(ModuleRedirectPage);

      it('must not register user and must not change site', () => {
        expect(mockRegisterGuestUser).not.toHaveBeenCalled();
        expect(mockSelectSiteMutation).not.toHaveBeenCalled();
      });
    });

    describe('site not match', () => {
      beforeEach(() => {
        mockGetSiteByCode.mockResolvedValue({
          data: {
            site: {
              id: newSiteId,
            },
          },
        });
      });

      renderComponent(ModuleRedirectPage);

      it('must change site', () => {
        expect(mockSelectSiteMutation).toHaveBeenCalled();
        expect(appInsights!.trackTrace as jest.Mock).not.toHaveBeenCalled();
      });
    });

    describe('module is not supported', () => {
      beforeEach(() => {
        mockParams.mockReturnValue({
          geoCode: 'PL',
          siteIdentifier: currentSiteId,
          module: 'xxxx',
        });
      });

      renderComponent(ModuleRedirectPage);

      it('must log error and redirect to root', () => {
        const trackTraceMock = appInsights!.trackTrace as jest.Mock;
        expect(trackTraceMock).toHaveBeenCalled();
        expect(trackTraceMock.mock.calls[0][0]).toEqual({
          message: 'Not supported redirection',
          severityLevel: SeverityLevel.Error,
        });
        expect(mockHistory.push.mock.calls[0][0]).toBe('/');
      });
    });

    describe('module is "order"', () => {
      const orderModuleUrl = 'some url returned by mockGetOrderModuleRedirectionPath';
      beforeEach(() => {
        mockParams.mockReturnValue({
          geoCode: 'PL',
          siteIdentifier: currentSiteId,
          module: 'order',
        });
        mockGetOrderModuleRedirectionPath.mockReturnValue(orderModuleUrl);
      });

      renderComponent(ModuleRedirectPage);

      it('should redirect to order url', () => {
        expect(mockHistory.push).toHaveBeenCalled();
        expect(mockHistory.push.mock.calls[0][0]).toBe(orderModuleUrl);
      });
    });
  });

  describe('when user is not logged in', () => {
    beforeEach(() => {
      mockParams.mockReturnValue({
        geoCode: 'PL',
        siteIdentifier: currentSiteId,
        module: 'order',
      });
      mockUseLoginStatus.mockReturnValue({
        isLoggedIn: false,
      });
    });

    describe('get geographies returns not 200 response', () => {
      beforeEach(() => {
        mockGetGeographies.mockResolvedValue({
          isSuccess: false,
          status: 400,
        });
      });

      renderComponent(ModuleRedirectPage);

      it('must log error and redirect to root', () => {
        const trackTraceMock = appInsights!.trackTrace as jest.Mock;
        expect(trackTraceMock).toHaveBeenCalled();
        expect(trackTraceMock.mock.calls[0][0]).toEqual({
          message: 'Get geographies request failed with status: 400',
          severityLevel: SeverityLevel.Error,
        });
        expect(mockHistory.push.mock.calls[0][0]).toBe('/');
      });
    });

    describe('get geographies does not return geography matched geoCode', () => {
      beforeEach(() => {
        mockGetGeographies.mockResolvedValue({
          isSuccess: true,
          status: 200,
          data: {
            geolocations: [
              {
                name: 'Portugal',
                code: 'PT',
                regionCode: 'EU',
              },
            ],
          },
        });
      });

      renderComponent(ModuleRedirectPage);

      it('must log error and redirect to root', () => {
        const trackTraceMock = appInsights!.trackTrace as jest.Mock;
        expect(trackTraceMock).toHaveBeenCalled();
        expect(trackTraceMock.mock.calls[0][0]).toEqual({
          message: 'Geography not found for geoCode: PL',
          severityLevel: SeverityLevel.Error,
        });
        expect(mockHistory.push.mock.calls[0][0]).toBe('/');
      });
    });

    describe('geography found', () => {
      beforeEach(() => {
        mockGetGeographies.mockResolvedValue({
          isSuccess: true,
          status: 200,
          data: {
            geolocationCode: 'PL',
            geolocations: [
              {
                name: 'Poland',
                code: 'PL',
                regionCode: 'EU',
              },
              {
                name: 'Portugal',
                code: 'PT',
                regionCode: 'EU',
              },
            ],
          },
        });
      });

      renderComponent(ModuleRedirectPage);

      it('should set geography and register user', () => {
        expect(mockSetGeography).toHaveBeenCalled();
        expect(mockRegisterGuestUser).toHaveBeenCalled();
      });
    });
  });
});
