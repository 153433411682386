import dspApi from '../../../services/api/dspApi';
import mockResponse from '../mocks/api';

import {
  AccommodationRequestDetailsResponse,
  AccommodationRequestListResponse,
  GetAccommodationRequestDetailsArgs,
  GetAccommodationRequestListArgs,
  PostCreateAccommodationRequestParams,
  PostCreateCommentParams,
  AccommodationRequestSitesResponse,
} from './api.types';

export const endpoints = {
  getAuditSurvey: '/v1/accommodation-requests/:id',
};

const accommodationRequestsApi = dspApi
  .enhanceEndpoints({ addTagTypes: ['accommodation-requests'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAccommodationRequestList: build.query<
        AccommodationRequestListResponse[],
        GetAccommodationRequestListArgs
      >({
        query: ({ upcoming }) => ({
          url: `/v1/accommodationrequests`,
          method: 'get',
          params: { filtertype: upcoming ? 'future' : 'past' },
          mockData: upcoming
            ? mockResponse.getAccommodationRequestUpcomingList
            : mockResponse.getAccommodationRequestPastList,
        }),
        providesTags: ['accommodation-requests'],
      }),
      getAccommodationRequestDetails: build.query<
        AccommodationRequestDetailsResponse,
        GetAccommodationRequestDetailsArgs
      >({
        query: ({ id }) => ({
          url: `/v1/accommodationrequests/${id}`,
          method: 'get',
          params: {},
          mockData: mockResponse.getAccommodationRequestDetails,
        }),
        providesTags: (result) => [{ type: 'accommodation-requests', id: result?.id }],
      }),
      createAccommodationRequest: build.mutation<
        AccommodationRequestDetailsResponse,
        PostCreateAccommodationRequestParams
      >({
        query: ({
          siteId,
          phoneNumber,
          description,
          arrivalDateTime,
          departureDateTime,
          guestUserName,
        }) => ({
          url: '/v1/accommodationrequests',
          method: 'post',
          data: {
            siteId,
            phoneNumber,
            description,
            arrivalDateTime,
            departureDateTime,
            guestUserName,
          },
        }),
        invalidatesTags: ['accommodation-requests'],
      }),
      comment: build.mutation<{}, PostCreateCommentParams>({
        query: ({ id, comment, attachments, serviceRequestId }) => ({
          url: `/v1/accommodationrequests/${id}/comment`,
          method: 'put',
          data: {
            serviceRequestId,
            comment,
            attachments,
          },
        }),
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          const patchDetailResult = dispatch(
            accommodationRequestsApi.util.updateQueryData(
              'getAccommodationRequestDetails',
              { id },
              (draft) => {
                const comments = draft?.comments ?? [];
                return draft
                  ? {
                      ...draft,
                      comments: [
                        {
                          description: patch.comment,
                          createdBy: patch.userName,
                          isCreatedByUser: true,
                          createdOn: new Date().toISOString(),
                          attachments: patch.attachments,
                        },
                        ...comments,
                      ],
                    }
                  : draft;
              }
            )
          );
          queryFulfilled.catch(patchDetailResult.undo);
        },
      }),
      getAccommodationSitesList: build.query<AccommodationRequestSitesResponse[], {}>({
        query: () => ({
          url: `/v1/accommodationrequests/sites`,
          method: 'get',
          mockData: mockResponse.getAccommodationSitesList,
        }),
        providesTags: ['accommodation-requests'],
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetAccommodationRequestDetailsQuery,
  useGetAccommodationRequestListQuery,
  useCreateAccommodationRequestMutation,
  useCommentMutation,
  useGetAccommodationSitesListQuery,
} = accommodationRequestsApi;
export default accommodationRequestsApi;
