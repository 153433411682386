import React from 'react';

export const SidebarContext = React.createContext({
  isSideBarCollapsed: false,
  setIsSideBarCollapsed: (isCollapsed: boolean) => {},
  isNavigationVisible: false,
  setIsNavigationVisible: (visible: boolean) => {},
});
export const SidebarContextProvider = SidebarContext.Provider;
export default function useSidebarContext() {
  return React.useContext(SidebarContext);
}
