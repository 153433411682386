import React from 'react';
import { useHistory } from 'react-router';

import { pagePaths } from '../../config';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';

import { FailedProps } from './Failed.types';

import NoticePage from '@/components/templates/NoticePage/NoticePage';
import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const Failed = ({ withNavBar }: FailedProps) => {
  const { label } = useServiceRequestTranslation(__filename);
  const history = useHistory();
  return (
    <NoticePage
      withNavBar={withNavBar}
      type={NOTICEPAGE_TYPE.ERROR}
      title={label('sorry', { textTransform: 'capitalize' })}
      tabTitle={label('Ref: Tabtitle')}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('to home', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
        {
          label: label('retry', { textTransform: 'capitalize' }),
          action: () => history.push(pagePaths['Create']),
        },
      ]}
    />
  );
};

export default Failed;
