import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import momentjs from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { SETUP_OPTIONS } from '../../../../constants';
import {
  useCloseNotificationMutation,
  useLazyGetNotificationsQuery,
} from '../../api/notifications/notificationsApi';
import { getCoreConfigs } from '../../config';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

import NotificationsSection from './NotificationsSection';

import { NotificationsIllustration } from '@/assets/illustrations';
import { BellIcon } from '@/assets/index';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import { Alert } from '@/components/molecules/Alert';
import { Notification } from '@/components/molecules/Notification/Notification.types';
import WidgetPlaceholder from '@/components/molecules/WidgetPlaceholder';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import ListPage from '@/components/templates/ListPage/ListPage';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { SERVICE } from '@/modules/config';
import { GetNotificationsResponse } from '@/modules/Core/api/notifications/notificationsApi.types';

import styles from './NotificationsList.module.css';

const NotificationsList = () => {
  const { label } = useCoreTranslation(__filename);

  const [alertNotification, setAlertNotification] = useState<Notification | undefined>(undefined);
  const { showNotificationsSeeMore, showMarkAllRead } = getCoreConfigs();
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [getNotificationsLock, setGetNotificationsLock] = useState<boolean>(false);
  const timeoutRef = useRef<TimeoutId | null>(null);
  const defaultValues: GetNotificationsResponse = {
    items: [],
    total: 0,
    pageIndex: 0,
    pageSize: 0,
    unreadNotificationCount: 0,
  };
  const [notifications, setNotifications] = useState<GetNotificationsResponse>(defaultValues);
  const [closeNotification] = useCloseNotificationMutation();
  const [getNotifications] = useLazyGetNotificationsQuery();
  const shouldDisableNotifications = useIsSetupOptionEnabled(
    SETUP_OPTIONS.MY_ACCOUNT.disableNotifications,
    SERVICE.MY_ACCOUNT
  );

  const refreshNotifications = useCallback(
    async (pageIndex: number) => {
      setGetNotificationsLock(true);
      const res = await getNotifications({ pageIndex });
      if (res.data) setNotifications(res.data);
      setGetNotificationsLock(false);
    },
    [getNotifications]
  );

  useEffect(() => {
    if (!shouldDisableNotifications) {
      // Refresh interval 5 minutes
      timeoutRef.current = setInterval(() => refreshNotifications(0), 5 * 60 * 1000);
    }

    return () => {
      if (timeoutRef.current && !shouldDisableNotifications) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [refreshNotifications, shouldDisableNotifications]);

  useEffect(() => {
    if (!shouldDisableNotifications) refreshNotifications(pageIndex);
  }, [refreshNotifications, pageIndex, shouldDisableNotifications]);

  const { items, total, unreadNotificationCount } = notifications ?? defaultValues;

  const loadMore = useCallback(() => setPageIndex(pageIndex + 1), [pageIndex]);

  const clearNotifications = useCallback(() => {
    items.forEach((item) => closeNotification({ notificationId: item.id }));
  }, [items, closeNotification]);

  const handleCloseAlert = (): void => {
    setAlertNotification(undefined);
  };

  const loadMoreDisabled = items.length >= total;

  const listRenderer = (notifications: Record<string, any>[]) => {
    if (notifications.length === 0) {
      return (
        <WidgetPlaceholder
          title={label('Ref: No notifications')}
          icon={<BellIcon className={styles.iconBell} />}
        />
      );
    }

    const lastMonday = momentjs().startOf('isoWeek');
    const { earlier, thisWeek } = (notifications as unknown as Notification[]).reduce(
      (acc, curr) => {
        const isBeforeMonday = momentjs(curr.notificationDate).isBefore(lastMonday, 'day');
        const key = isBeforeMonday ? 'earlier' : 'thisWeek';
        return {
          ...acc,
          [key]: [...acc[key], curr],
        };
      },
      {
        thisWeek: [],
        earlier: [],
      }
    );

    return (
      <>
        <NotificationsSection title={label('Ref: This week')} items={thisWeek} />
        <NotificationsSection title={label('Ref: Earlier')} items={earlier} />
        {showNotificationsSeeMore && (
          <div>
            <Button
              look={BUTTON_LOOK.SECONDARY}
              onClick={loadMore}
              loading={getNotificationsLock}
              disabled={loadMoreDisabled}
              key={loadMoreDisabled ? 'disabled' : 'enabled'}
              data-testid="see-more-notifications-button"
            >
              {label('Ref: See more')}
            </Button>
          </div>
        )}
      </>
    );
  };

  const actions =
    showMarkAllRead && unreadNotificationCount !== 0 ? (
      <ActionsBar>
        <Button onClick={clearNotifications} data-testid="notification-clear">
          {label('Ref: Mark all read', { textTransform: 'capitalize' })}
        </Button>
      </ActionsBar>
    ) : null;

  const itemsWithTestingId = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        'data-testid': `notification-item-${item.id}`,
      })),
    [items]
  );

  return (
    <>
      <ListPage
        title={label('Ref: Page title')}
        items={itemsWithTestingId}
        customListRenderer={listRenderer}
        aside={
          <Column.Complementary>
            <NotificationsIllustration />
          </Column.Complementary>
        }
        actions={actions}
        data-testid="notifications-list-page"
        config={{ disableInfiniteScroll: true }}
      />
      <Alert
        isOpen={!!alertNotification}
        onDismiss={handleCloseAlert}
        header={alertNotification?.title}
        message={alertNotification?.message}
        buttons={[label('Ref: ok')]}
        data-testid="notification-alert"
      />
    </>
  );
};

export default NotificationsList;
