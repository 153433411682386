const labels = {
  All: {
    'Ref: See item': 'См. Пункт',
    'Ref: Print': 'Распечатайте квитанцию',
    'Ref: Email': 'Получите квитанцию ​​по электронной почте',
    'Ref: Receipt Modal message': 'Как бы вы хотели получить квитанцию?',
    'Ref: Loyalty Reward': 'Награда за лояльность',
    'Ref: Equation for stamps per item': '1 Покупка = 1 штамп',
    'Ref: Paid by employer': 'Сумма, выплачиваемая вашим работодателем',
    'Ref: Paid by user': 'Сумма, выплаченная вами',
  },
  ProductsList: {
    'Ref: Page title': 'Список продуктов',
    'Ref: Facility details': 'Детали объекта',
    'Ref: Search products list': 'Поиск предметов',
    'Ref: filter label: menus': 'Меню',
    'Ref: filter label: moment': 'Момент',
    'Ref: filter label: category': 'Категория',
    'Ref: all': 'Все',
    'Ref: today': 'Cегодня',
    'Ref: tomorrow': 'Завтра',
    'Ref: see more': 'Узнать больше',
    'Ref: see less': 'Увидеть меньше',
    'Ref: reset': 'Перезагрузить',
    'Ref: View in cart': 'Просмотр в тележке',
    'Ref: You have changed location': 'Вы изменили местоположение',
    'Ref: You are now at': 'Вы сейчас в',
    'Ref: Menu card': 'Карта меню',
    'Ref: Table': 'Номер таблицы',
    'Service unavailable': 'Сервис недоступен',
    'Ref: Ordering unavailable':
      'Осторожный! Заказ на этом сайте в данный момент недоступен, попробуйте позже.',
    'Ref: Filters': 'Фильтры (аллергены и многое другое)',
  },
  Order: { 'Ref: Page title': 'порядок' },
  MenuSelector: {
    'Ref: Menu Page title': 'меню',
    'Ref: today': 'Cегодня',
    'Ref: tomorrow': 'Завтра',
    'Ref: Search products list': 'Поиск предметов',
    'Ref: You have changed location': 'Вы изменили местоположение',
    'Ref: You are now at': 'Вы сейчас в',
  },
  ProductDetails: {
    'Ref: Page title': 'информация о продукте',
    'Ref: Serving Size': 'Размер сервировки',
    'Ref: Nutrition': 'Пищевая информация',
    'Ref: Nutrition adults': 'Взрослые нужно около 2000 ккал в день',
    'Ref: Nutrition Calories': 'Калории',
    'Ref: Nutrition Calcium': 'Кальций',
    'Ref: Nutrition Fat': 'Жир',
    'Ref: Nutrition Saturated Fat': 'Насыщенный жир',
    'Ref: Nutrition Carbohydrates': 'углеводы',
    'Ref: Nutrition Sugar': 'Сахар',
    'Ref: Nutrition Protein': 'белка',
    'Ref: Nutrition Sodium': 'натрий',
    'Ref: Nutrition cholesterol': 'Холестерин',
    'Ref: Nutrition dietaryFiber': 'Пищевое волокно',
    'Ref: Nutrition iron': 'Железо',
    'Ref: Nutrition potassium': 'Калий',
    'Ref: Nutrition vitaminA': 'Витамин А.',
    'Ref: Nutrition vitaminC': 'Витамин C',
    'Ref: Additives': 'Добавки',
    'Ref: Emission': 'Выбросы CO2',
    'Ref: This product generates': 'Этот продукт генерирует',
    'Ref: of CO2': 'CO2',
    'Ref: emissions, which equals a': 'выбросы, которые равны',
    'Ref: car trip': 'Автомобильная поездка',
    'Ref: Ingredients': 'Рецепт ингредиента',
    'Ref: Allergens': 'Аллергены',
    'Ref: Always there': 'Всегда там',
    'Ref: Allergens selected': 'Аллергены, выбранные на фильтре:',
    'Ref: minimum required': 'минимальный требуется',
    'Ref: you have to add at least': 'Вы должны добавить хотя бы',
    'Ref: ingredients': 'ингредиенты',
    'Ref: Ingredient': 'ингредиент',
    'Ref: Free': 'Бесплатно',
    'Ref: Based on customization': 'На основании вашей настройки',
    'Ref: Additives eggs': 'яйца',
    'Ref: Additives fish': 'рыбы',
    'Ref: Additives milk': 'молоко',
    'Ref: Additives nuts': 'орехи',
    'Ref: Additives wheat': 'пшеница',
    'Ref: Additives peanuts': 'арахис',
    'Ref: Additives soy beans': 'соевые бобы',
    'Ref: Additives sesame seeds': 'семена кунжута',
    'Ref: Review': 'Рассмотрение',
    'Ref: Per portion': 'За часть',
    'Ref: Per 100g': 'За 100 г',
    'Ref: Allergen selected': 'Аллерген выбран в поисковом фильтре',
    'Ref: Fitbit Log': 'Журнал в Fitbit',
    'Ref: age verification required on delivery': 'Проверка возраста, требуемая при доставке',
    'Ref: alcohol content notification':
      'Размещая и заказывая один из этих предметов, вы заявляете, что вам в возрасте 18 лет или старше. Злоупотребление алкоголем опасно для вашего здоровья.',
    'Ref: default': 'По умолчанию',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} заработано, если вы заказываете этот продукт',
    'Ref: Loyalty Card Description':
      '{stamp_form} будет добавлено в следующую лояльность {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Программа штампов лояльности позволяет собирать марки для вознаграждений.',
    'Ref: Loyalty Modal Description':
      'Когда вы покупаете <b> {product_name}, 1 Stamp </b> будет добавлен в <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'и',
    'Ref: Stamp': 'Печать',
    'Ref: Stamps': 'Марки',
    'Ref: Program': 'Программа',
    'Ref: Programs': 'Программы',
    'Ref: Loyalty Stamps': 'Штампы лояльности',
    'Ref: See Loyalty Stamps': 'Смотрите марки верности',
    Favorite: 'Любимый',
    'Ref: In favorites': 'Продукт уже в списке фаворитов. Нажмите, чтобы удалить его.',
    'Ref: Not in favorites': 'Продукт еще не в списке фаворитов. Нажмите, чтобы добавить его.',
    'Ref: Added to favorites': 'Продукт был добавлен в список фаворитов.',
    'Ref: Removed from favorites': 'Продукт был удален из списка фаворитов.',
    'Ref: From': 'Из',
  },
  OrderHistory: { 'Ref: Orders': 'Приказ', 'Ref: Page Title': 'мои заказы' },
  MenuTile: { 'Ref: Scan&Go': 'Сканировать и идти', 'Ref: View only': 'Просмотр только' },
  ProductTile: {
    'Ref: Allergens': 'Аллергены',
    'Ref: Presence of allergen': 'Присутствие аллергена',
    'Ref: Scan': 'Сканирование',
    'Ref: From': 'Из',
    'Ref: Already in product favorite list. Click to remove it.':
      'Уже в любимом списке продукта. Нажмите, чтобы удалить его.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'В настоящее время не добавлен в список любимых продуктов. Нажмите, чтобы добавить его.',
    'Ref: Free': 'Бесплатно',
  },
  MenusWidget: { 'Ref: no menu': 'Нет меню на данный момент', 'Ref: Modal title': 'Меню на день' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Подбирать',
    'Ref: Eat-in': 'Еда',
    'Ref: Scan&Go': 'Сканировать и идти',
    'Ref: Delivery': 'Доставка',
    'Ref: Total': 'Общий',
    'Ref: How was facility name service': 'Как прошел сервис {facility_name}?',
    'Ref: How do you rate the service': 'Как вы оцениваете обслуживание?',
  },
};
export default labels;
