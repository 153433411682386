import { screen, cleanup, fireEvent } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import ScheduleFields from '../ScheduleFields';

jest.mock('../../../../../components/molecules/Calendar', () => (props: any) => {
  return <input onChange={(e) => props.onChange(e.target.value)} data-testid="date-input" />;
});

jest.mock('../../../../../components/molecules/DateTime', () => (props: any) => {
  return (
    <input
      value={props.value}
      onChange={(e) => {
        (props.onChange && props.onChange(e.target.value)) ||
          (props.onDismiss && props.onDismiss(e.target.value));
      }}
      data-testid={props['data-testid']}
    />
  );
});

const defaultProps = {
  label: (s: string) => s,
};

describe('ScheduleFields', () => {
  afterAll(() => cleanup());

  describe('Initial render', () => {
    let leaveAtOption: HTMLElement,
      arriveAtOption: HTMLElement,
      dateSelect: HTMLElement,
      timeSelect: HTMLElement;

    renderComponent(ScheduleFields, defaultProps);

    beforeEach(() => {
      leaveAtOption = screen.getByText(/leave/);
      arriveAtOption = screen.getByText(/arrive/);
      dateSelect = screen.getByTestId('date-select');
      timeSelect = screen.getByTestId('time-select');
    });

    it('Should have schedule type options', async () => {
      expect(leaveAtOption).toBeTruthy();
      expect(arriveAtOption).toBeTruthy();
    });

    it('Should have date and time select inputs', async () => {
      expect(dateSelect).toBeTruthy();
      expect(timeSelect).toBeTruthy();
    });
  });

  describe('Changing inputs', () => {
    let arriveAtOption: HTMLElement, dateSelect: HTMLElement, timeSelect: HTMLElement;

    const scheduleDate = new Date();
    const setScheduleType = jest.fn();
    const setScheduleDate = jest.fn();

    renderComponent(ScheduleFields, {
      ...defaultProps,
      scheduleDate,
      setScheduleType,
      setScheduleDate,
    });

    it('Should set schedule type', async () => {
      arriveAtOption = screen.getByText(/arrive/);
      fireEvent.click(arriveAtOption);

      expect(setScheduleType).toHaveBeenCalledWith('arrive');
    });

    it('Should set schedule date', async () => {
      dateSelect = screen.getByTestId('date-select');
      const value = '2022-11-08';

      fireEvent.change(dateSelect, { target: { value } });

      const newDate = scheduleDate;
      newDate.setDate(new Date(value).getDate());
      newDate.setMonth(new Date(value).getMonth());
      newDate.setFullYear(new Date(value).getFullYear());
      newDate.setSeconds(0);

      expect(setScheduleDate).toHaveBeenLastCalledWith(newDate);
    });

    it('Should set schedule time', async () => {
      timeSelect = screen.getByTestId('time-select');

      const value = '01:00';
      fireEvent.change(timeSelect as HTMLElement, { target: { value } });

      const newDate = scheduleDate;
      const [hours, minutes] = value.split(':');
      newDate.setHours(parseInt(hours));
      newDate.setMinutes(parseInt(minutes));
      newDate.setSeconds(0);

      expect(setScheduleDate).toHaveBeenLastCalledWith(newDate);
    });
  });
});
