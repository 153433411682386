const labels = {
  ContactForm: { 'Ref: Page title': 'Kontakta oss', 'Ref: Category field value': 'Kontakta oss' },
  ContactList: {
    'Ref: Page title': 'Kontakter',
    'call (action to place a call)': 'ring',
    'write (action to write a message)': 'skriva',
    'write (action to write an e-mail)': 'e-post',
    'Ref: Hint message':
      'Tänk på att vissa webbplatser kanske inte har reception. Om detta är fallet, vänligen hitta en av fasta telefoner som finns tillgängliga runt siten.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Ett fel inträffade och ditt meddelande lämnades inte in. Om det här felet kvarstår, vänligen kontakta din kundtjänst.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Ditt meddelande har skickats. Tack för att du tog kontakt med oss.',
  },
};
export default labels;
