import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ShareIcon, ThumbsUpIcon } from '../../../../assets';
import { ImageDefaultNoFoodImg } from '../../../../assets/images';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import ReactionsSummary from '../../../../components/molecules/ReactionsSummary/ReactionsSummary';
import { Tile, TileAction } from '../../../../components/molecules/Tile';
import Reactions from '../../../../components/organisms/Reactions';
import { reactionsWithLabels } from '../../../../helpers/reactions.helpers';
import { contentConfig, pagePaths } from '../../config';
import { useToggleReaction } from '../../hooks';

import { ContentTileProps } from './ContentTile.types';

const ContentTile: React.FC<ContentTileProps> = ({
  item,
  siteId = '',
  languageCode = '',
  triggerShareModal,
  label,
}) => {
  const { isReactionsEnabled } = useMemo(() => contentConfig(), []);

  const urlPath = pagePaths['ContentDetails'].replace(':id', item.id);

  const history = useHistory();

  const [toggleReaction] = useToggleReaction();

  const handleOnClick = () => history.push(urlPath);

  const image = useMemo(
    () => (
      <ImageWithFallback
        data-testid="content-tile"
        imgInfo={item.imgInfo}
        imgElement={<ImageDefaultNoFoodImg />}
      />
    ),
    [item.imgInfo]
  );

  const actions = useMemo(() => {
    const returnValue: TileAction[] = [
      {
        name: 'ShareContent',
        icon: <ShareIcon />,
        onClick: () => triggerShareModal(item.title, urlPath),
        'data-testid': `content-share-${item?.id}`,
      },
    ];

    if (isReactionsEnabled) {
      returnValue.push({
        name: 'Reaction',
        'data-testid': `content-${item?.id}-reaction`,
        customAction: (
          <Reactions.List
            key={`${item.id}_${item.reactions.me}`}
            alignRight
            userReaction={item.reactions.me}
            reactions={reactionsWithLabels(label, item.reactions.reactions)}
            handleReactionClick={(reaction) => toggleReaction(reaction, item, siteId, languageCode)}
            mainButtonValues={{
              icon: ThumbsUpIcon,
              label: label('Ref: Reaction - LIKE'),
            }}
          />
        ),
      });
    }
    return returnValue;
  }, [
    isReactionsEnabled,
    triggerShareModal,
    item,
    urlPath,
    label,
    toggleReaction,
    siteId,
    languageCode,
  ]);

  return (
    <Tile
      image={image}
      title={item.title}
      description={item.details}
      onClick={handleOnClick}
      belowDescription={isReactionsEnabled ? <ReactionsSummary {...item.reactions} /> : <></>}
      actions={actions}
    />
  );
};

export default ContentTile;
