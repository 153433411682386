const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Audity',
    'Ref: status0': 'OTEVŘENO',
    'Ref: status1': 'Dokončeno',
    'Ref: status2': 'Zrušeno',
    'Ref: connected': 'Připojeno',
    'Ref: not connected': 'Nepřipojený',
    'Ref: synchronise data': 'Synchronizovat data',
    'Ref: sync success': 'Data je úspěšně synchronizována',
    'Ref: Create new': 'Vytvořit nový',
    'Ref: Audit unsynchronised': 'Nesynchronizovaný',
    'Ref: Audit synchronised': 'Synchronizované',
    'Ref: Unsynced error title': 'Došlo k chybě',
    'Ref: Unsynced error description':
      'Zdá se, že to je váš první přístup k modulu auditů. Připojte se prosím k síti a „synchronizujte“ a zahájíte audit.',
    'Ref: Search all audits': 'Všechny audity',
    'Ref: Search active audits': 'OTEVŘENO',
    'Ref: Search completed audits': 'Dokončeno',
    'Ref: sync error': 'Synchronizace selhala',
    'Ref: sync error details': 'Opakujte se znovu připojit',
    'Ref: no search audit results':
      'Bohužel neexistují žádné audity odpovídající vašemu vyhledávání',
    'Ref: no audit results': 'Nemáte žádné k dispozici audity, vytvořte nové pro začátek',
    'Ref: Search canceled audits': 'Zrušeno',
    'Ref: Audit cancelled successfully': 'Audit úspěšně zrušen',
    'Ref: Cancel audit invite': 'zrušení',
    'Ref: Error audit cancel': 'Audit se nepodařilo zrušit',
    'Ref: Cancel audit confirmation': 'Jste si jisti, že chcete audit zrušit?',
    'Ref: Confirm': 'Potvrdit',
    'Ref: Cancel audit': 'Zrušit audit',
    'Ref: Change the location': 'Změnit umístění',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Podrobnosti o auditu',
    'Ref: questions': 'otázky',
    'Ref: question': 'otázka',
    'Ref: Additional Comments': 'Popis',
    'Ref: Not found title': 'Jejda!',
    'Ref: Not found text': 'Audit, ke kterému se snažíte získat přístup, neexistuje.',
    'Ref: Back to Audit list': 'Zpět na seznam auditů',
    'Ref: Success title': 'Děkuji!',
    'Ref: Success text': 'Dokončili jste audit',
    'Ref: submit': 'Předložit',
    'Ref: add attachment': 'Přidejte přílohu',
    'Ref: Unsupported elements':
      'Je nám líto, zdá se, že tento audit obsahuje obsah, který aplikace ještě nepodporuje. Budeme na tom pracovat.',
    'Ref: form error': 'Ne všechny otázky byly plně zodpovězeny',
    'Ref: form complete': 'Dokončeno od té doby',
    'Ref: attachment preview error': 'Musíte být online, abyste viděli tuto přílohu',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Vytvořit nový',
    'Ref: Location': 'Umístění',
    'Ref: Audit Stream Type': 'Proud',
    'Ref: Audit': 'Audit',
    'Ref: Asset': 'Aktivum',
    'Ref: Asset Name': 'Název aktiv',
    'Ref: Is joint audit?': 'Je společný audit?',
    'Ref: Who was present?': 'Kdo byl přítomen?',
    'Ref: submit': 'Předložit',
    'Ref: Select the Location': 'Vyberte umístění',
    'Ref: Select the Audit Stream Type': 'Vyberte typ proudu auditu',
    'Ref: Select the Audit': 'Vyberte audit',
    'Ref: Select the Asset': 'Vyberte aktivum',
    'Ref: form error': 'Ne všechna požadovaná pole byla plně zadána',
    'Ref: Audit error': 'Připojený audit',
    'Ref: Joint Audit Participants error': 'Společní účastníci auditu',
    'Ref: Audit created': 'Vytvořen audit',
    'Ref: Audit field length error': 'Délka by neměla překročit 100 znaků',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Změnit umístění',
    'Ref: Location changed': 'Poloha se změnila',
    'Ref: Location': 'Umístění',
    'Ref: Select the Location': 'Vyberte umístění',
    'Ref: Asset': 'Aktívum',
    'Ref: Select the Asset': 'Vyberte aktivum',
    'Ref: Asset Name': 'Název aktiv',
    'Ref: submit': 'Předložit',
    'Ref: Audit field length error': 'Délka by neměla překročit 100 znaků',
  },
};
export default labels;
