import { DefaultComponentWithoutChildrenProps } from 'src/types';

export enum TimePeriodEnum {
  AM = 'AM',
  PM = 'PM',
}

export type TimePeriodProps = {
  allowAmSelection: boolean;
  allowPmSelection: boolean;
  timePeriod: TimePeriodEnum;
  onTimePeriodChanged: (timePeriod: TimePeriodEnum) => void;
} & DefaultComponentWithoutChildrenProps;
