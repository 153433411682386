export enum UserSteps {
  HomePage = 'HomePageViewed',
  MenuList = 'MenuListPageViewed',
  ProductList = 'ProductListPageViewed',
  FiltersApplied = 'FiltersApplied',
  ProductDetails = 'ProductDetailsPageViewed',
  ShareModalOpened = 'ShareModalOpened',
  FacilityDetailsPageOpened = 'FacilityDetailsPageOpened',
  ReviewPageRedirectionTriggered = 'ReviewPageRedirectionTriggered',
  OrderPageRedirectionTriggered = 'OrderPageRedirectionTriggered',
  OpenedFacilityModal = 'OpenedFacilityModal',
  OpenedInfoModal = 'OpenedInfoModal',
  FreeCoffeePage = 'FreeCoffeePage',
  FreeCoffeeRedeemed = 'FreeCoffeeRedeemed',
  UserScannedQrCode = 'UserScannedQrCode',
  FreeCoffeeCodeScannedSuccessfully = 'FreeCoffeeCodeScannedSuccessfully',
}
