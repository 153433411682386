const labels = {
  All: {
    'starts at': 'starts at',
    'ends at': 'ends at',
    location: 'location',
    'coming date': 'coming date',
  },
  EventDetails: {
    details: 'details',
    'Ref: Page title': 'Ref: Page title',
    'Event dates': 'Event dates',
    'See online:': 'See online:',
    'See all dates': 'See all dates',
    'Remove from my selection': 'Remove from my selection',
    'Add to my selection': 'Add to my selection',
    'Ref: explain calendar usage': 'Ref: explain calendar usage',
    'Ref: Favorite': 'Ref: Favorite',
    'Ref:Feedback': 'Ref:Feedback',
    'Ref: Event site different title': 'Ref: Event site different title',
    'Ref: Event site different description': 'Ref: Event site different description',
    'Ref: See all dates': 'Ref: See all dates',
    'Ref: From': 'Ref: From',
    'Ref: to': 'Ref: to',
    'Ref: Coming date': 'Ref: Coming date',
    'Ref: Add to calendar': 'Ref: Add to calendar',
  },
  EventsList: {
    'Ref: Page title': 'Ref: Page title',
    'All dates': 'All dates',
    'All events': 'All events',
    'Ref: Search': 'Ref: Search',
    'My selection': 'My selection',
    'filter label: date': 'filter label: date',
    'filter label: coming events': 'filter label: coming events',
    'filter label: past events': 'filter label: past events',
    'filter label: interest': 'filter label: interest',
  },
  Widget: {
    'Ref: Carousel title': 'Ref: Carousel title',
    'No info yet, come back later': 'No info yet, come back later',
  },
};
export default labels;
