const mockResponse = {
  getStays: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        stays: [
          {
            id: '11874a17-f975-4786-bdf5-544a0053eba5',
            reservationNumber: '112153',
            siteId: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
            siteName: 'Amsterdam Headquarter',
            arrivalDateLocal: '2023-02-10T00:00:00',
            departureDateLocal: '2023-03-02T00:00:00',
            status: 'Confirmed',
          },
          {
            id: '29ae33f8-cd1f-4381-b213-f9229a852898',
            reservationNumber: '282879',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: '844a3c78-5183-41be-a15b-ee47ae8d3ebe',
            reservationNumber: '282887',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: '99b249b8-0b35-49ae-a5e0-4930ce556cb0',
            reservationNumber: '282881',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: '6b80602c-7869-4159-a88e-61fedc46be44',
            reservationNumber: '282884',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: '53b8fb96-3357-478e-935f-ba876be2b8d9',
            reservationNumber: '282880',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: '357a0250-0682-4b18-abfb-22f3d1dff165',
            reservationNumber: '282888',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: 'fc62637b-04fa-44a6-b07a-27f397c1eed8',
            reservationNumber: '282885',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: 'bc18fa7e-a84e-4266-96df-920f0aaee247',
            reservationNumber: '282883',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: 'bcf27872-861f-4ebe-b2de-759b8a3ffa68',
            reservationNumber: '282882',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
          {
            id: '648bf18a-f4e4-4fab-8e86-76e3f259c401',
            reservationNumber: '282886',
            siteId: '8f8a561e-c236-4f0a-aa96-05be70c25432',
            siteName: 'Cedar Valley Lodge',
            arrivalDateLocal: '2023-02-02T00:00:00',
            departureDateLocal: '2023-03-29T00:00:00',
            status: 'CheckingIn',
          },
        ],
      }),
  },
  getStayDetails: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        id: '11874a17-f975-4786-bdf5-544a0053ebc6',
        reservationNumber: '348752',
        siteId: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
        siteName: 'Cedar Valley Lodge',
        arrivalDateLocal: '2023-10-26T00:00:00',
        departureDateLocal: '2023-10-31T00:00:00',
        status: 'InHouse',
        room: '02-139',
        customProperties: {
          shift: 'Night',
        },
        action: [
          {
            type: 'CheckOut',
            isActive: true,
            msgCode: 'CheckOut_1',
            msgParams: {
              lodgeName: 'Cedar Valley Lodge',
              resNumber: '348802',
            },
            isUserRedirected: false,
            url: 'https://proxy.uat.api.ifm.sodexonet.com/api/mobile/v3/stays/c01c762b-0b33-4ed7-a4eb-544a0053ebc6/checkout',
          },
          // {
          //   type: 'CheckIn', // Sodexo integration knows that this is an action handled through redirection
          //   isActive: true,
          //   msgCode: 'CheckIn_1',
          //   msgParams: { lodgeName: 'Cedar Valley Lodge', resNumber: '112153' },
          //   isUserRedirected: true,
          //   url: 'https://cedarvalleylodge.innfinity.com/train/innfinitewf/#/checkin/cafc5fae-61dd-47af-afa9-a791554a2bbb?from=MyWayEmbedded&lang=en-GB',
          // },
          // {
          //   type: 'LuggagePickUp', // Sodexo integration knows that this is an action handled through redirection
          //   isActive: false,
          //   msgCode: 'CheckedIn_1',
          //   msgParams: { lodgeName: 'Cedar Valley Lodge', roomNumber: '256' },
          //   isUserRedirected: true,
          //   url: '',
          // },
        ],
      }),
  },
  postCustomAction: {
    ok: true,
    status: 200,
    json: async () => {
      await new Promise((r) => setTimeout(r, 2000));
      Promise.resolve();
    },
  },
};

export default mockResponse;
