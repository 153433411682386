import { IonItemGroup, IonItemDivider } from '@ionic/react';
import classNames from 'classnames';
import { useRef, useMemo } from 'react';

import { ChevronUpIcon } from '../../../assets';
import { ChevronDownIcon } from '../../../assets/icons';

import { SimpleTileList } from './SimpleTileList';
import { SectionProps } from './TilesList.types';

import styles from './TilesList.module.css';

import { ItemWithTestingProps } from 'src/types';

export const Section = <T extends ItemWithTestingProps>({
  section,
  reference,
  sectionItems,
  parentSectionId = null,
  expanded = false,
  emptyBottomSpace = false,
  ...restProps
}: SectionProps<T>) => {
  const {
    onExpand,
    config,
    twoTilesColumns = false,
    longTileTextVisible,
    itemRenderer,
    tileVariant,
  } = restProps;
  const sectionRef = useRef<HTMLSpanElement>(null);
  const sectionContent = useMemo(() => {
    if (expanded || config?.collapsibleSections === false) {
      if (section?.sections?.length) {
        return section.sections.map((subSection) => (
          <Section
            {...restProps}
            section={subSection}
            sectionItems={[...subSection.filter(sectionItems)]}
            parentSectionId={section.key}
            expanded={true}
          />
        ));
      } else {
        return (
          <>
            {section.beforeItems ? (
              <div className={styles.sectionTop}>{section.beforeItems}</div>
            ) : null}

            <SimpleTileList
              items={sectionItems}
              twoTilesColumns={twoTilesColumns}
              longTileTextVisible={longTileTextVisible}
              itemRenderer={itemRenderer}
              tileVariant={tileVariant}
            />

            {section.afterItems ? (
              <div className={styles.sectionBottom}>{section.afterItems}</div>
            ) : null}

            {emptyBottomSpace ? <div className={styles.emptyBottomSpace} /> : null}
          </>
        );
      }
    }

    return null;
  }, [
    config?.collapsibleSections,
    expanded,
    section.sections,
    section.key,
    section.beforeItems,
    section.afterItems,
    restProps,
    sectionItems,
    twoTilesColumns,
    longTileTextVisible,
    itemRenderer,
    tileVariant,
    emptyBottomSpace,
  ]);

  const { expandCollapse, onClickAction } = useMemo(() => {
    if (config?.collapsibleSections) {
      if (expanded) {
        return {
          expandCollapse: (
            <span slot="end" className={styles.expandCollapse} data-cy="section-expanded-icon">
              <ChevronUpIcon aria-label="collapse" className={styles.expandCollapseIcon} />
            </span>
          ),
          onClickAction: () => onExpand(section.key, parentSectionId, false),
        };
      } else {
        return {
          expandCollapse: (
            <span
              slot="end"
              className={styles.expandCollapse}
              onClick={() => {
                onExpand(section.key, parentSectionId, true, section.onExpand);
              }}
              role="button"
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  onExpand(section.key, parentSectionId, true, section.onExpand);
                }
              }}
            >
              <ChevronDownIcon
                aria-label="expand"
                className={styles.expandCollapseIcon}
                data-cy="section-collapsed-icon"
              />
            </span>
          ),
          onClickAction: () => {
            onExpand(section.key, parentSectionId, true, section.onExpand);
            sectionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          },
        };
      }
    }

    return { expandCollapse: false, onClickAction: undefined };
  }, [
    config?.collapsibleSections,
    expanded,
    onExpand,
    parentSectionId,
    section.key,
    section.onExpand,
  ]);

  if (!sectionItems.length) return null;

  return (
    <IonItemGroup
      key={parentSectionId ? `${parentSectionId}-${section.key}` : section.title}
      className={styles.tilesListSection}
    >
      {config?.collapsibleSections === false ? (
        <div
          className={classNames({
            [styles.section]: true,
            [styles.newSubSection]: parentSectionId !== null,
          })}
        >
          <span ref={reference} slot="start" id={'section_title_' + section.key}>
            {section?.icon && <span className={styles.sectionTitleIcon}>{section.icon}</span>}
            {section.title} {config?.displaySectionItemNumber && `• ${sectionItems.length}`}
          </span>
        </div>
      ) : (
        <IonItemDivider
          className={classNames({
            [styles.tilesListSectionHeader]: true,
            'ion-justify-content-between': true,
            [styles.subSection]: parentSectionId !== null,
            [styles.mainSection]: parentSectionId === null,
          })}
          sticky={true}
          onClick={onClickAction}
        >
          <span
            ref={sectionRef}
            slot="start"
            id={'section_title_' + section.key}
            className={classNames({
              bodySBold: true,
              [styles.title]: true,
              [styles.subSectionTitle]: parentSectionId !== null,
            })}
          >
            {section?.icon && <span className={styles.sectionTitleIcon}>{section.icon}</span>}
            {section.title} {config?.displaySectionItemNumber && `• ${sectionItems.length}`}
          </span>
          {expandCollapse}
        </IonItemDivider>
      )}
      {sectionContent}
    </IonItemGroup>
  );
};
