const labels = {
  All: {
    'starts at': 'zaczyna się o',
    'ends at': 'kończy się o',
    location: 'Lokalizacja',
    'coming date': 'Nadchodząca data',
    'Ref: Currently event not preferred. Click to make preferred':
      'Obecnie wydarzenie nie jest na liście preferowanych. Kliknij, aby je dodać do listy preferowanych',
    'Ref: Currently event preferred. Click to make not preferred':
      'Wydarzenie dodane do listy preferowanych. Kliknij, aby usunąć je z listy preferowanych',
  },
  EventDetails: {
    details: 'Detale',
    'Ref: Page title': 'Szczegóły wydarzenia',
    'Event dates': 'Daty wydarzenia',
    'See online:': 'Zobacz online:',
    'See all dates': 'Zobacz wszystkie daty',
    'Remove from my selection': 'Usuń z mojego wyboru',
    'Add to my selection': 'Dodaj do mojego preferowanego',
    'Ref: explain calendar usage':
      'Terminy wydarzeń są zaznaczone w poniższym kalendarzu. Nie przegap wydarzeń, które lubisz! Kliknij datę wydarzenia w kalendarzu, aby pobrać zaproszenie. Następnie otwórz pobrany plik, aby dodać wydarzenie do swojego kalendarza.',
    'Ref: Favorite': 'Ulubiony',
    'Ref:Feedback': 'Informacja zwrotna',
    'Ref: Event site different title': 'To wydarzenie nie należy do Twojej obecnej lokalizacji',
    'Ref: Event site different description':
      'Przeglądasz wydarzenie, które nie należy do Twojej obecnej lokalizacji. Nie będziesz mógł go zobaczyć na liście wydarzeń.',
    'Ref: See all dates': 'Zobacz wszystkie daty',
    'Ref: From': 'Z',
    'Ref: to': 'Do',
    'Ref: Coming date': 'Nadchodząca data',
    'Ref: Add to calendar': 'Dodaj do kalendarza',
  },
  EventsList: {
    'Ref: Page title': 'Wydarzenia',
    'All dates': 'Wszystkie daty',
    'All events': 'Wszystkie zdarzenia',
    'Ref: Search': 'Szukaj',
    'My selection': 'Mój preferowany',
    'filter label: date': 'Data',
    'filter label: coming events': 'nadchodzące wydarzenia',
    'filter label: past events': 'przeszłe wydarzenia',
    'filter label: interest': 'pierwszeństwo',
  },
  Widget: {
    'Ref: Carousel title': 'Wydarzenia',
    'No info yet, come back later': 'Nie ma jeszcze informacji, wróć później 😉',
  },
};
export default labels;
