const labels = {
  All: {
    'Ref: See item': "Vedi l'oggetto",
    'Ref: Print': 'Stampa la ricevuta',
    'Ref: Email': 'Ricevi la ricevuta via e-mail',
    'Ref: Receipt Modal message': 'Come vorresti ottenere la ricevuta?',
    'Ref: Loyalty Reward': 'Ricompensa di lealtà',
    'Ref: Equation for stamps per item': '1 acquisto = 1 timbro',
    'Ref: Paid by employer': "L'importo pagato dal tuo datore di lavoro",
    'Ref: Paid by user': "L'importo pagato da te",
  },
  ProductsList: {
    'Ref: Page title': 'Elenco dei prodotti',
    'Ref: Facility details': 'Dettagli della struttura',
    'Ref: Search products list': 'Articoli di ricerca',
    'Ref: filter label: menus': 'Menù',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoria',
    'Ref: all': 'Tutti',
    'Ref: today': 'Oggi',
    'Ref: tomorrow': 'Domani',
    'Ref: see more': 'Vedi altro',
    'Ref: see less': 'Vedere di meno',
    'Ref: reset': 'Ripristina',
    'Ref: View in cart': 'Visualizza nel carrello',
    'Ref: You have changed location': 'Hai cambiato posizione',
    'Ref: You are now at': 'Ora sei a',
    'Ref: Menu card': 'Menu',
    'Ref: Table': 'Numero della tabella',
    'Service unavailable': 'Servizio non disponibile',
    'Ref: Ordering unavailable':
      "Attento! L'ordinazione in questo sito non è disponibile al momento, prova più tardi.",
    'Ref: Filters': 'Filtri (allergeni e altro)',
  },
  Order: { 'Ref: Page title': 'Ordine' },
  MenuSelector: {
    'Ref: Menu Page title': 'menu',
    'Ref: today': 'Oggi',
    'Ref: tomorrow': 'Domani',
    'Ref: Search products list': 'Articoli di ricerca',
    'Ref: You have changed location': 'Hai cambiato posizione',
    'Ref: You are now at': 'Ora sei a',
  },
  ProductDetails: {
    'Ref: Page title': 'Dettagli del prodotto',
    'Ref: Serving Size': 'Porzioni',
    'Ref: Nutrition': 'Informazioni nutrizionali',
    'Ref: Nutrition adults': 'Gli adulti hanno bisogno di circa 2000 kcal al giorno',
    'Ref: Nutrition Calories': 'calorie',
    'Ref: Nutrition Calcium': 'Calcio',
    'Ref: Nutrition Fat': 'Grasso',
    'Ref: Nutrition Saturated Fat': 'Grassi saturi',
    'Ref: Nutrition Carbohydrates': 'carboidrati',
    'Ref: Nutrition Sugar': 'Zucchero',
    'Ref: Nutrition Protein': 'Proteina',
    'Ref: Nutrition Sodium': 'Sodio',
    'Ref: Nutrition cholesterol': 'Colesterolo',
    'Ref: Nutrition dietaryFiber': 'Fibra alimentare',
    'Ref: Nutrition iron': 'Ferro da stiro',
    'Ref: Nutrition potassium': 'Potassio',
    'Ref: Nutrition vitaminA': 'Vitamina A.',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Additives': 'Additivi',
    'Ref: Emission': 'emissioni di CO2',
    'Ref: This product generates': 'Questo prodotto genera',
    'Ref: of CO2': 'di CO2.',
    'Ref: emissions, which equals a': 'emissioni, che equivalgono a a',
    'Ref: car trip': "viaggio d'auto",
    'Ref: Ingredients': 'Ingredienti della ricetta',
    'Ref: Allergens': 'Allergeni',
    'Ref: Always there': 'Sempre lì',
    'Ref: Allergens selected': 'Allergeni selezionati sul filtro:',
    'Ref: minimum required': 'minimo richiesto',
    'Ref: you have to add at least': 'Devi aggiungere almeno',
    'Ref: ingredients': 'ingredienti',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Libero',
    'Ref: Based on customization': 'Basato sulla tua personalizzazione',
    'Ref: Additives eggs': 'uova',
    'Ref: Additives fish': 'pesce',
    'Ref: Additives milk': 'latte',
    'Ref: Additives nuts': 'noccioline',
    'Ref: Additives wheat': 'Grano',
    'Ref: Additives peanuts': 'arachidi',
    'Ref: Additives soy beans': 'fagioli di soia',
    'Ref: Additives sesame seeds': 'semi di sesamo',
    'Ref: Review': 'Revisione',
    'Ref: Per portion': 'Per porzione',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Allergen selected': 'Allergene selezionato sul filtro di ricerca',
    'Ref: Fitbit Log': 'Registro a fitbit',
    'Ref: age verification required on delivery': "Verifica dell'età richiesta alla consegna",
    'Ref: alcohol content notification':
      "Posizionando e ordina per uno di questi articoli, dichiari di avere 18 anni o più. L'abuso di alcol è pericoloso per la tua salute.",
    'Ref: default': 'Predefinito',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} guadagnato se ordini questo prodotto',
    'Ref: Loyalty Card Description':
      'Il {stamp_form} verrà aggiunto alla seguente lealtà {program_form}: <b> {zzz} </b>',
    'Ref: About Loyalty stamps':
      'Il programma di fedeltà ti consente di raccogliere francobolli per premi.',
    'Ref: Loyalty Modal Description':
      'Quando acquisti <b> {product_name}, 1 timbro </b> verrà aggiunto al <b> {zzz} </b> {program_form}',
    'Ref: and': 'E',
    'Ref: Stamp': 'Francobollo',
    'Ref: Stamps': 'Francobolli',
    'Ref: Program': 'Programma',
    'Ref: Programs': 'Programmi',
    'Ref: Loyalty Stamps': 'Francobolli fedeltà',
    'Ref: See Loyalty Stamps': 'Vedi francobolli fedeltà',
    Favorite: 'Preferito',
    'Ref: In favorites': "Il prodotto è già nell'elenco dei preferiti. Fare clic per rimuoverlo.",
    'Ref: Not in favorites':
      "Il prodotto non è ancora nell'elenco dei preferiti. Fare clic per aggiungerlo.",
    'Ref: Added to favorites': "Il prodotto è stato aggiunto all'elenco dei preferiti.",
    'Ref: Removed from favorites': "Il prodotto è stato rimosso dall'elenco dei preferiti.",
    'Ref: From': 'Da',
  },
  OrderHistory: { 'Ref: Orders': 'Ordini', 'Ref: Page Title': 'i miei ordini' },
  MenuTile: { 'Ref: Scan&Go': 'Scansionare e andare', 'Ref: View only': 'Visualizzare solamente' },
  ProductTile: {
    'Ref: Allergens': 'Allergeni',
    'Ref: Presence of allergen': 'Presenza di allergene',
    'Ref: Scan': 'Scansione',
    'Ref: From': 'Da',
    'Ref: Already in product favorite list. Click to remove it.':
      "Già nell'elenco preferito del prodotto. Fare clic per rimuoverlo.",
    'Ref: Currently not added to product favorite list. Click to add it.':
      "Attualmente non aggiunto all'elenco preferito del prodotto. Fare clic per aggiungerlo.",
    'Ref: Free': 'Gratuito',
  },
  MenusWidget: {
    'Ref: no menu': 'Nessun menu per il momento',
    'Ref: Modal title': 'Menu per la giornata',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Raccogliere',
    'Ref: Eat-in': 'Mangiare in',
    'Ref: Scan&Go': 'Scansionare e andare',
    'Ref: Delivery': 'Consegna',
    'Ref: Total': 'Totale',
    'Ref: How was facility name service': 'Come è stato il servizio {facility_name}?',
    'Ref: How do you rate the service': 'Come valuti il ​​servizio?',
  },
};
export default labels;
