const labels = {
  SiteDetails: {
    'Select site': 'Выберите этот сайт',
    'Ref: Page title': 'Детали сайта',
    'Ref: Skip map': 'Пропустить основной контент',
    'Ref: Unavailable site': 'Предварительный просмотр сайта не поддается вашему пользователю',
    'Ref: Sorry, no description yet': 'Извините, у нас еще нет описания.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Координаты сайта не найдены',
    'Ref: Page title': 'Места',
    'Recently visited': 'Недавно посетил',
    'All other sites': 'Все остальные сайты',
    'Sites in your country': 'Сайты в вашей стране',
    'Search results': 'Результаты поиска',
    'Select site': 'Выбор сайта',
    'Ref: Select site with QR Scanner': 'Выберите сайт с сканером QR',
    'Ref: Select site with code': 'Выберите сайт с кодом',
    'Ref: Validate': 'Проверять',
    'Ref: Enter site code': 'Введите код сайта',
    'Ref: Scanner error header': 'Сайт не найден',
    'Ref: Generic error': 'Что-то пошло не так',
    'Ref: Change site': 'Изменить сайт',
    'Ref: Change site description':
      'В тележке есть предметы! Изменение сайта приведет к очистке корзины.',
    'Ref: Cancel': 'Отмена',
    'Ref: Not found': 'Этот QR -код не был признан',
    'Ref: Unauthorized error': 'Несанкционированный запрос из -за уровня ограничения сайта',
    'Ref: Forbidden':
      'Извините, у вас нет правильных разрешений для доступа к этому сайту. Чтобы организовать доступ, пожалуйста, свяжитесь с сервисным центром Pilbara по телефону 1800 992 777',
    "Ref: We didn't find the site.": 'Мы не нашли сайт.',
    'Ref: We recommend some solutions:': 'Мы рекомендуем некоторые решения:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Попробуйте добавить свой сайт с помощью QR -сканера или с помощью кнопок ниже',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Войдите и войдите в систему, выбрав правильную страну',
    'Ref: The currently selected country is:': 'В настоящее время выбранная страна:',
  },
  SwitchSite: { 'Not on {site name}?': 'Не на{site name}?' },
  SiteTile: { Details: 'Подробности', Select: 'Выбирать' },
};
export default labels;
