import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { ISite } from '../../modules/Sites/types/sites.types';
import { PromiseResultWithData } from '../../types';
import { State } from '../../types/state.types';

const useResourcePermissions = (
  data: PromiseResultWithData<any>
): {
  onDifferentSite: boolean;
  notFound: boolean;
  permissionDenied: string;
} => {
  const siteId = useSelector((state: State) => state.Core.context.site?.id || undefined);

  const result = data.responseData || {};

  const sites = get(result, 'sites', []) ?? [];
  const onDifferentSite =
    sites.length > 0 && sites[0].id
      ? !sites.find((site: ISite) => site.id === siteId)
      : typeof sites[0] == 'string' && !sites.find((site: String) => site === siteId);

  return {
    notFound: data.responseStatus === 400,
    onDifferentSite: onDifferentSite,
    permissionDenied: result?.error?.code || data.responseStatus === 403,
  };
};

export default useResourcePermissions;
