import { isPlatform } from '@ionic/react';
import classNames from 'classnames';

import { SideBarHeaderProps } from './SideBarHeader.types';

import styles from './SideBarHeader.module.css';
const SideBarHeader = ({
  collapsed,
  title,
  cornerAction,
  toggleAriaLabel,
  actionIcon,
  isLoading,
}: SideBarHeaderProps) => {
  const isIos = isPlatform('ios');
  return (
    <div
      className={classNames(styles.sideBarHeader, collapsed ? styles.collapsed : styles.full, {
        [styles.iosStyle]: isIos,
      })}
    >
      {!isLoading && (
        <>
          {!collapsed && (
            <div>
              <span>{title}</span>
            </div>
          )}
          <button
            onClick={cornerAction}
            className={styles.toggleButton}
            data-testid="sidebar-toggle-button"
            aria-label={toggleAriaLabel}
            aria-expanded={!collapsed}
          >
            {actionIcon}
          </button>
        </>
      )}
    </div>
  );
};

export default SideBarHeader;
