const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Seu pedido',
    'Ref: Summary': 'Resumo',
    'Ref: Request category': 'Categoria de solicitação',
    'Ref: Person affected': 'A pessoa afetada por este pedido',
    'Ref: Your phone number': 'Número de telefone',
    'Ref: Description': 'Descrição',
    'Ref: Service Requests': 'Solicitações de serviço',
    'Ref: Request Service': 'Serviço de solicitação',
    'Ref: Success title': 'Seu pedido foi criado',
    'Ref: Error title': 'Opa!',
    'Ref: Error subtitle': 'Ocorreu um erro, por favor, tente novamente.',
    'Ref: Try again': 'Tente novamente',
    'Ref: you pronoun': '(tu)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Este é o seu hub de suporte no local de trabalho',
    'Ref: Tutorial: tabname': 'solicitações de',
    'Ref: Tutorial: body':
      'Estamos aqui para ajudá-lo. Por favor, registre uma solicitação por entrada.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Você está solicitando serviço no site {siteName}.',
    'Ref: Building': 'Prédio',
    'Ref: Room': 'Sala',
    'Ref: Description help text': 'Quanto mais detalhado você for, melhor poderá apoiá -lo.',
    'Ref: Next': 'Próximo',
    'Ref: Your request': 'Seu pedido',
    'Ref: PhoneNumber error msg': 'O número de telefone não é válido',
    'Ref: Type at least 3 characters to search': 'Digite pelo menos 3 caracteres para pesquisar',
    'Ref: No affected person results': 'Nenhuma pessoa que corresponda aos critérios solicitados',
    'Ref: Required': '* Obrigatório',
  },
  Summary: {
    'Ref: Location': 'Localização',
    'Ref: Attachment': 'Anexos',
    'Ref: Submit': 'Enviar',
    'Ref: No affected person results': 'Nenhuma pessoa que corresponda aos critérios solicitados',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Requisiçao de serviço',
    'Ref: createdOn': 'Criado em',
    'Ref: summary': 'Resumo',
    'Ref: category': 'Categoria de solicitação',
    'Ref: request raised for': 'A pessoa afetada por este pedido',
    'Ref: phoneNumber': 'Número de telefone',
    'Ref: location': 'Número de telefone',
    'Ref: description': 'Descrição',
    'Ref: attachments': 'Anexos',
  },
};
export default labels;
