const labels = {
  SiteDetails: {
    'Select site': 'Sélectionnez ce site',
    'Ref: Page title': 'Détails du site',
    'Ref: Skip map': 'Passez au contenu principal',
    'Ref: Unavailable site': 'Aperçu du site inévitable pour votre utilisateur',
    'Ref: Sorry, no description yet': "Désolé, nous n'avons pas encore de description.",
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Les coordonnées du site introuvables',
    'Ref: Page title': 'Des sites',
    'Recently visited': 'Récemment visité',
    'All other sites': 'Tous les autres sites',
    'Sites in your country': 'Sites de votre pays',
    'Search results': 'Résultats de recherche',
    'Select site': 'Sélectionner un site',
    'Ref: Select site with QR Scanner': 'Sélectionnez le site avec le scanner QR',
    'Ref: Select site with code': 'Sélectionnez le site avec le code',
    'Ref: Validate': 'Valider',
    'Ref: Enter site code': 'Entrez le code du site',
    'Ref: Scanner error header': 'Site introuvable',
    'Ref: Generic error': "Quelque chose s'est mal passé",
    'Ref: Change site': 'Changer le site',
    'Ref: Change site description':
      'Il y a des articles dans le chariot! La modification du site entraînera le nettoyage du panier.',
    'Ref: Cancel': 'Annuler',
    'Ref: Not found': "Ce code QR n'a pas été reconnu",
    'Ref: Unauthorized error': 'Demande non autorisée due au niveau de restriction du site',
    'Ref: Forbidden':
      "Désolé, vous n'avez pas les bonnes autorisations pour accéder à ce site. Pour organiser l'accès, veuillez contacter le centre de service Pilbara au 1800 992 777",
    "Ref: We didn't find the site.": "Nous n'avons pas trouvé le site.",
    'Ref: We recommend some solutions:': 'Nous recommandons certaines solutions:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      "1. Essayez d'ajouter votre site avec le scanner QR ou à l'aide de boutons ci-dessous",
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Connectez-vous et reconnectez-vous en sélectionnant le bon pays',
    'Ref: The currently selected country is:': 'Le pays actuellement sélectionné est:',
  },
  SwitchSite: { 'Not on {site name}?': 'Pas sur {site name}?' },
  SiteTile: { Details: 'Détails', Select: 'Sélectionner' },
};
export default labels;
