import { serviceTypes, modulesConfig } from '../config';

export const moduleId = 'Menus';
export const cacheVersion = '0.5';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  ProductsList: modulePath + '/:facilityId/products',
  ProductDetails: modulePath + '/:facilityId/:id/:date',
};

export const selectedProductListFiltersCacheKey = `${moduleId}_productList_filters`;
