const labels = {
  StaysList: {
    'Ref: Page title': 'การเข้าพัก',
    'Ref: Past': 'อดีต',
    'Current and future stays': 'การเข้าพักในปัจจุบันและอนาคต',
    'filter label: period': 'ระยะเวลา',
    'Ref: Search': 'ค้นหา',
  },
  StayDetails: {
    'Ref: Page title': 'รายละเอียดการเข้าพักของคุณ',
    'Arrival date': 'วันที่มาถึง ',
    'Departure date': 'วันเดินทาง',
    'Room number': 'ห้อง',
    'Reservation number': 'หมายเลขการจอง',
    CheckIn: 'เช็คอิน',
    CheckOut: 'เช็คเอาท์',
    CheckIn_1: 'เช็คอิน {lodgeName} มาถึงวันนี้? (หมายเลขการจอง {resNumber})',
    CheckedIn_1: 'คุณถูกตรวจสอบใน {lodgeName}, ห้อง {room number}',
    CheckOut_1:
      'ตรวจสอบหมายเลขการจอง {lodgeName} {resNumber} ตอนนี้หรือไม่ ปุ่มห้องของคุณจะถูกปิดการใช้งาน',
    CheckedOut_1: '{lodgeName} หมายเลขการจอง {resNumber} ได้เช็คเอาท์แล้ว',
  },
  StaysSuccess: {
    'Ref: Title': 'ขอบคุณสำหรับการพัก!',
    'Ref: Body': 'การชำระเงินของคุณได้รับการประมวลผลเรียบร้อยแล้ว',
    'Ref: Go to home': 'กลับบ้าน',
    'Ref: Provide a feedback': 'ให้ข้อเสนอแนะ',
  },
};
export default labels;
