import classNames from 'classnames';
import { FC } from 'react';

import { DefaultComponentProps } from '../../../types';

import styles from './Widget.module.css';

const Widget: FC<DefaultComponentProps & { id?: string }> = ({
  id,
  children,
  className,
  'data-testid': dataTestId = 'widget',
}) => {
  return (
    <section id={id} className={classNames(styles.widget, className)} data-testid={dataTestId}>
      {children}
    </section>
  );
};
Widget.displayName = 'Widget';

export default Widget;
