//implementation inspired by https://read.reduxbook.com/markdown/part2/12-clientside-persistance.html
//the action is in charge of defining whether data should be persisted
//login and logout processed specifically
//heavy lifting done in a worker when possible
//this file includes the loading of data at login
//this file does not include the loading of shared data at startup (done in index.js)

import md5 from 'md5';

import isLoggedIn from '../../helpers/isLoggedIn';

const persistDataLocallyMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  //if worker then move forward, otherwise just give up
  if (!window.Worker) return result;

  switch (action.type) {
    case 'LOGGED_IN':
      const userId = store.getState().Core.user.username.toLowerCase();

      //remove all keys with a username different from the current user
      persistDataWorkerAct({
        type: action.type,
        info: {
          userId,
        },
      });
      break;
    case 'LOGOUT':
      persistDataWorkerAct({
        type: action.type,
      });
      break;
    case '':
      break;
    default:
      //only persist when the action requests it
      if (action.persistLocally) {
        //if the data is not explicitely non-sensitive, it is considered sensitive and requires the user to be logged in
        let isSensitive = true;
        if (action.persistLocally.userSpecific === false) isSensitive = false;

        const appState = store.getState();

        if (isSensitive && (!isLoggedIn() || !appState.Core.user.username)) return result;

        //define the cache key and val to persist
        let key = action.persistLocally.moduleId;
        const val = {
          version: action.persistLocally.cacheVersion,
          userId: null,
          data: {
            ...appState[action.persistLocally.moduleId],
            locks: {}, //remove locks
          },
        };

        if (isSensitive) val.userId = appState.Core.user.username.toLowerCase();

        persistDataWorkerAct({
          info: {
            key,
            val,
          },
        });
      }

      //data that survives logout: check if the module asks to persist, and act accordingly
      //todo: this should be implemented for the above as well, that should not be action-specific
      const moduleId = action.moduleId;
      persistLogoutSurvivingData(store.getState(), moduleId);
  }

  return result;
};

const persistDataWorker = new Worker(
  `${process.env.PUBLIC_URL}/workers/persistDataLocallyWorker.js`
);

export const persistDataWorkerAct = (toDoAsync) => {
  // console.log('toDoAsync')
  // console.log(toDoAsync)

  persistDataWorker.postMessage(toDoAsync);
};

const persistLogoutSurvivingData = (state, moduleId) => {
  if (!moduleId) return;

  let logoutSurvivingDataStructure;
  try {
    logoutSurvivingDataStructure = require('../../modules/' +
      moduleId +
      '/config').logoutSurvivingDataStructure;
    if (!logoutSurvivingDataStructure) return;
  } catch (e) {
    return;
  }

  //now, we know that we need to persist data and we have the data structure so we go through the relevant branch of the state and keep only what's necessary
  const branch = state[moduleId];

  const dataToPersist = trimObject(branch, logoutSurvivingDataStructure);

  persistDataWorkerAct({
    info: {
      //hash the key so that the contactId is anonymised
      key: md5(moduleId + '|' + state.Core?.user?.contactId),
      val: dataToPersist,
    },
  });
};

//this function takes an object to be trimmed, a template structure and only returned the part of the object that matched the structure
//it walks recursively over nested levels
//e.g.:
//obj = { a: 1, b: {b1: 2, b2: 3}}
//trimmedStructure = {b: {b1: true}, c: true}
//returns: {b: {b1: 2}}
//a is not returned because it's not in the trimmmed structure, same for b2
//c is in the trimmed structure but not in the object to be trimmed so it's ignored
const trimObject = (obj, trimmedStructure) => {
  const trimmedObject = {};
  for (const k of Object.keys(trimmedStructure)) {
    if (!obj[k]) continue;
    if (trimmedStructure[k] === true) trimmedObject[k] = obj[k];
    else trimmedObject[k] = trimObject(obj[k], trimmedStructure[k]);
  }
  return trimmedObject;
};

export default persistDataLocallyMiddleware;
