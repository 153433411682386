import { useHistory } from 'react-router';

import { ErrorCircle } from '../../../../assets/illustrations/index';
import { ImageErrorUnicorn } from '../../../../assets/images/index';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { EMOJIS } from '../../../../constants';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

import { DefaultErrorPageProps } from './GenericErrorPage.types';

import styles from './DefaultErrorPage.module.css';

const DefaultErrorPage = ({ errorId }: DefaultErrorPageProps) => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);

  return (
    <SimpleFormPage hasBackLink={false} title={''} withNavBar={false}>
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            <ErrorCircle />
            <Title data-testid={'default-error-page-first-title'} size={TITLE_SIZE.HEADLINEL}>
              {label('Ref: Oops!', {
                replace: { robot_emoji: String.fromCodePoint(EMOJIS.robot) },
              })}
            </Title>
            <Title data-testid={'default-error-page-second-title'} size={TITLE_SIZE.HEADLINEXS}>
              {label('Ref: Error title')}
            </Title>
            <Title data-testid={'default-error-page-body'} size={TITLE_SIZE.BODYMDEFAULT}>
              {label('Ref: Error body')}
            </Title>
            <Title data-testid={'default-error-page-errorId'} size={TITLE_SIZE.BODYMBOLD}>
              {label('Ref: Error ID:', {
                replace: { errorId: errorId },
              })}
            </Title>

            <ActionsBar className={styles.actionButton}>
              <Button
                data-testid="error-page-secondary-action"
                look={BUTTON_LOOK.SECONDARY}
                onClick={() => history.push('/')}
              >
                {label('Ref: Back to home')}
              </Button>
            </ActionsBar>
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <ImageErrorUnicorn />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};
export default DefaultErrorPage;
