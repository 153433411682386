const labels = {
  All: {
    dailyMenu: 'dailyMenu',
    anytime: 'anytime',
    breakfast: 'breakfast',
    morningBreak: 'morningBreak',
    lunch: 'lunch',
    afternoonBreak: 'afternoonBreak',
    dinner: 'dinner',
    supper: 'supper',
    nightMeal: 'nightMeal',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Ref: Menu Page title',
    'Ref: View details': 'Ref: View details',
    'Ref: Facility details': 'Ref: Facility details',
  },
  OpeningHours: { everyday: 'everyday', 'open all day': 'open all day' },
  ProductsList: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Facility details': 'Ref: Facility details',
    'Ref: Search products list': 'Ref: Search products list',
    'Ref: filter label: date': 'Ref: filter label: date',
    'Ref: filter label: menus': 'Ref: filter label: menus',
    'Ref: filter label: moment': 'Ref: filter label: moment',
    'Ref: filter label: category': 'Ref: filter label: category',
    'Ref: filter label: dishes': 'Ref: filter label: dishes',
    'Ref: filter label: highlight': 'Ref: filter label: highlight',
    All: 'All',
  },
  ProductDetails: { 'Ref: Page title': 'Ref: Page title' },
  Widget: {
    'Ref: Carousel title': 'Ref: Carousel title',
    'No info yet, come back later': 'No info yet, come back later',
    'Ref: menus': 'Ref: menus',
    'Ref: see all': 'Ref: see all',
  },
};
export default labels;
