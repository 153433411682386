import { act, renderHook } from '@testing-library/react';

import useMenusVisits from './useMenusVisits';

describe('useMenusVisits hook', () => {
  beforeEach(() => {
    Object.defineProperty(window, 'localStorage', {
      value: {
        getItem: jest.fn(() => null),
        setItem: jest.fn(() => null),
      },
      writable: true,
    });
  });

  it('should return the initial menu visits', () => {
    const { result } = renderHook(() => useMenusVisits('123'));
    expect(result.current.menusVisits).toEqual([]);
  });

  it('should increase the visits for a menu item', () => {
    const { result } = renderHook(() => useMenusVisits('123'));
    act(() => {
      result.current.increaseMenuVisits(1);
    });
    expect(window.localStorage.setItem).toHaveBeenCalledTimes(1);
    expect(window.localStorage.setItem).toHaveBeenCalledWith(
      'menu_visits_123',
      JSON.stringify([{ menuId: 1, visits: 1 }])
    );
  });

  it('should increase the visits for an existing menu item', () => {
    Object.defineProperty(window, 'localStorage', {
      value: {
        getItem: jest.fn(() => JSON.stringify([{ menuId: 111, visits: 1 }])),
        setItem: jest.fn(() => null),
      },
      writable: true,
    });

    const { result } = renderHook(() => useMenusVisits('123'));
    act(() => {
      result.current.increaseMenuVisits(111);
    });

    expect(window.localStorage.setItem).toHaveBeenCalledTimes(1);
    expect(window.localStorage.setItem).toHaveBeenCalledWith(
      'menu_visits_123',
      JSON.stringify([{ menuId: 111, visits: 2 }])
    );
  });
});
