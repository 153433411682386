import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import LegalDocPage from './LegalDocPage';

const Legal = () => (
  <Switch>
    <Route path={pagePaths['LegalDoc']} children={<LegalDocPage />} />
  </Switch>
);

export default Legal;
