import { screen } from '@testing-library/react';

import { StatusCheckCircleIcon } from '../../../../../assets/icons';
import { TAG_COLOR } from '../../../../../components/molecules/Tile';
import { formatDate } from '../../../../../helpers/dateTime';
import { renderedComponent } from '../../../../../helpers/tests/renderComponent';
import { AuditStatus } from '../../../types';
import AuditTile, { getStickerProps } from '../AuditTile';
import { AuditTileProps } from '../AuditTile.types';

describe('AuditTile', () => {
  const mockAuditTileProps: AuditTileProps = {
    label: (s) => s,
    id: 'auditId',
    auditName: 'Sample Audit',
    auditStreamType: 'Stream Type',
    dueDate: new Date('2023-05-30').toString(),
    status: { label: 'Completed', value: 1 },
    workOrderNumber: 'WO123',
    workOrderLocation: 'Location',
    locationId: '1234',
    isUnsynchronised: false,
    handleSyncStatus: (s) => s,
    refreshLocalData: () => undefined,
  };

  afterEach(() => {
    jest.clearAllMocks();
  });

  it('should render AuditTile component correctly', () => {
    renderedComponent(AuditTile, { ...mockAuditTileProps });
    const titleElement = screen.getByText('Sample Audit');
    const descriptionElement = screen.getByText('WO123 • Stream Type');
    const synchroniseElement = screen.getByText('Ref: Audit synchronised');
    const tagElement = screen.getByText('Location');
    const dateElement = screen.getByText(formatDate(new Date(mockAuditTileProps.dueDate), 'en-US'));

    expect(titleElement).toBeInTheDocument();
    expect(descriptionElement).toBeInTheDocument();
    expect(synchroniseElement).toBeInTheDocument();
    expect(tagElement).toBeInTheDocument();
    expect(dateElement).toBeInTheDocument();
  });

  it('should return the correct props for AuditStatus.COMPLETED', () => {
    const status = AuditStatus.COMPLETED;
    const expectedProps: {
      icon: JSX.Element;
      color: TAG_COLOR;
    } = { icon: <StatusCheckCircleIcon />, color: TAG_COLOR.SUCCESS };

    const result = getStickerProps(status);

    expect(result).toEqual(expectedProps);
  });
});
