import { screen, cleanup, act, fireEvent } from '@testing-library/react';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import renderComponent from '../../../../../helpers/tests/renderComponent';
import { useGetContentQuery } from '../../../api';
import ContentDetails from '../ContentDetails';

const mockDetails = {
  id: 'some-id',
  details: 'Content details here',
  expiryDate: '2025-08-05T14:54:00Z',
  type: 'Normal',
  publishedDate: '2024-08-05T14:54:00Z',
  title: 'Yoga',
  images: [],
  preferences: [],
  link: { url: 'https://event-url.test', text: 'Link here' },
  linkedEntity: '',
  linkedEntityId: '',
  linkedEntityUrl: '',
  languageCode: '',
  matchUserPreferenceCount: '',
  score: '',
  mainImageId: undefined,
  reactions: {
    me: null,
    reactions: [],
    total: 0,
  },
};

jest.setTimeout(20000);

jest.mock('../../../../../helpers/reactions.helpers', () => ({
  reactionsWithLabels: () => [],
}));

const mockTrackContentView = jest.fn((args) => args);
jest.mock('../../../helpers/trackContentViews.helper', () => {
  return jest.fn().mockImplementation(() => {
    return { trackContentViews: mockTrackContentView };
  });
});

jest.mock('../../../../../store', () => ({
  store: {
    getState: () => ({
      Core: { services: { list: [] } },
      Shared: { language: { currentLanguageCode: 'en-US' } },
    }),
  },
}));

jest.mock('../../../api/api', () => ({
  useGetContentQuery: jest.fn(),
  useUpdateContentReactionMutation: () => [() => true],
  useDeleteContentReactionMutation: () => [() => true],
}));

jest.mock('@awesome-cordova-plugins/calendar', () => ({
  createEventInteractively: jest.fn(),
}));

describe('ContentDetails', () => {
  const env = global.process.env;
  afterAll(() => cleanup());

  describe('ContentDetails - MyWay&Wando', () => {
    describe('on initial render', () => {
      beforeEach(() => {
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
        (useGetContentQuery as jest.Mock).mockReturnValue({
          data: { ...mockDetails, markdownDecoded: true, markdown: '# Markdown' },
          isLoading: false,
        });
      });

      renderComponent(ContentDetails);

      it('should display', () => {
        expect(screen.getAllByText(mockDetails.title)).toBeTruthy();
        expect(screen.getAllByText('Mon, 08/05/2024')).toBeTruthy();
        expect(screen.queryByText('Category')).toBeNull();
        expect(screen.queryByText(mockDetails.type)).toBeNull();
        expect(screen.getByText(mockDetails.details)).toBeTruthy();
        expect(screen.getByText('Markdown')).toBeTruthy();
      });

      it('should share email button be presented and clickable', async () => {
        const shareButton = screen.getByTestId('share-button');

        await act(async () => {
          fireEvent.click(shareButton);
        });

        const shareBtn = screen.getByTestId('button-share-to-email');

        await act(async () => {
          fireEvent.click(shareBtn);
        });

        expect(shareBtn).toBeTruthy();
      });
    });
  });

  describe('ContentDetails - MyVillage', () => {
    describe('on initial render', () => {
      beforeEach(() => {
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE };

        (useGetContentQuery as jest.Mock).mockReturnValue({
          data: mockDetails,
          isLoading: false,
        });
      });

      renderComponent(ContentDetails);

      it('should display', () => {
        expect(screen.getAllByText(mockDetails.title)).toBeTruthy();
        expect(screen.getAllByText('Mon, 08/05/2024')).toBeTruthy();
        expect(screen.getAllByText(mockDetails.type)).toBeTruthy();
        expect(screen.getByText(mockDetails.details)).toBeTruthy();
      });

      it('should share email button be presented and clickable', async () => {
        const shareButton = screen.getByTestId('share-button');

        await act(async () => {
          fireEvent.click(shareButton);
        });

        const shareBtn = screen.getByTestId('button-share-to-email');

        await act(async () => {
          fireEvent.click(shareBtn);
        });

        expect(shareBtn).toBeTruthy();
      });
    });
  });
});
