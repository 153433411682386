const labels = {
  All: {
    'Ref: See item': 'ראה פריט',
    'Ref: Print': 'הדפיס את הקבלה שלך',
    'Ref: Email': 'קבל את הקבלה שלך באמצעות דואר אלקטרוני',
    'Ref: Receipt Modal message': 'איך תרצה לקבל את הקבלה שלך?',
    'Ref: Loyalty Reward': 'תגמול נאמנות',
    'Ref: Equation for stamps per item': '1 רכישה = חותמת 1',
    'Ref: Paid by employer': 'הסכום ששילם המעסיק שלך',
    'Ref: Paid by user': 'הסכום ששולם על ידך',
  },
  ProductsList: {
    'Ref: Page title': 'רשימת מוצרים',
    'Ref: Facility details': 'פרטי מתקן',
    'Ref: Search products list': 'חיפוש פריטים.',
    'Ref: filter label: menus': 'תַפרִיט',
    'Ref: filter label: moment': 'רֶגַע',
    'Ref: filter label: category': 'קטגוריה',
    'Ref: all': 'את כל',
    'Ref: today': 'היום',
    'Ref: tomorrow': 'מָחָר',
    'Ref: see more': 'ראה עוד',
    'Ref: see less': 'ראה פחות',
    'Ref: reset': 'אִתחוּל',
    'Ref: View in cart': 'נוף בעגלה',
    'Ref: You have changed location': 'שינית את המיקום',
    'Ref: You are now at': 'אתה עכשיו ב',
    'Ref: Menu card': 'תפריט',
    'Ref: Table': 'מספר טבלה',
    'Service unavailable': 'שירותים לא זמינים',
    'Ref: Ordering unavailable': 'זָהִיר! ההזמנה באתר זה אינה זמינה כרגע, אנא נסה בהמשך.',
    'Ref: Filters': 'פילטרים (אלרגנים ועוד)',
  },
  Order: { 'Ref: Page title': 'להזמין' },
  MenuSelector: {
    'Ref: Menu Page title': 'תפריטים',
    'Ref: today': 'היום',
    'Ref: tomorrow': 'מָחָר',
    'Ref: Search products list': 'חיפוש פריטים.',
    'Ref: You have changed location': 'שינית את המיקום',
    'Ref: You are now at': 'אתה עכשיו ב',
  },
  ProductDetails: {
    'Ref: Page title': 'פרטי מוצר',
    'Ref: Serving Size': 'גודל מנה',
    'Ref: Nutrition': 'מידע תזונתי',
    'Ref: Nutrition adults': 'מבוגרים צריכים סביב 2000 קקאל ביום',
    'Ref: Nutrition Calories': 'קלוריות',
    'Ref: Nutrition Calcium': 'סִידָן',
    'Ref: Nutrition Fat': 'שמן',
    'Ref: Nutrition Saturated Fat': 'שומן רווי',
    'Ref: Nutrition Carbohydrates': 'פחמימות',
    'Ref: Nutrition Sugar': 'סוכר',
    'Ref: Nutrition Protein': 'חלבון',
    'Ref: Nutrition Sodium': 'נתרן',
    'Ref: Nutrition cholesterol': 'סוונסטרול',
    'Ref: Nutrition dietaryFiber': 'סיבים תזונתיים',
    'Ref: Nutrition iron': 'בַּרזֶל',
    'Ref: Nutrition potassium': 'אֶשׁלָגָן',
    'Ref: Nutrition vitaminA': 'ויטמין',
    'Ref: Nutrition vitaminC': 'ויטמין סי',
    'Ref: Additives': 'תוספים',
    'Ref: Emission': 'פליטת CO2',
    'Ref: This product generates': 'מוצר זה מייצר',
    'Ref: of CO2': 'של CO2.',
    'Ref: emissions, which equals a': 'פליטות, אשר שווה',
    'Ref: car trip': 'טיול ברכב',
    'Ref: Ingredients': 'מרכיב מתכון',
    'Ref: Allergens': 'אלרגנים',
    'Ref: Always there': 'תמיד שם',
    'Ref: Allergens selected': 'אלרגנים שנבחרו על המסנן:',
    'Ref: minimum required': 'מינימום חובה',
    'Ref: you have to add at least': 'אתה צריך להוסיף לפחות',
    'Ref: ingredients': 'רכיבים',
    'Ref: Ingredient': 'מַרכִּיב',
    'Ref: Free': 'חינם',
    'Ref: Based on customization': 'מבוסס על ההתאמה האישית שלך',
    'Ref: Additives eggs': 'ביצים',
    'Ref: Additives fish': 'דג',
    'Ref: Additives milk': 'חלב',
    'Ref: Additives nuts': 'אֱגוֹזִים',
    'Ref: Additives wheat': 'חיטה',
    'Ref: Additives peanuts': 'בוטנים',
    'Ref: Additives soy beans': 'שעועית סויה',
    'Ref: Additives sesame seeds': 'שומשום',
    'Ref: Review': 'סקירה',
    'Ref: Per portion': 'לכל חלק',
    'Ref: Per 100g': 'לכל 100 גרם',
    'Ref: Allergen selected': 'אלרגן שנבחר במסנן חיפוש',
    'Ref: Fitbit Log': 'יומן ל- Fitbit',
    'Ref: age verification required on delivery': 'אימות גיל הנדרש עם הלידה',
    'Ref: alcohol content notification':
      'על ידי הצבת והזמנה לאחד מהפריטים הללו, אתה מצהיר שאתה בן 18 ומעלה. שימוש לרעה באלכוהול מסוכן לבריאותך.',
    'Ref: default': 'בְּרִירַת מֶחדָל',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} הרוויח אם אתה מזמין מוצר זה',
    'Ref: Loyalty Card Description':
      'ה- {stamp_form} יתווסף לנאמנות הבאה {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'תוכנית חותמות הנאמנות מאפשרת לך לאסוף בולים לתגמולים.',
    'Ref: Loyalty Modal Description':
      'כשאתה קונה <b> {product_name}, בול אחד </b> יתווסף ל <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'וכן',
    'Ref: Stamp': 'חותמת',
    'Ref: Stamps': 'בולים',
    'Ref: Program': 'תכנית',
    'Ref: Programs': 'תוכניות',
    'Ref: Loyalty Stamps': 'חותמות נאמנות',
    'Ref: See Loyalty Stamps': 'ראו חותמות נאמנות',
    Favorite: 'אהוב',
    'Ref: In favorites': 'המוצר כבר נמצא ברשימת המועדפים. לחץ כדי להסיר אותו.',
    'Ref: Not in favorites': 'המוצר עדיין לא נמצא ברשימת המועדפים. לחץ כדי להוסיף אותו.',
    'Ref: Added to favorites': 'המוצר נוסף לרשימת המועדפים.',
    'Ref: Removed from favorites': 'המוצר הוסר מרשימת המועדפים.',
    'Ref: From': 'מ',
  },
  OrderHistory: { 'Ref: Orders': 'הזמנות', 'Ref: Page Title': 'ההזמנות שלי' },
  MenuTile: { 'Ref: Scan&Go': 'סרוק & Go', 'Ref: View only': 'צפייה בלבד' },
  ProductTile: {
    'Ref: Allergens': 'אלרגנים',
    'Ref: Presence of allergen': 'נוכחות של אלרגן',
    'Ref: Scan': 'לִסְרוֹק',
    'Ref: From': 'מ',
    'Ref: Already in product favorite list. Click to remove it.':
      'כבר ברשימה המועדפת על המוצר. לחץ כדי להסיר אותו.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'נכון לעכשיו לא נוסף לרשימה המועדפת על המוצר. לחץ כדי להוסיף אותו.',
    'Ref: Free': 'חינם',
  },
  MenusWidget: { 'Ref: no menu': 'אין תפריט כרגע', 'Ref: Modal title': 'תפריט ליום' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'לאסוף',
    'Ref: Eat-in': 'לאכול',
    'Ref: Scan&Go': 'סרוק & Go',
    'Ref: Delivery': 'מְסִירָה',
    'Ref: Total': 'סך הכל',
    'Ref: How was facility name service': 'איך היה שירות {facility_name}?',
    'Ref: How do you rate the service': 'איך מדרגים את השירות?',
  },
};
export default labels;
