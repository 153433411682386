const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Yêu cầu chỗ ở',
    'Ref: Describe your request': 'Mô tả yêu cầu của bạn',
    'Ref: Confirm your request': 'Xác nhận yêu cầu của bạn',
    'Ref: Your request': 'Yêu cầu của bạn',
    'Ref: Summary': 'Bản tóm tắt',
    'Ref: Next': 'Kế tiếp',
    'Ref: Submit': 'Nộp',
    'Ref: Me': 'Tôi',
    'Ref: Arrival date': 'Ngày tới',
    'Ref: Arrival time': 'Thời gian đến',
    'Ref: Departure date': 'Ngày khởi hành',
    'Ref: Departure time': 'Giờ khởi hành',
    'Ref: request raised for': 'Người bị ảnh hưởng bởi yêu cầu này',
    'Ref: phoneNumber': 'Số điện thoại',
    'Ref: arrivalDate': 'Đến',
    'Ref: departureDate': 'Sự khởi hành',
    'Ref: description': 'Bình luận',
    'Ref: open': 'Mở',
    'Ref: pending': 'Chưa giải quyết',
    'Ref: refused': 'Bị từ chối',
    'Ref: closed': 'Đóng',
    'Ref: all': 'Tất cả',
    'Ref: Arrival': 'Đến',
    'Ref: Validate': 'Xác nhận',
    'Ref: site': 'Vị trí trang web',
    'Ref: Update us': 'Cập nhật cho chúng tôi',
    'Ref: email of guest': 'Email của khách',
    'Ref: GuestAccommodationRequestTitle': 'Yêu cầu của khách',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Yêu cầu mới',
    'Ref: Status': 'Trạng thái',
    'Ref: Date': 'Ngày',
    'Ref: Coming arrivals': 'Đến đến',
    'Ref: Past arrivals': 'Quá khứ đến',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Số điện thoại của khách',
    'Ref: Your phone number': 'Số điện thoại của bạn',
    'Ref: The phone number': 'Số điện thoại',
    'Ref: PhoneNumber error msg': 'Số điện thoại không hợp lệ',
    'Ref: Comment error msg': 'Số lượng ký tự tối đa là 250',
    'Ref: Guest first name': 'Tên khách',
    'Ref: Guest last name': 'Họ của khách',
    'Ref: request for': 'Đối với ai là yêu cầu này?',
    'Ref: request for me': 'Tôi',
    'Ref: request for guest': 'Một người nào khác',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Chi tiết yêu cầu chỗ ở',
    'Ref: Timeline': 'Mốc thời gian',
    'Ref: No updates placeholder': 'Không có bản cập nhật cho yêu cầu này',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Cảm ơn',
    'Ref: Success text':
      'Chúng tôi đã nhận được yêu cầu của bạn và đang xem xét nó ngay bây giờ. Chúng tôi sẽ lấy lại cho bạn sớm.',
    'Ref: Go to home': 'Đi về nhà',
    'Ref: Create another request': 'Tạo một yêu cầu khác',
  },
  CreateError: {
    'Ref: Create error title': 'Ối!',
    'Ref: Create error text': 'Có một lỗi đã xảy ra. Xin hãy thử lại.',
    'Ref: Retry': 'Thử lại',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Vui lòng cập nhật cho chúng tôi bất kỳ yếu tố mới nào về yêu cầu của bạn để chúng tôi có thể hỗ trợ tốt nhất cho bạn.',
  },
};
export default labels;
