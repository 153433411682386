import classNames from 'classnames';

import { TagProps, TAG_COLOR, TAG_VARIANT } from './Tile.types';

import styles from './Tile.module.css';

export const Tag: React.FC<TagProps> = ({
  name,
  icon,
  variant = TAG_VARIANT.TAG,
  color = TAG_COLOR.SECONDARY,
  element,
  'data-testid': dataTestId = 'tag',
}) => (
  <span
    key={name}
    data-testid={dataTestId}
    className={classNames({
      [styles.tag]: variant === TAG_VARIANT.TAG,
      [styles.chip]: variant === TAG_VARIANT.CHIP,
      [styles[color]]: true,
    })}
  >
    <span>
      {icon}
      {element ? element : <p className="paragraph">{name}</p>}
    </span>
  </span>
);
