import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { BuildingIcon, ChevronRightIcon } from '../../../../assets/icons';
import { ImageDefault } from '../../../../assets/images';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import { Tile, TileAction } from '../../../../components/molecules/Tile';
import { SIZE } from '../../../../constants';
import { pagePaths } from '../../config';
import { useSiteTranslation } from '../../hooks/useSiteTranslation';
import { SiteTileProps } from '../../types/sites.types';

const SiteTile = ({ site, onSiteSelection }: SiteTileProps) => {
  const history = useHistory();
  const { label } = useSiteTranslation(__filename);
  const siteDetailsUrl = pagePaths['SiteDetails'].replace(':id', site?.id);
  const siteDetailsRedirection = () => history?.push(siteDetailsUrl);
  const selectSiteRedirection = () => onSiteSelection(site);

  const addressParts = [
    site?.address?.line1,
    site?.address?.line2,
    site?.address?.line3,
    site?.address?.city,
    site?.address?.postalCode,
    site?.address?.stateOrProvince,
    site?.address?.country,
  ].filter(Boolean);
  const strAddress = addressParts.join(',');

  const image = useMemo(
    () => (
      <ImageWithFallback
        data-testid="site-tile"
        imgInfo={site.imgInfo ? site.imgInfo : undefined}
        imgElement={<ImageDefault />}
      />
    ),
    [site.imgInfo]
  );

  const actions: TileAction[] = [
    {
      name: 'Select site',
      'data-testid': `select-site-${site.id}`,
      customAction: (
        <Button
          look={BUTTON_LOOK.PRIMARY}
          suffix={ChevronRightIcon}
          size={SIZE.SMALL}
          onClick={selectSiteRedirection}
          data-testid={`select-site-${site.id}-action`}
        >
          {label('Select')}
        </Button>
      ),
    },
    {
      name: 'Site details',
      'data-testid': `select-site-${site.id}-details`,
      customAction: (
        <Button
          look={BUTTON_LOOK.SECONDARY}
          suffix={BuildingIcon}
          size={SIZE.SMALL}
          onClick={siteDetailsRedirection}
          data-testid={`select-site-${site.id}-details-action`}
        >
          {label('Details')}
        </Button>
      ),
    },
  ];

  return (
    <Tile
      id={site.id}
      image={image}
      title={site.name}
      belowTitle={strAddress}
      actions={actions}
      actionsWithLabel
      onClick={selectSiteRedirection}
    />
  );
};

export default SiteTile;
