import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { SERVICE } from '../../../config';

import ServiceRequestWidget from './index';

import { ServiceRequestProps } from '@/modules/ServiceRequest/types/types';

const serviceRequest1 = {
  id: '609fc3a0-a38a-ed11-81ad-6045bd8c55a5',
  currentStatus: 'In Progress',
  status: '',
  referenceNumber: 'SR-21749-D7V4R8',
  title: 'Mobile - test5',
  displayTitle: 'test5',
  description: '',
  createdOn: '2023-01-02T13:44:57Z',
  modifiedOn: '2023-01-02T13:45:29Z',
};

const serviceRequest2 = {
  id: '567e927c-a38a-ed11-81ad-6045bd8c55a5',
  currentStatus: 'In Progress',
  status: '',
  referenceNumber: 'SR-21748-V1F7D7',
  title: 'Mobile - test4',
  displayTitle: 'test4',
  description: '',
  createdOn: '2023-01-02T13:43:57Z',
  modifiedOn: '2023-01-02T13:44:33Z',
};

const serviceRequest3 = {
  id: 'ac7d64e9-9d8a-ed11-81ad-6045bd8c55a5',
  currentStatus: 'In Progress',
  status: '',
  referenceNumber: 'SR-21734-C1K7W5',
  title: 'Mobile - test1',
  displayTitle: 'test1',
  description: '',
  createdOn: '2023-01-02T13:04:00Z',
  modifiedOn: '2023-01-02T13:04:34Z',
};

const mockServiceRequests: Array<any> = [serviceRequest1, serviceRequest2, serviceRequest3];

const state = {
  Core: {
    services: {
      list: [
        {
          name: SERVICE.SERVICE_REQUEST,
        },
      ],
    },
  },
  ServiceRequest: {
    serviceRequests: mockServiceRequests,
  },
};

const mock: { data: ServiceRequestProps[]; isLoading: boolean } = {
  data: [],
  isLoading: false,
};
jest.mock('../../api/index', () => ({
  useGetServiceRequestsQuery: () => {
    return mock;
  },
}));

describe('Service request Widget', () => {
  afterAll(() => cleanup());

  describe('with data', () => {
    beforeEach(() => {
      mock.data = mockServiceRequests;
    });

    renderComponent(ServiceRequestWidget, {}, state);

    it('should render service requests', () => {
      const serviceRequestElement1 = screen.getAllByTestId(
        `ServiceRequestTile-${serviceRequest1.id}`
      );
      const serviceRequestElement2 = screen.getAllByTestId(
        `ServiceRequestTile-${serviceRequest2.id}`
      );
      const serviceRequestElement3 = screen.getAllByTestId(
        `ServiceRequestTile-${serviceRequest3.id}`
      );
      expect(serviceRequestElement1).toBeTruthy();
      expect(serviceRequestElement2).toBeTruthy();
      expect(serviceRequestElement3).toBeTruthy();
    });
  });

  describe('without data', () => {
    beforeEach(() => {
      mock.data = [];
    });

    renderComponent(
      ServiceRequestWidget,
      {},
      {
        ...state,
        ServiceRequest: { ...state.ServiceRequest, serviceRequests: [] },
      }
    );

    it('should render placeholder', () => {
      const menuElement = screen.getAllByTestId('widget-placeholder');
      expect(menuElement).toBeTruthy();
    });
  });
});
