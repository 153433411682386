import { modulesConfig, serviceTypes } from '../../config';

const moduleId = 'JourneyPlanner';
const cacheVersion = '0';

const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

const pagePaths = {
  Module: modulePath,
};

export { moduleId, cacheVersion, pagePaths };
