import { cleanup, screen } from '@testing-library/react';
import { FormProvider, useForm } from 'react-hook-form';

import { FormFields } from '../../../types';
import Create from '../Create';

import renderComponent from '@/helpers/tests/renderComponent';
import { useServiceRequestLightSetupOption } from '@/modules/ServiceRequestLight/hooks/useServiceRequestLightSetupOption';

jest.mock('@/modules/ServiceRequest/api/index', () => ({
  useGetSiteContactsQuery: () => ({
    data: [],
  }),
}));
jest.mock('@/modules/ServiceRequestLight/hooks/useServiceRequestLightSetupOption', () => ({
  useServiceRequestLightSetupOption: jest.fn().mockReturnValue(true),
}));

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => ({
    siteId: 'id1',
  }),
}));

const CreateFormTest = () => {
  const formMethods = useForm<FormFields>({
    defaultValues: {
      title: '',
      category: '',
      affectedPerson: {
        label: '',
        value: '',
      },
      phoneNumber: '',
      buildingText: '',
      roomText: '',
      description: '',
      attachments: [],
    },
  });
  return (
    <FormProvider {...formMethods}>
      <Create />
    </FormProvider>
  );
};

describe('ServiceRequestLight - Create', () => {
  afterEach(() => cleanup());
  beforeEach(() => {
    (useServiceRequestLightSetupOption as jest.Mock).mockReturnValue(true);
  });
  renderComponent(CreateFormTest);

  describe('Render', () => {
    it('should render', () => {
      const element = screen.queryByTestId('service-request-light-form');
      expect(element).toBeTruthy();
    });
  });

  describe('Setup options test', () => {
    it('should display categories field when enableCategories setup option is enabled', () => {
      const element = screen.queryByTestId('category-wrapper');
      expect(element).toBeTruthy();
    });

    it('should display affected person field when enableAffectedPerson setup option is enabled', () => {
      const element = screen.queryByTestId('affectedPerson-wrapper');
      expect(element).toBeTruthy();
    });
  });
});
