const labels = {
  All: {
    'Ref: See item': 'See item',
    'Ref: Print': 'Print your receipt',
    'Ref: Email': 'Receive your receipt by e-mail',
    'Ref: Receipt Modal message': 'How would you like to get your receipt?',
    'Ref: Loyalty Reward': 'Loyalty Reward',
    'Ref: Equation for stamps per item': '1 Purchase = 1 Stamp',
    'Ref: Paid by employer': 'The amount paid by your employer',
    'Ref: Paid by user': 'The amount paid by you',
  },
  ProductsList: {
    'Ref: Page title': 'Product list',
    'Ref: Facility details': 'Facility details',
    'Ref: Search products list': 'Search items',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Category',
    'Ref: all': 'All',
    'Ref: today': 'Today',
    'Ref: tomorrow': 'Tomorrow',
    'Ref: see more': 'See more',
    'Ref: see less': 'See less',
    'Ref: reset': 'Reset',
    'Ref: View in cart': 'View in cart',
    'Ref: You have changed location': 'You have changed location',
    'Ref: You are now at': 'You are now at',
    'Ref: Menu card': 'Menu card',
    'Ref: Table': 'Table number',
    'Service unavailable': 'Service unavailable',
    'Ref: Ordering unavailable':
      'Careful! Ordering at this site is unavailable at the moment, please try later.',
    'Ref: Filters': 'Filters (Allergens and more)',
  },
  Order: { 'Ref: Page title': 'Order' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menus',
    'Ref: today': 'Today',
    'Ref: tomorrow': 'Tomorrow',
    'Ref: Search products list': 'Search items',
    'Ref: You have changed location': 'You have changed location',
    'Ref: You are now at': 'You are now at',
  },
  ProductDetails: {
    'Ref: Page title': 'Product Details',
    'Ref: Serving Size': 'Serving Size',
    'Ref: Nutrition': 'Nutritional information',
    'Ref: Nutrition adults': 'Adults need around 2000 kcal a day',
    'Ref: Nutrition Calories': 'Calories',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Fat',
    'Ref: Nutrition Saturated Fat': 'Saturated fat',
    'Ref: Nutrition Carbohydrates': 'Carbohydrates',
    'Ref: Nutrition Sugar': 'Sugar',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Dietary Fiber',
    'Ref: Nutrition iron': 'Iron',
    'Ref: Nutrition potassium': 'Potassium',
    'Ref: Nutrition vitaminA': 'Vitamin A',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Additives': 'Additives',
    'Ref: Emission': 'CO2 emissions',
    'Ref: This product generates': 'This product generates',
    'Ref: of CO2': 'of CO2',
    'Ref: emissions, which equals a': 'emissions, which equals a',
    'Ref: car trip': 'car trip',
    'Ref: Ingredients': 'Recipe ingredient',
    'Ref: Allergens': 'Allergens',
    'Ref: Always there': 'Always there',
    'Ref: Allergens selected': ' allergens selected on the filter: ',
    'Ref: minimum required': 'minimum required',
    'Ref: you have to add at least': 'You have to add at least',
    'Ref: ingredients': 'ingredients',
    'Ref: Ingredient': 'ingredient',
    'Ref: Free': 'Free',
    'Ref: Based on customization': 'Based on your customization',
    'Ref: Additives eggs': 'eggs',
    'Ref: Additives fish': 'fish',
    'Ref: Additives milk': 'milk',
    'Ref: Additives nuts': 'nuts',
    'Ref: Additives wheat': 'wheat',
    'Ref: Additives peanuts': 'peanuts',
    'Ref: Additives soy beans': 'soy beans',
    'Ref: Additives sesame seeds': 'sesame seeds',
    'Ref: Review': 'Review',
    'Ref: Per portion': 'Per portion',
    'Ref: Per 100g': 'Per 100g',
    'Ref: Allergen selected': 'Allergen selected on search filter',
    'Ref: Fitbit Log': 'Log to Fitbit',
    'Ref: age verification required on delivery': 'Age Verification Required on Delivery',
    'Ref: alcohol content notification':
      'By placing and order for one of these items, you declare that you are aged 18 or over. Alcohol abuse is dangerous for your health.',
    'Ref: default': 'Default',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} earned if you order this product',
    'Ref: Loyalty Card Description':
      'The {stamp_form} will be added to the following loyalty {program_form}: <b>{scheme_names}</b>',
    'Ref: About Loyalty stamps':
      'The Loyalty Stamps program allows you to collect stamps for rewards.',
    'Ref: Loyalty Modal Description':
      'When you buy <b>{product_name}, 1 Stamp</b> will be added to the <b>{scheme_names}</b> {program_form}',
    'Ref: and': 'and',
    'Ref: Stamp': 'Stamp',
    'Ref: Stamps': 'Stamps',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programs',
    'Ref: Loyalty Stamps': 'Loyalty Stamps',
    'Ref: See Loyalty Stamps': 'See Loyalty Stamps',
    Favorite: 'Favorite',
    'Ref: In favorites': 'The product is already in the favorites list. Click to remove it.',
    'Ref: Not in favorites': 'The product is not yet in the favorites list. Click to add it.',
    'Ref: Added to favorites': 'The product has been added to the favorites list.',
    'Ref: Removed from favorites': 'The product has been removed from the favorites list.',
    'Ref: From': 'From',
  },
  OrderHistory: { 'Ref: Orders': 'Orders', 'Ref: Page Title': 'My Orders' },
  MenuTile: { 'Ref: Scan&Go': 'Scan & Go', 'Ref: View only': 'View only' },
  ProductTile: {
    'Ref: Allergens': 'Allergens',
    'Ref: Presence of allergen': 'Presence of allergen',
    'Ref: Scan': 'Scan',
    'Ref: From': 'From',
    'Ref: Already in product favorite list. Click to remove it.':
      'Already in product favorite list. Click to remove it.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Currently not added to product favorite list. Click to add it.',
    'Ref: Free': 'Free',
  },
  MenusWidget: { 'Ref: no menu': 'No menu for the moment', 'Ref: Modal title': 'Menu for the day' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Pickup',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Delivery': 'Delivery',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'How was {facility_name}´s service?',
    'Ref: How do you rate the service': 'How do you rate the service?',
  },
};
export default labels;
