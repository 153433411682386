import { IonCard, IonCardHeader, IonCardContent } from '@ionic/react';
import classNames from 'classnames';

import { TITLE_SIZE } from '../../atoms/Title';
import Title from '../../atoms/Title/Title';

import { CardProps, CardIconProps } from './Card.types';

import styles from './Card.module.css';

const CardIcon = ({ icon, className }: CardIconProps) => {
  const Icon = icon;
  return (
    <Icon
      className={classNames(className === undefined ? 'icon_primary' : className, styles.cardIcon)}
    />
  );
};

const Card = ({
  overTitle,
  title,
  icon,
  children,
  className,
  'data-testid': dataTestId = 'card',
}: CardProps) => {
  return (
    <div className={classNames(className)} data-testid={dataTestId}>
      {overTitle && (
        <div className={classNames('mb-M')}>
          <Title {...overTitle} />
        </div>
      )}
      <IonCard className={styles.card}>
        {(icon || title) && (
          <IonCardHeader className={styles.cardHeader}>
            {title && (
              <Title {...title}>
                {/* TODO: move title.className from title and titleSuffix span to this parent span
                 once 'font-size: 15px' is removed from * selector in App.css */}
                <span className={styles.title}>
                  <span className={styles.leftTitle}>
                    {icon && (
                      <span>
                        <CardIcon {...icon} />
                      </span>
                    )}
                    {title.children && <span className={title.className}>{title.children}</span>}
                    {title.small && <span className={TITLE_SIZE.BODYSDEFAULT}>{title.small}</span>}
                  </span>
                </span>
              </Title>
            )}
          </IonCardHeader>
        )}
        <IonCardContent className={classNames(styles.cardContent)}>{children}</IonCardContent>
      </IonCard>
    </div>
  );
};

export default Card;
