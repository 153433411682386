const labels = {
  ContentDetails: {
    'Ref: Page title': 'Innehållsdetaljer',
    'See the event': 'Se evenemang',
    'Take the survey': 'Delta i undersökningen',
    Date: 'Datum',
    Category: 'Kategori',
    'Ref: Content site different title': 'Detta innehåll tillhör inte din nuvarande webbplats',
    'Ref: Content site different description':
      'Du tittar på ett innehåll som inte tillhör din nuvarande webbplats. Du kommer inte att kunna se det i listan över innehåll.',
  },
  ContentList: {
    'Ref: Page title': 'Nyhetsflöde',
    'featured (as in featured content)': 'Featured',
    'Ref: category - all': 'Allt',
    'Ref: category - promotional': 'Erbjudander',
    'Ref: category - featured': 'Featured',
    'Ref: category - other': 'Övrig',
  },
  Widget: {
    'Ref: Carousel title': 'Kampanjer',
    'Ref: Carousel title promo': 'Toppnyheter',
    'No info yet, come back later': 'Ingen info ännu, kom tillbaka senare 😉',
    'Ref: Content': 'Innehåll',
    'Ref: Widget Title': 'Nyhetsflöde',
    'Ref: MyVillage Carousel title promo': 'Befordringar',
  },
};
export default labels;
