import { serviceRequestStatus } from '../../config';
import { CurrentServiceRequest } from '../../types/types';

export const generateTimeline = (serviceRequest: CurrentServiceRequest) => {
  return [
    {
      name: 'sent',
      date:
        serviceRequest.currentStatus === serviceRequestStatus.sent ? serviceRequest.createdOn : '',
      isProcessed: [
        serviceRequestStatus.sent,
        serviceRequestStatus.received,
        serviceRequestStatus.inProgress,
        serviceRequestStatus.completed,
      ].includes(serviceRequest.currentStatus),
    },
    {
      name: 'received',
      date:
        serviceRequest.currentStatus === serviceRequestStatus.received
          ? serviceRequest.createdOn
          : '',
      isProcessed: [
        serviceRequestStatus.received,
        serviceRequestStatus.inProgress,
        serviceRequestStatus.completed,
      ].includes(serviceRequest.currentStatus),
    },
    {
      name: 'inProgress',
      date:
        serviceRequest.currentStatus === serviceRequestStatus.inProgress
          ? serviceRequest.modifiedOn &&
            serviceRequest.currentStatus !== serviceRequestStatus.completed
            ? serviceRequest.modifiedOn
            : serviceRequest.createdOn
          : '',
      isProcessed: [serviceRequestStatus.inProgress, serviceRequestStatus.completed].includes(
        serviceRequest.currentStatus
      ),
    },
    {
      name: 'completed',
      date:
        serviceRequest.currentStatus === serviceRequestStatus.completed
          ? serviceRequest.modifiedOn
          : '',
      isProcessed: serviceRequest.currentStatus === serviceRequestStatus.completed,
      isLast: true,
    },
  ];
};
