const labels = {
  All: {
    Region: 'Region lub kraj',
    'Ref: Geography': 'Wybierz swój kraj lub region',
    'Ref: Country': 'Kraj',
    'Ref: Country tooltip': 'Twoje położenie odpowiada krajowi lokalizacji.',
    'Ref: Please select your country': 'Proszę wybrać swój kraj',
    'First name': 'Imię',
    'Last name': 'Nazwisko',
    'Ref: Full name': 'Imię i Nazwisko',
    'Mobile number': 'Numer telefonu komórkowego',
    'Mobile phone': 'Telefon komórkowy',
    Email: 'Adres e-mail',
    Password: 'Hasło',
    logout: 'Wyloguj',
    'Not a new user? Log in': 'Nie jesteś nowym użytkownikiem? Zaloguj się',
    'do not register, login': 'Nie jesteś nowym użytkownikiem? Zaloguj się',
    'Ref: I agree': 'Zgadzam się',
    Register: 'Zarejestruj się',
    'Ref: My language': 'Mój język',
    'Ref: Kiosk Fail Title': 'Ups! Coś poszło nie tak!',
    'Ref: Kiosk Fail Description':
      'Niestety, Twoje zamówienie się nie powiodło. Spróbuj ponownie lub poproś pracownika o pomoc.',
    'Ref: Kiosk Fail Back': 'Powrót do poprzedniej strony',
    'Ref: Kiosk Fail Action': 'Wyczyść sesję i zamów ponownie',
    Validate: 'Zweryfikuj',
    'Ref: error, incorrect credentials':
      'Te dane uwierzytelniające nie pasują do wybranego regionu',
    'Ref: Forgot password?': 'Zapomniałeś hasła?',
    'Ref: Hello': 'Witaj {wave_emoji}, proszę zaloguj się, aby kontynuować',
    'Ref: Form title': 'Zarejestruj się, aby kontynuować',
    'Already have an account? Login': 'Posiadasz już konto? Zaloguj się',
  },
  AccountPage: {
    'Ref: My Information': 'Moje informacje',
    'Ref: Settings': 'Ustawienia',
    'Ref: Email': 'E-mail',
    'Ref: Current site': 'Aktualna lokalizacja',
    'Ref: Choose site': 'Wybierz lokalizację',
    'Ref: Personal interests': 'Zainteresowania',
    'Ref: Food preferences': 'Preferencje żywieniowe',
    'Ref: Password': 'Hasło',
    'Ref: Page title': 'Konto',
    'Ref: Language': 'Język',
    'Ref: Communication preferences': 'Preferencje komunikacyjne',
    'Ref: Terms & conditions': 'Zasady i Warunki',
    'Ref: Privacy policy': 'Polityka prywatności',
    'Ref: Delete my account': 'Usuń moje konto',
    'Ref: Confirm deletion': 'Czy na pewno chcesz usunąć swoje konto?',
    'Ref: Request deletion': 'Czy na pewno chcesz poprosić o usunięcie swojego konta?',
    'Ref: Deletion description':
      'Twoja prośba zostanie rozpatrywana zgodnie z lokalnymi przepisami.',
    Placeholder: 'Napisz „delete”',
    'Ref: Delete description': 'Twoje dane zostaną usunięte. Ta akcja jest nieodwracalna.',
    delete: 'usuń',
    'Ref: Terms of sale': 'Warunki sprzedaży',
    'Ref: Imprint': 'Nadruk',
    'Ref: Cookie Settings': 'Ustawienia plików cookie',
    'Ref: Support': 'Support',
    'Ref: Register or login': 'Zarejestruj się lub zaloguj',
    'Ref: Filtering Preferences': 'Preferencje filtrowania',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Czy na pewno chcesz usunąć swoje konto?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Napisz „{word}” w polu wejściowym i potwierdź. Ta akcja jest nieodwracalna.',
    'Ref: Write "{word}"': 'Napisz „{word}”',
    'Ref: Delete my account': 'Usuń moje konto',
    'Ref: Words are not matching': 'Słowa nie pasują',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Wybierz swoją lokalizację, aby rozpocząć',
    'Ref: Choose location':
      'Znajdź szybko lokalizację, aktywując funkcję lokalizacji, skanując kod QR lub wyszukując po nazwie.',
    'Ref: next step': 'Zaczynajmy',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Zapisz się',
    'Ref: identify your location': 'Określ swoją lokalizację.',
    'Ref: option 1 - scan QR': 'Opcja 1: Skanuj rejestracyjny kod QR ',
    'Ref: option 2 - enter code': 'Opcja 2: Ręcznie wprowadź swój kod lokalizacji',
    'Ref: button to open qr code scanner modal': 'Mam QR: zeskanujmy go.',
    'Ref: button to open site code input modal': 'Wybierz stronę z kodem',
    'Ref: getting help with site identifier - support desk':
      'Jeśli nie masz pewności jak postępować, skontaktuj się z działem pomocy technicznej.',
    'Ref: getting help with site identifier - support form': 'Możesz także wysłać nam informację.',
    'Location code': 'Kod lokalizacji',
    'Ref: location code field placeholder': 'Twój kod lokalizacji tutaj',
    'Ref: Scanner header': 'Skanowanie kodu QR',
    'Ref: site code input header': 'Dodaj lokalizację z kodem',
    'Ref: not valid code': 'Ten kod nie jest ważny w wybranym regionie',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Rejestracja',
    'Ref: collected info': 'Podsumowanie',
    'Ref: additional info': 'Twoja informacja',
    'Confirm password': '*Potwierdź hasło',
    company: 'Kto jest Twoim pracodawcą?',
    location: 'Lokalizacja',
    username: 'Nazwa Użytkownika',
    register: 'Zapisz się',
    'Ref: password strength':
      'Twoje hasło musi mieć co najmniej 8 znaków, zawierać co najmniej jedną cyfrę, jedną wielką literę, jedną małą literę i jeden znak specjalny.',
    'passwords do not match': 'Hasła nie są zgodne.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Preferencje komunikacyjne',
    'Ref: Things happen while you are away': 'To się działo, gdy Cię nie było',
    'Ref: Would you like to receive update about offers and promotions?':
      'Czy chciałbyś otrzymywać aktualizacje dotyczące ofert i promocji?',
    'Ref: Would you like to hear about content we think you may like?':
      'Czy chciałbyś usłyszeć o treściach, które naszym zdaniem lubisz?',
    Yes: 'Tak',
    No: 'Nie',
    apply: 'Zastosuj',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Szczegóły Konta' },
  DeletionFailure: {
    'Ref: Body':
      'Wystąpił błąd i prośba o usunięcie konta nie została wysłana. Jeśli ten błąd nadal występuje, skontaktuj się z działem pomocy technicznej.',
    'Go to account': 'Przejdź do konta',
  },
  GenericFailurePage: {
    'Ref: Title': 'Coś poszło nie tak',
    'Ref: Body':
      'Przepraszamy, coś poszło nie tak. Jeśli ten problem nadal występuje, skontaktuj się z działem pomocy technicznej na miejscu.',
  },
  HomePage: {
    notifications: 'powiadomienia',
    basket: 'Koszyk',
    'Ref: Tabtitle': 'Strona główna',
    'contact us': 'Skontaktuj się z nami',
    'Ref: You are logged in as a guest user': 'Jesteś zalogowany jako gość',
    'Ref: Good morning': 'Dzień dobry',
    'Ref: Good afternoon': 'Dzień dobry',
    'Ref: Good evening before midnight': 'Dobry wieczór',
    'Ref: Good evening after midnight': 'Dobry wieczór',
    'Ref: Search': 'Szukaj',
    'Ref: products button aria': 'Znaleziono {results_form} produktów',
    'Ref: menus button aria': 'Znaleziono {results_form} menu',
    'Ref: facilities button aria': 'Znaleziono {results_form} w obiektach',
    'Ref: result': 'wynik',
    'Ref: results': 'wyniki',
    'Ref: Menus': 'Menu',
    'Ref: No results title': 'Ups!',
    'Ref: No results body': 'Brak wyników dla wyszukiwania',
    'Ref: Products': 'Produkty',
    'Ref: Facilities': 'Obiekty',
    'Ref: See more results': 'Zobacz więcej wyników wyszukiwania',
    'Ref: Accessibility instructions':
      'Aby rozpocząć korzystanie z wyszukiwania, musisz go kliknąć lub nacisnąć Enter podczas koncentracji.',
    'Ref: See more': 'Zobacz więcej',
    'Ref: singular form of products': 'produkt',
    'Ref: singular form of menus': 'menu',
    'Ref: singular form of facilities': 'obiekt',
    'Ref: Search results for': 'Wyniki wyszukiwania dla',
  },
  LanguagePage: {
    apply: 'Zastosuj',
    'My language': 'Mój język',
    'Select in which language you would like to read our app':
      'Wybierz, w jakim języku chcesz używać aplikacji',
    'Ref: Page title': 'Język',
  },
  LegalDocPage: {
    Acknowledge: 'Potwierdzenie',
    'Ref: Legal changed notification title': '„{legal_doc_type}” zostały zmodyfikowane',
    'Ref: Legal changed notification content':
      'Prosimy o zapoznanie się z nimi przed kontynuowaniem korzystania z aplikacji.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Zasady i Warunki',
    'Read privacy policy': 'Przeczytaj politykę prywatności',
    'Ref: Acknowledge legal docs': 'Akceptuję niniejsze warunki.',
    'Ref: Read and understood': 'Przeczytałem i zrozumiałem ',
    'Ref: No legal docs available - notice title': 'Wkrótce',
    'Ref: No legal docs available - notice body':
      'Przepraszamy, wygląda na to, że ta aplikacja obecnie nie jest dostępna w Twoim regionie',
    'Ref: The privacy Policy': 'Polityka prywatności',
    'Ref: Legal changed notification title': '„{legal_doc_type}” zostały zmodyfikowane',
    'Ref: Legal changed notification content':
      'Prosimy o zapoznanie się z nimi przed kontynuowaniem korzystania z aplikacji.',
  },
  LoginPage: {
    'Ref: Page title': 'Witamy w {app name} ',
    'Ref: Page subtitle': 'Zaloguj się, aby kontynuować',
    'Ref: Email field placeholder': 'Twój email',
    'Ref: Email field note': 'Nigdy nie udostępnimy Twojego e-maila nikomu.',
    'Ref: Password field title': 'Twoje hasło',
    register: 'Zapisz się',
    'I forgot my password.': 'Zapomniałeś hasła?',
    'I have trouble logging in': 'Problem z logowaniem?',
    'start registration': 'Rozpocznij rejestrację',
    'Ref: Register': 'Zarejestruj się',
    'Ref: Login': 'Zaloguj się',
    'Ref: Connection issue?': 'Problem z połączeniem?',
    'Ref: Connection issue message':
      'Jeśli masz jakikolwiek problem z rejestracją lub logowaniem, skontaktuj się z personelem na miejscu lub z pomocą techniczną.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Witamy w {Site_name}', 'Ref: Begin': 'Rozpocznij' },
  NotificationsList: {
    'Ref: No notifications': 'Na razie nie masz powiadomień',
    'Ref: This week': 'W tym tygodniu',
    'Ref: Earlier': 'Wcześniej',
    'Ref: Page title': 'Powiadomienia',
    'Ref: See more': 'Zobacz więcej',
    'Ref: Mark all read': 'Oznacz wszystkie jako przeczytane',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Zobacz wszystkie powiadomienia' },
  ServicesMenuPage: { 'Ref: Page title': 'Usługi', navigate: 'nawiguj' },
  DeletionSuccess: {
    'Ref: Title': 'Zażądano usunięcia Twojego konta',
    'Ref: Content': 'Zajmiemy się tym w najbliższym czasie. W każdej chwili możesz stracić dostęp.',
    'Log out': 'Wyloguj',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Wrócimy do Ciebie',
    'Ref: Body':
      'Przykro nam, że napotykasz na problemy. Skontaktujemy się z Tobą tak szybko jak to możliwe.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Problem z logowaniem?',
    'Ref: Email field placeholder': 'Twój adres email',
    'Ref: Mobile phone field placeholder': 'Numer telefonu',
    Company: 'Firma',
    'Ref: Company field placeholder': 'Nazwa firmy',
    'Ref: Company field note':
      'Jeśli jesteś konsultantem, jest to firma, dla której rzeczywiście pracujesz (nie pośrednicząca)',
    Location: 'Miasto lub lokalizacja',
    'Ref: Location field placeholder': 'Twoje miejsce pracy',
    'Ref: Location field note':
      'Jeśli miejsce jest poza oczekiwanym obszarem, zapraszamy do używania nazwy, w której obszar jest powszechnie określany.',
    Comment: 'Komentarz',
    'Ref: Comment field placeholder': 'Co poszło nie tak?',
    'Ref: Who is your employer?': 'Kto jest Twoim pracodawcą?',
    'Ref: What village are you staying at?': 'W jakiej wiosce przebywasz?',
    'Ref: Comment field note': 'Wszelkie informacje, które mogą nam pomóc, pomogą Tobie!',
    'Ref: Feedback Content - geography': 'Geografia',
    'Ref: Feedback Content - fullName': 'Nazwa',
    'Ref: Feedback Content - email': 'E-mail',
    'Ref: Feedback Content - phone': 'Telefon',
    'Ref: Feedback Content - company': 'Firma',
    'Ref: Feedback Content - city': 'Nazwa miasta / lokalizacji',
    'Ref: Feedback Content - whatVillage': 'Wieś',
    'Ref: Feedback Content - employer': 'Pracodawca',
    'Ref: Feedback Content - comment': 'Coś poszło nie tak',
  },
  AccountStartPage: {
    'Ref: Header': 'Wybierz swoją lokalizację, aby rozpocząć',
    'Ref: Body':
      'Znajdź szybko lokalizację, aktywując funkcję lokalizacji, skanując kod QR lub wyszukując po nazwie',
    'Ref: LetsGo button': 'Zaczynamy',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Rejestracja' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Zeskanuj kod QR lokalizacji',
    'Ref: Page title': 'Wybierz swoją lokalizację',
    'Ref: Select site with code': 'Wybierz lokalizację wprowadzając kod',
    'Ref: Enter site code': 'Wprowadź kod lokalizacji',
    'Ref: Scanner error header': 'Nie znaleziono lokalizacji',
    'Ref: Not found': 'Ten kod nie jest ważny w wybranej geografii',
    'Ref: Generic error': 'Coś poszło nie tak',
    'All other sites': 'Wszystkie inne lokalizacje',
    'Sites in your country': 'Lokalizacje w Twoim kraju',
    'Ref: Unauthorized error': 'Nieautoryzowane żądanie względem ograniczeń w lokalizacji',
    'Ref: Forbidden':
      'Przepraszamy, nie masz odpowiednich uprawnień do dostępu do tej lokalizacji. Aby uzyskać dostęp, skontaktuj się z Pilbara Service Center pod numerem 1800 992 777',
    "Ref: We didn't find the site.": 'Nie znaleźliśmy lokalizacji.',
    'Ref: We recommend some solutions:': 'Sugerujemy takie rozwiązania:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Spróbuj dodać lokalizację skanując jej kod QR lub za pomocą poniższych przycisków ',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Wyloguj się i zaloguj się, wybierając odpowiedni kraj',
    'Ref: The currently selected country is:': 'Obecnie wybrany kraj to:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Rejestracja',
    'Confirm password': 'Potwierdź hasło',
    'Email is not valid': 'adres email jest nieprawidłowy',
    'passwords do not match': 'Hasła nie pasują do siebie.',
    'Ref: Password strength':
      'Twoje hasło musi mieć co najmniej 8 znaków, zawiera co najmniej jedną liczbę, jedną wielką część, jedną małą literę i jeden charakter specjalny.',
    'Sign up': 'Zarejestruj się',
    'Continue as guest': 'Kontynuuj jako gość',
    'Already have an account? Login': 'Posiadasz już konto? Zaloguj się',
    'Ref: Registration success title': 'Dziękujemy za rejestrację',
    'Ref: Registration success content':
      'Twoje konto zostanie wkrótce utworzone. Otrzymasz e -mail potwierdzający.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Zaloguj się/zarejestruj',
    'Ref: Password strength':
      'Twoje hasło musi mieć co najmniej 8 znaków, zawierać co najmniej jedną cyfrę, jedną wielką literę, jedną małą literę i jeden znak specjalny.',
    Stamps: 'Pieczątki',
    'Order history': 'Historia zamówień',
    'to earn points': 'aby zdobyć punkty',
    'to see order history': 'aby zobaczyć historię zamówienia',
    'to place an order': 'aby złożyć zamówienie',
    'Sign up': 'Zapisz się',
    Login: 'Zaloguj się',
    'Email is not valid': 'adres email jest nieprawidłowy',
    'passwords do not match': 'Hasła nie pasują do siebie.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Dziękuję',
    'Ref: Body':
      'Już prawie gotowe! Wkrótce otrzymasz e-mail weryfikacyjny na podany adres. Otwórz e-mail i kliknij link, aby aktywować konto.',
    'Ref: Enter': 'Kontynuuj',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Wystąpił błąd',
    'Ref: Generic Error Body': 'Spróbuj ponownie później lub skontaktuj się z pomocy techniczną',
    'Ref: Missing IndexDb support':
      'Przepraszamy, nie możesz uzyskać dostępu do aplikacji w trybie incognito lub bez obsługi indexDB. Spróbuj za pomocą innej przeglądarki',
    'Ref: Resource not found': 'Treść, do której próbujesz uzyskać dostęp, nie istnieje.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Treść, którą próbujesz uzyskać dostęp, należy do prywatnej lokalizacji.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Treść, do której próbujesz uzyskać dostęp, nie jest autoryzowana do Twojego konta.',
    'Ref: TabTitle': 'Błąd',
    'Ref: Site link invalid':
      'Ten link jest nieprawidłowy, wybierz inną lokalizację lub skontaktuj się z pomocą techniczną, jeśli problem będzie się utrzymywał.',
    'Ref: Access Denied': 'Brak dostępu. Niewystarczające uprawnienia użytkownika.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Ups! {robot_emoji}',
    'Ref: Error title': 'Coś poszło nie tak!',
    'Ref: Error body':
      'Spróbuj odświeżyć tę stronę lub skontaktować się z pomocą techniczną, jeśli problem będzie się utrzymywał.',
    'Ref: Error ID:': 'Identyfikator błędu: {errorId}',
    'Ref: Back to home': 'Wróć do strony głównej',
  },
  Child: {
    'Ref: Route Not Found':
      'Usługa, której szukasz, nie jest dostępna na tej stronie. Spróbuj ponownie później lub skontaktuj się ze swoim pomocy technicznej.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Gratulacje, Twoje nowe hasło zostało pomyślnie zapisane, możesz teraz uzyskać dostęp do wszystkich naszych usług z konta.',
    Welcome: 'Witaj',
    'Ref: Welcome message':
      'Z powodzeniem zarejestrowałeś się w nowej aplikacji Bite i możesz nadal odkrywać i cieszyć się wszystkimi naszymi usługami.',
  },
  PasswordChange: {
    'Change Password': 'Zmień hasło',
    Welcome: 'Witaj',
    'Ref: Password message':
      'Twoje hasło musi zostać zmienione, ponieważ nie jest wystarczająco bezpieczne',
  },
  AccountPasswordChange: {
    'Change your password here': 'Zmień swoje hasło',
    'Your old password': 'Twoje stare hasło',
    'Type in your new password': 'Wpisz swoje nowe hasło',
    'Re-type in your new password': 'Wpisz ponownie nowe hasło',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'Twoje hasło zostało zmienione',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Wystąpił błąd, niestety Twoja zmiana nie została zapisana. Proszę spróbuj ponownie.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Zresetuj hasło',
    'Ref: Provide email':
      'Podaj adres e-mail używany podczas rejestracji w {app_name}. E-mail zostanie wysłany do Ciebie w celu zresetowania hasła.',
    'Ref: Something happened': 'Coś się stało. Spróbuj ponownie później.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Wysłaliśmy nowy link do zmiany hasła. Poczekaj 30 sekund, zanim wyślesz go ponownie',
    'Go to login page': 'Przejdź do strony logowania',
    'Resend email': 'Ponownie wyślij link do zmiany hasła',
    'Ref: Email sent title': 'Wysłano do Ciebie e-mail z linkiem do zmiany hasła',
    'Ref: TabTitle': 'Zresetuj hasło',
    'Ref: Email sent msg':
      'Link do zmiany hasła został wysłany na Twój adres {email_address}. Poczekaj 30 sekund przed kolejną próbą wysłania.',
  },
  ResetPassword: { 'Enter your new password': 'Wpisz swoje nowe hasło' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Twoje hasło zostało zmienione',
    'Go to login page': 'Przejdź do strony logowania',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Resetowanie hasła nie powiodło się',
    'Ref: Error': 'Wystąpił błąd, spróbuj ponownie',
    'Reset your password': 'Zresetuj swoje hasło',
    'Go to login page': 'Przejdź do strony logowania',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Polityka prywatności',
    'Ref: Privacy Policy Body':
      'Uzyskując dostęp do tej platformy, Sodexo SA i lokalny podmiot Sodexo przetworzą Twoje dane osobowe, aby przynajmniej zarządzać kontem oraz świadczyć usługi i oferty dostępne za pośrednictwem platformy. Jeśli chcesz uzyskać więcej informacji na temat przetwarzania danych osobowych, kto będzie do nich dostępu i jak wykonywać prawa, które masz na podstawie danych osobowych, zapoznaj się z polityką prywatności poniżej i dostępną w dowolnym momencie na koncie.',
    'Ref: Continue': 'Kontynuuj',
    'Ref: Read Privacy Policy': 'Przeczytaj politykę prywatności',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Idź do sklepu' },
  SharePage: { 'Ref: Select a site': 'Wybierz lokalizację' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Aby wyświetlić politykę prywatności, wybierz swój kraj.',
  },
};
export default labels;
