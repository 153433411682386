import classNames from 'classnames';

import { StarFilled, StarOutlined } from '../../../assets/icons';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';

import { RatingStarProps } from './RatingStar.types';

import styles from './RatingStar.module.css';

const DEFAULT_STARS_NUM = 5;

const RatingStar = ({
  question,
  srOnlyLegend,
  groupName = 'star-rating',
  value,
  required,
  onChange,
  starsNum,
  disabled,
  'data-testid': testId,
}: RatingStarProps) => {
  const { label } = useCustomTranslation();

  const nbStars = starsNum || DEFAULT_STARS_NUM;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    onChange(Number(event.target.value));
  };

  const getStarLabel = (index: number) => {
    return index === 0 ? label('Star') : label('Stars');
  };

  return (
    <fieldset
      id={groupName}
      name={groupName}
      className={classNames(styles.questionGroup)}
      data-testid={`${testId}`}
    >
      {question && (
        <legend className={classNames('bodySBold', 'mb-M')} data-testid={`${testId}-legend`}>
          {question}
          {required && ' *'}
        </legend>
      )}
      {srOnlyLegend && (
        <legend className="sr-only" data-testid={`${testId}-accessible-legend`}>
          {srOnlyLegend}
        </legend>
      )}
      {[...Array(nbStars)].map((_, index) => (
        <div key={`${groupName}-${index}`}>
          <input
            type="radio"
            checked={index + 1 === value}
            name={groupName}
            value={index + 1}
            id={`${groupName}-input-${index}`}
            onChange={handleOnChange}
            className="sr-only"
            aria-disabled={disabled}
            required={required}
            data-testid={`${testId}-star-${index}-input`}
          />
          <label
            htmlFor={`${groupName}-input-${index}`}
            data-testid={`${testId}-star-${index}-label`}
          >
            <span className="sr-only" data-testid={`${testId}-star-${index}-accessible-label`}>{`${
              index + 1
            } ${getStarLabel(index)}`}</span>
            {index < value ? <StarFilled /> : <StarOutlined />}
          </label>
        </div>
      ))}
    </fieldset>
  );
};

export default RatingStar;
