import Checkbox from '../../../../../components/atoms/Checkbox';
import CheckboxGroup from '../../../../../components/molecules/CheckboxGroup';

import { MultiChoiceProps } from './MultiChoice.types';

const MultiChoice = ({ id, choices, value, "data-testid": testId, ...props }: MultiChoiceProps) => {
  return (
    <CheckboxGroup
      {...props}
      name={id}
      value={value}
      onClick={(e: React.FormEvent<HTMLInputElement>, v: string[]) => {}}
    >
      {choices.map((choice: string) => (
        <CheckboxGroup.Controller
          key={`${id}_${choice}`}
          controlId={`${id}_${choice}`}
          label={choice}
          value={choice}
          data-testid={`${testId}-checkbox-group`}
          control={<Checkbox data-testid={`${testId}-checkbox`} />}
        />
      ))}
    </CheckboxGroup>
  );
};

export default MultiChoice;
