const labels = {
  All: {
    dailyMenu: '未知',
    anytime: '任何时间',
    breakfast: '早餐',
    morningBreak: '上午休息',
    lunch: '午餐',
    afternoonBreak: '下午休息',
    dinner: '晚餐',
    supper: '晚餐',
    nightMeal: '晚饭',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': '菜单',
    'Ref: View details': '查看详情',
    'Ref: Facility details': '设施详情',
  },
  OpeningHours: { everyday: '每天', 'open all day': '全天开放' },
  ProductsList: {
    'Ref: Page title': '菜单',
    'Ref: Facility details': '设施详情',
    'Ref: Search products list': '搜索项目',
    'Ref: filter label: date': '日期',
    'Ref: filter label: menus': '菜单',
    'Ref: filter label: moment': '片刻',
    'Ref: filter label: category': '类别',
    'Ref: filter label: dishes': '菜肴',
    'Ref: filter label: highlight': '突出显示或隐藏餐具的过滤器',
    All: '全部',
  },
  ProductDetails: { 'Ref: Page title': '产品详情' },
  Widget: {
    'Ref: Carousel title': '菜单',
    'No info yet, come back later': '暂无信息，请稍后再来😉',
    'Ref: menus': '菜单',
    'Ref: see all': '查看全部',
  },
};
export default labels;
