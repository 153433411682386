const labels = {
  All: {
    'starts at': 'เริ่มต้นที่',
    'ends at': 'สิ้นสุดที่',
    location: 'ที่ตั้ง',
    'coming date': 'วันที่แล้ว',
    'Ref: Currently event not preferred. Click to make preferred':
      'ปัจจุบันไม่ต้องการเหตุการณ์ คลิกเพื่อทำที่ต้องการ',
    'Ref: Currently event preferred. Click to make not preferred':
      'ปัจจุบันมีกิจกรรมที่ต้องการ คลิกเพื่อให้ไม่ต้องการ',
  },
  EventDetails: {
    details: 'รายละเอียด',
    'Ref: Page title': 'รายละเอียดเหตุการณ์',
    'Event dates': 'วันที่กิจกรรม',
    'See online:': 'ดูออนไลน์:',
    'See all dates': 'ดูวันที่ทั้งหมด',
    'Remove from my selection': 'ลบออกจากการเลือกของฉัน',
    'Add to my selection': 'เพิ่มลงในที่ฉันต้องการ',
    'Ref: explain calendar usage':
      'วันที่เหตุการณ์จะถูกไฮไลต์ในปฏิทินด้านล่าง อย่าพลาดเหตุการณ์ที่คุณชอบ! คลิกวันที่กิจกรรมในปฏิทินเพื่อดาวน์โหลดคำเชิญ จากนั้นเปิดไฟล์ที่ดาวน์โหลดเพื่อเพิ่มกิจกรรมลงในปฏิทินที่คุณต้องการ',
    'Ref: Favorite': 'ที่ชื่นชอบ',
    'Ref:Feedback': 'ข้อเสนอแนะ',
    'Ref: Event site different title': 'เหตุการณ์นี้ไม่ได้เป็นของเว็บไซต์ปัจจุบันของคุณ',
    'Ref: Event site different description':
      'คุณกำลังดูเหตุการณ์ที่ไม่ได้เป็นของเว็บไซต์ปัจจุบันของคุณ คุณจะไม่สามารถดูได้ในรายการเหตุการณ์',
    'Ref: See all dates': 'ดูวันที่ทั้งหมด',
    'Ref: From': 'จาก',
    'Ref: to': 'ถึง',
    'Ref: Coming date': 'วันที่มา',
    'Ref: Add to calendar': 'เพิ่มไปยังปฏิทิน',
  },
  EventsList: {
    'Ref: Page title': 'เหตุการณ์ที่เกิดขึ้น',
    'All dates': 'วันที่ทั้งหมด',
    'All events': 'เหตุการณ์ทั้งหมด',
    'Ref: Search': 'ค้นหา',
    'My selection': 'ที่ฉันต้องการ',
    'filter label: date': 'วันที่',
    'filter label: coming events': 'กิจกรรมที่กำลังจะมาถึง',
    'filter label: past events': 'เหตุการณ์ที่ผ่านมา',
    'filter label: interest': 'ความพึงใจ',
  },
  Widget: {
    'Ref: Carousel title': 'เหตุการณ์ที่เกิดขึ้น',
    'No info yet, come back later': 'ยังไม่มีข้อมูลกลับมาทีหลัง😉',
  },
};
export default labels;
