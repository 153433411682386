const labels = {
  All: {
    'starts at': '開始',
    'ends at': '結束',
    location: '位置',
    'coming date': '下一次日期',
    'Ref: Currently event not preferred. Click to make preferred': '當前事件不是首選。單擊以使首選',
    'Ref: Currently event preferred. Click to make not preferred':
      '當前的活動優先。點擊以使不是首選',
  },
  EventDetails: {
    details: '細節',
    'Ref: Page title': '事件詳細信息',
    'Event dates': '活動日期',
    'See online:': '在線查看：',
    'See all dates': '查看所有日期',
    'Remove from my selection': '從我的選擇中刪除',
    'Add to my selection': '加入我的首選',
    'Ref: explain calendar usage':
      '活動日期在下面的日曆中突出顯示。不要錯過你喜歡的活動！單擊日曆中的事件日期以下載邀請。然後打開下載的文件以將事件添加到Preffered日曆中。',
    'Ref: Favorite': '最喜歡的',
    'Ref:Feedback': '反饋',
    'Ref: Event site different title': '此事件不屬於您當前的站點',
    'Ref: Event site different description':
      '您正在查看不屬於您當前網站的事件。您將無法在事件列表中看到它。',
    'Ref: See all dates': '查看所有日期',
    'Ref: From': '從',
    'Ref: to': '到',
    'Ref: Coming date': '後續日期',
    'Ref: Add to calendar': '添加到日曆',
  },
  EventsList: {
    'Ref: Page title': '活動',
    'All dates': '所有日期',
    'All events': '所有活動',
    'Ref: Search': '搜索',
    'My selection': '我的首選',
    'filter label: date': '日期',
    'filter label: coming events': '即將到來的活動',
    'filter label: past events': '過去的事件',
    'filter label: interest': '偏好',
  },
  Widget: {
    'Ref: Carousel title': '活動',
    'No info yet, come back later': '暫無信息，請稍後再來😉',
  },
};
export default labels;
