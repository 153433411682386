import { IonCard, IonCardTitle, IonCardContent, IonCardSubtitle } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';

import './ChatMessage.css';

import { TestingPropsV2 } from 'src/types';

export type ChatMessageProps = {
  author: string;
  message: string;
  date: string;
  actions?: React.ReactNode;
  inheritStyle?: string;
} & TestingPropsV2;

const ChatMessage = ({
  author,
  message,
  date,
  actions,
  inheritStyle = '',
  'data-testid': testId,
}: ChatMessageProps) => (
  <IonCard className={'chatMessage '.concat(inheritStyle)} data-testid={`${testId}-chat-msg`}>
    <IonCardTitle className={classNames('bodySBold')} data-testid={`${testId}-chat-msg-author`}>
      {author}
    </IonCardTitle>
    <IonCardSubtitle className={classNames('bodySDefault')} data-testid={`${testId}-chat-date`}>
      {date}
    </IonCardSubtitle>
    <IonCardContent>
      <div className={classNames('bodySDefault')} data-testid={`${testId}-chat-msg-content`}>
        {message}
      </div>
      {actions && (
        <div className={classNames('actionsContainer')} data-testid={`${testId}-chat-msg-actions`}>
          {actions}
        </div>
      )}
    </IonCardContent>
  </IonCard>
);

export default ChatMessage;
