import { apiPath, dspApiPath } from '../../config';
import { APP_NAME } from '../../constants';
import { getAppSpecificData } from '../../helpers/misc';
import { serviceTypes, modulesConfig } from '../config';

import { ContentConfigType } from './types';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';
import { REACTIONS } from '@/types/reactions.types';

export const moduleId = 'Content';
export const cacheVersion = '0';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  ContentDetails: modulePath + '/:id/details',
};

export const contentConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      api: baseApi,
      baseUrl: apiPath,
      gethomeUrl: '/v2/content/homepage',
      contentsUrl: (_id: string) => `/v2/content`,
      reactionUrl: (_id: string) => '/v2/content/reaction',
      getHomeParams: (
        startDate: string,
        endDate: string,
        siteId: string,
        languageCode: string,
        contractId?: string
      ) => ({ startDate, endDate, siteId, languageCode, contractId }),
      getContentParams: (id: string, siteId: string) => ({ id, siteId }),
      updateReactionParams: (id: string, reactionType: REACTIONS | null) => ({ id, reactionType }),
      deleteContentParams: (id: string) => ({ id }),
      isReactionsEnabled: false,
      isCategoryEnabled: true,
      promotionsWidgetTitle: 'Ref: MyVillage Carousel title promo',
    },
    default: {
      api: dspApi,
      baseUrl: dspApiPath,
      gethomeUrl: '/v1/contents/homepage',
      contentsUrl: (id: string) => `/v1/contents/${id}`,
      reactionUrl: (id: string) => `/v1/contents/${id}/reaction`,
      getHomeParams: (
        _startDate: string,
        _endDate: string,
        siteId: string,
        _languageCode: string,
        _contractId?: string
      ) => ({ siteId }),
      getContentParams: (_id: string, _siteId: string) => undefined,
      updateReactionParams: (_id: string, reactionType: REACTIONS | null) => ({ reactionType }),
      deleteContentParams: (_id: string) => undefined,
      isReactionsEnabled: true,
      isCategoryEnabled: false,
      promotionsWidgetTitle: 'Ref: Carousel title promo',
    },
  };

  return getAppSpecificData<ContentConfigType>(configuration);
};
