const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Ref: AccommodationRequestTitle',
    'Ref: Describe your request': 'Ref: Describe your request',
    'Ref: Confirm your request': 'Ref: Confirm your request',
    'Ref: Your request': 'Ref: Your request',
    'Ref: Summary': 'Ref: Summary',
    'Ref: Next': 'Ref: Next',
    'Ref: Submit': 'Ref: Submit',
    'Ref: Me': 'Ref: Me',
    'Ref: Arrival date': 'Ref: Arrival date',
    'Ref: Arrival time': 'Ref: Arrival time',
    'Ref: Departure date': 'Ref: Departure date',
    'Ref: Departure time': 'Ref: Departure time',
    'Ref: request raised for': 'Ref: request raised for',
    'Ref: phoneNumber': 'Ref: phoneNumber',
    'Ref: arrivalDate': 'Ref: arrivalDate',
    'Ref: departureDate': 'Ref: departureDate',
    'Ref: description': 'Ref: description',
    'Ref: open': 'Ref: open',
    'Ref: pending': 'Ref: pending',
    'Ref: refused': 'Ref: refused',
    'Ref: closed': 'Ref: closed',
    'Ref: all': 'Ref: all',
    'Ref: Arrival': 'Ref: Arrival',
    'Ref: Validate': 'Ref: Validate',
    'Ref: site': 'Ref: site',
    'Ref: Update us': 'Ref: Update us',
    'Ref: email of guest': 'Ref: email of guest',
    'Ref: GuestAccommodationRequestTitle': 'Ref: GuestAccommodationRequestTitle',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Ref: NewAccommodationRequest',
    'Ref: Status': 'Ref: Status',
    'Ref: Date': 'Ref: Date',
    'Ref: Coming arrivals': 'Ref: Coming arrivals',
    'Ref: Past arrivals': 'Ref: Past arrivals',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Ref: Guest phone number',
    'Ref: Your phone number': 'Ref: Your phone number',
    'Ref: The phone number': 'Ref: The phone number',
    'Ref: PhoneNumber error msg': 'Ref: PhoneNumber error msg',
    'Ref: Comment error msg': 'Ref: Comment error msg',
    'Ref: Guest first name': 'Ref: Guest first name',
    'Ref: Guest last name': 'Ref: Guest last name',
    'Ref: request for': 'Ref: request for',
    'Ref: request for me': 'Ref: request for me',
    'Ref: request for guest': 'Ref: request for guest',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Ref: AccommodationRequestDetails',
    'Ref: Timeline': 'Ref: Timeline',
    'Ref: No updates placeholder': 'Ref: No updates placeholder',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Ref: Thank you',
    'Ref: Success text': 'Ref: Success text',
    'Ref: Go to home': 'Ref: Go to home',
    'Ref: Create another request': 'Ref: Create another request',
  },
  CreateError: {
    'Ref: Create error title': 'Ref: Create error title',
    'Ref: Create error text': 'Ref: Create error text',
    'Ref: Retry': 'Ref: Retry',
  },
  UpdateUs: { 'Ref: Update us msg': 'Ref: Update us msg' },
};
export default labels;
