const labels = {
  ContentDetails: {
    'Ref: Page title': 'Ref: Page title',
    'See the event': 'See the event',
    'Take the survey': 'Take the survey',
    Date: 'Date',
    Category: 'Category',
    'Ref: Content site different title': 'Ref: Content site different title',
    'Ref: Content site different description': 'Ref: Content site different description',
  },
  ContentList: {
    'Ref: Page title': 'Ref: Page title',
    'featured (as in featured content)': 'featured (as in featured content)',
    'Ref: category - all': 'Ref: category - all',
    'Ref: category - promotional': 'Ref: category - promotional',
    'Ref: category - featured': 'Ref: category - featured',
    'Ref: category - other': 'Ref: category - other',
  },
  Widget: {
    'Ref: Carousel title': 'Ref: Carousel title',
    'Ref: Carousel title promo': 'Ref: Carousel title promo',
    'No info yet, come back later': 'No info yet, come back later',
    'Ref: Content': 'Ref: Content',
    'Ref: Widget Title': 'Ref: Widget Title',
    'Ref: MyVillage Carousel title promo': 'Ref: MyVillage Carousel title promo',
  },
};
export default labels;
