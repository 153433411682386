import { FC, useMemo } from 'react';
import { useHistory } from 'react-router';

import { useToggleInterestMutation } from '../../api';
import { eventsConfig, pagePaths } from '../../config';
import { useToggleReaction } from '../../hooks';

import { EventTileProps } from './EventTile.types';

import { ImageDefaultNoFoodImg } from '@/assets/images';
import { ShareIcon, ThumbsUpIcon } from '@/assets/index';
import ImageWithFallback from '@/components/atoms/ImageWithFallback/ImageWithFallback';
import StarAction from '@/components/atoms/StarAction/StarAction';
import ReactionsSummary from '@/components/molecules/ReactionsSummary/ReactionsSummary';
import { Tile, TileAction } from '@/components/molecules/Tile';
import Reactions from '@/components/organisms/Reactions';
import { reactionsWithLabels } from '@/helpers/reactions.helpers';
import useSite from '@/modules/Core/hooks/useSite';

const EventTile: FC<EventTileProps> = ({
  event,
  label,
  tooltipId,
  triggerShareModal,
  'data-testid': dataTestId = 'event-tile',
  className,
}) => {
  const { isInterestEnabled, isReactionsEnabled } = useMemo(() => eventsConfig(), []);
  const { id: siteId } = useSite({ throwWhenNoActiveSite: true })!;
  const history = useHistory();

  const [toggleInterest] = useToggleInterestMutation();

  const [toggleReaction] = useToggleReaction();

  const image = useMemo(
    () => (
      <ImageWithFallback
        data-testid={dataTestId}
        imgInfo={event.mainImage ? { src: event.mainImage, alt: event.name } : undefined}
        imgElement={<ImageDefaultNoFoodImg />}
      />
    ),
    [event.name, event.mainImage, dataTestId]
  );

  const path = useMemo(() => pagePaths['EventDetails'].replace(':id', event.id), [event.id]);

  const actions = useMemo(() => {
    const returnValue: TileAction[] = [
      {
        name: 'ShareEvent',
        icon: <ShareIcon />,
        onClick: () => triggerShareModal(event.name, path),
        'data-testid': 'event-tile-share-modal-action',
      },
    ];

    if (isReactionsEnabled) {
      returnValue.push({
        name: 'Reaction',
        'data-testid': 'event-tile-reaction-action',
        customAction: (
          <Reactions.List
            tooltipId={tooltipId}
            alignRight
            userReaction={event.reactions.me}
            data-testid="event-tile-reaction"
            reactions={reactionsWithLabels(label, event.reactions.reactions)}
            handleReactionClick={(reaction) => toggleReaction(reaction, event, siteId ?? '')}
            mainButtonValues={{
              icon: ThumbsUpIcon,
              label: label('Ref: Reaction - LIKE'),
            }}
          />
        ),
      });
    }
    return returnValue;
  }, [
    event,
    isReactionsEnabled,
    siteId,
    label,
    path,
    toggleReaction,
    triggerShareModal,
    tooltipId,
  ]);

  return (
    <Tile
      key={`${event.id}_${event.reactions?.me}`}
      className={className}
      data-testid={dataTestId}
      title={event.name}
      image={image}
      description={
        event.location && event.description ? (
          <span>
            <strong>{event.location}</strong> • {event.description}
          </span>
        ) : (
          <strong>{event.location}</strong> || event.description
        )
      }
      onClick={() => history?.push(path)}
      preventBubblingForCorner
      cornerContent={
        isInterestEnabled ? (
          <StarAction
            isSelected={event.isUserInterested}
            title={label(
              !event.isUserInterested
                ? 'Ref: Currently event not preferred. Click to make preferred'
                : 'Ref: Currently event preferred. Click to make not preferred'
            )}
            onClick={() =>
              toggleInterest({
                eventId: event.id,
                isUserInterested: event.isUserInterested,
                siteId,
              })
            }
            data-testid={`event-tile-star-action-${event.id}`}
          />
        ) : null
      }
      belowDescription={
        isReactionsEnabled ? <ReactionsSummary tooltipId={tooltipId} {...event.reactions} /> : <></>
      }
      actions={actions}
    />
  );
};

export default EventTile;
