const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Solicitação de acomodação',
    'Ref: Describe your request': 'Descreva seu pedido',
    'Ref: Confirm your request': 'Confirme seu pedido',
    'Ref: Your request': 'Seu pedido',
    'Ref: Summary': 'Resumo',
    'Ref: Next': 'Próximo',
    'Ref: Submit': 'Enviar',
    'Ref: Me': 'Meu',
    'Ref: Arrival date': 'Data de chegada',
    'Ref: Arrival time': 'Tempo de chegada',
    'Ref: Departure date': 'Data de partida',
    'Ref: Departure time': 'Hora de partida',
    'Ref: request raised for': 'A pessoa afetada por este pedido',
    'Ref: phoneNumber': 'Número de telefone',
    'Ref: arrivalDate': 'Chegada',
    'Ref: departureDate': 'Partida',
    'Ref: description': 'Comente',
    'Ref: open': 'Abrir',
    'Ref: pending': 'Pendente',
    'Ref: refused': 'Recusou',
    'Ref: closed': 'Fechado',
    'Ref: all': 'Todos',
    'Ref: Arrival': 'Chegada',
    'Ref: Validate': 'Validar',
    'Ref: site': 'Localização do site',
    'Ref: Update us': 'Atualize -nos',
    'Ref: email of guest': 'E-mail do convidado',
    'Ref: GuestAccommodationRequestTitle': 'Solicitação de convidado',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Novo pedido',
    'Ref: Status': 'Status',
    'Ref: Date': 'Data',
    'Ref: Coming arrivals': 'Chegando chegando',
    'Ref: Past arrivals': 'Chegadas anteriores',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Número de telefone de convidado',
    'Ref: Your phone number': 'Seu número de telefone',
    'Ref: The phone number': 'O numero do telefone',
    'Ref: PhoneNumber error msg': 'O número de telefone não é válido',
    'Ref: Comment error msg': 'O número máximo de caracteres é 250',
    'Ref: Guest first name': 'Primeiro nome do convidado',
    'Ref: Guest last name': 'Sobrenome do convidado',
    'Ref: request for': 'Para quem é esse pedido?',
    'Ref: request for me': 'Meu',
    'Ref: request for guest': 'Alguém',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Detalhes da solicitação de acomodação',
    'Ref: Timeline': 'Linha do tempo',
    'Ref: No updates placeholder': 'Não há atualizações para este pedido',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Obrigado',
    'Ref: Success text':
      'Recebemos seu pedido e estamos analisando agora. Nós voltaremos para você em breve.',
    'Ref: Go to home': 'Vá para casa',
    'Ref: Create another request': 'Crie outra solicitação',
  },
  CreateError: {
    'Ref: Create error title': 'Opa!',
    'Ref: Create error text': 'Ocorreu um erro, por favor, tente novamente.',
    'Ref: Retry': 'Tente novamente',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Por favor, atualize -nos com qualquer novo elemento sobre sua solicitação para que possamos apoiá -lo melhor.',
  },
};
export default labels;
