import { useMemo } from 'react';

import { WIDGET_TYPE } from '../../../constants';
import { WidgetType } from '../../../types';
import { serviceTypes } from '../../config';
import { ActiveModule } from '../types/homePage.types';

import { Service } from '@/modules/Core/types/State.types';

function getActiveModulesId(services: Service[]) {
  const modulesId: ActiveModule[] = [];
  for (const service of services) {
    const defaultModuleId = serviceTypes[service.name]?.defaultModule;
    if (defaultModuleId) {
      modulesId.push({ moduleId: defaultModuleId, service: service.name });
    }
  }

  return modulesId;
}

export const useWidgets = ({
  services = [],
  isGuest = false,
}: {
  services: Service[];
  isGuest: boolean;
}) => {
  return useMemo(() => {
    const modules = getActiveModulesId(services);
    let widgets: WidgetType[] = [];
    let miniWidgets: WidgetType[] = [];
    const alreadyProcessedModules: Record<string, boolean> = {};
    const availableServices = modules.map((x) => x.service);

    for (const module of modules) {
      if (alreadyProcessedModules[module.moduleId]) continue;

      let moduleWidgets: WidgetType[] = [];

      try {
        moduleWidgets = require('../../' + module.moduleId + '/widgets').widgets;
      } catch (e) {
        // no widgets in this module
        continue;
      }

      moduleWidgets = moduleWidgets.filter(
        (w) =>
          isWidgetSupportedInService(availableServices, w.supportedServices) &&
          !isWidgetHiddenForGuest(w.hideForGuest, isGuest)
      );

      widgets.push(...moduleWidgets.filter((w) => w.type === WIDGET_TYPE.NORMAL));
      miniWidgets.push(...moduleWidgets.filter((w) => w.type === WIDGET_TYPE.MINI));
      alreadyProcessedModules[module.moduleId] = true;
    }

    return { widgets, miniWidgets };
  }, [isGuest, services]);
};

const isWidgetSupportedInService = (availableServices: string[], supportedServices?: string[]) => {
  if (!supportedServices) {
    return true;
  }

  return supportedServices.some((s) => availableServices.includes(s));
};

const isWidgetHiddenForGuest = (hideForGuest: boolean | undefined, isGuest: boolean) => {
  return isGuest && hideForGuest;
};
