import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { LocalNotificationSchema, LocalNotifications } from '@capacitor/local-notifications';
import moment from 'moment';

import { apiPath } from '../../../config';
import { endpoints } from '../api';
import { AuditInvite, AuditStatus } from '../types';

import { CacheService } from '@/services/CacheService';
import { OfflineQueueService } from '@/services/OfflineQueueService';

let listener: any = null;
const unsynchronisedNotificationId = 148621353;
const unsubmittedNotificationId = 148621354;

export const auditNotifications = async () => {
  const platform = Capacitor.getPlatform();
  if (platform === 'web') return;

  if (listener) return;

  /**
   * Setup a listener to intercept app state changes, for instance when the user
   * closes the app
   */
  console.log('[AUDIT NOTIFICATIONS] Setup state listener');
  listener = await App.addListener('appStateChange', async ({ isActive }) => {
    /**
     * When `isActive` is false it means the user closed the app, so we can check for
     * unsynchronised audits
     */
    if (!isActive) {
      console.log('[AUDIT NOTIFICATIONS] State changed to not active');
      const auditsQueue = OfflineQueueService.getInstance();
      const cache = CacheService.getInstance();

      const notifications: LocalNotificationSchema[] = [];

      const pendingAudits = await auditsQueue.getAll();
      const pending = await LocalNotifications.getPending();
      const unsynchronisedNotification = pending.notifications.filter(
        ({ id }) => id === unsynchronisedNotificationId
      );
      const unsubmittedNotification = pending.notifications.filter(
        ({ id }) => id === unsubmittedNotificationId
      );

      const auditInvites: AuditInvite[] = await cache.getValue(apiPath + endpoints.getAuditInvites);

      /**
       * If there are audits in the local queue and the notifications haven't been scheduled yet
       * we create a new notifications schedule
       */
      if (pendingAudits.length > 0 && unsynchronisedNotification.length === 0) {
        console.log(
          '[AUDIT NOTIFICATIONS] Pending audits found',
          auditsQueue,
          pending.notifications
        );

        notifications.push({
          id: unsynchronisedNotificationId,
          // TODO: investigate best approach to translate the notification
          title: 'Audits',
          body: 'You still have unsynchronised audits, click here to synchronise them',
          extra: { regardingEntity: 'Audit' },
          schedule: {
            repeats: true,
            every: 'day',
          },
        });
      }

      /**
       * Search audit invites cache for audits that are in Open state
       * for longer than the defined time
       */
      const openAuditInvites = auditInvites?.filter(({ id, state, dueDate }) => {
        const created = moment(dueDate).add(2, 'days').unix();
        return state === AuditStatus.OPEN && created < Date.now();
      });

      /**
       * If there are open audits in cache and the notifications haven't been scheduled yet
       * we create a new notifications schedule
       */
      if (openAuditInvites.length > 0 && unsubmittedNotification.length === 0) {
        console.log('[AUDIT NOTIFICATIONS] Found open audit for more than the defined time', {
          openAuditInvites: openAuditInvites.map(({ id }) => id),
          numberOfAudit: openAuditInvites.length,
        });

        notifications.push({
          id: unsubmittedNotificationId,
          // TODO: investigate best approach to translate the notification
          title: 'Audits',
          body: 'You still have unsubmitted audits, click here to submit them as soon as possible',
          extra: { regardingEntity: 'Audit' },
          schedule: {
            repeats: true,
            every: 'day',
          },
        });
      }

      if (notifications.length > 0) {
        LocalNotifications.schedule({ notifications });
      }

      /**
       * If there are no items in the queue we can just clear the pending notifications
       */
      if (pendingAudits.length === 0 && unsynchronisedNotification.length > 0) {
        console.log('[AUDIT NOTIFICATIONS] Cancel pending notifications due to no audits found');
        await LocalNotifications.cancel({ ...pending, notifications: unsynchronisedNotification });
      }

      /**
       * If there are no unsbumitted items we can just clear the pending notifications
       */
      if (openAuditInvites.length === 0 && unsubmittedNotification.length > 0) {
        console.log(
          '[AUDIT NOTIFICATIONS] Cancel pending notifications due to no open audits for more than 2 days'
        );
        await LocalNotifications.cancel({ ...pending, notifications: unsubmittedNotification });
      }
    }
  });
};

auditNotifications();
