const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Demande de logement',
    'Ref: Describe your request': 'Décrivez votre demande',
    'Ref: Confirm your request': 'Confirmez votre demande',
    'Ref: Your request': 'Votre demande',
    'Ref: Summary': 'Résumé',
    'Ref: Next': 'Suivant',
    'Ref: Submit': 'Soumettre',
    'Ref: Me': 'Moi',
    'Ref: Arrival date': "Date d'arrivée",
    'Ref: Arrival time': "Heure d'arrivée",
    'Ref: Departure date': 'Date de départ',
    'Ref: Departure time': 'Heure de départ',
    'Ref: request raised for': 'La personne concernée par cette demande',
    'Ref: phoneNumber': 'Numéro de téléphone',
    'Ref: arrivalDate': 'Arrivée',
    'Ref: departureDate': 'Départ',
    'Ref: description': 'Commentaire',
    'Ref: open': 'Ouvert',
    'Ref: pending': 'En attente',
    'Ref: refused': 'Refusé',
    'Ref: closed': 'Fermé',
    'Ref: all': 'Tous',
    'Ref: Arrival': 'Arrivée',
    'Ref: Validate': 'Valider',
    'Ref: site': "L'emplacement du site",
    'Ref: Update us': 'Me mettre à jour',
    'Ref: email of guest': "E-mail de l'invité",
    'Ref: GuestAccommodationRequestTitle': "Demande d'invité",
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Nouvelle demande',
    'Ref: Status': 'Statut',
    'Ref: Date': 'Date',
    'Ref: Coming arrivals': 'Arrivées à venir',
    'Ref: Past arrivals': 'Arrivées passées',
  },
  CreateForm: {
    'Ref: Guest phone number': "Numéro de téléphone de l'invité",
    'Ref: Your phone number': 'Votre numéro de téléphone',
    'Ref: The phone number': 'Le numéro de téléphone',
    'Ref: PhoneNumber error msg': "Le numéro de téléphone n'est pas valide",
    'Ref: Comment error msg': 'Le nombre maximum de caractères est de 250',
    'Ref: Guest first name': 'Prénom invité',
    'Ref: Guest last name': 'Nom invité',
    'Ref: request for': 'Pour qui est cette demande?',
    'Ref: request for me': 'Moi',
    'Ref: request for guest': "Quelqu'un d'autre",
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Détails de la demande de logement',
    'Ref: Timeline': 'Chronologie',
    'Ref: No updates placeholder': "Il n'y a pas de mises à jour pour cette demande",
  },
  CreateThankYou: {
    'Ref: Thank you': 'Merci',
    'Ref: Success text':
      "Nous avons reçu votre demande et nous l'examinons. Nous reviendrons vers vous bientôt.",
    'Ref: Go to home': "Aller à l'accueil",
    'Ref: Create another request': 'Créer une autre demande',
  },
  CreateError: {
    'Ref: Create error title': 'Oops!',
    'Ref: Create error text': "Une erreur s'est produite, veuillez réessayer.",
    'Ref: Retry': 'Essayer à nouveau',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Veuillez nous mettre à jour avec tout nouvel élément sur votre demande afin que nous puissions au mieux vous soutenir.',
  },
};
export default labels;
