const labels = {
  All: {
    'Ref: See item': 'Öğeye bakın',
    'Ref: Print': 'Makbuzunuzu yazdırın',
    'Ref: Email': 'Makbuzunuzu e-posta ile alın',
    'Ref: Receipt Modal message': 'Makbuzunuzu nasıl almak istersiniz?',
    'Ref: Loyalty Reward': 'Sadakat ödülü',
    'Ref: Equation for stamps per item': '1 satın alma = 1 damga',
    'Ref: Paid by employer': 'İşvereniniz tarafından ödenen miktar',
    'Ref: Paid by user': 'Sizin tarafınızdan ödenen miktar',
  },
  ProductsList: {
    'Ref: Page title': 'Ürün listesi',
    'Ref: Facility details': 'Tesis detayları',
    'Ref: Search products list': 'Arama öğeleri',
    'Ref: filter label: menus': 'Menü',
    'Ref: filter label: moment': 'An',
    'Ref: filter label: category': 'Kategori',
    'Ref: all': 'Tümü',
    'Ref: today': 'Bugün',
    'Ref: tomorrow': 'Yarın',
    'Ref: see more': 'Daha fazlasını görün',
    'Ref: see less': 'Daha az görün',
    'Ref: reset': 'Sıfırla',
    'Ref: View in cart': 'Sepette görüntüle',
    'Ref: You have changed location': 'Konumunuzu değiştirdiniz',
    'Ref: You are now at': 'Şu anda',
    'Ref: Menu card': 'Menü kartı',
    'Ref: Table': 'Tablo numarası',
    'Service unavailable': 'Servis mevcut değil',
    'Ref: Ordering unavailable':
      'Dikkatli olun! Bu siteden şu anda sipariş verilememektedir, lütfen daha sonra deneyin.',
    'Ref: Filters': 'Filtreler (Alerjenler ve daha fazlası)',
  },
  Order: { 'Ref: Page title': 'Sipariş' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menüler',
    'Ref: today': 'Bugün',
    'Ref: tomorrow': 'Yarın',
    'Ref: Search products list': 'Arama öğeleri',
    'Ref: You have changed location': 'Konumunuzu değiştirdiniz',
    'Ref: You are now at': 'Şu anda',
  },
  ProductDetails: {
    'Ref: Page title': 'Ürün Detayları',
    'Ref: Serving Size': 'Porsiyon Büyüklüğü',
    'Ref: Nutrition': 'Beslenme bilgileri',
    'Ref: Nutrition adults': "Yetişkinlerin günde yaklaşık 2000 kcal'ye ihtiyacı vardır",
    'Ref: Nutrition Calories': 'Kalori',
    'Ref: Nutrition Calcium': 'Kalsiyum',
    'Ref: Nutrition Fat': 'Şişman',
    'Ref: Nutrition Saturated Fat': 'Doymuş yağ',
    'Ref: Nutrition Carbohydrates': 'Karbonhidratlar',
    'Ref: Nutrition Sugar': 'Şeker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodyum',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Diyet Lifleri',
    'Ref: Nutrition iron': 'Demir',
    'Ref: Nutrition potassium': 'Potasyum',
    'Ref: Nutrition vitaminA': 'A Vitamini',
    'Ref: Nutrition vitaminC': 'C Vitamini',
    'Ref: Additives': 'Katkı maddeleri',
    'Ref: Emission': 'CO2 emisyonları',
    'Ref: This product generates': 'Bu ürün şunları üretir',
    'Ref: of CO2': "CO2'nin",
    'Ref: emissions, which equals a': 'emisyonlarına eşittir.',
    'Ref: car trip': 'araba yolculuğu',
    'Ref: Ingredients': 'Tarif malzemesi',
    'Ref: Allergens': 'Alerjenler',
    'Ref: Always there': 'Her zaman orada',
    'Ref: Allergens selected': ' filtre üzerinde seçilen alerjenler: ',
    'Ref: minimum required': 'minimum gerekli',
    'Ref: you have to add at least': 'En azından şunları eklemelisiniz',
    'Ref: ingredients': 'malzemeler',
    'Ref: Ingredient': 'içerik',
    'Ref: Free': 'Ücretsiz',
    'Ref: Based on customization': 'Özelleştirmenize göre',
    'Ref: Additives eggs': 'yumurta',
    'Ref: Additives fish': 'Balık',
    'Ref: Additives milk': 'süt',
    'Ref: Additives nuts': 'fındık',
    'Ref: Additives wheat': 'Buğday',
    'Ref: Additives peanuts': 'yer fıstığı',
    'Ref: Additives soy beans': 'soya fasulyesi',
    'Ref: Additives sesame seeds': 'susam tohumları',
    'Ref: Review': 'İnceleme',
    'Ref: Per portion': 'Porsiyon başına',
    'Ref: Per 100g': '100g Başına',
    'Ref: Allergen selected': 'Arama filtresinde seçilen alerjen',
    'Ref: Fitbit Log': "Fitbit'e giriş yapın",
    'Ref: age verification required on delivery': 'Teslimatta gerekli yaş doğrulaması',
    'Ref: alcohol content notification':
      'Bu eşyalardan birini yerleştirip sipariş ederek, 18 yaş ve üstü olduğunuzu beyan edersiniz. Alkol kötüye kullanımı sağlığınız için tehlikelidir.',
    'Ref: default': 'Varsayılan',
    'Ref: Loyalty Card Title Stamp':
      'Bu ürünü sipariş ederseniz {no_stamps} {stamp_form} kazanıldı',
    'Ref: Loyalty Card Description':
      '{stamp_form} aşağıdaki sadakat eklenecektir {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': 'Sadakat Pulları programı, ödüller için pul toplamanızı sağlar.',
    'Ref: Loyalty Modal Description':
      '<b> {product_name} satın aldığınızda, <b> {scheme_names} </b> {program_form} için 1 damga </b> eklenecektir.',
    'Ref: and': 'Ve',
    'Ref: Stamp': 'Damga vurmak',
    'Ref: Stamps': 'Pullar',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programlar',
    'Ref: Loyalty Stamps': 'Sadakat pulları',
    'Ref: See Loyalty Stamps': 'Sadakat Pullarına bakın',
    Favorite: 'Favori',
    'Ref: In favorites': 'Ürün zaten favoriler listesinde. Kaldırmak için tıklayın.',
    'Ref: Not in favorites': 'Ürün henüz favoriler listesinde değil. Eklemek için tıklayın.',
    'Ref: Added to favorites': 'Ürün favoriler listesine eklendi.',
    'Ref: Removed from favorites': 'Ürün favoriler listesinden kaldırıldı.',
    'Ref: From': 'Kimden',
  },
  OrderHistory: { 'Ref: Orders': 'Siparişler', 'Ref: Page Title': 'Siparişlerim' },
  MenuTile: { 'Ref: Scan&Go': 'Tarama ve Git', 'Ref: View only': 'Sadece görüntüle' },
  ProductTile: {
    'Ref: Allergens': 'Alerjenler',
    'Ref: Presence of allergen': 'Alerjen varlığı',
    'Ref: Scan': 'Tarama',
    'Ref: From': 'Kimden',
    'Ref: Already in product favorite list. Click to remove it.':
      'Zaten ürün favori listesinde. Kaldırmak için tıklayın.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Şu anda ürün favori listesine eklenmedi. Eklemek için tıklayın.',
    'Ref: Free': 'Özgür',
  },
  MenusWidget: { 'Ref: no menu': 'Şu an için menü yok', 'Ref: Modal title': 'Günün menüsü' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Pikap',
    'Ref: Eat-in': 'Eat-in',
    'Ref: Scan&Go': 'Tarama ve Git',
    'Ref: Delivery': 'Teslimat',
    'Ref: Total': 'Toplam',
    'Ref: How was facility name service': '{facility_name} ´s hizmeti nasıldı?',
    'Ref: How do you rate the service': 'Hizmeti nasıl değerlendiriyorsunuz?',
  },
};
export default labels;
