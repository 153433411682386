import ReduxSchedulerService from '../../services/ReduxSchedulerService';

const clearSchedulers = (storeAPI) => (next) => (action) => {
  Object.values(ReduxSchedulerService.schedulers).forEach((scheduler) => {
    const { shouldClear, clear } = scheduler;
    if (shouldClear(action, storeAPI.getState())) clear();
  });

  return next(action);
};

export default clearSchedulers;
