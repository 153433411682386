import IframeResizer from 'iframe-resizer-react';

import useWindowSize from '../../../helpers/hooks/useWindowSize';
import { TestingPropsV2 } from '../../../types';

export type iFrameProps = {
  iFrameUrl: string;
  onLoad?: () => void;
} & TestingPropsV2;

const Iframe = ({ iFrameUrl, onLoad = () => {}, 'data-testid': dataTestId }: iFrameProps) => {
  const { width } = useWindowSize();
  // Add padding to bottom on smaller screens for clearing menu height
  const padding = width < 1024 ? '160px' : '20px';

  return (
    <IframeResizer
      src={iFrameUrl}
      scrolling={true}
      onLoad={onLoad}
      style={{
        width: '100%',
        height: '100vh',
        overflowX: 'hidden',
        paddingBottom: padding,
        border: 'none',
      }}
      checkOrigin={false}
      data-testid={`${dataTestId}-iframe-resizer`}
    />
  );
};

export default Iframe;
