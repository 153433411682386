const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Audit',
    'Ref: status0': 'Membuka',
    'Ref: status1': 'Lengkap',
    'Ref: status2': 'Dibatalkan',
    'Ref: connected': 'Terhubung',
    'Ref: not connected': 'Tidak terhubung',
    'Ref: synchronise data': 'Menyinkronkan data',
    'Ref: sync success': 'Data berhasil disinkronkan',
    'Ref: Create new': 'Membuat baru',
    'Ref: Audit unsynchronised': 'Tidak disinkronkan',
    'Ref: Audit synchronised': 'Disinkronkan',
    'Ref: Unsynced error title': 'Sebuah kesalahan telah terjadi',
    'Ref: Unsynced error description':
      'Tampaknya pertama kali Anda mengakses modul audit. Harap terhubung ke jaringan dan "sinkronisasi" untuk mulai mengaudit.',
    'Ref: Search all audits': 'Semua audit',
    'Ref: Search active audits': 'Membuka',
    'Ref: Search completed audits': 'Lengkap',
    'Ref: sync error': 'Sinkronisasi gagal',
    'Ref: sync error details': 'Coba lagi untuk terhubung kembali',
    'Ref: no search audit results': 'Sayangnya, tidak ada audit yang sesuai dengan pencarian Anda',
    'Ref: no audit results':
      'Anda tidak memiliki audit yang tersedia, buat yang baru untuk memulai',
    'Ref: Search canceled audits': 'Dibatalkan',
    'Ref: Audit cancelled successfully': 'Audit berhasil dibatalkan',
    'Ref: Cancel audit invite': 'Membatalkan',
    'Ref: Error audit cancel': 'Audit gagal dibatalkan',
    'Ref: Cancel audit confirmation': 'Anda yakin ingin membatalkan audit?',
    'Ref: Confirm': 'Mengonfirmasi',
    'Ref: Cancel audit': 'Batalkan Audit',
    'Ref: Change the location': 'Ubah lokasi',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Detail Audit',
    'Ref: questions': 'pertanyaan',
    'Ref: question': 'pertanyaan',
    'Ref: Additional Comments': 'Keterangan',
    'Ref: Not found title': 'Ups!',
    'Ref: Not found text': 'Audit yang Anda coba akses tidak ada.',
    'Ref: Back to Audit list': 'Kembali ke daftar audit',
    'Ref: Success title': 'Terima kasih!',
    'Ref: Success text': 'Anda telah menyelesaikan audit',
    'Ref: submit': 'Kirim',
    'Ref: add attachment': 'Tambahkan lampiran',
    'Ref: Unsupported elements':
      'Maaf, tampaknya audit ini memiliki konten yang belum didukung oleh aplikasi. Kami akan mengerjakannya.',
    'Ref: form error': 'Tidak semua pertanyaan telah sepenuhnya dijawab',
    'Ref: form complete': 'Selesai sejak',
    'Ref: attachment preview error': 'Anda harus online untuk melihat lampiran ini',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Membuat baru',
    'Ref: Location': 'Lokasi',
    'Ref: Audit Stream Type': 'Sungai kecil',
    'Ref: Audit': 'Audit',
    'Ref: Asset': 'Aset',
    'Ref: Asset Name': 'Nama Aset',
    'Ref: Is joint audit?': 'Apakah audit bersama?',
    'Ref: Who was present?': 'Siapa yang hadir?',
    'Ref: submit': 'Kirim',
    'Ref: Select the Location': 'Pilih lokasi',
    'Ref: Select the Audit Stream Type': 'Pilih jenis aliran audit',
    'Ref: Select the Audit': 'Pilih audit',
    'Ref: Select the Asset': 'Pilih aset',
    'Ref: form error': 'Tidak semua bidang yang dibutuhkan telah sepenuhnya dimasukkan',
    'Ref: Audit error': 'Audit terlampir',
    'Ref: Joint Audit Participants error': 'Peserta audit bersama',
    'Ref: Audit created': 'Audit dibuat',
    'Ref: Audit field length error': 'Panjang tidak boleh melebihi 100 karakter',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Ubah lokasi',
    'Ref: Location changed': 'Lokasi berubah',
    'Ref: Location': 'Lokasi',
    'Ref: Select the Location': 'Pilih lokasi',
    'Ref: Asset': 'Aset',
    'Ref: Select the Asset': 'Pilih aset',
    'Ref: Asset Name': 'Nama Aset',
    'Ref: submit': 'Kirim',
    'Ref: Audit field length error': 'Panjang tidak boleh melebihi 100 karakter',
  },
};
export default labels;
