const labels = {
  All: {
    'starts at': 'Empieza',
    'ends at': 'Termina',
    location: 'Ubicación',
    'coming date': 'Próxima Fecha',
    'Ref: Currently event not preferred. Click to make preferred':
      'Actualmente no se prefiere el evento. Haga clic para preferir',
    'Ref: Currently event preferred. Click to make not preferred':
      'Actualmente preferido el evento. Haga clic para que no se prefiera',
  },
  EventDetails: {
    details: 'Detalles',
    'Ref: Page title': 'Detalles del evento',
    'Event dates': 'Fechas de eventos',
    'See online:': 'Ver en línea:',
    'See all dates': 'Ver todas las fechas',
    'Remove from my selection': 'Eliminar de mi selección',
    'Add to my selection': 'Añadir a mi preferido',
    'Ref: explain calendar usage':
      'Las fechas de los eventos se resaltan en el calendario a continuación. ¡No se pierda los eventos que le gustan! Haga clic en un evento en el calendario para descargar una invitación. Luego abra el archivo descargado para agregar el evento a su calendario preferido.',
    'Ref: Favorite': 'Favorito',
    'Ref:Feedback': 'Retroalimentación',
    'Ref: Event site different title': 'Este evento no pertenece a su sitio actual.',
    'Ref: Event site different description':
      'Está viendo un evento que no pertenece a su sitio actual. No podrás verlo en la lista de eventos.',
    'Ref: See all dates': 'Ver todas las fechas',
    'Ref: From': 'De',
    'Ref: to': 'a',
    'Ref: Coming date': 'Fecha de próxima',
    'Ref: Add to calendar': 'Añadir al calendario',
  },
  EventsList: {
    'Ref: Page title': 'Eventos',
    'All dates': 'Todas las fechas',
    'All events': 'Todos los eventos',
    'Ref: Search': 'Búsqueda',
    'My selection': 'Mis favoritos',
    'filter label: date': 'Fecha',
    'filter label: coming events': 'eventos próximos',
    'filter label: past events': 'eventos pasados',
    'filter label: interest': 'preferencia',
  },
  Widget: {
    'Ref: Carousel title': 'Eventos',
    'No info yet, come back later': 'No hay información todavía, vuelva más tarde 😉',
  },
};
export default labels;
