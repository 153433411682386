const labels = {
  All: {
    Region: 'Alue tai maa',
    'Ref: Geography': 'Valitse maasi tai alueesi',
    'Ref: Country': 'Maa',
    'Ref: Country tooltip': 'Sijainti vastaa sivustoa.',
    'Ref: Please select your country': 'Valitse maa',
    'First name': 'Etunimi',
    'Last name': 'Sukunimi',
    'Ref: Full name': 'Koko nimi',
    'Mobile number': 'Matkapuhelinnumero',
    'Mobile phone': 'Kännykkä',
    Email: 'Sähköpostiosoite',
    Password: 'Salasana',
    logout: 'Kirjaudu ulos',
    'Not a new user? Log in': 'Etkö ole uusi käyttäjä? Kirjaudu sisään',
    'do not register, login': 'Älä rekisteröidy, Kirjaudu sisään',
    'Ref: I agree': 'olen samaa mieltä',
    Register: 'Rekisteröidy',
    'Ref: My language': 'Minun kieleni',
    'Ref: Kiosk Fail Title': 'Oho! Jotain meni pieleen!',
    'Ref: Kiosk Fail Description':
      'Valitettavasti tilauksesi ei onnistunut. Yritä uudelleen tai pyydä henkilöstöä auttamaan sinua.',
    'Ref: Kiosk Fail Back': 'Takaisin edelliselle sivulle',
    'Ref: Kiosk Fail Action': 'Tyhjennä istunto ja tilaa uudelleen',
    Validate: 'Validoida',
    'Ref: error, incorrect credentials': 'Nämä valtakirjat eivät vastaa valittuun maantieteeseen.',
    'Ref: Forgot password?': 'Unohtuiko salasana?',
    'Ref: Hello': 'Hei {wave_emoji}, kirjaudu sisään jatkaaksesi',
    'Ref: Form title': 'Ole hyvä ja kirjaudu jatkamaan',
    'Already have an account? Login': 'Onko sinulla jo tili? Kirjaudu sisään',
  },
  AccountPage: {
    'Ref: My Information': 'Minun tietoni',
    'Ref: Settings': 'asetukset',
    'Ref: Email': 'Sähköposti',
    'Ref: Current site': 'Nykyinen sivusto',
    'Ref: Choose site': 'Valitse sivusto',
    'Ref: Personal interests': 'Henkilökohtaiset intressit',
    'Ref: Food preferences': 'Ruoka -asetukset',
    'Ref: Password': 'Salasana',
    'Ref: Page title': 'Tili',
    'Ref: Language': 'Kieli',
    'Ref: Communication preferences': 'Viestintäasetukset',
    'Ref: Terms & conditions': 'Käyttöehdot',
    'Ref: Privacy policy': 'Tietosuojakäytäntö',
    'Ref: Delete my account': 'Poista minun tilini',
    'Ref: Confirm deletion': 'Haluatko varmasti poistaa tilisi?',
    'Ref: Request deletion': 'Haluatko varmasti pyytää tilisi poistamista?',
    'Ref: Deletion description': 'Pyyntösi käsitellään paikallisen sääntelyn mukaisesti.',
    Placeholder: 'Kirjoita "Poista"',
    'Ref: Delete description': 'Tietosi poistetaan. Tämä toiminta on peruuttamaton.',
    delete: 'poista',
    'Ref: Terms of sale': 'Myyntiehdot',
    'Ref: Imprint': 'Jälki',
    'Ref: Cookie Settings': 'Evästeasetukset',
    'Ref: Support': 'Tuki',
    'Ref: Register or login': 'Rekisteröidy tai kirjaudu sisään',
    'Ref: Filtering Preferences': 'Suodatusasetukset',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Poistatko tilisi?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Kirjoita "{word}" syöttökenttään ja vahvista alaosa alla. Tämä toiminta on peruuttamaton.',
    'Ref: Write "{word}"': 'Kirjoita "{word}"',
    'Ref: Delete my account': 'Poista minun tilini',
    'Ref: Words are not matching': 'Sanat eivät sovi',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Valitsi sijaintisi aloittamiseen',
    'Ref: Choose location':
      'Löydä nopeasti sijainti aktivoimalla lokalisointi, skannaamalla QR -koodi tai etsimällä nimellä',
    'Ref: next step': 'Mennään',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Kirjaudu',
    'Ref: identify your location': 'Tunnista sijaintisi.',
    'Ref: option 1 - scan QR': 'Vaihtoehto 1: Skannaa rekisteröinti QR-koodilla',
    'Ref: option 2 - enter code': 'Vaihtoehto 2: Anna sijaintikoodi manuaalisesti',
    'Ref: button to open qr code scanner modal': 'Minulla on QR: Skannaa se',
    'Ref: button to open site code input modal': 'Valitse sivusto koodilla',
    'Ref: getting help with site identifier - support desk':
      'Jos ei ole selvää, miten edetä, ota yhteyttä tukipalveluun.',
    'Ref: getting help with site identifier - support form':
      'Voit myös lähettää meille muistiinpanon.',
    'Location code': 'Sijaintikoodi',
    'Ref: location code field placeholder': 'Sijaintikoodi täällä',
    'Ref: Scanner header': 'QR-koodin skannaaminen',
    'Ref: site code input header': 'Lisää sivusto koodilla',
    'Ref: not valid code': 'Tämä koodi ei ole kelvollinen valitussa maantieteessä',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Rekisteröinti',
    'Ref: collected info': 'Yhteenveto',
    'Ref: additional info': 'Sinun tietosi',
    'Confirm password': '*Vahvista salasana',
    company: 'Kuka on työnantajasi?',
    location: 'Sijainti',
    username: 'käyttäjätunnus',
    register: 'Kirjaudu',
    'Ref: password strength':
      'Salasanan on oltava vähintään 8 merkkiä pitkä, vähintään yksi numero, yksi iso, yksi pieni ja yksi erikoismerkki.',
    'passwords do not match': 'Salasanat eivät täsmää.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Viestintäasetukset',
    'Ref: Things happen while you are away': 'Asiat tapahtuvat, kun olet poissa',
    'Ref: Would you like to receive update about offers and promotions?':
      'Haluatko saada päivityksiä tarjouksista?',
    'Ref: Would you like to hear about content we think you may like?':
      'Haluatko kuulla sisällöstä, josta ajattelemme pitäväsi?',
    Yes: 'Kyllä',
    No: 'Ei',
    apply: 'Käytä',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Tilin tiedot' },
  DeletionFailure: {
    'Ref: Body':
      'Virhe on tapahtunut, eikä tilinpoistopyyntöäsi lähetetty. Jos tämä virhe jatkuu, ota yhteyttä tukipalvelu.',
    'Go to account': 'Mennä tilille',
  },
  GenericFailurePage: {
    'Ref: Title': 'Jotain meni pieleen',
    'Ref: Body':
      'Pahoittelut, jotain meni pieleen. Jos tämä ongelma jatkuu, ota yhteyttä paikan päällä.',
  },
  HomePage: {
    notifications: 'Ilmoitukset',
    basket: 'Kori',
    'Ref: Tabtitle': 'Koti',
    'contact us': 'Ota meihin yhteyttä',
    'Ref: You are logged in as a guest user': 'Olet kirjautunut sisään vierailijana',
    'Ref: Good morning': 'Hyvää huomenta',
    'Ref: Good afternoon': 'Hyvää iltapäivää',
    'Ref: Good evening before midnight': 'Hyvää iltaa',
    'Ref: Good evening after midnight': 'Hyvää iltaa',
    'Ref: Search': 'Hae',
    'Ref: products button aria': 'Löydetty {results_form} tuotteista',
    'Ref: menus button aria': 'Löydetty {results_form} valikoista',
    'Ref: facilities button aria': 'Löydetty {results_form} tilojen sisällä',
    'Ref: result': 'tulos',
    'Ref: results': 'tulokset',
    'Ref: Menus': 'Valikot',
    'Ref: No results title': 'Oho!',
    'Ref: No results body': 'Hakuasi ei vastaa tuloksia',
    'Ref: Products': 'Tuotteet',
    'Ref: Facilities': 'Tilat',
    'Ref: See more results': 'Katso lisää hakutuloksia',
    'Ref: Accessibility instructions':
      'Aloita haun käytön napsauttamalla sitä tai painattava Enter, kun se on keskittynyt.',
    'Ref: See more': 'Katso lisää',
    'Ref: singular form of products': 'tuote',
    'Ref: singular form of menus': 'valikko',
    'Ref: singular form of facilities': 'laitos',
    'Ref: Search results for': 'Etsi tuloksia',
  },
  LanguagePage: {
    apply: 'Käytä',
    'My language': 'Minun kieleni',
    'Select in which language you would like to read our app':
      'Valitse millä kielellä haluat lukea sovelluksemme',
    'Ref: Page title': 'Kieli',
  },
  LegalDocPage: {
    Acknowledge: 'Tunnustaa',
    'Ref: Legal changed notification title': '”{legal_doc_type}” on muokattu',
    'Ref: Legal changed notification content': 'Tarkista ne ennen sovelluksen käyttöä.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'käyttöehdot',
    'Read privacy policy': 'Lue tietosuojakäytäntö',
    'Ref: Acknowledge legal docs': 'Hyväksyn nämä ehdot.',
    'Ref: Read and understood': 'Olen lukenut ja ymmärtänyt ',
    'Ref: No legal docs available - notice title': 'Tulossa pian',
    'Ref: No legal docs available - notice body':
      'Anteeksi, näyttää siltä, ​​että tämä sovellus ei ole tällä hetkellä saatavilla maantieteelläsi.',
    'Ref: The privacy Policy': 'Tietosuojakäytäntö',
    'Ref: Legal changed notification title': '”{legal_doc_type}” on muokattu',
    'Ref: Legal changed notification content': 'Tarkista ne ennen sovelluksen käyttöä.',
  },
  LoginPage: {
    'Ref: Page title': 'Tervetuloa {app name}',
    'Ref: Page subtitle': 'Kirjaudu sisään jatkaaksesi',
    'Ref: Email field placeholder': 'Sähköpostisi',
    'Ref: Email field note': 'Emme koskaan jaa sähköpostiasi kenenkään kanssa.',
    'Ref: Password field title': 'Salasanasi',
    register: 'Kirjaudu',
    'I forgot my password.': 'Unohtuiko salasana?',
    'I have trouble logging in': 'Onko sinulla vaikeuksia kirjautua sisään?',
    'start registration': 'Aloita rekisteröinti',
    'Ref: Register': 'Rekisteröidä',
    'Ref: Login': 'Kirjaudu sisään',
    'Ref: Connection issue?': 'Yhteysongelma?',
    'Ref: Connection issue message':
      'Jos sinulla on ongelmia rekisteröitymiseen tai kirjautumiseen, ota yhteyttä tavalliseen paikan päällä olevaan henkilökuntaan tai tukipalvelu.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Hei ja tervetuloa {site_name}', 'Ref: Begin': 'Alkaa' },
  NotificationsList: {
    'Ref: No notifications': 'Sinulla ei ole ilmoituksia tällä hetkellä',
    'Ref: This week': 'Tämä viikko',
    'Ref: Earlier': 'Aikaisempi',
    'Ref: Page title': 'Ilmoitukset',
    'Ref: See more': 'Katso lisää',
    'Ref: Mark all read': 'Merkitse kaikki luettu',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Katso kaikki ilmoitukset' },
  ServicesMenuPage: { 'Ref: Page title': 'Palvelut', navigate: 'navigoida' },
  DeletionSuccess: {
    'Ref: Title': 'Tilisi poistoa pyydettiin',
    'Ref: Content': 'Käsittelemme sitä pian. Voit menettää pääsyn milloin tahansa.',
    'Log out': 'Kirjautua ulos',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Palaamme asiaan',
    'Ref: Body': 'Olemme pahoillamme, että sinulla on ongelmia. Otamme sinuun yhteyttä hyvin pian.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Onko sinulla vaikeuksia kirjautua sisään?',
    'Ref: Email field placeholder': 'Sähköpostiosoitteesi',
    'Ref: Mobile phone field placeholder': 'Matkapuhelinnumero',
    Company: 'Yritys',
    'Ref: Company field placeholder': 'Yrityksen nimi',
    'Ref: Company field note':
      'Jos olet konsultti, tämä on yritys, jolle olet työskennellyt, ei työnantajasi.',
    Location: 'Kaupunki tai sijainti',
    'Ref: Location field placeholder': 'Työpaikan sijainti',
    'Ref: Location field note':
      'Jos asutusalueen ulkopuolella voit käyttää nimeä, jolla aluetta kutsutaan yleisesti.',
    Comment: 'Kommentti',
    'Ref: Comment field placeholder': 'Mikä meni vikaan?',
    'Ref: Who is your employer?': 'Kuka on työnantajasi?',
    'Ref: What village are you staying at?': 'Missä kylässä olet?',
    'Ref: Comment field note': 'Kaikki tiedot, jotka auttavat meitä auttamaan sinua!',
    'Ref: Feedback Content - geography': 'Maantiede',
    'Ref: Feedback Content - fullName': 'Nimi',
    'Ref: Feedback Content - email': 'Sähköposti',
    'Ref: Feedback Content - phone': 'Puhelin',
    'Ref: Feedback Content - company': 'Yritys',
    'Ref: Feedback Content - city': 'Kaupungin / sijainnin nimi',
    'Ref: Feedback Content - whatVillage': 'Kylä',
    'Ref: Feedback Content - employer': 'Työnantaja',
    'Ref: Feedback Content - comment': 'Mikä meni vikaan',
  },
  AccountStartPage: {
    'Ref: Header': 'Valitsi sijaintisi aloittamiseen',
    'Ref: Body':
      'Löydä nopeasti sijainti aktivoimalla lokalisointi, skannaamalla QR -koodi tai etsiminen nimellä',
    'Ref: LetsGo button': 'Mennään',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Rekisteröinti' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Valitse sivusto QR -skannerilla',
    'Ref: Page title': 'Valitse sijainti',
    'Ref: Select site with code': 'Valitse sivusto koodilla',
    'Ref: Enter site code': 'Kirjoita sivustokoodi',
    'Ref: Scanner error header': 'Sivustoa ei löydy',
    'Ref: Not found': 'Tämä koodi ei ole kelvollinen valitussa maantieteessä',
    'Ref: Generic error': 'Jotain meni pieleen',
    'All other sites': 'Kaikki muut sivustot',
    'Sites in your country': 'Sivustot maassasi',
    'Ref: Unauthorized error': 'Sivuston rajoittamisen takia luvaton pyyntö',
    'Ref: Forbidden':
      'Valitettavasti sinulla ei ole oikeita käyttöoikeuksia käyttää tätä sivustoa. Jos haluat järjestää pääsyn, ota yhteyttä Pilbara Service Centeriin numeroon 1800 992 777',
    "Ref: We didn't find the site.": 'Emme löytäneet sivustoa.',
    'Ref: We recommend some solutions:': 'Suosittelemme joitain ratkaisuja:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Yritä lisätä sivustosi QR -skannerilla tai alla olevien painikkeiden avulla',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Kirjaudu ulos ja kirjaudu sisään valitsemalla oikea maa',
    'Ref: The currently selected country is:': 'Nykyinen valittu maa on:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Rekisteröinti',
    'Confirm password': 'Vahvista salasana',
    'Email is not valid': 'Sähköposti ei ole voimassa',
    'passwords do not match': 'Salasanat eivät täsmää.',
    'Ref: Password strength':
      'Salasanasi on oltava vähintään 8 merkkiä pitkä, sisältävät vähintään yksi numero, yksi iso kirjain, yksi pieni ja yksi erikoismerkki.',
    'Sign up': 'Kirjaudu',
    'Continue as guest': 'Jatka vieraana',
    'Already have an account? Login': 'Onko sinulla jo tili? Kirjaudu sisään',
    'Ref: Registration success title': 'Kiitos rekisteröitymisestä',
    'Ref: Registration success content':
      'Tilisi luodaan pian. Saat sähköpostin, joka vahvistaa luomisen.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Kirjaudu/kirjaudu',
    'Ref: Password strength':
      'Salasanan on oltava vähintään 8 merkkiä pitkä, vähintään yksi numero, yksi iso, yksi pieni ja yksi erikoismerkki.',
    Stamps: 'Leimat',
    'Order history': 'Tilaushistoria',
    'to earn points': 'ansaitse pisteitä',
    'to see order history': 'tilaushistoriasi',
    'to place an order': 'tee tilaus',
    'Sign up': 'Kirjaudu',
    Login: 'Kirjaudu sisään',
    'Email is not valid': 'Sähköposti ei ole voimassa',
    'passwords do not match': 'Salasanat eivät täsmää.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Kiitos',
    'Ref: Body':
      'Olet melkein valmis! Saat pian varmennus sähköpostiviestin toimitetusta osoitteesta. Avaa sähköposti ja napsauta linkkiä aktivoidaksesi tilisi.',
    'Ref: Enter': 'Jatka',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Virhe on tapahtunut',
    'Ref: Generic Error Body': 'Yritä myöhemmin uudelleen tai ota yhteyttä tukipalvelu.',
    'Ref: Missing IndexDb support':
      'Valitettavasti et pääse sovellukseen inkognito -tilassa tai ilman IndexDB -tukea. Ole hyvä ja yritä uudelleen toisen selaimen kanssa.',
    'Ref: Resource not found': 'Sisältöä, jota yrität käyttää, ei ole.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Sisältö, johon yrität käyttää, kuuluu yksityiselle sivustolle.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Sisältöä, jota yrität käyttää, ei ole lupaa tilillesi.',
    'Ref: TabTitle': 'Virhe',
    'Ref: Site link invalid':
      'Tämä linkki on virheellinen, valitse toinen sivusto tai ota yhteyttä tukipalvelu, jos ongelma jatkuu.',
    'Ref: Access Denied': 'Pääsy evätty. Riittämättömät käyttöoikeudet.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Oho! {robot_emoji}',
    'Ref: Error title': 'Jotain meni pieleen!',
    'Ref: Error body':
      'Yritä päivittää tämä sivu tai ottaa yhteyttä tukipalvelu, jos ongelma jatkuu.',
    'Ref: Error ID:': 'VIRHE ID: {errorId}',
    'Ref: Back to home': 'Takaisin kotiin',
  },
  Child: {
    'Ref: Route Not Found':
      'Etsimäsi palvelu ei ole käytettävissä tällä sivustolla. Yritä myöhemmin uudelleen tai ota yhteyttä tukipalvelu.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Onnittelut, uusi salasanasi on tallennettu onnistuneesti, voit nyt käyttää kaikkia palveluitamme tililtäsi.',
    Welcome: 'Tervetuloa',
    'Ref: Welcome message':
      'Olet nyt onnistuneesti rekisteröitynyt uuteen Bite -sovellukseen ja voit jatkaa kaikkien palveluidemme löytämistä ja nauttimista.',
  },
  PasswordChange: {
    'Change Password': 'Vaihda salasana',
    Welcome: 'Tervetuloa',
    'Ref: Password message': 'Salasanasi on vaihdettava, se ei ole tarpeeksi turvallinen',
  },
  AccountPasswordChange: {
    'Change your password here': 'Vaihda salasanasi täällä',
    'Your old password': 'Vanha salasanasi',
    'Type in your new password': 'Kirjoita uusi salasana',
    'Re-type in your new password': 'Uudelleentyyppi uudessa salasanasi',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'salasanasi on vaihdettu' },
  AccountPasswordChangeFailure: {
    'Ref: Error message': 'Tapahtui virhe, joten muutosta ei tallennettu. Yritä uudelleen.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Nollaa salasana',
    'Ref: Provide email':
      'Anna sähköpostiosoite, jota käytetään rekisteröidyt {app_name}. Sinulle lähetetään sähköpostiosoite palauttaaksesi salasanasi.',
    'Ref: Something happened': 'Jotain tapahtui. Yritä uudelleen myöhemmin.',
  },
  ResetEmailSent: {
    'Ref: New email sent': 'Olemme lähettäneet uuden sähköpostin. Odota 30 sekuntia uudelleen.',
    'Go to login page': 'Siirry kirjautumissivulle',
    'Resend email': 'Lähetä uudelleen sähköposti',
    'Ref: Email sent title': 'Salasanan palautus sähköposti on lähetetty sinulle',
    'Ref: TabTitle': 'Nollaa salasana',
    'Ref: Email sent msg':
      'Reset -linkki lähetettiin osoitteeseesi {email_address}. Odota 30 sekuntia ennen kuin yrität uudelleen.',
  },
  ResetPassword: { 'Enter your new password': 'Kirjoita uusi salasanasi' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Salasanasi on onnistuneesti vaihdettu',
    'Go to login page': 'Siirry kirjautumissivulle',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Salasanan palautus epäonnistuu',
    'Ref: Error': 'Tapahtuu virhe, yritä uudelleen',
    'Reset your password': 'Nollaa salasana',
    'Go to login page': 'Siirry kirjautumissivulle',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Tietosuojakäytäntö',
    'Ref: Privacy Policy Body':
      'Pääsymällä tälle alustalle, Sodexo SA ja paikallinen Sodexo -yksikkö käsittelevät henkilötietojasi ainakin tilisi hallitsemiseksi ja palvelujen tarjoamiseksi ja tarjoamiseksi saatavana alustan kautta. Jos haluat lisätietoja henkilötietojen käsittelystä, kuka sitä käyttää ja miten käyttää henkilötietojasi oikeuksia, ota huomioon alla olevaan tietosuojakäytäntöön ja saatavana milloin tahansa tililläsi.',
    'Ref: Continue': 'Jatkaa',
    'Ref: Read Privacy Policy': 'Lue tietosuojakäytäntö',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Mene kauppaan' },
  SharePage: { 'Ref: Select a site': 'Valitse sivusto' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Voit tarkastella tietosuojakäytäntöä valitse maasi.',
  },
};
export default labels;
