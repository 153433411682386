const mockResponse = {
  getSurveys: {
    ok: true,
    status: 200,
    json: () =>
      new Promise((resolve) =>
        setTimeout(
          () =>
            resolve([
              {
                id: '880f76c8-d6b6-ea11-a812-000d3a25c5d6',
                name: 'Survey Testing',
                description: '',
                publishedDate: '2020-06-25T11:28:00Z',
                expiryDate: '2025-06-24T16:00:00Z',
                type: 'Consumer App User Satisfaction',
                inviteId: '',
                isInvited: false,
              },
              {
                id: '2e980fb3-825d-ec11-8f8f-000d3a269fa0',
                name: 'SA Test Survey',
                description: 'Testing new survey',
                publishedDate: '2021-12-15T08:42:26Z',
                expiryDate: '2028-03-22T00:00:49Z',
                type: 'Consumer App User Satisfaction',
                inviteId: '',
                isInvited: false,
              },
              {
                id: 'f7c2ca09-8482-e911-a989-000d3a2bccc5',
                name: 'ConsumerApp Survey - User Sat Rooms',
                description: 'ConsumerApp Survey - User Sat Rooms',
                publishedDate: '2019-05-29T18:56:00Z',
                expiryDate: '2028-03-22T04:56:00Z',
                type: 'Consumer App User Satisfaction',
                inviteId: '',
                isInvited: false,
              },
              {
                id: '11e3f23a-44ab-ec11-983f-6045bd91a734',
                name: 'Survey 2022',
                description: 'Please take this survey!',
                publishedDate: '2022-03-24T13:03:00Z',
                expiryDate: '2028-03-22T10:26:00Z',
                type: 'Consumer App Ad-hoc Survey',
                inviteId: '',
                isInvited: false,
              },
            ]),
          1000
        )
      ),
  },
  getSurvey: {
    ok: true,
    status: 200,
    json: () =>
      new Promise((resolve) =>
        setTimeout(
          () =>
            resolve({
              id: '11e3f23a-44ab-ec11-983f-6045bd91a734',
              name: 'Survey 2022',
              description: 'Please take this survey!',
              publishedDate: '2022-03-24T13:03:00Z',
              type: 'Consumer App Ad-hoc Survey',
              questionGroups: [
                {
                  id: '962ce41f-47ab-ec11-983f-6045bd91a734',
                  name: 'Single question group',
                  orderIndex: 70,
                },
                {
                  id: 'd25c4526-47ab-ec11-983f-6045bd91a734',
                  name: 'Single question group',
                  orderIndex: 90,
                },
                {
                  id: 'ed5c4526-47ab-ec11-983f-6045bd91a734',
                  name: 'Single question group',
                  orderIndex: 110,
                },
                {
                  id: '475d4526-47ab-ec11-983f-6045bd91a734',
                  name: 'Single question group',
                  orderIndex: 130,
                },
              ],
              questions: [
                {
                  id: '992ce41f-47ab-ec11-983f-6045bd91a734',
                  questionGroupId: '962ce41f-47ab-ec11-983f-6045bd91a734',
                  helpText: '',
                  questionText: '[Single response]',
                  orderIndex: 10,
                  required: false,
                  questionType: 4,
                  answers: [
                    {
                      id: '730460fe-e006-41c1-b2bd-ea32bbbdd776',
                      name: 'response1',
                      orderIndex: 1,
                      faceExpression: 0,
                      imageUrl: '',
                      isDefault: false,
                    },
                    {
                      id: 'b594f515-13d3-461a-b098-b48b1c08ed7c',
                      name: 'response2',
                      orderIndex: 2,
                      faceExpression: 0,
                      imageUrl: '',
                      isDefault: false,
                    },
                    {
                      id: '02b5bf0e-91a3-4a63-9a80-7708055b8167',
                      name: 'response3',
                      orderIndex: 3,
                      faceExpression: 0,
                      imageUrl: '',
                      isDefault: false,
                    },
                  ],
                },
                {
                  id: 'd35c4526-47ab-ec11-983f-6045bd91a734',
                  questionGroupId: 'd25c4526-47ab-ec11-983f-6045bd91a734',
                  helpText: '',
                  questionText: '[Multiple responses]',
                  orderIndex: 10,
                  required: false,
                  questionType: 5,
                  answers: [
                    {
                      id: '28f6c0a2-8c77-42a1-97cd-158ae08bdc06',
                      name: 'response1',
                      orderIndex: 1,
                      faceExpression: 0,
                      imageUrl: '',
                      isDefault: false,
                    },
                    {
                      id: 'ee9c71d1-faa2-403c-9c29-dcaf5654c70c',
                      name: 'response2',
                      orderIndex: 2,
                      faceExpression: 0,
                      imageUrl: '',
                      isDefault: false,
                    },
                    {
                      id: '512ce485-f0db-43ed-a334-da0b7d5ce0a5',
                      name: 'response3',
                      orderIndex: 3,
                      faceExpression: 0,
                      imageUrl: '',
                      isDefault: false,
                    },
                  ],
                },
                {
                  id: 'f45c4526-47ab-ec11-983f-6045bd91a734',
                  questionGroupId: 'ed5c4526-47ab-ec11-983f-6045bd91a734',
                  helpText: '',
                  questionText: '[Short answer]',
                  orderIndex: 10,
                  required: false,
                  questionType: 1,
                  answers: [],
                },
                {
                  id: '485d4526-47ab-ec11-983f-6045bd91a734',
                  questionGroupId: '475d4526-47ab-ec11-983f-6045bd91a734',
                  helpText: '',
                  questionText: '[Long answer]',
                  orderIndex: 10,
                  required: false,
                  questionType: 2,
                  answers: [],
                },
              ],
            }),
          1000
        )
      ),
  },
  submitSurvey: {
    ok: true,
    status: 204,
    json: () => new Promise((resolve) => setTimeout(() => resolve({}), 1000)),
  },
  getContents: {
    ok: true,
    status: 200,
    json: () => new Promise((resolve) => setTimeout(() => resolve({ contents: [] }))),
  },
};

export default mockResponse;
