import { Email } from '../../../../assets/icons';
import { CardWithH2TitleAndIcon } from '../../../../storybook/molecules/Card.stories';
import { TITLE_TAG } from '../../../atoms/Title';
import List from '../../../organisms/List/List';
import { ListItemProps } from '../../../organisms/List/List.types';

import { FacilityContactProps, notEmpty } from './facilityContact.types';

import styles from './facilityContact.module.css';

const FacilityContact = ({ facility, label }: FacilityContactProps) => {
  if (facility?.phone || facility?.email) {
    const email = facility.email
      ? ({
          id: 'email',
          label: label('email', { textTransform: 'capitalize' }),
          value: <a href={'mailto:' + facility.email}>{facility.email}</a>,
          'data-testid': 'facility-contact-email',
        } as ListItemProps)
      : null;

    const phone = facility.phone
      ? ({
          id: 'phone',
          label: label('phone', { textTransform: 'capitalize' }),
          value: <a href={'tel:' + facility.phone}>{facility.phone}</a>,
          'data-testid': 'facility-contact-phone',
        } as ListItemProps)
      : null;

    const contacts = [email, phone].filter(notEmpty);

    return (
      <CardWithH2TitleAndIcon
        icon={{ icon: Email, className: styles.icon }}
        title={{
          tag: TITLE_TAG.H2,
          children: label('contact us', { textTransform: 'capitalize' }),
        }}
      >
        <List items={contacts} data-testid="facility-list" />
      </CardWithH2TitleAndIcon>
    );
  }
  return null;
};

export default FacilityContact;
