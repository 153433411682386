import classNames from 'classnames';
import React from 'react';

import { ErrorCircleIcon } from '../../../assets/icons';
import { TestingProps } from '../../../types';

import styles from './Select.module.css';

type SelectWrapperProps = {
  children: React.ReactNode;
  label?: string;
  required?: boolean;
  message?: string;
  controlId?: string;
  hasError?: boolean;
} & TestingProps;

const SelectWrapper = ({
  children,
  'data-cy': dataCy,
  'data-testid': testId,
  label,
  required,
  message,
  controlId,
  hasError,
}: SelectWrapperProps) => {
  return (
    <div
      className={classNames(styles.reactSelect, { [styles.errorWrapper]: hasError })}
      data-cy={`${dataCy}-wrapper`}
      data-testid={`${testId}-wrapper`}
    >
      {label && (
        <label
          className={classNames('formElementLabel')}
          data-cy={`${dataCy}-label`}
          data-testid={`${testId}-label`}
          htmlFor={controlId}
        >
          {label}
          {required && ' *'}
        </label>
      )}
      <div className={styles.selectInput} data-testid={`${testId}-input-wrapper`}>
        {children}
        {hasError && (
          <ErrorCircleIcon className={classNames('icon_danger_filled', styles.errorIcon)} />
        )}
      </div>
      {message && (
        <div className={classNames(styles.message)} data-testid={`${testId}-message`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default SelectWrapper;
