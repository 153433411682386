export type ServiceRequestLightHelpdeskListResponse = {
  listData: {
    data?: ServiceRequestLightHelpdeskObject[];
    metadata?: Metadata;
  };
  forbidden: boolean;
};

export type Metadata = {
  pageSize: number;
  pageNumber: number;
  hasNextPage: boolean;
};

export type ServiceRequestLightHelpdeskObject = {
  id: string;
  serviceRequestNumber: string;
  createDateTime: string;
  title: string;
  category?: string;
  affectedPersonFullName?: string;
  buildingText?: string;
  roomText?: string;
};

export type GetServiceRequestLightHelpdeskArgs = {
  siteId: string;
  pageSize?: number;
  pageNumber?: number;
  searchTerm?: string;
  startDate?: string;
  endDate?: string;
};

export type ExportServiceRequestsLightForHelpdeskArgs = {
  siteId: string;
  searchTerm?: string;
  startDate?: string;
  endDate?: string;
  format?: ExportServiceRequestsLightForHelpdeskFormat;
  timeZoneOffsetMinutes?: number;
  useErrorBoundary?: boolean;
};

export enum ExportServiceRequestsLightForHelpdeskFormat {
  Csv = 'csv',
}
