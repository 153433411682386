const labels = {
  StaysList: {
    'Ref: Page title': 'Fica',
    'Ref: Past': 'Passado',
    'Current and future stays': 'Estadias atuais e futuras',
    'filter label: period': 'Período',
    'Ref: Search': 'Procurar',
  },
  StayDetails: {
    'Ref: Page title': 'Detalhes da sua estadia',
    'Arrival date': 'Data de Chegada',
    'Departure date': 'Data de saída',
    'Room number': 'Quarto',
    'Reservation number': 'Número da reserva',
    CheckIn: 'Check-in',
    CheckOut: 'Verificação de saída',
    CheckIn_1: 'Verifique em {lodgeName}, chegando hoje? (Número de reserva {resNumber})',
    CheckedIn_1: 'Você está marcado em {lodgeName}, sala {roomNumber}.',
    CheckOut_1:
      'Confira {lodgeName} Número de reserva {resNumber} agora? Sua chave do quarto será desativada.',
    CheckedOut_1: '{lodgeName} Número de reserva {resNumber} verificou.',
  },
  StaysSuccess: {
    'Ref: Title': 'Obrigado pela sua estadia!',
    'Ref: Body': 'Sua finalização foi processada com sucesso.',
    'Ref: Go to home': 'Vá para casa',
    'Ref: Provide a feedback': 'Fornecer um feedback',
  },
};
export default labels;
