import React from 'react';
import { Store } from 'redux';

import { TranslationProps } from '../../../localization/localization.types';
import { ISite } from '../../Sites/types/sites.types';

import { OpeningHoursType } from '@/components/ecosystems/Facility/OpeningHours/openingHours.types';
import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

export type FacilityData = Record<string, Facility>;

export enum FacilityType {
  Undefined,
  FoodRetail,
  FoodNonRetail,
  NonFoodNonRetail,
  NonFoodRetail,
  ConferenceCatering,
}

export type Facility = {
  email: string;
  phone: string;
  description: string;
  access: string;
  longitude: string;
  latitude: string;
  openingHours: OpeningHoursType[];
  isFoodFacilityType: boolean;
  menuNote: string;
  imageInfo?: ImageInfo;
} & FacilityDataItem;

export type FacilityDataItem = {
  title: string;
  name: string;
  id: string;
  sequence: number;
  images: string[];
  facilityType: FacilityDataType;
  viewCounts: number;
  externalUrl: string | null;
  openingHours?: OpeningHoursType[];
};

export type FacilityDataType = {
  id: string;
  name: keyof typeof FACILITY_TYPE;
};

export type FacilityState = {
  data: FacilityData;
  list: FacilityStateList;
};

export type FacilityStateList = {
  [key: string]: string[];
};

export const FACILITY_TYPE = {
  'Food - Non-retail': FacilityType.FoodNonRetail,
  'Food - Retail': FacilityType.FoodRetail,
  'Non-food - Non-retail': FacilityType.NonFoodNonRetail,
  'Non-food - Retail': FacilityType.NonFoodRetail,
  'Conference Catering': FacilityType.ConferenceCatering,
};

export type WithFacilitiesComponentProps = {
  site: ISite;
  store: Store;
  wrappedComponent: React.ComponentType<WrappedComponentProps>;
};

export type WrappedComponentProps = {
  site: ISite;
  store: Store;
  facilities: Facility[];
  facilitiesFunctions: { getFacilityDetailsLink: (facilityId: string) => string };
};

export type FacilityTileProps = {
  id: string;
  title: string;
  imgInfo?: ImageInfo;
  facilityType: FacilityType;
  externalUrl?: string;
  focusOnRender?: boolean;
  label: TranslationProps['label'];
  onTileClickCallback?: (facilityId: string) => void;
  showOfferingIcon?: boolean;
};

export type FacilityItem = {
  id: string;
  title: string;
  imgInfo?: ImageInfo;
  facilityType: FacilityType;
  highlightedActions?: { element: React.ReactNode; action: Function }[];
  addendum?: string;
  externalUrl?: string;
  'data-testid': string;
};

export type ImageInfo = {
  src: string;
  alt: string;
};

export type FacilitiesConfigType = {
  api: typeof baseApi | typeof dspApi;
  baseUrl: string;
  getAllFacilities: string;
  getOneFacility: string;
  getFacilityParam: (id: string) => { id: string } | undefined;
};
