import classNames from 'classnames';
import React, { FC } from 'react';

import { DefaultComponentProps } from '../../../types';

import styles from './SecondaryActions.module.css';

const SecondaryActions: FC<DefaultComponentProps> = ({
  children,
  className,
  'data-testid': dataTestId = 'secondary-actions',
}) => {
  if (React.Children.count(children) > 3) {
    throw new Error('Component accepts max 3 children');
  }

  return (
    <div className={classNames('inlineContainer', className)} data-testid={dataTestId}>
      <div className={classNames(styles.wrapper)}>{children}</div>
    </div>
  );
};

export default SecondaryActions;
