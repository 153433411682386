import classNames from 'classnames';
import { useState } from 'react';

import Card from '../../../../components/molecules/Card/Card';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { ItineraryFields } from '../../components';
import ScheduleFields from '../../components/ScheduleFields';
import SchedulesList from '../../components/SchedulesList';
import { useJourneyPlannerTranslation } from '../../hooks/useJourneyPlannerTranslation';
import { scheduleTypeValues, ScheduleTypes } from '../../types';

import { SelectedStop } from './TimeTable.types';

import styles from './TimeTable.module.css';

const TimeTable = () => {
  const [selectedOrigin, setSelectedOrigin] = useState<SelectedStop | null>(null);
  const [selectedDestination, setSelectedDestination] = useState<SelectedStop | null>(null);
  const [scheduleType, setScheduleType] = useState<ScheduleTypes>(scheduleTypeValues.leave);
  const [scheduleDate, setScheduleDate] = useState<Date>(new Date());
  const { label } = useJourneyPlannerTranslation(__filename);

  return (
    <SimpleFormPage title={label('Ref: Page title')} hasBackLink={false}>
      <Container>
        <Column.Side>
          <Card className={classNames(styles.itineraryFields)}>
            <ItineraryFields
              selectedOrigin={selectedOrigin}
              selectedDestination={selectedDestination}
              setSelectedOrigin={setSelectedOrigin}
              setSelectedDestination={setSelectedDestination}
              label={label}
            />
          </Card>
          <Card>
            <ScheduleFields
              scheduleType={scheduleType}
              scheduleDate={scheduleDate}
              setScheduleType={setScheduleType}
              setScheduleDate={setScheduleDate}
              label={label}
            />
          </Card>
        </Column.Side>
        <Column.Main>
          <SchedulesList
            originId={selectedOrigin?.value}
            destinationId={selectedDestination?.value}
            scheduleDate={scheduleDate}
            scheduleType={scheduleType}
            label={label}
          />
        </Column.Main>
      </Container>
    </SimpleFormPage>
  );
};

export default TimeTable;
