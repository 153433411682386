const labels = {
  All: {
    'Ref: See item': "Voir l'article",
    'Ref: Print': 'Imprimez votre reçu',
    'Ref: Email': 'Recevez votre reçu par e-mail',
    'Ref: Receipt Modal message': 'Comment aimeriez-vous obtenir votre reçu?',
    'Ref: Loyalty Reward': 'Récompense de fidélité',
    'Ref: Equation for stamps per item': '1 achat = 1 timbre',
    'Ref: Paid by employer': 'Le montant payé par votre employeur',
    'Ref: Paid by user': 'Le montant payé par vous',
  },
  ProductsList: {
    'Ref: Page title': 'Liste de produits',
    'Ref: Facility details': 'Informations sur le point de vente',
    'Ref: Search products list': 'Rechercher des produits',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Catégorie',
    'Ref: all': 'toutes',
    'Ref: today': "Aujourd'hui",
    'Ref: tomorrow': 'Demain',
    'Ref: see more': 'Plus',
    'Ref: see less': 'Voir moins',
    'Ref: reset': 'Réinitialiser',
    'Ref: View in cart': 'Voir le panier',
    'Ref: You have changed location': "Vous avez changé l'emplacement",
    'Ref: You are now at': 'Vous êtes maintenant à',
    'Ref: Menu card': 'La carte du menu',
    'Ref: Table': 'Numéro de table',
    'Service unavailable': 'Service indisponible',
    'Ref: Ordering unavailable':
      "Attention! La commande sur ce site n'est pas disponible pour le moment, veuillez essayer plus tard.",
    'Ref: Filters': 'Filtres (allergènes et plus)',
  },
  Order: { 'Ref: Page title': 'Commande' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menus',
    'Ref: today': "Aujourd'hui",
    'Ref: tomorrow': 'Demain',
    'Ref: Search products list': 'Articles de recherche',
    'Ref: You have changed location': "Vous avez changé l'emplacement",
    'Ref: You are now at': 'Vous êtes maintenant à',
  },
  ProductDetails: {
    'Ref: Page title': 'détails du produit',
    'Ref: Serving Size': 'Portion',
    'Ref: Nutrition': 'Informations nutritionnelles',
    'Ref: Nutrition adults': "Les adultes ont besoin d'environ 2000 kcal par jour",
    'Ref: Nutrition Calories': 'Calories',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Lipides',
    'Ref: Nutrition Saturated Fat': 'Gras saturé',
    'Ref: Nutrition Carbohydrates': 'Glucides',
    'Ref: Nutrition Sugar': 'Sucre',
    'Ref: Nutrition Protein': 'Protéines',
    'Ref: Nutrition Sodium': 'Sodium',
    'Ref: Nutrition cholesterol': 'Cholestérol',
    'Ref: Nutrition dietaryFiber': 'Fibre alimentaire',
    'Ref: Nutrition iron': 'Fer',
    'Ref: Nutrition potassium': 'Potassium',
    'Ref: Nutrition vitaminA': 'Vitamine A',
    'Ref: Nutrition vitaminC': 'Vitamine C',
    'Ref: Additives': 'Additifs',
    'Ref: Emission': 'Emissions de CO2',
    'Ref: This product generates': 'Ce produit génère',
    'Ref: of CO2': 'de CO2',
    'Ref: emissions, which equals a': 'émissions, ce qui équivaut à',
    'Ref: car trip': 'voyage en voiture',
    'Ref: Ingredients': 'Ingrédient de la recette',
    'Ref: Allergens': 'Allergènes',
    'Ref: Always there': 'Toujours là',
    'Ref: Allergens selected': 'allergènes sélectionnés sur le filtre',
    'Ref: minimum required': 'minimum requis',
    'Ref: you have to add at least': 'Vous devez ajouter au moins',
    'Ref: ingredients': 'Ingrédients',
    'Ref: Ingredient': 'ingrédient',
    'Ref: Free': 'Gratuit',
    'Ref: Based on customization': 'En fonction de votre personnalisation',
    'Ref: Additives eggs': 'des œufs',
    'Ref: Additives fish': 'poisson',
    'Ref: Additives milk': 'Le Lait',
    'Ref: Additives nuts': 'des noisettes',
    'Ref: Additives wheat': 'du blé',
    'Ref: Additives peanuts': 'cacahuètes',
    'Ref: Additives soy beans': 'soja',
    'Ref: Additives sesame seeds': 'graines de sésame',
    'Ref: Review': 'La revue',
    'Ref: Per portion': 'Par portion',
    'Ref: Per 100g': 'Par 100g',
    'Ref: Allergen selected': 'Allergène sélectionné sur le filtre de recherche',
    'Ref: Fitbit Log': 'Journal sur fitbit',
    'Ref: age verification required on delivery': "Vérification de l'âge requise à la livraison",
    'Ref: alcohol content notification':
      "En plaçant et en commande pour l'un de ces articles, vous déclarez que vous avez 18 ans ou plus. L'abus d'alcool est dangereux pour votre santé.",
    'Ref: default': 'Défaut',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} gagné si vous commandez ce produit',
    'Ref: Loyalty Card Description':
      'Le {stamp_form} sera ajouté à la fidélité suivante {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Le programme de timbres de fidélité vous permet de collecter des timbres pour les récompenses.',
    'Ref: Loyalty Modal Description':
      'Lorsque vous achetez <b> {product_name}, 1 timbre </b> sera ajouté au <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'et',
    'Ref: Stamp': 'Timbre',
    'Ref: Stamps': 'Timbres',
    'Ref: Program': 'Programme',
    'Ref: Programs': 'Programmes',
    'Ref: Loyalty Stamps': 'Timbres de fidélité',
    'Ref: See Loyalty Stamps': 'Voir les timbres de fidélité',
    Favorite: 'Préféré',
    'Ref: In favorites':
      'Le produit est déjà dans la liste des favoris. Cliquez pour le supprimer.',
    'Ref: Not in favorites':
      "Le produit n'est pas encore dans la liste des favoris. Cliquez pour l'ajouter.",
    'Ref: Added to favorites': 'Le produit a été ajouté à la liste des favoris.',
    'Ref: Removed from favorites': 'Le produit a été supprimé de la liste des favoris.',
    'Ref: From': 'De',
  },
  OrderHistory: { 'Ref: Orders': 'Ordres', 'Ref: Page Title': 'Mes commandes' },
  MenuTile: { 'Ref: Scan&Go': 'Scan et aller', 'Ref: View only': 'Voir uniquement' },
  ProductTile: {
    'Ref: Allergens': 'Allergènes',
    'Ref: Presence of allergen': "Présence d'allergènes",
    'Ref: Scan': 'Analyse',
    'Ref: From': 'De',
    'Ref: Already in product favorite list. Click to remove it.':
      'Déjà dans la liste préférée du produit. Cliquez pour le supprimer.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      "Actuellement non ajouté à la liste préférée du produit. Cliquez pour l'ajouter.",
    'Ref: Free': 'Gratuit',
  },
  MenusWidget: {
    'Ref: no menu': 'Pas de menu pour le moment',
    'Ref: Modal title': 'Menu pour la journée',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Récupérer',
    'Ref: Eat-in': 'Faire un repas',
    'Ref: Scan&Go': 'Scan et aller',
    'Ref: Delivery': 'Livraison',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'Comment était le service {facility_name}}?',
    'Ref: How do you rate the service': 'Comment évaluez-vous le service?',
  },
};
export default labels;
