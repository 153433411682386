import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { apiPath, clientId, shouldUseMockData } from '../config';
import { saveTokens } from '../modules/Core/helpers/tokensHelpers';
import mockResponse from '../modules/Core/mockResponse';

import { axiosClient } from './axiosClient';
import { TokenRefreshResponse } from './tokenRefresh.types';

let refreshTokenPromise: Promise<AxiosResponse<TokenRefreshResponse>> | undefined;
export const refreshAccessTokenAxios = async (refreshToken: string) => {
  if (shouldUseMockData)
    return (await mockResponse.refreshAccessToken.json()) as TokenRefreshResponse;

  try {
    if (!refreshTokenPromise) {
      // refresh token can be used only once to acquire new access token
      // ensure that just one http call to refresh access token is in progress
      const url = `${apiPath}/v2/oauth/token`;
      const config: AxiosRequestConfig = {
        opts: { withoutAccessToken: true },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      refreshTokenPromise = axiosClient.post<TokenRefreshResponse>(
        url,
        {
          client_id: clientId,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        },
        config
      );
    }

    const response = await refreshTokenPromise!;
    saveTokens(response.data);

    return response.data;
  } finally {
    refreshTokenPromise = undefined;
  }
};
