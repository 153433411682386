const labels = {
  All: {
    'Ref: See item': 'Žiūrėkite daiktą',
    'Ref: Print': 'Atspausdinkite kvitą',
    'Ref: Email': 'Gaukite kvitą el. Paštu',
    'Ref: Receipt Modal message': 'Kaip norėtumėte gauti kvitą?',
    'Ref: Loyalty Reward': 'Lojalumo atlygis',
    'Ref: Equation for stamps per item': '1 pirkinys = 1 antspaudas',
    'Ref: Paid by employer': 'Jūsų darbdavio sumokėta suma',
    'Ref: Paid by user': 'Suma, kurią sumokėjote',
  },
  ProductsList: {
    'Ref: Page title': 'Produktų sąrašas',
    'Ref: Facility details': 'Išsami informacija',
    'Ref: Search products list': 'Ieškoti prekių',
    'Ref: filter label: menus': 'Meniu',
    'Ref: filter label: moment': 'Momentas',
    'Ref: filter label: category': 'Kategorija',
    'Ref: all': 'visi',
    'Ref: today': 'šiandien',
    'Ref: tomorrow': 'rytoj',
    'Ref: see more': 'Žiūrėti daugiau',
    'Ref: see less': 'Žiūrėkite mažiau',
    'Ref: reset': 'Atstatyti.  T',
    'Ref: View in cart': 'Vaizdas krepšyje',
    'Ref: You have changed location': 'Jūs pakeitėte vietą',
    'Ref: You are now at': 'Dabar esate',
    'Ref: Menu card': 'Meniu kortelė',
    'Ref: Table': 'Lentelės numeris',
    'Service unavailable': 'Aptarnavimas nepasiekiamas',
    'Ref: Ordering unavailable':
      'Atsargiai! Užsakymas šioje svetainėje šiuo metu nėra prieinamas, pabandykite vėliau.',
    'Ref: Filters': 'Filtrai (alergenai ir dar daugiau)',
  },
  Order: { 'Ref: Page title': 'Įsakymas' },
  MenuSelector: {
    'Ref: Menu Page title': 'meniu',
    'Ref: today': 'šiandien',
    'Ref: tomorrow': 'rytoj',
    'Ref: Search products list': 'Ieškoti prekių',
    'Ref: You have changed location': 'Jūs pakeitėte vietą',
    'Ref: You are now at': 'Dabar esate',
  },
  ProductDetails: {
    'Ref: Page title': 'Produkto Aprašymas',
    'Ref: Serving Size': 'Savo dydį',
    'Ref: Nutrition': 'Mitybos informacija',
    'Ref: Nutrition adults': 'Suaugusiesiems reikia maždaug 2000 kcal per dieną',
    'Ref: Nutrition Calories': 'Kalorijos',
    'Ref: Nutrition Calcium': 'Kalcis.',
    'Ref: Nutrition Fat': 'riebalai',
    'Ref: Nutrition Saturated Fat': 'Sotieji riebalai',
    'Ref: Nutrition Carbohydrates': 'angliavandeniai',
    'Ref: Nutrition Sugar': 'Cukrus',
    'Ref: Nutrition Protein': 'Baltymas',
    'Ref: Nutrition Sodium': 'natris',
    'Ref: Nutrition cholesterol': 'Cholesterolio kiekis',
    'Ref: Nutrition dietaryFiber': 'Maistinė lasteliena',
    'Ref: Nutrition iron': 'Iron.',
    'Ref: Nutrition potassium': 'Kalio',
    'Ref: Nutrition vitaminA': 'Vitaminas A.',
    'Ref: Nutrition vitaminC': 'Vitamino C',
    'Ref: Additives': 'Priedai.  T',
    'Ref: Emission': 'CO2 emisija',
    'Ref: This product generates': 'Šis produktas generuoja',
    'Ref: of CO2': 'CO2.',
    'Ref: emissions, which equals a': 'emisijos, kurios yra a',
    'Ref: car trip': 'Automobilių kelionė',
    'Ref: Ingredients': 'Receptų ingredientas',
    'Ref: Allergens': 'alergenai',
    'Ref: Always there': 'Visada ten',
    'Ref: Allergens selected': 'Alergenai, parinkti ant filtro:',
    'Ref: minimum required': 'būtinas minimumas',
    'Ref: you have to add at least': 'Jūs turite bent pridėti',
    'Ref: ingredients': 'ingridientai',
    'Ref: Ingredient': 'ingredientas',
    'Ref: Free': 'Laisvas',
    'Ref: Based on customization': 'Remiantis jūsų pritaikymu',
    'Ref: Additives eggs': 'kiaušiniai',
    'Ref: Additives fish': 'žuvis',
    'Ref: Additives milk': 'pienas',
    'Ref: Additives nuts': 'riešutai',
    'Ref: Additives wheat': 'kvieciai',
    'Ref: Additives peanuts': 'riešutai',
    'Ref: Additives soy beans': 'sojos pupelės',
    'Ref: Additives sesame seeds': 'sezamo sėklos',
    'Ref: Review': 'Peržiūra',
    'Ref: Per portion': 'Už porciją',
    'Ref: Per 100g': 'Už 100 g',
    'Ref: Allergen selected': 'Alergenas, pasirinktas paieškos filtre',
    'Ref: Fitbit Log': 'Prisijunkite prie „Fitbit“',
    'Ref: age verification required on delivery': 'Reikalingas amžių tikrinimas',
    'Ref: alcohol content notification':
      'Pateikdami ir užsisakydami vieną iš šių daiktų, jūs sakote, kad esate 18 metų ar vyresnis. Piktnaudžiavimas alkoholiu yra pavojingas jūsų sveikatai.',
    'Ref: default': 'Numatytas',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} uždirbo, jei užsisakote šį produktą',
    'Ref: Loyalty Card Description':
      '{stamp_form} bus pridėtas prie šios lojalumo {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Programa „Lojalumo antspaudai“ leidžia rinkti antspaudus apdovanojimams.',
    'Ref: Loyalty Modal Description':
      'Kai perkate <b> {product_name}, 1 antspaudas </b> bus pridėtas prie <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'ir',
    'Ref: Stamp': 'Antspaudas',
    'Ref: Stamps': 'Antspaudai',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programos',
    'Ref: Loyalty Stamps': 'Lojalumo antspaudai',
    'Ref: See Loyalty Stamps': 'Žr. Lojalumo antspaudus',
    Favorite: 'Mėgstamiausia',
    'Ref: In favorites':
      'Produktas jau yra mėgstamiausiųjų sąraše. Spustelėkite, kad pašalintumėte.',
    'Ref: Not in favorites':
      'Produktas dar nėra mėgstamiausiųjų sąraše. Spustelėkite, jei norite pridėti.',
    'Ref: Added to favorites': 'Produktas buvo įtrauktas į mėgstamiausių sąrašą.',
    'Ref: Removed from favorites': 'Produktas buvo pašalintas iš mėgstamiausių sąrašo.',
    'Ref: From': 'Nuo',
  },
  OrderHistory: { 'Ref: Orders': 'Įsakymai', 'Ref: Page Title': 'Mano įsakymai' },
  MenuTile: { 'Ref: Scan&Go': 'Nuskaityti ir eiti', 'Ref: View only': 'Tik vaizdas' },
  ProductTile: {
    'Ref: Allergens': 'Alergenai',
    'Ref: Presence of allergen': 'Alergeno buvimas',
    'Ref: Scan': 'Nuskaitymas',
    'Ref: From': 'Nuo',
    'Ref: Already in product favorite list. Click to remove it.':
      'Jau mėgstamiausių produktų sąraše. Spustelėkite, kad pašalintumėte.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Šiuo metu nėra įtraukta į mėgstamų produktų sąrašą. Spustelėkite, jei norite pridėti.',
    'Ref: Free': 'Laisvas',
  },
  MenusWidget: { 'Ref: no menu': 'Šiuo metu nėra meniu', 'Ref: Modal title': 'Dienos meniu' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Paimti',
    'Ref: Eat-in': 'Valgymas',
    'Ref: Scan&Go': 'Nuskaityti ir eiti',
    'Ref: Delivery': 'Pristatymas',
    'Ref: Total': 'Iš viso',
    'Ref: How was facility name service': 'Kaip buvo {facility_name} paslauga?',
    'Ref: How do you rate the service': 'Kaip vertinate paslaugą?',
  },
};
export default labels;
