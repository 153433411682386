const labels = {
  StaysList: {
    'Ref: Page title': 'נשאר',
    'Ref: Past': 'עבר',
    'Current and future stays': 'נשאר הנוכחי והעתיד',
    'filter label: period': 'פרק זמן',
    'Ref: Search': 'לחפש',
  },
  StayDetails: {
    'Ref: Page title': 'פרטי השהייה שלך',
    'Arrival date': 'תאריך הגעה',
    'Departure date': 'תאריך עזיבה',
    'Room number': 'חדר',
    'Reservation number': 'מספר הזמנה',
    CheckIn: 'קבלה',
    CheckOut: 'לבדוק',
    CheckIn_1: 'בדוק לתוך {lodgeName}, מגיע היום? (מספר ההזמנה {resNumber})',
    CheckedIn_1: 'אתה נבדק לתוך {lodgeName}, חדר {roomNumber}.',
    CheckOut_1: 'בדוק {lodgeName} מספר ההזמנה {resNumber} עכשיו? מפתח החדר שלך יופעל.',
    CheckedOut_1: '{lodgeName} מספר ההזמנה {resNumber} בדק.',
  },
  StaysSuccess: {
    'Ref: Title': 'תודה על השהות!',
    'Ref: Body': 'הקופה שלך עובדה בהצלחה.',
    'Ref: Go to home': 'לך הביתה',
    'Ref: Provide a feedback': 'לספק משוב',
  },
};
export default labels;
