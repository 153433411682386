/* eslint-disable no-restricted-imports */
import { svgFactory } from '../svgFactory';

import { ReactComponent as AisleSvg } from './Aisle.svg';
import { ReactComponent as AllergenAlcoholSvg } from './AllergenAlcohol.svg';
import { ReactComponent as AllergenArrachideSvg } from './AllergenArrachide.svg';
import { ReactComponent as AllergenBeefSvg } from './AllergenBeef.svg';
import { ReactComponent as AllergenCelerySvg } from './AllergenCelery.svg';
import { ReactComponent as AllergenCornSvg } from './AllergenCorn.svg';
import { ReactComponent as AllergenCrustaceanSvg } from './AllergenCrustacean.svg';
import { ReactComponent as AllergenEggSvg } from './AllergenEgg.svg';
import { ReactComponent as AllergenFishSvg } from './AllergenFish.svg';
import { ReactComponent as AllergenGlutamateSvg } from './AllergenGlutamate.svg';
import { ReactComponent as AllergenGlutenSvg } from './AllergenGluten.svg';
import { ReactComponent as AllergenLupinSvg } from './AllergenLupin.svg';
import { ReactComponent as AllergenMilkSvg } from './AllergenMilk.svg';
import { ReactComponent as AllergenMolluscSvg } from './AllergenMollusc.svg';
import { ReactComponent as AllergenMustardSvg } from './AllergenMustard.svg';
import { ReactComponent as AllergenNutsSvg } from './AllergenNuts.svg';
import { ReactComponent as AllergenPorcSvg } from './AllergenPorc.svg';
import { ReactComponent as AllergenPoultrySvg } from './AllergenPoultry.svg';
import { ReactComponent as AllergenSesameSvg } from './AllergenSesame.svg';
import { ReactComponent as AllergenSO2Svg } from './AllergenSO2.svg';
import { ReactComponent as AllergenSoyBeanSvg } from './AllergenSoyBean.svg';
import { ReactComponent as AllergenSpicySvg } from './AllergenSpicy.svg';
import { ReactComponent as ArrowDownSvg } from './ArrowDown.svg';
import { ReactComponent as ArrowDownCircleSvg } from './ArrowDownCircle.svg';
import { ReactComponent as ArrowLeftSvg } from './ArrowLeft.svg';
import { ReactComponent as ArrowRightSvg } from './ArrowRight.svg';
import { ReactComponent as BannerSvg } from './Banner.svg';
import { ReactComponent as BarcodeSvg } from './Barcode.svg';
import { ReactComponent as BuildingSvg } from './Building.svg';
import { ReactComponent as BusTimetableSvg } from './BusTimetable.svg';
import { ReactComponent as CalendarSvg } from './Calendar.svg';
import { ReactComponent as Calendar2Svg } from './Calendar2.svg';
import { ReactComponent as CartSvg } from './Cart.svg';
import { ReactComponent as ChatSvg } from './Chat.svg';
import { ReactComponent as CheckSvg } from './Check.svg';
import { ReactComponent as CheckboxCheckSvg } from './CheckboxCheck.svg';
import { ReactComponent as CheckCircleSvg } from './CheckCirclefill.svg';
import { ReactComponent as ChevronDownSvg } from './ChevronDown-24.svg';
import { ReactComponent as ChevronLeftSvg } from './ChevronLeft.svg';
import { ReactComponent as ChevronLeftWithDotSvg } from './ChevronLeftWithDot.svg';
import { ReactComponent as ChevronRightSvg } from './ChevronRight.svg';
import { ReactComponent as ClockSvg } from './Clock.svg';
import { ReactComponent as Close20Svg } from './Close-20.svg';
import { ReactComponent as CloseSvg } from './Close-24.svg';
import { ReactComponent as CoffeeSvg } from './Coffee.svg';
import { ReactComponent as CommentSvg } from './Comment.svg';
import { ReactComponent as CongrateSvg } from './Congrate.svg';
import { ReactComponent as CopySvg } from './Copy.svg';
import { ReactComponent as CreditCardSvg } from './CreditCard.svg';
import { ReactComponent as CrossSvg } from './Cross.svg';
import { ReactComponent as DeliverySvg } from './DeliveryIcon.svg';
import { ReactComponent as DocumentSvg } from './Document.svg';
import { ReactComponent as DownloadSvg } from './Download.svg';
import { ReactComponent as EditingListULSvg } from './EditingListUL.svg';
import { ReactComponent as EditionImageSvg } from './EditionImage.svg';
import { ReactComponent as EditionPhotoSvg } from './EditionPhoto.svg';
import { ReactComponent as EditOnSvg } from './EditOn.svg';
import { ReactComponent as EllipsisSvg } from './Ellipsis.svg';
import { ReactComponent as EmailSvg } from './Email.svg';
import { ReactComponent as ClearCircleSvg } from './Errorfill.svg';
import { ReactComponent as EventScheduleSvg } from './EventSchedule-24.svg';
import { ReactComponent as ExternalLinkSvg } from './ExternalLink.svg';
import { ReactComponent as EyeSvg } from './Eye.svg';
import { ReactComponent as EyeFillSvg } from './Eyefill.svg';
import { ReactComponent as EyeOnSvg } from './EyeOn.svg';
import { ReactComponent as EyeSlashSvg } from './EyeSlashfill.svg';
import { ReactComponent as FaqSvg } from './Faq.svg';
import { ReactComponent as FavoriteSvg } from './Favorite.svg';
import { ReactComponent as FileTreeSvg } from './FileTree.svg';
import { ReactComponent as FilterSvg } from './Filter.svg';
import { ReactComponent as FitbitSvg } from './Fitbit.svg';
import { ReactComponent as FloorSvg } from './Floor.svg';
import { ReactComponent as GiftSvg } from './Gift.svg';
import { ReactComponent as GlutenSvg } from './Gluten.svg';
import { ReactComponent as HomeSvg } from './Home.svg';
import { ReactComponent as ImagePlaceholderSvg } from './ImagePlaceholder.svg';
import { ReactComponent as InformationSvg } from './Information.svg';
import { ReactComponent as InformationFillSvg } from './InformationFill.svg';
import { ReactComponent as InterestingSvg } from './Interesting.svg';
import { ReactComponent as ItemsListSvg } from './ItemsList.svg';
import { ReactComponent as LikeSvg } from './Like.svg';
import { ReactComponent as ListTaskSvg } from './ListTask.svg';
import { ReactComponent as LocationSvg } from './Location.svg';
import { ReactComponent as LocationFillSvg } from './LocationFill.svg';
import { ReactComponent as LocationOffSvg } from './LocationOff.svg';
import { ReactComponent as LoveSvg } from './Love.svg';
import { ReactComponent as MagnifierSvg } from './Magnifier.svg';
import { ReactComponent as MapSvg } from './Map.svg';
import { ReactComponent as MenuDownloadSvg } from './MenuDownload.svg';
import { ReactComponent as MinusSignSvg } from './MinusSign.svg';
import { ReactComponent as MoreSvg } from './More-24.svg';
import { ReactComponent as MoreVerticalSvg } from './MoreVertical.svg';
import { ReactComponent as NavArrowRightSvg } from './NavArrowRight.svg';
import { ReactComponent as NavigatorMenu } from './NavigatorMenu.svg';
import { ReactComponent as NetworkSvg } from './Network.svg';
import { ReactComponent as NonOrderableSvg } from './NonOrderable-24.svg';
import { ReactComponent as NutritionsAndAllergensSvg } from './NutritionsAndAllergens.svg';
import { ReactComponent as OccupancySvg } from './Occupancy-24.svg';
import { ReactComponent as OperationsRefreshSvg } from './OperationsRefresh.svg';
import { ReactComponent as PaymentStatusSvg } from './PaymentStatus.svg';
import { ReactComponent as PencilSvg } from './Pencil.svg';
import { ReactComponent as PlusSignSvg } from './PlusSign.svg';
import { ReactComponent as QrSvg } from './QrCode.svg';
import { ReactComponent as ReceiptSvg } from './Receipt.svg';
import { ReactComponent as RefreshSvg } from './Refresh.svg';
import { ReactComponent as RestaurantSvg } from './Restaurant.svg';
import { ReactComponent as RewardSvg } from './Reward.svg';
import { ReactComponent as RoomSvg } from './Room.svg';
import { ReactComponent as SearchSvg } from './Search.svg';
import { ReactComponent as ShoppingBagSvg } from './ShoppingBag.svg';
import { ReactComponent as SoyBeanSvg } from './SoyBean.svg';
import { ReactComponent as SpeechBubbleSvg } from './SpeechBubble.svg';
import { ReactComponent as StampSvg } from './Stamp-24.svg';
import { ReactComponent as StarFilledSvg } from './StarFilled-24.svg';
import { ReactComponent as StarOutlinedSvg } from './StarOutlined-24.svg';
import { ReactComponent as StatusCheckCircleSvg } from './StatusCheckCircle.svg';
import { ReactComponent as StatusErrorSvg } from './StatusError.svg';
import { ReactComponent as StatusPendingSvg } from './StatusPending.svg';
import { ReactComponent as ThumbsUpSvg } from './ThumbsUp.svg';
import { ReactComponent as TimeSvg } from './Time.svg';
import { ReactComponent as ToolSvg } from './Tool.svg';
import { ReactComponent as TrashCanSvg } from './TrashCan.svg';
import { ReactComponent as TravelLocationSvg } from './TravelLocation.svg';
import { ReactComponent as TrophySvg } from './Trophy.svg';
import { ReactComponent as UploadSvg } from './Upload.svg';
import { ReactComponent as AccountSvg20 } from './User-20.svg';
import { ReactComponent as AccountSvg } from './User-24.svg';
import { ReactComponent as UserIdentificationSvg } from './UserIdentification.svg';
import { ReactComponent as ValidationDefaultSvg } from './ValidationDefault.svg';
import { ReactComponent as ValidationInvalidSvg } from './ValidationInvalid.svg';
import { ReactComponent as ValidationValidSvg } from './ValidationValid.svg';
import { ReactComponent as VeganSvg } from './Vegan.svg';
import { ReactComponent as WarningSvg } from './Warning.svg';
import { ReactComponent as ErrorCircleSvg } from './Warning.svg';
import { ReactComponent as WarningAllergensSvg } from './WarningAllergens.svg';
import { ReactComponent as WarningOutlined } from './WarningOutlined.svg';

export const AccountIcon = svgFactory(AccountSvg);
export const AccountIcon20 = svgFactory(AccountSvg20);
export const AisleIcon = svgFactory(AisleSvg);
export const AllergenAlcoholIcon = svgFactory(AllergenAlcoholSvg);
export const AllergenArrachideIcon = svgFactory(AllergenArrachideSvg);
export const AllergenBeefIcon = svgFactory(AllergenBeefSvg);
export const AllergenCeleryIcon = svgFactory(AllergenCelerySvg);
export const AllergenCornIcon = svgFactory(AllergenCornSvg);
export const AllergenCrustaceanIcon = svgFactory(AllergenCrustaceanSvg);
export const AllergenEggIcon = svgFactory(AllergenEggSvg);
export const AllergenFishIcon = svgFactory(AllergenFishSvg);
export const AllergenGlutamateIcon = svgFactory(AllergenGlutamateSvg);
export const AllergenGlutenIcon = svgFactory(AllergenGlutenSvg);
export const AllergenLupinIcon = svgFactory(AllergenLupinSvg);
export const AllergenMilkIcon = svgFactory(AllergenMilkSvg);
export const AllergenMolluscIcon = svgFactory(AllergenMolluscSvg);
export const AllergenMustardIcon = svgFactory(AllergenMustardSvg);
export const AllergenNutsIcon = svgFactory(AllergenNutsSvg);
export const AllergenPorcIcon = svgFactory(AllergenPorcSvg);
export const AllergenPoultryIcon = svgFactory(AllergenPoultrySvg);
export const AllergenSesameIcon = svgFactory(AllergenSesameSvg);
export const AllergenSO2Icon = svgFactory(AllergenSO2Svg);
export const AllergenSoyBeanIcon = svgFactory(AllergenSoyBeanSvg);
export const AllergenSpicyIcon = svgFactory(AllergenSpicySvg);
export const ArrowDown = svgFactory(ArrowDownSvg);
export const ArrowDownCircleIcon = svgFactory(ArrowDownCircleSvg);
export const ArrowRightIcon = svgFactory(ArrowRightSvg);
export const ArrowLeftIcon = svgFactory(ArrowLeftSvg);
export const BannerIcon = svgFactory(BannerSvg);
export const BarcodeIcon = svgFactory(BarcodeSvg);
export const BuildingIcon = svgFactory(BuildingSvg);
export const BusTimetableIcon = svgFactory(BusTimetableSvg);
export const Calendar2Icon = svgFactory(Calendar2Svg);
export const CalendarIcon = svgFactory(CalendarSvg);
export const CartIcon = svgFactory(CartSvg);
export const ChatIcon = svgFactory(ChatSvg);
export const CheckboxCheckIcon = svgFactory(CheckboxCheckSvg);
export const CheckCircleIcon = svgFactory(CheckCircleSvg);
export const CheckIcon = svgFactory(CheckSvg);
export const ChevronDownIcon = svgFactory(ChevronDownSvg);
export const ChevronLeftIcon = svgFactory(ChevronLeftSvg);
export const ChevronLeftWithDotIcon = svgFactory(ChevronLeftWithDotSvg);
export const ChevronRightIcon = svgFactory(ChevronRightSvg);
export const ClearCircleIcon = svgFactory(ClearCircleSvg);
export const ClockIcon = svgFactory(ClockSvg);
export const CloseIcon = svgFactory(CloseSvg);
export const Close20Icon = svgFactory(Close20Svg);
export const CoffeeIcon = svgFactory(CoffeeSvg);
export const CommentIcon = svgFactory(CommentSvg);
export const CongrateIcon = svgFactory(CongrateSvg);
export const CopyIcon = svgFactory(CopySvg);
export const CreditCardIcon = svgFactory(CreditCardSvg);
export const CrossIcon = svgFactory(CrossSvg);
export const DeliveryIcon = svgFactory(DeliverySvg);
export const DocumentIcon = svgFactory(DocumentSvg);
export const DownloadIcon = svgFactory(DownloadSvg);
export const EditOnIcon = svgFactory(EditOnSvg);
export const EditionPhotoIcon = svgFactory(EditionPhotoSvg);
export const Ellipsis = svgFactory(EllipsisSvg);
export const Email = svgFactory(EmailSvg);
export const ErrorCircleIcon = svgFactory(ErrorCircleSvg);
export const EventScheduleIcon = svgFactory(EventScheduleSvg);
export const ExternalLink = svgFactory(ExternalLinkSvg);
export const EyeFillIcon = svgFactory(EyeFillSvg);
export const EyeIcon = svgFactory(EyeSvg);
export const EyeOnIcon = svgFactory(EyeOnSvg);
export const EyeSlashIcon = svgFactory(EyeSlashSvg);
export const FaqIcon = svgFactory(FaqSvg);
export const FileTreeIcon = svgFactory(FileTreeSvg);
export const FilterIcon = svgFactory(FilterSvg);
export const FitbitIcon = svgFactory(FitbitSvg);
export const FloorIcon = svgFactory(FloorSvg);
export const GiftIcon = svgFactory(GiftSvg);
export const MagnifierIcon = svgFactory(MagnifierSvg);
export const NavArrowRightIcon = svgFactory(NavArrowRightSvg);
export const Gluten = svgFactory(GlutenSvg);
export const HomeIcon = svgFactory(HomeSvg);
export const ImagePlaceholderIcon = svgFactory(ImagePlaceholderSvg);
export const Information = svgFactory(InformationSvg);
export const InformationIcon = svgFactory(InformationFillSvg);
export const InterestingIcon = svgFactory(InterestingSvg);
export const ItemsListIcon = svgFactory(ItemsListSvg);
export const LikeIcon = svgFactory(LikeSvg);
export const ListTask = svgFactory(ListTaskSvg);
export const LocationIcon = svgFactory(LocationSvg);
export const LocationFillIcon = svgFactory(LocationFillSvg);
export const LocationOffIcon = svgFactory(LocationOffSvg);
export const LoveIcon = svgFactory(LoveSvg);
export const MapIcon = svgFactory(MapSvg);
export const MenuDownloadIcon = svgFactory(MenuDownloadSvg);
export const MinusSignIcon = svgFactory(MinusSignSvg);
export const MoreIcon = svgFactory(MoreSvg);
export const NetworkIcon = svgFactory(NetworkSvg);
export const NonOrderableIcon = svgFactory(NonOrderableSvg);
export const NutritionsAndAllergensIcon = svgFactory(NutritionsAndAllergensSvg);
export const OccupancyIcon = svgFactory(OccupancySvg);
export const OperationsRefreshIcon = svgFactory(OperationsRefreshSvg);
export const PaymentStatusIcon = svgFactory(PaymentStatusSvg);
export const Pencil = svgFactory(PencilSvg);
export const PlusSignIcon = svgFactory(PlusSignSvg);
export const QrIcon = svgFactory(QrSvg);
export const Receipt = svgFactory(ReceiptSvg);
export const RefreshIcon = svgFactory(RefreshSvg);
export const Restaurant = svgFactory(RestaurantSvg);
export const RewardIcon = svgFactory(RewardSvg);
export const RoomIcon = svgFactory(RoomSvg);
export const SearchIcon = svgFactory(SearchSvg);
export const ShoppingBagIcon = svgFactory(ShoppingBagSvg);
export const SoyBean = svgFactory(SoyBeanSvg);
export const SpeechBubbleIcon = svgFactory(SpeechBubbleSvg);
export const StampIcon = svgFactory(StampSvg);
export const StarFilled = svgFactory(StarFilledSvg);
export const StarOutlined = svgFactory(StarOutlinedSvg);
export const Time = svgFactory(TimeSvg);
export const Tool = svgFactory(ToolSvg);
export const TrashCanIcon = svgFactory(TrashCanSvg);
export const TrophyIcon = svgFactory(TrophySvg);
export const UploadIcon = svgFactory(UploadSvg);
export const UserIdentificationIcon = svgFactory(UserIdentificationSvg);
export const ValidationDefault = svgFactory(ValidationDefaultSvg);
export const ValidationInvalid = svgFactory(ValidationInvalidSvg);
export const ValidationValid = svgFactory(ValidationValidSvg);
export const WarningAllergens = svgFactory(WarningAllergensSvg);
export const WarningIcon = svgFactory(WarningSvg);
export const ThumbsUpIcon = svgFactory(ThumbsUpSvg);
export const FavoriteIcon = svgFactory(FavoriteSvg);
export const MoreVerticalIcon = svgFactory(MoreVerticalSvg);
export const VeganIcon = svgFactory(VeganSvg);
export const StatusErrorIcon = svgFactory(StatusErrorSvg);
export const StatusPendingIcon = svgFactory(StatusPendingSvg);
export const EditingListULIcon = svgFactory(EditingListULSvg);
export const TravelLocationIcon = svgFactory(TravelLocationSvg);
export const StatusCheckCircleIcon = svgFactory(StatusCheckCircleSvg);
export const EditionImageIcon = svgFactory(EditionImageSvg);
export const NavigatorMenuIcon = svgFactory(NavigatorMenu);
export const WarningOutlinedIcon = svgFactory(WarningOutlined);
