const labels = {
  All: {
    Feedback: 'Feedback',
    'Ref: Switch site component intro sentence': 'Ref: Switch site component intro sentence',
    Category: 'Category',
    Subject: 'Subject',
    Comment: 'Comment',
    'Date of your experience': 'Date of your experience',
    'Ref: Hint': 'Ref: Hint',
    'Ref: Log Request': 'Ref: Log Request',
    'Ref: Describe issue': 'Ref: Describe issue',
    Date: 'Date',
    'Ref: Share your opinion header': 'Ref: Share your opinion header',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurant / Café',
    'Desk / workstation area': 'Desk / workstation area',
    'Meeting room facilities': 'Meeting room facilities',
    'Coffee/tea facilities (pantry)': 'Coffee/tea facilities (pantry)',
    'Restrooms / washrooms': 'Restrooms / washrooms',
    'Reception / Security': 'Reception / Security',
    'Conference center': 'Conference center',
    'Fitness center': 'Fitness center',
    Mailroom: 'Mailroom',
    'Bicycle room': 'Bicycle room',
    'Shuttle services': 'Shuttle services',
    Lockers: 'Lockers',
    Helpdesk: 'Helpdesk',
  },
  Create: {
    'Tell us!': 'Tell us!',
    'Ref: All Ears': 'Ref: All Ears',
    'Ref: Provide Description': 'Ref: Provide Description',
    'Ref: Fill-in-the-blank sentence': 'Ref: Fill-in-the-blank sentence',
    'Ref: Link text to service request module': 'Ref: Link text to service request module',
    'Complaint type': 'Complaint type',
    'Feedback type': 'Feedback type',
    'Compliment type': 'Compliment type',
  },
  CreateFailure: { 'Ref: Body': 'Ref: Body' },
  CreateSuccess: { 'Ref: Body': 'Ref: Body', 'New feedback': 'New feedback' },
  CreateFlow: {
    Description: 'Description',
    'Ref: Provide Description': 'Ref: Provide Description',
    'Ref: I would like to provide a': 'Ref: I would like to provide a',
    'Ref: in regards to': 'Ref: in regards to',
    'Ref: &': 'Ref: &',
    'Ref: at': 'Ref: at',
    'Ref: on the': 'Ref: on the',
  },
};
export default labels;
