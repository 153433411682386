import { cleanup, screen } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';

import { NotificationsSectionProps } from './NotificationsList.types';
import NotificationsSection from './NotificationsSection';

jest.mock('../../api/notifications/notificationsApi', () => ({
  useCloseNotificationMutation: () => {
    return [];
  },
}));
describe('NotificationsSection', () => {
  afterAll(() => cleanup());

  const props: NotificationsSectionProps = { items: [], title: 'Some title' };
  renderComponent(NotificationsSection, props);

  it("Don't render anything when list is empty", () => {
    const element = screen.queryByTestId('notification-section-title');
    expect(element).not.toBeInTheDocument();
  });

  describe('renders without crashing', () => {
    const props: NotificationsSectionProps = {
      items: [
        {
          id: 'some-id-1',
          title: 'Some title here',
          message: 'Some message here',
          serviceId: 'some-id-2',
          notificationDate: '',
          linkedEntity: 'Event',
          eventId: 'some-id-3',
          contentId: 'some-id-4',
          acknowledgedOn: '',
          acknowledged: false,
        },
      ],
      title: 'Some title',
    };
    renderComponent(NotificationsSection, props);

    it('renders NotificationsSection component', () => {
      const element = screen.queryByTestId('notification-section-title');
      expect(element).toBeInTheDocument();
    });

    it('check title presence', () => {
      const element = screen.queryByTestId('notification-section-title');
      expect(element).toHaveTextContent(props.title);
    });

    it('check tile presence', () => {
      const element = screen.queryByTestId(`notification-tile-${props.items[0].id}`);
      expect(element).toBeInTheDocument();
    });
  });
});
