const labels = {
  All: {
    'status: all': 'Todo',
    'status: open': 'Abierto',
    'status: pending': 'Pendiente',
    'status: closed': 'Cerrado',
    Location: 'Ubicación',
    Subject: 'Tema',
    Description: 'Su solicitud detallada',
    'Ref: description field label': 'Descripción',
    'Ref: Title': 'Título',
    phone_number: 'Número de teléfono',
    request_category: 'Categoría de la solicitud',
    Attachments: 'Archivos adjuntos',
    'request service': 'Solicitud de servicio',
    'New service request': 'Crear otra solicitud',
    'Go to home': 'Ir a la página de inicio',
    'Back to this service request': 'Volver a esta solicitud de servicio',
    'Back to service requests': 'Volver a las solicitudes de servicio',
    Feedback: 'Comentarios',
    'Ref: Request category': 'Categoría',
    Cleaning: 'Limpieza',
    'Laundry services': 'Servicios de lavanderia',
    'Grounds maintenance': 'Jardinería',
    'Soft services': 'Soft services',
    'Hard services': 'Hard services',
    'Pest control services': 'Servicios de control de plagas',
    'Waste management': 'Gestión de residuos',
    'Asset Lifecycle Services': 'Servicios de ciclo de vida de los activos',
    'There are no updates for this request': 'No hay actualizaciones para esta solicitud',
    'Transportation Services': 'Servicio de transporte',
    'Ref: you pronoun': '(usted)',
    'Ref: SR raised for': 'Persona afectada por esta solicitud',
    'Ref: Select a location': 'Selecciona una ubicación',
    'Ref: Your request': 'Su petición',
    'Ref: Summary': 'Resumen',
    'Ref: site': 'Centro',
  },
  Comment: {
    'Ref: Page title': 'Actualiza la información',
    'Ref: please update us with details':
      '¿Más información para comunicarnos? ¿Algo adicional? Cuanto más sepamos acerca de su solicitud, mejor podremos ayudarle.',
  },
  Confirm: {
    'Ref: Page title': 'Confirma tu solicitud',
    'Ref: next': 'Enviar',
    summary: 'Resumen',
    'Ref: Save as my preferred location': 'Guardar como mi ubicación preferida',
  },
  Describe: {
    'Ref: Page title': 'Describa su solicitud',
    'Ref: Required fields: header': 'Por favor proporcione información adicional',
    'Ref: Required fields':
      'La siguiente información debe proporcionarse dentro del campo Detalles:',
    'Ref: Required field: title': 'Se requiere un título.',
    'Ref: Required field: description': 'Se requiere una descripción.',
    'Ref: Required field: phone': 'Se requiere un número de teléfono.',
    'Ref: Required field: category': 'Se requiere una categoría.',
    'Ref: Invalid field: category': 'La categoría proporcionada no es válida.',
    'Ref: Request template field label': 'Solicitudes populares',
    'Ref: Request template field placeholder': 'Hoja en blanco',
    your_request: 'Su solicitud',
    'Ref: Description message': 'Cuanto más detallado sea, mejor podemos ayudarle.',
    phone_number: 'Su número de teléfono (use su teléfono profesional si tiene uno)',
    'Ref: Affected person': 'Persona afectada por esta solicitud',
    'Ref: No affected person results':
      'No hay ninguna persona que coincida con los criterios solicitados',
    'Ref: Notification title': 'Ha cambiado la ubicación',
    'Ref: Notification info': 'Ahora está en {site name}',
    'Ref: Add photos': 'Agregar fotos',
    'Ref: Your location': 'Tu ubicación',
  },
  Details: {
    'Ref: Page title': 'Pedir detalles',
    Details: 'Detalles',
    Updates: 'Actualizaciones',
    'Update us': 'Actualícenos',
    'Ref: timeline': 'Cronología',
    'Ref: attachments': 'Archivos adjuntos',
    'Ref: location': 'Ubicación',
    'Ref: createdOn': 'Creado en',
    'Ref: reference': 'Referencia',
    'Feedback was submitted': 'Comentario enviado',
    'Ref: sent': 'Enviado',
    'Ref: received': 'Recibido',
    'Ref: inProgress': 'En progreso',
    'Ref: completed': 'Terminado',
    'Ref: conversation': 'Conversación',
    'Ref: Priority': 'Prioridad',
    'Ref: Target start date': 'Fecha de inicio del objetivo',
    'Ref: Target completion date': 'Fecha límite de finalización',
    'Ref: Priority fields placeholder message': 'Será poblado una vez validado por mesa de ayuda',
  },
  Failed: {
    'Ref: Body':
      'Se produjo un error y no se creó su solicitud de servicio. Si este error persiste, póngase en contacto con el mesa de ayuda',
    'Ref: Tabtitle': 'La solicitud de servicio no pudo crear',
  },
  Home: { 'Ref: Page title': 'Peticiones' },
  RequestFeedback: {
    'Ref: Page title': 'Comentarios',
    'Ref: Feedback headline': 'Por favor comparta sus comentarios',
    'Ref: Feedback title': 'Comentarios sobre SR # {ref}',
    'Ref: Question: general sentiment':
      'En general, ¿cómo está de satisfecho con la gestión que Sodexo ha hecho sobre tu solicitud?',
    'Ref: Question: answer quality': '¿Cómo calificaría la calidad de la gestión de tu solicitud?',
    'Ref: Question: staff interaction': '¿Cómo calificaría su interacción con nuestro personal?',
    'Ref: Question: efficiency': '¿Cómo calificaría la respuesta a su solicitud?',
    'Ref: Question: open': 'Comentario',
    'Ref: Open question placeholder': '¿Qué hicimos bien? ¿Dónde podemos mejorar?',
    no_comment: 'Sin comentarios',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Gracias por tomarse su tiempo para dejar un comentario, sus comentarios son importantes para que podamos mejorar su experiencia.',
  },
  Locate: {
    'Ref: Page title': 'Seleccione su ubicación',
    'Ref: Top bar title': 'Localice su solicitud',
    'Ref: Select this location': 'Seleccione esta ubicación',
    drill: 'navegar',
    'Ref: Scanner button': '¿Ubicación con un código QR? Escanéelo',
    'Ref: Scanner header': 'Por favor, escanee su código QR de ubicación',
    'Sorry, this location could not be found.': 'Lo sentimos, no se pudo encontrar esta ubicación',
    'Close scanner': 'Acerque el escáner',
    'Ref: Switch site component intro sentence':
      'Usted está solicitando un servicio en el centro {site name}.',
    'Ref: search location by keywords': 'Buscar una ubicación',
    'Ref: Enter Location name': 'Introduzca el nombre de la ubicación',
    'Ref: Enter manual location': 'Introduzca la ubicación manualmente',
    'Ref: Location name': 'Nombre de la ubicación',
    'Ref: No results':
      'No pudimos encontrar su ubicación. Es posible que desee introducirla manualmente.',
    'Ref: Up one level': 'Un nivel atrás',
    'Ref: Navigate': 'Navegar',
    'Ref: Select': 'Seleccione',
    'Ref: Selected Location': 'Ubicación seleccionada',
    'Ref: Preferred Location': 'Ubicación preferida',
    'Ref: Building': 'Edificio',
    'Ref: Floor': 'Planta',
    'Ref: Aisle': 'Pasillo',
    'Ref: Room': 'Sala',
    'Ref: Notification title': 'Ha cambiado la ubicación',
    'Ref: Notification info': 'Ahora está en {site name}',
    'Ref: Scanner error header': 'Error de exploración',
    'Ref: Scan QR Code': 'Escanear código QR',
    'Ref: Error body': 'Este código QR no es un código válido',
    'Ref: Loading Locations Warning':
      'Espere mientras preparemos su experiencia para una primera solicitud en este sitio.',
    'Ref: Search Result is limited to:':
      'El resultado de la búsqueda se limita a {limit}. Refine sus criterios de búsqueda.',
    'Ref: Open the location search field': 'Abra el campo de búsqueda de ubicación',
  },
  Success: {
    'Ref: Body':
      'Su solicitud de servicio fue enviada, se está procesando y aparecerá en la app en breve. Recibirá una nota cuando se resuelva. Mientras tanto, puede seguir su estado y agregar comentarios a través de esta aplicación.',
    'Ref: Tabtitle': 'Solicitud de servicio creada',
    'Ref: Download app title': 'Simplifique sus solicitudes de servicio',
    'Ref: Download app subtitle': 'Descarga nuestra aplicación y mejora tu experiencia',
    'Ref: Download app button': 'Descarga la aplicación',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Este es su centro de soporte',
    'Ref: Tutorial: tabname': 'Peticiones',
    'Ref: Tutorial: body': 'Estamos aquí para ayudarte. Registre una solicitud por entrada.',
    "What's new: title": 'Encuentre su ubicación fácilmente',
    "What's new: body":
      'Ahora puede escanear una ubicación QR, navegar por los niveles de las ubicaciones, o simplemente seleccionar nuestras sugerencias inteligentes.',
  },
  Widget: {
    'Ref: Service request': 'Petición de servicio',
    'Ref: Placeholder body': 'Sin solicitud de servicio por el momento',
    'Ref: New request button': 'Nueva solicitud',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Ha cambiado la ubicación',
    'Ref: Notification info': 'Ahora está en {site name}',
    'Ref: Welcome': '¡Bienvenido! {ola_emoji}',
    'Ref: Create service request text': '¿Notó algo que desea informar?',
    'Create a service request': 'Crear una solicitud de servicio',
    'Ref: Share feedback': 'Compartir un comentario',
    'Ref: Share text': '¿Te gustaría compartir algo con nosotros?',
    'Go to home': 'Ir a la página de inicio',
    'Ref: Error body':
      'El código QR que escaneaste ya no funciona. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Ha ocurrido un error',
    'Ref: Error body':
      'El código QR que escaneaste ya no funciona. Inténtelo de nuevo más tarde o comuníquese con el soporte.',
    'Go to home': 'Ir a la página de inicio',
  },
  ManualDescribe: {
    'Ref: Page title': 'Describa su solicitud',
    'Ref: Required fields: header': 'Proporcione información adicional',
    'Ref: Required fields':
      'Se debe proporcionar la siguiente información dentro del campo Detalles:',
    'Ref: Required field: title': 'Se requiere un resumen.',
    'Ref: Required field: description': 'Se requiere una descripción.',
    'Ref: Required field: category': 'Se requiere una categoría.',
    'Ref: Required field: buildingText': 'Se requiere un edificio.',
    'Ref: Invalid field: category': 'La categoría proporcionada no es válida.',
    'Ref: Your request': 'Su petición',
    'Ref: Description message': 'Cuanto más detallado sea, mejor podemos ayudarle.',
    'Ref: Do you have a location QR code': '¿Tiene un código QR de ubicación?',
    'Ref: Scan QR Code': 'Escanee código QR',
    'Ref: Please select category': 'Seleccione la categoría',
    'Ref: Please select site': 'Seleccione centro',
    'Ref: building': 'Edificio',
    'Ref: room': 'Sala',
    'Ref: Scan error': 'Error de exploración',
    'Ref: The QR code is not valid': 'El código QR no es válido',
    'Ref: Add location with QR Scanner': 'Agregar ubicación con el escáner QR',
    'Ref: Description': 'Descripción',
    'Ref: Forbidden error': 'Lo sentimos, este código QR no coincide con su ubicación registrada.',
    'Ref: Invalid error': 'Lo sentimos, parece que este código QR ya no es válido.',
  },
};
export default labels;
