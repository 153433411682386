const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Kérésed',
    'Ref: Summary': 'Összefoglalás',
    'Ref: Request category': 'Kérjen kategóriát',
    'Ref: Person affected': 'A kérés által érintett személy',
    'Ref: Your phone number': 'Telefonszám',
    'Ref: Description': 'Leírás',
    'Ref: Service Requests': 'Szolgáltatási kérelmek',
    'Ref: Request Service': 'Kérés szolgáltatás',
    'Ref: Success title': 'A kérése létrehozta',
    'Ref: Error title': 'Hoppá!',
    'Ref: Error subtitle': 'Hiba történt, kérjük, próbálkozzon újra.',
    'Ref: Try again': 'Próbáld újra',
    'Ref: you pronoun': '(te)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Ez a munkahelyi támogató hub',
    'Ref: Tutorial: tabname': 'Kérések',
    'Ref: Tutorial: body':
      'Azért vagyunk itt, hogy segítsünk. Kérjük, jelentkezzen be egy kérést bejegyzésenként.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Ön szolgáltatást kér a {siteName} webhelyen.',
    'Ref: Building': 'Épület',
    'Ref: Room': 'Szoba',
    'Ref: Description help text': 'Minél részletesebb vagy, annál jobban tudunk támogatni téged.',
    'Ref: Next': 'Következő',
    'Ref: Your request': 'A kérése',
    'Ref: PhoneNumber error msg': 'A telefonszám nem érvényes',
    'Ref: Type at least 3 characters to search': 'Írjon be legalább 3 karaktert a kereséshez',
    'Ref: No affected person results': 'Senkinek nem egyezik meg a kért kritériumokat',
    'Ref: Required': '* Szükséges',
  },
  Summary: {
    'Ref: Location': 'Elhelyezkedés',
    'Ref: Attachment': 'Mellékletek',
    'Ref: Submit': 'Beküldés',
    'Ref: No affected person results': 'Senkinek nem egyezik meg a kért kritériumokat',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Szolgáltatásigénylés',
    'Ref: createdOn': 'Készült ekkor',
    'Ref: summary': 'Összefoglalás',
    'Ref: category': 'Kérjen kategóriát',
    'Ref: request raised for': 'A kérés által érintett személy',
    'Ref: phoneNumber': 'Telefonszám',
    'Ref: location': 'Telefonszám',
    'Ref: description': 'Leírás',
    'Ref: attachments': 'Mellékletek',
  },
};
export default labels;
