import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';
import { getFacilityMenuPath } from '../helpers/order.helper';
import useAlterRedirect from '../hooks/useAlterRedirect';
import { MenuType } from '../types/menuSelector.types';

import MenuSelector from './MenuSelector';
import ProductDetails from './ProductDetails';
import ProductsList from './ProductsList/ProductsList';

import DeprecatedModulePage from '@/components/templates/DeprecatedModulePage';

interface IOrderProps extends RouteComponentProps<never> {
  children: React.ReactNode;
}

const Order = ({ children }: IOrderProps) => {
  useAlterRedirect();

  if (children) {
    return <>{children}</>;
  }

  return (
    <div>
      <Switch>
        <Route
          exact
          path={pagePaths.Module}
          render={() => <MenuSelector facilityMenuPath={getFacilityMenuPath} />}
        />
        <Route
          exact
          path={pagePaths.MenuModule}
          render={() => (
            <MenuSelector facilityMenuPath={getFacilityMenuPath} menuType={MenuType.NonOrderable} />
          )}
        />

        <Route
          path={pagePaths.MenuRedirection}
          children={<MenuSelector facilityMenuPath={getFacilityMenuPath} />}
        />
        <Route
          path={pagePaths.ViewableMenuRedirection}
          children={
            <MenuSelector facilityMenuPath={getFacilityMenuPath} menuType={MenuType.NonOrderable} />
          }
        />
        <Route path={pagePaths.ProductsList} children={<ProductsList />} />
        <Route path={pagePaths.MenuProductsList} children={<ProductsList />} />
        <Route path={pagePaths.ProductDetails} children={<ProductDetails />} />
        <Route path={pagePaths.MenuProductDetails} children={<ProductDetails />} />
        <Route exact path={pagePaths.OrderHistory} children={<DeprecatedModulePage />} />
      </Switch>
    </div>
  );
};

export default Order;
