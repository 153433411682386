const labels = {
  All: {
    Region: 'Регион или страна',
    'Ref: Geography': 'Пожалуйста, выберите свою страну или регион',
    'Ref: Country': 'Страна',
    'Ref: Country tooltip': 'Место соответствует стране сайта.',
    'Ref: Please select your country': 'Пожалуйста, выберите вашу страну',
    'First name': 'Имя',
    'Last name': 'Фамилия',
    'Ref: Full name': 'ФИО',
    'Mobile number': 'Номер мобильного',
    'Mobile phone': 'Мобильный телефон',
    Email: 'Адрес электронной почты',
    Password: 'Пароль',
    logout: 'Выйти',
    'Not a new user? Log in': 'Не новый пользователь? Авторизоваться',
    'do not register, login': 'Не новый пользователь? Авторизоваться',
    'Ref: I agree': 'Я согласен',
    Register: 'регистр',
    'Ref: My language': 'Мой язык',
    'Ref: Kiosk Fail Title': 'Уп! Что-то пошло не так!',
    'Ref: Kiosk Fail Description':
      'К сожалению, ваш заказ не был успешным. Пожалуйста, попробуйте еще раз или попросите сотрудника помочь вам.',
    'Ref: Kiosk Fail Back': 'Вернуться на предыдущую страницу',
    'Ref: Kiosk Fail Action': 'Очистите сеанс и заказать снова',
    Validate: 'Проверять',
    'Ref: error, incorrect credentials': 'Эти полномочия не совпадают в выбранной географии.',
    'Ref: Forgot password?': 'Забыли пароль?',
    'Ref: Hello': 'Привет {wave_emoji}, пожалуйста, войдите, чтобы продолжить',
    'Ref: Form title': 'Пожалуйста, зарегистрируйтесь, чтобы продолжить',
    'Already have an account? Login': 'Уже есть учетная запись? Авторизоваться',
  },
  AccountPage: {
    'Ref: My Information': 'Моя информация',
    'Ref: Settings': 'Настройки',
    'Ref: Email': 'Эл. адрес',
    'Ref: Current site': 'Текущий сайт',
    'Ref: Choose site': 'Выберите сайт',
    'Ref: Personal interests': 'Личный интерес',
    'Ref: Food preferences': 'Пищевые предпочтения',
    'Ref: Password': 'Пароль',
    'Ref: Page title': 'Счет',
    'Ref: Language': 'Язык',
    'Ref: Communication preferences': 'Предпочтения связи',
    'Ref: Terms & conditions': 'Условия и условия',
    'Ref: Privacy policy': 'Политика конфиденциальности',
    'Ref: Delete my account': 'Удалите мой аккаунт',
    'Ref: Confirm deletion': 'Вы уверены, что хотите удалить свою учетную запись?',
    'Ref: Request deletion': 'Вы уверены, что хотите запросить удаление вашей учетной записи?',
    'Ref: Deletion description':
      'Ваш запрос будет обрабатываться в соответствии с вашим местным регулированием.',
    Placeholder: 'Напишите "Удалить"',
    'Ref: Delete description': 'Ваши данные будут удалены. Это действие необратимо.',
    delete: 'Удалить',
    'Ref: Terms of sale': 'Условия продажи',
    'Ref: Imprint': 'Отпечаток',
    'Ref: Cookie Settings': 'Настройки cookie',
    'Ref: Support': 'Поддерживать',
    'Ref: Register or login': 'Зарегистрировать или входить в систему',
    'Ref: Filtering Preferences': 'Фильтрация предпочтений',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Вы обязательно удалите свою учетную запись?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Напишите "{word}" в поле ввода и подтвердите внизу ниже. Это действие необратимо.',
    'Ref: Write "{word}"': 'Написать "{word}"',
    'Ref: Delete my account': 'Удалите мой аккаунт',
    'Ref: Words are not matching': 'Слова не соответствуют',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Выбрал свое место для начала',
    'Ref: Choose location':
      'Найдите быстро место, активируя локализацию, сканируя QR -код или поиск по имени',
    'Ref: next step': 'Пойдем',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Подписаться',
    'Ref: identify your location': 'Пожалуйста, определите ваше местоположение.',
    'Ref: option 1 - scan QR': 'Вариант 1: Сканировать указанный код qr регистрации',
    'Ref: option 2 - enter code': 'Вариант 2: вручную введите свой код местоположения',
    'Ref: button to open qr code scanner modal': 'У меня есть QR: давайте сканировать это',
    'Ref: button to open site code input modal': 'Выберите сайт с кодом',
    'Ref: getting help with site identifier - support desk':
      'Если вам не ясно, как продолжить, пожалуйста, свяжитесь с вашим поддержкой.',
    'Ref: getting help with site identifier - support form':
      'Вы также можете отправить нам заметку.',
    'Location code': 'Код местоположения',
    'Ref: location code field placeholder': 'Ваш код местоположения здесь',
    'Ref: Scanner header': 'Сканирование QR-кода',
    'Ref: site code input header': 'Добавить сайт с кодом',
    'Ref: not valid code': 'Этот код не действителен в выбранной географии',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Регистрация',
    'Ref: collected info': 'Резюме',
    'Ref: additional info': 'Ваша информация',
    'Confirm password': '*Подтвердите Пароль',
    company: 'Кто ваш работодатель?',
    location: 'Расположение',
    username: 'имя пользователя',
    register: 'Подписаться',
    'Ref: password strength':
      'долго Ваш пароль должен быть не менее 8 символов, содержать по крайней мере один номер, один верхний регистр, один нижний регистр и один специальный символ.',
    'passwords do not match': 'Пароли не соответствуют.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Предпочтения связи',
    'Ref: Things happen while you are away': 'Вещи случаются, пока тебя нет',
    'Ref: Would you like to receive update about offers and promotions?':
      'Хотели бы вы получать обновления о предложениях и рекламных акциях?',
    'Ref: Would you like to hear about content we think you may like?':
      'Хотели бы вы услышать о контенте, который, как мы думаем, вам понравится?',
    Yes: 'Да',
    No: 'Нет',
    apply: 'подать заявление',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Детали учетной записи' },
  DeletionFailure: {
    'Ref: Body':
      'Произошла ошибка, и ваш запрос на удаление учетной записи не была представлена. Если эта ошибка настойчиво, пожалуйста, свяжитесь с вашей помощью Справочная служба.',
    'Go to account': 'Перейти к учетной записи',
  },
  GenericFailurePage: {
    'Ref: Title': 'Что-то пошло не так',
    'Ref: Body':
      'Извините, что-то пошло не так. Если этот вопрос сохраняется, пожалуйста, свяжитесь с вашей поддержкой на месте.',
  },
  HomePage: {
    notifications: 'уверенность',
    basket: 'корзины',
    'Ref: Tabtitle': 'Главная',
    'contact us': 'Связаться с нами',
    'Ref: You are logged in as a guest user':
      'Вы вошли в систему в качестве гостевого пользователя',
    'Ref: Good morning': 'Доброе утро',
    'Ref: Good afternoon': 'Добрый день',
    'Ref: Good evening before midnight': 'Добрый вечер',
    'Ref: Good evening after midnight': 'Добрый вечер',
    'Ref: Search': 'Поиск',
    'Ref: products button aria': 'Найдено {results_form} в продуктах',
    'Ref: menus button aria': 'Нашел {results_form} в меню',
    'Ref: facilities button aria': 'Найдено {results_form} в условиях',
    'Ref: result': 'результат',
    'Ref: results': 'Результаты',
    'Ref: Menus': 'Меню',
    'Ref: No results title': 'Уп!',
    'Ref: No results body': 'Нет результатов, соответствующих вашему поиску',
    'Ref: Products': 'Продукты',
    'Ref: Facilities': 'Удобства',
    'Ref: See more results': 'Смотрите больше результатов поиска',
    'Ref: Accessibility instructions':
      'Чтобы начать использовать поиск, вы должны нажать на него или нажать Enter, когда он сосредоточился.',
    'Ref: See more': 'Узнать больше',
    'Ref: singular form of products': 'продукт',
    'Ref: singular form of menus': 'меню',
    'Ref: singular form of facilities': 'средство',
    'Ref: Search results for': 'Результаты поиска для',
  },
  LanguagePage: {
    apply: 'подать заявление',
    'My language': 'Мой язык',
    'Select in which language you would like to read our app':
      'Выберите, на каком языке вы хотели бы прочитать наше приложение',
    'Ref: Page title': 'язык',
  },
  LegalDocPage: {
    Acknowledge: 'Сознавать',
    'Ref: Legal changed notification title': '«{legal_doc_type}» были изменены',
    'Ref: Legal changed notification content':
      'Пожалуйста, просмотрите их, прежде чем продолжить использование приложения.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Правила и условия',
    'Read privacy policy': 'Прочитайте политику конфиденциальности',
    'Ref: Acknowledge legal docs': 'Я принимаю эти условия.',
    'Ref: Read and understood': 'Я читал и понял ',
    'Ref: No legal docs available - notice title': 'Вскоре',
    'Ref: No legal docs available - notice body':
      'Извините, кажется, что это приложение в настоящее время не доступно в вашей географии.',
    'Ref: The privacy Policy': 'Политика конфиденциальности',
    'Ref: Legal changed notification title': '«{legal_doc_type}» были изменены',
    'Ref: Legal changed notification content':
      'Пожалуйста, просмотрите их, прежде чем продолжить использование приложения.',
  },
  LoginPage: {
    'Ref: Page title': 'Добро пожаловать в {app name} ',
    'Ref: Page subtitle': 'Войдите, чтобы продолжить',
    'Ref: Email field placeholder': 'Ваш адрес электронной почты',
    'Ref: Email field note': 'Мы никогда не будем делиться своим письмом с кем угодно.',
    'Ref: Password field title': 'Ваш пароль',
    register: 'Подписаться',
    'I forgot my password.': 'Забыл пароль?',
    'I have trouble logging in': 'Имея регистрацию неисправностей в?',
    'start registration': 'Начать оформление',
    'Ref: Register': 'Регистрация ',
    'Ref: Login': 'Авторизоваться',
    'Ref: Connection issue?': 'Проблема с сетевым подключением?',
    'Ref: Connection issue message':
      'Если у вас есть какие -либо проблемы для регистрации или входа в систему, пожалуйста, свяжитесь с обычным персоналом на месте или с помощью Справочная служба.',
  },
  WelcomePage: {
    'Ref: Welcome to': 'Привет и добро пожаловать в {site_name}',
    'Ref: Begin': 'Начинать',
  },
  NotificationsList: {
    'Ref: No notifications': 'У вас нет уведомлений на данный момент',
    'Ref: This week': 'На этой неделе',
    'Ref: Earlier': 'Ранее',
    'Ref: Page title': 'Уверенность',
    'Ref: See more': 'Узнать больше',
    'Ref: Mark all read': 'Отметить все как прочитанные',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Смотрите все уведомления' },
  ServicesMenuPage: { 'Ref: Page title': 'Услуги', navigate: 'навигация' },
  DeletionSuccess: {
    'Ref: Title': 'Удаление вашей учетной записи было запрошено',
    'Ref: Content':
      'Мы справимся с этим в ближайшее время. Вы можете потерять свой доступ в любое время.',
    'Log out': 'Выйти',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Мы вернемся к вам',
    'Ref: Body': 'Мы сожалеем, что вы сталкиваетесь с проблемами. Мы свяжемся с вами очень скоро.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Имея регистрацию неисправностей в?',
    'Ref: Email field placeholder': 'Ваша электронная почта',
    'Ref: Mobile phone field placeholder': 'Номер мобильного',
    Company: 'Компания',
    'Ref: Company field placeholder': 'Название компании',
    'Ref: Company field note':
      'Если вы являетесь консультантом, это компания, для которой вы в настоящее время работаете, а не ваш работодатель.',
    Location: 'Город или местоположение',
    'Ref: Location field placeholder': 'Расположение вашего рабочего места',
    'Ref: Location field note':
      'Если вне обитаемой области, не стесняйтесь использовать имя, с помощью которого область обычно называется.',
    Comment: 'Комментарий',
    'Ref: Comment field placeholder': 'Что пошло не так?',
    'Ref: Who is your employer?': 'Кто ваш работодатель?',
    'Ref: What village are you staying at?': 'В какой деревне ты остановился?',
    'Ref: Comment field note': 'Любая информация, которая может помочь нам помочь вам!',
    'Ref: Feedback Content - geography': 'География',
    'Ref: Feedback Content - fullName': 'Имя',
    'Ref: Feedback Content - email': 'Эл. почта',
    'Ref: Feedback Content - phone': 'Телефон',
    'Ref: Feedback Content - company': 'Компания',
    'Ref: Feedback Content - city': 'Название города / местоположения',
    'Ref: Feedback Content - whatVillage': 'Деревня',
    'Ref: Feedback Content - employer': 'Работодатель',
    'Ref: Feedback Content - comment': 'Что пошло не так',
  },
  AccountStartPage: {
    'Ref: Header': 'Выбрал свое место для начала',
    'Ref: Body': 'Найдите быстро место, активируя локализацию, сканируя QR -код или поиск по имени',
    'Ref: LetsGo button': 'Пойдем',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Регистрация' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Выберите сайт с сканером QR',
    'Ref: Page title': 'Выберите свое местоположение',
    'Ref: Select site with code': 'Выберите сайт с кодом',
    'Ref: Enter site code': 'Введите код сайта',
    'Ref: Scanner error header': 'Сайт не найден',
    'Ref: Not found': 'Этот код не действителен в выбранной географии',
    'Ref: Generic error': 'Что-то пошло не так',
    'All other sites': 'Все остальные сайты',
    'Sites in your country': 'Сайты в вашей стране',
    'Ref: Unauthorized error': 'Несанкционированный запрос из -за уровня ограничения сайта',
    'Ref: Forbidden':
      'Извините, у вас нет правильных разрешений для доступа к этому сайту. Чтобы организовать доступ, пожалуйста, свяжитесь с сервисным центром Pilbara по телефону 1800 992 777',
    "Ref: We didn't find the site.": 'Мы не нашли сайт.',
    'Ref: We recommend some solutions:': 'Мы рекомендуем некоторые решения:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Попробуйте добавить свой сайт с помощью QR -сканера или с помощью кнопок ниже',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Войдите и войдите в систему, выбрав правильную страну',
    'Ref: The currently selected country is:': 'В настоящее время выбранная страна:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Регистрация',
    'Confirm password': 'Подтвердить Пароль',
    'Email is not valid': 'Email не является допустимым',
    'passwords do not match': 'Пароли не соответствуют.',
    'Ref: Password strength':
      'Ваш пароль должен содержать не менее 8 символов, содержать хотя бы одно число, один верхний регистр, один нижний регистр и один специальный символ.',
    'Sign up': 'Подписаться',
    'Continue as guest': 'Продолжать в качестве гостя',
    'Already have an account? Login': 'Уже есть учетная запись? Авторизоваться',
    'Ref: Registration success title': 'Спасибо за регистрацию',
    'Ref: Registration success content':
      'Ваша учетная запись будет создана в ближайшее время. Вы получите электронное письмо, подтверждающее создание.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Пожалуйста, войдите/зарегистрируйтесь',
    'Ref: Password strength':
      'долго Ваш пароль должен быть не менее 8 символов, содержать по крайней мере один номер, один верхний регистр, один нижний регистр и один специальный символ.',
    Stamps: 'Штампы',
    'Order history': 'История заказов',
    'to earn points': 'Чтобы заработать очки',
    'to see order history': 'Чтобы увидеть историю заказа',
    'to place an order': 'установить порядок',
    'Sign up': 'Подписаться',
    Login: 'Авторизоваться',
    'Email is not valid': 'Email не является допустимым',
    'passwords do not match': 'Пароли не соответствуют.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Благодарю вас',
    'Ref: Body':
      'Ты почти сделал! Вскоре вы получите проверку по электронной почте по указанному адресу. Откройте это письмо и нажмите на ссылку, чтобы активировать свою учетную запись.',
    'Ref: Enter': 'Продолжать',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Произошла ошибка',
    'Ref: Generic Error Body':
      'Пожалуйста, попробуйте еще раз позже или свяжитесь с вашим Справочная служба.',
    'Ref: Missing IndexDb support':
      'Извините, вы не можете получить доступ к приложению в режиме Incognito или без поддержки IndexDB. Пожалуйста, повторно повторите с другим браузером.',
    'Ref: Resource not found': 'Контент, к которому вы пытаетесь получить доступ, не существует.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Контент, к которому вы пытаетесь получить доступ, принадлежит частному сайту.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Контент, к которому вы пытаетесь получить доступ, не является авторизованным для вашей учетной записи.',
    'Ref: TabTitle': 'Ошибка',
    'Ref: Site link invalid':
      'Эта ссылка недействительна, выберите другой сайт или свяжитесь с помощью Справочная служба, если проблема сохраняется.',
    'Ref: Access Denied': 'Доступ запрещен. Недостаточно прав пользователя.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Уп! {robot_emoji}',
    'Ref: Error title': 'Что-то пошло не так!',
    'Ref: Error body':
      'Попробуйте обновить эту страницу или связаться с Справочная служба, если проблема сохраняется.',
    'Ref: Error ID:': 'Идентификатор ошибки: {errorId}',
    'Ref: Back to home': 'Вернуться домой',
  },
  Child: {
    'Ref: Route Not Found':
      'Сервис, который вы ищете, недоступен на этом сайте. Пожалуйста, попробуйте еще раз позже или свяжитесь с вашим Справочная служба.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Поздравляю, ваш новый пароль был успешно сохранен, теперь вы можете получить доступ ко всем нашими услугам из своей учетной записи.',
    Welcome: 'Добро пожаловать',
    'Ref: Welcome message':
      'Теперь вы успешно зарегистрировались в новом приложении Bite и можете продолжать открывать и пользоваться всеми нашими услугами.',
  },
  PasswordChange: {
    'Change Password': 'Изменить пароль',
    Welcome: 'Добро пожаловать',
    'Ref: Password message': 'Ваш пароль должен быть изменен, потому что он недостаточно безопасен',
  },
  AccountPasswordChange: {
    'Change your password here': 'Измените свой пароль здесь',
    'Your old password': 'Ваш старый пароль',
    'Type in your new password': 'Введите свой новый пароль',
    'Re-type in your new password': 'Повторно в вашем новом пароле',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'Ваш пароль был изменен' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Произошла ошибка, поэтому ваше изменение не было сохранено. Пожалуйста, попробуйте еще раз.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Сброс пароля',
    'Ref: Provide email':
      'Пожалуйста, предоставьте адрес электронной почты, используемый при регистрации на {app_name}. Вам будет отправлено электронное письмо, чтобы сбросить пароль.',
    'Ref: Something happened': 'Что-то случилось. Пожалуйста, попробуйте позже.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Мы отправили новое письмо. Пожалуйста, подождите 30 секунд, чтобы снова приехать.',
    'Go to login page': 'Перейти на страницу входа в систему',
    'Resend email': 'Отправить электронное письмо',
    'Ref: Email sent title': 'Вам отправлено электронное письмо с сбросом пароля',
    'Ref: TabTitle': 'Сброс пароля',
    'Ref: Email sent msg':
      'Ссылка сброса была отправлена ​​на ваш адрес {email_address}. Пожалуйста, подождите 30 секунд, прежде чем попытаться приехать.',
  },
  ResetPassword: { 'Enter your new password': 'Введите свой новый пароль' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Ваш пароль был успешно изменен',
    'Go to login page': 'Перейти на страницу входа в систему',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Сброс пароля',
    'Ref: Error': 'Произошла ошибка, попробуйте еще раз',
    'Reset your password': 'Сбросить свой пароль',
    'Go to login page': 'Перейти на страницу входа в систему',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'политика конфиденциальности',
    'Ref: Privacy Policy Body':
      'Доступ к этой платформе, Sodexo SA и локальная организация Sodexo обрабатывают ваши личные данные, чтобы хотя бы управлять вашей учетной записью, предоставить услуги и предложения, доступные через платформу. Если вам нужна дополнительная информация о обработке ваших личных данных, которые будут к ним доступны, и о том, как использовать свои права на ваши личные данные, обратитесь к политике конфиденциальности ниже и доступны в любое время в вашей учетной записи.',
    'Ref: Continue': 'Продолжать',
    'Ref: Read Privacy Policy': 'Прочитайте Политику конфиденциальности',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Иди в магазин' },
  SharePage: { 'Ref: Select a site': 'Выберите сайт' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Чтобы просмотреть политику конфиденциальности, выберите свою страну.',
  },
};
export default labels;
