import { Capacitor } from '@capacitor/core';
import { useEffect, useRef } from 'react';

const createMetaTag = (platform: string, nativeViewportScale: number) => {
  document.querySelector("meta[name='viewport']")?.remove();
  const metaTag = document.createElement('meta');
  metaTag.name = 'viewport';

  if (platform === 'web') {
    metaTag.content =
      'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.0';
  } else {
    metaTag.content = `viewport-fit=cover, width=device-width, initial-scale=${nativeViewportScale}, minimum-scale=${nativeViewportScale}, maximum-scale=${nativeViewportScale}`;
  }

  document.getElementsByTagName('head')[0].appendChild(metaTag);
};

const useMetaViewport = () => {
  const currentScaleRef = useRef(1);

  useEffect(() => {
    const platform = Capacitor.getPlatform();
    createMetaTag(platform, currentScaleRef.current);
  }, []);
};

export default useMetaViewport;
