const labels = {
  All: {
    dailyMenu: 'Daily Menu',
    anytime: 'Anytime',
    breakfast: 'Breakfast',
    morningBreak: 'morning break',
    lunch: 'Lunch',
    afternoonBreak: 'afternoon break',
    dinner: 'Dinner',
    supper: 'Supper',
    nightMeal: 'Night meal',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menus',
    'Ref: View details': 'View details',
    'Ref: Facility details': 'Facility details',
  },
  OpeningHours: { everyday: 'everyday', 'open all day': 'open all day' },
  ProductsList: {
    'Ref: Page title': 'Menu',
    'Ref: Facility details': 'Facility details',
    'Ref: Search products list': 'Search items',
    'Ref: filter label: date': 'Date',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Category',
    'Ref: filter label: dishes': 'Dishes',
    'Ref: filter label: highlight': 'Highlight or hide filters with dishes',
    All: 'All',
  },
  ProductDetails: { 'Ref: Page title': 'Product Details' },
  Widget: {
    'Ref: Carousel title': 'Menus',
    'No info yet, come back later': 'No info yet, come back later 😉',
    'Ref: menus': 'Menus',
    'Ref: see all': 'See all',
  },
};
export default labels;
