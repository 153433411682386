import { IonHeader, IonToolbar } from '@ionic/react';
import classNames from 'classnames';
import { FC, useRef } from 'react';

import { DefaultComponentProps } from '../../../types';

import styles from './TitleBar.module.css';

const TitleBar: FC<DefaultComponentProps> = ({
  children,
  'data-testid': dataTestId = 'title-bar-toolbar',
}) => {
  const topBarRef = useRef();

  return (
    <IonHeader className={classNames('ion-no-border', styles.TitleBar)} ref={topBarRef.current}>
      <IonToolbar
        className={classNames('ion-justify-content-between', 'small', styles.TitleBar_Toolbar)}
        data-cy={dataTestId}
        data-testid={dataTestId}
      >
        {children}
      </IonToolbar>
    </IonHeader>
  );
};
TitleBar.displayName = 'TitleBar';

export default TitleBar;
