import { Switch, Route } from 'react-router-dom';

import FoodFacilitiesList from '../components/FoodFacilitiesList';
import ProductDetails from '../components/ProductDetails';
import ProductsList from '../components/ProductsList';
import { pagePaths } from '../config';

const Routes = () => {
  return (
    <Switch>
      <Route path={pagePaths.Module} exact children={<FoodFacilitiesList />} />
      <Route path={pagePaths.ProductsList} children={<ProductsList />} />
      <Route path={pagePaths.ProductDetails} children={<ProductDetails />} />
    </Switch>
  );
};

export default Routes;
