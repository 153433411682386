const labels = {
  ContentDetails: {
    'Ref: Page title': 'Sisältötiedot',
    'See the event': 'Katso tapahtuma',
    'Take the survey': 'Vastaa kyselyyn',
    Date: 'Päivämäärä',
    Category: 'Kategoria',
    'Ref: Content site different title': 'Tämä sisältö ei kuulu nykyiseen sivustoosi',
    'Ref: Content site different description':
      'Katsot sisältöä, joka ei kuulu nykyiseen sivustoosi. Et voi nähdä sitä sisällön luettelossa.',
  },
  ContentList: {
    'Ref: Page title': 'Uutisvirta',
    'featured (as in featured content)': 'Esillä',
    'Ref: category - all': 'Kaikki',
    'Ref: category - promotional': 'Mainos-',
    'Ref: category - featured': 'Esitelty',
    'Ref: category - other': 'Muut',
  },
  Widget: {
    'Ref: Carousel title': 'Uutiset ja artikkelit',
    'Ref: Carousel title promo': 'Tärkeimmät uutiset',
    'No info yet, come back later': 'Ei vielä tietoja, tule takaisin myöhemmin 😉',
    'Ref: Content': 'Sisältö',
    'Ref: Widget Title': 'Uutisvirta',
    'Ref: MyVillage Carousel title promo': 'Ylennykset',
  },
};
export default labels;
