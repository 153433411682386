const labels = {
  Success: {
    'Ref: Body':
      'Bedankt voor het invullen van deze enquête, uw tijd is waardevol, omdat deze enquêtes helpen om onze diensten te verbeteren.',
    'Ref: Tabtitle': 'Enquête voltooid',
    'Take another survey': 'Neem nog een enquête',
    'Go to home': 'Terug naar de Startpagina',
  },
  Survey: {
    'Ref: Page title': 'Details van de enquête',
    'Please answer all mandatory questions as identify by an asterisk':
      'Beantwoord alle verplichte vragen aangeduid door een asterisk: *',
    'Ref: Take another survey': 'Neem nog een enquête',
    'Ref: Go to Home': 'Terug naar de Startpagina',
  },
  SurveysList: {
    'Ref: Page title': 'Enquête',
    'Ref: Available until': 'Beschikbaar tot',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'U bekijkt momenteel enquêtes voor de site {site name}.',
  },
};
export default labels;
