import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { logout } from '../../actions';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

function LogoutButton() {
  const { label } = useCoreTranslation(__filename);

  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Button
      data-cy="button-logout"
      onClick={() => {
        dispatch(logout());
        history.push('/');
      }}
    >
      {label('logout', { textTransform: 'capitalize' })}
    </Button>
  );
}

export default LogoutButton;
