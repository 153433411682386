import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import TimeTable from './TimeTable';

const Routes = () => {
  return (
    <Switch>
      <Route path={pagePaths.Module} exact children={<TimeTable />} />
    </Switch>
  );
};

export default Routes;
