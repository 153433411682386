const labels = {
  All: {
    'status: all': 'Hepsi',
    'status: open': 'Aç',
    'status: pending': 'Bekliyor',
    'status: closed': 'Kapatıldı',
    Location: 'Lokasyon',
    Subject: 'Konu',
    Description: 'Ayrıntılı talebiniz',
    'Ref: description field label': 'Açıklama',
    'Ref: Title': 'Başlık',
    phone_number: 'Telefon numarası',
    request_category: 'İstek kategorisi',
    Attachments: 'Ekler',
    'request service': 'Servis talep edin',
    'New service request': 'Başka bir istek oluşturun',
    'Go to home': 'Eve git',
    'Back to this service request': 'Bu hizmet talebine geri dönün',
    'Back to service requests': 'Ana Sayfaya Git',
    Feedback: 'Geri bildirim',
    'Ref: Request category': 'Kategori',
    Cleaning: 'Temizlik',
    'Laundry services': 'Çamaşırhane hizmetleri',
    'Grounds maintenance': 'Zemin bakımı',
    'Soft services': 'Yumuşak hizmetler',
    'Hard services': 'Zor hizmetler',
    'Pest control services': 'Haşere kontrol hizmetleri',
    'Waste management': 'Atık yönetimi',
    'Asset Lifecycle Services': 'Varlık Yaşam Döngüsü Hizmetleri',
    'There are no updates for this request': 'Bu talep için herhangi bir güncelleme yok',
    'Transportation Services': 'Ulaşım Hizmeti',
    'Ref: you pronoun': '(sen)',
    'Ref: SR raised for': 'Bu talepten etkilenen kişi',
    'Ref: Select a location': 'Bir konum seçin',
    'Ref: Your request': 'Sizin isteğiniz',
    'Ref: Summary': 'Özet',
    'Ref: site': 'Site',
  },
  Comment: {
    'Ref: Page title': 'Bizi Güncelleyin',
    'Ref: please update us with details':
      'Bizimle paylaşmak istediğiniz başka bir şey var mı? Talebiniz hakkında ne kadar çok şey bilirsek size o kadar iyi destek olabiliriz.',
  },
  Confirm: {
    'Ref: Page title': 'Talebinizi onaylayın',
    'Ref: next': 'Gönder',
    summary: 'Özet',
    'Ref: Save as my preferred location': 'Tercih ettiğim konum olarak kaydedin',
  },
  Describe: {
    'Ref: Page title': 'Talebinizi açıklayın',
    'Ref: Required fields: header': 'Lütfen ek bilgi veriniz',
    'Ref: Required fields': 'Aşağıdaki bilgiler ayrıntılar alanı içinde sağlanmalıdır:',
    'Ref: Required field: title': 'Bir başlık gerekli.',
    'Ref: Required field: description': 'Bir açıklama gereklidir.',
    'Ref: Required field: phone': 'Bir telefon numarası gereklidir.',
    'Ref: Required field: category': 'Bir kategori gereklidir.',
    'Ref: Invalid field: category': 'Sağlanan kategori geçersizdir.',
    'Ref: Request template field label': 'Popüler talepler',
    'Ref: Request template field placeholder': 'Boş sayfa',
    your_request: 'Sizin isteğiniz',
    'Ref: Description message': 'Ne kadar çok bilgi verirseniz size o kadar iyi destek olabiliriz.',
    phone_number: 'Telefon numaranız (varsa lütfen profesyonel telefonunuzu kullanın)',
    'Ref: Affected person': 'Bu talepten etkilenen kişi',
    'Ref: No affected person results': 'İstenen kriterlere uyan kişi yok',
    'Ref: Notification title': 'Konumunuzu değiştirdiniz',
    'Ref: Notification info': 'Şu anda {site name} konumundasınız',
    'Ref: Add photos': 'Fotoğraf ekle',
    'Ref: Your location': 'Konumunuz',
  },
  Details: {
    'Ref: Page title': 'İstek Detayları',
    Details: 'Detaylar',
    Updates: 'Güncellemeler',
    'Update us': 'Bizi Güncelleyin',
    'Ref: timeline': 'Zaman Çizelgesi',
    'Ref: attachments': 'Ekler',
    'Ref: location': 'Konum',
    'Ref: createdOn': 'Üzerinde Oluşturuldu',
    'Ref: reference': 'Referans',
    'Feedback was submitted': 'Geri bildirim gönderildi',
    'Ref: sent': 'Gönderildi',
    'Ref: received': 'Alındı',
    'Ref: inProgress': 'Devam ediyor',
    'Ref: completed': 'Tamamlandı',
    'Ref: conversation': 'Konuşma',
    'Ref: Priority': 'Öncelik',
    'Ref: Target start date': 'Hedef başlangıç tarihi',
    'Ref: Target completion date': 'Hedef tamamlanma tarihi',
    'Ref: Priority fields placeholder message':
      'Yardım masası tarafından onaylandıktan sonra doldurulacaktır',
  },
  Failed: {
    'Ref: Body':
      'Bir hata oluştu ve hizmet talebiniz oluşturulamadı. Bu hata devam ederse, lütfen yardım masanızla iletişime geçin.',
    'Ref: Tabtitle': 'Hizmet talebi oluşturulamadı',
  },
  Home: { 'Ref: Page title': 'Talepler' },
  RequestFeedback: {
    'Ref: Page title': 'Geri bildirim',
    'Ref: Feedback headline': 'Lütfen görüşlerinizi paylaşın',
    'Ref: Feedback title': 'ST hakkında geri bildirim #{ref}',
    'Ref: Question: general sentiment':
      'Genel olarak, hizmet talebinizi ele alış şeklimizden ne kadar memnunsunuz?',
    'Ref: Question: answer quality': 'Aldığınız hizmetin kalitesini nasıl değerlendirirsiniz?',
    'Ref: Question: staff interaction':
      'Personelimizle olan etkileşiminizi nasıl değerlendirirsiniz?',
    'Ref: Question: efficiency': 'Verimliliğimizi nasıl değerlendirirsiniz?',
    'Ref: Question: open': 'Yorum',
    'Ref: Open question placeholder': 'Neyi iyi yaptık? Nereleri geliştirebiliriz?',
    no_comment: 'Yorum yok',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Zaman ayırıp yorum bıraktığınız için teşekkür ederiz, geri bildiriminiz bizim için önemlidir ve deneyiminizi geliştirmemize yardımcı olur.',
  },
  Locate: {
    'Ref: Page title': 'Bir konum seçin',
    'Ref: Top bar title': 'Talebinizi bulun',
    'Ref: Select this location': 'Bu konumu seçin',
    drill: 'göz atın',
    'Ref: Scanner button': 'QR ile konum? Tarayın',
    'Ref: Scanner header': 'Lütfen konumunuzu QR ile tarayın',
    'Sorry, this location could not be found.': 'Üzgünüz, bu konum bulunamadı.',
    'Close scanner': 'Tarayıcıyı kapat',
    'Ref: Switch site component intro sentence': 'Bu sitede hizmet talep ediyorsunuz {site name}.',
    'Ref: search location by keywords': 'Bir konum arayın',
    'Ref: Enter Location name': 'Konum adını girin',
    'Ref: Enter manual location': 'Konumu manuel olarak girin',
    'Ref: Location name': 'Konum adı',
    'Ref: No results': 'Konumunuzu bulamadık. Manuel olarak girmek isteyebilirsiniz.',
    'Ref: Up one level': 'Bir seviye yukarı',
    'Ref: Navigate': 'Gezinme',
    'Ref: Select': 'Seçiniz',
    'Ref: Selected Location': 'Seçilen Konum',
    'Ref: Preferred Location': 'Tercih Edilen Konum',
    'Ref: Building': 'Bina',
    'Ref: Floor': 'Zemin',
    'Ref: Aisle': 'Koridor',
    'Ref: Room': 'Oda',
    'Ref: Notification title': 'Konumunuzu değiştirdiniz',
    'Ref: Notification info': 'Şu anda {site name} konumundasınız',
    'Ref: Scanner error header': 'Tarama hatası',
    'Ref: Scan QR Code': 'QR Kodunu Tarayın',
    'Ref: Error body': 'Bu QR kodu geçerli bir kod değil',
    'Ref: Loading Locations Warning':
      'Bu sitedeki ilk talebiniz için deneyiminizi hazırlarken lütfen bekleyin.',
    'Ref: Search Result is limited to:':
      'Arama Sonucu {limit} ile sınırlıdır. Lütfen arama kriterlerinizi daraltın.',
    'Ref: Open the location search field': 'Konum arama alanını açın',
  },
  Success: {
    'Ref: Body':
      'Talebinizi aldık ve şu anda inceliyoruz. Yakında size geri döneceğiz. Hizmet talebinizi uygulamada görmek 20 dakika kadar sürebilir.',
    'Ref: Tabtitle': 'Hizmet talebi oluşturuldu',
    'Ref: Download app title': 'Hizmet taleplerinizi basitleştirin',
    'Ref: Download app subtitle': 'Uygulamamızı indirin ve deneyiminizi geliştirin',
    'Ref: Download app button': 'Uygulamayı indirin',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Burası sizin işyeri destek merkeziniz',
    'Ref: Tutorial: tabname': 'Talepler',
    'Ref: Tutorial: body':
      'Size yardımcı olmak için buradayız. Lütfen her giriş için bir talep kaydedin.',
    "What's new: title": 'Konumunuzu kolayca bulun',
    "What's new: body":
      'Tam konumunuzu bulmak için bir QR kodunu tarayın veya önerilerimizden birini seçin.',
  },
  Widget: {
    'Ref: Service request': 'Hizmet talebi',
    'Ref: Placeholder body': 'Şu an için hizmet talebi yok',
    'Ref: New request button': 'Yeni istek',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Konumunuzu değiştirdiniz',
    'Ref: Notification info': 'Şu anda {site name} konumundasınız',
    'Ref: Welcome': 'Hoş geldin! {wave_emoji}',
    'Ref: Create service request text': 'Bildirmek istediğiniz bir şey mi fark ettiniz?',
    'Create a service request': 'Hizmet talebi oluşturma',
    'Ref: Share feedback': 'Geri bildirim paylaşın',
    'Ref: Share text': 'Bizimle bir şeyler paylaşmak ister misiniz?',
    'Go to home': 'Eve git',
    'Ref: Error body':
      'Taradığınız QR kodu artık çalışmıyor. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Bir hata oluştu',
    'Ref: Error body':
      'Taradığınız QR kodu artık çalışmıyor. Lütfen daha sonra tekrar deneyin veya destek ekibiyle iletişime geçin.',
    'Go to home': 'Eve git',
  },
  ManualDescribe: {
    'Ref: Page title': 'Talebinizi açıklayın',
    'Ref: Required fields: header': 'Lütfen ek bilgi veriniz',
    'Ref: Required fields': 'Ayrıntılar alanı içinde aşağıdaki bilgiler sağlanmalıdır:',
    'Ref: Required field: title': 'Bir özet gereklidir.',
    'Ref: Required field: description': 'Bir açıklama gereklidir.',
    'Ref: Required field: category': 'Bir kategori gereklidir.',
    'Ref: Required field: buildingText': 'Bir bina gereklidir.',
    'Ref: Invalid field: category': 'Sağlanan kategori geçersizdir.',
    'Ref: Your request': 'Sizin isteğiniz',
    'Ref: Description message':
      'Ne kadar çok ayrıntı verirseniz size o kadar iyi destek olabiliriz.',
    'Ref: Do you have a location QR code': 'Bir konum QR kodunuz var mı?',
    'Ref: Scan QR Code': 'QR kodunu tarayın',
    'Ref: Please select category': 'Lütfen kategori seçiniz',
    'Ref: Please select site': 'Lütfen site seçiniz',
    'Ref: building': 'Bina',
    'Ref: room': 'Oda',
    'Ref: Scan error': 'Tarama hatası',
    'Ref: The QR code is not valid': 'QR kodu geçerli değil',
    'Ref: Add location with QR Scanner': 'QR Tarayıcı ile konum ekleyin',
    'Ref: Description': 'Açıklama',
    'Ref: Forbidden error': 'Üzgünüz, bu QR kodu kayıtlı konumunuzla eşleşmiyor.',
    'Ref: Invalid error': 'Üzgünüz, bu QR kodu artık geçerli değil.',
  },
};
export default labels;
