import { useEffect } from 'react';
import { useHistory } from 'react-router';

const useErrorRedirection = (errors: any) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (errors?.length && pathname !== '/error') {
        history.push('/error');
      }
    });

    return unlisten;
  }, [errors?.length, history]);
};

export default useErrorRedirection;
