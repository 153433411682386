const labels = {
  ContentDetails: {
    'Ref: Page title': 'Dettagli del contenuto',
    'See the event': "Vedi l'evento",
    'Take the survey': 'Partecipa al sondaggio',
    Date: 'Data',
    Category: 'Categoria',
    'Ref: Content site different title': 'Questo contenuto non appartiene al tuo sito attuale',
    'Ref: Content site different description':
      "Stai visualizzando un contenuto che non appartiene al tuo sito attuale. Non sarai in grado di vederlo nell'elenco dei contenuti.",
  },
  ContentList: {
    'Ref: Page title': 'Newsfeed',
    'featured (as in featured content)': 'In primo piano',
    'Ref: category - all': 'Tutto',
    'Ref: category - promotional': 'Promozionale',
    'Ref: category - featured': 'In primo piano',
    'Ref: category - other': 'Altro',
  },
  Widget: {
    'Ref: Carousel title': 'Novità e articoli',
    'Ref: Carousel title promo': 'Le notizie più importanti',
    'No info yet, come back later': 'Ancora nessuna informazione, torna più tardi 😉',
    'Ref: Content': 'Contenuto',
    'Ref: Widget Title': 'Newsfeed',
    'Ref: MyVillage Carousel title promo': 'Promozioni',
  },
};
export default labels;
