const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Запрос на обслуживание — Служба поддержки',
    'Ref: Forbidden heading': 'Запрещенный',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Вы администрируете запросы на обслуживание на сайте {siteName}.',
    'Ref: Dates': 'Даты',
    'Ref: SRL list refresh': 'Обновить список',
    'Ref: Export': 'экспорт',
    'Ref: Empty/not filled': 'Пустой / не заполненный',
    'Ref: Name': 'Имя',
    'Ref: Date': 'Дата',
    'Ref: Category': 'Категория',
    'Ref: User': 'Пользователь',
    'Ref: Location': 'Расположение',
  },
};
export default labels;
