const labels = {
  All: {
    Region: 'Bölge veya ülke',
    'Ref: Geography': 'Lütfen ülkenizi veya bölgenizi seçin',
    'Ref: Country': 'Ülke',
    'Ref: Country tooltip': 'Konum site ülkesine karşılık gelir.',
    'Ref: Please select your country': 'Lütfen ülkenizi seçiniz',
    'First name': 'İlk adı',
    'Last name': 'Soy isim',
    'Ref: Full name': 'Ad Soyad',
    'Mobile number': 'Cep numarası',
    'Mobile phone': 'Cep telefonu',
    Email: 'E -posta adresi',
    Password: 'Şifre',
    logout: 'Çıkış Yap',
    'Not a new user? Log in': 'Yeni bir kullanıcı değil mi? Giriş yapmak',
    'do not register, login': 'Yeni bir kullanıcı değil mi? Giriş yapmak',
    'Ref: I agree': 'Kabul ediyorum',
    Register: 'Kayıt ol',
    'Ref: My language': 'Benim dilim',
    'Ref: Kiosk Fail Title': 'Oops! Bir şeyler yanlış gitti!',
    'Ref: Kiosk Fail Description':
      'Ne yazık ki, siparişiniz başarılı değildi. Lütfen tekrar deneyin veya bir personelden size yardımcı olmasını isteyin.',
    'Ref: Kiosk Fail Back': 'Önceki sayfaya geri dön',
    'Ref: Kiosk Fail Action': 'Oturumu temizleyin ve tekrar sipariş edin',
    Validate: 'Doğrulamak',
    'Ref: error, incorrect credentials': 'Bu kimlik bilgileri, seçilen coğrafyada eşleşmiyor.',
    'Ref: Forgot password?': 'Parolanızı mı unuttunuz?',
    'Ref: Hello': 'Merhaba {wave_emoji}, devam etmek için lütfen giriş yapın',
    'Ref: Form title': 'Devam etmek için lütfen kaydolun',
    'Already have an account? Login': 'Zaten hesabınız var mı? Giriş yapmak',
  },
  AccountPage: {
    'Ref: My Information': 'Benim bilgim',
    'Ref: Settings': 'Ayarlar',
    'Ref: Email': 'E -posta',
    'Ref: Current site': 'Mevcut site',
    'Ref: Choose site': 'Bir Site Seçin',
    'Ref: Personal interests': 'Kişisel ilgi alanları',
    'Ref: Food preferences': 'Yemek tercihleri',
    'Ref: Password': 'Şifre',
    'Ref: Page title': 'Hesap',
    'Ref: Language': 'Dil',
    'Ref: Communication preferences': 'İletişim Tercihleri',
    'Ref: Terms & conditions': 'Şartlar ve koşullar',
    'Ref: Privacy policy': 'Gizlilik Politikası',
    'Ref: Delete my account': 'Hesabımı sil',
    'Ref: Confirm deletion': 'Hesabınızı silmek istediğinizden emin misiniz?',
    'Ref: Request deletion': 'Hesabınızın silinmesini talep etmek istediğinizden emin misiniz?',
    'Ref: Deletion description': 'İsteğiniz yerel düzenlemenize göre ele alınacaktır.',
    Placeholder: '"Sil" yaz',
    'Ref: Delete description': 'Verileriniz silinecektir. Bu eylem geri döndürülemez.',
    delete: 'silmek',
    'Ref: Terms of sale': 'Satış şartları',
    'Ref: Imprint': 'Baskı',
    'Ref: Cookie Settings': 'Çerez ayarları',
    'Ref: Support': 'Destek',
    'Ref: Register or login': 'Kayıt veya Giriş',
    'Ref: Filtering Preferences': 'Filtreleme Tercihleri',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Hesabınızı sileceğinizden emin misiniz?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Giriş alanına "{word}" yazın ve aşağıdaki alt ile onaylayın. Bu eylem geri döndürülemez.',
    'Ref: Write "{word}"': '"{word}" yaz',
    'Ref: Delete my account': 'Hesabımı sil',
    'Ref: Words are not matching': 'Kelimeler eşleşmiyor',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Başlangıç ​​için konumunuzu seçin',
    'Ref: Choose location':
      'Yerelleştirmeyi etkinleştirerek, bir QR kodunu tarayarak veya isme göre arama yaparak hızlı bir şekilde bir yer bulun',
    'Ref: next step': 'Hadi gidelim',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Kaydol',
    'Ref: identify your location': 'Lütfen konumunuzu tanımlayın.',
    'Ref: option 1 - scan QR': 'Seçenek 1: Verilen Kayıt QR kodunu tarayın',
    'Ref: option 2 - enter code': 'Seçenek 2: Konum kodunuzu manuel olarak girin',
    'Ref: button to open qr code scanner modal': 'Bir QR var: Hadi taramalıyım',
    'Ref: button to open site code input modal': "Kodlu Site'yi seçin",
    'Ref: getting help with site identifier - support desk':
      'Nasıl devam edeceği konusunda net değilseniz, lütfen destek masanızla iletişime geçin.',
    'Ref: getting help with site identifier - support form':
      'Ayrıca bize bir not gönderebilirsiniz.',
    'Location code': 'Konum kodu',
    'Ref: location code field placeholder': 'Buradaki konum kodunuz',
    'Ref: Scanner header': 'QR Kod Tarama',
    'Ref: site code input header': 'Kodlu site ekleyin',
    'Ref: not valid code': 'Bu kod seçilen coğrafyada geçerli değil',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Kayıt',
    'Ref: collected info': 'özet',
    'Ref: additional info': 'Bilgin',
    'Confirm password': '*Şifreyi Onayla',
    company: 'İşverenin kim?',
    location: 'Konum',
    username: 'Kullanıcı adı',
    register: 'Kaydol',
    'Ref: password strength':
      'Şifreniz en az 8 karakter uzunluğunda olmalı, en az bir sayı, bir büyük harf, bir küçük harf ve bir özel karakter içermelidir.',
    'passwords do not match': 'Parolalar uyuşmuyor.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'İletişim Tercihleri',
    'Ref: Things happen while you are away': 'Siz uzaktayken bir şeyler olur',
    'Ref: Would you like to receive update about offers and promotions?':
      'Teklifler ve promosyonlar hakkında güncellemeler almak ister misiniz?',
    'Ref: Would you like to hear about content we think you may like?':
      'Sevebileceğinizi düşündüğümüz içeriği duymak ister misiniz?',
    Yes: 'Evet',
    No: 'Numara',
    apply: 'uygulamak',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Hesap detayları' },
  DeletionFailure: {
    'Ref: Body':
      'Bir hata oluştu ve hesap silme talebiniz gönderilmedi. Bu hata devam ederse, lütfen yardım masanızla iletişime geçin.',
    'Go to account': 'Hesaba katmak',
  },
  GenericFailurePage: {
    'Ref: Title': 'Bir şeyler yanlış gitti',
    'Ref: Body':
      'Üzgünüm, bir şeyler ters gitti. Bu sorun devam ederse, lütfen yerinde desteğinize başvurun.',
  },
  HomePage: {
    notifications: 'bildirimler',
    basket: 'Sepet',
    'Ref: Tabtitle': 'Ev',
    'contact us': 'Bize Ulaşın',
    'Ref: You are logged in as a guest user': 'Konuk kullanıcı olarak oturum açtınız',
    'Ref: Good morning': 'Günaydın',
    'Ref: Good afternoon': 'Tünaydın',
    'Ref: Good evening before midnight': 'İyi akşamlar',
    'Ref: Good evening after midnight': 'İyi akşamlar',
    'Ref: Search': 'Aramak',
    'Ref: products button aria': 'Ürünler içinde {results_form} bulundu',
    'Ref: menus button aria': 'Menüler içinde {results_form} bulundu',
    'Ref: facilities button aria': 'Tesislerde {results_form} bulundu',
    'Ref: result': 'sonuç',
    'Ref: results': 'sonuçlar',
    'Ref: Menus': 'Menüler',
    'Ref: No results title': 'Oops!',
    'Ref: No results body': 'Aramanızla eşleşen sonuç yok',
    'Ref: Products': 'Ürünler',
    'Ref: Facilities': 'Tesisler',
    'Ref: See more results': 'Daha fazla arama sonucuna bakın',
    'Ref: Accessibility instructions':
      'Aramayı kullanmaya başlamak için üzerine tıklamanız veya odaklandığı sırada Enter tuşuna basın.',
    'Ref: See more': 'Daha fazla gör',
    'Ref: singular form of products': 'ürün',
    'Ref: singular form of menus': 'Menü',
    'Ref: singular form of facilities': 'tesis',
    'Ref: Search results for': 'için arama sonuçları',
  },
  LanguagePage: {
    apply: 'uygulamak',
    'My language': 'Benim dilim',
    'Select in which language you would like to read our app':
      'Uygulamamızı hangi dilde okumak istediğinizi seçin',
    'Ref: Page title': 'Dil',
  },
  LegalDocPage: {
    Acknowledge: 'Kabullenmek',
    'Ref: Legal changed notification title': '"{legal_doc_type}" değiştirildi',
    'Ref: Legal changed notification content':
      'Lütfen uygulamayı kullanmaya devam etmeden önce bunları inceleyin.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'şartlar ve koşullar',
    'Read privacy policy': 'Gizlilik politikasını okuyun',
    'Ref: Acknowledge legal docs': 'Bu şartlar ve koşulları kabul ediyorum.',
    'Ref: Read and understood': 'Okudum ve anladım ',
    'Ref: No legal docs available - notice title': 'Yakında gelecek',
    'Ref: No legal docs available - notice body':
      'Üzgünüz, bu uygulama şu anda coğrafyada bulunmuyor.',
    'Ref: The privacy Policy': 'Gizlilik Politikası',
    'Ref: Legal changed notification title': '"{legal_doc_type}" değiştirildi',
    'Ref: Legal changed notification content':
      'Lütfen uygulamayı kullanmaya devam etmeden önce bunları inceleyin.',
  },
  LoginPage: {
    'Ref: Page title': '{app name}',
    'Ref: Page subtitle': 'Devam etmek için giriş yapın',
    'Ref: Email field placeholder': 'E-posta adresiniz',
    'Ref: Email field note': 'E-postanı asla kimseyle paylaşmayacağız.',
    'Ref: Password field title': 'Şifreniz',
    register: 'Kaydol',
    'I forgot my password.': 'Parolanızı mı unuttunuz?',
    'I have trouble logging in': 'Giriş bağlantısında sorun mu yaşıyorsunuz?',
    'start registration': 'Kayıt başlat',
    'Ref: Register': 'Kayıt ol',
    'Ref: Login': 'Giriş yapmak',
    'Ref: Connection issue?': 'Bağlantı sorunu?',
    'Ref: Connection issue message':
      'Kaydolmak veya giriş yapmak için herhangi bir sorununuz varsa, lütfen her zamanki yerinde personel veya yardım masası ile iletişime geçin.',
  },
  WelcomePage: {
    'Ref: Welcome to': "Merhaba ve {site_name} 'e hoş geldiniz",
    'Ref: Begin': 'Başlamak',
  },
  NotificationsList: {
    'Ref: No notifications': 'Şu an hiçbir bildiriminiz yok',
    'Ref: This week': 'Bu hafta',
    'Ref: Earlier': 'Daha erken',
    'Ref: Page title': 'Bildirimler',
    'Ref: See more': 'Daha fazla gör',
    'Ref: Mark all read': 'Mark Tümü Oku',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Tüm bildirimleri gör' },
  ServicesMenuPage: { 'Ref: Page title': 'Hizmetler', navigate: 'gezinmek' },
  DeletionSuccess: {
    'Ref: Title': 'Hesabınızın silinmesi talep edildi',
    'Ref: Content':
      'Kısa süre içinde halledeceğiz. Erişiminizi istediğiniz zaman kaybedebilirsiniz.',
    'Log out': 'Çıkış Yap',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Biz sana geri döneceğiz',
    'Ref: Body': 'Üzgünüz, sorun yaşıyorsunuz. Yakında sizinle iletişim kuracağız.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Giriş bağlantısında sorun mu yaşıyorsunuz?',
    'Ref: Email field placeholder': 'Eposta adresiniz',
    'Ref: Mobile phone field placeholder': 'Telefon numarası',
    Company: 'Şirket',
    'Ref: Company field placeholder': 'Şirket Adı',
    'Ref: Company field note':
      'Eğer bir danışmansanız, bu şu anda çalıştığınız şirket, işvereniniz değil.',
    Location: 'Şehir veya konum',
    'Ref: Location field placeholder': 'İşyerinizin yeri',
    'Ref: Location field note':
      'Eğer bir yaşın dışında ise, alanın yaygın olarak adlandırıldığı adını kullanmaktan çekinmeyin.',
    Comment: 'Açıklama ekle',
    'Ref: Comment field placeholder': 'Ne yanlış gitti?',
    'Ref: Who is your employer?': 'İşvereniniz kim?',
    'Ref: What village are you staying at?': 'Hangi köyde kalıyorsun?',
    'Ref: Comment field note':
      'Bize yardımcı olabilecek herhangi bir bilgi size yardımcı olabilecek herhangi bir bilgi!',
    'Ref: Feedback Content - geography': 'Coğrafya',
    'Ref: Feedback Content - fullName': 'İsim',
    'Ref: Feedback Content - email': 'E-posta',
    'Ref: Feedback Content - phone': 'Telefon',
    'Ref: Feedback Content - company': 'Şirket',
    'Ref: Feedback Content - city': 'Şehir / Konum Adı',
    'Ref: Feedback Content - whatVillage': 'Köy',
    'Ref: Feedback Content - employer': 'İş veren',
    'Ref: Feedback Content - comment': 'Ne yanlış gitti',
  },
  AccountStartPage: {
    'Ref: Header': 'Başlangıç ​​için konumunuzu seçin',
    'Ref: Body':
      'Yerelleştirmeyi etkinleştirerek, bir QR kodunu tarayarak veya isme göre arama yaparak hızlı bir şekilde bir yer bulun',
    'Ref: LetsGo button': 'Hadi gidelim',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Kayıt' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'QR tarayıcı ile site seçin',
    'Ref: Page title': 'konumunuzu seçin',
    'Ref: Select site with code': "Kodlu Site'yi seçin",
    'Ref: Enter site code': 'Site Kodunu Girin',
    'Ref: Scanner error header': 'Site bulunamadı',
    'Ref: Not found': 'Bu kod seçilen coğrafyada geçerli değil',
    'Ref: Generic error': 'Bir şeyler yanlış gitti',
    'All other sites': 'Diğer tüm tesisler',
    'Sites in your country': 'Ülkenizdeki siteler',
    'Ref: Unauthorized error': 'Site kısıtlama seviyesi nedeniyle yetkisiz istek',
    'Ref: Forbidden':
      'Üzgünüm, bu siteye erişmek için doğru izinleriniz yok. Erişim ayarlamak için lütfen 1800 992 777 numaralı telefondan Pilbara Servis Merkezi ile iletişime geçin.',
    "Ref: We didn't find the site.": 'Siteyi bulamadık.',
    'Ref: We recommend some solutions:': 'Bazı çözümler öneriyoruz:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Sitenizi QR tarayıcı ile veya aşağıdaki düğmeler yardımıyla eklemeye çalışın',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Doğru ülkeyi seçerek oturum açın ve tekrar giriş yapın',
    'Ref: The currently selected country is:': 'Şu anda seçilen ülke:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Kayıt',
    'Confirm password': 'Şifreyi Onayla',
    'Email is not valid': 'E-posta geçerli değil',
    'passwords do not match': 'Parolalar uyuşmuyor.',
    'Ref: Password strength':
      'Parolanız en az 8 karakter uzunluğunda olmalı, en az bir sayı, bir büyük harf, bir küçük harf ve bir özel karakter içermelidir.',
    'Sign up': 'Üye olmak',
    'Continue as guest': 'Misafir olarak devam et',
    'Already have an account? Login': 'Zaten hesabınız var mı? Giriş yapmak',
    'Ref: Registration success title': 'kayıt olduğunuz için teşekkürler',
    'Ref: Registration success content':
      'Hesabınız yakında oluşturulacak. Yaratılışı doğrulayan bir e -posta alacaksınız.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Lütfen giriş yapın/kaydolun',
    'Ref: Password strength':
      'Şifreniz en az 8 karakter uzunluğunda olmalı, en az bir sayı, bir büyük harf, bir küçük harf ve bir özel karakter içermelidir.',
    Stamps: 'Pullar',
    'Order history': 'Sipariş Geçmişi',
    'to earn points': 'puan kazanmak için',
    'to see order history': 'Sipariş geçmişini görmek için',
    'to place an order': 'Sipariş vermek için',
    'Sign up': 'Üye olmak',
    Login: 'Giriş yapmak',
    'Email is not valid': 'E-posta geçerli değil',
    'passwords do not match': 'Parolalar uyuşmuyor.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Teşekkürler',
    'Ref: Body':
      'Neredeyse bitti! Yakında sağlanan adreste bir doğrulama e -postası alacaksınız. Bu e -postayı açın ve hesabınızı etkinleştirmek için bağlantıyı tıklayın.',
    'Ref: Enter': 'Devam et',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Bir hata oluştu',
    'Ref: Generic Error Body':
      'Lütfen daha sonra tekrar deneyin veya yardım masanızla iletişime geçin.',
    'Ref: Missing IndexDb support':
      'Üzgünüm, uygulamaya gizli modda veya indexdb desteği olmadan erişemezsiniz. Lütfen başka bir tarayıcı ile yeniden deneyin.',
    'Ref: Resource not found': 'Erişmeye çalıştığınız içerik mevcut değildir.',
    'Ref: Permission denied - SITE_HIDDEN': 'Erişmeye çalıştığınız içerik özel bir siteye aittir.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Erişmeye çalıştığınız içerik hesabınız için yetkili değildir.',
    'Ref: TabTitle': 'Hata',
    'Ref: Site link invalid':
      'Bu bağlantı geçersiz, başka bir site seçin veya sorun devam ederse yardım masasına başvurun.',
    'Ref: Access Denied': 'Erişim engellendi. Yetersiz kullanıcı izinleri.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Oops! {robot_emoji}',
    'Ref: Error title': 'Bir şeyler yanlış gitti!',
    'Ref: Error body':
      'Sorun devam ederse bu sayfayı yenilemeye veya yardım masası ile iletişime geçmeye çalışın.',
    'Ref: Error ID:': 'Hata Kimliği: {errorId}',
    'Ref: Back to home': 'Eve geri dön',
  },
  Child: {
    'Ref: Route Not Found':
      'Aradığınız hizmet bu sitede mevcut değildir. Lütfen daha sonra tekrar deneyin veya yardım masanızla iletişime geçin.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Tebrikler, yeni şifreniz başarıyla kaydedildi, artık tüm hizmetlerimize hesabınızdan erişebilirsiniz.',
    Welcome: 'Hoş geldin',
    'Ref: Welcome message':
      'Şimdi yeni ısırık uygulamasına başarıyla kaydoldunuz ve tüm hizmetlerimizin tadını çıkarmaya ve tadını çıkarmaya devam edebilirsiniz.',
  },
  PasswordChange: {
    'Change Password': 'Şifre değiştir',
    Welcome: 'Hoş geldin',
    'Ref: Password message': 'Şifrenizin değiştirilmesi gerekiyor çünkü yeterince güvenli değil',
  },
  AccountPasswordChange: {
    'Change your password here': 'Şifrenizi buradan değiştirin',
    'Your old password': 'Eski şifreniz',
    'Type in your new password': 'Yeni şifrenizi yazın',
    'Re-type in your new password': 'Yeni şifrenizde yeniden tip',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'Şifreniz değiştirildi' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Bir hata oluştu, bu nedenle değişikliğiniz kaydedilmedi. Lütfen tekrar deneyin.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Şifreyi yenile',
    'Ref: Provide email':
      'Lütfen {app_name} adresine kaydolurken kullanılan e -posta adresini sağlayın. Parolanızı sıfırlamak için size bir e -posta gönderilecektir.',
    'Ref: Something happened': 'Bir şey oldu. Lütfen daha sonra tekrar deneyiniz.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Yeni bir e -posta gönderdik. Lütfen tekrar tekrar sunmak için 30 saniye bekleyin.',
    'Go to login page': 'Giriş sayfasına git',
    'Resend email': 'Emaili yeniden gönder',
    'Ref: Email sent title': 'Size bir şifre sıfırlama e -postası gönderildi',
    'Ref: TabTitle': 'Şifreyi yenile',
    'Ref: Email sent msg':
      'Sıfırlama bağlantısı adresinize gönderildi {email_address}. Lütfen yeniden yapmaya çalışmadan önce 30 saniye bekleyin.',
  },
  ResetPassword: { 'Enter your new password': 'Yeni şifrenizi giriniz' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'şifreniz başarıyla değiştirildi',
    'Go to login page': 'Giriş sayfasına git',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Parola Sıfırlama Başarısız',
    'Ref: Error': 'Bir hatayla karşılaşıldı. Lütfen tekrar deneyin',
    'Reset your password': 'Şifrenizi sıfırlayın',
    'Go to login page': 'Giriş sayfasına git',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Gizlilik Politikası',
    'Ref: Privacy Policy Body':
      'Bu platforma erişerek, Sodexo SA ve yerel Sodexo varlığı, en azından hesabınızı yönetmek ve platform aracılığıyla sunulan hizmetleri ve teklifleri sağlamak için kişisel verilerinizi işleyecektir. Kişisel verilerinizin işlenmesi, kime erişebileceği ve kişisel verileriniz üzerindeki hakların nasıl kullanılacağına dair daha fazla bilgi istiyorsanız, lütfen aşağıdaki Gizlilik Politikasına ve hesabınızda herhangi bir zamanda kullanılabilir.',
    'Ref: Continue': 'Devam etmek',
    'Ref: Read Privacy Policy': 'Gizlilik Politikasını Oku',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Mağazaya Git' },
  SharePage: { 'Ref: Select a site': 'Bir Site Seçin' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Gizlilik politikasını görüntülemek için lütfen ülkenizi seçin.',
  },
};
export default labels;
