const labels = {
  AuditInviteList: {
    'Ref: Page title': '監査',
    'Ref: status0': '開ける',
    'Ref: status1': '完了しました',
    'Ref: status2': 'キャンセル',
    'Ref: connected': '接続',
    'Ref: not connected': '接続されていません',
    'Ref: synchronise data': 'データを同期します',
    'Ref: sync success': 'データは正常に同期しました',
    'Ref: Create new': '新しく作る',
    'Ref: Audit unsynchronised': '非そして非そして',
    'Ref: Audit synchronised': '同期',
    'Ref: Unsynced error title': 'エラーが発生しました',
    'Ref: Unsynced error description':
      '監査モジュールに初めてアクセスするのは初めてのようです。ネットワークに接続して「同期」して監査を開始してください。',
    'Ref: Search all audits': 'すべての監査',
    'Ref: Search active audits': '開ける',
    'Ref: Search completed audits': '完了しました',
    'Ref: sync error': '同期は失敗しました',
    'Ref: sync error details': '再接続するために再試行します',
    'Ref: no search audit results': '残念ながら、検索に対応する監査はありません',
    'Ref: no audit results': '利用可能な監査はありません、新しい作成して始めるために作成します',
    'Ref: Search canceled audits': 'キャンセル',
    'Ref: Audit cancelled successfully': '監査は正常にキャンセルされました',
    'Ref: Cancel audit invite': 'キャンセル',
    'Ref: Error audit cancel': '監査はキャンセルされませんでした',
    'Ref: Cancel audit confirmation': '監査をキャンセルしたいですか？',
    'Ref: Confirm': '確認する',
    'Ref: Cancel audit': '監査をキャンセルします',
    'Ref: Change the location': '場所を変更します',
  },
  AuditResponseForm: {
    'Ref: Page title': '監査の詳細',
    'Ref: questions': '質問',
    'Ref: question': '質問',
    'Ref: Additional Comments': '説明',
    'Ref: Not found title': 'おっとっと！',
    'Ref: Not found text': 'アクセスしようとしている監査は存在しません。',
    'Ref: Back to Audit list': '監査リストに戻ります',
    'Ref: Success title': 'ありがとう！',
    'Ref: Success text': '監査を完了しました',
    'Ref: submit': '送信',
    'Ref: add attachment': '添付ファイルを追加します',
    'Ref: Unsupported elements':
      '申し訳ありませんが、この監査にはアプリケーションがまだサポートしていないコンテンツがあるようです。私たちはそれに取り組みます。',
    'Ref: form error': 'すべての質問が完全に答えられているわけではありません',
    'Ref: form complete': 'それ以来完成しました',
    'Ref: attachment preview error': 'この添付ファイルを見るには、オンラインである必要があります',
  },
  AuditCreationForm: {
    'Ref: Page title': '新しく作る',
    'Ref: Location': '位置',
    'Ref: Audit Stream Type': 'ストリーム',
    'Ref: Audit': '監査',
    'Ref: Asset': '資産',
    'Ref: Asset Name': '資産名',
    'Ref: Is joint audit?': '共同監査ですか？',
    'Ref: Who was present?': '誰がいたの？',
    'Ref: submit': '送信',
    'Ref: Select the Location': '場所を選択します',
    'Ref: Select the Audit Stream Type': '監査ストリームタイプを選択します',
    'Ref: Select the Audit': '監査を選択します',
    'Ref: Select the Asset': 'アセットを選択します',
    'Ref: form error': '必要なすべてのフィールドが完全に入力されているわけではありません',
    'Ref: Audit error': '添付監査',
    'Ref: Joint Audit Participants error': '共同監査参加者',
    'Ref: Audit created': '作成された監査',
    'Ref: Audit field length error': '長さは100文字を超えてはなりません',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': '場所を変更します',
    'Ref: Location changed': '場所が変更されました',
    'Ref: Location': '位置',
    'Ref: Select the Location': '場所を選択します',
    'Ref: Asset': '資産',
    'Ref: Select the Asset': 'アセットを選択します',
    'Ref: Asset Name': '資産名',
    'Ref: submit': '送信',
    'Ref: Audit field length error': '長さは100文字を超えてはなりません',
  },
};
export default labels;
