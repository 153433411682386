const labels = {
  All: {
    Feedback: 'Votre avis',
    'Ref: Switch site component intro sentence':
      'Vos commentaires seront liés au site {site name}.',
    Category: 'Catégorie',
    Subject: 'Object',
    Comment: 'Commenter',
    'Date of your experience': 'Date de votre expérience *',
    'Ref: Hint': 'Avez-vous un problème ou un travail qui doit être résolu ?',
    'Ref: Log Request': 'Enregistrer une demande',
    'Ref: Describe issue': 'Veuillez nous parler de votre expérience',
    Date: 'Date',
    'Ref: Share your opinion header': 'Partagez votre opinion sur votre expérience au travail',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurant / café',
    'Desk / workstation area': 'Zone de bureau / poste de travail',
    'Meeting room facilities': 'Installations de salle de réunion',
    'Coffee/tea facilities (pantry)': 'Café / thé (garde-manger)',
    'Restrooms / washrooms': 'Toilettes / toilettes',
    'Reception / Security': 'Réception / sécurité',
    'Conference center': 'Centre de conférence',
    'Fitness center': 'Centre Fitness',
    Mailroom: 'Salle de compagnie',
    'Bicycle room': 'Salle de vélo',
    'Shuttle services': 'Services de navette',
    Lockers: 'Casiers',
    Helpdesk: "Bureau d'aide",
  },
  Create: {
    'Tell us!': 'Dites-nous!',
    'Ref: All Ears': 'Nous sommes tout ouïe !',
    'Ref: Provide Description': 'Veuillez fournir une description',
    'Ref: Fill-in-the-blank sentence': 'Je souhaite fournir un {0} concernant {1}',
    'Ref: Link text to service request module': 'Une demande ou un problème ? Enregistrez-le ici !',
    'Complaint type': 'Plainte {emoji}',
    'Feedback type': 'Feedback {emoji}',
    'Compliment type': 'Compliment {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      "Une erreur s'est produite et votre commentaire n'a pas été soumis. Si cette erreur persiste, veuillez contacter votre service d'assistance.",
  },
  CreateSuccess: {
    'Ref: Body':
      'Vos commentaires ont été soumis. Notre équipe support le lira attentivement. Le cas échéant, nous reviendrons vers vous.',
    'New feedback': 'Nouveaux commentaires',
  },
  CreateFlow: {
    Description: 'La description',
    'Ref: Provide Description': 'Veuillez fournir une date et une description',
    'Ref: I would like to provide a': 'Je voudrais fournir un',
    'Ref: in regards to': 'en ce qui concerne',
    'Ref: &': '&',
    'Ref: at': 'à',
    'Ref: on the': 'sur le',
  },
};
export default labels;
