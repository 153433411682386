import moment from 'moment';

import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { dspApiPath } from '../../../config';
import mockResponse from '../mockResponse';
import { IStay } from '../types/accomodation.types';

import {
  GetStayDetailsRequest,
  GetStaysRequest,
  GetStaysResponse,
  PostCustomActionRequest,
} from './api.types';

import dspApi from '@/services/api/dspApi';

const accomodationApi = dspApi.enhanceEndpoints({ addTagTypes: ['accomodation'] }).injectEndpoints({
  endpoints: (build) => ({
    getStays: build.query<GetStaysResponse, GetStaysRequest>({
      queryFn: async ({
        departureDateOnAfter = moment().add(-100, 'days').format('YYYY-MM-DD'),
      }) => {
        const baseQuery = axiosBaseQuery({ baseUrl: dspApiPath });
        const staysResponse = await baseQuery({
          url: '/v1/stays',
          method: 'get',
          params: { departureDateOnAfter },
          mockData: mockResponse.getStays,
        });

        const { data: { stays } = {} } = staysResponse;

        if (!stays) return { data: [] };

        return {
          data: stays,
        };
      },
    }),
    getStayDetails: build.query<IStay, GetStayDetailsRequest>({
      query: ({ stayId, preferredLanguage }: GetStayDetailsRequest) => ({
        url: `/v1/stays/${stayId}`,
        method: 'get',
        params: {
          preferredLanguage,
        },
        mockData: mockResponse.getStayDetails,
      }),
      providesTags: (result) => [{ type: 'accomodation', id: result?.id }],
    }),
    postCustomAction: build.mutation<void, PostCustomActionRequest>({
      queryFn: async ({ url, stayId }: PostCustomActionRequest) => {
        // this edge case is required for INNfinity System checkouts or any other custom actions.
        // The INNfintiy Api might return a custom action URL that might not be on the same as our IFM Api.
        // as result we have to set the Full URL in the request , rather than API relative path.
        const baseQuery = axiosBaseQuery({ baseUrl: url });
        const response = await baseQuery({
          url: '',
          method: 'post',
          mockData: mockResponse.postCustomAction,
        });
        return response;
      },
      invalidatesTags: (result, error, { stayId }) => [
        'accomodation',
        { type: 'accomodation', stayId },
      ],
    }),
  }),
  overrideExisting: true,
});

export const { useGetStaysQuery, useGetStayDetailsQuery, usePostCustomActionMutation } =
  accomodationApi;
export default accomodationApi;
