const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': '服務請求 - 幫助台',
    'Ref: Forbidden heading': '禁止',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      '您正在網站{siteName}上管理服務請求。',
    'Ref: Dates': '日期',
    'Ref: SRL list refresh': '刷新列表',
    'Ref: Export': '出口',
    'Ref: Empty/not filled': '空/沒有填充',
    'Ref: Name': '姓名',
    'Ref: Date': '日期',
    'Ref: Category': '類別',
    'Ref: User': '用戶',
    'Ref: Location': '地點',
  },
};
export default labels;
