import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import ProductsList from '../ProductsList';

const menus = [
  {
    id: 'eb77a55a-7f63-ef11-a4e6-002248991856',
    name: 'Tavern BBQ',
    dayparts: [
      {
        name: 'Tavern Menu Served - 2024-08-26',
        sections: [
          {
            id: '',
            name: 'To Share',
            items: [
              {
                id: '7a2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Chips',
                description: 'Beer battered chips with tomao sauce & aoli',
                price: '7.00',
                displayPrice: null,
                priceInText: 'AUD7.00',
                category: 'To Share',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '7e2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Battered Onion Rings',
                description: 'Battered Onion Rings with chipotle aioli',
                price: '8.00',
                displayPrice: null,
                priceInText: 'AUD8.00',
                category: 'To Share',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '802fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Wedges',
                description: 'Sidewinder wedges with sour cream & sweet chilli sauce ',
                price: '8.50',
                displayPrice: null,
                priceInText: 'AUD8.50',
                category: 'To Share',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Pizza',
            items: [
              {
                id: '7c2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Meat Lovers',
                description: 'Tomatoes, garlic, cheese, oregano, ham and pepperoni.',
                price: '13.00',
                displayPrice: null,
                priceInText: 'AUD13.00',
                category: 'Pizza',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '7f2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Hawaiian',
                description: 'Tomatoes, garlic, cheese, ham and pineapple',
                price: '12.00',
                displayPrice: null,
                priceInText: 'AUD12.00',
                category: 'Pizza',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '842fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Medium',
                description: 'Olives,cheese,bell peppers',
                price: '11.50',
                displayPrice: null,
                priceInText: 'AUD11.50',
                category: 'Pizza',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '862fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Chicken',
                description: 'Tomatoes, cheese, BBQ sauce, chicken, bacon, capsicum and oregano',
                price: '11.50',
                displayPrice: null,
                priceInText: 'AUD11.50',
                category: 'Pizza',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '872fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Vegetarian',
                description:
                  'Tomatoes, cheese, roasted peppers, pineapple, spinach leaves, black olives,jalapeno,banana peppers and feta.',
                price: '10.50',
                displayPrice: null,
                priceInText: 'AUD10.50',
                category: 'Pizza',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '8b2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Supreme',
                description:
                  'Tomatoes, cheese, ham, pepperoni, pineapple, roasted peppers, black olives, spinach leaves and feta.',
                price: '13.00',
                displayPrice: null,
                priceInText: 'AUD13.00',
                category: 'Pizza',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Burgers & Sandwiches',
            items: [
              {
                id: '8a2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Medium',
                description:
                  'Grilled beef pattie on a toasted Brioche bun with American cheese,pickle onion, tomato sauce and mustard ',
                price: '12.00',
                displayPrice: null,
                priceInText: 'AUD12.00',
                category: 'Burgers & Sandwiches',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '8e2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'The Aussie- Burger-65',
                description:
                  'Grilled beef pattie on a toasted Brioche bun with lettuce, cheese, beetroot, fried egg bacon & tomato sauce ',
                price: '14.00',
                displayPrice: null,
                priceInText: 'AUD14.00',
                category: 'Burgers & Sandwiches',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '932fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Chicken Schnitzel Burger',
                description:
                  'Chicken schnitzel on a toasted Turkish bread with bacon, cheese, lettuce, tomato & Hickory BBQ sauce ',
                price: '14.50',
                displayPrice: null,
                priceInText: 'AUD14.50',
                category: 'Burgers & Sandwiches',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '992fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Steak Sandwich',
                description:
                  'Grilled steak on toasted Turkish bread with lettuce, tomato, cheese tabasco fried onions & house made tomato chutney, grilled onions, mayo',
                price: '14.50',
                displayPrice: null,
                priceInText: 'AUD14.50',
                category: 'Burgers & Sandwiches',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Specials',
            items: [
              {
                id: '972fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Cajun Chicken Pizza',
                description:
                  'Tomatoes, cheese, tomato sauce, cajun spiced chicken, capsicum and red onion',
                price: '12.50',
                displayPrice: null,
                priceInText: 'AUD12.50',
                category: 'Specials',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'a12fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Mushroom Beef Burger',
                description:
                  'Grilled beef pattie on a toasted  brioche bun with sauted mushrooms, swiss cheese, tomato, mesculin lettuce, tomato relish served with beer battered chips & aoili',
                price: '12.50',
                displayPrice: null,
                priceInText: 'AUD12.50',
                category: 'Specials',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'a52fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Brisket Burger',
                description:
                  'Beef brisket on a toasted Brioche bun with a crunchy slaw, BBQ Chipotle sauce served with beer battered chips & aoili ',
                price: '12.00',
                displayPrice: null,
                priceInText: 'AUD12.00',
                category: 'Specials',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'a82fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Veg Burger',
                description: 'Veggie Burger with cheese,lettuce,tomatoes',
                price: '18.00',
                displayPrice: null,
                priceInText: 'AUD18.00',
                category: 'Specials',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'ab2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Burger & Beer',
                description: 'Mushroom Beef Burger and a Little Creatures Pale Ale',
                price: '16.00',
                displayPrice: null,
                priceInText: 'AUD16.00',
                category: 'Specials',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Tasty Plates',
            items: [
              {
                id: '9b2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Southern Fried Chicken',
                description: '1 Kilo Southern Fried Chicken with smokey chilli Aoili  dressing ',
                price: '15.00',
                displayPrice: null,
                priceInText: 'AUD15.00',
                category: 'Tasty Plates',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '9d2fe662-7f63-ef11-a671-000d3acc5707',
                name: 'Spicy Popcorn Pork',
                description: 'Spicy Battered Pork with Sriracha hot sauce & lemon ',
                price: '12.00',
                displayPrice: null,
                priceInText: 'AUD12.00',
                category: 'Tasty Plates',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'a32fe662-7f63-ef11-a671-000d3acc5707',
                name: '活力 Bites',
                description:
                  "Each platter contains jalapeno poppers, fried camembert, grilled cacciatore, salt'n'pepper squid & Turkish bread",
                price: '12.00',
                displayPrice: null,
                priceInText: 'AUD12.00',
                category: 'Tasty Plates',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
        ],
        dayofweek: 'Friday',
        date: '2024-09-06T00:00:00',
      },
      {
        name: 'DINNER',
        sections: [
          {
            id: '',
            name: 'Dinner - Soup',
            items: [
              {
                id: 'f593550e-7f63-ef11-a671-000d3acc5707',
                name: 'Roasted Red Pepper Soup with Feta',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Soup',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Dinner - Cooked to Order',
            items: [
              {
                id: '2194550e-7f63-ef11-a671-000d3acc5707',
                name: 'Grilled Rump Steak',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Cooked to Order',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '4394550e-7f63-ef11-a671-000d3acc5707',
                name: 'Herbed Grilled Chicken',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Cooked to Order',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '6194550e-7f63-ef11-a671-000d3acc5707',
                name: 'Grilled Sword Fish',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Cooked to Order',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Dinner - Destination Meal #1',
            items: [
              {
                id: 'ead94d14-7f63-ef11-a671-000d3acc5707',
                name: 'Marinated Eggplant Steak',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #1',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '04da4d14-7f63-ef11-a671-000d3acc5707',
                name: 'Vegetable Bar',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #1',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '2bda4d14-7f63-ef11-a671-000d3acc5707',
                name: 'Crispy Battered Fish',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #1',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '63da4d14-7f63-ef11-a671-000d3acc5707',
                name: 'Lemon Pepper Crusted & Grilled Fish Fillets',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #1',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Dinner - Destination Meal #2',
            items: [
              {
                id: '6dda4d14-7f63-ef11-a671-000d3acc5707',
                name: 'Chips',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #2',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '82da4d14-7f63-ef11-a671-000d3acc5707',
                name: 'Beef Meatballs with Red Pepper Olive & Tomato Sauce',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #2',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'a3da4d14-7f63-ef11-a671-000d3acc5707',
                name: 'Steamed Cabbage with Onion & Garlic',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #2',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Dinner - Destination Meal #3',
            items: [
              {
                id: 'c9da4d14-7f63-ef11-a671-000d3acc5707',
                name: 'Roast Carrots & Onions',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #3',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '14db4d14-7f63-ef11-a671-000d3acc5707',
                name: 'Mongolian Lamb with Hokkien Noodles',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #3',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'fb1f461a-7f63-ef11-a671-000d3acc5707',
                name: 'Stir-Fry Asian Greens',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #3',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Dinner - Destination Meal #4',
            items: [
              {
                id: '2520461a-7f63-ef11-a671-000d3acc5707',
                name: 'Steamed Broccoli',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #4',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '5120461a-7f63-ef11-a671-000d3acc5707',
                name: 'Massaman Vegetable Curry',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #4',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Dinner - Destination Meal #5',
            items: [
              {
                id: '6d20461a-7f63-ef11-a671-000d3acc5707',
                name: 'Coconut Rice',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Destination Meal #5',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Dinner - Dessert',
            items: [
              {
                id: '9020461a-7f63-ef11-a671-000d3acc5707',
                name: 'Apple Strudel',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Dessert',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'a420461a-7f63-ef11-a671-000d3acc5707',
                name: 'Custard',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Dinner - Dessert',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Crib - Grab & Go',
            items: [
              {
                id: 'b420461a-7f63-ef11-a671-000d3acc5707',
                name: 'G&G Greek Lemon Chicken & Macaroni Salad Bowl',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Grab & Go',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'c920461a-7f63-ef11-a671-000d3acc5707',
                name: 'G&G Italian Ham Salami Cheese & Pickle Sub',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Grab & Go',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: 'db20461a-7f63-ef11-a671-000d3acc5707',
                name: 'Mediterranean Grilled Vegetable & Pesto Roll',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Grab & Go',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '1b21461a-7f63-ef11-a671-000d3acc5707',
                name: 'Jam Scroll',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Grab & Go',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '2d21461a-7f63-ef11-a671-000d3acc5707',
                name: 'Corn Chips & Salsa',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Grab & Go',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '4021461a-7f63-ef11-a671-000d3acc5707',
                name: 'G&G Apple & Mixed Berry Yoghurt Cup',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Grab & Go',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
          {
            id: '',
            name: 'Crib - Heat & Eat',
            items: [
              {
                id: '4f21461a-7f63-ef11-a671-000d3acc5707',
                name: 'Beef Barbacoa Tacos',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Heat & Eat',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '6021461a-7f63-ef11-a671-000d3acc5707',
                name: 'Spicy Corn & Bean Casserole',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Heat & Eat',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
              {
                id: '6e21461a-7f63-ef11-a671-000d3acc5707',
                name: 'Mexican Black Bean Soup',
                description: '',
                price: null,
                displayPrice: null,
                priceInText: '',
                category: 'Crib - Heat & Eat',
                imageId: '',
                listImageUrl: '',
                detailImageUrl: '',
                foodSubCategoryDescription: '',
                nutritionFacts: null,
                allergens: [],
                isOrderable: false,
                modifiers: [],
                uomId: 0,
              },
            ],
          },
        ],
        dayofweek: 'Friday',
        date: '2024-09-06T00:00:00Z',
      },
    ],
    pickupspotreferenceid: '566ad89f-bdd2-e811-8199-e0071b67ccf1',
  },
];

jest.mock('../../../api/api', () => ({
  useGetMenusQuery: () => ({
    data: { menus },
    isLoading: false,
  }),
  useGetFoodFacilitiesQuery: () => ({
    data: {},
    isLoading: false,
  }),
}));

jest.mock('../../../../../components/molecules/Tile', () => ({ title }: any) => (
  <div data-testid="menu-item">{title}</div>
));

describe('ProductsList', () => {
  afterAll(() => cleanup());

  // Working on web, not working in tests
  describe.skip('Initial render', () => {
    let menuItems: HTMLElement[];

    renderComponent(ProductsList);

    beforeEach(() => {
      menuItems = screen.getAllByTestId('menu-item');
    });

    it('should display correct number of menu items', () => {
      expect(menuItems.length).toEqual(1);
    });
  });
});
