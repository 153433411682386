import React from 'react';
import { CalendarProps } from 'react-calendar';

import { TranslationProps } from '../../../localization/localization.types';
import { DefaultComponentWithoutChildrenPropsV2 } from '../../../types';
import { BADGE_PLACEMENT, BADGE_POSITION, BUTTON_LOOK } from '../Button';
import { ButtonLook } from '../Button/Button.types';

export enum FilterType {
  CALENDAR = 'CALENDAR',
  RANGE = 'RANGE',
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  CONDENSED = 'CONDENSED',
  EXPANDED = 'EXPANDED',
  CHECKBOX_SINGLE = 'CHECKBOX_SINGLE',
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  DROPDOWN = 'DROPDOWN',
  NONE = 'NONE',
  TAB = 'TAB',
}

export enum FilterPosition {
  NOT_IN_MODAL = 'NOT_IN_MODAL',
  MODAL_ON_MOBILE = 'MODAL_ON_MOBILE',
  ALWAYS_IN_MODAL = 'ALWAYS_IN_MODAL',
  INVISIBLE = 'INVISIBLE',
}

export type FormattedFilters = {
  visible: JSX.Element[];
  visibleFilters: Filter[];
  modal: JSX.Element[];
  modalFilters: Filter[];
  invisible: JSX.Element[];
  invisibleFilters: Filter[];
};

export type FilterOption = {
  label: string;
  value: string;
  group?: string;
  default?: boolean;
  icon?: React.ReactElement;
  'data-testid'?: string;
};

type BaseFilter = {
  id: string;
  name?: string;
  wrapperClassName?: string;
  options?: FilterOption[];
  getApplicableOptions?: (filtering: Filtering, options?: FilterOption[]) => FilterOption[]; //fuction that takes the overal filtering state and (if needed) the filter's options as its arguments and returns the list of applicable options
  multiple: boolean; //default: false
  position?: FilterPosition;
  text?: string;
  excludeCount?: boolean;
  withTitle?: boolean;
  apply?: (values: string[]) => string[];
};

export type DateFilter = {
  displayType: FilterType.CALENDAR;
  minDate: Date;
  maxDate: Date;
  tileClassName?: (props: { date: Date }) => string;
  tileDisabled?: CalendarProps['tileDisabled'];
} & BaseFilter;

export type RangeFilter = {
  displayType: FilterType.RANGE;
  min: number;
  max: number;
  defaultValue: number;
  customMaxLabel?: string;
  customMinLabel?: string;
  unit?: string;
} & BaseFilter;

export type MultiCardFilter = {
  displayType: FilterType.MULTIPLE_SELECT;
  options: FilterOption[];
  isMultipleSelect?: boolean;
  columns?: number;
  columnWidth?: string;
  seeMoreLabel?: string;
  seeLessLabel?: string;
  preventUnselect?: boolean;
} & BaseFilter;

export type SingleCheckboxFilter = {
  displayType: FilterType.CHECKBOX_SINGLE;
} & BaseFilter;

export type CheckboxGroupFilter = {
  displayType: FilterType.CHECKBOX_GROUP;
} & BaseFilter;

export type DropdownFilter = {
  displayType: FilterType.DROPDOWN;
  look?: BUTTON_LOOK;
} & BaseFilter;

export type NormalFilter = {
  displayType:
    | FilterType.CONDENSED
    | FilterType.EXPANDED
    | FilterType.NONE
    | undefined
    | FilterType.TAB;
} & BaseFilter;

export type Filter =
  | DateFilter
  | NormalFilter
  | RangeFilter
  | MultiCardFilter
  | SingleCheckboxFilter
  | CheckboxGroupFilter
  | DropdownFilter;

export type Filtering = Record<string, Record<string, any>>;

export type FiltersChangeCallback = (filtering: Filtering) => void;

export type FiltersProps = {
  filters: Filter[];
  handleChange: FiltersChangeCallback;
  filtering: Filtering;
  languageCode?: string;
  hideTitle: boolean;
  setModalState: (isModalOpen: boolean) => void;
  isModalOpen: boolean;
} & DefaultComponentWithoutChildrenPropsV2;

export type UseFiltersProps = {
  filters: Filter[];
  handleChange: FiltersChangeCallback;
  filtering: Filtering;
  testId: string;
  languageCode?: string;
  facilityId?: string;
} & TranslationProps;

export type ModalFiltersProps = {
  filters: Filter[];
  setModalState: (isModalOpen: boolean) => void;
  isModalOpen: boolean;
  onCommitFilter: () => void;
  onDismissModal: () => void;
  onResetModalFilters: () => void;
  filtering: Filtering;
  uncommitedFiltering?: Filtering;
  languageCode?: string;
  handleChange: (name: string, value: string, checked?: boolean) => void;
  handleCalendarChange?: (filterId: string, strDate: string) => void;
} & DefaultComponentWithoutChildrenPropsV2;

export type ButtonModalFiltersProps = {
  label: string;
  srOnlyLabel: string;
  hasFilters: boolean;
  filters: Filter[];
  filtering: Filtering;
  uncommitedFiltering: Filtering;
  hideFilterTitle: boolean;
  handleFilteringChange: (name: string, value: string, checked?: boolean) => void;
  selectedNum: number;
  badgePosition?: BADGE_POSITION;
  badgePlacement?: BADGE_PLACEMENT;
  look?: BUTTON_LOOK | ButtonLook;
  className?: string;
  languageCode?: string;
  onCommitFilter: () => void;
  onDismissModal: () => void;
  onResetModalFilters: () => void;
} & DefaultComponentWithoutChildrenPropsV2;

export type FilterComponentProps = {
  languageCode?: string;
  filter: Filter;
  filtering: Filtering;
  sectionRefs?: React.MutableRefObject<React.RefObject<HTMLSpanElement>[]>;
  activeSectionIndex?: number;
  handleChange: (name: string, value: string, checked?: boolean) => void;
  handleCalendarChange?: (filterId: string, strDate: string) => void;
  useScrollFiltering?: boolean;
} & TranslationProps &
  DefaultComponentWithoutChildrenPropsV2;
