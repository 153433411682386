import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { WarningOutlinedIcon } from '../../../../assets/icons';
import Title from '../../../../components/atoms/Title';
import WidgetPlaceholder from '../../../../components/molecules/WidgetPlaceholder';
import Widget from '../../../../components/organisms/Widget';
import useShareModal from '../../../../helpers/hooks/useShareModal';
import { State } from '../../../../types/state.types';
import { useGetHomeQuery } from '../../api';
import { ContentTile } from '../../components/ContentTile';
import { pagePaths } from '../../config';
import { useContentTranslation } from '../../hooks/useContentTranslation';
import { IContentType } from '../../types';

import { BackgroundWidgetContentIllustration } from '@/assets/illustrations';

const MAX_CONTENT_ITEMS = 3;

const ContentWidget = () => {
  const { label } = useContentTranslation(__filename);
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id);
  const currentLanguageCode = useSelector(
    (state: State) => state.Shared?.language?.currentLanguageCode
  );

  const { data: list = [], error } = useGetHomeQuery({
    languageCode: currentLanguageCode,
    siteId: siteId!,
    useErrorBoundary: false,
  });

  const { shareOptionsModal, triggerShareModal } = useShareModal({
    label,
  });

  const showSeeAll = list?.length > MAX_CONTENT_ITEMS;

  const items = useMemo(
    () =>
      list
        ?.filter((c) => c.type !== IContentType.PROMOTIONAL)
        .slice(0, MAX_CONTENT_ITEMS)
        .map((item) => {
          return (
            <ContentTile
              key={item.id}
              item={item}
              languageCode={currentLanguageCode}
              siteId={siteId}
              triggerShareModal={triggerShareModal}
              label={label}
            />
          );
        }),
    [currentLanguageCode, label, list, siteId, triggerShareModal]
  );

  return (
    <Widget>
      <Widget.Header illustration={<BackgroundWidgetContentIllustration />}>
        <Widget.Title>
          <Title data-testid={'content-widget-title'}>{label('Ref: Widget Title')}</Title>
          {showSeeAll && (
            <Widget.Link to={pagePaths['Module']} data-testid={'content-widget'}>
              {label('see all (as in see all items)', { textTransform: 'capitalize' })}
            </Widget.Link>
          )}
        </Widget.Title>
      </Widget.Header>
      <Widget.Body>
        {items && items.length > 0 ? (
          items
        ) : (
          <WidgetPlaceholder
            title={label(
              error ? 'Something went wrong, data not loaded' : 'No info yet, come back later'
            )}
            icon={error ? <WarningOutlinedIcon /> : undefined}
          />
        )}
        {shareOptionsModal}
      </Widget.Body>
    </Widget>
  );
};

export default ContentWidget;
