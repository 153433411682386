import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { EventType } from '../../types';

import EventWidget from './index';

const mock: { data: EventType[]; isLoading: boolean } = { data: [], isLoading: false };
jest.mock('../../api', () => ({
  useGetEventsQuery: () => {
    return mock;
  },
  useToggleInterestMutation: () => {
    return [];
  },
}));

jest.setTimeout(100000);

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => ({
    siteId: 'id1',
  }),
}));

const mockData: EventType[] = [
  {
    id: '300bd8aa-45ee-ec11-b5cf-0003ff4cdd18',
    name: 'Volleyball',
    location: 'Paris',
    description: 'Bring your own ball',
    startDate: '2022-06-22T14:57:00Z',
    endDate: '2024-10-16T14:57:00Z',
    publishedDate: '2022-06-16T23:00:00Z',
    eventDates: ['2022-06-22'],
    sites: [],
    link: {
      url: '',
      text: '',
    },
    images: [],
    preferences: [],
    isUserInterested: true,
    reactions: {
      reactions: [],
      me: null,
      total: 0,
    },
    'data-testid': 'event-tile',
  },
  {
    id: '9c97d4d3-ce14-ed11-b5cf-0003ff4cb25e',
    name: 'Yoga with Zoe',
    location: 'Vienna',
    description: 'Extraordinary yoga session',
    startDate: '2022-12-14T17:00:00Z',
    endDate: '2024-12-19T23:30:00Z',
    publishedDate: '2022-07-11T21:00:00Z',
    eventDates: ['2022-06-22'],
    sites: [],
    link: {
      url: '',
      text: '',
    },
    images: [],
    preferences: [],
    isUserInterested: true,
    reactions: {
      reactions: [],
      me: null,
      total: 0,
    },
    'data-testid': 'event-tile',
  },
  {
    id: 'bf5b67cc-d701-ed11-b5cf-0003ff4cdd18',
    name: 'Tabata',
    location: 'Bratislava',
    description: 'We will meet in the gym',
    startDate: '2022-12-15T17:00:00Z',
    endDate: '2024-12-15T23:30:00Z',
    publishedDate: '2022-08-15T21:00:00Z',
    eventDates: ['2022-07-25'],
    sites: [],
    link: {
      url: '',
      text: '',
    },
    images: [],
    preferences: [],
    isUserInterested: true,
    reactions: {
      reactions: [],
      me: null,
      total: 0,
    },
    'data-testid': 'event-tile',
  },
];

describe('EventWidget', () => {
  afterAll(() => cleanup());

  describe('with data', () => {
    beforeEach(() => {
      mock.data = mockData;
    });

    renderComponent(EventWidget);

    it('should render events', () => {
      const eventElement1 = screen.getByTestId(`EventTile-${mockData[0].id}`);
      const eventElement2 = screen.getByTestId(`EventTile-${mockData[1].id}`);
      const eventElement3 = screen.getByTestId(`EventTile-${mockData[2].id}`);
      expect(eventElement1).toBeTruthy();
      expect(eventElement2).toBeTruthy();
      expect(eventElement3).toBeTruthy();
    });
  });

  describe('without data', () => {
    beforeEach(() => {
      mock.data = [];
    });
    renderComponent(EventWidget);

    it('should render placeholder', () => {
      const eventElement = screen.getAllByTestId('widget-placeholder');
      expect(eventElement).toBeTruthy();
    });
  });
});
