const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Audit',
    'Ref: status0': 'Aprire',
    'Ref: status1': 'Completato',
    'Ref: status2': 'Annullato',
    'Ref: connected': 'Collegato',
    'Ref: not connected': 'Non collegata',
    'Ref: synchronise data': 'Sincronizzare i dati',
    'Ref: sync success': 'Dati sincronizzati correttamente',
    'Ref: Create new': 'Creare nuovo',
    'Ref: Audit unsynchronised': 'Non sincronizzato',
    'Ref: Audit synchronised': 'Sincronizzato',
    'Ref: Unsynced error title': "C'è stato un errore",
    'Ref: Unsynced error description':
      'Sembra che sia la prima volta che accedi al modulo di audit. Si prega di connettersi alla rete e "sincronizzare" per iniziare a revisione contabile.',
    'Ref: Search all audits': 'Tutti gli audit',
    'Ref: Search active audits': 'Aprire',
    'Ref: Search completed audits': 'Completato',
    'Ref: sync error': 'La sincronizzazione non è riuscita',
    'Ref: sync error details': 'Ritratta per riconnettersi',
    'Ref: no search audit results':
      'Sfortunatamente, non ci sono audit corrispondenti alla tua ricerca',
    'Ref: no audit results': 'Non hai audit disponibili, crea nuovi per iniziare',
    'Ref: Search canceled audits': 'Annullato',
    'Ref: Audit cancelled successfully': 'Audit annullato con successo',
    'Ref: Cancel audit invite': 'Annulla',
    'Ref: Error audit cancel': "L'audit non è stato cancellato",
    'Ref: Cancel audit confirmation': "Sei sicuro di voler annullare l'audit?",
    'Ref: Confirm': 'Confermare',
    'Ref: Cancel audit': 'Annulla audit',
    'Ref: Change the location': 'Cambia la posizione',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Dettagli di audit',
    'Ref: questions': 'domande',
    'Ref: question': 'domanda',
    'Ref: Additional Comments': 'Descrizione',
    'Ref: Not found title': 'Oops!',
    'Ref: Not found text': "L'audit che stai cercando di accedere non esiste.",
    'Ref: Back to Audit list': "Torna all'elenco di audit",
    'Ref: Success title': 'Grazie!',
    'Ref: Success text': "Hai completato l'audit",
    'Ref: submit': 'Invia',
    'Ref: add attachment': 'Aggiungi un allegato',
    'Ref: Unsupported elements':
      "Mi dispiace, sembra che questo audit abbia contenuti che l'applicazione non supporta ancora. Ci lavoreremo.",
    'Ref: form error': 'Non tutte le domande sono state completamente risposte',
    'Ref: form complete': 'Completato da allora',
    'Ref: attachment preview error': 'Devi essere online per vedere questo allegato',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Creare nuovo',
    'Ref: Location': 'Posizione',
    'Ref: Audit Stream Type': 'Flusso',
    'Ref: Audit': 'Audit',
    'Ref: Asset': 'Risorsa',
    'Ref: Asset Name': 'Nome delle risorse',
    'Ref: Is joint audit?': 'È audit congiunto?',
    'Ref: Who was present?': 'Chi era presente?',
    'Ref: submit': 'Invia',
    'Ref: Select the Location': 'Seleziona la posizione',
    'Ref: Select the Audit Stream Type': 'Seleziona il tipo di flusso di audit',
    'Ref: Select the Audit': "Seleziona l'audit",
    'Ref: Select the Asset': "Seleziona l'asset",
    'Ref: form error': 'Non tutti i campi richiesti sono stati completamente inseriti',
    'Ref: Audit error': 'Audit allegato',
    'Ref: Joint Audit Participants error': 'Partecipanti audit congiunti',
    'Ref: Audit created': 'Audit creato',
    'Ref: Audit field length error': 'La lunghezza non dovrebbe superare i 100 caratteri',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Cambia la posizione',
    'Ref: Location changed': 'Posizione cambiata',
    'Ref: Location': 'Posizione',
    'Ref: Select the Location': 'Seleziona la posizione',
    'Ref: Asset': 'Risorsa',
    'Ref: Select the Asset': "Seleziona l'asset",
    'Ref: Asset Name': 'Nome delle risorse',
    'Ref: submit': 'Invia',
    'Ref: Audit field length error': 'La lunghezza non dovrebbe superare i 100 caratteri',
  },
};
export default labels;
