const labels = {
  ContactForm: { 'Ref: Page title': 'Hubungi kami', 'Ref: Category field value': 'Hubungi kami' },
  ContactList: {
    'Ref: Page title': 'Kontak',
    'call (action to place a call)': 'panggilan',
    'write (action to write a message)': 'menulis',
    'write (action to write an e-mail)': 'surel',
    'Ref: Hint message':
      'Perlu diketahui bahwa beberapa situs mungkin tidak memiliki penerimaan. Jika ini masalahnya, silakan temukan salah satu telepon darat yang tersedia di sekitar situs.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Terjadi kesalahan dan pesan Anda tidak dikirimkan. Jika kesalahan ini berlanjut, silakan hubungi meja bantuan Anda.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Pesan Anda telah terkirim. Terima kasih telah menghubungi kami.',
  },
};
export default labels;
