const labels = {
  All: {
    'starts at': 'から始まります',
    'ends at': 'に終わります',
    location: '場所',
    'coming date': '日時',
    'Ref: Currently event not preferred. Click to make preferred':
      '現在、イベントは好ましくありません。クリックして優先されます',
    'Ref: Currently event preferred. Click to make not preferred':
      '現在、イベントが望ましい。クリックして好まないようにします',
  },
  EventDetails: {
    details: '詳細',
    'Ref: Page title': 'イベントの詳細',
    'Event dates': 'イベントの日時',
    'See online:': 'オンラインを見る：',
    'See all dates': 'すべての日付を見る',
    'Remove from my selection': '選択項目を削除します',
    'Add to my selection': 'マイセレクションに追加されました',
    'Ref: explain calendar usage':
      'イベントの日時は以下のカレンダーで強調表示されています。あなたが好きなイベントをお見逃しなく！カレンダーのイベント日をクリックして招待状をダウンロードします。次に、ダウンロードしたファイルを開くためにイベントを適切なカレンダーに追加します。',
    'Ref: Favorite': 'お気に入り',
    'Ref:Feedback': 'フィードバック',
    'Ref: Event site different title': 'このイベントは現在のサイトに属していません',
    'Ref: Event site different description':
      '現在のサイトに属さないイベントを表示しています。イベントのリストでそれを見ることができません。',
    'Ref: See all dates': 'すべての日付を参照してください',
    'Ref: From': 'から',
    'Ref: to': 'に',
    'Ref: Coming date': '来日',
    'Ref: Add to calendar': 'カレンダーに追加します',
  },
  EventsList: {
    'Ref: Page title': 'イベント',
    'All dates': 'すべての日付',
    'All events': 'すべてのイベント',
    'Ref: Search': '探す',
    'My selection': 'マイセレクション',
    'filter label: date': '日時',
    'filter label: coming events': '今後のイベント',
    'filter label: past events': '過去のイベント',
    'filter label: interest': '好み',
  },
  Widget: {
    'Ref: Carousel title': 'イベント',
    'No info yet, come back later': 'まだ情報がありません。時間を空けてアクセスしてください。',
  },
};
export default labels;
