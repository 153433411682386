import classNames from 'classnames';

import { DateRangeCustomHeaderProps } from './DateRange.types';

import { CalendarNextLabelIcon, CalendarPreviousLabelIcon } from '@/assets/index';

import styles from './DateRange.module.css';
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
const DateRangePickerHeader = ({
  monthDate,
  customHeaderCount,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  languageCode,
  label,
  'data-testid': dataTestId = 'date-picker-header',
  noOfMonthsToShow,
}: DateRangeCustomHeaderProps) => {
  return (
    <div className={classNames(styles.headerCustom__navigation)} data-testid={dataTestId}>
      <div className={classNames(styles.headerCustom__previousLabel)}>
        {customHeaderCount === 0 && (
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            aria-label={label('Ref: Previous Month')}
            data-testid={`${dataTestId}-button-prev`}
          >
            {<CalendarPreviousLabelIcon />}
          </button>
        )}
      </div>
      <div className={classNames(styles.headerCustom__activeMonth)}>
        <span tabIndex={0}>
          {monthDate?.toLocaleString(languageCode, {
            month: 'long',
            year: 'numeric',
          })}
        </span>
      </div>
      <div className={classNames(styles.headerCustom__nextLabel)}>
        {customHeaderCount === noOfMonthsToShow - 1 && (
          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            aria-label={label('Ref: Next Month')}
            data-testid={`${dataTestId}-button-next`}
          >
            {<CalendarNextLabelIcon />}
          </button>
        )}
      </div>
    </div>
  );
};

export default DateRangePickerHeader;
