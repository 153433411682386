const labels = {
  AuditInviteList: {
    'Ref: Page title': '審核',
    'Ref: status0': '打開',
    'Ref: status1': '完全的',
    'Ref: status2': '取消',
    'Ref: connected': '連接的',
    'Ref: not connected': '未連接',
    'Ref: synchronise data': '同步數據',
    'Ref: sync success': '數據成功地同步',
    'Ref: Create new': '創建新的',
    'Ref: Audit unsynchronised': '不同步',
    'Ref: Audit synchronised': '同步',
    'Ref: Unsynced error title': '發生了錯誤',
    'Ref: Unsynced error description':
      '這似乎是您第一次訪問審核模塊。請連接到網絡並“同步”以開始審核。',
    'Ref: Search all audits': '所有審核',
    'Ref: Search active audits': '打開',
    'Ref: Search completed audits': '完全的',
    'Ref: sync error': '同步失敗',
    'Ref: sync error details': '重試重新連接',
    'Ref: no search audit results': '不幸的是，沒有與您的搜索相對應的審核',
    'Ref: no audit results': '您沒有可用的審核，創建新的開始',
    'Ref: Search canceled audits': '取消',
    'Ref: Audit cancelled successfully': '審計成功取消了',
    'Ref: Cancel audit invite': '取消',
    'Ref: Error audit cancel': '審核未能取消',
    'Ref: Cancel audit confirmation': '您確定要取消審核嗎？',
    'Ref: Confirm': '確認',
    'Ref: Cancel audit': '取消審核',
    'Ref: Change the location': '更改位置',
  },
  AuditResponseForm: {
    'Ref: Page title': '審核細節',
    'Ref: questions': '問題',
    'Ref: question': '問題',
    'Ref: Additional Comments': '描述',
    'Ref: Not found title': '哎呀！',
    'Ref: Not found text': '您要訪問的審核不存在。',
    'Ref: Back to Audit list': '返回審核列表',
    'Ref: Success title': '謝謝你！',
    'Ref: Success text': '您已經完成了審核',
    'Ref: submit': '提交',
    'Ref: add attachment': '添加附件',
    'Ref: Unsupported elements': '抱歉，該審核似乎具有該應用程序不支持的內容。我們將努力。',
    'Ref: form error': '並非所有問題都已完全回答',
    'Ref: form complete': '從那以後完成',
    'Ref: attachment preview error': '您需要在線查看此附件',
  },
  AuditCreationForm: {
    'Ref: Page title': '創建新的',
    'Ref: Location': '地點',
    'Ref: Audit Stream Type': '溪流',
    'Ref: Audit': '審計',
    'Ref: Asset': '資產',
    'Ref: Asset Name': '資產名稱',
    'Ref: Is joint audit?': '聯合審計嗎？',
    'Ref: Who was present?': '誰在場？',
    'Ref: submit': '提交',
    'Ref: Select the Location': '選擇位置',
    'Ref: Select the Audit Stream Type': '選擇審核流類型',
    'Ref: Select the Audit': '選擇審核',
    'Ref: Select the Asset': '選擇資產',
    'Ref: form error': '並非所有必需的字段都已經完全輸入',
    'Ref: Audit error': '附件審核',
    'Ref: Joint Audit Participants error': '聯合審計參與者',
    'Ref: Audit created': '創建了審核',
    'Ref: Audit field length error': '長度不應超過100個字符',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': '更改位置',
    'Ref: Location changed': '位置更改',
    'Ref: Location': '地點',
    'Ref: Select the Location': '選擇位置',
    'Ref: Asset': '資產',
    'Ref: Select the Asset': '選擇資產',
    'Ref: Asset Name': '資產名稱',
    'Ref: submit': '提交',
    'Ref: Audit field length error': '長度不應超過100個字符',
  },
};
export default labels;
