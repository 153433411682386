const labels = {
  Success: {
    'Ref: Body':
      'Gracias por completar esta encuesta, su opinión es de gran utilidad ya que estas encuestas ayudan a mejorar nuestros servicios.',
    'Ref: Tabtitle': 'Encuesta completada',
    'Take another survey': 'Haga otra encuesta',
  },
  Survey: {
    'Ref: Page title': 'Detalles de la encuesta',
    'Please answer all mandatory questions as identify by an asterisk':
      'Responda todas las preguntas obligatorias identificadas con un asterisco: *',
    'Ref: Take another survey': 'Haga otra encuesta',
    'Ref: Go to Home': 'Ir a la página de inicio',
  },
  SurveysList: {
    'Ref: Page title': 'Encuesta',
    'Ref: Available until': 'Disponible hasta',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Actualmente está viendo encuestas para el centro {site name}.',
  },
};
export default labels;
