import { Switch, Route } from 'react-router-dom';

import SiteDetails from '../components/SiteDetails';
import SitesList from '../components/SitesList';
import { pagePaths } from '../config';

const Sites = () => {
  return (
    <div>
      <Switch>
        <Route exact path={pagePaths.Module} render={() => <SitesList />} />
        <Route exact path={pagePaths.SiteDetails} render={() => <SiteDetails />} />
      </Switch>
    </div>
  );
};

export default Sites;
