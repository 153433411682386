const labels = {
  ContentDetails: {
    'Ref: Page title': 'פרטי תוכן',
    'See the event': 'ראה את האירוע',
    'Take the survey': 'ענה על הסקר',
    Date: 'תַאֲרִיך',
    Category: 'קטגוריה',
    'Ref: Content site different title': 'תוכן זה אינו שייך לאתר הנוכחי שלך',
    'Ref: Content site different description':
      'אתה צופה בתוכן שאינו שייך לאתר הנוכחי שלך. לא תוכל לראות את זה ברשימת התוכן.',
  },
  ContentList: {
    'Ref: Page title': 'הזנת חדשות',
    'featured (as in featured content)': 'מומלצים',
    'Ref: category - all': 'את כל',
    'Ref: category - promotional': 'קידום מכירות',
    'Ref: category - featured': 'מומלצים',
    'Ref: category - other': 'אַחֵר',
  },
  Widget: {
    'Ref: Carousel title': 'מאמרי חדשות',
    'Ref: Carousel title promo': 'חדשות מובילות',
    'No info yet, come back later': 'אין עדיין מידע, חזור אחר כך 😉',
    'Ref: Content': 'תוֹכֶן',
    'Ref: Widget Title': 'הזנת חדשות',
    'Ref: MyVillage Carousel title promo': 'מבצעים',
  },
};
export default labels;
