const labels = {
  All: {
    dailyMenu: '日替わりメニュー',
    anytime: 'いつでも',
    breakfast: '朝食',
    morningBreak: '朝休憩',
    lunch: '昼食',
    afternoonBreak: '午後休憩',
    dinner: '夕食',
    supper: '晩餐',
    nightMeal: '夜食',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'メニュー',
    'Ref: View details': '詳細を見る',
    'Ref: Facility details': '施設詳細',
  },
  OpeningHours: { everyday: '毎日', 'open all day': '終日営業' },
  ProductsList: {
    'Ref: Page title': 'メニュー',
    'Ref: Facility details': '施設詳細',
    'Ref: Search products list': 'アイテムを検索',
    'Ref: filter label: date': '日時',
    'Ref: filter label: menus': 'メニュー',
    'Ref: filter label: moment': '時刻',
    'Ref: filter label: category': 'カテゴリー',
    'Ref: filter label: dishes': '商品',
    'Ref: filter label: highlight': '商品でフィルターを強調表示または非表示にします',
    All: '全て',
  },
  ProductDetails: { 'Ref: Page title': '詳細' },
  Widget: {
    'Ref: Carousel title': 'メニュー',
    'No info yet, come back later': 'まだ情報がありません。時間を空けてお試しください',
    'Ref: menus': 'メニュー',
    'Ref: see all': 'すべてを見る',
  },
};
export default labels;
