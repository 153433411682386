import classNames from 'classnames';

import Title, { TITLE_SIZE, TITLE_TAG } from '../Title';

import styles from './Filters.module.css';

import { TestingPropsV2 } from 'src/types';

const FilterWrapper = ({
  isInModal,
  children,
  value,
  withLabelValue,
  name = '',
  text = '',
  isButton = false,
  className,
  withTitle = true,
  'data-testid': testId,
}: {
  isInModal?: boolean;
  name?: string;
  children: React.ReactNode;
  value?: string;
  withLabelValue?: boolean;
  text?: string;
  isButton?: boolean;
  className?: any;
  withTitle?: boolean;
} & TestingPropsV2) => {
  return (
    <div
      className={classNames(styles.filterWrapper, className, { [styles.reversed]: isButton })}
      data-cy="filter-container"
      data-testid={`${testId}-filter-container`}
    >
      {withTitle && (withLabelValue || name) ? (
        <Title
          tag={TITLE_TAG.H2}
          size={TITLE_SIZE.BODYSBOLD}
          className={styles.filterTitle}
          data-testid={`${testId}-title`}
        >{`${name}${withLabelValue ? `: ${value}` : ''}`}</Title>
      ) : null}
      {text ? <div className="mb-L">{text}</div> : null}
      <div className={styles.filterContent}>{children}</div>
    </div>
  );
};

export default FilterWrapper;
