import WidgetBody from './Body';
import WidgetFooter from './Footer';
import WidgetHeader from './Header';
import WidgetLink from './Link';
import WidgetTitle from './Title';
import Widget from './Widget';

export type { WidgetHeaderProps, WidgetLinkProps } from './Widget.types';

export default Object.assign(Widget, {
  Header: WidgetHeader,
  Footer: WidgetFooter,
  Body: WidgetBody,
  Title: WidgetTitle,
  Link: WidgetLink,
});
