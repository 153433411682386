const labels = {
  TimeTable: {
    'leave at': 'Verlassen um',
    'arrive at': 'Ankommen bei',
    at: 'bei',
    'Ref: list placeholder': 'Füllen Sie das Formular aus, um die Stunden zu sehen',
    'Ref: Page title': 'Busse',
    'Ref: Departure': 'Abfahrt',
    'Ref: Arrivals': 'Ankunft',
    'Ref: minutes': 'Protokoll',
    'Ref: no items placeholder': 'Entschuldigung, derzeit sind keine Busse verfügbar.',
  },
};
export default labels;
