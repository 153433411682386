import { IonCard } from '@ionic/react';

import { Skeleton } from '@/components/atoms/Skeleton';

import styles from './LocateSkeleton.module.css';

const SkeletonServiceRequestItem = () => {
  return (
    <IonCard
      className={styles.card}
      placeholder={undefined}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <div className={styles.leftArea}>
        <Skeleton className={styles.dummyContent} width="50%" />
        <Skeleton className={styles.dummyContent} width="20%" />
      </div>
      <div className={styles.rightArea}>
        <Skeleton className={styles.button} width="50%" />
      </div>
    </IonCard>
  );
};

export default SkeletonServiceRequestItem;
