import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';

import { axiosBaseQuery } from '../../../../axios/axiosBaseQuery';
import { NOTIFICATIONS_PAGESIZE } from '../../../../constants';
import { getNotificationsConfig } from '../../apiConfig';
import { getCoreConfigs } from '../../config';
import mockResponse from '../../mocks/notificationsApi';

import {
  CloseNotificationArgs,
  GetNotificationsArgs,
  GetNotificationsResponse,
  Notification,
} from './notificationsApi.types';

const { api, baseUrl, getListBaseUrl, closeNotificationBaseUrl, closeNotificationParam } =
  getNotificationsConfig();

const notificationsApi = api.enhanceEndpoints({ addTagTypes: ['notifications'] }).injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<GetNotificationsResponse, GetNotificationsArgs>({
      queryFn: async ({
        startDateTimeUtc = new Date(),
        pageIndex,
        pageSize = NOTIFICATIONS_PAGESIZE,
      }: GetNotificationsArgs) => {
        const { isResponsePaginated } = getCoreConfigs();
        const baseQuery = axiosBaseQuery({ baseUrl: baseUrl });

        const response = await baseQuery({
          url: getListBaseUrl,
          method: 'get',
          params: { startDateTimeUtc, pageIndex, pageSize },
          mockData: mockResponse.getNotifications,
          opts: {
            useErrorBoundary: false,
          },
        });

        const rawData = isResponsePaginated ? response.data.data : response.data;

        const notifications = rawData.map((notif: Notification) => {
          //todo standardize: add object id and resolution url when relevant
          if (notif.serviceId) {
            switch (notif.serviceId) {
              case 'Content':
                notif.objectId = notif.contentId;
                notif.linkedEntity = 'Content';
                break;
              case 'Event':
              case 'Events':
                notif.objectId = notif.eventId;
                notif.linkedEntity = 'Event';
                break;
              case 'Survey':
              case 'Surveys':
                notif.objectId = notif.surveyId;
                notif.linkedEntity = 'Survey';
                break;
              default:
                notif.objectId = undefined;
            }
          }
          return notif;
        });

        const notificationsRes = isResponsePaginated
          ? {
              items: notifications,
              ...response.data,
            }
          : {
              items: response.data,
              pageIndex: 0,
              pageSize: response.data?.length ?? 0,
              total: response.data?.length ?? 0,
              unreadNotificationCount: response.data?.length ?? 0,
            };

        return { data: notificationsRes };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        if (newItems.pageIndex === 0 && currentCache.items.length) {
          newItems.items.forEach((n: Notification) => {
            const itemExists = currentCache.items.find((i: Notification) => i.id === n.id);
            if (!itemExists?.id) {
              currentCache.items.unshift(n);
            }
          });
          currentCache.unreadNotificationCount = newItems.unreadNotificationCount;
        } else {
          currentCache.items.push(...newItems.items);
          currentCache.unreadNotificationCount = newItems.unreadNotificationCount;
          currentCache.total = newItems.total;
          currentCache.pageSize = newItems.pageSize;
          currentCache.pageIndex = newItems.pageIndex;
        }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result) => [{ type: 'notifications', pageIndex: result?.pageIndex }],
    }),
    closeNotification: build.mutation<{}, CloseNotificationArgs>({
      query: ({ notificationId }) => ({
        url: closeNotificationBaseUrl(notificationId),
        method: 'post',
        data: closeNotificationParam(notificationId),
      }),
      async onQueryStarted({ notificationId, ...patch }, { dispatch, queryFulfilled, getState }) {
        const listPatches = [];
        for (const { endpointName, originalArgs } of notificationsApi.util.selectInvalidatedBy(
          getState() as RootState<any, any, 'dspApi' | 'api'>,
          [{ type: 'notifications' }]
        )) {
          if (endpointName !== 'getNotifications') continue;

          listPatches.push(
            dispatch(
              notificationsApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
                const items = draft.items.map((notification: Notification) => {
                  return notification.id === notificationId
                    ? {
                        ...notification,
                        acknowledged: true,
                        acknowledgedOn: new Date().toISOString(),
                      }
                    : notification;
                });

                return {
                  ...draft,
                  items,
                  unreadNotificationCount:
                    draft.unreadNotificationCount > 0 ? draft.unreadNotificationCount - 1 : 0,
                };
              })
            )
          );
          try {
            await queryFulfilled;
          } catch {
            listPatches.forEach((patch) => queryFulfilled.catch(patch.undo));
          }
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetNotificationsQuery,
  useCloseNotificationMutation,
  useLazyGetNotificationsQuery,
} = notificationsApi;
export default notificationsApi;
