const labels = {
  Success: {
    'Ref: Body': 'תודה שהשלמת סקר זה, זמןך חשוב כי סקרים אלה עוזרים בשיפור השירותים שלנו.',
    'Ref: Tabtitle': 'הסקר הושלם',
    'Take another survey': 'קח סקר נוסף',
  },
  Survey: {
    'Ref: Page title': 'פרטי הסקר',
    'Please answer all mandatory questions as identify by an asterisk':
      'אנא ענה על כל השאלות המנדטוריות כזהות על ידי כוכבית: *',
    'Ref: Take another survey': 'קח סקר נוסף',
    'Ref: Go to Home': 'לך הביתה',
  },
  SurveysList: {
    'Ref: Page title': 'סקר',
    'Ref: Available until': 'זמין עד',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'כעת אתה צופה בסקרים עבור האתר {site name}.',
  },
};
export default labels;
