import classNames from 'classnames';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import RatingStar from '../../../components/atoms/RatingStar/RatingStar';
import Textarea from '../../../components/atoms/Textarea/Textarea';
import Title, { TITLE_TAG } from '../../../components/atoms/Title';
import Card from '../../../components/molecules/Card/Card';
import List from '../../../components/organisms/List/List';
import { CONTROL_TYPE } from '../../../components/organisms/List/List.types';
import { QUESTION_TYPES } from '../config';
import { shortAnswerMaxLengh, longAnswerMaxLengh } from '../config';
import {
  Answer,
  ResponseMultipleValue,
  ResponseSingleValue,
  ResponseValue,
} from '../types/survey.types';

import styles from './SurveyQuestion.module.css';

const QuestionTitle = ({ questionText, required }: { questionText: string; required: boolean }) => {
  return (
    <Title tag={TITLE_TAG.H2} className={classNames('mb-S')}>
      {questionText}
      {required ? ' *' : false}
    </Title>
  );
};

const ResponseHolder = ({
  id,
  required,
  type,
  answers,
  response,
  setResponse,
  questionText,
  disabled,
}: {
  id: string;
  required: boolean;
  type: number;
  answers: Answer[];
  response?: ResponseValue;
  setResponse: (id: string, value: ResponseMultipleValue[] | ResponseSingleValue) => void;
  questionText: string;
  disabled?: boolean;
}) => {
  const inputName = questionText || `question-${uuidv4()}`;
  const questionTitleProps = { questionText, required };
  switch (type) {
    case QUESTION_TYPES.SHORT_ANSWER: {
      const value = typeof response === 'string' ? response : '';
      return (
        <Textarea
          id={id}
          label={questionText}
          onChange={(e) => setResponse(id, e.detail?.value || '')}
          value={value}
          required={required}
          maxlength={shortAnswerMaxLengh}
        />
      );
    }
    case QUESTION_TYPES.LONG_ANSWER: {
      const value = typeof response === 'string' ? response : '';
      return (
        <Textarea
          id={id}
          label={questionText}
          onChange={(e) => setResponse(id, e.detail?.value || '')}
          value={value}
          required={required}
          maxlength={longAnswerMaxLengh}
        />
      );
    }
    case QUESTION_TYPES.SINGLE_CHOICE:
      return (
        <>
          <QuestionTitle {...questionTitleProps} />
          <List
            data-testid="survey-answer-list"
            items={answers.map((answer) => ({
              id: answer.id,
              label: answer.name,
              'data-testid': `survey-answer-${answer.id}`,
              disabled: disabled,
              control: {
                type: CONTROL_TYPE.RADIO,
                props: {
                  checked: response === answer.id,
                  disabled: disabled,
                  value: answer.name,
                  name: inputName,
                  'data-testid': `answer-${answer.id}-radio-button`,
                },
              },
            }))}
            onChange={(selectedItems) => setResponse(id, selectedItems[0]?.id)}
          />
        </>
      );
    case QUESTION_TYPES.MULTIPLE_CHOICES: {
      return (
        <>
          <QuestionTitle {...questionTitleProps} />
          <List
            data-testid="survey-answer-list"
            items={answers.map((answer) => ({
              id: answer.id,
              label: answer.name,
              disabled: disabled,
              'data-testid': `survey-answer-${answer.id}`,
              control: {
                type: CONTROL_TYPE.CHECKBOX,
                props: {
                  checked: typeof response === 'object' ? response?.[answer.id] : false,
                  disabled: disabled,
                  value: answer.name,
                  name: inputName,
                  'data-testid': `answer-${answer.id}-checkbox`,
                },
              },
            }))}
            onChange={(selectedItems) => setResponse(id, selectedItems)}
          />
        </>
      );
    }
    case QUESTION_TYPES.RATING:
      return (
        <>
          <QuestionTitle {...questionTitleProps} />
          <RatingStar
            data-testid={`survey-question-star-rating`}
            groupName={id}
            value={Number(response)}
            onChange={(value) => setResponse(id, String(value))}
            starsNum={5}
          />
        </>
      );
    default:
      return <React.Fragment />;
  }
};

const SurveyQuestion = ({
  id,
  questionText,
  required = false,
  helpText,
  type,
  answers,
  response,
  setResponse,
}: {
  id: string;
  questionText: string;
  required?: boolean;
  helpText?: string;
  type: number;
  answers: Answer[];
  response?: ResponseValue;
  setResponse: (id: string, value: ResponseMultipleValue[] | ResponseSingleValue) => void;
}) => (
  <div className={classNames(styles.cardContainer)}>
    <Card>
      <ResponseHolder
        id={id}
        type={type}
        required={required}
        answers={answers}
        response={response}
        setResponse={setResponse}
        questionText={questionText}
      />
      <div>{helpText}</div>
    </Card>
  </div>
);
export default SurveyQuestion;
