import { screen, cleanup, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import ItineraryFields from '../ItineraryFields';

jest.mock('react-select', () => ({ 'data-testid': dataTestId, options, value, onChange }: any) => {
  function handleChange(event: any) {
    const option = options.find((option: any) => option.value === event.currentTarget.value);
    onChange(option);
  }
  return (
    <select
      id={dataTestId}
      name={dataTestId}
      data-testid={dataTestId}
      value={value || ''}
      onChange={handleChange}
    >
      {options.map(({ label, value }: any) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
});

const origins = [
  {
    id: '83130d17-5cba-e911-a85f-000d3a6a4119',
    name: 'Paraburdoo Mine MEM Admin G Shed Plant',
  },
  {
    id: 'e7322c32-057f-e911-a858-000d3a6a4164',
    name: 'Karratha Town Mine 6 Mile 7 Mile 8 Mile',
  },
  {
    id: '509c5458-077f-e911-a858-000d3a6a4164',
    name: 'Karratha Town Mine 8 Mile 7 Mile 6 Mile',
  },
  { id: 'd568c7ca-d6b4-e911-a85f-000d3a6a4164', name: 'NBWT Lab & Eng' },
  { id: '8c532e0d-d7b4-e911-a85f-000d3a6a4164', name: 'B2 Mine Warehouse' },
];

const destinations = [
  { id: '2a368326-1ad7-e611-811e-c4346bc56230', name: 'Searipple Village Bus Stop' },
  { id: 'b0562f1f-709f-ec11-b400-00224892e71e', name: 'Peninsula Palms Bus Stop' },
];

// API hooks mocks
jest.mock('../../../api', () => ({
  useGetOriginsQuery: () => ({
    data: origins,
  }),
  useGetDestinationsQuery: () => ({ data: destinations }),
}));

const defaultProps = {
  label: (s: string) => s,
};

describe('ItineraryFields', () => {
  afterAll(() => cleanup());

  describe('Initial render', () => {
    let originsSelect: HTMLElement;
    let destinationsSelect: HTMLElement;

    renderComponent(ItineraryFields, defaultProps);

    beforeEach(() => {
      originsSelect = screen.getByTestId('origins-select');
      destinationsSelect = screen.getByTestId('destinations-select');
    });

    it('Should have 2 select inputs', async () => {
      expect(originsSelect).toBeTruthy();
      expect(destinationsSelect).toBeTruthy();
    });
  });

  describe('Changing inputs', () => {
    let originsSelect: HTMLElement;
    let destinationsSelect: HTMLElement;

    const setSelectedOrigin = jest.fn();
    const setSelectedDestination = jest.fn();

    renderComponent(ItineraryFields, {
      ...defaultProps,
      setSelectedOrigin,
      setSelectedDestination,
    });

    it('Should set selected values', async () => {
      originsSelect = screen.getByTestId('origins-select');
      destinationsSelect = screen.getByTestId('destinations-select');

      await act(async () => {
        await userEvent.selectOptions(originsSelect, [origins[2].id]);
        await userEvent.selectOptions(destinationsSelect, [destinations[1].id]);
      });

      expect(setSelectedOrigin).toHaveBeenCalledWith({
        value: origins[2].id,
        label: origins[2].name,
      });
      expect(setSelectedDestination).toHaveBeenCalledWith({
        value: destinations[1].id,
        label: destinations[1].name,
      });
    });
  });
});
