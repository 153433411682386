import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import NoticePage from '..//NoticePage/NoticePage';
import { NOTICEPAGE_TYPE } from '../NoticePage/NoticePage.types';

export default function DeprecatedModulePage() {
  const history = useHistory();

  const { label } = useCustomTranslation();

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.ERROR}
      title={label('This module has been retired')}
      actions={[
        {
          label: label('Go to home', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
      ]}
    />
  );
}
