import StepperComponent from './Stepper';
import { StepperContainerProps } from './Stepper.types';
import { StepperConsumer } from './StepperContext';

const Stepper = ({ horizontalOnly = false }: StepperContainerProps) => {
  return (
    <StepperConsumer>
      {(steps) => <StepperComponent steps={steps} horizontalOnly={horizontalOnly} />}
    </StepperConsumer>
  );
};

export default Stepper;
