import { useHistory } from 'react-router';

import { useNavigationHistory } from '../../../context/NavigationHistory';

import { BackButtonProps } from './BackButton.types';

import { ArrowLeftIcon } from '@/assets/icons';

import './BackButton.css';

const BackButton = ({ 'data-testid': testId, customPath, onBeforeBack }: BackButtonProps) => {
  const { lastPath } = useNavigationHistory();

  const history = useHistory();

  const specificPath = customPath || lastPath;

  const goBack = () => {
    onBeforeBack?.();

    if (specificPath) {
      history.push(specificPath, { fromBackButton: true });
      return;
    }

    history.goBack();
  };

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => e.key === 'Enter' && goBack()}
      onClick={goBack}
      role="button"
      id="BackButton"
      data-testid={testId}
    >
      <ArrowLeftIcon name="chevron-back" data-cy="back-button" />
      <span className="sr-only">Return to the previous page</span>
    </div>
  );
};

export default BackButton;
