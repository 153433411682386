import { FC } from 'react';

import { DefaultComponentProps } from '../../../../types';

import styles from './Widgets.module.css';

const Widgets: FC<DefaultComponentProps> = ({
  children,
  'data-testid': dataTestId = 'titlebar-widgets',
}) => {
  return (
    <div
      slot="end"
      className={styles.TitleBarRight}
      data-cy="title-bar-right"
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
};
Widgets.displayName = 'Widgets';

export default Widgets;
