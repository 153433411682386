const labels = {
  Success: {
    'Ref: Body': 'Ref: Body',
    'Ref: Tabtitle': 'Ref: Tabtitle',
    'Take another survey': 'Take another survey',
  },
  Survey: {
    'Ref: Page title': 'Ref: Page title',
    'Please answer all mandatory questions as identify by an asterisk':
      'Please answer all mandatory questions as identify by an asterisk',
    'Ref: Take another survey': 'Ref: Take another survey',
    'Ref: Go to Home': 'Ref: Go to Home',
  },
  SurveysList: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Available until': 'Ref: Available until',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Ref: You are currently viewing surveys for the site {site name}.',
  },
};
export default labels;
