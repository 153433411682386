const labels = {
  All: {
    'Ref: See item': 'Siehe Artikel',
    'Ref: Print': 'Drucken Sie Ihre Quittung aus',
    'Ref: Email': 'Erhalten Sie Ihre Quittung per E-Mail',
    'Ref: Receipt Modal message': 'Wie möchten Sie Ihre Quittung erhalten?',
    'Ref: Loyalty Reward': 'Treuebonus',
    'Ref: Equation for stamps per item': '1 Kauf = 1 Stempel',
    'Ref: Paid by employer': 'Der von Ihrem Arbeitgeber gezahlte Betrag',
    'Ref: Paid by user': 'Der von Ihnen gezahlte Betrag',
  },
  ProductsList: {
    'Ref: Page title': 'Produktliste',
    'Ref: Facility details': 'Anlagendetails',
    'Ref: Search products list': 'Suchteile',
    'Ref: filter label: menus': 'Speisekarte',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Kategorie',
    'Ref: all': 'Alle',
    'Ref: today': 'Heute',
    'Ref: tomorrow': 'Morgen',
    'Ref: see more': 'Mehr sehen',
    'Ref: see less': 'Weniger sehen',
    'Ref: reset': 'Zurücksetzen',
    'Ref: View in cart': 'Blick im Waren',
    'Ref: You have changed location': 'Sie haben den Standort geändert',
    'Ref: You are now at': 'Sie sind jetzt bei',
    'Ref: Menu card': 'Speisekarte',
    'Ref: Table': 'Tisch Nummer',
    'Service unavailable': 'Dienst nicht verfügbar',
    'Ref: Ordering unavailable':
      'Vorsichtig! Die Bestellung an dieser Website ist momentan nicht verfügbar, versuchen Sie es später.',
    'Ref: Filters': 'Filter (Allergene und mehr)',
  },
  Order: { 'Ref: Page title': 'Befehl' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menüs',
    'Ref: today': 'Heute',
    'Ref: tomorrow': 'Morgen',
    'Ref: Search products list': 'Suchteile',
    'Ref: You have changed location': 'Sie haben den Standort geändert',
    'Ref: You are now at': 'Sie sind jetzt bei',
  },
  ProductDetails: {
    'Ref: Page title': 'Produktdetails',
    'Ref: Serving Size': 'Serviergröße',
    'Ref: Nutrition': 'Nährwert-Information',
    'Ref: Nutrition adults': 'Erwachsene brauchen rund 2000 kcal pro Tag',
    'Ref: Nutrition Calories': 'Kalorien',
    'Ref: Nutrition Calcium': 'Kalzium',
    'Ref: Nutrition Fat': 'Fett',
    'Ref: Nutrition Saturated Fat': 'Gesättigte Fettsäuren',
    'Ref: Nutrition Carbohydrates': 'Kohlenhydrate',
    'Ref: Nutrition Sugar': 'Zucker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Cholesterin',
    'Ref: Nutrition dietaryFiber': 'Ernährungsfaser',
    'Ref: Nutrition iron': 'Eisen',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Additives': 'Additive',
    'Ref: Emission': 'CO2-Emissionen',
    'Ref: This product generates': 'Dieses Produkt erzeugt',
    'Ref: of CO2': 'von CO2',
    'Ref: emissions, which equals a': 'Emissionen, was a',
    'Ref: car trip': 'Autoreise',
    'Ref: Ingredients': 'Rezeptzutat',
    'Ref: Allergens': 'Allergenen',
    'Ref: Always there': 'Immer da',
    'Ref: Allergens selected': 'Allergene, die auf dem Filter ausgewählt wurden:',
    'Ref: minimum required': 'Minimum erforderlich',
    'Ref: you have to add at least': 'Sie müssen zumindest hinzufügen',
    'Ref: ingredients': 'Zutaten',
    'Ref: Ingredient': 'Zutat',
    'Ref: Free': 'Frei',
    'Ref: Based on customization': 'Basierend auf Ihrer Anpassung',
    'Ref: Additives eggs': 'Eier',
    'Ref: Additives fish': 'Fisch',
    'Ref: Additives milk': 'Milch',
    'Ref: Additives nuts': 'Nüsse',
    'Ref: Additives wheat': 'Weizen',
    'Ref: Additives peanuts': 'Erdnüsse',
    'Ref: Additives soy beans': 'Sojabohnen',
    'Ref: Additives sesame seeds': 'Sesamsamen',
    'Ref: Review': 'Überprüfung',
    'Ref: Per portion': 'Pro Teil',
    'Ref: Per 100g': 'Pro 100g',
    'Ref: Allergen selected': 'Allergen im Suchfilter ausgewählt',
    'Ref: Fitbit Log': 'Protokoll bei Fitbit',
    'Ref: age verification required on delivery':
      'Altersüberprüfung bei der Lieferung erforderlich',
    'Ref: alcohol content notification':
      'Wenn Sie eines dieser Artikel platzieren und bestellen, erklären Sie, dass Sie 18 Jahre alt sind. Alkoholmissbrauch ist gefährlich für Ihre Gesundheit.',
    'Ref: default': 'Standard',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} verdient, wenn Sie dieses Produkt bestellen',
    'Ref: Loyalty Card Description':
      'Die {stamp_form} wird der folgenden Loyalität hinzugefügt {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Mit dem Programm für Loyalitätsmarken können Sie Briefmarken für Belohnungen sammeln.',
    'Ref: Loyalty Modal Description':
      'Wenn Sie <b> {product_name} kaufen, wird 1 Stempel </b> zum <b> {scheme_names} </b> {program_form} hinzugefügt',
    'Ref: and': 'Und',
    'Ref: Stamp': 'Briefmarke',
    'Ref: Stamps': 'Briefmarken',
    'Ref: Program': 'Programm',
    'Ref: Programs': 'Programme',
    'Ref: Loyalty Stamps': 'Loyalitätsmarken',
    'Ref: See Loyalty Stamps': 'Siehe Loyalitätsmarken',
    Favorite: 'Favorit',
    'Ref: In favorites':
      'Das Produkt befindet sich bereits in der Favoritenliste. Klicken Sie hier, um es zu entfernen.',
    'Ref: Not in favorites':
      'Das Produkt befindet sich noch nicht in der Favoritenliste. Klicken Sie hier, um es hinzuzufügen.',
    'Ref: Added to favorites': 'Das Produkt wurde in die Favoritenliste hinzugefügt.',
    'Ref: Removed from favorites': 'Das Produkt wurde aus der Favoritenliste entfernt.',
    'Ref: From': 'Aus',
  },
  OrderHistory: { 'Ref: Orders': 'Aufträge', 'Ref: Page Title': 'Meine Bestellungen' },
  MenuTile: { 'Ref: Scan&Go': 'Scannen und gehen', 'Ref: View only': 'Nur anschauen' },
  ProductTile: {
    'Ref: Allergens': 'Allergene',
    'Ref: Presence of allergen': 'Vorhandensein von Allergen',
    'Ref: Scan': 'Scan',
    'Ref: From': 'Aus',
    'Ref: Already in product favorite list. Click to remove it.':
      'Bereits in Produktfavoritenliste. Klicken Sie hier, um es zu entfernen.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Derzeit nicht zur Produktfavoritenliste hinzugefügt. Klicken Sie hier, um es hinzuzufügen.',
    'Ref: Free': 'Frei',
  },
  MenusWidget: {
    'Ref: no menu': 'Kein Menü für den Moment',
    'Ref: Modal title': 'Menü für den Tag',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Aufsammeln',
    'Ref: Eat-in': 'Essen',
    'Ref: Scan&Go': 'Scannen und gehen',
    'Ref: Delivery': 'Lieferung',
    'Ref: Total': 'Gesamt',
    'Ref: How was facility name service': 'Wie war {facility_name} ’s Service?',
    'Ref: How do you rate the service': 'Wie bewerten Sie den Service?',
  },
};
export default labels;
