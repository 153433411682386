import { cleanup, renderHook } from '@testing-library/react';

import { APP_DISPLAY_NAME } from '../../../../constants';
import useTrackContentViews from '../trackContentViews.helper';

const mockTrackContentViewsData = {
  title: 'Mock Content title',
  site: {
    id: '1111111',
    name: 'Test Site',
  },
  contract: {
    id: '36472822',
    name: 'Content Contract name',
  },
  language: 'en-GB',
  moduleId: 'Content',
};

const expectedTrackSiteVisitData = {
  properties: {
    siteId: mockTrackContentViewsData.site.id,
    siteName: mockTrackContentViewsData.site.name,
    contentTitle: mockTrackContentViewsData.title,
    contractId: mockTrackContentViewsData.contract.id,
    contractName: mockTrackContentViewsData.contract.name,
    language: mockTrackContentViewsData.language,
    appName: APP_DISPLAY_NAME.MYWAY,
    appPackage: 'com.sodexo.mywaycanary',
    appVersion: '11.1.0',
    module: mockTrackContentViewsData.moduleId,
  },
};

jest.mock('../../config', () => {
  return {
    serviceTypes: {
      Content: {
        path: '/content',
        defaultModule: 'Content',
        label: 'Content',
      },
    },
  };
});

const mockTrackPageView = jest.fn((args) => args);
jest.mock('@microsoft/applicationinsights-react-js', () => ({
  useAppInsightsContext: () => ({ trackPageView: mockTrackPageView }),
}));

jest.mock('react-router', () => {
  return {
    useLocation: () => ({
      pathname: '/content',
    }),
  };
});

describe('useTrackContentViews hook', () => {
  const env = global.process.env;

  afterAll(() => cleanup());

  describe('useTrackSiteVisit hook', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY,
        REACT_APP_MOBILE_PACKAGE_NAME: 'com.sodexo.mywaycanary',
        REACT_APP_BUILD_NUMBER: '11.1.0',
      };
    });

    it('should return result via function trackPageView', () => {
      renderHook(() => useTrackContentViews(mockTrackContentViewsData));

      expect(mockTrackPageView).toHaveBeenCalledWith(expectedTrackSiteVisitData);
    });
  });
});
