const labels = {
  All: {
    dailyMenu: 'Ежедневное меню',
    anytime: 'Любое время',
    breakfast: 'Завтрак',
    morningBreak: 'утренний перерыв',
    lunch: 'Обед',
    afternoonBreak: 'Последовый перерыв',
    dinner: 'Ужин',
    supper: 'Ужин',
    nightMeal: 'Ночная еда',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Меню',
    'Ref: View details': 'Посмотреть детали',
    'Ref: Facility details': 'Подробнее Facility',
  },
  OpeningHours: { everyday: 'ежедневно', 'open all day': 'Открыто весь день' },
  ProductsList: {
    'Ref: Page title': 'Меню',
    'Ref: Facility details': 'Подробнее Facility',
    'Ref: Search products list': 'Поиск предметов',
    'Ref: filter label: date': 'Свидание',
    'Ref: filter label: menus': 'Меню',
    'Ref: filter label: moment': 'Момент',
    'Ref: filter label: category': 'Категория',
    'Ref: filter label: dishes': 'Тарелки',
    'Ref: filter label: highlight': 'Выделите или скрыть фильтры с посудой',
    All: 'Все',
  },
  ProductDetails: { 'Ref: Page title': 'информация о продукте' },
  Widget: {
    'Ref: Carousel title': 'Меню',
    'No info yet, come back later': 'Пока нет информации, вернитесь позже 😉',
    'Ref: menus': 'Меню',
    'Ref: see all': 'Увидеть все',
  },
};
export default labels;
