import { IonModal } from '@ionic/react';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ChevronLeftIcon, CrossIcon } from '../../../assets/icons';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Title, { TITLE_SIZE, TITLE_TAG } from '../../atoms/Title';

import ColumnLayout from './ColumnLayout';
import { ModalProps } from './Modal.types';
import RowLayout from './RowLayout';

import styles from './Modal.module.css';

const Modal = ({
  children,
  title,
  leftButton,
  leftButtonGoBack,
  id,
  isOpen,
  footer,
  contentClassName,
  onDismiss,
  backdropDismiss = true,
  crossButtonDismiss = true,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}: ModalProps) => {
  const { label } = useCustomTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isOpen);

  const titleId = `${id}-title`;
  const titleIdLabel = `${titleId}-label`;
  const titleRef = useRef<HTMLHeadingElement | null>(null);

  // must be done as workaround because of ionic blocks escape when backdropDismiss = false
  // https://github.com/ionic-team/ionic-framework/issues/24794
  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isModalOpen) {
        setIsModalOpen(false);
      }
    },
    [isModalOpen]
  );

  useEffect(() => {
    if (backdropDismiss === false) document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [backdropDismiss, handleEscapeKey]);

  useEffect(() => {
    setTimeout(() => {
      titleRef.current?.focus();
    }, 1000); // workaround until migrated to ionic 6 https://ionic.io/docs/accessibility/ion-modal (https://azeuvs1aze987.visualstudio.com/IFM/_backlogs/backlog/DSP%20Cross%20Teams/Epics/?showParents=true&workitem=58609)
    setIsModalOpen(isOpen);
  }, [titleRef, isOpen]);

  return (
    <IonModal
      id={id}
      isOpen={isModalOpen}
      cssClass={'modal'}
      onDidDismiss={() => {
        onDismiss && onDismiss();
      }}
      data-cy={dataCy}
      data-testid={dataTestId}
      backdropDismiss={backdropDismiss}
      onDidPresent={() => {
        const modalElement: HTMLElement | null = document.querySelector(
          `#${id} div[role="dialog"]`
        );
        if (modalElement) {
          modalElement.setAttribute('aria-describedby', titleIdLabel);
          modalElement.setAttribute('aria-modal', 'true');
        }
      }}
    >
      <Title
        tag={TITLE_TAG.H1}
        size={TITLE_SIZE.HEADLINEXS}
        className={classNames(styles.title, { [styles.titleEmpty]: !title })}
        id={titleId}
      >
        {title}
      </Title>
      <div id={titleIdLabel} ref={titleRef} tabIndex={-1} className="sr-only">
        {title}
      </div>
      {leftButton && <button className={classNames(styles.leftButton)}>{leftButton}</button>}
      {leftButtonGoBack && (
        <button
          className={classNames(styles.leftButtonGoBack)}
          onClick={() => setIsModalOpen(false)}
          aria-label={label('go back button')}
        >
          {leftButtonGoBack && <ChevronLeftIcon />}
        </button>
      )}
      <button
        className={classNames(styles.modalClose)}
        onClick={() => setIsModalOpen(false)}
        aria-label={label('close')}
        data-testid={`${dataTestId}-button-close`}
      >
        {crossButtonDismiss && <CrossIcon />}
      </button>
      <div className={classNames(styles.content, contentClassName)}>{children}</div>
      {footer && <div className={classNames(styles.footer)}>{footer}</div>}
    </IonModal>
  );
};

export default Object.assign(Modal, {
  ColumnLayout: ColumnLayout,
  RowLayout: RowLayout,
});
