import moment from 'moment';

import { getLegalDocsConfig } from '../../apiConfig';
import mockResponse from '../../mocks/legalDocumentsApi';

import {
  GetAcknowledgementsSuccessResponse,
  GetAllLegalDocumentsSuccessResponse,
  GetOneLegalDocumentSuccessResponse,
} from './legalDocumentsApi.types';

const { apiVersion, api } = getLegalDocsConfig();

const legalDocsApi = api
  .enhanceEndpoints({ addTagTypes: ['legalDocs', 'acknowledgments'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getAcknowledgements: build.query<GetAcknowledgementsSuccessResponse, {}>({
        query: () => ({
          url: `/${apiVersion}/legaldocuments/acknowledgements`,
          method: 'get',
          mockData: mockResponse.getAcknowledgements,
        }),
        providesTags: ['acknowledgments'],
      }),
      getAllLegalDocuments: build.query<
        GetAllLegalDocumentsSuccessResponse,
        { geoCode: string; languageCode: string }
      >({
        query: (params) => ({
          url: `/${apiVersion}/legaldocuments`,
          method: 'get',
          mockData: mockResponse.getAllLegalDocuments,
          params,
        }),
        providesTags: ['legalDocs'],
        keepUnusedDataFor: 5,
      }),
      getOneLegalDocument: build.query<GetOneLegalDocumentSuccessResponse, { id: string }>({
        query: ({ id }) => ({
          url: `/${apiVersion}/legaldocuments/${id}`,
          method: 'get',
          mockData: mockResponse.getOneLegalDocument,
        }),
        providesTags: ['legalDocs'],
      }),
      getPublicLegalDocument: build.query<
        GetOneLegalDocumentSuccessResponse,
        { id: string; geoCode: string }
      >({
        query: ({ id, geoCode }) => ({
          url: `/${apiVersion}/publicdocuments/${id}`,
          method: 'get',
          mockData: mockResponse.getOneLegalDocument,
          params: { geoCode },
        }),
        providesTags: ['legalDocs'],
      }),
      acknowledgeLegalDoc: build.mutation<{}, { documentId: string }>({
        query: ({ documentId }) => ({
          url: `/${apiVersion}/legaldocuments/acknowledgements`,
          method: 'post',
          data: {
            documentId: documentId,
            acknowledgedOn: moment(new Date()).format('YYYY-MM-DD'),
          },
        }),
        invalidatesTags: ['acknowledgments'],
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetAcknowledgementsQuery,
  useGetAllLegalDocumentsQuery,
  useLazyGetOneLegalDocumentQuery,
  useGetPublicLegalDocumentQuery,
  useAcknowledgeLegalDocMutation,
} = legalDocsApi;
export default legalDocsApi;
