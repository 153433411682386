import { useHistory } from 'react-router';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import { pagePaths } from '../../config';
import { useFeedbackTranslation } from '../../hooks/useFeedbackTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const CreateSuccess = () => {
  const history = useHistory();
  const { label } = useFeedbackTranslation(__filename);

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('thank you', { textTransform: 'capitalize' })}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('Go to home'),
          action: () => history.push('/'),
        },
        {
          label: label('New feedback'),
          action: () => history.push(pagePaths['Create']),
        },
      ]}
    />
  );
};

export default CreateSuccess;
