const labels = {
  StaysList: {
    'Ref: Page title': 'Ở lại',
    'Ref: Past': 'Quá khứ',
    'Current and future stays': 'Ở hiện tại và tương lai',
    'filter label: period': 'Giai đoạn = Stage',
    'Ref: Search': 'Tìm kiếm',
  },
  StayDetails: {
    'Ref: Page title': 'Chi tiết lưu trú của bạn',
    'Arrival date': 'Ngày đến',
    'Departure date': 'Ngày khởi hành',
    'Room number': 'Phòng',
    'Reservation number': 'Mã đặt chỗ',
    CheckIn: 'Đăng ký vào',
    CheckOut: 'Thủ tục thanh toán',
    CheckIn_1: 'Kiểm tra vào {lodgeName}, đến hôm nay? (Số Đặt chỗ {resNumber})',
    CheckedIn_1: 'Bạn được kiểm tra vào {lodgeName}, phòng {roomNumber}.',
    CheckOut_1:
      'Kiểm tra {lodgeName} Số đặt phòng {resNumber} bây giờ? Khóa phòng của bạn sẽ bị vô hiệu hóa.',
    CheckedOut_1: '{lodgeName} Số đặt trước {resNumber} đã kiểm tra.',
  },
  StaysSuccess: {
    'Ref: Title': 'Cảm ơn bạn đã ở lại!',
    'Ref: Body': 'Thanh toán của bạn đã được xử lý thành công.',
    'Ref: Go to home': 'Đi về nhà',
    'Ref: Provide a feedback': 'Cung cấp một phản hồi',
  },
};
export default labels;
