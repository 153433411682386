import { cleanup /*, screen,   fireEvent */ } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { pagePaths } from '../../config';

import SharePage from './SharePage';

const mockSelectSiteMutation = jest.fn();
const mockGetSiteByCode = jest.fn();
jest.mock('../../../Sites/api/api', () => ({
  useSelectSiteMutation: () => [mockSelectSiteMutation],
  useLazyGetSiteByCodeQuery: () => [mockGetSiteByCode],
}));

describe('SharePage', () => {
  afterEach(() => cleanup());

  beforeEach(() => {
    mockGetSiteByCode.mockResolvedValue({
      error: { status: 401 },
    });
  });

  describe('on render with not logged in user', () => {
    const history = createMemoryHistory();
    renderComponent(SharePage, {}, {}, history);
    it('Redirect to login if user is not authenticated', () => {
      expect(history.location.pathname).toBe(pagePaths.Login);
    });
  });
});
