import classNames from 'classnames';
import { FC } from 'react';
import { useLocation } from 'react-router';

import { NavigatorMenuIcon } from '../../../../assets/icons';
import { breakPointsInPixels } from '../../../../config';
import { HIDE_NAVIGATION_ROUTES } from '../../../../constants';
import { useCustomTranslation } from '../../../../localization/hooks/useCustomTranslation';
import useDynamicTabName from '../../../../modules/Core/hooks/useDynamicTabName';
import BackButton from '../../../atoms/BackButton/BackButton';
import useSidebarContext from '../../../templates/SideBarWrapper/useSidebarContext';
import { TitleBarNavigationProps, TITLEBAR_CAPTION_TYPE } from '../TitleBar.types';

import styles from './Navigation.module.css';

const TitleBarNavigation: FC<TitleBarNavigationProps> = ({
  text,
  caption,
  'data-testid': dataTestId = 'titlebar-navigation',
  tabTitle,
  tabTitleWithoutAppName,
}) => {
  const { label } = useCustomTranslation();
  const location = useLocation();
  const { isSideBarCollapsed, setIsSideBarCollapsed, isNavigationVisible } = useSidebarContext();
  const isCaptionPageTitle = caption?.captionType === TITLEBAR_CAPTION_TYPE.DISPLAY_PAGE_TITLE;
  const isCaptionCurrentSite = caption?.captionType === TITLEBAR_CAPTION_TYPE.DISPLAY_CURRENT_SITE;
  const navText = isCaptionCurrentSite ? text : label('titlebar-navigation');
  const navTitle = isCaptionPageTitle && caption.title ? caption.title : navText;
  useDynamicTabName(tabTitle ?? caption?.title, tabTitleWithoutAppName);

  const hideNavigationRoutes = HIDE_NAVIGATION_ROUTES.some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <div
      slot="start"
      className={classNames(
        styles.TitleBar_TextContainer,
        {
          [styles.TitleBar_TextContainerWithBackButton]:
            caption && isCaptionPageTitle && !caption.hideBackButton,
        },
        { [styles.withSideBarCollapsed]: isSideBarCollapsed && isNavigationVisible },
        { [styles.withSideBarFull]: !isSideBarCollapsed && isNavigationVisible }
      )}
      data-testid={dataTestId}
    >
      {caption && isCaptionPageTitle && !caption.hideBackButton && (
        <BackButton
          data-testid={`${dataTestId}-back-btn`}
          customPath={caption.backButtonCustomPath}
          onBeforeBack={caption.onBeforeBack}
        />
      )}
      {caption &&
        isNavigationVisible &&
        !hideNavigationRoutes &&
        (caption?.hideBackButton || caption.hideBackButton === undefined) &&
        window.matchMedia('(max-width: ' + breakPointsInPixels['M'] + 'px)').matches && (
          <div
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && setIsSideBarCollapsed(false)}
            role="button"
            id="BackButton"
            data-testid="back-button"
            onClick={() => setIsSideBarCollapsed(false)}
            aria-label={label('Open navigation')}
            aria-pressed={!isSideBarCollapsed}
          >
            <NavigatorMenuIcon />
          </div>
        )}
      {!!caption && !!caption.title && isCaptionPageTitle ? (
        <>
          <h1
            aria-label={navTitle}
            className={classNames(styles.Homepage_Titlebar)}
            data-cy="homepage-titlebar"
          >
            <span data-cy="titlebar-site-name">{caption.title}</span>
          </h1>
        </>
      ) : null}
    </div>
  );
};
TitleBarNavigation.displayName = 'TitleBarNavigation';

export default TitleBarNavigation;
