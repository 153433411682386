import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';

import { enableGuestRequestSetupOption, pagePaths, statusMap } from '../../config';
import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';
import { AccommodationRequestFormFields } from '../../types';

import { DATE_FILTER } from './AccommodationRequest.types';

import { NavArrowRightIcon } from '@/assets/icons';
import { FacilityIllustration } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import { FilterPosition, Filtering, FilterType } from '@/components/atoms/Filters/Filters.types';
import { Tile, TileSkeleton } from '@/components/molecules/Tile';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import ListPage from '@/components/templates/ListPage/ListPage';
import { FilterPropsType } from '@/components/templates/ListPage/ListPage.types';
import { formatTime, formatDate } from '@/helpers/dateTime';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { useGetAccommodationRequestListQuery } from '@/modules/AccommodationRequest/api';
import RequestStatus from '@/modules/AccommodationRequest/components/RequestStatus/RequestStatus';
import { AccommodationRequestListItem } from '@/modules/AccommodationRequest/types/AccommodationRequest.types';
import { SERVICE } from '@/modules/config';
import useLanguage from '@/modules/Core/hooks/useLanguage';

import styles from './AccommodationRequest.module.css';

const AccommodationRequest = () => {
  const history = useHistory();
  const { label } = useAccommodationRequestTranslation(__filename);
  const { currentLanguageCode } = useLanguage();
  const [searchString, setSearchString] = useState('');
  const [filtering, setFiltering] = useState<Filtering>({});
  const [upcoming, setUpcoming] = useState<boolean>(true);
  const { data = [], isLoading } = useGetAccommodationRequestListQuery({ upcoming });

  const accommodationEnableGuestRequest = useIsSetupOptionEnabled(
    enableGuestRequestSetupOption,
    SERVICE.ACCOMMODATION_REQUEST
  );

  const { reset } = useFormContext<AccommodationRequestFormFields>();

  const items = data.map((item) => {
    return {
      ...item,
      'data-testid': `accommodation-request-list-item-${item.id}`,
      siteName: item.siteName,
      commentsSearch: item.comments?.map((m) => m.description).join(';'),
      linkPath: pagePaths['Details'].replace(':id', item.id),
      addendum: <RequestStatus status={item.status} /> ?? <></>,
    };
  });

  const accommodationTile = (item: AccommodationRequestListItem) => (
    <Tile
      id={item.id}
      key={`AccommodationRequestTile-${item.id}`}
      title={`${item.referenceNumber} ${'\u2022'} ${label('Ref: Arrival')}: ${
        item.arrivalDateTime
          ? `${formatDate(new Date(item.arrivalDateTime), currentLanguageCode)} -
        ${formatTime(new Date(item.arrivalDateTime))}`
          : ''
      }`}
      description={<span className={styles.siteName}>{item.siteName}</span>}
      tags={[{ name: item.status, element: item.addendum }]}
      onClick={() => history?.push(item.linkPath)}
      data-testid={`AccommodationRequestTile-${item.id}`}
      actions={[
        {
          name: 'NavArrowRightIcon',
          icon: <NavArrowRightIcon />,
          onClick: () => history?.push(item.linkPath),
          'data-testid': `accommodation-request-tile-${item.id}-right`,
        },
      ]}
    />
  );

  const filters = useMemo(() => {
    return [
      {
        id: 'filter_accommodation_request_date',
        name: label('Ref: Date'),
        position: FilterPosition.NOT_IN_MODAL,
        options: [
          {
            value: DATE_FILTER.FUTURE,
            label: label('Ref: Coming arrivals'),
            default: true,
          },
          {
            value: DATE_FILTER.PAST,
            label: label('Ref: Past arrivals'),
            default: false,
          },
        ],
        displayType: FilterType.EXPANDED,
        multiple: false,
        apply: (selectedValues: Array<DATE_FILTER>): Array<string> => {
          const filter = selectedValues[0];
          if (filter && filter === DATE_FILTER.PAST) {
            setUpcoming(false);
          }
          if (filter && filter === DATE_FILTER.FUTURE) {
            setUpcoming(true);
          }
          return items.map((el) => el.id);
        },
      },
      {
        id: 'filter_accommodation_request_status',
        name: label('Ref: Status'),
        position: FilterPosition.NOT_IN_MODAL,
        options: [
          {
            value: 'all',
            label: label('Ref: all'),
            default: true,
          },
          {
            value: 'open',
            label: label('Ref: open'),
          },
          {
            value: 'pending',
            label: label('Ref: pending'),
          },
          {
            value: 'refused',
            label: label('Ref: refused'),
          },
          {
            value: 'closed',
            label: label('Ref: closed'),
          },
        ],
        displayType: FilterType.DROPDOWN,
        multiple: false,
        apply: (selectedValues: string[]) => {
          const filteredAccommodationRequests = selectedValues.length
            ? items.filter(
                (accommodationRequest: AccommodationRequestListItem) =>
                  selectedValues.indexOf('all') === 0 ||
                  (accommodationRequest?.status &&
                    selectedValues.indexOf(statusMap[accommodationRequest.status.toLowerCase()]) >
                      -1)
              )
            : items;
          return filteredAccommodationRequests.map((a) => a.id);
        },
      },
    ] as FilterPropsType['filters'];
  }, [label, items]);

  const search = {
    searchableKeys: ['siteName', 'commentsSearch'],
    searchString: searchString,
    handleSearchChange: setSearchString,
  };

  const filter = useMemo<FilterPropsType>(
    () => ({
      filters,
      initialFiltering: filtering,
      handleFilteringChange: setFiltering,
    }),
    [filters, filtering]
  );

  const handleClick = () => {
    history.push(pagePaths.CreateForm);
    reset();
  };

  return (
    <ListPage
      data-testid="accommodation-request-home-page"
      hasBackLink={false}
      title={label(
        accommodationEnableGuestRequest
          ? 'Ref: GuestAccommodationRequestTitle'
          : 'Ref: AccommodationRequestTitle'
      )}
      items={items}
      isLoading={isLoading}
      filter={filter}
      search={search}
      hideFilterTitle={true}
      asideFirst
      aside={
        <Column.Complementary>
          <FacilityIllustration />
        </Column.Complementary>
      }
      actions={
        <ActionsBar>
          <Button
            onClick={handleClick}
            data-testid="accommodation-request-home-request-accommodation-create"
          >
            {label('Ref: NewAccommodationRequest')}
          </Button>
        </ActionsBar>
      }
      itemRenderer={(item: AccommodationRequestListItem) => accommodationTile(item)}
    >
      {isLoading &&
        Array.apply(null, Array(10)).map(() => (
          <TileSkeleton key={`${uuid()}-skeleton`} withoutActions withoutImage />
        ))}
    </ListPage>
  );
};

export default AccommodationRequest;
