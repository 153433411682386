import { Popover as TinyPopover } from 'react-tiny-popover';

import { PopoverType } from './Popover.types';

import styles from './Popover.module.css';

const Popover = ({ children, isOpen, content, onClickOutside }: PopoverType) => {
  return (
    <TinyPopover
      isOpen={isOpen}
      positions={['top', 'bottom', 'left', 'right']}
      content={<div className={styles.contentWrapper}>{content}</div>}
      onClickOutside={onClickOutside}
    >
      {children}
    </TinyPopover>
  );
};

export default Popover;
