const labels = {
  All: {
    dailyMenu: 'Günlük Menü',
    anytime: 'Her zaman',
    breakfast: 'Kahvaltı',
    morningBreak: 'sabah molası',
    lunch: 'Öğle Yemeği',
    afternoonBreak: 'öğleden sonra molası',
    dinner: 'Akşam Yemeği',
    supper: 'Akşam Yemeği',
    nightMeal: 'Gece yemeği',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menüler',
    'Ref: View details': 'Detayları görüntüle',
    'Ref: Facility details': 'Tesis detayları',
  },
  OpeningHours: { everyday: 'her gün', 'open all day': 'tüm gün açık' },
  ProductsList: {
    'Ref: Page title': 'Menü',
    'Ref: Facility details': 'Tesis detayları',
    'Ref: Search products list': 'Arama öğeleri',
    'Ref: filter label: date': 'Tarih',
    'Ref: filter label: menus': 'Menü',
    'Ref: filter label: moment': 'An',
    'Ref: filter label: category': 'Kategori',
    'Ref: filter label: dishes': 'Yemekler',
    'Ref: filter label: highlight': 'Filtreleri bulaşıklarla vurgulayın veya gizleyin',
    All: 'Tümü',
  },
  ProductDetails: { 'Ref: Page title': 'Ürün Detayları' },
  Widget: {
    'Ref: Carousel title': 'Menüler',
    'No info yet, come back later': 'Henüz bilgi yok, daha sonra gelin 😉',
    'Ref: menus': 'Menüler',
    'Ref: see all': 'Tümünü görün',
  },
};
export default labels;
