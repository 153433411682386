const labels = {
  TimeTable: {
    'leave at': 'Ayrılış Noktası',
    'arrive at': 'Varış Noktası',
    at: 'de',
    'Ref: list placeholder': 'Saatleri görmek için formu doldurunuz',
    'Ref: Page title': 'Otobüs',
    'Ref: Departure': 'Kalkış',
    'Ref: Arrivals': 'Varışlar',
    'Ref: minutes': 'Dakikalar',
    'Ref: no items placeholder': 'Üzgünüz, şu anda mevcut otobüs bulunmamaktadır.',
  },
};
export default labels;
