import { Filtering } from '../../atoms/Filters/Filters.types';

import { FilterPropsType, ItemType } from './ListPage.types';

import { ItemWithTestingProps } from 'src/types';

export const resetDisplayedItemsNum = ({
  configValue,
  defaultValue,
}: {
  configValue: number;
  defaultValue: number;
}) => configValue || defaultValue;

export const prepareSearchMetadata = <T extends ItemWithTestingProps>(
  items: T[],
  searchableKeys: string[]
) => {
  const searchedItems = {} as Record<string, Partial<ItemType>>;
  for (const item of items) {
    searchedItems[item.id] = searchableKeys.reduce((searchedItem, key) => {
      if (item[key]) {
        searchedItem[key] = item[key];
      } else {
        searchedItem[key] = '';
      }
      return searchedItem;
    }, {} as Record<string, string>);
  }

  return searchedItems;
};

export const searchItemsMetadata = (
  searchString: string,
  searchedItems: Record<string, Partial<ItemType>>,
  resultLimit?: number
) => {
  const searchWords = searchString.toLowerCase().split(' ');
  let result: string[] = [];
  for (const [id, item] of Object.entries(searchedItems)) {
    if (resultLimit && result.length >= resultLimit) break;
    let returned = true;
    //aggregate all the searched keys into a single string, and remove case
    const searchedText = Object.values(item).reduce(
      (res, val) => res + ' ' + (typeof val === 'string' ? val.toLowerCase() : ''),
      ''
    );

    //if the word is not found in the searchedText, that's it
    searchWords.forEach((searchWord) => {
      if (searchedText.indexOf(searchWord) === -1) returned = false;
    });

    if (returned) result.push(id);
  }
  return result;
};

export const applySearch = <T extends ItemWithTestingProps>(
  items: T[],
  searchString: string,
  searchableKeys: string[]
) => {
  if (!searchString) return items;
  //generate list of searchedItems based on searchable keys
  const searchedItems = prepareSearchMetadata(items, searchableKeys);

  //search
  const result = searchItemsMetadata(searchString, searchedItems);

  return items.filter((item) => result.includes(item.id));
};

export const applySearchOnMetadata = <T extends ItemWithTestingProps>(
  items: T[],
  searchString: string,
  searchedItems: Record<string, Partial<ItemType>>,
  resultLimit?: number
) => {
  if (!searchString) return items;
  //search
  const result = searchItemsMetadata(searchString, searchedItems, resultLimit);

  return items.filter((item) => result.includes(item.id));
};

export const applyFiltering = <T extends ItemWithTestingProps>(
  items: T[],
  filter?: FilterPropsType,
  filtering?: Filtering
) => {
  const filters = filter?.filters;
  if (!filters || !filtering) return items;

  //filter and return array of id
  let init = true;
  const result = filters.reduce((itemsId, filter) => {
    const selectedValues = Object.entries(filtering[filter.id] || {}).reduce(
      (res, [key, val]) => (val ? [...res, key] : res),
      [] as string[]
    );
    let allFilterItemsId;
    const thisFilterItemsId = !!filter.apply ? filter.apply(selectedValues) : itemsId;

    if (init) {
      init = false;
      allFilterItemsId = thisFilterItemsId;
    } else {
      allFilterItemsId = itemsId.filter((itemId) => thisFilterItemsId.includes(itemId));
    }
    return allFilterItemsId;
  }, [] as string[]);

  //filter items and return
  return items.filter((item) => result.includes(item.id));
};
