const mockResponse = {
  getAllFacilities: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        facilities: [
          {
            title: "Akshay's Facility",
            email: '',
            phone: '',
            name: "Akshay's Facility - FMG - FMG Hamilton",
            description: '',
            id: '764c9169-465c-ea11-b698-0003ff4ca494',
            sequence: 0,
            externalUrl: 'https://www.nationalgeographic.com/',
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580000',
              name: 'Food - Non-retail',
            },
            menuNote: '',
          },
          {
            title: "Akshay's Facility 2",
            email: '',
            phone: '',
            name: "Akshay's Facility 2 - FMG - FMG Hamilton",
            description: '',
            id: '0e110a8e-465c-ea11-b698-0003ff4ca494',
            sequence: 0,
            externalUrl: 'https://www.nationalgeographic.com/',
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [
              {
                closeTime: '',
                name: "Akshay's Opening hours",
                openTime: '',
                isOpenAllDay: false,
                day: [],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580000',
              name: 'Food - Non-retail',
            },
            menuNote: '',
          },
          {
            title: 'Nobin Cafe TEST 2',
            email: 'nobin@cafe.com',
            phone: '+115151151',
            name: 'Nobin Cafe TEST 2 - FMG - FMG Hamilton',
            description:
              "Nobin's Cafe Test offers authentic french pastries hand carved out of the dense bread forests located on the outskirts of the Louvre. Come visit or order something from this app to come collect later and your tongue would be thanking you for eternity.",
            id: '5ab2b628-6663-ea11-b698-0003ff4ca494',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [
              {
                closeTime: '20:30',
                name: 'Nobin opening hours',
                openTime: '08:30',
                isOpenAllDay: true,
                day: ['Friday', 'Thursday'],
              },
              {
                closeTime: '21:30',
                name: 'Nobin opening hours',
                openTime: '08:30',
                isOpenAllDay: true,
                day: ['Tuesday', 'Saturday', 'Wednesday'],
              },
              {
                closeTime: '22:30',
                name: 'Nobin opening hours',
                openTime: '08:30',
                isOpenAllDay: true,
                day: ['Monday'],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580000',
              name: 'Food - Non-retail',
            },
            menuNote: "This is a menu note for Nobi's Cafe!!",
          },
          {
            title: 'Testing Menu 03/16/2020',
            email: '',
            phone: '',
            name: 'Testing Menu 03/16/2020 - FMG - FMG Hamilton',
            description: '',
            id: '4377a903-4767-ea11-b698-0003ff4ca494',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: ['c0d3b54c-3050-e911-a986-000d3a2bccc5'],
            openingHours: [
              {
                closeTime: '',
                name: 'Testing',
                openTime: '',
                isOpenAllDay: true,
                day: [],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580000',
              name: 'Food - Non-retail',
            },
            menuNote: '',
          },
          {
            title: 'Chillie Restuarant',
            email: 'GGG.test@gmail.com',
            phone: '8787890987',
            name: 'Chillie Restuarant - FMG - FMG Hamilton',
            description: 'Menu1\nMenu2\nMenu3',
            externalUrl: 'https://www.nationalgeographic.com/',
            id: '744531cf-c9b5-ea11-8b72-0003ff4cb468',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: ['d320b36a-fda0-e911-a981-000d3a2bcd97'],
            openingHours: [
              {
                closeTime: '23:22',
                name: 'Chillie Restuarant',
                openTime: '20:21',
                isOpenAllDay: true,
                day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580001',
              name: 'Food - Retail',
            },
            menuNote: 'Testing menu note',
          },
          {
            title: 'New Test Facility',
            email: '',
            phone: '',
            name: 'New Test Facility - FMG - FMG Hamilton',
            description: '',
            id: '65ed16e1-0105-ea11-b863-0003ff4cd75f',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [
              {
                closeTime: '22:00',
                name: 'New Test Facilities Open Hours',
                openTime: '07:00',
                isOpenAllDay: false,
                day: ['Friday', 'Monday', 'Saturday', 'Thursday', 'Tuesday', 'Wednesday'],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580000',
              name: 'Food - Non-retail',
            },
            menuNote: '',
          },
          {
            title: 'The Mauritius Tavern Ext.',
            email: 'Magnecafe@hotmail.com',
            phone: '+230 57654321',
            name: 'The Mauritius Tavern - FMG - FMG Hamilton',
            description:
              'The Mauritius Tavern is the world’s largest coffeehouse chain, was founded in 1971. We provide the world’s ultimate coffee shop experience with the highest-quality products, most inviting stores, friendliest staff and best value.',
            id: 'b4703c0e-2874-ea11-99e5-0003ff4cdb10',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: ['e909ac85-155c-e911-a984-000d3a23db8b'],
            openingHours: [
              {
                closeTime: '19:00',
                name: 'Magne Café Opening Time',
                openTime: '08:30',
                isOpenAllDay: false,
                day: ['Monday', 'Thursday', 'Tuesday', 'Wednesday'],
              },
              {
                closeTime: '23:00',
                name: "Mother's Day Speial opening",
                openTime: '08:30',
                isOpenAllDay: false,
                day: [],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580001',
              name: 'Food - Retail',
            },
            menuNote: '',
            externalUrl: 'https://www.bananabeachclub.com/',
          },
          {
            title: 'The Mauritius Retail Shop',
            email: 'sodexo@demo.com',
            phone: '2451633',
            name: 'The Mauritius Retail Shop - FMG - FMG Hamilton',
            description: 'This is for Testing Purposes',
            id: '3b9148ac-a276-ea11-99e5-0003ff4cdb10',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: ['29fc7585-3050-e911-a975-000d3a2bcac2'],
            openingHours: [
              {
                closeTime: '22:30',
                name: 'Dinner Opening Hours',
                openTime: '17:00',
                isOpenAllDay: false,
                day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
              },
              {
                closeTime: '22:30',
                name: 'Holiday Opening Hours',
                openTime: '09:00',
                isOpenAllDay: false,
                day: ['Monday'],
              },
              {
                closeTime: '22:30',
                name: 'Opening Time',
                openTime: '08:30',
                isOpenAllDay: false,
                day: [],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580001',
              name: 'Food - Retail',
            },
            menuNote: 'Menu note demo: 5.00 p.m',
          },
          {
            title: 'Non-Retail Non-Food Facility',
            email: 'jurgen.klopppo@lfc.com',
            phone: '+44083923213921',
            name: 'Non-Retail Non-Food Facility - FMG - FMG Hamilton',
            description: 'This facility is of type Non-Food and Non-Retail',
            id: '1190f7bc-dd98-ea11-8b71-0003ff4cdb48',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [],
            isFoodFacilityType: false,
            facilityType: {
              id: '555580002',
              name: 'Non-food - Non-retail',
            },
            menuNote: 'This is a Menu Note',
          },
          {
            title: "Yuvan's Cafe",
            email: 'sodexo@demo.com',
            phone: '2451633',
            name: "Yuvan's Cafe - FMG - FMG Hamilton",
            description: 'This is for Testing Purposes',
            id: '6459d0b6-509b-ea11-8b71-0003ff4cdb48',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [
              {
                closeTime: '10:00',
                name: 'Breakfast',
                openTime: '07:00',
                isOpenAllDay: false,
                day: [],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580001',
              name: 'Food - Retail',
            },
            menuNote: 'Menu note 5.00 p.m',
          },
          {
            title: 'Restaurant',
            email: 'joe@thegym.com.au',
            phone: '+230 57654321',
            name: 'Restaurant - FMG - FMG Hamilton',
            description: 'This is for testing purposes.',
            id: '0891fdda-9cb0-ea11-8b71-0003ff4cdb48',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: ['b0c29a45-ef25-e911-a977-000d3a2bcc69'],
            openingHours: [
              {
                closeTime: '17:25',
                name: 'Restaurant',
                openTime: '08:15',
                isOpenAllDay: true,
                day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580001',
              name: 'Food - Retail',
            },
            menuNote: 'Testing Menu Note',
          },
          {
            title: 'Avanade testing',
            email: 'joe@thegym.com.au',
            phone: '451555',
            name: 'Avanade testing - FMG - FMG Hamilton',
            description: 'This is for testing purposes.',
            id: 'c2e718a0-20b2-ea11-8b71-0003ff4cdb48',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [
              {
                closeTime: '17:33',
                name: 'Avanade testing',
                openTime: '08:33',
                isOpenAllDay: true,
                day: ['Friday', 'Monday', 'Saturday', 'Sunday', 'Thursday', 'Tuesday', 'Wednesday'],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580001',
              name: 'Food - Retail',
            },
            menuNote: 'Testing Menu Note',
          },
        ],
      }),
  },
  getOneFacility: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        title: 'The Mauritius Tavern',
        email: 'Magnecafe@hotmail.com',
        phone: '+230 57654321',
        name: 'The Mauritius Tavern - FMG - FMG Hamilton',
        description:
          'The Mauritius Tavern is the world’s largest coffeehouse chain, was founded in 1971. We provide the world’s ultimate coffee shop experience with the highest-quality products, most inviting stores, friendliest staff and best value.',
        id: 'b4703c0e-2874-ea11-99e5-0003ff4cdb10',
        sequence: 0,
        access: '',
        longitude: '',
        latitude: '',
        images: ['e909ac85-155c-e911-a984-000d3a23db8b'],
        openingHours: [
          {
            closeTime: '19:00',
            name: 'Magne Café Opening Time',
            openTime: '08:30',
            isOpenAllDay: false,
            day: ['Monday', 'Tuesday', 'Wednesday', 'Thursday'],
          },
          {
            closeTime: '23:00',
            name: "Mother's Day Speial opening",
            openTime: '08:30',
            isOpenAllDay: false,
            day: [],
          },
        ],
        isFoodFacilityType: true,
        facilityType: {
          id: '555580001',
          name: 'Food - Retail',
        },
        menuNote: 'This is a menu note.',
        externalUrl: 'http://www.bananabeachclub.com/',
      }),
  },
};

export default mockResponse;
