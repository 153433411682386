const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'คำขอร้องของคุณ',
    'Ref: Summary': 'สรุป',
    'Ref: Request category': 'ขอหมวดหมู่',
    'Ref: Person affected': 'บุคคลที่ได้รับผลกระทบจากคำขอนี้',
    'Ref: Your phone number': 'หมายเลขโทรศัพท์',
    'Ref: Description': 'คำอธิบาย',
    'Ref: Service Requests': 'คำขอบริการ',
    'Ref: Request Service': 'ขอบริการ',
    'Ref: Success title': 'คำขอของคุณถูกสร้างขึ้น',
    'Ref: Error title': 'อ๊ะ!',
    'Ref: Error subtitle': 'เกิดข้อผิดพลาดโปรดลองอีกครั้ง',
    'Ref: Try again': 'ลองอีกครั้ง',
    'Ref: you pronoun': '(คุณ)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'นี่คือศูนย์ช่วยเหลือในที่ทำงานของคุณ',
    'Ref: Tutorial: tabname': 'การร้องขอ',
    'Ref: Tutorial: body': 'เราอยู่ที่นี่เพื่อช่วยคุณ โปรดบันทึกหนึ่งคำขอต่อรายการ',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'คุณกำลังขอบริการบนเว็บไซต์ {sitename}',
    'Ref: Building': 'อาคาร',
    'Ref: Room': 'ห้อง',
    'Ref: Description help text':
      'ยิ่งคุณมีรายละเอียดมากเท่าไหร่เราก็ยิ่งสามารถสนับสนุนคุณได้ดีขึ้นเท่านั้น',
    'Ref: Next': 'ต่อไป',
    'Ref: Your request': 'คำขอร้องของคุณ',
    'Ref: PhoneNumber error msg': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
    'Ref: No affected person results': 'ไม่มีบุคคลที่ตรงกันที่ร้องขอเกณฑ์',
    'Ref: Required': '* ที่จำเป็น',
  },
  Summary: {
    'Ref: Location': 'ที่ตั้ง',
    'Ref: Attachment': 'สิ่งที่แนบมา',
    'Ref: Submit': 'ส่ง',
    'Ref: No affected person results': 'ไม่ตรงกับเกณฑ์ที่ต้องการ',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'คำขอบริการ',
    'Ref: createdOn': 'สร้างบน',
    'Ref: summary': 'สรุป',
    'Ref: category': 'ขอหมวดหมู่',
    'Ref: request raised for': 'บุคคลที่ได้รับผลกระทบจากคำขอนี้',
    'Ref: phoneNumber': 'หมายเลขโทรศัพท์',
    'Ref: location': 'หมายเลขโทรศัพท์',
    'Ref: description': 'คำอธิบาย',
    'Ref: attachments': 'สิ่งที่แนบมา',
  },
};
export default labels;
