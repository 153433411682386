import { Capacitor } from '@capacitor/core';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Redirect, Route, useHistory } from 'react-router-dom';

import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import useLoginStatus from '../../../../helpers/hooks/useLoginStatus';
import { refreshAccessToken } from '../../actions';
import {
  getGuestRestrictedRoutes,
  legalDocType,
  pagePaths,
  pagePathsAvailableWithoutContract,
  pagePathsAvailableWithoutSiteContext,
} from '../../config';
import { useLegalDocAndAcknowledgments } from '../../hooks/useLegalDocAndAcknowledgments';

import { PrivateRouteProps } from './PrivateRoute.types';

import { State } from '@/types/state.types';

const PrivateRoute: FC<PrivateRouteProps> = ({ children, path, ...rest }) => {
  const { isLoggedIn, isGuest } = useLoginStatus();
  const history = useHistory();
  const location = useLocation();
  const currentPathMatch = useRouteMatch(path);

  const dispatch = useDispatch();

  const { access, user, locks } = useSelector((state: State) => state.Core);
  const {
    termsOfUseToAcknowledge,
    privacyPolicyToAcknowledge,
    isLoading: checkAcknowledgmentLoading,
  } = useLegalDocAndAcknowledgments();

  const shouldSelectSiteContext = access.shouldSelectSiteContext;

  const { tokenRefresh: isTokenRefreshing, getUserContext: isUserContextReturned } = locks;
  const contractId = user?.contract?.id;

  if (!isLoggedIn && !isTokenRefreshing) {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    if (refreshToken) {
      dispatch(refreshAccessToken(refreshToken));
      return <LoadingPage />;
    }
  }

  const isGuestRestrictedRoute = () => {
    const restrictedRoutes = getGuestRestrictedRoutes();
    return isGuest && restrictedRoutes.includes(location.pathname);
  };
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isTokenRefreshing || isUserContextReturned || checkAcknowledgmentLoading) {
          return <LoadingPage />;
        }

        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/access/login',
                state: { from: location },
              }}
            />
          );
        }

        if (isGuestRestrictedRoute())
          return (
            <Redirect to={{ pathname: pagePaths.GuestRegistration, state: { from: location } }} />
          );

        //specific case for legal docs
        if (currentPathMatch?.url === '/legal') return children;

        if (termsOfUseToAcknowledge && privacyPolicyToAcknowledge) {
          return (
            <Redirect
              to={{
                pathname: '/access/legal',
                state: { from: location },
              }}
            />
          );
        }

        if (Capacitor.isNativePlatform() && termsOfUseToAcknowledge) {
          return (
            <Redirect
              to={{
                pathname: pagePaths['LegalDoc']
                  .replace(':document_type', legalDocType.terms_of_use)
                  .replace(':version', 'current'),
                state: { from: location },
              }}
            />
          );
        }

        if (privacyPolicyToAcknowledge) {
          return (
            <Redirect
              to={{
                pathname: pagePaths['LegalDoc']
                  .replace(':document_type', legalDocType.privacy_policy)
                  .replace(':version', 'current'),
                state: { from: location },
              }}
            />
          );
        }

        if (!contractId) {
          if (
            !!currentPathMatch &&
            !pagePathsAvailableWithoutContract.includes(currentPathMatch.url)
          ) {
            return (
              <Redirect
                to={{
                  pathname: pagePaths['Onboarding'],
                  state: { from: location },
                }}
              />
            );
          }
        }

        if (
          shouldSelectSiteContext &&
          currentPathMatch &&
          !pagePathsAvailableWithoutSiteContext.includes(currentPathMatch.url)
        ) {
          return (
            <Redirect
              to={{
                pathname: pagePaths.Sites,
                state: { from: location },
              }}
            />
          );
        }
        const returnUrl = localStorage.getItem(LOCAL_STORAGE_KEYS.RETURN_URL);

        if (returnUrl && location.pathname === returnUrl) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.RETURN_URL);
        } else if (returnUrl && location.pathname !== returnUrl) {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.RETURN_URL);
          if (returnUrl !== '/' && returnUrl !== '/home') {
            // In Some modules will not display the full App Menu
            // The only option to navigate back is by clicking '<' button or Browser back
            // For the SSO scenarion the previous age is to aquire token
            // to allow an SSO user navigate back we insert a fake navigation
            history.push('/home');
            //redirect to new route
            return (
              <Redirect
                to={{
                  pathname: returnUrl,
                  state: { from: location },
                }}
              />
            );
          }
        }

        return children;
      }}
    />
  );
};

export default PrivateRoute;
