import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';

import NotificationsWidget from './NotificationsWidget';

import { GetNotificationsResponse } from '@/modules/Core/api/notifications/notificationsApi.types';

const stateWithNotifications = {
  Core: {
    notifications: {
      items: [
        {
          notificationId: 1,
          message: 'well well well',
        },
        {
          notificationId: 2,
          message: 'Mr Bond',
        },
      ],
      total: 2,
    },
  },
  Shared: { language: { currentLanguageCode: 'en' } },
};

const stateWithoutNotifications = {
  Core: {
    notifications: {
      items: [],
      total: 0,
    },
  },
  Shared: { language: { currentLanguageCode: 'en' } },
};

const mock: { data: GetNotificationsResponse; isLoading: boolean } = {
  data: {
    items: [],
    total: 0,
    pageIndex: 0,
    pageSize: 0,
    unreadNotificationCount: 0,
  },
  isLoading: false,
};
jest.mock('@/modules/Core/api/notifications/notificationsApi', () => ({
  useLazyGetNotificationsQuery: () => {
    const getNotifications = () => {
      return mock;
    };
    return [getNotifications];
  },
}));

jest.mock('@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled', () => ({
  useIsSetupOptionEnabled: jest.fn().mockReturnValue(false),
}));
const mockSelector = jest.fn();

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
}));

describe('NotificationsWidget component', () => {
  beforeEach(() => {
    mockSelector.mockReturnValue(stateWithNotifications);
  });

  describe('Render with props - hide prop is false', () => {
    afterAll(() => cleanup());
    renderComponent(NotificationsWidget, { hide: false });

    it('Should display widgets section', () => {
      const component = screen.getByTestId('titlebar-widgets');
      expect(component).toBeTruthy();
    });
    it('Should display link navigation', () => {
      const component = screen.getByTestId('titlebar-widgets-link-to');
      expect(component).toBeTruthy();
    });
    it('Should display icon', () => {
      const component = screen.getByTestId('title-bar-icon-notifications');
      expect(component).toBeTruthy();
    });
  });

  describe('Render with props - hide prop is true', () => {
    afterAll(() => cleanup());
    renderComponent(NotificationsWidget, { hide: true });

    it('Should not display component', () => {
      const component = screen.queryByTestId('titlebar-widgets');
      expect(component).toBeFalsy();
    });
  });
});

describe('NotificationsWidget component without notifications', () => {
  beforeEach(() => {
    mockSelector.mockReturnValue(stateWithoutNotifications);
  });

  describe('NotificationsWidget without notifications from state', () => {
    afterAll(() => cleanup());
    renderComponent(NotificationsWidget, { hide: true });

    it('Should not display component', () => {
      const component = screen.queryByTestId('titlebar-widgets');
      expect(component).toBeFalsy();
    });
  });
});
