const labels = {
  AuditInviteList: {
    'Ref: Page title': '감사',
    'Ref: status0': '열려 있는',
    'Ref: status1': '완전한',
    'Ref: status2': '취소 된',
    'Ref: connected': '연결',
    'Ref: not connected': '연결되지 않은',
    'Ref: synchronise data': '데이터 동기화',
    'Ref: sync success': '데이터가 성공적으로 동기화되었습니다',
    'Ref: Create new': '새로운 창조',
    'Ref: Audit unsynchronised': '동기화되지 않은',
    'Ref: Audit synchronised': '동기화',
    'Ref: Unsynced error title': '오류가 발생했습니다',
    'Ref: Unsynced error description':
      '감사 모듈에 처음 액세스 한 것으로 보입니다. 감사를 시작하려면 네트워크에 연결하고 "동기화"하십시오.',
    'Ref: Search all audits': '모든 감사',
    'Ref: Search active audits': '열려 있는',
    'Ref: Search completed audits': '완전한',
    'Ref: sync error': '동기화에 실패했습니다',
    'Ref: sync error details': '다시 연결하려면 다시 연결하십시오',
    'Ref: no search audit results': '불행히도 검색에 해당하는 감사가 없습니다.',
    'Ref: no audit results': '사용 가능한 감사가없고 시작할 새로 만들 수 있습니다.',
    'Ref: Search canceled audits': '취소 된',
    'Ref: Audit cancelled successfully': '감사가 성공적으로 취소되었습니다',
    'Ref: Cancel audit invite': '취소',
    'Ref: Error audit cancel': '감사가 취소되지 않았습니다',
    'Ref: Cancel audit confirmation': '감사를 취소 하시겠습니까?',
    'Ref: Confirm': '확인하다',
    'Ref: Cancel audit': '감사를 취소하십시오',
    'Ref: Change the location': '위치를 변경하십시오',
  },
  AuditResponseForm: {
    'Ref: Page title': '감사 세부 사항',
    'Ref: questions': '질문',
    'Ref: question': '질문',
    'Ref: Additional Comments': '설명',
    'Ref: Not found title': '죄송합니다!',
    'Ref: Not found text': '액세스하려는 감사는 존재하지 않습니다.',
    'Ref: Back to Audit list': '감사 목록으로 돌아갑니다',
    'Ref: Success title': '감사합니다!',
    'Ref: Success text': '감사를 완료했습니다',
    'Ref: submit': '제출하다',
    'Ref: add attachment': '부착물을 추가하십시오',
    'Ref: Unsupported elements':
      '죄송합니다.이 감사에는 아직 응용 프로그램이 지원하지 않는 콘텐츠가 있습니다. 우리는 그것에 대해 노력할 것입니다.',
    'Ref: form error': '모든 질문에 완전히 대답 한 것은 아닙니다',
    'Ref: form complete': '이후 완료되었습니다',
    'Ref: attachment preview error': '이 첨부 파일을 보려면 온라인에 있어야합니다',
  },
  AuditCreationForm: {
    'Ref: Page title': '새로운 창조',
    'Ref: Location': '위치',
    'Ref: Audit Stream Type': '개울',
    'Ref: Audit': '심사',
    'Ref: Asset': '유산',
    'Ref: Asset Name': '자산 이름',
    'Ref: Is joint audit?': '공동 감사입니까?',
    'Ref: Who was present?': '누가 참석 했습니까?',
    'Ref: submit': '제출하다',
    'Ref: Select the Location': '위치를 선택하십시오',
    'Ref: Select the Audit Stream Type': '감사 스트림 유형을 선택하십시오',
    'Ref: Select the Audit': '감사를 선택하십시오',
    'Ref: Select the Asset': '자산을 선택하십시오',
    'Ref: form error': '필요한 모든 필드가 완전히 입력 된 것은 아닙니다',
    'Ref: Audit error': '첨부 된 감사',
    'Ref: Joint Audit Participants error': '공동 감사 참가자',
    'Ref: Audit created': '감사가 생성되었습니다',
    'Ref: Audit field length error': '길이는 100자를 초과해서는 안됩니다',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': '위치를 변경하십시오',
    'Ref: Location changed': '위치가 변경되었습니다',
    'Ref: Location': '위치',
    'Ref: Select the Location': '위치를 선택하십시오',
    'Ref: Asset': '유산',
    'Ref: Select the Asset': '자산을 선택하십시오',
    'Ref: Asset Name': '자산 이름',
    'Ref: submit': '제출하다',
    'Ref: Audit field length error': '길이는 100자를 초과해서는 안됩니다',
  },
};
export default labels;
