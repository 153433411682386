const labels = {
  All: {
    dailyMenu: 'Desconhecido',
    anytime: 'Em qualquer momento',
    breakfast: 'Café da manhã',
    morningBreak: 'intervalo da manhã',
    lunch: 'Almoço',
    afternoonBreak: 'Break da tarde',
    dinner: 'Jantar',
    supper: 'Jantar',
    nightMeal: 'Refeição noturna',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menus',
    'Ref: View details': 'Ver detalhes',
    'Ref: Facility details': 'Detalhes da Instalação',
  },
  OpeningHours: { everyday: 'todo dia', 'open all day': 'Aberto o dia inteiro' },
  ProductsList: {
    'Ref: Page title': 'Cardápio',
    'Ref: Facility details': 'Detalhes da Instalação',
    'Ref: Search products list': 'Itens de pesquisa',
    'Ref: filter label: date': 'Encontro',
    'Ref: filter label: menus': 'Cardápio',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoria',
    'Ref: filter label: dishes': 'Pratos',
    'Ref: filter label: highlight': 'Destaque ou oculte filtros com pratos',
    All: 'Tudo',
  },
  ProductDetails: { 'Ref: Page title': 'Detalhes do produto' },
  Widget: {
    'Ref: Carousel title': 'Menus',
    'No info yet, come back later': 'Ainda não há informação, volte mais tarde 😉',
    'Ref: menus': 'Menus',
    'Ref: see all': 'Veja tudo',
  },
};
export default labels;
