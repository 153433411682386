import classNames from 'classnames';

import { TitleProps, TITLE_SIZE, TITLE_TAG } from './Title.types';

import styles from './Title.module.css';

const Title = ({
  tag = TITLE_TAG.SPAN,
  size = TITLE_SIZE.HEADLINEXS,
  id,
  suffix,
  className,
  children,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}: TitleProps) => {
  const Tag = tag;
  return (
    <Tag
      className={classNames('Title', styles.title, size, className)}
      data-cy={dataCy}
      data-testid={dataTestId}
      id={id}
    >
      {children}
      {suffix && <span>{suffix}</span>}
    </Tag>
  );
};

export default Title;