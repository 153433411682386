const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Audyty',
    'Ref: status0': 'otwarty',
    'Ref: status1': 'Zakończony',
    'Ref: status2': 'Odwołany',
    'Ref: connected': 'Połączony',
    'Ref: not connected': 'Nie połączony',
    'Ref: synchronise data': 'Synchronizować dane',
    'Ref: sync success': 'Dane zsynchronizowane pomyślnie',
    'Ref: Create new': 'Tworzyć nowe',
    'Ref: Audit unsynchronised': 'Nienchronizowany',
    'Ref: Audit synchronised': 'Zsynchronizowane',
    'Ref: Unsynced error title': 'Wystąpił błąd',
    'Ref: Unsynced error description':
      'Wydaje się, że po raz pierwszy dostęp do modułu audytu. Połącz się z siecią i „synchronizować”, aby rozpocząć kontrolę.',
    'Ref: Search all audits': 'Wszystkie audyty',
    'Ref: Search active audits': 'otwarty',
    'Ref: Search completed audits': 'Zakończony',
    'Ref: sync error': 'Synchronizacja nie powiodła się',
    'Ref: sync error details': 'Ponowne połączenie',
    'Ref: no search audit results': 'Niestety nie ma audytów odpowiadających wyszukiwaniu',
    'Ref: no audit results': 'Nie masz dostępnych audytów, utwórz nowe, aby rozpocząć',
    'Ref: Search canceled audits': 'Odwołany',
    'Ref: Audit cancelled successfully': 'Audyt pomyślnie anulowany',
    'Ref: Cancel audit invite': 'Anulować',
    'Ref: Error audit cancel': 'Audyt nie został anulowany',
    'Ref: Cancel audit confirmation': 'Czy na pewno chcesz anulować audyt?',
    'Ref: Confirm': 'Potwierdzać',
    'Ref: Cancel audit': 'Anuluj audyt',
    'Ref: Change the location': 'Zmień lokalizację',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Szczegóły audytu',
    'Ref: questions': 'pytania',
    'Ref: question': 'pytanie',
    'Ref: Additional Comments': 'Opis',
    'Ref: Not found title': 'Ups!',
    'Ref: Not found text': 'Audyt, do którego próbujesz uzyskać dostęp, nie istnieje.',
    'Ref: Back to Audit list': 'Powrót do listy audytu',
    'Ref: Success title': 'Dziękuję!',
    'Ref: Success text': 'Ukończyłeś audyt',
    'Ref: submit': 'Składać',
    'Ref: add attachment': 'Dodaj załącznik',
    'Ref: Unsupported elements':
      'Przepraszam, wydaje się, że ten audyt ma treść, której aplikacja jeszcze nie obsługuje. Będziemy nad tym pracować.',
    'Ref: form error': 'Nie odpowiedziała na wszystkie pytania',
    'Ref: form complete': 'Ukończone od tego czasu',
    'Ref: attachment preview error': 'Musisz być online, aby zobaczyć ten załącznik',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Tworzyć nowe',
    'Ref: Location': 'Lokalizacja',
    'Ref: Audit Stream Type': 'Strumień',
    'Ref: Audit': 'Rewizja',
    'Ref: Asset': 'Zaleta',
    'Ref: Asset Name': 'Nazwa aktywów',
    'Ref: Is joint audit?': 'Czy wspólny audyt?',
    'Ref: Who was present?': 'Kto był obecny?',
    'Ref: submit': 'Składać',
    'Ref: Select the Location': 'Wybierz lokalizację',
    'Ref: Select the Audit Stream Type': 'Wybierz typ strumienia audytu',
    'Ref: Select the Audit': 'Wybierz audyt',
    'Ref: Select the Asset': 'Wybierz zasób',
    'Ref: form error': 'Nie wszystkie wymagane pola zostały w pełni wprowadzone',
    'Ref: Audit error': 'Załączony audyt',
    'Ref: Joint Audit Participants error': 'Wspólni uczestnicy audytu',
    'Ref: Audit created': 'Utworzony audyt',
    'Ref: Audit field length error': 'Długość nie powinna przekraczać 100 znaków',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Zmień lokalizację',
    'Ref: Location changed': 'Lokalizacja zmieniona',
    'Ref: Location': 'Lokalizacja',
    'Ref: Select the Location': 'Wybierz lokalizację',
    'Ref: Asset': 'Zaleta',
    'Ref: Select the Asset': 'Wybierz zasób',
    'Ref: Asset Name': 'Nazwa aktywów',
    'Ref: submit': 'Składać',
    'Ref: Audit field length error': 'Długość nie powinna przekraczać 100 znaków',
  },
};
export default labels;
