import { ReactionsAPIType } from '../../../types/reactions.types';
import { ISite } from '../../Sites/types/sites.types';

import { TestingPropsV2 } from 'src/types';

export enum DATE_FILTER {
  PAST = 'past',
  FUTURE = 'future',
}

export type EventPreference = {
  id: string;
  name: string;
  type: string;
  description: string;
};

type EventLinkType = {
  url: string;
  text: string;
};

type EventImage = {
  id: string;
  fileSize: string;
  mimeType: string;
  content: string;
};

export type EventType = {
  id: string;
  name: string;
  description: string;
  markdown?: string;
  markdownDecoded?: boolean;
  startDate: string;
  endDate: string;
  publishedDate: string;
  eventDates: string[];
  location: string;
  link: EventLinkType;
  preferences: Pick<EventPreference, 'id' | 'name'>[];
  sites: Pick<ISite, 'id' | 'name'>[];
  images: string[] | EventImage[];
  isUserInterested: boolean;
  reactions: ReactionsAPIType;
  mainImage?: string;
} & TestingPropsV2;

export type EventState = {
  data?: Partial<GETEventDetailsResponse>;
  list: EventType[];
  preferences: EventPreference[];
  locks: {
    getList: boolean;
    getOne: boolean;
    getPreferences: boolean;
    postInterest: boolean;
    postReaction: boolean;
  };
};

export type EventsListFilters = {
  filter_date?: {
    future?: boolean;
    past?: boolean;
  };
  filter_interest?: {
    all_events: boolean;
    interested_only: boolean;
  };
};

export type SectionItem = {
  id: string;
  key: string;
  timestamp: number;
  title: string;
  filter: (unfilteredItems: Array<EventType>) => Array<EventType>;
} & TestingPropsV2;

export type GETEventDetailsResponse = {
  responseData: EventType;
  responseStatus: number;
};
