const labels = {
  All: {
    'starts at': 'başlar',
    'ends at': 'Biter',
    location: 'Konum',
    'coming date': 'gelecek tarih',
    'Ref: Currently event not preferred. Click to make preferred':
      'Şu anda etkinlik tercih edilmiyor. Tercih Edilmek İçin Tıklayın',
    'Ref: Currently event preferred. Click to make not preferred':
      'Şu anda etkinlik tercih ediliyor. Tercih edilmemek için tıklayın',
  },
  EventDetails: {
    details: 'Detaylar',
    'Ref: Page title': 'etkinlik detayları',
    'Event dates': 'Etkinlik tarihleri',
    'See online:': 'Çevrimiçi görün:',
    'See all dates': 'Tüm tarihleri ​​gör',
    'Remove from my selection': 'Seçimimden kaldır',
    'Add to my selection': 'Tercihlerime Ekle',
    'Ref: explain calendar usage':
      'Etkinlik tarihleri aşağıdaki takvimde vurgulanmıştır. Beğendiğiniz etkinlikleri kaçırmayın! Davetiyeyi indirmek için takvimdeki bir etkinlik tarihine tıklayın. Ardından etkinliği tercih ettiğiniz takvime eklemek için indirilen dosyayı açın.',
    'Ref: Favorite': 'Favori',
    'Ref:Feedback': 'Geri bildirim',
    'Ref: Event site different title': 'Bu etkinlik mevcut sitenize ait değil',
    'Ref: Event site different description':
      'Mevcut sitenize ait olmayan bir etkinliği görüntülüyorsunuz.Bu etkinliği etkinlikler listesinde göremezsiniz.',
    'Ref: See all dates': 'Tüm tarihleri görün',
    'Ref: From': 'Kimden',
    'Ref: to': 'Kime',
    'Ref: Coming date': 'Yaklaşan yarih',
    'Ref: Add to calendar': 'Takvime ekle',
  },
  EventsList: {
    'Ref: Page title': 'Etkinlikler',
    'All dates': 'Tüm Tarihler',
    'All events': 'Tüm etkinlikler',
    'Ref: Search': 'Arama',
    'My selection': 'Benim Tercihim',
    'filter label: date': 'Tarih',
    'filter label: coming events': 'Gelecek Etkinlikler',
    'filter label: past events': 'Geçmiş Etkinlikler',
    'filter label: interest': 'tercih',
  },
  Widget: {
    'Ref: Carousel title': 'Etkinlikler',
    'No info yet, come back later': 'Henüz bilgi yok, daha sonra geri gel 😉',
  },
};
export default labels;
