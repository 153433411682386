const labels = {
  All: {
    Region: 'Regio of land',
    'Ref: Geography': 'Selecteer uw land of regio',
    'Ref: Country': 'Land',
    'Ref: Country tooltip': 'De locatie komt overeen met het land van de site.',
    'Ref: Please select your country': 'Selecteer uw land alstublieft',
    'First name': 'Voornaam',
    'Last name': 'Achternaam',
    'Ref: Full name': 'Voor-en achternaam',
    'Mobile number': 'Mobiel nummer',
    'Mobile phone': 'Mobiele telefoon',
    Email: 'E-mailadres',
    Password: 'Wachtwoord',
    logout: 'Uitloggen',
    'Not a new user? Log in': 'Geen nieuwe gebruiker? Log in',
    'do not register, login': 'Geen nieuwe gebruiker? Log in',
    'Ref: I agree': 'daar ben ik het mee eens',
    Register: 'Registreer',
    'Ref: My language': 'Mijn taal',
    'Ref: Kiosk Fail Title': 'Oeps! Er is iets fout gegaan!',
    'Ref: Kiosk Fail Description':
      'Helaas was uw bestelling niet succesvol. Probeer het opnieuw of vraag een medewerker om u te helpen.',
    'Ref: Kiosk Fail Back': 'Terug naar de vorige pagina',
    'Ref: Kiosk Fail Action': 'Wis de sessie en bestel opnieuw',
    Validate: 'Bevestigen',
    'Ref: error, incorrect credentials':
      'Deze inloggegevens komen niet overeen in de gekozen locatie.',
    'Ref: Forgot password?': 'Wachtwoord vergeten?',
    'Ref: Hello': 'Hallo {hand_emoji}, log in om door te gaan',
    'Ref: Form title': 'Meld u aan om door te gaan',
    'Already have an account? Login': 'Heb je al een account? Log in',
  },
  AccountPage: {
    'Ref: My Information': 'Mijn informatie',
    'Ref: Settings': 'Instellingen',
    'Ref: Email': 'E -mail',
    'Ref: Current site': 'Huidige site',
    'Ref: Choose site': 'Kies een site',
    'Ref: Personal interests': 'Persoonlijke interesses',
    'Ref: Food preferences': 'Voedselvoorkeuren',
    'Ref: Password': 'Wachtwoord',
    'Ref: Page title': 'Account',
    'Ref: Language': 'Taal',
    'Ref: Communication preferences': 'Communicatie voorkeuren',
    'Ref: Terms & conditions': 'Algemene voorwaarden',
    'Ref: Privacy policy': 'Privacybeleid',
    'Ref: Delete my account': 'Verwijder mijn account',
    'Ref: Confirm deletion': 'Weet u zeker dat u uw account wilt verwijderen?',
    'Ref: Request deletion': 'Weet u zeker dat u de verwijdering van uw account wilt aanvragen?',
    'Ref: Deletion description': 'Uw verzoek wordt afgehandeld volgens uw lokale regelgeving.',
    Placeholder: 'Schrijf "verwijderen"',
    'Ref: Delete description': 'Uw gegevens worden verwijderd. Deze actie is onomkeerbaar.',
    delete: 'verwijderen',
    'Ref: Terms of sale': 'Verkoopsvoorwaarden',
    'Ref: Imprint': 'Afdrukken',
    'Ref: Cookie Settings': 'Cookie -instellingen',
    'Ref: Support': 'Support',
    'Ref: Register or login': 'Registreer of log in',
    'Ref: Filtering Preferences': 'Filtervoorkeuren',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Weet u zeker dat u uw account wil verwijderen?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Schrijf "{word}" in invoerveld en bevestig met de knop hieronder. Deze actie is onomkeerbaar.',
    'Ref: Write "{word}"': 'Schrijf "{word}"',
    'Ref: Delete my account': 'Verwijder mijn account',
    'Ref: Words are not matching': 'Woorden komen niet overeen',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Kies uw locatie om te starten',
    'Ref: Choose location':
      'Zoek snel een locatie door de lokalisatie te activeren, een QR -code te scannen of op naam te zoeken',
    'Ref: next step': 'Laten we beginnen',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Inschrijven',
    'Ref: identify your location': 'Identificeer uw locatie.',
    'Ref: option 1 - scan QR': 'Optie 1: Scan de verstrekte registratie QR-code',
    'Ref: option 2 - enter code': 'Optie 2: Voer handmatig uw locatiecode in',
    'Ref: button to open qr code scanner modal': 'Ik heb een QR: scannen',
    'Ref: button to open site code input modal': 'Selecteer Site met code',
    'Ref: getting help with site identifier - support desk':
      'Als u niet duidelijk bent hoe u doorgaat, neem dan contact op met uw ondersteuning.',
    'Ref: getting help with site identifier - support form': 'U kunt ons ook een bericht sturen.',
    'Location code': 'Locatie code',
    'Ref: location code field placeholder': 'Uw locatiecode hier',
    'Ref: Scanner header': 'Scan een QR-code',
    'Ref: site code input header': 'Site toevoegen met code',
    'Ref: not valid code': 'Deze code is niet geldig in de geselecteerde locatie',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Registratie',
    'Ref: collected info': 'Samenvatting',
    'Ref: additional info': 'Uw informatie',
    'Confirm password': '*Bevestig wachtwoord',
    company: 'Wie is uw werkgever?',
    location: 'Plaats',
    username: 'gebruikersnaam',
    register: 'Inschrijven',
    'Ref: password strength':
      'Uw wachtwoord moet minstens 8 tekens lang zijn en minimaal één cijfer, één hoofdletter, één kleine letter en één speciaal teken bevatten.',
    'passwords do not match': 'Wachtwoorden komen niet overeen.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'communicatie voorkeuren',
    'Ref: Things happen while you are away': 'Er gebeuren dingen terwijl je weg bent',
    'Ref: Would you like to receive update about offers and promotions?':
      'Wilt u updates ontvangen over aanbiedingen en promoties?',
    'Ref: Would you like to hear about content we think you may like?':
      'Wil je horen over inhoud die we denken dat je misschien leuk vindt?',
    Yes: 'Ja',
    No: 'Nee',
    apply: 'toepassen',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Accountgegevens' },
  DeletionFailure: {
    'Ref: Body':
      'Er is een fout opgetreden en uw verzoek voor een account is niet ingediend. Als deze fout aanhoudt, neem dan contact op met uw helpdesk.',
    'Go to account': 'Ga naar uw account',
  },
  GenericFailurePage: {
    'Ref: Title': 'Er is iets fout gegaan',
    'Ref: Body':
      'Sorry, er ging iets mis. Als dit probleem aanhoudt, neem dan contact op met uw ondersteuning van uw on-site.',
  },
  HomePage: {
    notifications: 'meldingen',
    basket: 'Mand',
    'Ref: Tabtitle': 'Startpagina',
    'contact us': 'Neem contact met ons op',
    'Ref: You are logged in as a guest user': 'U bent aangemeld als gastgebruiker',
    'Ref: Good morning': 'Goedemorgen',
    'Ref: Good afternoon': 'Goedemiddag',
    'Ref: Good evening before midnight': 'Goedenavond',
    'Ref: Good evening after midnight': 'Goedenavond',
    'Ref: Search': 'Zoekopdracht',
    'Ref: products button aria': 'Gevonden {results_form} binnen producten',
    'Ref: menus button aria': "Gevonden {results_form} binnen menu's",
    'Ref: facilities button aria': 'Gevonden {results_form} binnen faciliteiten',
    'Ref: result': 'resultaat',
    'Ref: results': 'resultaat',
    'Ref: Menus': "Menu's",
    'Ref: No results title': 'Oeps!',
    'Ref: No results body': 'Geen resultaten die overeenkomen met uw zoekopdracht',
    'Ref: Products': 'Producten',
    'Ref: Facilities': 'Faciliteiten',
    'Ref: See more results': 'Zie meer zoekresultaten',
    'Ref: Accessibility instructions':
      'Om de zoekopdracht te gebruiken, moet u erop klikken of op Enter drukken terwijl u deze hebt gefocust.',
    'Ref: See more': 'Bekijk meer',
    'Ref: singular form of products': 'Product',
    'Ref: singular form of menus': 'menu',
    'Ref: singular form of facilities': 'faciliteit',
    'Ref: Search results for': 'zoekresultaten voor',
  },
  LanguagePage: {
    apply: 'toepassen',
    'My language': 'Mijn taal',
    'Select in which language you would like to read our app':
      'Selecteer in welke taal u onze app wilt gebruiken',
    'Ref: Page title': 'Taal',
  },
  LegalDocPage: {
    Acknowledge: 'Bevestig',
    'Ref: Legal changed notification title': 'De "{legal_doc_type}" zijn gewijzigd',
    'Ref: Legal changed notification content': 'Bekijk ze voordat je de app verder gebruikt.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'algemene voorwaarden',
    'Read privacy policy': 'Lees het Privacybeleid',
    'Ref: Acknowledge legal docs': 'Ik accepteer deze algemene voorwaarden.',
    'Ref: Read and understood': 'Ik heb gelezen en begrepen ',
    'Ref: No legal docs available - notice title': 'Komt binnenkort',
    'Ref: No legal docs available - notice body':
      'Sorry, het lijkt erop dat deze applicatie momenteel niet beschikbaar is in jouw locatie.',
    'Ref: The privacy Policy': 'het privacybeleid',
    'Ref: Legal changed notification title': 'De "{legal_doc_type}" zijn gewijzigd',
    'Ref: Legal changed notification content': 'Bekijk ze voordat je de app verder gebruikt.',
  },
  LoginPage: {
    'Ref: Page title': 'Welkom bij {app name} ',
    'Ref: Page subtitle': 'Log in om door te gaan',
    'Ref: Email field placeholder': 'Jouw email',
    'Ref: Email field note': 'We zullen uw e-mail nooit met iemand delen.',
    'Ref: Password field title': 'Uw wachtwoord',
    register: 'Inschrijven',
    'I forgot my password.': 'Wachtwoord vergeten?',
    'I have trouble logging in': 'Ik heb een probleem met inloggen?',
    'start registration': 'Start registratie',
    'Ref: Register': 'Register',
    'Ref: Login': 'Log in',
    'Ref: Connection issue?': 'Verbindingsprobleem?',
    'Ref: Connection issue message':
      'Als u een probleem heeft om zich te registreren of in te loggen, neem dan contact op met het gebruik van personeel ter plaatse of de hulpbureau.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Hallo en welkom bij {site_name}', 'Ref: Begin': 'Beginnen' },
  NotificationsList: {
    'Ref: No notifications': 'U hebt op dit moment geen meldingen',
    'Ref: This week': 'Deze week',
    'Ref: Earlier': 'Eerder',
    'Ref: Page title': 'Meldingen',
    'Ref: See more': 'Bekijk meer',
    'Ref: Mark all read': 'Markeer alles als gelezen',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Zie alle meldingen' },
  ServicesMenuPage: { 'Ref: Page title': 'Diensten', navigate: 'navigeren' },
  DeletionSuccess: {
    'Ref: Title': 'De verwijdering van uw account is gevraagd',
    'Ref: Content':
      'We zullen dit snel afhandelen. U kunt uw toegang op elk gewenst moment verliezen.',
    'Log out': 'Uitloggen',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'We houden u op de hoogte',
    'Ref: Body': 'Het spijt ons dat u problemen ondervindt. We nemen snel contact met u op.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Heeft U problemen met inloggen?',
    'Ref: Email field placeholder': 'Jouw e-mailadres',
    'Ref: Mobile phone field placeholder': 'Mobiele nummer',
    Company: 'Bedrijf',
    'Ref: Company field placeholder': 'Bedrijfsnaam',
    'Ref: Company field note':
      'Als u een consultant bent, bedoelen we hier het bedrijf waarvoor u momenteel in opdracht werkt, niet uw werkgever.',
    Location: 'Stad of locatie',
    'Ref: Location field placeholder': 'Locatie van je werkplek',
    'Ref: Location field note':
      'Indien buiten een bewoond gebied, gebruik dan gerust om de naam waarmee men gewoonlijk naar het geboed verwijst.',
    Comment: 'Commentaar',
    'Ref: Comment field placeholder': 'Wat ging er mis?',
    'Ref: Who is your employer?': 'Wie is uw werkgever?',
    'Ref: What village are you staying at?': 'In welk dorp verblijf je?',
    'Ref: Comment field note': 'Alle informatie die ons kan helpen om u te helpen!',
    'Ref: Feedback Content - geography': 'Locatie',
    'Ref: Feedback Content - fullName': 'Naam',
    'Ref: Feedback Content - email': 'E-mail',
    'Ref: Feedback Content - phone': 'Telefoon',
    'Ref: Feedback Content - company': 'Bedrijf',
    'Ref: Feedback Content - city': 'Stad / plaatsnaam',
    'Ref: Feedback Content - whatVillage': 'Dorp',
    'Ref: Feedback Content - employer': 'Werkgever',
    'Ref: Feedback Content - comment': 'Wat ging er mis',
  },
  AccountStartPage: {
    'Ref: Header': 'Kies uw locatie om te starten',
    'Ref: Body':
      'Zoek snel een locatie door de lokalisatie te activeren, een QR -code te scannen of op naam te zoeken',
    'Ref: LetsGo button': 'Laten we beginnen',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Registratie' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Selecteer Site met QR -scanner',
    'Ref: Page title': 'kies je locatie',
    'Ref: Select site with code': 'Selecteer Site met code',
    'Ref: Enter site code': 'Voer de sitecode in',
    'Ref: Scanner error header': 'Site niet gevonden',
    'Ref: Not found': 'Deze code is niet geldig in de geselecteerde locatie',
    'Ref: Generic error': 'Er is iets fout gegaan',
    'All other sites': 'Alle andere sites',
    'Sites in your country': 'Sites in uw land',
    'Ref: Unauthorized error': 'Ongeautoriseerd verzoek vanwege sitebeperkingsniveau',
    'Ref: Forbidden':
      'Sorry, u hebt niet de juiste machtigingen om toegang te krijgen tot deze site. Neem contact op met het Pilbara Service Center op 1800 992 777 om toegang te regelen',
    "Ref: We didn't find the site.": 'We hebben de site niet gevonden.',
    'Ref: We recommend some solutions:': 'We raden enkele oplossingen aan:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Probeer uw site toe te voegen met QR -scanner of met behulp van onderstaande knoppen',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Log uit en log weer in en selecteer het juiste land.',
    'Ref: The currently selected country is:': 'Het momenteel geselecteerde land is:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Registratie',
    'Confirm password': 'bevestig wachtwoord',
    'Email is not valid': 'E-mail is niet geldig',
    'passwords do not match': 'Wachtwoorden komen niet overeen.',
    'Ref: Password strength':
      'Uw wachtwoord moet minimaal 8 tekens lang zijn, ten minste één nummer, één hoofdletter, één kleine letter en één speciaal teken bevatten.',
    'Sign up': 'Aanmelden',
    'Continue as guest': 'Ga door als gast',
    'Already have an account? Login': 'Heb je al een account? Log in',
    'Ref: Registration success title': 'bedankt voor het registreren',
    'Ref: Registration success content':
      'Uw account wordt binnenkort aangemaakt. U ontvangt een e -mail die de creatie bevestigt.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Log in/meld u aan',
    'Ref: Password strength':
      'Uw wachtwoord moet minstens 8 tekens lang zijn en minimaal één cijfer, één hoofdletter, één kleine letter en één speciaal teken bevatten.',
    Stamps: 'Stempels',
    'Order history': 'Bestelgeschiedenis',
    'to earn points': 'om punten te verdienen',
    'to see order history': 'Om ordergeschiedenis te zien',
    'to place an order': 'om een bestelling te plaatsen',
    'Sign up': 'Aanmelden',
    Login: 'Log in',
    'Email is not valid': 'E-mail is niet geldig',
    'passwords do not match': 'Wachtwoorden komen niet overeen.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Bedankt',
    'Ref: Body':
      'Je bent bijna klaar! U ontvangt binnenkort een verificatie -e -mail op het opgegeven adres. Open deze e -mail en klik op de link om uw account te activeren.',
    'Ref: Enter': 'Doorgaan',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Er is een fout opgetreden',
    'Ref: Generic Error Body': 'Probeer het later opnieuw of neem contact op met uw helpdesk.',
    'Ref: Missing IndexDb support':
      'Sorry, u hebt geen toegang tot de applicatie in de incognito -modus of zonder indexdb -ondersteuning. Probeer het opnieuw met een andere browser.',
    'Ref: Resource not found': 'Inhoud die u probeert te openen, bestaat niet.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Inhoud die u probeert te openen, is van een privésite.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Inhoud die u probeert te openen, is niet toegestaan ​​voor uw account.',
    'Ref: TabTitle': 'Fout',
    'Ref: Site link invalid':
      'Deze link is ongeldig, selecteer een andere site of neem contact op met uw helpdesk als het probleem aanhoudt.',
    'Ref: Access Denied': 'Toegang geweigerd. Onvoldoende gebruikersrechten.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Oeps! {robot_emoji}',
    'Ref: Error title': 'Er is iets fout gegaan!',
    'Ref: Error body':
      'Probeer deze pagina te vernieuwen of contact op te nemen met uw helpdesk als het probleem aanhoudt.',
    'Ref: Error ID:': 'Fout -ID: {errorId}',
    'Ref: Back to home': 'Terug naar de startpagina',
  },
  Child: {
    'Ref: Route Not Found':
      'De service die u zoekt, is niet beschikbaar op deze site. Probeer het later opnieuw of neem contact op met uw helpdesk.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Gefeliciteerd, uw nieuwe wachtwoord is succesvol opgeslagen, u hebt nu toegang tot al onze services van uw account.',
    Welcome: 'Welkom',
    'Ref: Welcome message':
      'U hebt zich nu met succes geregistreerd bij de nieuwe Bite -applicatie en U kunt verdergaan met het ontdekken en genieten van al onze diensten.',
  },
  PasswordChange: {
    'Change Password': 'Verander wachtwoord',
    Welcome: 'Welkom',
    'Ref: Password message': 'Uw wachtwoord moet worden gewijzigd omdat het niet veilig genoeg is',
  },
  AccountPasswordChange: {
    'Change your password here': 'Wijzig hier uw wachtwoord',
    'Your old password': 'Uw oude wachtwoord',
    'Type in your new password': 'Typ uw nieuwe wachtwoord in',
    'Re-type in your new password': 'Type opnieuw uw nieuwe wachtwoord in',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'Uw wachtwoord is veranderd' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Er is een fout opgetreden, uw verandering is niet opgeslagen. Probeer het opnieuw.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Reset wachtwoord',
    'Ref: Provide email':
      'Geef het e -mailadres op dat wordt gebruikt bij het registreren op {app_name}. Een e -mail wordt naar u verzonden om uw wachtwoord te resetten.',
    'Ref: Something happened': 'Er is iets gebeurd. Probeer het later opnieuw.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'We hebben een nieuwe e -mail gestuurd. Wacht alstublieft 30 seconden om opnieuw te verzenden.',
    'Go to login page': 'Ga naar de inlogpagina',
    'Resend email': 'Email opnieuw verzenden',
    'Ref: Email sent title': 'Er is een wachtwoord reset e -mail naar u verzonden',
    'Ref: TabTitle': 'Reset wachtwoord',
    'Ref: Email sent msg':
      'De reset -link is naar uw adres verzonden {email_address}. Wacht alstublieft 30 seconden voordat u opnieuw probeert te verzenden.',
  },
  ResetPassword: { 'Enter your new password': 'Typ uw nieuwe wachtwoord' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Uw wachtwoord is succesvol gewijzigd',
    'Go to login page': 'Ga naar de inlogpagina',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Wachtwoord reset mislukt',
    'Ref: Error': 'Er is een fout opgetreden. Probeer het opnieuw',
    'Reset your password': 'Stel je wachtwoord opnieuw in',
    'Go to login page': 'Ga naar de inlogpagina',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Privacybeleid',
    'Ref: Privacy Policy Body':
      'Bij gebruik van dit platform zullen Sodexo SA en de lokale Sodexo entiteit uw persoonsgegevens verwerken om ten minste uw account te beheren en om de diensten   beschikbaar via het platform aan te bieden. Als u meer informatie wenst over de verwerking van uw persoonsgegevens, wie er toegang toe heeft en hoe u uw rechten op uw persoonsgegevens kunt uitoefenen, raadpleeg dan het onderstaande privacybeleid dat op elk moment beschikbaar is in uw account.',
    'Ref: Continue': 'Doorgaan',
    'Ref: Read Privacy Policy': 'Lees het privacybeleid',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Ga naar de winkel' },
  SharePage: { 'Ref: Select a site': 'Selecteer een site' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Selecteer uw land om het privacybeleid te bekijken.',
  },
};
export default labels;
