import { useEffect, useRef } from 'react';

function usePrevious<ValueType>(value: ValueType): ValueType {
  const ref: any = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePrevious;
