const labels = {
  All: {
    'Ref: AccommodationRequestTitle': '숙박 요청',
    'Ref: Describe your request': '귀하의 요청을 설명하십시오',
    'Ref: Confirm your request': '귀하의 요청을 확인하십시오',
    'Ref: Your request': '당신의 요청',
    'Ref: Summary': '요약',
    'Ref: Next': '다음',
    'Ref: Submit': '제출하다',
    'Ref: Me': '나',
    'Ref: Arrival date': '도착 일',
    'Ref: Arrival time': '도착 시간',
    'Ref: Departure date': '출발 날짜',
    'Ref: Departure time': '출발 시각',
    'Ref: request raised for': '이 요청의 영향을받는 사람',
    'Ref: phoneNumber': '전화 번호',
    'Ref: arrivalDate': '도착',
    'Ref: departureDate': '출발',
    'Ref: description': '논평',
    'Ref: open': '열려 있는',
    'Ref: pending': '보류 중',
    'Ref: refused': '거부',
    'Ref: closed': '닫은',
    'Ref: all': '모두',
    'Ref: Arrival': '도착',
    'Ref: Validate': '확인',
    'Ref: site': '사이트 위치',
    'Ref: Update us': '우리를 업데이트하십시오',
    'Ref: email of guest': '손님의 이메일',
    'Ref: GuestAccommodationRequestTitle': '게스트 요청',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': '새로운 요청',
    'Ref: Status': '상태',
    'Ref: Date': '날짜',
    'Ref: Coming arrivals': '도착',
    'Ref: Past arrivals': '과거 도착',
  },
  CreateForm: {
    'Ref: Guest phone number': '손님의 전화 번호',
    'Ref: Your phone number': '전화 번호',
    'Ref: The phone number': '전화 번호',
    'Ref: PhoneNumber error msg': '전화 번호는 유효하지 않습니다',
    'Ref: Comment error msg': '최대 문자 수는 250입니다',
    'Ref: Guest first name': '손님 이름',
    'Ref: Guest last name': '손님 성',
    'Ref: request for': '이 요청은 누구입니까?',
    'Ref: request for me': '나',
    'Ref: request for guest': '다른 사람',
  },
  Details: {
    'Ref: AccommodationRequestDetails': '숙박 요청 세부 정보',
    'Ref: Timeline': '타임 라인',
    'Ref: No updates placeholder': '이 요청에 대한 업데이트는 없습니다',
  },
  CreateThankYou: {
    'Ref: Thank you': '감사합니다',
    'Ref: Success text':
      '우리는 귀하의 요청을 받았으며 지금 조사하고 있습니다. 우리는 곧 당신에게 돌아갈 것입니다.',
    'Ref: Go to home': '집에 가십시오',
    'Ref: Create another request': '다른 요청을 만드십시오',
  },
  CreateError: {
    'Ref: Create error title': '죄송합니다!',
    'Ref: Create error text': '에러가 발생했습니다. 다시 시도 해주세요.',
    'Ref: Retry': '다시 시도하십시오',
  },
  UpdateUs: {
    'Ref: Update us msg':
      '귀하의 요청에 대한 새로운 요소로 업데이트하여 귀하를 지원할 수 있습니다.',
  },
};
export default labels;
