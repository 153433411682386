const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Serviceförfrågan - Helpdesk',
    'Ref: Forbidden heading': 'Förbjuden',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Du administrerar serviceförfrågningar på webbplatsen {siteName}.',
    'Ref: Dates': 'Datum',
    'Ref: SRL list refresh': 'Uppdateringslista',
    'Ref: Export': 'exportera',
    'Ref: Empty/not filled': 'Tom / inte fylld',
    'Ref: Name': 'namn',
    'Ref: Date': 'Datum',
    'Ref: Category': 'Kategori',
    'Ref: User': 'Användare',
    'Ref: Location': 'Plats',
  },
};
export default labels;
