const labels = {
  TimeTable: {
    'leave at': 'leave at',
    'arrive at': 'arrive at',
    at: 'at',
    'Ref: list placeholder': 'Ref: list placeholder',
    'Ref: Page title': 'Ref: Page title',
    'Ref: Departure': 'Ref: Departure',
    'Ref: Arrivals': 'Ref: Arrivals',
    'Ref: minutes': 'Ref: minutes',
    'Ref: no items placeholder': 'Ref: no items placeholder',
  },
};
export default labels;
