import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import * as H from 'history';
import { useSelector } from 'react-redux';

import { IN_APP_BROWSER_FRAMELESS } from '../../../constants';
import { State } from '../../../types/state.types';
import { useSetUserRegionCodeMutation } from '../api/account/accountApi';
import { themeKeys } from '../helpers/helpers';
import { getSSOCallbackUrl, getSSOReturnUrl } from '../helpers/onboardingHelpers';

const useInvokeSsoRedirect = () => {
  const [setUserRegionCodeMutation] = useSetUserRegionCodeMutation();
  const theme = useSelector((state: State) => state.Core.context.theme?.settings || []);

  const invokeSSORedirect = async (
    tokenUrl: string,
    email: string,
    geoCode: string,
    regionCode: string,
    useSystemBrowser: boolean,
    history: H.History<unknown>
  ) => {
    const { platform } = await Device.getInfo();
    const isMobile = Capacitor.isNativePlatform();

    await setUserRegionCodeMutation({
      username: email,
      regionCode: regionCode,
      callbackUrl: getSSOCallbackUrl(email, geoCode),
      isMobile,
    });

    if (useSystemBrowser) {
      const defaultThemeSettings = JSON.parse(process.env.REACT_APP_DEFAULT_THEME_JSON || '[]');

      const fallbackPrimaryColor =
        defaultThemeSettings.find((el: { key: string; value: string }) =>
          themeKeys.primaryColor.includes(el.key)
        )?.value || '#283897';

      const primaryColor =
        theme.find((el: Record<string, string>) => themeKeys.primaryColor.includes(el.key))
          ?.value || fallbackPrimaryColor;

      await Browser.open({
        url: tokenUrl,
        windowName: '_self',
        toolbarColor: primaryColor,
        presentationStyle: 'popover',
      });

      return;
    }

    const SSOInAppBrowser = InAppBrowser.create(
      tokenUrl,
      platform === 'web' ? '_self' : '_blank',
      IN_APP_BROWSER_FRAMELESS
    );
    SSOInAppBrowser.on('loadstart')?.subscribe({
      next: (evt) => {
        if (evt.url.indexOf('token-idp') > -1) {
          SSOInAppBrowser.close();
          history.push(getSSOReturnUrl(email, geoCode));
        }
      },
    });
  };

  return invokeSSORedirect;
};

export default useInvokeSsoRedirect;
