const labels = {
  TimeTable: {
    'leave at': 'לעזוב ב',
    'arrive at': 'הגיע ל',
    at: 'בְּ-',
    'Ref: list placeholder': 'מלא את הטופס כדי לראות את השעות',
    'Ref: Page title': 'אוטובוסים',
    'Ref: Departure': 'יְצִיאָה',
    'Ref: Arrivals': 'כניסות',
    'Ref: minutes': 'דקות',
    'Ref: no items placeholder': 'סליחה, אין אוטובוסים זמינים כרגע.',
  },
};
export default labels;
