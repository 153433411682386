const labels = {
  All: {
    Feedback: 'Penilaian',
    'Ref: Switch site component intro sentence':
      'Umpan balik Anda akan ditautkan ke situs {site name}.',
    Category: 'Kategori',
    Subject: 'Subyek',
    Comment: 'Komentar',
    'Date of your experience': 'Tanggal pengalaman Anda *',
    'Ref: Hint': 'Apakah Anda memiliki masalah atau pekerjaan yang perlu diperbaiki?',
    'Ref: Log Request': 'Catat permintaan',
    'Ref: Describe issue': 'Tolong beri tahu kami tentang pengalaman Anda',
    Date: 'Tanggal',
    'Ref: Share your opinion header':
      'Bagikan pendapat Anda tentang pengalaman Anda di tempat kerja',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restoran / Kafe',
    'Desk / workstation area': 'Area meja / workstation',
    'Meeting room facilities': 'Fasilitas ruang pertemuan',
    'Coffee/tea facilities (pantry)': 'Fasilitas kopi/teh (dapur)',
    'Restrooms / washrooms': 'Toilet / kamar mandi',
    'Reception / Security': 'Penerimaan / Keamanan',
    'Conference center': 'Pusat konferensi',
    'Fitness center': 'Pusat kebugaran',
    Mailroom: 'Ruang surat',
    'Bicycle room': 'Kamar Sepeda',
    'Shuttle services': 'Layanan antar -jemput',
    Lockers: 'Loker',
    Helpdesk: 'Meja Bantuan',
  },
  Create: {
    'Tell us!': 'Beritahu kami!',
    'Ref: All Ears': 'Kita semua telinga!',
    'Ref: Provide Description': 'Harap berikan deskripsi',
    'Ref: Fill-in-the-blank sentence': 'Saya ingin memberikan {0} sehubungan dengan {1}',
    'Ref: Link text to service request module': 'Permintaan atau masalah? Log di sini!',
    'Complaint type': 'Keluhan {emoji}',
    'Feedback type': 'Umpan balik {emoji}',
    'Compliment type': 'Pujian {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Terjadi kesalahan dan umpan balik Anda tidak dikirimkan. Jika kesalahan ini berlanjut, silakan hubungi meja bantuan Anda.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Umpan balik Anda dikirimkan. Tim dukungan tempat kerja kami akan membacanya dengan hati-hati. Jika perlu, kami akan kembali kepada Anda.',
    'New feedback': 'Umpan balik baru',
  },
  CreateFlow: {
    Description: 'Keterangan',
    'Ref: Provide Description': 'Harap berikan tanggal dan deskripsi',
    'Ref: I would like to provide a': 'Saya ingin memberikan a',
    'Ref: in regards to': 'dalam hal',
    'Ref: &': '&',
    'Ref: at': 'pada',
    'Ref: on the': 'di',
  },
};
export default labels;
