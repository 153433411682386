import { GoogleMap, Marker } from '@react-google-maps/api';
import classNames from 'classnames';

import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';

import useMap from './hooks/useMap';
import { MapCustomMarkerPros, MapProps } from './Map.types';
import { myPlaceGeneratedIcon } from './mapIconsGenerator';

import styles from './Map.module.css';

const MapDefaultZoom = 12;

const Map = <T extends object>({
  positionCoordinates,
  center,
  markers,
  height,
  width,
  onMarkerClick,
  fitMapToMarkers,
  zoom = MapDefaultZoom,
  "data-testid": testId,
}: MapProps<T>) => {
  const mapHeight = height ?? '100%';
  const mapWidth = width ?? '100%';
  const mapLibraries = ['geometry'];
  const { onLoad, isLoaded } = useMap({ markers, fitMapToMarkers, mapLibraries });
  const userPlaceIconMarker = myPlaceGeneratedIcon();

  const CustomMarker = (props: MapCustomMarkerPros<T>) => {
    const { data } = props;

    const onClick = () => {
      if (onMarkerClick && data) onMarkerClick(data);
    };

    return <Marker onClick={onClick} {...props} />;
  };

  const markerList = markers?.map((m) => (
    <CustomMarker
      data-testid={`${testId}-map-custom-marker`}
      key={m.coordinates?.latitude}
      position={{ lat: m?.coordinates?.latitude, lng: m?.coordinates?.longitude }}
      icon={m.icon}
      data={m.data}
    />
  ));

  const mapCenter = { lat: center?.latitude, lng: center?.longitude };

  return isLoaded ? (
    <div data-testid={`${testId}-map-wrapper`} className={classNames(styles.mapWrapper)}>
      <GoogleMap
        onLoad={onLoad}
        zoom={zoom}
        mapContainerStyle={{ height: mapHeight, width: mapWidth, borderRadius: '8px' }}
        center={mapCenter}
        options={{ streetViewControl: false, mapTypeControl: false }}
      >
        {positionCoordinates && (
          <Marker
            data-testid={`${testId}-map-marker`}
            position={{
              lat: positionCoordinates.latitude,
              lng: positionCoordinates.longitude,
            }}
            icon={userPlaceIconMarker}
          />
        )}
        {markerList}
      </GoogleMap>
    </div>
  ) : (
    <LoadingPage />
  );
};

export default Map;
