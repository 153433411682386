import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';
import { FormFields } from '../types';

import Create from './Create';
import Details from './Details';
import List from './List';
import Summary from './Summary';

import { getTutorial } from '@/modules/ServiceRequestLight/helpers/helpers';
import Tutorials from '@/modules/ServiceRequestLight/routes/Tutorials';

const Routes = () => {
  const history = useHistory();
  const tutorials = useMemo(() => getTutorial(), []);
  const whatsNewLastUpdate = useMemo(() => new Date('2022-04-20T10:00:00Z'), []);
  const formMethods = useForm<FormFields>({
    mode: 'all',
    defaultValues: {
      title: '',
      category: '',
      affectedPerson: {
        value: '',
        label: '',
      },
      phoneNumber: '',
      buildingText: '',
      roomText: '',
      description: '',
      attachments: [],
    },
  });

  useEffect(() => {
    if (!tutorials?.lastDisplayed || tutorials.lastDisplayed < whatsNewLastUpdate) {
      history.push(pagePaths.Tutorials + '?redirectTo=' + pagePaths.Module);
    }
  }, [tutorials, history, whatsNewLastUpdate]);

  return (
    <FormProvider {...formMethods}>
      <Switch>
        <Route path={pagePaths.Tutorials} exact children={<Tutorials />} />
        <Route path={pagePaths.Module} exact children={<List />} />
        <Route path={pagePaths.Create} exact children={<Create />} />
        <Route path={pagePaths.Summary} exact children={<Summary />} />
        <Route path={pagePaths.Details} exact children={<Details />} />
      </Switch>
    </FormProvider>
  );
};

export default Routes;
