import { memo } from 'react';

import { Skeleton, SkeletonSquare } from '../../../../components/atoms/Skeleton/Skeleton';

import styles from './EventSkeleton.module.css';

const EventListSkeleton: React.FC = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((index) => (
        <div key={index} className={styles.wrapper}>
          <SkeletonSquare className={styles.image} width="72px" />
          <Skeleton className={styles.title} width="25%" />
          <div className={styles.contentArea}>
            <Skeleton className={styles.dummyContent} width="80%" />
            <Skeleton className={styles.dummyContent} width="70%" />
          </div>
          <Skeleton className={styles.socialButton} width="20px" />
        </div>
      ))}
    </>
  );
};

export default memo(EventListSkeleton);
