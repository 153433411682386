import momentjs from 'moment/moment';

import { apiPath, dspApiPath } from '../../../config';
import { APP_NAME } from '../../../constants';
import { getAppSpecificData } from '../../../helpers/misc';
import { serviceTypes, modulesConfig } from '../../config';
import { GetEventsArgs } from '../api/api.types';
import { DATE_FILTER } from '../types';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';
import { REACTIONS } from '@/types/reactions.types';

export const moduleId = 'Events';
export const cacheVersion = '3';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  EventDetails: modulePath + '/:id/details',
};

export const eventsConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      api: baseApi,
      baseUrl: apiPath,
      getEventsListUrl: '/v3/event/list',
      getEventsListParams: ({ siteId, future }: GetEventsArgs) => ({
        siteId,
        startDate: momentjs()
          .add(future ? 0 : -1, 'y')
          .format('YYYY-MM-DD'),
        endDate: momentjs()
          .add(future ? 1 : 0, 'y')
          .format('YYYY-MM-DD'),
      }),
      getEventDetailsUrl: (_: string) => '/v3/event',
      getEventDetailsParams: (eventId: string) => ({
        id: eventId,
      }),
      reactionsUrl: (_: string) => '/v2/event/reaction',
      updateReactionParams: ({
        id,
        reactionType,
      }: {
        id: string;
        reactionType: REACTIONS | null;
      }) => ({ id, reactionType }),
      deleteReactionParams: (id: string) => ({ id }),
      isInterestEnabled: false,
      isReactionsEnabled: false,
      displayPreferencesFilter: true,
    },
    default: {
      api: dspApi,
      baseUrl: dspApiPath,
      getEventsListUrl: '/v1/events',
      getEventsListParams: ({ siteId, future, preferredevents }: GetEventsArgs) => ({
        siteId,
        filterType: future ? DATE_FILTER.FUTURE : DATE_FILTER.PAST,
        preferredevents,
      }),
      getEventDetailsUrl: (eventId: string) => `/v1/events/${eventId}`,
      getEventDetailsParams: (_: string) => undefined,
      reactionsUrl: (eventId: string) => `/v1/events/${eventId}/reaction`,
      updateReactionParams: ({
        id,
        reactionType,
      }: {
        id: string;
        reactionType: REACTIONS | null;
      }) => ({ reactionType }),
      deleteReactionParams: (_id: string) => undefined,
      isInterestEnabled: true,
      isReactionsEnabled: true,
      displayPreferencesFilter: false,
    },
  };

  return getAppSpecificData<{
    api: typeof baseApi | typeof dspApi;
    baseUrl: string;
    getEventsListUrl: string;
    getEventsListParams: (input: GetEventsArgs) => {
      siteId: string;
      startDate?: string;
      endDate?: string;
      filterType?: DATE_FILTER;
      preferredevents?: boolean;
    };
    getEventDetailsUrl: (eventId: string) => string;
    getEventDetailsParams: (eventId: string) =>
      | {
          id: string;
        }
      | undefined;
    reactionsUrl: (eventId: string) => string;
    updateReactionParams: (payload: { id: string; reactionType: REACTIONS | null }) => {
      id?: string;
      reactionType: REACTIONS | null;
    };
    deleteReactionParams: (id: string) => { id: string } | undefined;
    isInterestEnabled: boolean;
    isReactionsEnabled: boolean;
    displayPreferencesFilter: boolean;
  }>(configuration);
};
