const labels = {
  StaysList: {
    'Ref: Page title': 'Stays',
    'Ref: Past': 'Past',
    'Current and future stays': 'Current and future stays',
    'filter label: period': 'Period',
    'Ref: Search': 'Search',
  },
  StayDetails: {
    'Ref: Page title': 'Your stay details',
    'Arrival date': 'Arrival date',
    'Departure date': 'Departure date',
    'Room number': 'Room',
    'Reservation number': 'Reservation Number',
    CheckIn: 'Check In',
    CheckOut: 'Check Out',
    CheckIn_1: 'Check into {lodgeName}, arriving today? (Reservation Number {resNumber})',
    CheckedIn_1: 'You are checked into {lodgeName}, room {roomNumber}.',
    CheckOut_1:
      'Check out {lodgeName} reservation number {resNumber} now? Your room key will be deactivated.',
    CheckedOut_1: '{lodgeName} reservation number {resNumber} has checked out.',
  },
  StaysSuccess: {
    'Ref: Title': 'Thank you for your staying!',
    'Ref: Body': 'Your checkout has been successfully processed.',
    'Ref: Go to home': 'Go to home',
    'Ref: Provide a feedback': 'Provide a feedback',
  },
};
export default labels;
