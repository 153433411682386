import { Geolocation } from '@capacitor/geolocation';
import { useEffect, useState } from 'react';

interface IGeoCoordinates {
  latitude: number;
  longitude: number;
}

type UseLocationParams = {
  getUserLocationOnComponentMount?: boolean;
};

const useGeolocation = (
  { getUserLocationOnComponentMount = false }: UseLocationParams = {
    getUserLocationOnComponentMount: false,
  }
) => {
  const [geoLocation, setGeoLocation] = useState<IGeoCoordinates | null>();
  const [isGeoLoading, setIsGeoLoading] = useState(false);

  const getGeoLocation = async () => {
    try {
      setIsGeoLoading(true);
      const position = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });
      setGeoLocation({ longitude: position.coords.longitude, latitude: position.coords.latitude });
    } catch (error) {
      // User refused the geolocation
      console.error(error);
    } finally {
      setIsGeoLoading(false);
    }
  };

  useEffect(() => {
    if (getUserLocationOnComponentMount) {
      getGeoLocation();
    }
  }, [getUserLocationOnComponentMount]);

  return { geoLocation, getGeoLocation, isGeoLoading };
};

export default useGeolocation;
