export function getFlagClassName(isoCode: string | undefined): string | undefined {
  if (!isoCode) {
    return undefined;
  }

  const isoCodeLower = isoCode.toLowerCase();

  switch (isoCodeLower) {
    case 'uk':
      return 'fi fi-gb';
    case 'na':
      return 'fi fi-us';
    default:
      return `fi fi-${isoCodeLower}`;
  }
}
