import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { Tag } from './Tag';
import { TAG_VARIANT, TILE_VARIANT, TileProps } from './Tile.types';
import { TileActions } from './TileActions';

import Loader from '@/components/atoms/Loader';

import styles from './Tile.module.css';

const clickStopBubbling: React.MouseEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
  e.currentTarget.blur();
};

const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
  if (e.key === 'Enter' || e.key === ' ') {
    e.stopPropagation();
    e.currentTarget.click();
  }
};

export const Tile: React.FC<TileProps> = ({
  title,
  oneLineTile,
  variant = TILE_VARIANT.STANDARD,
  description,
  oneLineDescription,
  childText,
  strikethroughChildText,
  image,
  cornerContent,
  isHighlight = false,
  customContent,
  chips = [],
  stickers = [],
  tags = [],
  actions = [],
  actionsCollapseAfter,
  actionsWithLabel = false,
  popoverPosition,
  onClick,
  onCornerClick,
  moreActionText,
  className,
  'data-testid': dataTestId = 'tile',
  children,
  fullChildrenHeight = false,
  dark = false,
  belowDescription,
  belowTitle,
  isLoading,
  preventBubblingForCorner = false,
  focusOnRender = false,
  ...props
}) => {
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  /**
   * Actually Safari doesn't want to apply multiline clamp on first renderer, so we need
   * to trigger another paint to make it works.
   */

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    focusOnRender && setTimeout(() => divRef.current?.focus(), 1000);
  }, [focusOnRender]);

  const renderChips = () =>
    chips.map((chip) => (
      <Tag
        key={chip.name}
        name={chip.name}
        icon={chip.icon}
        color={chip.color}
        variant={TAG_VARIANT.CHIP}
      />
    ));

  const renderStickers = () =>
    stickers.map((sticker) => (
      <Tag key={sticker.name} name={sticker.name} icon={sticker.icon} color={sticker.color} />
    ));

  const checkStickersOrChips = () => stickers?.length > 0 || chips?.length > 0;

  useEffect(() => {
    if (descriptionRef.current) {
      setTimeout(() => descriptionRef.current?.classList.add(styles.clampedTitle), 0);
    }
  }, []);

  return (
    <div
      className={classNames(styles.container, {
        [styles.dark]: dark,
      })}
    >
      <div
        onClick={onClick}
        tabIndex={0}
        ref={divRef}
        onKeyDown={handleKeyDown}
        className={classNames(className, {
          [styles.component]: true,
          [styles.withoutImage]: !image,
          [styles.highlight]: isHighlight,
          [styles.componentWithClick]: onClick,
          [styles.variantBig]: variant === TILE_VARIANT.BIG,
          [styles.withChildren]: children,
          [styles.actionsWithLabelComponent]: actionsWithLabel,
        })}
        {...props}
        data-testid={dataTestId}
      >
        {image && <div className={styles.image}>{image}</div>}
        {cornerContent && (
          <div
            onClick={(preventBubblingForCorner || onCornerClick) && clickStopBubbling}
            className={styles.corner}
            onKeyDown={(preventBubblingForCorner || onCornerClick) && handleKeyDown}
          >
            <div
              onClick={onCornerClick}
              data-testid={`${dataTestId}-corner`}
              onKeyDown={onCornerClick && handleKeyDown}
            >
              {cornerContent}
            </div>
          </div>
        )}
        <div
          className={classNames({
            [styles.details]: true,
            [styles.withoutImage]: !image,
            [styles.withoutActions]: !(actions?.length > 0) && !children,
          })}
        >
          <div>
            {customContent && !checkStickersOrChips() && (
              <div className={styles.customContent}>{customContent}</div>
            )}

            {customContent && checkStickersOrChips() && (
              <div className={styles.contentContainer}>
                <div className={styles.customContent}>{customContent}</div>
                <div className={classNames(styles.stickers, styles.stickers_and_chips)}>
                  {chips?.length > 0 && renderChips()}
                  {stickers?.length > 0 && renderStickers()}
                </div>
              </div>
            )}

            {!customContent && checkStickersOrChips() && (
              <div className={classNames(styles.stickers, styles.stickers_and_chips)}>
                {chips?.length > 0 && renderChips()}
                {stickers?.length > 0 && renderStickers()}
              </div>
            )}
            <h2 className={classNames({ [styles.title]: true, [styles.oneLine]: oneLineTile })}>
              {title}
            </h2>
            {belowTitle && (
              <div
                className={classNames(styles.description, {
                  [styles.oneLine]: oneLineDescription,
                })}
              >
                <p ref={descriptionRef}>{belowTitle}</p>
              </div>
            )}
          </div>
          <div>
            {description && (
              <div
                className={classNames(styles.description, {
                  [styles.oneLine]: oneLineDescription,
                })}
              >
                <p ref={descriptionRef}>{description}</p>
              </div>
            )}
            {tags?.length > 0 && (
              <div className={classNames(styles.stickers, styles.tags)}>
                {tags.map((tag) => (
                  <Tag
                    key={tag.name}
                    name={tag.name}
                    icon={tag.icon}
                    color={tag.color}
                    element={tag.element}
                  />
                ))}
              </div>
            )}
            {belowDescription}
          </div>
        </div>

        {actions?.length > 0 && (
          <div
            onClick={clickStopBubbling}
            className={actionsWithLabel ? styles.actionsWithLabel : styles.actions}
            data-testid={`${dataTestId}-actions`}
            onKeyDown={handleKeyDown}
          >
            {isLoading ? (
              <div className={styles.scaleDownLoader}>
                <Loader data-testid={dataTestId}/>
              </div>
            ) : (
              <TileActions
                actions={actions}
                moreActionText={moreActionText}
                popoverPosition={popoverPosition}
                actionsCollapseAfter={actionsCollapseAfter}
                data-testid={`${dataTestId}-actions`}
              />
            )}
          </div>
        )}

        {children && (
          <div
            className={classNames({
              [styles.childrenContainer]: true,
              [styles.withoutImage]: !image,
              [styles.fullHeight]: fullChildrenHeight,
            })}
            data-testid={`${dataTestId}-children`}
            onKeyDown={handleKeyDown}
          >
            {childText && (
              <div className={classNames(styles.childTextContainer)}>
                <span className={styles.childText}>
                  {strikethroughChildText ? (
                    <>
                      <span>{childText}</span>
                      <span className={styles.strikethroughChildText}>
                        {strikethroughChildText}
                      </span>
                    </>
                  ) : (
                    childText
                  )}
                </span>
              </div>
            )}
            {children && (
              <div
                className={classNames(styles.childrenContentContainer)}
                onClick={clickStopBubbling}
                onKeyDown={handleKeyDown}
              >
                {children}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
