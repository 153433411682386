import { APP_NAME } from '../../constants';
import { serviceTypes, modulesConfig } from '../config';

import { getAppSpecificData } from '@/helpers/misc';

export const moduleId = 'ServiceRequest';
export const cacheVersion = '1';

export const initialize = () => {
  //tmp
  return true;
};

export const statusMap = {
  completed: 'closed',
  cancelled: 'closed',
  pending: 'pending',
  'on hold': 'pending',
  local: 'pending',
  'in progress': 'open',
};

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  Home: modulePath,
  Create: modulePath + '/create',
  Comment: modulePath + '/:id/comment',
  ComplainSuccess: modulePath + '/:id/complain_success',
  Details: modulePath + '/:id/details',
  Feedback: modulePath + '/:id/feedback',
  FeedbackSuccess: modulePath + '/:id/feedback_success',
  Tutorials: modulePath + '/tutorials',
  QrCreate: modulePath + '/qrcreate/:siteId/:locationId',
  QrLandingPage: modulePath + '/qr/:siteId/:locationId',
};

export const requestCategories = [
  'Cleaning',
  'Laundry services',
  'Pest control services',
  'Grounds maintenance',
  'Soft services',
  'Hard services',
  'Waste management',
  'Asset Lifecycle Services',
  'Transportation Services',
];

export const longAnswerMaxLength = 1200;

export const SERVICE_REQUEST_STEPS = {
  LOCATE: 'locate',
  MANUAL_DESCRIBE: 'manualDescribe',
  DESCRIBE: 'describe',
  CONFIRM: 'confirm',
  SUCCESS: 'success',
  FAILED: 'failed',
};

function getTunnelConfiguration() {
  const configurations = {
    [APP_NAME.MYVILLAGE]: {
      initialStep: SERVICE_REQUEST_STEPS.MANUAL_DESCRIBE,
      steps: [
        SERVICE_REQUEST_STEPS.MANUAL_DESCRIBE,
        SERVICE_REQUEST_STEPS.CONFIRM,
        SERVICE_REQUEST_STEPS.SUCCESS,
        SERVICE_REQUEST_STEPS.FAILED,
      ],
      pathToLocate: '',
      pathToDescribe: `${pagePaths['Create']}/describe`,
    },
    default: {
      initialStep: SERVICE_REQUEST_STEPS.LOCATE,
      steps: [
        SERVICE_REQUEST_STEPS.LOCATE,
        SERVICE_REQUEST_STEPS.DESCRIBE,
        SERVICE_REQUEST_STEPS.CONFIRM,
        SERVICE_REQUEST_STEPS.SUCCESS,
        SERVICE_REQUEST_STEPS.FAILED,
      ],
      pathToLocate: `${pagePaths['Create']}/locate`,
      pathToDescribe: `${pagePaths['Create']}/describe`,
    },
  };

  return getAppSpecificData(configurations);
}

export const tunnelConfiguration = getTunnelConfiguration();

export const getServiceRequestConfigurations = () => {
  const configurations = {
    [APP_NAME.MYVILLAGE]: {
      requestTypesFromApi: true,
      qrScannerWhitelist: ['50754440-a7d2-e911-a812-000d3a6aa0b3'] as string[],
    },
    default: {
      requestTypesFromApi: false,
      qrScannerWhitelist: [] as string[],
    },
  };

  return getAppSpecificData(configurations);
};

export const getDetailsSectionsConfiguration = () => {
  const fields = {
    [APP_NAME.MYVILLAGE]: { timeline: true, conversation: false, updateUs: false, feedback: false },
    default: { timeline: false, conversation: true, updateUs: true, feedback: true },
  };

  return getAppSpecificData(fields);
};

export const serviceRequestStatus = {
  sent: 'Sent',
  received: 'Received',
  inProgress: 'In Progress',
  completed: 'Completed',
};

export const getApiEndpoints = () => {
  const configurations = {
    [APP_NAME.MYVILLAGE]: {
      getServiceRequests: '/v2/servicerequest/list',
      getServiceRequest: '/v2/servicerequest',
      create: '/v2/servicerequest',
      feedback: '/v2/feedback',
      validateQrCode: '/v2/servicerequest/validateqrcode',
    },
    default: {
      getServiceRequests: '/v1/servicerequests',
      getServiceRequest: `/v1/servicerequests/:serviceRequestId`,
      create: '/v1/servicerequests',
      feedback: '/v2/feedback',
      validateQrCode: '/v1/servicerequests/validateqrcode',
    },
  };

  return getAppSpecificData(configurations);
};
