import classNames from 'classnames';

import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import AdditionalComment from '../AdditionalComment';
import QuestionAttachments from '../QuestionAttachments';

import {
  QuestionComponentProps,
  QuestionInputsProps,
  QuestionTypes,
} from './QuestionComponent.types';

import { SingleChoice, StarRating, MultiChoice, SingleLineText, MultiLineText } from './';

import styles from './QuestionComponent.module.css';

const QuestionComponent = ({
  questionType,
  questionResponse,
  text,
  control,
  isRequired,
  isDisabled,
  error,
  label,
  ...props
}: QuestionComponentProps) => {
  const questionInputs: QuestionInputsProps = {
    [QuestionTypes.SingleChoice]: SingleChoice,
    [QuestionTypes.MultiChoice]: MultiChoice,
    [QuestionTypes.StarRating]: StarRating,
    [QuestionTypes.SingleLineText]: SingleLineText,
    [QuestionTypes.MultiLineText]: MultiLineText,
  };

  const Component = questionInputs[questionType];

  const shouldRenderAdditionalFields = ![
    QuestionTypes.SingleLineText,
    QuestionTypes.MultiLineText,
  ].includes(questionType);

  const { response, additionalComment, attachments } = questionResponse || {};

  const additionalCommentProps = {
    label,
    isDisabled,
    isCommentRequired: props.isCommentRequired,
    name: `comment_${props.id}`,
    control,
    value: additionalComment,
    rules: {
      required: props.isCommentRequired ? label('Ref: description required') : '',
    },
  };

  const rules = {
    required: isRequired,
  };

  return Component ? (
    <Card
      className={classNames('mb-L', {
        [styles.error]: error,
      })}
      data-testid="question"
    >
      <Title size={TITLE_SIZE.BODYSBOLD} className={classNames('mb-S')}>
        {text}
        {isRequired && ' *'}
      </Title>
      <Component
        {...props}
        control={control}
        rules={rules}
        disabled={isDisabled}
        value={response}
        data-testid={`question-input-${questionType}`}
      />
      {shouldRenderAdditionalFields && (
        <AdditionalComment {...additionalCommentProps}></AdditionalComment>
      )}
      <QuestionAttachments
        name={`attachments_${props.id}`}
        control={control}
        attachments={attachments}
        isComplete={props.isComplete}
        isDisabled={isDisabled}
        label={label}
      />
    </Card>
  ) : null;
};

export default QuestionComponent;
