import classNames from 'classnames';
import { Link } from 'react-router-dom';

import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { pagePaths } from '../config';
import { useSiteTranslation } from '../hooks/useSiteTranslation';
import { SwitchSiteParams } from '../types/sites.types';

import useSite from '@/modules/Core/hooks/useSite';

import styles from './SwitchSite.module.css';

const SwitchSite = ({ sentence, redirectTo }: SwitchSiteParams) => {
  const currentSite = useSite({ throwWhenNoActiveSite: true });
  const { label } = useSiteTranslation(__filename);

  if (!currentSite) return <LoadingPage />;

  return (
    <p className={classNames(styles.wrapper)}>
      {sentence.replace('{site name}', currentSite.name) +
        ' ' +
        label('Not on {site name}?', {
          replace: { 'site name': currentSite.name },
          end: 'whitespace',
        }) +
        ' '}
      <Link
        to={{ pathname: pagePaths['Module'], state: { onSwitchSitePath: redirectTo } }}
        style={{ textDecoration: 'underline', fontWeight: 'bold' }}
      >
        {label('Switch site')}
      </Link>
    </p>
  );
};

export default SwitchSite;
