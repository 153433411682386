import { act, screen, cleanup, render, within } from '@testing-library/react';

import * as hooks from '../../../api';
import { AccommodationRequestDetailsResponse } from '../../../api/api.types';
import Details from '../Details';

import { addProviders } from '@/helpers/tests/addProviders';

const mock: { data: AccommodationRequestDetailsResponse; isLoading: boolean } = {
  data: {
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37d',
    referenceNumber: '#0612152',
    title: 'Five Start Stay at Koksijde Air Base',
    status: 'In Progress',
    arrivalDateTime: '2023-12-31T10:25:35Z',
    departureDateTime: '2024-01-02T10:00:00',
    description: 'I need the 5 (Five) star room\nwith a view to the Launch Pad',
    phoneNumber: '+32 2 442 34 49',
    siteName: 'FMG Hamilton site',
    raisedForUserName: 'Michel Hofman',
    affectedPerson: 'Michel Hofman',
    serviceRequestId: 'b1fd459c-39e1-4f6d-bdd9-aaaaaaaaa',
  },
  isLoading: false,
};

jest.mock('../../../api', () => ({
  useGetAccommodationRequestDetailsQuery: jest.fn(),
}));

jest.mock('@/modules/AccommodationRequest/hooks/useAccommodationRequestTranslation', () => {
  return {
    useAccommodationRequestTranslation: () => ({
      label: (key: string) => key,
    }),
  };
});

describe('Accommodation Details', () => {
  afterAll(() => cleanup());

  describe('Initial render', () => {
    beforeEach(async () => {
      (hooks.useGetAccommodationRequestDetailsQuery as jest.Mock).mockReturnValueOnce({
        data: mock.data,
        isFetching: false,
      });
      await act(async () => {
        render(addProviders(<Details />));
      });
    });

    it('should have all fields', async () => {
      expect(screen.getAllByText('Ref: request raised for')).toBeTruthy();

      expect(
        screen.getByTestId('accommodation-request-detais-raised-for-list-item-non-link')
      ).toBeTruthy();

      expect(screen.getAllByText('Ref: site')).toBeTruthy();
      expect(
        screen.getByTestId('accommodation-request-detais-site-name-list-item-wrapper')
      ).toBeTruthy();

      expect(screen.getAllByText('Ref: phoneNumber')).toBeTruthy();
      expect(
        screen.getByTestId('accommodation-request-detais-phone-number-list-item-wrapper')
      ).toBeTruthy();
      expect(screen.getAllByText('Ref: arrivalDate')).toBeTruthy();
      expect(
        screen.getByTestId('accommodation-request-detais-arrival-date-time-list-item-non-link')
      ).toBeTruthy();
      expect(screen.getAllByText('Ref: departureDate')).toBeTruthy();
      expect(
        screen.getByTestId('accommodation-request-detais-departure-date-time-list-item-non-link')
      ).toBeTruthy();
      expect(screen.getAllByText('Ref: description')).toBeTruthy();
      expect(
        screen.getByTestId('accommodation-request-detais-description-list-item-non-link')
      ).toBeTruthy();
    });
  });

  describe('With status', () => {
    beforeEach(() => jest.resetModules());
    it("'In Progress' should display 'Open'", async () => {
      (hooks.useGetAccommodationRequestDetailsQuery as jest.Mock).mockReturnValueOnce({
        data: mock.data,
        isFetching: false,
      });

      await act(async () => {
        render(addProviders(<Details />));
      });

      expect(screen.getByTestId('accommodation-request-status')).toBeTruthy();
      const { getByText } = within(screen.getByTestId('accommodation-request-status'));
      expect(getByText('Ref: open')).toBeTruthy();
    });

    it("'Pending' should display 'Pending'", async () => {
      (hooks.useGetAccommodationRequestDetailsQuery as jest.Mock).mockReturnValueOnce({
        data: { ...mock.data, status: 'Pending' },
        isFetching: false,
      });
      await act(async () => {
        render(addProviders(<Details />));
      });

      expect(screen.getByTestId('accommodation-request-status')).toBeTruthy();
      const { getByText } = within(screen.getByTestId('accommodation-request-status'));
      expect(getByText('Ref: pending')).toBeTruthy();
    });

    it("'Completed' should display 'Closed'", async () => {
      (hooks.useGetAccommodationRequestDetailsQuery as jest.Mock).mockReturnValueOnce({
        data: { ...mock.data, status: 'Completed' },
        isFetching: false,
      });
      await act(async () => {
        render(addProviders(<Details />));
      });

      expect(screen.getByTestId('accommodation-request-status')).toBeTruthy();
      const { getByText } = within(screen.getByTestId('accommodation-request-status'));
      expect(getByText('Ref: closed')).toBeTruthy();
    });

    it("'Refused' should display 'Refused'", async () => {
      (hooks.useGetAccommodationRequestDetailsQuery as jest.Mock).mockReturnValueOnce({
        data: { ...mock.data, status: 'Refused' },
        isFetching: false,
      });
      await act(async () => {
        render(addProviders(<Details />));
      });

      expect(screen.getByTestId('accommodation-request-status')).toBeTruthy();
      const { getByText } = within(screen.getByTestId('accommodation-request-status'));
      expect(getByText('Ref: refused')).toBeTruthy();
    });
  });
});
