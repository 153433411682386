const labels = {
  ContactForm: { 'Ref: Page title': 'Contattaci', 'Ref: Category field value': 'Contattaci' },
  ContactList: {
    'Ref: Page title': 'Contatti',
    'call (action to place a call)': 'chiama',
    'write (action to write a message)': 'scrivere',
    'write (action to write an e-mail)': 'e-mail',
    'Ref: Hint message':
      'Si avverte del fatto che alcuni siti potrebbero non avere una reception. In tal caso, si prega di individuare uno dei telefoni fissi disponibili sul sito.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Si è verificato un errore e il messaggio non è stato inviato. Se questo errore persiste, contattare la tua assistenza.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Il tuo messaggio è stato inviato. Grazie per averci contattato.',
  },
};
export default labels;
