import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { pagePaths, SERVICE_REQUEST_STEPS, tunnelConfiguration } from '../../config';
import { defaultSR, getSRTutorial } from '../../helpers/helpers';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';
import Confirm from '../Confirm';
import Describe from '../Describe/Describe';
import Failed from '../Failed/Failed';
import Locate from '../Locate';
import ManualDescribe from '../ManualDescribe';
import Success from '../Sucess/Success';
import { whatsNewLastUpdate } from '../Tutorials/Tutorials';

import { TunnelSteps } from './CreateTunnel.types';

import Tunnel from '@/components/Tunnel';
import { useGetAccountUserContextQuery } from '@/modules/Core/api/account/userContextApi';
import { ServiceRequestCreationProps } from '@/modules/ServiceRequest/types/types';
import { State } from '@/types/state.types';

const CreateTunnel = () => {
  const { label } = useServiceRequestTranslation(__filename);
  const history = useHistory();
  const location = useLocation();
  const [currentSR, setCurrentSR] = useState(defaultSR);
  const siteId = useSelector((state: State) =>
    state.Core.context.site ? state.Core.context.site.id : undefined
  );
  const tutorials = useMemo(() => getSRTutorial(), []);
  const { data: userData } = useGetAccountUserContextQuery({});
  const { preferredLocation, mobile: userPhoneNumber } = userData ?? { mobile: '' };
  const [disabledFields, setDisabledFields] = useState(false);

  const code = new URLSearchParams(location.search).get('code') || undefined;

  useEffect(() => {
    if (!tutorials?.lastDisplayed || tutorials.lastDisplayed < whatsNewLastUpdate) {
      history.push(
        pagePaths['Tutorials'] + '?redirectTo=' + location.pathname + (code ? `?code=${code}` : '')
      );
    }
  }, [code, history, location, tutorials]);

  useEffect(() => {
    setCurrentSR({
      ...defaultSR,
      isPreferredLocationCheckboxChecked: true,
      myLocation: undefined,
      phoneNumber: userPhoneNumber || '',
      site: siteId,
    });
  }, [siteId, preferredLocation, userPhoneNumber]);

  const handleChange = (createServiceRequest: ServiceRequestCreationProps) => {
    setCurrentSR({ ...currentSR, ...createServiceRequest });
  };

  const {
    isPreferredLocationCheckboxChecked,
    myLocation,
    title,
    category,
    description,
    phoneNumber,
    site,
    buildingText,
    roomText,
    additionalFields,
    raisedFor,
    templateId,
    attachments,
  } = currentSR;

  const { initialStep, steps, pathToLocate, pathToDescribe } = tunnelConfiguration;

  const tunnelSteps: TunnelSteps = {
    locate: {
      id: SERVICE_REQUEST_STEPS.LOCATE,
      path: pagePaths['Create'] + '/locate',
      title: label('Ref: Select a location'),
      component: Locate,
      customProps: {
        isPreferredLocationCheckboxChecked,
        //  previousLocations,
        myLocation,
        handleChange,
      },
      getApplicability: (locationId: string) => locationId === undefined || roomText !== '',
      getNextStepId: () => 'describe',
    },
    describe: {
      id: SERVICE_REQUEST_STEPS.DESCRIBE,
      path: pagePaths['Create'] + '/describe',
      title: label('Ref: Your request'),
      component: Describe,
      customProps: {
        hasLocation: !!(myLocation || roomText),
        assetTypeId: myLocation ? myLocation.assetTypeId : undefined, //tmp todo: review when asset type id returned by api
        myLocation,
        title,
        category,
        description,
        phoneNumber,
        roomText,
        additionalFields,
        raisedFor,
        templateId,
        attachments,
        handleChange,
        previousPath: pagePaths['Create'] + '/locate',
      },
      getApplicability: () => true,
      getNextStepId: () => 'confirm',
    },
    confirm: {
      id: SERVICE_REQUEST_STEPS.CONFIRM,
      path: pagePaths['Create'] + '/confirm',
      title: label('Ref: Summary'),
      component: Confirm,
      customProps: {
        myLocation,
        selectedSiteId: site,
        isPreferredLocationCheckboxChecked,
        title,
        category,
        description,
        phoneNumber,
        roomText,
        buildingText,
        additionalFields,
        raisedFor,
        templateId,
        attachments,
        pathToLocate,
        pathToDescribe,
      },
      getApplicability: () => true,
      getNextStepId: (isSubmittedSuccessfully: boolean) =>
        isSubmittedSuccessfully ? 'success' : 'failed',
    },
    success: {
      id: SERVICE_REQUEST_STEPS.SUCCESS,
      path: pagePaths['Create'] + '/success',
      component: Success,
      customProps: {
        clearDraft: () => {
          const clearSR = {
            ...defaultSR,
            isPreferredLocationCheckboxChecked: true,
          };
          if (currentSR) clearSR.phoneNumber = currentSR.phoneNumber;
          setCurrentSR(clearSR);
        },
      },
      getApplicability: () => true,
      getNextStepId: () => false,
    },
    failed: {
      id: SERVICE_REQUEST_STEPS.FAILED,
      path: pagePaths['Create'] + '/failed',
      component: Failed,
      customProps: {},
      getApplicability: () => true,
      getNextStepId: () => false,
    },
    manualDescribe: {
      id: SERVICE_REQUEST_STEPS.MANUAL_DESCRIBE,
      path: pagePaths['Create'] + '/describe',
      title: label('Ref: Your request'),
      component: ManualDescribe,
      customProps: {
        title,
        category,
        description,
        site,
        buildingText,
        roomText,
        attachments,
        disabledFields,
        setDisabledFields,
        handleChange,
      },
      getApplicability: () => true,
      getNextStepId: () => 'confirm',
    },
  };

  const activeSteps = steps.map((step: string) => tunnelSteps[step]);

  return <Tunnel steps={activeSteps} startStepId={initialStep} exitToPath="" />;
};

export default CreateTunnel;
