const labels = {
  StaysList: {
    'Ref: Page title': 'Vistelse',
    'Ref: Past': 'Dåtid',
    'Current and future stays': 'Nuvarande och framtida vistelser',
    'filter label: period': 'Period',
    'Ref: Search': 'Sök',
  },
  StayDetails: {
    'Ref: Page title': 'Dina vistelseinformation',
    'Arrival date': 'Ankomstdatum',
    'Departure date': 'Avgångsdatum',
    'Room number': 'Rum',
    'Reservation number': 'Bokningsnummer',
    CheckIn: 'Checka in',
    CheckOut: 'Kolla upp',
    CheckIn_1: 'Checka in {lodgeName}, anländer idag? (Bokningsnummer {resNumber})',
    CheckedIn_1: 'Du är checkad in {lodgeName}, rum {roomNumber}.',
    CheckOut_1:
      'Checka ut {lodgeName}  bokningsnummer {resNumber} Nu? Din rumsnyckel kommer att inaktiveras.',
    CheckedOut_1: '{lodgeName} Reservationsnummer {resNumber} har checkat ut.',
  },
  StaysSuccess: {
    'Ref: Title': 'Tack för att du stannar!',
    'Ref: Body': 'Din kassa har framgångsrikt behandlats.',
    'Ref: Go to home': 'Gå till hemmet',
    'Ref: Provide a feedback': 'Ge en feedback',
  },
};
export default labels;
