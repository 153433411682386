export default function getFileName(filePath: string): string {
  const pathComponents = filePath.split(/[\\/]/);
  const fileName = pathComponents[pathComponents.length - 1];

  const fileNameComponents = fileName.split('.');

  if (fileNameComponents.length <= 1) {
    return fileNameComponents[0];
  }

  const fileExtension = fileNameComponents.pop()!.toLowerCase();

  if (fileExtension.match(/jsx?|tsx?$/)) {
    return fileNameComponents.join('.');
  }

  return fileName;
}
