const labels = {
  StaysList: {
    'Ref: Page title': 'Séjour',
    'Ref: Past': 'Passé',
    'Current and future stays': 'Séjours en cours et à venir',
    'filter label: period': 'Période',
    'Ref: Search': 'Recherche',
  },
  StayDetails: {
    'Ref: Page title': 'Détails de votre séjour',
    'Arrival date': "Date d'arrivée",
    'Departure date': 'Date de départ',
    'Room number': 'Chambre',
    'Reservation number': 'Numéro de réservation',
    CheckIn: 'Enregistrement',
    CheckOut: 'Départ',
    CheckIn_1: "Arrivée dans {lodgeName}, aujourd'hui? (Numéro de réservation {resnumber})",
    CheckedIn_1: 'Vous êtes dans {lodgeName}, room {roomnumber}.',
    CheckOut_1:
      'Libérer {lodgeName} Numéro de réservation {resnumber} maintenant? Votre clé de chambre sera désactivée.',
    CheckedOut_1: '{lodgeName} numéro de réservation {reseNumber} a vérifié.',
  },
  StaysSuccess: {
    'Ref: Title': 'Merci pour votre séjour!',
    'Ref: Body': 'Votre paiement a été traité avec succès.',
    'Ref: Go to home': "Aller à l'accueil",
    'Ref: Provide a feedback': 'Donner un avis',
  },
};
export default labels;
