import { Storage, Drivers } from '@ionic/storage';

// Default TTL is two minutes
const DEFAULT_TTL_IN_MILLIS = 60 * 2 * 1000;

export class CacheService {
  public storage: Storage;
  static instance: CacheService | undefined;
  public debug = false;

  constructor(debug = false) {
    this.storage = new Storage({
      name: 'app_cache',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage, Drivers.IndexedDB],
    });

    this.storage.create();
    this.debug = debug;
  }

  log(text: string, ...args: Array<unknown>) {
    if (this.debug) {
      console.log.call(this, text, ...args);
    }
  }

  async getValue(key: string) {
    const value = await this.storage.get(key);
    if (!value) return value;

    this.log(`[CACHE] Retrieved from db ${key}`, value);

    if (value != null && value.expireTime <= Date.now()) {
      this.log(`[CACHE] Deleting ${key}`);
      await this.storage.remove(key);
      return undefined;
    }

    this.log(`[CACHE] Retrieved ${key}`, value.value);

    return value.value;
  }

  async setValue(key: string, value: unknown, ttlInMilliseconds = DEFAULT_TTL_IN_MILLIS) {
    const storeValue = {
      value,
      expireTime: Date.now() + ttlInMilliseconds,
    };

    this.log(`[CACHE] Storing ${key} for ${ttlInMilliseconds}`);

    return this.storage.set(key, storeValue);
  }

  async remove(key: string) {
    this.log(`[CACHE] Removing ${key}`);
    return this.storage.remove(key);
  }

  async keys() {
    return this.storage.keys();
  }

  async flush() {
    return this.storage.clear();
  }

  static getInstance(): CacheService {
    if (!this.instance) {
      this.instance = new CacheService();
    }

    return this.instance;
  }
}
