import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config/default';
import EventDetails from '../routes/Details';

import EventsList from './EventsList/EventsList';

const Routes = ({ children }: { children: React.ReactNode }) => {
  if (children) return <>{children}</>;

  return (
    <Switch>
      <Route path={pagePaths['EventDetails']} children={<EventDetails />} />
      <Route path={pagePaths['Module']} children={<EventsList />} />
    </Switch>
  );
};

export default Routes;
