const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'サービスリクエスト - ヘルプデスク',
    'Ref: Forbidden heading': '禁断',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'サイト{siteName}でサービスリクエストを管理しています。',
    'Ref: Dates': '日付',
    'Ref: SRL list refresh': 'リフレッシュリスト',
    'Ref: Export': '輸出',
    'Ref: Empty/not filled': '空/満たされていない',
    'Ref: Name': '名前',
    'Ref: Date': '日付',
    'Ref: Category': 'カテゴリー',
    'Ref: User': 'ユーザー',
    'Ref: Location': '位置',
  },
};
export default labels;
