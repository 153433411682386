import classNames from 'classnames';
import React from 'react';
import ReactSelect, { components, CSSObjectWithLabel, SingleValue } from 'react-select';

import { ChevronUpIcon } from '../../../assets';

import { DropdownProps } from './Dropdown.types';

import { ChevronDownIcon } from '@/assets/icons';
import { BUTTON_LOOK } from '@/components/atoms/Button';
import { SelectOption } from '@/components/atoms/Select';

import styles from './Dropdown.module.css';

const Dropdown: React.FC<DropdownProps> = ({
  className,
  value,
  id,
  buttonLook = BUTTON_LOOK.PRIMARY,
  options,
  labelText,
  isDisabled,
  hasCustomLabel,
  openUp,
  onChange,
  'data-testid': dataTestId = 'dropdown-input',
}) => {
  const handleOnChange = (option: SingleValue<SelectOption>) => onChange(option as SelectOption);

  return (
    <div data-testid={dataTestId}>
      <ReactSelect
        id={id}
        aria-label={labelText}
        classNamePrefix="reactSelectDropdown"
        className={classNames(className)}
        isSearchable={false}
        value={options?.find(({ value: v }) => v === value)}
        options={options}
        isDisabled={isDisabled}
        styles={{
          valueContainer: (baseStyles: CSSObjectWithLabel) => ({
            display: 'flex',
            flexWrap: 'nowrap',
            lineHeight: '1.25rem',
            fontSize: '0.875rem',
          }),
          control: () => ({}),
          menu: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            width: 'auto',
            marginTop: '0.25rem',
            borderRadius: 'var(--border-radius-md)',
            boxShadow: 'var(--default-shadow)',
            zIndex: 2,
          }),
          option: (provided, state) => ({
            ...provided,
            fontSize: 'var(--font-size-small)',
            padding: 'var(--default_increment)',
            cursor: 'pointer',
            backgroundColor:
              state.isFocused || state.isSelected
                ? 'var(--ion-color-primary-ultralight) !important'
                : provided.backgroundColor,
            color: state.isSelected ? 'var(--ion-color-primary-darker)' : provided.color,
            ':hover': {
              ...provided[':hover'],
              backgroundColor: 'var(--ion-color-primary-ultralight)',
            },
          }),
        }}
        menuPlacement={openUp ? 'top' : 'auto'}
        components={{
          Control: (props) => {
            return (
              <components.Control
                {...props}
                className={classNames(styles.button, {
                  [styles.primary]: buttonLook === BUTTON_LOOK.PRIMARY,
                  [styles.secondary]: buttonLook === BUTTON_LOOK.SECONDARY,
                  [styles.tertiary]: buttonLook === BUTTON_LOOK.TERTIARY,
                  [styles.disabled]: isDisabled,
                })}
              >
                <div className={classNames(styles.buttonValue)}>
                  {props.children}
                  <div className={styles.chevronIcon}>
                    {openUp ? (
                      <ChevronUpIcon width={20} height={20} role="presentation" />
                    ) : (
                      <ChevronDownIcon width={20} height={20} role="presentation" />
                    )}
                  </div>
                </div>
              </components.Control>
            );
          },
          SingleValue: (props) => (
            <div className={styles.value}>
              {hasCustomLabel ? props.data.customLabel : props.children}
            </div>
          ),
          Placeholder: (props) => <div className={styles.placeholder}>{props.children}</div>,
          DropdownIndicator: () => <></>,
          IndicatorSeparator: () => <></>,
          Menu: (props) => <components.Menu {...props} className={classNames(styles.menu)} />,
          Option: (props) => {
            const isEven = props.selectProps.options.indexOf(props.data) % 2 === 1;
            const customClassName = isEven ? styles.optionEven : '';

            return (
              <components.Option {...props} className={customClassName}>
                {props.children}
              </components.Option>
            );
          },
        }}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default Dropdown;
