import { HubConnectionState } from '@microsoft/signalr';
import { act, waitFor } from '@testing-library/react';
import { renderHook } from '@testing-library/react';

import useSignalRHubConnection from './useSignalRHubConnection';

describe('useSignalRHubConnection hook', () => {
  let connectionBuilder: any;
  let hubConnection: any;
  let hubConnectionStartMock: jest.Mock;
  let hubConnectionStopMock: jest.Mock;

  beforeEach(() => {
    hubConnectionStartMock = jest.fn();
    hubConnectionStopMock = jest.fn();

    hubConnection = {
      state: () => HubConnectionState.Disconnected,
      onclose: jest.fn(),
      onreconnected: jest.fn(),
      onreconnecting: jest.fn(),
      start: hubConnectionStartMock,
      stop: hubConnectionStopMock,
    };

    connectionBuilder = {
      build: jest.fn().mockReturnValue(hubConnection),
    };
  });

  afterEach(() => {
    jest.resetAllMocks();
    jest.restoreAllMocks();
    jest.useRealTimers();
  });

  it('should connect to the hub when shouldConnectToHub is true', async () => {
    const { result } = renderHook(() => useSignalRHubConnection(true, connectionBuilder, 0));

    expect(connectionBuilder.build).toHaveBeenCalled();
    expect(hubConnection.start).toHaveBeenCalled();

    expect(result.current.connection).toBeUndefined();
    expect(result.current.connectionState).toBe(HubConnectionState.Disconnected);

    jest.spyOn(hubConnection, 'state').mockReturnValue(HubConnectionState.Connected);

    await waitFor(() => {
      expect(result.current.connection).toBe(hubConnection);
      expect(result.current.connectionState).toBe(HubConnectionState.Connected);
    });
  });

  it('should not connect to the hub when shouldConnectToHub is false', async () => {
    const { result } = renderHook(() => useSignalRHubConnection(false, connectionBuilder, 0));

    expect(connectionBuilder.build).not.toHaveBeenCalled();
    expect(hubConnection.start).not.toHaveBeenCalled();

    expect(result.current.connection).toBeUndefined();
    expect(result.current.connectionState).toBe(HubConnectionState.Disconnected);
  });

  it('should retry call to "connectToHub" when reconnectIntervalInMs > 0 and failed to connect', async () => {
    hubConnectionStartMock.mockRejectedValueOnce('error');
    renderHook(() => useSignalRHubConnection(true, connectionBuilder, 1000));

    await act(async () => {
      jest.useFakeTimers();
    });

    await act(async () => {
      jest.advanceTimersByTime(10000);
    });

    expect(hubConnection.start).toHaveBeenCalledTimes(2);
  });

  it('should retry call to "connectToHub" from "onclose" callback and reconnectIntervalInMs > 0', async () => {
    renderHook(() => useSignalRHubConnection(true, connectionBuilder, 1000));

    await act(async () => {
      jest.useFakeTimers();
    });

    act(() => {
      const onCloseCallback = hubConnection.onclose.mock.calls[0][0];
      // invoke the callback - in running app this would be invoked by signalR client
      onCloseCallback('error');
    });

    await act(async () => {
      jest.advanceTimersByTime(10000);
    });

    expect(hubConnection.start).toHaveBeenCalledTimes(2);
  });

  it('should NOT retry call to "connectToHub" from "onclose" callback and and reconnectIntervalInMs <= 0', async () => {
    renderHook(() => useSignalRHubConnection(true, connectionBuilder, 0));

    await act(async () => {
      jest.useFakeTimers();
    });

    act(() => {
      const onCloseCallback = hubConnection.onclose.mock.calls[0][0];
      // invoke the callback - in running app this would be invoked by signalR client
      onCloseCallback('error');
    });

    expect(hubConnection.start).toHaveBeenCalledTimes(1);
  });

  it('should retry "connectToHub" from "onreconnecting" callback and reconnectIntervalInMs > 0', async () => {
    renderHook(() => useSignalRHubConnection(true, connectionBuilder, 1000));

    await act(async () => {
      jest.useFakeTimers();
    });

    act(() => {
      const onReconnectingCallback = hubConnection.onreconnecting.mock.calls[0][0];
      // invoke the callback - in running app this would be invoked by signalR client
      onReconnectingCallback('connectionId');
    });

    await act(async () => {
      jest.advanceTimersByTime(1000);
    });

    expect(hubConnection.start).toHaveBeenCalledTimes(2);
  });

  it('should NOT retry "connectToHub" from "onreconnecting" callback and and reconnectIntervalInMs <= 0', async () => {
    renderHook(() => useSignalRHubConnection(true, connectionBuilder, 0));

    await act(async () => {
      jest.useFakeTimers();
    });

    act(() => {
      const onReconnectingCallback = hubConnection.onreconnecting.mock.calls[0][0];
      // invoke the callback - in running app this would be invoked by signalR client
      onReconnectingCallback('connectionId');
    });

    expect(hubConnection.start).toHaveBeenCalledTimes(1);
  });
});
