const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Solicitação de serviço - helpdesk',
    'Ref: Forbidden heading': 'Proibido',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Você está administrando solicitações de serviço no site {siteName}.',
    'Ref: Dates': 'datas',
    'Ref: SRL list refresh': 'Atualizar lista',
    'Ref: Export': 'exportar',
    'Ref: Empty/not filled': 'Vazio / não preenchido',
    'Ref: Name': 'Nome',
    'Ref: Date': 'Data',
    'Ref: Category': 'Categoria',
    'Ref: User': 'Do utilizador',
    'Ref: Location': 'Localização',
  },
};
export default labels;
