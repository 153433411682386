import { legalDocType } from '../../config';

export const publicLegalDocTypeCodesToNameMap = {
  224960001: legalDocType.privacy_policy,
  224960010: legalDocType.geolocation,
  224960011: legalDocType.terms_of_use,
};

export const publicLegalDocTypeNamesToCodeMap = {
  [legalDocType.privacy_policy]: 224960001,
  [legalDocType.geolocation]: 224960010,
  [legalDocType.terms_of_use]: 224960011,
};
