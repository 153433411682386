const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Konaklama talebi',
    'Ref: Describe your request': 'İsteğinizi açıklayın',
    'Ref: Confirm your request': 'İsteğinizi onaylayın',
    'Ref: Your request': 'İsteğin',
    'Ref: Summary': 'Özet',
    'Ref: Next': 'Sonraki',
    'Ref: Submit': 'Göndermek',
    'Ref: Me': 'Ben',
    'Ref: Arrival date': 'Varış tarihi',
    'Ref: Arrival time': 'Varış zamanı',
    'Ref: Departure date': 'Ayrılış tarihi',
    'Ref: Departure time': 'Hareket saati',
    'Ref: request raised for': 'Bu talepten etkilenen kişi',
    'Ref: phoneNumber': 'Telefon numarası',
    'Ref: arrivalDate': 'Varış',
    'Ref: departureDate': 'Kalkış',
    'Ref: description': 'Yorum',
    'Ref: open': 'Açık',
    'Ref: pending': 'Askıda olması',
    'Ref: refused': 'Reddetti',
    'Ref: closed': 'Kapalı',
    'Ref: all': 'Tüm',
    'Ref: Arrival': 'Varış',
    'Ref: Validate': 'Doğrulamak',
    'Ref: site': 'Site konumu',
    'Ref: Update us': 'Bizi Güncelle',
    'Ref: email of guest': 'Konuğun e-postası',
    'Ref: GuestAccommodationRequestTitle': 'Misafir Talebi',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Yeni istek',
    'Ref: Status': 'Durum',
    'Ref: Date': 'Tarih',
    'Ref: Coming arrivals': 'Gelecek varışlar',
    'Ref: Past arrivals': 'Geçmiş varışlar',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Konuk telefon numarası',
    'Ref: Your phone number': 'Telefon numaran',
    'Ref: The phone number': 'Telefon numarası',
    'Ref: PhoneNumber error msg': 'Telefon numarası geçerli değil',
    'Ref: Comment error msg': 'Maksimum karakter sayısı 250',
    'Ref: Guest first name': 'Konuk Adı',
    'Ref: Guest last name': 'Misafir Soyadı',
    'Ref: request for': 'Bu istek kim için?',
    'Ref: request for me': 'Ben',
    'Ref: request for guest': 'Başkası',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Konaklama Talebi Ayrıntıları',
    'Ref: Timeline': 'Zaman çizelgesi',
    'Ref: No updates placeholder': 'Bu istek için güncelleme yok',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Teşekkür ederim',
    'Ref: Success text': 'İsteğinizi aldık ve şimdi bakıyoruz. Yakında size geri döneceğiz.',
    'Ref: Go to home': 'Eve gitmek',
    'Ref: Create another request': 'Başka bir istek oluşturun',
  },
  CreateError: {
    'Ref: Create error title': 'Oops!',
    'Ref: Create error text': 'Bir hata oluştu. Lütfen tekrar deneyin.',
    'Ref: Retry': 'Tekrar deneyin',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Sizi en iyi şekilde destekleyebilmemiz için lütfen isteğinizle ilgili yeni bir unsurla bizi güncelleyin.',
  },
};
export default labels;
