const labels = {
  All: {
    'Ref: See item': 'Se objekt',
    'Ref: Print': 'Skriv ut ditt kvitto',
    'Ref: Email': 'Få ditt kvitto via e-post',
    'Ref: Receipt Modal message': 'Hur vill du få ditt kvitto?',
    'Ref: Loyalty Reward': 'Lojalitetsbelöning',
    'Ref: Equation for stamps per item': '1 köp = 1 stämpel',
    'Ref: Paid by employer': 'Det belopp som din arbetsgivare betalar',
    'Ref: Paid by user': 'Det belopp som du betalar',
  },
  ProductsList: {
    'Ref: Page title': 'Beställ',
    'Ref: Facility details': 'Anläggningsinformation',
    'Ref: Search products list': 'Sök',
    'Ref: filter label: menus': 'Meny',
    'Ref: filter label: moment': 'Ögonblick',
    'Ref: filter label: category': 'Kategori',
    'Ref: all': 'Allt',
    'Ref: today': 'I dag',
    'Ref: tomorrow': 'I morgon',
    'Ref: see more': 'Se mer',
    'Ref: see less': 'Se mindre',
    'Ref: reset': 'Återställa',
    'Ref: View in cart': 'Se i varukorg',
    'Ref: You have changed location': 'Du har ändrat plats',
    'Ref: You are now at': 'Du är nu på',
    'Ref: Menu card': 'Menykort',
    'Ref: Table': 'Bordsnummer',
    'Service unavailable': 'Service inte tillgänglig',
    'Ref: Ordering unavailable':
      'Försiktig! Att beställa på denna webbplats är inte tillgänglig just nu, försök senare.',
    'Ref: Filters': 'Filter (allergener och mer)',
  },
  Order: { 'Ref: Page title': 'Beställ' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menyer',
    'Ref: today': 'I dag',
    'Ref: tomorrow': 'I morgon',
    'Ref: Search products list': 'Sökartiklar',
    'Ref: You have changed location': 'Du har ändrat plats',
    'Ref: You are now at': 'Du är nu på',
  },
  ProductDetails: {
    'Ref: Page title': 'Produktinformation',
    'Ref: Serving Size': 'Serveringsstorlek',
    'Ref: Nutrition': 'Näringsdeklaration',
    'Ref: Nutrition adults': 'Vuxna behöver cirka 2000 kcal per dag',
    'Ref: Nutrition Calories': 'Kalorier',
    'Ref: Nutrition Calcium': 'Kalcium',
    'Ref: Nutrition Fat': 'Fett',
    'Ref: Nutrition Saturated Fat': 'Mättat fett',
    'Ref: Nutrition Carbohydrates': 'Kolhydrater',
    'Ref: Nutrition Sugar': 'Socker',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Kolesterol',
    'Ref: Nutrition dietaryFiber': 'Kostfiber',
    'Ref: Nutrition iron': 'Järn',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamin A',
    'Ref: Nutrition vitaminC': 'C-vitamin',
    'Ref: Additives': 'Tillsatser',
    'Ref: Emission': 'Koldioxidutsläpp',
    'Ref: This product generates': 'Denna produkt genererar',
    'Ref: of CO2': 'av CO2',
    'Ref: emissions, which equals a': 'utsläpp, vilket är lika med en',
    'Ref: car trip': 'bilresa',
    'Ref: Ingredients': 'Ingredienser',
    'Ref: Allergens': 'Allergener',
    'Ref: Always there': 'Alltid där',
    'Ref: Allergens selected': 'Allergener valt på filtret:',
    'Ref: minimum required': 'Minsta krävs',
    'Ref: you have to add at least': 'Du måste lägga till åtminstone',
    'Ref: ingredients': 'Ingredienser',
    'Ref: Ingredient': 'ingrediens',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Baserat på din anpassning',
    'Ref: Additives eggs': 'ägg',
    'Ref: Additives fish': 'fisk',
    'Ref: Additives milk': 'mjölk',
    'Ref: Additives nuts': 'nötter',
    'Ref: Additives wheat': 'vete',
    'Ref: Additives peanuts': 'jordnötter',
    'Ref: Additives soy beans': 'sojabönor',
    'Ref: Additives sesame seeds': 'sesamfrön',
    'Ref: Review': 'Recension',
    'Ref: Per portion': 'Per portion',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Allergen selected': 'Allergen vald på sökfilter',
    'Ref: Fitbit Log': 'Logga till Fitbit',
    'Ref: age verification required on delivery': 'Ålderverifiering krävs vid leveransen',
    'Ref: alcohol content notification':
      'Genom att placera och beställa för en av dessa artiklar förklarar du att du är 18 år eller äldre. Alkoholmissbruk är farligt för din hälsa.',
    'Ref: default': 'Standard',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} tjänat om du beställer den här produkten',
    'Ref: Loyalty Card Description':
      '{stamp_form} kommer att läggas till följande lojalitet {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Programmet för lojalitetsstämplar låter dig samla frimärken för belöningar.',
    'Ref: Loyalty Modal Description':
      'När du köper <b> {product_name} kommer 1 stämpel </b> att läggas till <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'och',
    'Ref: Stamp': 'Stämpel',
    'Ref: Stamps': 'Stämplar',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Program',
    'Ref: Loyalty Stamps': 'Lojalitetsstämplar',
    'Ref: See Loyalty Stamps': 'Se lojalitetsstämplar',
    Favorite: 'Favorit',
    'Ref: In favorites': 'Produkten finns redan i favoritlistan. Klicka för att ta bort det.',
    'Ref: Not in favorites':
      'Produkten finns ännu inte i favoritlistan. Klicka för att lägga till det.',
    'Ref: Added to favorites': 'Produkten har lagts till i favoritlistan.',
    'Ref: Removed from favorites': 'Produkten har tagits bort från favoritlistan.',
    'Ref: From': 'Från',
  },
  OrderHistory: { 'Ref: Orders': 'Order', 'Ref: Page Title': 'Mina beställningar' },
  MenuTile: { 'Ref: Scan&Go': 'Skanning & gå', 'Ref: View only': 'Titta enbart' },
  ProductTile: {
    'Ref: Allergens': 'Allergener',
    'Ref: Presence of allergen': 'Närvaro av allergen',
    'Ref: Scan': 'Skanna',
    'Ref: From': 'Från',
    'Ref: Already in product favorite list. Click to remove it.':
      'Redan i produktens favoritlista. Klicka för att ta bort det.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'För närvarande inte läggs till i produktens favoritlista. Klicka för att lägga till det.',
    'Ref: Free': 'Fri',
  },
  MenusWidget: {
    'Ref: no menu': 'Ingen meny för tillfället',
    'Ref: Modal title': 'Meny för dagen',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Plocka upp',
    'Ref: Eat-in': 'Ätte',
    'Ref: Scan&Go': 'Skanning & gå',
    'Ref: Delivery': 'Leverans',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'Hur var {facility_name} ´s tjänst?',
    'Ref: How do you rate the service': 'Hur betygsätter du tjänsten?',
  },
};
export default labels;
