import classNames from 'classnames';
import React, { FC } from 'react';

import useWindowSize from '../../../helpers/hooks/useWindowSize';

import {
  ContainerProps,
  ContainerWithSideBottomSectionProps,
  ContainerWithNotificationProps,
  ContainerWithAdditionalSectionsProps,
} from './Container.types';

import styles from './Container.module.css';

const AllowedChildrenElements = [
  'Column.Side',
  'Column.Main',
  'Column.Complementary',
  'ActionsBar',
  'ActionsBar.Static',
];

const Container: FC<ContainerProps> = ({
  children,
  className,
  'data-testid': dataTestId = 'container',
}) => {
  // TODO create or find some useBreakpoint package
  const { width } = useWindowSize();
  const isMediumAndSmaller = width < 1024;

  const newChildren = [...children];

  // Separate actions Bar to be put into specific place
  const actionsBar = newChildren.find((child: JSX.Element | null) =>
    child?.type?.displayName?.startsWith('ActionsBar')
  );
  if (actionsBar && !isMediumAndSmaller) {
    newChildren.splice(newChildren.indexOf(actionsBar), 1);
  }

  // Walk through components, validate and inject actionsBar
  const filteredChildren = React.Children.map(
    newChildren,
    (child: JSX.Element | null, index: number) => {
      if (!child) {
        return;
      }

      const displayName = child?.type?.displayName;

      // Restrict used components
      if (!AllowedChildrenElements.includes(displayName)) {
        throw new Error(`${displayName} is not allowed as Container children`);
      }

      // Inject ActionsBar into specific column if not in medium and smaller breakpoint
      if (!isMediumAndSmaller) {
        const targetColumn = actionsBar?.props.inMainColumn
          ? ['Column.Main']
          : ['Column.Side', 'Column.Complementary'];

        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [child.props.children, actionsBar]);
        }
      }

      return child;
    }
  );

  // Maximum two columns are supported
  const columns = newChildren.filter((child: JSX.Element | null) =>
    child?.type?.displayName?.startsWith('Column')
  );
  if (columns.length !== 2) {
    throw new Error('Container has to have 2 columns');
  }

  return (
    <section className={classNames(styles.container, className)} data-testid={dataTestId}>
      {filteredChildren}
    </section>
  );
};

Container.displayName = 'Container';

const ContainerWithSideBottomSection: FC<ContainerWithSideBottomSectionProps> = (props) => {
  const { sideBottomSection: thirdSection, children } = props;

  const { width } = useWindowSize();
  const isMediumAndSmaller = width < 1024;

  const newChildren = [...children];

  const filteredChildren = React.Children.map(
    newChildren,
    (child: JSX.Element | null, index: number) => {
      if (!child) {
        return;
      }

      const displayName = child?.type?.displayName;

      if (!isMediumAndSmaller) {
        const targetColumn = ['Column.Side', 'Column.Complementary'];
        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [child.props.children, thirdSection]);
        }
      }

      if (isMediumAndSmaller) {
        const targetColumn = ['Column.Main'];
        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [child.props.children, thirdSection]);
        }
      }

      return child;
    }
  );
  //@ts-ignore //todo
  return <Container {...props}>{filteredChildren}</Container>;
};

ContainerWithSideBottomSection.displayName = 'Container.WithBottomSection';

const ContainerWithNotification: FC<ContainerWithNotificationProps> = (props) => {
  const { notificationContent, children } = props;

  const { width } = useWindowSize();
  const isMediumAndSmaller = width < 1024;

  const newChildren = [...children];

  const filteredChildren = React.Children.map(
    newChildren,
    (child: JSX.Element | null, index: number) => {
      if (!child) {
        return;
      }

      const displayName = child?.type?.displayName;

      if (!isMediumAndSmaller) {
        const targetColumn = ['Column.Main'];
        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [
            notificationContent,
            child.props.children,
          ]);
        }
      }

      if (isMediumAndSmaller) {
        const targetColumn = ['Column.Side', 'Column.Complementary'];
        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [
            notificationContent,
            child.props.children,
          ]);
        }
      }
      return child;
    }
  );
  //@ts-ignore //todo
  return <Container {...props}>{filteredChildren}</Container>;
};

ContainerWithNotification.displayName = 'Container.WithNotification';

const ContainerCentered: FC<ContainerProps> = (props) => {
  const { children, className } = props;

  return (
    <Container className={classNames(styles.centered, className)} {...props}>
      {children}
    </Container>
  );
};

ContainerCentered.displayName = 'Container.ContainerCentered';

const ContainerWithAdditionalSections: FC<ContainerWithAdditionalSectionsProps> = (props) => {
  const { notificationContent, sideBottomSection: thirdSection, children } = props;

  const { width } = useWindowSize();
  const isMediumAndSmaller = width < 1024;

  const newChildren = [...children];

  const filteredChildren = React.Children.map(
    newChildren,
    (child: JSX.Element | null, index: number) => {
      if (!child) {
        return;
      }

      const displayName = child?.type?.displayName;

      if (!isMediumAndSmaller) {
        const targetColumn = ['Column.Main'];
        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [
            notificationContent,
            child.props.children,
          ]);
        }
      }

      if (isMediumAndSmaller) {
        const targetColumn = ['Column.Side', 'Column.Complementary'];
        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [
            notificationContent,
            child.props.children,
          ]);
        }
      }

      if (!isMediumAndSmaller) {
        const targetColumn = ['Column.Side', 'Column.Complementary'];
        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [child.props.children, thirdSection]);
        }
      }

      if (isMediumAndSmaller) {
        const targetColumn = ['Column.Main'];
        if (targetColumn.includes(displayName)) {
          return React.cloneElement(child, child.props, [child.props.children, thirdSection]);
        }
      }

      return child;
    }
  );
  //@ts-ignore //todo
  return <Container {...props}>{filteredChildren}</Container>;
};

ContainerWithAdditionalSections.displayName = 'Container.WithAdditionalSections';

export default Object.assign(Container, {
  WithSideBottomSection: ContainerWithSideBottomSection,
  WithNotification: ContainerWithNotification,
  Centered: ContainerCentered,
  WithAdditionalSections: ContainerWithAdditionalSections,
});
