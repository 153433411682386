import momentjs from 'moment';
import { Provider } from 'react-redux';

import configureStore from '../../store';

import { DecoratorFunction } from './DecoratorFunction.type';

const notificationDate = momentjs().startOf('week').add(1, 'days');

const store = configureStore({
  Core: {
    context: {
      site: { name: 'Storybook Test site' },
    },
    user: {
      contract: {
        id: 'contractId',
      },
    },
    services: {
      list: [],
    },
    notifications: {
      items: [
        {
          id: '7cbb9ded-4504-ee11-913a-0003ff691794',
          title: 'Hey, this is a notification !',
          message: "Hello this is a notification, I'm testing it, I send a message 🎆",
          notificationDate: notificationDate,
          serviceId: '',
          contentId: '',
          surveyId: '',
          eventId: '',
          surveyUrl: '',
          expiryDate: notificationDate.add(5, 'days'),
          acknowledged: false,
          acknowledgedOn: null,
        },
      ],
      total: 1,
      pageIndex: 0,
      pageSize: 10,
      unreadNotificationCount: 1,
    },
    access: { shouldSelectSiteContext: false },
  },
});

const ReduxStoreDecorator: DecoratorFunction = (Story) => (
  <Provider store={store}>
    <Story />
  </Provider>
);

export default ReduxStoreDecorator;
