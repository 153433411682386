import momentjs from 'moment';
import { useEffect, useState } from 'react';

import { DATE_FORMAT } from '../../../../constants';
import { cacheSelectedFilters } from '../../helpers/menuSelector.helper';
import { MenuListFiltering } from '../../types/menuSelector.types';

const useMenuSelectorFilter = (
  menuDateFromUrl: string | undefined,
  defaultFiltersFromCache: MenuListFiltering | null
) => {
  const dateFromFilter = Object.keys(defaultFiltersFromCache?.filter_date || {})[0];

  const selectedDate = menuDateFromUrl || dateFromFilter || momentjs().format(DATE_FORMAT);
  const [menuDate, setMenuDate] = useState<string>(selectedDate);
  const [filtering, setFiltering] = useState<MenuListFiltering>({
    filter_date: {
      [selectedDate]: true,
    },
  });

  useEffect(() => {
    if (menuDateFromUrl) {
      const menuDateFromUrlFiltering = {
        filter_date: {
          [menuDateFromUrl]: true,
        },
      };
      setFiltering(menuDateFromUrlFiltering);
      cacheSelectedFilters(menuDateFromUrlFiltering);
    }
  }, [menuDateFromUrl]);

  return {
    menuDate,
    setMenuDate,
    filtering,
    setFiltering,
  };
};

export default useMenuSelectorFilter;
