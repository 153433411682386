import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { dspApiPath } from '../../../config';
import dspApi from '../../../services/api/dspApi';
import mockResponse from '../mocks/api';

import {
  ServiceRequestLightHelpdeskListResponse,
  GetServiceRequestLightHelpdeskArgs,
  ExportServiceRequestsLightForHelpdeskArgs,
  ExportServiceRequestsLightForHelpdeskFormat,
} from './api.types';

const serviceRequestLightHelpdeskApi = dspApi
  .enhanceEndpoints({ addTagTypes: ['service_request_light_helpdesk'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getServiceRequestLightHelpdeskList: build.query<
        ServiceRequestLightHelpdeskListResponse,
        GetServiceRequestLightHelpdeskArgs
      >({
        queryFn: async ({
          siteId,
          pageNumber,
          pageSize,
          searchTerm,
          startDate,
          endDate,
        }: GetServiceRequestLightHelpdeskArgs) => {
          const baseQuery = axiosBaseQuery({ baseUrl: dspApiPath });

          const response = await baseQuery({
            url: `/v1/servicerequestslight/helpdesk/${siteId}`,
            params: {
              pageNumber: pageNumber || 1,
              pageSize: Number(pageSize) || 10,
              searchTerm,
              startDate,
              endDate,
            },
            method: 'GET',
            mockData: mockResponse.getServiceRequestLightHelpdeskList,
          });

          const result = response.data || {};

          return {
            data: {
              listData: result,
              forbidden: response?.error?.status === 403,
            },
          };
        },
        providesTags: ['service_request_light_helpdesk'],
      }),

      exportServiceRequestLightHelpdeskList: build.query<
        Blob,
        ExportServiceRequestsLightForHelpdeskArgs
      >({
        query: ({
          siteId,
          searchTerm,
          startDate,
          endDate,
          format = ExportServiceRequestsLightForHelpdeskFormat.Csv,
          timeZoneOffsetMinutes = new Date().getTimezoneOffset(),
          useErrorBoundary = false,
        }) => ({
          url: `/v1/servicerequestslight/helpdesk/${siteId}/export`,
          method: 'GET',
          responseType: 'blob',
          params: { format, searchTerm, startDate, endDate, timeZoneOffsetMinutes },
          opts: { useErrorBoundary },
        }),
        providesTags: ['service_request_light_helpdesk'],
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetServiceRequestLightHelpdeskListQuery,
  useLazyGetServiceRequestLightHelpdeskListQuery,
  useLazyExportServiceRequestLightHelpdeskListQuery,
} = serviceRequestLightHelpdeskApi;
export default serviceRequestLightHelpdeskApi;
