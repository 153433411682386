import { useHistory } from 'react-router';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import { pagePaths } from '../../config';
import { useFeedbackTranslation } from '../../hooks/useFeedbackTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const CreateFailure = () => {
  const history = useHistory();
  const { label } = useFeedbackTranslation(__filename);

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.ERROR}
      title={label('sorry (standalone)', { textTransform: 'capitalize' })}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('Go to home'),
          action: () => history.push('/'),
        },
        {
          label: label('retry', { textTransform: 'capitalize' }),
          action: () => history.push(pagePaths['Create']),
        },
      ]}
    />
  );
};

export default CreateFailure;
