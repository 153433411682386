const labels = {
  All: {
    'Ref: See item': 'Veja o item',
    'Ref: Print': 'Imprima seu recibo',
    'Ref: Email': 'Receba seu recibo por e-mail',
    'Ref: Receipt Modal message': 'Como você gostaria de obter seu recibo?',
    'Ref: Loyalty Reward': 'Recompensa de lealdade',
    'Ref: Equation for stamps per item': '1 compra = 1 carimbo',
    'Ref: Paid by employer': 'O valor pago pelo seu empregador',
    'Ref: Paid by user': 'O valor pago por você',
  },
  ProductsList: {
    'Ref: Page title': 'Lista de produtos',
    'Ref: Facility details': 'Detalhes da instalação',
    'Ref: Search products list': 'Itens de pesquisa',
    'Ref: filter label: menus': 'Cardápio',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoria',
    'Ref: all': 'Tudo',
    'Ref: today': 'Hoje',
    'Ref: tomorrow': 'Amanhã',
    'Ref: see more': 'Ver mais',
    'Ref: see less': 'Veja menos',
    'Ref: reset': 'Redefinir',
    'Ref: View in cart': 'Vista no carrinho',
    'Ref: You have changed location': 'Você mudou o local',
    'Ref: You are now at': 'Você está agora em',
    'Ref: Menu card': 'Cardápio',
    'Ref: Table': 'Número da tabela',
    'Service unavailable': 'Serviço não disponível',
    'Ref: Ordering unavailable':
      'Cuidado! O pedido neste site não está disponível no momento, tente mais tarde.',
    'Ref: Filters': 'Filtros (alérgenos e muito mais)',
  },
  Order: { 'Ref: Page title': 'Pedido' },
  MenuSelector: {
    'Ref: Menu Page title': 'menus',
    'Ref: today': 'Hoje',
    'Ref: tomorrow': 'Amanhã',
    'Ref: Search products list': 'Itens de pesquisa',
    'Ref: You have changed location': 'Você mudou o local',
    'Ref: You are now at': 'Você está agora em',
  },
  ProductDetails: {
    'Ref: Page title': 'Detalhes do produto',
    'Ref: Serving Size': 'Porção',
    'Ref: Nutrition': 'Informação nutricional',
    'Ref: Nutrition adults': 'Os adultos precisam de cerca de 2000 kcal por dia',
    'Ref: Nutrition Calories': 'calorias',
    'Ref: Nutrition Calcium': 'Cálcio',
    'Ref: Nutrition Fat': 'Gordura',
    'Ref: Nutrition Saturated Fat': 'Gordura saturada',
    'Ref: Nutrition Carbohydrates': 'Carboidratos',
    'Ref: Nutrition Sugar': 'Açúcar',
    'Ref: Nutrition Protein': 'Proteína',
    'Ref: Nutrition Sodium': 'Sódio',
    'Ref: Nutrition cholesterol': 'Colesterol',
    'Ref: Nutrition dietaryFiber': 'Fibra dietética',
    'Ref: Nutrition iron': 'Ferro',
    'Ref: Nutrition potassium': 'Potássio',
    'Ref: Nutrition vitaminA': 'Vitamina A',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Additives': 'Aditivos',
    'Ref: Emission': 'Emissões de CO2',
    'Ref: This product generates': 'Este produto gera',
    'Ref: of CO2': 'de CO2.',
    'Ref: emissions, which equals a': 'emissões, que é igual a um',
    'Ref: car trip': 'viagem de carro',
    'Ref: Ingredients': 'Ingrediente da receita',
    'Ref: Allergens': 'alérgenos',
    'Ref: Always there': 'Sempre lá',
    'Ref: Allergens selected': 'Alérgenos selecionados no filtro:',
    'Ref: minimum required': 'Mínimo requerido',
    'Ref: you have to add at least': 'Você tem que adicionar pelo menos',
    'Ref: ingredients': 'ingredientes',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Livre',
    'Ref: Based on customization': 'Com base em sua personalização',
    'Ref: Additives eggs': 'ovos',
    'Ref: Additives fish': 'peixe',
    'Ref: Additives milk': 'leite',
    'Ref: Additives nuts': 'nozes',
    'Ref: Additives wheat': 'trigo',
    'Ref: Additives peanuts': 'amendoim',
    'Ref: Additives soy beans': 'Beans de soja',
    'Ref: Additives sesame seeds': 'sementes de Sesamo',
    'Ref: Review': 'Análise',
    'Ref: Per portion': 'Por porção',
    'Ref: Per 100g': 'Por 100g',
    'Ref: Allergen selected': 'Alérgeno selecionado no filtro de pesquisa',
    'Ref: Fitbit Log': 'Log para fitbit',
    'Ref: age verification required on delivery': 'Verificação de idade necessária na entrega',
    'Ref: alcohol content notification':
      'Ao colocar e pedir um desses itens, você declara que tem 18 anos ou mais. O abuso de álcool é perigoso para sua saúde.',
    'Ref: default': 'Predefinição',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ganho se você pedir este produto',
    'Ref: Loyalty Card Description':
      'O {stamp_form} será adicionado à seguinte lealdade {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'O programa de selos de fidelidade permite que você colete selos para recompensas.',
    'Ref: Loyalty Modal Description':
      'Quando você compra <b> {product_name}, 1 carimbo </b> será adicionado ao <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'e',
    'Ref: Stamp': 'Carimbo',
    'Ref: Stamps': 'Selos',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programas',
    'Ref: Loyalty Stamps': 'Selos de lealdade',
    'Ref: See Loyalty Stamps': 'Veja selos de fidelidade',
    Favorite: 'Favorito',
    'Ref: In favorites': 'O produto já está na lista de favoritos. Clique para removê -lo.',
    'Ref: Not in favorites':
      'O produto ainda não está na lista dos favoritos. Clique para adicioná -lo.',
    'Ref: Added to favorites': 'O produto foi adicionado à lista de favoritos.',
    'Ref: Removed from favorites': 'O produto foi removido da lista de favoritos.',
    'Ref: From': 'A partir de',
  },
  OrderHistory: { 'Ref: Orders': 'Ordens', 'Ref: Page Title': 'minhas ordens' },
  MenuTile: { 'Ref: Scan&Go': 'Digitalize e vá', 'Ref: View only': 'Vista apenas' },
  ProductTile: {
    'Ref: Allergens': 'Alérgenos',
    'Ref: Presence of allergen': 'Presença de alérgenos',
    'Ref: Scan': 'Varredura',
    'Ref: From': 'A partir de',
    'Ref: Already in product favorite list. Click to remove it.':
      'Já na lista favorita do produto. Clique para removê -lo.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Atualmente não é adicionado à lista favorita do produto. Clique para adicioná -lo.',
    'Ref: Free': 'Livre',
  },
  MenusWidget: { 'Ref: no menu': 'Sem menu para o momento', 'Ref: Modal title': 'Menu para o dia' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Pegar',
    'Ref: Eat-in': 'Comer',
    'Ref: Scan&Go': 'Digitalize e vá',
    'Ref: Delivery': 'Entrega',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': 'Como foi o serviço {facility_name} »?',
    'Ref: How do you rate the service': 'Como você avalia o serviço?',
  },
};
export default labels;
