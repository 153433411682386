import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import mockResponse from '../mockResponse';
import { ServiceRequest, ServiceRequestType, Site } from '../types/apiResponse.types';

import {
  FeedbackServiceRequestParams,
  GetServiceRequestSiteTemplatesParams,
  GetServiceRequestTypesParams,
  QRCodeResponse,
} from './api.types';

import baseApi from '@/services/api/baseApi';

const gatewayServiceRequestApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['service_requests', 'service_requests_locations'] })
  .injectEndpoints({
    endpoints: (build) => ({
      /**
       * GET '/v2/servicerequests/sites' - get service requests sites
       */
      getServiceRequestsSites: build.query<Site[], {}>({
        queryFn: async () => {
          const baseQuery = axiosBaseQuery();
          const sitesResponse = await baseQuery({
            url: '/v2/servicerequest/sites',
            method: 'get',
            mockData: mockResponse.getServiceRequestsSites,
          });

          const { data: { sites } = {} } = sitesResponse;

          if (!sites) return { data: [] };

          return {
            data: sites,
          };
        },
        providesTags: ['service_requests'],
      }),
      /**
       * GET '/v2/servicerequest/templates' - get service requests templates
       */
      getTemplates: build.query<any, GetServiceRequestSiteTemplatesParams>({
        queryFn: async ({ siteId, assetTypeId }) => {
          const baseQuery = axiosBaseQuery();
          const contactsResponse = await baseQuery({
            url: '/v2/servicerequest/templates',
            method: 'get',
            params: {
              siteId,
              assetTypeId,
            },
            mockData: mockResponse.getTemplates,
          });

          const { data: { data } = {} } = contactsResponse;
          if (!data) return { data: [] };

          return {
            data: data,
          };
        },
        providesTags: ['service_requests'],
      }),
      /**
       * GET /v2/servicerequest/requesttypes - get service requests types
       */
      getServiceRequestsTypes: build.query<ServiceRequestType[], GetServiceRequestTypesParams>({
        queryFn: async ({ siteId }) => {
          const baseQuery = axiosBaseQuery();
          const typesResponse = await baseQuery({
            url: '/v2/servicerequest/requesttypes',
            method: 'get',
            params: {
              siteId,
            },
            mockData: mockResponse.getServiceRequestTypes,
          });

          const { data: { serviceRequestTypes } = {} } = typesResponse;

          if (!serviceRequestTypes) return { data: [] };

          return {
            data: serviceRequestTypes,
          };
        },
        providesTags: ['service_requests'],
      }),

      /**
       * GET /v1/servicerequest - get asset information
       */
      getAssetInformation: build.query<QRCodeResponse, string | null>({
        query: (code: string) => ({
          url: '/v1/servicerequest/asset',
          method: 'get',
          params: { code },
          mockData: mockResponse.getAssetInformation,
        }),
      }),
      /**
       * POST /v1/feedback
       */ feedback: build.mutation<ServiceRequest, FeedbackServiceRequestParams>({
        query: ({
          siteId,
          serviceRequestId,
          title,
          generalSentiment,
          staffInteractionRating,
          answerQualityRating,
          efficiencyRating,
          comment,
          feedbackType,
        }) => ({
          url: '/v2/feedback',
          method: 'post',
          data: {
            siteId,
            serviceRequestId,
            title,
            generalSentiment,
            staffInteractionRating,
            answerQualityRating,
            efficiencyRating,
            comment,
            feedbackType,
            feedbackDate: new Date().toISOString(),
          },
        }),
        invalidatesTags: () => [{ type: 'service_requests' }],
      }),
    }),

    overrideExisting: true,
  });

export const {
  useGetAssetInformationQuery,
  useGetServiceRequestsSitesQuery,
  useGetServiceRequestsTypesQuery,
  useGetTemplatesQuery,
  useFeedbackMutation,
} = gatewayServiceRequestApi;
export default gatewayServiceRequestApi;
