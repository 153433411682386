const labels = {
  All: {
    dailyMenu: 'Thực đơn hàng ngày',
    anytime: 'Bất cứ lúc nào ',
    breakfast: 'Bữa ăn sáng',
    morningBreak: 'nghỉ sáng',
    lunch: 'Bữa ăn trưa',
    afternoonBreak: 'buổi chiều nghỉ ngơi',
    dinner: 'Bữa ăn tối',
    supper: 'Bữa ăn tối',
    nightMeal: 'Bữa ăn khuya',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Thực đơn',
    'Ref: View details': 'Xem chi tiết',
    'Ref: Facility details': 'Chi tiết cơ sở',
  },
  OpeningHours: { everyday: 'Hằng ngày', 'open all day': 'Mở cửa cả ngày' },
  ProductsList: {
    'Ref: Page title': 'Thực đơn',
    'Ref: Facility details': 'Chi tiết cơ sở',
    'Ref: Search products list': 'Tìm kiếm các mục',
    'Ref: filter label: date': 'Ngày',
    'Ref: filter label: menus': 'Thực đơn',
    'Ref: filter label: moment': 'Khoảng khăc',
    'Ref: filter label: category': 'Danh mục',
    'Ref: filter label: dishes': 'Đĩa',
    'Ref: filter label: highlight': 'Làm nổi bật hoặc ẩn các bộ lọc với các món ăn',
    All: 'Tất cả các',
  },
  ProductDetails: { 'Ref: Page title': 'Thông tin chi tiết sản phẩm' },
  Widget: {
    'Ref: Carousel title': 'Thực đơn',
    'No info yet, come back later': 'Chưa có thông tin nào, hãy quay lại sau 😉',
    'Ref: menus': 'Thực đơn',
    'Ref: see all': 'Nhìn thấy tất cả',
  },
};
export default labels;
