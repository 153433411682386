import { getByText, screen } from '@testing-library/react';
import { createMemoryHistory } from 'history';
import { act } from 'react';
import { useParams } from 'react-router';

import { useGetMenusQuery } from '../api/menuApi';
import { Menu } from '../api/menuApi.types';

import ProductDetails from './ProductDetails';

import { renderedComponent } from '@/helpers/tests/renderComponent';

const mockHistory = createMemoryHistory();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: jest.fn(),
  useHistory: () => mockHistory,
}));

const menuId = '2';
const menuItemId1 = '25678';
const facilityId = 'facilityId1';
const menuDate = '2024-01-31';
const siteId = 'siteId';

const menu: Menu = {
  id: menuId,
  facilityId: facilityId,
  name: 'no orderable OMS menu',
  date: menuDate,
  menuItems: [
    {
      menuItemId: menuItemId1,
      name: 'Product 2 no orderable OMS',
      description: 'Product 2 no orderable OMS description',
      genericCategory: 'Burger',
      mealName: 'Tasty burger',
      priceWithTax: 11,
      category: 'Breakfast',
    },
  ],
};

jest.mock('@/modules/Core/hooks/useLanguage', () => ({
  __esModule: true,
  default: () => ({
    currentLanguageCode: 'en-US',
  }),
}));

const omsNoOrderableMenuItemParams = {
  id: menuItemId1,
  facilityId,
  date: menuDate,
  menuId: menuId,
};

jest.mock('../api/menuApi', () => ({
  useGetMenusQuery: jest.fn(),
}));

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => ({
    id: siteId,
    currency: {
      isoCode: 'EUR',
    },
  }),
}));

describe('ProductDetails', () => {
  beforeEach(() => {
    Element.prototype.scrollIntoView = jest.fn();
    (useGetMenusQuery as jest.Mock).mockReturnValue({
      data: [menu],
      isFetching: false,
    });
    (useParams as jest.Mock).mockReturnValue(omsNoOrderableMenuItemParams);
  });

  describe('on render for menu item', () => {
    beforeEach(async () => {
      await act(() => renderedComponent(ProductDetails, {}, {}));
    });

    it('shows sections', async () => {
      expect(screen.queryByTestId('menuItem-tile-viewable')).toBeTruthy();
      expect(screen.getByText('Product 2 no orderable OMS')).toBeTruthy();

      expect(screen.getByTestId('menuItem-image')).toBeTruthy();
      expect(screen.getByTestId('menuItem-description')).toBeTruthy();

      const menuTileViewableContainer = screen.getByTestId('menuItem-tile-viewable');
      expect(getByText(menuTileViewableContainer, '€11.00')).toBeTruthy();
    });
  });
});
