import { fireEvent, screen } from '@testing-library/react';

import renderComponent from '../../../../../../helpers/tests/renderComponent';
import StarRating from '../StarRating';

const props = {
  questionType: 5,
  maximumRating: 5,
  'data-testid': 'star-rating',
};

describe('StarRating', () => {
  describe('Render', () => {
    let starRating: HTMLElement | null;

    renderComponent(StarRating, props);

    it('should render StarRating question type', () => {
      starRating = screen.queryByTestId('star-rating');
      expect(starRating).toBeTruthy();
    });

    it('should render 5 stars', () => {
      const starNo1 = screen.queryByTestId('star-rating-star-0-input');
      const starNo2 = screen.queryByTestId('star-rating-star-1-input');
      const starNo3 = screen.queryByTestId('star-rating-star-2-input');
      const starNo4 = screen.queryByTestId('star-rating-star-3-input');
      const starNo5 = screen.queryByTestId('star-rating-star-4-input');
      expect(starNo1).toBeTruthy();
      expect(starNo2).toBeTruthy();
      expect(starNo3).toBeTruthy();
      expect(starNo4).toBeTruthy();
      expect(starNo5).toBeTruthy();
    });
  });

  describe('On change', () => {
    const onChange = jest.fn();

    renderComponent(StarRating, { ...props, onChange });

    it('should change star rating', () => {
      const starNo3 = screen.queryByTestId('star-rating-star-2-input');

      if (starNo3) {
        fireEvent.click(starNo3);
      }

      const filledStars = screen.queryAllByText('StarFilled-24.svg');

      expect(onChange).toHaveBeenCalledWith(3);
      expect(filledStars.length).toEqual(3);
    });
  });
});
