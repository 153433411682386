const labels = {
  AuditInviteList: {
    'Ref: Page title': 'การตรวจสอบ',
    'Ref: status0': 'เปิด',
    'Ref: status1': 'สมบูรณ์',
    'Ref: status2': 'ยกเลิก',
    'Ref: connected': 'ซึ่งเชื่อมต่อกัน',
    'Ref: not connected': 'ไม่ได้เชื่อมต่อ',
    'Ref: synchronise data': 'ซิงโครไนซ์ข้อมูล',
    'Ref: sync success': 'ข้อมูลซิงโครไนซ์สำเร็จ',
    'Ref: Create new': 'สร้างใหม่',
    'Ref: Audit unsynchronised': 'ไม่ได้ซิงโครไนซ์',
    'Ref: Audit synchronised': 'ซิงโครไนซ์',
    'Ref: Unsynced error title': 'เกิดข้อผิดพลาด',
    'Ref: Unsynced error description':
      'ดูเหมือนว่าจะเป็นครั้งแรกที่คุณเข้าถึงโมดูลการตรวจสอบ โปรดเชื่อมต่อกับเครือข่ายและ“ ซิงโครไนซ์” เพื่อเริ่มการตรวจสอบ',
    'Ref: Search all audits': 'การตรวจสอบทั้งหมด',
    'Ref: Search active audits': 'เปิด',
    'Ref: Search completed audits': 'สมบูรณ์',
    'Ref: sync error': 'การซิงโครไนซ์ล้มเหลว',
    'Ref: sync error details': 'ลองเชื่อมต่ออีกครั้ง',
    'Ref: no search audit results': 'น่าเสียดายที่ไม่มีการตรวจสอบที่สอดคล้องกับการค้นหาของคุณ',
    'Ref: no audit results': 'คุณไม่มีการตรวจสอบที่มีให้สร้างใหม่เพื่อเริ่มต้นใช้งาน',
    'Ref: Search canceled audits': 'ยกเลิก',
    'Ref: Audit cancelled successfully': 'การตรวจสอบยกเลิกสำเร็จ',
    'Ref: Cancel audit invite': 'ยกเลิก',
    'Ref: Error audit cancel': 'การตรวจสอบไม่สามารถยกเลิกได้',
    'Ref: Cancel audit confirmation': 'แน่ใจหรือว่าต้องการยกเลิกการตรวจสอบ?',
    'Ref: Confirm': 'ยืนยัน',
    'Ref: Cancel audit': 'ยกเลิกการตรวจสอบ',
    'Ref: Change the location': 'เปลี่ยนตำแหน่ง',
  },
  AuditResponseForm: {
    'Ref: Page title': 'รายละเอียดการตรวจสอบ',
    'Ref: questions': 'คำถาม',
    'Ref: question': 'คำถาม',
    'Ref: Additional Comments': 'คำอธิบาย',
    'Ref: Not found title': 'อ๊ะ!',
    'Ref: Not found text': 'การตรวจสอบที่คุณพยายามเข้าถึงไม่มีอยู่',
    'Ref: Back to Audit list': 'กลับไปที่รายการตรวจสอบ',
    'Ref: Success title': 'ขอบคุณ!',
    'Ref: Success text': 'คุณได้ทำการตรวจสอบเสร็จแล้ว',
    'Ref: submit': 'ส่ง',
    'Ref: add attachment': 'เพิ่มไฟล์แนบ',
    'Ref: Unsupported elements':
      'ขออภัยดูเหมือนว่าการตรวจสอบนี้มีเนื้อหาที่แอปพลิเคชันยังไม่รองรับ เราจะทำงานกับมัน',
    'Ref: form error': 'ไม่ใช่ทุกคำถามที่ได้รับคำตอบอย่างเต็มที่',
    'Ref: form complete': 'เสร็จสมบูรณ์ตั้งแต่',
    'Ref: attachment preview error': 'คุณต้องออนไลน์เพื่อดูไฟล์แนบนี้',
  },
  AuditCreationForm: {
    'Ref: Page title': 'สร้างใหม่',
    'Ref: Location': 'ที่ตั้ง',
    'Ref: Audit Stream Type': 'ลำธาร',
    'Ref: Audit': 'การตรวจสอบ',
    'Ref: Asset': 'สินทรัพย์',
    'Ref: Asset Name': 'ชื่อสินทรัพย์',
    'Ref: Is joint audit?': 'เป็นการตรวจสอบร่วมหรือไม่?',
    'Ref: Who was present?': 'ใครเป็นปัจจุบัน?',
    'Ref: submit': 'ส่ง',
    'Ref: Select the Location': 'เลือกตำแหน่ง',
    'Ref: Select the Audit Stream Type': 'เลือกประเภทสตรีมการตรวจสอบ',
    'Ref: Select the Audit': 'เลือกการตรวจสอบ',
    'Ref: Select the Asset': 'เลือกสินทรัพย์',
    'Ref: form error': 'ไม่ได้ป้อนฟิลด์ที่จำเป็นทั้งหมดทั้งหมด',
    'Ref: Audit error': 'การตรวจสอบที่แนบมา',
    'Ref: Joint Audit Participants error': 'ผู้เข้าร่วมการตรวจสอบร่วม',
    'Ref: Audit created': 'การตรวจสอบที่สร้างขึ้น',
    'Ref: Audit field length error': 'ความยาวไม่ควรเกิน 100 อักขระ',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'เปลี่ยนตำแหน่ง',
    'Ref: Location changed': 'เปลี่ยนตำแหน่ง',
    'Ref: Location': 'ที่ตั้ง',
    'Ref: Select the Location': 'เลือกตำแหน่ง',
    'Ref: Asset': 'สินทรัพย์',
    'Ref: Select the Asset': 'เลือกสินทรัพย์',
    'Ref: Asset Name': 'ชื่อสินทรัพย์',
    'Ref: submit': 'ส่ง',
    'Ref: Audit field length error': 'ความยาวไม่ควรเกิน 100 อักขระ',
  },
};
export default labels;
