import { Device } from '@capacitor/device';
import { pianoAnalytics } from 'piano-analytics-js';

import getServiceName from '@/helpers/getServiceName.helper';

const pianoSiteId = process.env.REACT_APP_PIANO_SITE_ID;
const pianoCollectDomain = process.env.REACT_APP_PIANO_COLLECTION_DOMAIN;

if (pianoSiteId && pianoCollectDomain) {
  pianoAnalytics.setConfigurations({
    site: Number(pianoSiteId),
    collectDomain: pianoCollectDomain,
    campaignPrefix: ['at_', 'utm_'],
  });
}

const setSiteContext = (
  siteId?: string,
  siteName?: string,
  contractId?: string,
  contractName?: string,
  contactId?: string
) => {
  if (pianoSiteId && pianoCollectDomain) {
    pianoAnalytics.setProperties(
      {
        user_id: contactId,
        site_id: siteId,
        sodexo_site: siteName,
        sodexo_contract_id: contractId,
        sodexo_contract: contractName,
      },
      {
        persistent: true,
        events: ['*'],
      }
    );
  }
};

const setGeographyContext = (geoCode?: string, regionCode?: string) => {
  if (pianoSiteId && pianoCollectDomain) {
    pianoAnalytics.setProperties(
      {
        sodexo_site_country: geoCode ?? '',
        sodexo_region: regionCode ?? '',
      },
      {
        persistent: true,
        events: ['*'],
      }
    );
  }
};
const setAppContext = async () => {
  const [deviceInfo, deviceUniqId] = await Promise.all([Device.getInfo(), Device.getId()]);
  if (pianoSiteId && pianoCollectDomain) {
    pianoAnalytics.setProperties(
      {
        app_name: process.env.REACT_APP_APP_NAME,
        app_version: process.env.REACT_APP_BUILD_NUMBER,
        app_env: process.env.REACT_APP_PORTAL_ENV_CODE,
        app_package: process.env.REACT_APP_MOBILE_PACKAGE_NAME,
        nav_platform: deviceInfo.platform,
        idclient: deviceUniqId.identifier,
      },
      {
        persistent: true,
        events: ['*'],
      }
    );
  }
};

const trackPageView = (path: string, documentTitle: string) => {
  if (pianoSiteId && pianoCollectDomain) {
    pianoAnalytics.sendEvent('page.display', {
      page: path,
      content_title: documentTitle,
      module: getServiceName(path) || 'N/A',
    });
  }
};

const trackPageViewWithFacilities = (path: string, facilityId: string, documentTitle: string) => {
  if (pianoSiteId && pianoCollectDomain) {
    pianoAnalytics.sendEvent('page.display', {
      page: path,
      content_title: documentTitle,
      sodexo_facility: facilityId,
      sodexo_facility_group: '$FACILITY',
      module: getServiceName(path) || 'N/A',
    });
  }
};

const trackToasterDisplay = (name: string, label: string, path: string) => {
  if (pianoSiteId && pianoCollectDomain) {
    pianoAnalytics.sendEvent('toaster.display', {
      toaster_name: name,
      toaster_page: path,
      toaster_label: label,
      toaster_module: getServiceName(path) || 'N/A',
    });
  }
};

const trackButtonClick = (button: string, otherProps?: Record<string, string>) => {
  if (pianoSiteId && pianoCollectDomain) {
    pianoAnalytics.sendEvent('button.click', {
      button: button,
      ...otherProps,
    });
  }
};

const trackCustomInteraction = (interaction: string, otherProps?: Record<string, string>) => {
  if (pianoSiteId && pianoCollectDomain) {
    pianoAnalytics.sendEvent('custom.interaction', {
      interaction: interaction,
      ...otherProps,
    });
  }
};

export {
  trackPageView,
  trackButtonClick,
  trackToasterDisplay,
  trackPageViewWithFacilities,
  trackCustomInteraction,
  setSiteContext,
  setAppContext,
  setGeographyContext,
};
