const labels = {
  All: {
    'Ref: See item': '请参阅项目',
    'Ref: Print': '打印收据',
    'Ref: Email': '通过电子邮件收到收据',
    'Ref: Receipt Modal message': '您想如何获得收据？',
    'Ref: Loyalty Reward': '忠诚度奖励',
    'Ref: Equation for stamps per item': '1购买= 1邮票',
    'Ref: Paid by employer': '您的雇主支付的金额',
    'Ref: Paid by user': '您支付的金额',
  },
  ProductsList: {
    'Ref: Page title': '商品列表',
    'Ref: Facility details': '设施的详细信息',
    'Ref: Search products list': '搜索项目',
    'Ref: filter label: menus': '菜单',
    'Ref: filter label: moment': '片刻',
    'Ref: filter label: category': '类别',
    'Ref: all': '所有',
    'Ref: today': '今天',
    'Ref: tomorrow': '明天',
    'Ref: see more': '查看详情',
    'Ref: see less': '隐藏详情',
    'Ref: reset': '重置',
    'Ref: View in cart': '在购物车中查看',
    'Ref: You have changed location': '您已经更改了位置',
    'Ref: You are now at': '你现在在',
    'Ref: Menu card': '菜单卡',
    'Ref: Table': '表号',
    'Service unavailable': '暂停服务',
    'Ref: Ordering unavailable': '小心！目前在此站点上订购是不可用的，请稍后再试。',
    'Ref: Filters': '过滤器（过敏原等）',
  },
  Order: { 'Ref: Page title': '命令' },
  MenuSelector: {
    'Ref: Menu Page title': '菜单',
    'Ref: today': '今天',
    'Ref: tomorrow': '明天',
    'Ref: Search products list': '搜索项目',
    'Ref: You have changed location': '位置已更改',
    'Ref: You are now at': '你现在位于',
  },
  ProductDetails: {
    'Ref: Page title': '产品详情',
    'Ref: Serving Size': '分量',
    'Ref: Nutrition': '营养信息',
    'Ref: Nutrition adults': '成年人每天需要大约2000千卡',
    'Ref: Nutrition Calories': '卡路里',
    'Ref: Nutrition Calcium': '钙',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '饱和脂肪',
    'Ref: Nutrition Carbohydrates': '碳水化合物',
    'Ref: Nutrition Sugar': '糖',
    'Ref: Nutrition Protein': '蛋白',
    'Ref: Nutrition Sodium': '钠',
    'Ref: Nutrition cholesterol': '胆固醇',
    'Ref: Nutrition dietaryFiber': '膳食纤维',
    'Ref: Nutrition iron': '铁',
    'Ref: Nutrition potassium': '钾',
    'Ref: Nutrition vitaminA': '维生素A',
    'Ref: Nutrition vitaminC': '维生素C',
    'Ref: Additives': '添加剂',
    'Ref: Emission': '二氧化碳排放量',
    'Ref: This product generates': '该产品产生',
    'Ref: of CO2': '二氧化碳',
    'Ref: emissions, which equals a': '排放，等于a',
    'Ref: car trip': '汽车旅行',
    'Ref: Ingredients': '食谱成分',
    'Ref: Allergens': '过敏原',
    'Ref: Always there': '总是在那里',
    'Ref: Allergens selected': '在过滤器上选择过敏原：',
    'Ref: minimum required': '最低要求',
    'Ref: you have to add at least': '您必须至少添加',
    'Ref: ingredients': '配料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '自由',
    'Ref: Based on customization': '根据您的自定义',
    'Ref: Additives eggs': '蛋',
    'Ref: Additives fish': '鱼',
    'Ref: Additives milk': '牛奶',
    'Ref: Additives nuts': '坚果',
    'Ref: Additives wheat': '小麦',
    'Ref: Additives peanuts': '花生',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': '芝麻',
    'Ref: Review': '审查',
    'Ref: Per portion': '每部分',
    'Ref: Per 100g': '每100克',
    'Ref: Allergen selected': '在搜索过滤器上选择的过敏原',
    'Ref: Fitbit Log': '登录到Fitbit',
    'Ref: age verification required on delivery': '交货需要的年龄验证',
    'Ref: alcohol content notification':
      '通过为其中一个项目订购并订购，您声明您年龄在18岁或以上。酗酒对您的健康是危险的。',
    'Ref: default': '默认',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}如果您订购此产品',
    'Ref: Loyalty Card Description':
      '{stamp_form}将添加到以下忠诚度{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps': '忠诚邮票程序使您可以收集邮票以获得奖励。',
    'Ref: Loyalty Modal Description':
      '当您购买<b> {product_name}时，将添加1张邮票</b>将添加到<b> {scheme_names} </b> {program_form}',
    'Ref: and': '和',
    'Ref: Stamp': '邮票',
    'Ref: Stamps': '邮票',
    'Ref: Program': '程序',
    'Ref: Programs': '程式',
    'Ref: Loyalty Stamps': '忠诚邮票',
    'Ref: See Loyalty Stamps': '查看忠诚邮票',
    Favorite: '最喜欢的',
    'Ref: In favorites': '该产品已经在收藏夹列表中。单击以删除它。',
    'Ref: Not in favorites': '该产品尚未在收藏夹列表中。单击以添加。',
    'Ref: Added to favorites': '该产品已添加到收藏夹列表中。',
    'Ref: Removed from favorites': '该产品已从收藏夹列表中删除。',
    'Ref: From': '从',
  },
  OrderHistory: { 'Ref: Orders': '命令', 'Ref: Page Title': '我的订单' },
  MenuTile: { 'Ref: Scan&Go': '扫描和去', 'Ref: View only': '只读' },
  ProductTile: {
    'Ref: Allergens': '过敏原',
    'Ref: Presence of allergen': '过敏原的存在',
    'Ref: Scan': '扫描',
    'Ref: From': '从',
    'Ref: Already in product favorite list. Click to remove it.':
      '已经在产品最喜欢的列表中了。单击以删除它。',
    'Ref: Currently not added to product favorite list. Click to add it.':
      '目前尚未添加到产品喜欢的列表中。单击以添加。',
    'Ref: Free': '自由的',
  },
  MenusWidget: { 'Ref: no menu': '目前没有菜单', 'Ref: Modal title': '一天的菜单' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': '打包',
    'Ref: Eat-in': '堂食',
    'Ref: Scan&Go': '扫描和去',
    'Ref: Delivery': '送货',
    'Ref: Total': '全部的',
    'Ref: How was facility name service': '{facility_name}的服务如何？',
    'Ref: How do you rate the service': '您如何评价服务？',
  },
};
export default labels;
