import { FC, useCallback, useMemo, useState } from 'react';

import { RadioOnClickProps } from '../../atoms/Radio/Radio.types';

import { RadioGroupProps } from './RadioGroup.types';
import RadioGroupContext from './RadioGroupContext';

const RadioGroup: FC<RadioGroupProps> = ({
  children,
  name,
  value,
  disabled,
  onClick,
  'data-testid': dataTestId = 'radio-group',
}) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);

  const onClickValue = useCallback(
    (e: React.FormEvent<HTMLInputElement>, { value: v }: RadioOnClickProps) => {
      setSelectedValue(v);
      if (onClick) onClick(e, v);
    },
    [onClick]
  );

  const contextValue = useMemo(
    () => ({ name, value: selectedValue, disabled, onClick: onClickValue }),
    [disabled, name, onClickValue, selectedValue]
  );

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <div data-testid={dataTestId}>{children}</div>
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
