import { RouteComponentProps } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import FacilitiesList from './FacilitiesList/FacilitiesList';
import FacilityDetails from './FacilityDetails/FacilityDetails';

const Facilities = ({ children }: { children: React.ReactNode } & RouteComponentProps) => {
  if (children) return <>{children}</>;

  return (
    <Switch>
      <Route path={pagePaths['FacilityDetails']} children={<FacilityDetails />} />
      <Route path={pagePaths['Module']} children={<FacilitiesList />} />
    </Switch>
  );
};

export default Facilities;
