import { Network } from '@capacitor/network';
import classNames from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { EditionListIcon, SearchNoResultIcon } from '../../../../assets';
import { OperationsRefreshIcon } from '../../../../assets/icons';
import { AuditIllustration } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import { TileSkeleton } from '../../../../components/molecules/Tile';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import useConnection from '../../../../helpers/hooks/useConnection';
import { useGetAuditInvitesQuery } from '../../api';
import { AuditTile } from '../../components/AuditTile';
import { pagePaths } from '../../config';
import { useListFilter, useLocalData, useSync } from '../../hooks';
import { useAuditsTranslation } from '../../hooks/useAuditsTranslation';
import { AuditInvite } from '../../types';

import toast from '@/components/atoms/ModalToast';

import styles from './AuditInviteList.module.css';

const AuditInviteList = () => {
  const { label } = useAuditsTranslation(__filename);
  const history = useHistory();
  const isConnected = useConnection();

  const { auditInvites: queuedData = [], refreshLocalData } = useLocalData();

  const toastErrorHeader = (testId: string, title: string, subTitle: string) => {
    return (
      <div data-testid={testId}>
        <Title size={TITLE_SIZE.BODYSBOLD}>
          <span className={styles.errorText}>{title}</span>
        </Title>
        <Title size={TITLE_SIZE.BODYSDEFAULT}>
          <span className={styles.errorText}>{subTitle}</span>
        </Title>
      </div>
    );
  };

  const {
    isLoading: isSynching,
    checkSyncStatus,
    onFetchData,
  } = useSync({
    successLabel: label('Ref: sync success'),
    errorLabel: toastErrorHeader(
      'error-toast-sync',
      label('Ref: sync error'),
      label('Ref: sync error details')
    ),
    onSuccess: refreshLocalData,
  });

  const { data = [], isFetching: isLoading } = useGetAuditInvitesQuery(null);

  const sortedData = [...data, ...queuedData].sort(
    (a, b) => a.state - b.state || moment(a.dueDate).unix() - moment(b.dueDate).unix()
  );
  const { search, filter } = useListFilter({ label, data: sortedData });

  const handleSyncStatus = useCallback(
    async (action: Function) => {
      const syncStatus = await checkSyncStatus();
      const { connected } = await Network.getStatus();

      if (!syncStatus.fetch && !connected) {
        toast.error(
          toastErrorHeader(
            'error-toast',
            label('Ref: Unsynced error title'),
            label('Ref: Unsynced error description')
          ),
          {
            toastId: 'unsynced-data-error-toast',
            position: 'top-center',
            closeOnClick: true,
            draggable: false,
            closeButton: false,
          }
        );

        return;
      }

      if (action) action();
    },
    [checkSyncStatus, label]
  );

  const noResults = () => {
    if (!isLoading && sortedData.length > 0) {
      return {
        icon: <SearchNoResultIcon width="32" height="32" />,
        title: label('Ref: no search audit results'),
        iconClasses: styles.icon,
        infoMessageClasses: styles.infoMessage,
        titleClasses: styles.title,
      };
    } else if (!isLoading && sortedData.length === 0) {
      return {
        icon: <EditionListIcon width="32" height="32" />,
        title: label('Ref: no audit results'),
        iconClasses: styles.icon,
        infoMessageClasses: styles.infoMessage,
      };
    }
  };

  return (
    <ListPage
      data-testid="audit-invite-list"
      hideFilterTitle
      hasBackLink={false}
      isLoading={isLoading}
      title={label('Ref: Page title')}
      filter={filter}
      search={search}
      items={sortedData}
      noResults={noResults()}
      noResultsClasses={'flex: none;'}
      listTopContent={
        <div className={classNames(styles.topContent)}>
          <div className={classNames(styles.connectedStatus)}>
            <div
              className={classNames(styles.connectedStatusIcon, {
                [styles.statusConnected]: isConnected,
                [styles.statusDisconnected]: !isConnected,
              })}
            />

            <Title size={TITLE_SIZE.BODYSBOLD}>
              {isConnected ? label('Ref: connected') : label('Ref: not connected')}
            </Title>
          </div>
          <Button
            look={BUTTON_LOOK.TERTIARY}
            onClick={onFetchData}
            disabled={isSynching || !isConnected}
            data-testid="audits-list-refresh"
          >
            <div className={styles.syncButton}>
              <OperationsRefreshIcon className={classNames({ [styles.loading]: isSynching })} />
              {label('Ref: synchronise data')}
            </div>
          </Button>
        </div>
      }
      itemRenderer={(item: AuditInvite) => (
        <AuditTile
          key={item.id}
          {...item}
          status={{ value: item.state, label: label(`Ref: status${item.state}`) }}
          label={label}
          handleSyncStatus={handleSyncStatus}
          refreshLocalData={refreshLocalData}
        />
      )}
      aside={
        <Column.Complementary>
          <AuditIllustration />
        </Column.Complementary>
      }
      actions={
        <ActionsBar>
          <Button
            data-testid="audits-list-create-new"
            look={BUTTON_LOOK.PRIMARY}
            onClick={() => handleSyncStatus(() => history.push(pagePaths.AuditCreationForm))}
          >
            {label('Ref: Create new')}
          </Button>
        </ActionsBar>
      }
    >
      {isLoading &&
        Array.apply(null, Array(10)).map((_, i) => (
          <TileSkeleton key={i} withoutImage withoutActions />
        ))}
    </ListPage>
  );
};

export default AuditInviteList;
