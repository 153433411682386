const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Auditai',
    'Ref: status0': 'Atviras',
    'Ref: status1': 'Baigta',
    'Ref: status2': 'Atšauktas',
    'Ref: connected': 'Prijungtas',
    'Ref: not connected': 'Neprisijungęs',
    'Ref: synchronise data': 'Sinchronizuokite duomenis',
    'Ref: sync success': 'Duomenys sėkmingai sinchronizuoti',
    'Ref: Create new': 'Sukurk naują',
    'Ref: Audit unsynchronised': 'Nesinchronizuotas',
    'Ref: Audit synchronised': 'Sinchronizuotas',
    'Ref: Unsynced error title': 'Įvyko klaida',
    'Ref: Unsynced error description':
      'Atrodo, kad tai yra pirmas kartas, kai galite pasiekti „Audits“ modulį. Norėdami pradėti auditą, susisiekite su tinklu ir „sinchronizuokite“.',
    'Ref: Search all audits': 'Visi auditai',
    'Ref: Search active audits': 'Atviras',
    'Ref: Search completed audits': 'Baigta',
    'Ref: sync error': 'Sinchronizacija nepavyko',
    'Ref: sync error details': 'Bandyti vėl prisijungti',
    'Ref: no search audit results': 'Deja, jūsų paieškai atitinkančių auditų nėra',
    'Ref: no audit results': 'Jūs neturite turimų auditų, sukurkite naują, kad pradėtumėte',
    'Ref: Search canceled audits': 'Atšauktas',
    'Ref: Audit cancelled successfully': 'Auditas sėkmingai atšauktas',
    'Ref: Cancel audit invite': 'Atšaukti',
    'Ref: Error audit cancel': 'Auditas nebuvo atšauktas',
    'Ref: Cancel audit confirmation': 'Ar tikrai norite atšaukti auditą?',
    'Ref: Confirm': 'Patvirtinti',
    'Ref: Cancel audit': 'Atšaukti auditą',
    'Ref: Change the location': 'Pakeiskite vietą',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Audito informacija',
    'Ref: questions': 'klausimai',
    'Ref: question': 'klausimas',
    'Ref: Additional Comments': 'apibūdinimas',
    'Ref: Not found title': 'Oi!',
    'Ref: Not found text': 'Audito, kurį bandote pasiekti, neegzistuoja.',
    'Ref: Back to Audit list': 'Atgal į audito sąrašą',
    'Ref: Success title': 'Ačiū!',
    'Ref: Success text': 'Jūs baigėte auditą',
    'Ref: submit': 'Pateikti',
    'Ref: add attachment': 'Pridėkite priedą',
    'Ref: Unsupported elements':
      'Deja, atrodo, kad šis auditas turi turinio, kurio programa dar nepalaiko. Mes dirbsime.',
    'Ref: form error': 'Ne į visus klausimus buvo išsamiai atsakyta',
    'Ref: form complete': 'Baigta nuo',
    'Ref: attachment preview error': 'Norėdami pamatyti šį priedą, turite būti internete',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Sukurk naują',
    'Ref: Location': 'Vieta',
    'Ref: Audit Stream Type': 'Srautas',
    'Ref: Audit': 'Auditas',
    'Ref: Asset': 'Turtas',
    'Ref: Asset Name': 'Turto pavadinimas',
    'Ref: Is joint audit?': 'Ar bendras auditas?',
    'Ref: Who was present?': 'Kas buvo?',
    'Ref: submit': 'Pateikti',
    'Ref: Select the Location': 'Pasirinkite vietą',
    'Ref: Select the Audit Stream Type': 'Pasirinkite audito srauto tipą',
    'Ref: Select the Audit': 'Pasirinkite auditą',
    'Ref: Select the Asset': 'Pasirinkite turtą',
    'Ref: form error': 'Ne visi reikalingi laukai buvo visiškai įvesti',
    'Ref: Audit error': 'Pridedamas auditas',
    'Ref: Joint Audit Participants error': 'Jungtiniai audito dalyviai',
    'Ref: Audit created': 'Sukurtas auditas',
    'Ref: Audit field length error': 'Ilgis neturėtų viršyti 100 simbolių',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Pakeiskite vietą',
    'Ref: Location changed': 'Pakeista vieta',
    'Ref: Location': 'Vieta',
    'Ref: Select the Location': 'Pasirinkite vietą',
    'Ref: Asset': 'Turtas',
    'Ref: Select the Asset': 'Pasirinkite turtą',
    'Ref: Asset Name': 'Turto pavadinimas',
    'Ref: submit': 'Pateikti',
    'Ref: Audit field length error': 'Ilgis neturėtų viršyti 100 simbolių',
  },
};
export default labels;
