import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DeprecatedModulePage from '../../components/templates/DeprecatedModulePage';

import { pagePaths } from './config';

export default function FitBit() {
  return (
    <Switch>
      <Route path={pagePaths['Module']} children={<DeprecatedModulePage />} />
    </Switch>
  );
}
