/* eslint-disable no-restricted-imports */

import { ReactComponent as AccommodationRequestSvg } from './AccommodationRequest.svg';
import { ReactComponent as AddSvg } from './Add-24.svg';
import { ReactComponent as AddItemSvg } from './AddItem-24.svg';
import { ReactComponent as ArrowRightSvg } from './ArrowRight-24px.svg';
import { ReactComponent as AvatarSvg } from './Avatar-24.svg';
import { ReactComponent as BellSvg20 } from './Bell-20.svg';
import { ReactComponent as BellSvg } from './Bell-24.svg';
import { ReactComponent as BellFillSvg } from './BellFill-24.svg';
import { ReactComponent as CalendarNextLabelSvg } from './CalendarNextLabel.svg';
import { ReactComponent as CalendarPreviousLabelSvg } from './CalendarPreviousLabel.svg';
import { ReactComponent as CheckboxSelectedSvg } from './CheckboxSelected.svg';
import { ReactComponent as CheckboxUnselectedSvg } from './CheckboxUnselected.svg';
import { ReactComponent as CheckCicleSvg } from './CheckCicle.svg';
import { ReactComponent as CheckCiclefillSvg } from './CheckCiclefill.svg';
import { ReactComponent as ChevronRightSvg } from './chevron_right-24px.svg';
import { ReactComponent as ChevronUpSvg } from './ChevronUp-24.svg';
import { ReactComponent as ClickCollectSvg } from './ClickCollect-24.svg';
import { ReactComponent as ConfirmationSvg } from './Confirmation.svg';
import { ReactComponent as ContentSvg } from './Content.svg';
import { ReactComponent as ContentPageSvg } from './ContentPage.svg';
import { ReactComponent as GermanyFlagSvg } from './DEFlag.svg';
import { ReactComponent as DocumentSvg } from './Document-24.svg';
import { ReactComponent as DotFilledSvg } from './DotFilled.svg';
import { ReactComponent as DotOutlineSvg } from './DotOutline.svg';
import { ReactComponent as EditSvg } from './Edit-24.svg';
import { ReactComponent as EditionListSvg } from './EditionList.svg';
import { ReactComponent as EditOnSvg } from './EditOn.svg';
import { ReactComponent as EmailSvg } from './Email-24.svg';
import { ReactComponent as ErrorSvg } from './Error-24.svg';
import { ReactComponent as SpainFlagSvg } from './ESFlag.svg';
import { ReactComponent as EventSvg } from './Event-24.svg';
import { ReactComponent as ExternalLinkSvg } from './ExternalLink-24.svg';
import { ReactComponent as FaceSmileSvg } from './FaceSmile-24.svg';
import { ReactComponent as FacilitiesSvg } from './Facilities.svg';
import { ReactComponent as FavoriteSvg } from './Favorite-24.svg';
import { ReactComponent as FeedbackSvg } from './Feedback-24.svg';
import { ReactComponent as Filter24Svg } from './Filter-24.svg';
import { ReactComponent as FilterSvg } from './filter.svg';
import { ReactComponent as FranceFlagSvg } from './FRFlag.svg';
import { ReactComponent as InformationSvg } from './Information-24.svg';
import { ReactComponent as InProgressSvg } from './InProgress.svg';
import { ReactComponent as LinkSvg } from './Link-24.svg';
import { ReactComponent as ListSvg } from './List-24.svg';
import { ReactComponent as LocationSvg } from './Location-24.svg';
import { ReactComponent as LoginSvg } from './Login-24.svg';
import { ReactComponent as LogoutSvg } from './Logout-24.svg';
import { ReactComponent as MailSvg } from './mail.svg';
import { ReactComponent as MapSvg } from './Map-24.svg';
import { ReactComponent as MoreHorizontalSvg } from './MoreHorizontal-24.svg';
import { ReactComponent as NetworkSvg } from './Network-24.svg';
import { ReactComponent as OrderSvg } from './Order-24.svg';
import { ReactComponent as PauseSvg } from './Pause-24.svg';
import { ReactComponent as PaymentSvg } from './Payment-24.svg';
import { ReactComponent as Phone24Svg } from './Phone-24.svg';
import { ReactComponent as PhoneSvg } from './phone.svg';
import { ReactComponent as PlaySvg } from './Play-24.svg';
import { ReactComponent as PurchaseSvg } from './Purchase-24.svg';
import { ReactComponent as QrCodeSvg } from './qr_code.svg';
import { ReactComponent as QrCode24Svg } from './QrCode-24.svg';
import { ReactComponent as QtyDecreaseSvg } from './qty_decrease.svg';
import { ReactComponent as QtyIncreaseSvg } from './qty_increase.svg';
import { ReactComponent as RadioSelectedSvg } from './RadioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from './RadioUnselected.svg';
import { ReactComponent as ReceiptSvg } from './Receipt-24.svg';
import { ReactComponent as Redo20Svg } from './Redo-20.svg';
import { ReactComponent as RedoSvg } from './Redo-24.svg';
import { ReactComponent as RequestSvg } from './Request-24.svg';
import { ReactComponent as RestaurantSvg } from './Restaurant-24.svg';
import { ReactComponent as SearchSvg } from './Search-24.svg';
import { ReactComponent as SearchNoResultSvg } from './SearchNoResult.svg';
import { ReactComponent as SendSvg } from './Send-24.svg';
import { ReactComponent as ServiceRequestSvg } from './ServiceRequest.svg';
import { ReactComponent as ShareSvg20 } from './Share-20.svg';
import { ReactComponent as ShareSvg } from './Share.svg';
import { ReactComponent as ShoppingBagSvg20 } from './ShoppingBag-20.svg';
import { ReactComponent as ShoppingBagSvg } from './ShoppingBag-24.svg';
import { ReactComponent as ShoppingCartSvg } from './ShoppingCart-24.svg';
import { ReactComponent as StarFilledSvg } from './StarFilled-24.svg';
import { ReactComponent as StarOutlinedSvg } from './StarOutlined-24.svg';
import { ReactComponent as StaysSvg } from './Stays.svg';
import { ReactComponent as StopSvg } from './Stop-24.svg';
import { ReactComponent as StoreSvg } from './Store-24.svg';
import { ReactComponent as SuppliersSvg } from './Suppliers.svg';
import { ReactComponent as SurveySvg } from './Survey.svg';
import { svgFactory } from './svgFactory';
import { ReactComponent as ThumbsUpSvg } from './ThumbsUp.svg';
import topImage, { ReactComponent as TitleBarImageSvg } from './title_bar_image.svg';
import { ReactComponent as TranslateSvg20 } from './Translate-20.svg';
import { ReactComponent as TranslateSvg } from './Translate-24.svg';
import { ReactComponent as TrashSvg } from './Trash-24.svg';
import { ReactComponent as UKFlagSvg } from './UKFlag.svg';
import { ReactComponent as UserSvg } from './User-24.svg';
import { ReactComponent as USFlagSvg } from './USFlag.svg';

export { topImage };

export const AccommodationRequestIcon = svgFactory(AccommodationRequestSvg);
export const AddIcon = svgFactory(AddSvg);
export const AddItemIcon = svgFactory(AddItemSvg);
export const ArrowRightIcon = svgFactory(ArrowRightSvg);
export const AvatarIcon = svgFactory(AvatarSvg);
export const BellIcon = svgFactory(BellSvg);
export const BellIcon20 = svgFactory(BellSvg20);
export const BellFillIcon = svgFactory(BellFillSvg);
export const CalendarNextLabelIcon = svgFactory(CalendarNextLabelSvg);
export const CalendarPreviousLabelIcon = svgFactory(CalendarPreviousLabelSvg);
export const CheckCicleIcon = svgFactory(CheckCicleSvg);
export const CheckCiclefillIcon = svgFactory(CheckCiclefillSvg);
export const CheckboxSelectedIcon = svgFactory(CheckboxSelectedSvg);
export const CheckboxUnselectedIcon = svgFactory(CheckboxUnselectedSvg);
export const ChevronRightIcon = svgFactory(ChevronRightSvg);
export const ChevronUpIcon = svgFactory(ChevronUpSvg);
export const ClickCollectIcon = svgFactory(ClickCollectSvg);
export const ConfirmationIcon = svgFactory(ConfirmationSvg);
export const ContentIcon = svgFactory(ContentSvg);
export const ContentPageIcon = svgFactory(ContentPageSvg);
export const DocumentIcon = svgFactory(DocumentSvg);
export const DotFilledIcon = svgFactory(DotFilledSvg);
export const DotOutlineIcon = svgFactory(DotOutlineSvg);
export const EditIcon = svgFactory(EditSvg);
export const EditOnIcon = svgFactory(EditOnSvg);
export const EmailIcon = svgFactory(EmailSvg);
export const ErrorIcon = svgFactory(ErrorSvg);
export const EventIcon = svgFactory(EventSvg);
export const ExternalLinkIcon = svgFactory(ExternalLinkSvg);
export const FaceSmileIcon = svgFactory(FaceSmileSvg);
export const FacilitiesIcon = svgFactory(FacilitiesSvg);
export const FavoriteIcon = svgFactory(FavoriteSvg);
export const FeedbackIcon = svgFactory(FeedbackSvg);
export const FilterIcon = svgFactory(FilterSvg);
export const Filter24Icon = svgFactory(Filter24Svg);
export const InProgressIcon = svgFactory(InProgressSvg);
export const InformationIcon = svgFactory(InformationSvg);
export const LinkIcon = svgFactory(LinkSvg);
export const ListIcon = svgFactory(ListSvg);
export const LocationIcon = svgFactory(LocationSvg);
export const LoginIcon = svgFactory(LoginSvg);
export const LogoutIcon = svgFactory(LogoutSvg);
export const MailIcon = svgFactory(MailSvg);
export const MapIcon = svgFactory(MapSvg);
export const MoreHorizontalIcon = svgFactory(MoreHorizontalSvg);
export const NetworkIcon = svgFactory(NetworkSvg);
export const OrderIcon = svgFactory(OrderSvg);
export const PauseIcon = svgFactory(PauseSvg);
export const PaymentIcon = svgFactory(PaymentSvg);
export const PhoneIcon = svgFactory(PhoneSvg);
export const Phone24Icon = svgFactory(Phone24Svg);
export const PlayIcon = svgFactory(PlaySvg);
export const PurchaseIcon = svgFactory(PurchaseSvg);
export const QrCodeIcon = svgFactory(QrCodeSvg);
export const QrCode24Icon = svgFactory(QrCode24Svg);
export const QtyDecreaseIcon = svgFactory(QtyDecreaseSvg);
export const QtyIncreaseIcon = svgFactory(QtyIncreaseSvg);
export const RadioSelectedIcon = svgFactory(RadioSelectedSvg);
export const RadioUnselectedIcon = svgFactory(RadioUnselectedSvg);
export const ReceiptIcon = svgFactory(ReceiptSvg);
export const RedoIcon = svgFactory(RedoSvg);
export const RedoIcon20 = svgFactory(Redo20Svg);
export const RequestIcon = svgFactory(RequestSvg);
export const RestaurantIcon = svgFactory(RestaurantSvg);
export const SearchIcon = svgFactory(SearchSvg);
export const SendIcon = svgFactory(SendSvg);
export const ServiceRequestIcon = svgFactory(ServiceRequestSvg);
export const ShareIcon = svgFactory(ShareSvg);
export const Share20Icon = svgFactory(ShareSvg20);
export const ShoppingBagIcon = svgFactory(ShoppingBagSvg);
export const ShoppingBagIcon20 = svgFactory(ShoppingBagSvg20);
export const ShoppingCartIcon = svgFactory(ShoppingCartSvg);
export const StarFilledIcon = svgFactory(StarFilledSvg);
export const StarOutlinedIcon = svgFactory(StarOutlinedSvg);
export const StaysIcon = svgFactory(StaysSvg);
export const StopIcon = svgFactory(StopSvg);
export const StoreIcon = svgFactory(StoreSvg);
export const SuppliersIcon = svgFactory(SuppliersSvg);
export const SurveyIcon = svgFactory(SurveySvg);
export const TitleBarImageIcon = svgFactory(TitleBarImageSvg);
export const ThumbsUpIcon = svgFactory(ThumbsUpSvg);
export const TranslateIcon = svgFactory(TranslateSvg);
export const TranslateIcon20 = svgFactory(TranslateSvg20);
export const TrashIcon = svgFactory(TrashSvg);
export const USFlag = svgFactory(USFlagSvg);
export const UKFlag = svgFactory(UKFlagSvg);
export const FRFlag = svgFactory(FranceFlagSvg);
export const DEFlag = svgFactory(GermanyFlagSvg);
export const ESFlag = svgFactory(SpainFlagSvg);
export const UserIcon = svgFactory(UserSvg);
export const SearchNoResultIcon = svgFactory(SearchNoResultSvg);
export const EditionListIcon = svgFactory(EditionListSvg);
