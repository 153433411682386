const mockResponse = {
  registerPushNotification: {
    ok: true,
    status: 201,
    json: () => Promise.resolve({}),
  },
  unregisterPushNotification: {
    ok: true,
    status: 201,
    json: () => Promise.resolve({}),
  },
};

export default mockResponse;
