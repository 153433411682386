const labels = {
  All: {
    'Ref: See item': 'Ref: See item',
    'Ref: Print': 'Ref: Print',
    'Ref: Email': 'Ref: Email',
    'Ref: Receipt Modal message': 'Ref: Receipt Modal message',
    'Ref: Loyalty Reward': 'Ref: Loyalty Reward',
    'Ref: Equation for stamps per item': 'Ref: Equation for stamps per item',
    'Ref: Paid by employer': 'Ref: Paid by employer',
    'Ref: Paid by user': 'Ref: Paid by user',
  },
  ProductsList: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Facility details': 'Ref: Facility details',
    'Ref: Search products list': 'Ref: Search products list',
    'Ref: filter label: menus': 'Ref: filter label: menus',
    'Ref: filter label: moment': 'Ref: filter label: moment',
    'Ref: filter label: category': 'Ref: filter label: category',
    'Ref: all': 'Ref: all',
    'Ref: today': 'Ref: today',
    'Ref: tomorrow': 'Ref: tomorrow',
    'Ref: see more': 'Ref: see more',
    'Ref: see less': 'Ref: see less',
    'Ref: reset': 'Ref: reset',
    'Ref: View in cart': 'Ref: View in cart',
    'Ref: You have changed location': 'Ref: You have changed location',
    'Ref: You are now at': 'Ref: You are now at',
    'Ref: Menu card': 'Ref: Menu card',
    'Ref: Table': 'Ref: Table',
    'Service unavailable': 'Service unavailable',
    'Ref: Ordering unavailable': 'Ref: Ordering unavailable',
    'Ref: Filters': 'Ref: Filters',
  },
  Order: { 'Ref: Page title': 'Ref: Page title' },
  MenuSelector: {
    'Ref: Menu Page title': 'Ref: Menu Page title',
    'Ref: today': 'Ref: today',
    'Ref: tomorrow': 'Ref: tomorrow',
    'Ref: Search products list': 'Ref: Search products list',
    'Ref: You have changed location': 'Ref: You have changed location',
    'Ref: You are now at': 'Ref: You are now at',
  },
  ProductDetails: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Serving Size': 'Ref: Serving Size',
    'Ref: Nutrition': 'Ref: Nutrition',
    'Ref: Nutrition adults': 'Ref: Nutrition adults',
    'Ref: Nutrition Calories': 'Ref: Nutrition Calories',
    'Ref: Nutrition Calcium': 'Ref: Nutrition Calcium',
    'Ref: Nutrition Fat': 'Ref: Nutrition Fat',
    'Ref: Nutrition Saturated Fat': 'Ref: Nutrition Saturated Fat',
    'Ref: Nutrition Carbohydrates': 'Ref: Nutrition Carbohydrates',
    'Ref: Nutrition Sugar': 'Ref: Nutrition Sugar',
    'Ref: Nutrition Protein': 'Ref: Nutrition Protein',
    'Ref: Nutrition Sodium': 'Ref: Nutrition Sodium',
    'Ref: Nutrition cholesterol': 'Ref: Nutrition cholesterol',
    'Ref: Nutrition dietaryFiber': 'Ref: Nutrition dietaryFiber',
    'Ref: Nutrition iron': 'Ref: Nutrition iron',
    'Ref: Nutrition potassium': 'Ref: Nutrition potassium',
    'Ref: Nutrition vitaminA': 'Ref: Nutrition vitaminA',
    'Ref: Nutrition vitaminC': 'Ref: Nutrition vitaminC',
    'Ref: Additives': 'Ref: Additives',
    'Ref: Emission': 'Ref: Emission',
    'Ref: This product generates': 'Ref: This product generates',
    'Ref: of CO2': 'Ref: of CO2',
    'Ref: emissions, which equals a': 'Ref: emissions, which equals a',
    'Ref: car trip': 'Ref: car trip',
    'Ref: Ingredients': 'Ref: Ingredients',
    'Ref: Allergens': 'Ref: Allergens',
    'Ref: Always there': 'Ref: Always there',
    'Ref: Allergens selected': 'Ref: Allergens selected',
    'Ref: minimum required': 'Ref: minimum required',
    'Ref: you have to add at least': 'Ref: you have to add at least',
    'Ref: ingredients': 'Ref: ingredients',
    'Ref: Ingredient': 'Ref: Ingredient',
    'Ref: Free': 'Ref: Free',
    'Ref: Based on customization': 'Ref: Based on customization',
    'Ref: Additives eggs': 'Ref: Additives eggs',
    'Ref: Additives fish': 'Ref: Additives fish',
    'Ref: Additives milk': 'Ref: Additives milk',
    'Ref: Additives nuts': 'Ref: Additives nuts',
    'Ref: Additives wheat': 'Ref: Additives wheat',
    'Ref: Additives peanuts': 'Ref: Additives peanuts',
    'Ref: Additives soy beans': 'Ref: Additives soy beans',
    'Ref: Additives sesame seeds': 'Ref: Additives sesame seeds',
    'Ref: Review': 'Ref: Review',
    'Ref: Per portion': 'Ref: Per portion',
    'Ref: Per 100g': 'Ref: Per 100g',
    'Ref: Allergen selected': 'Ref: Allergen selected',
    'Ref: Fitbit Log': 'Ref: Fitbit Log',
    'Ref: age verification required on delivery': 'Ref: age verification required on delivery',
    'Ref: alcohol content notification': 'Ref: alcohol content notification',
    'Ref: default': 'Ref: default',
    'Ref: Loyalty Card Title Stamp': 'Ref: Loyalty Card Title Stamp',
    'Ref: Loyalty Card Description': 'Ref: Loyalty Card Description',
    'Ref: About Loyalty stamps': 'Ref: About Loyalty stamps',
    'Ref: Loyalty Modal Description': 'Ref: Loyalty Modal Description',
    'Ref: and': 'Ref: and',
    'Ref: Stamp': 'Ref: Stamp',
    'Ref: Stamps': 'Ref: Stamps',
    'Ref: Program': 'Ref: Program',
    'Ref: Programs': 'Ref: Programs',
    'Ref: Loyalty Stamps': 'Ref: Loyalty Stamps',
    'Ref: See Loyalty Stamps': 'Ref: See Loyalty Stamps',
    Favorite: 'Favorite',
    'Ref: In favorites': 'Ref: In favorites',
    'Ref: Not in favorites': 'Ref: Not in favorites',
    'Ref: Added to favorites': 'Ref: Added to favorites',
    'Ref: Removed from favorites': 'Ref: Removed from favorites',
    'Ref: From': 'Ref: From',
  },
  OrderHistory: { 'Ref: Orders': 'Ref: Orders', 'Ref: Page Title': 'Ref: Page Title' },
  MenuTile: { 'Ref: Scan&Go': 'Ref: Scan&Go', 'Ref: View only': 'Ref: View only' },
  ProductTile: {
    'Ref: Allergens': 'Ref: Allergens',
    'Ref: Presence of allergen': 'Ref: Presence of allergen',
    'Ref: Scan': 'Ref: Scan',
    'Ref: From': 'Ref: From',
    'Ref: Already in product favorite list. Click to remove it.':
      'Ref: Already in product favorite list. Click to remove it.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Ref: Currently not added to product favorite list. Click to add it.',
    'Ref: Free': 'Ref: Free',
  },
  MenusWidget: { 'Ref: no menu': 'Ref: no menu', 'Ref: Modal title': 'Ref: Modal title' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Ref: Pickup',
    'Ref: Eat-in': 'Ref: Eat-in',
    'Ref: Scan&Go': 'Ref: Scan&Go',
    'Ref: Delivery': 'Ref: Delivery',
    'Ref: Total': 'Ref: Total',
    'Ref: How was facility name service': 'Ref: How was facility name service',
    'Ref: How do you rate the service': 'Ref: How do you rate the service',
  },
};
export default labels;
