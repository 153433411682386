const labels = {
  All: {
    Feedback: 'Zpětná vazba',
    'Ref: Switch site component intro sentence':
      'Vaše zpětná vazba bude propojena na stránku {site name}.',
    Category: 'Kategorie',
    Subject: 'Aihe',
    Comment: 'Kommentti',
    'Date of your experience': 'Datum vaší zkušenosti *',
    'Ref: Hint': 'Máte problém nebo práci, která potřebuje opravu?',
    'Ref: Log Request': 'Kirjaudu pyyntöön',
    'Ref: Describe issue': 'Prosím, řekněte nám o svých zkušenostech',
    Date: 'Datum',
    'Ref: Share your opinion header': 'Sdílejte svůj názor na svou zkušenost v práci',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurace / kavárna',
    'Desk / workstation area': 'Oblast stolu / pracovní stanice',
    'Meeting room facilities': 'Zasedací místnost',
    'Coffee/tea facilities (pantry)': 'Káva/čajová zařízení (spíž)',
    'Restrooms / washrooms': 'Toalety / toalety',
    'Reception / Security': 'Recepce / zabezpečení',
    'Conference center': 'Konferenční centrum',
    'Fitness center': 'Fitness centrum',
    Mailroom: 'Poštovní místnost',
    'Bicycle room': 'Kola',
    'Shuttle services': 'Kyvadlové služby',
    Lockers: 'Skříňky',
    Helpdesk: 'Podpora',
  },
  Create: {
    'Tell us!': 'Řekni nám!',
    'Ref: All Ears': 'Jsme všichni uši!',
    'Ref: Provide Description': 'Anna kuvaus',
    'Ref: Fill-in-the-blank sentence': 'Chci poskytnout {0} z hlediska {1}',
    'Ref: Link text to service request module': 'Žádost nebo problém? Přihlaste se sem!',
    'Complaint type': 'Stížnost {emoji}',
    'Feedback type': 'Zpětná vazba {emoji}',
    'Compliment type': 'Kompliment {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Došlo k chybě a vaše zpětná vazba nebyla odeslána. Pokud tato chyba přetrvává, obraťte se prosím na podporu.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Vaše zpětná vazba byla odeslána. Naše tým podpory na pracovišti ho pečlivě přečte. Pokud je to vhodné, vrátíme se k vám.',
    'New feedback': 'Nová zpětná vazba',
  },
  CreateFlow: {
    Description: 'Popis',
    'Ref: Provide Description': 'Uveďte prosím datum a popis',
    'Ref: I would like to provide a': 'Chtěl bych poskytnout',
    'Ref: in regards to': 'co se týká',
    'Ref: &': '&',
    'Ref: at': 'v',
    'Ref: on the': 'dne',
  },
};
export default labels;
