import { Attachment, SiteProps } from '@/types/common.types';

export type Level = {
  name: string;
  level: number;
  id: string;
};

export type Property = {
  id: string;
  name: string;
};

export type Building = {
  name: string;
  level: number;
  id: string;
};

export enum CurrentStatus {
  InProgress = 'In Progress',
  Local = 'local',
}

export type TimelineMarker = {
  name: string;
  date: string;
  isProcessed: boolean;
};

export type Image = {
  fileName: string;
  id: string;
  content?: string;
};

export type CurrentServiceRequest = ServiceRequestProps & {
  location: LocationProps;
  messages: Message[];
  timeline: TimelineMarker[];
  images?: Image[];
};

export type Message = {
  description: string;
  createdBy: string;
  createdOn: string;
  isCreatedByUser: boolean;
  attachments?: Attachment[];
};

export type SentimentRatingType = {
  id: string;
  name: string;
};

export type RequestType = {
  id: string;
  name: string;
};

export type ServiceRequestUser = {
  id: string;
  mobile: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export type LocationProps = {
  id: string;
  name: string;
  title?: string;
  searchableTitle?: string;
  level?: number;
  assetTypeId?: string;
  searchable?: string;
  site?: SiteProps;
  hasChildren?: boolean;
  parentId?: string;
};

export type RaisedForType = {
  value: string;
  label: string;
  contactId?: string;
  firstName?: string;
  lastName?: string;
};

export type ServiceRequestCreationProps = {
  title?: string;
  description?: string;
  site?: string;
  buildingText?: string;
  roomText?: string;
  category?: string;
  raisedFor?: RaisedForType;
  templateId?: string;
  attachments?: HTMLInputElement[];
  phoneNumber?: string;
  myLocation?: LocationProps;
  isPreferredLocationCheckboxChecked?: boolean;
  additionalFields?: any[];
};

export type ServiceRequestProps = {
  referenceNumber: string;
  title: string;
  status: string;
  createdOn: string;
  modifiedOn: string;
  description: string;
  building?: LocationProps;
  wing?: LocationProps;
  room?: LocationProps;
  property?: LocationProps;
  floor?: LocationProps;
  site?: SiteProps;
  roomText?: string;
  category?: string;
  raisedFor?: RaisedForType;
  templateId?: string;
  attachments?: HTMLInputElement[];
  currentStatus: string;
  tmpId?: string;
  displayTitle?: string;
  id: string;
  isFeedbackSubmitted?: boolean;
  isComplaintSubmitted?: boolean;
  priority?: string;
  targetStartDateDate?: string;
  targetEndDateDate?: string;
  images?: Image[];
  messages?: Message[];
  correlationId?: string;
};

export type SrTutorial = {
  lastDisplayed: Date;
};
