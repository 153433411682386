const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Granskning',
    'Ref: status0': 'Öppen',
    'Ref: status1': 'Avslutad',
    'Ref: status2': 'Inställt',
    'Ref: connected': 'Ansluten',
    'Ref: not connected': 'Ej ansluten',
    'Ref: synchronise data': 'Synkronisera data',
    'Ref: sync success': 'Datasynkroniserad framgångsrikt',
    'Ref: Create new': 'Skapa ny',
    'Ref: Audit unsynchronised': 'Osynkroniserad',
    'Ref: Audit synchronised': 'Synkroniserad',
    'Ref: Unsynced error title': 'Ett fel har uppstått',
    'Ref: Unsynced error description':
      'Det verkar vara din första gång åtkomst till revisionsmodulen. Anslut till nätverk och "synkronisera" för att börja revision.',
    'Ref: Search all audits': 'Alla granskningar',
    'Ref: Search active audits': 'Öppen',
    'Ref: Search completed audits': 'Avslutad',
    'Ref: sync error': 'Synkronisering misslyckades',
    'Ref: sync error details': 'Försök igen för att återansluta',
    'Ref: no search audit results': 'Tyvärr finns det inga revisioner som motsvarar din sökning',
    'Ref: no audit results':
      'Du har inga tillgängliga granskningar, skapar nya för att komma igång',
    'Ref: Search canceled audits': 'Inställt',
    'Ref: Audit cancelled successfully': 'Granskningen avbröts framgångsrikt',
    'Ref: Cancel audit invite': 'Annullera',
    'Ref: Error audit cancel': 'Revision misslyckades med att avbrytas',
    'Ref: Cancel audit confirmation': 'Är du säker på att du vill avbryta revisionen?',
    'Ref: Confirm': 'Bekräfta',
    'Ref: Cancel audit': 'Avbryt revisionen',
    'Ref: Change the location': 'Ändra platsen',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Granskningsuppgifter',
    'Ref: questions': 'frågor',
    'Ref: question': 'fråga',
    'Ref: Additional Comments': 'Beskrivning',
    'Ref: Not found title': 'hoppsan!',
    'Ref: Not found text': 'Den granskning du försöker komma åt inte finns.',
    'Ref: Back to Audit list': 'Tillbaka till revisionslistan',
    'Ref: Success title': 'Tack!',
    'Ref: Success text': 'Du har slutfört revisionen',
    'Ref: submit': 'Skicka in',
    'Ref: add attachment': 'Lägg till en bilaga',
    'Ref: Unsupported elements':
      'Tyvärr, det verkar som om denna revision har innehåll att ansökan inte stöder ännu. Vi arbetar med det.',
    'Ref: form error': 'Inte alla frågor har besvarats fullt ut',
    'Ref: form complete': 'Slutförd sedan',
    'Ref: attachment preview error': 'Du måste vara online för att se den här bilagan',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Skapa ny',
    'Ref: Location': 'Plats',
    'Ref: Audit Stream Type': 'Ström',
    'Ref: Audit': 'Granska',
    'Ref: Asset': 'Tillgång',
    'Ref: Asset Name': 'Tillgångsnamn',
    'Ref: Is joint audit?': 'Är gemensam revision?',
    'Ref: Who was present?': 'Vem var närvarande?',
    'Ref: submit': 'Skicka in',
    'Ref: Select the Location': 'Välj plats',
    'Ref: Select the Audit Stream Type': 'Välj granskningsströmstyp',
    'Ref: Select the Audit': 'Välj granskningen',
    'Ref: Select the Asset': 'Välj tillgången',
    'Ref: form error': 'Inte alla obligatoriska fält har matats in helt',
    'Ref: Audit error': 'Bifogad granskning',
    'Ref: Joint Audit Participants error': 'Gemensam revisionsdeltagare',
    'Ref: Audit created': 'Revision skapad',
    'Ref: Audit field length error': 'Längden bör inte överstiga 100 tecken',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Ändra platsen',
    'Ref: Location changed': 'Plats ändras',
    'Ref: Location': 'Plats',
    'Ref: Select the Location': 'Välj plats',
    'Ref: Asset': 'Tillgång',
    'Ref: Select the Asset': 'Välj tillgången',
    'Ref: Asset Name': 'Tillgångsnamn',
    'Ref: submit': 'Skicka in',
    'Ref: Audit field length error': 'Längden bör inte överstiga 100 tecken',
  },
};
export default labels;
