import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import { AuditBIllustration } from '../../../../assets/illustrations';
import { HookSelect as Select } from '../../../../components/atoms/Select';
import { Skeleton } from '../../../../components/atoms/Skeleton';
import Card from '../../../../components/molecules/Card/Card';
import Form from '../../../../components/molecules/Form';
import { requiredRule } from '../../../../components/molecules/Form/helpers';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import {
  useGetAssetsQuery,
  useGetAuditLocationsQuery,
  useGetAuditSurveyQuery,
  useUpdateAuditInviteStateMutation,
} from '../../api';
import { AuditSurveyResponse } from '../../api/api.types';
import SubmitButton from '../../components/SubmitButton';
import { pagePaths } from '../../config';
import { useLocalData } from '../../hooks';
import { useAuditsTranslation } from '../../hooks/useAuditsTranslation';

import { AuditEditLocationFormFields } from './AuditEditLocationForm.types';

import toast from '@/components/atoms/ModalToast';
import Textarea from '@/components/atoms/Textarea';
import { OfflineQueueService } from '@/services/OfflineQueueService';

import styles from './AuditEditLocationForm.module.css';

const AuditEditLocationForm = () => {
  const { label } = useAuditsTranslation(__filename);
  const history = useHistory();
  const { id: auditInviteId }: { id: string } = useParams();

  const localData = useLocalData(auditInviteId);

  const { handleSubmit, control, watch, setValue } = useForm<AuditEditLocationFormFields>({
    mode: 'onSubmit',
  });

  const locationId = watch('locationId');
  const assetId = watch('assetId');
  const assetName = watch('assetName');

  const { data, isFetching } = useGetAuditSurveyQuery(auditInviteId);

  const { data: locationData = [] } = useGetAuditLocationsQuery();
  const { data: assetData = [], isFetching: isFetchingAssets } = useGetAssetsQuery(locationId, {
    skip: !Boolean(locationId),
  });

  const [updateAuditLocation, { isLoading: isSubmitting }] = useUpdateAuditInviteStateMutation();

  const auditInviteData = useMemo(
    () =>
      ({
        ...(localData?.audit || {}),
        ...data,
        locationId: localData?.audit?.locationId || data?.locationId,
        assetId: localData?.audit?.assetId || data?.assetId,
        assetName: localData?.audit?.assetName || data?.assetName,
      } as AuditSurveyResponse),
    [data, localData?.audit]
  );

  const locations = locationData?.map(({ id, name }) => ({ value: id, label: name }));
  const assets = assetData?.map(({ id, name }) => ({ value: id, label: name }));

  const initialLocationId = auditInviteData?.locationId || '';
  const initialAssetId = auditInviteData?.assetId || '';
  const initialAssetName = auditInviteData?.assetName || '';

  const isValidForm = !isEqual(
    {
      locationId: initialLocationId,
      assetId: initialAssetId,
      assetName: initialAssetName,
    },
    { locationId, assetId, assetName }
  );

  const onSuccess = useCallback(() => {
    toast.success(label('Ref: Location changed'), {
      position: 'top-center',
      closeOnClick: true,
      draggable: false,
      closeButton: false,
    });

    history.push(pagePaths.Module);
  }, [history, label]);

  const onSubmit: SubmitHandler<AuditEditLocationFormFields> = useCallback(
    async (formData) => {
      if (!isValidForm) return;

      const offlineQueue = OfflineQueueService.getInstance();

      if (auditInviteData) {
        const body = {
          workOrderId: auditInviteData.workOrderId,
          locationId: formData.locationId,
          workOrderLocation: locationData?.find((location) => location.id === formData.locationId)
            ?.name,
          assetId: formData.assetId,
          assetName: formData.assetName,
        };

        try {
          const queuedItem = await offlineQueue.getValue(auditInviteId);

          if (queuedItem) {
            if (queuedItem?.method === 'post') {
              const updatedItem = {
                ...queuedItem,
                data: {
                  ...queuedItem.data,
                  ...body,
                },
              };

              await offlineQueue.setValue(auditInviteId, updatedItem);
              onSuccess();
              return;
            }

            await offlineQueue.remove(auditInviteId);
          }

          await updateAuditLocation({
            id: auditInviteId,
            state: undefined,
            location: body,
          });

          onSuccess();
        } catch (err) {
          console.error(err);
        }
      }
    },
    [auditInviteData, auditInviteId, isValidForm, locationData, onSuccess, updateAuditLocation]
  );

  useEffect(() => {
    setValue('locationId', initialLocationId);
    setValue('assetId', initialAssetId);
    setValue('assetName', initialAssetName);
  }, [initialAssetId, initialAssetName, initialLocationId, setValue]);

  const pageTitle = label('Ref: Change the location');

  const noOptionsMessage = () => (
    <div className={styles.optionsFetchingPlaceholder}>
      <Skeleton width="60%" />
    </div>
  );

  return (
    <SimpleFormPage withNavBar={false} title={pageTitle} backButtonCustomPath={pagePaths.Module}>
      <Container>
        <Column.Main>
          {isFetching ? (
            <Card className={classNames('mb-L')} data-testid="skeleton-form">
              <div className={styles.container}>
                <div className={classNames(styles.dropdown)}>
                  <div className={classNames(styles.skeletonRow)}>
                    <div className={classNames(styles.skeletonLabel)}>
                      <Skeleton width="40%" />
                    </div>
                    <div className={classNames(styles.skeletonInput)}>
                      <Skeleton width="100%" />
                    </div>
                  </div>
                </div>
                <div className={classNames(styles.dropdown)}>
                  <div className={classNames(styles.skeletonRow)}>
                    <div className={classNames(styles.skeletonLabel)}>
                      <Skeleton width="40%" />
                    </div>
                    <div className={classNames(styles.skeletonInput)}>
                      <Skeleton width="100%" />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.inputWrapper}>
                <div className={classNames(styles.dropdown)}>
                  <div className={classNames(styles.skeletonRow)}>
                    <div className={classNames(styles.skeletonLabel)}>
                      <Skeleton width="40%" />
                    </div>
                    <div className={classNames(styles.skeletonInput)}>
                      <Skeleton width="100%" />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Card className={classNames('mb-L', styles.auditDetailsCard)}>
                <div className={styles.container}>
                  <div className={classNames(styles.dropdown)}>
                    <Select
                      defaultValue={initialLocationId}
                      name="locationId"
                      data-testid="locations-select"
                      label={label('Ref: Location')}
                      placeholder={label('Ref: Select the Location')}
                      control={control}
                      required
                      options={locations}
                      noOptionsMessage={noOptionsMessage}
                      rules={requiredRule(label('Ref: Location'), label)}
                      onChange={() => setValue('assetId', '')}
                    />
                  </div>
                  <div className={classNames(styles.dropdown)}>
                    <Select
                      defaultValue={initialAssetId}
                      name="assetId"
                      data-testid="assets-select"
                      label={label('Ref: Asset')}
                      placeholder={label('Ref: Select the Asset')}
                      control={control}
                      {...(isFetchingAssets
                        ? { options: [], noOptionsMessage }
                        : { options: assets })}
                      disabled={assets.length === 0 || isFetchingAssets}
                      onChange={() => {}}
                    />
                  </div>
                </div>
                <div className={styles.inputWrapper}>
                  <Textarea
                    value={initialAssetName}
                    label={label('Ref: Asset Name')}
                    name="assetName"
                    data-testid="asset-name-textarea"
                    rows={1}
                    maxlength={100}
                    control={control}
                    rules={{
                      maxLength: {
                        value: 100,
                        message: label('Ref: Audit field length error'),
                      },
                    }}
                  />
                </div>
              </Card>
              <SubmitButton label={label} isLoading={isSubmitting} isValidForm={isValidForm} />
            </Form>
          )}
        </Column.Main>
        <Column.Complementary>
          <AuditBIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default AuditEditLocationForm;
