import { useHistory } from 'react-router';

import NoticePage from '../../../components/templates/NoticePage/NoticePage';
import { pagePaths } from '../config';
import { useSurveyTranslation } from '../hooks/useSurveyTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const Success = () => {
  const { label } = useSurveyTranslation(__filename);
  const history = useHistory();

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('thank you', { textTransform: 'capitalize' })}
      tabTitle={label('Ref: Tabtitle')}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('Take another survey'),
          action: () => history.push(pagePaths.Module),
        },
        {
          label: label('Go to home', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
      ]}
    />
  );
};

export default Success;
