const labels = {
  AuditInviteList: {
    'Ref: Page title': 'ביקורת',
    'Ref: status0': 'לִפְתוֹחַ',
    'Ref: status1': 'הושלם',
    'Ref: status2': 'מבוטל',
    'Ref: connected': 'מְחוּבָּר',
    'Ref: not connected': 'לא מחובר',
    'Ref: synchronise data': 'סנכרון נתונים',
    'Ref: sync success': 'נתונים מסונכרנים בהצלחה',
    'Ref: Create new': 'צור חדש',
    'Ref: Audit unsynchronised': 'לא סינכרוני',
    'Ref: Audit synchronised': 'מסונכרן',
    'Ref: Unsynced error title': 'אירעה שגיאה',
    'Ref: Unsynced error description':
      'נראה שזו הפעם הראשונה שלך גישה למודול הביקורות. אנא התחבר לרשת ו"סנכרן "כדי להתחיל בביקורת.',
    'Ref: Search all audits': 'כל הביקורות',
    'Ref: Search active audits': 'לִפְתוֹחַ',
    'Ref: Search completed audits': 'הושלם',
    'Ref: sync error': 'סנכרון נכשל',
    'Ref: sync error details': 'לנסות מחדש להתחבר מחדש',
    'Ref: no search audit results': 'למרבה הצער, אין ביקורת המתאימה לחיפוש שלך',
    'Ref: no audit results': 'אין לך ביקורות זמינות, צור חדש כדי להתחיל',
    'Ref: Search canceled audits': 'מבוטל',
    'Ref: Audit cancelled successfully': 'ביקורת בוטלה בהצלחה',
    'Ref: Cancel audit invite': 'לְבַטֵל',
    'Ref: Error audit cancel': 'ביקורת לא נכשלה בוטלה',
    'Ref: Cancel audit confirmation': 'האם אתה בטוח שאתה רוצה לבטל את הביקורת?',
    'Ref: Confirm': 'לְאַשֵׁר',
    'Ref: Cancel audit': 'בטל ביקורת',
    'Ref: Change the location': 'שנה את המיקום',
  },
  AuditResponseForm: {
    'Ref: Page title': 'פרטי ביקורת',
    'Ref: questions': 'שאלות',
    'Ref: question': 'שְׁאֵלָה',
    'Ref: Additional Comments': 'תיאור',
    'Ref: Not found title': 'אופס!',
    'Ref: Not found text': 'הביקורת אליהן אתה מנסה לגשת לא קיימת.',
    'Ref: Back to Audit list': 'חזרה לרשימת הביקורת',
    'Ref: Success title': 'תודה!',
    'Ref: Success text': 'השלמת את הביקורת',
    'Ref: submit': 'שלח',
    'Ref: add attachment': 'הוסף קובץ מצורף',
    'Ref: Unsupported elements':
      'סליחה, נראה כי לביקורת זו יש תוכן כי היישום עדיין לא תומך. נעבוד על זה.',
    'Ref: form error': 'לא כל השאלות נענו במלואן',
    'Ref: form complete': 'הושלם מאז',
    'Ref: attachment preview error': 'אתה צריך להיות מקוון כדי לראות קובץ מצורף זה',
  },
  AuditCreationForm: {
    'Ref: Page title': 'צור חדש',
    'Ref: Location': 'מקום',
    'Ref: Audit Stream Type': 'זרם',
    'Ref: Audit': 'בְּדִיקָה',
    'Ref: Asset': 'נכס',
    'Ref: Asset Name': 'שם נכס',
    'Ref: Is joint audit?': 'האם ביקורת משותפת?',
    'Ref: Who was present?': 'מי היה נוכח?',
    'Ref: submit': 'שלח',
    'Ref: Select the Location': 'בחר את המיקום',
    'Ref: Select the Audit Stream Type': 'בחר בסוג זרם הביקורת',
    'Ref: Select the Audit': 'בחר את הביקורת',
    'Ref: Select the Asset': 'בחר את הנכס',
    'Ref: form error': 'לא כל השדות הנדרשים הוזנו במלואם',
    'Ref: Audit error': 'ביקורת מצורפת',
    'Ref: Joint Audit Participants error': 'משתתפי ביקורת משותפים',
    'Ref: Audit created': 'ביקורת נוצרה',
    'Ref: Audit field length error': 'אורך לא אמור לעלות על 100 תווים',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'שנה את המיקום',
    'Ref: Location changed': 'המיקום השתנה',
    'Ref: Location': 'מקום',
    'Ref: Select the Location': 'בחר את המיקום',
    'Ref: Asset': 'נכס',
    'Ref: Select the Asset': 'בחר את הנכס',
    'Ref: Asset Name': 'שם נכס',
    'Ref: submit': 'שלח',
    'Ref: Audit field length error': 'אורך לא אמור לעלות על 100 תווים',
  },
};
export default labels;
