import { screen, cleanup, render } from '@testing-library/react';

import { addProviders } from '../../../../../helpers/tests/addProviders';
import * as hooks from '../../../api';
import { scheduleTypeValues } from '../../../types';
import SchedulesList from '../SchedulesList';

const schedules = [
  {
    departure: '2023-02-28T22:05:00Z',
    arrival: '2023-02-28T22:45:00Z',
    durationMins: 40,
    itinerary: 'Bina Bina - Yandi Village',
  },
  {
    departure: '2023-02-28T22:20:00Z',
    arrival: '2023-02-28T23:00:00Z',
    durationMins: 40,
    itinerary: 'Bina Bina Mine - Yandi Village',
  },
];

// API hooks mocks
jest.mock('../../../api', () => ({
  useGetSchedulesQuery: jest.fn(),
}));

const defaultProps = {
  label: (s: string) => s,
  scheduleDate: new Date(),
  scheduleType: scheduleTypeValues.leave,
};

describe('SchedulesList', () => {
  afterAll(() => cleanup());

  beforeEach(() => jest.resetModules());

  describe('Initial render', () => {
    beforeAll(() => {
      (hooks.useGetSchedulesQuery as jest.Mock).mockReturnValueOnce({ data: [] });
      render(addProviders(<SchedulesList {...defaultProps} />));
    });

    it('Should have empty list placeholder', async () => {
      const placeholder = screen.getByText('Ref: list placeholder');
      expect(placeholder).toBeTruthy();
    });
  });

  describe('Fetching and rendering data', () => {
    it('Should show loading list when fetching', () => {
      (hooks.useGetSchedulesQuery as jest.Mock).mockReturnValueOnce({ data: [], isFetching: true });
      render(addProviders(<SchedulesList {...defaultProps} originId="123" destinationId="321" />));

      const loadingPlaceholder = screen.getByTestId('loading-placeholder');
      expect(loadingPlaceholder).toBeTruthy();
    });

    it('Should display list of retrieved items', () => {
      (hooks.useGetSchedulesQuery as jest.Mock).mockReturnValueOnce({
        data: schedules,
        isFetching: false,
      });
      render(addProviders(<SchedulesList {...defaultProps} originId="123" destinationId="321" />));

      const scheduleEntries = screen.getAllByTestId('schedule-entry');
      expect(scheduleEntries.length).toEqual(2);
    });

    it('Should display no items placeholder if no data was retrieved', () => {
      (hooks.useGetSchedulesQuery as jest.Mock).mockReturnValueOnce({
        data: [],
        isFetching: false,
      });
      render(addProviders(<SchedulesList {...defaultProps} originId="123" destinationId="321" />));

      const placeholder = screen.getByText('Ref: no items placeholder');
      expect(placeholder).toBeTruthy();
    });
  });
});
