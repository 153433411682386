const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Service Request - Helpdesk',
    'Ref: Forbidden heading': 'Forbidden',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'You are administrating service requests on the site {siteName}.',
    'Ref: Dates': 'Dates',
    'Ref: SRL list refresh': 'Refresh list',
    'Ref: Export': 'export',
    'Ref: Empty/not filled': 'Empty/not filled',
    'Ref: Name': 'Name',
    'Ref: Date': 'Date',
    'Ref: Category': 'Category',
    'Ref: User': 'User',
    'Ref: Location': 'Location',
  },
};
export default labels;
