import { useCallback, useMemo, useState } from 'react';

import { isSmallViewport } from '../../../helpers/windowSize';

import { Filter, MultiCardFilter, Filtering, UseFiltersProps } from './Filters.types';
import { getExpandedView, getFilteringStateAfterReset, isFilterInModal } from './helpers';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import { UserSteps } from '@/types/userSteps.types';

export const useFilters = ({
  filters,
  filtering,
  handleChange,
  label,
  languageCode = 'en-US',
  facilityId,
  testId,
}: UseFiltersProps) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, Record<string, boolean>>>(
    {}
  );
  const { logUserSteps } = useUserStepsInsightsLogging();

  //for events, i.e. everything but calendars
  const handleFilterChange = useCallback(
    (name: string, value: string, checked?: boolean) => {
      const filter = filters.find((item: Filter) => item.id === name);

      if (!filter) return;
      const newFilteringState: Filtering = { ...filtering, ...selectedFilters };

      const isSelected = checked !== undefined ? checked : true;

      if ((filter as MultiCardFilter).preventUnselect && !isSelected) return;

      if (filter.multiple) {
        newFilteringState[filter.id] = {
          ...newFilteringState[filter.id],
          [value]: isSelected,
        };
      } else {
        newFilteringState[filter.id] = { [value]: isSelected };
      }

      const isMomentFilter = filter.id === 'filter_moment';
      const hasCategoryFilter = filters.some((item: Filter) => item.id === 'filter_category');

      if (isMomentFilter && hasCategoryFilter) {
        newFilteringState['filter_category'] = { all: true };
      }

      setSelectedFilters(newFilteringState);

      const isInModal = isFilterInModal({
        position: filter.position,
        isSmallScreen: isSmallViewport(),
      });

      if (!isInModal) {
        handleChange(newFilteringState);
      }
    },
    [filtering, filters, handleChange, selectedFilters]
  );

  //for calendars
  const handleCalendarChange = useCallback(
    (filterId: string, strDate: string) => {
      const filter = filters.find((item: Filter) => item.id === filterId);

      if (!filter) return;

      const newFilteringState = { ...filtering, [filterId]: { [strDate]: true } };
      setSelectedFilters(newFilteringState);

      const isInModal = isFilterInModal({
        position: filter.position,
        isSmallScreen: isSmallViewport(),
      });

      if (!isInModal) {
        handleChange(newFilteringState);
      }
    },
    [filtering, filters, handleChange]
  );

  const onCommitFilter = useCallback(() => {
    handleChange({ ...filtering, ...selectedFilters });
    logUserSteps({ event: UserSteps.FiltersApplied, facilityId });
  }, [filtering, handleChange, logUserSteps, facilityId, selectedFilters]);

  const { visible, visibleFilters, invisible, invisibleFilters, modal, modalFilters } = useMemo(
    () =>
      getExpandedView({
        filters,
        filtering: { ...filtering, ...selectedFilters },
        label,
        languageCode,
        handleCalendarChange,
        handleChange: handleFilterChange,
        testId,
      }),
    [
      filtering,
      filters,
      handleCalendarChange,
      handleFilterChange,
      label,
      languageCode,
      selectedFilters,
      testId,
    ]
  );

  const resetFilterById = useCallback(
    (filterId: string) => {
      const filterToReset = visibleFilters.find((obj) => {
        return obj.id === filterId;
      });

      if (filterToReset) {
        const newFilters = { ...selectedFilters, [filterId]: {} };
        setSelectedFilters(newFilters);
        handleChange(newFilters);
      }
    },
    [handleChange, selectedFilters, visibleFilters]
  );

  const onDismissModal = useCallback(() => {
    const newFilter = modalFilters.reduce((acc, filter) => {
      const { [filter.id]: removeFilter, ...restFilter } = acc;
      return restFilter;
    }, selectedFilters);

    setSelectedFilters(newFilter);
  }, [modalFilters, selectedFilters]);

  const resetModalFilters = useCallback(() => {
    const filteringStateAfterReset = getFilteringStateAfterReset(modalFilters);
    setSelectedFilters(filteringStateAfterReset);
    handleChange(filteringStateAfterReset);
  }, [handleChange, modalFilters]);

  return {
    selectedFilters,
    visibleComponents: visible,
    visibleFilters,
    invisibleComponents: invisible,
    invisibleFilters,
    modalComponents: modal,
    modalFilters,
    onCommitFilter,
    resetFilterById,
    resetModalFilters,
    handleFilterChange,
    handleCalendarChange,
    onDismissModal,
  };
};
