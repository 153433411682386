const labels = {
  All: {
    'Ref: See item': 'Ver artículo',
    'Ref: Print': 'Imprima su recibo',
    'Ref: Email': 'Reciba su recibo por correo electrónico',
    'Ref: Receipt Modal message': '¿Cómo le gustaría obtener su recibo?',
    'Ref: Loyalty Reward': 'Recompensa de lealtad',
    'Ref: Equation for stamps per item': '1 compra = 1 sello',
    'Ref: Paid by employer': 'La cantidad pagada por su empleador',
    'Ref: Paid by user': 'La cantidad pagada por usted',
  },
  ProductsList: {
    'Ref: Page title': 'Lista de productos',
    'Ref: Facility details': 'Detalles de la instalación',
    'Ref: Search products list': 'Artículos de búsqueda',
    'Ref: filter label: menus': 'Menú',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoría',
    'Ref: all': 'Todo',
    'Ref: today': 'Hoy',
    'Ref: tomorrow': 'Mañana',
    'Ref: see more': 'Ver más',
    'Ref: see less': 'Ver menos',
    'Ref: reset': 'Reiniciar',
    'Ref: View in cart': 'Ver en el carrito',
    'Ref: You have changed location': 'Has cambiado la ubicación',
    'Ref: You are now at': 'Ahora estas en',
    'Ref: Menu card': 'Tarjeta de menú',
    'Ref: Table': 'Número de tabla',
    'Service unavailable': 'Servicio no disponible',
    'Ref: Ordering unavailable':
      '¡Cuidadoso! Pedir en este sitio no está disponible en este momento, intente más tarde.',
    'Ref: Filters': 'Filtros (alérgenos y más)',
  },
  Order: { 'Ref: Page title': 'Pedido' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menús',
    'Ref: today': 'Hoy',
    'Ref: tomorrow': 'Mañana',
    'Ref: Search products list': 'Artículos de búsqueda',
    'Ref: You have changed location': 'Has cambiado la ubicación',
    'Ref: You are now at': 'Ahora estas en',
  },
  ProductDetails: {
    'Ref: Page title': 'Detalles de producto',
    'Ref: Serving Size': 'Tamaño de la porción',
    'Ref: Nutrition': 'Información nutricional',
    'Ref: Nutrition adults': 'Los adultos necesitan alrededor de 2000 KCAL al día.',
    'Ref: Nutrition Calories': 'Calorías',
    'Ref: Nutrition Calcium': 'Calcio',
    'Ref: Nutrition Fat': 'Grasa',
    'Ref: Nutrition Saturated Fat': 'Grasa saturada',
    'Ref: Nutrition Carbohydrates': 'Carbohidratos',
    'Ref: Nutrition Sugar': 'Azúcar',
    'Ref: Nutrition Protein': 'Proteína',
    'Ref: Nutrition Sodium': 'Sodio',
    'Ref: Nutrition cholesterol': 'Colesterol',
    'Ref: Nutrition dietaryFiber': 'Fibra dietética',
    'Ref: Nutrition iron': 'Hierro',
    'Ref: Nutrition potassium': 'Potasio',
    'Ref: Nutrition vitaminA': 'Vitamina A',
    'Ref: Nutrition vitaminC': 'Vitamina C',
    'Ref: Additives': 'Aditivos',
    'Ref: Emission': 'emisiones de CO2',
    'Ref: This product generates': 'Este producto genera',
    'Ref: of CO2': 'de co2',
    'Ref: emissions, which equals a': 'Emisiones, que igualan a un',
    'Ref: car trip': 'viaje en coche',
    'Ref: Ingredients': 'Receta ingrediente',
    'Ref: Allergens': 'Alérgenos',
    'Ref: Always there': 'Siempre allí',
    'Ref: Allergens selected': 'Alérgenos seleccionados en el filtro:',
    'Ref: minimum required': 'mínimo requerido',
    'Ref: you have to add at least': 'Tienes que agregar al menos',
    'Ref: ingredients': 'ingredientes',
    'Ref: Ingredient': 'ingrediente',
    'Ref: Free': 'Gratis',
    'Ref: Based on customization': 'Basado en su personalización',
    'Ref: Additives eggs': 'huevos',
    'Ref: Additives fish': 'pescado',
    'Ref: Additives milk': 'Leche',
    'Ref: Additives nuts': 'nueces',
    'Ref: Additives wheat': 'trigo',
    'Ref: Additives peanuts': 'miseria',
    'Ref: Additives soy beans': 'frijoles de soya',
    'Ref: Additives sesame seeds': 'semillas de sésamo',
    'Ref: Review': 'Revisar',
    'Ref: Per portion': 'Por porción',
    'Ref: Per 100g': 'Por 100 g',
    'Ref: Allergen selected': 'Alergeno seleccionado en el filtro de búsqueda',
    'Ref: Fitbit Log': 'Registro a Fitbit',
    'Ref: age verification required on delivery': 'Verificación de edad requerida en el parto',
    'Ref: alcohol content notification':
      'Al colocar y ordenar uno de estos artículos, declara que tiene 18 años o más. El abuso de alcohol es peligroso para su salud.',
    'Ref: default': 'Defecto',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} ganado si solicita este producto',
    'Ref: Loyalty Card Description':
      'El {stamp_form} se agregará a la siguiente lealtad {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'El programa de sellos de lealtad le permite recolectar sellos para recompensas.',
    'Ref: Loyalty Modal Description':
      'Cuando compre <b> {product_name}, se agregará 1 sello </b> al <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'y',
    'Ref: Stamp': 'Estampilla',
    'Ref: Stamps': 'Sellos',
    'Ref: Program': 'Programa',
    'Ref: Programs': 'Programas',
    'Ref: Loyalty Stamps': 'Sellos de lealtad',
    'Ref: See Loyalty Stamps': 'Ver sellos de lealtad',
    Favorite: 'Favorito',
    'Ref: In favorites': 'El producto ya está en la lista de favoritos. Haga clic para eliminarlo.',
    'Ref: Not in favorites':
      'El producto aún no está en la lista de favoritos. Haga clic para agregarlo.',
    'Ref: Added to favorites': 'El producto se ha agregado a la lista de favoritos.',
    'Ref: Removed from favorites': 'El producto ha sido eliminado de la lista de favoritos.',
    'Ref: From': 'De',
  },
  OrderHistory: { 'Ref: Orders': 'Pedidos', 'Ref: Page Title': 'Mis ordenes' },
  MenuTile: { 'Ref: Scan&Go': 'Escanear y ir', 'Ref: View only': 'Sólo vista' },
  ProductTile: {
    'Ref: Allergens': 'Alérgenos',
    'Ref: Presence of allergen': 'Presencia de alérgeno',
    'Ref: Scan': 'Escanear',
    'Ref: From': 'De',
    'Ref: Already in product favorite list. Click to remove it.':
      'Ya en la lista favorita del producto. Haga clic para eliminarlo.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Actualmente no se agregó a la lista favorita del producto. Haga clic para agregarlo.',
    'Ref: Free': 'Gratis',
  },
  MenusWidget: {
    'Ref: no menu': 'No hay menú por el momento',
    'Ref: Modal title': 'Menú para el día',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Levantar',
    'Ref: Eat-in': 'Comedor',
    'Ref: Scan&Go': 'Escanear y ir',
    'Ref: Delivery': 'Entrega',
    'Ref: Total': 'Total',
    'Ref: How was facility name service': '¿Cómo fue el servicio {facility_name} ´?',
    'Ref: How do you rate the service': '¿Cómo se califica el servicio?',
  },
};
export default labels;
