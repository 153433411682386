const labels = {
  All: {
    'starts at': 'começa em',
    'ends at': 'termina em',
    location: 'Localização',
    'coming date': 'chegada data',
    'Ref: Currently event not preferred. Click to make preferred':
      'Atualmente evento não preferido. Clique para tornar preferido',
    'Ref: Currently event preferred. Click to make not preferred':
      'Atualmente o evento preferido. Clique para tornar não preferido',
  },
  EventDetails: {
    details: 'Detalhes',
    'Ref: Page title': 'detalhes do evento',
    'Event dates': 'Datas de eventos',
    'See online:': 'Veja online:',
    'See all dates': 'Veja todas as datas',
    'Remove from my selection': 'Retire da minha seleção',
    'Add to my selection': 'Adicionar ao meu preferido',
    'Ref: explain calendar usage':
      'As datas de eventos são destacadas no calendário abaixo. Não perca os eventos que você gosta! Clique em uma data de evento no calendário para baixar um convite. Em seguida, abra o arquivo baixado para adicionar o evento ao seu calendário preffered.',
    'Ref: Favorite': 'Favorito',
    'Ref:Feedback': 'Comentários',
    'Ref: Event site different title': 'Este evento não pertence ao seu site atual',
    'Ref: Event site different description':
      'Você está visualizando um evento que não pertence ao seu site atual. Você não poderá vê -lo na lista de eventos.',
    'Ref: See all dates': 'Veja todas as datas',
    'Ref: From': 'De',
    'Ref: to': 'para',
    'Ref: Coming date': 'Data de vinda',
    'Ref: Add to calendar': 'Adicione ao calendário',
  },
  EventsList: {
    'Ref: Page title': 'Eventos',
    'All dates': 'Todas as datas',
    'All events': 'Todos os eventos',
    'Ref: Search': 'Procurar',
    'My selection': 'Meu preferido',
    'filter label: date': 'Encontro',
    'filter label: coming events': 'eventos próximos',
    'filter label: past events': 'eventos passados',
    'filter label: interest': 'preferência',
  },
  Widget: {
    'Ref: Carousel title': 'Eventos',
    'No info yet, come back later': 'Ainda não há informação, volte mais tarde 😉',
  },
};
export default labels;
