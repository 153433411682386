const labels = {
  ContactForm: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Category field value': 'Ref: Category field value',
  },
  ContactList: {
    'Ref: Page title': 'Ref: Page title',
    'call (action to place a call)': 'call (action to place a call)',
    'write (action to write a message)': 'write (action to write a message)',
    'write (action to write an e-mail)': 'write (action to write an e-mail)',
    'Ref: Hint message': 'Ref: Hint message',
  },
  FormSubmissionFailure: { 'Ref: Message body': 'Ref: Message body' },
  FormSubmissionSuccess: { 'Ref: submitted success': 'Ref: submitted success' },
};
export default labels;
