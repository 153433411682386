import TitleBarNavigation from './Navigation';
import TitleBar from './TitleBar';
import TitleBarTwoIconsLink from './TitleBarTwoIconsLink/TitleBarTwoIconsLink';
import TitleBarWidget from './TitleBarWidget';
import TitleBarWidgets from './Widgets';

export type { TitleBarWidgetProps as TitleBarLinkProps, TitleBarIconProps } from './TitleBar.types';

export default Object.assign(TitleBar, {
  Widgets: TitleBarWidgets,
  Navigation: TitleBarNavigation,
  Widget: TitleBarWidget,
  TwoIconsLink: TitleBarTwoIconsLink,
});
