const labels = {
  All: {
    dailyMenu: 'Menú diario',
    anytime: 'En cualquier momento',
    breakfast: 'Desayuno',
    morningBreak: 'descanso de la mañana',
    lunch: 'Almuerzo',
    afternoonBreak: 'descanso de la tarde',
    dinner: 'Cena',
    supper: 'Cena',
    nightMeal: 'Comida Nochera',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menús',
    'Ref: View details': 'Ver detalles',
    'Ref: Facility details': 'Detalles de instalación',
  },
  OpeningHours: { everyday: 'todos los días', 'open all day': 'Abierto todo el día' },
  ProductsList: {
    'Ref: Page title': 'Menú',
    'Ref: Facility details': 'Detalles de instalación',
    'Ref: Search products list': 'Artículos de búsqueda',
    'Ref: filter label: date': 'Fecha',
    'Ref: filter label: menus': 'Menú',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoría',
    'Ref: filter label: dishes': 'Platos',
    'Ref: filter label: highlight': 'Resaltar u ocultar filtros con platos',
    All: 'Todos',
  },
  ProductDetails: { 'Ref: Page title': 'Detalles del producto' },
  Widget: {
    'Ref: Carousel title': 'Menús',
    'No info yet, come back later': 'No hay información todavía, vuelve más tarde 😉',
    'Ref: menus': 'Menús',
    'Ref: see all': 'Ver todo',
  },
};
export default labels;
