import { cleanup, fireEvent, screen } from '@testing-library/react';
import { createMemoryHistory } from 'history';
import { FormProvider, useForm } from 'react-hook-form';

import renderComponent from '@/helpers/tests/renderComponent';
import { pagePaths } from '@/modules/AccommodationRequest/config';
import CreateError from '@/modules/AccommodationRequest/routes/CreateError';
import { AccommodationRequestFormFields } from '@/modules/AccommodationRequest/types';

const CreateErrorTest = () => {
  const formMethods = useForm<AccommodationRequestFormFields>({
    defaultValues: {
      phoneNumber: '',
      comment: '',
      guestFirstName: '',
      guestLastName: '',
      site: '3a301679-bd7c-e911-a97e-000d3a2bcd97',
      dateFields: {
        arrivalDate: new Date('2023-09-25T19:02:55.059'),
        departureDate: new Date('2023-09-26T12:00:00.059'),
      },
    },
  });
  return (
    <FormProvider {...formMethods}>
      <CreateError />
    </FormProvider>
  );
};

const mockHistory = createMemoryHistory();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
}));

describe('CreateError', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    renderComponent(CreateErrorTest);

    it('should render accomodation create error screen', async () => {
      expect(screen.getByTestId('create-error-title')).toBeTruthy();
      expect(screen.getByTestId('create-error-text')).toBeTruthy();
      expect(screen.getByTestId('button-action-primary')).toBeTruthy();
      expect(screen.getByTestId('button-action-secondary')).toBeTruthy();
    });
  });

  describe('on "Go to home" button click', () => {
    let homeButton: HTMLElement;
    renderComponent(CreateErrorTest);

    beforeEach(() => {
      homeButton = screen.getByTestId('button-action-secondary');
    });

    it('should have redirected to homepage', () => {
      expect(homeButton).toBeTruthy();

      fireEvent.click(homeButton);
      expect(mockHistory.location.pathname).toBe('/');
    });
  });

  describe('on "Retry" button click', () => {
    let retryButton: HTMLElement;
    renderComponent(CreateErrorTest);

    beforeEach(() => {
      retryButton = screen.getByTestId('button-action-primary');
    });

    it('should have redirected to create form', () => {
      expect(retryButton).toBeTruthy();
      fireEvent.click(retryButton);
      expect(mockHistory.location.pathname).toBe(pagePaths.CreateForm);
    });
  });
});
