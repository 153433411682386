import { cleanup } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../helpers/tests/renderComponent';

import WorkplaceLogin from './WorkplaceLogin';

const mockHistory = createMemoryHistory();
const mockAllQueryStrings =
  '?regionCode=EU&geoCode=FR&module=events&token=eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiRG9yaW4gTWFub2xpIiwic3ViIjoiRG9yaW4uTWFub2xpLmV4dEBzb2RleG8uY29tIiwiY2lkIjoiMzU2ZGRkZWItNTgwNy1lZDExLTgyZTUtMDAwZDNhYThlNWMyIiwiY2NpZCI6IjllZTQ0ZGE4LWY0N2ItZTkxMS1hOTdkLTAwMGQzYTJiY2M2OSIsInJzaWQiOiJhMDI1MWI2ZC00ODdlLWU5MTEtYTk3Ny0wMDBkM2EyYmNhYzIiLCJyb2xlIjoiQ29uc3VtZXIgQXBwIFVzZXIiLCJzc28iOiJ0cnVlIiwibmJmIjoxNzA0NDU1MTcwLCJleHAiOjE3MDQ0NTY5NzAsImlzcyI6Imh0dHBzOi8vb2F1dGgucG9ydGFsLnNvZGV4by5jb20iLCJhdWQiOiIyNDE1ZjNjOS1mZWEwLTQ5ODUtOTc0Ny1kMjU1NmExMDZmY2YifQ.gPNek65IlyzQUync9oDcKRRt9ppaQIFzBceztHx8a17A0pAUKxB6qi0AXCufkWiP0GYOuW7el4IwRnsX8ZDZDQ';

const mockLocation = jest.fn();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
  useLocation: () => mockLocation(),
}));

const mockDispatch = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockDispatch,
}));

describe('WorkplaceLogin', () => {
  describe('redirect to module', () => {
    beforeEach(() => {
      mockLocation.mockReturnValue({
        search: mockAllQueryStrings,
      });
    });
    renderComponent(WorkplaceLogin);
    afterAll(() => cleanup());
    it("When all query strings passed should redirect to module 'events'", () => {
      expect(mockDispatch).toHaveBeenCalled();
      expect(mockHistory.location.pathname).toBe('/events');
    });
  });
  describe('When module query string missing should authorise and redirect to homepage', () => {
    beforeEach(() => {
      const mockNoModuleQueryStrings =
        '?regionCode=EU&geoCode=FR&token=eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiRG9yaW4gTWFub2xpIiwic3ViIjoiRG9yaW4uTWFub2xpLmV4dEBzb2RleG8uY29tIiwiY2lkIjoiMzU2ZGRkZWItNTgwNy1lZDExLTgyZTUtMDAwZDNhYThlNWMyIiwiY2NpZCI6IjllZTQ0ZGE4LWY0N2ItZTkxMS1hOTdkLTAwMGQzYTJiY2M2OSIsInJzaWQiOiJhMDI1MWI2ZC00ODdlLWU5MTEtYTk3Ny0wMDBkM2EyYmNhYzIiLCJyb2xlIjoiQ29uc3VtZXIgQXBwIFVzZXIiLCJzc28iOiJ0cnVlIiwibmJmIjoxNzA0NDU1MTcwLCJleHAiOjE3MDQ0NTY5NzAsImlzcyI6Imh0dHBzOi8vb2F1dGgucG9ydGFsLnNvZGV4by5jb20iLCJhdWQiOiIyNDE1ZjNjOS1mZWEwLTQ5ODUtOTc0Ny1kMjU1NmExMDZmY2YifQ.gPNek65IlyzQUync9oDcKRRt9ppaQIFzBceztHx8a17A0pAUKxB6qi0AXCufkWiP0GYOuW7el4IwRnsX8ZDZDQ';

      mockLocation.mockReturnValue({
        search: mockNoModuleQueryStrings,
      });
    });
    renderComponent(WorkplaceLogin);
    afterAll(() => cleanup());

    it('render without module should redirect to homepage', () => {
      expect(mockHistory.location.pathname).toBe('/home');
    });
  });
  describe('When geoCode not passed redirect to login page', () => {
    beforeEach(() => {
      const mockNogeoCodeQueryStrings =
        '?regionCode=EU&token=eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiRG9yaW4gTWFub2xpIiwic3ViIjoiRG9yaW4uTWFub2xpLmV4dEBzb2RleG8uY29tIiwiY2lkIjoiMzU2ZGRkZWItNTgwNy1lZDExLTgyZTUtMDAwZDNhYThlNWMyIiwiY2NpZCI6IjllZTQ0ZGE4LWY0N2ItZTkxMS1hOTdkLTAwMGQzYTJiY2M2OSIsInJzaWQiOiJhMDI1MWI2ZC00ODdlLWU5MTEtYTk3Ny0wMDBkM2EyYmNhYzIiLCJyb2xlIjoiQ29uc3VtZXIgQXBwIFVzZXIiLCJzc28iOiJ0cnVlIiwibmJmIjoxNzA0NDU1MTcwLCJleHAiOjE3MDQ0NTY5NzAsImlzcyI6Imh0dHBzOi8vb2F1dGgucG9ydGFsLnNvZGV4by5jb20iLCJhdWQiOiIyNDE1ZjNjOS1mZWEwLTQ5ODUtOTc0Ny1kMjU1NmExMDZmY2YifQ.gPNek65IlyzQUync9oDcKRRt9ppaQIFzBceztHx8a17A0pAUKxB6qi0AXCufkWiP0GYOuW7el4IwRnsX8ZDZDQ';

      mockLocation.mockReturnValue({
        search: mockNogeoCodeQueryStrings,
      });
    });
    renderComponent(WorkplaceLogin);
    afterAll(() => cleanup());

    it('render without module should redirect to homepage', () => {
      expect(mockHistory.location.pathname).toBe('/access/login');
    });
  });
});
