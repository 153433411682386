import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import StepperCreate from '../../components/StepperCreate';
import { enableGuestRequestSetupOption, pagePaths } from '../../config';
import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';
import { AccommodationRequestFormFields } from '../../types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import List from '@/components/organisms/List/List';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { formatDate, formatTime } from '@/helpers/dateTime';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { useCreateAccommodationRequestMutation } from '@/modules/AccommodationRequest/api';
import { SERVICE } from '@/modules/config';
import { useGetSitesQuery } from '@/modules/Sites/api/api';
import { GridItem } from '@/types/common.types';
import { State } from '@/types/state.types';

export const guestUserPhoneNumber = '123456789';

const CreateSummary = () => {
  const history = useHistory();
  const { label } = useAccommodationRequestTranslation(__filename);
  const accommodationEnableGuestRequest = useIsSetupOptionEnabled(
    enableGuestRequestSetupOption,
    SERVICE.ACCOMMODATION_REQUEST
  );
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);
  const { data: allSites = [] } = useGetSitesQuery({});
  const { getValues } = useFormContext<AccommodationRequestFormFields>();
  const values = getValues();
  const selectedSite = allSites.find((s) => s.id === values.site);
  const [createAccommodationRequest, { isSuccess, isError, isLoading }] =
    useCreateAccommodationRequestMutation();
  const items: GridItem[] = [];
  const guestName = `${values.guestFirstName} ${values.guestLastName}`;

  items.push({
    id: 'site',
    label: label('Ref: site'),
    value: selectedSite?.name ?? '',
    path: pagePaths.CreateForm,
    'data-testid': 'site',
  });

  if (accommodationEnableGuestRequest)
    items.push({
      id: 'email',
      label: label('Ref: email of guest'),
      value: values.email,
      path: pagePaths.CreateForm,
      'data-testid': 'email',
    });
  else
    items.push({
      id: 'phoneNumber',
      label: label('Ref: phoneNumber'),
      value: values.phoneNumber,
      path: pagePaths.CreateForm,
      'data-testid': 'phoneNumber',
    });

  items.push(
    {
      id: 'raisedFor',
      label: label('Ref: request raised for'),
      value: guestName !== ' ' ? guestName : label('Ref: Me'),
      path: pagePaths.CreateForm,
      'data-testid': 'raised-for',
    },
    {
      id: 'arrival',
      label: label('Ref: arrivalDate'),
      value: values.dateFields?.arrivalDate
        ? `${formatDate(new Date(values.dateFields.arrivalDate), currentLanguageCode)} -
        ${formatTime(new Date(values.dateFields.arrivalDate))}`
        : '',
      path: pagePaths.CreateForm,
      'data-testid': 'arrival',
    },
    {
      id: 'departure',
      label: label('Ref: departureDate'),
      value: values.dateFields?.departureDate
        ? `${formatDate(new Date(values.dateFields.departureDate), currentLanguageCode)} -
        ${formatTime(new Date(values.dateFields.departureDate))}`
        : '',
      path: pagePaths.CreateForm,
      'data-testid': 'departure',
    },
    {
      id: 'comment',
      label: label('Ref: description'),
      value: values.comment,
      path: pagePaths.CreateForm,
      'data-testid': 'comment',
    }
  );

  const handleCreate = useCallback(() => {
    if (accommodationEnableGuestRequest) {
      createAccommodationRequest({
        arrivalDateTime: values.dateFields.arrivalDate.toISOString(),
        departureDateTime: values.dateFields.departureDate.toISOString(),
        description: `${values.comment}\nemail: ${values.email}`,
        phoneNumber: guestUserPhoneNumber,
        siteId: values.site,
        guestUserName: guestName !== ' ' ? guestName : '',
      });
    } else {
      createAccommodationRequest({
        arrivalDateTime: values.dateFields.arrivalDate.toISOString(),
        departureDateTime: values.dateFields.departureDate.toISOString(),
        description: values.comment,
        phoneNumber: values.phoneNumber,
        siteId: values.site,
        guestUserName: guestName !== ' ' ? guestName : '',
      });
    }
  }, [values, createAccommodationRequest, guestName, accommodationEnableGuestRequest]);

  useEffect(() => {
    if (isSuccess) {
      history.push(pagePaths.CreateThankYou);
    } else if (isError) {
      history.push(pagePaths.CreateError);
    }
  }, [isSuccess, isError, history]);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <SimpleFormPage
      title={label('Ref: Confirm your request')}
      data-testid="accommodation-request-create-summary"
    >
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              tag: TITLE_TAG.H2,
              children: label('Ref: Summary'),
              size: TITLE_SIZE.HEADLINES,
            }}
          >
            <List data-testid="accommodation-request-create-summary-list" items={items} />
          </Card>
        </Column.Main>
        <Column.Side>
          <StepperCreate stepIndex={1} />
        </Column.Side>
        <ActionsBar>
          <Button
            data-testid="accommodation-request-create-summary-submit"
            look={BUTTON_LOOK.PRIMARY}
            onClick={handleCreate}
          >
            {label('Ref: Submit', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default CreateSummary;
