import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import { TestingPropsV2 } from '../../../types';

import { WarningIcon } from '@/assets/icons';
import DismissButton from '@/components/atoms/DismissButton/DismissButton';
import toast from '@/components/atoms/ModalToast';
import Title, { TITLE_SIZE } from '@/components/atoms/Title';

export const useShowToast = () => {
  const { label } = useCustomTranslation();

  const showError = ({ 'data-testid': dataTestid, 'data-cy': dataCy }: TestingPropsV2) => {
    toast.error(
      <div data-testid={`${dataTestid}-error-toast`} data-cy={dataCy}>
        <Title size={TITLE_SIZE.BODYSBOLD}>{label('Ref: toast error title')}</Title>
        <Title size={TITLE_SIZE.BODYSDEFAULT}>{label('Ref: toast error subtitle')}</Title>
      </div>,
      {
        icon: <WarningIcon />,
        position: 'top-center',
        closeOnClick: true,
        draggable: false,
        closeButton: (
          <DismissButton data-testid={`${dataTestid}-error-toast-dismiss-btn`} label={label} />
        ),
        hideProgressBar: true,
      }
    );
  };

  return {
    showError,
  };
};
