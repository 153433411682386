import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { AccordionProps } from './Accordion.types';
import AccordionSection from './AccordionSection';
import { isOpenSection } from './helpers/Accordion.helpers';

import styles from './Accordion.module.css';

const Accordion = ({
  sections,
  allowMultipleSectionOpen = false,
  defaultOpenedSections = [],
  titleCustomClass,
  inheritStyles,
  "data-testid": testId,
}: AccordionProps) => {
  const [openedSections, setOpenedSections] = useState(defaultOpenedSections);

  const onSectionToggle = useCallback(
    (sectionId: any) => {
      const sectionWasOpened = openedSections.includes(sectionId);

      setOpenedSections((prevSectionsOpened) => {
        const sectionAdded = allowMultipleSectionOpen
          ? [...prevSectionsOpened, sectionId]
          : [sectionId];

        return sectionWasOpened
          ? prevSectionsOpened.filter((id) => id !== sectionId)
          : sectionAdded;
      });
    },
    [allowMultipleSectionOpen, openedSections, setOpenedSections]
  );

  return (
    <div className={classNames(styles.accordionWrapper)} data-testid={`accordion-${testId}`}>
      {sections.map((section) => (
        <AccordionSection
          key={section.id}
          section={section}
          titleCustomClass={titleCustomClass}
          inheritStyles={inheritStyles}
          tabIndex={0}
          onToggle={onSectionToggle}
          isSectionOpen={isOpenSection(openedSections, section.id)}
          data-testid={`accordion-${section['data-testid']}`}
        />
      ))}
    </div>
  );
};

export default Accordion;
