import React from 'react';
import { useHistory } from 'react-router';

import { pagePaths } from '../../config';
import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Title, { TITLE_SIZE } from '@/components/atoms/Title';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import ErrorPage from '@/components/templates/ErrorPage';
import styles from '@/modules/AccommodationRequest/routes/CreateError/CreateError.module.css';

const CreateError: React.FC = () => {
  const history = useHistory();
  const { label } = useAccommodationRequestTranslation(__filename);

  return (
    <ErrorPage>
      <h1 className={styles.title} data-testid="create-error-title">
        {label('Ref: Create error title')}
      </h1>
      <Title data-testid="create-error-text" size={TITLE_SIZE.HEADLINEXS} className={styles.text}>
        {label('Ref: Create error text')}
      </Title>
      <ActionsBar className={styles.actionButton}>
        <Button
          data-testid="create-error-primary-action"
          look={BUTTON_LOOK.PRIMARY}
          aria-label={label('Ref: Retry')}
          onClick={() => history.push(pagePaths.CreateForm)}
        >
          {label('Ref: Retry')}
        </Button>
        <Button
          data-testid="create-error-secondary-action"
          look={BUTTON_LOOK.SECONDARY}
          aria-label={label('Go to home')}
          onClick={() => history.push('/')}
        >
          {label('Go to home')}
        </Button>
      </ActionsBar>
    </ErrorPage>
  );
};

export default CreateError;
