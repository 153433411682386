const labels = {
  SiteDetails: {
    'Select site': '選擇此地點',
    'Ref: Page title': '地點詳情',
    'Ref: Skip map': '跳至主要內容',
    'Ref: Unavailable site': '預覽不可用',
    'Ref: Sorry, no description yet': '抱歉，當前地點的描述還未設置。',
  },
  SitesList: {
    'Ref: Site coordinates not found': '未找到當前地點坐標信息',
    'Ref: Page title': '地點',
    'Recently visited': '最近訪問',
    'All other sites': '所有其它地點',
    'Sites in your country': '您所在國家的地點',
    'Search results': '搜索結果',
    'Select site': '選擇一個地點',
    'Ref: Select site with QR Scanner': '通過二維碼選擇地點',
    'Ref: Select site with code': '通過代碼選擇地點',
    'Ref: Validate': '驗證',
    'Ref: Enter site code': '輸入地點代碼',
    'Ref: Scanner error header': '未找到地點',
    'Ref: Generic error': '出錯了',
    'Ref: Change site': '更改地點',
    'Ref: Change site description': '購物車中有項目！更改地點將導致清除購物車。',
    'Ref: Cancel': '取消',
    'Ref: Not found': '該二維碼尚未被設置',
    'Ref: Unauthorized error': '由於站點限制級別而未經授權的請求',
    'Ref: Forbidden': '非常抱歉，您對當前地點的訪問授權似乎有問題。請與服務中心聯繫。',
    "Ref: We didn't find the site.": '我們未找到此地點。',
    'Ref: We recommend some solutions:': '我們建議：',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1.嘗試掃描地點二維碼或點擊下面的按鈕添加您的地點',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2.註銷並在重新登錄時選擇正確的國家',
    'Ref: The currently selected country is:': '當前選擇的國家是：',
  },
  SwitchSite: { 'Not on {site name}?': '不是{site name}？' },
  SiteTile: { Details: '詳情', Select: '選擇' },
};
export default labels;
