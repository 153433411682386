import { saveTokens } from '../../helpers/tokensHelpers';
import mockResponse from '../../mocks/guestUserApi';

import {
  ConvertGuestToRegisteredUsersRequest,
  RegisterGuestUserRequest,
} from './guestUserApi.types';

import baseApi from '@/services/api/baseApi';

import { TokenRefreshResponse } from 'src/axios/tokenRefresh.types';

const guestUserApi = baseApi.enhanceEndpoints({ addTagTypes: ['guest'] }).injectEndpoints({
  endpoints: (build) => ({
    convertGuestToRegisteredUsers: build.mutation<void, ConvertGuestToRegisteredUsersRequest>({
      query: (args) => ({
        url: '/v2/user/guesttoregisteruser',
        method: 'post',
        data: { ...args },
        mockData: mockResponse.convertGuestToRegisteredUsers,
      }),
      transformErrorResponse: (response) => {
        const errorResponse = response.data as any;
        return errorResponse.Message;
      },
    }),
    registerGuestUser: build.mutation<TokenRefreshResponse, RegisterGuestUserRequest>({
      query: (args) => ({
        url: '/v3/registrations/guestusers',
        method: 'post',
        data: { contractId: args.contractId, siteId: args.siteId },
        mockData: mockResponse.registerGuestUser,
      }),
      async onQueryStarted({ currentGeoCode }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          saveTokens(data);
          dispatch({
            type: 'LOGGED_IN',
            currentGeoCode: currentGeoCode,
            username: 'guest_user',
            isGuest: true,
          });
        } catch (error) {
          console.error('Error registering user', error);
          dispatch({ type: 'GUEST_USER_REGISTRATION_FAILED' });
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const { useConvertGuestToRegisteredUsersMutation, useRegisterGuestUserMutation } =
  guestUserApi;
export default guestUserApi;
