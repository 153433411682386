const labels = {
  All: {
    'Ref: See item': 'Xem mục',
    'Ref: Print': 'In biên lai của bạn',
    'Ref: Email': 'Nhận biên lai của bạn qua e-mail',
    'Ref: Receipt Modal message': 'Bạn muốn nhận biên lai của mình như thế nào?',
    'Ref: Loyalty Reward': 'Phần thưởng trung thành',
    'Ref: Equation for stamps per item': '1 mua = 1 tem',
    'Ref: Paid by employer': 'Số tiền mà chủ nhân của bạn trả',
    'Ref: Paid by user': 'Số tiền mà bạn đã trả',
  },
  ProductsList: {
    'Ref: Page title': 'Danh sách sản phẩm',
    'Ref: Facility details': 'Chi tiết cơ sở',
    'Ref: Search products list': 'Tìm kiếm các mục',
    'Ref: filter label: menus': 'Thực đơn',
    'Ref: filter label: moment': 'Khoảng khăc',
    'Ref: filter label: category': 'Danh mục',
    'Ref: all': 'tất cả',
    'Ref: today': 'Hôm nay',
    'Ref: tomorrow': 'Ngày mai',
    'Ref: see more': 'Xem thêm',
    'Ref: see less': 'Thấy ít',
    'Ref: reset': 'Cài lại',
    'Ref: View in cart': 'Xem trong giỏ hàng',
    'Ref: You have changed location': 'Bạn đã thay đổi vị trí',
    'Ref: You are now at': 'Bây giờ bạn đang ở',
    'Ref: Menu card': 'Thẻ menu',
    'Ref: Table': 'Số bảng',
    'Service unavailable': 'Dịch vụ Không sẵn có',
    'Ref: Ordering unavailable':
      'Cẩn thận! Đặt hàng tại trang web này là không có sẵn tại thời điểm này, vui lòng thử sau.',
    'Ref: Filters': 'Bộ lọc (chất gây dị ứng và nhiều hơn nữa)',
  },
  Order: { 'Ref: Page title': 'Gọi món' },
  MenuSelector: {
    'Ref: Menu Page title': 'Thực đơn ',
    'Ref: today': 'Hôm nay',
    'Ref: tomorrow': 'Ngày mai',
    'Ref: Search products list': 'Tìm kiếm các mục',
    'Ref: You have changed location': 'Bạn đã thay đổi vị trí',
    'Ref: You are now at': 'Bây giờ bạn đang ở',
  },
  ProductDetails: {
    'Ref: Page title': 'Thông tin chi tiết sản phẩm',
    'Ref: Serving Size': 'Phục vụ kích thước.',
    'Ref: Nutrition': 'Thông tin dinh dưỡng',
    'Ref: Nutrition adults': 'Người lớn cần khoảng 2000 kcal mỗi ngày',
    'Ref: Nutrition Calories': 'Calo',
    'Ref: Nutrition Calcium': 'Canxi.',
    'Ref: Nutrition Fat': 'chất béo',
    'Ref: Nutrition Saturated Fat': 'Chất béo bão hòa',
    'Ref: Nutrition Carbohydrates': 'Carbohydrates',
    'Ref: Nutrition Sugar': 'Đường',
    'Ref: Nutrition Protein': 'Chất đạm',
    'Ref: Nutrition Sodium': 'Natri',
    'Ref: Nutrition cholesterol': 'Cholesterol.',
    'Ref: Nutrition dietaryFiber': 'Chất xơ',
    'Ref: Nutrition iron': 'Sắt',
    'Ref: Nutrition potassium': 'Kali.',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamin C',
    'Ref: Additives': 'chất phụ gia',
    'Ref: Emission': 'Lượng khí thải CO2',
    'Ref: This product generates': 'Sản phẩm này tạo ra.',
    'Ref: of CO2': 'của CO2',
    'Ref: emissions, which equals a': 'khí thải, bằng một',
    'Ref: car trip': 'Chuyến xe',
    'Ref: Ingredients': 'Thành phần công thức',
    'Ref: Allergens': 'Dị ứng ',
    'Ref: Always there': 'Luôn luôn ở đó',
    'Ref: Allergens selected': 'Chất gây dị ứng được chọn trên bộ lọc:',
    'Ref: minimum required': 'đòi hỏi tối thiểu',
    'Ref: you have to add at least': 'Bạn phải thêm ít nhất',
    'Ref: ingredients': 'Thành phần',
    'Ref: Ingredient': 'nguyên liệu',
    'Ref: Free': 'Miễn phí',
    'Ref: Based on customization': 'Dựa trên tùy chỉnh của bạn',
    'Ref: Additives eggs': 'trứng',
    'Ref: Additives fish': 'cá',
    'Ref: Additives milk': 'Sữa',
    'Ref: Additives nuts': 'quả hạch',
    'Ref: Additives wheat': 'lúa mì',
    'Ref: Additives peanuts': 'đậu phộng',
    'Ref: Additives soy beans': 'Đậu đậu nành',
    'Ref: Additives sesame seeds': 'Hạt mè',
    'Ref: Review': 'Xét lại',
    'Ref: Per portion': 'Mỗi phần',
    'Ref: Per 100g': 'Mỗi 100g',
    'Ref: Allergen selected': 'Dị ứng được chọn trên bộ lọc tìm kiếm',
    'Ref: Fitbit Log': 'Đăng nhập vào Fitbit',
    'Ref: age verification required on delivery': 'Cần xác minh tuổi khi giao hàng',
    'Ref: alcohol content notification':
      'Bằng cách đặt và đặt hàng cho một trong những mặt hàng này, bạn tuyên bố rằng bạn từ 18 tuổi trở lên. Lạm dụng rượu là nguy hiểm cho sức khỏe của bạn.',
    'Ref: default': 'Mặc định',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} kiếm được nếu bạn đặt hàng sản phẩm này',
    'Ref: Loyalty Card Description':
      '{stamp_form} sẽ được thêm vào lòng trung thành sau {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Chương trình Tem Loyalty cho phép bạn thu thập tem cho phần thưởng.',
    'Ref: Loyalty Modal Description':
      'Khi bạn mua <b> {product_name}, 1 tem </b> sẽ được thêm vào <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'Và',
    'Ref: Stamp': 'Con tem',
    'Ref: Stamps': 'Tem',
    'Ref: Program': 'Chương trình',
    'Ref: Programs': 'Chương trình',
    'Ref: Loyalty Stamps': 'Tem trung thành',
    'Ref: See Loyalty Stamps': 'Xem tem trung thành',
    Favorite: 'Yêu thích',
    'Ref: In favorites': 'Sản phẩm đã có trong danh sách yêu thích. Bấm để loại bỏ nó.',
    'Ref: Not in favorites': 'Sản phẩm chưa có trong danh sách yêu thích. Bấm để thêm nó.',
    'Ref: Added to favorites': 'Sản phẩm đã được thêm vào danh sách yêu thích.',
    'Ref: Removed from favorites': 'Sản phẩm đã được xóa khỏi danh sách yêu thích.',
    'Ref: From': 'Từ',
  },
  OrderHistory: { 'Ref: Orders': 'Đơn đặt hàng', 'Ref: Page Title': 'Đơn đặt hàng của tôi' },
  MenuTile: { 'Ref: Scan&Go': 'SCAN & GO', 'Ref: View only': 'Chỉ xem' },
  ProductTile: {
    'Ref: Allergens': 'Chất gây dị ứng',
    'Ref: Presence of allergen': 'Sự hiện diện của chất gây dị ứng',
    'Ref: Scan': 'Quét',
    'Ref: From': 'Từ',
    'Ref: Already in product favorite list. Click to remove it.':
      'Đã có trong danh sách yêu thích của sản phẩm. Bấm để loại bỏ nó.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Hiện không được thêm vào danh sách sản phẩm yêu thích. Bấm để thêm nó.',
    'Ref: Free': 'Miễn phí',
  },
  MenusWidget: {
    'Ref: no menu': 'Không có menu nào cho thời điểm này',
    'Ref: Modal title': 'Thực đơn cho ngày',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Đón',
    'Ref: Eat-in': 'Ăn vào',
    'Ref: Scan&Go': 'SCAN & GO',
    'Ref: Delivery': 'Vận chuyển',
    'Ref: Total': 'Tổng cộng',
    'Ref: How was facility name service': '{facility_name} dịch vụ như thế nào?',
    'Ref: How do you rate the service': 'Làm thế nào để bạn đánh giá dịch vụ?',
  },
};
export default labels;
