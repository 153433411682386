const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Nachfrage nach Unterkunft',
    'Ref: Describe your request': 'Beschreiben Sie Ihre Anfrage',
    'Ref: Confirm your request': 'Bestätigen Sie Ihre Anfrage',
    'Ref: Your request': 'Ihre Anfrage',
    'Ref: Summary': 'Zusammenfassung',
    'Ref: Next': 'Nächste',
    'Ref: Submit': 'Einreichen',
    'Ref: Me': 'Mich',
    'Ref: Arrival date': 'Ankunftsdatum',
    'Ref: Arrival time': 'Ankunftszeit',
    'Ref: Departure date': 'Abfahrtsdatum',
    'Ref: Departure time': 'Abfahrtszeit',
    'Ref: request raised for': 'Die von dieser Anfrage betroffene Person',
    'Ref: phoneNumber': 'Telefonnummer',
    'Ref: arrivalDate': 'Ankunft',
    'Ref: departureDate': 'Abfahrt',
    'Ref: description': 'Kommentar',
    'Ref: open': 'Offen',
    'Ref: pending': 'Ausstehend',
    'Ref: refused': 'Abgelehnt',
    'Ref: closed': 'Geschlossen',
    'Ref: all': 'Alle',
    'Ref: Arrival': 'Ankunft',
    'Ref: Validate': 'Bestätigen',
    'Ref: site': 'Standort Standort',
    'Ref: Update us': 'Aktualisieren Sie uns',
    'Ref: email of guest': 'E-Mail des Gastes',
    'Ref: GuestAccommodationRequestTitle': 'Gastanfrage',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Neue Anfrage',
    'Ref: Status': 'Status',
    'Ref: Date': 'Datum',
    'Ref: Coming arrivals': 'Kommende Ankünfte',
    'Ref: Past arrivals': 'Frühere Ankünfte',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Telefonnummer des Gastes',
    'Ref: Your phone number': 'Deine Telefonnummer',
    'Ref: The phone number': 'Die Telefonnummer',
    'Ref: PhoneNumber error msg': 'Die Telefonnummer ist nicht gültig',
    'Ref: Comment error msg': 'Die maximale Anzahl von Zeichen beträgt 250',
    'Ref: Guest first name': 'Gäste Vorname',
    'Ref: Guest last name': 'Gäste Nachname',
    'Ref: request for': 'Für wen ist diese Anfrage?',
    'Ref: request for me': 'Mich',
    'Ref: request for guest': 'Jemand anderes',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Details zur Unterkunftsanfrage',
    'Ref: Timeline': 'Zeitleiste',
    'Ref: No updates placeholder': 'Es gibt keine Updates für diese Anfrage',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Danke',
    'Ref: Success text':
      'Wir haben Ihre Anfrage erhalten und befassen uns jetzt. Wir werden uns bald bei Ihnen melden.',
    'Ref: Go to home': 'Zur Startseite',
    'Ref: Create another request': 'Erstellen Sie eine andere Anfrage',
  },
  CreateError: {
    'Ref: Create error title': 'Hoppla!',
    'Ref: Create error text': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    'Ref: Retry': 'Versuchen Sie es erneut',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Bitte aktualisieren Sie uns mit jedem neuen Element über Ihre Anfrage, damit wir Sie am besten unterstützen können.',
  },
};
export default labels;
