import { HookInput as Input } from '../../../../../components/atoms/Input';

import { SingleLineTextProps } from './SingleLineText.types';

const SingleLineText = ({ id, value, ...props }: SingleLineTextProps) => {
  return (
    <Input
      {...props}
      name={id}
      defaultValue={value}
      data-testid={'audit-question-component-single-line-text'}
    />
  );
};

export default SingleLineText;
