import { useLocation } from 'react-router-dom';

import FeedbackFormPageComponent from '../../../Feedback/components/Form';
import { pagePaths } from '../../config';
import useContactsTranslation from '../../hooks/useContactsTranslation';

const ContactForm = () => {
  const { label } = useContactsTranslation(__filename);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subject = queryParams.get('subject') ?? undefined;

  return (
    <FeedbackFormPageComponent
      pageTitle={label('Ref: Page title')}
      category={label('Ref: Category field value')}
      subject={subject}
      successPath={pagePaths['FormSubmissionSuccess']}
      failurePath={pagePaths['FormSubmissionFailure']}
    />
  );
};

export default ContactForm;
