const labels = {
  ContactForm: {
    'Ref: Page title': 'Ota meihin yhteyttä',
    'Ref: Category field value': 'Ota meihin yhteyttä',
  },
  ContactList: {
    'Ref: Page title': 'Yhteystiedot',
    'call (action to place a call)': 'soittaa puhelimella',
    'write (action to write a message)': 'kirjoittaa',
    'write (action to write an e-mail)': 'sähköposti',
    'Ref: Hint message':
      'Huomaa, että joillakin kohteilla ei ehkä ole vastaanottoa. Jos näin on, etsi jokin kohteessa olevista lankapuhelimista.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Tapahtui virhe ja viestisi ei lähetetty. Jos tämä virhe toistuu, ota yhteyttä tukipalvelu.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Viestisi lähetettiin. Kiitos, että otit meihin yhteyttä.',
  },
};
export default labels;
