const labels = {
  All: {
    Region: 'Regionas ar šalis',
    'Ref: Geography': 'Pasirinkite savo šalį ar regioną',
    'Ref: Country': 'Šalis',
    'Ref: Country tooltip': 'Vieta atitinka svetainės šalį.',
    'Ref: Please select your country': 'Prašome pasirinkti savo šalįl',
    'First name': 'Pirmas vardas',
    'Last name': 'Pavardė',
    'Ref: Full name': 'Pilnas vardas',
    'Mobile number': 'Mobilaus telefono numeris',
    'Mobile phone': 'Mobilusis telefonas',
    Email: 'Elektroninio pašto adresas',
    Password: 'Slaptažodis',
    logout: 'Atsijungti',
    'Not a new user? Log in': 'Ne naujas vartotojas? Prisijungti',
    'do not register, login': 'Ne naujas vartotojas? Prisijungti',
    'Ref: I agree': 'aš sutinku',
    Register: 'Registruotis',
    'Ref: My language': 'Mano kalba',
    'Ref: Kiosk Fail Title': 'Oi! Kažkas nutiko ne taip!',
    'Ref: Kiosk Fail Description':
      'Deja, jūsų užsakymas nebuvo sėkmingas. Pabandykite dar kartą arba paprašykite personalo nario jums padėti.',
    'Ref: Kiosk Fail Back': 'Atgal į ankstesnį puslapį',
    'Ref: Kiosk Fail Action': 'Išvalykite sesiją ir vėl užsisakykite',
    Validate: 'Patvirtinti',
    'Ref: error, incorrect credentials': 'Šie įgaliojimai neatitinka pasirinktos geografijos.',
    'Ref: Forgot password?': 'Pamiršote slaptažodį?',
    'Ref: Hello': 'Sveiki {wave_emoji}, prašome prisijungti, kad galėtumėte tęsti',
    'Ref: Form title': 'Prašau užsiregistruoti tęsti',
    'Already have an account? Login': 'Jau turite paskyrą? Prisijungti',
  },
  AccountPage: {
    'Ref: My Information': 'Mano informacija',
    'Ref: Settings': 'Nustatymai',
    'Ref: Email': 'El. Paštas',
    'Ref: Current site': 'Dabartinė svetainė',
    'Ref: Choose site': 'Pasirinkite svetainę',
    'Ref: Personal interests': 'Asmeniniai interesai',
    'Ref: Food preferences': 'Maisto nuostatos',
    'Ref: Password': 'Slaptažodis',
    'Ref: Page title': 'Sąskaita',
    'Ref: Language': 'Kalba',
    'Ref: Communication preferences': 'Bendravimo nuostatos',
    'Ref: Terms & conditions': 'Sąlygos ir nuostatos',
    'Ref: Privacy policy': 'Privatumo politika',
    'Ref: Delete my account': 'Ištrinkite mano paskyrą',
    'Ref: Confirm deletion': 'Ar tikrai norite ištrinti savo paskyrą?',
    'Ref: Request deletion': 'Ar tikrai norite paprašyti ištrynimo iš savo paskyros?',
    'Ref: Deletion description': 'Jūsų užklausa bus tvarkoma pagal jūsų vietinį reglamentą.',
    Placeholder: 'Parašykite „Ištrinti“',
    'Ref: Delete description': 'Jūsų duomenys bus ištrinti. Šis veiksmas yra negrįžtamas.',
    delete: 'Ištrinti',
    'Ref: Terms of sale': 'Pardavimo sąlygos',
    'Ref: Imprint': 'Įspaudas',
    'Ref: Cookie Settings': 'Slapukų nustatymai',
    'Ref: Support': 'Palaikymas',
    'Ref: Register or login': 'Užsiregistruokite arba prisijunkite',
    'Ref: Filtering Preferences': 'Filtravimo nuostatos',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Ar tikrai ištrinate savo paskyrą?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Parašykite „{word}“ įvesties lauke ir patvirtinkite apačioje žemiau. Šis veiksmas yra negrįžtamas.',
    'Ref: Write "{word}"': 'Parašykite „{word}“',
    'Ref: Delete my account': 'Ištrinkite mano paskyrą',
    'Ref: Words are not matching': 'Žodžiai neatitinka',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Pasirinkite savo vietą pradžiai',
    'Ref: Choose location':
      'Greitai raskite vietą, suaktyvindami lokalizaciją, nuskaitydami QR kodą arba ieškodami pavadinimo',
    'Ref: next step': 'Eime',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Registruotis',
    'Ref: identify your location': 'Nurodykite savo vietą.',
    'Ref: option 1 - scan QR': '1 variantas: nuskaitykite pateiktą registracijos QR kodą',
    'Ref: option 2 - enter code': '2 variantas: rankiniu būdu įveskite savo vietos kodą',
    'Ref: button to open qr code scanner modal': 'Turiu QR: nuskaitysime',
    'Ref: button to open site code input modal': 'Pasirinkite svetainę su kodu',
    'Ref: getting help with site identifier - support desk':
      'Jei nesate aišku, kaip tęsti, kreipkitės į palaikymo stalą.',
    'Ref: getting help with site identifier - support form':
      'Taip pat galite atsiųsti mums pastabą.',
    'Location code': 'Vietos kodas',
    'Ref: location code field placeholder': 'Jūsų vietos kodas čia',
    'Ref: Scanner header': 'Skenuojant QR kodą',
    'Ref: site code input header': 'Pridėti svetainę su kodu',
    'Ref: not valid code': 'Šis kodas negalioja pasirinktoje geografijoje',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Registracija',
    'Ref: collected info': 'santrauka',
    'Ref: additional info': 'Tavo Informacija',
    'Confirm password': '*Patvirtinti slaptažodį',
    company: 'Kas yra jūsų darbdavys?',
    location: 'vieta',
    username: 'Vartotojo vardas',
    register: 'Registruotis',
    'Ref: password strength':
      'Jūsų slaptažodis turi būti mažiausiai 8 simbolių ilgio, turėti bent vieną numerį, vieną didžiųjų, vieną mažosiomis raidėmis ir vieną ypatingą charakterį.',
    'passwords do not match': 'Slaptažodžiai nesutampa.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Bendravimo nuostatos',
    'Ref: Things happen while you are away': 'Viskas nutinka, kol esate išvykęs',
    'Ref: Would you like to receive update about offers and promotions?':
      'Ar norėtumėte gauti atnaujinimus apie pasiūlymus ir reklamas?',
    'Ref: Would you like to hear about content we think you may like?':
      'Ar norėtumėte sužinoti apie turinį, kuris, mūsų manymu, jums gali patikti?',
    Yes: 'Taip',
    No: 'Ne',
    apply: 'kreiptis',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Išsami sąskaitos informacija' },
  DeletionFailure: {
    'Ref: Body':
      'Įvyko klaida ir jūsų prašymas ištremti sąskaitą nebuvo pateikta. Jei ši klaida išlieka, susisiekite su savo pagalbos tarnyba.',
    'Go to account': 'Eik į sąskaitą',
  },
  GenericFailurePage: {
    'Ref: Title': 'Kažkas negerai',
    'Ref: Body':
      'Atsiprašau, kažkas negerai. Jei šis klausimas išlieka, kreipkitės į savo svetainės palaikymą.',
  },
  HomePage: {
    notifications: 'Pranešimai',
    basket: 'krepšys',
    'Ref: Tabtitle': 'Namai',
    'contact us': 'Susisiekite su mumis',
    'Ref: You are logged in as a guest user': 'Jūs esate prisijungęs kaip kviestinis vartotojas',
    'Ref: Good morning': 'Labas rytas',
    'Ref: Good afternoon': 'Laba diena',
    'Ref: Good evening before midnight': 'Labas vakaras',
    'Ref: Good evening after midnight': 'Labas vakaras',
    'Ref: Search': 'Paieška',
    'Ref: products button aria': 'Rasta {results_form} produktuose',
    'Ref: menus button aria': 'Meniu rasta {results_form}',
    'Ref: facilities button aria': 'Rasta {results_form} patalpose',
    'Ref: result': 'rezultatas',
    'Ref: results': 'rezultatai',
    'Ref: Menus': 'Meniu',
    'Ref: No results title': 'Oi!',
    'Ref: No results body': 'Jokių rezultatų, atitinkančių jūsų paiešką',
    'Ref: Products': 'Produktai',
    'Ref: Facilities': 'Priemonės',
    'Ref: See more results': 'Žr. Daugiau paieškos rezultatų',
    'Ref: Accessibility instructions':
      'Norėdami pradėti naudoti paiešką, turite spustelėti ją arba paspauskite ENTER, kol ji buvo nukreipta.',
    'Ref: See more': 'Žiūrėti daugiau',
    'Ref: singular form of products': 'produktas',
    'Ref: singular form of menus': 'Meniu',
    'Ref: singular form of facilities': 'įrenginys',
    'Ref: Search results for': 'Pieškos rezultatai',
  },
  LanguagePage: {
    apply: 'kreiptis',
    'My language': 'Mano kalba',
    'Select in which language you would like to read our app':
      'Pasirinkite, kuria kalba norėtumėte perskaityti mūsų programą',
    'Ref: Page title': 'kalba',
  },
  LegalDocPage: {
    Acknowledge: 'Pripažinti',
    'Ref: Legal changed notification title': '„{legal_doc_type}“ buvo modifikuoti',
    'Ref: Legal changed notification content': 'Peržiūrėkite juos prieš tęsdami programą.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Sąlygos ir nuostatos',
    'Read privacy policy': 'Perskaitykite privatumo politiką',
    'Ref: Acknowledge legal docs': 'Aš sutinku su šiomis sąlygomis.',
    'Ref: Read and understood': 'Aš perskaičiau ir supratau ',
    'Ref: No legal docs available - notice title': 'Netrukus',
    'Ref: No legal docs available - notice body':
      'Atsiprašome, atrodo, kad ši programa šiuo metu nėra prieinama jūsų geografijoje.',
    'Ref: The privacy Policy': 'Privatumo politika',
    'Ref: Legal changed notification title': '„{legal_doc_type}“ buvo modifikuoti',
    'Ref: Legal changed notification content': 'Peržiūrėkite juos prieš tęsdami programą.',
  },
  LoginPage: {
    'Ref: Page title': 'Atvykę į {app name} ',
    'Ref: Page subtitle': 'Prisijunkite, kad tęstumėte',
    'Ref: Email field placeholder': 'Tavo elektroninis paštas',
    'Ref: Email field note': 'Mes niekada nesidalinsime jūsų el. Laišku su kuo nors.',
    'Ref: Password field title': 'Jūsų slaptažodis',
    register: 'Registruotis',
    'I forgot my password.': 'Pamiršote slaptažodį?',
    'I have trouble logging in': 'Patiria sunkumų prisijungiant?',
    'start registration': 'Pradėkite registraciją',
    'Ref: Register': 'Registruotis',
    'Ref: Login': 'Prisijungti',
    'Ref: Connection issue?': 'Ryšio problema?',
    'Ref: Connection issue message':
      'Jei turite problemų registruotis ar prisijungti, susisiekite su įprastais darbuotojais vietoje arba pagalbos tarnyba.',
  },
  WelcomePage: {
    'Ref: Welcome to': 'Sveiki ir sveiki atvykę į {site_name}',
    'Ref: Begin': 'Pradėti',
  },
  NotificationsList: {
    'Ref: No notifications': 'Šiuo metu neturite pranešimų',
    'Ref: This week': 'Šią savaitę',
    'Ref: Earlier': 'Anksčiau',
    'Ref: Page title': 'Pranešimai',
    'Ref: See more': 'Žiūrėti daugiau',
    'Ref: Mark all read': 'Pažymėkite visus skaitykite',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Žr. Visus pranešimus' },
  ServicesMenuPage: { 'Ref: Page title': 'Paslaugos', navigate: 'Rodyti kelią' },
  DeletionSuccess: {
    'Ref: Title': 'Buvo paprašyta ištrynti jūsų sąskaitą',
    'Ref: Content': 'Netrukus susitvarkysime. Galite bet kada prarasti prieigą.',
    'Log out': 'Atsijungti',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Mes susisieksime su Jumis',
    'Ref: Body':
      'Apgailestaujame, kad susiduriate su problemomis. Mes greitai susisieksime su jumis.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Patiria sunkumų prisijungiant?',
    'Ref: Email field placeholder': 'Jūsų elektroninio pašto adresas',
    'Ref: Mobile phone field placeholder': 'Mobilaus telefono numeris',
    Company: 'Įmonė',
    'Ref: Company field placeholder': 'Įmonės pavadinimas',
    'Ref: Company field note':
      'Jei esate konsultantas, tai yra įmonė, už kurią šiuo metu dirbate, o ne jūsų darbdavys.',
    Location: 'Miestas ar vieta',
    'Ref: Location field placeholder': 'Jūsų darbo vietos vieta',
    'Ref: Location field note':
      'Jei už apgyvendintos teritorijos ribų, nedvejodami naudojate pavadinimą, kuriuo plotas yra paprastai vadinamas.',
    Comment: 'komentaras',
    'Ref: Comment field placeholder': 'Kas nutiko?',
    'Ref: Who is your employer?': 'Kas yra jūsų darbdavys?',
    'Ref: What village are you staying at?': 'Kokiame kaime jūs apsistojate?',
    'Ref: Comment field note': 'Bet kokia informacija, kuri gali padėti mums padėti!',
    'Ref: Feedback Content - geography': 'Geografija',
    'Ref: Feedback Content - fullName': 'vardas',
    'Ref: Feedback Content - email': 'El. Paštas',
    'Ref: Feedback Content - phone': 'Telefonas',
    'Ref: Feedback Content - company': 'Įmonė',
    'Ref: Feedback Content - city': 'Miesto / Vietos pavadinimas',
    'Ref: Feedback Content - whatVillage': 'Kaimas',
    'Ref: Feedback Content - employer': 'Darbdavys',
    'Ref: Feedback Content - comment': 'Kas nutiko',
  },
  AccountStartPage: {
    'Ref: Header': 'Pasirinkite savo vietą pradžiai',
    'Ref: Body':
      'Greitai raskite vietą, suaktyvindami lokalizaciją, nuskaitydami QR kodą arba ieškodami pavadinimo',
    'Ref: LetsGo button': 'Eime',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Registracija' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Pasirinkite svetainę su QR skaitytuvu',
    'Ref: Page title': 'Pasirinkite savo vietą',
    'Ref: Select site with code': 'Pasirinkite svetainę su kodu',
    'Ref: Enter site code': 'Įveskite svetainės kodą',
    'Ref: Scanner error header': 'Svetainė nerasta',
    'Ref: Not found': 'Šis kodas negalioja pasirinktoje geografijoje',
    'Ref: Generic error': 'Kažkas nutiko ne taip',
    'All other sites': 'Visos kitos svetainės',
    'Sites in your country': 'Svetainės jūsų šalyje',
    'Ref: Unauthorized error': 'Neteisėta užklausa dėl svetainės apribojimo lygio',
    'Ref: Forbidden':
      'Atsiprašome, jūs neturite tinkamų leidimų, kad galėtumėte pasiekti šią svetainę. Norėdami susitarti dėl prieigos, susisiekite su „Pilbara“ paslaugų centru 1800 992 777',
    "Ref: We didn't find the site.": 'Neradome svetainės.',
    'Ref: We recommend some solutions:': 'Mes rekomenduojame keletą sprendimų:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Pabandykite pridėti savo svetainę naudodami QR skaitytuvą arba naudodamiesi žemiau pateiktais mygtukais',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Atsijungti ir atsijunkite atgal pasirinkdami teisingą šalį',
    'Ref: The currently selected country is:': 'Šiuo metu pasirinkta šalis yra:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Registracija',
    'Confirm password': 'Patvirtinti slaptažodį',
    'Email is not valid': 'El. Paštas negalioja',
    'passwords do not match': 'Slaptažodžiai nesutampa.',
    'Ref: Password strength':
      'Jūsų slaptažodis turi būti bent 8 simbolių ilgio, jame yra bent vienas skaičius, viena didžioji raidė, viena maža ir viena speciali simboliu.',
    'Sign up': 'Registruotis',
    'Continue as guest': 'Tęskite svečią',
    'Already have an account? Login': 'Jau turite paskyrą? Prisijungti',
    'Ref: Registration success title': 'Ačiū, kad užsiregistravote',
    'Ref: Registration success content':
      'Jūsų paskyra netrukus bus sukurta. Gausite el. Laišką, patvirtinantį kūrybą.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Prašome prisijungti/prisiregistruoti',
    'Ref: Password strength':
      'Jūsų slaptažodis turi būti mažiausiai 8 simbolių ilgio, turėti bent vieną numerį, vieną didžiųjų, vieną mažosiomis raidėmis ir vieną ypatingą charakterį.',
    Stamps: 'Antspaudai',
    'Order history': 'Užsakymų Istorija',
    'to earn points': 'uždirbti taškų',
    'to see order history': 'Norėdami pamatyti užsakymo istoriją',
    'to place an order': 'Užsakyti',
    'Sign up': 'Registruotis',
    Login: 'Prisijungti',
    'Email is not valid': 'El. Paštas negalioja',
    'passwords do not match': 'Slaptažodžiai nesutampa.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Ačiū',
    'Ref: Body':
      'Tu beveik baigei! Netrukus gausite patvirtinimo el. Laišką nurodytu adresu. Atidarykite šį el. Laišką ir spustelėkite nuorodą, kad suaktyvintumėte savo paskyrą.',
    'Ref: Enter': 'Tęsti',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Įvyko klaida',
    'Ref: Generic Error Body':
      'Bandykite dar kartą vėliau arba susisiekite su savo pagalbos tarnyba.',
    'Ref: Missing IndexDb support':
      'Atsiprašome, jūs negalite pasiekti programos inkognito režimu arba be „IndexDB“ palaikymo. Prašau dar kartą pakartoti su kita naršykle.',
    'Ref: Resource not found': 'Turinio, kurį bandote pasiekti, neegzistuoja.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Turinys, kurį bandote pasiekti, priklauso privačiai svetainei.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Turinys, kurį bandote pasiekti, nėra įgaliotas jūsų paskyroje.',
    'Ref: TabTitle': 'Klaida',
    'Ref: Site link invalid':
      'Ši nuoroda negalioja, pasirinkite kitą svetainę arba susisiekite su pagalbos tarnyba, jei problema išlieka.',
    'Ref: Access Denied': 'Prieiga uždrausta. Nepakankami vartotojo leidimai.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Oi! {robot_emoji}',
    'Ref: Error title': 'Kažkas nutiko ne taip!',
    'Ref: Error body':
      'Pabandykite atnaujinti šį puslapį arba susisiekite su pagalbos tarnyba, jei problema išlieka.',
    'Ref: Error ID:': 'Klaidos ID: {errorId}',
    'Ref: Back to home': 'Grįžti namo',
  },
  Child: {
    'Ref: Route Not Found':
      'Jūsų ieškoma paslauga šioje svetainėje nėra. Bandykite dar kartą vėliau arba susisiekite su savo pagalbos tarnyba.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Sveikiname, jūsų naujas slaptažodis buvo sėkmingai išsaugotas, dabar galite pasiekti visas mūsų paslaugas iš savo paskyros.',
    Welcome: 'Sveiki',
    'Ref: Welcome message':
      'Dabar jūs sėkmingai užsiregistravote naujoje „Bite“ programoje ir galite toliau atrasti ir mėgautis visomis mūsų paslaugomis.',
  },
  PasswordChange: {
    'Change Password': 'Pakeisti slaptažodį',
    Welcome: 'Sveiki',
    'Ref: Password message': 'Jūsų slaptažodį reikia pakeisti, nes jis nėra pakankamai saugus',
  },
  AccountPasswordChange: {
    'Change your password here': 'Pakeiskite slaptažodį čia',
    'Your old password': 'Jūsų senas slaptažodis',
    'Type in your new password': 'Įveskite savo naują slaptažodį',
    'Re-type in your new password': 'Iš naujo įveskite savo naują slaptažodį',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'tavo slaptazodis buvo pakeistas',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Įvyko klaida, todėl jūsų pakeitimas nebuvo išsaugotas. Prašau, pabandykite dar kartą.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Atstatyti slaptažodį',
    'Ref: Provide email':
      'Pateikite el. Pašto adresą, naudojamą registruojant {app_name}. El. Laiškas bus išsiųstas iš naujo nustatyti slaptažodį.',
    'Ref: Something happened': 'Kažkas nutiko. Pabandykite dar kartą vėliau.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Mes atsiuntėme naują el. Laišką. Palaukite 30 sekundžių, kad dar kartą pakeis.',
    'Go to login page': 'Eikite į prisijungimo puslapį',
    'Resend email': 'Atsakyti el. Laišką',
    'Ref: Email sent title': 'Jums išsiųstas slaptažodžio nustatymo el. Laiškas',
    'Ref: TabTitle': 'Atstatyti slaptažodį',
    'Ref: Email sent msg':
      'Iš naujo nustatymo nuoroda buvo išsiųsta į jūsų adresą {email_address}. Prieš bandydami iš naujo palaukite 30 sekundžių.',
  },
  ResetPassword: { 'Enter your new password': 'Įveskite savo naują slaptažodį' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Jūsų slaptažodis buvo sėkmingai pakeistas',
    'Go to login page': 'Eikite į prisijungimo puslapį',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Slaptažodžio atstatymas nepavyksta',
    'Ref: Error': 'Įvyko klaida, bandykite dar kartą',
    'Reset your password': 'Atstatyti slaptažodį',
    'Go to login page': 'Eikite į prisijungimo puslapį',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Privatumo politika',
    'Ref: Privacy Policy Body':
      'Prieinant prie šios platformos, „Sodexo SA“ ir vietinis „Sodexo“ subjektas apdoros jūsų asmeninius duomenis, kad bent jau valdys jūsų sąskaitą ir teiks paslaugas bei pasiūlymus, kuriuos galima rasti per platformą. Jei norite gauti daugiau informacijos apie jūsų asmens duomenų tvarkymą, kas prie jų prieigos ir kaip naudotis jūsų asmens duomenų teisėmis, kreipkitės į žemiau pateiktą privatumo politiką ir bet kuriuo metu galite rasti savo sąskaitoje.',
    'Ref: Continue': 'Tęsti',
    'Ref: Read Privacy Policy': 'Perskaitykite privatumo politiką',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Nueik į parduotuvę' },
  SharePage: { 'Ref: Select a site': 'Pasirinkite svetainę' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Norėdami peržiūrėti privatumo politiką, pasirinkite savo šalį.',
  },
};
export default labels;
