import { ionFireEvent as fireEvent } from '@ionic/react-test-utils';
import { screen, cleanup } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { act } from 'react';

import { APP_NAME } from '../../../../constants';
import expectInputError from '../../../../helpers/tests/expectInputError';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultState as mockCoreDefaultState } from '../../../../modules/Core/reducers/coreReducer';
import { defaultState as mockSharedDefaultState } from '../../../../reducers/sharedReducer';

import TroubleLoggingInPage from './TroubleLoggingInPage';

import { feedbackTypes } from '@/modules/Feedback/config';

const mockDispatch = jest.fn((action) => action());
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) =>
    callback({
      Shared: {
        ...mockSharedDefaultState,
        geographies: {
          ...mockSharedDefaultState.geographies,
          list: [{ name: 'Europe', code: 'EU' }],
        },
      },
      Core: mockCoreDefaultState,
    }),
  useDispatch: () => mockDispatch,
}));

jest.mock('react-select', () => ({ 'data-testid': dataTestId, options, value, onChange }: any) => {
  function handleChange(event: any) {
    const option = options.find((option: any) => option.value === event.currentTarget.value);
    onChange(option);
  }
  return (
    <select
      id={dataTestId}
      name={dataTestId}
      data-testid={dataTestId}
      value={value || ''}
      onChange={handleChange}
    >
      {options.map(({ label, value }: any) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
});

jest.mock('../../actions', () => ({
  ...jest.requireActual('../../actions'),
  sendSupportEmail: jest.fn(),
  setGeography: jest.fn(),
}));

const mockSendSupportEmail = jest.fn();

jest.mock('../../api/account/accountApi', () => ({
  useSendSupportEmailMutation: () => [mockSendSupportEmail, { isLoading: false }],
}));

const mockCreateFeedback = jest.fn();

jest.mock('../../../Feedback/api/api', () => ({
  useCreateFeedbackMutation: () => [mockCreateFeedback, { isLoading: false }],
}));

describe('TroubleLoggingInPage', () => {
  const env = global.process.env;

  describe('when is MyWay', () => {
    beforeAll(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYWAY };
    });

    afterAll(() => cleanup());

    describe('on initial render', () => {
      let geography: any, email: any, phone: any, city: any, comment: any, company: any;

      renderComponent(TroubleLoggingInPage);

      beforeEach(() => {
        geography = screen.getByTestId('region-select');
        email = screen.getByTestId('trouble-logging-email-hook-input-field');
        phone = screen.getByTestId('trouble-logging-phone-hook-input-field');
        city = screen.getByTestId('trouble-logging-city-hook-input-field');
        comment = screen.getByTestId('trouble-logging-comment-hook-input-field');
        company = screen.getByTestId('trouble-logging-company-hook-input-field');
      });

      it('should have correct input fields', () => {
        expect(geography).toBeTruthy();
        expect(email).toBeTruthy();
        expect(phone).toBeTruthy();
        expect(city).toBeTruthy();
        expect(comment).toBeTruthy();
        expect(company).toBeTruthy();
      });
    });

    describe('on submit form without required fields', () => {
      let button: any;

      renderComponent(TroubleLoggingInPage);

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');
      });

      it('should require fields', async () => {
        await act(async () => {
          fireEvent.click(button);
        });

        expectInputError('trouble-logging-email-hook-input-status-message');
        expectInputError('trouble-logging-phone-hook-input-status-message');
        expectInputError('trouble-logging-company-hook-input-status-message');
        expectInputError('trouble-logging-comment-hook-input-status-message');

        expect(mockCreateFeedback).not.toHaveBeenCalled();
      });
    });

    describe('on submit form with all fields', () => {
      let geography: any,
        email: any,
        phone: any,
        company: any,
        city: any,
        comment: any,
        button: any;

      renderComponent(TroubleLoggingInPage);

      beforeEach(async () => {
        geography = screen.getByTestId('region-select');
        email = screen.getByTestId('trouble-logging-email-hook-input-field');
        phone = screen.getByTestId('trouble-logging-phone-hook-input-field');
        company = screen.getByTestId('trouble-logging-company-hook-input-field');
        city = screen.getByTestId('trouble-logging-city-hook-input-field');
        comment = screen.getByTestId('trouble-logging-comment-hook-input-field');

        await act(async () => {
          await userEvent.selectOptions(geography, ['EU']);
          await userEvent.type(email, 'user@email.com');
          await userEvent.type(phone, '123456');
          await userEvent.type(company, 'Big Corp Inc.');
          await userEvent.type(city, 'Gotham');
          await userEvent.type(comment, 'I cant login');
        });
      });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');

        mockCreateFeedback.mockResolvedValue({});
        await act(async () => {
          fireEvent.click(button);
        });
      });

      it('should create feedback', () => {
        const args = {
          comment:
            'Language: en-US, \nRef: Feedback Content - geography: EU, \nRef: Feedback Content - email: user@email.com, \nRef: Feedback Content - phone: 123456, \nRef: Feedback Content - company: Big Corp Inc., \nRef: Feedback Content - city: Gotham, \nRef: Feedback Content - comment: I cant login',
          title: 'I have trouble logging in',
          feedbackType: {
            id: feedbackTypes['Feedback type'],
            name: 'Feedback type',
          },
          feedbackDate: expect.any(String),
          withAccessToken: false,
        };

        expect(mockCreateFeedback).toHaveBeenCalledWith(expect.objectContaining(args));
      });
    });
  });

  describe('when is MyVillage', () => {
    beforeAll(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYVILLAGE };
    });

    afterAll(() => cleanup());

    describe('on initial render', () => {
      let fullName: any, email: any, whatVillage: any, employer: any;

      renderComponent(TroubleLoggingInPage);

      beforeEach(() => {
        fullName = screen.getByTestId('trouble-logging-full-name-hook-input-field');
        email = screen.getByTestId('trouble-logging-email-hook-input-field');
        whatVillage = screen.getByTestId('trouble-logging-village-hook-input-field');
        employer = screen.getByTestId('trouble-logging-employer-hook-input-field');
      });

      it('should have correct input fields', () => {
        expect(fullName).toBeTruthy();
        expect(email).toBeTruthy();
        expect(whatVillage).toBeTruthy();
        expect(employer).toBeTruthy();
      });
    });

    describe('on submitting form without required fields', () => {
      let button: any;

      renderComponent(TroubleLoggingInPage);

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');
      });

      it('should require fields', async () => {
        await act(async () => {
          fireEvent.click(button);
        });

        expectInputError('trouble-logging-email-hook-input-status-message');
        expectInputError('trouble-logging-full-name-hook-input-status-message');

        expect(mockSendSupportEmail).not.toHaveBeenCalledWith({
          email: 'clark.kent@superman.com',
          employer: 'Justice League',
          fullName: 'Clark Kent',
          village: 'Smallville',
        });
      });
    });

    describe('on submitting form with required fields', () => {
      let fullName: any, email: any, whatVillage: any, employer: any, button: any;

      renderComponent(TroubleLoggingInPage);

      beforeEach(async () => {
        fullName = screen.getByTestId('trouble-logging-full-name-hook-input-field');
        email = screen.getByTestId('trouble-logging-email-hook-input-field');
        whatVillage = screen.getByTestId('trouble-logging-village-hook-input-field');
        employer = screen.getByTestId('trouble-logging-employer-hook-input-field');

        await act(async () => {
          await userEvent.type(fullName, 'Clark Kent');
          await userEvent.type(email, 'clark.kent@superman.com');
          await userEvent.type(whatVillage, 'Smallville');
          await userEvent.type(employer, 'Justice League');
        });
      });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');

        mockSendSupportEmail.mockResolvedValue({});

        await act(async () => {
          fireEvent.click(button);
        });
      });

      it('should create support request', () => {
        expect(mockSendSupportEmail).toHaveBeenCalledWith({
          email: 'clark.kent@superman.com',
          employer: 'Justice League',
          fullName: 'Clark Kent',
          village: 'Smallville',
        });
      });
    });
  });
});
