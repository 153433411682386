import { Skeleton, SkeletonSquare } from '../Skeleton';

import style from './SideBarItem.module.css';

const SideBarItemSkeleton = ({ collapsed }: { collapsed?: boolean }) => (
  <div className={style.SideBarItemWrapper}>
    <div className={style.skeletonIconWrapper}>
      <div className={style.icon}>
        <SkeletonSquare />
      </div>
    </div>
    {!collapsed && (
      <div className={style.title}>
        <Skeleton width={'8.125rem'} />
      </div>
    )}
  </div>
);

export default SideBarItemSkeleton;
