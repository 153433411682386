import { FC } from 'react';

import FacilityContact from '../../../../components/ecosystems/Facility/FacilityContact/FacilityContact';
import FacilityDescription from '../../../../components/ecosystems/Facility/FacilityDescription/FacilityDescription';
import OpeningHours from '../../../../components/ecosystems/Facility/OpeningHours/OpeningHours';
import Modal from '../../../../components/organisms/Modal';
import { FacilityDetailsProps } from '../../types/facilityDetails.types';

import styles from './FacilityDetails.module.css';

const FacilityDetails: FC<FacilityDetailsProps> = ({
  label,
  isFacilityModalOpen,
  onFacilityModalDismiss,
  facility,
}) => {
  return (
    <Modal
      title={label('Ref: Facility details')}
      isOpen={isFacilityModalOpen}
      id="facility details"
      onDismiss={onFacilityModalDismiss}
      data-testid="facility-details-modal"
    >
      <Modal.ColumnLayout>
        <div className={styles.infoWrapper}>
          <OpeningHours facility={facility} label={label} />
          <FacilityContact facility={facility} label={label} />
          <FacilityDescription facility={facility} label={label} />
        </div>
      </Modal.ColumnLayout>
    </Modal>
  );
};

export default FacilityDetails;
