import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';
import List from '../routes/List';

const Routes = ({ children }: { children: React.ReactNode }) => {
  if (children) return <>{children}</>;

  return (
    <Switch>
      <Route path={pagePaths.Module} children={<List />} />
    </Switch>
  );
};

export default Routes;
