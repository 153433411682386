const labels = {
  ContactForm: {
    'Ref: Page title': 'Susisiekite su mumis',
    'Ref: Category field value': 'Susisiekite su mumis',
  },
  ContactList: {
    'Ref: Page title': 'kontaktai',
    'call (action to place a call)': 'Skambinkite',
    'write (action to write a message)': 'Rašykite',
    'write (action to write an e-mail)': 'El. Paštas',
    'Ref: Hint message':
      'Atminkite, kad kai kuriose svetainėse gali būti nepriimta. Tokiu atveju rasite vieną iš svetainės esančių fiksuotojo ryšio telefonų.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Įvyko klaida ir jūsų pranešimas nebuvo pateiktas. Jei ši klaida išlieka, kreipkitės į savo pagalbos tarnybą.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Jūsų žinutė buvo pateikta. Dėkojame, kad susisiekėte su mumis.',
  },
};
export default labels;
