import { useHistory, useParams } from 'react-router';

import { pagePaths } from '../../config';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';

import NoticePage from '@/components/templates/NoticePage/NoticePage';
import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const FeedbackSuccess = () => {
  const { label } = useServiceRequestTranslation(__filename);
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const actions = [
    {
      label: label('Go to home', { textTransform: 'capitalize' }),
      action: () => history.push('/'),
    },
  ];

  const requestId = params.id;
  if (requestId) {
    actions.push({
      label: label('Back to this service request'),
      action: () => history.push(pagePaths['Details'].replace(':id', requestId)),
    });
  }

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('thank you', { textTransform: 'capitalize' })}
      content={label('Ref: Body')}
      actions={actions}
    />
  );
};
export default FeedbackSuccess;
