import { useSelector } from 'react-redux';

import { formatDate } from '../../../../helpers/dateTime';
import useShareModal from '../../../../helpers/hooks/useShareModal';
import { State } from '../../../../types/state.types';
import { useGetHomeQuery } from '../../api/api';
import { ContentTile } from '../../components/ContentTile';
import { contentConfig } from '../../config';
import { useContentTranslation } from '../../hooks/useContentTranslation';

import { getFilters } from './ContentList.helper';
import { ContentItem } from './ContentList.types';

import { EventIllustration } from '@/assets/illustrations';
import { TileSkeleton } from '@/components/molecules/Tile';
import Column from '@/components/organisms/Column';
import ListPage from '@/components/templates/ListPage/ListPage';
import { useSetupOption } from '@/helpers/hooks/useSetupOption/useSetupOption';
import { SERVICE } from '@/modules/config';

const ContentList = () => {
  const { label } = useContentTranslation(__filename);
  const displayContentCategories = useSetupOption('displayContentCategories', SERVICE.CONTENT);
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  const { currentLanguageCode: languageCode } = useSelector(
    (state: State) => state.Shared?.language
  );

  const { isCategoryEnabled } = contentConfig();

  const { data: list = [], isLoading } = useGetHomeQuery({
    languageCode,
    siteId,
  });

  const { shareOptionsModal, triggerShareModal } = useShareModal({
    label,
  });

  const items: Array<ContentItem> = list.map((contentPiece) => {
    return {
      ...contentPiece,
      type: contentPiece.type?.toLowerCase(),
      date: formatDate(new Date(contentPiece.publishedDate), languageCode),
      'data-testid': `content-list-item-${contentPiece.id}`,
    };
  });

  const filters = getFilters({ items, label });

  const showFilters = displayContentCategories.isActive || isCategoryEnabled;

  const search = {
    searchableKeys: ['title'],
  };

  const dates = [...new Set(items.map(({ date }) => date))];
  const sections = dates.map((title) => ({
    title,
    key: title,
    filter: (itemsToFilter: ContentItem[]) => itemsToFilter.filter((item) => item.date === title),
  }));

  return (
    <ListPage
      hasBackLink={false}
      title={label('Ref: Page title')}
      isLoading={isLoading}
      items={items}
      search={search}
      filter={showFilters ? { filters } : undefined}
      data-testid="content-list"
      itemRenderer={(item) => (
        <ContentTile
          key={item.id}
          item={item}
          triggerShareModal={triggerShareModal}
          siteId={siteId}
          languageCode={languageCode}
          label={label}
        />
      )}
      sections={sections}
      hideFilterTitle
      aside={
        <Column.Complementary>
          <EventIllustration />
        </Column.Complementary>
      }
    >
      {isLoading &&
        Array.from(Array(10).keys()).map((value: number) => (
          <TileSkeleton key={value} withoutActions />
        ))}
      {shareOptionsModal}
    </ListPage>
  );
};

export default ContentList;
