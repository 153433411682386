import { TextareaChangeEventDetail } from '@ionic/core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { pagePaths } from '../../config/default';
import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';

import { Woman2Illustration } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import Textarea from '@/components/atoms/Textarea/Textarea';
import { TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import { AttachmentData } from '@/components/organisms/Attachments/Attachments.types';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { validateForm, FormValidationAlert } from '@/components/validateForm';
import { formatDate, formatTime } from '@/helpers/dateTime';
import {
  useGetAccommodationRequestDetailsQuery,
  useCommentMutation,
} from '@/modules/AccommodationRequest/api';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import { State } from '@/types/state.types';

const MAX_FILES_NUM = 2;
const MAX_FILE_SIZE = 1024 * 1024 * 10;

const getFormValidationRules = (commentBody: string) => {
  //params: use the label keys directly
  return {
    Comment: () => !!commentBody?.length,
  };
};

const UpdateUs = () => {
  const history = useHistory();
  let { id } = useParams<{ id: string }>();
  const { label } = useAccommodationRequestTranslation(__filename);
  const { currentLanguageCode } = useLanguage();
  const user = useSelector((state: State) => state.Core.user);
  const username = `${user.firstName} ${user.lastName}`;
  const [comment, { isSuccess: commentSubmissionOutcome }] = useCommentMutation({});
  const { data, isLoading } = useGetAccommodationRequestDetailsQuery({ id });
  const [commentBody, setCommentBody] = useState('');
  const [attachments, setAttachments] = useState<AttachmentData[]>([]);
  const [showFormValidationAlert, setShowFormValidationAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const contentRef = useRef();

  useEffect(() => {
    if (commentSubmissionOutcome === true && id) {
      history.push(pagePaths.Details.replace(':id', id));
    }
  }, [commentSubmissionOutcome, id, history]);

  const detailsTitle = useMemo(
    () =>
      data &&
      `${data.referenceNumber} ${'•'} ${label('Ref: Arrival')}: ${formatDate(
        new Date(data.arrivalDateTime),
        currentLanguageCode
      )} - ${formatTime(new Date(data.arrivalDateTime))}`,
    [data, label, currentLanguageCode]
  );

  if (isLoading) {
    return <LoadingPage />;
  }
  const handleChange = (e: CustomEvent<TextareaChangeEventDetail>) =>
    setCommentBody(e.detail.value || '');

  const { isValid: isFormValid, missingInfo: formMissingInfo } = validateForm(
    getFormValidationRules(commentBody)
  );

  const submitComment = async () => {
    if (isFormValid) {
      setSubmitting(true);
      await comment({
        id: id,
        comment: commentBody,
        serviceRequestId: data?.serviceRequestId ?? '',
        userName: username,
        attachments: attachments.map((attachment: AttachmentData) => ({
          id: '#',
          fileName: attachment.file.name,
          content: attachment.data?.substring(
            attachment.data?.indexOf('base64,')! + 'base64,'.length
          ),
        })),
      });
      setSubmitting(false);
    } else {
      setShowFormValidationAlert(true);
    }
  };

  return (
    <SimpleFormPage title={label('Ref: Update us')} contentRef={contentRef}>
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              children: detailsTitle,
              tag: TITLE_TAG.H2,
            }}
          >
            <form>
              <Textarea
                id="commentBody"
                label={label('Ref: Update us msg')}
                data-testid={'comment-body-text-area'}
                required
                value={commentBody}
                onChange={handleChange}
              />
              <Attachments
                attachments={attachments}
                maxFilesNum={MAX_FILES_NUM}
                maxFileSize={MAX_FILE_SIZE}
                attach={setAttachments}
                data-testid="comment-attachments"
              />
            </form>
          </Card>
          <FormValidationAlert
            missingInfo={formMissingInfo}
            msgCodes={{}}
            show={showFormValidationAlert}
            onDidDismiss={() => {
              setShowFormValidationAlert(false);
            }}
            label={label}
          />
        </Column.Main>
        <Column.Complementary>
          <Woman2Illustration />
        </Column.Complementary>
        <ActionsBar inMainColumn>
          <Button
            onClick={submitComment}
            loading={submitting}
            data-testid="comment-submit-button"
            disabled={!isFormValid}
            isClickDisabled
          >
            {label('Ref: Submit')}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default UpdateUs;
