import { QuestionResponse } from '../api/api.types';

import { TestingPropsV2 } from 'src/types';

export enum AuditStatus {
  OPEN = 0,
  COMPLETED = 1,
  CANCELLED = 2,
}

export type AuditInvite = {
  id: string;
  auditName: string;
  auditStreamType: string;
  workOrderNumber: string;
  workOrderLocation: string;
  dueDate: string;
  state: AuditStatus;
  locationId?: string;
  assetId?: string;
  assetName?: string;
  responses?: QuestionResponse[];
} & TestingPropsV2;

export type AuditQuestion = {
  id: string;
  text: string;
  questionType: number;
  choices: string[];
  choiceType: string;
  order: number;
  isMultiline: boolean;
  isRequired: boolean;
  isCommentRequired: boolean;
  maximumRating: number;
  otherProperties: string;
};

export type Location = { id: string; name: string };

export type Stream = { id: string; name: string };

export type Audit = {
  id: string;
  name: string;
  auditStreamId: string;
  auditStreamName: string;
  questions: AuditQuestion[];
};

export type Asset = {
  id: string;
  name: string;
  masterAssetName: string;
  locationId: string;
  assetLevel: string;
};
