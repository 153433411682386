import { IonToolbar } from '@ionic/react';
import classNames from 'classnames';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../atoms/Button/Button';
import Hint from '../../atoms/Hint';

import styles from './ActionsBar.module.css';

const ActionsBar = ({ actions, topContent, stylingOptions }) => {
  let actionButtons;
  let count = 0;
  if (actions?.length) {
    actionButtons = (
      <IonToolbar id="ActionsBarToolbar">
        <div
          id="ActionButtonsExternalContainer"
          className={stylingOptions?.contentBackground ? stylingOptions.contentBackground : ''}
        >
          <div id="ActionButtonsContainer">
            {actions.map((actionDef) => {
              count++;
              let look;
              if (actionDef?.look) look = actionDef?.look;
              else {
                switch (count) {
                  case 1:
                    look = 'primary';
                    break;
                  case 2:
                    if (stylingOptions?.contentBackground === 'background_primary')
                      look = 'secondary_on_primary';
                    else look = 'secondary';
                    break;
                  default:
                    if (stylingOptions?.contentBackground === 'background_primary')
                      look = 'tertiary_on_primary';
                    else look = 'tertiary';
                }
              }

              const button = (
                <Button
                  id={actionDef?.id ?? uuidv4()}
                  data-testid={`button-action-${look}`}
                  data-cy={`button-action-${look}`}
                  key={actionDef.label}
                  look={look}
                  onClick={actionDef?.action}
                  loading={actionDef?.loading}
                  affix={actionDef?.affix ?? ' '}
                  suffix={actionDef?.suffix ?? ' '}
                  disabled={actionDef?.disabled}
                  isMultiline={actionDef?.isMultiline}
                  isClickDisabled={actionDef?.isClickDisabled}
                >
                  {actionDef?.label}
                </Button>
              );

              if (!actionDef?.left && !actionDef?.right)
                return (
                  <React.Fragment key={count}>
                    {actionDef?.hint && <Hint data-testid='actions-bar-side' text={actionDef?.hint} />}
                    {button}
                  </React.Fragment>
                );

              return (
                <React.Fragment key={count}>
                  {actionDef?.hint && <Hint data-testid='actions-bar' text={actionDef?.hint} />}
                  <div key={count} className={classNames(styles.ActionBar_ButtonContainer)}>
                    {button}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </IonToolbar>
    );
  }

  return (
    <React.Fragment>
      {topContent ? <div id="ActionsBarTopContent">{topContent}</div> : null}
      {actionButtons}
    </React.Fragment>
  );
};

export default ActionsBar;
