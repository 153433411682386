const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': "Demande de service - Service d'assistance",
    'Ref: Forbidden heading': 'Interdit',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Vous administrez des demandes de service sur le site {siteName}.',
    'Ref: Dates': 'Dates',
    'Ref: SRL list refresh': 'Rafraîchir la liste',
    'Ref: Export': 'exporter',
    'Ref: Empty/not filled': 'Vide / non rempli',
    'Ref: Name': 'Nom',
    'Ref: Date': 'Date',
    'Ref: Category': 'Catégorie',
    'Ref: User': 'Utilisateur',
    'Ref: Location': 'Emplacement',
  },
};
export default labels;
