const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Permintaan akomodasi',
    'Ref: Describe your request': 'Jelaskan permintaan Anda',
    'Ref: Confirm your request': 'Konfirmasikan permintaan Anda',
    'Ref: Your request': 'Permintaanmu',
    'Ref: Summary': 'Ringkasan',
    'Ref: Next': 'Berikutnya',
    'Ref: Submit': 'Kirim',
    'Ref: Me': 'Aku',
    'Ref: Arrival date': 'Tanggal kedatangan',
    'Ref: Arrival time': 'Jam kedatangan',
    'Ref: Departure date': 'Tanggal keberangkatan',
    'Ref: Departure time': 'Waktu keberangkatan',
    'Ref: request raised for': 'Orang yang terkena dampak permintaan ini',
    'Ref: phoneNumber': 'Nomor telepon',
    'Ref: arrivalDate': 'Kedatangan',
    'Ref: departureDate': 'Keberangkatan',
    'Ref: description': 'Komentar',
    'Ref: open': 'Membuka',
    'Ref: pending': 'Tertunda',
    'Ref: refused': 'Ditolak',
    'Ref: closed': 'Tertutup',
    'Ref: all': 'Semua',
    'Ref: Arrival': 'Kedatangan',
    'Ref: Validate': 'Mengesahkan',
    'Ref: site': 'Lokasi',
    'Ref: Update us': 'Perbarui kami',
    'Ref: email of guest': 'Email tamu',
    'Ref: GuestAccommodationRequestTitle': 'Permintaan Tamu',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Permintaan baru',
    'Ref: Status': 'Status',
    'Ref: Date': 'Tanggal',
    'Ref: Coming arrivals': 'Kedatangan kedatangan',
    'Ref: Past arrivals': 'Kedatangan masa lalu',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Jumlah Telepon Tamu',
    'Ref: Your phone number': 'Nomor telepon Anda',
    'Ref: The phone number': 'Nomor telepon',
    'Ref: PhoneNumber error msg': 'Nomor telepon tidak valid',
    'Ref: Comment error msg': 'Jumlah karakter maksimum adalah 250',
    'Ref: Guest first name': 'Nama Depan Tamu',
    'Ref: Guest last name': 'Nama Belakang Tamu',
    'Ref: request for': 'Untuk siapa permintaan ini?',
    'Ref: request for me': 'Aku',
    'Ref: request for guest': 'Orang lain',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Detail Permintaan Akomodasi',
    'Ref: Timeline': 'Linimasa',
    'Ref: No updates placeholder': 'Tidak ada pembaruan untuk permintaan ini',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Terima kasih',
    'Ref: Success text':
      'Kami telah menerima permintaan Anda dan sedang memeriksanya sekarang. Kami akan segera menghubungi Anda.',
    'Ref: Go to home': 'Pergi ke rumah',
    'Ref: Create another request': 'Buat permintaan lain',
  },
  CreateError: {
    'Ref: Create error title': 'Ups!',
    'Ref: Create error text': 'Terjadi kesalahan, silakan coba lagi.',
    'Ref: Retry': 'Coba lagi',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Harap perbarui kami dengan elemen baru tentang permintaan Anda sehingga kami dapat mendukung Anda.',
  },
};
export default labels;
