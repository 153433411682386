import classNames from 'classnames';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import { breakPointsInPixels } from '../../../config';
import useWindowSize from '../../../helpers/hooks/useWindowSize';
import Stepper from '../../atoms/Stepper';

import BottomBar, { BAR_POSITION } from './BottomBar';
import Column from './Column';
import { LayoutBehaviorType, LayoutProps } from './Layout.types';

import styles from './Layout.module.css';

export enum MAIN_WIDTH {
  NARROW = 1,
  WIDE = 2,
}

// It must be used to avoid overlapping the content with the bottomBar in the mobile view
const MOBILE_OFFSET_BETWEEN_CONTENT_AND_BOTTOM_BAR = 16;
const Layout = ({
  mainWidth = MAIN_WIDTH.NARROW,
  sideImage,
  layoutBehaviorType,
  topContent,
  bottomBar,
  children,
  secondColumn,
  withStepper = false,
}: LayoutProps) => {
  const sideImageRef = useRef<HTMLDivElement>(null);
  const bottomBarRef = useRef<HTMLDivElement>(null);

  const [isMobile, setIsMobile] = useState<boolean>(
    !window.matchMedia(`(min-width: ${breakPointsInPixels.L}px)`).matches
  );

  const windowSize = useWindowSize();

  useEffect(() => {
    if (secondColumn && sideImage) {
      throw new Error('Combining two column layout with side image is forbidden');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsMobile(!window.matchMedia(`(min-width: ${breakPointsInPixels.L}px)`).matches);
  }, [windowSize]);

  const isBottomBarFixed = !bottomBar?.position || bottomBar.position === BAR_POSITION.DYNAMIC;
  const [bottomBarHeight, setBottomBarHeight] = useState(0);
  const bottomBarDomNode = bottomBarRef.current;
  const isTwoColumnsSuccessLayout =
    layoutBehaviorType === LayoutBehaviorType.SuccessPage && (secondColumn || sideImage);
  const isCenteredPage = layoutBehaviorType === LayoutBehaviorType.CenteredPage;
  const isSuccessPage = layoutBehaviorType === LayoutBehaviorType.SuccessPage;

  useLayoutEffect(() => {
    if (!bottomBarDomNode || !isBottomBarFixed) return;

    const bottomBarResizeObserver = new ResizeObserver((entries) => {
      var entry = entries.length > 0 ? entries[0] : undefined;
      if (entry) {
        const height = bottomBarRef?.current?.clientHeight
          ? bottomBarRef?.current?.clientHeight + MOBILE_OFFSET_BETWEEN_CONTENT_AND_BOTTOM_BAR
          : 0;
        setBottomBarHeight(height);
      } else setBottomBarHeight(0);
    });

    bottomBarResizeObserver.observe(bottomBarDomNode);
    return () => bottomBarResizeObserver.unobserve(bottomBarDomNode);
  }, [bottomBarDomNode, isBottomBarFixed]);

  return (
    <div
      className={classNames(styles.layout, {
        [styles.doubleColumn]: secondColumn,
        [styles.withSideImage]: sideImage,
        [styles.withoutBottomBar]: !bottomBar,
      })}
    >
      {withStepper && (
        <div className={classNames(styles.stepper)}>
          <Stepper horizontalOnly />
        </div>
      )}

      {topContent && (
        <div
          className={classNames({
            [styles.topContentMobile]: isMobile,
            [styles.topContent]: !isMobile,
            [styles.topContentSuccessMobile]: layoutBehaviorType === LayoutBehaviorType.SuccessPage,
          })}
        >
          {topContent}
        </div>
      )}

      <div
        className={classNames(styles.contentWrapper, {
          [styles.verticalCenterTwoColumn]: layoutBehaviorType === LayoutBehaviorType.SuccessPage,
          [styles.centered]: isCenteredPage,
          [styles.noGrow]:
            layoutBehaviorType ===
            LayoutBehaviorType.DoubleColumnCenteredBottomBarAndSideImageOnMobile,
        })}
      >
        {/* Main column, can be width 1 or 2 columns */}
        <div
          className={classNames(styles.mainContainer, {
            // TODO refactor to utilize showSideImageOnMobile and verticalCenterColumns along with others, remove LayoutBehaviorType
            [styles.sideImageShowMobileWrapper]:
              isTwoColumnsSuccessLayout ||
              layoutBehaviorType === LayoutBehaviorType.DetailWithImagePage ||
              layoutBehaviorType ===
                LayoutBehaviorType.DoubleColumnCenteredBottomBarAndSideImageOnMobile,
            [styles.horizontalcenterTwoColumn]: isTwoColumnsSuccessLayout,
            [styles.sideImageShowMobileWrapperCentered]: isSuccessPage,
          })}
          style={isMobile ? { paddingBottom: bottomBarHeight - 24 } : {}}
        >
          <Column
            className={classNames(styles.column, styles.content, {
              [styles.twoColWidth]: mainWidth === MAIN_WIDTH.WIDE,
            })}
          >
            {topContent && layoutBehaviorType === LayoutBehaviorType.SuccessPage && (
              <div className={classNames(styles.topContentSuccessWeb, 'mb-L')}>{topContent}</div>
            )}
            {children}
            {!secondColumn &&
              layoutBehaviorType !==
                LayoutBehaviorType.DoubleColumnCenteredBottomBarAndSideImageOnMobile && (
                <BottomBar {...{ bottomBar, bottomBarRef }} />
              )}
          </Column>
          {secondColumn && (
            <Column className={classNames(styles.column, styles.secondColumn)}>
              {secondColumn}
              <BottomBar {...{ bottomBar, bottomBarRef }} />
            </Column>
          )}
          {sideImage && (
            <Column
              className={classNames(styles.column, styles.sideImage, {
                [styles.sideImageShowMobile]:
                  layoutBehaviorType === LayoutBehaviorType.SuccessPage ||
                  layoutBehaviorType === LayoutBehaviorType.DetailWithImagePage ||
                  layoutBehaviorType ===
                    LayoutBehaviorType.DoubleColumnCenteredBottomBarAndSideImageOnMobile,
              })}
            >
              <div ref={sideImageRef} data-cy="layout-side-image">
                {sideImage}
              </div>
            </Column>
          )}
        </div>
      </div>
      {layoutBehaviorType ===
        LayoutBehaviorType.DoubleColumnCenteredBottomBarAndSideImageOnMobile && (
        <div className={styles.bottomBarCenterWrapper}>
          <BottomBar {...{ bottomBar, bottomBarRef }} />
        </div>
      )}
    </div>
  );
};

export default Layout;
