import classNames from 'classnames';

import { Skeleton, SkeletonSquare } from '../../atoms/Skeleton';

import style from './SideBarSite.module.css';
const SideBarSiteSkeleton = ({ collapsed }: { collapsed?: boolean }) => (
  <div className={classNames(style.SideBarSite)}>
    <div className={style.iconWrapper}>
      <div className={style.icon}>
        <SkeletonSquare />
      </div>
    </div>
    {!collapsed && (
      <div>
        <div className={style.title}>
          <Skeleton width={'9.375rem'} />
        </div>
        <div>
          <Skeleton width={'9.375rem'} />
        </div>
      </div>
    )}
  </div>
);

export default SideBarSiteSkeleton;
