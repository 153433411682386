const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Denetimler',
    'Ref: status0': 'Açık',
    'Ref: status1': 'Tamamlanmış',
    'Ref: status2': 'İptal edildi',
    'Ref: connected': 'Bağlı',
    'Ref: not connected': 'Bağlı değil',
    'Ref: synchronise data': 'Verileri senkronize edin',
    'Ref: sync success': 'Veriler başarıyla senkronize edildi',
    'Ref: Create new': 'Yeni oluşturmak',
    'Ref: Audit unsynchronised': 'Senkronize edilmemiş',
    'Ref: Audit synchronised': 'Senkronize edilmiş',
    'Ref: Unsynced error title': 'bir hata oluştu',
    'Ref: Unsynced error description':
      'Denetim modülüne ilk kez erişmeniz gibi görünüyor. Lütfen ağa bağlanın ve denetime başlamak için “senkronize edin”.',
    'Ref: Search all audits': 'Tüm Denetimler',
    'Ref: Search active audits': 'Açık',
    'Ref: Search completed audits': 'Tamamlanmış',
    'Ref: sync error': 'Senkronizasyon başarısız oldu',
    'Ref: sync error details': 'Yeniden Bağlanmak İçin Yeniden Diyak',
    'Ref: no search audit results': 'Ne yazık ki, aramanıza karşılık gelen denetim yok',
    'Ref: no audit results': 'Mevcut denetiminiz yok, başlamak için yeni oluştur',
    'Ref: Search canceled audits': 'İptal edildi',
    'Ref: Audit cancelled successfully': 'Denetim başarıyla iptal edildi',
    'Ref: Cancel audit invite': 'İptal etmek',
    'Ref: Error audit cancel': 'Denetim iptal edilemedi',
    'Ref: Cancel audit confirmation': 'Denetimi iptal etmek istediğinizden emin misiniz?',
    'Ref: Confirm': 'Onaylamak',
    'Ref: Cancel audit': 'Denetimi İptal Et',
    'Ref: Change the location': 'Konumu değiştir',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Denetim Ayrıntıları',
    'Ref: questions': 'soru',
    'Ref: question': 'soru',
    'Ref: Additional Comments': 'Tanım',
    'Ref: Not found title': 'Oops!',
    'Ref: Not found text': 'Erişmeye çalıştığınız denetim mevcut değildir.',
    'Ref: Back to Audit list': 'Denetim listesine geri dön',
    'Ref: Success title': 'Teşekkür ederim!',
    'Ref: Success text': 'Denetimi tamamladınız',
    'Ref: submit': 'Göndermek',
    'Ref: add attachment': 'Bir ek ekle',
    'Ref: Unsupported elements':
      'Üzgünüm, bu denetimin uygulamanın henüz desteklemediği içeriği var gibi görünüyor. Üzerinde çalışacağız.',
    'Ref: form error': 'Tüm sorular tam olarak cevaplanmadı',
    'Ref: form complete': 'O zamandan beri tamamlandı',
    'Ref: attachment preview error': 'Bu eki görmek için çevrimiçi olmanız gerekiyor',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Yeni oluşturmak',
    'Ref: Location': 'Konum',
    'Ref: Audit Stream Type': 'Aktarım',
    'Ref: Audit': 'Denetim',
    'Ref: Asset': 'Varlık',
    'Ref: Asset Name': 'Varlık Adı',
    'Ref: Is joint audit?': 'Ortak denetim mi?',
    'Ref: Who was present?': 'Kim mevcuttu?',
    'Ref: submit': 'Göndermek',
    'Ref: Select the Location': 'Konumu seçin',
    'Ref: Select the Audit Stream Type': 'Denetim akışı türünü seçin',
    'Ref: Select the Audit': 'Denetimi seçin',
    'Ref: Select the Asset': 'Varlığı seçin',
    'Ref: form error': 'Gerekli tüm alanlar tam olarak girilmedi',
    'Ref: Audit error': 'Ekli denetim',
    'Ref: Joint Audit Participants error': 'Ortak Denetim Katılımcıları',
    'Ref: Audit created': 'Denetim Oluşturuldu',
    'Ref: Audit field length error': 'Uzunluk 100 karakteri geçmemelidir',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Konumu değiştir',
    'Ref: Location changed': 'Konum Değiştirildi',
    'Ref: Location': 'Konum',
    'Ref: Select the Location': 'Konumu seçin',
    'Ref: Asset': 'Varlık',
    'Ref: Select the Asset': 'Varlığı seçin',
    'Ref: Asset Name': 'Varlık Adı',
    'Ref: submit': 'Göndermek',
    'Ref: Audit field length error': 'Uzunluk 100 karakteri geçmemelidir',
  },
};
export default labels;
