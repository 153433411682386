export function isIndexDbAvailable(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    try {
      let preExist: boolean = true;
      const DB_CHECK_NAME = 'validate-browser-context-for-indexeddb-analytics-module';
      const request = indexedDB.open(DB_CHECK_NAME);
      request.onsuccess = () => {
        request.result.close();
        // delete database only when it doesn't pre-exist
        if (!preExist) {
          indexedDB.deleteDatabase(DB_CHECK_NAME);
        }
        resolve(true);
      };
      request.onupgradeneeded = () => {
        preExist = false;
      };

      request.onerror = () => {
        reject(false);
      };
    } catch (error) {
      reject(false);
    }
  });
}
