import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useGetHomeQuery } from '../../api';
import { moduleId, pagePaths } from '../../config';
import { contentConfig } from '../../config';
import { useContentTranslation } from '../../hooks/useContentTranslation';
import { IContentType, ContentWidgetProps } from '../../types';

import { ImageDefaultNoFoodImg } from '@/assets/images';
import ImageWithFallback from '@/components/atoms/ImageWithFallback/ImageWithFallback';
import { Tile } from '@/components/molecules/Tile';
import MiniWidget from '@/components/organisms/MiniWidget';
import { State } from '@/types/state.types';

const MAX_PROMO_CONTENT_ITEMS = 3;

const PromotionsWidget: React.FC<ContentWidgetProps> = () => {
  const { label } = useContentTranslation(__filename);
  const { promotionsWidgetTitle } = contentConfig();
  const history = useHistory();
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id);
  const currentLanguageCode = useSelector(
    (state: State) => state.Shared?.language?.currentLanguageCode
  );
  const { data: list = [] } = useGetHomeQuery({
    languageCode: currentLanguageCode,
    siteId: siteId!,
    useErrorBoundary: false,
  });

  const items = useMemo(
    () =>
      list
        ?.filter((c) => c.type === IContentType.PROMOTIONAL)
        .slice(0, MAX_PROMO_CONTENT_ITEMS)
        .map((item) => {
          return (
            <Tile
              key={item.id}
              id={item.id}
              title={item.title}
              description={item.details}
              dark
              image={
                <ImageWithFallback
                  data-testid="promotions-widget"
                  imgInfo={item.imgInfo}
                  imgElement={<ImageDefaultNoFoodImg />}
                />
              }
              onClick={() => history?.push(pagePaths['ContentDetails'].replace(':id', item.id))}
            />
          );
        }),
    [history, list]
  );

  return (
    <>
      {items && items.length > 0 ? (
        <MiniWidget id={'carousel_promo_' + moduleId} title={label(promotionsWidgetTitle)}>
          {items}
        </MiniWidget>
      ) : undefined}
    </>
  );
};

export default PromotionsWidget;
