import { screen, cleanup, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import { renderedComponent } from '../../../../../helpers/tests/renderComponent';
import { Contact } from '../../../types/Contacts';
import ContactList from '../ContactList';

const contacts: Array<Contact> = [
  { id: 'id-1', email: 'mail1@ok.com', name: 'Name 1', phone: '11111' },
  { id: 'id-2', email: 'mail2@ok.com', name: 'Name 2', phone: '22222' },
  { id: 'id-3', email: 'mail3@ok.com', name: 'Name 2', phone: '33333' },
];

jest.mock('../../../api', () => ({
  useGetContactsQuery: () => ({ data: { siteContacts: contacts } }),
}));
jest.mock('../../../../services', () => ({
  checkIfServiceAvailable: () => true,
}));

describe('Contact List', () => {
  afterEach(() => cleanup());

  describe('Initial render', () => {
    beforeEach(async () => {
      //. (useGetContactsQuery as jest.Mock).mockReturnValueOnce({ data: { siteContacts: contacts } });
      await act(async () => {
        renderedComponent(ContactList);
      });
    });

    it('Should render page with list', () => {
      const item = screen.getByText('Contacts');
      expect(item).toBeTruthy();
    });

    it('Should display all Contacts on the List', async () => {
      const items = screen.getAllByTestId('contact-title');
      expect(items).toHaveLength(contacts.length);
    });

    it('should filter items', async () => {
      const searchInput = screen.getByTestId(
        'contacts-list-page-search-bar-list-page-search-filter-bar-input-field'
      ) as HTMLInputElement;

      await act(async () => {
        await userEvent.type(searchInput, contacts[0].name);
        await new Promise((r) => setTimeout(r, 400));
      });

      const items = screen.getAllByTestId('contact-title');

      expect(items).toHaveLength(1);
    });
  });
});
