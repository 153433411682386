import { FC, useMemo, useState } from 'react';

import { getCurrentLanguageCode } from '../../../helpers/misc';
import { languages } from '../../../modules/Core/config';
import { Language } from '../../../modules/Core/types/config.types';
import Button, { BUTTON_LOOK } from '../../atoms/Button';
import List from '../../organisms/List/List';
import { CONTROL_TYPE } from '../../organisms/List/List.types';
import Modal from '../../organisms/Modal';

import { LangSelectorProps } from './LangSelector.types';

const LangSelector: FC<LangSelectorProps> = ({
  label,
  open,
  setModalOpen,
  handleLanguageSubmit,
}) => {
  const languageCode = getCurrentLanguageCode();

  const [modalSelectedLanguage, setModalSelectedLanguage] = useState(languageCode);

  const currentLanguage = languages.find(({ code }) => code === modalSelectedLanguage) as Language;

  const listOfLanguages = useMemo(
    () =>
      languages
        .filter((language) => !language.name.includes('Label keys'))
        .map((l) => ({
          id: l.code,
          label: l.name,
          'data-testid': `lang-item-${l.code}`,
          control: {
            type: CONTROL_TYPE.RADIO,
            props: {
              id: l.code,
              value: l.code,
              name: 'language',
              checked: l.code === modalSelectedLanguage,
              'data-testid': `lang-item-${l.code}-radio`,
            },
          },
        })),
    [modalSelectedLanguage]
  );

  const handleSubmit = () => {
    handleLanguageSubmit(modalSelectedLanguage, currentLanguage?.name);
    setModalOpen(false);
  };

  const onDismiss = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      id="lang_modal"
      data-testid="lang-modal"
      onDismiss={onDismiss}
      title={label('Ref: My language')}
      footer={
        <Button
          fullWidth
          look={BUTTON_LOOK.PRIMARY}
          onClick={handleSubmit}
          data-testid="language-selector-handle-submit"
        >
          {label('Validate')}
        </Button>
      }
    >
      <Modal.ColumnLayout>
        <List
          items={listOfLanguages}
          onChange={(option) => setModalSelectedLanguage(option[0].id)}
          role="group"
          aria-labelledby={label('Ref: My language')}
          data-testid="lang-list"
        />
      </Modal.ColumnLayout>
    </Modal>
  );
};

export default LangSelector;
