import * as H from 'history';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EyeIcon } from '../../../../assets/icons';
import { ReceptionIllustration } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import { FilterType, NormalFilter } from '../../../../components/atoms/Filters/Filters.types';
import { Tile } from '../../../../components/molecules/Tile';
import Column from '../../../../components/organisms/Column';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { formatDate } from '../../../../helpers/dateTime';
import { TranslationProps } from '../../../../localization/localization.types';
import { useGetStaysQuery } from '../../api';
import StayStatus from '../../components/StayStatus';
import { pagePaths } from '../../config';
import { useAccomodationTranslation } from '../../hooks/useAccomodationTranslation';
import { IStay } from '../../types/accomodation.types';

import { StaysListProps } from './StaysLists.types';

import { State } from '@/types/state.types';

import './StaysList.css';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(23, 59, 59);

const getStaysItems = (
  stays: IStay[],
  label: TranslationProps['label'],
  currentLanguageCode: string,
  history: H.History<unknown>
) => {
  return stays.map((stay) => {
    const path = pagePaths['StayDetails'].replace(':id', stay.id);
    return {
      id: stay.id,
      title: `${stay.reservationNumber} - ${stay.siteName}`,
      description: (
        <>
          <p>{`${label('from (followed by a date, or date and time)', {
            textTransform: 'capitalize',
            end: 'colon',
          })} ${formatDate(new Date(stay.arrivalDateLocal), currentLanguageCode)} ${label(
            'to (followed by a date, or date and time)',
            {
              start: 'whitespace',
              textTransform: 'capitalize',
              end: 'colon',
            }
          )} ${formatDate(new Date(stay.departureDateLocal), currentLanguageCode)}`}</p>
          <p>
            <StayStatus status={stay.status} />
          </p>
        </>
      ),
      onClick: () => history.push(path),
      actions: [
        {
          name: label('See more'),
          customAction: (
            <Button
              data-testid="corner_action"
              look={BUTTON_LOOK.PRIMARY}
              onClick={() => history.push(path)}
            >
              <EyeIcon title={label('See more')} />
            </Button>
          ),
        },
      ],
      'data-testid': `stays-list-item`,
    };
  });
};

const StaysList = ({ parentFilter, parentSearch }: StaysListProps) => {
  const { data: stays = [], isFetching: lock } = useGetStaysQuery({});
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);
  const { label } = useAccomodationTranslation(__filename);

  const history = useHistory();

  const filters: NormalFilter[] = [
    {
      id: 'filters_period',
      options: [
        {
          value: 'current_future',
          label: label('Current and future stays'),
          default: true,
        },
        {
          value: 'past',
          label: label('Ref: Past', { textTransform: 'capitalize' }),
        },
      ],
      displayType: FilterType.EXPANDED,
      multiple: false,
      apply: (selectedValues: string[]) => {
        const selectedValue = selectedValues[0];
        return stays
          .filter((item) => {
            const departureDate = new Date(item.departureDateLocal);
            return selectedValue === 'current_future'
              ? departureDate > yesterday
              : departureDate <= yesterday;
          })
          .map((item) => item.id);
      },
    },
  ];

  const filter = {
    filters,
    initialFiltering: parentFilter.filtering,
    handleFilteringChange: parentFilter.handleFilteringChange,
  };

  const search = {
    searchableKeys: ['title'],
    searchString: parentSearch.searchString,
    handleSearchChange: parentSearch.handleSearchChange,
    placeholder: label('Ref: Search'),
  };

  if (lock) return <LoadingPage />;

  const items = getStaysItems(stays, label, currentLanguageCode, history);

  return (
    <ListPage
      hasBackLink={false}
      title={label('Ref: Page title')}
      filter={filter}
      items={items}
      itemRenderer={(item) => <Tile key={item.id} {...item} />}
      hideFilterTitle={true}
      search={search}
      data-testid="stays-list-page"
      aside={
        <Column.Complementary>
          <ReceptionIllustration />
        </Column.Complementary>
      }
    />
  );
};

export default StaysList;
