import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useCreateRequestMutation } from '../../api';
import StepperCreate from '../../components/StepperCreate';
import { pagePaths } from '../../config';
import { getServiceRequestLight, removeServiceRequestLight } from '../../helpers/helpers';
import {
  useServiceRequestLightSetupOptionValue,
  useServiceRequestLightSetupOption,
} from '../../hooks/useServiceRequestLightSetupOption';
import { useServiceRequestLightTranslation } from '../../hooks/useServiceRequestLightTranslation';
import { FormFields } from '../../types';

import { WarningIcon } from '@/assets/icons';
import Button from '@/components/atoms/Button';
import DismissButton from '@/components/atoms/DismissButton/DismissButton';
import toast from '@/components/atoms/ModalToast';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import List from '@/components/organisms/List/List';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { renderAffectedPersonName } from '@/modules/ServiceRequest/helpers/helpers';
import { GridItem } from '@/types/common.types';
import { State } from '@/types/state.types';

import styles from './Summary.module.css';

const Summary = (): ReactElement => {
  const { label } = useServiceRequestLightTranslation(__filename);
  const history = useHistory();
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  const currentSiteName = useSelector((state: State) => state.Core?.context?.site?.name || '');
  const [storedRequests, setStoredRequests] = useState<FormFields | undefined>(undefined);
  const { getValues, reset } = useFormContext<FormFields>();
  const defaultValues = getValues();
  const currentUser = useSelector((state: State) => state.Core?.user);

  const serviceHelpdeskEmail = useServiceRequestLightSetupOptionValue('serviceHelpdeskEmail');
  const siteLanguageIsoCode = useServiceRequestLightSetupOptionValue('siteLanguageIsoCode');

  const showAffectedPerson = useServiceRequestLightSetupOption('enableAffectedPerson');
  const showCategories = useServiceRequestLightSetupOption('enableCategories');

  useEffect(() => {
    const fetchCachedRequests = async () => {
      const res = await getServiceRequestLight();
      setStoredRequests(res);
    };

    fetchCachedRequests().catch((err) => console.error(err));
  }, []);
  const values = useMemo(() => {
    if (storedRequests) {
      return { ...defaultValues, ...storedRequests };
    }
    return defaultValues;
  }, [storedRequests, defaultValues]);

  const [createRequest, { isLoading }] = useCreateRequestMutation();

  const handleCreateRequest = useCallback(async () => {
    const response = await createRequest({
      attachments: values.attachments ?? [],
      title: values.title,
      category: values.category,
      phoneNumber: values.phoneNumber,
      description: values.description,
      buildingText: values.buildingText ?? '',
      roomText: values.roomText ?? '',
      siteId: siteId,
      raisedForId: values.affectedPerson?.value,
      applicationName: process.env.REACT_APP_APP_NAME,
      serviceHelpdeskEmail: serviceHelpdeskEmail?.value || '',
      siteLanguageIsoCode: siteLanguageIsoCode?.value || '',
    });

    const { error } = response as { error: { status: string } };

    const toastErrorHeader = (testId: string, title: string, subTitle: string) => {
      return (
        <div data-testid={testId} className={styles.errorTextWrapper}>
          <div>
            <Title size={TITLE_SIZE.BODYSBOLD}>{title}</Title>
            <Title size={TITLE_SIZE.BODYSDEFAULT}>{subTitle}</Title>
          </div>
          <div
            role="button"
            aria-label={label('Ref: Try again')}
            onClick={() => {
              history.push(pagePaths.Create);
            }}
            tabIndex={0}
            onKeyDown={(e) => e.key === 'Enter' && history.push(pagePaths.Create)}
          >
            <Title size={TITLE_SIZE.BODYSBOLD} className={styles.tryAgain}>
              {label('Ref: Try again')}
            </Title>
          </div>
        </div>
      );
    };

    if (error?.status) {
      toast.error(
        toastErrorHeader(
          'create-request-error-toast',
          label('Ref: Error title'),
          label('Ref: Error subtitle')
        ),
        {
          icon: <WarningIcon />,
          position: 'top-center',
          className: styles.errorToastBody,
          closeOnClick: true,
          draggable: false,
          closeButton: (
            <DismissButton data-testid="create-request-error-toast-dismiss-btn" label={label} />
          ),
          hideProgressBar: true,
        }
      );
      return;
    }

    if (!error?.status) {
      toast.success(label('Ref: Success title'), {
        toastId: 'create-request-success-toast',
        position: 'top-center',
        closeOnClick: true,
        draggable: false,
        closeButton: false,
      });
      history.push(pagePaths.Module);
      reset();
      removeServiceRequestLight();
    }
  }, [
    createRequest,
    serviceHelpdeskEmail,
    siteLanguageIsoCode,
    values,
    siteId,
    label,
    history,
    reset,
  ]);

  const items: GridItem[] = [];
  items.push({
    id: 'summary',
    label: label('Ref: Summary'),
    value: values.title,
    path: pagePaths.Create,
    'data-testid': 'summary',
  });
  if (showCategories && values.category !== '') {
    items.push({
      id: 'request-category',
      label: label('Ref: Request category'),
      value: values.category,
      path: pagePaths.Create,
      'data-testid': 'category',
    });
  }
  if (showAffectedPerson && values.affectedPerson?.label !== '') {
    items.push({
      id: 'affectedPerson',
      label: label('Ref: Person affected'),
      value:
        values.affectedPerson?.value === currentUser?.contactId
          ? renderAffectedPersonName(
              currentUser.firstName || '',
              currentUser.lastName || '',
              currentUser.email || '',
              label,
              true
            )
          : values.affectedPerson?.label ?? '',
      path: pagePaths.Create,
      'data-testid': 'affectedPerson',
    });
  }
  if (values.phoneNumber && values.phoneNumber !== '') {
    items.push({
      id: 'phoneNumber',
      label: label('Ref: Your phone number'),
      value: values.phoneNumber,
      path: pagePaths.Create,
      'data-testid': 'phoneNumber',
    });
  }
  items.push(
    {
      id: 'location',
      label: label('Ref: Location'),
      value: [currentSiteName, values.buildingText, values.roomText]
        .filter((value) => !!value)
        .join(' / '),
      path: pagePaths.Create,
      'data-testid': 'location',
    },
    {
      id: 'description',
      label: label('Ref: Description'),
      value: values.description,
      path: pagePaths.Create,
      'data-testid': 'description',
    }
  );
  if (values.attachments && values.attachments.length > 0) {
    items.push({
      id: 'attachment',
      label: label('Ref: Attachment'),
      value: (
        <Attachments
          attachments={values.attachments}
          readOnly
          closeButtonNotVisible
          data-testid="attachments"
        />
      ),
      path: pagePaths.Create,
      'data-testid': 'attachments',
      className: styles.attachments,
    });
  }

  return (
    <SimpleFormPage
      title={label('Ref: ServiceRequestLightTitle')}
      data-testid="service-request-light-summary"
    >
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              tag: TITLE_TAG.H2,
              children: label('Ref: Summary'),
              size: TITLE_SIZE.HEADLINES,
            }}
          >
            <List data-testid="service-request-light-summary-list-item" items={items} />
          </Card>
        </Column.Main>
        <Column.Side>
          <StepperCreate stepIndex={1} />
        </Column.Side>
        <ActionsBar>
          <Button
            onClick={handleCreateRequest}
            data-testid="service-request-light-summary-validate-button"
            loading={isLoading}
          >
            {label('Ref: Submit')}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default Summary;
