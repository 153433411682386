import { getApiEndpoints } from '../configs';
import mockResponse from '../mocks/api';

import { GetContactsResponse } from './api.types';

import { isMyVillage } from '@/modules/Core/helpers/helpers';
import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

const featuredApi = isMyVillage() ? baseApi : dspApi;
const apiEndpoints = getApiEndpoints();

const contactsApi = featuredApi.enhanceEndpoints({ addTagTypes: ['contacts'] }).injectEndpoints({
  endpoints: (build) => ({
    getContacts: build.query<GetContactsResponse, string>({
      query: (siteId) => ({
        url: apiEndpoints.getSiteContacts.replace(':siteId', siteId),
        method: 'get',
        mockData: mockResponse.getContacts,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetContactsQuery } = contactsApi;
export default contactsApi;
