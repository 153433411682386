import baseApi from '../../../services/api/baseApi';
import mockResponse from '../mockResponse';

import { BusStop, GetSchedulesArgs, Schedule } from './api.types';

const journeyPlannerApi = baseApi
  .enhanceEndpoints({ addTagTypes: ['journeyPlanner'] })
  .injectEndpoints({
    endpoints: (build) => ({
      // These types refer to the request result and query arguments <ResulType, QueryArgs>
      getOrigins: build.query<BusStop[], void>({
        /**
         * `query` is used to pass details to the `baseQuery` method in order to build the request.
         * Optionally, we can use `queryFn` instead of `query` if we want to bypass
         * the `baseQuery` and use our own custom action
         * https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#customizing-queries-with-queryfn
         */
        query: () => ({
          url: '/v1/journeyplanner/stops',
          method: 'get',
          mockData: mockResponse.getOrigins,
        }),
      }),
      getDestinations: build.query<BusStop[], string | undefined>({
        query: (stopId: string) => ({
          url: `/v1/journeyplanner/stops/${stopId}/destinations`,
          method: 'get',
          mockData: mockResponse.getDestinations,
        }),
      }),
      getSchedules: build.query<Schedule[], GetSchedulesArgs>({
        query: (args: GetSchedulesArgs) => ({
          url: '/v1/journeyplanner/schedules',
          method: 'get',
          params: args,
          mockData: mockResponse.getSchedules,
        }),
      }),
    }),
  });

/**
 * Hooks are automatically generated based on the name of methods defined in `endpoints`.
 * Ex. getOrigins > useGetOriginsQuery
 * https://redux-toolkit.js.org/rtk-query/api/created-api/overview#react-hooks
 */
export const { useGetOriginsQuery, useGetDestinationsQuery, useGetSchedulesQuery } =
  journeyPlannerApi;
export default journeyPlannerApi;
