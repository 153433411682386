const labels = {
  All: {
    Feedback: 'Feedback',
    'Ref: Switch site component intro sentence':
      'Uw feedback wordt gelinkt aan de site {site name}.',
    Category: 'Categorie',
    Subject: 'Onderwerp',
    Comment: 'Commentaar',
    'Date of your experience': 'Datum van uw ervaring *',
    'Ref: Hint': 'Heeft u een probleem of taak die moet worden opgelost?',
    'Ref: Log Request': 'Meld hier uw aanvraag',
    'Ref: Describe issue': 'Vertel ons alsjeblieft over je ervaring',
    Date: 'Datum',
    'Ref: Share your opinion header': 'Deel uw mening over uw ervaring op het werk',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurant / café',
    'Desk / workstation area': 'Bureau / werkplaats',
    'Meeting room facilities': 'Vergader faciliteiten',
    'Coffee/tea facilities (pantry)': 'Koffie/theefaciliteiten (pantry)',
    'Restrooms / washrooms': 'Toiletten / sanitair',
    'Reception / Security': 'Ontvangst / beveiliging',
    'Conference center': 'Conferentiecentrum',
    'Fitness center': 'Fitness centrum',
    Mailroom: 'Postkamer',
    'Bicycle room': 'Fietsruimte',
    'Shuttle services': 'Pendeldiensten',
    Lockers: 'Lockers',
    Helpdesk: 'Helpdesk',
  },
  Create: {
    'Tell us!': 'Vertel het ons!',
    'Ref: All Ears': 'We zijn een en al oor!',
    'Ref: Provide Description': 'Geef een beschrijving',
    'Ref: Fill-in-the-blank sentence': 'Ik wil een {0} leveren met betrekking tot {1}',
    'Ref: Link text to service request module': 'Een aanvraag of een probleem? Meld het hier!',
    'Complaint type': 'Klacht {emoji}',
    'Feedback type': 'Feedback {emoji}',
    'Compliment type': 'Compliment {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Er is een fout opgetreden en uw feedback is niet ingediend. Als deze fout aanhoudt, neem dan contact op met uw helpdesk.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Uw feedback is ingediend. Ons ondersteuningsteam van de werkplek zal het zorgvuldig lezen. Indien van toepassing komen we bij u terug.',
    'New feedback': 'Nieuwe feedback',
  },
  CreateFlow: {
    Description: 'Beschrijving',
    'Ref: Provide Description': 'Geef een datum en beschrijving op',
    'Ref: I would like to provide a': 'Ik zou graag een',
    'Ref: in regards to': 'met betrekking tot',
    'Ref: &': '&',
    'Ref: at': 'Om',
    'Ref: on the': 'op de',
  },
};
export default labels;
