const labels = {
  Success: {
    'Ref: Body':
      'Спасибо за завершение этого обследования, ваше время ценно, поскольку эти исследования помогают улучшить наши услуги.',
    'Ref: Tabtitle': 'Обзор завершен',
    'Take another survey': 'Примите еще один опрос',
  },
  Survey: {
    'Ref: Page title': 'Детали опроса',
    'Please answer all mandatory questions as identify by an asterisk':
      'Пожалуйста, ответьте на все обязательные вопросы, как идентифицировать звездочку: *',
    'Ref: Take another survey': 'Примите еще один опрос',
    'Ref: Go to Home': 'Иди домой',
  },
  SurveysList: {
    'Ref: Page title': 'Опрос',
    'Ref: Available until': 'Доступно до',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'В настоящее время вы просматриваете опросы для сайта {site name}.',
  },
};
export default labels;
