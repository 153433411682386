const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Ref: ServiceRequestLightHelpdeskTitle',
    'Ref: Forbidden heading': 'Ref: Forbidden heading',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Ref: You are administrating service requests on the site {siteName}.',
    'Ref: Dates': 'Ref: Dates',
    'Ref: SRL list refresh': 'Ref: SRL list refresh',
    'Ref: Export': 'Ref: Export',
    'Ref: Empty/not filled': 'Ref: Empty/not filled',
    'Ref: Name': 'Ref: Name',
    'Ref: Date': 'Ref: Date',
    'Ref: Category': 'Ref: Category',
    'Ref: User': 'Ref: User',
    'Ref: Location': 'Ref: Location',
  },
};
export default labels;
