import classNames from 'classnames';
import { useCallback } from 'react';
import { osName } from 'react-device-detect';
import { useHistory } from 'react-router';

import { pagePaths as corePagePaths } from '../../../Core/config';
import { getAppStoreImagePath, getAppStoreUrl } from '../../helpers/helpers';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';

import { SuccessProps } from './Success.types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Title, { TITLE_SIZE } from '@/components/atoms/Title';
import ActionCard from '@/components/organisms/ActionCard/ActionCard';
import SuccessPage from '@/components/templates/SuccessPage';
import { getAppName, openUrl } from '@/helpers/misc';

import styles from './Success.module.css';

const Success = ({ clearDraft, withNavBar }: SuccessProps) => {
  const { label } = useServiceRequestTranslation(__filename);
  const history = useHistory();
  const appName = getAppName();
  const storeUrl = getAppStoreUrl(appName, osName);
  const appImagePath = getAppStoreImagePath(appName);

  const redirectToStore = useCallback(() => {
    openUrl(storeUrl!);
  }, [storeUrl]);

  return (
    <SuccessPage withNavBar={withNavBar}>
      <div className={styles.mainContainer}>
        <Title
          size={TITLE_SIZE.HEADLINEL}
          data-testid="service-request-success-title"
          className={classNames(styles.title)}
        >
          {label('thank you', { textTransform: 'capitalize' })}
        </Title>
        <Title size={TITLE_SIZE.BODYSBOLD} data-testid="accomodation-request-success-text">
          {label('Ref: Body')}
        </Title>
        {!!storeUrl && !!appImagePath && (
          <ActionCard
            data-testid={'download-the-app'}
            onActionButtonClick={redirectToStore}
            text={label('Ref: Download app title')}
            subText={label('Ref: Download app subtitle')}
            buttonText={label('Ref: Download app button')}
            image={<img src={appImagePath} alt="" />}
          ></ActionCard>
        )}
        <Button
          className={classNames(styles.goToHomeButton)}
          data-testid="button-action-go-home"
          look={BUTTON_LOOK.SECONDARY}
          onClick={() => {
            if (clearDraft) clearDraft();
            history.push(corePagePaths['Home']);
          }}
        >
          {label('Back to service requests')}
        </Button>
      </div>
    </SuccessPage>
  );
};
export default Success;
