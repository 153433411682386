const labels = {
  All: {
    'Ref: See item': 'Zie item',
    'Ref: Print': 'Druk uw ontvangstbewijs af',
    'Ref: Email': 'Ontvang uw ontvangstbewijs per e-mail',
    'Ref: Receipt Modal message': 'Hoe zou u uw ontvangstbewijs willen krijgen?',
    'Ref: Loyalty Reward': 'Loyaliteitsbeloning',
    'Ref: Equation for stamps per item': '1 aankoop = 1 stempel',
    'Ref: Paid by employer': 'Het bedrag dat door uw werkgever wordt betaald',
    'Ref: Paid by user': 'Het door u betaalde bedrag',
  },
  ProductsList: {
    'Ref: Page title': 'Product lijst',
    'Ref: Facility details': 'Details van de diensten',
    'Ref: Search products list': 'Zoekopdrachten',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Categorie',
    'Ref: all': 'Alle',
    'Ref: today': 'Vandaag',
    'Ref: tomorrow': 'Morgen',
    'Ref: see more': 'Bekijk meer',
    'Ref: see less': 'Minder',
    'Ref: reset': 'Reset',
    'Ref: View in cart': 'Bekijk in winkelwagen',
    'Ref: You have changed location': 'Je hebt van locatie veranderd',
    'Ref: You are now at': 'Je bent nu bij',
    'Ref: Menu card': 'Menukaart',
    'Ref: Table': 'Tafelnummer',
    'Service unavailable': 'Service onbeschikbaar',
    'Ref: Ordering unavailable':
      'Opgelet! Bestellen op deze site is momenteel niet beschikbaar, probeer het later opnieuw.',
    'Ref: Filters': 'Filters (allergenen en meer)',
  },
  Order: { 'Ref: Page title': 'Bestelling' },
  MenuSelector: {
    'Ref: Menu Page title': "Menu's",
    'Ref: today': 'Vandaag',
    'Ref: tomorrow': 'Morgen',
    'Ref: Search products list': 'Zoekopdrachten',
    'Ref: You have changed location': 'Je bent van locatie veranderd',
    'Ref: You are now at': 'Je bent nu bij',
  },
  ProductDetails: {
    'Ref: Page title': 'Productdetails',
    'Ref: Serving Size': 'Serveergrootte',
    'Ref: Nutrition': 'Voedingsinformatie',
    'Ref: Nutrition adults': 'Volwassenen hebben ongeveer 2000 kcal per dag nodig',
    'Ref: Nutrition Calories': 'Calorieën',
    'Ref: Nutrition Calcium': 'Calcium',
    'Ref: Nutrition Fat': 'Vet',
    'Ref: Nutrition Saturated Fat': 'Verzadigd vet',
    'Ref: Nutrition Carbohydrates': 'koolhydraten',
    'Ref: Nutrition Sugar': 'Suiker',
    'Ref: Nutrition Protein': 'Eiwit',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Voedingsvezels',
    'Ref: Nutrition iron': 'Ijzer',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'Vitamine A',
    'Ref: Nutrition vitaminC': 'Vitamine C',
    'Ref: Additives': 'Additieven',
    'Ref: Emission': 'CO2 uitstoot',
    'Ref: This product generates': 'Dit product genereert',
    'Ref: of CO2': 'van CO2',
    'Ref: emissions, which equals a': 'emissies, die gelijk is aan a',
    'Ref: car trip': 'autorit',
    'Ref: Ingredients': 'Recept ingrediënt',
    'Ref: Allergens': 'Allergenen',
    'Ref: Always there': 'Altijd daar',
    'Ref: Allergens selected': 'Allergenen geselecteerd op het filter:',
    'Ref: minimum required': 'Minimaal vereist',
    'Ref: you have to add at least': 'Je moet tenminste toevoegen',
    'Ref: ingredients': 'ingrediënten',
    'Ref: Ingredient': 'ingrediënt',
    'Ref: Free': 'Vrij',
    'Ref: Based on customization': 'Op basis van uw aanpassing',
    'Ref: Additives eggs': 'eieren',
    'Ref: Additives fish': 'vis',
    'Ref: Additives milk': 'melk',
    'Ref: Additives nuts': 'noten',
    'Ref: Additives wheat': 'tarwe',
    'Ref: Additives peanuts': "pinda's",
    'Ref: Additives soy beans': 'soja bonen',
    'Ref: Additives sesame seeds': 'Sesam zaden',
    'Ref: Review': 'Beoordeling',
    'Ref: Per portion': 'Per portie',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Allergen selected': 'Allergeen geselecteerd op zoekfilter',
    'Ref: Fitbit Log': 'Log naar Fitbit',
    'Ref: age verification required on delivery': 'Leeftijdsverificatie vereist bij levering',
    'Ref: alcohol content notification':
      'Door een van deze items te bestellen, verklaart u dat u 18 jaar of ouder bent. Alcoholmisbruik is gevaarlijk voor uw gezondheid.',
    'Ref: default': 'Standaard',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form} verdiend als u dit product bestelt',
    'Ref: Loyalty Card Description':
      'De {stamp_form} wordt toegevoegd aan de volgende loyaliteit {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Met het Loyalty Stamps -programma kunt u verzamelen voor beloningen.',
    'Ref: Loyalty Modal Description':
      'Wanneer u <b> {product_name} koopt, wordt 1 stempel </b> toegevoegd aan de <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'En',
    'Ref: Stamp': 'Zegel',
    'Ref: Stamps': 'Zegels',
    'Ref: Program': 'Programma',
    'Ref: Programs': "Programma's",
    'Ref: Loyalty Stamps': 'Loyaliteitstzegels',
    'Ref: See Loyalty Stamps': 'Zie loyaliteitszegels',
    Favorite: 'Favoriet',
    'Ref: In favorites': 'Het product staat al in de favorietenlijst. Klik om het te verwijderen.',
    'Ref: Not in favorites':
      'Het product staat nog niet in de favorietenlijst. Klik om het toe te voegen.',
    'Ref: Added to favorites': 'Het product is toegevoegd aan de lijst met favorieten.',
    'Ref: Removed from favorites': 'Het product is verwijderd uit de favorietenlijst.',
    'Ref: From': 'Van',
  },
  OrderHistory: { 'Ref: Orders': 'Bestellingen', 'Ref: Page Title': 'mijn bestellingen' },
  MenuTile: { 'Ref: Scan&Go': 'Scan & Go', 'Ref: View only': 'Alleen bekijken' },
  ProductTile: {
    'Ref: Allergens': 'Allergenen',
    'Ref: Presence of allergen': 'Aanwezigheid van allergeen',
    'Ref: Scan': 'Scannen',
    'Ref: From': 'Van',
    'Ref: Already in product favorite list. Click to remove it.':
      'Al in de favoriete lijst van product. Klik om het te verwijderen.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Momenteel niet toegevoegd aan de favoriete lijst met product. Klik om het toe te voegen.',
    'Ref: Free': 'Vrij',
  },
  MenusWidget: { 'Ref: no menu': 'Geen menu voorlopig', 'Ref: Modal title': 'Menu voor de dag' },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Afhalen',
    'Ref: Eat-in': 'Ter plaatse consummeren',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Delivery': 'Levering',
    'Ref: Total': 'Totaal',
    'Ref: How was facility name service': 'Hoe was {facility_name} ´s service?',
    'Ref: How do you rate the service': 'Hoe beoordeelt u de service?',
  },
};
export default labels;
