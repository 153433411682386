import classNames from 'classnames';
import React, { useRef } from 'react';

import useStateWithProps from '../../../helpers/hooks/useStateWithProps';
import { generateId } from '../../../helpers/utils';
import Title, { TITLE_SIZE } from '../Title';

import { RadioProps } from './Radio.types';

import styles from './Radio.module.css';

const Radio = ({
  id,
  name,
  value,
  checked = false,
  disabled = false,
  inputRef,
  inputLabel,
  labelHidden,
  look,
  onClick,
  onChange,
  'data-testid': testId,
  ...rest
}: RadioProps) => {
  const [isChecked, setIsChecked] = useStateWithProps(checked);

  const localRef = useRef<HTMLInputElement | null>(null);
  const ref = inputRef || localRef;

  const radioId = id || `radio-${generateId()}`;

  const handleOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const { currentTarget: { checked: eventChecked } = {} } = e;
    if (eventChecked !== undefined) setIsChecked(eventChecked);

    if (onClick) {
      onClick(e, { value, checked: eventChecked });
    }
  };

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, value);
    }
  };

  const radio = (
    <div
      className={classNames(styles.radio, {
        [styles.checked]: isChecked,
        [styles.disabled]: disabled,
        [styles[`style-${look}`]]: look,
      })}
      data-testid={`${testId}-container`}
    >
      <input
        {...rest}
        ref={ref}
        type="radio"
        id={radioId}
        name={name}
        value={value}
        disabled={disabled}
        checked={isChecked}
        aria-checked={isChecked}
        onClick={handleOnClick}
        onChange={handleOnChange}
        tabIndex={0}
        data-testid={`${testId}-input`}
      />
    </div>
  );

  return inputLabel ? (
    <label className={classNames(styles.radioWrapper)} data-testid={`${testId}-label-wrapper`}>
      {radio}
      <Title
        className={labelHidden ? 'sr-only' : ''}
        data-testid={`${testId}-label-value`}
        size={TITLE_SIZE.BODYSDEFAULT}
      >
        {inputLabel}
      </Title>
    </label>
  ) : (
    radio
  );
};

export default Radio;
