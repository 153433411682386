const labels = {
  ContactForm: {
    'Ref: Page title': 'Skontaktuj się z nami',
    'Ref: Category field value': 'Skontaktuj się z nami',
  },
  ContactList: {
    'Ref: Page title': 'Kontakty',
    'call (action to place a call)': 'połączenie',
    'write (action to write a message)': 'napisz wiadomość',
    'write (action to write an e-mail)': 'napisz e-mail',
    'Ref: Hint message':
      'Należy pamiętać, że w niektórych miejscach może nie być zasięgu. W takim przypadku prosimy o skorzystanie z jednego z telefonów stacjonarnych dostępnych w okolicy.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Wystąpił błąd i Twoja wiadomość nie została przesłana. Jeśli ten błąd nadal występuje, skontaktuj się z działem pomocy technicznej.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success':
      'Twoja wiadomość została przesłana. Dziękujemy za skontaktowanie się z nami.',
  },
};
export default labels;
