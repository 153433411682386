import React, { ComponentProps, ReactNode } from 'react';

import { DefaultComponentWithoutChildrenProps, TestingProps } from '../../../types';
import Button from '../../atoms/Button';

export enum TILE_VARIANT {
  STANDARD = 'standard',
  BIG = 'big',
}

export enum TAG_VARIANT {
  TAG = 'tag',
  CHIP = 'chip',
}

export enum TAG_COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  DARK = 'dark',
  LIGHT = 'light',
}

type TilePropsBase = {
  title: React.ReactNode;
  actionsCollapseAfter?: number;
  oneLineTile?: boolean;
  variant?: TILE_VARIANT;
  description?: React.ReactNode;
  oneLineDescription?: boolean;
  image?: React.ReactNode;
  cornerContent?: React.ReactNode;
  isHighlight?: boolean;
  chips?: Tag[];
  stickers?: Tag[];
  tags?: TagProps[];
  moreActionText?: string;
  dark?: boolean;
  customContent?: React.ReactNode;
  belowDescription?: React.ReactElement;
  belowTitle?: React.ReactNode;
  actionsWithLabel?: boolean;
  popoverPosition?: POPOVER_POSITION;
  isLoading?: boolean;
  onCornerClick?: React.MouseEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  preventBubblingForCorner?: boolean;
  focusOnRender?: boolean;
} & Omit<ComponentProps<'div'>, 'children'> &
  TestingProps;

type TilePropsActions = {
  actions?: TileAction[];
  childText?: never;
  strikethroughChildText?: never;
  children?: never;
  fullChildrenHeight?: never;
} & TilePropsBase;

type TilePropsChildren = {
  actions?: never;
  childText?: React.ReactNode;
  strikethroughChildText?: string;
  children?: React.ReactNode;
  fullChildrenHeight?: boolean;
} & TilePropsBase;

export type TileProps = TilePropsActions | TilePropsChildren;

export type Tag = {
  name: string;
  icon?: React.ReactNode;
  color?: TAG_COLOR;
};

export type TileAction = {
  name: string;
  icon?: React.ReactNode;
  customAction?: React.ReactNode;
} & Omit<
  ComponentProps<typeof Button>,
  | 'look'
  | 'inheritStyle'
  | 'affix'
  | 'suffix'
  | 'badge'
  | 'size'
  | 'contentCenterAlign'
  | 'alignTop'
  | 'isMultiline'
  | 'isColumn'
>;

export type TileListProps = {
  variant?: TILE_VARIANT;
  twoTilesColumn?: boolean;
} & ComponentProps<'div'> &
  TestingProps;

export type TileActionsProps = {
  actions: TileAction[];
  moreActionText?: string;
  moreActionSrOnlyText?: string;
  popoverPosition?: POPOVER_POSITION;
  actionsCollapseAfter?: number;
} & TestingProps;

export type TagProps = {
  name: string;
  element?: JSX.Element;
  icon?: React.ReactNode;
  variant?: TAG_VARIANT;
  color?: TAG_COLOR;
} & TestingProps;

export type ButtonPopoverListProps = {
  list?: TileAction[];
  srOnlyText?: string;
  popoverPosition?: POPOVER_POSITION;
} & TestingProps;

export type TileSkeletonProps = {
  variant?: TILE_VARIANT;
  withoutImage?: boolean;
  withoutActions?: boolean;
  withoutChildren?: boolean;
  children?: ReactNode | ReactNode[];
} & DefaultComponentWithoutChildrenProps;

export enum POPOVER_POSITION {
  default = 'default',
  bottom = 'bottom',
}
