import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { AuditIllustration } from '../../../../assets/illustrations';
import Checkbox from '../../../../components/atoms/Checkbox';
import { HookSelect as Select } from '../../../../components/atoms/Select';
import { Skeleton } from '../../../../components/atoms/Skeleton';
import Textarea from '../../../../components/atoms/Textarea';
import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import Form from '../../../../components/molecules/Form';
import { requiredRule } from '../../../../components/molecules/Form/helpers';
import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import {
  useCreateAuditMutation,
  useGetAssetsQuery,
  useGetAuditLocationsQuery,
  useGetAuditsQuery,
  useGetStreamsQuery,
} from '../../api';
import SubmitButton from '../../components/SubmitButton';
import { pagePaths } from '../../config';
import { useAuditsTranslation } from '../../hooks/useAuditsTranslation';

import { AuditCreationFormFields, SelectedOption } from './AuditCreationForm.types';

import toast from '@/components/atoms/ModalToast';

import styles from './AuditCreationForm.module.css';

const AuditCreationForm = () => {
  const { label } = useAuditsTranslation(__filename);
  const history = useHistory();
  const [selectedLocation, setLocation] = useState<SelectedOption | null>(null);
  const [selectedStream, setStream] = useState<SelectedOption | null>(null);
  const [isJointAudit, setIsJointAudit] = useState<boolean>(false);

  const { data: locationData = [] } = useGetAuditLocationsQuery();
  const { data: streamData = [] } = useGetStreamsQuery();
  const { data: auditData = [], isFetching: isFetchingAudits } = useGetAuditsQuery(
    selectedStream?.value || null,
    { skip: !Boolean(selectedStream) }
  );
  const { data: assetData = [], isFetching: isFetchingAssets } = useGetAssetsQuery(
    selectedLocation?.value || null,
    { skip: !Boolean(selectedLocation) }
  );
  const [
    createAuditMutation,
    {
      data: { id: createdAuditId } = { id: null },
      isLoading: isSubmitting,
      isSuccess: isSubmitted,
    },
  ] = useCreateAuditMutation();

  const locations = useMemo(
    () => locationData?.map(({ id, name }) => ({ value: id, label: name })),
    [locationData]
  );
  const streams = useMemo(
    () => streamData?.map(({ id, name }) => ({ value: id, label: name })),
    [streamData]
  );
  const audits = useMemo(
    () => auditData?.map(({ id, name }) => ({ value: id, label: name })),
    [auditData]
  );
  const assets = useMemo(
    () => assetData?.map(({ id, name }) => ({ value: id, label: name })),
    [assetData]
  );

  const onChangeLocation = useCallback(
    ({ value, label }: SelectedOption) => {
      setLocation({ value, label });
    },
    [setLocation]
  );
  const onChangeStream = useCallback(
    ({ value, label }: SelectedOption) => {
      setStream({ value, label });
    },
    [setStream]
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
    setValue,
  } = useForm<AuditCreationFormFields>({
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<AuditCreationFormFields> = useCallback(
    (formData) => {
      const body = {
        locationId: formData.locationId,
        auditId: formData.auditId,
        assetId: formData.assetId,
        assetName: formData.assetName,
        isJointAudit:
          (formData.jointAuditParticipants && formData.jointAuditParticipants?.length > 0) || false,
        jointAuditParticipants: formData.jointAuditParticipants,
        dueDate: moment().format(),
      };

      createAuditMutation(body);
    },
    [createAuditMutation]
  );

  const pageTitle = label('Ref: Page title');
  const noOptionsMessage = () => (
    <div className={styles.optionsFetchingPlaceholder}>
      <Skeleton width="60%" />
    </div>
  );

  useEffect(() => {
    if (isSubmitted && createdAuditId) {
      toast.success(label('Ref: Audit created'), {
        position: 'top-center',
        closeOnClick: true,
        draggable: false,
        closeButton: false,
      });

      history.replace(pagePaths.AuditResponseForm.replace(':id', createdAuditId));
    }
  }, [isSubmitted, createdAuditId, label, history]);

  return (
    <SimpleFormPage withNavBar={false} title={pageTitle}>
      <Container>
        <Column.Main>
          {Object.keys(errors).length > 0 && (
            <Notification
              look={NOTIFICATION_LOOK.ERROR}
              dismissable
              title={label('Ref: form error')}
              data-testid="error-notification"
            />
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Card className={classNames('mb-L', styles.auditDetailsCard)}>
              <div className={styles.container}>
                <div className={styles.dropdown}>
                  <Select
                    name="locationId"
                    data-testid="locations-select"
                    label={label('Ref: Location')}
                    placeholder={label('Ref: Select the Location')}
                    control={control}
                    required
                    options={locations}
                    noOptionsMessage={noOptionsMessage}
                    onChange={onChangeLocation}
                    rules={requiredRule(label('Ref: Location'), label)}
                  />
                </div>
                <div className={styles.dropdown}>
                  <Select
                    name="streamId"
                    data-testid="streams-select"
                    label={label('Ref: Audit Stream Type')}
                    placeholder={label('Ref: Select the Audit Stream Type')}
                    control={control}
                    required
                    options={streams}
                    noOptionsMessage={noOptionsMessage}
                    onChange={onChangeStream}
                    rules={requiredRule(label('Ref: Audit Stream Type'), label)}
                  />
                </div>
                <div className={styles.dropdown}>
                  <Select
                    name="auditId"
                    data-testid="audits-select"
                    label={label('Ref: Audit')}
                    placeholder={label('Ref: Select the Audit')}
                    control={control}
                    required
                    disabled={audits.length === 0 && !isFetchingAudits}
                    {...(isFetchingAudits
                      ? { options: [], noOptionsMessage }
                      : { options: audits })}
                    rules={requiredRule(label('Ref: Audit error'), label)}
                    onChange={() => {}}
                  />
                </div>
                <div className={styles.dropdown}>
                  <Select
                    name="assetId"
                    data-testid="assets-select"
                    label={label('Ref: Asset')}
                    placeholder={label('Ref: Select the Asset')}
                    control={control}
                    {...(isFetchingAssets
                      ? { options: [], noOptionsMessage }
                      : { options: assets })}
                    disabled={assets.length === 0 && !isFetchingAssets}
                    onChange={() => {}}
                  />
                </div>
              </div>
              <div className={styles.inputWrapper}>
                <Textarea
                  label={label('Ref: Asset Name')}
                  name="assetName"
                  data-testid="asset-name-textarea"
                  rows={1}
                  maxlength={100}
                  control={control}
                  rules={{
                    maxLength: {
                      value: 100,
                      message: label('Ref: Audit field length error'),
                    },
                  }}
                />
              </div>
            </Card>
            <Card className={classNames('mb-L')}>
              <Title size={TITLE_SIZE.BODYSBOLD}>
                {label('Ref: Is joint audit?')}
                <Checkbox
                  name="isJointAudit"
                  data-testid="joint-audit-checkbox"
                  checked={isJointAudit}
                  onClick={() => {
                    setIsJointAudit(!isJointAudit);
                    setValue('jointAuditParticipants', '');
                    clearErrors('jointAuditParticipants');
                  }}
                ></Checkbox>
              </Title>
              <div className={styles.inputWrapper}>
                <Textarea
                  label={label('Ref: Who was present?')}
                  name="jointAuditParticipants"
                  data-testid="joint-audit-participants-textarea"
                  disabled={!isJointAudit}
                  rows={1}
                  maxlength={100}
                  control={control}
                  required={isJointAudit}
                  rules={{
                    required: isJointAudit ? label('Ref: Joint Audit Participants error') : false,
                    maxLength: isJointAudit
                      ? { value: 100, message: label('Ref: Audit field length error') }
                      : false,
                  }}
                />
              </div>
            </Card>
            <SubmitButton label={label} isLoading={isSubmitting} />
          </Form>
        </Column.Main>
        <Column.Complementary>
          <AuditIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default AuditCreationForm;
