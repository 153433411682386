const labels = {
  ContactForm: { 'Ref: Page title': 'Bize Ulaşın', 'Ref: Category field value': 'Bize Ulaşın' },
  ContactList: {
    'Ref: Page title': 'Kişiler',
    'call (action to place a call)': 'aramak',
    'write (action to write a message)': 'yazmak',
    'write (action to write an e-mail)': 'e-posta',
    'Ref: Hint message':
      'Lütfen bazı sitelerin resepsiyona sahip olmayabileceğini unutmayın. Bu durumda lütfen sitede bulunan sabit telefonlardan birini bulun.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Bir hata oluştu ve mesajınız gönderilmedi. Bu hata devam ederse, lütfen yardım masanıza başvurun.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success':
      'Mesajınız gönderildi. Bizimle iletişime geçtiğiniz için teşekkür ederiz.',
  },
};
export default labels;
