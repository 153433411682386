import classNames from 'classnames';

import { InputRowProps } from './InputRow.types';

import styles from './InputRow.module.css';

const InputRow = ({ children }: InputRowProps) => {
  return <div className={classNames(styles.inputRow)}>{children}</div>;
};

export default InputRow;
