import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { getApiEndpoints } from '../config';
import mockResponse from '../mockResponse';
import { ServiceRequest } from '../types/apiResponse.types';
import { CurrentStatus, ServiceRequestProps } from '../types/types';

import {
  GetQrInfoResponse,
  GetServiceRequestParams,
  GetValidateQrCodeParams,
  PostCreateServiceRequestParams,
  QrInfoResponse,
} from './api.types';

import { getUserContext } from '@/modules/Core/actions';
import { isMyVillage } from '@/modules/Core/helpers/helpers';
import {
  getLocalServiceRequest,
  removeLocalServiceRequest,
  saveLocalServiceRequest,
  srTimeDiff,
} from '@/modules/ServiceRequest/helpers/helpers';
import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

const featuredApi = isMyVillage() ? baseApi : dspApi;
const apiEndpoints = getApiEndpoints();

const serviceRequestApi = featuredApi
  .enhanceEndpoints({ addTagTypes: ['service_requests', 'service_requests_locations'] })
  .injectEndpoints({
    endpoints: (build) => ({
      /**
       * GET /v1/servicerequests - get service requests list
       */
      getServiceRequests: build.query<ServiceRequestProps[], { useErrorBoundary?: boolean }>({
        query: ({ useErrorBoundary }) => ({
          url: apiEndpoints.getServiceRequests,
          method: 'get',
          opts: { useErrorBoundary },
          mockData: mockResponse.getAllServiceRequests,
        }),
        transformResponse: (response: { serviceRequests: ServiceRequestProps[] }) => {
          const currentResponse = response.serviceRequests ? response.serviceRequests : [];
          // Check if we have current local service requests
          const localServiceRequests = getLocalServiceRequest();
          const localCache: ServiceRequestProps[] = [];
          localServiceRequests.forEach((localSR: ServiceRequestProps) => {
            // Check if local service request is already created on backend side
            const serviceCreated = response.serviceRequests.find(
              (sr: ServiceRequestProps) =>
                sr.correlationId === localSR.correlationId ||
                (sr.title === localSR.title && srTimeDiff(localSR.createdOn, sr.createdOn))
            );

            // Remove if no correlationId is found and createdOn is more than 10 minutes
            if (
              localSR.correlationId === '' &&
              moment().diff(moment(localSR.createdOn), 'seconds') > 600
            ) {
              removeLocalServiceRequest(localSR.id);
            }

            // if not push to cache again
            if (!serviceCreated) {
              localCache.push(localSR);
            } else {
              // Remove from local cache
              removeLocalServiceRequest(localSR.id);
            }
          });

          return [...localCache, ...currentResponse];
        },
        providesTags: ['service_requests'],
      }),
      /**
       * GET /v1/servicerequests - get service request
       */
      getServiceRequest: build.query<ServiceRequestProps, GetServiceRequestParams>({
        query: ({ id, emailId }) => ({
          url: apiEndpoints.getServiceRequest.replace(':serviceRequestId', id),
          method: 'get',
          params: { id, emailId },
          mockData: mockResponse.getOneServiceRequest,
        }),
        // keep any type as we
        transformResponse: (response: { serviceRequest: ServiceRequestProps }) => {
          return response.serviceRequest;
        },

        providesTags: (_result, _error, { id }) => [{ type: 'service_requests', id }],
      }),
      /**
       * POST /v1/servicerequest
       */
      createServiceRequest: build.mutation<ServiceRequest, PostCreateServiceRequestParams>({
        query: ({
          siteId,
          location,
          referenceNumber,
          title,
          buildingText,
          roomText,
          description,
          raisedForId,
          templateId,
          images,
          category,
          consumerAppUrlTemplate,
        }) => ({
          url: apiEndpoints.create,
          method: 'post',
          data: {
            siteId,
            location,
            locationId: location?.id || '',
            referenceNumber,
            title,
            buildingText: buildingText || roomText,
            roomText,
            description,
            raisedForId,
            templateId,
            requestTypeId: category,
            images,
            consumerAppUrlTemplate,
          },
        }),
        async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
          const nowDateTime = new Date();
          const localId = 'LOCAL-' + uuidv4();
          const localSR = {
            ...patch,
            id: localId,
            currentStatus: CurrentStatus.Local,
            status: CurrentStatus.InProgress,
            modifiedOn: new Date(nowDateTime.setMilliseconds(0)).toJSON(),
            createdOn: new Date(nowDateTime.setMilliseconds(0)).toJSON(),
            displayTitle: patch.title,
            images: patch.images,
            correlationId: '',
          };

          const patchResultWidget = dispatch(
            serviceRequestApi.util.updateQueryData(
              'getServiceRequests',
              { useErrorBoundary: false },
              (draft) => {
                draft.unshift(localSR);
                return draft;
              }
            )
          );
          const patchResult = dispatch(
            serviceRequestApi.util.updateQueryData('getServiceRequests', {}, (draft) => {
              draft.unshift(localSR);
              return draft;
            })
          );

          try {
            const response = await queryFulfilled;
            saveLocalServiceRequest({
              ...localSR,
              correlationId: response.data.correlationId || '',
            });
          } catch (error) {
            removeLocalServiceRequest(localId);
            queryFulfilled.catch(patchResult.undo);
            queryFulfilled.catch(patchResultWidget.undo);
          }
        },
      }),
      /**
       * GET validateqrcode
       */
      validateQrCode: build.query<GetQrInfoResponse, GetValidateQrCodeParams>({
        query: ({ locationId, siteId, currentSiteId, useErrorBoundary }) => ({
          url: apiEndpoints.validateQrCode,
          method: 'get',
          params: { locationId, siteId, currentSiteId },
          mockData: mockResponse.checkQrInfo,
          opts: { useErrorBoundary },
        }),
        transformResponse: async (response: QrInfoResponse, _, args) => {
          await args.dispatch(getUserContext());
          return {
            isSiteChanged:
              args.currentSiteId !== '' ? response.site.id !== args.currentSiteId : false,
            qrInfoValid: true,
          };
        },
      }),
    }),

    overrideExisting: true,
  });

export const {
  useGetServiceRequestsQuery,
  useGetServiceRequestQuery,
  useCreateServiceRequestMutation,
  useLazyValidateQrCodeQuery,
} = serviceRequestApi;
export default serviceRequestApi;
