import { languages } from '../../../modules/Core/config';

export const mainLanguages = [
  'English (United Kingdom)',
  'English (United States)',
  'Deutsch',
  'Español',
  'Français',
];

const filteredLanguages = languages
  .filter((language) => {
    return mainLanguages.includes(language.name);
  })
  .map((language) => {
    return { code: language.code, name: language.name };
  })
  .sort((a, b) => {
    const order: Record<string, number> = {
      'English (United Kingdom)': 0,
      'English (United States)': 1,
      Deutsch: 2,
      Español: 3,
      Français: 4,
    };
    return order[a.name] - order[b.name];
  });

const extendedFilteredWithDefault = [...filteredLanguages, { code: '', name: 'Other' }];
export default extendedFilteredWithDefault;

export const modalLanguages = languages
  .filter((language) => !mainLanguages.includes(language.name) && language.name !== 'Label keys')
  .map((language) => {
    return { code: language.code, name: language.name };
  });
