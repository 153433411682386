import { BUTTON_LOOK } from '../../atoms/Button';

const buttonLook = (index: number) => {
  switch (index) {
    case 1:
      return BUTTON_LOOK.PRIMARY;
    case 2:
      return BUTTON_LOOK.SECONDARY;
    default:
      return BUTTON_LOOK.TERTIARY;
  }
};

export { buttonLook };
