import { IonButton } from '@ionic/react';

import { SIZE } from '../../../constants';
import { TestingPropsV2 } from '../../../types';

export type ButtonLook =
  | 'primary'
  | 'secondary'
  | 'secondary_on_primary'
  | 'secondary_inline'
  | 'tertiary'
  | 'tertiary_on_primary'
  | 'inactive'
  | 'action_card';

export enum BUTTON_LOOK {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_ON_PRIMARY = 'secondary_on_primary',
  SECONDARY_INLINE = 'secondary_inline',
  TERTIARY = 'tertiary',
  TERTIARY_ON_PRIMARY = 'tertiary_on_primary',
  ACTION_CARD = 'action_card',
}

export enum BADGE_POSITION {
  RIGHT = 'right',
  LEFT = 'left',
}

export enum BADGE_PLACEMENT {
  INNER = 'inner',
  OUTER = 'outer',
}

export type ButtonProps = {
  look?: BUTTON_LOOK | ButtonLook; // Fallback for backwards compatibility, TODO move all buttons to enums instead of string literals
  children?: React.ReactNode;
  loading?: boolean;
  inheritStyle?: string;
  affix?:
    | string
    | React.ComponentType<{
        className?: string;
      }>;
  suffix?:
    | string
    | React.ComponentType<{
        className?: string;
      }>;
  badge?: string;
  badgePosition?: BADGE_POSITION;
  badgePlacement?: BADGE_PLACEMENT;
  size?: SIZE;
  contentCenterAlign?: boolean;
  alignTop?: boolean;
  isMultiline?: boolean;
  isColumn?: boolean;
  srOnlyText?: string;
  fullWidth?: boolean;
  active?: boolean;
  isClickDisabled?: boolean;
  id?: string;
  analyticsKey?: string;
} & Omit<
  React.ComponentProps<typeof IonButton>,
  'size' | 'placeholder' | 'onPointerEnterCapture' | 'onPointerLeaveCapture'
> &
  TestingPropsV2;
