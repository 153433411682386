import { Menu } from '../ProductsList/ProductsList.types';

export const getMenuItem = (menus: Menu[] = [], menuItemId: string) => {
  const [menu] = menus;

  if (!menu) return undefined;

  for (const dayPart of menu.dayparts) {
    for (const section of dayPart.sections) {
      for (const menuItem of section.items) {
        if (menuItem.id === menuItemId) return menuItem;
      }
    }
  }

  return;
};
