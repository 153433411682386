import { FieldValues, useController } from 'react-hook-form';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';

import DropdownIndicator from './DropdownIndicator';
import { customStyles, useSelectOptionSwitch } from './helpers';
import IndicatorSeparator from './IndicatorSeparator';
import Option from './Option';
import { HookSelectProps, SelectOption, SelectProps } from './Select.types';
import SelectWrapper from './SelectWrapper';

const HookSelect = <TFieldValues extends FieldValues = FieldValues>({
  control,
  rules,
  required,
  defaultValue,
  onChange,
  onInputChange,
  ...props
}: HookSelectProps<TFieldValues>) => {
  const {
    field: { value, onChange: onChangeHookForm, onBlur }, // TODO add ref for input focus
    fieldState: { error, isDirty },
    formState: { isSubmitted },
  } = useController({
    defaultValue,
    name: props.name,
    control,
    rules,
  });

  const { selectedOption } = useSelectOptionSwitch(props.options, value);

  const handleOnChange = (option: MultiValue<SelectOption> | SingleValue<SelectOption>) => {
    const opt = option as SelectOption;
    // TODO: Handle multiple selections
    onChangeHookForm(opt.value);
    if (onChange) onChange(opt);
  };

  const otherProps: Partial<SelectProps> = {};

  if (error && (isDirty || isSubmitted)) {
    // otherProps.hasError = true;

    if (error?.message) {
      otherProps.message = error.message.toString();
    }
  }

  if (rules && 'required' in rules) {
    otherProps.required = true;
  }

  return (
    <SelectWrapper
      data-cy={props['data-cy']}
      data-testid={props['data-testid']}
      label={props.label}
      message={otherProps.message}
      required={otherProps.required}
      hasError={Boolean(error)}
    >
      <ReactSelect
        {...props}
        required={required}
        value={selectedOption || null}
        onBlur={onBlur}
        components={{ DropdownIndicator, IndicatorSeparator, Option }}
        classNamePrefix="reactSelect"
        styles={customStyles}
        isOptionDisabled={(option: SelectOption) => option.disabled || false}
        data-cy={props['data-cy']}
        inputId={props['data-testid']}
        isDisabled={props.disabled}
        onChange={handleOnChange}
        onInputChange={onInputChange}
        formatOptionLabel={props.formatOptionLabel}
      />
    </SelectWrapper>
  );
};

export default HookSelect;
