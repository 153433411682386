import mockResponse from '../../mockResponse';

import { AppUpdateRequest, AppUpdateResponse } from './appUpdateApi.type';

import dspApi from '@/services/api/dspApi';
import { store } from '@/store/index';

const appUpdateApi = dspApi.enhanceEndpoints({ addTagTypes: ['app_version'] }).injectEndpoints({
  endpoints: (build) => ({
    getAppUpdate: build.query<AppUpdateResponse, AppUpdateRequest>({
      query: (args) => ({
        url: '/v1/appupdate',
        params: { ...args },
        method: 'GET',
        mockData: mockResponse.getAppVersion,
      }),
      transformResponse: (response: AppUpdateResponse) => {
        if (response !== undefined) {
          store.dispatch({
            type: 'GET_APP_VERSION_SUCCESS',
            appVersionInfo: response,
          });
        }
        return response;
      },
      providesTags: ['app_version'],
    }),
  }),
  overrideExisting: true,
});

export const { useGetAppUpdateQuery } = appUpdateApi;
export default appUpdateApi;
