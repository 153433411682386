const labels = {
  All: {
    'status: all': 'All',
    'status: open': 'Open',
    'status: pending': 'Pending',
    'status: closed': 'Closed',
    Location: 'Location',
    Subject: 'Subject',
    Description: 'Your detailed request',
    'Ref: description field label': 'Description',
    'Ref: Title': 'Title',
    phone_number: 'Phone number',
    request_category: 'Request category',
    Attachments: 'Attachments',
    'request service': 'Request service',
    'New service request': 'Create another request',
    'Go to home': 'Go to home',
    'Back to this service request': 'Back to this service request',
    'Back to service requests': 'Go to Home',
    Feedback: 'Feedback',
    'Ref: Request category': 'Category',
    Cleaning: 'Cleaning',
    'Laundry services': 'Laundry services',
    'Grounds maintenance': 'Grounds maintenance',
    'Soft services': 'Soft services',
    'Hard services': 'Hard services',
    'Pest control services': 'Pest control services',
    'Waste management': 'Waste management',
    'Asset Lifecycle Services': 'Asset Lifecycle Services',
    'There are no updates for this request': 'There are no updates for this request',
    'Transportation Services': 'Transportation Service',
    'Ref: you pronoun': '(you)',
    'Ref: SR raised for': 'Person affected by this request',
    'Ref: Select a location': 'Select a location',
    'Ref: Your request': 'Your request',
    'Ref: Summary': 'Summary',
    'Ref: site': 'Site',
  },
  Comment: {
    'Ref: Page title': 'Update Us',
    'Ref: please update us with details':
      'Do you want to share anything else with us? The more we know about your request the better we can support you.',
  },
  Confirm: {
    'Ref: Page title': 'Confirm your request',
    'Ref: next': 'Submit',
    summary: 'Summary',
    'Ref: Save as my preferred location': 'Save as my preferred location',
  },
  Describe: {
    'Ref: Page title': 'Describe your request',
    'Ref: Required fields: header': 'Please provide additional information',
    'Ref: Required fields':
      'The following information should be provided inside the details field:',
    'Ref: Required field: title': 'A title is required.',
    'Ref: Required field: description': 'A description is required.',
    'Ref: Required field: phone': 'A phone number is required.',
    'Ref: Required field: category': 'A category is required.',
    'Ref: Invalid field: category': 'Provided category is invalid.',
    'Ref: Request template field label': 'Popular requests',
    'Ref: Request template field placeholder': 'Blank sheet',
    your_request: 'Your request',
    'Ref: Description message':
      'The more information you can provide the better we can support you.',
    phone_number: 'Your phone number (please use your professional phone if you have one)',
    'Ref: Affected person': 'Person affected by this request',
    'Ref: No affected person results': 'No person matching requested criteria',
    'Ref: Notification title': 'You have changed location',
    'Ref: Notification info': 'You are now at {site name}',
    'Ref: Add photos': 'Add photos',
    'Ref: Your location': 'Your location',
  },
  Details: {
    'Ref: Page title': 'Request Details',
    Details: 'Details',
    Updates: 'Updates',
    'Update us': 'Update Us',
    'Ref: timeline': 'Timeline',
    'Ref: attachments': 'Attachments',
    'Ref: location': 'Location',
    'Ref: createdOn': 'Created On',
    'Ref: reference': 'Reference',
    'Feedback was submitted': 'Feedback sent',
    'Ref: sent': 'Sent',
    'Ref: received': 'Received',
    'Ref: inProgress': 'In progress',
    'Ref: completed': 'Completed',
    'Ref: conversation': 'Conversation',
    'Ref: Priority': 'Priority',
    'Ref: Target start date': 'Target start date',
    'Ref: Target completion date': 'Target completion date',
    'Ref: Priority fields placeholder message': 'It will be populated once validated by helpdesk',
  },
  Failed: {
    'Ref: Body':
      'An error occured and your service request was not created. If this error persists, please contact your helpdesk.',
    'Ref: Tabtitle': 'Service request failed to create',
  },
  Home: { 'Ref: Page title': 'Requests' },
  RequestFeedback: {
    'Ref: Page title': 'Feedback',
    'Ref: Feedback headline': 'Please share your feedback',
    'Ref: Feedback title': 'Feedback about SR #{ref}',
    'Ref: Question: general sentiment':
      'Overall, how satisfied are you with how we handled your service request?',
    'Ref: Question: answer quality': 'How would you rate the quality of the service you received?',
    'Ref: Question: staff interaction': 'How would you rate your interactions with our staff?',
    'Ref: Question: efficiency': 'How would you rate our efficiency?',
    'Ref: Question: open': 'Comment',
    'Ref: Open question placeholder': 'What did we do well? Where can we improve?',
    no_comment: 'No Comment',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Thanks for taking the time to leave a comment, your feedback is important to us and helps us improve your experience.',
  },
  Locate: {
    'Ref: Page title': 'Select a location',
    'Ref: Top bar title': 'Locate your request',
    'Ref: Select this location': 'Select this location',
    drill: 'browse',
    'Ref: Scanner button': 'Location with a QR? Scan it',
    'Ref: Scanner header': 'Please scan your location QR',
    'Sorry, this location could not be found.': 'Sorry, this location could not be found.',
    'Close scanner': 'Close scanner',
    'Ref: Switch site component intro sentence':
      'You are requesting service at this site {site name}.',
    'Ref: search location by keywords': 'Search a location',
    'Ref: Enter Location name': 'Enter location name',
    'Ref: Enter manual location': 'Enter location manually',
    'Ref: Location name': 'Location name',
    'Ref: No results': "We couldn't find your location. You may want to input it manually.",
    'Ref: Up one level': 'Up one level',
    'Ref: Navigate': 'Navigate',
    'Ref: Select': 'Select',
    'Ref: Selected Location': 'Selected Location',
    'Ref: Preferred Location': 'Preferred Location',
    'Ref: Building': 'Building',
    'Ref: Floor': 'Floor',
    'Ref: Aisle': 'Aisle',
    'Ref: Room': 'Room',
    'Ref: Notification title': 'You have changed location',
    'Ref: Notification info': 'You are now at {site name}',
    'Ref: Scanner error header': 'Scan error',
    'Ref: Scan QR Code': 'Scan QR Code',
    'Ref: Error body': "This QR code isn't a valid code",
    'Ref: Loading Locations Warning':
      'Please wait while we are preparing your experience for a first request at this site.',
    'Ref: Search Result is limited to:':
      'Search Result is limited to {limit}. Please refine your search criteria.',
    'Ref: Open the location search field': 'Open the location search field',
  },
  Success: {
    'Ref: Body':
      'We have received your request and are looking into it now. We will get back to you soon. It may take up to 20mins to see your service request in the app.',
    'Ref: Tabtitle': 'Service request created',
    'Ref: Download app title': 'Simplify your service requests',
    'Ref: Download app subtitle': 'Download our app and elevate your experience',
    'Ref: Download app button': 'Download the app',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'This is your workplace support hub',
    'Ref: Tutorial: tabname': 'Requests',
    'Ref: Tutorial: body': 'We are here to help you. Please log one request per entry.',
    "What's new: title": 'Find your location easily',
    "What's new: body":
      'Scan a QR code to find your exact location, or select from our suggestions.',
  },
  Widget: {
    'Ref: Service request': 'Service request',
    'Ref: Placeholder body': 'No service request for the moment',
    'Ref: New request button': 'New request',
  },
  QrLandingPage: {
    'Ref: Notification title': 'You have changed location',
    'Ref: Notification info': 'You are now at {site name}',
    'Ref: Welcome': 'Welcome! {wave_emoji}',
    'Ref: Create service request text': 'Noticed something you want to report?',
    'Create a service request': 'Create a service request',
    'Ref: Share feedback': 'Share a feedback',
    'Ref: Share text': 'Would like to share something with us?',
    'Go to home': 'Go to home',
    'Ref: Error body':
      'The QR code you scanned no longer works. Please try again later or contact support.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'An error has occured',
    'Ref: Error body':
      'The QR code you scanned no longer works. Please try again later or contact support.',
    'Go to home': 'Go to home',
  },
  ManualDescribe: {
    'Ref: Page title': 'Describe your request',
    'Ref: Required fields: header': 'Please provide additional information',
    'Ref: Required fields':
      'The following information should be provided inside the Details field:',
    'Ref: Required field: title': 'A summary is required.',
    'Ref: Required field: description': 'A description is required.',
    'Ref: Required field: category': 'A category is required.',
    'Ref: Required field: buildingText': 'A building is required.',
    'Ref: Invalid field: category': 'Provided category is invalid.',
    'Ref: Your request': 'Your request',
    'Ref: Description message': 'The more details you provide the better we can support you.',
    'Ref: Do you have a location QR code': 'Do you have a location QR code?',
    'Ref: Scan QR Code': 'Scan QR code',
    'Ref: Please select category': 'Please select category',
    'Ref: Please select site': 'Please select site',
    'Ref: building': 'Building',
    'Ref: room': 'Room',
    'Ref: Scan error': 'Scan error',
    'Ref: The QR code is not valid': 'The QR code is not valid',
    'Ref: Add location with QR Scanner': 'Add location with QR Scanner',
    'Ref: Description': 'Description',
    'Ref: Forbidden error': "We're sorry, this QR code does not match your registered location.",
    'Ref: Invalid error': "We're sorry, it appears this QR code is no longer valid.",
  },
};
export default labels;
