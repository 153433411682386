import { AccountIcon20 } from '../../../../../assets/icons';
import TitleBar from '../../../../../components/organisms/TitleBar';
import { LinkProps } from '../../../../../components/organisms/TitleBar/TitleBar.types';
import { pagePaths } from '../../../config';
import { useCoreTranslation } from '../../../hooks/useCoreTranslation';

const AccountWidget = ({ hide }: LinkProps) => {
  const { label } = useCoreTranslation(__filename);

  if (hide) return <></>;

  return (
    <TitleBar.Widget
      label={label}
      labelKey="Account"
      Icon={AccountIcon20}
      linkTo={pagePaths['Account']}
      iconOnTheLeft
    />
  );
};

export default AccountWidget;
