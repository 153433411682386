import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { IN_APP_BROWSER_FRAMELESS } from '../../../constants';
import useTimeAgo from '../../../helpers/hooks/useTimeAgo';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import { Alert } from '../Alert';

import { getServiceIcon, getUrl } from './Notification.helpers';
import { NotificationTileProps } from './Notification.types';

import { ClockIcon } from '@/assets/icons';
import { Tile } from '@/components/molecules/Tile';
import { Tag } from '@/components/molecules/Tile/Tile.types';
import { useCloseNotificationMutation } from '@/modules/Core/api/notifications/notificationsApi';
import { State } from '@/types/state.types';

import styles from './NotificationTile.module.css';

const NotificationTile = ({
  id,
  serviceId,
  title,
  message,
  notificationDate,
  contentId,
  surveyId,
  eventId,
  acknowledged,
  contentType,
  linkUrl,
  'data-testid': testId = 'notification-tile',
  'data-cy': testCy,
}: NotificationTileProps) => {
  const { label } = useCustomTranslation();
  const history = useHistory();
  const [isNotificationAlertOpen, setIsNotificationAlertOpen] = useState(false);
  const service = useSelector((state: State) =>
    state.Core.services.list.find((service) => service.id === serviceId)
  ) || { name: '' };

  const { text: timeAgo } = useTimeAgo(notificationDate, label);
  const [closeNotification] = useCloseNotificationMutation();

  const closeNotif = (notificationId: string) => {
    closeNotification({ notificationId });
  };

  const closeNotificationAlert = () => {
    setIsNotificationAlertOpen(false);
    if (id) {
      closeNotif(id);
    }
  };

  const isInMomentSurvey = contentType === 'Inmoment-Survey' && linkUrl;

  const onClick = () => {
    if (isInMomentSurvey) {
      if (id) {
        closeNotif(id);
      }
      InAppBrowser.create(linkUrl, '_blank', IN_APP_BROWSER_FRAMELESS);
      return;
    }

    const url = getUrl(service, eventId, surveyId, contentId);
    if (url) {
      history.push(url);
      if (id) {
        closeNotif(id);
      }
    } else {
      setIsNotificationAlertOpen(true);
    }
  };

  let stickers: Tag[] = [];
  if (isInMomentSurvey) {
    stickers = [{ name: label('Ref: Survey'), icon: getServiceIcon({ name: 'Survey' }) }];
  } else if (service?.name) {
    stickers = [{ name: service?.name, icon: getServiceIcon(service) }];
  }

  const tags = [
    {
      name: timeAgo,
      icon: <ClockIcon width={16} height={16} />,
    },
  ];

  return (
    <div className={styles.tileWrapper} data-testid={testId} data-cy={testCy}>
      <Tile
        className={classNames({
          [styles.highlighted]: !acknowledged,
        })}
        title={title}
        stickers={stickers}
        description={message}
        tags={tags}
        onClick={onClick}
      />
      <Alert
        isOpen={isNotificationAlertOpen}
        onDismiss={closeNotificationAlert}
        className="popup-warning"
        header={title}
        message={message}
        buttons={[label('Ref: ok')]}
        data-testid={`${testId}-alert`}
      />
    </div>
  );
};

export default NotificationTile;
