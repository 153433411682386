import classNames from 'classnames';
import React, { useRef } from 'react';

import { CheckboxCheckIcon } from '../../../assets/icons';
import useStateWithProps from '../../../helpers/hooks/useStateWithProps';
import { generateId } from '../../../helpers/utils';
import Title, { TITLE_SIZE } from '../Title';

import { CheckboxProps } from './Checkbox.types';

import styles from './Checkbox.module.css';

const Checkbox = ({
  id,
  name,
  value,
  checked = false,
  disabled = false,
  inputRef,
  inputLabel,
  onClick,
  onChange,
  'data-testid': testId,
  ...rest
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useStateWithProps<boolean>(checked);

  const localRef = useRef<HTMLInputElement | null>(null);
  const ref = inputRef || localRef;

  const checkboxId = id || `checkbox-${generateId()}`;

  const handleOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (ref) {
      ref.current?.focus();
    }

    const { currentTarget: { checked: eventChecked } = {} } = e;
    if (eventChecked !== undefined) setIsChecked(eventChecked);

    if (onClick) {
      onClick(e, { value, checked: eventChecked });
    }
  };

  const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, value);
    }
  };

  const checkbox = (
    <div
      className={classNames(styles.checkbox, {
        [styles.checked]: isChecked,
        [styles.disabled]: disabled,
      })}
      data-testid={`${testId}-container`}
    >
      <CheckboxCheckIcon className={classNames(styles.icon)} />
      <input
        {...rest}
        ref={ref}
        type="checkbox"
        id={checkboxId}
        name={name}
        value={value}
        disabled={disabled}
        checked={isChecked}
        aria-checked={isChecked}
        onClick={handleOnClick}
        onChange={handleOnChange}
        tabIndex={0}
        data-testid={`${testId}-input`}
      />
    </div>
  );

  return inputLabel ? (
    <label className={classNames(styles.checkboxWrapper)} data-testid={`${testId}-label-wrapper`}>
      {checkbox}
      <Title size={TITLE_SIZE.BODYSDEFAULT} data-testid={`${testId}-label-value`}>
        {inputLabel}
      </Title>
    </label>
  ) : (
    checkbox
  );
};

export default Checkbox;
