import { screen } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { Phone24Icon } from '../../../../../assets';
import renderComponent from '../../../../../helpers/tests/renderComponent';
import ContactItem from '../ContactItem';
import { ContactItemProps } from '../ContactItem.types';

describe('Contact Item', () => {
  const title = 'This is the contact title';
  const label = jest.fn();
  const items: ContactItemProps['items'] = [
    { external: false, to: 'to1', text: 'text1', labelKey: 'labelKey1', icon: Phone24Icon },
    { external: false, to: 'to2', text: 'text2', labelKey: 'labelKey2', icon: Phone24Icon },
  ];

  renderComponent(ContactItem, { label, title, items }, {}, createMemoryHistory());

  it('Should show contact information', () => {
    expect(screen.getByText(title)).toBeInTheDocument();
    expect(screen.getByText(items[0].text)).toBeInTheDocument();
    expect(screen.getByText(items[1].text)).toBeInTheDocument();
  });
});
