import classNames from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import Button, { BUTTON_LOOK } from '../../../components/atoms/Button';
import FormLine from '../../../components/atoms/FormLine/FormLine';
import Title, { TITLE_TAG, TITLE_SIZE } from '../../../components/atoms/Title';
import Card from '../../../components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '../../../components/molecules/Notification';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import { EMOJIS, INPUT_TYPE } from '../../../constants';
import useGeoCode from '../../../helpers/hooks/useGeoCode';
import useLoginStatus from '../../../helpers/hooks/useLoginStatus';
import useToggle from '../../../helpers/hooks/useToggle';
import { clearErrors } from '../actions';
import {
  useLazyGetIdentityContextQuery,
  useLazyCheckEmailExistsQuery,
} from '../api/account/accountApi';
import { useConvertGuestToRegisteredUsersMutation } from '../api/guestUser/guestUserApi';
import { useLoginMutation } from '../api/login/loginApi';
import { pagePaths } from '../config';
import { getForgottenUrl } from '../helpers/login.helper';
import { isSSOUser } from '../helpers/onboardingHelpers';
import { useCoreTranslation } from '../hooks/useCoreTranslation';
import useInvokeSsoRedirect from '../hooks/useInvokeSsoRedirect';
import useSite from '../hooks/useSite';
import { IUrlLocation } from '../types/guestRegistrationForm.types';

import { AccountInformationFormFields } from './Onboarding/AccountInformation/AccountInformation.types';

import { CoverIllustration } from '@/assets/illustrations';
import { EmailInput, PasswordInput, HookInput as Input } from '@/components/atoms/Input';
import Form from '@/components/molecules/Form';
import { requiredRule, trimFields } from '@/components/molecules/Form/helpers';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import { useSwitchSite } from '@/modules/Order/hooks/useSiteSwitch/useSwitchSite';
import { store } from '@/store/store';
import { State } from '@/types/state.types';

import styles from './GuestRegistrationForm.module.css';

const GuestRegistrationForm = () => {
  const contentRef = useRef(null);

  const history = useHistory();
  const invokeSSORedirect = useInvokeSsoRedirect();

  const site = useSite();
  const siteId = site?.id;

  const { user } = useLoginStatus();
  const currentLanguageCode = user?.preferredLanguage?.languageCode;

  const { currentGeoCode, currentRegionCode } = useGeoCode();

  const {
    locks: { getUserContext: getUserContextLocked },
  } = useSelector((state: State) => state.Core);

  const [email, setEmail] = useState<string>('');
  const [submitting, setSubmitting] = useState(false);

  const [shouldShowOtherFields, setShouldShowOtherFields] = useState(false);
  const [convertingGuestToRegisteredUserError, setConvertingGuestToRegisteredUserError] =
    useState(undefined);
  const dispatch = useDispatch();
  const [getIdentityContext] = useLazyGetIdentityContextQuery();
  const [convertGuestToRegisteredUsers, { isLoading }] = useConvertGuestToRegisteredUsersMutation();
  const [login, { isLoading: loggingInLocked }] = useLoginMutation();

  const { switchSite } = useSwitchSite();

  const { label } = useCoreTranslation(__filename);

  const {
    state: { from },
  }: IUrlLocation = useLocation();

  const [checkEmailExists] = useLazyCheckEmailExistsQuery();

  const formDefaultValues = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  };

  const {
    handleSubmit: handleSubmitRegistrationForm,
    setError,
    formState: { isValid },
    control,
    clearErrors: removeErrors,
    trigger,
    watch,
  } = useForm<AccountInformationFormFields>({
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const {
    state: loginError,
    toggleOn: showLoginError,
    toggleOff: dismissLoginError,
  } = useToggle(false);

  useEffect(() => {
    const subscription = watch((data) => {
      if (loginError) {
        dismissLoginError();
      }
      if (data.email && email !== data.email) {
        setEmail(data.email);
        setShouldShowOtherFields(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [dismissLoginError, email, loginError, watch]);

  const onSubmit: SubmitHandler<AccountInformationFormFields> = async (data) => {
    setSubmitting(true);
    const trimmedData = trimFields<AccountInformationFormFields>(data);

    const userIdentity = await getIdentityContext({ username: trimmedData.email });
    if (userIdentity.data && isSSOUser(userIdentity.data)) {
      await invokeSSORedirect(
        userIdentity.data.tokenUrl,
        trimmedData.email,
        currentGeoCode,
        currentRegionCode,
        userIdentity.data.useSystemBrowser,
        history
      );
      return;
    }

    if (displayRegistrationForm) {
      const emailExists = await checkEmailExists({
        email: trimmedData.email,
      });

      if (emailExists.isSuccess) {
        setError('email', {
          type: 'custom',
          message: label('Ref: Input error E-mail already exists'),
        });
        setSubmitting(false);
        return;
      }
    }

    // Display all fields
    if (!shouldShowOtherFields) {
      setShouldShowOtherFields(true);
      setSubmitting(false);
      return;
    }

    if (displayRegistrationForm && shouldShowOtherFields) {
      await handleRegistration(data);
    } else {
      await attemptLogin(data);
    }

    setSubmitting(false);
  };

  useEffect(() => {
    return () => {
      dispatch(clearErrors);
    };
  }, [dispatch]);

  useEffect(
    function onUsernameChange() {
      setShouldShowOtherFields(false);
    },
    [email]
  );

  const getHeadline = () => {
    if (displayRegistrationForm) {
      return label('Ref: Form title');
    }

    return label('Ref: Hello', {
      replace: { wave_emoji: String.fromCodePoint(EMOJIS.wave) },
    });
  };

  const { state: displayRegistrationForm, toggle: toggleRegistrationForm } = useToggle(true);

  const notification = loginError
    ? {
        look: NOTIFICATION_LOOK.ERROR,
        title: label('Ref: error, incorrect credentials'),
        dismissable: true,
        onDismiss: dismissLoginError,
      }
    : false;

  const isLocked = loggingInLocked || getUserContextLocked || isLoading;
  if (isLocked) return <LoadingPage />;

  const registerFields = (
    <>
      <FormLine data-testid="guest-registration-first-name">
        <Input
          control={control}
          name="firstName"
          inputLabel={label('First name')}
          data-cy="input-first-name"
          data-testid="guest-registration-first-name"
          rules={requiredRule(label('First name'), label)}
        />
      </FormLine>
      <FormLine data-testid="guest-registration-last-name">
        <Input
          control={control}
          name="lastName"
          inputLabel={label('Last name')}
          data-ci="input-last-name"
          data-testid="guest-registration-last-name"
          rules={requiredRule(label('Last name'), label)}
        />
      </FormLine>
      <FormLine data-testid="guest-registration-password">
        <PasswordInput
          control={control}
          name="password"
          inputLabel={label('Password')}
          labelFunc={label}
          data-cy="input-password"
          data-testid="guest-registration-new"
          autocomplete="new-password"
          rules={requiredRule(label('Password'), label)}
          onFocus={() => {
            removeErrors(['confirmPassword']);
          }}
        />
      </FormLine>
      <div>
        <Input
          control={control}
          name="confirmPassword"
          inputLabel={label('Confirm password')}
          inputType={INPUT_TYPE.PASSWORD}
          data-cy="input-password-confirm"
          data-testid="guest-registration-confirm-password"
          autocomplete="new-password"
          rules={requiredRule(label('Confirm password'), label)}
        />
      </div>
    </>
  );

  const emailField = (
    <FormLine data-testid="guest-registration-email">
      <EmailInput
        control={control}
        name="email"
        inputLabel={label('Email')}
        labelFunc={label}
        data-ci="input-email"
        data-testid="guest-registration"
        required
      />
    </FormLine>
  );

  const passwordField = (
    <>
      <Input
        control={control}
        name="password"
        inputLabel={label('Password', { textTransform: 'capitalize' })}
        title={label('Ref: Password field title')}
        autocomplete="current-password"
        data-cy="input-password"
        data-testid="guest-registration-current-password"
        id="password"
        inputType={INPUT_TYPE.PASSWORD}
        placeholder="********"
        required
        rules={requiredRule(label('Password'), label)}
      />
      <a href={getForgottenUrl(currentLanguageCode, email)}>
        <Title
          size={TITLE_SIZE.BODYSBOLD}
          className={classNames(styles.forgottenPassword, 'underlinedText')}
        >
          {label('Ref: Forgot password?')}
        </Title>
      </a>
    </>
  );

  const attemptLogin = async (data: AccountInformationFormFields) => {
    const response = (await login({
      geoCode: currentGeoCode,
      username: data.email,
      psw: data.password,
      currentLanguageCode,
      scope: 'offline_access',
    })) as any;
    if (response.data) {
      handleSuccessfulLogin();
    } else {
      showLoginError();
    }
  };

  const handleRegistration = async (data: AccountInformationFormFields) => {
    setConvertingGuestToRegisteredUserError(undefined);
    const response = (await convertGuestToRegisteredUsers({
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      contractId: user?.contract?.id,
      siteId,
    })) as any;

    if (!response.error) {
      await login({
        geoCode: currentGeoCode,
        username: data.email,
        psw: data.password,
        currentLanguageCode,
        scope: 'offline_access',
      });
      history.push({
        pathname: pagePaths.GuestRegistrationSuccess,
        state: { from },
      });
    } else {
      setConvertingGuestToRegisteredUserError(response.error);
    }
  };

  const handleSuccessfulLogin = () => {
    const state = store.getState() as State;

    if (state.Core?.context?.siteHasChanged && siteId) {
      switchSite(siteId);
    }

    history.push(from.pathname);
  };

  const showSections = () => {
    if (displayRegistrationForm && shouldShowOtherFields) {
      return (
        <>
          {emailField}
          {registerFields}
        </>
      );
    } else if (!displayRegistrationForm && shouldShowOtherFields) {
      return (
        <>
          {emailField}
          {passwordField}
        </>
      );
    } else {
      return <>{emailField}</>;
    }
  };

  const primaryButtonLabel = shouldShowOtherFields
    ? displayRegistrationForm
      ? 'Sign up'
      : 'Log in'
    : 'Continue';

  const handleSecondaryButtonClick = () => {
    toggleRegistrationForm();
    removeErrors();
    trigger('email');

    if (loginError) {
      dismissLoginError();
    }
  };

  return (
    <SimpleFormPage
      title={label('Register')}
      hasBackLink={true}
      actions={[]}
      withNavBar={false}
      actionsBarTopContent={null}
      contentRef={contentRef}
    >
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES} className={'mb-L'}>
              {getHeadline()}
            </Title>

            {notification && <Notification inheritStyle={classNames('mb-M')} {...notification} />}

            {displayRegistrationForm && convertingGuestToRegisteredUserError && (
              <Notification
                look={NOTIFICATION_LOOK.ERROR}
                title={convertingGuestToRegisteredUserError}
                inheritStyle={classNames(styles.errorMessage, 'mb-M')}
              />
            )}
            <Card>
              <Form onSubmit={handleSubmitRegistrationForm(onSubmit)}>{showSections()}</Form>
            </Card>
            <ActionsBar>
              <Button
                data-testid="primarybutton"
                data-cy="button-action-primary"
                look={BUTTON_LOOK.PRIMARY}
                onClick={handleSubmitRegistrationForm(onSubmit)}
                disabled={!isValid}
                loading={submitting}
                isClickDisabled={true}
              >
                {label(primaryButtonLabel)}
              </Button>
              <Button
                data-testid="secondarybutton"
                onClick={handleSecondaryButtonClick}
                look={displayRegistrationForm ? BUTTON_LOOK.TERTIARY : BUTTON_LOOK.SECONDARY}
              >
                {displayRegistrationForm
                  ? label('Already have an account? Login')
                  : label('Register')}
              </Button>
            </ActionsBar>
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <CoverIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};

export default GuestRegistrationForm;
