import { BarcodeScanner, ScanResult } from '@capacitor-community/barcode-scanner';
import { Camera } from '@capacitor/camera'; // TODO issue https://github.com/capacitor-community/barcode-scanner/issues/66

import { Reader } from '../reader.types';

const ELEMENT_ID_TO_HIDE = 'Page';

class NativeReader implements Reader {
  bodyBgColor = document.getElementsByTagName('body')[0].style.background;

  async checkPermission() {
    const { camera: permission } = await Camera.requestPermissions({ permissions: ['camera'] });
    return permission === 'granted';
  }

  async startScan() {
    const status = await this.checkPermission();

    if (!status) {
      throw new Error('Permission denied');
    }
    await BarcodeScanner.hideBackground();

    document.getElementsByTagName('body')[0].style.background = 'transparent';
    document.getElementById(ELEMENT_ID_TO_HIDE)!.style.display = 'none';

    const result = await BarcodeScanner.startScan();

    if (result.hasContent) {
      this.restoreStyles();
      return result.content;
    }
  }

  changeDisplay(display: string) {
    const elementToHide = document.getElementById(ELEMENT_ID_TO_HIDE);
    if (elementToHide) {
      elementToHide.style.display = display;
    }
  }

  async startScanning(callback: (result: ScanResult, err?: any) => void) {
    const status = await this.checkPermission();

    if (!status) {
      throw new Error('Permission denied');
    }
    await BarcodeScanner.hideBackground();

    document.getElementsByTagName('body')[0].style.background = 'transparent';
    this.changeDisplay('none');

    await BarcodeScanner.startScanning(undefined, callback);
  }

  restoreStyles() {
    document.getElementsByTagName('body')[0].style.background = this.bodyBgColor;
    this.changeDisplay('flex');
  }

  async stopScan() {
    this.restoreStyles();
    await BarcodeScanner.stopScan();
  }
}

export default new NativeReader();
