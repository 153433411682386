import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { ValidationInvalid, ValidationDefault, ValidationValid } from '../../../../assets/icons';
import { TranslationProps } from '../../../../localization/localization.types';
import { TestingPropsV2 } from '../../../../types';

import styles from './PasswordValidation.module.css';

type PasswordValidationProps = {
  rules: Record<string, RegExp>;
  value: string;
  triggerValidation: boolean;
} & TranslationProps &
  TestingPropsV2;

const PasswordValidation = React.memo(
  ({
    rules,
    value,
    label,
    triggerValidation,
    'data-testid': dataTestId,
  }: PasswordValidationProps) => {
    const [errors, setError] = useState<string[]>([]);

    useEffect(() => {
      const errors: string[] = [];
      if (triggerValidation) {
        Object.entries(rules).forEach((rule) => {
          const [name, pattern] = rule;
          if (!pattern.test(value) || value === undefined) {
            errors.push(name);
          }
        });
      }
      setError(errors);
    }, [value, rules, triggerValidation]);

    const getValidationIcon = (name: string) =>
      errors.includes(name) ? ValidationInvalid : ValidationValid;

    return (
      <div data-testid={`${dataTestId}-wrapper`} className={classNames(styles.wrapper)}>
        <span className={classNames({ [styles.error]: errors.length > 0 && triggerValidation })}>
          {label('Password must contain')}
        </span>
        <ul data-testid={`${dataTestId}-rules-list`} className={styles.list}>
          {Object.entries(rules).map((rule) => {
            const [name] = rule;
            const Icon = triggerValidation ? getValidationIcon(name) : ValidationDefault;

            return (
              <li
                data-testid={`${dataTestId}-rule`}
                key={name}
                className={classNames(styles.rule, {
                  [styles.error]: errors.includes(name),
                  [styles.success]: !errors.includes(name) && triggerValidation,
                })}
              >
                <Icon data-testid={`${dataTestId}-rule-icon`} className={styles.icon} />
                <span data-testid={`${dataTestId}-rule-text`}>
                  {label(`Ref: Password validation ${name}`)}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);

export default PasswordValidation;
