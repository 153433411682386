import { DefaultComponentWithoutChildrenProps } from '../../../types';

export type TimePickerProps = {
  id: string;
  value?: string;
  minTime?: string;
  onChange: (time: string) => void;
  currentLanguageCode: string;
} & DefaultComponentWithoutChildrenProps;

export enum ChangeDirection {
  Increment,
  Decrement,
}
