import { useHistory } from 'react-router';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import { openUrl } from '../../../../helpers/misc';
import { useAccomodationTranslation } from '../../hooks/useAccomodationTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';
import { useSetupOption } from '@/helpers/hooks/useSetupOption/useSetupOption';
import { SERVICE } from '@/modules/config';

const StaysSuccess = () => {
  const history = useHistory();
  const { label } = useAccomodationTranslation(__filename);
  const stayFeedbackSurveyOption = useSetupOption('stayFeedbackSurveyUrl', SERVICE.STAY);

  const actions = [
    {
      label: label('Ref: Go to home'),
      action: () => history.push('/'),
    },
  ];
  if (stayFeedbackSurveyOption.isActive && stayFeedbackSurveyOption.value) {
    actions.push({
      label: label('Ref: Provide a feedback'),
      action: () => openUrl(stayFeedbackSurveyOption.value!),
    });
  }

  return (
    <NoticePage
      id="stays-success"
      type={NOTICEPAGE_TYPE.SUCCESS}
      title={label('Ref: Title')}
      content={label('Ref: Body')}
      actions={actions}
    />
  );
};

export default StaysSuccess;
