import { useHistory } from 'react-router';

import NoticePage from '../../../../../components/templates/NoticePage/NoticePage';
import { pagePaths } from '../../../config';
import { useCoreTranslation } from '../../../hooks/useCoreTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const ResetPasswordSuccess = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);

  return (
    <NoticePage
      hideAllWidgets
      type={NOTICEPAGE_TYPE.SUCCESS}
      withNavBar={false}
      title={label('Ref: Password changed')}
      actions={[
        {
          label: label('Go to login page'),
          action: () => history.push(pagePaths.Home),
        },
      ]}
    />
  );
};

export default ResetPasswordSuccess;
