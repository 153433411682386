import classNames from 'classnames';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { pagePaths as corePagePaths } from '../../../Core/config';
import { feedbackTypes, sentimentToFeedback, sentimentTypes } from '../../../Feedback/config';
import {
  useFeedbackMutation,
  useGetServiceRequestQuery,
  useGetServiceRequestsQuery,
} from '../../api/index';
import { pagePaths, longAnswerMaxLength } from '../../config';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';
import SentimentRating from '../SentimentRating/SentimentRating';

import { ManSit2Illustration } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import Textarea from '@/components/atoms/Textarea/Textarea';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import useToggle from '@/helpers/hooks/useToggle';
import { FeedbackServiceRequestParams } from '@/modules/ServiceRequest/api/api.types';
import {
  Answers,
  FormValues,
  QuestionsInfo,
} from '@/modules/ServiceRequest/components/RequestFeedback/RequestFeedback.types';
import { SentimentRatingType, ServiceRequestProps } from '@/modules/ServiceRequest/types/types';
import { State } from '@/types/state.types';

import styles from './RequestFeedback.module.css';

const RequestFeedback = () => {
  const { label } = useServiceRequestTranslation(__filename);
  const history = useHistory();
  const [feedback] = useFeedbackMutation();
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  let { id: currentServiceRequestId } = useParams<{ id: string }>();

  const [currentServiceRequest, setCurrentServiceRequest] = useState<ServiceRequestProps>();

  const currentUser = useSelector((state: State) => state.Core?.user);
  const isLocallyCreatedSR = currentServiceRequestId.match(/LOCAL-/) !== null;
  const { data: serviceRequest } = useGetServiceRequestQuery(
    {
      id: currentServiceRequestId,
      emailId: currentUser?.email,
    },
    {
      skip: isLocallyCreatedSR,
    }
  );
  const { data: serviceRequests } = useGetServiceRequestsQuery({}, { skip: !isLocallyCreatedSR });

  useEffect(() => {
    if (serviceRequest) {
      setCurrentServiceRequest(serviceRequest);
    }

    if (serviceRequests) {
      const request = serviceRequests.find((r) => r.id === currentServiceRequestId);
      if (request) setCurrentServiceRequest(request);
    }
  }, [serviceRequest, serviceRequests, currentServiceRequestId]);
  const contentRef = useRef(null);
  const {
    state: isFormValid,
    toggleOn: setIsFormValidTrue,
    toggleOff: setIsFormValidFalse,
  } = useToggle(false);
  const {
    state: areOptionalQuestionsDisplayed,
    toggleOn: setAreOptionalQuestionsDisplayedTrue,
    toggleOff: setAreOptionalQuestionsDisplayedFalse,
  } = useToggle(false);
  const {
    state: submitting,
    toggleOn: setSubmittingTrue,
    toggleOff: setSubmittingFalse,
  } = useToggle(false);
  const [formValues, setFormValues] = useState<FormValues>({
    generalSentiment: undefined,
    staffInteractionRating: undefined,
    answerQualityRating: undefined,
    efficiencyRating: undefined,
    comment: '',
  });

  const handleChange = (id: string, value: number) => {
    let key = id;
    let newValue: number | SentimentRatingType = value;

    if (id.indexOf('generalSentiment') > -1) key = 'generalSentiment';
    if (id.indexOf('staffInteractionRating') > -1) key = 'staffInteractionRating';
    if (id.indexOf('answerQualityRating') > -1) key = 'answerQualityRating';
    if (id.indexOf('efficiencyRating') > -1) key = 'efficiencyRating';

    if (id.indexOf('generalSentiment') > -1 || id.indexOf('Rating') > -1) {
      newValue = sentimentTypes[newValue - 1];
    }
    if (id.indexOf('comment') > -1) key = 'comment';

    const newFormValues = {
      ...formValues,
      [key]: newValue,
    };

    setFormValues(newFormValues);

    //validate form
    if (newFormValues.generalSentiment !== undefined) {
      setIsFormValidTrue();
    } else {
      setIsFormValidFalse();
    }

    // show/hide optional questions
    if (newFormValues.generalSentiment !== undefined) {
      setAreOptionalQuestionsDisplayedTrue();
    } else {
      setAreOptionalQuestionsDisplayedFalse();
    }
  };

  const handleComment = (event: Event) => {
    const comment = (event.target as HTMLTextAreaElement)?.value;
    setFormValues((state) => ({ ...state, comment }));
  };

  if (!currentServiceRequest) {
    return <LoadingPage />;
  }

  let answers: Answers = {
    generalSentiment: formValues.generalSentiment ?? sentimentTypes[1],
    comment: label('no_comment'),
  };

  if (areOptionalQuestionsDisplayed) {
    answers = {
      ...answers,
      staffInteractionRating: formValues.staffInteractionRating,
      answerQualityRating: formValues.answerQualityRating,
      efficiencyRating: formValues.efficiencyRating,
      comment: formValues.comment || label('no_comment'),
    };
  }

  const submitFeedback = async () => {
    if (!isFormValid) {
      return;
    }

    const submitActionArgs: FeedbackServiceRequestParams = {
      siteId,
      serviceRequestId: currentServiceRequestId,
      title: label('Ref: Feedback title', {
        replace: { ref: currentServiceRequest.referenceNumber },
      }),
      ...answers,
    };

    if (submitActionArgs.generalSentiment?.id) {
      const generalSentimentId = submitActionArgs.generalSentiment.id;
      const convertedToFeedback = sentimentToFeedback[generalSentimentId];
      submitActionArgs.feedbackType = {
        id: feedbackTypes[convertedToFeedback].toString(),
        name: convertedToFeedback,
      };
    }

    setSubmittingTrue();
    const feedbackResponse = await feedback(submitActionArgs);
    setSubmittingFalse();

    if ('data' in feedbackResponse) {
      return history.replace(pagePaths['FeedbackSuccess'].replace(':id', currentServiceRequestId));
    }
    return history.push(corePagePaths['GenericFailurePage']);
  };

  const ratingQuestionsInfo: QuestionsInfo = {
    generalSentiment: {
      groupName: 'generalSentiment',
      question: label('Ref: Question: general sentiment'),
      value: formValues.generalSentiment,
      required: true,
    },
    answerQualityRating: {
      groupName: 'answerQualityRating',
      question: label('Ref: Question: answer quality'),
      value: formValues.answerQualityRating,
    },
    staffInteractionRating: {
      groupName: 'staffInteractionRating',
      question: label('Ref: Question: staff interaction'),
      value: formValues.staffInteractionRating,
    },
    efficiencyRating: {
      groupName: 'efficiencyRating',
      question: label('Ref: Question: efficiency'),
      value: formValues.efficiencyRating,
    },
  };

  const optionalQuestions = areOptionalQuestionsDisplayed ? (
    <React.Fragment>
      <Card className={classNames('mb-M')}>
        <SentimentRating
          {...ratingQuestionsInfo.answerQualityRating}
          data-testid={'sentimentRating-answerQualityRating-input'}
          onChange={(value) => handleChange('answerQualityRating', value)}
        />
      </Card>
      <Card className={classNames('mb-M')}>
        <SentimentRating
          {...ratingQuestionsInfo.efficiencyRating}
          data-testid={'sentimentRating-efficiencyRating-input'}
          onChange={(value) => handleChange('efficiencyRating', value)}
        />
      </Card>
      <Card className={classNames('mb-M')}>
        <SentimentRating
          {...ratingQuestionsInfo.staffInteractionRating}
          data-testid={'sentimentRating-staffInteractionRating-input'}
          onChange={(value) => handleChange('staffInteractionRating', value)}
        />
      </Card>
      <Card className={classNames(styles.cardContainer)}>
        <Textarea
          id="Feedback_comment"
          label={label('Ref: Question: open')}
          rows={3}
          onChange={handleComment}
          value={formValues.comment}
          placeholder={label('Ref: Open question placeholder')}
          maxlength={longAnswerMaxLength}
        />
      </Card>
    </React.Fragment>
  ) : (
    ''
  );

  return (
    <SimpleFormPage
      title={label('Ref: Page title')}
      centerVertically={true}
      contentRef={contentRef}
    >
      <Container>
        <Column.Main>
          <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES} className={classNames('mb-M')}>
            {label('Ref: Feedback headline')}
          </Title>
          <Card className={classNames(styles.cardContainer, 'mb-M')}>
            <SentimentRating
              {...ratingQuestionsInfo.generalSentiment}
              data-testid={'sentimentRating-generalSentiment-input'}
              onChange={(value) => handleChange('generalSentiment', value)}
            />
          </Card>
          {optionalQuestions}
        </Column.Main>
        <Column.Complementary>
          <ManSit2Illustration />
        </Column.Complementary>
        <ActionsBar>
          <Button
            data-testid={'submit-feedback-button'}
            loading={submitting}
            onClick={submitFeedback}
            disabled={!isFormValid}
          >
            {label('submit', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default RequestFeedback;
