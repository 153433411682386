const labels = {
  Success: {
    'Ref: Body':
      'Ačiū už užpildydami šią apklausą, jūsų laikas yra vertinga, nes šie tyrimai padės pagerinti mūsų paslaugas.',
    'Ref: Tabtitle': 'Apklausa baigta',
    'Take another survey': 'Atlikite kitą apklausą',
  },
  Survey: {
    'Ref: Page title': 'Apklausos informacija',
    'Please answer all mandatory questions as identify by an asterisk':
      'Prašome atsakyti į visus privalomus klausimus, kaip nustatykite žvaigždute: *',
    'Ref: Take another survey': 'Atlikite kitą apklausą',
    'Ref: Go to Home': 'Eiti namo',
  },
  SurveysList: {
    'Ref: Page title': 'apklausa',
    'Ref: Available until': 'Galimas iki',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Šiuo metu jūs žiūrite svetainės {site name} apklausas.',
  },
};
export default labels;
