import {
  BowlIllustration,
  BreakfastIllustration,
  BurgerIllustration,
  BurritoIllustration,
  CaramelIllustration,
  ChickenIllustration,
  ChocolateIllustration,
  CoffeeIllustration,
  CokeIllustration,
  DishIllustration,
  DrinkIllustration,
  EggIllustration,
  FriesIllustration,
  FruitsIllustration,
  KebabIllustration,
  NoodleIllustration,
  OtherIllustration,
  PastaIllustration,
  PizzaIllustration,
  QuesadillaIllustration,
  SaladIllustration,
  SandwishIllustration,
  SnacksIllustration,
  SoupIllustration,
  TeaIllustration,
  TurkeyIllustration,
  VegetableIllustration,
  WrapIllustration,
} from '../../../../assets/illustrations';
import { SectionType } from '../../../../components/organisms/TilesList/TilesList.types';
import { Menu } from '../../api/menuApi.types';
import { selectedProductListFiltersCacheKey } from '../../config';
import {
  ProductCategory,
  ProductListConfig,
  ProductListFiltering,
  ProductListItem,
} from '../../types/productList.types';

import { TestingPropsV2 } from 'src/types';

export const mapToListModel = ({
  menu,
  selectedMoment,
}: {
  menu: Menu;
  selectedMoment: string | undefined;
}): ProductListItem[] => {
  const menuListResult: ProductListItem[] = [];

  for (const menuItem of menu?.menuItems) {
    if (selectedMoment && menuItem?.mealName?.toLowerCase() !== selectedMoment.toLowerCase()) {
      continue;
    }

    menuListResult.push({
      id: `${menu.id}_${menuItem.menuItemId}`,
      menuItemId: menuItem.menuItemId,
      title: menuItem.name,
      description: menuItem.description,
      price: menuItem.priceWithTax,
      category: menuItem.category ?? '',
      subCategory: menuItem.subCategory,
      moment: menuItem.mealName ?? '',
      genericCategory: menuItem?.genericCategory ?? '',
    });
  }

  return menuListResult;
};

export const buildSections = (menuItems: ProductListItem[]) => {
  return menuItems.reduce<SectionType<ProductListItem & TestingPropsV2>[]>(
    (sectionResult, menuItem) => {
      if (sectionResult.find((x) => x.title === menuItem.category)) return sectionResult;
      return [
        ...sectionResult,
        {
          title: menuItem.category,
          key: menuItem.category as string,
          filter: (items) => items.filter((item) => item.category === menuItem.category),
          'data-testid': `section-${menuItem.category}`,
          sections: buildSubSections(
            menuItems.filter((item) => item.category === menuItem.category && item.subCategory),
            menuItem.category
          ),
        },
      ];
    },
    []
  );
};

const buildSubSections = (sectionItems: ProductListItem[], section: string) => {
  return sectionItems.reduce<SectionType<ProductListItem & TestingPropsV2>[]>(
    (subsectionResult, menuItem) => {
      if (subsectionResult.find((x) => x.title === menuItem.subCategory)) return subsectionResult;
      return [
        ...subsectionResult,
        {
          title: menuItem.subCategory as string,
          key: menuItem.subCategory as string,
          'data-testid': `section-${menuItem.subCategory}`,
          filter: (items) =>
            items.filter(
              (item) => item.category === section && item.subCategory === menuItem.subCategory
            ),
        },
      ];
    },
    []
  );
};

export const buildConfig = (numOfSections: number): ProductListConfig => {
  const config: ProductListConfig = {
    displayedItemsNum: 0,
  };
  if (numOfSections) {
    config.collapsibleSections = false;
    config.singleExpandedSection = true;
    config.disableInfiniteScroll = true;
  }

  return config;
};

const getCacheKey = (siteId: string, menu: string, date: string) =>
  `${selectedProductListFiltersCacheKey}_${siteId}_${menu}_${date}`;

export const cacheSelectedFilters = (
  filters: ProductListFiltering,
  siteId: string,
  menu: string,
  date: string
) => localStorage.setItem(getCacheKey(siteId, menu, date), JSON.stringify(filters));

export const getDefaultFiltersFromCache = (siteId: string, menu: string, date: string) => {
  const defaultFiltersCache = localStorage.getItem(getCacheKey(siteId, menu, date));
  const defaultFilters = defaultFiltersCache
    ? (JSON.parse(defaultFiltersCache) as ProductListFiltering)
    : null;

  return defaultFilters;
};

export const buildFilteringState = ({
  moments,
  defaultFilters,
}: {
  moments: string[];
  defaultFilters?: ProductListFiltering;
}) => {
  const momentFilter = {
    filter_moment: moments?.length ? { [moments[0]]: true } : {},
  };

  return defaultFilters ? defaultFilters : { ...momentFilter };
};

export const getProductIllustration = (category: string): JSX.Element => {
  switch (category as ProductCategory) {
    case 'Coffee':
      return <CoffeeIllustration />;
    case 'Burger':
      return <BurgerIllustration />;
    case 'Pizza':
      return <PizzaIllustration />;
    case 'Kebab':
      return <KebabIllustration />;
    case 'Pasta':
      return <PastaIllustration />;
    case 'Sandwich':
      return <SandwishIllustration />;
    case 'Noodle':
      return <NoodleIllustration />;
    case 'Soup':
      return <SoupIllustration />;
    case 'Drink':
      return <DrinkIllustration />;
    case 'Vegetable':
      return <VegetableIllustration />;
    case 'Fruit':
      return <FruitsIllustration />;
    case 'Salad':
      return <SaladIllustration />;
    case 'Snacks':
      return <SnacksIllustration />;
    case 'Chicken':
      return <ChickenIllustration />;
    case 'Breakfast':
      return <BreakfastIllustration />;
    case 'Fries':
      return <FriesIllustration />;
    case 'Wrap':
      return <WrapIllustration />;
    case 'Bowl':
      return <BowlIllustration />;
    case 'Chocolate':
      return <ChocolateIllustration />;
    case 'Quesadilla':
      return <QuesadillaIllustration />;
    case 'Caramel':
      return <CaramelIllustration />;
    case 'Eggs':
      return <EggIllustration />;
    case 'Turkey':
      return <TurkeyIllustration />;
    case 'Burrito':
      return <BurritoIllustration />;
    case 'Tea':
      return <TeaIllustration />;
    case 'Dish':
      return <DishIllustration />;
    case 'Coke':
      return <CokeIllustration />;
    default:
      return <OtherIllustration />;
  }
};
