const labels = {
  SiteDetails: {
    'Select site': '이 사이트를 선택하십시오',
    'Ref: Page title': '사이트 세부 사항',
    'Ref: Skip map': '주요 콘텐츠로 건너 뜁니다',
    'Ref: Unavailable site': '사용자에게는 사이트 미리보기를 사용할 수 없습니다',
    'Ref: Sorry, no description yet': '죄송합니다. 아직 설명이 없습니다.',
  },
  SitesList: {
    'Ref: Site coordinates not found': '사이트 좌표를 찾을 수 없습니다',
    'Ref: Page title': '사이트',
    'Recently visited': '최근 방문했다',
    'All other sites': '다른 모든 사이트',
    'Sites in your country': '귀하의 나라의 사이트',
    'Search results': '검색 결과',
    'Select site': '사이트를 선택하십시오',
    'Ref: Select site with QR Scanner': 'QR 스캐너로 사이트를 선택하십시오',
    'Ref: Select site with code': '코드로 사이트를 선택하십시오',
    'Ref: Validate': '확인',
    'Ref: Enter site code': '사이트 코드를 입력하십시오',
    'Ref: Scanner error header': '사이트를 찾을 수 없습니다',
    'Ref: Generic error': '뭔가 잘못되었습니다',
    'Ref: Change site': '사이트 변경 사이트',
    'Ref: Change site description':
      '카트 안에 품목이 있습니다! 사이트를 변경하면 카트가 제거됩니다.',
    'Ref: Cancel': '취소',
    'Ref: Not found': '이 QR 코드는 인식되지 않았습니다',
    'Ref: Unauthorized error': '사이트 제한 수준으로 인한 무단 요청',
    'Ref: Forbidden':
      '죄송합니다.이 사이트에 액세스 할 수있는 적절한 권한이 없습니다. 액세스를 주선하려면 1800 992 777의 Pilbara 서비스 센터에 문의하십시오.',
    "Ref: We didn't find the site.": '우리는 사이트를 찾지 못했습니다.',
    'Ref: We recommend some solutions:': '몇 가지 솔루션을 권장합니다.',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. QR 스캐너 또는 아래 버튼의 도움으로 사이트를 추가하십시오.',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. 올바른 국가를 선택하여 로그 아웃하고 로그인하십시오.',
    'Ref: The currently selected country is:': '현재 선택된 국가는 다음과 같습니다.',
  },
  SwitchSite: { 'Not on {site name}?': '{site name}에 없습니까?' },
  SiteTile: { Details: '세부', Select: '고르다' },
};
export default labels;
