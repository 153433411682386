import { screen, cleanup, act, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import AccommodationRequest from '../AccommodationRequest';

import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import renderComponent from '@/helpers/tests/renderComponent';

const mock = [
  {
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37e',
    referenceNumber: '#0612156',
    title: 'Five Start Stay at Koksijde Ground Base',
    status: 'In Progress',
    arrivalDateTime: '2023-12-31T10:25:35Z',
    siteName: 'FMG Hamilton site',
    messages: [
      {
        description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
        createdBy: 'Michel Hofman',
        isCreatedByUser: true,
        createdOn: '2023-08-31T10:25:35Z',
      },
      {
        description: 'Aye Aye, General! The Base is at your service!',
        createdBy: 'Sodexo',
        isCreatedByUser: false,
        createdOn: '2023-09-01T10:25:35Z',
      },
    ],
  },
  {
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37d',
    referenceNumber: '#0612155',
    title: 'Five Start Stay at Koksijde Water Base',
    status: 'Pending',
    arrivalDateTime: '2023-12-15T10:25:35Z',
    siteName: 'FMG Hamilton site',
    messages: [
      {
        description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
        createdBy: 'Michel Hofman',
        isCreatedByUser: true,
        createdOn: '2023-08-15T10:25:35Z',
      },
      {
        description: 'Aye Aye, General! The Base is at your service!',
        createdBy: 'Sodexo',
        isCreatedByUser: false,
        createdOn: '2023-09-01T10:25:35Z',
      },
    ],
  },
  {
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37c',
    referenceNumber: '#0612154',
    title: 'Five Start Stay at Koksijde Air Base',
    status: 'Completed',
    arrivalDateTime: '2023-12-10T10:25:35Z',
    siteName: 'FMG Hamilton site',
    messages: [
      {
        description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
        createdBy: 'Michel Hofman',
        isCreatedByUser: true,
        createdOn: '2023-08-31T10:25:35Z',
      },
      {
        description: 'Aye Aye, General! The Base is at your service! Test3',
        createdBy: 'Sodexo',
        isCreatedByUser: false,
        createdOn: '2023-09-01T10:25:35Z',
      },
    ],
  },
  {
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37b',
    referenceNumber: '#0612153',
    title: 'Five Start Stay at Koksijde Test Base',
    status: 'Refused',
    arrivalDateTime: '2023-12-03T10:25:35Z',
    siteName: 'El Dorado village',
    messages: [
      {
        description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
        createdBy: 'Michel Hofman',
        isCreatedByUser: true,
        createdOn: '2023-08-31T10:25:35Z',
      },
      {
        description: 'Aye Aye, General! The Base is at your service! Test2',
        createdBy: 'Sodexo',
        isCreatedByUser: false,
        createdOn: '2023-09-01T10:25:35Z',
      },
    ],
  },
  {
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37a',
    referenceNumber: '#0612152',
    title: 'Five Start Stay at Koksijde Missle Base',
    status: 'Completed',
    arrivalDateTime: '2023-11-29T10:25:35Z',
    siteName: 'FMG Hamilton site',
    messages: [
      {
        description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
        createdBy: 'Michel Hofman',
        isCreatedByUser: true,
        createdOn: '2023-08-31T10:25:35Z',
      },
      {
        description: 'Aye Aye, General! The Base is at your service! Test1',
        createdBy: 'Sodexo',
        isCreatedByUser: false,
        createdOn: '2023-09-01T10:25:35Z',
      },
    ],
  },
];

jest.mock('react-hook-form', () => ({
  ...jest.requireActual('react-hook-form'),
  useFormContext: () => ({
    reset: () => jest.fn(),
  }),
}));

jest.mock('@/modules/AccommodationRequest/api', () => ({
  useGetAccommodationRequestListQuery: () => ({
    data: mock,
  }),
}));

jest.mock('@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled', () => ({
  useIsSetupOptionEnabled: jest.fn(),
}));

describe('AccommodationRequest', () => {
  describe('when rendering', () => {
    beforeEach(() => {
      (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(false);
    });

    renderComponent(AccommodationRequest);

    beforeAll(() => {});

    it('should render Accommodation list item data', async () => {
      expect(
        screen.getByTestId(
          'accommodation-request-home-page-search-bar-list-page-filters-and-search-container'
        )
      ).toBeTruthy();
    });

    it('should have the correct number of tiles listed', () => {
      const tiles = screen.getAllByTestId(/AccommodationRequestTile-.+-actions-0-content/i);
      expect(tiles.length).toEqual(5);
    });

    it('should display the correct content', () => {
      expect(
        screen.getByTestId('AccommodationRequestTile-b1fd459c-39e1-4f6d-bdd9-ceff0252e37c')
      ).toBeTruthy();
      expect(
        screen.getByTestId('AccommodationRequestTile-b1fd459c-39e1-4f6d-bdd9-ceff0252e37b')
      ).toBeTruthy();
      expect(screen.getByText('Refused')).toBeTruthy();
    });

    it('should have all 4 filter buttons and search input', () => {
      expect(screen.getByText(/Coming arrivals/i)).toBeTruthy();
      expect(screen.getByText(/Past arrivals/i)).toBeTruthy();
    });

    it('should display status filter', () => {
      expect(screen.getByText('All')).toBeTruthy();
    });

    it('should display correct title', () => {
      const titleBar = screen.getByTestId('titlebar-navigation');
      expect(titleBar.innerHTML).toContain('Accommodation Request');
    });
  });

  describe('with enable guest request setupOption enabled', () => {
    beforeEach(() => {
      (useIsSetupOptionEnabled as jest.Mock).mockReturnValue(true);
    });

    renderComponent(AccommodationRequest);

    it('should display correct title', () => {
      const titleBar = screen.getByTestId('titlebar-navigation');
      expect(titleBar.innerHTML).toContain('Guest Request');
    });
  });

  afterAll(() => cleanup());

  describe('on primary button click', () => {
    let button: HTMLElement;
    const history = createMemoryHistory();

    renderComponent(AccommodationRequest, {}, undefined, history);

    beforeAll(() => {});

    afterAll(() => cleanup());

    beforeEach(async () => {
      button = screen.getByText('New request');

      await act(async () => {
        fireEvent.click(button);
      });
    });

    it('should redirect to new request creation', async () => {
      expect(history.location.pathname).toBe('/accommodation_request/create-form');
    });
  });

  describe('on Accommodation tile click', () => {
    let serviceRequest: HTMLElement;
    const history = createMemoryHistory();
    renderComponent(AccommodationRequest, {}, undefined, history);

    beforeAll(() => {});

    afterAll(() => cleanup());

    beforeEach(() => {
      serviceRequest = screen.getByTestId(
        'AccommodationRequestTile-b1fd459c-39e1-4f6d-bdd9-ceff0252e37e'
      );
    });

    it('should redirect to SR detail', () => {
      fireEvent.click(serviceRequest);
      expect(history.location.pathname).toBe(`/accommodation_request/${mock[0].id}/details`);
    });
  });
});
