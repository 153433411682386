const labels = {
  All: {
    dailyMenu: 'Menu du jour',
    anytime: 'Peu importe',
    breakfast: 'Petit déjeuner',
    morningBreak: 'pause matinale',
    lunch: 'Déjeuner',
    afternoonBreak: "pause de l'après-midi",
    dinner: 'Dîner',
    supper: 'Souper',
    nightMeal: 'Repas du soir',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menus',
    'Ref: View details': 'Voir les détails',
    'Ref: Facility details': "Détails de l'installation",
  },
  OpeningHours: { everyday: 'tous les jours', 'open all day': 'Ouvert toute la journée' },
  ProductsList: {
    'Ref: Page title': 'Menu',
    'Ref: Facility details': "Détails de l'installation",
    'Ref: Search products list': 'Articles de recherche',
    'Ref: filter label: date': 'Date',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Catégorie',
    'Ref: filter label: dishes': 'Vaisselle',
    'Ref: filter label: highlight': 'Mettre en surbrillance ou masquer les filtres avec des plats',
    All: 'Tout',
  },
  ProductDetails: { 'Ref: Page title': 'Fiche du produit' },
  Widget: {
    'Ref: Carousel title': 'Menus',
    'No info yet, come back later': 'Aucune information encore, revenez plus tard 😉',
    'Ref: menus': 'Menus',
    'Ref: see all': 'Voir tout',
  },
};
export default labels;
