const labels = {
  ContentDetails: {
    'Ref: Page title': 'İçerik Detayları',
    'See the event': 'Etkinliği görün',
    'Take the survey': 'Ankete katılın',
    Date: 'Tarih',
    Category: 'Kategori',
    'Ref: Content site different title': 'Bu İçerik mevcut sitenize ait değil',
    'Ref: Content site different description':
      'Mevcut sitenize ait olmayan bir içeriği görüntülüyorsunuz. Bu içeriği içerik listesinde göremezsiniz.',
  },
  ContentList: {
    'Ref: Page title': 'Haber akışı',
    'featured (as in featured content)': 'Öne Çıkanlar',
    'Ref: category - all': 'Tümü',
    'Ref: category - promotional': 'Promosyonel',
    'Ref: category - featured': 'Öne Çıkanlar',
    'Ref: category - other': 'Diğer',
  },
  Widget: {
    'Ref: Carousel title': 'Haberler & Makaleler',
    'Ref: Carousel title promo': 'En İyi Haberler',
    'No info yet, come back later': 'Henüz bilgi yok, daha sonra gelin 😉',
    'Ref: Content': 'İçerik',
    'Ref: Widget Title': 'Haber akışı',
    'Ref: MyVillage Carousel title promo': 'Promosyonlar',
  },
};
export default labels;
