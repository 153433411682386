import classNames from 'classnames';

import { SIZE } from '../../../constants';
import textTransform, { TRANSFORM_TYPE } from '../../../helpers/textTransform';
import Button, { BUTTON_LOOK } from '../Button';
import Tooltip, { TOOLTIP_POSITION } from '../Tooltip';

import { Filtering, NormalFilter } from './Filters.types';
import { getFilterApplicableOptions } from './helpers';

import styles from './ExpandedFilter.module.css';

import { TestingPropsV2 } from 'src/types';

export const TabFilter = ({
  filter,
  filtering,
  onChange,
  'data-testid': testId,
}: {
  filter: NormalFilter;
  filtering: Filtering;
  onChange: (filterId: string, value: string) => void;
} & TestingPropsV2) => {
  const { id } = filter;

  const applicableOptions = getFilterApplicableOptions(filter, filtering);
  const labelMaxLength = 15;
  return (
      <div className={classNames(styles.expandedFilterButtonContainer)} >
        {applicableOptions.map((option) =>
          option.label.length > labelMaxLength ? (
            <Tooltip key={option.value} content={option.label} place={TOOLTIP_POSITION.Top}>
              <Button
                look={
                  filtering[id]?.[option.value] ? BUTTON_LOOK.PRIMARY : BUTTON_LOOK.SECONDARY_INLINE
                }
                data-cy={'filter-option-expanded'}
                data-testid={`${testId}-option-${filter.id}-${option.value}`}
                onClick={() => {
                  onChange(filter.id, option.value);
                }}
                size={SIZE.SMALL}
                className={classNames(styles.expandedFilterButton)}
              >
                {textTransform(option.label, TRANSFORM_TYPE.CAPITALIZE)}
              </Button>
            </Tooltip>
          ) : (
            <Button
              key={option.value}
              look={
                filtering[id]?.[option.value] ? BUTTON_LOOK.PRIMARY : BUTTON_LOOK.SECONDARY_INLINE
              }
              data-cy={'filter-option-expanded'}
              data-testid={`${testId}-option-${filter.id}-${option.value}`}
              onClick={() => {
                onChange(filter.id, option.value);
              }}
              size={SIZE.SMALL}
              className={classNames(styles.expandedFilterButton)}
            >
              {textTransform(option.label, TRANSFORM_TYPE.CAPITALIZE)}
            </Button>
          )
        )}
      </div>
  );
};
