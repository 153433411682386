import { Network } from '@capacitor/network';
import { act, cleanup, fireEvent, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import { renderedComponent } from '../../../../../helpers/tests/renderComponent';
import { useGetAuditInvitesQuery } from '../../../api';
import { useSync } from '../../../hooks';
import AuditInviteList from '../AuditInviteList';

jest.mock('react-select', () => ({ 'data-testid': dataTestId, options, value, onChange }: any) => {
  function handleChange(event: any) {
    const option = options.find((option: any) => option.value === event.currentTarget.value);
    onChange(option);
  }
  return (
    <select
      id={dataTestId}
      name={dataTestId}
      data-testid={dataTestId}
      value={value || ''}
      onChange={handleChange}
    >
      {options.map(({ label, value }: any) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
});

jest.mock('@capacitor/network', () => ({
  ...jest.requireActual('@capacitor/network'),
  Network: {
    addListener: jest.fn(),
    removeAllListeners: () => ({ catch: jest.fn() }),
    getStatus: jest.fn(),
  },
}));

jest.mock('../../../hooks', () => ({
  ...jest.requireActual('../../../hooks'),
  useSync: jest.fn(),
}));

const auditInvites = [
  {
    id: '97b0a59e-9501-4edf-abd9-24978540971b',
    auditName: 'L2-Cleaning Laundry',
    auditStreamType: 'Accomodation',
    workOrderNumber: '3080296',
    workOrderLocation: 'Mesa H Village',
    dueDate: '2023-04-01T00:00:00+00:00',
    state: 0,
  },
  {
    auditName: 'L2-Care and Maintenance Property-Grounds',
    auditStreamType: null,
    dueDate: '2023-03-19T00:00:00+00:00',
    id: 'cad567fb-c47a-4660-b957-838e4fb95b1d',
    state: 1,
    workOrderLocation: 'Hope Downs 1 Village',
    workOrderNumber: '4191958',
  },
  {
    auditName: 'L2-Care and Maintenance Property-Grounds',
    auditStreamType: 'Grounds Maintenance',
    dueDate: '2023-03-29T00:00:00+00:00',
    id: '1bc24ac9-3252-4fc9-a151-b79ea96308a8',
    state: 2,
    workOrderLocation: 'Hope Downs 1 Village',
    workOrderNumber: '4193066',
  },
];

jest.mock('../../../api', () => ({
  ...jest.requireActual('../../../api'),
  useGetAuditInvitesQuery: jest.fn(),
  useLazyGetAuditLocationsQuery: () => [],
  useLazyGetStreamsQuery: () => [],
  useLazyGetAuditsQuery: () => [],
  useLazyGetAssetsQuery: () => [],
  useCreateAuditMutation: () => [() => {}],
}));

describe('AuditInviteList', () => {
  afterAll(() => cleanup());

  beforeEach(() => {
    (Network.getStatus as jest.Mock).mockReturnValue({ connected: true });
    (useSync as jest.Mock).mockReturnValue({
      isLoading: false,
      checkSyncStatus: () => ({ fetch: true, post: true }),
      onFetchData: jest.fn(),
    });
  });

  describe('Initial render', () => {
    beforeEach(async () => {
      (useGetAuditInvitesQuery as jest.Mock).mockReturnValue({ data: auditInvites });

      await act(async () => {
        renderedComponent(AuditInviteList);
      });
    });

    it('Should render page with list', () => {
      const item = screen.getByText('L2-Cleaning Laundry');

      expect(item).toBeTruthy();
    });
  });

  describe('Fetching', () => {
    beforeEach(async () => {
      (useGetAuditInvitesQuery as jest.Mock).mockReturnValue({ data: [], isFetching: true });
      await act(async () => {
        renderedComponent(AuditInviteList);
      });
    });

    it('Should display loading skeleton', () => {
      const skeletons = screen.getAllByTestId('tile-skeleton');
      expect(skeletons.length).toBeGreaterThan(0);
    });
  });

  describe('On click', () => {
    describe('When is online', () => {
      beforeEach(async () => {
        (useGetAuditInvitesQuery as jest.Mock).mockReturnValue({
          data: auditInvites,
          isLoading: false,
        });

        await act(async () => {
          renderedComponent(AuditInviteList);
        });
      });

      it('Should redirect to details page', async () => {
        const audits = screen.queryAllByTestId('tile');

        await act(async () => {
          fireEvent.click(audits[1]);
        });

        expect(window.location.pathname).toEqual('/audits/cad567fb-c47a-4660-b957-838e4fb95b1d');
      });
    });

    describe('When is offline', () => {
      beforeEach(async () => {
        (useGetAuditInvitesQuery as jest.Mock).mockReturnValue({
          data: auditInvites,
          isLoading: false,
        });
        (useSync as jest.Mock).mockReturnValue({
          isLoading: false,
          checkSyncStatus: () => ({ fetch: false, post: false }),
          onFetchData: jest.fn(),
        });
        (Network.getStatus as jest.Mock).mockReturnValue({ connected: false });

        await act(async () => {
          renderedComponent(AuditInviteList);
        });
      });

      it('Should throw error if is offline and doesnt have synced data', async () => {
        const audits = screen.queryAllByTestId('tile');

        await act(async () => {
          fireEvent.click(audits[1]);
        });
        expect(await screen.findByTestId('error-toast')).toBeInTheDocument();
      });
    });
  });

  describe('On create new', () => {
    describe('When is online', () => {
      beforeEach(async () => {
        (useGetAuditInvitesQuery as jest.Mock).mockReturnValue({
          data: auditInvites,
          isLoading: false,
        });

        await act(async () => {
          renderedComponent(AuditInviteList);
        });
      });

      it('Should redirect to create form', async () => {
        const button = screen.getByTestId('button-action-primary');

        await act(async () => {
          fireEvent.click(button);
        });

        expect(window.location.pathname).toEqual('/audits/create');
      });
    });

    describe('When is offline', () => {
      beforeEach(async () => {
        (useGetAuditInvitesQuery as jest.Mock).mockReturnValue({
          data: auditInvites,
          isLoading: false,
        });
        (useSync as jest.Mock).mockReturnValue({
          isLoading: false,
          checkSyncStatus: () => ({ fetch: false, post: false }),
          onFetchData: jest.fn(),
        });
        (Network.getStatus as jest.Mock).mockReturnValue({ connected: false });

        await act(async () => {
          renderedComponent(AuditInviteList);
        });
      });

      it('Should throw error if is offline and doesnt have synced data', async () => {
        const button = screen.getByTestId('button-action-primary');

        await act(async () => {
          fireEvent.click(button);
        });

        expect(await screen.findByTestId('error-toast')).toBeInTheDocument();
      });
    });
  });

  describe('On search', () => {
    beforeEach(async () => {
      (useGetAuditInvitesQuery as jest.Mock).mockReturnValue({ data: auditInvites });
      await act(async () => {
        renderedComponent(AuditInviteList);
      });
      await act(async () => {
        const input = screen.getByTestId(
          'audit-invite-list-search-bar-list-page-search-filter-bar-input-field'
        );
        await userEvent.type(input, 'Laundry');
        await new Promise((r) => setTimeout(r, 400));
      });
    });

    it('Should render page with list with correct number of items', () => {
      const items = screen.getAllByTestId('tile');
      expect(items.length).toEqual(1);
    });
  });

  describe('Filtering', () => {
    let filterDropdown: HTMLElement;

    beforeEach(async () => {
      (useGetAuditInvitesQuery as jest.Mock).mockReturnValue({ data: auditInvites });
      await act(async () => {
        renderedComponent(AuditInviteList);
      });

      filterDropdown = screen
        .getByTestId(
          'audit-invite-list-search-bar-list-page-filters-filter_audit_status-filter-filter_audit_status'
        )
        .querySelector('select') as HTMLSelectElement;
    });

    it('Should render all elements when filter is not applied', () => {
      const items = screen.getAllByTestId('tile');
      expect(items.length).toEqual(3);
    });

    it('Should filter list acording to selection', async () => {
      await act(async () => {
        await userEvent.selectOptions(filterDropdown, ['0']);
      });

      const items = screen.getAllByTestId('tile');
      expect(items.length).toEqual(1);
    });
  });
});
