import { useParams } from 'react-router';

import MM from '../../../services/ModulesManager';
import { ApiType } from '../../Core/api';
import { ContentPageDetailRouteParams } from '../types';

const useGetContentPageParams = () => {
  const params = useParams<ContentPageDetailRouteParams>();

  if (params.id) {
    return {
      navItemName: params.navItemName,
      contentPageId: params.id,
    };
  }

  return {
    navItemName: params.navItemName,
    contentPageId: (
      MM<ApiType>().getApi(
        'Core',
        'getContentPageIdByNavItemName'
      ) as ApiType['getContentPageIdByNavItemName']
    )(params.navItemName),
  };
};

export default useGetContentPageParams;
