import { FilterIcon } from '../../../assets/icons';
import { SIZE } from '../../../constants';
import Button, { BADGE_PLACEMENT, BADGE_POSITION } from '../../atoms/Button';
import FilterWrapper from '../../atoms/Filters/FilterWrapper';

import { ButtonFilterListPageProps } from './ListPage.types';

export const ButtonFilterListPage: React.FC<ButtonFilterListPageProps> = ({
  label,
  srOnlyLabel,
  onClick,
  selectedNum,
  badgePosition = BADGE_POSITION.RIGHT,
  badgePlacement = BADGE_PLACEMENT.INNER,
  look = 'secondary',
  className,
  'data-testid': testId,
}) => {
  return (
    <div>
      <FilterWrapper isButton className={className} data-testid={testId}>
        <Button
          look={look}
          onClick={onClick}
          affix={() => <FilterIcon />}
          badge={selectedNum ? selectedNum.toString() : ''}
          badgePosition={badgePosition}
          badgePlacement={badgePlacement}
          size={SIZE.SMALL}
          data-testid={`${testId}-button`}
        >
          <span data-testid={`${testId}-label`}>{label}</span>
          <span className="sr-only">{srOnlyLabel}</span>
        </Button>
      </FilterWrapper>
    </div>
  );
};
