const labels = {
  TimeTable: {
    'leave at': '出発する',
    'arrive at': '到着する',
    at: 'で',
    'Ref: list placeholder': 'フォームに記入して時間を確認します',
    'Ref: Page title': 'バス',
    'Ref: Departure': '出発',
    'Ref: Arrivals': '到着',
    'Ref: minutes': '分',
    'Ref: no items placeholder': '申し訳ありませんが、現時点では利用可能なバスはありません。',
  },
};
export default labels;
