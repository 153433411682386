const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Boendebegäran',
    'Ref: Describe your request': 'Beskriv din begäran',
    'Ref: Confirm your request': 'Bekräfta din begäran',
    'Ref: Your request': 'Din förfrågan',
    'Ref: Summary': 'Sammanfattning',
    'Ref: Next': 'Nästa',
    'Ref: Submit': 'Skicka in',
    'Ref: Me': 'Mig',
    'Ref: Arrival date': 'Ankomstdatum',
    'Ref: Arrival time': 'Ankomst tid',
    'Ref: Departure date': 'Avgångsdatum',
    'Ref: Departure time': 'Avgångstid',
    'Ref: request raised for': 'Den person som drabbats av denna begäran',
    'Ref: phoneNumber': 'Telefonnummer',
    'Ref: arrivalDate': 'Ankomst',
    'Ref: departureDate': 'Avresa',
    'Ref: description': 'Kommentar',
    'Ref: open': 'Öppen',
    'Ref: pending': 'I väntan på',
    'Ref: refused': 'Vägrade',
    'Ref: closed': 'Stängd',
    'Ref: all': 'Allt',
    'Ref: Arrival': 'Ankomst',
    'Ref: Validate': 'Bekräfta',
    'Ref: site': 'Platsplats',
    'Ref: Update us': 'Uppdatera oss',
    'Ref: email of guest': 'Gästens e-post',
    'Ref: GuestAccommodationRequestTitle': 'Gästförfrågan',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Ny förfrågan',
    'Ref: Status': 'Status',
    'Ref: Date': 'Datum',
    'Ref: Coming arrivals': 'Kommer ankomster',
    'Ref: Past arrivals': 'Tidigare ankomster',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Gästnummer',
    'Ref: Your phone number': 'Ditt telefonnummer',
    'Ref: The phone number': 'Telefonnumret',
    'Ref: PhoneNumber error msg': 'Telefonnumret är inte giltigt',
    'Ref: Comment error msg': 'Maximalt antal tecken är 250',
    'Ref: Guest first name': 'Gästnamn',
    'Ref: Guest last name': 'Gästnamn',
    'Ref: request for': 'För vem är denna begäran?',
    'Ref: request for me': 'Mig',
    'Ref: request for guest': 'Någon annan',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Information om begäran om boende',
    'Ref: Timeline': 'Tidslinje',
    'Ref: No updates placeholder': 'Det finns inga uppdateringar för denna begäran',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Tack',
    'Ref: Success text':
      'Vi har fått din begäran och undersöker den nu. Vi kommer snart tillbaka till dig.',
    'Ref: Go to home': 'Gå till hemmet',
    'Ref: Create another request': 'Skapa en annan begäran',
  },
  CreateError: {
    'Ref: Create error title': 'hoppsan!',
    'Ref: Create error text': 'Ett fel har inträffat. Var vänlig försök igen.',
    'Ref: Retry': 'Försök igen',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Uppdatera oss med alla nya element om din begäran så att vi bäst kan stödja dig.',
  },
};
export default labels;
