import { useHistory } from 'react-router';

import NoticePage from '../../../../../components/templates/NoticePage/NoticePage';
import { pagePaths } from '../../../config';
import { useCoreTranslation } from '../../../hooks/useCoreTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const DeletionFailure = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);

  return (
    <NoticePage
      withNavBar={false}
      type={NOTICEPAGE_TYPE.ERROR}
      title={label('sorry (standalone)', { textTransform: 'capitalize' })}
      content={label('Ref: Body')}
      actions={[
        {
          label: label('Go to account'),
          action: () => history.push(pagePaths['Account']),
        },
      ]}
    />
  );
};

export default DeletionFailure;
