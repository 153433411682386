import classNames from 'classnames';
import React from 'react';
import ReactMarkDown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Link from '../Link';

import { getLanguageBody } from './renderContent.helpers';
import { MarkDownContentProps } from './RenderContent.types';

import styles from './RenderContent.module.css';

const MarkDownContent = React.forwardRef<HTMLDivElement, MarkDownContentProps>(
  (
    { children, 'data-cy': dataCy = 'markdown-content', 'data-testid': dataTestId, language },
    ref
  ) => {
    const translatedContentPart = getLanguageBody(children, language);

    // To allow line breaking, ReactMarkdown needs two spaces before newline
    const markDown = translatedContentPart.replace(/\n/gi, '  \n');

    return (
      <div
        className={classNames(styles.renderContent)}
        data-cy={dataCy}
        data-testid={dataTestId}
        ref={ref}
      >
        <ReactMarkDown
          remarkPlugins={[remarkGfm]}
          components={{
            // Replace anchor with custom Link component
            a: ({ href, children, title }) => (
              <Link data-testid={dataTestId} to={href as string} title={title}>
                {children}
              </Link>
            ),
          }}
        >
          {markDown}
        </ReactMarkDown>
      </div>
    );
  }
);

export default MarkDownContent;
