import { Service } from '../types/State.types';

import { hasNavigationEnabled } from './availableServices.helper';

import { SERVICE } from '@/modules/config';


const services: Service[] = [
  {
    id: '1',
    name: SERVICE.AUDITS,
    navigation: {
      isEnabled: false,
      name: 'audits navigation',
      orderIndex: 1,
      imageId: null,
      imageName: null,
    },
    setupOptions: [],
    contentPageId: '1',
  },
  {
    id: '2',
    name: SERVICE.CONTENT,
    navigation: {
      isEnabled: true,
      name: 'content navigation',
      orderIndex: 1,
      imageId: null,
      imageName: null,
    },
    setupOptions: [],
    contentPageId: '2',
  },
];

describe('hasNavigationEnabled', () => {
  it('returns false when service not found', () => {
    const result = hasNavigationEnabled(services, SERVICE.ACCOMMODATION_REQUEST);

    expect(result).toBeFalsy();
  });

  it('returns false when service found but navigation not enabled', () => {
    const result = hasNavigationEnabled(services, SERVICE.AUDITS);

    expect(result).toBeFalsy();
  });

  it('returns true when service found and navigation enabled', () => {
    const result = hasNavigationEnabled(services, SERVICE.CONTENT);

    expect(result).toBeTruthy();
  });
});
