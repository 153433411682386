const labels = {
  StaysList: {
    'Ref: Page title': 'Konaklamalar',
    'Ref: Past': 'Geçmiş',
    'Current and future stays': 'Mevcut ve gelecekteki konaklamalar',
    'filter label: period': 'Dönem',
    'Ref: Search': 'Arama',
  },
  StayDetails: {
    'Ref: Page title': 'Konaklama detaylarınız',
    'Arrival date': 'Varış tarihi',
    'Departure date': 'Kalkış tarihi',
    'Room number': 'Oda',
    'Reservation number': 'Rezervasyon Numarası',
    CheckIn: 'Giriş Yapın',
    CheckOut: 'Çıkış Yapın',
    CheckIn_1: "Bugün gelen {lodgeName}'e giriş yapın? (Rezervasyon Numarası {resNumber})",
    CheckedIn_1: "{lodgeName}, oda {roomNumber}'na giriş yaptınız.",
    CheckOut_1:
      'Şimdi {lodgeName} rezervasyon numarasını {resNumber} kontrol edelim mi? Oda anahtarınız devre dışı bırakılacaktır.',
    CheckedOut_1: '{lodgeName} rezervasyon numarası {resNumber} çıkış yapmıştır.',
  },
  StaysSuccess: {
    'Ref: Title': 'Kaldığınız için teşekkür ederiz!',
    'Ref: Body': 'Çıkış işleminiz başarıyla gerçekleştirilmiştir.',
    'Ref: Go to home': 'Eve git',
    'Ref: Provide a feedback': 'Bir geri bildirim sağlayın',
  },
};
export default labels;
