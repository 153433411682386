const labels = {
  TimeTable: {
    'leave at': 'Lämna vid',
    'arrive at': 'Komma fram till',
    at: 'på',
    'Ref: list placeholder': 'Fyll i formuläret för att se timmarna',
    'Ref: Page title': 'Bussar',
    'Ref: Departure': 'Avresa',
    'Ref: Arrivals': 'ankomster',
    'Ref: minutes': 'minuter',
    'Ref: no items placeholder': 'Tyvärr finns det inga bussar tillgängliga just nu.',
  },
};
export default labels;
