import { Skeleton } from '../../../../components/atoms/Skeleton';

const SkeletonListItem = () => (
  <>
    <div>
      <Skeleton width="170px" />
    </div>
    <div>
      <Skeleton width="200px" />
    </div>
    <div>
      <Skeleton width="280px" />
    </div>
    <div>
      <Skeleton width="140px" />
    </div>
  </>
);

export default SkeletonListItem;
