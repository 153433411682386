const labels = {
  TimeTable: {
    'leave at': 'Palikti',
    'arrive at': 'Atvykti',
    at: 'at',
    'Ref: list placeholder': 'Užpildykite formą, kad pamatytumėte valandas',
    'Ref: Page title': 'Autobusai',
    'Ref: Departure': 'Išvykimas',
    'Ref: Arrivals': 'Atvykimas',
    'Ref: minutes': 'minutės',
    'Ref: no items placeholder': 'Atsiprašome, šiuo metu nėra autobusų.',
  },
};
export default labels;
