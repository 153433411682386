import { RangeChangeEventDetail, RangeValue } from '@ionic/core';
import { IonRange } from '@ionic/react';
import classNames from 'classnames';
import { useEffect } from 'react';

import useStateWithProps from '../../../helpers/hooks/useStateWithProps';
import useToggle from '../../../helpers/hooks/useToggle';

import { RangeProps } from './Range.types';

import styles from './Range.module.css';

const Range = ({
  value = 0,
  onChange,
  min,
  max,
  isDualKnobs = false,
  disabled = false,
  customMaxLabel = '',
  customMinLabel = '',
  showLabel = true,
  ariaLabel = 'Range input',
  'data-testid': testId,
  ...props
}: RangeProps) => {
  const { state: isFocused, toggleOn: focusedOn, toggleOff: focusedOff } = useToggle(false);
  const [rangeValue, setRangeValue] = useStateWithProps<RangeValue>(value);
  const [newRangeValue, setNewRangeValue] = useStateWithProps<RangeValue>(value);

  const handleRangeChange = (e: CustomEvent<RangeChangeEventDetail>) => {
    setNewRangeValue(e.detail.value);
  };

  useEffect(() => {
    if (JSON.stringify(rangeValue) !== JSON.stringify(newRangeValue)) {
      onChange(newRangeValue);
      setRangeValue(newRangeValue);
    }
  }, [rangeValue, onChange, newRangeValue, setRangeValue]);

  return (
    <>
      <div className={styles.rangeWrapper} data-testid={`${testId}-wrapper`}>
        <IonRange
          min={min}
          max={max}
          role="slider"
          value={rangeValue}
          onIonChange={handleRangeChange}
          onIonFocus={focusedOn}
          onIonBlur={focusedOff}
          className={classNames(styles.range, { [styles.focused]: isFocused })}
          mode="ios"
          pin={showLabel}
          dualKnobs={isDualKnobs}
          disabled={disabled}
          aria-label={ariaLabel}
          data-testid={`${testId}-input`}
          {...props}
        />
        <div className={classNames(styles.limitsWrapper)} data-testid={`${testId}-limits-wrapper`}>
          <span data-testid={`${testId}-limits-value-min`}>{(customMinLabel || min) ?? ''}</span>
          <span data-testid={`${testId}-limits-value-max`}>{(customMaxLabel || max) ?? ''}</span>
        </div>
      </div>
    </>
  );
};

export default Range;
