import { Network } from '@capacitor/network';
import { useEffect, useState } from 'react';

const useConnection = () => {
  const [isConnected, setIsConnected] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const { connected } = await Network.getStatus();
      setIsConnected(connected);
    })();
  }, []);

  useEffect(() => {
    Network.addListener('networkStatusChange', (status) => {
      setIsConnected(status.connected);
    });

    return () => {
      Network.removeAllListeners().catch((err) =>
        console.error('Network remove listener err:', err)
      );
    };
  }, []);

  return isConnected;
};

export default useConnection;
