import { screen, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import ServiceRequestLightList from '../List';

import renderComponent from '@/helpers/tests/renderComponent';
import { pagePaths } from '@/modules/ServiceRequestLight/config';

const mock = [
  {
    'data-testid': 'accommodation-request-list-item',
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37e',
    referenceNumber: '0612156',
    createdOn: '2020-04-20T09:34:46Z',
    title: 'Broken glass on the 1. floor',
    summary: 'Broken glass on the 1. floor',
    category: 'Cleaning',
    raisedFor: 'Michel Hofman',
    phoneNumber: '+33792374728',
    siteName: 'FMG Camp',
    room: 'Kitchen',
    building: 'Brolga',
    description: 'Found broken glass in the kitchen, please clean it up.',
  },
  {
    'data-testid': 'accommodation-request-list-item',
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37f',
    referenceNumber: '0612156',
    createdOn: '2020-04-20T09:34:46Z',
    title: 'Soap in ladies bathroom',
    summary: 'Soap in ladies bathroom',
    category: 'Soft services',
    raisedFor: 'Anna Stone',
    phoneNumber: '+33792374728',
    siteName: 'FMG Hamilton',
    room: 'Bathroom',
    building: 'Brolga',
    description: 'Please fill in the liquid soap in ladies bathroom on the 2. floor.',
  },
  {
    'data-testid': 'accommodation-request-list-item',
    id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37g',
    referenceNumber: '0612156',
    createdOn: '2020-04-20T09:34:46Z',
    title: 'Broken dishwasher',
    summary: 'Broken dishwasher',
    category: 'Hard services',
    raisedFor: 'Joe Hardy',
    phoneNumber: '+33792374728',
    siteName: 'Sanofi Alston',
    room: 'Kitchen',
    building: 'Brolga',
    description: 'Please, fix not working dishwasher on the 3. floor.',
  },
];

jest.mock('react-hook-form', () => ({
  ...jest.requireActual('react-hook-form'),
  useFormContext: () => ({
    reset: () => jest.fn(),
  }),
}));

jest.mock('@/modules/ServiceRequestLight/api', () => ({
  useGetServiceRequestLightListQuery: () => ({
    data: mock,
  }),
}));

describe('AccommodationRequest', () => {
  describe('on initial render', () => {
    let searchInput: HTMLElement;
    let button: HTMLElement;

    renderComponent(ServiceRequestLightList);

    beforeEach(() => {
      searchInput = screen.getByTestId(
        'service-request-light-list-search-bar-list-page-filters-and-search-container'
      );
      button = screen.getByText(/Request Service/i);
    });

    it('should render search input and button', () => {
      expect(searchInput).toBeTruthy();
      expect(button).toBeTruthy();
    });

    it('should have the correct number of tiles listed', () => {
      const tiles = screen.getAllByTestId(/ServiceRequestLightTile-.+-actions-0-content/i);
      expect(tiles.length).toEqual(3);
    });

    it('should display the correct content', () => {
      expect(screen.getAllByText(/Broken dishwasher/i)).toBeTruthy();
    });
  });

  describe('on primary button click', () => {
    let button: HTMLElement;
    const history = createMemoryHistory();

    renderComponent(ServiceRequestLightList, undefined, undefined, history);

    beforeEach(() => {
      button = screen.getByText(/Request Service/i);
    });

    it('should redirect to new service request light creation', () => {
      expect(button).toBeTruthy();

      fireEvent.click(button);
      expect(history.location.pathname).toBe(pagePaths.Create);
    });
  });
});
