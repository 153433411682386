import AsyncSelect from './AsyncSelect';
import { highlightSearchedWords } from './helpers';
import HookSelect from './HookSelect';
import Select from './StaticSelect';
export { default as DropdownIndicator } from './DropdownIndicator';
export { default as IndicatorSeparator } from './IndicatorSeparator';
export { default as Option } from './Option';

export type { SelectOption, SelectValue } from './Select.types';

export default Select;
export { AsyncSelect, highlightSearchedWords, HookSelect };
