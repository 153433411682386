import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { CoffeeNotCheckImage } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import InfoMessage from '../../../../components/molecules/InfoMessage';
import TitleBar from '../../../../components/organisms/TitleBar';
import { TITLEBAR_CAPTION_TYPE } from '../../../../components/organisms/TitleBar/TitleBar.types';
import DefaultPage from '../../../../components/templates/DefaultPage/DefaultPage';
import PageWithIframe from '../../../../components/templates/PageWithIframe/PageWithIframe';
import useQuery from '../../../../helpers/hooks/useQuery';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';
import { CoreState } from '../../types/State.types';
import AccountPage from '../TitleBarWidgets/AccountWidget';
import NotificationsWidget from '../TitleBarWidgets/NotificationsWidget';

import { usePostVendorCodeMutation } from '@/modules/Core/api/vendor/vendorApi';

import styles from './ExternalContentPage.module.css';

const ExternalContentPage = () => {
  const query = useQuery();
  const url = query.get('url');
  const title = query.get('title') ?? '';
  const vendorCode = query.get('code') ?? '';
  const history = useHistory();
  const { user } = useSelector((state: { Core: CoreState }) => state.Core);
  const { label } = useCoreTranslation(__filename);

  const [serviceUrl, setServiceUrl] = useState<string | null>();
  const [postVendorCode] = usePostVendorCodeMutation();

  useEffect(() => {
    if (url) setServiceUrl(atob(url));

    if (vendorCode) {
      const fetchData = async () => {
        if (user) {
          const response = await postVendorCode({
            vendorCode: vendorCode,
            userId: user.contactId!,
            email: user.email!,
            firstName: user.firstName,
            lastName: user.lastName?.substring(0, 1)!,
          });

          if ('data' in response && response?.data?.token) {
            setServiceUrl(`${atob(url!)}?jwt=${response?.data?.token}`);
          }
        }
      };

      fetchData();
    }
  }, [user, url, vendorCode, postVendorCode]);

  const emptyInfoPage = (
    <DefaultPage
      titleBar={
        <TitleBar>
          <TitleBar.Navigation
            caption={{
              hideBackButton: true,
              title,
              captionType: TITLEBAR_CAPTION_TYPE.DISPLAY_PAGE_TITLE,
            }}
          />
          <TitleBar.Widgets>
            <NotificationsWidget />
            <AccountPage />
          </TitleBar.Widgets>
        </TitleBar>
      }
    >
      <div>
        <InfoMessage
          title={label('Ref: External content not available')}
          icon={<CoffeeNotCheckImage />}
          iconClasses={styles.customIcon}
          data-testid="external-content-page-info-message"
          button={
            <Button
              data-testid={'button-action-go-home'}
              look="secondary"
              onClick={() => history.push('/')}
            >
              {label('Go to home')}
            </Button>
          }
        ></InfoMessage>
      </div>
    </DefaultPage>
  );

  return (
    <>
      {serviceUrl ? (
        <PageWithIframe title={title} iFrameUrl={serviceUrl} hideBackButton={true} />
      ) : (
        emptyInfoPage
      )}
    </>
  );
};

export default ExternalContentPage;
