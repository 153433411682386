import classNames from 'classnames';
import * as H from 'history';

import { ArrowRightIcon } from '../../../../assets';
import { Information } from '../../../../assets/icons';
import { ImageDefaultFoodImg } from '../../../../assets/images';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import { TranslationProps } from '../../../../localization/localization.types';
import { pagePaths } from '../../config';

import { FoodFacility } from './FoodFacilitiesList.types';

import styles from './FoodFacilitiesList.module.css';

export const convertFacilitiesToList = ({
  facilities,
  facilityImages,
  label,
  viewFacilityDetails,
  history,
}: {
  facilities: FoodFacility[];
  facilityImages: { key: string; image: string }[];
  viewFacilityDetails: (facility: FoodFacility) => void;
  history: H.History<unknown>;
} & TranslationProps) => {
  const handleOnClick = (facility: FoodFacility) => () => viewFacilityDetails(facility);

  return facilities.map((facility: FoodFacility) => {
    const description = (
      <button
        className={classNames(styles.addendum)}
        onClick={handleOnClick(facility)}
        data-testid="view-details-button"
      >
        <Information /> <span>{label('Ref: View details')}</span>
      </button>
    );

    const linkPath = pagePaths.ProductsList.replace(':facilityId', facility.id);

    const handleMainAction = () => history.push(linkPath);

    const imgInfo = facilityImages?.find((x) => x.key === facility.id)
      ? {
          src: facilityImages?.find((x) => x.key === facility.id)?.image || '',
          alt: facility.title,
        }
      : undefined;

    const image = (
      <ImageWithFallback
        data-testid="image"
        imgInfo={imgInfo}
        imgElement={<ImageDefaultFoodImg />}
      />
    );

    const actions = [
      {
        icon: <ArrowRightIcon />,
        onClick: handleMainAction,
      },
    ];

    return {
      id: facility.id,
      title: facility.title,
      image,
      description,
      imgInfo,
      actions,
      'data-testid': `tile-food-facility-${facility.id}`,
    };
  });
};
