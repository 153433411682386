const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Tvůj požadavek',
    'Ref: Summary': 'souhrn',
    'Ref: Request category': 'Kategorie požadavků',
    'Ref: Person affected': 'Osoba ovlivněná touto žádostí',
    'Ref: Your phone number': 'Telefonní číslo',
    'Ref: Description': 'Popis',
    'Ref: Service Requests': 'Žádosti o službu',
    'Ref: Request Service': 'Požádat o službu',
    'Ref: Success title': 'Vaše žádost byla vytvořena',
    'Ref: Error title': 'Jejda!',
    'Ref: Error subtitle': 'Došlo k chybě, zkuste to znovu.',
    'Ref: Try again': 'Zkus to znovu',
    'Ref: you pronoun': '(vy)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Toto je náboje podpory na pracovišti',
    'Ref: Tutorial: tabname': 'Požadavky',
    'Ref: Tutorial: body':
      'Jsme tu, abychom vám pomohli. Přihlaste se prosím jeden požadavek na záznam.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Požádáte o službu na webu {SiteName}.',
    'Ref: Building': 'Budova',
    'Ref: Room': 'Pokoj, místnost',
    'Ref: Description help text': 'Čím podrobnější jste, tím lépe vás můžeme podpořit.',
    'Ref: Next': 'další',
    'Ref: Your request': 'Tvůj požadavek',
    'Ref: PhoneNumber error msg': 'Telefonní číslo není platné',
    'Ref: No affected person results': 'Žádná osoba odpovídající požadovaná kritéria',
    'Ref: Required': '* Požadované',
  },
  Summary: {
    'Ref: Location': 'Umístění',
    'Ref: Attachment': 'Přílohy',
    'Ref: Submit': 'Předložit',
    'Ref: No affected person results': 'Žádná osoba odpovídající požadovaná kritéria',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Požadavek služby',
    'Ref: createdOn': 'Vytvořeno na',
    'Ref: summary': 'souhrn',
    'Ref: category': 'Kategorie požadavků',
    'Ref: request raised for': 'Osoba ovlivněná touto žádostí',
    'Ref: phoneNumber': 'Telefonní číslo',
    'Ref: location': 'Telefonní číslo',
    'Ref: description': 'Popis',
    'Ref: attachments': 'Přílohy',
  },
};
export default labels;
