import { Camera, CameraPermissionState } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { browserName, BrowserTypes } from 'react-device-detect';

import { appInsights } from '../appInsights';

import { waitSeconds } from './misc';

const waitBetweenCheckSeconds = 0.5;
const maxChecksNumber = 200;

export const hasCameraPermission = async () => {
  const isNativePlatform = Capacitor.isNativePlatform();
  const { camera: cameraAccess } = await Camera.checkPermissions();

  if (isNativePlatform) return await hasCameraPermissionNative(cameraAccess);

  return hasCameraPermissionWeb(cameraAccess);
};

const hasCameraPermissionNative = async (cameraAccess: CameraPermissionState) => {
  if (cameraAccess === 'denied' || cameraAccess === 'prompt') {
    const { camera: cameraAccessRequest } = await Camera.requestPermissions({
      permissions: ['camera'],
    });

    cameraAccess = cameraAccessRequest;
    if (cameraAccess === 'prompt') {
      let iterationNumber = 0;
      while (true) {
        if (iterationNumber > maxChecksNumber) {
          appInsights?.trackTrace({
            message: `Failed to update camera permsissions`,
            severityLevel: SeverityLevel.Warning,
          });
          break;
        }

        const result = await Camera.checkPermissions();
        if (result.camera !== 'prompt') {
          cameraAccess = result.camera;
          break;
        }

        await waitSeconds(waitBetweenCheckSeconds);
        iterationNumber++;
      }
    }
  }

  return cameraAccess === 'granted' ? true : false;
};

const hasCameraPermissionWeb = (cameraAccess: CameraPermissionState) => {
  if (browserName === BrowserTypes.Firefox) {
    return true;
  }

  return cameraAccess !== 'denied';
};
