import Resizer from 'react-image-file-resizer';

export type ImageOptimizationOptions = {
  maxWidthOrHeight: number;
  maxImageSizeInBytes: number;
  startingQuality: number;
  minimumQuality: number;
  qualitySteps: number;
};

class ImageOptimizationService {
  private startingQuality: number;
  private qualityDecreaseStep: number;
  private minimumQuality: number;
  private maxWidthOrHeight: number = 4096;

  constructor(options: ImageOptimizationOptions) {
    this.startingQuality = options.startingQuality ?? 90;
    this.qualityDecreaseStep = options.qualitySteps ?? 10;
    this.minimumQuality = options.minimumQuality ?? 50;
  }

  public async applyMaxWidthOrHeightAndQuality(
    file: File,
    quality: number,
    maxWidthOrHeightInPixels: number
  ): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        maxWidthOrHeightInPixels,
        maxWidthOrHeightInPixels,
        'JPEG',
        quality,
        0,
        (optimizedFile) => {
          if (optimizedFile && optimizedFile instanceof File) {
            resolve(optimizedFile);
          }
          reject(
            new Error(
              `Image optimization failed for quality: ${quality} and max width/height: ${maxWidthOrHeightInPixels}`
            )
          );
        },
        'file'
      );
    });
  }

  public async applyMaxFileSize(
    file: File,
    maxSizeInBytes: number,
    maxWidthOrHeightInPixels: number
  ): Promise<File> {
    let optimizedFile: File;
    let quality = this.startingQuality;
    do {
      optimizedFile = await this.applyMaxWidthOrHeightAndQuality(
        file,
        quality,
        maxWidthOrHeightInPixels
      );
      quality -= this.qualityDecreaseStep;
    } while (optimizedFile.size > maxSizeInBytes && quality > this.minimumQuality);

    if (optimizedFile.size < maxSizeInBytes) {
      return optimizedFile;
    } else {
      throw new Error(`Image optimization failed for max file size in bytes: ${maxSizeInBytes}`);
    }
  }
}

export default ImageOptimizationService;
