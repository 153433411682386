const labels = {
  All: {
    Feedback: '피드백',
    'Ref: Switch site component intro sentence': '귀하의 의견은 사이트 {site name}에 링크됩니다.',
    Category: '범주',
    Subject: '주제',
    Comment: '논평',
    'Date of your experience': '경험 날짜 *',
    'Ref: Hint': '고정을 필요로하는 문제 나 직업이 있습니까?',
    'Ref: Log Request': '요청을 기록하십시오',
    'Ref: Describe issue': '귀하의 경험에 대해 알려주십시오',
    Date: '날짜',
    'Ref: Share your opinion header': '직장에서의 경험에 대한 의견을 공유하십시오',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': '레스토랑 / 카페',
    'Desk / workstation area': '책상 / 워크 스테이션 영역',
    'Meeting room facilities': '회의실 시설',
    'Coffee/tea facilities (pantry)': '커피/차 시설 (식료품 저장실)',
    'Restrooms / washrooms': '화장실 / 화장실',
    'Reception / Security': '수신 / 보안',
    'Conference center': '컨퍼런스 센터',
    'Fitness center': '피트니스 센터',
    Mailroom: '메일 룸',
    'Bicycle room': '자전거 실',
    'Shuttle services': '셔틀 서비스',
    Lockers: '사물함',
    Helpdesk: '안내 데스크',
  },
  Create: {
    'Tell us!': '우리에게 말해봐!',
    'Ref: All Ears': '우리는 모두 귀입니다!',
    'Ref: Provide Description': '설명을 제공하십시오',
    'Ref: Fill-in-the-blank sentence': '{1}과 관련하여 {0}을 제공하고 싶습니다.',
    'Ref: Link text to service request module': '요청 또는 문제? 여기에 로그인하십시오!',
    'Complaint type': '불만 {emoji}',
    'Feedback type': '피드백 {emoji}',
    'Compliment type': '칭찬 {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      '오류가 발생했고 피드백이 제출되지 않았습니다. 이 오류가 계속되면 헬프 데스크에 문의하십시오.',
  },
  CreateSuccess: {
    'Ref: Body':
      '귀하의 의견이 제출되었습니다. 우리의 직장 지원 팀은 신중하게 읽을 것입니다. 적절한 경우, 우리는 당신에게 돌아올 것입니다.',
    'New feedback': '새로운 피드백',
  },
  CreateFlow: {
    Description: '설명',
    'Ref: Provide Description': '날짜와 설명을 제공하십시오',
    'Ref: I would like to provide a': '제공하고 싶습니다',
    'Ref: in regards to': '에 관해서',
    'Ref: &': '&이었다.',
    'Ref: at': '~에',
    'Ref: on the': '에',
  },
};
export default labels;
