const labels = {
  Success: {
    'Ref: Body':
      'Obrigado por concluir esta pesquisa, seu tempo é valioso, pois essas pesquisas ajudam a melhorar nossos serviços.',
    'Ref: Tabtitle': 'Pesquisa concluída',
    'Take another survey': 'Faça outra pesquisa',
  },
  Survey: {
    'Ref: Page title': 'Detalhes da pesquisa',
    'Please answer all mandatory questions as identify by an asterisk':
      'Por favor, responda a todas as perguntas obrigatórias como identificar por um asterisco: *',
    'Ref: Take another survey': 'Faça outra pesquisa',
    'Ref: Go to Home': 'Vá para casa',
  },
  SurveysList: {
    'Ref: Page title': 'Pesquisa',
    'Ref: Available until': 'Disponível até',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Atualmente, você está visualizando pesquisas para o site {site name}.',
  },
};
export default labels;
