import classNames from 'classnames';
import React from 'react';

import { SkeletonProps } from './Skeleton.types';

import styles from './Skeleton.module.css';

export const Skeleton: React.FC<SkeletonProps> = ({ width = '100%', className }) => (
  <span className={classNames(styles.skeleton, className)} style={{ width }} />
);

export const SkeletonSquare: React.FC<SkeletonProps> = ({ className, ...props }) => (
  <Skeleton className={classNames(styles.skeletonSquare, className)} {...props} />
);

export const SkeletonCircle: React.FC<SkeletonProps> = ({ className, ...props }) => (
  <SkeletonSquare className={classNames(styles.skeletonCircle, className)} {...props} />
);
