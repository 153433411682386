const labels = {
  Success: {
    'Ref: Body':
      'ขอขอบคุณที่กรอกแบบสำรวจนี้เวลาของคุณมีค่าเนื่องจากแบบสอบถามเหล่านี้ช่วยในการปรับปรุงบริการของเรา',
    'Ref: Tabtitle': 'การสำรวจเสร็จสมบูรณ์',
    'Take another survey': 'ทำแบบสำรวจอื่น',
  },
  Survey: {
    'Ref: Page title': 'รายละเอียดการสำรวจ',
    'Please answer all mandatory questions as identify by an asterisk':
      'กรุณาตอบคำถามบังคับทั้งหมดตามที่ระบุโดยเครื่องหมายดอกจัน: *',
    'Ref: Take another survey': 'ทำแบบสำรวจอื่น',
    'Ref: Go to Home': 'กลับบ้าน',
  },
  SurveysList: {
    'Ref: Page title': 'สำรวจ',
    'Ref: Available until': 'มีให้จนถึง',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'คุณกำลังดูแบบสำรวจสำหรับเว็บไซต์ {site name}',
  },
};
export default labels;
