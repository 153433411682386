const labels = {
  All: {
    Region: 'ภูมิภาคหรือประเทศ',
    'Ref: Geography': 'โปรดเลือกประเทศหรือภูมิภาคของคุณ',
    'Ref: Country': 'ประเทศ',
    'Ref: Country tooltip': 'สถานที่ตั้งสอดคล้องกับประเทศไซต์',
    'Ref: Please select your country': 'กรุณาเลือกประเทศของคุณ',
    'First name': 'ชื่อจริง',
    'Last name': 'นามสกุล',
    'Ref: Full name': 'ชื่อเต็ม',
    'Mobile number': 'เบอร์มือถือ',
    'Mobile phone': 'โทรศัพท์มือถือ',
    Email: 'ที่อยู่อีเมล',
    Password: 'รหัสผ่าน',
    logout: 'ออกจากระบบ',
    'Not a new user? Log in': 'ไม่ใช่ผู้ใช้ใหม่? เข้าสู่ระบบ',
    'do not register, login': 'ไม่ใช่ผู้ใช้ใหม่? เข้าสู่ระบบ',
    'Ref: I agree': 'ฉันเห็นด้วย',
    Register: 'ลงทะเบียน',
    'Ref: My language': 'ภาษาของฉัน',
    'Ref: Kiosk Fail Title': 'อ๊ะ! บางอย่างผิดพลาด!',
    'Ref: Kiosk Fail Description':
      'น่าเสียดายที่คำสั่งซื้อของคุณไม่ประสบความสำเร็จ โปรดลองอีกครั้งหรือขอให้พนักงานช่วยคุณ',
    'Ref: Kiosk Fail Back': 'กลับไปที่หน้าก่อนหน้า',
    'Ref: Kiosk Fail Action': 'ล้างเซสชันและสั่งซื้ออีกครั้ง',
    Validate: 'ตรวจสอบความถูกต้อง',
    'Ref: error, incorrect credentials': 'ข้อมูลประจำตัวเหล่านี้ไม่ตรงกันในภูมิศาสตร์ที่เลือก',
  },
  AccountPage: {
    'Ref: My Information': 'ข้อมูลของฉัน',
    'Ref: Settings': 'การตั้งค่า',
    'Ref: Email': 'อีเมล',
    'Ref: Current site': 'ไซต์ปัจจุบัน',
    'Ref: Choose site': 'เลือกไซต์',
    'Ref: Personal interests': 'ความสนใจส่วนตัว',
    'Ref: Food preferences': 'การตั้งค่าอาหาร',
    'Ref: Password': 'รหัสผ่าน',
    'Ref: Page title': 'บัญชี',
    'Ref: Language': 'ภาษา',
    'Ref: Communication preferences': 'การตั้งค่าการสื่อสาร',
    'Ref: Terms & conditions': 'ข้อตกลงและเงื่อนไข',
    'Ref: Privacy policy': 'นโยบายความเป็นส่วนตัว',
    'Ref: Delete my account': 'ลบบัญชีของฉัน',
    'Ref: Confirm deletion': 'แน่ใจหรือว่าต้องการลบบัญชีของคุณ?',
    'Ref: Request deletion': 'แน่ใจหรือว่าต้องการขอการลบบัญชีของคุณ?',
    'Ref: Deletion description': 'คำขอของคุณจะได้รับการจัดการตามระเบียบท้องถิ่นของคุณ',
    Placeholder: 'เขียน "ลบ"',
    'Ref: Delete description': 'ข้อมูลของคุณจะถูกลบ การกระทำนี้กลับไม่ได้',
    delete: 'ลบ',
    'Ref: Terms of sale': 'เงื่อนไขในการขาย',
    'Ref: Imprint': 'รอยประทับ',
    'Ref: Cookie Settings': 'การตั้งค่าคุกกี้',
    'Ref: Support': 'สนับสนุน',
    'Ref: Register or login': 'ลงทะเบียนหรือเข้าสู่ระบบ',
    'Ref: Filtering Preferences': 'การตั้งค่าการกรอง',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'คุณแน่ใจว่าจะลบบัญชีของคุณหรือไม่?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'เขียน "{word}" ในฟิลด์อินพุตและยืนยันด้วยด้านล่างด้านล่าง การกระทำนี้กลับไม่ได้',
    'Ref: Write "{word}"': 'เขียน "{word}"',
    'Ref: Delete my account': 'ลบบัญชีของฉัน',
    'Ref: Words are not matching': 'คำไม่ตรงกัน',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'เลือกตำแหน่งของคุณสำหรับการเริ่มต้น',
    'Ref: Choose location':
      'ค้นหาตำแหน่งได้อย่างรวดเร็วโดยเปิดใช้งานการแปลการสแกนรหัส QR หรือค้นหาตามชื่อ',
    'Ref: next step': 'ไปกันเถอะ',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'ลงชื่อ',
    'Ref: identify your location': 'โปรดระบุตำแหน่งของคุณ',
    'Ref: option 1 - scan QR': 'ตัวเลือกที่ 1: สแกนรหัส QR ลงทะเบียนให้',
    'Ref: option 2 - enter code': 'ตัวเลือกที่ 2: ป้อนรหัสตำแหน่งของคุณด้วยตนเอง',
    'Ref: button to open qr code scanner modal': 'ฉันมี QR: มาสแกนกันเถอะ',
    'Ref: button to open site code input modal': 'เลือกไซต์ด้วยรหัส',
    'Ref: getting help with site identifier - support desk':
      'หากคุณไม่ชัดเจนเกี่ยวกับวิธีการดำเนินการโปรดติดต่อฝ่ายสนับสนุนของคุณ',
    'Ref: getting help with site identifier - support form': 'คุณสามารถส่งบันทึกย่อให้เรา',
    'Location code': 'รหัสตำแหน่ง',
    'Ref: location code field placeholder': 'รหัสตำแหน่งของคุณที่นี่',
    'Ref: Scanner header': 'สแกนรหัส QR',
    'Ref: site code input header': 'เพิ่มไซต์ด้วยรหัส',
    'Ref: not valid code': 'รหัสนี้ไม่ถูกต้องในภูมิศาสตร์ที่เลือก',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'การลงทะเบียน',
    'Ref: collected info': 'ตรวจสอบคำขอของคุณ',
    'Ref: additional info': 'ข้อมูลของคุณ',
    'Confirm password': '* ยืนยันรหัสผ่าน',
    company: 'นายจ้างของคุณคือใคร',
    location: 'ที่ตั้ง',
    username: 'ชื่อผู้ใช้',
    register: 'ลงชื่อ',
    'Ref: password strength':
      'รหัสผ่านของคุณจะต้องมีความยาวอย่างน้อย 8 ตัวอักษรมีอย่างน้อยหนึ่งหมายเลขตัวพิมพ์ใหญ่หนึ่งตัวพิมพ์เล็กหนึ่งตัวและอักขระพิเศษหนึ่งตัว',
    'passwords do not match': 'รหัสผ่านไม่ตรงกัน.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'การตั้งค่าการสื่อสาร',
    'Ref: Things happen while you are away': 'สิ่งต่างๆเกิดขึ้นในขณะที่คุณไม่อยู่',
    'Ref: Would you like to receive update about offers and promotions?':
      'คุณต้องการรับการอัปเดตเกี่ยวกับข้อเสนอและโปรโมชั่นหรือไม่?',
    'Ref: Would you like to hear about content we think you may like?':
      'คุณต้องการได้ยินเกี่ยวกับเนื้อหาที่เราคิดว่าคุณอาจชอบหรือไม่?',
    Yes: 'ใช่',
    No: 'ไม่',
    apply: 'นำมาใช้',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'รายละเอียดบัญชี' },
  DeletionFailure: {
    'Ref: Body':
      'ข้อผิดพลาดเกิดขึ้นและไม่ได้ส่งคำขอการลบบัญชีของคุณ หากข้อผิดพลาดนี้ยังคงมีอยู่โปรดติดต่อฝ่ายช่วยเหลือของคุณ',
    'Go to account': 'ไปบัญชี',
  },
  GenericFailurePage: {
    'Ref: Title': 'อะไรบางอย่างผิดปกติ',
    'Ref: Body':
      'ขอโทษมีบางอย่างผิดพลาด. หากปัญหานี้ยังคงมีอยู่โปรดติดต่อฝ่ายสนับสนุนในสถานที่ของคุณ',
  },
  HomePage: {
    notifications: 'การแจ้งเตือน',
    basket: 'รถเข็น',
    'Ref: Tabtitle': 'บ้าน',
    'contact us': 'ติดต่อเรา',
    'Ref: You are logged in as a guest user': 'คุณเข้าสู่ระบบในฐานะผู้ใช้แขก',
    'Ref: Good morning': 'สวัสดีตอนเช้า',
    'Ref: Good afternoon': 'สวัสดีตอนบ่าย',
    'Ref: Good evening before midnight': 'สวัสดีตอนเย็น',
    'Ref: Good evening after midnight': 'สวัสดีตอนเย็น',
    'Ref: Search': 'ค้นหา',
    'Ref: products button aria': 'พบ {results_form} ภายในผลิตภัณฑ์',
    'Ref: menus button aria': 'พบ {results_form} ภายในเมนู',
    'Ref: facilities button aria': 'พบ {results_form} ภายในสิ่งอำนวยความสะดวก',
    'Ref: result': 'ผลลัพธ์',
    'Ref: results': 'ผลลัพธ์',
    'Ref: Menus': 'เมนู',
    'Ref: No results title': 'อ๊ะ!',
    'Ref: No results body': 'ไม่มีผลลัพธ์ที่ตรงกับการค้นหาของคุณ',
    'Ref: Products': 'สินค้า',
    'Ref: Facilities': 'สิ่งอำนวยความสะดวก',
    'Ref: See more results': 'ดูผลการค้นหาเพิ่มเติม',
    'Ref: Accessibility instructions':
      'ในการเริ่มต้นใช้การค้นหาคุณต้องคลิกที่มันหรือกด Enter ในขณะที่มุ่งเน้น',
    'Ref: See more': 'ดูเพิ่มเติม',
    'Ref: singular form of products': 'ผลิตภัณฑ์',
    'Ref: singular form of menus': 'เมนู',
    'Ref: singular form of facilities': 'สิ่งอำนวยความสะดวก',
    'Ref: Search results for': 'ผลการค้นหาสำหรับ',
  },
  LanguagePage: {
    apply: 'นำมาใช้',
    'My language': 'ภาษาของฉัน',
    'Select in which language you would like to read our app':
      'เลือกภาษาที่คุณต้องการอ่านแอปของเรา',
    'Ref: Page title': 'ภาษา',
  },
  LegalDocPage: {
    Acknowledge: 'รับทราบ',
    'Ref: Legal changed notification title': 'ได้รับการแก้ไข“ {legal_doc_type}”',
    'Ref: Legal changed notification content': 'โปรดตรวจสอบพวกเขาก่อนที่จะดำเนินการต่อโดยใช้แอพ',
  },
  LegalDocsPage: {
    'Ref: Page title': 'ข้อตกลงและเงื่อนไข',
    'Read privacy policy': 'อ่านนโยบายความเป็นส่วนตัว',
    'Ref: Acknowledge legal docs': 'ฉันยอมรับข้อกำหนดและเงื่อนไขเหล่านี้',
    'Ref: Read and understood': 'ฉันได้อ่านและเข้าใจแล้ว ',
    'Ref: No legal docs available - notice title': 'เร็วๆ นี้',
    'Ref: No legal docs available - notice body':
      'ขออภัยดูเหมือนว่าแอปพลิเคชันนี้ไม่สามารถใช้งานได้ในภูมิศาสตร์ของคุณ',
    'Ref: The privacy Policy': 'นโยบายความเป็นส่วนตัว',
    'Ref: Legal changed notification title': 'ได้รับการแก้ไข“ {legal_doc_type}”',
    'Ref: Legal changed notification content': 'โปรดตรวจสอบพวกเขาก่อนที่จะดำเนินการต่อโดยใช้แอพ',
  },
  LoginPage: {
    'Ref: Page title': 'ยินดีต้อนรับสู่แอพ {app name} ',
    'Ref: Page subtitle': 'ลงชื่อเข้าใช้เพื่อดำเนินการต่อ',
    'Ref: Email field placeholder': 'อีเมลของคุณ',
    'Ref: Email field note': 'เราจะไม่แชร์อีเมลของคุณกับใคร',
    'Ref: Password field title': 'รหัสผ่านของคุณ',
    register: 'ลงชื่อ',
    'I forgot my password.': 'ลืมรหัสผ่าน?',
    'I have trouble logging in': 'มีปัญหาในการเข้าสู่ระบบ?',
    'start registration': 'เริ่มการลงทะเบียน',
    'Ref: Register': 'ลงทะเบียน',
    'Ref: Hello': 'สวัสดี {wave_emoji} โปรดเข้าสู่ระบบเพื่อดำเนินการต่อ',
    'Ref: Login': 'เข้าสู่ระบบ',
    'Ref: Forgot password?': 'ลืมรหัสผ่าน?',
    'Ref: Connection issue?': 'ปัญหาการเชื่อมต่อ?',
    'Ref: Connection issue message':
      'หากคุณมีปัญหาในการลงทะเบียนหรือเข้าสู่ระบบโปรดติดต่อเจ้าหน้าที่นอกสถานที่หรือช่วยเหลือ',
  },
  WelcomePage: { 'Ref: Welcome to': 'สวัสดีและยินดีต้อนรับสู่ {site_name}', 'Ref: Begin': 'เริ่ม' },
  NotificationsList: {
    'Ref: No notifications': 'คุณไม่มีการแจ้งเตือนในขณะนี้',
    'Ref: This week': 'ในสัปดาห์นี้',
    'Ref: Earlier': 'ก่อนหน้านี้',
    'Ref: Page title': 'การแจ้งเตือน',
    'Ref: See more': 'ดูเพิ่มเติม',
    'Ref: Mark all read': 'ทำเครื่องหมายทั้งหมดที่อ่าน',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'ดูการแจ้งเตือนทั้งหมด' },
  ServicesMenuPage: { 'Ref: Page title': 'บริการ', navigate: 'นำทาง' },
  DeletionSuccess: {
    'Ref: Title': 'ขอการลบบัญชีของคุณ',
    'Ref: Content': 'เราจะจัดการมันในไม่ช้า คุณอาจสูญเสียการเข้าถึงได้ตลอดเวลา',
    'Log out': 'ออกจากระบบ',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'เราจะติดต่อกลับไปหาคุณ ',
    'Ref: Body': 'ขออภัยที่คุณกำลังเผชิญปัญหา เราจะติดต่อคุณเร็ว ๆ นี้',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'มีปัญหาในการเข้าสู่ระบบ?',
    'Ref: Email field placeholder': ' ที่อยู่อีเมลของคุณ',
    'Ref: Mobile phone field placeholder': 'หมายเลขโทรศัพท์',
    Company: 'บริษัท',
    'Ref: Company field placeholder': 'ชื่อ บริษัท',
    'Ref: Company field note':
      'หากคุณเป็นที่ปรึกษานี่คือ บริษัท ที่คุณทำงานอยู่ไม่ใช่นายจ้างของคุณ',
    Location: 'เมืองหรือที่ตั้ง',
    'Ref: Location field placeholder': 'ที่ตั้งของที่ทำงานของคุณ',
    'Ref: Location field note':
      'หากนอกพื้นที่ที่อยู่อาศัยรู้สึกอิสระที่จะใช้ชื่อที่บริเวณนั้นถูกอ้างถึงโดยทั่วไป',
    Comment: 'ความคิดเห็น',
    'Ref: Comment field placeholder': 'เกิดอะไรขึ้น ?',
    'Ref: Who is your employer?': 'นายจ้างของคุณคือใคร?',
    'Ref: What village are you staying at?': 'คุณอยู่หมู่บ้านอะไรอยู่?',
    'Ref: Comment field note': 'ข้อมูลใด ๆ ที่สามารถช่วยเราช่วยคุณได้!',
    'Ref: Feedback Content - geography': 'ภูมิศาสตร์',
    'Ref: Feedback Content - fullName': 'ชื่อ',
    'Ref: Feedback Content - email': 'อีเมล',
    'Ref: Feedback Content - phone': 'โทรศัพท์',
    'Ref: Feedback Content - company': 'บริษัท',
    'Ref: Feedback Content - city': 'ชื่อเมือง / สถานที่ตั้ง',
    'Ref: Feedback Content - whatVillage': 'หมู่บ้าน',
    'Ref: Feedback Content - employer': 'นายจ้าง',
    'Ref: Feedback Content - comment': 'เกิดอะไรขึ้น',
  },
  AccountStartPage: {
    'Ref: Header': 'เลือกตำแหน่งของคุณสำหรับการเริ่มต้น',
    'Ref: Body': 'ค้นหาตำแหน่งได้อย่างรวดเร็วโดยเปิดใช้งานการแปลการสแกนรหัส QR หรือค้นหาตามชื่อ',
    'Ref: LetsGo button': 'ไปกันเถอะ',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'การลงทะเบียน' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'เลือกไซต์ด้วยสแกนเนอร์ QR',
    'Ref: Page title': 'เลือกตำแหน่งของคุณ',
    'Ref: Select site with code': 'เลือกไซต์ด้วยรหัส',
    'Ref: Enter site code': 'ป้อนรหัสไซต์',
    'Ref: Scanner error header': 'ไม่พบไซต์',
    'Ref: Not found': 'รหัสนี้ไม่ถูกต้องในภูมิศาสตร์ที่เลือก',
    'Ref: Generic error': 'บางอย่างผิดพลาด',
    'All other sites': 'เว็บไซต์อื่น ๆ ทั้งหมด',
    'Sites in your country': 'เว็บไซต์ในประเทศของคุณ',
    'Ref: Unauthorized error': 'คำขอที่ไม่ได้รับอนุญาตเนื่องจากระดับการ จำกัด ไซต์',
    'Ref: Forbidden':
      'ขออภัยคุณไม่มีสิทธิ์ในการเข้าถึงเว็บไซต์นี้ หากต้องการจัดการการเข้าถึงโปรดติดต่อศูนย์บริการ Pilbara ที่ 1800 992 777',
    "Ref: We didn't find the site.": 'เราไม่พบไซต์',
    'Ref: We recommend some solutions:': 'เราขอแนะนำวิธีแก้ปัญหาบางอย่าง:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. ลองเพิ่มไซต์ของคุณด้วยเครื่องสแกน QR หรือด้วยความช่วยเหลือของปุ่มด้านล่าง',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. ออกจากระบบและเข้าสู่ระบบโดยเลือกประเทศที่ถูกต้อง',
    'Ref: The currently selected country is:': 'ประเทศที่เลือกในปัจจุบันคือ:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'การลงทะเบียน',
    'Ref: Form title': 'กรุณาลงทะเบียนเพื่อดำเนินการต่อ',
    'Confirm password': 'ยืนยันรหัสผ่าน',
    'Email is not valid': 'อีเมลไม่ถูกต้อง',
    'passwords do not match': 'รหัสผ่านไม่ตรงกัน.',
    'Ref: Password strength':
      'รหัสผ่านของคุณต้องมีความยาวอย่างน้อย 8 อักขระมีอย่างน้อยหนึ่งหมายเลขหนึ่งตัวพิมพ์ใหญ่หนึ่งตัวพิมพ์เล็กหนึ่งตัวและอักขระพิเศษหนึ่งตัว',
    'Sign up': 'ลงชื่อ',
    'Continue as guest': 'ยังคงเป็นแขกรับเชิญ',
    'Already have an account? Login': 'มีบัญชีอยู่แล้ว? เข้าสู่ระบบ',
    'Ref: Registration success title': 'ขอบคุณสำหรับการลงทะเบียน',
    'Ref: Registration success content':
      'บัญชีของคุณจะถูกสร้างขึ้นเร็ว ๆ นี้ คุณจะได้รับอีเมลยืนยันการสร้าง',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'กรุณาเข้าสู่ระบบ/ลงทะเบียน',
    'Ref: Password strength':
      'รหัสผ่านของคุณจะต้องมีความยาวอย่างน้อย 8 ตัวอักษรมีอย่างน้อยหนึ่งหมายเลขตัวพิมพ์ใหญ่หนึ่งตัวพิมพ์เล็กหนึ่งตัวและอักขระพิเศษหนึ่งตัว',
    Stamps: 'แสตมป์',
    'Order history': 'ประวัติความเป็นมา',
    'to earn points': 'เพื่อรับคะแนน',
    'to see order history': 'เพื่อดูประวัติคำสั่งซื้อ',
    'to place an order': 'การสั่งซื้อสินค้า',
    'Sign up': 'ลงชื่อ',
    Login: 'เข้าสู่ระบบ',
    'Email is not valid': 'อีเมลไม่ถูกต้อง',
    'passwords do not match': 'รหัสผ่านไม่ตรงกัน.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'ขอบคุณ',
    'Ref: Body':
      'คุณเกือบจะเสร็จแล้ว! คุณจะได้รับอีเมลยืนยันตามที่อยู่ที่ให้ไว้ในไม่ช้า เปิดอีเมลนี้และคลิกลิงก์เพื่อเปิดใช้งานบัญชีของคุณ',
    'Ref: Enter': 'ดำเนินการต่อ',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'เกิดข้อผิดพลาด',
    'Ref: Generic Error Body': 'โปรดลองอีกครั้งในภายหลังหรือติดต่อฝ่ายช่วยเหลือของคุณ',
    'Ref: Missing IndexDb support':
      'ขออภัยคุณไม่สามารถเข้าถึงแอปพลิเคชันในโหมด Incognito หรือไม่มีการสนับสนุน IndexDB โปรดลองใหม่กับเบราว์เซอร์อื่น',
    'Ref: Resource not found': 'เนื้อหาที่คุณพยายามเข้าถึงไม่มีอยู่',
    'Ref: Permission denied - SITE_HIDDEN': 'เนื้อหาที่คุณพยายามเข้าถึงเป็นของไซต์ส่วนตัว',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'เนื้อหาที่คุณพยายามเข้าถึงไม่ได้รับอนุญาตสำหรับบัญชีของคุณ',
    'Ref: TabTitle': 'ข้อผิดพลาด',
    'Ref: Site link invalid':
      'ลิงค์นี้ไม่ถูกต้องเลือกไซต์อื่นหรือติดต่อฝ่ายช่วยเหลือหากปัญหายังคงมีอยู่',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'อ๊ะ! {robot_emoji}',
    'Ref: Error title': 'บางอย่างผิดพลาด!',
    'Ref: Error body': 'ลองรีเฟรชหน้านี้หรือติดต่อ ผู้ช่วยเหลือ หากปัญหายังคงมีอยู่',
    'Ref: Error ID:': 'ID ข้อผิดพลาด: {errorId}',
    'Ref: Back to home': 'กลับไปที่บ้าน',
  },
  Child: {
    'Ref: Route Not Found':
      'บริการที่คุณกำลังมองหาไม่มีอยู่ในเว็บไซต์นี้ โปรดลองอีกครั้งในภายหลังหรือติดต่อฝ่ายช่วยเหลือของคุณ',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'ขอแสดงความยินดีด้วยรหัสผ่านใหม่ของคุณได้รับการบันทึกเรียบร้อยแล้วตอนนี้คุณสามารถเข้าถึงบริการทั้งหมดของเราจากบัญชีของคุณ',
    Welcome: 'ยินดีต้อนรับ',
    'Ref: Welcome message':
      'ตอนนี้คุณได้ลงทะเบียนกับแอปพลิเคชัน Bite ใหม่ที่ประสบความสำเร็จและสามารถค้นพบและเพลิดเพลินกับบริการทั้งหมดของเราต่อไป',
  },
  PasswordChange: {
    'Change Password': 'เปลี่ยนรหัสผ่าน',
    Welcome: 'ยินดีต้อนรับ',
    'Ref: Password message': 'รหัสผ่านของคุณจะต้องมีการเปลี่ยนแปลงเนื่องจากไม่ปลอดภัยพอ',
  },
  AccountPasswordChange: {
    'Change your password here': 'เปลี่ยนรหัสผ่านที่นี่',
    'Your old password': 'รหัสผ่านเก่าของคุณ',
    'Type in your new password': 'พิมพ์รหัสผ่านใหม่ของคุณ',
    'Re-type in your new password': 'ประเภทรหัสผ่านใหม่ของคุณอีกครั้ง',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'รหัสผ่านของคุณเปลี่ยนไป' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'เกิดข้อผิดพลาดดังนั้นการเปลี่ยนแปลงของคุณจึงไม่ได้บันทึกไว้ กรุณาลองอีกครั้ง.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'รีเซ็ตรหัสผ่าน',
    'Ref: Provide email':
      'โปรดระบุที่อยู่อีเมลที่ใช้เมื่อลงทะเบียนบน {app_name} อีเมลจะถูกส่งถึงคุณเพื่อรีเซ็ตรหัสผ่าน',
    'Ref: Something happened': 'มีบางอย่างเกิดขึ้น โปรดลองอีกครั้งในภายหลัง.',
  },
  ResetEmailSent: {
    'Ref: New email sent': 'เราได้ส่งอีเมลใหม่ โปรดรอ 30 วินาทีเพื่อกลับอีกครั้ง',
    'Go to login page': 'ไปที่หน้าเข้าสู่ระบบ',
    'Resend email': 'ส่งอีเมลกลับ',
    'Ref: Email sent title': 'ส่งอีเมลรีเซ็ตรหัสผ่านให้คุณแล้ว',
    'Ref: TabTitle': 'รีเซ็ตรหัสผ่าน',
    'Ref: Email sent msg':
      'ลิงค์รีเซ็ตถูกส่งไปยังที่อยู่ของคุณ {email_address} โปรดรอ 30 วินาทีก่อนที่จะพยายามส่งต่อ',
  },
  ResetPassword: { 'Enter your new password': 'ป้อนรหัสผ่านใหม่ของคุณ' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'รหัสผ่านของคุณถูกเปลี่ยนเรียบร้อยแล้ว',
    'Go to login page': 'ไปที่หน้าเข้าสู่ระบบ',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'การรีเซ็ตรหัสผ่านล้มเหลว',
    'Ref: Error': 'เกิดข้อผิดพลาดโปรดลองอีกครั้ง',
    'Reset your password': 'รีเซ็ตรหัสผ่านของคุณ',
    'Go to login page': 'ไปที่หน้าเข้าสู่ระบบ',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'นโยบายความเป็นส่วนตัว',
    'Ref: Privacy Policy Body':
      'ด้วยการเข้าถึงแพลตฟอร์มนี้ Sodexo SA และเอนทิตีของ Sodexo ในท้องถิ่นจะประมวลผลข้อมูลส่วนบุคคลของคุณเพื่อจัดการบัญชีของคุณอย่างน้อยและเพื่อให้บริการและข้อเสนอที่มีให้ผ่านแพลตฟอร์ม หากคุณต้องการข้อมูลเพิ่มเติมเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของคุณใครจะเข้าถึงได้และวิธีการใช้สิทธิ์ที่คุณมีในข้อมูลส่วนบุคคลของคุณโปรดปรึกษานโยบายความเป็นส่วนตัวด้านล่างและมีอยู่ตลอดเวลาในบัญชีของคุณ',
    'Ref: Continue': 'ดำเนินการต่อ',
    'Ref: Read Privacy Policy': 'อ่านนโยบายความเป็นส่วนตัว',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'ไปเก็บ' },
  SharePage: { 'Ref: Select a site': 'เลือกไซต์' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'หากต้องการดูนโยบายความเป็นส่วนตัวโปรดเลือกประเทศของคุณ',
  },
};
export default labels;
