import { screen, cleanup } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { ISite } from '../../../Sites/types/sites.types';
import { useGetFacilitiesListQuery } from '../../api/index';

import FacilitiesList from './FacilitiesList';

jest.mock('../../../config', () => {
  return {
    serviceTypes: {
      Facilities: {
        path: '/facilities',
        defaultModule: 'Facilities',
        label: 'Facilities',
      },
      'Food.Order': {
        path: '/order',
        excludeSubPaths: ['/history'],
        defaultModule: 'Order',
        label: 'Order',
      },
      Review: {
        path: '/review',
        defaultModule: 'Review',
        label: 'Feedback',
      },
    },
    modulesConfig: {
      Facilities: {
        serviceType: 'Facilities',
      },
      Order: {
        serviceType: 'Food.Order',
      },
      Review: {
        serviceType: 'Review',
      },
    },
  };
});

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () =>
    ({
      id: siteId,
      currency: {
        isoCode: 'EUR',
      },
    } as ISite),
}));

jest.mock('@/modules/config', () => ({
  ...jest.requireActual('@/modules/config'),
  SERVICE: {
    ...jest.requireActual('@/modules/config').SERVICE,
    REVIEW: 'Review',
  },
}));

const siteId = '123';

const facilitiesFromEndpoint = [
  {
    id: '1',
    title: 'Mess Menu',
    description: 'Mess Menu (Food-Non-Retail)',
    images: [],
    isFoodFacilityType: true,
    facilityType: {
      id: '555580000',
      name: 'Food - Non-retail',
    },
  },
  {
    id: '2',
    title: 'Chillie Restuarant',
    description: 'Chillie Restuarant (Food - Retail)',
    images: [],
    isFoodFacilityType: true,
    facilityType: {
      id: '555580001',
      name: 'Food - Retail',
    },
  },
];

jest.mock('../../api/index', () => ({
  ...jest.requireActual('../../api/index'),
  useGetFacilitiesListQuery: jest.fn().mockReturnValue({
    data: { facilities: facilitiesFromEndpoint },
    isLoading: false,
  }),
}));

describe('FacilitiesList', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let facilities: HTMLElement[];

    beforeEach(() => {
      (useGetFacilitiesListQuery as jest.Mock).mockReturnValue({
        data: { facilities: facilitiesFromEndpoint },
        isLoading: false,
      });
    });

    renderComponent(FacilitiesList, {});

    it('should have the correct number of facilities listed', () => {
      facilities = screen.getAllByTestId(/^facility-tile-\d+$/);
      expect(facilities.length).toEqual(2);
    });
  });

  describe('on filtering', () => {
    let input: HTMLElement;
    let facilities: HTMLElement[];

    beforeEach(() => {
      (useGetFacilitiesListQuery as jest.Mock).mockReturnValue({
        data: { facilities: facilitiesFromEndpoint },
        isLoading: false,
      });
    });

    renderComponent(FacilitiesList, {});

    beforeEach(async () => {
      input = screen.getByTestId(
        'facility-list-search-bar-list-page-search-filter-bar-input-field'
      );
      await userEvent.type(input, 'Mess');
      await new Promise((r) => setTimeout(r, 400));
    });

    it('should have search input', () => {
      expect(screen.getByPlaceholderText('Search')).toBeTruthy();
    });

    it('should have the correct number of facilities listed', () => {
      facilities = screen.getAllByTestId(/^facility-tile-\d+-actions$/);
      expect(facilities.length).toEqual(1);
    });

    it('should filter the correct facility', () => {
      expect(screen.getByText('Mess Menu')).toBeTruthy();
    });
  });
});
