const labels = {
  Success: {
    'Ref: Body':
      'Değerleri zamanınızdan ayırıp bu anketi tamamladığınız için teşekkür ederiz, bu anketler hizmetlerimizi geliştirmeye yardımcı olacaktır.',
    'Ref: Tabtitle': 'Anket tamamlandı',
    'Take another survey': 'Başka bir ankete katılın',
  },
  Survey: {
    'Ref: Page title': 'Anket Detayları',
    'Please answer all mandatory questions as identify by an asterisk':
      'Anket / Lütfen yıldız işaretiyle belirtilen tüm zorunlu soruları yanıtlayın',
    'Ref: Take another survey': 'Başka bir ankete katılın',
    'Ref: Go to Home': 'Ana Sayfaya Git',
  },
  SurveysList: {
    'Ref: Page title': 'Anketler',
    'Ref: Available until': 'Şu tarihe kadar kullanılabilir',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Şu anda {site name} sitesi için anketleri görüntülüyorsunuz.',
  },
};
export default labels;
