const labels = {
  All: {
    'Ref: AccommodationRequestTitle': '宿泊施設のリクエスト',
    'Ref: Describe your request': 'あなたの要求を説明してください',
    'Ref: Confirm your request': 'リクエストを確認してください',
    'Ref: Your request': 'あなたの要求',
    'Ref: Summary': 'まとめ',
    'Ref: Next': '次',
    'Ref: Submit': '提出する',
    'Ref: Me': '自分',
    'Ref: Arrival date': '到着日',
    'Ref: Arrival time': '到着時刻',
    'Ref: Departure date': '出発日',
    'Ref: Departure time': '出発時間',
    'Ref: request raised for': 'このリクエストの影響を受けた人',
    'Ref: phoneNumber': '電話番号',
    'Ref: arrivalDate': '到着',
    'Ref: departureDate': '出発',
    'Ref: description': 'コメント',
    'Ref: open': '開ける',
    'Ref: pending': '保留中',
    'Ref: refused': '拒否した',
    'Ref: closed': '閉まっている',
    'Ref: all': '全て',
    'Ref: Arrival': '到着',
    'Ref: Validate': '検証',
    'Ref: site': '立地',
    'Ref: Update us': '更新してください',
    'Ref: email of guest': 'ゲストのメールアドレス',
    'Ref: GuestAccommodationRequestTitle': 'ゲストのリクエスト',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': '新しいリクエスト',
    'Ref: Status': '状態',
    'Ref: Date': '日付',
    'Ref: Coming arrivals': '来る到着',
    'Ref: Past arrivals': '過去の到着',
  },
  CreateForm: {
    'Ref: Guest phone number': 'ゲストの電話番号',
    'Ref: Your phone number': 'あなたの電話番号',
    'Ref: The phone number': '電話番号',
    'Ref: PhoneNumber error msg': '電話番号は無効です',
    'Ref: Comment error msg': '文字の最大数は250です',
    'Ref: Guest first name': 'ゲスト名',
    'Ref: Guest last name': 'ゲストの姓',
    'Ref: request for': 'このリクエストは誰ですか？',
    'Ref: request for me': '自分',
    'Ref: request for guest': '他の誰か',
  },
  Details: {
    'Ref: AccommodationRequestDetails': '宿泊施設のリクエストの詳細',
    'Ref: Timeline': 'タイムライン',
    'Ref: No updates placeholder': 'このリクエストの更新はありません',
  },
  CreateThankYou: {
    'Ref: Thank you': 'ありがとう',
    'Ref: Success text':
      '私たちはあなたの要求を受け取り、今それを調べています。すぐにご連絡いたします。',
    'Ref: Go to home': '家に行く',
    'Ref: Create another request': '別のリクエストを作成します',
  },
  CreateError: {
    'Ref: Create error title': 'おっとっと！',
    'Ref: Create error text': 'エラーが発生しました。もう一度お試しください。',
    'Ref: Retry': 'もう一度やり直してください',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'あなたのリクエストに関する新しい要素を更新してください。そうすれば、私たちがあなたを最もよくサポートできるようにしてください。',
  },
};
export default labels;
