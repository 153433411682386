const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Jūsų užklausa',
    'Ref: Summary': 'Santrauka',
    'Ref: Request category': 'Užklausų kategorija',
    'Ref: Person affected': 'Asmuo, paveiktas šio prašymo',
    'Ref: Your phone number': 'Telefono numeris',
    'Ref: Description': 'apibūdinimas',
    'Ref: Service Requests': 'Paslaugų užklausos',
    'Ref: Request Service': 'Užklausos tarnyba',
    'Ref: Success title': 'Jūsų prašymas buvo sukurtas',
    'Ref: Error title': 'Oi!',
    'Ref: Error subtitle': 'Įvyko klaida, bandykite dar kartą.',
    'Ref: Try again': 'Bandyk iš naujo',
    'Ref: you pronoun': '(tu)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Tai yra jūsų darbo vietos palaikymo stebulė',
    'Ref: Tutorial: tabname': 'Užklausos',
    'Ref: Tutorial: body':
      'Mes esame čia, kad jums padėtume. Prašome registruoti vieną užklausą kiekviename įraše.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Jūs prašote paslaugos svetainėje {siteName}.',
    'Ref: Building': 'Pastatas',
    'Ref: Room': 'Kambarys',
    'Ref: Description help text': 'Kuo išsamesnis esate, tuo geriau galime jus palaikyti.',
    'Ref: Next': 'Kitas',
    'Ref: Your request': 'Jūsų užklausa',
    'Ref: PhoneNumber error msg': 'Telefono numeris negalioja',
    'Ref: Type at least 3 characters to search': 'Norėdami ieškoti, įveskite bent 3 simbolius',
    'Ref: No affected person results': 'Nė vienas asmuo, atitinkantis reikalaujamus kriterijus',
    'Ref: Required': '* Reikalaujama',
  },
  Summary: {
    'Ref: Location': 'Vieta',
    'Ref: Attachment': 'Priedai',
    'Ref: Submit': 'Pateikti',
    'Ref: No affected person results': 'Nė vienas asmuo, atitinkantis neprašytus kriterijus',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Paslaugos užklausa',
    'Ref: createdOn': 'Sukurtas',
    'Ref: summary': 'Santrauka',
    'Ref: category': 'Užklausų kategorija',
    'Ref: request raised for': 'Asmuo, paveiktas šio prašymo',
    'Ref: phoneNumber': 'Telefono numeris',
    'Ref: location': 'Telefono numeris',
    'Ref: description': 'apibūdinimas',
    'Ref: attachments': 'Priedai',
  },
};
export default labels;
