import { TextareaChangeEventDetail } from '@ionic/core';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  useServiceRequestCommentMutation,
  useGetServiceRequestQuery,
  useGetServiceRequestsQuery,
} from '../../api/index';
import { pagePaths } from '../../config';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';
import { CurrentServiceRequest } from '../../types/types';

import { Woman2Illustration } from '@/assets/illustrations';
import Button from '@/components/atoms/Button';
import Textarea from '@/components/atoms/Textarea/Textarea';
import { TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import { AttachmentData } from '@/components/organisms/Attachments/Attachments.types';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { validateForm, FormValidationAlert } from '@/components/validateForm';
import { State } from '@/types/state.types';

const MAX_FILES_NUM = 2;
const MAX_FILE_SIZE = 1024 * 1024 * 10;

const getFormValidationRules = (commentBody: string) => {
  //params: use the label keys directly
  return {
    Comment: () => !!commentBody?.length,
  };
};

const Comment = () => {
  const history = useHistory();
  const { label } = useServiceRequestTranslation(__filename);
  let { id: currentServiceRequestId } = useParams<{ id: string }>();
  const [comment, { isSuccess: commentSubmissionOutcome }] = useServiceRequestCommentMutation({});
  const [currentServiceRequest, setCurrentServiceRequest] =
    useState<CurrentServiceRequest | undefined>();
  const currentUser = useSelector((state: State) => state.Core?.user);
  const isLocallyCreatedSR = currentServiceRequestId.match(/LOCAL-/) !== null;
  const { data: serviceRequest } = useGetServiceRequestQuery(
    {
      id: currentServiceRequestId,
      emailId: currentUser?.email,
    },
    {
      skip: isLocallyCreatedSR,
    }
  );
  const { data: serviceRequests } = useGetServiceRequestsQuery({}, { skip: !isLocallyCreatedSR });

  useEffect(() => {
    if (serviceRequest) {
      setCurrentServiceRequest(serviceRequest as unknown as CurrentServiceRequest);
    }

    if (serviceRequests) {
      const request = serviceRequests.find((r) => r.id === currentServiceRequestId);
      if (request) setCurrentServiceRequest(request as unknown as CurrentServiceRequest);
    }
  }, [serviceRequest, serviceRequests, currentServiceRequestId]);

  const [commentBody, setCommentBody] = useState('');
  const [attachments, setAttachments] = useState<AttachmentData[]>([]);
  const [showFormValidationAlert, setShowFormValidationAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const contentRef = useRef();

  useEffect(() => {
    if (commentSubmissionOutcome === true && currentServiceRequestId) {
      history.push(pagePaths['Details'].replace(':id', currentServiceRequestId));
    }
  }, [commentSubmissionOutcome, currentServiceRequestId, history]);

  if (!currentServiceRequest) {
    //TODO: add skeleton
    return <LoadingPage />;
  }
  const handleChange = (e: CustomEvent<TextareaChangeEventDetail>) =>
    setCommentBody(e.detail.value || '');

  const { isValid: isFormValid, missingInfo: formMissingInfo } = validateForm(
    getFormValidationRules(commentBody)
  );

  const submitComment = async () => {
    if (isFormValid) {
      setSubmitting(true);
      await comment({
        emailId: currentUser?.email,
        serviceRequestId: currentServiceRequestId,
        commentBody,
        userName: `${currentUser?.firstName} ${currentUser?.lastName}`,
        images: attachments.map((attachment: AttachmentData) => ({
          id: 'id',
          fileName: attachment.file.name,
          content: attachment.data?.substring(
            attachment.data?.indexOf('base64,')! + 'base64,'.length
          ),
        })),
      });
      setSubmitting(false);
    }
  };

  return (
    <SimpleFormPage title={label('Ref: Page title')} contentRef={contentRef}>
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              children: currentServiceRequest.displayTitle,
              tag: TITLE_TAG.H2,
            }}
          >
            <form>
              <Textarea
                id="commentBody"
                label={label('Ref: please update us with details')}
                required
                value={commentBody}
                onChange={handleChange}
              />
              <Attachments
                attachments={attachments}
                maxFilesNum={MAX_FILES_NUM}
                maxFileSize={MAX_FILE_SIZE}
                attach={setAttachments}
                data-testid="comment-attachments"
              />
            </form>
          </Card>
          <FormValidationAlert
            missingInfo={formMissingInfo}
            msgCodes={{}}
            show={showFormValidationAlert}
            onDidDismiss={() => {
              setShowFormValidationAlert(false);
            }}
            label={label}
          />
        </Column.Main>
        <Column.Complementary>
          <Woman2Illustration />
        </Column.Complementary>
        <ActionsBar inMainColumn>
          <Button
            onClick={submitComment}
            loading={submitting}
            data-testid="comment-submit-button"
            disabled={!isFormValid}
          >
            {label('submit', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default Comment;
