const labels = {
  All: {
    Region: 'Region nebo země',
    'Ref: Geography': 'Vyberte prosím svou zemi nebo region',
    'Ref: Country': 'Země',
    'Ref: Country tooltip': 'Poloha odpovídá zemi.',
    'Ref: Please select your country': 'Vyberte svou zemi',
    'First name': 'Jméno',
    'Last name': 'Příjmení',
    'Ref: Full name': 'Celé jméno',
    'Mobile number': 'Číslo mobilního telefonu',
    'Mobile phone': 'Mobilní telefon',
    Email: 'Emailová adresa',
    Password: 'Heslo',
    logout: 'Odhlásit se',
    'Not a new user? Log in': 'Nejste nový uživatel? Přihlásit se',
    'do not register, login': 'Nejste nový uživatel? Přihlásit se',
    'Ref: I agree': 'souhlasím',
    Register: 'Registrovat',
    'Ref: My language': 'Můj jazyk',
    'Ref: Kiosk Fail Title': 'Jejda! Něco se pokazilo!',
    'Ref: Kiosk Fail Description':
      'Vaše objednávka bohužel nebyla úspěšná. Zkuste to prosím znovu nebo požádejte zaměstnance, aby vám pomohl.',
    'Ref: Kiosk Fail Back': 'Zpět na předchozí stránku',
    'Ref: Kiosk Fail Action': 'Vyčistěte relaci a objednejte si znovu',
    Validate: 'Ověřit',
    'Ref: error, incorrect credentials': 'Tato pověření neodpovídají ve zvolené geografii.',
  },
  AccountPage: {
    'Ref: My Information': 'Moje informace',
    'Ref: Settings': 'Nastavení',
    'Ref: Email': 'E-mailem',
    'Ref: Current site': 'Aktuální web',
    'Ref: Choose site': 'Vyberte web',
    'Ref: Personal interests': 'Osobní zájmy',
    'Ref: Food preferences': 'Preference jídla',
    'Ref: Password': 'Heslo',
    'Ref: Page title': 'Účet',
    'Ref: Language': 'Jazyk',
    'Ref: Communication preferences': 'Komunikační preference',
    'Ref: Terms & conditions': 'Všeobecné obchodní podmínky',
    'Ref: Privacy policy': 'Zásady ochrany osobních údajů',
    'Ref: Delete my account': 'Smazat můj účet',
    'Ref: Confirm deletion': 'Jste si jisti, že chcete smazat svůj účet?',
    'Ref: Request deletion': 'Jste si jisti, že chcete požádat o vymazání vašeho účtu?',
    'Ref: Deletion description': 'Vaše žádost bude řešena podle vaší místní nařízení.',
    Placeholder: 'Napište „smazat“',
    'Ref: Delete description': 'Vaše data budou odstraněna. Tato akce je nevratná.',
    delete: 'vymazat',
    'Ref: Terms of sale': 'Podmínky prodeje',
    'Ref: Imprint': 'Otisk',
    'Ref: Cookie Settings': 'Nastavení cookie',
    'Ref: Support': 'Podpěra, podpora',
    'Ref: Register or login': 'Zaregistrujte se nebo přihlaste',
    'Ref: Filtering Preferences': 'Filtrování preferencí',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Určitě odstraníte svůj účet?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Napište "{word}" do vstupního pole a potvrďte spodní část níže. Tato akce je nevratná.',
    'Ref: Write "{word}"': 'Napište "{word}"',
    'Ref: Delete my account': 'Smazat můj účet',
    'Ref: Words are not matching': 'Slova se neshodují',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Vyberte si polohu pro start',
    'Ref: Choose location':
      'Najděte rychle umístění aktivací lokalizace, skenováním QR kódu nebo vyhledáváním podle názvu',
    'Ref: next step': 'Pojďme',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Přihlásit se',
    'Ref: identify your location': 'Identifikujte svou polohu.',
    'Ref: option 1 - scan QR': 'Možnost 1: Skenování registrace QR kód',
    'Ref: option 2 - enter code': 'Možnost 2: ručně zadejte kód umístění',
    'Ref: button to open qr code scanner modal': 'Mám QR: Skenuj to',
    'Ref: button to open site code input modal': 'Vyberte web s kódem',
    'Ref: getting help with site identifier - support desk':
      'Pokud nejste jasné, jak pokračovat, obraťte se na servis podpory.',
    'Ref: getting help with site identifier - support form': 'Můžete nám také poslat poznámku.',
    'Location code': 'Poloha Kód',
    'Ref: location code field placeholder': 'Kód vaší polohy zde',
    'Ref: Scanner header': 'Skenování QR kód',
    'Ref: site code input header': 'Přidejte web s kódem',
    'Ref: not valid code': 'Tento kód není platný ve vybrané geografii',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Registrace',
    'Ref: collected info': 'souhrn',
    'Ref: additional info': 'Tvé informace',
    'Confirm password': '*Potvrďte heslo',
    company: 'Kdo je váš zaměstnavatel?',
    location: 'Umístění',
    username: 'Uživatelské jméno',
    register: 'Přihlásit se',
    'Ref: password strength':
      'Vaše heslo musí mít délku nejméně 8 znaků, obsahují alespoň jedno číslo, jedna velká písmena, jedna malá písmena a jeden speciální znak.',
    'passwords do not match': 'Hesla se neshodují.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Komunikační preference',
    'Ref: Things happen while you are away': 'Věci se stávají, když jste pryč',
    'Ref: Would you like to receive update about offers and promotions?':
      'Chtěli byste dostávat aktualizace o nabídkách a propagačních akcích?',
    'Ref: Would you like to hear about content we think you may like?':
      'Chtěli byste slyšet o obsahu, o kterém si myslíte, že se vám bude líbit?',
    Yes: 'Ano',
    No: 'Ne',
    apply: 'aplikovat',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Detaily účtu' },
  DeletionFailure: {
    'Ref: Body':
      'Došlo k chybě a vaše žádost o odstranění účtu nebyla předložena. Pokud tato chyba přetrvává, kontaktujte prosím svou pomoc.',
    'Go to account': 'Přejít na účet',
  },
  GenericFailurePage: {
    'Ref: Title': 'Něco se pokazilo',
    'Ref: Body':
      'Promiň, něco se pokazilo. Pokud tento problém přetrvává, obraťte se na podporu na místě.',
  },
  HomePage: {
    notifications: 'oznámení',
    basket: 'Košík',
    'Ref: Tabtitle': 'Domov',
    'contact us': 'Kontaktujte nás',
    'Ref: You are logged in as a guest user': 'Jste přihlášeni jako hostující uživatel',
    'Ref: Good morning': 'Dobré ráno',
    'Ref: Good afternoon': 'Dobré odpoledne',
    'Ref: Good evening before midnight': 'Dobrý večer',
    'Ref: Good evening after midnight': 'Dobrý večer',
    'Ref: Search': 'Vyhledávání',
    'Ref: products button aria': 'Nalezeno {results_form} v rámci produktů',
    'Ref: menus button aria': 'Nalezeno {results_form} v nabídce',
    'Ref: facilities button aria': 'Nalezeno {results_form} v rámci zařízení',
    'Ref: result': 'výsledek',
    'Ref: results': 'Výsledek',
    'Ref: Menus': 'Nabídky',
    'Ref: No results title': 'Jejda!',
    'Ref: No results body': 'Žádné výsledky odpovídající vašemu vyhledávání',
    'Ref: Products': 'produkty',
    'Ref: Facilities': 'Zařízení',
    'Ref: See more results': 'Podívejte se na další výsledky vyhledávání',
    'Ref: Accessibility instructions':
      'Chcete -li začít používat vyhledávání, musíte na něj kliknout nebo stisknout Enter a zaostřit.',
    'Ref: See more': 'Vidět víc',
    'Ref: singular form of products': 'produkt',
    'Ref: singular form of menus': 'Jídelní lístek',
    'Ref: singular form of facilities': 'zařízení',
    'Ref: Search results for': 'Výsledky hledání pro',
  },
  LanguagePage: {
    apply: 'aplikovat',
    'My language': 'Můj jazyk',
    'Select in which language you would like to read our app':
      'Vyberte, ve kterém jazyce si chcete přečíst naši aplikaci',
    'Ref: Page title': 'Jazyk',
  },
  LegalDocPage: {
    Acknowledge: 'Potvrdit',
    'Ref: Legal changed notification title': 'Byly upraveny „{legal_doc_type}“',
    'Ref: Legal changed notification content':
      'Před pokračováním v používání aplikace je prosím zkontrolujte.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Všeobecné obchodní podmínky',
    'Read privacy policy': 'Přečtěte si zásady ochrany osobních údajů',
    'Ref: Acknowledge legal docs': 'Přijímám tyto podmínky.',
    'Ref: Read and understood': 'Četl jsem a pochopil ',
    'Ref: No legal docs available - notice title': 'Již brzy',
    'Ref: No legal docs available - notice body':
      'Je mi líto, že tato aplikace není v současné době k dispozici ve vaší geografii.',
    'Ref: The privacy Policy': 'Zásady ochrany osobních údajů',
    'Ref: Legal changed notification title': 'Byly upraveny „{legal_doc_type}“',
    'Ref: Legal changed notification content':
      'Před pokračováním v používání aplikace je prosím zkontrolujte.',
  },
  LoginPage: {
    'Ref: Page title': 'Vítejte na {app name}',
    'Ref: Page subtitle': 'Přihlaste se pro pokračování',
    'Ref: Email field placeholder': 'Tvůj e-mail',
    'Ref: Email field note': 'Nikdy sdílíme svůj e-mail s kýmkoli.',
    'Ref: Password field title': 'Vaše heslo',
    register: 'Přihlásit se',
    'I forgot my password.': 'Zapomenuté heslo?',
    'I have trouble logging in': 'Máte potíže s přihlášením?',
    'start registration': 'Začněte registrace',
    'Ref: Register': 'Registrovat',
    'Ref: Hello': 'Ahoj {wave_emoji}, přihlaste se a pokračujte',
    'Ref: Login': 'Přihlásit se',
    'Ref: Forgot password?': 'Zapomenuté heslo?',
    'Ref: Connection issue?': 'Problém s připojením?',
    'Ref: Connection issue message':
      'Máte -li jakýkoli problém k registraci nebo přihlášení, kontaktujte prosím obvyklý personál na místě nebo podporu.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Dobrý den a vítejte na {site_name}', 'Ref: Begin': 'Začít' },
  NotificationsList: {
    'Ref: No notifications': 'Na okamžik nemáte žádná oznámení',
    'Ref: This week': 'Tento týden',
    'Ref: Earlier': 'Dříve',
    'Ref: Page title': 'Oznámení',
    'Ref: See more': 'Vidět víc',
    'Ref: Mark all read': 'Označte všechny čtení',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Podívejte se na všechna oznámení' },
  ServicesMenuPage: { 'Ref: Page title': 'Služby', navigate: 'navigovat' },
  DeletionSuccess: {
    'Ref: Title': 'Vymazání vašeho účtu bylo požadováno',
    'Ref: Content': 'Brzy to zvládneme. Můžete ztratit svůj přístup kdykoli.',
    'Log out': 'Odhlásit se',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Vrátíme se k vám',
    'Ref: Body': 'Je nám líto, že čelíte problémům. Velmi brzy vás budeme kontaktovat.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Máte potíže s přihlášením?',
    'Ref: Email field placeholder': 'Vaše emailová adresa',
    'Ref: Mobile phone field placeholder': 'Číslo mobilního telefonu',
    Company: 'Společnost',
    'Ref: Company field placeholder': 'Jméno společnosti',
    'Ref: Company field note':
      'Pokud jste konzultant, je to společnost, pro kterou pracujete, ne zaměstnavatel.',
    Location: 'Město nebo umístění',
    'Ref: Location field placeholder': 'Umístění vašeho pracoviště',
    'Ref: Location field note':
      'Pokud je mimo obydlenou oblast, neváhejte použít název, podle kterého se oblast běžně odkazuje.',
    Comment: 'Komentář',
    'Ref: Comment field placeholder': 'Co se pokazilo?',
    'Ref: Who is your employer?': 'Kdo je váš zaměstnavatel?',
    'Ref: What village are you staying at?': 'Ve které vesnici zůstáváte?',
    'Ref: Comment field note': 'Jakékoliv informace, které nám mohou pomoci vám pomoci!',
    'Ref: Feedback Content - geography': 'Zeměpis',
    'Ref: Feedback Content - fullName': 'název',
    'Ref: Feedback Content - email': 'E-mailem',
    'Ref: Feedback Content - phone': 'Telefon',
    'Ref: Feedback Content - company': 'Společnost',
    'Ref: Feedback Content - city': 'Název města / umístění',
    'Ref: Feedback Content - whatVillage': 'Vesnice',
    'Ref: Feedback Content - employer': 'Zaměstnavatel',
    'Ref: Feedback Content - comment': 'Co se pokazilo',
  },
  AccountStartPage: {
    'Ref: Header': 'Vyberte si polohu pro start',
    'Ref: Body':
      'Najděte rychle umístění aktivací lokalizace, skenováním QR kódu nebo vyhledáváním podle názvu',
    'Ref: LetsGo button': 'Pojďme',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Registrace' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Vyberte web se skenerem QR',
    'Ref: Page title': 'Vyberte si polohu',
    'Ref: Select site with code': 'Vyberte web s kódem',
    'Ref: Enter site code': 'Zadejte kód webu',
    'Ref: Scanner error header': 'Web nebyl nalezen',
    'Ref: Not found': 'Tento kód není platný ve vybrané geografii',
    'Ref: Generic error': 'Něco se pokazilo',
    'All other sites': 'Všechny ostatní stránky',
    'Sites in your country': 'Weby ve vaší zemi',
    'Ref: Unauthorized error': 'Neoprávněný požadavek z důvodu úrovně omezení webu',
    'Ref: Forbidden':
      'Je nám líto, nemáte správná oprávnění k přístupu k tomuto webu. Chcete -li zajistit přístup, kontaktujte prosím Service Center Pilbara na 1800 992 777',
    "Ref: We didn't find the site.": 'Web jsme nenašli.',
    'Ref: We recommend some solutions:': 'Doporučujeme některá řešení:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Zkuste přidat svůj web se skenerem QR nebo pomocí tlačítek níže',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Odhlaste se a přihlaste se zpět výběrem správné země',
    'Ref: The currently selected country is:': 'Aktuálně vybraná země je:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Registrace',
    'Ref: Form title': 'Zaregistrujte se prosím a pokračujte',
    'Confirm password': 'Potvrďte heslo',
    'Email is not valid': 'E -mail není platný',
    'passwords do not match': 'Hesla se neshodují.',
    'Ref: Password strength':
      'Vaše heslo musí být dlouhé nejméně 8 znaků, obsahuje alespoň jedno číslo, jedno velké písmena, jeden malý a jeden speciální znak.',
    'Sign up': 'Přihlásit se',
    'Continue as guest': 'Pokračovat jako host',
    'Already have an account? Login': 'Máte již účet? Přihlásit se',
    'Ref: Registration success title': 'Děkujeme vám za registraci',
    'Ref: Registration success content':
      'Váš účet bude vytvořen brzy. Obdržíte e -mail potvrzující vytvoření.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Přihlaste se/zaregistrujte se',
    'Ref: Password strength':
      'Vaše heslo musí mít délku nejméně 8 znaků, obsahují alespoň jedno číslo, jedna velká písmena, jedna malá písmena a jeden speciální znak.',
    Stamps: 'Známky',
    'Order history': 'Historie objednávek',
    'to earn points': 'získat body',
    'to see order history': 'Chcete -li vidět historii objednávky',
    'to place an order': 'umístit objednávku',
    'Sign up': 'Přihlásit se',
    Login: 'Přihlásit se',
    'Email is not valid': 'E -mail není platný',
    'passwords do not match': 'Hesla se neshodují.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Děkuji',
    'Ref: Body':
      'Jsi skoro hotový! Brzy obdržíte ověřovací e -mail na poskytnuté adrese. Otevřete tento e -mail a kliknutím na odkaz aktivujte svůj účet.',
    'Ref: Enter': 'Pokračovat',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Vyskytla se chyba',
    'Ref: Generic Error Body': 'Zkuste to prosím znovu později nebo kontaktujte svou pomoc.',
    'Ref: Missing IndexDb support':
      'Omlouvám se, nemůžete přistupovat k aplikaci v režimu Incognito nebo bez podpory IndexDB. Opakujte prosím další prohlížeč.',
    'Ref: Resource not found': 'Obsah, který se snažíte získat přístup, neexistuje.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Obsah, který se snažíte získat přístup, patří k soukromému webu.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Obsah, který se snažíte získat přístup, není pro váš účet autorizován.',
    'Ref: TabTitle': 'Chyba',
    'Ref: Site link invalid':
      'Tento odkaz je neplatný, vyberte jinou stránku nebo pokud problém přetrvává, kontaktujte pomoc.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Jejda! {robot_emoji}',
    'Ref: Error title': 'Něco se pokazilo!',
    'Ref: Error body':
      'Pokuste se tuto stránku obnovit nebo kontaktovat podporu, pokud problém přetrvává.',
    'Ref: Error ID:': 'ID chyby: {errorId}',
    'Ref: Back to home': 'Zpátky domů',
  },
  Child: {
    'Ref: Route Not Found':
      'Služba, kterou hledáte, není na tomto webu k dispozici. Zkuste to prosím znovu později nebo kontaktujte svou pomoc.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Gratulujeme, vaše nové heslo bylo úspěšně uloženo, nyní můžete získat přístup ke všem našim službám z vašeho účtu.',
    Welcome: 'Vítejte',
    'Ref: Welcome message':
      'Nyní jste se úspěšně zaregistrovali do nové aplikace Bite Application a můžete i nadále objevovat a užít si všechny naše služby.',
  },
  PasswordChange: {
    'Change Password': 'Změnit heslo',
    Welcome: 'Vítejte',
    'Ref: Password message': 'Vaše heslo je třeba změnit, protože není dostatečně bezpečné',
  },
  AccountPasswordChange: {
    'Change your password here': 'Změňte své heslo zde',
    'Your old password': 'Vaše staré heslo',
    'Type in your new password': 'Zadejte své nové heslo',
    'Re-type in your new password': 'Znovu se ve svém novém hesle',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'Vaše heslo bylo změněno' },
  AccountPasswordChangeFailure: {
    'Ref: Error message': 'Došlo k chybě, takže vaše změna nebyla uložena. Prosím zkuste to znovu.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Obnovit heslo',
    'Ref: Provide email':
      'Uveďte e -mailovou adresu použitá při registraci na {app_name}. Bude vám zasláno e -mail k resetování hesla.',
    'Ref: Something happened': 'Něco se stalo. Prosím zkuste to znovu později.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Poslali jsme nový e -mail. Počkejte prosím 30 sekund, než znovu znovu odejděte.',
    'Go to login page': 'Přejděte na přihlašovací stránku',
    'Resend email': 'Přeposlat email',
    'Ref: Email sent title': 'Byl vám zaslán e -mail s resetováním hesla',
    'Ref: TabTitle': 'Obnovit heslo',
    'Ref: Email sent msg':
      'Odkaz na resetování byl odeslán na vaši adresu {email_address}. Než se pokusíte znovu odeslat, počkejte 30 sekund.',
  },
  ResetPassword: { 'Enter your new password': 'Zadejte své nové heslo' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Vaše heslo bylo úspěšně změněno',
    'Go to login page': 'Přejděte na přihlašovací stránku',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Resetování hesla selhání',
    'Ref: Error': 'Došlo k chybě, zkuste to prosím znovu',
    'Reset your password': 'Obnovit heslo',
    'Go to login page': 'Přejděte na přihlašovací stránku',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Zásady ochrany osobních údajů',
    'Ref: Privacy Policy Body':
      'Přístupem k této platformě budou Sodexo SA a místní entita Sodexo zpracovat vaše osobní údaje, aby alespoň spravovaly váš účet a poskytovaly služby a nabídky dostupné prostřednictvím platformy. Pokud chcete více informací o zpracování vašich osobních údajů, kteří k nim budou mít přístup a jak uplatnit práva, která máte na svých osobních údajích, obraťte se na níže uvedené zásady ochrany osobních údajů a kdykoli na svém účtu k dispozici.',
    'Ref: Continue': 'Pokračovat',
    'Ref: Read Privacy Policy': 'Přečtěte si zásady ochrany osobních údajů',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Jít do obchodu' },
  SharePage: { 'Ref: Select a site': 'Vyberte web' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Chcete -li zobrazit zásady ochrany osobních údajů, vyberte prosím svou zemi.',
  },
};
export default labels;
