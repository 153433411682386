const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Audits',
    'Ref: status0': 'Open',
    'Ref: status1': 'Voltooid',
    'Ref: status2': 'Geannuleerd',
    'Ref: connected': 'Verbonden',
    'Ref: not connected': 'Niet verbonden',
    'Ref: synchronise data': 'Synchroniseer gegevens',
    'Ref: sync success': 'Gegevens met succes gesynchroniseerd',
    'Ref: Create new': 'Maak nieuw',
    'Ref: Audit unsynchronised': 'Niet gesynchroniseerd',
    'Ref: Audit synchronised': 'Gesynchroniseerd',
    'Ref: Unsynced error title': 'er is een fout opgetreden',
    'Ref: Unsynced error description':
      'Het lijkt uw eerste keer dat u toegang heeft tot de auditsmodule. Maak verbinding met netwerk en "synchroniseren" om te beginnen met auditing.',
    'Ref: Search all audits': 'Alle audits',
    'Ref: Search active audits': 'Open',
    'Ref: Search completed audits': 'Voltooid',
    'Ref: sync error': 'Synchronisatie is mislukt',
    'Ref: sync error details': 'Opnieuw proberen om opnieuw verbinding te maken',
    'Ref: no search audit results':
      'Helaas zijn er geen audits die overeenkomen met uw zoekopdracht',
    'Ref: no audit results': 'U hebt geen beschikbare audits, maak nieuw om te beginnen',
    'Ref: Search canceled audits': 'Geannuleerd',
    'Ref: Audit cancelled successfully': 'Audit met succes geannuleerd',
    'Ref: Cancel audit invite': 'Annuleren',
    'Ref: Error audit cancel': 'Audit is niet geannuleerd',
    'Ref: Cancel audit confirmation': 'Weet u zeker dat u de audit wilt annuleren?',
    'Ref: Confirm': 'Bevestigen',
    'Ref: Cancel audit': 'Annuleer de audit',
    'Ref: Change the location': 'Verander de locatie',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Auditgegevens',
    'Ref: questions': 'vragen',
    'Ref: question': 'vraag',
    'Ref: Additional Comments': 'Beschrijving',
    'Ref: Not found title': 'Oeps!',
    'Ref: Not found text': 'De audit die u probeert te openen, bestaat niet.',
    'Ref: Back to Audit list': 'Terug naar de auditlijst',
    'Ref: Success title': 'Bedankt!',
    'Ref: Success text': 'U hebt de audit voltooid',
    'Ref: submit': 'Indienen',
    'Ref: add attachment': 'Voeg een bijlage toe',
    'Ref: Unsupported elements':
      'Sorry, het lijkt erop dat deze audit inhoud heeft die de applicatie nog niet ondersteunt. We zullen eraan werken.',
    'Ref: form error': 'Niet alle vragen zijn volledig beantwoord',
    'Ref: form complete': 'Voltooid sinds',
    'Ref: attachment preview error': 'U moet online zijn om deze bijlage te zien',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Maak nieuw',
    'Ref: Location': 'Plaats',
    'Ref: Audit Stream Type': 'Stroom',
    'Ref: Audit': 'Audit',
    'Ref: Asset': 'Bezit',
    'Ref: Asset Name': 'Activanaam',
    'Ref: Is joint audit?': 'Is gezamenlijke audit?',
    'Ref: Who was present?': 'Wie was aanwezig?',
    'Ref: submit': 'Indienen',
    'Ref: Select the Location': 'Selecteer de locatie',
    'Ref: Select the Audit Stream Type': 'Selecteer het auditstreamtype',
    'Ref: Select the Audit': 'Selecteer de audit',
    'Ref: Select the Asset': 'Selecteer het actief',
    'Ref: form error': 'Niet alle vereiste velden zijn volledig ingevoerd',
    'Ref: Audit error': 'Bijgevoegde audit',
    'Ref: Joint Audit Participants error': 'Joint Audit -deelnemers',
    'Ref: Audit created': 'Audit gemaakt',
    'Ref: Audit field length error': 'Lengte mag niet hoger zijn dan 100 tekens',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Verander de locatie',
    'Ref: Location changed': 'Locatie veranderd',
    'Ref: Location': 'Plaats',
    'Ref: Select the Location': 'Selecteer de locatie',
    'Ref: Asset': 'Bezit',
    'Ref: Select the Asset': 'Selecteer het actief',
    'Ref: Asset Name': 'Activanaam',
    'Ref: submit': 'Indienen',
    'Ref: Audit field length error': 'Lengte mag niet hoger zijn dan 100 tekens',
  },
};
export default labels;
