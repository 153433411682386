const labels = {
  ContentDetails: {
    'Ref: Page title': 'Detalles del contenido',
    'See the event': 'Ver el evento',
    'Take the survey': 'Participe en la encuesta',
    Date: 'Fecha',
    Category: 'Categoría',
    'Ref: Content site different title': 'Este contenido no pertenece a su sitio actual.',
    'Ref: Content site different description':
      'Está viendo un contenido que no pertenece a su sitio actual. No podrá verlo en la lista de contenido.',
  },
  ContentList: {
    'Ref: Page title': 'Noticias',
    'featured (as in featured content)': 'Destacados',
    'Ref: category - all': 'Todos',
    'Ref: category - promotional': 'Promocional',
    'Ref: category - featured': 'Presentado',
    'Ref: category - other': 'Otro',
  },
  Widget: {
    'Ref: Carousel title': 'Noticias y Artículos',
    'Ref: Carousel title promo': 'Las noticias más importantes',
    'No info yet, come back later': 'No hay información todavía, vuelva más tarde 😉',
    'Ref: Content': 'Contenido',
    'Ref: Widget Title': 'Noticias',
    'Ref: MyVillage Carousel title promo': 'Promociones',
  },
};
export default labels;
