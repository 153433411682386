import { DefaultComponentProps } from '../../../types';

export enum COLUMN_ELEMENT {
  DIV = 'div',
  SECTION = 'section',
  ASIDE = 'aside',
}

export type ColumnProps = {
  element?: COLUMN_ELEMENT;
} & DefaultComponentProps;
