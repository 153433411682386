import momentjs from 'moment';
import { useCallback, useMemo } from 'react';

import { Calendar2Icon } from '../../../assets/icons';
import { SIZE } from '../../../constants';
import { formatDate } from '../../../helpers/dateTime';
import useToggle from '../../../helpers/hooks/useToggle';
import { TranslationProps } from '../../../localization/localization.types';
import Calendar from '../../molecules/Calendar';
import Modal from '../../organisms/Modal';
import Button from '../Button/Button';

import { DateFilter, Filtering } from './Filters.types';
import { getFilterApplicableOptions } from './helpers';

import { TestingPropsV2 } from 'src/types';

const CalendarFilter = ({
  filter,
  filtering,
  label,
  onChange,
  languageCode,
  'data-testid': testId,
}: {
  filter: DateFilter;
  filtering: Filtering;
  onChange: (filterId: string, strDate: string) => void;
  languageCode: string;
} & TestingPropsV2 &
  TranslationProps) => {
  const { state: showCalendar, toggleOn: openCalendar, toggleOff: hideCalendar } = useToggle(false);

  const applicableOptions = useMemo(
    () => getFilterApplicableOptions(filter, filtering),
    [filter, filtering]
  );

  //take the first value, no multiple values allowed
  const getCalendarValue = useCallback(() => {
    const applicableStrDate = Object.keys(filtering[filter.id] || {})[0];
    let applicableDate;
    if (!applicableStrDate) applicableDate = momentjs().toDate();
    else applicableDate = momentjs(applicableStrDate, 'YYYY-MM-DD').toDate();

    //tackle the edge case of invalid dates
    if (applicableDate.getTime()) return applicableDate;
  }, [filter.id, filtering]);

  //the calendar needs to be explicitly set as an option, and is identified by the option value
  const isCalendarActive = useMemo(() => {
    if (!filtering[filter.id]) return false;

    const isAnotherOptionActive = applicableOptions.reduce(
      (res, op) => {
        return res ? res : filtering?.[filter.id]?.[op.value];
      }, //fine as cannot conflit with the calendar option itself
      false
    );

    return isAnotherOptionActive ? false : !!Object.keys(filtering?.[filter.id]).length;
  }, [applicableOptions, filter.id, filtering]);

  return filtering[filter.id] ? (
    <>
      <Button
        look={isCalendarActive ? 'primary' : 'secondary_inline'}
        data-cy={'filter-option-expanded-calendar'}
        data-testid={`${testId}-filter-option-expanded-calendar`}
        onClick={openCalendar}
        size={SIZE.SMALL}
        affix={() => <Calendar2Icon />}
        srOnlyText={label('calendar')}
      >
        {isCalendarActive
          ? formatDate(
              momentjs(Object.keys(filtering[filter.id])[0], 'YYYY-MM-DD').toDate(),
              languageCode
            )
          : ''}
      </Button>
      {applicableOptions.map((option) => (
        <Button
          key={option.value}
          look={filtering[filter.id][option.value] ? 'primary' : 'secondary_inline'}
          data-cy={'filter-option-expanded'}
          data-testid={`${testId}-filter-option-expanded`}
          size={SIZE.SMALL}
          onClick={() => {
            onChange(filter.id, option.value);
          }}
        >
          {option.label}
        </Button>
      ))}

      <Modal
        title={label('calendar', { textTransform: 'capitalize' })}
        isOpen={showCalendar}
        id="calendar_modal"
        onDismiss={hideCalendar}
        data-testid={`${testId}-filter-modal`}
      >
        <Calendar
          value={getCalendarValue()}
          data-testid={`${testId}-filter-modal-calendar`}
          minDetail="month"
          minDate={filter?.minDate}
          maxDate={filter?.maxDate}
          onChange={(val: Date) => {
            const strDate = momentjs(val.getTime()).format('YYYY-MM-DD');
            onChange(filter.id, strDate);
            hideCalendar();
          }}
          locale={languageCode}
          tileClassName={filter?.tileClassName}
          tileDisabled={
            filter?.tileDisabled
              ? filter.tileDisabled //if the filter provides a function, use it
              : ({ date }) => {
                  //otherwise just use the min and max dates
                  if (date.getTime() < filter.minDate.getTime() || date > filter.maxDate)
                    return true;
                  return false;
                }
          }
        />
      </Modal>
    </>
  ) : null;
};

export default CalendarFilter;
