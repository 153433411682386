import classnames from 'classnames';
import { FC } from 'react';

import { TITLE_SIZE } from '../../atoms/Title';
import Slider from '../../molecules/Slider';

import { MiniWidgetProps } from './MiniWidget.types';

import styles from './MiniWidget.module.css';

const MiniWidget: FC<MiniWidgetProps> = ({
  id,
  title,
  children,
  className,
  'data-testid': dataTestId = 'miniWidget',
}) => {
  return (
    <div className={classnames(styles.container, className)} data-testid={dataTestId}>
      <Slider
        title={title}
        id={`slider_${id}`}
        slidesToScroll={1}
        slidesToShow={1}
        titleSize={TITLE_SIZE.SUBTITLES}
        dark
        dots
        swipe
      >
        {children}
      </Slider>
    </div>
  );
};

export default MiniWidget;
