const labels = {
  All: {
    Region: 'Region eller land',
    'Ref: Geography': 'Geografi',
    'Ref: Country': 'Land',
    'Ref: Country tooltip': 'Platsen motsvarar platslandet.',
    'Ref: Please select your country': 'Välj land',
    'First name': 'Förnamn',
    'Last name': 'Efternamn',
    'Ref: Full name': 'Fullständiga namn',
    'Mobile number': 'Mobilnummer',
    'Mobile phone': 'Mobiltelefon',
    Email: 'E-postadress',
    Password: 'Lösenord',
    logout: 'Logga ut',
    'Not a new user? Log in': 'Inte en ny användare? Logga in',
    'do not register, login': 'Inte en ny användare? Logga in',
    'Ref: I agree': 'jag godkänner',
    Register: 'Registrera',
    'Ref: My language': 'Mitt språk',
    'Ref: Kiosk Fail Title': 'hoppsan! Något gick fel!',
    'Ref: Kiosk Fail Description':
      'Tyvärr var din beställning inte framgångsrik. Försök igen eller be en anställd att hjälpa dig.',
    'Ref: Kiosk Fail Back': 'Tillbaka till föregående sida',
    'Ref: Kiosk Fail Action': 'Rensa sessionen och beställ igen',
    Validate: 'Bekräfta',
    'Ref: error, incorrect credentials': 'Dessa referenser matchar inte i den valda geografin.',
    'Ref: Forgot password?': 'Glömt ditt lösenord?',
    'Ref: Hello': 'Hej {wave_emoji}, Logga in för att fortsätta',
    'Ref: Form title': 'Registrera dig för att fortsätta',
    'Already have an account? Login': 'Har du redan ett konto? Logga in',
  },
  AccountPage: {
    'Ref: My Information': 'Min information',
    'Ref: Settings': 'Inställningar',
    'Ref: Email': 'E-post',
    'Ref: Current site': 'Aktuell webbplats',
    'Ref: Choose site': 'Välj en webbplats',
    'Ref: Personal interests': 'Personliga intressen',
    'Ref: Food preferences': 'Matpreferenser',
    'Ref: Password': 'Lösenord',
    'Ref: Page title': 'konto',
    'Ref: Language': 'Språk',
    'Ref: Communication preferences': 'Kommunikationspreferenser',
    'Ref: Terms & conditions': 'Allmänna Villkor',
    'Ref: Privacy policy': 'Integritetspolicy',
    'Ref: Delete my account': 'Ta bort mitt konto',
    'Ref: Confirm deletion': 'Är du säker på att du vill ta bort ditt konto?',
    'Ref: Request deletion': 'Är du säker på att du vill begära radering av ditt konto?',
    'Ref: Deletion description': 'Din begäran kommer att hanteras enligt din lokala förordning.',
    Placeholder: 'Skriv "Radera"',
    'Ref: Delete description': 'Dina data kommer att raderas. Denna åtgärd är irreversibel.',
    delete: 'radera',
    'Ref: Terms of sale': 'Försäljningsvillkor',
    'Ref: Imprint': 'Avtryck',
    'Ref: Cookie Settings': 'Kakinställningar',
    'Ref: Support': 'Stöd',
    'Ref: Register or login': 'Registrera eller logga in',
    'Ref: Filtering Preferences': 'Filtreringspreferenser',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Är du säker på att ta bort ditt konto?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Skriv "{word}" i inmatningsfältet och bekräfta med botten nedan. Denna åtgärd är irreversibel.',
    'Ref: Write "{word}"': 'Skriv "{word}"',
    'Ref: Delete my account': 'Ta bort mitt konto',
    'Ref: Words are not matching': 'Ord matchar inte',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Välj din plats för start',
    'Ref: Choose location':
      'Hitta snabbt en plats genom att aktivera lokaliseringen, skanna en QR -kod eller söka efter namn',
    'Ref: next step': 'Nu går vi',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Bli Medlem',
    'Ref: identify your location': 'Identifiera din plats.',
    'Ref: option 1 - scan QR': 'Alternativ 1: Skanna registreringen QR -kod som tillhandahålls',
    'Ref: option 2 - enter code': 'Alternativ 2: Ange din platskod manuellt',
    'Ref: button to open qr code scanner modal': 'Jag har en QR: låt oss skanna den',
    'Ref: button to open site code input modal': 'Välj webbplats med kod',
    'Ref: getting help with site identifier - support desk':
      'Om du inte är klar hur du går vidare, vänligen kontakta ditt supportdisk.',
    'Ref: getting help with site identifier - support form':
      'Du kan också skicka oss en anteckning.',
    'Location code': 'Platskod',
    'Ref: location code field placeholder': 'Din platskod här',
    'Ref: Scanner header': 'Skanna en QR -kod',
    'Ref: site code input header': 'Lägg till webbplats med kod',
    'Ref: not valid code': 'Denna kod är inte giltig i den valda geografin',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Registrering',
    'Ref: collected info': 'Sammanfattning',
    'Ref: additional info': 'Din information',
    'Confirm password': '*Bekräfta lösenord',
    company: 'Vem är din arbetsgivare?',
    location: 'Plats',
    username: 'Användarnamn',
    register: 'Bli Medlem',
    'Ref: password strength':
      'Ditt lösenord måste vara minst 8 tecken långt, innehåller minst ett nummer, en versaler, en gemener och ett speciellt tecken.',
    'passwords do not match': 'Lösenorden matchar inte.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Kommunikationspreferenser',
    'Ref: Things happen while you are away': 'Saker händer medan du är borta',
    'Ref: Would you like to receive update about offers and promotions?':
      'Vill du få uppdateringar om erbjudanden och kampanjer?',
    'Ref: Would you like to hear about content we think you may like?':
      'Vill du höra om innehåll vi tror att du kanske gillar?',
    Yes: 'Ja',
    No: 'Nej',
    apply: 'tillämpa',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Kontouppgifter' },
  DeletionFailure: {
    'Ref: Body':
      'Ett fel har inträffat och din begäran om borttagning av konton inte lämnades in. Om det här felet kvarstår, vänligen kontakta din kundtjänst.',
    'Go to account': 'Gå till konto',
  },
  GenericFailurePage: {
    'Ref: Title': 'Något gick fel',
    'Ref: Body':
      'Ett fel har inträffat och din begäran om borttagning av konton inte lämnades in. Om det här felet kvarstår, vänligen kontakta din kundtjänst.',
  },
  HomePage: {
    notifications: 'meddelanden',
    basket: 'Korg',
    'Ref: Tabtitle': 'Hem',
    'contact us': 'Kontakta oss',
    'Ref: You are logged in as a guest user': 'Du är inloggad som gästanvändare',
    'Ref: Good morning': 'God morgon',
    'Ref: Good afternoon': 'God eftermiddag',
    'Ref: Good evening before midnight': 'God kväll',
    'Ref: Good evening after midnight': 'God kväll',
    'Ref: Search': 'Sök',
    'Ref: products button aria': 'Hittade {results_form} inom produkter',
    'Ref: menus button aria': 'Hittade {results_form} inom menyer',
    'Ref: facilities button aria': 'Hittade {results_form} inom faciliteter',
    'Ref: result': 'resultat',
    'Ref: results': 'resultat',
    'Ref: Menus': 'Menyer',
    'Ref: No results title': 'hoppsan!',
    'Ref: No results body': 'Inga resultat som matchar din sökning',
    'Ref: Products': 'Produkter',
    'Ref: Facilities': 'Anläggningar',
    'Ref: See more results': 'Se fler sökresultat',
    'Ref: Accessibility instructions':
      'För att börja använda sökningen måste du klicka på den eller trycka på Enter när du har fokuserat.',
    'Ref: See more': 'Se mer',
    'Ref: singular form of products': 'produkt',
    'Ref: singular form of menus': 'meny',
    'Ref: singular form of facilities': 'anläggning',
    'Ref: Search results for': 'Sökresultat för',
  },
  LanguagePage: {
    apply: 'tillämpa',
    'My language': 'Mitt språk',
    'Select in which language you would like to read our app':
      'Välj på vilket språk du vill läsa vår app',
    'Ref: Page title': 'Språk',
  },
  LegalDocPage: {
    Acknowledge: 'Erkänna',
    'Ref: Legal changed notification title': '"{legal_doc_type}" har modifierats',
    'Ref: Legal changed notification content':
      'Vänligen granska dem innan du fortsätter med appen.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Allmänna Villkor',
    'Read privacy policy': 'Läs sekretesspolicyn',
    'Ref: Acknowledge legal docs': 'Jag accepterar dessa villkor.',
    'Ref: Read and understood': 'Jag har läst och förstått',
    'Ref: No legal docs available - notice title': 'Kommer snart',
    'Ref: No legal docs available - notice body':
      'Tyvärr verkar det som om den här applikationen för närvarande inte är tillgänglig i din geografi.',
    'Ref: The privacy Policy': 'sekretesspolicyn',
    'Ref: Legal changed notification title': '"{legal_doc_type}" har modifierats',
    'Ref: Legal changed notification content':
      'Vänligen granska dem innan du fortsätter med appen.',
  },
  LoginPage: {
    'Ref: Page title': 'Välkommen till {app name}',
    'Ref: Page subtitle': 'Logga in för att fortsätta',
    'Ref: Email field placeholder': 'Din email',
    'Ref: Email field note': 'Vi delar aldrig din e -post med någon.',
    'Ref: Password field title': 'Ditt lösenord',
    register: 'Bli Medlem',
    'I forgot my password.': 'Glömt ditt lösenord?',
    'I have trouble logging in': 'Jag har problem med att logga in',
    'start registration': 'starta registrering',
    'Ref: Register': 'Registrera',
    'Ref: Login': 'Logga in',
    'Ref: Connection issue?': 'Anslutningsproblem?',
    'Ref: Connection issue message':
      'Om du har problem för att registrera dig eller logga in, vänligen kontakta vanlig personal på plats eller kundtjänst.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Hej och välkommen till {site_name}', 'Ref: Begin': 'Börja' },
  NotificationsList: {
    'Ref: No notifications': 'Du har inga aviseringar för tillfället',
    'Ref: This week': 'Denna vecka',
    'Ref: Earlier': 'Tidigare',
    'Ref: Page title': 'Meddelanden',
    'Ref: See more': 'Se mer',
    'Ref: Mark all read': 'markera allt läst',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Se alla aviseringar' },
  ServicesMenuPage: { 'Ref: Page title': 'Tjänster', navigate: 'navigera' },
  DeletionSuccess: {
    'Ref: Title': 'Raderingen av ditt konto begärdes',
    'Ref: Content':
      'Vi kommer att hantera det inom kort. Du kan förlora din tillgång när som helst.',
    'Log out': 'Logga ut',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Vi kommer tillbaka till dig',
    'Ref: Body': 'Vi är ledsna att du står inför problem. Vi kommer snart att kontakta dig.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Har du problem med att logga in?',
    'Ref: Email field placeholder': 'din e-postadress',
    'Ref: Mobile phone field placeholder': 'Mobilnummer',
    Company: 'Företag',
    'Ref: Company field placeholder': 'Företagsnamn',
    'Ref: Company field note':
      'Om du är konsult är detta det företag som du för närvarande arbetar med, inte din arbetsgivare.',
    Location: 'Stad eller plats',
    'Ref: Location field placeholder': 'Plats för din arbetsplats',
    'Ref: Location field note':
      'Om du är utanför ett bebodd område, använd gärna namnet som området vanligtvis hänvisas till.',
    Comment: 'Kommentar',
    'Ref: Comment field placeholder': 'Vad gick fel?',
    'Ref: Who is your employer?': 'Vem är din arbetsgivare?',
    'Ref: What village are you staying at?': 'Vilken by bor du i?',
    'Ref: Comment field note': 'All information som kan hjälpa oss hjälpa dig!',
    'Ref: Feedback Content - geography': 'Geografi',
    'Ref: Feedback Content - fullName': 'namn',
    'Ref: Feedback Content - email': 'E-post',
    'Ref: Feedback Content - phone': 'Telefon',
    'Ref: Feedback Content - company': 'Företag',
    'Ref: Feedback Content - city': 'Stad / platsnamn',
    'Ref: Feedback Content - whatVillage': 'By',
    'Ref: Feedback Content - employer': 'Arbetsgivare',
    'Ref: Feedback Content - comment': 'Vad gick fel',
  },
  AccountStartPage: {
    'Ref: Header': 'Välj din plats för start',
    'Ref: Body':
      'Hitta snabbt en plats genom att aktivera lokaliseringen, skanna en QR -kod eller söka efter namn',
    'Ref: LetsGo button': 'Nu går vi',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Registrering' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Välj webbplats med QR -skanner',
    'Ref: Page title': 'Välj din plats',
    'Ref: Select site with code': 'Välj webbplats med kod',
    'Ref: Enter site code': 'Ange webbplatskod',
    'Ref: Scanner error header': 'Webbplatsen hittades inte',
    'Ref: Not found': 'Denna kod är inte giltig i den valda geografin',
    'Ref: Generic error': 'Något gick fel',
    'All other sites': 'Alla andra webbplatser',
    'Sites in your country': 'Webbplatser i ditt land',
    'Ref: Unauthorized error': 'Obehörig begäran på grund av webbplatsbegränsningsnivå',
    'Ref: Forbidden':
      'Tyvärr har du inte rätt behörighet att komma åt den här webbplatsen. För att ordna åtkomst, vänligen kontakta Pilbara Service Center på 1800 992 777',
    "Ref: We didn't find the site.": 'Vi hittade inte webbplatsen.',
    'Ref: We recommend some solutions:': 'Vi rekommenderar några lösningar:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Försök att lägga till din webbplats med QR -skanner eller med hjälp av knapparna nedan',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Logga ut och logga in igen genom att välja rätt land',
    'Ref: The currently selected country is:': 'Det för närvarande valda landet är:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Registrering',
    'Confirm password': 'Bekräfta lösenord',
    'Email is not valid': 'E-post är inte giltig',
    'passwords do not match': 'Lösenorden matchar inte.',
    'Ref: Password strength':
      'Ditt lösenord måste vara minst 8 tecken långt, innehåller minst ett nummer, en versaler, en gemener och ett speciellt tecken.',
    'Sign up': 'Bli Medlem',
    'Continue as guest': 'Fortsätt som gäst',
    'Already have an account? Login': 'Har du redan ett konto? Logga in',
    'Ref: Registration success title': 'tack för din registrering',
    'Ref: Registration success content':
      'Ditt konto skapas snart. Du kommer att få ett e -postmeddelande som bekräftar skapandet.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Logga in/registrera dig',
    'Ref: Password strength':
      'Ditt lösenord måste vara minst 8 tecken långt, innehåller minst ett nummer, en versaler, en gemener och ett speciellt tecken.',
    Stamps: 'Stämplar',
    'Order history': 'Beställningshistorik',
    'to earn points': 'att tjäna poäng',
    'to see order history': 'att se ordningshistorik',
    'to place an order': 'att lägga en order',
    'Sign up': 'Bli Medlem',
    Login: 'Logga in',
    'Email is not valid': 'E-post är inte giltig',
    'passwords do not match': 'Lösenorden matchar inte.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Tack',
    'Ref: Body':
      'Du är nästan klar! Du kommer snart att få ett verifieringsmeddelande på den angivna adressen. Öppna det här e -postmeddelandet och klicka på länken för att aktivera ditt konto.',
    'Ref: Enter': 'Fortsätta',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Ett fel har uppstått',
    'Ref: Generic Error Body': 'Försök igen senare eller kontakta din kundtjänst.',
    'Ref: Missing IndexDb support':
      'Tyvärr, du kan inte komma åt applikationen i inkognito -läge eller utan indexDB -stöd. Vänligen försök igen med en annan webbläsare.',
    'Ref: Resource not found': 'Innehåll som du försöker komma åt finns inte.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Innehåll du försöker komma åt tillhör en privat webbplats.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Innehåll som du försöker komma åt är inte auktoriserat för ditt konto.',
    'Ref: TabTitle': 'Fel',
    'Ref: Site link invalid':
      'Den här länken är ogiltig, välj en annan webbplats eller kontakta kundtjänst om problemet kvarstår.',
    'Ref: Access Denied': 'Tillträde beviljas ej. Otillräckliga användarbehörigheter.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Hoppsan! {robot_emoji}',
    'Ref: Error title': 'Något gick fel!',
    'Ref: Error body':
      'Försök att uppdatera den här sidan eller kontakta kundtjänst om problemet kvarstår.',
    'Ref: Error ID:': 'Error -id: {errorId}',
    'Ref: Back to home': 'Tillbaka till hemmet',
  },
  Child: {
    'Ref: Route Not Found':
      'Tjänsten du letar efter är inte tillgänglig på den här webbplatsen. Försök igen senare eller kontakta din kundtjänst.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Grattis, ditt nya lösenord har sparats framgångsrikt, du kan nu komma åt alla våra tjänster från ditt konto.',
    Welcome: 'Välkommen',
    'Ref: Welcome message':
      'Du har nu framgångsrikt registrerat dig i den nya Bite -applikationen och kan fortsätta att upptäcka och njuta av alla våra tjänster.',
  },
  PasswordChange: {
    'Change Password': 'Ändra lösenord',
    Welcome: 'Välkommen',
    'Ref: Password message': 'Ditt lösenord måste ändras eftersom det inte är tillräckligt säkert',
  },
  AccountPasswordChange: {
    'Change your password here': 'Ändra ditt lösenord här',
    'Your old password': 'Ditt gamla lösenord',
    'Type in your new password': 'Skriv in ditt nya lösenord',
    'Re-type in your new password': 'Återprov i ditt nya lösenord',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'Ditt lösenord har ändrats' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Ett fel inträffade, varför din förändring inte sparades. Var god försök igen.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Återställ lösenord',
    'Ref: Provide email':
      'Ange den e -postadress som används när du registrerar dig på {app_name}. Ett e -postmeddelande skickas till dig för att återställa ditt lösenord.',
    'Ref: Something happened': 'Något hände. Vänligen försök igen senare.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Vi har skickat ett nytt e -postmeddelande. Vänta 30 sekunder för att skicka igen.',
    'Go to login page': 'Gå till inloggningssidan',
    'Resend email': 'Skicka email igen',
    'Ref: Email sent title':
      'Ett e -postmeddelande om återställning av lösenord har skickats till dig',
    'Ref: TabTitle': 'Återställ lösenord',
    'Ref: Email sent msg':
      'Återställningslänken skickades till din adress {email_address}. Vänta 30 sekunder innan du försöker skicka igen.',
  },
  ResetPassword: { 'Enter your new password': 'Ange ditt nya lösenord' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Ditt lösenord har ändrats',
    'Go to login page': 'Gå till inloggningssidan',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Lösenordsåterställning misslyckas',
    'Ref: Error': 'Ett fel inträffade, försök igen',
    'Reset your password': 'Återställ ditt lösenord',
    'Go to login page': 'Gå till inloggningssidan',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Integritetspolicy',
    'Ref: Privacy Policy Body':
      'Genom att komma åt denna plattform kommer Sodexo SA och den lokala Sodexo -enheten att behandla dina personuppgifter för att åtminstone hantera ditt konto och för att tillhandahålla tjänster och erbjudanden tillgängliga via plattformen. Om du vill ha mer information om behandlingen av dina personuppgifter, vem som kommer att få tillgång till den och hur du utövar de rättigheter du har om dina personuppgifter, vänligen kontakta sekretesspolicyn nedan och när som helst på ditt konto.',
    'Ref: Continue': 'Fortsätta',
    'Ref: Read Privacy Policy': 'Läs integritetspolicy',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Gå till affär' },
  SharePage: { 'Ref: Select a site': 'Välj en webbplats' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'För att se sekretesspolicyn, vänligen välj ditt land.',
  },
};
export default labels;
