import { IonInput } from '@ionic/react';

import { TranslationProps } from '../../../localization/localization.types';
import { DefaultComponentWithoutChildrenProps } from '../../../types';

export enum TITLEBAR_CAPTION_TYPE {
  DISPLAY_CURRENT_SITE = 'level1',
  DISPLAY_PAGE_TITLE = 'level2',
}

export type TitleBarCaption = {
  captionType:
    | TITLEBAR_CAPTION_TYPE.DISPLAY_CURRENT_SITE
    | TITLEBAR_CAPTION_TYPE.DISPLAY_PAGE_TITLE;
  hideBackButton?: boolean;
  title?: string;
  backButtonCustomPath?: string;
  onBeforeBack?: () => void;
} & DefaultComponentWithoutChildrenProps;

export type TitleBarWidgetProps = {
  labelKey: string;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  highlightText?: string;
  srOnlyText?: string;
  filled?: boolean;
  forceToDisplayLabel?: boolean;
  iconOnTheLeft?: boolean;
} & ConditionalTitleBarWidgetProps &
  TranslationProps &
  DefaultComponentWithoutChildrenProps;

type ConditionalTitleBarWidgetProps =
  | ({ linkTo?: string; onClick?: never } & DefaultComponentWithoutChildrenProps)
  | ({ linkTo?: never; onClick?: () => void } & DefaultComponentWithoutChildrenProps);

export type TitleBarIconProps = {
  position: 'left' | 'right';
  filled?: boolean;
  icon: React.ComponentType<{
    className?: string;
  }>;
  keyText?: string;
} & DefaultComponentWithoutChildrenProps;

export type TitleBarTwoIconsLinkProps = React.ComponentProps<typeof IonInput> & {
  textLink: string;
  textLinkKey: string;
  icons: TitleBarIconProps[];
  linkTo: string;
  filled?: boolean;
} & DefaultComponentWithoutChildrenProps;

export type TitleBarNavigationProps = {
  text?: string;
  caption?: TitleBarCaption;
  tabTitle?: string;
  tabTitleWithoutAppName?: boolean;
} & DefaultComponentWithoutChildrenProps;

export type LinkProps = {
  hide?: boolean;
} & DefaultComponentWithoutChildrenProps;
