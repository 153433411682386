import classNames from 'classnames';

import SideBarItem from '../../atoms/SideBarItem';

import { SideBarSectionProps } from './SideBarSection.types';

import styles from './SideBarSection.module.css';
const SideBarSection = ({
  title,
  collapsed,
  items,
  scrollable,
  onItemClick,
  tooltipId,
  'data-testid': dataTestId = 'sidebar-section',
}: SideBarSectionProps) => {
  return items.length ? (
    <div
      className={classNames(
        styles.sideBarSection,
        scrollable ? styles.scrollable : '',
        collapsed ? styles.collapsed : ''
      )}
      data-testid={dataTestId}
    >
      {title && !collapsed && (
        <div>
          <div className={classNames(scrollable ? styles.scrollTitleBck : '')} />
          <div className={styles.title}>
            <span>{title}</span>
          </div>
          <div className={classNames(scrollable ? styles.scrollTitleClear : '')} />
        </div>
      )}
      <div className={styles.scroll}>
        {items.map((item, index) => (
          <SideBarItem
            tooltipId={tooltipId}
            data-testid={`side-bar-item-${item.title}-${index}`}
            key={`side-bar-item-${item.title}-${index}`}
            icon={item.icon}
            title={item.title}
            linkTo={item.linkTo}
            active={item.active ?? false}
            collapsed={collapsed}
            onClick={onItemClick}
            tabindex={-1}
          />
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SideBarSection;
