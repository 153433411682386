import { modulesConfig, serviceTypes } from '../../config';

const moduleId = 'Audits';
const cacheVersion = '0';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

const pagePaths = {
  Module: modulePath,
  AuditResponseForm: `${modulePath}/:id`,
  AuditCreationForm: `${modulePath}/create`,
  AuditEditLocationForm: `${modulePath}/update/:id`,
};

const maxFileSize = 1024 * 1024 * 3; // 3MB
const maxFileNum = 3;
const maxFileNameLength = 100;

export { cacheVersion, maxFileNameLength, maxFileNum, maxFileSize, moduleId, pagePaths };
