import classNames from 'classnames';

import SideBarHeader from '../../atoms/SideBarHeader';
import SideBarSection from '../../molecules/SideBarSection';
import SideBarSectionSkeleton from '../../molecules/SideBarSection/SideBarSectionSkeleton';
import SideBarSite from '../../molecules/SideBarSite';

import { SideBarProps } from './SideBar.types';

import { BuildingIcon, ChevronLeftIcon, ChevronRightIcon } from '@/assets/icons';

import styles from './DesktopSideBar.module.css';

const DesktopSideBar = ({
  site,
  homeSection,
  recentSection,
  servicesSection,
  title,
  'data-testid': dataTestId = 'sidebar',
  label,
  isLoading,
  onSideBarCollapsed,
  isSideBarCollapsed,
  tooltipId,
}: SideBarProps) => {
  const toggleAriaLabel = isSideBarCollapsed
    ? label('Ref: expand menu')
    : label('Ref: collapse menu');

  const siteItem = {
    icon: <BuildingIcon />,
    title: site.name,
    linkTo: site.linkTo,
    active: site.active,
  };

  const openSideBarAction = () => {
    if (onSideBarCollapsed) {
      onSideBarCollapsed(false);
    }
  };

  const closeSideBarAction = () => {
    if (onSideBarCollapsed) {
      onSideBarCollapsed(true);
    }
  };

  return (
    <div
      className={classNames(
        styles.sideBarWrapper,
        isSideBarCollapsed ? styles.collapsed : styles.full
      )}
      data-testid={dataTestId}
    >
      <SideBarHeader
        collapsed={isSideBarCollapsed}
        title={title}
        cornerAction={isSideBarCollapsed ? openSideBarAction : closeSideBarAction}
        toggleAriaLabel={toggleAriaLabel}
        actionIcon={isSideBarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        isLoading={isLoading}
      />
      <SideBarSite
        collapsed={isSideBarCollapsed}
        sectionTitle={site.title}
        icon={siteItem.icon}
        title={siteItem.title}
        linkTo={siteItem.linkTo}
        isLoading={isLoading}
        active={siteItem.active}
      />
      {isLoading ? (
        <>
          <SideBarSectionSkeleton
            titleSkeleton={false}
            itemsCount={1}
            collapsed={isSideBarCollapsed}
          />
          <SideBarSectionSkeleton
            titleSkeleton={true}
            itemsCount={5}
            collapsed={isSideBarCollapsed}
          />
        </>
      ) : (
        <>
          <SideBarSection
            tooltipId={tooltipId}
            collapsed={isSideBarCollapsed}
            items={[homeSection]}
          />
          <SideBarSection
            tooltipId={tooltipId}
            collapsed={isSideBarCollapsed}
            title={recentSection.sectionTitle}
            items={recentSection.items}
          />
          <SideBarSection
            tooltipId={tooltipId}
            collapsed={isSideBarCollapsed}
            title={servicesSection.sectionTitle}
            items={servicesSection.items}
            scrollable
          />
        </>
      )}
    </div>
  );
};
DesktopSideBar.displayName = 'SideBar.Desktop';
export default DesktopSideBar;
