import * as H from 'history';
import momentjs from 'moment';

import {
  Filter,
  FilterPosition,
  FilterType,
} from '../../../components/atoms/Filters/Filters.types';
import { DATE_FORMAT } from '../../../constants';
import { toLocalDate } from '../../../helpers/dateTime';
import { TranslationProps } from '../../../localization/localization.types';
import { pagePaths, selectedMenuFiltersCacheKey } from '../config';
import {
  FacilityMenuItem,
  IDateFilter,
  MenuListFiltering,
  MenuSelectorListItem,
  MenuType,
} from '../types/menuSelector.types';

export const getDateFilter = <T extends IDateFilter>({
  label,
  listElements,
  selectedDate,
  updateDate,
}: {
  listElements: T[];
  selectedDate: string;
  updateDate: Function;
} & TranslationProps): Filter[] => {
  const isDateToday = selectedDate === momentjs().format(DATE_FORMAT);
  const isDateTomorrow = selectedDate === momentjs().add(1, 'days').format(DATE_FORMAT);

  return [
    {
      id: 'filter_date',
      position: FilterPosition.NOT_IN_MODAL,
      name: label('Ref: filter label: date', { textTransform: 'capitalize' }),
      options: [
        {
          value: momentjs().format(DATE_FORMAT),
          label: label('today', { textTransform: 'capitalize' }),
          default: isDateToday,
        },
        {
          value: momentjs().add(1, 'days').format(DATE_FORMAT),
          label: label('tomorrow', { textTransform: 'capitalize' }),
          default: isDateTomorrow,
        },
      ],
      displayType: FilterType.CALENDAR,
      multiple: false,
      apply: (selectedValues: string[]) => {
        const selectedValue = selectedValues[0] ?? selectedDate;
        updateDate(selectedValue);
        return listElements
          .filter(
            (item) =>
              item.menu.date && momentjs(item.menu.date).format(DATE_FORMAT) === selectedValue
          )
          .map((item) => item.id);
      },
      minDate: momentjs().startOf('day').toDate(),
      maxDate: momentjs().add(10, 'days').startOf('day').toDate(),
    },
  ];
};

export const convertToMenuItems = ({
  facilities = [],
}: {
  facilities: FacilityMenuItem[];
}): MenuSelectorListItem[] => {
  return facilities.map((facility: FacilityMenuItem) => {
    return {
      id: `${facility.id}_${facility.menu.id}`,
      facilityTitle: facility.title,
      facilityId: facility.id,
      menu: facility.menu,
      menuImageSource: facility.image?.src,
      'data-testid': `menu-selector-list-item-${facility.id}`,
    };
  });
};

export const cacheSelectedFilters = (filters: MenuListFiltering) =>
  localStorage.setItem(selectedMenuFiltersCacheKey, JSON.stringify(filters));

export const getDefaultFiltersFromCache = () => {
  const defaultFiltersCache = localStorage.getItem(selectedMenuFiltersCacheKey);
  const defaultFilters = defaultFiltersCache
    ? (JSON.parse(defaultFiltersCache) as MenuListFiltering)
    : null;

  if (defaultFilters?.filter_date) {
    const date = momentjs(new Date(toLocalDate(Object.keys(defaultFilters.filter_date)[0])))
      .startOf('day')
      .toDate();
    var today = momentjs(new Date()).startOf('day').toDate();

    if (date < today) defaultFilters.filter_date = { [momentjs(today).format('YYYY-MM-DD')]: true };
  }

  return defaultFilters;
};

export const redirectToMenuListOrProductsList = ({
  menuId,
  selectedDate,
  history,
  listElements,
  menuType = MenuType.All,
}: {
  menuId?: string;
  selectedDate: string;
  history: H.History<unknown>;
  listElements: MenuSelectorListItem[];
  menuType?: MenuType;
}) => {
  if (menuId) {
    const facilityId = listElements.find((x) => x.menu.id === menuId)?.menu.facilityId;
    const pagePath =
      menuType === MenuType.NonOrderable ? pagePaths.MenuProductsList : pagePaths.ProductsList;
    if (facilityId) {
      const path = pagePath
        .replace(':facilityId', facilityId)
        .replace(':date', selectedDate)
        .replace(':menuId', menuId.toString());

      history.replace(path);
      return;
    }
  }

  menuType === MenuType.NonOrderable
    ? history.replace(pagePaths.MenuModule)
    : history.replace(pagePaths.Module);
};
