import { useSelector } from 'react-redux';

import isLoggedIn from '../isLoggedIn';

import { State } from '@/types/state.types';

const useLoginStatus = () =>
  useSelector((state: State) => ({
    isGuest: !!state.Core?.user?.isGuest,
    isLoggedIn: isLoggedIn(),
    user: state.Core?.user,
    isSSOUser: state.Core?.user?.isSSOUser,
  }));

export default useLoginStatus;
