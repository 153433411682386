const labels = {
  ContentDetails: {
    'Ref: Page title': 'Inhaltsdetails',
    'See the event': 'Siehe das Ereignis',
    'Take the survey': 'Nehmen Sie an der Umfrage teil',
    Date: 'Datum',
    Category: 'Kategorie',
    'Ref: Content site different title': 'Dieser Inhalt gehört nicht zu Ihrer aktuellen Website',
    'Ref: Content site different description':
      'Sie sehen sich einen Inhalt an, der nicht zu Ihrer aktuellen Website gehört. Sie werden es nicht in der Liste der Inhalte sehen können.',
  },
  ContentList: {
    'Ref: Page title': 'Neuigkeiten',
    'featured (as in featured content)': 'Vorgestellt',
    'Ref: category - all': 'Alle',
    'Ref: category - promotional': 'Werbemaßnahmen',
    'Ref: category - featured': 'Vorgestellt',
    'Ref: category - other': 'Sonstiges',
  },
  Widget: {
    'Ref: Carousel title': 'Nachrichten & Artikel',
    'Ref: Carousel title promo': 'Top -Nachrichten',
    'No info yet, come back later': 'Noch keine Informationen, komm später zurück 😉',
    'Ref: Content': 'Inhalt',
    'Ref: Widget Title': 'Neuigkeiten',
    'Ref: MyVillage Carousel title promo': 'Werbeaktionen',
  },
};
export default labels;
