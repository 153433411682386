const labels = {
  All: {
    Feedback: 'Comentarios',
    'Ref: Switch site component intro sentence':
      'Sus comentarios se vincularán con el centro {site name}.',
    Category: 'Categoría',
    Subject: 'Tema',
    Comment: 'Comentario',
    'Date of your experience': 'Fecha de su experiencia *',
    'Ref: Hint': '¿Tiene un problema o trabajo que necesite arreglar?',
    'Ref: Log Request': 'Abra una solicitud',
    'Ref: Describe issue': 'Cuéntenos sobre su experiencia',
    Date: 'Fecha',
    'Ref: Share your opinion header': 'Comparta su opinión sobre su experiencia en el trabajo',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurante / cafetería',
    'Desk / workstation area': 'Escritorio / área de estación de trabajo',
    'Meeting room facilities': 'Instalaciones de la sala de reuniones',
    'Coffee/tea facilities (pantry)': 'Instalaciones de café/té (despensa)',
    'Restrooms / washrooms': 'Baños / baños',
    'Reception / Security': 'Recepción / seguridad',
    'Conference center': 'Centro de conferencias',
    'Fitness center': 'Gimnasio',
    Mailroom: 'Sala de correo',
    'Bicycle room': 'Cuarto de bicicleta',
    'Shuttle services': 'Servicios de transporte',
    Lockers: 'Casilleros',
    Helpdesk: 'Mesa de ayuda',
  },
  Create: {
    'Tell us!': '¡Cuéntenos!',
    'Ref: All Ears': '¡Somos todo oídos!',
    'Ref: Provide Description': 'Por favor, proporcione una descripción',
    'Ref: Fill-in-the-blank sentence': 'Quiero proporcionar un {0} en lo que respecta a {1}',
    'Ref: Link text to service request module': '¿Una solicitud o un problema? ¡Regístrelo aquí!',
    'Complaint type': 'Queja {emoji}',
    'Feedback type': 'Comentarios {emoji}',
    'Compliment type': 'Cumplido {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Ocurrió un error y no se envió su opinión. Si este error persiste, póngase en contacto con el mesa de ayuda.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Su comentario fue enviado. Nuestro equipo lo leerá cuidadosamente. Si es necesario contactaremos con usted.',
    'New feedback': 'Nuevos comentarios',
  },
  CreateFlow: {
    Description: 'Descripción',
    'Ref: Provide Description': 'Proporcione una fecha y descripción',
    'Ref: I would like to provide a': 'Me gustaría proporcionar un',
    'Ref: in regards to': 'en lo que respecta a',
    'Ref: &': 'y',
    'Ref: at': 'a',
    'Ref: on the': 'sobre el',
  },
};
export default labels;
