import classNames from 'classnames';

import { breakPointsInPixels } from '../../../config';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import Link from '../Link';
import Tooltip, { TOOLTIP_POSITION } from '../Tooltip';

import { SidebarItemProps } from './SideBarItem.types';

import style from './SideBarItem.module.css';

const SideBarItem = ({
  active,
  collapsed,
  title,
  icon,
  linkTo,
  onClick,
  tabindex,
  tooltipId,
  'data-testid': dataTestId = 'sidebar-item',
}: SidebarItemProps) => {
  const displayTooltip = window.matchMedia(
    '(min-width: ' + breakPointsInPixels['M'] + 'px)'
  ).matches;
  return (
    <div
      className={classNames(style.SideBarItemWrapper, active ? style.active : '')}
      data-testid={dataTestId}
      onClick={() => onClick && onClick()}
      onKeyDown={() => onClick && onClick()}
      role="button"
      tabIndex={tabindex}
    >
      <Link
        data-testid={dataTestId}
        to={linkTo}
        srOnlyText={'Open ' + title}
        {...(active ? { 'aria-current': 'page' } : {})}
      >
        <div className={classNames(style.SideBarItem, collapsed ? style.collapsed : '')}>
          <ConditionalWrapper
            condition={displayTooltip}
            wrapperIfTrue={(children) => (
              <Tooltip
                tooltipId={tooltipId}
                className={style.customTooltip}
                content={title}
                place={TOOLTIP_POSITION.Right}
                customStyles={collapsed ? style.customTooltipCollapsed : style.customTooltipFull}
              >
                {children}
              </Tooltip>
            )}
          >
            {collapsed ? (
              <div className={style.iconWrapper}>
                <div className={style.icon}>{icon}</div>
              </div>
            ) : (
              <>
                <div className={style.iconWrapper}>
                  <div className={style.icon}>{icon}</div>
                </div>
                <div className={style.title}>
                  <span>{title}</span>
                </div>
              </>
            )}
          </ConditionalWrapper>
        </div>
      </Link>
    </div>
  );
};

export default SideBarItem;
