import classNames from 'classnames';

import { ContentTitleProps } from './ContentTitle.types';

import Title, { TITLE_SIZE } from '@/components/atoms/Title';

import styles from './ContentTitle.module.css';

const ContentTitle = ({
  subtitle,
  title,
  className,
  subtitleIcon: Icon,
  'data-testid': dataTestId,
}: ContentTitleProps) => (
  <div data-testid={dataTestId} className={classNames(className)}>
    {subtitle && (
      <span className={classNames(styles.iconLocationWrapper, 'mb-S')}>
        {Icon ? <Icon /> : null}
        <Title size={TITLE_SIZE.BODYSBOLD} className={styles.title}>
          {subtitle}
        </Title>
      </span>
    )}
    {title}
  </div>
);

export default ContentTitle;
