import momentjs from 'moment/moment';

export const hasEventForDate = (date: Date, eventDatesString: string[]) => {
  return eventDatesString.find(
    (strDate: string) => strDate === momentjs(date.getTime()).format('YYYY-MM-DD')
  );
};

export const hasEventForMonth = (date: Date, eventDatesString: string[]) => {
  const year = momentjs(date).year();
  const month = momentjs(date).month();
  return eventDatesString.find(
    (strDate: string) =>
      month === momentjs(strDate, 'YYYY-MM-DD').month() &&
      year === momentjs(strDate, 'YYYY-MM-DD').year()
  );
};
