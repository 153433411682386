const labels = {
  ContactForm: {
    'Ref: Page title': 'Lépjen kapcsolatba velünk',
    'Ref: Category field value': 'Lépjen kapcsolatba velünk',
  },
  ContactList: {
    'Ref: Page title': 'Kapcsolatok',
    'call (action to place a call)': 'hívás',
    'write (action to write a message)': 'ír',
    'write (action to write an e-mail)': 'email',
    'Ref: Hint message':
      'Felhívjuk figyelmét, hogy egyes webhelyeknek nem lehetnek fogadása. Ha ez a helyzet, kérjük, keresse meg a webhely körül elérhető vezetékes telefonokat.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Hiba történt, és az üzenetet nem nyújtották be. Ha ez a hiba továbbra is fennáll, forduljon a ügyfélszolgálat.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Üzenetét elküldtük. Köszönjük, hogy felvette velünk a kapcsolatot.',
  },
};
export default labels;
