import { screen } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';
import { SERVICE } from '../../../config';
import { Menu } from '../../api/menuApi.types';
import { FacilityMenuItem } from '../../types/menuSelector.types';

import Widget from './MenusWidget';

const menus: Menu[] = [
  {
    id: '3329',
    facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
    name: 'Bite Everyday',
    date: new Date().toISOString().split('T')[0],
    menuItems: [],
  },
  {
    id: '3330',
    facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
    name: 'Bite Weekends',
    date: new Date().toISOString().split('T')[0],
    menuItems: [],
  },
];

const facilityMenus: FacilityMenuItem[] = [
  {
    id: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
    title: 'Mess Menu Facility',
    name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston',
    sequence: 0,
    type: 1,
    menu: menus[0],
  },
  {
    id: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
    title: 'Mess Menu Facility 2',
    name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston 2',
    sequence: 0,
    type: 1,
    menu: menus[1],
  },
];

const siteId = '123';
const state = {
  Core: {
    services: {
      list: [
        {
          name: SERVICE.FOOD_ORDER,
        },
      ],
    },
  },
};

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => ({
    id: siteId,
  }),
}));

jest.mock('@/modules/Core/hooks/useLanguage', () => {
  return {
    __esModule: true,
    default: () => 'en-US',
  };
});

const mockUseFacilityMenuList = jest.fn();
jest.mock('../../hooks/useFacilityMenuList', () => ({
  __esModule: true,
  default: () => mockUseFacilityMenuList(),
}));

describe('Menus Widget', () => {
  describe('With data', () => {
    beforeEach(() => {
      mockUseFacilityMenuList.mockReturnValue({
        facilityMenus: facilityMenus,
        isLoading: false,
      });
    });

    renderComponent(Widget, {}, state);

    it('should render menus', () => {
      const menuElement = screen.getAllByTestId(`MenuTile-${facilityMenus[0].id}_${menus[0].id}`);
      expect(menuElement).toBeTruthy();
    });
  });

  describe('No data', () => {
    beforeEach(() => {
      mockUseFacilityMenuList.mockReturnValue({
        facilityMenus: [],
        isLoading: false,
      });
    });

    renderComponent(Widget, {}, state);

    it('should render placeholder', () => {
      const menuElement = screen.getAllByTestId('widget-placeholder');
      expect(menuElement).toBeTruthy();
    });
  });

  describe('Loading data', () => {
    beforeEach(() => {
      mockUseFacilityMenuList.mockReturnValue({
        isLoading: true,
      });
    });

    renderComponent(Widget, {}, state);

    it('should show skeleton placeholder', () => {
      const skeletonPlaceholder = screen.getAllByTestId('skeleton-placeholder');
      expect(skeletonPlaceholder.length).toEqual(3);
    });
  });
});
