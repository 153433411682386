const labels = {
  ContactForm: { 'Ref: Page title': 'お問い合わせ', 'Ref: Category field value': 'お問い合わせ' },
  ContactList: {
    'Ref: Page title': 'お問い合わせ',
    'call (action to place a call)': '電話',
    'write (action to write a message)': '詳細を記入',
    'write (action to write an e-mail)': 'Eメール',
    'Ref: Hint message':
      '一部のサイトには受付がない場合があります。その際は利用可能な固定電話の1つ確認してください',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'エラーが発生し、メッセージが送信されませんでした。このエラーが解決しない場合は、ヘルプデスクに連絡してください。',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success':
      'あなたのメッセージは送信されました。ご連絡いただきありがとうございます。',
  },
};
export default labels;
