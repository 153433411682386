import { ReactElement } from 'react';
import { ITooltip } from 'react-tooltip';

import { DefaultComponentProps } from '../../../types';

export type TooltipProps = {
  isOpen?: boolean;
  content: ReactElement | string;
  customStyles?: string;
  'tab-index'?: number;
  'aria-label'?: string;
  tooltipId?: string;
  delayShow?: number;
} & DefaultComponentProps &
  Pick<ITooltip, 'place' | 'noArrow'>;

export enum TOOLTIP_POSITION {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}
