import { act, cleanup, screen } from '@testing-library/react';

import { renderedComponent } from '../../../../../helpers/tests/renderComponent';
import QuestionComponent from '../QuestionComponent';

const props = {
  label: jest.fn().mockReturnValue('some label'),
  isCommentRequired: false,
};

jest.mock('react-hook-form', () => ({
  useController: () => ({ field: {}, fieldState: {}, formState: {} }),
}));

describe('QuestionComponent', () => {
  afterAll(() => cleanup());

  describe('Render', () => {
    it('should render SingleChoice question type', async () => {
      const question = {
        questionType: 1,
        choices: ['Yes', 'No', 'Maybe'],
        ...props,
      };

      await act(async () => {
        renderedComponent(QuestionComponent, question);
      });

      const radioGroup = screen.queryByTestId('question-input-1');

      expect(radioGroup).toBeTruthy();
    });

    it('should render MultiChoice question type', async () => {
      const question = {
        questionType: 2,
        choices: ['Banana', 'Apple', 'Something else'],
        ...props,
      };

      await act(async () => {
        renderedComponent(QuestionComponent, question);
      });

      const checkboxGroup = screen.queryByTestId('checkbox-group');
      expect(checkboxGroup).toBeTruthy();
    });

    it('should render StarRating question type', async () => {
      const question = {
        questionType: 5,
        maximumRating: 5,
        ...props,
      };

      await act(async () => {
        renderedComponent(QuestionComponent, question);
      });

      const starRating = screen.queryByTestId('question-input-5');
      expect(starRating).toBeTruthy();

      const starNo1 = screen.queryByTestId('question-input-5-star-0-input');
      const starNo2 = screen.queryByTestId('question-input-5-star-1-input');
      const starNo3 = screen.queryByTestId('question-input-5-star-2-input');
      const starNo4 = screen.queryByTestId('question-input-5-star-3-input');
      const starNo5 = screen.queryByTestId('question-input-5-star-4-input');
      expect(starNo1).toBeTruthy();
      expect(starNo2).toBeTruthy();
      expect(starNo3).toBeTruthy();
      expect(starNo4).toBeTruthy();
      expect(starNo5).toBeTruthy();
    });

    it('should render SingleLineText question type', async () => {
      const question = { questionType: 3, ...props };

      await act(async () => {
        renderedComponent(QuestionComponent, question);
      });

      const singleLineText = screen.queryByTestId('audit-question-component-single-line-text-hook-input-field');
      expect(singleLineText).toBeTruthy();
    });

    it('should render MultiLineText question type', async () => {
      const question = {
        questionType: 4,
        choices: [],
        ...props,
      };

      await act(async () => {
        renderedComponent(QuestionComponent, question);
      });

      const multiLineText = screen.queryByTestId('audit-question-component-multi-line-text');
      expect(multiLineText).toBeTruthy();
    });

    it('should render nothing', () => {
      renderedComponent(QuestionComponent);

      const radioGroup = screen.queryByTestId('radio-group');
      expect(radioGroup).toBeNull();
    });
  });
});
