import { ReactNode } from 'react';

import { TestingProps } from '../../../types';

export enum PICKER_TYPE {
  DATE = 'date',
  TIME = 'time',
}

export type DateTimeProps = {
  id: string;
  formattedValue?: boolean;
  value?: Date;
  type?: PICKER_TYPE;
  placeholder?: string;
  format?: string;
  className?: string;
  minDate?: Date;
  close?: boolean;
  onChange?: (value: Date | string) => void;
  onDismiss?: (value?: Date | string) => void;
  modalTitle?: string;
  footer?: ReactNode;
} & TestingProps;
