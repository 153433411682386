import { useLazyExportServiceRequestLightHelpdeskListQuery } from '../../../api';

import { useShowToast } from '@/helpers/hooks/useShowToast/useShowToast';
import { download } from '@/helpers/misc';

function useDownloadSRLHelpdeskExport({
  siteId,
  searchTerm = '',
  startDate,
  endDate,
}: {
  siteId: string;
  searchTerm?: string;
  startDate?: string;
  endDate?: string;
}) {
  const [downloadServiceRequestsExport, { isFetching: exportIsLoading }] =
    useLazyExportServiceRequestLightHelpdeskListQuery();
  const { showError } = useShowToast();

  const downloadExport = async () => {
    const response = await downloadServiceRequestsExport({
      siteId,
      searchTerm,
      startDate,
      endDate,
    });

    if (response.error || !response.data) {
      showError({ 'data-testid': 'export' });
      return;
    }

    await download(response.data, 'ServiceRequestsExport.csv', 'text/csv;charset=utf-8;');
  };

  return { downloadExport, exportIsLoading };
}

export default useDownloadSRLHelpdeskExport;
