const labels = {
  StaysList: {
    'Ref: Page title': 'Verblijf',
    'Ref: Past': 'Verleden',
    'Current and future stays': 'Huidige en toekomstige verblijven',
    'filter label: period': 'Punt uit',
    'Ref: Search': 'Zoekopdracht',
  },
  StayDetails: {
    'Ref: Page title': 'Uw verblijf gegevens',
    'Arrival date': 'Aankomstdatum',
    'Departure date': 'Vertrekdatum',
    'Room number': 'kamer',
    'Reservation number': 'Reserveringsnummer',
    CheckIn: 'Check in',
    CheckOut: 'Uitchecken',
    CheckIn_1: 'Controleer in {lodgeName}, aankomend vandaag? (Reserveringsnummer {res-nummer})',
    CheckedIn_1: 'U wordt ingecheckt in {lodgeName}, kamer {roomNumber}.',
    CheckOut_1:
      'Bekijk nu {lodgeName} Reserveringsnummer {resNumber} nu? Uw kamertoets wordt gedeactiveerd.',
    CheckedOut_1: '{lodgeName} Reserveringsnummer {res-nummer} is uitgecheckt.',
  },
  StaysSuccess: {
    'Ref: Title': 'Bedankt voor je verblijf!',
    'Ref: Body': 'Uw kassa is succesvol verwerkt.',
    'Ref: Go to home': 'Ga naar huis',
    'Ref: Provide a feedback': 'Geef feedback',
  },
};
export default labels;
