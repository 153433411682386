const labels = {
  All: {
    'starts at': 'starts at',
    'ends at': 'ends at',
    location: 'Location',
    'coming date': 'coming date',
    'Ref: Currently event not preferred. Click to make preferred':
      'Currently event not preferred. Click to make preferred',
    'Ref: Currently event preferred. Click to make not preferred':
      'Currently event preferred. Click to make not preferred',
  },
  EventDetails: {
    details: 'Details',
    'Ref: Page title': 'Event Details',
    'Event dates': 'Event dates',
    'See online:': 'See online:',
    'See all dates': 'See all dates',
    'Remove from my selection': 'Remove from my selection',
    'Add to my selection': 'Add to my preferred',
    'Ref: explain calendar usage':
      "Event dates are highlighted in the calendar below. Don't miss the events you like! Click an event date in the calendard to download an invitation. Then open the downloaded file to add the event to your preffered calendar.",
    'Ref: Favorite': 'Favorite',
    'Ref:Feedback': 'Feedback',
    'Ref: Event site different title': 'This Event does not belong to your current site',
    'Ref: Event site different description':
      "You are viewing an event that does not belong to your current site. You won't be able to see it in the list of events.",
    'Ref: See all dates': 'See all dates',
    'Ref: From': 'From',
    'Ref: to': 'to',
    'Ref: Coming date': 'Coming date',
    'Ref: Add to calendar': 'Add to calendar',
  },
  EventsList: {
    'Ref: Page title': 'Events',
    'All dates': 'All dates',
    'All events': 'All events',
    'Ref: Search': 'Search',
    'My selection': 'My preferred',
    'filter label: date': 'Date',
    'filter label: coming events': 'coming events',
    'filter label: past events': 'past events',
    'filter label: interest': 'preference',
  },
  Widget: {
    'Ref: Carousel title': 'Events',
    'No info yet, come back later': 'No info yet, come back later 😉',
  },
};
export default labels;
