import classNames from 'classnames';
import { FC } from 'react';

import ReactionIcon from '../../atoms/ReactionIcon';

import { ReactionsSummaryProps } from './ReactionsSummary.types';

import styles from './ReactionsSummary.module.css';

const ReactionsSummary: FC<ReactionsSummaryProps> = ({
  reactions,
  total,
  tooltipId,
  className,
  'data-testid': dataTestId = 'reactions-summary',
}) => {
  if (total === 0) {
    return null;
  }

  return (
    <div className={classNames(styles.wrapper, className)} data-testid={dataTestId}>
      {reactions.map((reaction) => (
        <ReactionIcon
          tooltipId={tooltipId}
          key={reaction.type}
          reaction={reaction.type}
          content={reaction.count?.toString() || ''}
          className={styles.reaction}
          data-testid="reactions-summary-icon"
          useCustomAriaLabel
        />
      ))}
      <span className={classNames(styles.total)}>{total}</span>
    </div>
  );
};

export default ReactionsSummary;
