import { useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';

import Create from '../components/Create';
import CreateFailure from '../components/CreateFailure';
import CreateFlow from '../components/CreateFlow';
import CreateSuccess from '../components/CreateSuccess';
import AlwaysOnSurveys from '../components/InMoment';
import { pagePaths, getFeedbackModuleConfig } from '../config';

import { useSetupOption, useSetupOptionList } from '@/helpers/hooks/useSetupOption/useSetupOption';
import { SERVICE } from '@/modules/config';

const Routes = () => {
  const { dynamicFlow } = getFeedbackModuleConfig();
  const { isActive: dynamicFeedbackIsActive, steps: flowSteps } = dynamicFlow;

  const surveyReceiverEmail = useSetupOption('InmomentSurveyReceiverEmail', SERVICE.FEEDBACK);
  const surveys = useSetupOptionList('InmomentSurvey', SERVICE.FEEDBACK);
  const campaignId = useSetupOption('InmomentCampaignId', SERVICE.FEEDBACK);

  const surveysRecord = useMemo(
    () => ({
      surveyReceiverEmail: surveyReceiverEmail?.value,
      campaignId: campaignId?.value,
      surveys: surveys?.[0]?.isActive ? surveys : undefined,
    }),
    [surveyReceiverEmail, campaignId, surveys]
  );

  return (
    <div>
      <Switch>
        <Route path={pagePaths['Success']} children={<CreateSuccess />} />
        <Route path={pagePaths['Failure']} children={<CreateFailure />} />

        {dynamicFeedbackIsActive ? (
          /**
           * The purpose of this route is to create a feedback flow that can
           * handle a dynamic number of steps. It receives the steps configuration
           * and builds the flow. This way we can have different flows according to the App
           */
          <Route path={pagePaths['Module']} children={<CreateFlow steps={flowSteps} />} />
        ) : surveysRecord.surveys ? (
          <Route
            path={pagePaths['Module']}
            children={
              <AlwaysOnSurveys
                surveys={surveysRecord.surveys}
                surveyReceiverEmail={surveysRecord.surveyReceiverEmail || ''}
                campaignId={surveysRecord.campaignId || ''}
              />
            }
          />
        ) : (
          <Route path={pagePaths['Module']} children={<Create />} />
        )}
      </Switch>
    </div>
  );
};

export default Routes;
