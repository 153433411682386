const labels = {
  All: {
    dailyMenu: 'Menu dnia',
    anytime: 'Zawsze',
    breakfast: 'Śniadanie',
    morningBreak: 'poranna przerwa',
    lunch: 'Lunch',
    afternoonBreak: 'popołudniowa przerwa',
    dinner: 'Obiad',
    supper: 'Kolacja',
    nightMeal: 'Nocny posiłek',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menu',
    'Ref: View details': 'Pokaż szczegóły',
    'Ref: Facility details': 'Szczegóły obiektu',
  },
  OpeningHours: { everyday: 'codzienny', 'open all day': 'Otwarte całą dobę' },
  ProductsList: {
    'Ref: Page title': 'Menu',
    'Ref: Facility details': 'Szczegóły obiektu',
    'Ref: Search products list': 'Wyszukaj przedmioty',
    'Ref: filter label: date': 'Data',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Za chwilę',
    'Ref: filter label: category': 'Kategoria',
    'Ref: filter label: dishes': 'Dania',
    'Ref: filter label: highlight': 'Podświetl lub ukryj filtry za pomocą naczyń',
    All: 'Wszystko',
  },
  ProductDetails: { 'Ref: Page title': 'Szczegóły Produktu' },
  Widget: {
    'Ref: Carousel title': 'Menu',
    'No info yet, come back later': 'Nie ma jeszcze informacji, wróć później 😉',
    'Ref: menus': 'Menu',
    'Ref: see all': 'Widzieć wszystko',
  },
};
export default labels;
