const labels = {
  SiteDetails: {
    'Select site': 'Pasirinkite šią svetainę',
    'Ref: Page title': 'Informacija apie svetainę',
    'Ref: Skip map': 'Pereikite prie pagrindinio turinio',
    'Ref: Unavailable site': 'Svetainės peržiūra negalima jūsų vartotojui',
    'Ref: Sorry, no description yet': 'Deja, mes dar neturime aprašymo.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Svetainės koordinatės nerasta',
    'Ref: Page title': 'Svetainės',
    'Recently visited': 'Neseniai apsilankė',
    'All other sites': 'Visos kitos svetainės',
    'Sites in your country': 'Svetainės jūsų šalyje',
    'Search results': 'Paieškos rezultatai',
    'Select site': 'Pasirinkite Svetainės',
    'Ref: Select site with QR Scanner': 'Pasirinkite svetainę su QR skaitytuvu',
    'Ref: Select site with code': 'Pasirinkite svetainę su kodu',
    'Ref: Validate': 'Patvirtinti',
    'Ref: Enter site code': 'Įveskite svetainės kodą',
    'Ref: Scanner error header': 'Svetainė nerasta',
    'Ref: Generic error': 'Kažkas nutiko ne taip',
    'Ref: Change site': 'Keisti svetainę',
    'Ref: Change site description':
      'Krepšelyje yra daiktų! Svetainės pakeitimas leis išvalyti krepšį.',
    'Ref: Cancel': 'Atšaukti',
    'Ref: Not found': 'Šis QR kodas nebuvo pripažintas',
    'Ref: Unauthorized error': 'Neteisėta užklausa dėl svetainės apribojimo lygio',
    'Ref: Forbidden':
      'Atsiprašome, jūs neturite tinkamų leidimų, kad galėtumėte pasiekti šią svetainę. Norėdami susitarti dėl prieigos, susisiekite su „Pilbara“ paslaugų centru 1800 992 777',
    "Ref: We didn't find the site.": 'Neradome svetainės.',
    'Ref: We recommend some solutions:': 'Mes rekomenduojame keletą sprendimų:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Pabandykite pridėti savo svetainę naudodami QR skaitytuvą arba naudodamiesi žemiau pateiktais mygtukais',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Atsijungti ir atsijunkite atgal pasirinkdami teisingą šalį',
    'Ref: The currently selected country is:': 'Šiuo metu pasirinkta šalis yra:',
  },
  SwitchSite: { 'Not on {site name}?': 'Ne {site name}?' },
  SiteTile: { Details: 'Detalės', Select: 'Pasirinkti' },
};
export default labels;
