import classNames from 'classnames';
import { useCallback } from 'react';

import {
  CONTROL_TYPE,
  ListItemProps,
  ListItemOnClickProps,
  ListProps,
  isQuantitySetterProps,
} from './List.types';
import ListItem from './ListItem';

import styles from './List.module.css';

const List = ({
  items,
  onChange,
  twoColumn = false,
  'data-testid': testId,
  ...props
}: ListProps) => {
  const roleHandler = (control: CONTROL_TYPE) => {
    switch (control) {
      case CONTROL_TYPE.CHECKBOX:
        return 'group';
      case CONTROL_TYPE.RADIO:
        return 'radiogroup';
      default:
        return;
    }
  };
  const onControlClicked = useCallback(
    (id: string, props: ListItemOnClickProps) => {
      const clickedOption = items.find((element) => element.id === id);
      if (clickedOption === undefined) return;

      const updatedItems = [];

      const { control } = clickedOption;

      const controlType = control?.type;

      switch (controlType) {
        case CONTROL_TYPE.RADIO: {
          items.forEach((item) => {
            const isChecked = item.id === id;
            if (isChecked) updatedItems.push(item);
          });
          break;
        }
        case CONTROL_TYPE.CHECKBOX: {
          items.forEach((item) => {
            if (isQuantitySetterProps(item.control!.props)) return;
            const isChecked = item.id === id ? props?.checked : item.control!.props.checked;
            if (isChecked) updatedItems.push(item);
          });
          break;
        }
        case CONTROL_TYPE.QUANTITY: {
          items.forEach((item) => {
            const quantity = item.id === id ? props.quantity : item.quantity;
            const hasQuantity = (quantity || 0) > 0;

            if (hasQuantity) {
              updatedItems.push({ ...item, quantity });
            }
          });
          break;
        }
        default:
          updatedItems.push(...items);
          break;
      }

      if (onChange) onChange(updatedItems, id);
    },
    [items, onChange]
  );

  return (
    <ul
      className={classNames(styles.list, { [styles.twoColumns]: twoColumn })}
      role={roleHandler(items[0]?.control?.type as CONTROL_TYPE)}
      data-testid={testId}
      {...props}
    >
      {items
        .filter((item) => !item.hide)
        .map((item: ListItemProps) => {
          delete item.hide;
          return (
            <ListItem
              hasRole={roleHandler(items[0].control?.type as CONTROL_TYPE) !== undefined}
              key={item.id}
              {...item}
              onClick={item?.control ? onControlClicked : undefined}
              data-testid={`${testId}-${item['data-testid']}-list-item`}
            />
          );
        })}
    </ul>
  );
};

export default List;
