const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Serviceanfrage - Helpdesk',
    'Ref: Forbidden heading': 'Verboten',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Sie verwalten Serviceanfragen auf der Site {siteName}.',
    'Ref: Dates': 'Termine',
    'Ref: SRL list refresh': 'Liste aktualisieren',
    'Ref: Export': 'Export',
    'Ref: Empty/not filled': 'Leer / nicht gefüllt',
    'Ref: Name': 'Name',
    'Ref: Date': 'Datum',
    'Ref: Category': 'Kategorie',
    'Ref: User': 'Benutzer',
    'Ref: Location': 'Standort',
  },
};
export default labels;
