import { useController } from 'react-hook-form';

import Textarea from './Textarea';
import { ControlledTextareaProps, TextareaWrapperProps } from './Textarea.types';

const TextareaWrapper = ({ control, rules = {}, ...props }: TextareaWrapperProps) =>
  control ? (
    <ControlledTextarea {...props} control={control} rules={rules} />
  ) : (
    <Textarea {...props} />
  );

const ControlledTextarea = ({ control, rules, ...props }: ControlledTextareaProps) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name: props.name || '', rules, defaultValue: props.value });

  return (
    <Textarea
      {...props}
      value={value}
      onChange={(_, v) => onChange(v)}
      hasError={!!error}
      comment={error?.message}
    />
  );
};

export default TextareaWrapper;
