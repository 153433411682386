const labels = {
  TimeTable: {
    'leave at': 'Wyjść o',
    'arrive at': 'Dotrze o',
    at: 'w',
    'Ref: list placeholder': 'Wypełnij formularz, aby zobaczyć godziny',
    'Ref: Page title': 'Autobusy',
    'Ref: Departure': 'Wyjazd',
    'Ref: Arrivals': 'Przyloty',
    'Ref: minutes': 'minuty',
    'Ref: no items placeholder': 'Przepraszam, w tej chwili nie ma dostępnych autobusów.',
  },
};
export default labels;
