const mockResponse = {
  getNotifications: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        data: [
          {
            id: 'myNotifId#1',
            title: 'You just won an iPhone!',
            message: '...or did you? Learn more about the scam and protect yourself.',
            notificationDate: '2021-04-09',
            serviceId: 'Content',
            contentId: '36f59363-85f6-ea11-b5d9-0003ff4ccd4d',
            auditId: '',
            eventId: '',
          },
          {
            id: 'myNotifId#2',
            title: 'A notification without link',
            message: 'Just to say hello. Stay safe.',
            notificationDate: '2021-04-10',
            serviceId: '',
            contentId: '',
            auditId: '',
            eventId: '',
          },
        ],
      }),
  },
};

export default mockResponse;
