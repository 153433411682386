import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { SetupOption } from '../../../types/state.types';
import { State, Actions } from '../types/index';
import { Service } from '../types/State.types';

let defaultAccess = {
  invalidPsw: false,
  isFirstLogin: true,
  shouldSelectSiteContext: undefined,
};

const defaultContext = {
  currentGeoCode: undefined,
  theme: null,
  site: undefined,
  siteIdBeforeChange: null,
};

const defaultLegalDocs = {
  list: undefined, //undefined indicates the legal docs were not fetched, [] indicates no legal docs
  detailed: {},
};

const defaultServices = {
  list: [],
  visited: [],
};

const defaultSites = {
  list: [],
  visited: {},
};

const defaultUser = {
  contract: undefined,
  preferredLanguage: { id: '4bba9048-623f-4d2f-aa81-64da7fac16a4', languageCode: 'en-US' },
  theme: null,
  username: '',
  firstName: '',
  isGuest: false,
  isSSOUser: false,
};

const defaultIdentity = {
  client: null,
  type: null,
  provider: null,
  tokenUrl: null,
  useSystemBrowser: false,
};

export const defaultState = {
  access: defaultAccess,
  context: defaultContext,
  firebase: {},
  legalDocs: defaultLegalDocs,
  locks: {},
  errors: {},
  notifications: {
    items: [],
    pageIndex: 0,
    total: 0,
    unreadNotificationCount: 0,
  },
  services: defaultServices,
  sites: defaultSites,
  user: defaultUser,
  identity: defaultIdentity,
  appVersionInfo: undefined,
};

const coreReducer = (state: State = defaultState, action: Actions) => {
  switch (action.type) {
    case 'RESET_STATE':
      const currentGeoCode = state.context.currentGeoCode;
      return {
        ...defaultState,
        context: {
          ...defaultContext,
          currentGeoCode,
        },
      };
    case 'FIREBASE_TOKEN_SET_STATUS':
      return {
        ...state,
        firebase: {
          isTokenExisting: action.isTokenExisting,
        },
      };
    case 'SET_DATA_TRACKING':
      return {
        ...state,
        user: {
          ...state.user,
          useDataTracking: action.dataTracking,
        },
      };
    case 'LOAD_CORE_CACHE':
      return {
        ...state,
        ...action.cachedData,
      };
    case 'GEOGRAPHY_SET':
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE, action.geoCode);
      return {
        ...state,
        context: {
          ...state.context,
          currentGeoCode: action.geoCode,
        },
      };
    case 'USERNAME_SET':
      return {
        ...state,
        user: {
          ...state.user,
          username: action.username,
        },
      };

    case 'LOGGED_IN':
      return {
        ...defaultState,
        access: {
          ...defaultAccess,
          invalidPsw: false,
        },
        context: {
          ...defaultContext,
          currentGeoCode: action.currentGeoCode,
        },
        user: {
          ...defaultUser,
          isGuest: action.isGuest,
          isSSOUser: action.isSSOUser,
          username: action.username || state.user.username, //if refreshing the token
        },
      };
    case 'TOKEN_REFRESH_START':
      return {
        ...state,
        locks: {
          ...state.locks,
          tokenRefresh: true,
        },
      };
    case 'TOKEN_REFRESH_END':
      return {
        ...state,
        locks: {
          ...state.locks,
          tokenRefresh: false,
        },
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        errors: {},
      };
    case 'USER_CONTEXT_FETCHING':
      return {
        ...state,
        locks: {
          ...state.locks,
          getUserContext: true,
        },
      };
    case 'USER_CONTEXT_FETCHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          getUserContext: false,
        },
      };
    case 'USER_CONTEXT_RETURNED':
      return {
        ...state,
        access: {
          ...state.access,
          ...action.access,
        },
        user: {
          ...state.user,
          ...action.user,
          username: state.user.username, //keep the username since it's defined at login
        },
        context: {
          ...defaultContext,
          ...action.context,
          currentGeoCode: action.context.currentGeoCode || state.context.currentGeoCode,
          previousSite: { ...state.context.site },
          siteHasChanged: action.context.site?.id !== state.context.site?.id,
        },
      };
    case 'CLEAR_SITE_ID_BEFORE_CHANGE':
      return {
        ...state,
        context: {
          ...state.context,
          previousSite: null,
        },
      };
    case 'FIRST_LOGIN_COMPLETED':
      return {
        ...state,
        access: {
          ...state.access,
          isFirstLogin: false,
        },
      };
    case 'USER_CONTEXT_UPDATING':
      return {
        ...state,
        locks: {
          ...state.locks,
          updateUserContext: true,
        },
      };
    case 'USER_CONTEXT_UPDATE_FINISHED':
      return {
        ...state,
        locks: {
          ...state.locks,
          updateUserContext: false,
        },
      };
    case 'USER_CONTEXT_UPDATE_ACCEPTED':
      const { mobile, preferredLanguageId, preferredLocation } = action;

      let newUser = state.user;
      if (mobile) newUser.mobile = mobile;
      if (preferredLanguageId) newUser.preferredLanguageId = preferredLanguageId;
      if (preferredLocation) newUser.preferredLocation = preferredLocation;

      return {
        ...state,
        locks: {
          ...state.locks,
          updateUserContext: false,
        },
        user: newUser,
      };
    case 'CLEAR_SITE_CONTEXT':
      return {
        ...state,
        context: {
          ...state.context,
          site: null,
        },
      };

    case 'AVAILABLE_SERVICES_DEFINED':
      const availableServices = action.services.map((service: Service) => {
        return {
          id: service.id,
          name: service.name,
          navigation: service.navigation,
          setupOptions: service.setupOptions?.reduce(
            //clean setup options: remove empty ones, trim the others
            (setupOptions: SetupOption[], setupOption: SetupOption) => {
              if (setupOption.code)
                setupOptions.push({
                  ...setupOption,
                  code: setupOption.code.trim(),
                  value: setupOption.value.trim(),
                });
              return setupOptions;
            },
            []
          ),
          contentPageId: service.contentPageId,
        };
      });
      return {
        ...state,
        services: {
          ...state.services,
          list: availableServices,
        },
      };
    case 'THEME_RETURNED':
      return {
        ...state,
        context: {
          ...state.context,
          theme: {
            id: action.id,
            name: action.name,
            settings: action.settings,
          },
        },
      };
    case 'REGISTRATION_POSTING':
      return {
        ...state,
        locks: { register: true },
      };

    case 'REGISTRATION_POSTED':
      return {
        ...state,
        locks: { register: false },
      };
    case 'ACCOUNT_DELETION':
      return {
        ...state,
        locks: {
          ...state.locks,
          deleteAccount: true,
        },
      };
    case 'ACCOUNT_DELETION_SUCCESS':
      return {
        ...state,
        locks: {
          ...state.locks,
          deleteAccount: true,
        },
      };
    case 'ACCOUNT_DELETION_FAILURE':
      return {
        ...state,
        locks: {
          ...state.locks,
          deleteAccount: false,
        },
      };
    case 'GET_APP_VERSION_REQUEST':
      return {
        ...state,
        locks: { ...state.locks, getAppVersion: true },
      };
    case 'GET_APP_VERSION_SUCCESS':
      return {
        ...state,
        appVersionInfo: action.appVersionInfo,
      };
    case 'IS_SSO_SET':
      return { ...state, user: { ...state.user, isSSOUser: action.isSSOUser } };
    case 'SET_MS_TEAMS_CONTEXT':
      return {
        ...state,
        msTeamsContext: {
          ...state.msTeamsContext,
          TeamsContext: action.TeamsContext,
          shouldRetryInit: action.shouldRetryInit,
        },
      };
    default:
      return state;
  }
};

export default coreReducer;
