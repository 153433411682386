import * as signalR from '@microsoft/signalr';
import { useCallback, useEffect, useState } from 'react';

type ConnectionWrapper = {
  hubConnection: signalR.HubConnection;
  isMounted: boolean;
};

const useSignalRHubConnection = (
  shouldConnectToHub: boolean,
  connectionBuilder: signalR.HubConnectionBuilder,
  reconnectIntervalInMs: number
) => {
  const [connection, setConnection] = useState<signalR.HubConnection | undefined>();
  const [connectionState, setConnectionState] = useState<signalR.HubConnectionState>(
    signalR.HubConnectionState.Disconnected
  );

  const connectToHub = useCallback(
    async (connectionWrapper: ConnectionWrapper) => {
      const { hubConnection, isMounted } = connectionWrapper;

      if (hubConnection.state === signalR.HubConnectionState.Connected) return;

      try {
        console.log(`Connecting to hub: ${hubConnection.baseUrl}`);

        hubConnection.onclose((error) => {
          setConnectionState(hubConnection.state);

          if (isMounted && reconnectIntervalInMs > 0) {
            console.log(`Hub connection ${hubConnection.baseUrl} lost`, error);
            setTimeout(() => connectToHub(connectionWrapper), reconnectIntervalInMs);
          }
        });

        hubConnection.onreconnected((error) => {
          setConnectionState(hubConnection.state);
          console.log(`Hub connection ${hubConnection.baseUrl} reconnected`, error);
        });

        hubConnection.onreconnecting((connectionId) => {
          setConnectionState(hubConnection.state);

          if (isMounted && reconnectIntervalInMs > 0) {
            console.log(`Hub connection ${hubConnection.baseUrl} reconnecting`, connectionId);
            setTimeout(() => connectToHub(connectionWrapper), reconnectIntervalInMs);
          }
        });

        if (isMounted) {
          await hubConnection.start();

          setConnectionState(hubConnection.state);
          setConnection(hubConnection);

          console.debug(`Connected to hub: ${hubConnection.baseUrl}, awaiting notifications`);
        }
      } catch (err) {
        setConnectionState(hubConnection.state);
        console.error(`Could not connect to hub: ${hubConnection.baseUrl}`, err);

        if (isMounted && reconnectIntervalInMs > 0) {
          setTimeout(() => connectToHub(connectionWrapper), reconnectIntervalInMs);
        }
      }
    },
    [reconnectIntervalInMs]
  );

  useEffect(
    () => {
      let connectionWrapper: ConnectionWrapper;
      if (shouldConnectToHub) {
        connectionWrapper = {
          hubConnection: connectionBuilder.build(),
          isMounted: true,
        };
        connectToHub(connectionWrapper);
      }

      return () => {
        (async () => {
          if (connectionWrapper) {
            setConnectionState(signalR.HubConnectionState.Disconnected);
            connectionWrapper.isMounted = false;
            await connectionWrapper.hubConnection.stop();
          }
        })();
      };
    },
    // eslint-disable-next-line
    [connectToHub, shouldConnectToHub]
  );

  return { connection, connectionState };
};

export default useSignalRHubConnection;
