import { useController } from 'react-hook-form';

import RatingStar from './RatingStar';
import { ControlledRatingStarProps, RatingStarWrapperProps } from './RatingStar.types';

const RatingStarWrapper = ({ control, rules = {}, ...props }: RatingStarWrapperProps) =>
  control ? (
    <ControlledRatingStar control={control} rules={rules} {...props} />
  ) : (
    <RatingStar {...props} />
  );

const ControlledRatingStar = ({ control, rules, ...props }: ControlledRatingStarProps) => {
  const {
    field: { value, onChange },
  } = useController({ control, name: props.groupName, rules, defaultValue: props.value });

  return <RatingStar {...props} value={value} onChange={onChange} />;
};

export default RatingStarWrapper;
