const labels = {
  ContentDetails: {
    'Ref: Page title': '內容細節',
    'See the event': '查看活動',
    'Take the survey': '參加調查',
    Date: '日期',
    Category: '類別',
    'Ref: Content site different title': '此內容不屬於您當前的網站',
    'Ref: Content site different description':
      '您正在查看不屬於當前網站的內容。您將無法在內容列表中看到它。',
  },
  ContentList: {
    'Ref: Page title': '新聞源',
    'featured (as in featured content)': '精選',
    'Ref: category - all': '全部',
    'Ref: category - promotional': '促銷',
    'Ref: category - featured': '特色',
    'Ref: category - other': '其他',
  },
  Widget: {
    'Ref: Carousel title': '新聞&文章',
    'Ref: Carousel title promo': '最新消息',
    'No info yet, come back later': '暫無信息，請稍後再來😉',
    'Ref: Content': '內容',
    'Ref: Widget Title': '新聞源',
    'Ref: MyVillage Carousel title promo': '晉升',
  },
};
export default labels;
