import {
  useGetAcknowledgementsQuery,
  useGetAllLegalDocumentsQuery,
} from '../api/legalDocuments/legalDocumentsApi';
import { checkDocsToAcknowledge } from '../helpers/helpers';

import useLanguage from './useLanguage';

import useGeoCode from '@/helpers/hooks/useGeoCode';
import useLoginStatus from '@/helpers/hooks/useLoginStatus';

export const useLegalDocAndAcknowledgments = () => {
  const { isLoggedIn } = useLoginStatus();
  const { currentLanguageCode: languageCode } = useLanguage();
  const { currentGeoCode: geoCode } = useGeoCode();

  const { data: legalDocumentsResponse, isFetching: getAllLegalDocumentsLoading } =
    useGetAllLegalDocumentsQuery({ geoCode, languageCode }, { skip: !isLoggedIn });

  const { data: acknowledgementsResponse, isFetching: getAcknowledgmentsLoading } =
    useGetAcknowledgementsQuery(
      {},
      {
        skip: !isLoggedIn,
      }
    );

  const legalDocuments = legalDocumentsResponse?.documents;
  const acknowledgements = acknowledgementsResponse?.acknowledgements;

  const docsToAcknowledge = checkDocsToAcknowledge({
    documents: legalDocuments,
    acknowledgements: acknowledgements,
  });

  return {
    ...docsToAcknowledge,
    isLoading: getAllLegalDocumentsLoading || getAcknowledgmentsLoading,
    legalDocuments,
    acknowledgements,
  };
};
