const labels = {
  All: {
    dailyMenu: '未知',
    anytime: '任何時間',
    breakfast: '早餐',
    morningBreak: '上午休息',
    lunch: '午餐',
    afternoonBreak: '下午休息',
    dinner: '晚餐',
    supper: '晚餐',
    nightMeal: '晚飯',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': '菜單',
    'Ref: View details': '查看詳情',
    'Ref: Facility details': '設施詳情',
  },
  OpeningHours: { everyday: '每天', 'open all day': '全天開放' },
  ProductsList: {
    'Ref: Page title': '菜單',
    'Ref: Facility details': '設施詳情',
    'Ref: Search products list': '搜索項目',
    'Ref: filter label: date': '日期',
    'Ref: filter label: menus': '菜單',
    'Ref: filter label: moment': '片刻',
    'Ref: filter label: category': '類別',
    'Ref: filter label: dishes': '菜餚',
    'Ref: filter label: highlight': '突出顯示或隱藏餐具的過濾器',
    All: '全部',
  },
  ProductDetails: { 'Ref: Page title': '產品詳情' },
  Widget: {
    'Ref: Carousel title': '菜單',
    'No info yet, come back later': '暫無信息，請稍後再來😉',
    'Ref: menus': '菜單',
    'Ref: see all': '查看全部',
  },
};
export default labels;
