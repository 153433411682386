import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import LoadingPage from '../components/templates/LoadingPage/LoadingPage';

import { StepperProvider } from './atoms/Stepper';

//a tunnel is just a convenient way to organize a prescriptive user flow. each component in the tunnel must accept a goToNext prop
const Tunnel = (props) => {
  const { steps, startStepId, exitToPath, visibleSteps, hideStepper } = props;

  const history = useHistory();
  const location = useLocation();

  const currentPath = location.pathname;

  const [savedPath, setSavedPath] = useState();
  const [currentStepId, setCurrentStepId] = useState();

  const findStepById = useCallback(
    (id) => {
      return steps.find((el) => el.id === id);
    },
    [steps]
  );

  const findStepByPath = useCallback(
    (path) => {
      return steps.find((el) => el.path === path);
    },
    [steps]
  );

  const getApplicableStep = useCallback(
    (initStep = false) => {
      let candidateStep = initStep ? initStep : findStepByPath(currentPath);

      if (candidateStep === undefined) candidateStep = findStepById(startStepId);

      if (candidateStep.getApplicability === undefined || candidateStep.getApplicability()) {
        let newPath = candidateStep.path;
        if (currentPath === candidateStep.path) {
          setSavedPath(newPath);
          setCurrentStepId(candidateStep.id);
        } else {
          setSavedPath(newPath);
          setCurrentStepId(candidateStep.id);
          history.replace(newPath);
        }
        return;
      }

      let nextStepId = false;
      if (candidateStep.getNextStepId !== undefined) nextStepId = candidateStep.getNextStepId();

      if (!nextStepId) {
        history.push(exitToPath);
        return;
      }

      return getApplicableStep(steps[nextStepId]);
    },
    [currentPath, exitToPath, findStepById, findStepByPath, history, startStepId, steps]
  );

  useEffect(() => {
    if (location.pathname !== savedPath) getApplicableStep();
  }, [getApplicableStep, location, savedPath]);

  if (!currentStepId) return <LoadingPage />;

  const currentStep = findStepById(currentStepId);
  const StepComponent = currentStep.component;

  const goToNext = (...args) => {
    let nextStepId = currentStep.getNextStepId(...args);
    let nextPath = exitToPath;

    if (nextStepId) {
      let nextStep = findStepById(nextStepId);
      nextPath = nextStep.path;
    }

    history.push(nextPath);
  };

  let filteredSteps = steps.filter((step) => step.getNextStepId() !== false);
  if (visibleSteps?.length) {
    filteredSteps = visibleSteps;
  }
  const stepperSteps = !hideStepper
    ? filteredSteps.map((step) => ({
        current: step.path === location.pathname,
        path: step.path,
        title: step.title,
      }))
    : [];

  return (
    <StepperProvider value={stepperSteps}>
      <StepComponent goToNext={goToNext} {...currentStep.customProps} />
    </StepperProvider>
  );
};

export default Tunnel;
