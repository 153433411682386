const mockResponse = {
  getContacts: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        siteContacts: [
          {
            id: '06f19c42-a0cd-ea11-bf22-0003ff4ccf43',
            name: 'Laundry Services',
            // "phone": "5987654321",
            email: 'lservices@myservice.com',
          },
          {
            id: '6913a943-6ea5-ea11-8b71-0003ff4cdb48',
            name: "Gym's Contact",
            phone: '1233456789',
            // "email": "mygym@test.com"
          },
          {
            id: '226e4273-b8a9-ea11-8b71-0003ff4cdb48',
            name: 'Infrastructure and Security',
            phone: '111222333',
            email: 'infrasecu@sdx.com',
          },
          {
            id: '03229940-b9a9-ea11-8b71-0003ff4cdb48',
            name: 'Management@sdx.test',
            phone: '222333444555',
            email: 'management@sdx.test',
          },
          {
            id: '43bb39db-b9a9-ea11-8b71-0003ff4cdb48',
            name: 'HealthNSafety',
            phone: '999888777666',
            email: 'healthsafety@sdx.test',
          },
          {
            id: 'ade2568d-bca9-ea11-8b71-0003ff4cdb48',
            name: 'Customer Care',
            phone: '55557777788',
            email: 'custcare@sdx.test',
          },
        ],
      }),
  },
};

export default mockResponse;
