import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AccountIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import FormLine from '../../../../components/atoms/FormLine/FormLine';
import { PasswordInput, HookInput as Input } from '../../../../components/atoms/Input';
import { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import Form from '../../../../components/molecules/Form';
import { requiredRule, trimFields } from '../../../../components/molecules/Form/helpers';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { INPUT_TYPE } from '../../../../constants';
import { changePassword } from '../../actions';
import { pagePaths, getChangePasswordConfiguration } from '../../config';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

import { PasswordChangeFormFields } from './AccountPasswordChange.types';

type FormDefaultValues = {
  password: string;
  confirmPassword: string;
  currentPassword?: string;
};

const AccountPasswordChange = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);
  const dispatch = useDispatch();

  const [submitButtonIsLoading, setSubmitButtonIsLoading] = useState<boolean>(false);

  const fields = getChangePasswordConfiguration();

  const formDefaultValues: FormDefaultValues = {
    password: '',
    confirmPassword: '',
  };

  if (fields.currentPassword) formDefaultValues.currentPassword = '';

  const {
    handleSubmit,
    setError,
    formState: { isValid },
    control,
  } = useForm<PasswordChangeFormFields>({
    mode: 'onChange',
    defaultValues: formDefaultValues,
  });

  const onSubmit: SubmitHandler<PasswordChangeFormFields> = async (data) => {
    setSubmitButtonIsLoading(true);

    const trimmedData = trimFields<PasswordChangeFormFields>(data);

    if (trimmedData.password !== trimmedData.confirmPassword) {
      setError('confirmPassword', {
        type: 'custom',
        message: label('Ref: Password does not match'),
      });
      setSubmitButtonIsLoading(false);
      return;
    }

    const result = await dispatch(
      changePassword({
        password: trimmedData.password,
        ...(fields.currentPassword && { old_password: trimmedData.currentPassword }),
      })
    );

    if (!result.ok && result?.responseData?.Detail === 'Old Password is not valid') {
      setSubmitButtonIsLoading(false);
      setError('currentPassword', {
        type: 'custom',
        message: label('Ref: Old password does not match'),
      });
      return;
    }

    if (!result.ok) {
      history.push(pagePaths.AccountPasswordChangeFailure);
      return;
    }

    if (result.ok) {
      history.push(pagePaths.AccountPasswordChangeSuccess);
      return;
    }
  };

  return (
    <SimpleFormPage title={label('Password')}>
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              tag: TITLE_TAG.H1,
              size: TITLE_SIZE.HEADLINES,
              children: label('Change your password here'),
            }}
          >
            <Form onSubmit={handleSubmit(onSubmit)}>
              {fields.currentPassword && (
                <FormLine data-testid="account-current-password">
                  <Input
                    control={control}
                    name="currentPassword"
                    inputLabel={label('Your old password')}
                    inputType={INPUT_TYPE.PASSWORD}
                    data-testid="account-current-password"
                    data-cy="input-current-password"
                    autocomplete="current-password"
                    rules={requiredRule(label('Your old password'), label)}
                  />
                </FormLine>
              )}
              <FormLine data-testid="account-new-password">
                <PasswordInput
                  control={control}
                  name="password"
                  inputLabel={label('Type in your new password')}
                  labelFunc={label}
                  data-testid="account-new"
                  data-cy="input-password"
                  autocomplete="new-password"
                  rules={requiredRule(label('New password'), label)}
                />
              </FormLine>
              <FormLine data-testid="account-confirm-password">
                <Input
                  control={control}
                  name="confirmPassword"
                  inputLabel={label('Re-type in your new password')}
                  inputType={INPUT_TYPE.PASSWORD}
                  data-testid="account-confirm-password"
                  data-cy="input-password-confirm"
                  autocomplete="new-password"
                  rules={requiredRule(label('Confirm password'), label)}
                />
              </FormLine>
            </Form>
          </Card>
        </Column.Main>
        <Column.Complementary>
          <AccountIllustration />
        </Column.Complementary>
        <ActionsBar.Static inMainColumn>
          <Button
            data-testid="account-psw-change-submit"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
            loading={submitButtonIsLoading}
            disabled={!isValid}
            isClickDisabled
          >
            {label('Change password', {
              textTransform: 'capitalize',
            })}
          </Button>
        </ActionsBar.Static>
      </Container>
    </SimpleFormPage>
  );
};

export default AccountPasswordChange;
