import { AxiosError } from 'axios';

import { apiPath, shouldUseMockData } from '../config';

import { AxiosBaseQuery, Query } from './axiosBaseQuery.types';
import { axiosClient } from './axiosClient';

export const axiosBaseQuery = ({ baseUrl }: AxiosBaseQuery = { baseUrl: apiPath }) => {
  return async <T>({ url, method, data, params, mockData, opts = {}, headers = {} }: Query<T>) => {
    if (shouldUseMockData) {
      if ((mockData?.status || 200) >= 300) {
        return { error: { status: mockData?.status } };
      }
      return { data: await mockData?.json() };
    }

    try {
      const response = await axiosClient({
        url: baseUrl + url,
        method,
        data,
        params,
        opts,
        headers,
      });

      if (response.status >= 300) {
        const error = {
          message: response.statusText,
          status: response.status,
          response: response.data,
        };
        throw error;
      }

      return { data: response.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.status,
          data: err.response,
        },
      };
    }
  };
};
