import { WIDGET_TYPE } from '../../../constants';
import { WidgetType } from '../../../types';

import MenusWidget from './MenusWidget';
export const widgets: WidgetType[] = [
  {
    id: 'MenusWidget',
    type: WIDGET_TYPE.NORMAL,
    component: MenusWidget,
    supportedServices: ['Food.Menu', 'Food.Order'],
  },
];
