import React from 'react';
import { Link } from 'react-router-dom';

import { isExternalUrl } from '../../../helpers/misc';

import { handleAnchorClick } from './Link.helpers';
import { LinkCustomProps } from './Link.types';

const CustomLink = React.memo(({ to, children, srOnlyText, 'data-testid': dataTestId, ...rest }: LinkCustomProps) => {
  if (isExternalUrl({ to })) {
    return (
      <a data-testid={`${dataTestId}-external-link`} href={to.toString()} onClick={handleAnchorClick(to.toString())} {...rest}>
        {children}
        <span data-testid={`${dataTestId}-external-link-sr-text`} className="sr-only">{srOnlyText}</span>
      </a>
    );
  }

  return (
    <Link data-testid={`${dataTestId}-link`} to={to} {...rest}>
      {children}
      {srOnlyText ? <span data-testid={`${dataTestId}-link-sr-text`} className="sr-only">{srOnlyText}</span> : <></>}
    </Link>
  );
});

export default CustomLink;
