export const myPlaceGeneratedIcon = () => {
  const documentComputedStyles = getComputedStyle(document.documentElement);

  const firstColor = documentComputedStyles.getPropertyValue('--ion-color-tertiary');
  const secondColor = documentComputedStyles.getPropertyValue('--ion-color-primary');

  return (
    'data:image/svg+xml;charset=UTF-8;base64,' +
    btoa(`<svg width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="${firstColor}" />
    <rect
      x="18"
      y="47.6567"
      width="8"
      height="8"
      transform="rotate(-45 18 47.6567)"
      fill="${firstColor}"
    />
    <path
      d="M24 23.25C21.5147 23.25 19.5 21.2353 19.5 18.75C19.5 16.2647 21.5147 14.25 24 14.25C26.4853 14.25 28.5 16.2647 28.5 18.75C28.5 21.2353 26.4853 23.25 24 23.25Z"
      stroke="${secondColor}"
      stroke-width="1.5"
    />
    <path
      d="M24 23.25C23.5417 23.25 15.75 25 15.75 30.25L15.75 32.75C15.75 33.3023 16.1977 33.75 16.75 33.75L31.25 33.75C31.8023 33.75 32.25 33.3023 32.25 32.75L32.25 30.25C32.25 25 24.4583 23.25 24 23.25Z"
      stroke="${secondColor}"
      stroke-width="1.5"
    />
    <ellipse cx="28.5" cy="30" rx="0.75" ry="0.75" transform="rotate(-90 28.5 30)" fill="${secondColor}" />
  </svg>`)
  );
};

export const siteActiveGeneratedIcon = () => {
  const documentComputedStyles = getComputedStyle(document.documentElement);

  const firstColor = documentComputedStyles.getPropertyValue('--ion-color-primary');

  return (
    'data:image/svg+xml;charset=UTF-8;base64,' +
    btoa(`<svg width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="${firstColor}"/>
    <rect x="18" y="47.6567" width="8" height="8" transform="rotate(-45 18 47.6567)" fill="${firstColor}"/>
    <path d="M15.75 21V31.4167C15.75 31.9167 16.08 32.25 16.575 32.25H31.425C31.92 32.25 32.25 31.9167 32.25 31.4167V21" stroke="white" stroke-width="1.5"/>
    <path d="M23.25 32.25V24.75H18.75V32.25" stroke="white" stroke-width="1.5"/>
    <path d="M19.5 19.5C19.5 20.775 20.475 21.75 21.75 21.75C23.025 21.75 24 20.775 24 19.5" stroke="white" stroke-width="1.5"/>
    <path d="M24 19.5C24 20.775 24.975 21.75 26.25 21.75C27.525 21.75 28.5 20.775 28.5 19.5" stroke="white" stroke-width="1.5"/>
    <path d="M28.5 19.5C28.5 20.775 30.225 21.75 31.5 21.75C32.775 21.75 33.75 20.775 33.75 19.5L30.225 16.05C30.075 15.825 29.85 15.75 29.625 15.75H18.375C18.15 15.75 17.925 15.825 17.775 16.05L14.25 19.5C14.25 20.775 15.225 21.75 16.5 21.75C17.775 21.75 19.5 20.775 19.5 19.5" stroke="white" stroke-width="1.5"/>
    <path d="M29.25 25.5C29.6642 25.5 30 25.1642 30 24.75C30 24.3358 29.6642 24 29.25 24C28.8358 24 28.5 24.3358 28.5 24.75C28.5 25.1642 28.8358 25.5 29.25 25.5Z" fill="white"/>
    <path d="M25.5 27.75H30" stroke="white" stroke-width="1.5"/>
    </svg>
    `)
  );
};

export const siteInactiveGeneratedIcon = () => {
  const documentComputedStyles = getComputedStyle(document.documentElement);

  const firstColor = documentComputedStyles.getPropertyValue('--ion-color-primary-ultralight');
  const secondColor = documentComputedStyles.getPropertyValue('--ion-color-primary');

  return (
    'data:image/svg+xml;charset=UTF-8;base64,' +
    btoa(`<svg width="48" height="54" viewBox="0 0 48 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="8" fill="${firstColor}"/>
    <rect x="18" y="47.6567" width="8" height="8" rx="4" transform="rotate(-45 18 47.6567)" fill="${firstColor}"/>
    <path d="M15.75 21V31.4167C15.75 31.9167 16.08 32.25 16.575 32.25H31.425C31.92 32.25 32.25 31.9167 32.25 31.4167V21" stroke="${secondColor}" stroke-width="1.5"/>
    <path d="M23.25 32.25V24.75H18.75V32.25" stroke="${secondColor}" stroke-width="1.5"/>
    <path d="M19.5 19.5C19.5 20.775 20.475 21.75 21.75 21.75C23.025 21.75 24 20.775 24 19.5" stroke="${secondColor}" stroke-width="1.5"/>
    <path d="M24 19.5C24 20.775 24.975 21.75 26.25 21.75C27.525 21.75 28.5 20.775 28.5 19.5" stroke="${secondColor}" stroke-width="1.5"/>
    <path d="M28.5 19.5C28.5 20.775 30.225 21.75 31.5 21.75C32.775 21.75 33.75 20.775 33.75 19.5L30.225 16.05C30.075 15.825 29.85 15.75 29.625 15.75H18.375C18.15 15.75 17.925 15.825 17.775 16.05L14.25 19.5C14.25 20.775 15.225 21.75 16.5 21.75C17.775 21.75 19.5 20.775 19.5 19.5" stroke="${secondColor}" stroke-width="1.5"/>
    <path d="M29.25 25.5C29.6642 25.5 30 25.1642 30 24.75C30 24.3358 29.6642 24 29.25 24C28.8358 24 28.5 24.3358 28.5 24.75C28.5 25.1642 28.8358 25.5 29.25 25.5Z" fill="${secondColor}"/>
    <path d="M25.5 27.75H30" stroke="${secondColor}" stroke-width="1.5"/>
    </svg>
    
    `)
  );
};
