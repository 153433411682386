import { renderHook, waitFor } from '@testing-library/react';
import moment from 'moment';

import { apiPath } from '../../../../config';
import { CacheService } from '../../../../services/CacheService';
import { OfflineQueueService } from '../../../../services/OfflineQueueService';
import { endpoints } from '../../api';
import useLocalData from '../useLocalData';

jest.mock('../../../../services/CacheService');
jest.mock('../../../../services/OfflineQueueService');

describe('useLocalData', () => {
  let cacheMock: any;
  let offlineQueueMock: any;

  beforeEach(() => {
    cacheMock = {
      getValue: jest.fn(),
    };
    offlineQueueMock = {
      getValue: jest.fn(),
      getAll: jest.fn(),
    };
    (CacheService.getInstance as jest.Mock).mockReturnValue(cacheMock);
    (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  describe('when getting a single audit invite details', () => {
    it('should get local unsynced audit when id is provided', async () => {
      const id = 'test-id';
      const auditId = 'audit-id';
      const auditName = 'Test Audit';
      const auditStreamName = 'Test Audit Stream';

      offlineQueueMock.getValue.mockResolvedValue({ data: { auditId } });
      cacheMock.getValue.mockResolvedValue([
        { id: auditId, name: auditName, auditStreamName, questions: [] },
      ]);

      const { result } = renderHook(() => useLocalData(id));
      expect(result.current.audit).toBeUndefined();

      await waitFor(async () => {
        expect(result.current.audit).toEqual({
          inviteId: id,
          auditId,
          auditName,
          auditStream: auditStreamName,
          workOrderId: undefined,
          workOrderNumber: undefined,
          questions: [],
          state: 0,
          response: {
            submitDate: undefined,
            responses: undefined,
          },
        });
      });
    });

    it('should get details from cached audit invites list when there is no queued item with the specified id', async () => {
      const auditInviteId = 'invite-id';
      const auditId = 'audit-id';
      const auditName = 'Test Audit';
      const auditStreamName = 'Test Audit Stream';
      const workOrderId = 'work-order-id';
      const workOrderNumber = '123';
      const workOrderLocation = 'Far far away';
      const locationId = '47754440-a7d2-e911-a812-000d3a6aa0b3';
      const dueDate = moment().format();
      const state = 0;

      offlineQueueMock.getValue.mockResolvedValue(undefined);
      offlineQueueMock.getAll.mockResolvedValue(undefined);

      cacheMock.getValue.mockResolvedValueOnce([
        {
          id: auditInviteId,
          auditId,
          auditName,
          auditStreamType: auditStreamName,
          workOrderId,
          workOrderNumber,
          workOrderLocation,
          locationId,
          dueDate,
          state,
        },
      ]);
      cacheMock.getValue.mockResolvedValueOnce([
        { id: auditId, name: auditName, auditStreamName, questions: [] },
      ]);

      const { result } = renderHook(() => useLocalData(auditInviteId));

      await waitFor(async () => {
        expect(result.current.audit).toEqual({
          inviteId: auditInviteId,
          auditId,
          auditName,
          auditStream: auditStreamName,
          workOrderId,
          workOrderNumber,
          questions: [],
          state: 0,
          response: undefined,
        });
      });
    });
  });

  it('should handle undefined queuedItem when no item is found', async () => {
    const id = 'test-id';
    offlineQueueMock.getValue.mockResolvedValue(undefined);

    const { result } = renderHook(() => useLocalData(id));

    await waitFor(async () => {
      expect(result.current.audit).toBeUndefined();
    });
  });

  it('should get queued audits', async () => {
    const audits = [{ id: 'audit-id', name: 'Test Audit', auditStreamName: 'Stream Type' }];
    const locations = [{ id: 'location-id', name: 'Test Location' }];
    const queuedItems = [
      {
        k: 'item-key',
        v: {
          url: `${apiPath}${endpoints.createAudit}`,
          method: 'post',
          data: { auditId: 'audit-id', locationId: 'location-id', dueDate: '2023-05-24' },
        },
      },
    ];

    offlineQueueMock.getAll.mockResolvedValue(queuedItems);
    cacheMock.getValue.mockResolvedValueOnce(audits).mockResolvedValueOnce(locations);

    const { result } = renderHook(() => useLocalData());

    await waitFor(async () => {
      expect(result.current.auditInvites).toHaveLength(1);
      expect(result.current.auditInvites[0]).toEqual({
        auditId: 'audit-id',
        auditName: 'Test Audit',
        auditStreamType: 'Stream Type',
        'data-testid': 'audit-invite-audit-id',
        dueDate: '2023-05-24',
        id: 'item-key',
        isUnsynchronised: true,
        state: 0,
        locationId: 'location-id',
        workOrderLocation: 'Test Location',
        workOrderNumber: '',
      });
    });
  });
});
