const labels = {
  ContactForm: { 'Ref: Page title': '聯繫我們', 'Ref: Category field value': '聯繫我們' },
  ContactList: {
    'Ref: Page title': '連絡人',
    'call (action to place a call)': '電話',
    'write (action to write a message)': '留言',
    'write (action to write an e-mail)': '電子郵件',
    'Ref: Hint message':
      '請注意，某些地點可能沒有前台。如果是這種情況，請查找周圍是否有可用的座機。',
  },
  FormSubmissionFailure: {
    'Ref: Message body': '發生錯誤，您的郵件未提交。如果此錯誤仍然存在，請聯繫您的服務台。',
  },
  FormSubmissionSuccess: { 'Ref: submitted success': '您的留言已提交。感謝您與我們聯繫。' },
};
export default labels;
