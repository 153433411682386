import { useHistory } from 'react-router-dom';

import { pagePaths } from '../../config';
import useContactsTranslation from '../../hooks/useContactsTranslation';

import NoticePage from '@/components/templates/NoticePage/NoticePage';
import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const FormSubmissionFailure = () => {
  const history = useHistory();
  const { label } = useContactsTranslation(__filename);

  return (
    <NoticePage
      title={label('sorry (standalone)', { textTransform: 'capitalize' })}
      content={label('Ref: Message body')}
      type={NOTICEPAGE_TYPE.ERROR}
      actions={[
        {
          label: label('to home (as in navigate to the homepage)', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
        {
          label: label('retry', { textTransform: 'capitalize' }),
          action: () => history.push(pagePaths.ContactForm),
        },
      ]}
    />
  );
};

export default FormSubmissionFailure;
