const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Szolgáltatási kérelem - ügyfélszolgálat',
    'Ref: Forbidden heading': 'Tiltott',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'A szolgáltatási kérelmeket a {siteName} webhelyen adminisztrálja.',
    'Ref: Dates': 'Dátumok',
    'Ref: SRL list refresh': 'Lista frissítése',
    'Ref: Export': 'export',
    'Ref: Empty/not filled': 'Üres / nem tele',
    'Ref: Name': 'Név',
    'Ref: Date': 'Dátum',
    'Ref: Category': 'Kategória',
    'Ref: User': 'Felhasználó',
    'Ref: Location': 'Elhelyezkedés',
  },
};
export default labels;
