import classNames from 'classnames';

import { Information } from '../../../../assets/icons';
import { CardWithH2TitleAndIcon } from '../../../../storybook/molecules/Card.stories';
import { TITLE_SIZE, TITLE_TAG } from '../../../atoms/Title';

import { FacilityDetailsProps } from './facilityDescription.types';

import styles from './facilityDescription.module.css';

const FacilityDescription = ({ facility, label }: FacilityDetailsProps) => {
  const showDetails = facility?.openingHours?.length && facility?.description;

  return showDetails ? (
    <CardWithH2TitleAndIcon
      icon={{ icon: Information, className: styles.icon }}
      title={{
        tag: TITLE_TAG.H2,
        children: label('More (as in: see more info)'),
        size: TITLE_SIZE.HEADLINEXS,
      }}
    >
      {facility.description ? (
        <div className={classNames(styles.descriptionContainer)}>{facility.description}</div>
      ) : null}
    </CardWithH2TitleAndIcon>
  ) : null;
};

export default FacilityDescription;
