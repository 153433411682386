import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import PrivacyPolicyIntroductionPage from '../PrivacyPolicyIntroductionPage';

describe('PrivacyPolicyIntroductionPage', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let title: HTMLElement,
      content: HTMLElement,
      firstButton: HTMLElement,
      secondButton: HTMLElement;

    renderComponent(PrivacyPolicyIntroductionPage);

    beforeEach(() => {
      title = screen.getByTestId('privacy-policy-page-title');
      content = screen.getByTestId('privacy-policy-page-body');
      firstButton = screen.getByTestId('button-action-primary');
      secondButton = screen.getByTestId('button-action-tertiary');
    });

    it('should have displayed generic text', () => {
      expect(title).toBeTruthy();
      expect(content).toBeTruthy();
      expect(firstButton).toBeTruthy();
      expect(secondButton).toBeTruthy();

      expect(screen.getByText('Ref: Privacy Policy Title')).toBeTruthy();
      expect(screen.getByText('Ref: Privacy Policy Body')).toBeTruthy();
      expect(screen.getByText('Ref: Continue')).toBeTruthy();
      expect(screen.getByText('Ref: Read Privacy Policy')).toBeTruthy();
    });
  });

  describe('on "Continue" button click', () => {
    let button: HTMLElement;
    const acknowledge = jest.fn();
    renderComponent(PrivacyPolicyIntroductionPage, {
      acknowledge,
    });

    beforeEach(() => {
      button = screen.getByTestId('button-action-primary');
      fireEvent.click(button);
      acknowledge.mockReturnValue(() => Promise.resolve({ ok: true }));
    });

    it('should have redirected to Homepage', async () => {
      const history = createMemoryHistory();
      expect(screen.getByText('Ref: Continue')).toBeTruthy();
      expect(acknowledge).toHaveBeenCalled();
      expect(history.location.pathname).toBe('/');
    });
  });

  describe('on "Read Privacy Policy" button click', () => {
    let button: HTMLElement;
    const showLegalDocsPage = jest.fn();
    renderComponent(PrivacyPolicyIntroductionPage, { showLegalDocsPage });

    beforeEach(() => {
      button = screen.getByTestId('button-action-tertiary');
      fireEvent.click(button);
      showLegalDocsPage.mockReturnValue(() => Promise.resolve({ ok: true }));
    });

    it('should redirect to LegalDocsPage', async () => {
      expect(screen.getByText('Ref: Read Privacy Policy')).toBeTruthy();
      expect(showLegalDocsPage).toHaveBeenCalled();
    });
  });
});
