const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Your request',
    'Ref: Summary': 'Summary',
    'Ref: Request category': 'Request category',
    'Ref: Person affected': 'The person affected by this request',
    'Ref: Your phone number': 'Phone number',
    'Ref: Description': 'Description',
    'Ref: Service Requests': 'Service Requests',
    'Ref: Request Service': 'Request Service',
    'Ref: Success title': 'Your request has been created',
    'Ref: Error title': 'Oops!',
    'Ref: Error subtitle': 'An error has occurred, please try again.',
    'Ref: Try again': 'Try again',
    'Ref: you pronoun': '(you)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'This is your workplace support hub',
    'Ref: Tutorial: tabname': 'Requests',
    'Ref: Tutorial: body': 'We are here to help you. Please log one request per entry.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'You are requesting service on the site {siteName}.',
    'Ref: Building': 'Building',
    'Ref: Room': 'Room',
    'Ref: Description help text': 'The more detailed you are, the better we can support you.',
    'Ref: Next': 'Next',
    'Ref: Your request': 'Your request',
    'Ref: PhoneNumber error msg': 'Phone number is not valid',
    'Ref: Type at least 3 characters to search': 'Type at least 3 characters to search',
    'Ref: No affected person results': 'No person matching requested criteria',
    'Ref: Required': '* Required',
  },
  Summary: {
    'Ref: Location': 'Location',
    'Ref: Attachment': 'Attachments',
    'Ref: Submit': 'Submit',
    'Ref: No affected person results': 'No person matching requested criteria',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Service Request',
    'Ref: createdOn': 'Created on',
    'Ref: summary': 'Summary',
    'Ref: category': 'Request category',
    'Ref: request raised for': 'The person affected by this request',
    'Ref: phoneNumber': 'Phone number',
    'Ref: location': 'Phone number',
    'Ref: description': 'Description',
    'Ref: attachments': 'Attachments',
  },
};
export default labels;
