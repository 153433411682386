import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { defaultNamespace } from './hooks/useCustomTranslation';

export const fallbackLng = 'en-US';

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend(async (language: string, namespace: string) => {
      if (namespace === defaultNamespace) {
        const commonTranslations = await import(`../languages/${language}.ts`);
        return { ...commonTranslations.default };
      }

      const namespaceTranslations = await import(
        `../modules/${namespace}/languages/${language}.ts`
      );

      return { ...namespaceTranslations.default };
    })
  )
  .init({
    debug: false,
    fallbackLng: fallbackLng,
    lng: fallbackLng,
    load: 'currentOnly',

    nsSeparator: '::', // default is ':' which will not work because a lot of keys has ':' as part of key, e.g 'Ref: Audit'
    defaultNS: defaultNamespace,
    fallbackNS: defaultNamespace,
    interpolation: {
      escapeValue: false,
      prefix: '{',
      suffix: '}',
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
