import history from '../../../history';
import { ISite } from '../types/sites.types';

import { pagePaths } from './../config';

export function getMarkersFromSites({
  sites,
  selectedSiteId,
  inactiveMarkerIcon,
  activeMarkerIcon,
}: {
  sites: ISite[];
  selectedSiteId?: string;
  inactiveMarkerIcon: string;
  activeMarkerIcon: string;
}) {
  return (sites || [])
    .filter((site) => Boolean(site.geoCoordinates))
    .map((site) => ({
      id: site.id,
      icon: site.id === selectedSiteId ? activeMarkerIcon : inactiveMarkerIcon,
      coordinates: {
        longitude: site.geoCoordinates!.longitude,
        latitude: site.geoCoordinates!.latitude,
      },
      data: { site },
    }));
}

export const switchSiteWithRedirect = (onSwitchSitePath: string) =>
  history.push(pagePaths['Module'], { onSwitchSitePath });
