import moment from 'moment';
import { useEffect, useState } from 'react';

import { Receipt, WarningOutlinedIcon } from '../../../../assets/icons';
import Title from '../../../../components/atoms/Title';
import WidgetPlaceholder from '../../../../components/molecules/WidgetPlaceholder/WidgetPlaceholder';
import Widget from '../../../../components/organisms/Widget';
import useShareModal from '../../../../helpers/hooks/useShareModal';
import { useGetEventsQuery } from '../../api';
import EventTile from '../../components/EventTile';
import { pagePaths } from '../../config';
import { findNextEventDateString } from '../../helpers/eventDates.helper';
import { useEventTranslation } from '../../hooks/useEventTranslation';
import { EventType } from '../../types';

import { BackgroundWidgetEventIllustration } from '@/assets/illustrations';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';

const MAX_ITEMS = 3;

const EventWidget = () => {
  const { id: siteId } = useSite({ throwWhenNoActiveSite: true })!;
  const { currentLanguageCode } = useLanguage();
  const { label } = useEventTranslation(__filename);
  const [widgetEvents, setWidgetEvents] = useState<EventType[]>([]);
  const { data: list = [], error } = useGetEventsQuery({
    siteId,
    future: true,
    preferredevents: false,
    useErrorBoundary: false,
  });

  useEffect(() => {
    setWidgetEvents([]);
  }, [siteId]);

  useEffect(() => {
    if (list && list.length) {
      setWidgetEvents(list.length > MAX_ITEMS ? list.slice(0, MAX_ITEMS) : list);
    }
  }, [list]);

  const { shareOptionsModal, triggerShareModal } = useShareModal({
    shareName: '',
    label,
  });

  const items = widgetEvents.map((eventPiece: EventType) => {
    const description =
      label('from (followed by a date, or date and time)', {
        textTransform: 'capitalize',
        end: 'colon',
      }) +
      findNextEventDateString(eventPiece.eventDates, currentLanguageCode) +
      label('at (followed by time)', {
        start: 'whitespace',
        end: 'whitespace',
      }) +
      moment.utc(eventPiece.startDate).format('LT') +
      label('to (followed by a date, or date and time)', {
        start: 'whitespace',
        end: 'colon',
      }) +
      moment.utc(eventPiece.endDate).format('LT');

    return { ...eventPiece, description };
  });

  const widgetItems = items.map((item: EventType, index: number) => (
    <EventTile
      key={`${index}_${item.id}`}
      event={item}
      label={label}
      triggerShareModal={triggerShareModal}
      data-testid={`EventTile-${item.id}`}
    />
  ));

  return (
    <>
      <Widget>
        <Widget.Header illustration={<BackgroundWidgetEventIllustration />}>
          <Widget.Title>
            <Title>{label('Ref: Carousel title')}</Title>
            {items?.length ? (
              <Widget.Link to={pagePaths['Module']}>
                {label('see all (as in see all items)', { textTransform: 'capitalize' })}
              </Widget.Link>
            ) : null}
          </Widget.Title>
        </Widget.Header>
        <Widget.Body>
          {items?.length ? (
            widgetItems
          ) : (
            <WidgetPlaceholder
              title={label(
                error ? 'Something went wrong, data not loaded' : 'No info yet, come back later'
              )}
              icon={error ? <WarningOutlinedIcon /> : <Receipt />}
            />
          )}
        </Widget.Body>
      </Widget>
      {shareOptionsModal}
    </>
  );
};

export default EventWidget;
