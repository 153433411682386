const labels = {
  StaysList: {
    'Ref: Page title': 'Soggiorni',
    'Ref: Past': 'Passato',
    'Current and future stays': 'Soggiorni attuali e futuri',
    'filter label: period': 'Periodo',
    'Ref: Search': 'Ricerca',
  },
  StayDetails: {
    'Ref: Page title': 'Dettagli dei tuoi Soggiorni',
    'Arrival date': "Data d'arrivo",
    'Departure date': 'Data di partenza',
    'Room number': 'camera',
    'Reservation number': 'Numero di prenotazione',
    CheckIn: 'Registrazione',
    CheckOut: 'Uscita',
    CheckIn_1: 'Check in {lodgeName}, arrivato oggi? (Numero di prenotazione {resNumber})',
    CheckedIn_1: 'Sei arrivato in {lodgeName}, room {roomNumber}.',
    CheckOut_1:
      'Controlla {lodgeName} numero di prenotazione {resNumber} ora? La chiave della stanza sarà disattivata.',
    CheckedOut_1: '{lodgeName} numero di prenotazione {resNumber} ha lasciato.',
  },
  StaysSuccess: {
    'Ref: Title': 'Grazie per il tuo soggiorno!',
    'Ref: Body': 'Il tuo checkout è stato elaborato con successo.',
    'Ref: Go to home': 'Vai a casa',
    'Ref: Provide a feedback': 'Fornire un feedback',
  },
};
export default labels;
