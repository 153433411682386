import { cleanup, screen } from '@testing-library/react';

import Tutorials from '../Tutorials';

import renderComponent from '@/helpers/tests/renderComponent';

describe('Tutorials', () => {
  afterAll(() => cleanup());
  renderComponent(Tutorials);
  describe('Render', () => {
    it('should render', () => {
      const element = screen.queryByTestId('button-action-primary-content');
      expect(element).toBeTruthy();
    });

    it('should display message', () => {
      expect(screen.getByText('This is your workplace support hub')).toBeTruthy();
      expect(
        screen.getByText('We are here to help you. Please log one request per entry.')
      ).toBeTruthy();
    });
  });
});
