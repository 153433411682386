const labels = {
  Success: {
    'Ref: Body':
      'Grazie per aver aderito a questo sondaggio, il tuo tempo è prezioso come questi sondaggi che ci aiutano a migliorare i nostri servizi.',
    'Ref: Tabtitle': 'Sondaggio completato',
    'Take another survey': 'Fare un altro sondaggio',
  },
  Survey: {
    'Ref: Page title': 'Dettagli del sondaggio',
    'Please answer all mandatory questions as identify by an asterisk':
      'Si prega di rispondere a tutte le domande obbligatorie come identificative da un asterisco: *',
    'Ref: Take another survey': 'Fare un altro sondaggio',
    'Ref: Go to Home': 'Vai a casa',
  },
  SurveysList: {
    'Ref: Page title': 'Sondaggio',
    'Ref: Available until': 'Disponibile fino',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Attualmente stai visualizzando sondaggi per il sito {site name}.',
  },
};
export default labels;
