const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Din förfrågan',
    'Ref: Summary': 'Sammanfattning',
    'Ref: Request category': 'Begäran kategori',
    'Ref: Person affected': 'Den person som drabbats av denna begäran',
    'Ref: Your phone number': 'Telefonnummer',
    'Ref: Description': 'Beskrivning',
    'Ref: Service Requests': 'Serviceförfrågningar',
    'Ref: Request Service': 'Begäran',
    'Ref: Success title': 'Din begäran har skapats',
    'Ref: Error title': 'Hoppsan!',
    'Ref: Error subtitle': 'Ett fel har inträffat. Var vänlig försök igen.',
    'Ref: Try again': 'Försök igen',
    'Ref: you pronoun': '(du)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Det här är ditt arbetsplatsstödnav',
    'Ref: Tutorial: tabname': 'Förfrågningar',
    'Ref: Tutorial: body': 'Vi är här för att hjälpa dig. Vänligen logga en begäran per post.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Du begär service på webbplatsen {siteName}.',
    'Ref: Building': 'Byggnad',
    'Ref: Room': 'Rum',
    'Ref: Description help text': 'Ju mer detaljerad du är, desto bättre kan vi stödja dig.',
    'Ref: Next': 'Nästa',
    'Ref: Your request': 'Din förfrågan',
    'Ref: PhoneNumber error msg': 'Telefonnummer är inte giltigt',
    'Ref: Type at least 3 characters to search': 'Skriv minst 3 tecken för att söka',
    'Ref: No affected person results': 'Ingen person matchar begärda kriterier',
    'Ref: Required': '* Krävs',
  },
  Summary: {
    'Ref: Location': 'Plats',
    'Ref: Attachment': 'Bilagor',
    'Ref: Submit': 'Skicka in',
    'Ref: No affected person results': 'Ingen person matchar begärda kriterier',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Serviceförfrågan',
    'Ref: createdOn': 'Skapad på',
    'Ref: summary': 'Sammanfattning',
    'Ref: category': 'Begäran kategori',
    'Ref: request raised for': 'Den person som drabbats av denna begäran',
    'Ref: phoneNumber': 'Telefonnummer',
    'Ref: location': 'Telefonnummer',
    'Ref: description': 'Beskrivning',
    'Ref: attachments': 'Bilagor',
  },
};
export default labels;
