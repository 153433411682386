const labels = {
  All: {
    'status: all': 'Allt',
    'status: open': 'Öppna',
    'status: pending': 'I väntan på',
    'status: closed': 'Stängd',
    Location: 'Plats',
    Subject: 'Ämne',
    Description: 'Din detaljerade begäran',
    'Ref: description field label': 'Beskrivning',
    'Ref: Title': 'Titel',
    phone_number: 'Telefonnummer',
    request_category: 'Begäran kategori',
    Attachments: 'Bilagor',
    'request service': 'Begäran',
    'New service request': 'Skapa en annan begäran',
    'Go to home': 'Gå till hemmet',
    'Back to this service request': 'Tillbaka till denna tjänstförfrågan',
    'Back to service requests': 'Gå till hemmet',
    Feedback: 'Respons',
    'Ref: Request category': 'Kategori',
    Cleaning: 'Rengöring',
    'Laundry services': 'Tvättstjänster',
    'Grounds maintenance': 'Underhåll av mark',
    'Soft services': 'Mjuka tjänster',
    'Hard services': 'Hårda tjänster',
    'Pest control services': 'Skadedjursbekämpningstjänster',
    'Waste management': 'Avfallshantering',
    'Asset Lifecycle Services': 'Tillgångscykeltjänster',
    'There are no updates for this request': 'Det finns inga uppdateringar för denna begäran',
    'Transportation Services': 'Transporttjänst',
    'Ref: you pronoun': '(du)',
    'Ref: SR raised for': 'Den berörda personen av denna begäran',
    'Ref: Select a location': 'Välj en plats',
    'Ref: Your request': 'Din förfrågan',
    'Ref: Summary': 'Sammanfattning',
    'Ref: site': 'Webbplats',
  },
  Comment: {
    'Ref: Page title': 'Uppdatera oss',
    'Ref: please update us with details':
      'Mer information för att kommunicera till oss? En kommentar? Ju mer vi vet om din begäran, desto bättre kan vi stödja dig.',
  },
  Confirm: {
    'Ref: Page title': 'Bekräfta din begäran',
    'Ref: next': 'Skicka in',
    summary: 'Sammanfattning',
    'Ref: Save as my preferred location': 'Spara som min föredragna plats',
  },
  Describe: {
    'Ref: Page title': 'Beskriv din begäran',
    'Ref: Required fields: header': 'Ange ytterligare information',
    'Ref: Required fields': 'Följande information bör tillhandahållas i fältet detaljer:',
    'Ref: Required field: title': 'En titel krävs.',
    'Ref: Required field: description': 'En beskrivning krävs.',
    'Ref: Required field: phone': 'Ett telefonnummer krävs.',
    'Ref: Required field: category': 'En kategori krävs.',
    'Ref: Invalid field: category': 'Förutsatt att kategorin är ogiltig.',
    'Ref: Request template field label': 'Populära förfrågningar',
    'Ref: Request template field placeholder': 'Tomt papper',
    your_request: 'Din förfrågan',
    'Ref: Description message': 'Ju mer detaljerad du är, desto bättre kan vi stödja dig.',
    phone_number: 'Ditt telefonnummer (använd din professionella telefon om du har en)',
    'Ref: Affected person': 'Påverkad person av denna begäran',
    'Ref: No affected person results': 'Ingen person matchar begärda kriterier',
    'Ref: Notification title': 'Du har ändrat plats',
    'Ref: Notification info': 'Du är nu på {site name}',
    'Ref: Add photos': 'Lägg till foton',
    'Ref: Your location': 'Din plats',
  },
  Details: {
    'Ref: Page title': 'Begär detaljer',
    Details: 'Information',
    Updates: 'Uppdateringar',
    'Update us': 'Uppdatera oss',
    'Ref: timeline': 'Tidslinje',
    'Ref: attachments': 'Bilagor',
    'Ref: location': 'Plats',
    'Ref: createdOn': 'Skapad på',
    'Ref: reference': 'Referens',
    'Feedback was submitted': 'Feedback skickad',
    'Ref: sent': 'Skickad',
    'Ref: received': 'Mottagen',
    'Ref: inProgress': 'Pågående',
    'Ref: completed': 'Avslutad',
    'Ref: conversation': 'Konversation',
    'Ref: Priority': 'Prioritet',
    'Ref: Target start date': 'Målstartdatum',
    'Ref: Target completion date': 'Måltidsdatum',
    'Ref: Priority fields placeholder message':
      'Det kommer att fyllas när du valideras av kundtjänst',
  },
  Failed: {
    'Ref: Body':
      'Ett fel inträffade och din serviceförfrågan skapades inte. Om det här felet kvarstår, vänligen kontakta din kundtjänst.',
    'Ref: Tabtitle': 'Serviceförfrågan misslyckades med att skapa',
  },
  Home: { 'Ref: Page title': 'Förfrågningar' },
  RequestFeedback: {
    'Ref: Page title': 'Respons',
    'Ref: Feedback headline': 'Vänligen dela dina feedback',
    'Ref: Feedback title': 'Feedback om sr #{ref}',
    'Ref: Question: general sentiment':
      'Sammantaget, hur nöjd är du med hur Sodexo hanterade din tjänstförfrågan?',
    'Ref: Question: answer quality': 'Hur skulle du betygsätta kvaliteten på tjänsten du fick?',
    'Ref: Question: staff interaction':
      'Hur skulle du betygsätta dina interaktioner med vår personal?',
    'Ref: Question: efficiency': 'Hur skulle du betygsätta vår effektivitet?',
    'Ref: Question: open': 'Kommentar',
    'Ref: Open question placeholder': 'Vad gjorde vi bra? Var kan vi förbättra?',
    no_comment: 'Ingen kommentar',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Tack för att du tog dig tid att lämna en kommentar, din feedback är viktig för oss att förbättra din upplevelse.',
  },
  Locate: {
    'Ref: Page title': 'Välj en plats',
    'Ref: Top bar title': 'Leta reda på din begäran',
    'Ref: Select this location': 'Välj den här platsen',
    drill: 'bläddra',
    'Ref: Scanner button': 'Plats med en QR? Skanna den',
    'Ref: Scanner header': 'Vänligen skanna din plats QR',
    'Sorry, this location could not be found.': 'Tyvärr, den här platsen kunde inte hittas.',
    'Close scanner': 'Stänga skanner',
    'Ref: Switch site component intro sentence': 'Du begär service på webbplatsen {site name}.',
    'Ref: search location by keywords': 'Sök en plats',
    'Ref: Enter Location name': 'Ange platsnamn',
    'Ref: Enter manual location': 'Ange plats manuellt',
    'Ref: Location name': 'Platsnamn',
    'Ref: No results': 'Vi kunde inte hitta din plats. Du kanske vill mata in det manuellt.',
    'Ref: Up one level': 'Upp en nivå',
    'Ref: Navigate': 'Navigera',
    'Ref: Select': 'Välj',
    'Ref: Selected Location': 'Vald plats',
    'Ref: Preferred Location': 'föredragen plats',
    'Ref: Building': 'Byggnad',
    'Ref: Floor': 'Våning',
    'Ref: Aisle': 'Korridor',
    'Ref: Room': 'Rum',
    'Ref: Notification title': 'Du har ändrat plats',
    'Ref: Notification info': 'Du är nu på {site name}',
    'Ref: Scanner error header': 'Skanningsfel',
    'Ref: Scan QR Code': 'Skanna QR kod',
    'Ref: Error body': 'Denna QR -kod är inte en giltig kod',
    'Ref: Loading Locations Warning':
      'Vänta medan vi förbereder din upplevelse för en första begäran på den här webbplatsen.',
    'Ref: Search Result is limited to:':
      'Sökresultatet är begränsat till {limit}. Vänligen förfina dina sökkriterier.',
    'Ref: Open the location search field': 'Öppna platsens sökfält',
  },
  Success: {
    'Ref: Body':
      'Vi har fått din begäran och undersöker det nu. Vi kommer snart tillbaka till dig. Det kan ta upp till 20 minuter att se din tjänstförfrågan i appen.',
    'Ref: Tabtitle': 'Servicebegäran skapad',
    'Ref: Download app title': 'Förenkla dina serviceförfrågningar',
    'Ref: Download app subtitle': 'Ladda ner vår app och höj din upplevelse',
    'Ref: Download app button': 'Ladda ner appen',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Det här är ditt arbetsplatsstödnav',
    'Ref: Tutorial: tabname': 'Förfrågningar',
    'Ref: Tutorial: body': 'Vi är här för att hjälpa dig. Vänligen logga en begäran per post.',
    "What's new: title": 'Hitta din plats enkelt',
    "What's new: body":
      'Du kan nu skanna en plats QR, borra ner platsnivåer eller bara välja från våra intelligenta förslag.',
  },
  Widget: {
    'Ref: Service request': 'Serviceförfrågan',
    'Ref: Placeholder body': 'Ingen serviceförfrågan för tillfället',
    'Ref: New request button': 'Ny förfrågan',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Du har ändrat plats',
    'Ref: Notification info': 'Du är nu på {site name}',
    'Ref: Welcome': 'Välkommen! {wave_emoji}',
    'Ref: Create service request text': 'Har du märkt något du vill rapportera?',
    'Create a service request': 'Skapa ett ärende',
    'Ref: Share feedback': 'Dela en feedback',
    'Ref: Share text': 'Vill du dela något med oss?',
    'Go to home': 'Gå till startsidan',
    'Ref: Error body':
      'QR-koden du skannade fungerar inte längre. Försök igen senare eller kontakta supporten.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Ett fel har uppstått',
    'Ref: Error body':
      'QR-koden du skannade fungerar inte längre. Försök igen senare eller kontakta supporten.',
    'Go to home': 'Gå till startsidan',
  },
  ManualDescribe: {
    'Ref: Page title': 'Beskriv din begäran',
    'Ref: Required fields: header': 'Ange ytterligare information',
    'Ref: Required fields': 'Följande information bör tillhandahållas i fältet detaljer:',
    'Ref: Required field: title': 'En sammanfattning krävs.',
    'Ref: Required field: description': 'En beskrivning krävs.',
    'Ref: Required field: category': 'En kategori krävs.',
    'Ref: Required field: buildingText': 'En byggnad krävs.',
    'Ref: Invalid field: category': 'Förutsatt att kategorin är ogiltig.',
    'Ref: Your request': 'Ditt ärende',
    'Ref: Description message': 'Ju mer detaljerad du är, desto bättre kan vi stödja dig.',
    'Ref: Do you have a location QR code': 'Har du en plats QR -kod?',
    'Ref: Scan QR Code': 'Skanna QR kod',
    'Ref: Please select category': 'Välj kategori',
    'Ref: Please select site': 'Välj webbplats',
    'Ref: building': 'Byggnad',
    'Ref: room': 'Rum',
    'Ref: Scan error': 'Skanningsfel',
    'Ref: The QR code is not valid': 'QR -koden är inte giltig',
    'Ref: Add location with QR Scanner': 'Lägg till plats med QR -skanner',
    'Ref: Description': 'Beskrivning',
    'Ref: Forbidden error': 'Vi är ledsna, den här QR -koden matchar inte din registrerade plats.',
    'Ref: Invalid error': 'Vi är ledsna, det verkar som om denna QR -kod inte längre är giltig.',
  },
};
export default labels;
