import classNames from 'classnames';

import Textarea from '../../../../components/atoms/Textarea';

import { AdditionalCommentProps } from './AdditionalComment.types';

import styles from './AdditionalComment.module.css';

const AdditionalComment = ({
  isDisabled,
  isCommentRequired,
  name,
  label,
  ...props
}: AdditionalCommentProps) => {
  return (
    <div className={classNames(styles.wrapper)}>
      <Textarea
        {...props}
        name={name}
        label={label('Ref: Additional Comments')}
        required={isCommentRequired}
        data-testid={'additional-comment-textarea'}
        disabled={isDisabled}
      />
    </div>
  );
};

export default AdditionalComment;
