const labels = {
  ContentDetails: {
    'Ref: Page title': '컨텐츠 세부 사항',
    'See the event': '이벤트를 참조하십시오',
    'Take the survey': '설문 조사에 참여 해주세요',
    Date: '날짜',
    Category: '범주',
    'Ref: Content site different title': '이 콘텐츠는 현재 사이트에 속하지 않습니다',
    'Ref: Content site different description':
      '현재 사이트에 속하지 않는 콘텐츠를보고 있습니다. 콘텐츠 목록에서 볼 수 없습니다.',
  },
  ContentList: {
    'Ref: Page title': '뉴스 피드',
    'featured (as in featured content)': '특집',
    'Ref: category - all': '모두',
    'Ref: category - promotional': '프로모션',
    'Ref: category - featured': '추천',
    'Ref: category - other': '다른',
  },
  Widget: {
    'Ref: Carousel title': '뉴스 및 기사',
    'Ref: Carousel title promo': '최고의 뉴스',
    'No info yet, come back later': '아직 정보가없고 나중에 다시 오십시오 😉',
    'Ref: Content': '콘텐츠',
    'Ref: Widget Title': '뉴스 피드',
    'Ref: MyVillage Carousel title promo': '프로모션',
  },
};
export default labels;
