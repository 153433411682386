import classNames from 'classnames';

import ImageWithFallback from '../../../components/atoms/ImageWithFallback/ImageWithFallback';

import { getProductIllustration } from './ProductsList/productList.helper';

import styles from './ProductDetails.module.css';

import { DefaultComponentWithoutChildrenPropsV2 } from 'src/types';

export const DEFAULT_NUTRITION_CALCULATION = 'DefaultNutritionCalculation';
export const defaultNutritionValue = 100;

type MenuImageProps = {
  menuItem: MenuItem | undefined;
} & DefaultComponentWithoutChildrenPropsV2;

export const MenuImage = ({ menuItem, 'data-testid': dataTestId }: MenuImageProps) => {
  return (
    <div className={classNames(styles.imgContent)} data-testid={dataTestId}>
      <ImageWithFallback
        data-testid="product-details"
        imgElement={getProductIllustration(menuItem?.genericCategory as string)}
      />
    </div>
  );
};
export const MenuDescription = ({
  menuItem,
  'data-testid': dataTestId,
}: { menuItem: MenuItem | undefined } & DefaultComponentWithoutChildrenPropsV2) =>
  menuItem?.description ? (
    <p className={styles.menuDescription} data-testid={dataTestId}>
      {menuItem.description}
    </p>
  ) : null;

export function getRedirectURL(prefix: string, facilityId: string, menuId: string, date: string) {
  const newURL = `/${prefix}/${facilityId}/${menuId}/${date}/products`;

  return newURL;
}
