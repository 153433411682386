import { StringOptions } from '../localization.types';

export function applyStringOptions(txt: string, options?: StringOptions): string {
  let result = txt;
  // TODO refactor to use newer textTransform helper. Since this requires more refactoring to use enums instead of string literals
  switch (options?.textTransform) {
    case 'uppercase':
      result = result.toUpperCase();
      break;
    case 'lowercase':
      result = result.toLowerCase();
      break;
    case 'capitalize':
      result = result.charAt(0).toUpperCase() + result.slice(1);
      break;
    default:
  }
  switch (
    options?.start //todo: support asian languages
  ) {
    case 'whitespace':
      result = ' ' + result;
      break;
    case 'comma':
      result = ', ' + result;
      break;
    case 'colon':
      result = ': ' + result;
      break;
    case 'semi-colon':
      result = '; ' + result;
      break;
    default:
  }
  switch (
    options?.end //todo: support asian languages
  ) {
    case 'whitespace':
      result = result + ' ';
      break;
    case 'comma':
      result = result + ', ';
      break;
    case 'colon':
      result = result + ': ';
      break;
    case 'semi-colon':
      result = result + '; ';
      break;
    default:
  }

  return result;
}
