import { screen, cleanup } from '@testing-library/react';

import { formatDate } from '../../../../../helpers/dateTime';
import renderComponent from '../../../../../helpers/tests/renderComponent';
import { STAY_STATUS } from '../../../components/StayStatus/StayStatus.types';
import { IStay, IDetailedStay } from '../../../types/accomodation.types';
import StayDetails from '../StayDetails';

import { useGetStayDetailsQuery } from '@/modules/Accomodation/api';

const stayId = 'some-id';
const currentLanguageCode = 'en-US';

const mockPostCustomActionMutation = jest.fn();

jest.mock('@/modules/Accomodation/api', () => ({
  useGetStayDetailsQuery: jest.fn(),
  usePostCustomActionMutation: () => [mockPostCustomActionMutation, { loading: false }],
}));

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: () => ({ id: stayId }),
}));

describe('StayDetails', () => {
  afterAll(() => cleanup());

  describe('When requesting stay info', () => {
    beforeEach(() => {
      (useGetStayDetailsQuery as jest.Mock).mockReturnValue({
        data: {},
        isFetching: true,
      });
    });

    renderComponent(StayDetails);

    it('Should display skeleton', async () => {
      expect(screen.queryByTestId('skeleton-placeholder1')).toBeTruthy();
      expect(screen.queryByTestId('skeleton-placeholder2')).toBeTruthy();
    });

    it('check if getStayDetails was called', async () => {
      expect(useGetStayDetailsQuery).toHaveBeenCalled();
    });

    it('check if correct parameters was passsed in getStayDetails call', async () => {
      expect(useGetStayDetailsQuery).toHaveBeenCalledWith({
        stayId,
        preferredLanguage: currentLanguageCode,
      });
    });
  });

  describe('When Stay has no actions', () => {
    const stay: IStay = {
      id: 'some-id',
      reservationNumber: '99',
      siteId: 'site-id',
      siteName: 'site name',
      arrivalDateLocal: '2022-01-01 20:20:10',
      departureDateLocal: 'Some place',
      status: STAY_STATUS.CHECKED_IN,
    };

    beforeEach(() => {
      (useGetStayDetailsQuery as jest.Mock).mockReturnValue({ data: stay, isFetching: false });
    });

    renderComponent(StayDetails);

    it('should display page without actions bar', () => {
      expect(screen.queryByTestId('container')).not.toBeNull();
      expect(screen.queryByTestId('actions-bar')).toBeNull();
    });

    it('should display title', () => {
      const title = screen.getByTestId('title');
      expect(title).toHaveTextContent(stay.siteName);
    });

    it('should display stay info', () => {
      const reservationNumber = screen.getByTestId(
        'stay-details-list-reservation-number-item-list-item-value'
      );
      expect(reservationNumber).toHaveTextContent(stay.reservationNumber);

      const arrivalDateLocal = screen.getByTestId(
        'stay-details-list-arrival-date-item-list-item-value'
      );
      expect(arrivalDateLocal).toHaveTextContent(
        formatDate(new Date(stay.arrivalDateLocal), currentLanguageCode)
      );

      const departureDateLocal = screen.getByTestId(
        'stay-details-list-departure-date-item-list-item-value'
      );
      expect(departureDateLocal).toHaveTextContent(
        formatDate(new Date(stay.departureDateLocal), currentLanguageCode)
      );

      expect(true).toBeTruthy();
    });
  });

  describe('when Stay has actions', () => {
    const stay: IDetailedStay = {
      id: 'some-id',
      reservationNumber: '99',
      siteId: 'site-id',
      siteName: 'site name',
      arrivalDateLocal: '2022-01-01 20:20:10',
      departureDateLocal: 'Some place',
      status: STAY_STATUS.CHECKED_IN,
      room: '999',
      action: [
        {
          type: 'CheckOut',
          isActive: true,
          msgCode: '',
          msgParams: { lodgeName: '', resNumber: '' },
          isUserRedirected: true,
          url: '',
        },
      ],
      customProperties: { property1: 'Value 1', property2: 'Value 2' },
    };

    beforeEach(() => {
      (useGetStayDetailsQuery as jest.Mock).mockReturnValue({ data: stay, isFetching: false });
    });

    renderComponent(StayDetails);

    it('should display title', () => {
      const title = screen.getByTestId('title');
      expect(title).toHaveTextContent(stay.siteName);
    });

    it('should display actions bar', () => {
      expect(screen.queryByTestId('container')).not.toBeNull();
      expect(screen.queryByTestId('actions-bar')).not.toBeNull();
    });

    it('should display room number', () => {
      const room = screen.getByTestId('stay-details-list-room-item-list-item-value');
      expect(room).toHaveTextContent(stay.room!);
    });

    it('should display custom properties', () => {
      const property1 = screen.getByTestId('stay-details-list-stay-item-property1-list-item-value');
      expect(property1).toHaveTextContent(stay?.customProperties?.property1!);

      const property2 = screen.getByTestId('stay-details-list-stay-item-property2-list-item-value');
      expect(property2).toHaveTextContent(stay?.customProperties?.property2!);
    });
  });
});
