const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'La tua richiesta',
    'Ref: Summary': 'Riepilogo',
    'Ref: Request category': 'Categoria di richiesta',
    'Ref: Person affected': 'La persona interessata da questa richiesta',
    'Ref: Your phone number': 'Numero di telefono',
    'Ref: Description': 'Descrizione',
    'Ref: Service Requests': 'Richieste di servizio',
    'Ref: Request Service': 'Servizio di richiesta',
    'Ref: Success title': 'La tua richiesta è stata creata',
    'Ref: Error title': 'Oops!',
    'Ref: Error subtitle': 'Si è verificato un errore. Per favore riprova.',
    'Ref: Try again': 'Riprova',
    'Ref: you pronoun': '(lei)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Questo è il tuo hub di supporto sul posto di lavoro',
    'Ref: Tutorial: tabname': 'Richieste',
    'Ref: Tutorial: body': 'Siamo qui per aiutarvi. Registra una richiesta per voce.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Stai richiedendo il servizio sul sito {siteName}.',
    'Ref: Building': 'Edificio',
    'Ref: Room': 'Camera',
    'Ref: Description help text': 'Più sei dettagliato, meglio possiamo supportarti.',
    'Ref: Next': 'Prossimo',
    'Ref: Your request': 'La tua richiesta',
    'Ref: PhoneNumber error msg': 'Il numero di telefono non è valido',
    'Ref: Type at least 3 characters to search': 'Digita almeno 3 caratteri per la ricerca',
    'Ref: No affected person results': 'Nessuna persona corrisponde ai criteri richiesti',
    'Ref: Required': '* Necessario',
  },
  Summary: {
    'Ref: Location': 'Posizione',
    'Ref: Attachment': 'Allegati',
    'Ref: Submit': 'Invia',
    'Ref: No affected person results': 'Nessuna persona corrisponde ai criteri richiesti',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Richiesta servizio',
    'Ref: createdOn': 'Creato',
    'Ref: summary': 'Riepilogo',
    'Ref: category': 'Categoria di richiesta',
    'Ref: request raised for': 'La persona interessata da questa richiesta',
    'Ref: phoneNumber': 'Numero di telefono',
    'Ref: location': 'Numero di telefono',
    'Ref: description': 'Descrizione',
    'Ref: attachments': 'Allegati',
  },
};
export default labels;
