export enum TRANSFORM_TYPE {
  UPPERCASE = 'UPPERCASE',
  LOWERCASE = 'LOSERCASE',
  CAPITALIZE = 'CAPITALIZE',
}

const textTransform = (text: string, transform: TRANSFORM_TYPE): string => {
  switch (transform) {
    case TRANSFORM_TYPE.LOWERCASE:
      return text.toLowerCase();
    case TRANSFORM_TYPE.UPPERCASE:
      return text.toUpperCase();
    case TRANSFORM_TYPE.CAPITALIZE:
      return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
    default:
      return text;
  }
};

export default textTransform;
