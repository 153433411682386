const labels = {
  TimeTable: {
    'leave at': '离开',
    'arrive at': '到达',
    at: '在',
    'Ref: list placeholder': '填写表格以查看小时',
    'Ref: Page title': '公共汽车',
    'Ref: Departure': '离开',
    'Ref: Arrivals': '到达',
    'Ref: minutes': '分钟',
    'Ref: no items placeholder': '抱歉，目前没有公共汽车。',
  },
};
export default labels;
