const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Žádost o ubytování',
    'Ref: Describe your request': 'Popište svůj požadavek',
    'Ref: Confirm your request': 'Potvrďte svůj požadavek',
    'Ref: Your request': 'Tvůj požadavek',
    'Ref: Summary': 'souhrn',
    'Ref: Next': 'další',
    'Ref: Submit': 'Předložit',
    'Ref: Me': 'Mě',
    'Ref: Arrival date': 'Den příjezdu',
    'Ref: Arrival time': 'Čas příjezdu',
    'Ref: Departure date': 'Datum odletu',
    'Ref: Departure time': 'Čas odjezdu',
    'Ref: request raised for': 'Osoba ovlivněná touto žádostí',
    'Ref: phoneNumber': 'Telefonní číslo',
    'Ref: arrivalDate': 'Příchod',
    'Ref: departureDate': 'Odchod',
    'Ref: description': 'Komentář',
    'Ref: open': 'Otevřeno',
    'Ref: pending': 'Čekající',
    'Ref: refused': 'Odmítl',
    'Ref: closed': 'Zavřeno',
    'Ref: all': 'Všechno',
    'Ref: Arrival': 'Příchod',
    'Ref: Validate': 'Ověřit',
    'Ref: site': 'Umístění webu',
    'Ref: Update us': 'Aktualizujte nás',
    'Ref: email of guest': 'E-mail hosta',
    'Ref: GuestAccommodationRequestTitle': 'Žádost hosta',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Nový požadavek',
    'Ref: Status': 'Postavení',
    'Ref: Date': 'Datum',
    'Ref: Coming arrivals': 'Přicházející příjezdy',
    'Ref: Past arrivals': 'Minulé příjezdy',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Telefonní číslo hosta',
    'Ref: Your phone number': 'Vaše telefonní číslo',
    'Ref: The phone number': 'Telefonní číslo',
    'Ref: PhoneNumber error msg': 'Telefonní číslo není platné',
    'Ref: Comment error msg': 'Maximální počet znaků je 250',
    'Ref: Guest first name': 'Křestní jméno hosta',
    'Ref: Guest last name': 'Příjmení hosta',
    'Ref: request for': 'Pro koho je tato žádost?',
    'Ref: request for me': 'Mě',
    'Ref: request for guest': 'Někdo jiný',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Podrobnosti žádosti o ubytování',
    'Ref: Timeline': 'Časová osa',
    'Ref: No updates placeholder': 'Pro tento požadavek neexistují žádné aktualizace',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Děkuji',
    'Ref: Success text': 'Obdrželi jsme vaši žádost a nyní se na něj díváme. Brzy se vám vrátíme.',
    'Ref: Go to home': 'Jít domů',
    'Ref: Create another request': 'Vytvořte další požadavek',
  },
  CreateError: {
    'Ref: Create error title': 'Jejda!',
    'Ref: Create error text': 'Došlo k chybě, zkuste to znovu.',
    'Ref: Retry': 'Zkus to znovu',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Aktualizujte nás s jakýmkoli novým prvkem o vaší žádosti, abychom vás mohli nejlépe podpořit.',
  },
};
export default labels;
