import {
  getOrderModuleRedirectionPath,
  getProductDetailsRedirectionPath,
  getRedirectionPathWithSiteIdentifier,
} from './ModuleRedirectPage.helper';

import { formatDateAsIS08601 } from '@/helpers/dateTime';

describe('getOrderModuleRedirectionPath', () => {
  describe('when query string valid', () => {
    it('should return redirect url', () => {
      const queryParams = new URLSearchParams('menuId=718&menuDate=2023-07-11');
      const url = getOrderModuleRedirectionPath(queryParams);
      expect(url).toBe('/order/redirect/718/2023-07-11');
    });
  });

  describe('when query string missing menu id', () => {
    it('should return redirect url with n-no-menu', () => {
      const queryParams = new URLSearchParams('menuDate=2023-07-11');
      const url = getOrderModuleRedirectionPath(queryParams);
      expect(url).toBe('/order/redirect/no-menu/2023-07-11');
    });
  });

  describe('when query string missing menu date', () => {
    it('should return root url', () => {
      const queryParams = new URLSearchParams('menuId=718');
      const url = getOrderModuleRedirectionPath(queryParams);
      expect(url).toBe('/order/redirect/718/no-date');
    });
  });

  describe('when query string missing', () => {
    it('should return root url', () => {
      const queryParams = new URLSearchParams('');
      const url = getOrderModuleRedirectionPath(queryParams);
      expect(url).toBe('/order/redirect/no-menu/no-date');
    });
  });
});

describe('getOrderRedirectionPathWithSiteIdentifier', () => {
  describe('when all parameters are valid', () => {
    it('should return correct redirect url', () => {
      const routeParams = {
        menuId: '718',
        menuDate: '2023-07-11',
      };
      const url = getRedirectionPathWithSiteIdentifier('order', 'site123', 'US', routeParams);
      expect(url).toBe('/module_redirect/US/site123/order?menuId=718&menuDate=2023-07-11');
    });
  });

  describe('when menu id is missing', () => {
    it('should return redirect url with no-menu', () => {
      const routeParams = {
        menuId: 'no-menu',
        menuDate: '2023-07-11',
      };
      const url = getRedirectionPathWithSiteIdentifier('order', 'site123', 'US', routeParams);
      expect(url).toBe('/module_redirect/US/site123/order?menuId=no-menu&menuDate=2023-07-11');
    });
  });

  describe('when query string is missing', () => {
    it('should return redirect url with no-menu and no-date', () => {
      const routeParams = {
        menuId: 'no-menu',
        date: 'no-date',
      };

      const url = getRedirectionPathWithSiteIdentifier('order', 'site123', 'US', routeParams);

      expect(url).toBe('/module_redirect/US/site123/order?menuId=no-menu&date=no-date');
    });
  });
});

describe('getProductDetailsRedirectionPath', () => {
  describe('when query string valid', () => {
    it('should return order product details url', () => {
      const queryParams = new URLSearchParams(
        'facilityId=6257cf2e-b6d3-ed11-9ac8-0003ff69197e&menuId=3348&date=2023-10-20&id=16861795'
      );
      const url = getProductDetailsRedirectionPath('/order', queryParams);
      expect(url).toBe(
        `/order/product/6257cf2e-b6d3-ed11-9ac8-0003ff69197e/3348/${formatDateAsIS08601(
          new Date()
        )}/16861795`
      );
    });
    it('should return menu product details url', () => {
      const queryParams = new URLSearchParams(
        'facilityId=6257cf2e-b6d3-ed11-9ac8-0003ff69197e&menuId=3348&date=2023-10-20&id=16861795'
      );
      const url = getProductDetailsRedirectionPath('/menu', queryParams);
      expect(url).toBe(
        `/menu/product/6257cf2e-b6d3-ed11-9ac8-0003ff69197e/3348/${formatDateAsIS08601(
          new Date()
        )}/16861795`
      );
    });
  });

  describe('when query string is invalid', () => {
    it('should have no facility in url', () => {
      const queryParams = new URLSearchParams('menuId=3348&&date=2023-10-20&id=16861795');
      const url = getProductDetailsRedirectionPath('/order', queryParams);
      expect(url).toBe(
        `/order/product/no-facility/3348/${formatDateAsIS08601(new Date())}/16861795`
      );
    });
    it('should have no menu id in url', () => {
      const queryParams = new URLSearchParams(
        'facilityId=6257cf2e-b6d3-ed11-9ac8-0003ff69197e&date=2023-10-20&id=16861795'
      );
      const url = getProductDetailsRedirectionPath('/order', queryParams);
      expect(url).toBe(
        `/order/product/6257cf2e-b6d3-ed11-9ac8-0003ff69197e/no-menu/${formatDateAsIS08601(
          new Date()
        )}/16861795`
      );
    });
    it('should have no date in url', () => {
      const queryParams = new URLSearchParams(
        'facilityId=6257cf2e-b6d3-ed11-9ac8-0003ff69197e&menuId=3348&id=16861795'
      );
      const url = getProductDetailsRedirectionPath('/order', queryParams);
      expect(url).toBe('/order/product/6257cf2e-b6d3-ed11-9ac8-0003ff69197e/3348/no-date/16861795');
    });
    it('should have no menu item id in url', () => {
      const queryParams = new URLSearchParams(
        'facilityId=6257cf2e-b6d3-ed11-9ac8-0003ff69197e&date=2023-10-20&menuId=3348'
      );
      const url = getProductDetailsRedirectionPath('/order', queryParams);
      expect(url).toBe(
        `/order/product/6257cf2e-b6d3-ed11-9ac8-0003ff69197e/3348/${formatDateAsIS08601(
          new Date()
        )}/no-menu-item`
      );
    });
  });
});
