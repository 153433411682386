import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { SIZE } from '../../../constants';
import Button from '../../atoms/Button';

import styles from './FillInTheBlanks.module.css';

const FillInTheBlanks = ({ sentence, options: propsOptions, selectedOptions, handleChange }) => {
  const [options, setOptions] = useState(propsOptions);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOptionsList, setSelectedOptionsList] = useState(selectedOptions || []);

  useEffect(() => {
    setOptions(propsOptions);
  }, [propsOptions, setOptions]);

  useEffect(() => {
    setSelectedOptionsList(selectedOptions || []);
  }, [selectedOptions, setSelectedOptionsList]);

  const handleSelect = (value) => {
    setSelectedOptionsList({
      ...selectedOptionsList,
      [currentStep]: value,
    });
    setCurrentStep((previousStep) => previousStep + 1);
    if (handleChange) handleChange(currentStep, value);
  };

  let sentenceFragments = [];
  let leftToProcess = sentence;
  let index;

  //limited to 9 placeholders
  while ((index = leftToProcess.search(/[{1-9}]/g)) > -1) {
    if (index) sentenceFragments.push(leftToProcess.substring(0, index));
    else sentenceFragments.push('');
    const step = parseInt(leftToProcess.substring(index + 1, index + 2));

    const selectedOption =
      options[step]?.find((el) => el.value === selectedOptionsList[step]) ||
      (selectedOptionsList[step]?.value && { label: selectedOptionsList[step].value });

    selectedOption?.label
      ? sentenceFragments.push(
          <Button data-testid="fill-in-blanks-selected" look="tertiary" size={SIZE.SMALL} onClick={() => setCurrentStep(step)}>
            {selectedOption?.label}
          </Button>
        )
      : sentenceFragments.push(<div className={styles.notYetSelected} data-testid="fill-in-blanks-not-selected" />);
    if (currentStep < step + 1 && selectedOptionsList[step + 1] === undefined)
      //stop there
      leftToProcess = '';
    else leftToProcess = leftToProcess.substring(index + 3);
  }

  sentenceFragments.push(leftToProcess);

  return (
    <div>
      <div className={classNames(styles.text, 'bodyMDefault')}>
        {sentenceFragments.reduce((output, fragment) => (
          <>
            {output}
            {fragment}
          </>
        ))}
      </div>
      {options[currentStep]?.length > 0 && (
        <div className={styles.optionsToSelect}>
          {options[currentStep]?.map((option) => (
            <Button
              data-testid="fill-in-blanks-option"
              key={option.value}
              look="secondary"
              size={SIZE.SMALL}
              onClick={() => handleSelect(option.value)}
            >
              {option.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default FillInTheBlanks;
