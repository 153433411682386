import { useHistory } from 'react-router';

import { ImageDefaultFoodImg } from '../../../../assets/images';
import ImageWithFallback from '../../../../components/atoms/ImageWithFallback/ImageWithFallback';
import { Tile } from '../../../../components/molecules/Tile';
import { MenuTileProps } from '../../types/menuSelector.types';

const MenuTile = ({
  id,
  facilityTitle,
  menu,
  menuImageSource,
  facilityMenuPath,
  selectedDate,
  focusOnRender,
}: MenuTileProps) => {
  const history = useHistory();

  const facilityPath = facilityMenuPath
    ? facilityMenuPath(menu.id, menu.facilityId, selectedDate, true)
    : '';
  const imgInfo = menuImageSource
    ? {
        src: menuImageSource,
        alt: facilityTitle,
      }
    : undefined;
  const image = (
    <ImageWithFallback
      data-testid="menu-tile"
      imgInfo={imgInfo}
      imgElement={<ImageDefaultFoodImg />}
    />
  );

  return (
    <Tile
      id={id}
      title={facilityTitle}
      description={menu.name}
      image={image}
      onClick={() => history?.push(facilityPath)}
      data-testid={`MenuTile-${id}`}
      focusOnRender={focusOnRender}
    />
  );
};

export default MenuTile;
