import { useHistory } from 'react-router';

import { useGetChildrenPagesQuery } from '../../api';
import getChildrenPageUrl from '../../helpers/getChildrenPageUrl';
import useGetContentPageParams from '../../hooks/useGetContentPageParams';
import { ChildrenPageResponseItem } from '../../types';

import { ChildrenPagesListProps } from './ChildrenPagesList.types';

import { NavArrowRightIcon } from '@/assets/icons';
import { ImageDefault } from '@/assets/images';
import ImageWithFallback from '@/components/atoms/ImageWithFallback/ImageWithFallback';
import { Tile } from '@/components/molecules/Tile';
import TilesList from '@/components/organisms/TilesList';

import styles from './ChildrenPagesList.module.css';

function ChildrenPagesList({ contentPageId }: ChildrenPagesListProps) {
  const { data, isFetching } = useGetChildrenPagesQuery({ id: contentPageId });

  const { navItemName } = useGetContentPageParams();

  const history = useHistory();

  if (isFetching || !data) {
    return null;
  }

  const navigateToChildrenPage = (item: ChildrenPageResponseItem) =>
    history.push(getChildrenPageUrl(navItemName, item));

  return (
    <TilesList
      items={data}
      className={styles.childrenPagesList}
      itemRenderer={(item) => (
        <Tile
          key={item.id}
          data-testid={item.id}
          title={item.name}
          description={item.description}
          image={
            <ImageWithFallback
              data-testid="children-pages-list"
              imgInfo={item.contentImage ? { src: item.contentImage, alt: item.name } : undefined}
              imgElement={<ImageDefault />}
            />
          }
          onClick={() => navigateToChildrenPage(item)}
          actions={[
            {
              name: 'NavArrowRightIcon',
              icon: <NavArrowRightIcon />,
              onClick: () => navigateToChildrenPage(item),
              'data-testid': `children-page-tile-${item.id}-right`,
            },
          ]}
        />
      )}
    />
  );
}

export default ChildrenPagesList;
