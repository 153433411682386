const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Sinun pyyntösi',
    'Ref: Summary': 'Yhteenveto',
    'Ref: Request category': 'Pyyntöluokka',
    'Ref: Person affected': 'Henkilö, johon tämä pyyntö vaikuttaa',
    'Ref: Your phone number': 'Puhelinnumero',
    'Ref: Description': 'Kuvaus',
    'Ref: Service Requests': 'Palvelupyynnöt',
    'Ref: Request Service': 'Pyyntöpalvelu',
    'Ref: Success title': 'Pyyntösi on luotu',
    'Ref: Error title': 'Oho!',
    'Ref: Error subtitle': 'Tapahtui virhe. Ole hyvä ja yritä uudelleen.',
    'Ref: Try again': 'Yritä uudelleen',
    'Ref: you pronoun': '(sinä)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Tämä on työpaikan tukikeskuksen',
    'Ref: Tutorial: tabname': 'Pyynnöt',
    'Ref: Tutorial: body':
      'Olemme täällä auttamassa sinua. Kirjaudu sisään yksi pyyntö merkintää kohti.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Pyydät palvelua sivustolla {siteName}.',
    'Ref: Building': 'Rakennus',
    'Ref: Room': 'Huone',
    'Ref: Description help text':
      'Mitä yksityiskohtaisempi olet, sitä paremmin voimme tukea sinua.',
    'Ref: Next': 'Seuraava',
    'Ref: Your request': 'Sinun pyyntösi',
    'Ref: PhoneNumber error msg': 'Puhelinnumero ei ole kelvollinen',
    'Ref: Type at least 3 characters to search': 'Kirjoita vähintään 3 merkkiä etsiäksesi',
    'Ref: No affected person results': 'Kukaan henkilö vastaa pyydettyjä kriteerejä',
    'Ref: Required': '* Edellytetään',
  },
  Summary: {
    'Ref: Location': 'Sijainti',
    'Ref: Attachment': 'Liitetiedot',
    'Ref: Submit': 'Lähetä',
    'Ref: No affected person results': 'Kukaan henkilö vastaa pyydettyjä kriteerejä',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Huolto pyyntö',
    'Ref: createdOn': 'Luotu',
    'Ref: summary': 'Yhteenveto',
    'Ref: category': 'Pyyntöluokka',
    'Ref: request raised for': 'Henkilö, johon tämä pyyntö vaikuttaa',
    'Ref: phoneNumber': 'Puhelinnumero',
    'Ref: location': 'Puhelinnumero',
    'Ref: description': 'Kuvaus',
    'Ref: attachments': 'Liitetiedot',
  },
};
export default labels;
