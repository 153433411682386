import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { LOCAL_STORAGE_KEYS } from '../../../constants';
import useGeoCode from '../useGeoCode';

import {
  setSiteContext,
  setAppContext,
  trackPageView,
  trackPageViewWithFacilities,
  trackToasterDisplay,
  trackButtonClick,
  trackCustomInteraction,
  setGeographyContext,
} from '@/helpers/analytics';
import { isIosMobile } from '@/helpers/misc';
import { State } from '@/types/state.types';

export const pathsExcludedFromGlobalTracking = [
  '/facilities/(.*)/details',
  '/order/cart',
  '/access/legal',
];
export const TRACK_PAGE_CHANGE_DELAY = 400;

const useAnalytics = () => {
  const site = useSelector((state: State) => state?.Core?.context?.site);

  const { contract, contactId, useDataTracking } = useSelector((state: State) => state?.Core?.user);
  const regionCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE) ?? undefined;
  const { currentGeoCode } = useGeoCode();
  const trackPageViewTimeoutRef = useRef<NodeJS.Timeout | undefined>();

  const canUseAnalytics = useMemo((): boolean => {
    if (isIosMobile()) {
      return useDataTracking || false;
    } else {
      return true;
    }
  }, [useDataTracking]);

  useEffect(() => {
    if (canUseAnalytics && site && contract)
      setSiteContext(site?.id, site?.name, contract?.id, contract?.name, contactId);
  }, [site, contract, contactId, canUseAnalytics]);

  useEffect(() => {
    if (canUseAnalytics) setAppContext();
  }, [canUseAnalytics]);

  useEffect(() => {
    if (canUseAnalytics) setGeographyContext(currentGeoCode, regionCode);
  }, [canUseAnalytics, currentGeoCode, regionCode]);

  const cancelScheduledTrackPageEvenIfPossible = useCallback(() => {
    if (trackPageViewTimeoutRef.current) {
      clearTimeout(trackPageViewTimeoutRef.current);
      trackPageViewTimeoutRef.current = undefined;
    }
  }, []);

  const trackPageViewEvent = useCallback(
    (path: string, isGlobal: boolean = true, facilityId?: string) => {
      if (!canUseAnalytics) return;

      if (
        isGlobal &&
        pathsExcludedFromGlobalTracking.some((excludedPath) => path.match(excludedPath))
      )
        return;

      cancelScheduledTrackPageEvenIfPossible();
      trackPageViewTimeoutRef.current = setTimeout(() => {
        // setTimeout has 2 purposes
        // 1. ensure document title is set when page view event is sent
        // 2. when route change is just redirect - do not track it - skipPreviousTrackPageViewEventIfPossible
        if (facilityId) trackPageViewWithFacilities(path, facilityId, document.title);
        else trackPageView(path, document.title);
      }, TRACK_PAGE_CHANGE_DELAY);
    },
    [canUseAnalytics, cancelScheduledTrackPageEvenIfPossible]
  );

  const trackToasterDisplayEvent = useCallback(
    (name: string, label: string, path: string) => {
      if (!canUseAnalytics) return;

      trackToasterDisplay(name, label, path);
    },
    [canUseAnalytics]
  );

  const trackButtonClickEvent = useCallback(
    (button: string, otherProps?: Record<string, string>) => {
      if (!canUseAnalytics) return;

      trackButtonClick(button, otherProps);
    },
    [canUseAnalytics]
  );

  const trackCustomInteractionEvent = useCallback(
    (path: string) => {
      if (!canUseAnalytics) return;

      trackCustomInteraction(path);
    },
    [canUseAnalytics]
  );

  return {
    trackPageViewEvent,
    trackToasterDisplayEvent,
    trackButtonClickEvent,
    trackCustomInteractionEvent,
    cancelScheduledTrackPageEvenIfPossible,
  };
};

export default useAnalytics;
