import momentjs from 'moment';

import { getCurrentLanguageCode } from './misc';

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export const formatDate = (
  date: Date | string,
  languageCode: string,
  customFormatOptions?: Intl.DateTimeFormatOptions
) => {
  // Ensure date is a Date object
  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return dateObj.toLocaleDateString(languageCode, {
    ...dateFormatOptions,
    ...customFormatOptions,
  });
};

export const formatTime = (time: Date) =>
  time.toLocaleTimeString(getCurrentLanguageCode(), timeFormatOptions);

export const formatDateTime = (dateTime: Date, languageCode: string) =>
  dateTime.toLocaleString(languageCode, {
    ...dateFormatOptions,
    ...timeFormatOptions,
  });

export const toLocalDate = (dateTime: string | Date, withTime = false) => {
  if (typeof dateTime === 'object') {
    return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
  }
  if (dateTime.indexOf('-') > 0) {
    const [year, month, dayMaybeWithTime] = dateTime.split('-');
    const [day, timeValues] = dayMaybeWithTime.split('T');
    if (!withTime) {
      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    let [hours, minutes, seconds] = timeValues.split(':');
    seconds = seconds.replace('Z', '');

    return new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hours || 0),
      Number(minutes || 0),
      Number(seconds || 0)
    );
  } else {
    return new Date(dateTime);
  }
};

//date must be a Date object.
export const formatDateAsIS08601 = (date: Date) => {
  let day: string | number = date.getDate();
  if (day < 10) day = '0' + day;
  let month: string | number = date.getMonth() + 1;
  if (month < 10) month = '0' + month;
  let year = date.getFullYear();
  return year + '-' + month + '-' + day;
};

export const stripTimeZoneFromDateTime = (date: Date, endOfDay?: boolean): string => {
  endOfDay ? date.setHours(23, 59, 59, 999) : date.setHours(0, 0, 0, 0);

  return `${momentjs(date).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
};

export const checkIf24HourClockForLanguage = (languageCode: string) => {
  const formatter = new Intl.DateTimeFormat(languageCode, { hour: 'numeric' });
  const options = formatter.resolvedOptions();
  return options.hourCycle === 'h23' || options.hourCycle === 'h24';
};
