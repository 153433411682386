const labels = {
  ContentDetails: {
    'Ref: Page title': 'Detail Konten',
    'See the event': 'Lihat acara tersebut',
    'Take the survey': 'Ikuti survei',
    Date: 'Tanggal',
    Category: 'Kategori',
    'Ref: Content site different title': 'Konten ini bukan milik situs Anda saat ini',
    'Ref: Content site different description':
      'Anda melihat konten yang bukan milik situs Anda saat ini. Anda tidak akan dapat melihatnya dalam daftar konten.',
  },
  ContentList: {
    'Ref: Page title': 'NEWSFEED',
    'featured (as in featured content)': 'Unggulan',
    'Ref: category - all': 'Semua',
    'Ref: category - promotional': 'Promosi',
    'Ref: category - featured': 'Ditampilkan',
    'Ref: category - other': 'Lainnya',
  },
  Widget: {
    'Ref: Carousel title': 'Artikel berita',
    'Ref: Carousel title promo': 'Berita teratas',
    'No info yet, come back later': 'Belum ada info, kembali lagi 😉',
    'Ref: Content': 'Isi',
    'Ref: Widget Title': 'NEWSFEED',
    'Ref: MyVillage Carousel title promo': 'Promosi',
  },
};
export default labels;
