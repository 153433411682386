import { FC, useCallback, useMemo, useState } from 'react';

import { CheckboxOnClickEvent, CheckboxOnClickProps } from '../../atoms/Checkbox/Checkbox.types';

import { CheckboxGroupProps } from './CheckboxGroup.types';
import CheckboxGroupContext from './CheckboxGroupContext';

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  children,
  name,
  value,
  disabled,
  onClick,
  'data-testid': dataTestId = 'checkbox-group',
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(value || []);

  const onClickValue = useCallback(
    (e: CheckboxOnClickEvent, { value, checked }: CheckboxOnClickProps) => {
      if (!value) return;

      const newSelectedValue = checked
        ? [...selectedValues, value]
        : [...selectedValues].filter((v) => v !== value);

      setSelectedValues(newSelectedValue);
      if (onClick) onClick(e, newSelectedValue);
    },
    [onClick, selectedValues]
  );

  const contextValue = useMemo(
    () => ({ name, value: selectedValues, disabled, onClick: onClickValue }),
    [disabled, name, onClickValue, selectedValues]
  );

  return (
    <CheckboxGroupContext.Provider value={contextValue}>
      <div data-testid={dataTestId}>{children}</div>
    </CheckboxGroupContext.Provider>
  );
};

export default CheckboxGroup;
