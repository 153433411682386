import { cleanup, screen } from '@testing-library/react';

import { renderedComponent } from '../../../../../helpers/tests/renderComponent';
import AdditionalComment from '../AdditionalComment';

const props = {
  isCommentRequired: false,
  label: jest.fn().mockReturnValue('some label'),
};

describe('AdditionalComment', () => {
  afterAll(() => cleanup());

  describe('Render', () => {
    it('should render additional comment components', () => {
      renderedComponent(AdditionalComment, props);

      const additionalComment = screen.queryByTestId('additional-comment-textarea');
      expect(additionalComment).toBeTruthy();
    });
  });
});
