const mockData = {
  getOrigins: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: '83130d17-5cba-e911-a85f-000d3a6a4119',
          name: 'Paraburdoo Mine MEM Admin G Shed Plant',
        },
        {
          id: 'e7322c32-057f-e911-a858-000d3a6a4164',
          name: 'Karratha Town Mine 6 Mile 7 Mile 8 Mile',
        },
        {
          id: '509c5458-077f-e911-a858-000d3a6a4164',
          name: 'Karratha Town Mine 8 Mile 7 Mile 6 Mile',
        },
        { id: 'd568c7ca-d6b4-e911-a85f-000d3a6a4164', name: 'NBWT Lab & Eng' },
        { id: '8c532e0d-d7b4-e911-a85f-000d3a6a4164', name: 'B2 Mine Warehouse' },
      ]),
  },
  getDestinations: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        { id: '2a368326-1ad7-e611-811e-c4346bc56230', name: 'Searipple Village Bus Stop' },
        { id: 'b0562f1f-709f-ec11-b400-00224892e71e', name: 'Peninsula Palms Bus Stop' },
      ]),
  },
  getSchedules: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          departure: '2023-02-28T22:05:00Z',
          arrival: '2023-02-28T22:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-02-28T22:20:00Z',
          arrival: '2023-02-28T23:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-01T10:05:00Z',
          arrival: '2023-03-01T10:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-01T10:20:00Z',
          arrival: '2023-03-01T11:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-01T22:05:00Z',
          arrival: '2023-03-01T22:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-01T22:20:00Z',
          arrival: '2023-03-01T23:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-02T10:05:00Z',
          arrival: '2023-03-02T10:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-02T10:20:00Z',
          arrival: '2023-03-02T11:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-02T22:05:00Z',
          arrival: '2023-03-02T22:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-02T22:20:00Z',
          arrival: '2023-03-02T23:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-03T10:05:00Z',
          arrival: '2023-03-03T10:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-03T10:20:00Z',
          arrival: '2023-03-03T11:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-03T22:05:00Z',
          arrival: '2023-03-03T22:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-03T22:20:00Z',
          arrival: '2023-03-03T23:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-04T10:05:00Z',
          arrival: '2023-03-04T10:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-04T10:20:00Z',
          arrival: '2023-03-04T11:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-04T22:05:00Z',
          arrival: '2023-03-04T22:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-04T22:20:00Z',
          arrival: '2023-03-04T23:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-05T10:05:00Z',
          arrival: '2023-03-05T10:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-05T10:20:00Z',
          arrival: '2023-03-05T11:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-05T22:05:00Z',
          arrival: '2023-03-05T22:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-05T22:20:00Z',
          arrival: '2023-03-05T23:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-06T10:05:00Z',
          arrival: '2023-03-06T10:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-06T10:20:00Z',
          arrival: '2023-03-06T11:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-06T22:05:00Z',
          arrival: '2023-03-06T22:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-06T22:20:00Z',
          arrival: '2023-03-06T23:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
        {
          departure: '2023-03-07T10:05:00Z',
          arrival: '2023-03-07T10:45:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina - Yandi Village',
        },
        {
          departure: '2023-03-07T10:20:00Z',
          arrival: '2023-03-07T11:00:00Z',
          durationMins: 40,
          itinerary: 'Bina Bina Mine - Yandi Village',
        },
      ]),
  },
};

export default mockData;
