export const keycodes = {
  END: 'End',
  ENTER: 'Enter',
  HOME: 'Home',
  SPACE: ' ',
  SPACE_DEPRECATED: 'Spacebar',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
};
