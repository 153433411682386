const labels = {
  Success: {
    'Ref: Body':
      'Tack för att du har slutfört denna undersökning, din tid är värdefull eftersom dessa undersökningar hjälper till att förbättra våra tjänster.',
    'Ref: Tabtitle': 'Undersökning avslutad',
    'Take another survey': 'Gör en ny undersökning',
  },
  Survey: {
    'Ref: Page title': 'Undersökningsuppgifter',
    'Please answer all mandatory questions as identify by an asterisk':
      'Undersökning/vänligen svara på alla obligatoriska frågor som identifieras av en asterisk',
    'Ref: Take another survey': 'Gör en ny undersökning',
    'Ref: Go to Home': 'Gå till startsidan',
  },
  SurveysList: {
    'Ref: Page title': 'Undersökningar',
    'Ref: Available until': 'Tillgänglig tills',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Du tittar för närvarande på undersökningar för webbplatsen {site name}.',
  },
};
export default labels;
