const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Szálláskérés',
    'Ref: Describe your request': 'Írja le kérését',
    'Ref: Confirm your request': 'Erősítse meg kérését',
    'Ref: Your request': 'A kérése',
    'Ref: Summary': 'Összefoglalás',
    'Ref: Next': 'Következő',
    'Ref: Submit': 'Beküldés',
    'Ref: Me': 'Nekem',
    'Ref: Arrival date': 'Érkezési dátum',
    'Ref: Arrival time': 'Érkezési idő',
    'Ref: Departure date': 'Indulási időpont',
    'Ref: Departure time': 'Indulási idő',
    'Ref: request raised for': 'A kérés által érintett személy',
    'Ref: phoneNumber': 'Telefonszám',
    'Ref: arrivalDate': 'Érkezés',
    'Ref: departureDate': 'Indulás',
    'Ref: description': 'Megjegyzés',
    'Ref: open': 'Nyisd ki',
    'Ref: pending': 'Függőben levő',
    'Ref: refused': 'Megtagadta',
    'Ref: closed': 'Zárva',
    'Ref: all': 'Minden',
    'Ref: Arrival': 'Érkezés',
    'Ref: Validate': 'Érvényesít',
    'Ref: site': 'Helyszíni hely',
    'Ref: Update us': 'Frissítsen minket',
    'Ref: email of guest': 'A vendég e-mailje',
    'Ref: GuestAccommodationRequestTitle': 'Vendégkérés',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Új kérés',
    'Ref: Status': 'Állapot',
    'Ref: Date': 'Dátum',
    'Ref: Coming arrivals': 'KÖVETKEZŐ ÉRZÉSEK',
    'Ref: Past arrivals': 'Múltbeli érkezések',
  },
  CreateForm: {
    'Ref: Guest phone number': 'A vendég telefonszáma',
    'Ref: Your phone number': 'A telefonszámod',
    'Ref: The phone number': 'A telefonszám',
    'Ref: PhoneNumber error msg': 'A telefonszám nem érvényes',
    'Ref: Comment error msg': 'A karakterek maximális száma 250',
    'Ref: Guest first name': 'Keresztnév',
    'Ref: Guest last name': 'Vendég vezetéknév',
    'Ref: request for': 'Mert ki ez a kérés?',
    'Ref: request for me': 'Nekem',
    'Ref: request for guest': 'Valaki más',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'A szálláskérés részletei',
    'Ref: Timeline': 'Idővonal',
    'Ref: No updates placeholder': 'Nincsenek frissítések erre a kérésre',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Köszönöm',
    'Ref: Success text':
      'Megkaptuk a kérését, és most megvizsgáljuk. Hamarosan visszatérünk hozzád.',
    'Ref: Go to home': 'Menj haza',
    'Ref: Create another request': 'Hozzon létre egy újabb kérést',
  },
  CreateError: {
    'Ref: Create error title': 'Hoppá!',
    'Ref: Create error text': 'Hiba történt, kérjük, próbálkozzon újra.',
    'Ref: Retry': 'Próbáld újra',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Kérjük, frissítsen minket bármilyen új elemmel a kérésére, hogy a legjobban támogassuk Önt.',
  },
};
export default labels;
