const labels = {
  SiteDetails: {
    'Select site': 'Select site',
    'Ref: Page title': 'Ref: Page title',
    'Ref: Skip map': 'Ref: Skip map',
    'Ref: Unavailable site': 'Ref: Unavailable site',
    'Ref: Sorry, no description yet': 'Ref: Sorry, no description yet',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Ref: Site coordinates not found',
    'Ref: Page title': 'Ref: Page title',
    'Recently visited': 'Recently visited',
    'All other sites': 'All other sites',
    'Sites in your country': 'Sites in your country',
    'Search results': 'Search results',
    'Select site': 'Select site',
    'Ref: Select site with QR Scanner': 'Ref: Select site with QR Scanner',
    'Ref: Select site with code': 'Ref: Select site with code',
    'Ref: Validate': 'Ref: Validate',
    'Ref: Enter site code': 'Ref: Enter site code',
    'Ref: Scanner error header': 'Ref: Scanner error header',
    'Ref: Generic error': 'Ref: Generic error',
    'Ref: Change site': 'Ref: Change site',
    'Ref: Change site description': 'Ref: Change site description',
    'Ref: Cancel': 'Ref: Cancel',
    'Ref: Not found': 'Ref: Not found',
    'Ref: Unauthorized error': 'Ref: Unauthorized error',
    'Ref: Forbidden': 'Ref: Forbidden',
    "Ref: We didn't find the site.": "Ref: We didn't find the site.",
    'Ref: We recommend some solutions:': 'Ref: We recommend some solutions:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below',
    'Ref: 2. Log out and log back in by selecting the correct country':
      'Ref: 2. Log out and log back in by selecting the correct country',
    'Ref: The currently selected country is:': 'Ref: The currently selected country is:',
  },
  SwitchSite: { 'Not on {site name}?': 'Not on {site name}?' },
  SiteTile: { Details: 'Details', Select: 'Select' },
};
export default labels;
