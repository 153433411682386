import classNames from 'classnames';

import Title, { TITLE_SIZE } from '../../../components/atoms/Title';
import { MenuItem } from '../api/menuApi.types';
import { getPrice } from '../helpers/order.helper';
import { SiteCurrency } from '../types/productList.types';

import styles from './ProductDetails.module.css';

import { DefaultComponentWithoutChildrenPropsV2 } from 'src/types';

type MenuTitleViewableProps = {
  menuItem: MenuItem | undefined;
  languageCode: string;
  site: { id: string; currency: SiteCurrency };
} & DefaultComponentWithoutChildrenPropsV2;

export const MenuTitleViewable = ({
  menuItem,
  languageCode,
  site,
  'data-testid': dataTestId,
}: MenuTitleViewableProps) => {
  const priceFormatted = getPrice(menuItem?.priceWithTax, languageCode, site.currency.isoCode);

  return (
    <div data-testid={dataTestId}>
      <Title size={TITLE_SIZE.HEADLINES} className={classNames(styles.menuTitle)}>
        {menuItem?.name}
      </Title>
      <Title size={TITLE_SIZE.HEADLINEXS} className={classNames(styles.menuPrice)}>
        {priceFormatted}
      </Title>
    </div>
  );
};
