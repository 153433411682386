import classNames from 'classnames';
import { FC } from 'react';

import { ColumnProps, COLUMN_ELEMENT } from './Column.types';

import styles from './Column.module.css';

/*
 * Universal column
 */
const Column: FC<ColumnProps> = ({
  children,
  className,
  element: Element = COLUMN_ELEMENT.SECTION,
  'data-testid': dataTestId = 'column',
}) => {
  return (
    <Element className={classNames(styles.column, className)} data-testid={dataTestId}>
      {children}
    </Element>
  );
};
Column.displayName = 'Column';

/*
 * Flexible width column
 */
const ColumnFlex: FC<ColumnProps> = (props) => (
  <Column
    {...props}
    className={classNames(props.className, styles.flex)}
    element={COLUMN_ELEMENT.SECTION}
  />
);
ColumnFlex.displayName = 'Column.Main';

/*
 * Fixed width column
 */
const ColumnFix: FC<ColumnProps> = (props) => (
  <Column
    {...props}
    className={classNames(props.className, styles.fix)}
    element={COLUMN_ELEMENT.ASIDE}
  />
);
ColumnFix.displayName = 'Column.Side';

/*
 * Fixed width complementary column
 */
const ColumnComplementary: FC<ColumnProps> = (props) => (
  <Column
    {...props}
    className={classNames(props.className, styles.fix, styles.complementary)}
    element={COLUMN_ELEMENT.ASIDE}
  />
);
ColumnComplementary.displayName = 'Column.Complementary';

export default Object.assign(Column, {
  Main: ColumnFlex,
  Side: ColumnFix,
  Complementary: ColumnComplementary,
});
