const labels = {
  Success: {
    'Ref: Body':
      '이 설문 조사를 완료 해 주셔서 감사 드리면 이러한 설문 조사가 우리의 서비스를 개선하는 데 도움이 될 때 귀하의 시간은 가치가 있습니다.',
    'Ref: Tabtitle': '설문 조사가 완료되었습니다',
    'Take another survey': '다른 설문 조사를하십시오',
  },
  Survey: {
    'Ref: Page title': '설문 조사 세부 사항',
    'Please answer all mandatory questions as identify by an asterisk':
      '별표가 식별하는 것으로 모든 필수 질문에 답하십시오 : *',
    'Ref: Take another survey': '다른 설문 조사를하십시오',
    'Ref: Go to Home': '집에 가십시오',
  },
  SurveysList: {
    'Ref: Page title': '조사',
    'Ref: Available until': '이용 가능',
    'Ref: You are currently viewing surveys for the site {site name}.':
      '현재 사이트 {site name}에 대한 설문 조사를보고 있습니다.',
  },
};
export default labels;
