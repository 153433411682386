import { Route, Switch } from 'react-router-dom';

import { pagePaths } from '../config';

import AuditCreationForm from './AuditCreationForm';
import AuditEditLocationForm from './AuditEditLocationForm';
import AuditInviteList from './AuditInviteList';
import AuditResponseForm from './AuditResponseForm';

const Routes = () => {
  return (
    <Switch>
      <Route path={pagePaths.Module} exact children={<AuditInviteList />} />
      <Route path={pagePaths.AuditCreationForm} exact children={<AuditCreationForm />} />
      <Route path={pagePaths.AuditResponseForm} exact children={<AuditResponseForm />} />
      <Route path={pagePaths.AuditEditLocationForm} exact children={<AuditEditLocationForm />} />
    </Switch>
  );
};

export default Routes;
