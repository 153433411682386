// TODO find a better place for global types to live
type ServiceDefinitionNavigation = {
  name: string;
  isEnabled: boolean;
  orderIndex: number;
  imageId?: string | null;
  imageName?: string | null;
};

export type ServiceDefinitionSetupOptions = {
  value: string | null;
  name: string | null;
  code: string | null;
  isServiceType: boolean;
  isServicePointer: boolean;
};

export type ServiceDefinition = {
  id?: string;
  name: string;
  navigation: ServiceDefinitionNavigation;
  setupOptions: ServiceDefinitionSetupOptions[];
  contentPageId?: string | null;
};

export const defaultServices: ServiceDefinition[] = [
  // {
  //   name: 'Menu',
  //   navigation: {
  //     name: 'Menu',
  //     isEnabled: true,
  //     orderIndex: 0,
  //   },
  //   setupOptions: [
  //     {
  //       name: 'module',
  //       value: 'Menu',
  //       code: 'module',
  //       isServiceType: true,
  //       isServicePointer: false,
  //     },
  //   ],
  // }
];

const getServiceConfiguration = (): string[] | null | undefined => {
  const configuration = process.env.REACT_APP_SERVICES;
  if (!configuration) return null;

  return configuration.split(';');
};

export const serviceConfiguration = getServiceConfiguration();
