const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Paslaugos užklausa - pagalbos tarnyba',
    'Ref: Forbidden heading': 'Draudžiama',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Jūs administruojate paslaugų užklausas svetainėje {siteName}.',
    'Ref: Dates': 'Datos',
    'Ref: SRL list refresh': 'Atnaujinti sąrašą',
    'Ref: Export': 'eksportas',
    'Ref: Empty/not filled': 'Tuščias / neužpildytas',
    'Ref: Name': 'Vardas',
    'Ref: Date': 'Data',
    'Ref: Category': 'Kategorija',
    'Ref: User': 'Vartotojas',
    'Ref: Location': 'Vieta',
  },
};
export default labels;
