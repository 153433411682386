const labels = {
  Success: {
    'Ref: Body':
      'Köszönjük, hogy időt szánt a kérdőív kitöltésére, mivel ezek segítségével tudunk javítani szolgáltatásainkon.',
    'Ref: Tabtitle': 'A felmérés befejeződött',
    'Take another survey': 'Vegyünk egy újabb felmérést',
  },
  Survey: {
    'Ref: Page title': 'Felmérés részletei',
    'Please answer all mandatory questions as identify by an asterisk':
      'Kérjük, válaszoljon az összes kötelező kérdésre, amint az Asterisk azonosítja: *',
    'Ref: Take another survey': 'Vegyünk egy újabb felmérést',
    'Ref: Go to Home': 'Menj haza',
  },
  SurveysList: {
    'Ref: Page title': 'Kérdőív',
    'Ref: Available until': 'Elérhető amíg',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Jelenleg a {site name} webhely felméréseit nézi meg.',
  },
};
export default labels;
