import classNames from 'classnames';
import React from 'react';

import { ColumnProps } from './Layout.types';

import styles from './Layout.module.css';

const Column = React.forwardRef<HTMLDivElement, ColumnProps>(({ children, ...props }, ref) => {
  return (
    <div className={props.className} {...props}>
      <div className={classNames(styles.columnInner)} ref={ref}>
        {children}
      </div>
    </div>
  );
});

export default Column;
