const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'คำขอที่พัก',
    'Ref: Describe your request': 'อธิบายคำขอของคุณ',
    'Ref: Confirm your request': 'ยืนยันคำขอของคุณ',
    'Ref: Your request': 'คำขอร้องของคุณ',
    'Ref: Summary': 'สรุป',
    'Ref: Next': 'ต่อไป',
    'Ref: Submit': 'ส่ง',
    'Ref: Me': 'ฉัน',
    'Ref: Arrival date': 'วันที่มาถึง',
    'Ref: Arrival time': 'เวลาถึง',
    'Ref: Departure date': 'วันเดินทาง',
    'Ref: Departure time': 'เวลาออกเดินทาง',
    'Ref: request raised for': 'บุคคลที่ได้รับผลกระทบจากคำขอนี้',
    'Ref: phoneNumber': 'หมายเลขโทรศัพท์',
    'Ref: arrivalDate': 'การมาถึง',
    'Ref: departureDate': 'การออกเดินทาง',
    'Ref: description': 'การแสดงความคิดเห็น',
    'Ref: open': 'เปิด',
    'Ref: pending': 'รอดำเนินการ',
    'Ref: refused': 'ปฏิเสธ',
    'Ref: closed': 'ปิด',
    'Ref: all': 'ทั้งหมด',
    'Ref: Arrival': 'การมาถึง',
    'Ref: Validate': 'ตรวจสอบความถูกต้อง',
    'Ref: site': 'สถานที่ตั้งไซต์',
    'Ref: Update us': 'อัพเดทเรา',
    'Ref: email of guest': 'อีเมลของผู้เข้าพัก',
    'Ref: GuestAccommodationRequestTitle': 'คำขอของแขก',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'คำขอใหม่',
    'Ref: Status': 'สถานะ',
    'Ref: Date': 'วันที่',
    'Ref: Coming arrivals': 'มาถึง',
    'Ref: Past arrivals': 'มาถึงที่ผ่านมา',
  },
  CreateForm: {
    'Ref: Guest phone number': 'หมายเลขโทรศัพท์ของแขก',
    'Ref: Your phone number': 'หมายเลขโทรศัพท์ของคุณ',
    'Ref: The phone number': 'หมายเลขโทรศัพท์',
    'Ref: PhoneNumber error msg': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
    'Ref: Comment error msg': 'จำนวนอักขระสูงสุดคือ 250',
    'Ref: Guest first name': 'แขกชื่อแรก',
    'Ref: Guest last name': 'นามสกุลของแขก',
    'Ref: request for': 'คำขอนี้คือใคร?',
    'Ref: request for me': 'ฉัน',
    'Ref: request for guest': 'คนอื่น',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'รายละเอียดคำขอที่พัก',
    'Ref: Timeline': 'เส้นเวลา',
    'Ref: No updates placeholder': 'ไม่มีการอัปเดตสำหรับคำขอนี้',
  },
  CreateThankYou: {
    'Ref: Thank you': 'ขอบคุณ',
    'Ref: Success text': 'เราได้รับคำขอของคุณและกำลังดูอยู่แล้ว เราจะติดต่อกลับเร็ว ๆ นี้',
    'Ref: Go to home': 'กลับบ้าน',
    'Ref: Create another request': 'สร้างคำขออื่น',
  },
  CreateError: {
    'Ref: Create error title': 'อ๊ะ!',
    'Ref: Create error text': 'เกิดข้อผิดพลาดโปรดลองอีกครั้ง',
    'Ref: Retry': 'ลองอีกครั้ง',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'โปรดอัปเดตเราด้วยองค์ประกอบใหม่ใด ๆ เกี่ยวกับคำขอของคุณเพื่อให้เราสามารถสนับสนุนคุณได้ดีที่สุด',
  },
};
export default labels;
