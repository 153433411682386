const labels = {
  TimeTable: {
    'leave at': 'Partiamo alle',
    'arrive at': 'Arriva a',
    at: 'a',
    'Ref: list placeholder': 'Completa il modulo per vedere le ore',
    'Ref: Page title': 'Autobus',
    'Ref: Departure': 'Partenza',
    'Ref: Arrivals': 'Arrivi',
    'Ref: minutes': 'minuti',
    'Ref: no items placeholder': 'Mi dispiace, non ci sono autobus disponibili in questo momento.',
  },
};
export default labels;
