const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Auditointi',
    'Ref: status0': 'Avata',
    'Ref: status1': 'Valmis',
    'Ref: status2': 'Peruutettu',
    'Ref: connected': 'Kytketty',
    'Ref: not connected': 'Ei yhteyttä',
    'Ref: synchronise data': 'Synkronoida tiedot',
    'Ref: sync success': 'Data synkronoitu onnistuneesti',
    'Ref: Create new': 'Luo uusi',
    'Ref: Audit unsynchronised': 'Syytön',
    'Ref: Audit synchronised': 'Synkronoitu',
    'Ref: Unsynced error title': 'Tapahtui virhe',
    'Ref: Unsynced error description':
      'Näyttää siltä, ​​että olet ensimmäistä kertaa pääsy tarkastusmoduuliin. Ole hyvä ja muodosta yhteys verkkoon ja "synkronoi" aloittaaksesi auditoinnin.',
    'Ref: Search all audits': 'Kaikki tarkastukset',
    'Ref: Search active audits': 'Avata',
    'Ref: Search completed audits': 'Valmis',
    'Ref: sync error': 'Synkronointi epäonnistui',
    'Ref: sync error details': 'Yrittää uudelleen yhteyden muodostamiseksi',
    'Ref: no search audit results': 'Valitettavasti hakuasi ei ole tarkastus',
    'Ref: no audit results':
      'Sinulla ei ole käytettävissä olevia auditointeja, luo uusi aloittamiseen',
    'Ref: Search canceled audits': 'Peruutettu',
    'Ref: Audit cancelled successfully': 'Tarkastus peruutettiin onnistuneesti',
    'Ref: Cancel audit invite': 'Peruuttaa',
    'Ref: Error audit cancel': 'Tarkastus ei peruutettu',
    'Ref: Cancel audit confirmation': 'Haluatko varmasti peruuttaa tarkastuksen?',
    'Ref: Confirm': 'Vahvistaa',
    'Ref: Cancel audit': 'Peruuta tarkastus',
    'Ref: Change the location': 'Vaihda sijainti',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Tarkastustiedot',
    'Ref: questions': 'kysymykset',
    'Ref: question': 'kyseenalaistaa',
    'Ref: Additional Comments': 'Kuvaus',
    'Ref: Not found title': 'Oho!',
    'Ref: Not found text': 'Tarkastusta, jota yrität käyttää, ei ole.',
    'Ref: Back to Audit list': 'Takaisin tarkastusluetteloon',
    'Ref: Success title': 'Kiitos!',
    'Ref: Success text': 'Olet suorittanut tarkastus',
    'Ref: submit': 'Lähetä',
    'Ref: add attachment': 'Lisää liite',
    'Ref: Unsupported elements':
      'Valitettavasti näyttää siltä, ​​että tämä auditointi on sisältöä, jota sovellus ei vielä tue. Työskentelemme sen kanssa.',
    'Ref: form error': 'Kaikiin kysymyksiin ei ole vastattu täysin',
    'Ref: form complete': 'Valmistunut siitä lähtien',
    'Ref: attachment preview error': 'Sinun on oltava verkossa nähdäksesi tämän liitteen',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Luo uusi',
    'Ref: Location': 'Sijainti',
    'Ref: Audit Stream Type': 'Purkaa',
    'Ref: Audit': 'Tarkastaa',
    'Ref: Asset': 'Omaisuus',
    'Ref: Asset Name': 'Omaisuuden nimi',
    'Ref: Is joint audit?': 'Onko yhteinen tarkastus?',
    'Ref: Who was present?': 'Kuka oli läsnä?',
    'Ref: submit': 'Lähetä',
    'Ref: Select the Location': 'Valitse sijainti',
    'Ref: Select the Audit Stream Type': 'Valitse tilintarkastusvirtatyyppi',
    'Ref: Select the Audit': 'Valitse tarkastus',
    'Ref: Select the Asset': 'Valitse omaisuus',
    'Ref: form error': 'Kaikki vaadittavat kentät eivät ole täysin syötetty',
    'Ref: Audit error': 'Liitetty tarkastus',
    'Ref: Joint Audit Participants error': 'Yhteiset tarkastuskäyttäjät',
    'Ref: Audit created': 'Luotu tilintarkastus',
    'Ref: Audit field length error': 'Pituuden ei pitäisi ylittää 100 merkkiä',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Vaihda sijainti',
    'Ref: Location changed': 'Sijainti muuttui',
    'Ref: Location': 'Sijainti',
    'Ref: Select the Location': 'Valitse sijainti',
    'Ref: Asset': 'Omaisuus',
    'Ref: Select the Asset': 'Valitse omaisuus',
    'Ref: Asset Name': 'Omaisuuden nimi',
    'Ref: submit': 'Lähetä',
    'Ref: Audit field length error': 'Pituuden ei pitäisi ylittää 100 merkkiä',
  },
};
export default labels;
