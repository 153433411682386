const labels = {
  ContactForm: {
    'Ref: Page title': 'Contacte con nosotros',
    'Ref: Category field value': 'Contacte con nosotros',
  },
  ContactList: {
    'Ref: Page title': 'Contactos',
    'call (action to place a call)': 'llamar',
    'write (action to write a message)': 'escribir',
    'write (action to write an e-mail)': 'Email',
    'Ref: Hint message':
      'Tenga en cuenta que algunos centros pueden no tener recepción. Si este es el caso, localice uno de los teléfonos fijos disponibles en el centro',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Ocurrió un error y su mensaje no fue enviado. Si este error persiste, póngase en contacto con el mesa de ayuda.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success':
      'Su mensaje fue enviado. Gracias por ponerse en contacto con nosotros.',
  },
};
export default labels;
