const labels = {
  All: {
    dailyMenu: 'Dienos meniu',
    anytime: 'kada',
    breakfast: 'pusryčiai',
    morningBreak: 'Rytinė pertrauka',
    lunch: 'pietūs',
    afternoonBreak: 'Po pietų pertraukos',
    dinner: 'vakarienė',
    supper: 'Vakarienė',
    nightMeal: 'Naktinis miltai',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Meniu',
    'Ref: View details': 'Išsamiau',
    'Ref: Facility details': 'Facility detalės',
  },
  OpeningHours: { everyday: 'kiekvieną dieną', 'open all day': 'atidarykite visą dieną' },
  ProductsList: {
    'Ref: Page title': 'Meniu',
    'Ref: Facility details': 'Facility detalės',
    'Ref: Search products list': 'Ieškoti prekių',
    'Ref: filter label: date': 'Data',
    'Ref: filter label: menus': 'Meniu',
    'Ref: filter label: moment': 'Momentas',
    'Ref: filter label: category': 'Kategorija',
    'Ref: filter label: dishes': 'Patiekalai',
    'Ref: filter label: highlight': 'Paryškinkite arba paslėpkite filtrus patiekalais',
    All: 'Viskas',
  },
  ProductDetails: { 'Ref: Page title': 'Produkto Aprašymas' },
  Widget: {
    'Ref: Carousel title': 'Meniu',
    'No info yet, come back later': 'Dar nėra informacijos, grįžk vėliau 😉',
    'Ref: menus': 'Meniu',
    'Ref: see all': 'Matyti viską',
  },
};
export default labels;
