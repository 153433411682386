import React, { FC, useMemo } from 'react';

import Button, { BUTTON_LOOK } from '../../atoms/Button';

import { ButtonAlert, AlertButtonProps } from './Alert.types';

export const AlertButton: FC<AlertButtonProps> = ({
  button,
  stateInput,
  onDismiss,
  'data-testid': alertDataTestId,
}) => {
  const valueProps = useMemo<ButtonAlert>(() => {
    if (typeof button === 'string') {
      return {
        text: button,
        'data-testid': alertDataTestId, 
      };
    }
    return button;
  }, [button, alertDataTestId]);

  const { text, handler, 'data-testid': dataTestId, ...buttonProps } = valueProps;

  const onClick = () => {
    let isHandle: boolean | void | Promise<void> = true;
    if (handler) {
      isHandle = handler(stateInput);
    }
    isHandle !== false && onDismiss && onDismiss();
  };

  return (
    <Button
      look={BUTTON_LOOK.TERTIARY}
      onClick={onClick}
      data-testid={`${alertDataTestId}-button-${(dataTestId && dataTestId !== alertDataTestId) ? dataTestId : 'default'}`}
      {...buttonProps}
    >
      {text}
    </Button>
  );
};
