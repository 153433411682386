import React, { useCallback, useMemo, useState } from 'react';

import { isSmallViewport } from '../../../helpers/windowSize';

import { FilterComponentProps, FilterType } from './Filters.types';
import FilterWrapper from './FilterWrapper';
import {
  formatLabelValue,
  getFilterApplicableOptions,
  getFilterComponentFromFilterConfig,
  isFilterInModal,
} from './helpers';

export const FilterComponent: React.FC<FilterComponentProps> = ({
  filter,
  filtering,
  sectionRefs,
  activeSectionIndex,
  label,
  className,
  languageCode = 'en-US',
  handleChange,
  handleCalendarChange,
  'data-testid': testId,
}) => {
  const { id, name, displayType, position, text } = filter;

  const filtersWithoutOptions = [FilterType.RANGE, undefined];
  const filtersWithValueInLabel = [FilterType.RANGE];
  const isSmallScreen = isSmallViewport();
  const isInModal = isFilterInModal({ position, isSmallScreen });
  const applicableOptions = useMemo(
    () => getFilterApplicableOptions(filter, filtering),
    [filter, filtering]
  );
  const filterHasApplicableOptions = applicableOptions?.length > 0;
  const isFilterWithoutOptions = filtersWithoutOptions.includes(displayType);

  const [val, setVal] = useState(Object.keys(filtering[filter.id] || {})[0]);

  const onChange = useCallback(
    (name: string, value: string, checked?: boolean) => {
      setVal(value);
      handleChange(name, value, checked);
    },
    [handleChange]
  );

  const onChangeDate = useCallback(
    (filterId: string, strDate: string) => {
      setVal(strDate);
      handleCalendarChange && handleCalendarChange(filterId, strDate);
    },
    [handleCalendarChange]
  );

  const displayedFilter = useMemo(
    () =>
      getFilterComponentFromFilterConfig({
        filter,
        filtering,
        label,
        languageCode,
        id,
        applicableOptions,
        isInModal,
        handleChange: onChange,
        handleCalendarChange: onChangeDate,
        testId,
        sectionRefs,
        activeSectionIndex
      }),
    [
      applicableOptions,
      filter,
      filtering,
      onChangeDate,
      onChange,
      id,
      isInModal,
      label,
      languageCode,
      testId,
      sectionRefs,
      activeSectionIndex
    ]
  );
  const formattedValue = formatLabelValue(filter, val);

  //if no options available, stop there
  if (!filterHasApplicableOptions && !isFilterWithoutOptions) {
    return null;
  }

  return (
    <FilterWrapper
      isInModal={isInModal}
      name={name}
      key={filter.id}
      withLabelValue={displayType && filtersWithValueInLabel.includes(displayType)}
      withTitle={filter.withTitle}
      value={formattedValue}
      text={text}
      data-testid={`${testId}-filter-wrapper`}
      className={className}
    >
      {displayedFilter}
    </FilterWrapper>
  );
};
