const mockData = {
  getFoodFacilities: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        facilities: [
          {
            title: "Akshay's Facility",
            email: '',
            phone: '',
            name: "Akshay's Facility - FMG - FMG Hamilton",
            description: '',
            id: '764c9169-465c-ea11-b698-0003ff4ca494',
            sequence: 0,
            externalUrl: 'https://www.nationalgeographic.com/',
            access: '',
            longitude: '',
            latitude: '',
            images: ['7fe80e56-1766-eb11-a812-000d3abf44f2'],
            openingHours: [],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580000',
              name: 'Food - Non-retail',
            },
            menuNote: '',
          },
          {
            title: "Akshay's Facility 2",
            email: '',
            phone: '',
            name: "Akshay's Facility 2 - FMG - FMG Hamilton",
            description: '',
            id: '0e110a8e-465c-ea11-b698-0003ff4ca494',
            sequence: 0,
            externalUrl: 'https://www.nationalgeographic.com/',
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [
              {
                closeTime: '',
                name: "Akshay's Opening hours",
                openTime: '',
                isOpenAllDay: false,
                day: [],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580000',
              name: 'Food - Non-retail',
            },
            menuNote: '',
          },
          {
            title: 'Nobin Cafe TEST 2',
            email: 'nobin@cafe.com',
            phone: '+115151151',
            name: 'Nobin Cafe TEST 2 - FMG - FMG Hamilton',
            description:
              "Nobin's Cafe Test offers authentic french pastries hand carved out of the dense bread forests located on the outskirts of the Louvre. Come visit or order something from this app to come collect later and your tongue would be thanking you for eternity.",
            id: '5ab2b628-6663-ea11-b698-0003ff4ca494',
            sequence: 0,
            access: '',
            longitude: '',
            latitude: '',
            images: [],
            openingHours: [
              {
                closeTime: '20:30',
                name: 'Nobin opening hours',
                openTime: '08:30',
                isOpenAllDay: true,
                day: ['Friday', 'Thursday'],
              },
              {
                closeTime: '21:30',
                name: 'Nobin opening hours',
                openTime: '08:30',
                isOpenAllDay: true,
                day: ['Tuesday', 'Saturday', 'Wednesday'],
              },
              {
                closeTime: '22:30',
                name: 'Nobin opening hours',
                openTime: '08:30',
                isOpenAllDay: true,
                day: ['Monday'],
              },
            ],
            isFoodFacilityType: true,
            facilityType: {
              id: '555580000',
              name: 'Food - Non-retail',
            },
            menuNote: "This is a menu note for Nobi's Cafe!!",
          },
        ],
      }),
  },
  getMenus: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        menus: [
          {
            id: '42982bcf-6ae1-ec11-b5cf-0003ff857a05',
            name: 'Brockman 4 Concept + Crib',
            dayparts: [
              {
                name: 'CRIB',
                sections: [
                  {
                    id: '',
                    name: 'Created by You (MYO)',
                    items: [
                      {
                        id: '673be8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Mediterranean Veg Cheese and Pesto Focaccia',
                        description: 'Mediterranean Veg Cheese and Pesto Focaccia',
                        price: '6.00',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Created by You (MYO)',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: 'c83be8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Roast Pork Mustard and Coleslaw Roll',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Created by You (MYO)',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '763ce8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Cre8ted Ham and Swiss Cheese on Rye',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Created by You (MYO)',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                    ],
                  },
                  {
                    id: '',
                    name: 'Created for You (Grab and Go)',
                    items: [
                      {
                        id: '7c83e0ee-6ae1-ec11-bb3d-00224892f217',
                        name: 'Created Vietnamese Noodle Bowl',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Created for You (Grab and Go)',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '3084e0ee-6ae1-ec11-bb3d-00224892f217',
                        name: 'Mediterranean Pasta Salad',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Created for You (Grab and Go)',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: 'c884e0ee-6ae1-ec11-bb3d-00224892f217',
                        name: 'French Onion Dip',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Created for You (Grab and Go)',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                    ],
                  },
                  {
                    id: '',
                    name: 'Heat and Eat',
                    items: [
                      {
                        id: '2485e0ee-6ae1-ec11-bb3d-00224892f217',
                        name: 'Baked Chicken Drumsticks with Honey Mustard Sauce',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Heat and Eat',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '4d85e0ee-6ae1-ec11-bb3d-00224892f217',
                        name: 'Fiery Lamb Ragu with Fettucini',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Heat and Eat',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '7785e0ee-6ae1-ec11-bb3d-00224892f217',
                        name: 'Chicken and Corn Soup',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Heat and Eat',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                    ],
                  },
                ],
                dayofweek: 'Monday',
                date: '2022-06-20T00:00:00Z',
              },
              {
                name: 'DINNER',
                sections: [
                  {
                    id: '',
                    name: 'Soup',
                    items: [
                      {
                        id: 'a990ede2-6ae1-ec11-bb3d-00224892f217',
                        name: 'Mulligatawny Soup',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Soup',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                    ],
                  },
                  {
                    id: '',
                    name: 'Cook to Order',
                    items: [
                      {
                        id: '1591ede2-6ae1-ec11-bb3d-00224892f217',
                        name: 'Grilled Rump Steak',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Cook to Order',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '6e91ede2-6ae1-ec11-bb3d-00224892f217',
                        name: 'Garden Herbed Grilled Chicken',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Cook to Order',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: 'd991ede2-6ae1-ec11-bb3d-00224892f217',
                        name: 'Grilled Salmon',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Cook to Order',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '4392ede2-6ae1-ec11-bb3d-00224892f217',
                        name: 'Plant Based Burger Pattie',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Cook to Order',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: 'a192ede2-6ae1-ec11-bb3d-00224892f217',
                        name: 'Choose your Vegetables',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Cook to Order',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                    ],
                  },
                  {
                    id: '',
                    name: 'Dinner Destinations',
                    items: [
                      {
                        id: '7e38e8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Roast Pork',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Dinner Destinations',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: 'd238e8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Lamb Saag Aloo',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Dinner Destinations',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '2639e8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Butter Chicken Curry',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Dinner Destinations',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '8439e8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Red Lentil Dahl with Roasted Eggplant',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Dinner Destinations',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                    ],
                  },
                  {
                    id: '',
                    name: 'Dessert',
                    items: [
                      {
                        id: 'e339e8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Indian Rice Pudding',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Dessert',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                      {
                        id: '423ae8e8-6ae1-ec11-bb3d-00224892f217',
                        name: 'Chocolate Cake with Chocolate Sauce',
                        description: '',
                        price: '',
                        displayPrice: '',
                        priceInText: '',
                        category: 'Dessert',
                        imageId: '',
                        listImageUrl: '',
                        detailImageUrl: '',
                        foodSubCategoryDescription: '',
                        nutritionFacts: null,
                        allergens: [],
                        isOrderable: false,
                        modifiers: [],
                        uOMId: 0,
                      },
                    ],
                  },
                ],
                dayofweek: 'Monday',
                date: '2022-06-20T00:00:00Z',
              },
            ],
            pickupspotreferenceid: 'a06ad89f-bdd2-e811-8199-e0071b67ccf1',
          },
        ],

        // menus: [
        //   {
        //     id: '160',
        //     name: 'IFMS Breakfast Tavern Menu',
        //     dayparts: [
        //       {
        //         name: 'Breakfast',
        //         sections: [
        //           {
        //             id: 'Breakfast Favourites',
        //             name: 'Breakfast Favourites',
        //             items: [
        //               {
        //                 id: 'PUR16|3317838|2408',
        //                 name: 'BBQ Brekky Wrap',
        //                 description:
        //                   'Tortilla wrap filled with scrambled eggs, bacon, cheese, chorizo sausage and bbq sauce',
        //                 price: '14.20',
        //                 displayPrice: '14.20',
        //                 priceInText: '',
        //                 category: '',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2408-20210827105714-foodImg.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2408-20200512012741-Breakky_Wrap.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '850',
        //                   fat: '64g',
        //                   carbohydrates: '17g',
        //                   protein: '53g',
        //                   sodium: '2320mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2099,
        //               },
        //               {
        //                 id: 'PUR17|3317839|2409',
        //                 name: 'Breakfast Burger',
        //                 description:
        //                   'Beef burger patty on a brioche roll with bacon, egg, cheese and chipotle bbq sauce. Served with potato gems',
        //                 price: '14.59',
        //                 displayPrice: '14.59',
        //                 priceInText: '',
        //                 category: '',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2409-20200512012708-Breakfast_Burger.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2409-20200512012750-Breakfast_Burger.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1130',
        //                   fat: '58g',
        //                   carbohydrates: '92g',
        //                   protein: '58g',
        //                   sodium: '2960mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2100,
        //               },
        //               {
        //                 id: 'PUR18|3317840|2410',
        //                 name: 'Loaded Potato Gems',
        //                 description:
        //                   'Potato gems topped with crispy bacon pieces, melted cheese and sour cream',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: '',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2410-20200512012723-Loaded_Potato_Gems.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2410-20200512012758-Loaded_Potato_Gems.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '990',
        //                   fat: '72g',
        //                   carbohydrates: '48g',
        //                   protein: '36g',
        //                   sodium: '2300mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2101,
        //               },
        //               {
        //                 id: 'PUR19|3317841|2411',
        //                 name: 'Chicken Nuggets',
        //                 description: '10 Chicken nuggets served with tomato sauce',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: '',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2411-20200512012730-Chicken_Nuggets.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2411-20200512012805-Chicken_Nuggets.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '750',
        //                   fat: '44g',
        //                   carbohydrates: '53g',
        //                   protein: '32g',
        //                   sodium: '2650mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2102,
        //               },
        //             ],
        //           },
        //         ],
        //         dayofweek: 'Tuesday',
        //         date: '2021-08-03T00:00:00',
        //       },
        //     ],
        //     pickupspotreferenceid: '160',
        //   },
        //   {
        //     id: '103',
        //     name: 'IFMS Standard Tavern Menu 2020',
        //     dayparts: [
        //       {
        //         name: 'Dinner',
        //         sections: [
        //           {
        //             id: 'Bar Meals',
        //             name: 'Bar Meals',
        //             items: [
        //               {
        //                 id: 'PUR4|3314267|2380',
        //                 name: 'Chipotle Wings',
        //                 description:
        //                   'Seasoned wings tossed through a BBQ Chiplotle sauce with fries and ranch on the side',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Tasty Plates',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2380-20210829140833-chiplot_wings.jpg',
        //                 detailImageUrl: '',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1540',
        //                   fat: '125g',
        //                   carbohydrates: '67g',
        //                   protein: '82g',
        //                   sodium: '4550mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Eggs',
        //                     contains: true,
        //                     allergen: 2,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Celery',
        //                     contains: true,
        //                     allergen: 10,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 35,
        //                         name: 'Iceberg Lettuce Salad',
        //                         price: 9.0,
        //                         priceLabel: 'AUD 9.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|35',
        //                         description: 'Iceberg Lettuce Salad',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 36,
        //                         name: 'Simple 3 Onion Salad',
        //                         price: 7.0,
        //                         priceLabel: 'AUD 7.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|36',
        //                         description: 'Simple 3 Onion Salad',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 37,
        //                         name: 'Simple Cucumber Salad',
        //                         price: 7.0,
        //                         priceLabel: 'AUD 7.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|37',
        //                         description: 'Simple Cucumber Salad',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 38,
        //                         name: 'Simple Tomato Salad',
        //                         price: 9.0,
        //                         priceLabel: 'AUD 9.00',
        //                         sortOrder: 4,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|38',
        //                         description: 'Simple Tomato Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 28,
        //                     name: 'Salad Base',
        //                     description: 'Salad Base',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|28',
        //                   },
        //                 ],
        //                 uOMId: 2068,
        //               },
        //               {
        //                 id: 'PUR9|3314272|2385',
        //                 name: 'Chicken Pizza',
        //                 description:
        //                   'Tomatoes, cheese, BBQ sauce, chicken, bacon, capsicum and oregano',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Pizzas',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2385-20210827122435-chicken_pizza.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2385-20210830150832-chicpizza.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2340',
        //                   fat: '82g',
        //                   carbohydrates: '231g',
        //                   protein: '156g',
        //                   sodium: '4490mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                   {
        //                     name: 'Sulphites',
        //                     contains: true,
        //                     allergen: 17,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 62,
        //                         name: 'Iceberg Lettuce Salad',
        //                         price: 15.0,
        //                         priceLabel: 'AUD 15.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|62',
        //                         description: 'Iceberg Lettuce Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 63,
        //                         name: 'Simple 3 Onion Salad',
        //                         price: 14.0,
        //                         priceLabel: 'AUD 14.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|63',
        //                         description: 'Simple 3 Onion Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 64,
        //                         name: 'Simple Cucumber Salad',
        //                         price: 16.0,
        //                         priceLabel: 'AUD 16.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|64',
        //                         description: 'Simple Cucumber Salad',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 37,
        //                     name: 'Salad Base',
        //                     description: 'Salad Base',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|37',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 65,
        //                         name: 'Feta Cheese',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|65',
        //                         description: 'Feta Cheese',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 66,
        //                         name: 'Prawn Mayonnaise',
        //                         price: 15.0,
        //                         priceLabel: 'AUD 15.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|66',
        //                         description: 'Prawn Mayonnaise',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 38,
        //                     name: 'Extra Protein',
        //                     description: 'Extra Protein',
        //                     type: 2,
        //                     selectionLimit: 1,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|38',
        //                   },
        //                 ],
        //                 uOMId: 2073,
        //               },
        //               {
        //                 id: 'PUR23|3314264|2399',
        //                 name: 'Beyond Burger',
        //                 description:
        //                   'Beyond Meat burger patty, lettuce, tomato, onion and vegan mayonaaise. Served with veggie fries',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Burgers & Sandwiches',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2399-20210826125720-foodImg.jpg',
        //                 detailImageUrl: '',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1080',
        //                   fat: '60g',
        //                   carbohydrates: '106g',
        //                   protein: '45g',
        //                   sodium: '1640mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 28,
        //                         name: 'Tuna',
        //                         price: 10.0,
        //                         priceLabel: 'AUD 10.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|28',
        //                         description: 'Tuna',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 29,
        //                         name: 'Tuna Light Mayo',
        //                         price: 16.0,
        //                         priceLabel: 'AUD 16.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|29',
        //                         description: 'Tuna Light Mayo',
        //                         min: 0,
        //                         max: 5,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 5,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 30,
        //                         name: 'Grated Cheese',
        //                         price: 11.0,
        //                         priceLabel: 'AUD 11.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|30',
        //                         description: 'Grated Cheese',
        //                         min: 0,
        //                         max: 5,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 5,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 25,
        //                     name: 'Star Filling',
        //                     description: 'Star Filling',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|25',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 31,
        //                         name: 'Simple Cucumber Salad',
        //                         price: 7.0,
        //                         priceLabel: 'AUD 7.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|31',
        //                         description: 'Simple Cucumber Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 32,
        //                         name: 'Simple Tomato Salad',
        //                         price: 9.0,
        //                         priceLabel: 'AUD 9.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|32',
        //                         description: 'Simple Tomato Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 26,
        //                     name: 'Salad Base',
        //                     description: 'Salad Base',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|26',
        //                   },
        //                 ],
        //                 uOMId: 2087,
        //               },
        //               {
        //                 id: 'PUR22|3314263|2398',
        //                 name: 'Cheese Burger',
        //                 description:
        //                   'Grilled beef patty on a brioche bun with cheese, pickle, onion, tomato sauce and mustard. Served with fries',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Burgers & Sandwiches',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2398-20200421082736-Cheeseburger.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2398-20200421082657-Cheeseburger.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1080',
        //                   fat: '37g',
        //                   carbohydrates: '135g',
        //                   protein: '44g',
        //                   sodium: '2350mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Eggs',
        //                     contains: true,
        //                     allergen: 2,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Mustard',
        //                     contains: true,
        //                     allergen: 9,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 24,
        //                         name: 'Cheesy Beans',
        //                         price: 9.0,
        //                         priceLabel: 'AUD 9.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|24',
        //                         description: 'Cheesy Beans',
        //                         min: 0,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 25,
        //                         name: 'Baked Beans',
        //                         price: 7.0,
        //                         priceLabel: 'AUD 7.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|25',
        //                         description: 'Baked Beans',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 23,
        //                     name: 'Star Filling',
        //                     description: 'Star Filling',
        //                     type: 1,
        //                     selectionLimit: 2,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|23',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 26,
        //                         name: 'Shredded Ham',
        //                         price: 7.0,
        //                         priceLabel: 'AUD 7.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|26',
        //                         description: 'Shredded Ham',
        //                         min: 0,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 27,
        //                         name: 'Coronation Chicken',
        //                         price: 9.0,
        //                         priceLabel: 'AUD 9.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|27',
        //                         description: 'Coronation Chicken',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 24,
        //                     name: 'Guest Filling',
        //                     description: 'Guest Filling',
        //                     type: 1,
        //                     selectionLimit: 2,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|24',
        //                   },
        //                 ],
        //                 uOMId: 2086,
        //               },
        //               {
        //                 id: 'PUR6|3314269|2382',
        //                 name: 'Hawaiian Pizza',
        //                 description: 'Tomatoes, garlic, cheese, ham and pineapple',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Pizzas',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2382-20210824150925-Hawai_Pizza.jpg',
        //                 detailImageUrl: '',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2260',
        //                   fat: '88g',
        //                   carbohydrates: '232g',
        //                   protein: '117g',
        //                   sodium: '5510mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 70,
        //                         name: 'Boiled Egg',
        //                         price: 19.0,
        //                         priceLabel: 'AUD 19.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|70',
        //                         description: 'Boiled Egg',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 71,
        //                         name: 'Sliced Gammon',
        //                         price: 12.0,
        //                         priceLabel: 'AUD 12.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|71',
        //                         description: 'Sliced Gammon',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 72,
        //                         name: 'Flaked Tuna',
        //                         price: 11.0,
        //                         priceLabel: 'AUD 11.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|72',
        //                         description: 'Flaked Tuna',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 73,
        //                         name: 'Grated Cheddar Cheese',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 4,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|73',
        //                         description: 'Grated Cheddar Cheese',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 40,
        //                     name: 'Extra Protein',
        //                     description: 'Extra Protein',
        //                     type: 2,
        //                     selectionLimit: 1,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|40',
        //                   },
        //                 ],
        //                 uOMId: 2070,
        //               },
        //               {
        //                 id: 'PUR1|3314255|2377',
        //                 name: 'Hot Chips',
        //                 description: 'Beer batterd chips with tomato sauce and aoli',
        //                 price: '14.59',
        //                 displayPrice: '14.59',
        //                 priceInText: '',
        //                 category: 'Tasty Plates',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2377-20210827162729-foodImg.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2377-20200421074616-Chips.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '840',
        //                   fat: '26g',
        //                   carbohydrates: '136g',
        //                   protein: '14g',
        //                   sodium: '1830mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 43,
        //                         name: 'Boiled Egg',
        //                         price: 12.0,
        //                         priceLabel: 'AUD 12.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|43',
        //                         description: 'Boiled Egg',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 44,
        //                         name: 'Sliced Gammon',
        //                         price: 5.0,
        //                         priceLabel: 'AUD 5.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|44',
        //                         description: 'Sliced Gammon',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 45,
        //                         name: 'Flaked Tuna',
        //                         price: 5.0,
        //                         priceLabel: 'AUD 5.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|45',
        //                         description: 'Flaked Tuna',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 30,
        //                     name: 'Extra Protein',
        //                     description: 'Extra Protein',
        //                     type: 2,
        //                     selectionLimit: 1,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|30',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 46,
        //                         name: 'Potato Salad',
        //                         price: 11.0,
        //                         priceLabel: 'AUD 11.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|46',
        //                         description: 'Potato Salad',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 47,
        //                         name: 'Curried Pasta Salad',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|47',
        //                         description: 'Curried Pasta Salad',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 31,
        //                     name: 'Salad Base',
        //                     description: 'Salad Base',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|31',
        //                   },
        //                 ],
        //                 uOMId: 2065,
        //               },
        //               {
        //                 id: 'PUR7|3314270|2383',
        //                 name: 'Meat Lovers Pizza',
        //                 description: 'Tomatoes, garlic, cheese, oregano, ham and pepperoni',
        //                 price: '13.00',
        //                 displayPrice: '13.00',
        //                 priceInText: '',
        //                 category: 'Pizzas',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2383-20200421074323-BBQ_meatlovers.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2383-20200421074825-BBQ_meatlovers.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2840',
        //                   fat: '134g',
        //                   carbohydrates: '227g',
        //                   protein: '169g',
        //                   sodium: '8550mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                   {
        //                     name: 'Sulphites',
        //                     contains: true,
        //                     allergen: 17,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 74,
        //                         name: 'Smoked Mackeral Fillet',
        //                         price: 15.0,
        //                         priceLabel: 'AUD 15.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|74',
        //                         description: 'Smoked Mackeral Fillet',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 75,
        //                         name: 'Cottage Cheese',
        //                         price: 16.0,
        //                         priceLabel: 'AUD 16.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|75',
        //                         description: 'Cottage Cheese',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 76,
        //                         name: 'Sliced Chicken',
        //                         price: 15.0,
        //                         priceLabel: 'AUD 15.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|76',
        //                         description: 'Sliced Chicken',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 77,
        //                         name: 'Falafel',
        //                         price: 14.0,
        //                         priceLabel: 'AUD 14.00',
        //                         sortOrder: 4,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|77',
        //                         description: 'Falafel',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 41,
        //                     name: 'Extra Protein',
        //                     description: 'Extra Protein',
        //                     type: 2,
        //                     selectionLimit: 1,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|41',
        //                   },
        //                 ],
        //                 uOMId: 2071,
        //               },
        //               {
        //                 id: 'PUR12|3314257|2388',
        //                 name: 'Chicken Schnitzel Burger',
        //                 description:
        //                   'Chicken schnitzel on toasted turkish bread with bacon, cheese, lettuce, tomato and hickory bbq sauce',
        //                 price: '14.50',
        //                 displayPrice: '14.50',
        //                 priceInText: '',
        //                 category: 'Burgers & Sandwiches',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2388-20200421061257-Chicken_Burger.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2388-20200421074737-Chicken_Burger.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1290',
        //                   fat: '57g',
        //                   carbohydrates: '157g',
        //                   protein: '32g',
        //                   sodium: '2660mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Eggs',
        //                     contains: true,
        //                     allergen: 2,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 33,
        //                         name: 'Shredded Ham',
        //                         price: 6.0,
        //                         priceLabel: 'AUD 6.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|33',
        //                         description: 'Shredded Ham',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 34,
        //                         name: 'Coronation Chicken',
        //                         price: 14.0,
        //                         priceLabel: 'AUD 14.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|34',
        //                         description: 'Coronation Chicken',
        //                         min: 1,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 27,
        //                     name: 'Guest Filling',
        //                     description: 'Guest Filling',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|27',
        //                   },
        //                 ],
        //                 uOMId: 2076,
        //               },
        //               {
        //                 id: 'PUR8|3314271|2384',
        //                 name: 'Supreme Pizza',
        //                 description:
        //                   'Tomatoes, cheese, ham, pepperoni, pineapple, roasted peppers, black olives, spinach leaves and feta',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Pizzas',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2384-20200421074345-gourmet_baked.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2384-20200421074843-gourmet_baked.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2530',
        //                   fat: '118g',
        //                   carbohydrates: '220g',
        //                   protein: '134g',
        //                   sodium: '6800mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 78,
        //                         name: 'Sliced Turkey',
        //                         price: 18.0,
        //                         priceLabel: 'AUD 18.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|78',
        //                         description: 'Sliced Turkey',
        //                         min: 0,
        //                         max: 4,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 4,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 79,
        //                         name: 'Sliced Pastrami',
        //                         price: 14.0,
        //                         priceLabel: 'AUD 14.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|79',
        //                         description: 'Sliced Pastrami',
        //                         min: 1,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 80,
        //                         name: 'Sliced Brie',
        //                         price: 11.0,
        //                         priceLabel: 'AUD 11.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|80',
        //                         description: 'Sliced Brie',
        //                         min: 0,
        //                         max: 3,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 3,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 42,
        //                     name: 'Extra Protein',
        //                     description: 'Extra Protein',
        //                     type: 2,
        //                     selectionLimit: 4,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|42',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 81,
        //                         name: 'Potato Salad',
        //                         price: 14.0,
        //                         priceLabel: 'AUD 14.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|81',
        //                         description: 'Potato Salad',
        //                         min: 1,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 82,
        //                         name: 'Curried Pasta Salad',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|82',
        //                         description: 'Curried Pasta Salad',
        //                         min: 1,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 43,
        //                     name: 'Salad Base',
        //                     description: 'Salad Base',
        //                     type: 1,
        //                     selectionLimit: 2,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|43',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 83,
        //                         name: 'Chia Seeds',
        //                         price: 12.0,
        //                         priceLabel: 'AUD 12.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|83',
        //                         description: 'Chia Seeds',
        //                         min: 0,
        //                         max: 3,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 3,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 84,
        //                         name: 'Croutons',
        //                         price: 5.0,
        //                         priceLabel: 'AUD 5.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|84',
        //                         description: 'Croutons',
        //                         min: 0,
        //                         max: 3,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 3,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 44,
        //                     name: 'Sprinkles',
        //                     description: 'Sprinkles',
        //                     type: 2,
        //                     selectionLimit: 3,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|44',
        //                   },
        //                 ],
        //                 uOMId: 2072,
        //               },
        //               {
        //                 id: 'PUR11|3314256|2387',
        //                 name: 'The Aussie Burger',
        //                 description:
        //                   'Grilled beef pattie on a sesame seed bun with lettuce, cheese, beetroot, fried egg, bacon and burger sauce',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Burgers & Sandwiches',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2387-20200421061240-Aussie_Burger.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2387-20200421074746-Aussie_Burger.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1420',
        //                   fat: '74g',
        //                   carbohydrates: '128g',
        //                   protein: '57g',
        //                   sodium: '2600mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Eggs',
        //                     contains: true,
        //                     allergen: 2,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Sesame',
        //                     contains: true,
        //                     allergen: 16,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                   {
        //                     name: 'Sulphites',
        //                     contains: true,
        //                     allergen: 17,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 48,
        //                         name: 'Cottage Cheese',
        //                         price: 16.0,
        //                         priceLabel: 'AUD 16.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|48',
        //                         description: 'Cottage Cheese',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 49,
        //                         name: 'Sliced Chicken',
        //                         price: 15.0,
        //                         priceLabel: 'AUD 15.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|49',
        //                         description: 'Sliced Chicken',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 50,
        //                         name: 'Falafel',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|50',
        //                         description: 'Falafel',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 51,
        //                         name: 'Sliced Turkey',
        //                         price: 19.0,
        //                         priceLabel: 'AUD 19.00',
        //                         sortOrder: 4,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|51',
        //                         description: 'Sliced Turkey',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 32,
        //                     name: 'Extra Protein',
        //                     description: 'Extra Protein',
        //                     type: 2,
        //                     selectionLimit: 1,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|32',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 52,
        //                         name: 'Potato Salad',
        //                         price: 11.0,
        //                         priceLabel: 'AUD 11.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|52',
        //                         description: 'Potato Salad',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 53,
        //                         name: 'Curried Pasta Salad',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|53',
        //                         description: 'Curried Pasta Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 33,
        //                     name: 'Salad Base',
        //                     description: 'Salad Base',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|33',
        //                   },
        //                 ],
        //                 uOMId: 2075,
        //               },
        //               {
        //                 id: 'PUR5|3314268|2381',
        //                 name: 'Vegetarian Pizza',
        //                 description:
        //                   'Tomatoes, cheese, roasted peppers, pineapple, spinach leaves, black olives and feta',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Pizzas',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2381-20200421074400-vegetarian_baked.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2381-20200421074913-vegetarian_baked.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2250',
        //                   fat: '89g',
        //                   carbohydrates: '242g',
        //                   protein: '103g',
        //                   sodium: '5250mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 67,
        //                         name: 'Sweetcorn Salad',
        //                         price: 18.0,
        //                         priceLabel: 'AUD 18.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|67',
        //                         description: 'Sweetcorn Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 68,
        //                         name: 'Potato Salad',
        //                         price: 14.0,
        //                         priceLabel: 'AUD 14.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|68',
        //                         description: 'Potato Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 69,
        //                         name: 'Curried Pasta Salad',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|69',
        //                         description: 'Curried Pasta Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 39,
        //                     name: 'Salad Base',
        //                     description: 'Salad Base',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|39',
        //                   },
        //                 ],
        //                 uOMId: 2069,
        //               },
        //               {
        //                 id: 'PUR21|3314262|2397',
        //                 name: 'Southern Chicken Tenders',
        //                 description:
        //                   'Boneless southern chicken tenders served with ranch dressing and fries',
        //                 price: '20.00',
        //                 displayPrice: '20.00',
        //                 priceInText: '',
        //                 category: 'Tasty Plates',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2397-20200421060922-Southern_Chicken_Tenders.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2397-20200421074641-Southern_Chicken_Tenders.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1260',
        //                   fat: '82g',
        //                   carbohydrates: '123g',
        //                   protein: '51g',
        //                   sodium: '3130mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Eggs',
        //                     contains: true,
        //                     allergen: 2,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Celery',
        //                     contains: true,
        //                     allergen: 10,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 17,
        //                         name: 'Onion Bhaji',
        //                         price: 15.0,
        //                         priceLabel: 'AUD 15.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|17',
        //                         description: 'Onion Bhaji',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 18,
        //                         name: 'Samosa',
        //                         price: 13.0,
        //                         priceLabel: 'AUD 13.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|18',
        //                         description: 'Samosa',
        //                         min: 1,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 20,
        //                     name: 'Onion Bhaji or Samosa',
        //                     description: 'Onion Bhaji or Samosa',
        //                     type: 2,
        //                     selectionLimit: 7,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|20',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 19,
        //                         name: 'Shredded Ham',
        //                         price: 6.0,
        //                         priceLabel: 'AUD 6.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|19',
        //                         description: 'Shredded Ham',
        //                         min: 1,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 20,
        //                         name: 'Coronation Chicken',
        //                         price: 14.0,
        //                         priceLabel: 'AUD 14.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|20',
        //                         description: 'Coronation Chicken',
        //                         min: 1,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 21,
        //                     name: 'Guest Filling',
        //                     description: 'Guest Filling',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|21',
        //                   },
        //                 ],
        //                 uOMId: 2085,
        //               },
        //               {
        //                 id: 'PUR25|3314265|2498',
        //                 name: 'Riblet Burger ',
        //                 description: 'Smokey BBQ Pork Riblet Burger with Chips on the side ',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Burgers & Sandwiches',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2498-20200831234810-r.png',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2498-20200831235203-Copy_of_Copy_of_Untitled.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1150',
        //                   fat: '64g',
        //                   carbohydrates: '17g',
        //                   protein: '58g',
        //                   sodium: '2320mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Eggs',
        //                     contains: true,
        //                     allergen: 2,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 9,
        //                         name: 'Cheese topping',
        //                         price: 0.0,
        //                         priceLabel: '',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|9',
        //                         description: 'Cheese topping',
        //                         min: 1,
        //                         max: 2,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 2,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 10,
        //                         name: 'Mustard Sauce',
        //                         price: 0.0,
        //                         priceLabel: '',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|10',
        //                         description: 'Mustard Sauce',
        //                         min: 1,
        //                         max: 3,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 3,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 16,
        //                     name: 'Burger Toppings',
        //                     description: 'Burger Toppings',
        //                     type: 2,
        //                     selectionLimit: 2,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|16',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 54,
        //                         name: 'Feta Cheese',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|54',
        //                         description: 'Feta Cheese',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 55,
        //                         name: 'Prawn Mayonnaise',
        //                         price: 15.0,
        //                         priceLabel: 'AUD 15.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|55',
        //                         description: 'Prawn Mayonnaise',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 34,
        //                     name: 'Extra Protein',
        //                     description: 'Extra Protein',
        //                     type: 2,
        //                     selectionLimit: 1,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|34',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 56,
        //                         name: 'French Dressing',
        //                         price: 9.0,
        //                         priceLabel: 'AUD 9.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|56',
        //                         description: 'French Dressing',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 57,
        //                         name: 'Caeser Dressing',
        //                         price: 11.0,
        //                         priceLabel: 'AUD 11.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|57',
        //                         description: 'Caeser Dressing',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 58,
        //                         name: 'Mint & Yoghurt Dressing',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|58',
        //                         description: 'Mint & Yoghurt Dressing',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 35,
        //                     name: 'Dressing',
        //                     description: 'Dressing',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|35',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 59,
        //                         name: 'Chia Seeds',
        //                         price: 12.0,
        //                         priceLabel: 'AUD 12.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|59',
        //                         description: 'Chia Seeds',
        //                         min: 0,
        //                         max: 3,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 3,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 60,
        //                         name: 'Croutons',
        //                         price: 5.0,
        //                         priceLabel: 'AUD 5.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|60',
        //                         description: 'Croutons',
        //                         min: 0,
        //                         max: 3,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 3,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 61,
        //                         name: 'Pumpkin Seeds',
        //                         price: 5.0,
        //                         priceLabel: 'AUD 5.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|61',
        //                         description: 'Pumpkin Seeds',
        //                         min: 0,
        //                         max: 3,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 3,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 36,
        //                     name: 'Sprinkles',
        //                     description: 'Sprinkles',
        //                     type: 2,
        //                     selectionLimit: 2,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|36',
        //                   },
        //                 ],
        //                 uOMId: 2189,
        //               },
        //               {
        //                 id: 'PUR20|3314261|2396',
        //                 name: 'Veggie Fries',
        //                 description: 'Vegetable fries with smokey chilli aioli',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Tasty Plates',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2396-20200421060943-Veggie_Fries.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2396-20200421074650-Veggie_Fries.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '570',
        //                   fat: '38g',
        //                   carbohydrates: '44g',
        //                   protein: '6g',
        //                   sodium: '700mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 15,
        //                         name: 'Peri Peri',
        //                         price: 0.4,
        //                         priceLabel: 'AUD 0.40',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|15',
        //                         description: 'Peri Peri',
        //                         min: 0,
        //                         max: 0,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 0,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 16,
        //                         name: 'Cheese Dip',
        //                         price: 0.4,
        //                         priceLabel: 'AUD 0.40',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|16',
        //                         description: 'Cheese Dip',
        //                         min: 0,
        //                         max: 0,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 0,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 19,
        //                     name: '',
        //                     description: '',
        //                     type: 2,
        //                     selectionLimit: 0,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|19',
        //                   },
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 39,
        //                         name: 'Coleslaw Salad',
        //                         price: 10.0,
        //                         priceLabel: 'AUD 10.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|39',
        //                         description: 'Coleslaw Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 40,
        //                         name: 'Sweetcorn Salad',
        //                         price: 9.0,
        //                         priceLabel: 'AUD 9.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|40',
        //                         description: 'Sweetcorn Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 41,
        //                         name: 'Potato Salad',
        //                         price: 11.0,
        //                         priceLabel: 'AUD 11.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|41',
        //                         description: 'Potato Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 42,
        //                         name: 'Curried Pasta Salad',
        //                         price: 8.0,
        //                         priceLabel: 'AUD 8.00',
        //                         sortOrder: 4,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|42',
        //                         description: 'Curried Pasta Salad',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 29,
        //                     name: 'Salad Base',
        //                     description: 'Salad Base',
        //                     type: 1,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|29',
        //                   },
        //                 ],
        //                 uOMId: 2084,
        //               },
        //               {
        //                 id: 'PUR2|3314260|2378',
        //                 name: 'Wedges',
        //                 description: 'Sidewinder wedges with sour cream & sweet chilli sauce',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Tasty Plates',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2378-20200421060953-Wedges.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2378-20200421074701-Wedges.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1000',
        //                   fat: '46g',
        //                   carbohydrates: '127g',
        //                   protein: '17g',
        //                   sodium: '2650mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 21,
        //                         name: 'Tuna',
        //                         price: 16.0,
        //                         priceLabel: 'AUD 16.00',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|21',
        //                         description: 'Tuna',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 22,
        //                         name: 'Tuna Light Mayo',
        //                         price: 11.0,
        //                         priceLabel: 'AUD 11.00',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|22',
        //                         description: 'Tuna Light Mayo',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                       {
        //                         valueId: 23,
        //                         name: 'Grated Cheese',
        //                         price: 14.0,
        //                         priceLabel: 'AUD 14.00',
        //                         sortOrder: 3,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|23',
        //                         description: 'Grated Cheese',
        //                         min: 0,
        //                         max: 1,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 1,
        //                         showPriceWay: 0,
        //                         firstFree: true,
        //                       },
        //                     ],
        //                     modifierId: 22,
        //                     name: 'Star Filling',
        //                     description: 'Star Filling',
        //                     type: 2,
        //                     selectionLimit: 1,
        //                     isRequired: true,
        //                     sourceSystemKey: 'ModifierCollection|22',
        //                   },
        //                 ],
        //                 uOMId: 2066,
        //               },
        //               {
        //                 id: 'PUR15|3314259|2391',
        //                 name: 'Spicy Popcorn Pork',
        //                 description: 'Spicy battered pork with sriracha hot sauce and fries',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Tasty Plates',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2391-20200421061003-Popcorn_Pork.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2391-20200421074715-Popcorn_Pork.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '830',
        //                   fat: '18g',
        //                   carbohydrates: '108g',
        //                   protein: '53g',
        //                   sodium: '3810mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2079,
        //               },
        //             ],
        //           },
        //           {
        //             id: 'Drinks',
        //             name: 'Drinks',
        //             items: [
        //               {
        //                 id: 'PLU2490|3314338|2178',
        //                 name: 'AUSSIE WATER 1.5L',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Water',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2178-20200319041206-Aussie_Natural_Water_1.5L.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2178-20200319044131-Aussie_Natural_Water_1.5L.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1865,
        //               },
        //               {
        //                 id: 'PLU3533|3314332|2069',
        //                 name: 'DARE DBL ESPRSSO ICED COFFEE 500ML',
        //                 description: '',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2069-20200319042441-Dare_Double_Espresso_500ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2069-20200319044727-Dare_Double_Espresso_500ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '420',
        //                   fat: '18g',
        //                   carbohydrates: '48g',
        //                   protein: '17g',
        //                   sodium: '200mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1756,
        //               },
        //               {
        //                 id: 'PLU4011|3314296|2048',
        //                 name: 'COCA COLA  600ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2048-20200319041619-Coca-Cola_Classic_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2048-20200319052132-Coca-Cola_Classic_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '260',
        //                   fat: '0g',
        //                   carbohydrates: '64g',
        //                   protein: '0g',
        //                   sodium: '60mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1735,
        //               },
        //               {
        //                 id: 'PLU3224|3314273|2046',
        //                 name: 'RED BULL 473ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2046-20201207174021-snapshotimagehandler_1395935961.jpeg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2046-20201207174029-snapshotimagehandler_1395935961.jpeg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '220',
        //                   fat: '0g',
        //                   carbohydrates: '51g',
        //                   protein: '0g',
        //                   sodium: '200mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1733,
        //               },
        //               {
        //                 id: 'PLU603|3314299|2061',
        //                 name: 'POWERADE BERRY ICE POWERFLOW 600ML',
        //                 description: '',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2061-20200319041447-Powerade_Berry_Ice_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2061-20200319044601-Powerade_Berry_Ice_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '150',
        //                   fat: '0g',
        //                   carbohydrates: '35g',
        //                   protein: '0g',
        //                   sodium: '170mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1748,
        //               },
        //               {
        //                 id: 'PLU19658|3314339|2067',
        //                 name: 'E&T GREEN POWER 350ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Juice',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2067-20200319044707-E_T_Green_Power_Smoothie_350ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2067-20200319044649-E_T_Green_Power_Smoothie_350ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '200',
        //                   fat: '1g',
        //                   carbohydrates: '42g',
        //                   protein: '3g',
        //                   sodium: '20mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1754,
        //               },
        //               {
        //                 id: 'PLU19659|3314340|2068',
        //                 name: 'E&T STRAIGHT OJ 350ML',
        //                 description: '',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Juice',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2068-20200319044709-E_T_Orange_Juice_350ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2068-20200319044645-E_T_Orange_Juice_350ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '150',
        //                   fat: '2g',
        //                   carbohydrates: '27g',
        //                   protein: '2g',
        //                   sodium: '0mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1755,
        //               },
        //               {
        //                 id: 'PLU604|3314281|2059',
        //                 name: 'POWERADE MOUNTAIN BLAST POWERFLOW 600ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2059-20200319041452-Powerade_Mountain_Blast_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2059-20200319044607-Powerade_Mountain_Blast_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '150',
        //                   fat: '0g',
        //                   carbohydrates: '35g',
        //                   protein: '0g',
        //                   sodium: '170mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1746,
        //               },
        //               {
        //                 id: 'PLU16567|3314300|2049',
        //                 name: 'COCA COLA  NO SUGAR 600ML',
        //                 description: '',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2049-20200319041623-Coca-Cola_No_Sugar_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2049-20200319052136-Coca-Cola_No_Sugar_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '0g',
        //                   carbohydrates: '<1g',
        //                   protein: '0g',
        //                   sodium: '25mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1736,
        //               },
        //               {
        //                 id: 'PLU176|3314276|2039',
        //                 name: 'RED BULL CANS 250ML',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2039-20200319041320-Redbull_250ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2039-20200319044455-Redbull_250ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '120',
        //                   fat: '0g',
        //                   carbohydrates: '27g',
        //                   protein: '0g',
        //                   sodium: '105mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1726,
        //               },
        //               {
        //                 id: 'PLU3532|3314333|2070',
        //                 name: 'DARE ESPRESSO ICED COFFEE 500ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2070-20200319042445-Dare_Espresso_500ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2070-20200319044729-Dare_Espresso_500ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '410',
        //                   fat: '18g',
        //                   carbohydrates: '45g',
        //                   protein: '17g',
        //                   sodium: '220mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1757,
        //               },
        //               {
        //                 id: 'PLU2492|3314335|2179',
        //                 name: 'AUSSIE WATER 600ML',
        //                 description: '',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Water',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2179-20200319041203-Aussie_Natura_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2179-20200319044133-Aussie_Natura_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1866,
        //               },
        //               {
        //                 id: 'PLU1629|3314279|2421',
        //                 name: 'MT FRANKLIN 600ML PET',
        //                 description: '',
        //                 price: '4.00',
        //                 displayPrice: '4.00',
        //                 priceInText: '',
        //                 category: 'Water',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2421-20200618011436-SODEXO_APP_720x540_P4-6.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2421-20200618011913-SODEXO_APP_720x540_P4-6.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2112,
        //               },
        //               {
        //                 id: 'PLU5064|3314275|2423',
        //                 name: '2 x RED BULL 473ML SUGAR FREE',
        //                 description: '',
        //                 price: '8.50',
        //                 displayPrice: '8.50',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2423-20200618011343-SODEXO_APP_720x540_P4-8.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2423-20200618011852-SODEXO_APP_720x540_P4-8.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2114,
        //               },
        //               {
        //                 id: 'PLU899|3314288|2071',
        //                 name: 'BROWNES CHILL COFFEE PERC 600ML',
        //                 description: '',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2071-20200319042528-Brownes_Percolated_Coffee_Chill_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2071-20200319044733-Brownes_Percolated_Coffee_Chill_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '320',
        //                   fat: '9g',
        //                   carbohydrates: '34g',
        //                   protein: '22g',
        //                   sodium: '220mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1758,
        //               },
        //               {
        //                 id: 'PLU1629|3314278|2037',
        //                 name: 'MT FRANKLIN 600ML PET',
        //                 description: '',
        //                 price: '0.00',
        //                 displayPrice: '0.00',
        //                 priceInText: '',
        //                 category: 'Water',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2037-20200319041229-Mount_Franklin_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2037-20200319044152-Mount_Franklin_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1724,
        //               },
        //               {
        //                 id: 'PLU253|3314280|2060',
        //                 name: 'POWERADE WALLABY GOLD RUSH POWERFLOW 600ML',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2060-20200319041455-Powerade_Gold_Rush_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2060-20200319044604-Powerade_Gold_Rush_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '150',
        //                   fat: '0g',
        //                   carbohydrates: '35g',
        //                   protein: '0g',
        //                   sodium: '170mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1747,
        //               },
        //               {
        //                 id: 'PLU5064|3314274|2047',
        //                 name: 'RED BULL S/FREE 473ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2047-20201207174152-snapshotimagehandler_1395935961.jpeg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2047-20201207174204-snapshotimagehandler_1395935961.jpeg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '15',
        //                   fat: '0g',
        //                   carbohydrates: '0g',
        //                   protein: '0g',
        //                   sodium: '190mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1734,
        //               },
        //               {
        //                 id: 'PLU436|3314291|2056',
        //                 name: 'COCA COLA 1.25L',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2056-20200319041627-Coca-Cola_Classic_1.25L.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2056-20200319052142-Coca-Cola_Classic_1.25L.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '540',
        //                   fat: '0g',
        //                   carbohydrates: '',
        //                   protein: '0g',
        //                   sodium: '125mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1743,
        //               },
        //               {
        //                 id: 'PLU17957|3314351|2064',
        //                 name: 'MAXIMUS GRAPE 1L',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2064-20200319041527-Maximus_Grape_1L.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2064-20200319044616-Maximus_Grape_1L.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '310',
        //                   fat: '1g',
        //                   carbohydrates: '74g',
        //                   protein: '1g',
        //                   sodium: '300mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1751,
        //               },
        //               {
        //                 id: 'PLU4010|3314292|2050',
        //                 name: 'COCA COLA 375ML CAN',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2050-20200319041633-Coca-Cola_Classic_375ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2050-20200319052146-Coca-Cola_Classic_375ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '160',
        //                   fat: '0g',
        //                   carbohydrates: '40g',
        //                   protein: '0g',
        //                   sodium: '40mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1737,
        //               },
        //               {
        //                 id: 'PLU177|3314277|2040',
        //                 name: 'RED BULL S/FREE CANS 250ML',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2040-20200319041335-Redbull_Sugar_Free_250ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2040-20200319044503-Redbull_Sugar_Free_250ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '5',
        //                   fat: '0g',
        //                   carbohydrates: '0g',
        //                   protein: '0g',
        //                   sodium: '105mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1727,
        //               },
        //               {
        //                 id: 'PLU4615|3314289|2073',
        //                 name: 'BROWNES CHILL DBLE ESPRESSO 600ML',
        //                 description: '',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2073-20200319042526-Brownes_Double_Espresso_Chill_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2073-20200319044736-Brownes_Double_Espresso_Chill_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '480',
        //                   fat: '22g',
        //                   carbohydrates: '51g',
        //                   protein: '19g',
        //                   sodium: '230mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1760,
        //               },
        //               {
        //                 id: 'PLU4010|3314293|2471',
        //                 name: 'CCA 375ML COKE',
        //                 description: '',
        //                 price: '2.00',
        //                 displayPrice: '2.00',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2471-20200819081103-SODEXO_CLICK___COLLECT_270x270_P5-10.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2471-20200819080859-SODEXO_CLICK___COLLECT_1120x1050_P5-10.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '160',
        //                   fat: '0g',
        //                   carbohydrates: '40g',
        //                   protein: '0g',
        //                   sodium: '40mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2162,
        //               },
        //               {
        //                 id: 'PLU17957|3314350|2492',
        //                 name: 'MAXIMUS GRAPE 1L ',
        //                 description: '',
        //                 price: '3.50',
        //                 displayPrice: '3.50',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2492-20200819082558-SODEXO_CLICK___COLLECT_270x270_P5-16.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2492-20200819082631-SODEXO_CLICK___COLLECT_1120x1050_P5-16.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '320',
        //                   fat: '0g',
        //                   carbohydrates: '18g',
        //                   protein: '0g',
        //                   sodium: '75mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2183,
        //               },
        //               {
        //                 id: 'PLU2350|3314352|2491',
        //                 name: 'MAXIMUS BLUE 1L ',
        //                 description: '',
        //                 price: '3.50',
        //                 displayPrice: '3.50',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2491-20200819082555-SODEXO_CLICK___COLLECT_270x270_P5-16.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2491-20200819082634-SODEXO_CLICK___COLLECT_1120x1050_P5-16.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '330',
        //                   fat: '0g',
        //                   carbohydrates: '19g',
        //                   protein: '0g',
        //                   sodium: '80mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2182,
        //               },
        //               {
        //                 id: 'PLU4002|3314287|2072',
        //                 name: 'BROWNES CHILL ICED COFFEE 600ML',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2072-20200319042532-Brownes_Iced_Coffee_Chill_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2072-20200319044807-Brownes_Iced_Coffee_Chill_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '450',
        //                   fat: '20g',
        //                   carbohydrates: '47g',
        //                   protein: '19g',
        //                   sodium: '230mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1759,
        //               },
        //               {
        //                 id: 'PLU2306|3314345|2041',
        //                 name: 'V BLUE 250ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2041-20200319041406-V_Engery_Drink_Blue_250ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2041-20200319044519-V_Engery_Drink_Blue_250ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1728,
        //               },
        //               {
        //                 id: 'PLU1587|3314286|2036',
        //                 name: 'MT/FRANK SPR/WTR NAT STIL 1.5L',
        //                 description: '',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Water',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2036-20200319041223-Mount_Franklin_1.5L.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2036-20200319044149-Mount_Franklin_1.5L.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1723,
        //               },
        //               {
        //                 id: 'PLU2350|3314353|2062',
        //                 name: 'MAXIMUS BLUE 1L',
        //                 description: '',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2062-20200319041530-Maximus_Blue_1L.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2062-20200319044618-Maximus_Blue_1L.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '310',
        //                   fat: '1g',
        //                   carbohydrates: '74g',
        //                   protein: '1g',
        //                   sodium: '300mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1749,
        //               },
        //               {
        //                 id: 'PLU443|3314295|2052',
        //                 name: 'COCA COLA DIET 600ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2052-20200319041638-Diet_Coca-Cola_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2052-20200319052151-Diet_Coca-Cola_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '0g',
        //                   carbohydrates: '<1g',
        //                   protein: '0g',
        //                   sodium: '90mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1739,
        //               },
        //               {
        //                 id: 'PLU601|3314297|2054',
        //                 name: 'COCA COLA FANTA 600ML',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2054-20200319041644-Fanta_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2054-20200319052154-Fanta_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '280',
        //                   fat: '1g',
        //                   carbohydrates: '68g',
        //                   protein: '<1g',
        //                   sodium: '5mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1741,
        //               },
        //               {
        //                 id: 'PLU2346|3314354|2063',
        //                 name: 'MAXIMUS RED 1L',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2063-20200319041534-Maximus_Red_1L.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2063-20200319044621-Maximus_Red_1L.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '310',
        //                   fat: '1g',
        //                   carbohydrates: '74g',
        //                   protein: '1g',
        //                   sodium: '300mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1750,
        //               },
        //               {
        //                 id: 'PLU2318|3314357|2044',
        //                 name: 'V BLUE 500ML',
        //                 description: '',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2044-20200319041409-V_Engery_Drink_Blue_500ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2044-20200319044521-V_Engery_Drink_Blue_500ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1731,
        //               },
        //               {
        //                 id: 'PLU4465|3314285|2077',
        //                 name: 'BARISTA BROS DBL ICE COFFEE 500ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2077-20200319042548-Barista_Bros_Double_Espresso_500ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2077-20200319044813-Barista_Bros_Double_Espresso_500ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '320',
        //                   fat: '8g',
        //                   carbohydrates: '50g',
        //                   protein: '12g',
        //                   sodium: '390mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1764,
        //               },
        //               {
        //                 id: 'PLU2318|3314356|2462',
        //                 name: 'V BLUE 500ML CAN ',
        //                 description: '',
        //                 price: '4.00',
        //                 displayPrice: '4.00',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2462-20200819080441-SODEXO_CLICK___COLLECT_270x270_P5-7.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2462-20200819080654-SODEXO_CLICK___COLLECT_1120x1050_P5-7.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '1220',
        //                   fat: '0g',
        //                   carbohydrates: '69g',
        //                   protein: '0g',
        //                   sodium: '250mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2153,
        //               },
        //               {
        //                 id: 'PLU2346|3314355|2490',
        //                 name: 'MAXIMUS RED 1L ',
        //                 description: '',
        //                 price: '3.50',
        //                 displayPrice: '3.50',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2490-20200819082551-SODEXO_CLICK___COLLECT_270x270_P5-16.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2490-20200819082638-SODEXO_CLICK___COLLECT_1120x1050_P5-16.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '330',
        //                   fat: '0g',
        //                   carbohydrates: '19g',
        //                   protein: '0g',
        //                   sodium: '80mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2181,
        //               },
        //               {
        //                 id: 'PLU5343|3314283|2078',
        //                 name: 'BARISTA BROS ICED CHOC 500ML',
        //                 description: '',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2078-20200319042554-Barista_Bros_Iced_Chocolate_500ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2078-20200319044818-Barista_Bros_Iced_Chocolate_500ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '390',
        //                   fat: '11g',
        //                   carbohydrates: '51g',
        //                   protein: '17g',
        //                   sodium: '360mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1765,
        //               },
        //               {
        //                 id: 'PLU3917|3314290|2066',
        //                 name: 'LUCOZADE ORIG BTL 380ML',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2066-20200319041556-Lucozade_Original_380ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2066-20200319044627-Lucozade_Original_380ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '280',
        //                   fat: '0g',
        //                   carbohydrates: '70g',
        //                   protein: '0g',
        //                   sodium: '5mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1753,
        //               },
        //               {
        //                 id: 'PLU2298|3314347|2042',
        //                 name: 'V GREEN 250ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2042-20200319041404-V_Energy_Drink_250ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2042-20200319044534-V_Energy_Drink_250ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1729,
        //               },
        //               {
        //                 id: 'PLU16565|3314301|2057',
        //                 name: 'COCA COLA NO SUGAR 1.25L',
        //                 description: '',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2057-20200319041650-Coca-Cola_No_Sugar_1.25L.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2057-20200319052158-Coca-Cola_No_Sugar_1.25L.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '0g',
        //                   carbohydrates: '1g',
        //                   protein: '0g',
        //                   sodium: '50mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1744,
        //               },
        //               {
        //                 id: 'PLU15695|3314331|2065',
        //                 name: 'BEROCCA TWIST DRINK ORIG 250ML',
        //                 description: '',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Sports Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2065-20200319041558-Berocca_Twist_Go_Original.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2065-20200319044631-Berocca_Twist_Go_Original.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '15',
        //                   fat: '1g',
        //                   carbohydrates: '2g',
        //                   protein: '<1g',
        //                   sodium: '270mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1752,
        //               },
        //               {
        //                 id: 'PLU2310|3314358|2045',
        //                 name: 'V GREEN 500ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2045-20200319041417-V_Energy_Drink_500ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2045-20200319044530-V_Energy_Drink_500ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1732,
        //               },
        //               {
        //                 id: 'PLU16563|3314302|2051',
        //                 name: 'COCA COLA NO SUGAR 375ML CAN',
        //                 description: '',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2051-20200319041655-Coca-Cola_No_Sugar_375ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2051-20200319052203-Coca-Cola_No_Sugar_375ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '0g',
        //                   carbohydrates: '0g',
        //                   protein: '0g',
        //                   sodium: '15mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1738,
        //               },
        //               {
        //                 id: 'PLU4464|3314284|2076',
        //                 name: 'BARISTA BROS ICED COFFEE 500ML',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2076-20200319042558-Barista_Bros_Iced_Coffee_500_ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2076-20200319044825-Barista_Bros_Iced_Coffee_500_ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '260',
        //                   fat: '8g',
        //                   carbohydrates: '35g',
        //                   protein: '12g',
        //                   sodium: '350mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1763,
        //               },
        //               {
        //                 id: 'PLU2310|3314359|2460',
        //                 name: 'V GREEN 500ML CAN',
        //                 description: '',
        //                 price: '4.00',
        //                 displayPrice: '4.00',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2460-20200819080422-SODEXO_CLICK___COLLECT_270x270_P5-7.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2460-20200819080649-SODEXO_CLICK___COLLECT_1120x1050_P5-7.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '980',
        //                   fat: '0g',
        //                   carbohydrates: '53g',
        //                   protein: '0g',
        //                   sodium: '500mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2151,
        //               },
        //               {
        //                 id: 'PLU16563|3314303|2472',
        //                 name: 'CCA 375ML COKE NO SUGAR',
        //                 description: '',
        //                 price: '2.00',
        //                 displayPrice: '2.00',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2472-20200819081100-SODEXO_CLICK___COLLECT_270x270_P5-10.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2472-20200819080907-SODEXO_CLICK___COLLECT_1120x1050_P5-10.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '5',
        //                   fat: '0g',
        //                   carbohydrates: '0g',
        //                   protein: '0g',
        //                   sodium: '15mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2163,
        //               },
        //               {
        //                 id: 'PLU16456|3314349|2075',
        //                 name: 'V ICED CHOC 500ML',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2075-20200319042616-V_Iced_Chocolate_500ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2075-20200319044843-V_Iced_Chocolate_500ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1762,
        //               },
        //               {
        //                 id: 'PLU2300|3314346|2043',
        //                 name: 'V SUGAR FREE 250ML',
        //                 description: '',
        //                 price: '0.20',
        //                 displayPrice: '0.20',
        //                 priceInText: '',
        //                 category: 'Energy Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2043-20200319041424-V_Energy_Drink_Sugarfree_250ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2043-20200319044548-V_Energy_Drink_Sugarfree_250ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1730,
        //               },
        //               {
        //                 id: 'PLU481|3314294|2058',
        //                 name: 'COCA COLA SPRITE 1.25L',
        //                 description: '',
        //                 price: '0.10',
        //                 displayPrice: '0.10',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2058-20200319041708-Sprite_1.25L.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2058-20200319052210-Sprite_1.25L.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '530',
        //                   fat: '0g',
        //                   carbohydrates: '126g',
        //                   protein: '0g',
        //                   sodium: '140mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1745,
        //               },
        //               {
        //                 id: 'PLU469|3314298|2053',
        //                 name: 'COCA COLA SPRITE 600ML',
        //                 description: '',
        //                 price: '23.68',
        //                 displayPrice: '23.68',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2053-20200319041713-Sprite_600ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2053-20200319052214-Sprite_600ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '250',
        //                   fat: '0g',
        //                   carbohydrates: '61g',
        //                   protein: '0g',
        //                   sodium: '70mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1740,
        //               },
        //               {
        //                 id: 'PLU4559|3314348|2074',
        //                 name: 'V ICED COFFEE 500ML',
        //                 description: '',
        //                 price: '0.40',
        //                 displayPrice: '0.40',
        //                 priceInText: '',
        //                 category: 'Dairy',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2074-20200319042618-V_Iced_Coffee_Double_Espresso_500ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2074-20200319044845-V_Iced_Coffee_Double_Espresso_500ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1761,
        //               },
        //               {
        //                 id: 'PLU90712|3314337|2465',
        //                 name: 'B/BERG BRWD DRK G/BEER   375ML',
        //                 description: '',
        //                 price: '2.50',
        //                 displayPrice: '2.50',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2465-20200819081108-SODEXO_CLICK___COLLECT_270x270_P5-8.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2465-20200819080847-SODEXO_CLICK___COLLECT_1120x1050_P5-8.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '680',
        //                   fat: '0g',
        //                   carbohydrates: '',
        //                   protein: '0g',
        //                   sodium: '40mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2156,
        //               },
        //               {
        //                 id: 'PLU15638|3314336|2055',
        //                 name: 'B/BERG GINGER BEER 375ML',
        //                 description: '',
        //                 price: '3.60',
        //                 displayPrice: '3.60',
        //                 priceInText: '',
        //                 category: 'Soft Drinks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2055-20200319042411-Bundaberg_Ginger_Beer_375ml.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2055-20200319052227-Bundaberg_Ginger_Beer_375ml.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '160',
        //                   fat: '0g',
        //                   carbohydrates: '40g',
        //                   protein: '0g',
        //                   sodium: '40mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1742,
        //               },
        //             ],
        //           },
        //           {
        //             id: 'Snack Food',
        //             name: 'Snack Food',
        //             items: [
        //               {
        //                 id: 'PLU17814|3314344|2080',
        //                 name: 'KUDITJ CAMP FIRE SMOKEY JERKY 35G',
        //                 description: '',
        //                 price: '6.00',
        //                 displayPrice: '6.00',
        //                 priceInText: '',
        //                 category: 'Jerky',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2080-20200319042640-Jerky_Bag_35g_-_Camp_Fire.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2080-20200319044907-Jerky_Bag_35g_-_Camp_Fire.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '100',
        //                   fat: '2g',
        //                   carbohydrates: '2g',
        //                   protein: '19g',
        //                   sodium: '440mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Fish',
        //                     contains: true,
        //                     allergen: 8,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1767,
        //               },
        //               {
        //                 id: 'PLU5370|3314317|2090',
        //                 name: 'SMITHS BBQ CRINKLE 170G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2090-20200319042724-Smiths_Crinkle_Cut_BBQ_170g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2090-20200319045602-Smiths_Crinkle_Cut_BBQ_170g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '910',
        //                   fat: '56g',
        //                   carbohydrates: '88g',
        //                   protein: '11g',
        //                   sodium: '840mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1777,
        //               },
        //               {
        //                 id: 'PLU14826|3314329|2095',
        //                 name: 'NOBBYS CASHEWS 50G',
        //                 description: '',
        //                 price: '4.40',
        //                 displayPrice: '4.40',
        //                 priceInText: '',
        //                 category: 'Nuts',
        //                 imageId: '',
        //                 listImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2095-20200319043019-Nobby's_Salted_Cashews_50g.jpg",
        //                 detailImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2095-20200319050309-Nobby's_Salted_Cashews_50g.jpg",
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '290',
        //                   fat: '26g',
        //                   carbohydrates: '8g',
        //                   protein: '10g',
        //                   sodium: '240mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Treenuts',
        //                     contains: true,
        //                     allergen: 3,
        //                   },
        //                   {
        //                     name: 'Peanut',
        //                     contains: true,
        //                     allergen: 4,
        //                   },
        //                   {
        //                     name: 'Nuts',
        //                     contains: true,
        //                     allergen: 15,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Sesame',
        //                     contains: true,
        //                     allergen: 16,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1782,
        //               },
        //               {
        //                 id: 'PLU15500|3314312|2101',
        //                 name: 'SMITHS BBQ CRINKL 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2101-20200319042726-Smiths_Crinkle_Cut_BBQ_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2101-20200319045604-Smiths_Crinkle_Cut_BBQ_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '480',
        //                   fat: '29g',
        //                   carbohydrates: '46g',
        //                   protein: '6g',
        //                   sodium: '440mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1788,
        //               },
        //               {
        //                 id: 'PLU14817|3314330|2093',
        //                 name: 'NOBBYS CASHEWS SALT PKT 150G',
        //                 description: '',
        //                 price: '10.00',
        //                 displayPrice: '10.00',
        //                 priceInText: '',
        //                 category: 'Nuts',
        //                 imageId: '',
        //                 listImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2093-20200319043026-Nobby's_Salted_Cashews_150g.jpg",
        //                 detailImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2093-20200319050002-Nobby's_Salted_Cashews_150g.jpg",
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '910',
        //                   fat: '77g',
        //                   carbohydrates: '24g',
        //                   protein: '30g',
        //                   sodium: '720mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Treenuts',
        //                     contains: true,
        //                     allergen: 3,
        //                   },
        //                   {
        //                     name: 'Peanut',
        //                     contains: true,
        //                     allergen: 4,
        //                   },
        //                   {
        //                     name: 'Nuts',
        //                     contains: true,
        //                     allergen: 15,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Sesame',
        //                     contains: true,
        //                     allergen: 16,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1780,
        //               },
        //               {
        //                 id: 'PLU17813|3314343|2082',
        //                 name: 'KUDITJ HONEY BEE HONEY JERKY 35G',
        //                 description: '',
        //                 price: '6.00',
        //                 displayPrice: '6.00',
        //                 priceInText: '',
        //                 category: 'Jerky',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2082-20200319042642-Jerky_Bag_35g_-_Honey_Bee.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2082-20200319044910-Jerky_Bag_35g_-_Honey_Bee.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '110',
        //                   fat: '2g',
        //                   carbohydrates: '5g',
        //                   protein: '18g',
        //                   sodium: '420mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Fish',
        //                     contains: true,
        //                     allergen: 8,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1769,
        //               },
        //               {
        //                 id: 'PLU842672|3314313|2444',
        //                 name: 'SMITHS CRINKL BBQ         90GM',
        //                 description: '',
        //                 price: '2.75',
        //                 displayPrice: '2.75',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2444-20200819081713-SODEXO_CLICK___COLLECT_270x270_P5-4.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2444-20200819081607-SODEXO_CLICK___COLLECT_1120x1050_P5-4.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2240',
        //                   fat: '33g',
        //                   carbohydrates: '52g',
        //                   protein: '7g',
        //                   sodium: '500mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2135,
        //               },
        //               {
        //                 id: 'PLU17811|3314342|2079',
        //                 name: 'KUDITJ KICKING COW CHILLI JERKY 35G',
        //                 description: '',
        //                 price: '6.00',
        //                 displayPrice: '6.00',
        //                 priceInText: '',
        //                 category: 'Jerky',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2079-20200319042646-Jerky_Bag_35g_-_Kicking_Cow.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2079-20200319044912-Jerky_Bag_35g_-_Kicking_Cow.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '110',
        //                   fat: '3g',
        //                   carbohydrates: '2g',
        //                   protein: '19g',
        //                   sodium: '440mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Fish',
        //                     contains: true,
        //                     allergen: 8,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1766,
        //               },
        //               {
        //                 id: 'PLU14837|3314328|2094',
        //                 name: 'NOBBYS PEANUTS SALTED 50G',
        //                 description: '',
        //                 price: '2.50',
        //                 displayPrice: '2.50',
        //                 priceInText: '',
        //                 category: 'Nuts',
        //                 imageId: '',
        //                 listImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2094-20200319043031-Nobby's_Salted_Peanuts_50g.jpg",
        //                 detailImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2094-20200319050312-Nobby's_Salted_Peanuts_50g.jpg",
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '290',
        //                   fat: '24g',
        //                   carbohydrates: '20g',
        //                   protein: '15g',
        //                   sodium: '',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Treenuts',
        //                     contains: true,
        //                     allergen: 3,
        //                   },
        //                   {
        //                     name: 'Peanut',
        //                     contains: true,
        //                     allergen: 4,
        //                   },
        //                   {
        //                     name: 'Nuts',
        //                     contains: true,
        //                     allergen: 15,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                   {
        //                     name: 'Sesame',
        //                     contains: true,
        //                     allergen: 16,
        //                   },
        //                   {
        //                     name: 'Gluten',
        //                     contains: true,
        //                     allergen: 11,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1781,
        //               },
        //               {
        //                 id: 'PLU19020|3314319|2092',
        //                 name: 'SMITHS CHICKEN CRINK 170G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2092-20200319042738-Smith's_Crinkle_Cut_Chicken_170g.jpg",
        //                 detailImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2092-20200319045613-Smith's_Crinkle_Cut_Chicken_170g.jpg",
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '910',
        //                   fat: '56g',
        //                   carbohydrates: '88g',
        //                   protein: '12g',
        //                   sodium: '840mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1779,
        //               },
        //               {
        //                 id: 'PLU17812|3314341|2081',
        //                 name: 'KUDITJ RIDGEY DIDGE PEPPER JERKY 35G',
        //                 description: '',
        //                 price: '6.00',
        //                 displayPrice: '6.00',
        //                 priceInText: '',
        //                 category: 'Jerky',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2081-20200319042650-Jerky_Bag_35g_-_Ridgey_Didge.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2081-20200319044915-Jerky_Bag_35g_-_Ridgey_Didge.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '120',
        //                   fat: '2g',
        //                   carbohydrates: '4g',
        //                   protein: '20g',
        //                   sodium: '570mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Fish',
        //                     contains: true,
        //                     allergen: 8,
        //                   },
        //                   {
        //                     name: 'Wheat',
        //                     contains: true,
        //                     allergen: 6,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1768,
        //               },
        //               {
        //                 id: 'PLU842656|3314306|2445',
        //                 name: 'SMITHS CRINKL CHICKEN     90GM',
        //                 description: '',
        //                 price: '2.75',
        //                 displayPrice: '2.75',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl: '',
        //                 detailImageUrl: '',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2240',
        //                   fat: '33g',
        //                   carbohydrates: '52g',
        //                   protein: '7g',
        //                   sodium: '510mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2136,
        //               },
        //               {
        //                 id: 'PLU15501|3314307|2100',
        //                 name: 'SMITHS CHICKEN CRINKL 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2100-20200319042741-Smiths_Chicken_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2100-20200319045610-Smiths_Chicken_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '480',
        //                   fat: '29g',
        //                   carbohydrates: '46g',
        //                   protein: '6g',
        //                   sodium: '440mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1787,
        //               },
        //               {
        //                 id: 'PLU5371|3314318|2089',
        //                 name: 'SMITHS CHSE & ONION CRINK 170G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2089-20200319042746-Smiths_Crinkle_Cut_Cheese_Onion_170g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2089-20200319045630-Smiths_Crinkle_Cut_Cheese_Onion_170g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '920',
        //                   fat: '57g',
        //                   carbohydrates: '87g',
        //                   protein: '12g',
        //                   sodium: '700mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1776,
        //               },
        //               {
        //                 id: 'PLU15502|3314311|2098',
        //                 name: 'SMITHS CRINKL CHSE/ONION  90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2098-20200319042749-Smiths_Crinkle_Cut_Cheese_Onion_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2098-20200319045633-Smiths_Crinkle_Cut_Cheese_Onion_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '480',
        //                   fat: '30g',
        //                   carbohydrates: '46g',
        //                   protein: '6g',
        //                   sodium: '370mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1785,
        //               },
        //               {
        //                 id: 'PLU842664|3314310|2443',
        //                 name: 'SMITHS CRINKL CHSE/ONION  90GM',
        //                 description: '',
        //                 price: '2.75',
        //                 displayPrice: '2.75',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2443-20200819081716-SODEXO_CLICK___COLLECT_270x270_P5-4.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2443-20200819081612-SODEXO_CLICK___COLLECT_1120x1050_P5-4.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2260',
        //                   fat: '34g',
        //                   carbohydrates: '51g',
        //                   protein: '8g',
        //                   sodium: '400mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2134,
        //               },
        //               {
        //                 id: 'PLU5372|3314320|2091',
        //                 name: 'SMITHS ORIG CRINK  170G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2091-20200319042800-Smith's_Crinkle_Cut_Original_170g.jpg",
        //                 detailImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2091-20200319045656-Smith's_Crinkle_Cut_Original_170g.jpg",
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '930',
        //                   fat: '59g',
        //                   carbohydrates: '86g',
        //                   protein: '11g',
        //                   sodium: '940mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1778,
        //               },
        //               {
        //                 id: 'PLU842630|3314305|2440',
        //                 name: 'SMITHS CRINKL ORIG        90GM',
        //                 description: '',
        //                 price: '2.75',
        //                 displayPrice: '2.75',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2440-20200819081702-SODEXO_CLICK___COLLECT_270x270_P5-4.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2440-20200819081553-SODEXO_CLICK___COLLECT_1120x1050_P5-4.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2290',
        //                   fat: '35g',
        //                   carbohydrates: '51g',
        //                   protein: '7g',
        //                   sodium: '560mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2131,
        //               },
        //               {
        //                 id: 'PLU15503|3314304|2099',
        //                 name: 'SMITHS ORIG CRINKL 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2099-20200319042802-Smith's_Crinkle_Cut_Original_90g.jpg",
        //                 detailImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2099-20200319045658-Smith's_Crinkle_Cut_Original_90g.jpg",
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '490',
        //                   fat: '31g',
        //                   carbohydrates: '45g',
        //                   protein: '6g',
        //                   sodium: '500mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1786,
        //               },
        //               {
        //                 id: 'PLU842648|3314309|2439',
        //                 name: 'SMITHS CRINKL SLT/VIN     90GM',
        //                 description: '',
        //                 price: '2.75',
        //                 displayPrice: '2.75',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2439-20200819081659-SODEXO_CLICK___COLLECT_270x270_P5-4.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2439-20200819081548-SODEXO_CLICK___COLLECT_1120x1050_P5-4.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '2230',
        //                   fat: '33g',
        //                   carbohydrates: '50g',
        //                   protein: '6g',
        //                   sodium: '800mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 2130,
        //               },
        //               {
        //                 id: 'PLU15504|3314308|2097',
        //                 name: 'SMITHS SALT & VIN CRINK 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2097-20200319042811-Smith's_Crinkle_Cut_Salt_Vinegar_90g.jpg",
        //                 detailImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2097-20200319045706-Smith's_Crinkle_Cut_Salt_Vinegar_90g.jpg",
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '470',
        //                   fat: '29g',
        //                   carbohydrates: '45g',
        //                   protein: '6g',
        //                   sodium: '710mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1784,
        //               },
        //               {
        //                 id: 'PLU5373|3314321|2088',
        //                 name: 'SMITHS SALT & VIN CRINKL 170G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2088-20200319042808-Smith's_Crinkle_Cut_Salt_Vinegar_170g.jpg",
        //                 detailImageUrl:
        //                   "https://sodexoitzstageau.blob.core.windows.net/fooditem/2088-20200319045704-Smith's_Crinkle_Cut_Salt_Vinegar_170g.jpg",
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '910',
        //                   fat: '56g',
        //                   carbohydrates: '85g',
        //                   protein: '11g',
        //                   sodium: '1350mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1775,
        //               },
        //               {
        //                 id: 'PLU20341|3314325|2106',
        //                 name: 'CHEETOS CHEESE&BAC BALLS 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2106-20200319042828-Cheetos_Balls_Cheese_Bacon_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2106-20200319045726-Cheetos_Balls_Cheese_Bacon_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '460',
        //                   fat: '30g',
        //                   carbohydrates: '43g',
        //                   protein: '5g',
        //                   sodium: '610mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1793,
        //               },
        //               {
        //                 id: 'PLU15492|3314334|2105',
        //                 name: 'CHEEZELS CHEESE 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2105-20200319042837-Cheezels_Cheese_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2105-20200319045729-Cheezels_Cheese_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '480',
        //                   fat: '28g',
        //                   carbohydrates: '51g',
        //                   protein: '6g',
        //                   sodium: '1080mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1792,
        //               },
        //               {
        //                 id: 'PLU5367|3314322|2086',
        //                 name: 'DORITOS NACHO CHEESE 170G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2086-20200319042848-Doritos_Nacho_Cheese_170g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2086-20200319045742-Doritos_Nacho_Cheese_170g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '880',
        //                   fat: '46g',
        //                   carbohydrates: '100g',
        //                   protein: '15g',
        //                   sodium: '970mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1773,
        //               },
        //               {
        //                 id: 'PLU5368|3314323|2085',
        //                 name: 'DORITOS SUPREME CHEESE 170G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2085-20200319042849-Doritos_Cheese_Supreme_170g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2085-20200319045742-Doritos_Cheese_Supreme_170g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '880',
        //                   fat: '46g',
        //                   carbohydrates: '100g',
        //                   protein: '15g',
        //                   sodium: '970mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1772,
        //               },
        //               {
        //                 id: 'PLU20342|3314327|2102',
        //                 name: 'TWISTIES CHEESE 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2102-20200319042858-Twisties_Cheese_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2102-20200319045754-Twisties_Cheese_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1789,
        //               },
        //               {
        //                 id: 'PLU15495|3314314|2096',
        //                 name: 'RRD HONEY SOY CHICKEN 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2096-20200319042907-Red_Rock_Deli_Potato_Chips_Honey_Soy_Chicken_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2096-20200319045801-Red_Rock_Deli_Potato_Chips_Honey_Soy_Chicken_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '440',
        //                   fat: '21g',
        //                   carbohydrates: '54g',
        //                   protein: '7g',
        //                   sodium: '470mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1783,
        //               },
        //               {
        //                 id: 'PLU15507|3314326|2103',
        //                 name: 'TWISTIES CHICKEN 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2103-20200319042903-Twisties_Chicken_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2103-20200319045756-Twisties_Chicken_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '',
        //                   fat: '',
        //                   carbohydrates: '',
        //                   protein: '',
        //                   sodium: '',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1790,
        //               },
        //               {
        //                 id: 'PLU15448|3314315|2084',
        //                 name: 'RRD SEA SALT 165G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2084-20200319042913-Red_Rock_Deli_Potato_Chips_Sea_Salt_165g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2084-20200319045806-Red_Rock_Deli_Potato_Chips_Sea_Salt_165g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '800',
        //                   fat: '38g',
        //                   carbohydrates: '100g',
        //                   protein: '13g',
        //                   sodium: '840mg',
        //                 },
        //                 allergens: [
        //                   {
        //                     name: 'Milk',
        //                     contains: true,
        //                     allergen: 1,
        //                   },
        //                   {
        //                     name: 'Soybean',
        //                     contains: true,
        //                     allergen: 7,
        //                   },
        //                 ],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1771,
        //               },
        //               {
        //                 id: 'PLU15445|3314316|2083',
        //                 name: 'RRD SOUR CRM & SWT CHILL 165G',
        //                 description: '',
        //                 price: '5.10',
        //                 displayPrice: '5.10',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2083-20200319042918-Red_Rock_Deli_Potato_Chips_Sweet_Chilli_Sour_Cream_165g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2083-20200319045808-Red_Rock_Deli_Potato_Chips_Sweet_Chilli_Sour_Cream_165g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '800',
        //                   fat: '38g',
        //                   carbohydrates: '99g',
        //                   protein: '13g',
        //                   sodium: '1130mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1770,
        //               },
        //               {
        //                 id: 'PLU20340|3314324|2104',
        //                 name: 'BURGER RINGS 90G',
        //                 description: '',
        //                 price: '3.70',
        //                 displayPrice: '3.70',
        //                 priceInText: '',
        //                 category: 'Chips',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2104-20200319042927-Burger_Rings_90g.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/2104-20200319045826-Burger_Rings_90g.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '470',
        //                   fat: '25g',
        //                   carbohydrates: '54g',
        //                   protein: '6g',
        //                   sodium: '860mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [],
        //                 uOMId: 1791,
        //               },
        //             ],
        //           },
        //           {
        //             id: '$5 Deals',
        //             name: '$5 Deals',
        //             items: [
        //               {
        //                 id: 'PLU|3314360|3219',
        //                 name: 'Chicken Garlic Balls',
        //                 description: '',
        //                 price: '5.00',
        //                 displayPrice: '5.00',
        //                 priceInText: '',
        //                 category: 'Snacks',
        //                 imageId: '',
        //                 listImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/3219-20201030064817-270_p_garlic_balls.jpg',
        //                 detailImageUrl:
        //                   'https://sodexoitzstageau.blob.core.windows.net/fooditem/3219-20201030064822-1120p_garlic_balls.jpg',
        //                 foodSubCategoryDescription: '',
        //                 nutritionFacts: {
        //                   calories: '590',
        //                   fat: '42g',
        //                   carbohydrates: '27g',
        //                   protein: '22g',
        //                   sodium: '940mg',
        //                 },
        //                 allergens: [],
        //                 isOrderable: true,
        //                 modifiers: [
        //                   {
        //                     values: [
        //                       {
        //                         valueId: 7,
        //                         name: 'Mustard',
        //                         price: 0.0,
        //                         priceLabel: '',
        //                         sortOrder: 1,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|7',
        //                         description: 'Mustard',
        //                         min: 1,
        //                         max: 3,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 3,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                       {
        //                         valueId: 8,
        //                         name: 'Mint',
        //                         price: 0.0,
        //                         priceLabel: '',
        //                         sortOrder: 2,
        //                         sourceSystemKey: 'BiteFoodItemModifierItem|8',
        //                         description: 'Mint',
        //                         min: 1,
        //                         max: 4,
        //                         selected: false,
        //                         quantity: 0,
        //                         storedFoodItemModifierValueId: 0,
        //                         tax: 0.0,
        //                         isDefault: false,
        //                         maximumQuantity: 4,
        //                         showPriceWay: 0,
        //                         firstFree: false,
        //                       },
        //                     ],
        //                     modifierId: 15,
        //                     name: '',
        //                     description: '',
        //                     type: 2,
        //                     selectionLimit: 2,
        //                     isRequired: false,
        //                     sourceSystemKey: 'ModifierCollection|15',
        //                   },
        //                 ],
        //                 uOMId: 2911,
        //               },
        //             ],
        //           },
        //         ],
        //         dayofweek: 'Tuesday',
        //         date: '2021-08-03T00:00:00',
        //       },
        //     ],
        //     pickupspotreferenceid: '103',
        //   },
        // ],
      }),
  },
};

export default mockData;
