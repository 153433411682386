export enum SERVICE_REQUEST_STATUS {
  PENDING = 'pending',
  CLOSED = 'closed',
  OPEN = 'open',
}

export type ServiceRequestStatusProps = {
  status: SERVICE_REQUEST_STATUS;
  displayStatus: string;
};
