import { RouteComponentProps } from 'react-router';

import { REACTIONS, ReactionsAPIType } from '../../types/reactions.types';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';
export interface ContentState {
  data?: Partial<GETContentDetailsResponse>;
  list: IContent[];
  locks: {
    getList: boolean;
    getOne: boolean;
    postReaction: boolean;
  };
}

export type ContentImageType = {
  id: string;
  fileSize: string;
  mimeType: string;
  content: string;
  fileName: string;
};

export enum IContentType {
  NORMAL = 'Normal',
  PROMOTIONAL = 'Promotional',
  FEATURED = 'Featured',
  INMOMENT_SURVEY = 'Inmoment-Survey',
}
export interface IContent {
  id: string;
  details: string;
  expiryDate: string;
  type: IContentType;
  publishedDate: string;
  title: string;
  images: string[] | ContentImageType[];
  preferences: string[];
  link: { url: string; text: string };
  linkedEntity: string;
  linkedEntityId: string;
  linkedEntityUrl: string;
  languageCode: string;
  matchUserPreferenceCount: number;
  score: string;
  mainImageId?: string;
  reactions: ReactionsAPIType;
  imgInfo?: {
    src: string;
    alt: string;
  };
  markdownDecoded?: boolean;
  markdown?: string;
}

export type GETContentDetailsResponse = {
  responseData: IContent;
  responseStatus: number;
};

type MyVillageGetOneRequestType = {
  siteId: string;
};

export type GetOneRequestType = {
  id: string;
} & MyVillageGetOneRequestType;

export type ContentWidgetProps = RouteComponentProps<never>;

export type ContentWidgetState = { articleImages: Record<string, string> };

export type WidgetTile = { normal: any[]; promo: any[] }; // TODO refactor and good luck ;-)

export type TrackContentViewsProps = {
  title: string;
  site: { id: string; name: string };
  contract: { id: string; name: string };
  language: string;
  moduleId: string;
};

export type ContentConfigType = {
  api: typeof baseApi | typeof dspApi;
  baseUrl: string;
  gethomeUrl: string;
  contentsUrl: (id: string) => string;
  reactionUrl: (id: string) => string;
  getHomeParams: (
    startDate: string,
    endDate: string,
    siteId: string,
    languageCode: string,
    contractId?: string
  ) => {
    siteId: string;
    startDate?: string;
    endDate?: string;
    languageCode?: string;
    contractId?: string;
  };
  getContentParams: (id: string, siteId: string) => { id: string; siteId: string } | undefined;
  updateReactionParams: (
    id: string,
    reactionType: REACTIONS | null
  ) => { id: string; reactionType: REACTIONS | null } | { reactionType: REACTIONS | null };
  deleteContentParams: (id: string) => { id: string } | undefined;
  isReactionsEnabled: boolean;
  isCategoryEnabled: boolean;
  promotionsWidgetTitle: string;
};
