const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Запрос на проживание',
    'Ref: Describe your request': 'Опишите ваш запрос',
    'Ref: Confirm your request': 'Подтвердите свой запрос',
    'Ref: Your request': 'Ваш запрос',
    'Ref: Summary': 'Краткое содержание',
    'Ref: Next': 'Следующий',
    'Ref: Submit': 'Представлять на рассмотрение',
    'Ref: Me': 'Мне',
    'Ref: Arrival date': 'Дата прибытия',
    'Ref: Arrival time': 'Время прибытия',
    'Ref: Departure date': 'Дата отбытия',
    'Ref: Departure time': 'Время отправления',
    'Ref: request raised for': 'Лицо, затронутое этим запросом',
    'Ref: phoneNumber': 'Номер телефона',
    'Ref: arrivalDate': 'Прибытие',
    'Ref: departureDate': 'Отправление',
    'Ref: description': 'Комментарий',
    'Ref: open': 'Открыть',
    'Ref: pending': 'В ожидании',
    'Ref: refused': 'Отказался',
    'Ref: closed': 'Закрыто',
    'Ref: all': 'Все',
    'Ref: Arrival': 'Прибытие',
    'Ref: Validate': 'Проверять',
    'Ref: site': 'Местонахождение площадки',
    'Ref: Update us': 'Обновить нас',
    'Ref: email of guest': 'Электронная почта гостя',
    'Ref: GuestAccommodationRequestTitle': 'Гостевой запрос',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Новый запрос',
    'Ref: Status': 'Положение дел',
    'Ref: Date': 'Дата',
    'Ref: Coming arrivals': 'Прибывшие прибытия',
    'Ref: Past arrivals': 'Прошлые прибытия',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Номер телефона гостя',
    'Ref: Your phone number': 'Ваш номер телефона',
    'Ref: The phone number': 'Телефонный номер',
    'Ref: PhoneNumber error msg': 'Номер телефона не действителен',
    'Ref: Comment error msg': 'Максимальное количество символов - 250',
    'Ref: Guest first name': 'Гость имя',
    'Ref: Guest last name': 'Гость фамилия',
    'Ref: request for': 'Для кого этот запрос?',
    'Ref: request for me': 'Мне',
    'Ref: request for guest': 'Кто-нибудь другой',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Подробная информация о проживании',
    'Ref: Timeline': 'График',
    'Ref: No updates placeholder': 'Для этого запроса нет обновлений',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Спасибо',
    'Ref: Success text': 'Мы получили ваш запрос и сейчас изучаем его. Мы скоро вернусь к вам.',
    'Ref: Go to home': 'Иди домой',
    'Ref: Create another request': 'Создайте еще один запрос',
  },
  CreateError: {
    'Ref: Create error title': 'Уп!',
    'Ref: Create error text': 'Произошла ошибка, попробуйте еще раз.',
    'Ref: Retry': 'Попробуйте еще раз',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Пожалуйста, обновите нас с любым новым элементом вашего запроса, чтобы мы могли наилучшим образом поддержать вас.',
  },
};
export default labels;
