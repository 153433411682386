const labels = {
  All: {
    'status: all': 'Všechno',
    'status: open': 'OTEVŘENO',
    'status: pending': 'čekající',
    'status: closed': 'ZAVŘENO',
    Location: 'Umístění',
    Subject: 'Předmět',
    Description: 'Vaše podrobný požadavek',
    'Ref: description field label': 'Popis',
    'Ref: Title': 'Titul',
    phone_number: 'Vaše telefonní číslo',
    request_category: 'Kategorie požadavku',
    Attachments: 'Přílohy',
    'request service': 'Žádost o službu',
    'New service request': 'Vytvořte další požadavek',
    'Go to home': 'Jít domů',
    'Back to this service request': 'Zpět na tuto žádost o službu',
    'Back to service requests': 'Zpět na požadavky na služby',
    Feedback: 'Zpětná vazba',
    'Ref: Request category': 'Kategorie',
    Cleaning: 'Čištění',
    'Laundry services': 'Prádelna',
    'Grounds maintenance': 'Údržba pozemků',
    'Soft services': 'Měkké služby',
    'Hard services': 'Tvrdé služby',
    'Pest control services': 'Služby kontroly škůdců',
    'Waste management': 'Nakládání s odpady',
    'Asset Lifecycle Services': 'Služby životního cyklu aktiv',
    'There are no updates for this request': 'Pro tento požadavek neexistují žádné aktualizace',
    'Transportation Services': 'Dopravní služba',
    'Ref: you pronoun': '(vy)',
    'Ref: SR raised for': 'Postižená osoba touto žádostí',
    'Ref: Select a location': 'Vyberte umístění',
    'Ref: Your request': 'Tvůj požadavek',
    'Ref: Summary': 'Souhrn',
  },
  Comment: {
    'Ref: Page title': 'Aktualizovat nás.',
    'Ref: please update us with details':
      'Více informací pro nás komunikují? Komentář? Čím více víme o vaší žádosti, tím lépe vám můžeme podpořit.',
  },
  Confirm: {
    'Ref: Page title': 'Potvrďte svůj požadavek',
    'Ref: next': 'Předložit',
    summary: 'souhrn',
    'Ref: Save as my preferred location': 'Uložit jako moje preferované umístění',
  },
  Describe: {
    'Ref: Page title': 'Popište svůj požadavek',
    'Ref: Required fields: header': 'Uveďte další informace',
    'Ref: Required fields': 'V poli podrobností by měly být poskytnuty následující informace:',
    'Ref: Required field: title': 'Je vyžadován titul.',
    'Ref: Required field: description': 'Popis je vyžadován.',
    'Ref: Required field: phone': 'Je vyžadováno telefonní číslo.',
    'Ref: Required field: category': 'Je vyžadována kategorie.',
    'Ref: Invalid field: category': 'Poskytnutá kategorie je neplatná.',
    'Ref: Request template field label': 'Populární požadavky',
    'Ref: Request template field placeholder': 'Prázdná stránka',
    your_request: 'Tvůj požadavek',
    'Ref: Description message': 'Čím podrobnější jste, tím lépe vás můžeme podpořit.',
    phone_number:
      'Vaše telefonní číslo (pokud máte tento, použijte prosím svůj profesionální telefon)',
    'Ref: Affected person': 'Postižená osoba touto žádostí',
    'Ref: No affected person results': 'Žádná osoba odpovídající požadovaná kritéria',
    'Ref: Notification title': 'Změnili jste umístění',
    'Ref: Notification info': 'Nyní jste na {site name}',
    'Ref: Add photos': 'Přidejte fotky',
    'Ref: Your location': 'Vaše pozice',
  },
  Details: {
    'Ref: Page title': 'Požadovat detaily',
    Details: 'Podrobnosti',
    Updates: 'Aktualizace',
    'Update us': 'Aktualizovat nás.',
    'Ref: timeline': 'Časová osa',
    'Ref: attachments': 'Přílohy',
    'Ref: location': 'Umístění',
    'Ref: createdOn': 'Vytvořeno na',
    'Ref: reference': 'Odkaz',
    'Feedback was submitted': 'Zpětná vazba odeslána',
    'Ref: sent': 'Odesláno',
    'Ref: received': 'Přijat',
    'Ref: inProgress': 'Probíhá',
    'Ref: completed': 'Dokončeno',
    'Ref: conversation': 'Konverzace',
    'Ref: Priority': 'Přednost',
    'Ref: Target start date': 'Datum zahájení cíle',
    'Ref: Target completion date': 'Datum dokončení cíle',
    'Ref: Priority fields placeholder message': 'Bude naplněna, jakmile je ověřena pomocí podpory',
  },
  Failed: {
    'Ref: Body':
      'Došlo k chybě a váš požadavek na službu nebyl vytvořen. Pokud tato chyba přetrvává, obraťte se prosím na podporu.',
    'Ref: Tabtitle': 'Žádost o službu se nepodařilo vytvořit',
  },
  Home: { 'Ref: Page title': 'Požadavky' },
  RequestFeedback: {
    'Ref: Page title': 'Zpětná vazba',
    'Ref: Feedback headline': 'Sdílejte prosím své zpětné vazby',
    'Ref: Feedback title': 'Zpětná vazba o SR # {ref}',
    'Ref: Question: general sentiment':
      'Celkově, jak jste spokojeni s tím, jak Sodexo zpracovával vaši žádost o službu?',
    'Ref: Question: answer quality': 'Jak byste ohodnotili kvalitu služby, kterou jste obdrželi?',
    'Ref: Question: staff interaction': 'Jak byste ohodnotili své interakce s našimi zaměstnanci?',
    'Ref: Question: efficiency': 'Jak byste ohodnotili naši efektivitu?',
    'Ref: Question: open': 'Komentář',
    'Ref: Open question placeholder': 'Co jsme udělali dobře? Kde se můžeme zlepšit?',
    no_comment: 'Bez komentáře',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Díky za to, že jste si svůj čas zanechal komentář, vaše zpětná vazba je pro nás důležitá pro zlepšení vašich zkušeností.',
  },
  Locate: {
    'Ref: Page title': 'Vyhledejte svůj požadavek',
    'Ref: Top bar title': 'Vyhledejte svůj požadavek',
    'Ref: Select this location': 'Vyberte toto umístění',
    drill: 'Procházet',
    'Ref: Scanner button': 'Umístění s QR? Skenovat ji',
    'Ref: Scanner header': 'Prosím, skenujte svou polohu qr',
    'Sorry, this location could not be found.': 'Je nám líto, toto místo nebylo možné nalézt.',
    'Close scanner': 'Zavřít skener',
    'Ref: Switch site component intro sentence': 'Žádáte službu na stránkách {site name}.',
    'Ref: search location by keywords': 'Prohledejte místo',
    'Ref: Enter Location name': 'Zadejte název místa',
    'Ref: Enter manual location': 'Zadejte umístění ručně',
    'Ref: Location name': 'Název umístění',
    'Ref: No results': 'Nemohli jsme najít vaši polohu. Možná budete chtít zadat ručně.',
    'Ref: Up one level': 'Jednu úroveň',
    'Ref: Navigate': 'Navigovat',
    'Ref: Select': 'Vybrat',
    'Ref: Selected Location': 'Vybrané umístění',
    'Ref: Preferred Location': 'Preferované místo',
    'Ref: Building': 'Budova',
    'Ref: Floor': 'Podlaha',
    'Ref: Aisle': 'Ulička',
    'Ref: Room': 'Pokoj, místnost',
    'Ref: Notification title': 'Změnili jste umístění',
    'Ref: Notification info': 'Nyní jste na {site name}',
    'Ref: Scanner error header': 'Chyba skenování',
    'Ref: Scan QR Code': 'Naskenujte QR kód',
    'Ref: Error body': 'Tento QR kód není platný kód',
    'Ref: Loading Locations Warning':
      'Počkejte prosím, když připravujeme vaše zkušenosti na první požadavek na tomto webu.',
    'Ref: Search Result is limited to:':
      'Výsledek vyhledávání je omezen na {limit}. Upřesněte prosím svá vyhledávací kritéria.',
    'Ref: Open the location search field': 'Otevřete pole vyhledávání polohy',
  },
  Success: {
    'Ref: Body':
      'Vaše žádost o službu byla odeslána a zpracována. V této aplikaci se zobrazí krátce. Při vyřešení obdržíte poznámku. Mezitím můžete sledovat jeho stav a přidat k ní prostřednictvím této aplikace.',
    'Ref: Tabtitle': 'Vytvořena žádost o službu',
    'Ref: Download app title': 'Zjednodušte své servisní požadavky',
    'Ref: Download app subtitle': 'Stáhněte si naši aplikaci a pozvedněte svůj zážitek',
    'Ref: Download app button': 'Stáhněte si aplikaci',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Toto je náboje podpory na pracovišti',
    'Ref: Tutorial: tabname': 'Požadavky',
    'Ref: Tutorial: body':
      'Jsme tu, abychom vám pomohli. Přihlaste se prosím jeden požadavek na záznam.',
    "What's new: title": 'Najít svou polohu snadno',
    "What's new: body":
      'Nyní můžete skenovat umístění QR, úrovně umístění umístění, nebo jen vybrat z našich inteligentních návrhů.',
  },
  Widget: {
    'Ref: Service request': 'Požadavek služby',
    'Ref: Placeholder body': 'Žádná žádost o službu na okamžik',
    'Ref: New request button': 'Nový požadavek',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Změnili jste umístění',
    'Ref: Notification info': 'Nyní jste na {site name}',
    'Welcome back': 'Vítej zpět',
    'Create a service request': 'Vytvořte požadavek na službu',
    'Ref: Share feedback': 'Sdílejte zpětnou vazbu',
    'Go to home': 'Jít domů',
    'Ref: Error body':
      'Kód QR, který jste naskenovali, již nefunguje nebo není platný. Prosím zkuste to znovu později.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Vyskytla se chyba',
    'Ref: Error body':
      'QR kód, který jste naskenovali, již nefunguje. Prosím zkuste to znovu později.',
    'Go to home': 'Jít domů',
  },
  ManualDescribe: {
    'Ref: Page title': 'Popište svůj požadavek',
    'Ref: Required fields: header': 'Uveďte další informace',
    'Ref: Required fields': 'Následující informace by měly být poskytnuty v poli podrobností:',
    'Ref: Required field: title': 'Je vyžadováno shrnutí.',
    'Ref: Required field: description': 'Je vyžadován popis.',
    'Ref: Required field: category': 'Je vyžadována kategorie.',
    'Ref: Required field: buildingText': 'Je nutná budova.',
    'Ref: Invalid field: category': 'Poskytnutá kategorie je neplatná.',
    'Ref: Your request': 'Tvůj požadavek',
    'Ref: Description message': 'Čím podrobnější jste, tím lépe vás můžeme podpořit.',
    'Ref: Do you have a location QR code': 'Máte kód QR umístění?',
    'Ref: Scan QR Code': 'Naskenujte QR kód',
    'Ref: Please select category': 'Vyberte prosím kategorii',
    'Ref: Please select site': 'Vyberte prosím web',
    'Ref: site': 'Místo',
    'Ref: building': 'Budova',
    'Ref: room': 'Pokoj, místnost',
    'Ref: Scan error': 'Chyba skenování',
    'Ref: The QR code is not valid': 'QR kód není platný',
    'Ref: Add location with QR Scanner': 'Přidejte polohu se skenerem QR',
    'Ref: Description': 'Popis',
    'Ref: Forbidden error': 'Je nám líto, tento QR kód neodpovídá vašemu registrovanému umístění.',
    'Ref: Invalid error': 'Je nám líto, zdá se, že tento QR kód již není platný.',
  },
};
export default labels;
