import { useCallback, useEffect, useMemo, useState } from 'react';

import useWindowSize from '../../../helpers/hooks/useWindowSize';
import { isSmallViewport } from '../../../helpers/windowSize';
import { Filtering } from '../../atoms/Filters/Filters.types';
import {
  getSelectedOptionsNum,
  hasFilterInModal,
  hasFilterVisible,
} from '../../atoms/Filters/helpers';

import {
  applyFiltering,
  applySearch,
  prepareSearchMetadata,
  applySearchOnMetadata,
} from './listPage.helpers';
import { UseFilteringProps } from './ListPage.types';

import { ItemWithTestingProps } from 'src/types';

export const useFiltering = <T extends ItemWithTestingProps>({
  items,
  filter,
  search,
  useMetadataSet = false,
  resultLimit,
}: UseFilteringProps<T>) => {
  const [searchString, setSearchString] = useState<string>(search?.searchString || '');
  const [filtering, setFiltering] = useState<Filtering>({});
  const windowSize = useWindowSize();
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(isSmallViewport());
  const [itemsMetadata, setItemsMetadata] = useState({});

  useEffect(() => {
    setIsSmallScreen(isSmallViewport());
  }, [windowSize]);

  useEffect(() => {
    //initial filtering: as provided through props, then ONLY IF NOT YET AVAILABLE THROUGH PROPS as provided through default filter values for static and dynamic optionsets
    //this is to be able to store state in parent component and pass it down as props that take precedence over filter default values
    let initialFilteringState = filter?.initialFiltering || {};

    //initial filtering state for static optionsets
    for (const filterItem of filter?.filters || []) {
      if (initialFilteringState[filterItem.id]) continue;

      initialFilteringState[filterItem.id] = {};

      if (filterItem?.options?.length) {
        for (const filterOption of filterItem.options) {
          if (filterOption.default) initialFilteringState[filterItem.id][filterOption.value] = true;
        }
      }
    }

    //initial filtering state for dynamic optionsets: loop once, in the order in which the filters are provided
    for (const filterItem of filter?.filters || []) {
      //we don't have a getApplicableOptions function: skip
      if (initialFilteringState[filterItem.id] || !filterItem.getApplicableOptions) continue;

      const options = filterItem.getApplicableOptions(initialFilteringState);

      for (const filterOption of options) {
        if (filterOption.default) initialFilteringState[filterItem.id][filterOption.value] = true;
      }
    }
    if (useMetadataSet && search?.searchableKeys) {
      const searchableItems = prepareSearchMetadata(items, search?.searchableKeys);
      setItemsMetadata(searchableItems);
    }
    setFiltering((state) => {
      const currentFilters = Object.entries(state)?.reduce(
        (acc, [k, v]) => (Object.keys(v).length ? { ...acc, [k]: v } : acc),
        {}
      );
      return { ...initialFilteringState, ...(currentFilters || {}) };
    });
  }, [filter, items, search?.searchableKeys, useMetadataSet]);

  useEffect(() => {
    setSearchString(search?.searchString || '');
  }, [search?.searchString]);

  const filterItems = useMemo(() => {
    let itemsFilter = [...items];
    if (searchString && search?.searchableKeys) {
      if (useMetadataSet) {
        itemsFilter = applySearchOnMetadata(itemsFilter, searchString, itemsMetadata, resultLimit);
      } else {
        // new Apply search without reparce the dataset
        itemsFilter = applySearch(itemsFilter, searchString, search.searchableKeys);
      }
    }
    if (filtering) {
      itemsFilter = applyFiltering(itemsFilter, filter, filtering);
    }
    return itemsFilter;
  }, [
    items,
    searchString,
    search?.searchableKeys,
    filtering,
    useMetadataSet,
    itemsMetadata,
    resultLimit,
    filter,
  ]);

  const hasFilters = useMemo(() => {
    const filters = filter?.filters;
    return !!filters && filters.length > 0 && !!filtering;
  }, [filter?.filters, filtering]);

  const { selectedNum, hasAtLeastOneFilterInModal, hasAtLeastOneFilterVisible } = useMemo(() => {
    const filters = filter?.filters;
    const selectedNum =
      filters && hasFilters && filtering
        ? getSelectedOptionsNum(filters, isSmallScreen, filtering)
        : 0;
    const hasAtLeastOneFilterInModal = filters ? hasFilterInModal(filters, isSmallScreen) : false;
    const hasAtLeastOneFilterVisible = filters ? hasFilterVisible(filters, isSmallScreen) : false;

    return { selectedNum, hasAtLeastOneFilterInModal, hasAtLeastOneFilterVisible };
  }, [filter?.filters, filtering, hasFilters, isSmallScreen]);

  const handleSearchChange = useCallback(
    (searchString: string) => {
      setSearchString(searchString);
      if (search?.handleSearchChange) search.handleSearchChange(searchString);
    },
    [search]
  );

  const handleFilteringChange = useCallback(
    (newFilteringState: Filtering) => {
      setFiltering(newFilteringState);
      if (filter?.handleFilteringChange) filter.handleFilteringChange(newFilteringState);
    },
    [filter]
  );

  return {
    filterItems,
    hasFilters,
    selectedNum,
    hasAtLeastOneFilterInModal,
    hasAtLeastOneFilterVisible,
    searchString,
    filtering,
    handleSearchChange,
    handleFilteringChange,
  };
};
