import { sentimentTypes } from '../../../Feedback/config';
import { SentimentRatingType } from '../../types/types';

import { SentimentRatingProps } from './SentimentRating.types';

import RatingStar from '@/components/atoms/RatingStar/RatingStar';

const sentimentTypeToNumber = (sentimentType: SentimentRatingType | undefined) => {
  if (!sentimentType) {
    return 0;
  }
  return sentimentTypes.findIndex((el) => el.id === sentimentType.id) + 1;
};

const SentimentRating = ({ value, onChange, ...props }: SentimentRatingProps) => (
  <RatingStar
    {...props}
    data-testid={`sentiment-star-rating`}
    value={sentimentTypeToNumber(value)}
    onChange={onChange}
  />
);

export default SentimentRating;
