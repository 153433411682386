const labels = {
  All: {
    'Ref: AccommodationRequestTitle': '住宿請求',
    'Ref: Describe your request': '描述您的要求',
    'Ref: Confirm your request': '確認您的要求',
    'Ref: Your request': '你的申請',
    'Ref: Summary': '概括',
    'Ref: Next': '下一個',
    'Ref: Submit': '提交',
    'Ref: Me': '我',
    'Ref: Arrival date': '到達日期',
    'Ref: Arrival time': '到達時間',
    'Ref: Departure date': '出發日期',
    'Ref: Departure time': '出發時間',
    'Ref: request raised for': '受此要求影響的人',
    'Ref: phoneNumber': '電話號碼',
    'Ref: arrivalDate': '到達',
    'Ref: departureDate': '離開',
    'Ref: description': '評論',
    'Ref: open': '打開',
    'Ref: pending': '待辦的',
    'Ref: refused': '拒絕',
    'Ref: closed': '關閉',
    'Ref: all': '全部',
    'Ref: Arrival': '到達',
    'Ref: Validate': '證實',
    'Ref: site': '地點坐標',
    'Ref: Update us': '更新我們',
    'Ref: email of guest': '客人的電子郵件',
    'Ref: GuestAccommodationRequestTitle': '賓客要求',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': '新要求',
    'Ref: Status': '地位',
    'Ref: Date': '日期',
    'Ref: Coming arrivals': '即將到來',
    'Ref: Past arrivals': '過去的到來',
  },
  CreateForm: {
    'Ref: Guest phone number': '電話號碼',
    'Ref: Your phone number': '你的電話號碼',
    'Ref: The phone number': '電話號碼',
    'Ref: PhoneNumber error msg': '電話號碼無效',
    'Ref: Comment error msg': '最大字符數為250',
    'Ref: Guest first name': '來賓的名字',
    'Ref: Guest last name': '來賓姓',
    'Ref: request for': '對於誰是這個請求？',
    'Ref: request for me': '我',
    'Ref: request for guest': '其他人',
  },
  Details: {
    'Ref: AccommodationRequestDetails': '住宿請求詳細信息',
    'Ref: Timeline': '時間線',
    'Ref: No updates placeholder': '此請求沒有更新',
  },
  CreateThankYou: {
    'Ref: Thank you': '謝謝',
    'Ref: Success text': '我們已經收到您的要求，現在正在調查。我們將盡快與您聯繫。',
    'Ref: Go to home': '回家',
    'Ref: Create another request': '創建另一個請求',
  },
  CreateError: {
    'Ref: Create error title': '哎呀！',
    'Ref: Create error text': '發生了錯誤。請再試一次。',
    'Ref: Retry': '再試一次',
  },
  UpdateUs: {
    'Ref: Update us msg': '請向我們更新有關您的請求的任何新元素，以便我們最好地支持您。',
  },
};
export default labels;
