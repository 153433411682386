import baseApi from '../../../../services/api/baseApi';
import { getUserContext } from '../../actions';
import { saveTokens } from '../../helpers/tokensHelpers';
import mockResponse from '../../mocks/accountApi';
import userContextApi from '../account/userContextApi';

import { clientId } from './../../../../config';
import { IdpLoginRequestArgs, LoginRequestArgs } from './loginApi.types';

import { TokenRefreshResponse } from 'src/axios/tokenRefresh.types';

const loginApi = baseApi.enhanceEndpoints({ addTagTypes: ['login'] }).injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<TokenRefreshResponse, LoginRequestArgs>({
      query: (args) => ({
        url: `/v2/oauth/token`,
        method: 'post',
        data: {
          username: args.username,
          password: args.psw,
          client_id: args.client_id ?? clientId,
          grant_type: args.grant_type ?? 'password',
          scope: args.scope,
        },
        mockData: mockResponse.login,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }),
      async onQueryStarted(
        { geoCode, username, currentLanguageCode },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          //persist access and refresh tokens locally
          saveTokens(data);

          await dispatch({
            type: 'LOGGED_IN',
            currentGeoCode: geoCode,
            username,
          });
          await dispatch(userContextApi.util.invalidateTags(['userContext']));
          await dispatch(getUserContext({ currentLanguageCode }));
        } catch {
          console.log('Error logging in');
        }
      },
    }),
    getIdpAccessToken: build.mutation<TokenRefreshResponse, IdpLoginRequestArgs>({
      query: (args) => ({
        url: `/v2/identity/token`,
        method: 'post',
        data: {
          username: args.username,
          type: args.type,
        },
        mockData: mockResponse.login,
      }),
      async onQueryStarted(
        { geocode, currentLanguageCode, username },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;

          saveTokens(data);

          await dispatch({
            type: 'LOGGED_IN',
            currentGeoCode: geocode,
            username,
            isSSOUser: true,
          });
          await dispatch(getUserContext({ currentLanguageCode }));
        } catch {
          console.log('Error logging in');
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const { useLoginMutation, useGetIdpAccessTokenMutation } = loginApi;
export default loginApi;
