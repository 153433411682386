const mockResponse = {
  convertGuestToRegisteredUsers: {
    ok: true,
    status: 202,
    json: () => Promise.resolve(),
  },
  registerGuestUser: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        access_token:
          'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiR3Vlc3QiLCJzdWIiOiJkMTY0Mjk1OS0wNmZlLTQ4NWMtOGQxYy0xNzliMGU1YjkwZGIiLCJjaWQiOiJmMDhlNzY5Yy03NGU1LWVjMTEtYmIzYy0wMDBkM2EyM2JhNzEiLCJjY2lkIjoiOWVlNDRkYTgtZjQ3Yi1lOTExLWE5N2QtMDAwZDNhMmJjYzY5Iiwicm9sZSI6IkNvbnN1bWVyIEFwcCBVc2VyIC0gR3Vlc3QiLCJuYmYiOjE2NTQ1MDQ5NjYsImV4cCI6MTY1NDUwNjc2NiwiaXNzIjoiaHR0cHM6Ly9vYXV0aC5wb3J0YWwuc29kZXhvLmNvbSIsImF1ZCI6IjI0MTVmM2M5LWZlYTAtNDk4NS05NzQ3LWQyNTU2YTEwNmZjZiJ9.4A0MUxMGyYQqqVnVQBM92eE1XA0dBGvDzTaOoCwNq4iKyhp0nq-pQgaq3cO7IM1sfcApiCmqUY5ufC0qeBkNSQ',
        token_type: 'bearer',
        expires_in: 1799,
        refresh_token: 'MzJiNjg2NTM4ODk5NDM5Mzk2MWQ3MDBmODllMGMwNWY',
      }),
  },
};

export default mockResponse;
