//id: string, to make sure we don't duplicate the calls
//action: the action to be scheduled. must be a function
//breakwhen: a function that takes the state as the only paramater. Interval will be cleared when true
//startDelay: in seconds
//interval: in seconds

class ReduxSchedulerService {
  static schedulers: Record<string, ReduxSchedulerService> = {};

  public id: string;
  public dispatchAction: () => void;
  public shouldClear: (action: unknown, state: unknown) => boolean;
  public startDelay: number;
  public interval: number;
  public clear: (() => void) | undefined;
  public remainingIterations: number;
  public maxIterations: number = 0;

  constructor({
    id,
    dispatchAction,
    shouldClear,
    startDelay,
    interval,
    maxIterations,
  }: {
    id: string;
    dispatchAction: () => void;
    shouldClear: (action: any, state: any) => boolean;
    startDelay: number;
    interval: number;
    maxIterations: number;
  }) {
    this.id = id;
    this.dispatchAction = dispatchAction;
    this.shouldClear = shouldClear; // a function that takes an action type and store state and returns a boolean
    this.startDelay = startDelay;
    this.interval = interval;
    this.clear = undefined;
    this.remainingIterations = maxIterations;
  }
  apply = () => {
    // console.log('apply')
    const timeoutFunc = () => {
      console.log('timeoutFunc');
      if (this.remainingIterations > 0) {
        this.remainingIterations -= 1;
        this.dispatchAction();
      } else {
        if (this.clear) {
          this.clear();
        }
        delete ReduxSchedulerService.schedulers[this.id];
      }
    };
    let scheduledFunc;
    if (this.interval) {
      // console.log('interval')
      scheduledFunc = () => {
        // console.log('interval func')
        timeoutFunc();
        this.maxIterations -= 1;
        const clearId = setInterval(timeoutFunc, this.interval * 1000);
        this.clear = () => clearInterval(clearId);
      };
    } else scheduledFunc = timeoutFunc;

    this.clear = (() => {
      const clearId = setTimeout(scheduledFunc, this.startDelay * 1000);
      return () => clearTimeout(clearId);
    })();

    ReduxSchedulerService.schedulers[this.id] = this;
  };
}

export default ReduxSchedulerService;
