import { act, cleanup, renderHook } from '@testing-library/react';

import { APP_DISPLAY_NAME } from '../../../../constants';
import useTrackSiteVisit, { getServiceName } from '../trackSiteVisit.helper';

const mockTrackSiteVisitData = {
  site: {
    id: '1111111',
    name: 'Test Site',
  },
  contactId: '1234567',
  contract: {
    id: '36472822',
    name: 'Test Contract name',
  },
  language: 'en-GB',
  appName: APP_DISPLAY_NAME.MYWAY,
  appPackage: 'com.sodexo.mywaycanary',
  appVersion: '11.1.0',
  module: 'Home',
};

const expectedTrackSiteVisitData = {
  properties: {
    siteId: mockTrackSiteVisitData.site.id,
    siteName: mockTrackSiteVisitData.site.name,
    contactId: mockTrackSiteVisitData.contactId,
    contractId: mockTrackSiteVisitData.contract.id,
    contractName: mockTrackSiteVisitData.contract.name,
    language: mockTrackSiteVisitData.language,
    appName: mockTrackSiteVisitData.appName,
    appVersion: mockTrackSiteVisitData.appVersion,
    appPackage: mockTrackSiteVisitData.appPackage,
    module: mockTrackSiteVisitData.module,
  },
};

const mockTrackPageView = jest.fn((args) => args);
jest.mock('@microsoft/applicationinsights-react-js', () => ({
  useAppInsightsContext: () => ({ trackPageView: mockTrackPageView }),
}));

jest.mock('react-router', () => {
  return {
    useLocation: () => ({
      pathname: '/home',
    }),
  };
});

const mockSelector = jest.fn().mockReturnValue({
  Core: {
    user: {
      useDataTracking: true,
    },
  },
});

jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) => callback(mockSelector()),
}));

jest.mock('react-redux', () => ({
  useSelector: jest.fn(),
}));

describe('useTrackSiteVisit hook', () => {
  const env = global.process.env;

  afterAll(() => cleanup());

  describe('getServiceName', () => {
    it('should return Home', () => {
      const service = getServiceName('/home');

      expect(service).toBe('Home');
    });

    it('should return Services', () => {
      const service = getServiceName('/services');

      expect(service).toBe('Services');
    });

    it('should return Account', () => {
      const service = getServiceName('/account');

      expect(service).toBe('Account');
    });

    it('should return Register instead of Account', () => {
      const service = getServiceName('/account/information');

      expect(service).toBe('Register');
    });

    it('should return map non-service page', () => {
      const service = getServiceName('/guest/register');

      expect(service).toBe('GuestRegistration');
    });

    it('should return Legal', () => {
      const service = getServiceName('/access/legal');

      expect(service).toBe('Legal');
    });

    it('should return LoginPage for login url', () => {
      const service = getServiceName('/access/login');

      expect(service).toBe('LoginPage');
    });

    it('should return LoginPage for forgotten password url', () => {
      const service = getServiceName('/access/forgotten_password');

      expect(service).toBe('LoginPage');
    });

    it('should return valid serviceName', () => {
      const service = getServiceName('/contacts');

      expect(service).toBe('Contacts');
    });

    it('should return null if nothing is found', () => {
      const service = getServiceName('/testing');

      expect(service).toBe(undefined);
    });
  });

  describe('useTrackSiteVisit hook', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_APP_NAME: mockTrackSiteVisitData.appName,
        REACT_APP_MOBILE_PACKAGE_NAME: mockTrackSiteVisitData.appPackage,
        REACT_APP_BUILD_NUMBER: mockTrackSiteVisitData.appVersion,
      };
    });

    it('should return result via function trackPageView', async () => {
      await act(async () => {
        renderHook(() => useTrackSiteVisit(mockTrackSiteVisitData));
      });

      expect(mockTrackPageView).toHaveBeenCalledWith(expectedTrackSiteVisitData);
    });
  });
});
