import classNames from 'classnames';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { INPUT_TYPE, SIZE } from '../../../../constants';
import {
  useGetAssetInformationQuery,
  useGetServiceRequestsSitesQuery,
  useGetServiceRequestsTypesQuery,
} from '../../api/index';
import { getServiceRequestConfigurations, requestCategories } from '../../config';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';
import { Site } from '../../types/apiResponse.types';
import { RequestType } from '../../types/types';

import { FormAction, FormState, ManualDescribeProps } from './ManualDescribe.types';

import { QrCodeIcon } from '@/assets/index';
import Button from '@/components/atoms/Button';
import FormLine from '@/components/atoms/FormLine/FormLine';
import Input from '@/components/atoms/Input';
import InputRow from '@/components/atoms/InputRow';
import toast from '@/components/atoms/ModalToast';
import Select from '@/components/atoms/Select';
import Stepper from '@/components/atoms/Stepper';
import Textarea from '@/components/atoms/Textarea/Textarea';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import { Alert } from '@/components/molecules/Alert';
import Card from '@/components/molecules/Card/Card';
import QrCodeReader from '@/components/molecules/QrCode/QrCodeReader';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { AttachmentData } from '@/types/common.types';
import { State } from '@/types/state.types';

import styles from './ManualDescribe.module.css';

const SET_VALUES = 'SET_VALUES';

const ManualDescribe = ({
  title,
  category,
  description,
  site,
  buildingText,
  roomText,
  attachments,
  createServiceRequestLock,
  disabledFields,
  setDisabledFields,
  handleChange: parentHandleChange,
  goToNext,
}: ManualDescribeProps) => {
  const { label } = useServiceRequestTranslation(__filename);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState<{ field: string; message: string }[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showQrCodeAlert, setshowQrCodeAlert] = useState(false);

  const siteId = useSelector((state: State) => state.Core.context.site?.id || undefined);
  const [formValues, dispatch] = useReducer(formReducer, {
    title,
    category,
    description,
    site: siteId,
    buildingText,
    roomText,
    attachments,
  });

  const { requestTypesFromApi, qrScannerWhitelist = [] } = getServiceRequestConfigurations();

  const location = useLocation();
  const [code, setCode] = useState('');
  const { data: assets, error } = useGetAssetInformationQuery(code, { skip: !Boolean(code) });

  const { data: sites = [], isFetching: serviceRequestSitesLock } = useGetServiceRequestsSitesQuery(
    {}
  );
  const { data: requestTypes = [], isFetching: serviceRequestTypesLock } =
    useGetServiceRequestsTypesQuery(
      { siteId: formValues.site ?? '' },
      {
        skip: !(requestTypesFromApi && formValues?.site),
      }
    );
  const popupMessage = formErrors
    .map((k: { field: string; message: string }) => k.message)
    .join('<br/>');

  const validateForm = useCallback(
    (callback?: Function) => {
      const missingInfo: { field: string; message: string }[] = [];
      const required = ['title', 'site', 'category', 'buildingText', 'description'];

      const rules = [
        ...required.map((fieldName) => ({
          target: fieldName,
          validate: (val: string = '') => {
            const fieldValue = val?.trim();

            if (!fieldValue || !fieldValue.length)
              missingInfo.push({
                field: fieldName,
                message: label(`Ref: Required field: ${fieldName}`),
              });
          },
        })),
      ];

      for (const rule of rules) {
        const values: { [input: string]: any } = formValues;
        rule.validate(values[rule.target]);
      }

      setIsFormValid(missingInfo.length === 0);
      setFormErrors(missingInfo);

      if (missingInfo.length) {
        if (callback) callback();
      }
    },
    [formValues, label]
  );

  useEffect(() => {
    validateForm();
  }, [validateForm, formValues]);

  useEffect(() => {
    const urlCode = new URLSearchParams(location.search).get('code');
    if (urlCode) setCode(urlCode);
  }, [location]);

  useEffect(() => {
    if (error) {
      const { status } = error as { status: number };
      if (status === 403) {
        toast.error(label('Ref: Forbidden error'), {
          toastId: 'forbidden-error-toast',
          position: 'top-center',
          closeOnClick: true,
          draggable: false,
          closeButton: false,
        });
      }
      if (status === 404) {
        toast.error(label('Ref: Invalid error'), {
          toastId: 'invalid-error-toast',
          position: 'top-center',
          closeOnClick: true,
          draggable: false,
          closeButton: false,
        });
      }
    }
  }, [error, label]);

  const handleChange = useCallback(
    (values: FormState) => {
      dispatch({ type: SET_VALUES, state: values });

      if (parentHandleChange) {
        parentHandleChange(values);
      }
    },
    [parentHandleChange, dispatch]
  );

  useEffect(() => {
    if (assets) {
      dispatch({
        type: SET_VALUES,
        state: {
          site: sites.find((x) => x.id === assets.siteId)?.id,
          buildingText: assets.buildingName,
          roomText: assets.roomName,
        },
      });
      setDisabledFields(true);
    }
  }, [assets, setDisabledFields, sites]);

  const handleSubmit = useCallback(() => {
    const {
      title = '',
      site = '',
      buildingText = '',
      roomText = '',
      description = '',
      category = '',
      attachments = [],
    } = formValues;

    const siteName = sites.find((s) => s.id === site)?.name;

    const myLocation = {
      title: roomText
        ? `${siteName} / ${buildingText} / ${roomText}`
        : `${siteName} / ${buildingText}`,
    };

    handleChange({
      title,
      site,
      buildingText,
      roomText,
      description,
      category,
      attachments,
      myLocation,
    });

    goToNext();
  }, [formValues, goToNext, handleChange, sites]);

  const handleQRCode = useCallback(
    async (url: string) => {
      const [, search] = url.split('?');
      const assetCode = new URLSearchParams(search).get('code');

      if (!assetCode) {
        toast.error(label('Ref: Invalid error'), {
          toastId: 'invalid-error-toast',
          position: 'top-center',
          closeOnClick: true,
          draggable: false,
          closeButton: false,
        });
        return;
      }

      if (assetCode) setCode(assetCode);
    },
    [label]
  );

  const serviceRequestCategories: RequestType[] = requestTypesFromApi
    ? requestTypes
    : requestCategories.map((cat) => ({ name: label(cat), id: cat }));

  const maxFilesNum = 5;
  const maxFileSize = 1024 * 1024 * 10;
  const maxTotalFileSize = 1024 * 1024 * 21;

  if (serviceRequestSitesLock || createServiceRequestLock) return <LoadingPage />;

  const showScanner = qrScannerWhitelist.length > 0 ? qrScannerWhitelist.includes(site) : true;

  return (
    <SimpleFormPage title={label('Ref: Page title')} hasBackLink>
      <Container>
        <Column.Main>
          <Card
            overTitle={{
              tag: TITLE_TAG.H2,
              size: TITLE_SIZE.HEADLINES,
              children: label('Ref: Your request'),
            }}
          >
            <FormLine data-testid="service-request-manual-describe-summary">
              <Input
                id="title"
                data-testid="service-request-manual-describe-summary"
                inputLabel={label('Summary')}
                inputType={INPUT_TYPE.TEXT}
                required
                value={formValues.title || ''}
                onInputChange={(val: string) => handleChange({ title: val })}
              />
            </FormLine>
            <FormLine data-testid="service-request-manual-describe-site">
              <Select
                name="site"
                data-testid="site-select"
                data-cy="site-select"
                label={label('Ref: site')}
                required
                placeholder={label('Ref: Please select site')}
                onChange={(option) =>
                  handleChange({ site: option.value, ...(requestTypesFromApi && { category: '' }) })
                }
                value={formValues.site}
                options={sites.map(({ id, name }: Site) => ({ label: label(name), value: id }))}
                disabled={disabledFields}
                noOptionsMessage={() => label('Ref: No options')}
              />
            </FormLine>
            <FormLine data-testid="service-request-manual-describe-category">
              <Select
                name="category"
                data-testid="category-select"
                label={label('Ref: Request category')}
                required
                placeholder={label('Ref: Please select category')}
                onChange={(option) => handleChange({ category: option.value })}
                value={formValues.category || ''}
                data-cy="category-select"
                options={serviceRequestCategories.map(({ id, name }: RequestType) => ({
                  label: label(name),
                  value: id,
                }))}
                disabled={serviceRequestTypesLock}
                noOptionsMessage={() => label('Ref: No options')}
              />
            </FormLine>

            <InputRow>
              <FormLine data-testid="service-request-manual-describe-building">
                <Input
                  id="building"
                  data-testid="service-request-manual-describe-building"
                  inputLabel={label('Ref: building')}
                  inputType={INPUT_TYPE.TEXT}
                  required
                  value={formValues.buildingText || ''}
                  disabled={disabledFields}
                  onInputChange={(val: string) => handleChange({ buildingText: val })}
                />
              </FormLine>
              <FormLine data-testid="service-request-manual-describe-room">
                <Input
                  id="room"
                  data-testid="service-request-manual-describe-room"
                  inputLabel={label('Ref: room')}
                  inputType={INPUT_TYPE.TEXT}
                  value={formValues.roomText || ''}
                  disabled={disabledFields}
                  onInputChange={(val: string) => handleChange({ roomText: val })}
                />
              </FormLine>
            </InputRow>

            <FormLine data-testid="service-request-manual-describe-description">
              <Textarea
                id="description"
                data-testid="description-input"
                label={label('Ref: description field label')}
                comment={label('Ref: Description message')}
                required
                value={formValues.description || ''}
                onChange={(e) => handleChange({ description: e.detail.value || '' })}
              />
            </FormLine>
            <Attachments
              attachments={formValues.attachments || []}
              maxFilesNum={maxFilesNum}
              maxFileSize={maxFileSize}
              maxTotalFileSize={maxTotalFileSize}
              data-testid="attachments-manual-describe"
              attach={(items: AttachmentData[]) => handleChange({ attachments: items })}
            />

            {!isFormValid && (
              <Alert
                isOpen={showAlert}
                onDismiss={() => setShowAlert(false)}
                className="popup-warning data-cy-missing-info-popup"
                header={label('Ref: Required fields: header')}
                message={<div dangerouslySetInnerHTML={{ __html: popupMessage }}></div>}
                buttons={[label('ok', { textTransform: 'capitalize' })]}
                data-testid="missing-info-popup"
              />
            )}

            <Alert
              isOpen={showQrCodeAlert}
              onDismiss={() => setshowQrCodeAlert(false)}
              className="popup-warning data-cy-missing-info-popup"
              header={label('Ref: Scan error')}
              message={label('Ref: The QR code is not valid')}
              buttons={[label('ok', { textTransform: 'capitalize' })]}
              data-testid="invalid-qr-code-popup"
            />
          </Card>
        </Column.Main>
        <Column.Side>
          <Stepper />

          {showScanner && (
            <div className={classNames(styles.scanLocationCode)}>
              <div>
                <Title size={TITLE_SIZE.BODYSBOLD}>
                  {label('Ref: Do you have a location QR code')}
                </Title>
              </div>
              <div>
                <QrCodeReader
                  label={label}
                  buttonLabel={label('Ref: Scan QR Code')}
                  title={label('Ref: Add location with QR Scanner')}
                  buttonProps={{
                    look: 'secondary',
                    inheritStyle: classNames(styles.qrScanButton),
                    size: SIZE.SMALL,
                    affix: QrCodeIcon,
                    contentCenterAlign: true,
                    'data-testid': 'manual-describe-qr-code-button',
                  }}
                  onQrCodeScan={({ response = '' }) => handleQRCode(response)}
                />
              </div>
            </div>
          )}
        </Column.Side>
        <ActionsBar>
          <Button
            {...(isFormValid
              ? {
                  onClick: handleSubmit,
                  'data-testid': 'manual-describe-next-button',
                }
              : {
                  disabled: true,
                  isClickDisabled: true,
                  onClick: () => validateForm(() => setShowAlert(true)),
                  'data-testid': 'manual-describe-next-button-inactive',
                })}
          >
            {label('next', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case SET_VALUES:
      return {
        ...state,
        ...action.state,
      };
    default:
      return state;
  }
}

export default ManualDescribe;
