import { ChatIcon } from '../../../assets/icons';
import ChatMessage from '../../atoms/ChatMessage/ChatMessage';
import WidgetPlaceholder from '../../molecules/WidgetPlaceholder';

const Chat = ({ messages, title, 'data-testid': testId }) => {
  return (
    <div style={{ width: '100%' }}>
      {messages && messages.length ? (
        messages.map((message) => (
          <ChatMessage {...message} key={`message.date-${Math.random()}`} data-testid={testId} />
        ))
      ) : (
        <WidgetPlaceholder icon={<ChatIcon width={32} />} title={title} />
      )}
      <div style={{ clear: 'both' }} />
    </div>
  );
};

export default Chat;
