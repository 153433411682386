import { apiPath, dspApiPath } from '../../config';
import { APP_NAME } from '../../constants';
import { getAppSpecificData } from '../../helpers/misc';
import { serviceTypes, modulesConfig } from '../config';

import { SurveyConfigType } from './types/config.types';

import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

export const moduleId = 'Surveys';
export const cacheVersion = '0';

export const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const pagePaths = {
  Module: modulePath,
  Survey: modulePath + '/:id',
  Success: modulePath + '/success',
};

export const QUESTION_TYPES = {
  SHORT_ANSWER: 1,
  LONG_ANSWER: 2,
  SINGLE_CHOICE: 4,
  MULTIPLE_CHOICES: 5,
  RATING: 9,
};

export const shortAnswerMaxLengh = 40;
export const longAnswerMaxLengh = 255;

export const surveysConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      api: baseApi,
      apiBaseUrl: apiPath,
    },
    default: {
      api: dspApi,
      apiBaseUrl: dspApiPath,
    },
  };

  return getAppSpecificData<SurveyConfigType>(configuration);
};
