import { isResponseUnsuccessful } from '../../axios/axios.helper';
import { axiosClient } from '../../axios/axiosClient';
import { shouldUseMockData } from '../../config';

import { ImageResponse } from './image.types';

import { coreConfig } from '@/modules/Core/apiConfig';
import mockResponse from '@/modules/Core/mockResponse';

const { baseUrl, imagesUrl } = coreConfig();

export const fetchImagesFromServer = async (imageIds: string[]): Promise<ImageResponse[]> => {
  if (shouldUseMockData) {
    return (await mockResponse.getImages.json()) as ImageResponse[];
  }

  const url = baseUrl.concat(imagesUrl);

  const response = await axiosClient.get<ImageResponse[]>(url, {
    params: { imageIds },
    paramsSerializer: {
      serialize: (params) => {
        const imageIds = params.imageIds.map((id: string) => `id=${id}`);
        const otherParams = Object.keys(params)
          .filter((key) => key !== 'imageIds')
          .map((key) => `${key}=${params[key]}`);
        const queryString = [...imageIds, ...otherParams].join('&');

        return queryString;
      },
    },
    opts: { useErrorBoundary: false },
  });

  if (isResponseUnsuccessful(response)) {
    throw new Error(`Error fetching images`);
  }

  return response.data;
};
