/// component based on https://github.com/Hacker0x01/react-datepicker
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';

import { SIZE } from '../../../constants';
import { formatDate } from '../../../helpers/dateTime';
import usePrevious from '../../hooks/usePrevious';

import { DateRangeProps, DateRangeValue, dateRangeFormat } from './DateRange.types';
import DateRangeHeader from './DateRangePickerHeader';

import { Calendar2Icon } from '@/assets/icons';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Modal from '@/components/organisms/Modal';
import useToggle from '@/helpers/hooks/useToggle';
import useWindowSize from '@/helpers/hooks/useWindowSize';

import styles from './DateRange.module.css';

const DateRange = ({
  id,
  'data-testid': dataTestId = 'date-range',
  modalTitle,
  languageCode,
  label,
  onChange,
  currentDateRangeIso,
}: DateRangeProps) => {
  const [rangeValue, setRangeValue] = useState<DateRangeValue>();
  const [textRangeValue, setTextRangeValue] = useState<string>(label('Ref: All Dates'));
  const { state: modalOpen, toggleOn: openModal, toggleOff: closeModal } = useToggle(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [ignoreResetToPrevious, setIgnoreResetToPrevious] = useState<boolean>(false);
  const { width } = useWindowSize();

  const formatDateRange = (selectedRange: DateRangeValue) => {
    setTextRangeValue(label('Ref: All Dates'));

    const [start, end] = selectedRange ?? [null, null];
    if (start === null && end === null) {
      return;
    }
    const startDate: string = start ? formatDate(start, languageCode, dateRangeFormat) : '';
    const endDate: string = end ? formatDate(end, languageCode, dateRangeFormat) : '';
    const todayString = new Date().toDateString();

    if (
      (start?.toDateString() === todayString && !end) ||
      (start?.toDateString() === end?.toDateString() && end?.toDateString() === todayString)
    ) {
      setTextRangeValue(label('Ref: Today'));
      return;
    } else {
      if (start?.toDateString() === end?.toDateString()) {
        setTextRangeValue(startDate);
        return;
      }
    }
    setTextRangeValue(endDate ? `${startDate} - ${endDate}` : startDate);
  };

  const previousDateRange = usePrevious(currentDateRangeIso) ?? [null, null];
  const { width: windowWidth } = useWindowSize();
  const noOfMonthsToShow = windowWidth > 768 ? 2 : 1;

  // closeModal() is triggered also when Today and All dates are clicekd
  // in these cases , revert to precious date range is not needed
  const onCloseModal = () => {
    if (!ignoreResetToPrevious) {
      const [start, end] = previousDateRange ?? [null, null];

      setStartDate(start && start !== 'undefined' ? new Date(start) : null);
      setEndDate(end && end !== 'undefined' ? new Date(end.slice(0, -1)) : null);
    }
    setIgnoreResetToPrevious(false);
    closeModal();
  };

  const onChangeDateRange = useCallback((selectedRange: DateRangeValue) => {
    const [start, end] = selectedRange;
    setStartDate(start);
    setEndDate(end);
    setRangeValue(selectedRange);
  }, []);

  const onOpenModal = () => {
    openModal();
  };

  const onTodaySelect = () => {
    const start = new Date();
    start.setHours(0, 0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59, 0);
    const todayDateRange: DateRangeValue = [start, end];

    setRangeValue(todayDateRange);
    setStartDate(start);
    setEndDate(end);
    formatDateRange(todayDateRange);
    setIgnoreResetToPrevious(true);
    if (onChange) onChange(todayDateRange);
    closeModal();
  };

  const onAllDatesSelect = () => {
    setRangeValue([null, null]);
    setStartDate(null);
    setEndDate(null);
    formatDateRange([null, null]);
    setIgnoreResetToPrevious(true);
    if (onChange) onChange([null, null]);
    closeModal();
  };

  const onValidate = () => {
    let [start, end] = rangeValue ?? [null, null];
    setIgnoreResetToPrevious(false);
    if (start && !end) {
      end = start;
    }
    if (start !== null || end !== null) {
      formatDateRange([start, end]);
      if (onChange) onChange([start, end]);
      closeModal();
    }
  };

  const defaultDates = (
    <div className={classNames(styles.defaultDates)}>
      <Button
        data-testid={`${dataTestId}-modal-all-dates`}
        look={BUTTON_LOOK.SECONDARY}
        onClick={onAllDatesSelect}
        aria-label={label('Ref: All Dates')}
      >
        {label('Ref: All Dates')}
      </Button>
      <Button
        data-testid={`${dataTestId}-modal-today`}
        look={BUTTON_LOOK.SECONDARY}
        onClick={onTodaySelect}
        aria-label={label('Ref: Today')}
      >
        {label('Ref: Today')}
      </Button>
    </div>
  );
  const modalFooter = (
    <>
      <Button
        className={styles.validateButton}
        look={BUTTON_LOOK.PRIMARY}
        data-cy={`${dataTestId}-validate-btn`}
        data-testid={`${dataTestId}-validate-btn`}
        size={SIZE.SMALL}
        disabled={!startDate && !endDate}
        onClick={onValidate}
        aria-label={label('Ref: Validate')}
      >
        {label('Ref: Validate')}
      </Button>
    </>
  );

  return (
    <>
      <Button
        look={!modalOpen ? BUTTON_LOOK.PRIMARY : BUTTON_LOOK.SECONDARY_INLINE}
        data-cy={`${dataTestId}-button`}
        data-testid={`${dataTestId}-button`}
        onClick={onOpenModal}
        size={SIZE.SMALL}
        affix={Calendar2Icon}
        aria-label={`${label('Ref: Calendar')}. ${textRangeValue} ${label('Ref: Selected')}`}
      >
        {width >= 425 && textRangeValue}
      </Button>
      <Modal
        id={id}
        isOpen={modalOpen}
        contentClassName={classNames(styles.modalContent)}
        onDismiss={onCloseModal}
        data-testid={`${dataTestId}-modal`}
        data-cy={`${dataTestId}-modal`}
        title={modalTitle}
        footer={modalFooter}
        aria-label={modalTitle}
        crossButtonDismiss={true}
      >
        <div className={classNames(styles.dateRange)}>
          {defaultDates}
          <DatePicker
            showPopperArrow={false}
            onChange={(date) => onChangeDateRange(date)}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            formatWeekDay={(day) => day.substring(0, 1).toUpperCase()}
            renderCustomHeader={({
              monthDate,
              customHeaderCount,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <DateRangeHeader
                data-testid={`${dataTestId}-picker-header-${customHeaderCount}`}
                data-cy={`${dataTestId}-date-picker-header-${customHeaderCount}`}
                monthDate={monthDate}
                customHeaderCount={customHeaderCount}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                prevMonthButtonDisabled={prevMonthButtonDisabled}
                nextMonthButtonDisabled={nextMonthButtonDisabled}
                languageCode={languageCode}
                label={label}
                noOfMonthsToShow={noOfMonthsToShow}
              />
            )}
            renderDayContents={(day) => <span>{`${day}`}</span>}
            monthsShown={noOfMonthsToShow}
          />
        </div>
      </Modal>
    </>
  );
};

export default DateRange;
