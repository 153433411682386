const labels = {
  TimeTable: {
    'leave at': 'Để lại',
    'arrive at': 'Đến',
    at: 'tại',
    'Ref: list placeholder': 'Hoàn thành biểu mẫu để xem giờ',
    'Ref: Page title': 'Xe buýt',
    'Ref: Departure': 'Sự khởi hành',
    'Ref: Arrivals': 'Đến',
    'Ref: minutes': 'phút',
    'Ref: no items placeholder': 'Xin lỗi, không có xe buýt có sẵn tại thời điểm này.',
  },
};
export default labels;
