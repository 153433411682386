import classNames from 'classnames';
import React from 'react';

import AccountWidget from '../../../modules/Core/components/TitleBarWidgets/AccountWidget';
import NotificationsWidget from '../../../modules/Core/components/TitleBarWidgets/NotificationsWidget';
import Iframe from '../../atoms/IFrame/iFrame';
import TitleBar from '../../organisms/TitleBar';
import { TITLEBAR_CAPTION_TYPE } from '../../organisms/TitleBar/TitleBar.types';
import DefaultPage from '../DefaultPage/DefaultPage';
import BottomBar from '../Layout/BottomBar';

import styles from './PageWithIframe.module.css';

export type PageWithIframeProps = {
  title: string;
  children?: React.ReactNode;
  iFrameUrl: string;
  bottomBar?: any;
  hideBackButton?: boolean;
  onIFrameLoad?: () => void;
};
const PageWithIframe = ({
  title,
  children,
  iFrameUrl,
  bottomBar,
  hideBackButton,
  onIFrameLoad,
}: PageWithIframeProps) => {
  return (
    <DefaultPage
      titleBar={
        <TitleBar>
          <TitleBar.Navigation
            caption={{
              title,
              hideBackButton,
              captionType: TITLEBAR_CAPTION_TYPE.DISPLAY_PAGE_TITLE,
            }}
          />
          <TitleBar.Widgets>
            <NotificationsWidget />
            <AccountWidget />
          </TitleBar.Widgets>
        </TitleBar>
      }
    >
      <div className={classNames(styles.iframePage)}>
        <div className={classNames(styles.iframeInner)}>
          {iFrameUrl && (
            <Iframe data-testid="iframe-page" iFrameUrl={iFrameUrl} onLoad={onIFrameLoad} />
          )}
          {children}
        </div>

        {bottomBar && <BottomBar bottomBar={bottomBar} />}
      </div>
    </DefaultPage>
  );
};

export default PageWithIframe;
