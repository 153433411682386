const labels = {
  StaysList: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Past': 'Ref: Past',
    'Current and future stays': 'Current and future stays',
    'filter label: period': 'filter label: period',
    'Ref: Search': 'Ref: Search',
  },
  StayDetails: {
    'Ref: Page title': 'Ref: Page title',
    'Arrival date': 'Arrival date',
    'Departure date': 'Departure date',
    'Room number': 'Room number',
    'Reservation number': 'Reservation number',
    CheckIn: 'CheckIn',
    CheckOut: 'CheckOut',
    CheckIn_1: 'CheckIn_1',
    CheckedIn_1: 'CheckedIn_1',
    CheckOut_1: 'CheckOut_1',
    CheckedOut_1: 'CheckedOut_1',
  },
  StaysSuccess: {
    'Ref: Title': 'Ref: Title',
    'Ref: Body': 'Ref: Body',
    'Ref: Go to home': 'Ref: Go to home',
    'Ref: Provide a feedback': 'Ref: Provide a feedback',
  },
};
export default labels;
