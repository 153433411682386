import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import { keycodes } from '../../../helpers/keycodes';

import { AccordionSectionProps } from './Accordion.types';

import styles from './AccordionSection.module.css';

const KEY_TOGGLER = [keycodes.ENTER, keycodes.SPACE, keycodes.SPACE_DEPRECATED];

const AccordionSection = ({
  section,
  tabIndex,
  isSectionOpen,
  onToggle,
  titleCustomClass,
  inheritStyles,
  "data-testid": testId,
}: AccordionSectionProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [sectionHeight, setSectionHeight] = useState(contentRef?.current?.scrollHeight);

  const handleToggle = () => onToggle(section.id);
  const handleSizeChange = () => {
    setSectionHeight(contentRef?.current?.scrollHeight);
  };

  const currentElement = contentRef.current;
  useEffect(() => {
    contentRef.current && contentRef.current.addEventListener('resize', handleSizeChange);
    return () => {
      currentElement?.removeEventListener('resize', handleSizeChange);
    };
  }, [contentRef, currentElement]);

  const handleKeyPress = (evt: React.KeyboardEvent<HTMLDivElement>): void => {
    const keyCode = evt.key;

    if (KEY_TOGGLER.includes(keyCode)) {
      evt.preventDefault();
      handleToggle();
    }
  };

  return (
    <div key={section.id}>
      <div
        className={classNames(styles.titleWrapper, inheritStyles)}
        role="button"
        onClick={handleToggle}
        onKeyPress={handleKeyPress}
        tabIndex={tabIndex}
        aria-expanded={isSectionOpen}
        data-testid={`section-${testId}`}      
        >
        <h5 className={classNames('headlineXS', titleCustomClass)} data-testid={`section-${testId}-headline`}  >{section.title}</h5>
        <div 
          className={classNames(styles.chevronWrapper, { [styles.chevronOpen]: isSectionOpen })} 
          data-testid={`section-${testId}-open-close-icon`}>
          <ChevronDownIcon />
        </div>
      </div>
      <div
        ref={contentRef}
        data-testid={`section-${testId}-content`}  
        className={classNames(styles.contentWrapper)}
        style={isSectionOpen ? { height: sectionHeight } : { height: '0px' }}
      >
        {section.content}
      </div>
    </div>
  );
};

export default AccordionSection;
