import { TileList } from '../../molecules/Tile';

import { SimpleTileListProps } from './TilesList.types';

export const SimpleTileList = <T extends {}>({
  items,
  twoTilesColumns,
  itemRenderer,
  tileVariant,
  className,
}: SimpleTileListProps<T>) => {
  return (
    <TileList variant={tileVariant} twoTilesColumn={twoTilesColumns} className={className}>
      {items.map((x) => itemRenderer(x))}
    </TileList>
  );
};
