export type SVGProps = React.SVGProps<SVGSVGElement> & {
  title?: string | undefined;
};

export const svgFactory: (
  SVGItem: React.FunctionComponent<SVGProps>
) => React.FunctionComponent<SVGProps> = (SVGItem) => (props) =>
  (
    <SVGItem
      aria-label={props['aria-label'] || props.title}
      aria-hidden={!props.title || !props['aria-label']}
      {...props}
    />
  );
