import { CheckCiclefillIcon } from '../../../assets';
import { DefaultSuccessIllustration } from '../../../assets/illustrations';
import Column from '../../organisms/Column';
import Container from '../../organisms/Container';
import SimpleFormPage from '../SimpleFormPage/SimpleFormPage';

import { SuccessPageProps } from './SuccessPage.types';

import styles from './SuccessPage.module.css';

const SuccessPage = ({
  title,
  children,
  withNavBar = false,
  hideAllWidgets = false,
}: SuccessPageProps) => {
  return (
    <SimpleFormPage
      hasBackLink={false}
      title={title}
      withNavBar={withNavBar}
      hideAllWidgets={hideAllWidgets}
    >
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            <CheckCiclefillIcon className={styles.icon} width="72" height="72" />
            {children}
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <DefaultSuccessIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};
export default SuccessPage;
