import { useSelector } from 'react-redux';

import { SERVICE } from '../../../modules/config';
import { State } from '../../../types/state.types';

import { SetupOptionHookType } from './useSetupOption.types';

export const useSetupOption = (name: string, serviceName: SERVICE): SetupOptionHookType => {
  const setupOptionObject = useSetupOptionList(name, serviceName);

  if (setupOptionObject?.length > 1)
    throw new Error('useSetupOption: Multiple setup options found');

  return setupOptionObject[0];
};

export const useSetupOptionList = (name: string, serviceName: SERVICE): SetupOptionHookType[] => {
  const defaultReturn = [
    {
      isActive: false,
      value: null,
      setupOption: undefined,
    } as SetupOptionHookType,
  ];

  const setupOptionList = useSelector((state: State) => {
    const service = state.Core.services.list.find(
      (el: { name: string }) => el.name === serviceName
    );

    const list =
      service?.setupOptions
        ?.filter((el: { name: string }) => el.name.toUpperCase() === name.toUpperCase())
        .map((option) => {
          return {
            isActive: !!option.value,
            value: option?.value || null,
            setupOption: option,
          } as SetupOptionHookType;
        }) || [];

    return list.length ? list : defaultReturn;
  });

  return setupOptionList;
};
