/**
 * Get zoom level for map to show approximatively ${milesToShow} miles
 * in the map of width ${totalPxWidth}px at latitude ${latitude}
 * @returns zoom level
 */
export const getZoomForPxByMilesForLat = ({
  totalPxWidth,
  milesToShow,
  lat,
}: {
  totalPxWidth: number;
  milesToShow: number;
  lat: number;
}) => {
  return Math.floor(
    Math.log2((97.2713 * Math.cos((lat * Math.PI) / 180) * totalPxWidth) / milesToShow)
  );
};
