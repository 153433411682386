import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { TranslationProps } from '../../../localization/localization.types';
import { TestingProps } from '../../../types';

export type DateRangeValue = [start: Date | null, end: Date | null];

export type DateRangeProps = {
  id: string;
  modalTitle?: string;
  languageCode: string;
  currentDateRangeIso?: [string | null, string | null];
  onChange?: (selectedRange?: DateRangeValue) => void;
} & TranslationProps &
  TestingProps;

export type DateRangeCustomHeaderProps = {
  languageCode: string;
  noOfMonthsToShow: number;
} & Partial<ReactDatePickerCustomHeaderProps> &
  TranslationProps &
  TestingProps;

export const dateRangeFormat: Intl.DateTimeFormatOptions = {
  weekday: undefined,
  month: 'short',
  year: undefined,
};
