import { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Phone24Icon, FeedbackIcon, EmailIcon } from '../../../../assets';
import { State } from '../../../../types/state.types';
import { SERVICE } from '../../../config';
import { checkIfServiceAvailable } from '../../../services';
import { useGetContactsQuery } from '../../api';
import { ContactItem } from '../../components/ContactItem';
import { pagePaths } from '../../config';
import { contactsConfig } from '../../config';
import useContactsTranslation from '../../hooks/useContactsTranslation';
import { Contact } from '../../types/Contacts';

import { HelpdeskIllustration } from '@/assets/illustrations';
import Hint from '@/components/atoms/Hint';
import Card from '@/components/molecules/Card/Card';
import InfoMessage from '@/components/molecules/InfoMessage';
import Column from '@/components/organisms/Column';
import ListPage from '@/components/templates/ListPage/ListPage';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';

import styles from './ContactList.module.css';

const ContactList = () => {
  const { label } = useContactsTranslation(__filename);
  const siteId = useSelector((state: State) => state.Core?.context?.site?.id || '');
  const { data: { siteContacts = [] } = {}, isLoading } = useGetContactsQuery(siteId);
  const { shouldDisplayFeedbackButton } = contactsConfig();
  const isFeedbackServiceAvailable = checkIfServiceAvailable(SERVICE.FEEDBACK);

  const listRenderer = (items: Array<any>): ReactNode => {
    return items.length ? (
      <Card className={styles.contactsListCard} data-testid="card-contacts">
        {items.map((item: Contact) => {
          const interactions = [];

          if (item.phone) {
            interactions.push({
              icon: Phone24Icon,
              text: item.phone,
              external: true,
              to: `tel:${item.phone}`,
              labelKey: 'call (action to place a call)',
            });
          }

          if (item?.email?.includes('@')) {
            interactions.push({
              icon: EmailIcon,
              text: item.email,
              external: true,
              to: encodeURI(`mailto:${item.email}?subject=${item.name}`),
              labelKey: 'write (action to write an e-mail)',
            });
          } else if (isFeedbackServiceAvailable && shouldDisplayFeedbackButton) {
            interactions.push({
              icon: FeedbackIcon,
              text: item.email,
              to: encodeURI(pagePaths['ContactForm'] + '?s=' + item.name),
              labelKey: 'write (action to write a message)',
            });
          }

          return <ContactItem key={item.id} title={item.name} label={label} items={interactions} />;
        })}
      </Card>
    ) : (
      <InfoMessage title={label('Your selection did not return any result.')}></InfoMessage>
    );
  };

  const search = {
    searchableKeys: ['name', 'phone', 'email'],
  };

  const siteContactsWithTestId = useMemo(
    () =>
      siteContacts.map((contact) => ({
        ...contact,
        'data-testid': `contact-${contact.id}`,
      })),
    [siteContacts]
  );

  if (isLoading) return <LoadingPage />;

  return (
    <ListPage
      data-testid="contacts-list-page"
      hasBackLink={false}
      title={label('Ref: Page title')}
      items={siteContactsWithTestId}
      customListRenderer={listRenderer}
      search={search}
      aside={
        <Column.Complementary>
          <HelpdeskIllustration />
        </Column.Complementary>
      }
    >
      <Hint
        data-testid="contacts"
        text={label('Ref: Hint message')}
        className={styles.hintMessage}
      />
    </ListPage>
  );
};

export default ContactList;
