import { APP_NAME } from './constants';
import { getAppName } from './helpers/misc';

export const apiHost = process.env.REACT_APP_API_HOST || '';
export const dspApiPath = process.env.REACT_APP_DSP_API_HOST || '';

export const apiPath = (() => {
  const appName = getAppName(APP_NAME);

  if (appName === APP_NAME.MYVILLAGE) return apiHost + '/api';
  return apiHost + '/api/mobile';
})();

export const myVillageApiPath = apiHost + '/api';

export const clientId = process.env.REACT_APP_CLIENT_ID;
export const shouldUseMockData = process.env.REACT_APP_USE_MOCK_DATA === 'true';

export const isOnline = () => navigator.onLine;

/**
 * This object maps URL to ttl in milliseconds.
 *
 * The maximum cache time for authenticated
 * requests is still constrained by the expiration
 * of the authToken, currenty set at 30'.
 */
export const cacheableUrls = {
  '/v2/notification/inapplist': 60000 * 15,
  '/v3/servicerequest/locationsearch': 60000 * 3600 * 8,
  '/v3/facilities/*/menu': 60000 * 3600,
};

export const breakPointsInPixels = {
  //all values are minimum, i.e. if media width >= VAL then it applies
  XS: 440,
  S: 540,
  M: 720,
  L: 960,
  XL: 1140,
};

export const AuditSyncFrequency = (() => {
  // run sync every hour on dev
  if (apiHost?.includes('.dev.')) return 60 * 60 * 1000;
  return 24 * 60 * 60 * 1000;
})();
