const labels = {
  Success: {
    'Ref: Body':
      'Ihre Eingaben wurden übermittelt und werden geprüft. Vielen Dank, dass Sie sich die Zeit genommen haben, teilzunehmen.',
    'Ref: Tabtitle': 'Umfrage abgeschlossen',
    'Take another survey': 'Nehmen Sie an einer weiteren Umfrage teil',
  },
  Survey: {
    'Ref: Page title': 'Umfragedetails',
    'Please answer all mandatory questions as identify by an asterisk':
      'Umfrage/Bitte beantworten Sie alle Pflichtfragen, die mit einem Sternchen gekennzeichnet sind',
    'Ref: Take another survey': 'Nehmen Sie eine weitere Umfrage an',
    'Ref: Go to Home': 'Zur Startseite',
  },
  SurveysList: {
    'Ref: Page title': 'Umfrage',
    'Ref: Available until': 'Verfügbar bis',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Sie sehen sich derzeit Umfragen für den Standort {site name} an.',
  },
};
export default labels;
