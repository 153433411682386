import classNames from 'classnames';
import momentjs from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Calendar2Icon } from '../../../../assets/icons';
import { ImageDefaultFoodImg } from '../../../../assets/images';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title from '../../../../components/atoms/Title';
import Calendar from '../../../../components/molecules/Calendar';
import Dropdown from '../../../../components/molecules/Dropdown';
import { Tile, TileSkeleton } from '../../../../components/molecules/Tile';
import WidgetPlaceholder from '../../../../components/molecules/WidgetPlaceholder';
import Modal from '../../../../components/organisms/Modal';
import Widget from '../../../../components/organisms/Widget';
import BigWithSmallElementRow from '../../../../components/templates/BigWithSmallElementRow';
import { DATE_FORMAT, SIZE } from '../../../../constants';
import useSite from '../../../Core/hooks/useSite';
import { useGetFoodFacilitiesQuery, useGetMenusQuery } from '../../api';
import { FoodFacility } from '../../components/FoodFacilitiesList';
import { Menu, MenuItem } from '../../components/ProductsList/ProductsList.types';
import { pagePaths } from '../../config';
import { useMenusTranslation } from '../../hooks/useMenusTranslation';

import { mapItemsPerDaypart } from './Widget.helpers';

import { BackgroundWidgetMenusIllustration } from '@/assets/illustrations';

import styles from './Widget.module.css';

const MenusWidget = () => {
  const { label } = useMenusTranslation(__filename);
  const history = useHistory();
  const site = useSite();

  const [selectedFacility, setSelectedFacility] = useState('');
  const [selectedMoment, setSelectedMoment] = useState('');
  const [selectedDate, setSelectedDate] = useState(momentjs().format(DATE_FORMAT));
  const [modalOpen, setModalOpen] = useState(false);

  const { isFetching: isLoadingGetFoodFacilities, data: dataGetFoodFacilities } =
    useGetFoodFacilitiesQuery({ siteId: site?.id || '', useErrorBoundary: false });
  const { isFetching: isLoadingGetMenus, data: dataGetMenus } = useGetMenusQuery(
    {
      facilityId: selectedFacility,
      date: selectedDate,
      useErrorBoundary: false,
    },
    { skip: !selectedFacility }
  );

  const { facilities }: { facilities: FoodFacility[] } = dataGetFoodFacilities || {
    facilities: [],
  };
  const { menus }: { menus: Menu[] } = dataGetMenus || { menus: [] };

  // Selecting the first menu of the retrieved menus, since it's an array
  // TODO: what to do if there are multiple menus? Is that a possibility?
  const [menu] = menus;

  const itemsPerDaypart: { [daypart: string]: MenuItem[] } | undefined = mapItemsPerDaypart(menu);

  const selectedItems = itemsPerDaypart && itemsPerDaypart[selectedMoment];

  const handleFacilityChange = useCallback(({ value }: { value: string }) => {
    setSelectedFacility(value);
  }, []);

  useEffect(() => {
    setSelectedFacility(facilities[0]?.id);
  }, [facilities]);

  useEffect(() => {
    const moments = [...new Set(menu?.dayparts?.map((item) => item.name?.toLowerCase()))];
    setSelectedMoment(moments[0]);
  }, [menu]);

  return (
    <>
      <Widget>
        <Widget.Header illustration={<BackgroundWidgetMenusIllustration />}>
          <Widget.Title>
            <Title>{label('Ref: menus')}</Title>

            {selectedItems?.length ? (
              <Widget.Link to={pagePaths.ProductsList.replace(':facilityId', selectedFacility)}>
                {label('see all (as in see all items)')}
              </Widget.Link>
            ) : null}

            <BigWithSmallElementRow>
              <Dropdown
                value={selectedFacility}
                onChange={handleFacilityChange}
                buttonLook={BUTTON_LOOK.TERTIARY}
                options={facilities.map(({ id, title }) => ({ value: id, label: title }))}
              />

              <Button
                look={BUTTON_LOOK.PRIMARY}
                size={SIZE.SMALL}
                className={styles.filtersButton}
                onClick={() => setModalOpen(true)}
                data-testid="menus-widget-filter"
              >
                <Calendar2Icon />
              </Button>
            </BigWithSmallElementRow>
          </Widget.Title>
        </Widget.Header>
        <Widget.Body>
          {isLoadingGetMenus || isLoadingGetFoodFacilities ? (
            <>
              <TileSkeleton
                className={classNames(styles.skeleton)}
                withoutActions
                data-testid="skeleton-placeholder"
              />
              <TileSkeleton
                className={classNames(styles.skeleton)}
                withoutActions
                data-testid="skeleton-placeholder"
              />
              <TileSkeleton
                className={classNames(styles.skeleton)}
                withoutActions
                data-testid="skeleton-placeholder"
              />
            </>
          ) : (
            <>
              {selectedItems?.slice(0, 3).map(({ id, name, category, priceInText }) => (
                <Tile
                  key={id}
                  image={<ImageDefaultFoodImg />}
                  title={name}
                  stickers={[{ name: category }]}
                  childText={priceInText}
                  onClick={() =>
                    history.push(
                      pagePaths.ProductDetails.replace(
                        ':facilityId/:id/:date',
                        `${selectedFacility}/${id}/${selectedDate}`
                      )
                    )
                  }
                >
                  {' '}
                </Tile>
              ))}
              {!selectedItems?.length && (
                <WidgetPlaceholder title={label('No info yet, come back later')} />
              )}
            </>
          )}
        </Widget.Body>
      </Widget>

      <Modal isOpen={modalOpen} onDismiss={() => setModalOpen(false)}>
        <Calendar
          value={momentjs(selectedDate).toDate()}
          onChange={(date: Date) => {
            setSelectedDate(momentjs(date).format(DATE_FORMAT));
            setModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default MenusWidget;
