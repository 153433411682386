const labels = {
  All: {
    'status: all': 'Semua',
    'status: open': 'Buka',
    'status: pending': 'Tertunda',
    'status: closed': 'Tutup',
    Location: 'Lokasi',
    Subject: 'Subyek',
    Description: 'Permintaan terperinci Anda',
    'Ref: description field label': 'Keterangan',
    'Ref: Title': 'Judul',
    phone_number: 'Nomor telepon Anda',
    request_category: 'Kategori permintaan',
    Attachments: 'Lampiran',
    'request service': 'Layanan Permintaan',
    'New service request': 'Buat permintaan lain',
    'Go to home': 'Pergi ke rumah',
    'Back to this service request': 'Kembali ke Permintaan Layanan ini',
    'Back to service requests': 'Kembali ke Permintaan Layanan',
    Feedback: 'Penilaian',
    'Ref: Request category': 'Kategori',
    Cleaning: 'Pembersihan',
    'Laundry services': 'Layanan binatu.',
    'Grounds maintenance': 'Pemeliharaan alasan',
    'Soft services': 'Layanan lunak',
    'Hard services': 'Layanan keras',
    'Pest control services': 'Layanan Pengendalian Hama',
    'Waste management': 'Penanganan limbah',
    'Asset Lifecycle Services': 'Layanan Lifecycle Asset',
    'There are no updates for this request': 'Tidak ada pembaruan untuk permintaan ini',
    'Transportation Services': 'Layanan transportasi',
    'Ref: you pronoun': '(Anda)',
    'Ref: SR raised for': 'Orang yang terkena dampak berdasarkan permintaan ini',
    'Ref: Select a location': 'Pilih lokasi',
    'Ref: Your request': 'Permintaanmu',
    'Ref: Summary': 'Ringkasan',
    'Ref: site': 'Lokasi',
  },
  Comment: {
    'Ref: Page title': 'Perbarui Kami',
    'Ref: please update us with details':
      'Lebih banyak informasi untuk diberikan kepada kami? Komentar? Semakin kami tahu tentang permintaan Anda, semakin baik kami dapat mendukung Anda.',
  },
  Confirm: {
    'Ref: Page title': 'Konfirmasikan permintaan Anda',
    'Ref: next': 'Kirim',
    summary: 'Ringkasan',
    'Ref: Save as my preferred location': 'Simpan sebagai lokasi pilihan saya',
  },
  Describe: {
    'Ref: Page title': 'Jelaskan permintaan Anda',
    'Ref: Required fields: header': 'Berikan informasi tambahan',
    'Ref: Required fields': 'Informasi berikut harus disediakan di dalam bidang detail:',
    'Ref: Required field: title': 'Judul diperlukan.',
    'Ref: Required field: description': 'Diperlukan deskripsi.',
    'Ref: Required field: phone': 'Diperlukan nomor telepon.',
    'Ref: Required field: category': 'Diperlukan kategori.',
    'Ref: Invalid field: category': 'Kategori yang disediakan tidak valid.',
    'Ref: Request template field label': 'Permintaan populer',
    'Ref: Request template field placeholder': 'Lembar kosong',
    your_request: 'Permintaanmu',
    'Ref: Description message': 'Semakin detail Anda, semakin baik kami dapat mendukung Anda.',
    phone_number:
      'Nomor telepon Anda (silakan gunakan ponsel profesional Anda jika Anda memilikinya)',
    'Ref: Affected person': 'Orang yang terkena dampak berdasarkan permintaan ini',
    'Ref: No affected person results': 'Tidak ada orang yang cocok dengan kriteria yang diminta',
    'Ref: Notification title': 'Anda telah mengubah lokasi',
    'Ref: Notification info': 'Anda sekarang di {site name}',
    'Ref: Add photos': 'Menambahkan foto',
    'Ref: Your location': 'Lokasimu',
  },
  Details: {
    'Ref: Page title': 'Detail permintaan',
    Details: 'Detail',
    Updates: 'Pembaruan.',
    'Update us': 'Perbarui Kami',
    'Ref: timeline': 'Linimasa',
    'Ref: attachments': 'Lampiran',
    'Ref: location': 'Lokasi',
    'Ref: createdOn': 'Dibuat pada',
    'Ref: reference': 'Referensi',
    'Feedback was submitted': 'penilaian dikirim',
    'Ref: sent': 'Terkirim',
    'Ref: received': 'Diterima',
    'Ref: inProgress': 'Sedang berlangsung',
    'Ref: completed': 'Lengkap',
    'Ref: conversation': 'Percakapan',
    'Ref: Priority': 'Prioritas',
    'Ref: Target start date': 'Tanggal Mulai Target',
    'Ref: Target completion date': 'Tanggal Penyelesaian Target',
    'Ref: Priority fields placeholder message':
      'Itu akan diisi setelah divalidasi oleh meja bantuan',
  },
  Failed: {
    'Ref: Body':
      'Terjadi kesalahan dan permintaan layanan Anda tidak dibuat. Jika kesalahan ini berlanjut, silakan hubungi meja bantuan Anda.',
    'Ref: Tabtitle': 'Permintaan layanan gagal membuat',
  },
  Home: { 'Ref: Page title': 'Permintaan' },
  RequestFeedback: {
    'Ref: Page title': 'Penilaian',
    'Ref: Feedback headline': 'Silakan bagikan umpan balik Anda',
    'Ref: Feedback title': 'Umpan Balik Tentang SR # {REF}',
    'Ref: Question: general sentiment':
      'Secara keseluruhan, seberapa puas Anda dengan cara Sodexo menangani permintaan layanan Anda?',
    'Ref: Question: answer quality': 'Bagaimana Anda menilai kualitas layanan yang Anda terima?',
    'Ref: Question: staff interaction': 'Bagaimana Anda menilai interaksi Anda dengan staf kami?',
    'Ref: Question: efficiency': 'Bagaimana Anda menilai efisiensi kami?',
    'Ref: Question: open': 'Komentar',
    'Ref: Open question placeholder':
      'Apa yang kami lakukan dengan baik? Di mana kita bisa meningkatkan?',
    no_comment: 'Tidak ada komentar',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Terima kasih telah meluangkan waktu Anda untuk memberikan komentar, penilaian Anda penting bagi kami untuk meningkatkan pengalaman Anda.',
  },
  Locate: {
    'Ref: Page title': 'Temukan permintaan Anda',
    'Ref: Top bar title': 'Temukan permintaan Anda',
    'Ref: Select this location': 'Pilih lokasi ini',
    drill: 'Jelajahi',
    'Ref: Scanner button': 'Lokasi dengan QR? Pindai itu',
    'Ref: Scanner header': 'Silakan Pindai Lokasi Anda QR',
    'Sorry, this location could not be found.': 'Maaf, lokasi ini tidak dapat ditemukan.',
    'Close scanner': 'Tutup pemindai',
    'Ref: Switch site component intro sentence': 'Anda meminta layanan pada situs {site name}.',
    'Ref: search location by keywords': 'Cari lokasi',
    'Ref: Enter Location name': 'Masukkan nama lokasi',
    'Ref: Enter manual location': 'Masukkan lokasi secara manual',
    'Ref: Location name': 'Nama lokasi',
    'Ref: No results':
      'Kami tidak dapat menemukan lokasi Anda. Anda mungkin ingin memasukkannya secara manual.',
    'Ref: Up one level': 'Naik satu level',
    'Ref: Navigate': 'Menavigasi',
    'Ref: Select': 'Pilih',
    'Ref: Selected Location': 'Lokasi yang dipilih',
    'Ref: Preferred Location': 'Lokasi yang disukai',
    'Ref: Building': 'Bangunan',
    'Ref: Floor': 'Lantai',
    'Ref: Aisle': 'Lorong',
    'Ref: Room': 'Kamar',
    'Ref: Notification title': 'Anda telah mengubah lokasi',
    'Ref: Notification info': 'Anda sekarang di {site name}',
    'Ref: Scanner error header': 'Kesalahan pemindaian',
    'Ref: Scan QR Code': 'Pindai kode QR',
    'Ref: Error body': 'Kode QR ini bukan kode yang valid',
    'Ref: Loading Locations Warning':
      'Harap tunggu saat kami mempersiapkan pengalaman Anda untuk permintaan pertama di situs ini.',
    'Ref: Search Result is limited to:':
      'Hasil pencarian terbatas pada {limit}. Harap perbaiki kriteria pencarian Anda.',
    'Ref: Open the location search field': 'Buka bidang Pencarian Lokasi',
  },
  Success: {
    'Ref: Body':
      'Permintaan layanan Anda dikirimkan dan sedang diproses. Ini akan muncul di aplikasi ini segera. Anda akan menerima catatan saat diselesaikan. Sementara itu, Anda dapat mengikuti statusnya dan menambahkan komentar untuk itu melalui aplikasi ini.',
    'Ref: Tabtitle': 'Permintaan layanan dibuat',
    'Ref: Download app title': 'Sederhanakan permintaan layanan Anda',
    'Ref: Download app subtitle': 'Unduh aplikasi kami dan tingkatkan pengalaman Anda',
    'Ref: Download app button': 'Unduh aplikasinya',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Ini adalah hub dukungan tempat kerja Anda',
    'Ref: Tutorial: tabname': 'Permintaan',
    'Ref: Tutorial: body':
      'Kami di sini untuk membantu Anda. Harap logam satu permintaan per entri.',
    "What's new: title": 'Temukan lokasi Anda dengan mudah',
    "What's new: body":
      'Anda sekarang dapat memindai lokasi QR, menelusuri level lokasi, atau hanya memilih dari saran cerdas kami.',
  },
  Widget: {
    'Ref: Service request': 'Permintaan layanan',
    'Ref: Placeholder body': 'Tidak ada permintaan layanan untuk saat ini',
    'Ref: New request button': 'Permintaan baru',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Anda telah mengubah lokasi',
    'Ref: Notification info': 'Anda sekarang di {site name}',
    'Ref: Welcome': 'Selamat datang! {gelombang_emoji}',
    'Ref: Create service request text': 'Melihat sesuatu yang ingin Anda laporkan?',
    'Create a service request': 'Buat Permintaan Layanan',
    'Ref: Share feedback': 'Bagikan masukan',
    'Ref: Share text': 'Ingin berbagi sesuatu dengan kami?',
    'Go to home': 'Pergi ke rumah',
    'Ref: Error body':
      'Kode QR yang Anda pindai tidak lagi berfungsi. Silakan coba lagi nanti atau hubungi dukungan.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Kesalahan telah terjadi',
    'Ref: Error body':
      'Kode QR yang Anda pindai tidak lagi berfungsi. Silakan coba lagi nanti atau hubungi dukungan.',
    'Go to home': 'Pergi ke rumah',
  },
  ManualDescribe: {
    'Ref: Page title': 'Jelaskan permintaan Anda',
    'Ref: Required fields: header': 'Harap berikan informasi tambahan',
    'Ref: Required fields': 'Informasi berikut harus disediakan di dalam bidang Detail:',
    'Ref: Required field: title': 'Diperlukan ringkasan.',
    'Ref: Required field: description': 'Deskripsi diperlukan.',
    'Ref: Required field: category': 'Diperlukan kategori.',
    'Ref: Required field: buildingText': 'Diperlukan bangunan.',
    'Ref: Invalid field: category': 'Kategori yang disediakan tidak valid.',
    'Ref: Your request': 'Permintaanmu',
    'Ref: Description message': 'Semakin detail Anda, semakin baik kami dapat mendukung Anda.',
    'Ref: Do you have a location QR code': 'Apakah Anda memiliki kode QR lokasi?',
    'Ref: Scan QR Code': 'Pindai kode QR',
    'Ref: Please select category': 'Pilih kategori',
    'Ref: Please select site': 'Pilih situs',
    'Ref: building': 'Bangunan',
    'Ref: room': 'Kamar',
    'Ref: Scan error': 'Kesalahan pemindaian',
    'Ref: The QR code is not valid': 'Kode QR tidak valid',
    'Ref: Add location with QR Scanner': 'Tambahkan lokasi dengan pemindai QR',
    'Ref: Description': 'Keterangan',
    'Ref: Forbidden error':
      'Kami minta maaf, kode QR ini tidak cocok dengan lokasi terdaftar Anda.',
    'Ref: Invalid error': 'Kami minta maaf, tampaknya kode QR ini tidak lagi valid.',
  },
};
export default labels;
