import { ComponentStory, ComponentMeta } from '@storybook/react';

import { UserIcon } from '../../../assets';
import { INPUT_TYPE } from '../../../constants';
import ReduxStoreDecorator from '../../../storybook/helpers/ReduxStoreDecorator';
import { storyTitle } from '../../../storybook/helpers/titleHelper';

import Input from './Input';

export default {
  title: storyTitle('Input', 'atoms'),
  decorators: [ReduxStoreDecorator],
  component: Input,
} as ComponentMeta<typeof Input>;

const Template: ComponentStory<typeof Input> = (args) => <Input {...args} />;

export const Default = Template.bind({});
Default.args = {
  placeholder: 'This is default input placeholder',
  inputMsg: 'This is hint message',
  inputLabel: 'Test input',
  "data-testid": "storybook-default"
};

export const Required = Template.bind({});
Required.args = {
  placeholder: 'This is placeholder',
  inputLabel: 'This field is required',
  inputMsg: 'This field is required',
  required: true,
  "data-testid": "storybook-required"
};

export const WithLabel = Template.bind({});
WithLabel.args = {
  inputLabel: 'This input has a label',
  placeholder: 'This is default input placeholder',
  inputMsg: 'This is hint message',
  "data-testid": "storybook-with-label"
};

export const Valid = Template.bind({});
Valid.args = {
  isValid: true,
  inputLabel: 'This field is not required',
  placeholder: 'This is valid input placeholder',
  value: 'This is valid input',
  "data-testid": "storybook-valid"
};

export const Error = Template.bind({});
Error.args = {
  hasError: true,
  statusMessage: 'This input has error message',
  inputLabel: 'Test input',
  "data-testid": "storybook-error"
};

export const Disabled = Template.bind({});
Disabled.args = {
  disabled: true,
  statusMessage: 'This input is disabled',
  inputLabel: 'Test input',
  "data-testid": "storybook-disabled"
};

export const WithIcon = Template.bind({});
WithIcon.args = {
  inputType: INPUT_TYPE.EMAIL,
  inputLabel: 'E-mail',
  icons: [
    {
      icon: UserIcon,
      position: 'left',
      "data-testid": "user"
    },
  ],
  "data-testid": "storybook-with-icon"
};

export const Password = Template.bind({});
Password.args = {
  inputType: INPUT_TYPE.PASSWORD,
  inputLabel: 'Test input',
  "data-testid": "storybook-password"
};
