import classNames from 'classnames';

import { statusMap } from '../../config';
import { useAccommodationRequestTranslation } from '../../hooks/useAccommodationRequestTranslation';
import { statusType } from '../../types/AccommodationRequest.types';

import { StatusProp } from './RequestStatus.types';

import { CheckIcon, CrossIcon, StatusPendingIcon, RefreshIcon } from '@/assets/icons';
import Status from '@/components/molecules/Status';

import styles from './RequestStatus.module.css';

const statusStyling: { [id: string]: statusType } = {
  open: {
    icon: <RefreshIcon />,
    className: classNames(styles.request_status, styles.request_status_open, styles.statusIcon),
  },
  pending: {
    icon: <StatusPendingIcon />,
    className: classNames(styles.request_status, styles.request_status_pending, styles.statusIcon),
  },
  refused: {
    icon: <CrossIcon />,
    className: classNames(styles.request_status, styles.request_status_refused, styles.statusIcon),
  },
  closed: {
    icon: <CheckIcon />,
    className: classNames(styles.request_status, styles.request_status_closed, styles.statusIcon),
  },
};

const RequestStatus = ({ status }: StatusProp) => {
  const displayStatus = status ? statusMap[status.toLowerCase()] : statusMap.pending;
  const { icon, className } = statusStyling[displayStatus];
  const { label } = useAccommodationRequestTranslation(__filename);

  return (
    <Status
      icon={icon}
      status={label(`Ref: ${displayStatus}`)}
      inheritStyle={className}
      data-testid="accommodation-request-status"
      data-cy="accommodation-request-status"
    />
  );
};

export default RequestStatus;
