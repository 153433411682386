import { LabelWrapperProps } from './List.types';

import styles from './List.module.css';

const LabelWrapper = ({ children, hasLabel, id }: LabelWrapperProps) =>
  hasLabel ? (
    <label className={styles.itemLabelWrapper} htmlFor={id}>
      {children}
    </label>
  ) : (
    <>{children}</>
  );

export default LabelWrapper;
