import momentjs from 'moment';
import { ReactElement, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';

import { MenuIllustration } from '../../../assets/illustrations';
import Column from '../../../components/organisms/Column';
import ListPage from '../../../components/templates/ListPage/ListPage';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { DATE_FORMAT } from '../../../constants';
import { orderConfig, pagePaths } from '../config';
import {
  cacheSelectedFilters,
  convertToMenuItems,
  getDateFilter,
  getDefaultFiltersFromCache,
  redirectToMenuListOrProductsList,
} from '../helpers/menuSelector.helper';
import { getMenuType } from '../helpers/order.helper';
import useFacilityMenuList from '../hooks/useFacilityMenuList';
import useMenuSelectorFilter from '../hooks/useMenuSelectorFilter/useMenuSelectorFilter';
import { useOrderTranslation } from '../hooks/useOrderTranslation';
import {
  MenuListFiltering,
  MenuListProps,
  MenuSelectorListItem,
  MenuSelectorRouteParams,
  MenuType,
} from '../types/menuSelector.types';

import MenuTile from './MenuTile/MenuTile';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import useSite from '@/modules/Core/hooks/useSite';
import { State } from '@/types/state.types';
import { UserSteps } from '@/types/userSteps.types';

const MenuSelector = ({ facilityMenuPath, menuType }: MenuListProps): ReactElement => {
  const { isDateFilteringEnabled, isNavbarEnabled } = orderConfig();
  const { logUserSteps } = useUserStepsInsightsLogging();

  const { menuId, date } = useParams<MenuSelectorRouteParams>();
  const { path } = useRouteMatch();

  const isRedirection =
    path === pagePaths.MenuRedirection || path === pagePaths.ViewableMenuRedirection;

  const menuDateParams = momentjs(date, DATE_FORMAT, true);
  const menuDate =
    menuDateParams.isValid() && menuDateParams.isAfter(momentjs().add(-1, 'days'))
      ? date
      : undefined;

  const defaultFiltersFromCache = getDefaultFiltersFromCache();
  const availableServices = useSelector((state: State) => state.Core?.services.list);
  const calculatedMenuType = getMenuType(availableServices, menuType === MenuType.NonOrderable);
  const { label } = useOrderTranslation(__filename);

  const site = useSite();

  const {
    menuDate: selectedDate,
    setMenuDate: updateDate,
    filtering,
    setFiltering,
  } = useMenuSelectorFilter(menuDate, defaultFiltersFromCache);

  const { facilityMenus: facilities, isLoading: menusLoading } = useFacilityMenuList(selectedDate);
  const history = useHistory();

  const listElements: MenuSelectorListItem[] = useMemo(
    () =>
      convertToMenuItems({
        facilities,
      }),
    [facilities]
  );

  useEffect(
    function redirectToMenu() {
      if (isRedirection)
        redirectToMenuListOrProductsList({
          listElements,
          selectedDate,
          menuId,
          history,
          menuType: calculatedMenuType,
        });
    },
    [history, listElements, menuId, selectedDate, isRedirection, calculatedMenuType]
  );

  useEffect(() => {
    logUserSteps({ event: UserSteps.MenuList });
  }, [logUserSteps]);

  const dateFilter = useMemo(
    () => getDateFilter({ label, listElements, selectedDate, updateDate }),
    [label, listElements, selectedDate, updateDate]
  );

  const listFilters = {
    filters: dateFilter,
    initialFiltering: filtering,
    handleFilteringChange: (filtering: MenuListFiltering) => {
      setFiltering(filtering);
      cacheSelectedFilters(filtering);
    },
  };

  const filter = {
    ...(isDateFilteringEnabled ? { filter: listFilters } : {}),
  };

  const search = {
    searchableKeys: ['facilityTitle'],
    placeholder: label('Ref: Search products list'),
  };

  const facilityList: ReactElement = (
    <>
      <ListPage
        data-testid="menu-selector-list"
        hasBackLink={false}
        title={label('Ref: Menu Page title')}
        items={listElements}
        {...filter}
        withNavBar={isNavbarEnabled}
        hideFilterTitle={true}
        search={search}
        aside={
          <Column.Complementary>
            <MenuIllustration />
          </Column.Complementary>
        }
        itemRenderer={(item: MenuSelectorListItem) => {
          const props = {
            ...item,
            facilityMenuPath,
            selectedDate,
          };
          return <MenuTile key={item.id} {...props} />;
        }}
      />
    </>
  );

  if (menusLoading || !site) return <LoadingPage />;

  return facilityList;
};

export default MenuSelector;
