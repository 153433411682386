const labels = {
  Success: {
    'Ref: Body':
      'Dziękujemy za wypełnienie tej ankiety, Twój czas jest cenny, ponieważ te ankiety pomagają w ulepszaniu naszych usług.',
    'Ref: Tabtitle': 'Ankieta zakończona',
    'Take another survey': 'Wypełnij kolejną ankietę',
  },
  Survey: {
    'Ref: Page title': 'Szczegóły ankiety',
    'Please answer all mandatory questions as identify by an asterisk':
      'Ankieta / Proszę odpowiedzieć na wszystkie obowiązkowe pytania oznaczone gwiazdką',
    'Ref: Take another survey': 'Wypełnij kolejną ankietę',
    'Ref: Go to Home': 'Przejdź do strony głównej',
  },
  SurveysList: {
    'Ref: Page title': 'Ankiety',
    'Ref: Available until': 'Dostępne do czasu',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Przeglądasz obecnie ankiety dla lokalizacji {site name}.',
  },
};
export default labels;
