const labels = {
  All: {
    Feedback: 'Обратная связь',
    'Ref: Switch site component intro sentence': 'Ваш отзыв будет связан с сайтом {site name}.',
    Category: 'Категория',
    Subject: 'Тема',
    Comment: 'Комментарий',
    'Date of your experience': 'Дата вашего опыта *',
    'Ref: Hint': 'У вас есть проблема или работа, которая требует исправления?',
    'Ref: Log Request': 'Войти запрос',
    'Ref: Describe issue': 'Расскажите, пожалуйста, о своем опыте',
    Date: 'Свидание',
    'Ref: Share your opinion header': 'Поделитесь своим мнением о своем опыте на работе',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Ресторан / кафе',
    'Desk / workstation area': 'Стойка / зона рабочей станции',
    'Meeting room facilities': 'Услуги для конференц -зала',
    'Coffee/tea facilities (pantry)': 'Кофе/чая (кладовая)',
    'Restrooms / washrooms': 'Уборные / туалетные комнаты',
    'Reception / Security': 'Прием / безопасность',
    'Conference center': 'Конференц-центр',
    'Fitness center': 'Фитнес-центр',
    Mailroom: 'Почтовый зал',
    'Bicycle room': 'Велосипедная комната',
    'Shuttle services': 'Шаттл Услуги',
    Lockers: 'Шкафчики',
    Helpdesk: 'Справочная служба',
  },
  Create: {
    'Tell us!': 'Скажи нам!',
    'Ref: All Ears': 'Мы все уши!',
    'Ref: Provide Description': 'Просьба представить описание',
    'Ref: Fill-in-the-blank sentence': 'Я хочу предоставить {0} в отношении {1}',
    'Ref: Link text to service request module': 'Запрос или проблема? Войдите здесь!',
    'Complaint type': 'Жалоба {emoji}',
    'Feedback type': 'Обратная связь {emoji}',
    'Compliment type': 'Комплимент {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Произошла ошибка, и ваша обратная связь не была отправлена. Если эта ошибка сохраняется, пожалуйста, свяжитесь с вашим Справочная служба.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Ваш отзыв был представлен. Наша команда поддержки на рабочем месте будет внимательно прочитать ее. При необходимости мы вернемся к вам.',
    'New feedback': 'Новые отзывы',
  },
  CreateFlow: {
    Description: 'Описание',
    'Ref: Provide Description': 'Пожалуйста, предоставьте дату и описание',
    'Ref: I would like to provide a': 'Я хотел бы предоставить',
    'Ref: in regards to': 'в связи с',
    'Ref: &': 'И',
    'Ref: at': 'в',
    'Ref: on the': 'на',
  },
};
export default labels;
