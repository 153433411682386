import dspApi from '../../../services/api/dspApi';
import mockResponse from '../mocks/api';

import {
  ServiceRequestLightListResponse,
  CreateRequestArgs,
  GetServiceRequestLightDetailsArgs,
  ServiceRequestLightDetailResponse,
} from './api.types';

import { AttachmentData } from '@/components/organisms/Attachments/Attachments.types';

const serviceRequestLightApi = dspApi
  .enhanceEndpoints({ addTagTypes: ['service_request_light'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getServiceRequestLightList: build.query<ServiceRequestLightListResponse[], {}>({
        query: () => ({
          url: '/v1/servicerequestslight',
          method: 'GET',
          params: {},
          mockData: mockResponse.getServiceRequestLightList,
        }),
        providesTags: ['service_request_light'],
      }),
      getServiceRequestLightDetail: build.query<
        ServiceRequestLightDetailResponse,
        GetServiceRequestLightDetailsArgs
      >({
        query: ({ id }) => ({
          url: `/v1/servicerequestslight/${id}`,
          method: 'GET',
          params: {},
          mockData: mockResponse.getServiceRequestLightList,
        }),
        providesTags: ['service_request_light'],
      }),
      createRequest: build.mutation<void, CreateRequestArgs>({
        query: (data) => ({
          url: '/v1/servicerequestslight',
          method: 'POST',
          data: {
            ...data,
            attachments: data.attachments?.map((attachment: AttachmentData) => {
              return { fileName: attachment.file.name, content: attachment.data };
            }),
          },
          mockData: mockResponse.createRequestLight,
        }),
        invalidatesTags: ['service_request_light'],
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetServiceRequestLightListQuery,
  useCreateRequestMutation,
  useGetServiceRequestLightDetailQuery,
} = serviceRequestLightApi;
export default serviceRequestLightApi;
