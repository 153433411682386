const labels = {
  All: {
    'Ref: See item': 'Szczegóły',
    'Ref: Print': 'Wydrukuj paragon',
    'Ref: Email': 'Otrzymaj paragon na e-mail',
    'Ref: Receipt Modal message': 'Jak chciałbyś otrzymać swój paragon?',
    'Ref: Loyalty Reward': 'Nagroda lojalnościowa',
    'Ref: Equation for stamps per item': '1 zakup = 1 pieczątka',
    'Ref: Paid by employer': 'Kwota opłacona przez Twojego pracodawcę',
    'Ref: Paid by user': 'Kwota opłacona przez Ciebie',
  },
  ProductsList: {
    'Ref: Page title': 'Lista produktów',
    'Ref: Facility details': 'Szczegóły dotyczące obiektu',
    'Ref: Search products list': 'Wyszukaj produkty',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Kategoria',
    'Ref: all': 'Wszystkie',
    'Ref: today': 'Dziś',
    'Ref: tomorrow': 'Jutro',
    'Ref: see more': 'Zobacz więcej',
    'Ref: see less': 'Zobacz mniej',
    'Ref: reset': 'Zresetuj',
    'Ref: View in cart': 'Zobacz w koszyku',
    'Ref: You have changed location': 'Zmieniłeś lokalizację',
    'Ref: You are now at': 'Jesteś teraz w',
    'Ref: Menu card': 'Menu',
    'Ref: Table': 'Numer stolika',
    'Service unavailable': 'Usługa niedostępna',
    'Ref: Ordering unavailable':
      'Uwaga! Zamówienie w tej lokalizacji jest w tej chwili niedostępne, spróbuj później.',
    'Ref: Filters': 'Filtry (alergeny i więcej)',
  },
  Order: { 'Ref: Page title': 'Zamów' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menu',
    'Ref: today': 'Dziś',
    'Ref: tomorrow': 'Jutro',
    'Ref: Search products list': 'Wyszukaj menu po nazwie',
    'Ref: You have changed location': 'Zmieniłeś lokalizację',
    'Ref: You are now at': 'Jesteś teraz w',
  },
  ProductDetails: {
    'Ref: Page title': 'Szczegóły Produktu',
    'Ref: Serving Size': 'Wielkość porcji',
    'Ref: Nutrition': 'Informacje żywieniowe',
    'Ref: Nutrition adults': 'Dorośli potrzebują około 2000 kcal dziennie',
    'Ref: Nutrition Calories': 'Kalorie',
    'Ref: Nutrition Calcium': 'Wapń',
    'Ref: Nutrition Fat': 'Tłuszcze',
    'Ref: Nutrition Saturated Fat': 'Tłuszcz nasycony',
    'Ref: Nutrition Carbohydrates': 'Węglowodany',
    'Ref: Nutrition Sugar': 'Cukier',
    'Ref: Nutrition Protein': 'Białko',
    'Ref: Nutrition Sodium': 'Sód',
    'Ref: Nutrition cholesterol': 'Cholesterol',
    'Ref: Nutrition dietaryFiber': 'Błonnik pokarmowy',
    'Ref: Nutrition iron': 'Żelazo',
    'Ref: Nutrition potassium': 'Potas',
    'Ref: Nutrition vitaminA': 'Witamina A.',
    'Ref: Nutrition vitaminC': 'Witamina C',
    'Ref: Additives': 'Dodatki',
    'Ref: Emission': 'Emisje CO2.',
    'Ref: This product generates': 'Ten produkt generuje',
    'Ref: of CO2': 'CO2.',
    'Ref: emissions, which equals a': 'emisje, które są równe',
    'Ref: car trip': 'podróży autem',
    'Ref: Ingredients': 'Składnik przepisu',
    'Ref: Allergens': 'Alergeny',
    'Ref: Always there': 'Zawsze występują',
    'Ref: Allergens selected': 'Alergeny wybrane na filtrze:',
    'Ref: minimum required': 'wymagane minimum',
    'Ref: you have to add at least': 'Musisz przynajmniej dodać',
    'Ref: ingredients': 'Składniki',
    'Ref: Ingredient': 'składnik',
    'Ref: Free': 'Darmowy',
    'Ref: Based on customization': 'Na podstawie Twoich preferencji',
    'Ref: Additives eggs': 'jajka',
    'Ref: Additives fish': 'ryba',
    'Ref: Additives milk': 'mleko',
    'Ref: Additives nuts': 'orzechy',
    'Ref: Additives wheat': 'pszenica',
    'Ref: Additives peanuts': 'orzeszki ziemne',
    'Ref: Additives soy beans': 'fasola sojowa',
    'Ref: Additives sesame seeds': 'ziarenka sezamu',
    'Ref: Review': 'Recenzja',
    'Ref: Per portion': 'Na porcję',
    'Ref: Per 100g': 'Na 100 g',
    'Ref: Allergen selected': 'Alergen wybrany w filtrach wyszukiwania',
    'Ref: Fitbit Log': 'Zaloguj się do Fitbit',
    'Ref: age verification required on delivery': 'Weryfikacja wieku wymagana podczas dostawy',
    'Ref: alcohol content notification':
      'Zamawiając jeden z tych produktów, oświadczasz, że masz 18 lat lub więcej. Nadużywanie alkoholu jest niebezpieczne dla Twojego zdrowia.',
    'Ref: default': 'Domyślnie',
    'Ref: Loyalty Card Title Stamp':
      'Uzyskasz {no_stamps} {stamp_form}, jeśli zamówisz ten produkt',
    'Ref: Loyalty Card Description':
      '{stamp_form} zostanie dodane do programu stałego klienta {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program znaczków lojalnościowych pozwala zbierać pieczątki i wymieniać je na nagrody.',
    'Ref: Loyalty Modal Description':
      'Kiedy kupisz <b> {product_name}, 1 pieczątka </b> zostanie dodany do <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'i',
    'Ref: Stamp': 'Pieczątka',
    'Ref: Stamps': 'Pieczątki',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programy',
    'Ref: Loyalty Stamps': 'Pieczątki lojalnościowe',
    'Ref: See Loyalty Stamps': 'Zobacz pieczątki lojalnościowe',
    Favorite: 'Ulubione',
    'Ref: In favorites': 'Produkt jest już na liście ulubionych. Kliknij, aby go usunąć.',
    'Ref: Not in favorites':
      'Produkt nie jest jeszcze na liście ulubionych. Kliknij, aby go dodać.',
    'Ref: Added to favorites': 'Produkt został dodany do listy ulubionych.',
    'Ref: Removed from favorites': 'Produkt został usunięty z listy ulubionych.',
    'Ref: From': 'Od',
  },
  OrderHistory: { 'Ref: Orders': 'Zamówienia', 'Ref: Page Title': 'Moje zamówienia' },
  MenuTile: { 'Ref: Scan&Go': 'Zeskanuj i kup', 'Ref: View only': 'Tylko podgląd' },
  ProductTile: {
    'Ref: Allergens': 'Alergeny',
    'Ref: Presence of allergen': 'Obecność alergenu',
    'Ref: Scan': 'Skanowanie',
    'Ref: From': 'Od',
    'Ref: Already in product favorite list. Click to remove it.':
      'Produkt jest na liście ulubionych. Kliknij, aby go usunąć.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Obecnie produkt nie jest dodany do listy ulubionych. Kliknij, aby go dodać.',
    'Ref: Free': 'Bezpłatny',
  },
  MenusWidget: {
    'Ref: no menu': 'Brak dostępnych menu dla tego momentu',
    'Ref: Modal title': 'Menu na dzień',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Odbiór osobisty',
    'Ref: Eat-in': 'Na miejscu',
    'Ref: Scan&Go': 'Zeskanuj i kup',
    'Ref: Delivery': 'Dostawa',
    'Ref: Total': 'Suma',
    'Ref: How was facility name service': 'Jaka była usługa {facility_name}?',
    'Ref: How do you rate the service': 'Jak oceniasz usługę?',
  },
};
export default labels;
