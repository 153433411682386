import { Network } from '@capacitor/network';
import { ionFireEvent } from '@ionic/react-test-utils';
import { act, cleanup, screen } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import QuestionAttachments from '../QuestionAttachments';

const props = {
  label: (s: string) => s,
  isComplete: true,
};

const attachments = [
  { id: '123', name: 'file.jpg', size: 123456 },
  { id: '456', name: 'file_2.jpg', size: 123456 },
];

jest.mock('react-hook-form', () => ({
  useController: () => ({
    field: {
      value: attachments.map(({ id, name, size }) => ({
        id,
        file: { name, size },
      })),
    },
    fieldState: {},
    formState: {},
  }),
}));

jest.mock('@capacitor/network', () => ({
  ...jest.requireActual('@capacitor/network'),
  Network: {
    addListener: jest.fn(),
    removeAllListeners: () => ({ catch: jest.fn() }),
    getStatus: jest.fn(),
  },
}));

jest.mock('@/helpers/hooks/Analytics/useAnalytics', () => ({
  __esModule: true,
  default: () => ({
    useAnalytics: jest.fn(),
    trackToasterDisplayEvent: jest.fn(),
    trackButtonClickEvent: jest.fn(),
  }),
}));

describe('QuestionAttachments', () => {
  afterAll(() => cleanup());

  beforeEach(() => {
    (Network.getStatus as jest.Mock).mockReturnValue({ connected: true });
  });

  describe('Render', () => {
    renderComponent(QuestionAttachments, { ...props, attachments });

    it('should render attachment files', async () => {
      const attachmentPreviewButtons = screen.queryAllByTestId(
        'question-attachments-attachments-container-thumb-btn'
      );
      expect(attachmentPreviewButtons.length).toEqual(2);
    });
  });

  describe('Offline', () => {
    beforeEach(() => {
      (Network.getStatus as jest.Mock).mockReturnValue({ connected: false });
    });

    renderComponent(QuestionAttachments, { ...props, attachments });

    it('should not allow attachment preview', async () => {
      const attachmentPreviewButtons = screen.queryAllByTestId(
        'question-attachments-attachments-container-thumb-btn'
      );

      await act(async () => {
        ionFireEvent.click(attachmentPreviewButtons[0]);
      });

      expect(await screen.findByText('Ref: attachment preview error')).toBeInTheDocument();
    });
  });
});
