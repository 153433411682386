import { useEffect, useState, ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';

import { SelectOption } from './Select.types';

export const customStyles = {
  menu: (provided: any) => {
    const { boxShadow, borderRadius, borderColor, zIndex, ...leftProps } = provided;
    return leftProps;
  },
  option: (provided: any) => {
    const { color, backgroundColor, ...leftProps } = provided;
    return leftProps;
  },
  singleValue: (provided: any) => {
    const { color, ...leftProps } = provided;
    return leftProps;
  },
  control: (provided: any) => {
    const { backgroundColor, boxShadow, borderRadius, borderColor, ...leftProps } = provided;
    delete leftProps['&:hover'];
    return leftProps;
  },
  placeholder: (provided: any) => {
    const { color, ...leftProps } = provided;
    return leftProps;
  },
};

export const useSelectOptionSwitch = (
  options: SelectOption[],
  value: SelectOption | string | undefined | null
) => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined | null>();

  // If value is string, find proper option for label rendering
  useEffect(() => {
    switch (typeof value) {
      case 'string':
        setSelectedOption(options?.find((o) => o.value === value) || undefined);
        break;
      case 'number':
        setSelectedOption(options?.find((o) => o.value.toString() === value) || undefined);
        break;
      default:
        setSelectedOption(value);
    }
  }, [options, value]);

  return { selectedOption };
};

// TODO proper typing from react-select
export const highlightSearchedWords = (
  data: SelectOption,
  {
    inputValue,
  }: {
    inputValue: string;
  }
): ReactNode => {
  const label =
    inputValue !== ''
      ? reactStringReplace(data.label, new RegExp('(' + inputValue + ')', 'gi'), (match, i) => (
          <strong key={i}>{match}</strong>
        ))
      : data.label;
  return <span>{label}</span>;
};
