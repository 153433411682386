import { DefaultFailIllustration, ErrorCircle } from '../../../assets/illustrations';
import Column from '../../organisms/Column';
import Container from '../../organisms/Container';
import SimpleFormPage from '../SimpleFormPage/SimpleFormPage';

import { ErrorPageProps } from './ErrorPage.types';

import styles from './ErrorPage.module.css';

const ErrorPage = ({ title, children }: ErrorPageProps) => {
  return (
    <SimpleFormPage hasBackLink={false} title={title} withNavBar={false}>
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            <ErrorCircle />
            {children}
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <DefaultFailIllustration />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};
export default ErrorPage;
