const labels = {
  All: {
    'starts at': 'alkaa',
    'ends at': 'päättyy',
    location: 'Sijainti',
    'coming date': 'Tuleva päivä',
    'Ref: Currently event not preferred. Click to make preferred':
      'Tällä hetkellä tapahtuma ei ole suositeltavaa. Napsauta tehdäksesi mieluummin',
    'Ref: Currently event preferred. Click to make not preferred':
      'Tällä hetkellä tapahtuma mieluummin. Napsauta tehdäksesi mieluummin',
  },
  EventDetails: {
    details: 'Yksityiskohdat',
    'Ref: Page title': 'Tapahtuman Yksityiskohdat',
    'Event dates': 'Tapahtumapäivät',
    'See online:': 'Katso verkossa:',
    'See all dates': 'Katso kaikki päivämäärät',
    'Remove from my selection': 'Poista valintani',
    'Add to my selection': 'Lisää suosikkiini',
    'Ref: explain calendar usage':
      'Tapahtumapäivät korostetaan alla olevassa kalenterissa. Älä missaa tapahtumia joista pidät! Voit ladata kutsun napsauttamalla tapahtumapäivää kalenterissa. Avaa sitten ladattu tiedosto lisätäksesi tapahtuma etukäteen kalenteriisi.',
    'Ref: Favorite': 'Suosikki',
    'Ref:Feedback': 'Palaute',
    'Ref: Event site different title': 'Tämä tapahtuma ei kuulu nykyiseen sivustoosi',
    'Ref: Event site different description':
      'Katsot tapahtumaa, joka ei kuulu nykyiseen sivustoosi. Et voi nähdä sitä tapahtumien luettelossa.',
    'Ref: See all dates': 'Katso kaikki päivämäärät',
    'Ref: From': '-Sta',
    'Ref: to': '-lla',
    'Ref: Coming date': 'Tulemispäivä',
    'Ref: Add to calendar': 'Lisää kalenteriin',
  },
  EventsList: {
    'Ref: Page title': 'Tapahtumat',
    'All dates': 'Kaikki päivämäärät',
    'All events': 'Kaikki tapahtumat',
    'Ref: Search': 'Hae',
    'My selection': 'Valintani',
    'filter label: date': 'Päivämäärä',
    'filter label: coming events': 'tulevat tapahtumat',
    'filter label: past events': 'viime tapahtumat',
    'filter label: interest': 'Mieltymykset',
  },
  Widget: {
    'Ref: Carousel title': 'Tapahtumat',
    'No info yet, come back later': 'Ei vielä tietoja, tule takaisin myöhemmin 😉',
  },
};
export default labels;
