import { getCoreRowModel, flexRender, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import useWindowSize from '../../../helpers/hooks/useWindowSize';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';

import { TableProps } from './Table.types';

import TableSkeleton from '@/components/atoms/Table/TableSkeletonRow';
import InfoMessage from '@/components/molecules/InfoMessage';

import styles from './Table.module.css';

const Table = <TData extends object>({
  data,
  columns,
  onRowClick,
  onInfiniteScroll,
  isLoading,
  hasMoreItems,
  isFetchingNextPage,
}: TableProps<TData>) => {
  const { label } = useCustomTranslation();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMoreItems,
    onLoadMore: onInfiniteScroll,
  });

  const { width } = useWindowSize();
  const isMobile = width < 768;

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={styles.row}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className={classNames(styles.th, 'bodyMBold')}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          style={{ display: isLoading && !isFetchingNextPage ? '' : 'none' }}
          className={styles.tbody}
          data-testid="table-loading"
        >
          <TableSkeleton columnsCount={columns.length} />
        </tbody>
        <tbody
          className={styles.tbody}
          style={{ display: isLoading && !isFetchingNextPage ? 'none' : '' }}
        >
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={classNames(styles.row, {
                [styles.clickableRow]: !!onRowClick,
              })}
              data-testid={`table-row-${row.id}`}
              {...(!!onRowClick
                ? {
                    onClick: () => onRowClick(row.original),
                    onKeyDown: (e) =>
                      (e.code === 'Enter' || e.code === 'Space') && onRowClick(row.original),
                    role: 'button',
                    tabIndex: 0,
                  }
                : {})}
            >
              {row.getVisibleCells().map((cell, index) => {
                return (
                  <td key={cell.id} className={classNames(styles.cell, 'bodySDefault')}>
                    <div className={classNames(styles.threeLinesEllipsis)}>
                      {isMobile && index !== 0 && (
                        <strong>{cell.column.columnDef.header?.toString()}: </strong>
                      )}
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
          {isLoading && isFetchingNextPage && <TableSkeleton columnsCount={columns.length} />}
        </tbody>
      </table>
      <div
        className={classNames(styles.scrollTrigger)}
        ref={sentryRef}
        data-testid="load-more-trigger"
      />
      {!isLoading && data.length === 0 && (
        <InfoMessage title={label('Your selection did not return any result.')} />
      )}
    </div>
  );
};

export default Table;
