import { screen, cleanup, fireEvent } from '@testing-library/react';
import { act } from 'react-dom/test-utils';

import { APP_DISPLAY_NAME } from '../../../../constants';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultState as coreDefaultState } from '../../../Core/reducers/coreReducer';

import EventDetails from './Details';

jest.setTimeout(100000);

jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: jest.fn(),
  }),
}));

const mockEventDetails = {
  id: '3c849437-f62c-ed11-97b0-0003ff4c9d2a',
  name: 'Event - Check external link',
  type: '',
  description: 'Test if external link is displayed for events.',
  startDate: '2022-09-05T14:06:00Z',
  endDate: '2022-11-30T19:06:00Z',
  publishedDate: '2022-09-04T18:30:00Z',
  eventDates: ['2022-11-28', '2022-12-06'],
  location: 'FR',
  link: {
    url: 'https://ifmportal-test.sodexonet.com/',
    text: 'Link to iFM Portal',
  },
  preferences: [
    {
      id: 'b5d3e004-1529-e911-9461-0003ff4cb3ff',
      name: 'Vegetarian',
    },
    {
      id: '908ac037-ec55-e911-b047-0003ff4cc1c5',
      name: 'Test-Diet',
    },
    {
      id: 'cef1c266-af4e-e911-867a-0003ff4cd72c',
      name: 'Theme Nights',
    },
  ],
  images: [],
  isUserInterested: true,
  reactions: {
    reactions: [],
    me: null,
    total: 0,
  },
};

jest.mock('@awesome-cordova-plugins/calendar', () => ({
  createEventInteractively: jest.fn(),
}));

const site = {
  id: '3c849437-f62c-ed11-97b0-0003ff4c9d2a',
  name: 'Testing site',
};

// TODO change once core default state and types are in sync
const coreState: any = {
  ...coreDefaultState,
};
coreState.context.site = site;

describe('EventDetails', () => {
  const env = global.process.env;
  afterAll(() => cleanup());

  describe('ContentDetails - MyWay&Wando', () => {
    beforeEach(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
    });

    describe('Initial render', () => {
      renderComponent(
        EventDetails,
        {
          eventDetails: { ...mockEventDetails, markdownDecoded: true, markdown: '# Markdown' },
        },
        coreState
      );

      it('should have all fields', () => {
        expect(screen.getAllByText('FR')).toBeTruthy();
        expect(screen.getAllByText('Event - Check external link')).toBeTruthy();
        expect(screen.getByText('Test if external link is displayed for events.')).toBeTruthy();
        expect(screen.getByTestId('secondary-actions')).toBeTruthy();
        expect(screen.getByTestId('share-button')).toBeTruthy();
        expect(screen.getByText('Markdown')).toBeTruthy();
        expect(screen.getByTestId('bookmark-button')).toBeTruthy();
      });
    });
  });
  describe('ContentDetails - MyVillage', () => {
    beforeEach(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE };
    });

    describe('Initial render', () => {
      renderComponent(
        EventDetails,
        {
          eventDetails: mockEventDetails,
        },
        coreState
      );

      it('should have all fields', () => {
        expect(screen.getAllByText('FR')).toBeTruthy();
        expect(screen.getAllByText('Event - Check external link')).toBeTruthy();
        expect(screen.getByText('Test if external link is displayed for events.')).toBeTruthy();
        expect(screen.getByTestId('secondary-actions')).toBeTruthy();
        expect(screen.getByTestId('share-button')).toBeTruthy();
        expect(screen.queryByText('Markdown')).toBeNull();
      });
    });
  });
  describe('When sharing', () => {
    const mockClipboard = {
      writeText: jest.fn().mockImplementation(() => {
        Promise.resolve();
      }),
    };
    Object.assign(navigator, {
      clipboard: mockClipboard,
    });

    renderComponent(
      EventDetails,
      {
        eventDetails: mockEventDetails,
      },
      coreState
    );

    it('should display modal with sharing options', async () => {
      const shareButton = screen.getByTestId('share-button');
      fireEvent.click(shareButton);
      expect(await screen.findByTestId('share-modal')).toBeTruthy();
      expect(screen.getByTestId('button-share-to-email')).toBeTruthy();
      expect(screen.getByTestId('button-share-copy-clipboard')).toBeTruthy();
    });

    it('should copy the link to clipboard (memory)', async () => {
      const shareButton = screen.getByTestId('share-button');
      fireEvent.click(shareButton);
      const modal = await screen.findByTestId('share-modal');
      expect(modal).toBeTruthy();
      const copyToMemory = await screen.findByTestId('button-share-copy-clipboard');
      expect(copyToMemory).toBeTruthy();

      await act(async () => {
        fireEvent.click(copyToMemory);
      });

      expect(mockClipboard.writeText).toHaveBeenCalled();
    });
  });

  describe('Event is on different site', () => {
    renderComponent(
      EventDetails,
      {
        eventDetails: mockEventDetails,
        onDifferentSite: true,
      },
      coreState
    );

    it('should display notification', () => {
      const notification = screen.getByTestId('notification-top-content');
      expect(notification).toHaveTextContent('This Event does not belong to your current site');
    });
  });
  // TODO add test for setting the interest
});
