import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import { INPUT_TYPE, SIZE } from '../../../constants';
import useToggle from '../../../helpers/hooks/useToggle';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Card from '../../molecules/Card/Card';
import { BUTTON_LOOK } from '../Button';
import Button from '../Button/Button';
import Input from '../Input/Input';

import { SearchBarProps } from './SearchBar.types';

import { SearchIcon } from '@/assets/icons';

import styles from './SearchBar.module.css';

const SearchBar = ({
  searchString,
  above,
  right,
  below,
  placeholder,
  withCardWrapper,
  handleChange,
  collapsible = false,
  debounce = 333,
  readOnly = false,
  withBackground,
  handleClick,
  handleKeyDown,
  focusOnRender = false,
  'data-testid': testId,
  srOnlyText,
}: SearchBarProps) => {
  const { label } = useCustomTranslation();
  const {
    state: searchBarDisplayed,
    toggleOn: openSearchBar,
    toggleOff: hideSearchBar,
  } = useToggle(false);

  const inputRef = useRef<HTMLInputElement>();

  const handleInputChange = (val: string) => {
    if (val !== undefined && handleChange) handleChange(val);
  };

  const handleIconOnClick = () => {
    openSearchBar();
    setTimeout(() => inputRef.current?.focus(), 100);
  };

  const collapseAndClearSearch = () => {
    hideSearchBar();
    if (handleChange) handleChange('');
  };

  const handleKeyDownOnFocusedElement = (e: any) => {
    if (handleKeyDown) {
      if (e.code === 'Enter' || e.code === 'Space') {
        handleKeyDown();
      }

      if (e.code === 'Tab') return;

      e.preventDefault();
    }
  };

  useEffect(() => {
    focusOnRender && window.setTimeout(() => inputRef.current?.focus?.(), 800);
  }, [focusOnRender]);

  const searchInput = (
    // As this search input acts as button and search those need to be supressed as those are handled in Input component
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    <div
      onClick={handleClick}
      onKeyDownCapture={handleKeyDownOnFocusedElement}
      className={classNames(styles.searchInputWrapper, {
        [styles.hidden]: collapsible && !!right?.length && !searchBarDisplayed,
      })}
      data-testid={`${testId}-search-input-wrapper`}
    >
      <Input
        readOnly={readOnly}
        inputType={INPUT_TYPE.SEARCH}
        enterkeyhint="search"
        inputmode="search"
        minlength={1}
        placeholder={placeholder ? placeholder : label('Search')}
        value={searchString}
        onInputChange={handleInputChange}
        debounce={debounce}
        data-cy="input-search"
        icons={[
          { icon: SearchIcon, position: 'left', className: styles.icon, 'data-testid': 'search' },
        ]}
        data-testid={testId}
        inputRef={inputRef}
        withBackground={withBackground}
      />
    </div>
  );

  const collapsibleSearchInput = (
    <>
      <Button
        affix={() => <SearchIcon />}
        look={BUTTON_LOOK.SECONDARY}
        size={SIZE.SMALL}
        onClick={handleIconOnClick}
        data-testid={`${testId}-open-search-button`}
        data-cy={'open-search-button'}
        className={classNames(styles.searchButton, {
          [styles.hidden]: searchBarDisplayed,
        })}
        srOnlyText={srOnlyText ? srOnlyText : label('Ref: Open search')}
      />
      {searchInput}
      <Button
        look={BUTTON_LOOK.SECONDARY}
        size={SIZE.SMALL}
        onClick={collapseAndClearSearch}
        data-testid={`${testId}-close-search-button`}
        data-cy={'close-search-button'}
        className={classNames(styles.searchButton, {
          [styles.hidden]: !searchBarDisplayed,
        })}
        srOnlyText={label('Ref: Close search')}
      >
        {label('close')}
      </Button>
    </>
  );

  const searchBody = (
    <div className={classNames(styles.search, 'search')} data-testid={`${testId}-wrapper`}>
      {above}
      <div
        className={classNames(styles.horizontalWrapper)}
        data-testid={`${testId}-horizontal-wrapper`}
      >
        {collapsible && !!right?.length ? collapsibleSearchInput : searchInput}
        {!!right?.length && (
          <div
            className={classNames(styles.searchBarRight, {
              [styles.hidden]: searchBarDisplayed,
              [styles.withBorder]: !searchBarDisplayed,
            })}
          >
            {right}
          </div>
        )}
      </div>
      {below}
    </div>
  );

  if (withCardWrapper) {
    return <Card className={styles.searchContainer}>{searchBody}</Card>;
  }
  return searchBody;
};

export default SearchBar;
