import classNames from 'classnames';
import { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { breakPointsInPixels } from '../../../../config';
import { TitleBarWidgetProps } from '../TitleBar.types';

import styles from './TitleBarWidget.module.css';

const TitleBarWidget: FC<TitleBarWidgetProps> = ({
  label,
  labelKey,
  Icon,
  linkTo,
  highlightText,
  srOnlyText,
  filled,
  forceToDisplayLabel = false,
  iconOnTheLeft = false,
  'data-testid': dataTestId = 'titlebar-widgets',
  onClick,
}: TitleBarWidgetProps) => {
  const location = useLocation();
  const shouldDisplayLabel =
    forceToDisplayLabel ||
    window.matchMedia('(min-width: ' + breakPointsInPixels['L'] + 'px)').matches;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onClick?.();
    }
  };
  const active = location.pathname === linkTo;
  const content = (
    <>
      {shouldDisplayLabel && !iconOnTheLeft && label(labelKey, { textTransform: 'capitalize' })}
      <span className={styles.icnWrapper}>
        <Icon
          title={label(labelKey)}
          className={classNames({
            [styles.titleBarIcon]: true,
            [styles.filled]: filled,
            [styles.iconOnTheLeft]: iconOnTheLeft && shouldDisplayLabel,
          })}
          data-cy={'title-bar-icon-' + labelKey}
          data-testid={'title-bar-icon-' + labelKey}
        />
        {!!highlightText && (
          <span
            className={styles.titleBarIconTag}
            data-cy="title-bar-icon-tag"
            data-testid={dataTestId + '-highlighted-text'}
            aria-live="polite"
            aria-atomic="true"
          >
            {!!srOnlyText && <span className="sr-only">{label(srOnlyText)}</span>}
            {highlightText}
          </span>
        )}
      </span>
      {!highlightText && !!srOnlyText && <span className="sr-only">{label(srOnlyText)}</span>}
      {shouldDisplayLabel && iconOnTheLeft && label(labelKey, { textTransform: 'capitalize' })}
    </>
  );

  if (!!linkTo) {
    return (
      <div
        className={classNames(styles.titleBarLink, { [styles.active]: active })}
        data-testid={dataTestId}
      >
        <Link to={linkTo} data-testid={`${dataTestId}-link-to`} aria-label={labelKey}>
          {content}
        </Link>
      </div>
    );
  }

  if (!!onClick) {
    return (
      <div
        className={classNames(styles.titleBarLink, { [styles.active]: active })}
        data-testid={dataTestId}
      >
        <div
          onClick={onClick}
          className={styles.widgetButton}
          data-testid={dataTestId + '-on-click'}
          role="button"
          tabIndex={0}
          onKeyDown={handleKeyDown}
        >
          {content}
        </div>
      </div>
    );
  }

  return null;
};

export default TitleBarWidget;
