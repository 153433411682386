import { Network } from '@capacitor/network';

import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { apiPath } from '../../../config';
import baseApi from '../../../services/api/baseApi';
import { OfflineQueueService } from '../../../services/OfflineQueueService';
import mockResponse from '../mocks/api';
import { Asset, Audit, AuditInvite, AuditStatus } from '../types';

import {
  AuditInvitesRequestData,
  AuditSurveyResponse,
  CreateAndRepondAuditRequestData,
  CreateAndRepondAuditResponseData,
  CreateAuditRequestData,
  CreateAuditResponseData,
  CreateAuditSurveyResponseData,
  EditAuditLocationResponseData,
  GetAssets,
  GetAudits,
  GetLocations,
  GetStreams,
} from './api.types';

import { CacheService } from '@/services/CacheService';

export const endpoints = {
  getAuditInvites: '/v1/auditinvites',
  getAuditSurvey: '/v1/auditinvites/:id',
  getLocations: '/v1/auditinvites/locations',
  getStreams: '/v1/auditinvites/streams',
  getAudits: '/v1/auditinvites/audits',
  getAssets: '/v1/auditinvites/assets',
  createAuditSurveyResponse: '/v1/auditinvites',
  createAudit: '/v1/auditinvites/auditinvite',
  createAndRespondAudit: '/v1/auditinvites/auditinvitewithresponse',
  updateAuditInvite: '/v1/auditinvites/:id',
};

const auditsApi = baseApi.enhanceEndpoints({ addTagTypes: ['audits'] }).injectEndpoints({
  endpoints: (build) => ({
    getAuditInvites: build.query<AuditInvite[], AuditInvitesRequestData | null>({
      queryFn: async (params) => {
        const { connected } = await Network.getStatus();
        const offlineMode = !connected ? true : params?.includeResponses;

        const baseQuery = axiosBaseQuery();

        const response = await baseQuery({
          url: endpoints.getAuditInvites,
          method: 'get',
          params: {
            stateCode: params?.code,
            includeResponses: params?.includeResponses,
          },
          opts: { offlineMode },
          mockData: mockResponse.getAuditInvites,
        });

        const offlineQueue = OfflineQueueService.getInstance();
        const queuedItems = await offlineQueue.getAll();

        return {
          data: response.data.map((auditInvite: AuditInvite) => {
            const auditResponse = queuedItems.find(
              ({ v: { data: responseData } }) => responseData.inviteId === auditInvite.id
            );
            const auditCancelled = queuedItems.find(
              ({ k, v }) => k === auditInvite.id && v.data.state === AuditStatus.CANCELLED
            );
            const auditUpdated = queuedItems.find(
              ({ k, v }) => k === auditInvite.id && v.data.location
            );

            return {
              ...auditInvite,
              ...(auditResponse
                ? { state: 1, responses: auditResponse?.v.data.responses, isUnsynchronised: true }
                : {}),
              ...(auditCancelled ? { state: AuditStatus.CANCELLED, isUnsynchronised: true } : {}),
              ...(auditUpdated
                ? {
                    locationId: auditUpdated?.v.data.location.locationId,
                    workOrderLocation: auditUpdated?.v.data.location.workOrderLocation,
                    assetId: auditUpdated?.v.data.location.assetId,
                    assetName: auditUpdated?.v.data.location.assetName,
                    isUnsynchronised: true,
                  }
                : {}),
            };
          }),
        };
      },
      providesTags: ['audits'],
    }),
    getAuditSurvey: build.query<AuditSurveyResponse, string>({
      query: (id) => ({
        url: endpoints.getAuditSurvey.replace(':id', id),
        method: 'get',
        mockData: mockResponse.getAuditSurvey,
      }),
      providesTags: (result) => [{ type: 'audits', id: result?.inviteId }],
    }),
    getAuditLocations: build.query<GetLocations, void>({
      query: () => ({
        url: endpoints.getLocations,
        method: 'get',
        mockData: mockResponse.getLocations,
        opts: { offlineMode: true },
      }),
    }),
    getStreams: build.query<GetStreams, void>({
      query: () => ({
        url: endpoints.getStreams,
        method: 'get',
        mockData: mockResponse.getAudits,
        opts: { offlineMode: true },
      }),
    }),
    getAudits: build.query<GetAudits, string | null>({
      queryFn: async (auditStreamId: string) => {
        const { connected } = await Network.getStatus();
        const offlineMode = !(auditStreamId && connected) || false;

        const baseQuery = axiosBaseQuery();

        const response = await baseQuery({
          url: endpoints.getAudits,
          method: 'get',
          params: {
            auditStreamId: !offlineMode ? auditStreamId : undefined,
          },
          mockData: mockResponse.getAssets,
          opts: { offlineMode },
        });

        if (!connected) {
          return {
            data: (await response.data).filter(
              (audit: Audit) => audit.auditStreamId === auditStreamId
            ),
          };
        }

        return { data: response.data };
      },
    }),
    getAssets: build.query<GetAssets, string | null>({
      queryFn: async (locationId: string) => {
        const { connected } = await Network.getStatus();
        const offlineMode = !(locationId && connected) || false;

        const baseQuery = axiosBaseQuery();

        const response = await baseQuery({
          url: endpoints.getAssets,
          method: 'get',
          params: {
            locationId: !offlineMode ? locationId : undefined,
          },
          mockData: mockResponse.getAssets,
          opts: { offlineMode },
        });

        if (!connected) {
          return {
            data: (await response.data).filter((asset: Asset) => asset.locationId === locationId),
          };
        }

        return { data: response.data };
      },
    }),
    createAuditSurveyResponse: build.mutation<void, CreateAuditSurveyResponseData>({
      query: (data) => ({
        url: endpoints.createAuditSurveyResponse,
        method: 'post',
        data,
        mockData: mockResponse.createAuditSurveyResponse,
        opts: { offlineMode: true },
      }),
      invalidatesTags: (result, error, { inviteId }) => ['audits', { type: 'audits', inviteId }],
    }),
    createAudit: build.mutation<CreateAuditResponseData, CreateAuditRequestData>({
      query: (data) => ({
        url: endpoints.createAudit,
        method: 'post',
        data,
        mockData: mockResponse.createAudit,
        opts: { offlineMode: true },
      }),
      invalidatesTags: ['audits'],
    }),
    createAndRespondAudit: build.mutation<
      CreateAndRepondAuditRequestData,
      CreateAndRepondAuditResponseData
    >({
      query: (data) => ({
        url: endpoints.createAndRespondAudit,
        method: 'post',
        data,
        mockData: mockResponse.createAndRespondAudit,
      }),
      invalidatesTags: ['audits'],
    }),
    updateAuditInviteState: build.mutation<
      void,
      { id: string; state?: number; location?: EditAuditLocationResponseData }
    >({
      queryFn: async ({ id, state, location }) => {
        const { connected } = await Network.getStatus();
        const offlineMode = !connected;
        const cache = CacheService.getInstance();
        const offlineQueue = OfflineQueueService.getInstance();
        const url = endpoints.getAuditInvites;
        const auditInvites: AuditInvite[] = await cache.getValue(apiPath + url);

        if (offlineMode && (await offlineQueue.getValue(id))) {
          await offlineQueue.remove(id);
          if (auditInvites?.length > 0) {
            const prunedInvites = auditInvites.filter((item) => item.id !== id);
            await cache.setValue(url, prunedInvites);
          }

          return { data: {} };
        }

        const baseQuery = axiosBaseQuery();

        const response = await baseQuery({
          url: endpoints.updateAuditInvite.replace(':id', id),
          method: 'patch',
          data: { state, location },
          mockData: mockResponse.updateAuditInviteState,
          opts: { offlineMode },
        });

        // Loop through list to get the record and update the state value. Note: not using .map() for performance reasons
        if (auditInvites?.length > 0) {
          if (state) {
            for (let i = 0; i < auditInvites.length; i++) {
              if (auditInvites[i].id === id) {
                auditInvites[i].state = AuditStatus.CANCELLED;
                break;
              }
            }
          }
          // if (auditLocationResponse) {
          //   for (let i = 0; i < auditInvites.length; i++) {
          //     if (auditInvites[i].id === id) {
          //       auditInvites[i].locationId = auditLocationResponse.locationId;
          //       break;
          //     }
          //   }
          // }
          await cache.setValue(url, auditInvites);
        }

        return { data: response.data };
      },
      invalidatesTags: ['audits'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAuditInvitesQuery,
  useGetAuditSurveyQuery,
  useLazyGetAuditInvitesQuery,
  useLazyGetAuditLocationsQuery,
  useLazyGetStreamsQuery,
  useLazyGetAuditsQuery,
  useLazyGetAssetsQuery,
  useGetAuditLocationsQuery,
  useGetStreamsQuery,
  useGetAuditsQuery,
  useGetAssetsQuery,
  useCreateAuditSurveyResponseMutation,
  useCreateAuditMutation,
  useCreateAndRespondAuditMutation,
  useUpdateAuditInviteStateMutation,
} = auditsApi;
export default auditsApi;
