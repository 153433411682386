const labels = {
  ContentDetails: {
    'Ref: Page title': 'Детали содержания',
    'See the event': 'Смотрите событие',
    'Take the survey': 'Пройдите опрос',
    Date: 'Свидание',
    Category: 'Категория',
    'Ref: Content site different title': 'Этот контент не принадлежит вашему текущему сайту',
    'Ref: Content site different description':
      'Вы просматриваете контент, который не принадлежит вашему текущему сайту. Вы не сможете увидеть это в списке контента.',
  },
  ContentList: {
    'Ref: Page title': 'Новостная лента',
    'featured (as in featured content)': 'Рекомендуемые',
    'Ref: category - all': 'Все',
    'Ref: category - promotional': 'Рекламный',
    'Ref: category - featured': 'Показан',
    'Ref: category - other': 'Другой',
  },
  Widget: {
    'Ref: Carousel title': 'Новости и статьи',
    'Ref: Carousel title promo': 'Главные новости',
    'No info yet, come back later': 'Пока нет информации, вернитесь позже 😉',
    'Ref: Content': 'Содержание',
    'Ref: Widget Title': 'Новостная лента',
    'Ref: MyVillage Carousel title promo': 'Акции',
  },
};
export default labels;
