import { convertLocationsApiResponseToProps } from '../helpers/helpers';

import { LocationResponseType } from '@/modules/ServiceRequest/types/apiResponse.types';
import { LocationProps } from '@/modules/ServiceRequest/types/types';

export function cleanLocations(result: LocationProps[], candidate: LocationResponseType) {
  const cleanedCandidate = convertLocationsApiResponseToProps(candidate);
  if (cleanedCandidate) result.push(cleanedCandidate);
  return result.sort((a: LocationProps, b: LocationProps) => {
    return a.title?.localeCompare(b.title!)!;
  });
}
