import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { Base64 } from 'js-base64';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { DownloadIcon } from '../../../../assets/icons';
import { TermsAndConditionsIllustration } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Checkbox from '../../../../components/atoms/Checkbox';
import { HtmlContent } from '../../../../components/atoms/RenderContent';
import { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { SIZE } from '../../../../constants';
import useLoginStatus from '../../../../helpers/hooks/useLoginStatus';
import { saveHtmlAsPdf } from '../../../../helpers/misc';
import { logout } from '../../actions';
import {
  useAcknowledgeLegalDocMutation,
  useLazyGetOneLegalDocumentQuery,
} from '../../api/legalDocuments/legalDocumentsApi';
import {
  legalDocumentsConfig,
  pagePaths,
  legalDocType,
  legalDocTypeNamesToCodeMap,
} from '../../config';
import {
  getLegalDocLabel,
  checkIfAcknowledgeAfterLegalDocModification,
} from '../../helpers/helpers';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';
import { useLegalDocAndAcknowledgments } from '../../hooks/useLegalDocAndAcknowledgments';
import { IUrlLocation } from '../../types/guestRegistrationForm.types';
import { PrivacyPolicyIntroductionPage } from '../PrivacyPolicyIntroduction';

import { useTrackPageView } from '@/helpers/hooks/Analytics/useTrackPageView';

import styles from './LegalDocsPage.module.css';

const LegalDocsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn } = useLoginStatus();

  const {
    termsOfUseToAcknowledge,
    privacyPolicyToAcknowledge,
    isLoading: legalDocsLoading,
    legalDocuments,
    acknowledgements,
  } = useLegalDocAndAcknowledgments();

  const [
    getOneLegalDocument,
    { data: legalDocument, isLoading: getOneLegalDocumentLoading, isSuccess, isError },
  ] = useLazyGetOneLegalDocumentQuery();
  const [acknowledgeLegalDoc] = useAcknowledgeLegalDocMutation();

  const location: IUrlLocation = useLocation();
  const { label } = useCoreTranslation(__filename);

  const [wasAcknowledged, setWasAcknowledged] = useState(false);
  const [showIntroductionPage, setShowIntroductionPage] = useState(true);

  const isLocked = legalDocsLoading || getOneLegalDocumentLoading;

  const isNativePlatform = Capacitor.isNativePlatform();
  const legalDocumentType = isNativePlatform
    ? legalDocType.terms_of_use
    : legalDocType.privacy_policy;

  const legalDocumentId = legalDocuments?.find(
    (el) => el.type.value === legalDocTypeNamesToCodeMap[legalDocumentType]
  )?.id;

  const isAcknowledgeAfterLegalDocModification = checkIfAcknowledgeAfterLegalDocModification(
    acknowledgements ?? [],
    legalDocumentType
  );
  // Do not show introduction page on native app
  useEffect(() => {
    setShowIntroductionPage(!isNativePlatform);
  }, [isNativePlatform]);

  if (!isLoggedIn) history.replace('/');

  const { shouldDisplayDownloadButton } = legalDocumentsConfig();

  const redirect = useCallback(() => {
    let termOfUseAndPrivacyPolicyAcknowledged = !privacyPolicyToAcknowledge;

    if (isNativePlatform) {
      termOfUseAndPrivacyPolicyAcknowledged =
        termOfUseAndPrivacyPolicyAcknowledged && !termsOfUseToAcknowledge;
    }
    if (!termOfUseAndPrivacyPolicyAcknowledged) {
      return false;
    }
    history.replace(location.state?.from || { pathname: '/' });
    return true;
  }, [
    history,
    location.state?.from,
    isNativePlatform,
    privacyPolicyToAcknowledge,
    termsOfUseToAcknowledge,
  ]);

  const acknowledgeAllLegalDocs = useCallback(() => {
    let documentsToAcknowledge = [
      {
        documentId: privacyPolicyToAcknowledge,
      },
    ];

    if (isNativePlatform)
      documentsToAcknowledge.push({
        documentId: termsOfUseToAcknowledge,
      });

    documentsToAcknowledge.forEach(({ documentId }) => {
      if (documentId) acknowledgeLegalDoc({ documentId: documentId.toString() });
    });
  }, [isNativePlatform, privacyPolicyToAcknowledge, termsOfUseToAcknowledge, acknowledgeLegalDoc]);

  const showLegalDocsPage = () => {
    setShowIntroductionPage(false);
  };

  useEffect(() => {
    if (redirect() || !legalDocumentId || isSuccess || isError) return;

    getOneLegalDocument({ id: legalDocumentId });
  }, [redirect, legalDocumentId, legalDocument, getOneLegalDocument, isSuccess, isError]);

  const showNoticePage = !legalDocument;
  const logPageView = (showNoticePage || legalDocument) && !isLocked;
  useTrackPageView({ isGlobal: false, skip: !logPageView });

  const webActions = (
    <Button
      data-testid="legal-docs-acknowledge-all-web"
      onClick={async () => {
        acknowledgeAllLegalDocs();
      }}
    >
      {label('Continue', { textTransform: 'capitalize' })}
    </Button>
  );

  const nativePlatformActions = (
    <Button
      data-testid="legal-docs-acknowledge-native"
      disabled={!wasAcknowledged}
      look={!wasAcknowledged ? BUTTON_LOOK.SECONDARY : BUTTON_LOOK.PRIMARY}
      onClick={async () => {
        acknowledgeAllLegalDocs();
      }}
    >
      {label('Ref: I agree', { textTransform: 'capitalize' })}
    </Button>
  );

  /** If in progress, wait */
  if (isLocked) {
    return <LoadingPage />;
  }

  /** If we don't have the legal docs, stop right there */
  if (showNoticePage) {
    return (
      <NoticePage
        title={label('Ref: No legal docs available - notice title')}
        content={label('Ref: No legal docs available - notice body')}
        actions={[
          {
            label: label('logout', { textTransform: 'capitalize' }),
            action: async () => {
              await dispatch(logout());
              history.push('/');
            },
          },
        ]}
      />
    );
  }

  const actionsBarTopContent = (
    <div>
      <div className={classNames(styles.checkboxWrapper)}>
        <Checkbox
          id="legal-accept"
          onClick={() => setWasAcknowledged(!wasAcknowledged)}
          inputLabel={label('Ref: Acknowledge legal docs')}
          data-testid="legal-accept-checkbox"
        />
      </div>
      <br />
      <label htmlFor="legal-accept" className={classNames('bodySDefault', styles.labelWithLink)}>
        {label('Ref: Read and understood')}
        <Link
          className={classNames('bodySLink', styles.boldLink)}
          to={pagePaths.LegalDoc.replace(
            /:version|:document_type/g,
            (matched) =>
              ({ ':document_type': legalDocType.privacy_policy, ':version': 'current' }[matched] ||
              '')
          )}
        >
          {label('Ref: The privacy Policy', { textTransform: 'capitalize' })}
        </Link>
      </label>
    </div>
  );

  const notificationContent = (
    <Notification
      look={NOTIFICATION_LOOK.INFO}
      dismissable
      title={label('Ref: Legal changed notification title', {
        replace: {
          legal_doc_type: getLegalDocLabel(
            isNativePlatform ? legalDocType.terms_of_use : legalDocType.privacy_policy,
            label
          ),
        },
      })}
      inheritStyle={classNames(styles.notificationWrapper)}
    >
      {label('Ref: Legal changed notification content')}
    </Notification>
  );

  return (
    <>
      {showIntroductionPage && (
        <PrivacyPolicyIntroductionPage
          showLegalDocsPage={showLegalDocsPage}
          acknowledge={acknowledgeAllLegalDocs}
        />
      )}
      {!showIntroductionPage && (
        <SimpleFormPage
          hasBackLink={false}
          title={getLegalDocLabel(legalDocumentType, label)}
          hideAccountWidget
          hideCartWidget
          hideNotificationsWidget={!isAcknowledgeAfterLegalDocModification}
        >
          <Container>
            <Column.Main>
              {isAcknowledgeAfterLegalDocModification && notificationContent}
              <Card
                overTitle={{
                  tag: TITLE_TAG.H1,
                  size: TITLE_SIZE.HEADLINES,
                  children: getLegalDocLabel(legalDocumentType, label),
                  className: styles.legalTitle,
                  ...(shouldDisplayDownloadButton
                    ? {
                        suffix: (
                          <Button
                            data-testid="legal-docs-download"
                            look="tertiary"
                            onClick={() => saveHtmlAsPdf(Base64.decode(legalDocument.data))}
                            size={SIZE.SMALL}
                            inheritStyle={styles.titleSuffix}
                          >
                            <DownloadIcon className={classNames(styles.titleIcon)} />
                          </Button>
                        ),
                      }
                    : {}),
                }}
              >
                <div className={classNames(styles.wrapper)}>
                  <HtmlContent data-testid="legal-docs-page-html-content">
                    {Base64.decode(legalDocument.data)}
                  </HtmlContent>
                </div>
              </Card>
            </Column.Main>
            <Column.Complementary>
              <TermsAndConditionsIllustration />
            </Column.Complementary>
            <ActionsBar>
              {isNativePlatform && actionsBarTopContent}
              {isNativePlatform ? nativePlatformActions : webActions}
            </ActionsBar>
          </Container>
        </SimpleFormPage>
      )}
    </>
  );
};

export default LegalDocsPage;
