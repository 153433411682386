import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { SIZE } from '../../../../constants';
import { useGetServiceRequestsQuery } from '../../api/index';
import { pagePaths } from '../../config';
import { formatSrForTile } from '../../helpers/helpers';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';

import { Tool, PlusSignIcon, WarningOutlinedIcon } from '@/assets/icons';
import { BackgroundWidgetServiceRequestIllustration } from '@/assets/illustrations';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Title from '@/components/atoms/Title';
import { Tile } from '@/components/molecules/Tile';
import WidgetPlaceholder from '@/components/molecules/WidgetPlaceholder';
import Widget from '@/components/organisms/Widget';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import { ServiceRequestProps } from '@/modules/ServiceRequest/types/types';

const MAX_ITEMS = 3;

const ServiceRequestWidget = () => {
  const history = useHistory();
  const { label } = useServiceRequestTranslation(__filename);
  const { currentLanguageCode } = useLanguage();
  const [widgetRequests, setWidgetRequests] = useState<ServiceRequestProps[]>([]);
  const { data: serviceRequests, error } = useGetServiceRequestsQuery(
    { useErrorBoundary: false },
    // Pull new changes from server every 4 minutes
    { pollingInterval: 4 * 60 * 1000 }
  );

  useEffect(() => {
    if (serviceRequests) {
      setWidgetRequests(
        serviceRequests.length > MAX_ITEMS ? serviceRequests.slice(0, MAX_ITEMS) : serviceRequests
      );
    }
  }, [serviceRequests]);

  let items =
    widgetRequests?.map((sr: any) => formatSrForTile(sr, label, currentLanguageCode)) || [];

  let widgetItems = items?.map((item: any, index: number) => {
    const path = `${pagePaths['Details'].replace(':id', item.id)}?fromWidget=1`;

    return (
      <Tile
        key={`${index}_${item.id}`}
        id={item.id}
        title={item.title}
        description={item.description}
        tags={[{ name: '', icon: item.addendum }]}
        onClick={() => history?.push(path)}
        data-testid={`ServiceRequestTile-${item.id}`}
      />
    );
  });

  return (
    <Widget>
      <Widget.Header illustration={<BackgroundWidgetServiceRequestIllustration />}>
        <Widget.Title>
          <Title>{label('Ref: Service request')}</Title>
          {items.length ? (
            <Widget.Link to={pagePaths['Module']}>
              {label('see all (as in see all items)', { textTransform: 'capitalize' })}
            </Widget.Link>
          ) : null}
        </Widget.Title>
      </Widget.Header>
      <Widget.Body>
        {items.length ? (
          widgetItems
        ) : (
          <WidgetPlaceholder
            title={label(error ? 'Something went wrong, data not loaded' : 'Ref: Placeholder body')}
            icon={error ? <WarningOutlinedIcon /> : <Tool />}
          />
        )}
      </Widget.Body>
      <Widget.Footer>
        <Button
          size={SIZE.SMALL}
          look={BUTTON_LOOK.PRIMARY}
          affix={PlusSignIcon}
          className="d-block"
          contentCenterAlign
          onClick={() => history.push(pagePaths['Create'])}
          data-testid="create-new-request"
        >
          {label('Ref: New request button')}
        </Button>
      </Widget.Footer>
    </Widget>
  );
};

export default ServiceRequestWidget;
