const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: status0': 'Ref: status0',
    'Ref: status1': 'Ref: status1',
    'Ref: status2': 'Ref: status2',
    'Ref: connected': 'Ref: connected',
    'Ref: not connected': 'Ref: not connected',
    'Ref: synchronise data': 'Ref: synchronise data',
    'Ref: sync success': 'Ref: sync success',
    'Ref: Create new': 'Ref: Create new',
    'Ref: Audit unsynchronised': 'Ref: Audit unsynchronised',
    'Ref: Audit synchronised': 'Ref: Audit synchronised',
    'Ref: Unsynced error title': 'Ref: Unsynced error title',
    'Ref: Unsynced error description': 'Ref: Unsynced error description',
    'Ref: Search all audits': 'Ref: Search all audits',
    'Ref: Search active audits': 'Ref: Search active audits',
    'Ref: Search completed audits': 'Ref: Search completed audits',
    'Ref: sync error': 'Ref: sync error',
    'Ref: sync error details': 'Ref: sync error details',
    'Ref: no search audit results': 'Ref: no search audit results',
    'Ref: no audit results': 'Ref: no audit results',
    'Ref: Search canceled audits': 'Ref: Search canceled audits',
    'Ref: Audit cancelled successfully': 'Ref: Audit cancelled successfully',
    'Ref: Cancel audit invite': 'Ref: Cancel audit invite',
    'Ref: Error audit cancel': 'Ref: Error audit cancel',
    'Ref: Cancel audit confirmation': 'Ref: Cancel audit confirmation',
    'Ref: Confirm': 'Ref: Confirm',
    'Ref: Cancel audit': 'Ref: Cancel audit',
    'Ref: Change the location': 'Ref: Change the location',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: questions': 'Ref: questions',
    'Ref: question': 'Ref: question',
    'Ref: Additional Comments': 'Ref: Additional Comments',
    'Ref: Not found title': 'Ref: Not found title',
    'Ref: Not found text': 'Ref: Not found text',
    'Ref: Back to Audit list': 'Ref: Back to Audit list',
    'Ref: Success title': 'Ref: Success title',
    'Ref: Success text': 'Ref: Success text',
    'Ref: submit': 'Ref: submit',
    'Ref: add attachment': 'Ref: add attachment',
    'Ref: Unsupported elements': 'Ref: Unsupported elements',
    'Ref: form error': 'Ref: form error',
    'Ref: form complete': 'Ref: form complete',
    'Ref: attachment preview error': 'Ref: attachment preview error',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Location': 'Ref: Location',
    'Ref: Audit Stream Type': 'Ref: Audit Stream Type',
    'Ref: Audit': 'Ref: Audit',
    'Ref: Asset': 'Ref: Asset',
    'Ref: Asset Name': 'Ref: Asset Name',
    'Ref: Is joint audit?': 'Ref: Is joint audit?',
    'Ref: Who was present?': 'Ref: Who was present?',
    'Ref: submit': 'Ref: submit',
    'Ref: Select the Location': 'Ref: Select the Location',
    'Ref: Select the Audit Stream Type': 'Ref: Select the Audit Stream Type',
    'Ref: Select the Audit': 'Ref: Select the Audit',
    'Ref: Select the Asset': 'Ref: Select the Asset',
    'Ref: form error': 'Ref: form error',
    'Ref: Audit error': 'Ref: Audit error',
    'Ref: Joint Audit Participants error': 'Ref: Joint Audit Participants error',
    'Ref: Audit created': 'Ref: Audit created',
    'Ref: Audit field length error': 'Ref: Audit field length error',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Ref: Change the location',
    'Ref: Location changed': 'Ref: Location changed',
    'Ref: Location': 'Ref: Location',
    'Ref: Select the Location': 'Ref: Select the Location',
    'Ref: Asset': 'Ref: Asset',
    'Ref: Select the Asset': 'Ref: Select the Asset',
    'Ref: Asset Name': 'Ref: Asset Name',
    'Ref: submit': 'Ref: submit',
    'Ref: Audit field length error': 'Ref: Audit field length error',
  },
};
export default labels;
