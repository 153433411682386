import { screen, cleanup, fireEvent, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import renderComponent from '../../../../../helpers/tests/renderComponent';
import FoodFacilitiesList from '../FoodFacilitiesList';

jest.mock('@/helpers/images/images.helper', () => ({
  ...jest.requireActual('@/helpers/images/images.helper'),
  getImagesSrcFromIds: () => [{ id: 'imageId', content: 'image' }],
}));

const facilities = [
  {
    id: '1',
    title: 'Burger King',
    description: 'The greatest restaurant in the city.',
    email: 'jurajs.restaurant@juraj.com',
    phone: '+421999999999',
    openingHours: [
      { closeTime: '17:00', openTime: '11:00', isOpenAllDay: false, day: ['Monday', 'Tuesday'] },
    ],
  },
  {
    id: '2',
    title: 'Mcdonalds',
    description: 'The greatest restaurant in the city.',
    email: 'jurajs.restaurant@juraj.com',
    phone: '+421999999999',
    openingHours: [
      { closeTime: '17:00', openTime: '11:00', isOpenAllDay: false, day: ['Monday', 'Tuesday'] },
    ],
  },
];

jest.mock('../../../api/api', () => ({
  useGetFoodFacilitiesQuery: () => ({
    data: { facilities },
    isLoading: false,
  }),
}));

describe('FoodFacilitiesList', () => {
  const env = global.process.env;

  beforeAll(() => {
    global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYVILLAGE };
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  afterAll(() => cleanup());

  describe('Initial render', () => {
    let facilities: HTMLElement[];

    renderComponent(FoodFacilitiesList);

    it('should display facilities', () => {
      facilities = screen.getAllByTestId(/tile-food-facility-\d+$/i);
      expect(facilities.length).toEqual(2);
    });
  });

  describe('on click view details', () => {
    let buttons: any;
    let modal: any;

    renderComponent(FoodFacilitiesList);

    beforeEach(async () => {
      buttons = screen.getAllByTestId(/view-details-button/i);
      expect(buttons.length).toEqual(2);
    });

    it('should open modal', async () => {
      await act(async () => {
        fireEvent.click(buttons[0]);
      });

      modal = screen.getByTestId('facility-details-modal');
      expect(modal).toBeTruthy();
    });

    it('modal contains data', async () => {
      await act(async () => {
        fireEvent.click(buttons[0]);
      });

      const openTime = facilities[0].openingHours[0].openTime;
      const closeTime = facilities[0].openingHours[0].closeTime;
      const days = facilities[0].openingHours[0].day.join(', ');

      expect(screen.getByText('Opening hours')).toBeTruthy();
      expect(screen.getByText(days)).toBeTruthy();
      expect(screen.getByText(`${openTime}-${closeTime}`)).toBeTruthy();
      expect(screen.getByText('Contact us')).toBeTruthy();
      expect(screen.getByText(facilities[0].email)).toBeTruthy();
      expect(screen.getByText(facilities[0].phone)).toBeTruthy();
      expect(screen.getByText('More')).toBeTruthy();
      expect(screen.getByText(facilities[0].description)).toBeTruthy();
    });
  });

  describe('on filtering', () => {
    let input: HTMLElement;
    let facilities: HTMLElement[];

    renderComponent(FoodFacilitiesList);

    beforeEach(async () => {
      input = screen.getByTestId(
        'food-facilities-list-search-bar-list-page-search-filter-bar-input-field'
      );
      await userEvent.type(input, 'Mc');
      await new Promise((r) => setTimeout(r, 400));
    });

    it('should have search input', () => {
      expect(screen.getByPlaceholderText('Search')).toBeTruthy();
    });

    it('should have the correct number of facilities listed', () => {
      facilities = screen.getAllByTestId(/tile-food-facility-\d+$/i);
      expect(facilities.length).toEqual(1);
    });

    it('should filter the correct facility', () => {
      expect(screen.getByText('Mcdonalds')).toBeTruthy();
    });
  });
});
