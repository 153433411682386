import { Storage } from '@ionic/storage';

import { AuditStateCacheService } from '../AuditStateCacheService';

jest.mock('@ionic/storage');

describe('AuditStateCacheService', () => {
  let service: AuditStateCacheService;

  beforeEach(() => {
    jest.clearAllMocks();
    service = new AuditStateCacheService(true);
  });

  it('should create storage on instantiation', () => {
    expect(Storage).toHaveBeenCalled();
    expect(service.storage.create).toHaveBeenCalled();
  });

  it('should set and retrieve values', async () => {
    (service.storage.get as jest.Mock).mockResolvedValueOnce({ value: 'testValue' });

    await service.setValue('testKey', 'testValue');
    const value = await service.getValue('testKey');

    expect(service.storage.set).toHaveBeenCalledWith('testKey', { value: 'testValue' });
    expect(value).toBe('testValue');
  });

  it('should return undefined if no value is found', async () => {
    (service.storage.get as jest.Mock).mockResolvedValueOnce(undefined);

    const value = await service.getValue('testKey');

    expect(value).toBeUndefined();
  });

  it('should remove specific keys', async () => {
    await service.remove('testKey');
    expect(service.storage.remove).toHaveBeenCalledWith('testKey');
  });

  it('should retrieve all keys', async () => {
    await service.keys();
    expect(service.storage.keys).toHaveBeenCalled();
  });

  it('should flush the entire storage', async () => {
    await service.flush();
    expect(service.storage.clear).toHaveBeenCalled();
  });

  it('should return the same instance for getInstance', () => {
    const instance1 = AuditStateCacheService.getInstance();
    const instance2 = AuditStateCacheService.getInstance();

    expect(instance1).toBe(instance2);
  });
});
