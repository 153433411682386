import axios from 'axios';

import {
  accessTokenInterceptor,
  commonHeadersInterceptor,
  offlineRequestInterceptor,
  cacheResponseInterceptor,
  serializeBodyInterceptor,
} from './interceptors';

export const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(accessTokenInterceptor);
axiosClient.interceptors.request.use(commonHeadersInterceptor);
axiosClient.interceptors.request.use(serializeBodyInterceptor);
axiosClient.interceptors.request.use(offlineRequestInterceptor);

axiosClient.interceptors.response.use(cacheResponseInterceptor);
