import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';

import TilesList from '../../organisms/TilesList/TilesList';
import InfoMessage from '../InfoMessage';

import { ContentListProps } from './ContentList.types';

import styles from './ContentList.module.css';

import { ItemWithTestingProps } from 'src/types';

const defaultDisplayedItemsNum = 25;
const defaultDisplayedItemsIncrement = 25;

export const ContentList = <T extends ItemWithTestingProps>({
  items,
  customListRenderer,
  noResults,
  noResultsClasses,
  sections,
  sectionRefs,
  twoTilesColumns,
  className,
  itemRenderer,
  tileVariant,
  longTileTextVisible,
  config,
  isLoading,
  onLoadMore,
  'data-testid': testId,
}: ContentListProps<T>) => {
  const [displayedItemsNum, setDisplayedItemsNum] = useState<number>(0);

  useEffect(() => {
    const itemsNum = config?.disableInfiniteScroll
      ? items.length
      : config?.displayedItemsNum !== undefined
      ? config?.displayedItemsNum
      : defaultDisplayedItemsNum;

    setDisplayedItemsNum(itemsNum);
  }, [config?.disableInfiniteScroll, config?.displayedItemsNum, items.length]);

  const displayedItemsPaginated = useMemo(() => {
    let resultMemo = [...items];

    if (!config?.disableInfiniteScroll && displayedItemsNum) {
      resultMemo = resultMemo.slice(0, displayedItemsNum);
    }

    return resultMemo;
  }, [items, displayedItemsNum, config?.disableInfiniteScroll]);

  const displayMore = (e: any) => {
    setDisplayedItemsNum(
      displayedItemsNum ? displayedItemsNum + defaultDisplayedItemsIncrement : 0
    );
    onLoadMore && onLoadMore();
    e.target.complete();
  };

  const hasItemsToDisplay = displayedItemsPaginated.length > 0;

  if (isLoading) {
    return null;
  }

  return customListRenderer ? (
    <>{customListRenderer(displayedItemsPaginated)}</>
  ) : hasItemsToDisplay && itemRenderer ? (
    <>
      <TilesList
        sections={sections}
        sectionRefs={sectionRefs}
        items={displayedItemsPaginated}
        config={config}
        twoTilesColumns={twoTilesColumns}
        longTileTextVisible={!!longTileTextVisible}
        itemRenderer={itemRenderer}
        tileVariant={tileVariant}
        className={className}
        data-testid={`${testId}-tile-list`}
      />
      {!config?.disableInfiniteScroll && (
        <IonInfiniteScroll
          threshold="20%"
          onIonInfinite={(e) => displayMore(e)}
          disabled={displayedItemsNum > displayedItemsPaginated.length}
        >
          <IonInfiniteScrollContent loadingSpinner="bubbles"></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      )}
    </>
  ) : (
    <div className={classNames(noResultsClasses || styles.listNoResult)}>
      <InfoMessage {...noResults} data-testid={`${testId}-tile-list`} />
    </div>
  );
};
