const labels = {
  All: {
    'status: all': 'את כל',
    'status: open': 'לפתוח',
    'status: pending': 'ממתין ל',
    'status: closed': 'סגור',
    Location: 'מקום',
    Subject: 'נושא',
    Description: 'הבקשה המפורטת שלך',
    'Ref: description field label': 'תיאור',
    'Ref: Title': 'כותרת',
    phone_number: 'מספר הטלפון שלך',
    request_category: 'בקשת קטגוריה',
    Attachments: 'קבצים מצורפים',
    'request service': 'בקשה לשירות',
    'New service request': 'צור בקשה נוספת',
    'Go to home': 'לך הביתה',
    'Back to this service request': 'חזרה לבקשת שירות זו',
    'Back to service requests': 'חזרה לבקשות שירות',
    Feedback: 'משוב',
    'Ref: Request category': 'קטגוריה',
    Cleaning: 'ניקוי',
    'Laundry services': 'שירותי כביסה',
    'Grounds maintenance': 'תחזוקת הקרקע',
    'Soft services': 'שירותים רכים',
    'Hard services': 'שירותים קשים',
    'Pest control services': 'שירותי הדברה',
    'Waste management': 'ניהול פסולת',
    'Asset Lifecycle Services': 'שירותי מחזור חיי הנכס',
    'There are no updates for this request': 'אין עדכונים לבקשה זו',
    'Transportation Services': 'שירות תחבורה',
    'Ref: you pronoun': '(אתה)',
    'Ref: SR raised for': 'אדם מושפע על ידי בקשה זו',
    'Ref: Select a location': 'בחר מיקום',
    'Ref: Your request': 'בקשתך',
    'Ref: Summary': 'סיכום',
    'Ref: site': 'אֲתַר',
  },
  Comment: {
    'Ref: Page title': 'עדכן אותנו',
    'Ref: please update us with details':
      'מידע נוסף שתעביר אלינו? תגובה? ככל שנדע יותר על בקשתך, כך נוכל לתמוך בך טוב יותר.',
  },
  Confirm: {
    'Ref: Page title': 'אשר את בקשתך',
    'Ref: next': 'שלח',
    summary: 'סיכום',
    'Ref: Save as my preferred location': 'שמור כמיקום המועדף עלי',
  },
  Describe: {
    'Ref: Page title': 'תאר את בקשתך',
    'Ref: Required fields: header': 'ספק מידע נוסף',
    'Ref: Required fields': 'יש לספק את המידע הבא בתוך שדה הפרטים:',
    'Ref: Required field: title': 'נדרשת כותרת.',
    'Ref: Required field: description': 'יש צורך בתיאור.',
    'Ref: Required field: phone': 'נדרש מספר טלפון.',
    'Ref: Required field: category': 'נדרשת קטגוריה.',
    'Ref: Invalid field: category': 'הקטגוריה בתנאי אינה חוקית.',
    'Ref: Request template field label': 'בקשות פופולריות',
    'Ref: Request template field placeholder': 'דף ריק',
    your_request: 'בקשתך',
    'Ref: Description message': 'ככל שאתה מפורט יותר, אנו יכולים לתמוך בך טוב יותר.',
    phone_number: 'מספר הטלפון שלך (אנא השתמש בטלפון המקצועי שלך אם יש לך כזה)',
    'Ref: Affected person': 'אדם מושפע על ידי בקשה זו',
    'Ref: No affected person results': 'אף אדם לא תואם קריטריונים מבוקשים',
    'Ref: Notification title': 'שינית את המיקום',
    'Ref: Notification info': 'אתה נמצא כעת ב {site name}',
    'Ref: Add photos': 'להוסיף תמונות',
    'Ref: Your location': 'המיקום שלך',
  },
  Details: {
    'Ref: Page title': 'בקש פרטים',
    Details: 'פרטים',
    Updates: 'עדכונים',
    'Update us': 'עדכן אותנו',
    'Ref: timeline': 'ציר זמן',
    'Ref: attachments': 'קבצים מצורפים',
    'Ref: location': 'מקום',
    'Ref: createdOn': 'נוצר ב',
    'Ref: reference': 'התייחסות',
    'Feedback was submitted': 'משוב נשלח',
    'Ref: sent': 'נשלח',
    'Ref: received': 'קיבלו',
    'Ref: inProgress': 'בתהליך',
    'Ref: completed': 'הושלם',
    'Ref: conversation': 'שִׂיחָה',
    'Ref: Priority': 'עדיפות',
    'Ref: Target start date': 'תאריך התחלה יעד',
    'Ref: Target completion date': 'תאריך השלמת היעד',
    'Ref: Priority fields placeholder message': 'זה יאכלס לאחר שתוקף על ידי דלפק העזרה',
  },
  Failed: {
    'Ref: Body': 'אירעה שגיאה לבקשת השירות שלך לא נוצר. אם שגיאה זו נמשכת, צור קשר עם התמיכה שלך.',
    'Ref: Tabtitle': 'בקשת השירות נכשלה ביצירת',
  },
  Home: { 'Ref: Page title': 'בקשות' },
  RequestFeedback: {
    'Ref: Page title': 'משוב',
    'Ref: Feedback headline': 'אנא שתף ​​את המשוב שלך',
    'Ref: Feedback title': 'משוב על SR # {ref}',
    'Ref: Question: general sentiment':
      'בסך הכל, כמה אתה מרוצה מהאופן בו Sodexo טיפל בבקשת השירות שלך?',
    'Ref: Question: answer quality': 'איך היית מדרג את איכות השירות שקיבלת?',
    'Ref: Question: staff interaction': 'איך היית מדרג את האינטראקציות שלך עם הצוות שלנו?',
    'Ref: Question: efficiency': 'איך היית מדרג את היעילות שלנו?',
    'Ref: Question: open': 'תגובה',
    'Ref: Open question placeholder': 'מה עשינו טוב? היכן נוכל להשתפר?',
    no_comment: 'אין תגובה',
  },
  FeedbackSuccess: {
    'Ref: Body': 'תודה שהקצת זמן להשאר תגובה, המשוב שלך חשוב לנו כדי לשפר את החוויה שלך.',
  },
  Locate: {
    'Ref: Page title': 'אתר את בקשתך',
    'Ref: Top bar title': 'אתר את בקשתך',
    'Ref: Select this location': 'בחר מיקום זה',
    drill: 'לְדַפדֵף',
    'Ref: Scanner button': 'מיקום עם QR? לסרוק את זה',
    'Ref: Scanner header': 'אנא סרוק את מיקום QR',
    'Sorry, this location could not be found.': 'מצטערים, מיקום זה לא נמצא.',
    'Close scanner': 'סגור סורק',
    'Ref: Switch site component intro sentence': 'אתה מבקש שירות באתר {site name}.',
    'Ref: search location by keywords': 'חפש במיקום',
    'Ref: Enter Location name': 'הזן שם מיקום',
    'Ref: Enter manual location': 'הזן מיקום באופן ידני',
    'Ref: Location name': 'שם מיקום',
    'Ref: No results': 'לא הצלחנו למצוא את המיקום שלך. ייתכן שתרצה להזין אותו באופן ידני.',
    'Ref: Up one level': 'שלב אחד למעלה',
    'Ref: Navigate': 'נווט',
    'Ref: Select': 'בחר',
    'Ref: Selected Location': 'מיקום נבחר',
    'Ref: Preferred Location': 'מיקום מועדף',
    'Ref: Building': 'בִּניָן',
    'Ref: Floor': 'Etage',
    'Ref: Aisle': 'מַעֲבָר',
    'Ref: Room': 'חֶדֶר',
    'Ref: Notification title': 'שינית את המיקום',
    'Ref: Notification info': 'אתה נמצא כעת ב {site name}',
    'Ref: Scanner error header': 'שגיאת סריקה',
    'Ref: Scan QR Code': 'סרוק קוד QR',
    'Ref: Error body': 'קוד QR זה אינו קוד תקף',
    'Ref: Loading Locations Warning':
      'אנא המתן בזמן שאנחנו מכינים את החוויה שלך לבקשה ראשונה באתר זה.',
    'Ref: Search Result is limited to:':
      'תוצאת החיפוש מוגבלת ל- {limit}. אנא צמצם את קריטריוני החיפוש שלך.',
    'Ref: Open the location search field': 'פתח את שדה חיפוש המיקום',
  },
  Success: {
    'Ref: Body':
      'בקשת השירות שלך הוגשה ומעובדת. זה יציג ביישום זה בקרוב. תקבל פתק כאשר הוא נפתר. בינתיים, אתה יכול לעקוב אחר הסטטוס שלה ולהוסיף הערות על זה באמצעות יישום זה.',
    'Ref: Tabtitle': 'בקשת שירות נוצרה',
    'Ref: Download app title': 'פשט את בקשות השירות שלך',
    'Ref: Download app subtitle': 'הורד את האפליקציה שלנו והעלה את החוויה שלך',
    'Ref: Download app button': 'הורד את האפליקציה',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'זוהי רכזת התמיכה במקום העבודה שלך',
    'Ref: Tutorial: tabname': 'בקשות',
    'Ref: Tutorial: body': 'אנחנו כאן כדי לעזור לך. אנא רשום בקשה אחת לכל ערך.',
    "What's new: title": 'מצא את המיקום שלך בקלות',
    "What's new: body":
      'עכשיו אתה יכול לסרוק מיקום QR, לקדוח את רמות המיקום, או פשוט לבחור מתוך ההצעות החכמות שלנו.',
  },
  Widget: {
    'Ref: Service request': 'בקשת שירות',
    'Ref: Placeholder body': 'אין בקשת שירות לרגע',
    'Ref: New request button': 'בקשה חדשה',
  },
  QrLandingPage: {
    'Ref: Notification title': 'שינית את המיקום',
    'Ref: Notification info': 'אתה נמצא כעת ב {שם {site name}',
    'Ref: Welcome': 'ברוך הבא! {wave_emoji}',
    'Ref: Create service request text': 'שמת לב למשהו שאתה רוצה לדווח?',
    'Create a service request': 'צור בקשת שירות',
    'Ref: Share feedback': 'שתף משוב',
    'Ref: Share text': 'רוצה לשתף אותנו במשהו?',
    'Go to home': 'לך הביתה',
    'Ref: Error body': 'קוד ה-QR שסרקת כבר לא עובד. נסה שוב מאוחר יותר או פנה לתמיכה.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'ארעה שגיאה',
    'Ref: Error body': 'קוד ה-QR שסרקת כבר לא עובד. נסה שוב מאוחר יותר או פנה לתמיכה.',
    'Go to home': 'לך הביתה',
  },
  ManualDescribe: {
    'Ref: Page title': 'תאר את בקשתך',
    'Ref: Required fields: header': 'אנא ספק מידע נוסף',
    'Ref: Required fields': 'יש למסור את המידע הבא בשדה הפרטים:',
    'Ref: Required field: title': 'נדרש סיכום.',
    'Ref: Required field: description': 'יש צורך בתיאור.',
    'Ref: Required field: category': 'יש צורך בקטגוריה.',
    'Ref: Required field: buildingText': 'נדרש בניין.',
    'Ref: Invalid field: category': 'קטגוריה בתנאי אינה חוקית.',
    'Ref: Your request': 'בקשתך',
    'Ref: Description message': 'ככל שאתה מפורט יותר, אנו יכולים לתמוך בך טוב יותר.',
    'Ref: Do you have a location QR code': 'האם יש לך קוד QR מיקום?',
    'Ref: Scan QR Code': 'סרוק קוד QR',
    'Ref: Please select category': 'אנא בחר קטגוריה',
    'Ref: Please select site': 'אנא בחר אתר',
    'Ref: building': 'בִּניָן',
    'Ref: room': 'חֶדֶר',
    'Ref: Scan error': 'שגיאת סריקה',
    'Ref: The QR code is not valid': 'קוד ה- QR אינו תקף',
    'Ref: Add location with QR Scanner': 'הוסף מיקום עם סורק QR',
    'Ref: Description': 'תיאור',
    'Ref: Forbidden error': 'אנו מצטערים, קוד ה- QR הזה אינו תואם את המיקום הרשום שלך.',
    'Ref: Invalid error': 'אנו מצטערים, נראה כי קוד ה- QR הזה כבר לא תקף.',
  },
};
export default labels;
