const labels = {
  ContactForm: {
    'Ref: Page title': 'Связаться с нами',
    'Ref: Category field value': 'Связаться с нами',
  },
  ContactList: {
    'Ref: Page title': 'контакты',
    'call (action to place a call)': 'вызов',
    'write (action to write a message)': 'записывать',
    'write (action to write an e-mail)': 'Эл. почта',
    'Ref: Hint message':
      'Имейте в виду, что некоторые сайты могут не иметь приема. Если это так, пожалуйста, найдите один из стационарных телефонов, доступных на сайте.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Произошла ошибка, и ваше сообщение не было отправлено. Если эта ошибка сохраняется, пожалуйста, свяжитесь с вашим Справочная служба.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Ваше сообщение было отправлено. Спасибо, что связались с нами.',
  },
};
export default labels;
