import { Tag } from '../../../components/molecules/Tile';

export const buildSticker = ({
  productCategory,
}: {
  productCategory?: string | null;
}): Tag | undefined => {
  if (productCategory) {
    return {
      name: productCategory,
    };
  }
};
