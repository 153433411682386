const mockResponse = {
  wiboToken: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        token:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJEemhvUkhwbUx6WVYyUlJMMDNlS2dJeTd2OVAyIiwibmFtZSI6IlNod2V0YSIsImxhc3RfbmFtZSI6IkFsYXZhbmRpIiwiZW1haWwiOiJhcHB0ZXN0U0FAbXl3YXkuY29tIiwiY3JlYXRlZF9hdCI6IjIwMjMtMDgtMTggMTI6MjQ6MDYrMDA6MDAiLCJleHAiOjE2OTIzNjUwNDZ9.YY1z7VP6zJxix6lhv5AtS9BntlUASQR9SKn5YWvuddk',
      }),
  },
};

export default mockResponse;
