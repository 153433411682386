const labels = {
  All: {
    Region: '地区或国家',
    'Ref: Geography': '请选择您的国家或地区',
    'Ref: Country': '国家',
    'Ref: Country tooltip': '该位置对应于现场国家。',
    'Ref: Please select your country': '请选择你的国家',
    'First name': '名',
    'Last name': '姓',
    'Ref: Full name': '全名',
    'Mobile number': '手机号码',
    'Mobile phone': '手机',
    Email: '电子邮件地址',
    Password: '密码',
    logout: '登出',
    'Not a new user? Log in': '不是新用户吗？登录',
    'do not register, login': '不是新用户吗？登录',
    'Ref: I agree': '我同意',
    Register: '注册',
    'Ref: My language': '我的语言',
    'Ref: Kiosk Fail Title': '哎呀！出了些问题！',
    'Ref: Kiosk Fail Description': '不幸的是，您的订单没有成功。请重试或要求工作人员为您提供帮助。',
    'Ref: Kiosk Fail Back': '返回上一页',
    'Ref: Kiosk Fail Action': '清除会话并再次订购',
    Validate: '确认',
    'Ref: error, incorrect credentials': '这些凭据与所选位置不匹配。',
    'Ref: Forgot password?': '忘记密码？',
    'Ref: Hello': '你好{wave_emoji}，请登录以继续',
    'Ref: Form title': '请注册以继续',
    'Already have an account? Login': '已经有一个帐户？登录',
  },
  AccountPage: {
    'Ref: My Information': '我的信息',
    'Ref: Settings': '设置',
    'Ref: Email': '电子邮件',
    'Ref: Current site': '当前地点',
    'Ref: Choose site': '选择一个地点',
    'Ref: Personal interests': '个人兴趣',
    'Ref: Food preferences': '食物偏好',
    'Ref: Password': '密码',
    'Ref: Page title': '帐户',
    'Ref: Language': '语言',
    'Ref: Communication preferences': '沟通偏好',
    'Ref: Terms & conditions': '条款和条件',
    'Ref: Privacy policy': '隐私政策',
    'Ref: Delete my account': '删除我的账户',
    'Ref: Confirm deletion': '您确定要删除帐户吗？',
    'Ref: Request deletion': '您确定要请求删除帐户吗？',
    'Ref: Deletion description': '您的请求将根据您的本地法规处理。',
    Placeholder: '请输入“删除”',
    'Ref: Delete description': '您的数据将被删除。此动作是不可逆转的。',
    delete: '删除',
    'Ref: Terms of sale': '销售条款',
    'Ref: Imprint': '版本说明',
    'Ref: Cookie Settings': 'cookie设置',
    'Ref: Support': '支持',
    'Ref: Register or login': '注册或登录',
    'Ref: Filtering Preferences': '过滤首选项',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': '您确认要删除您的帐户吗？',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      '在输入字段中写入“ {word}”，并在下面的底部确认。此动作是不可逆转的。',
    'Ref: Write "{word}"': '写“ {word}”',
    'Ref: Delete my account': '删除我的账户',
    'Ref: Words are not matching': '单词不匹配',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': '选择您的位置以继续',
    'Ref: Choose location': '通过定位、扫描二维码或查询名称来快速找到位置',
    'Ref: next step': '开始',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': '注册',
    'Ref: identify your location': '请确定您的位置。',
    'Ref: option 1 - scan QR': '选项1：扫描提供的注册QR码',
    'Ref: option 2 - enter code': '选项2：手动输入您的位置代码',
    'Ref: button to open qr code scanner modal': '我有一个QR码：让我们扫描它',
    'Ref: button to open site code input modal': '选择代码的站点',
    'Ref: getting help with site identifier - support desk':
      '如果您不清楚如何继续，请联系您的支持台。',
    'Ref: getting help with site identifier - support form': '您也可以向我们发送一个消息。',
    'Location code': '位置代码',
    'Ref: location code field placeholder': '您的位置代码在这里',
    'Ref: Scanner header': '扫描二维码',
    'Ref: site code input header': '用代码添加站点',
    'Ref: not valid code': '此代码在选定的位置无效',
  },
  AccountRegistrationForm: {
    'Ref: Page title': '注册',
    'Ref: collected info': '确认你的请求',
    'Ref: additional info': '您的资料',
    'Confirm password': '*确认密码',
    company: '你的雇主是？',
    location: '位置',
    username: '用户名',
    register: '注册',
    'Ref: password strength':
      '您的密码必须至少8个字符长，至少包含一个数字，一个大写字母，一个小写字母和一个特殊字符。',
    'passwords do not match': '密码不匹配。',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': '沟通偏好',
    'Ref: Things happen while you are away': '你不在时发生的事情',
    'Ref: Would you like to receive update about offers and promotions?':
      '您想收到有关报价和促销的最新信息吗？',
    'Ref: Would you like to hear about content we think you may like?':
      '您想了解我们认为您可能喜欢的内容吗？',
    Yes: '是的',
    No: '不',
    apply: '应用',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': '帐户详细资料' },
  DeletionFailure: {
    'Ref: Body': '发生了错误，您的帐户删除请求尚未提交。如果此错误持续存在，请联系您的帮助台。',
    'Go to account': '转到帐户',
  },
  GenericFailurePage: {
    'Ref: Title': '出问题了',
    'Ref: Body': '抱歉，出了一些问题。如果此问题仍然存在，请联系您的现场支持。',
  },
  HomePage: {
    notifications: '通知',
    basket: '购物车',
    'Ref: Tabtitle': '首页',
    'contact us': '联系我们',
    'Ref: You are logged in as a guest user': '您当前以访客身份登录',
    'Ref: Good morning': '早上好',
    'Ref: Good afternoon': '下午好',
    'Ref: Good evening before midnight': '晚上好',
    'Ref: Good evening after midnight': '晚上好',
    'Ref: Search': '搜索',
    'Ref: products button aria': '在产品中找到{results_form}。',
    'Ref: menus button aria': '在菜单中找到{results_form}。',
    'Ref: facilities button aria': '在设施中找到{result_form}',
    'Ref: result': '结果',
    'Ref: results': '结果',
    'Ref: Menus': '菜单',
    'Ref: No results title': '哎呀！',
    'Ref: No results body': '没有匹配您的搜索的结果',
    'Ref: Products': '产品',
    'Ref: Facilities': '设施',
    'Ref: See more results': '查看更多搜索结果',
    'Ref: Accessibility instructions': '要开始使用搜索，您必须单击它或在将其集中在Enter时按ENTER。',
    'Ref: See more': '查看更多',
    'Ref: singular form of products': '产品',
    'Ref: singular form of menus': '菜单',
    'Ref: singular form of facilities': '设施',
    'Ref: Search results for': '为。。。。寻找结果',
  },
  LanguagePage: {
    apply: '应用',
    'My language': '我的语言',
    'Select in which language you would like to read our app': '选择应用程序显示的语言',
    'Ref: Page title': '语言',
  },
  LegalDocPage: {
    Acknowledge: '确认',
    'Ref: Legal changed notification title': '“{legal_doc_type}”已修改',
    'Ref: Legal changed notification content': '在继续使用该应用程序之前，请查看它们。',
  },
  LegalDocsPage: {
    'Ref: Page title': '条款及细则',
    'Read privacy policy': '阅读隐私政策',
    'Ref: Acknowledge legal docs': '我接受这些条款和条件。',
    'Ref: Read and understood': '我已经阅读和理解 ',
    'Ref: No legal docs available - notice title': '马上就来',
    'Ref: No legal docs available - notice body': '对不起，似乎此申请目前无法在您的地理中使用。',
    'Ref: The privacy Policy': '隐私政策',
    'Ref: Legal changed notification title': '“{legal_doc_type}”已修改',
    'Ref: Legal changed notification content': '在继续使用该应用程序之前，请查看它们。',
  },
  LoginPage: {
    'Ref: Page title': '欢迎来到{app name} ',
    'Ref: Page subtitle': '请登录以继续',
    'Ref: Email field placeholder': '你的邮件',
    'Ref: Email field note': '我们永远不会与任何人分享您的电子邮件。',
    'Ref: Password field title': '你的密码',
    register: '注册',
    'I forgot my password.': '忘记密码？',
    'I have trouble logging in': '登录时遇到问题？',
    'start registration': '开始注册',
    'Ref: Register': '注册',
    'Ref: Login': '登录',
    'Ref: Connection issue?': '连接问题？',
    'Ref: Connection issue message':
      '如果您在注册或登录时遇到任何问题，请联系现场工作人员或服务台。',
  },
  WelcomePage: { 'Ref: Welcome to': '您好，欢迎来到{site_name}', 'Ref: Begin': '开始' },
  NotificationsList: {
    'Ref: No notifications': '您目前尚无通知',
    'Ref: This week': '本星期',
    'Ref: Earlier': '较早',
    'Ref: Page title': '通知',
    'Ref: See more': '查看更多',
    'Ref: Mark all read': '标记所有阅读',
  },
  NotificationsWidget: { 'Ref: See all notifications': '查看所有通知' },
  ServicesMenuPage: { 'Ref: Page title': '服务', navigate: '导航' },
  DeletionSuccess: {
    'Ref: Title': '请求删除您的帐户',
    'Ref: Content': '我们很快就会处理。您可能随时失去访问权限。',
    'Log out': '登出',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': '我们会尽快给您回复',
    'Ref: Body': '很抱歉您遇到问题。 我们会尽快与您联系。',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': '登录时遇到问题？',
    'Ref: Email field placeholder': '您的电子邮件地址',
    'Ref: Mobile phone field placeholder': '电话号码',
    Company: '公司',
    'Ref: Company field placeholder': '公司名',
    'Ref: Company field note': '如果您是第三方顾问，这是您目前正在工作的公司，而不是您的雇主。',
    Location: '城市或地点',
    'Ref: Location field placeholder': '你的工作场所的位置',
    'Ref: Location field note': '如果在居住区域之外，请随时使用该区域通常被提及的名称。',
    Comment: '评论',
    'Ref: Comment field placeholder': '发生了什么?',
    'Ref: Who is your employer?': '您的雇主是谁？',
    'Ref: What village are you staying at?': '您住在哪个村庄？',
    'Ref: Comment field note': '任何可以帮助我们帮助您的信息！',
    'Ref: Feedback Content - geography': '地理',
    'Ref: Feedback Content - fullName': '姓名',
    'Ref: Feedback Content - email': '电子邮件',
    'Ref: Feedback Content - phone': '电话',
    'Ref: Feedback Content - company': '公司',
    'Ref: Feedback Content - city': '城市 /位置名称',
    'Ref: Feedback Content - whatVillage': '村庄',
    'Ref: Feedback Content - employer': '雇主',
    'Ref: Feedback Content - comment': '什么地方出了错',
  },
  AccountStartPage: {
    'Ref: Header': '选择您的位置开始',
    'Ref: Body': '通过激活本地化，扫描QR码或按名称搜索来快速找到位置',
    'Ref: LetsGo button': '我们走吧',
  },
  AccountOnboardingInformat: { 'Ref: Page title': '注册' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': '选择QR扫描仪的站点',
    'Ref: Page title': '选择您的位置',
    'Ref: Select site with code': '通过代码选择地点',
    'Ref: Enter site code': '输入站点代码',
    'Ref: Scanner error header': '未找到地点',
    'Ref: Not found': '此代码在选定的地点中无效',
    'Ref: Generic error': '出问题了',
    'All other sites': '所有其它地点',
    'Sites in your country': '您所在国家的地点',
    'Ref: Unauthorized error': '由于站点限制级别而未经授权的请求',
    'Ref: Forbidden':
      '抱歉，您没有正确的权限可以访问此网站。要安排访问，请致电1800 992 777与Pilbara服务中心联系',
    "Ref: We didn't find the site.": '我们没有找到该地点。',
    'Ref: We recommend some solutions:': '我们建议：',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1.尝试扫描场所的二维码或点击下面的按钮关联您的地点',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2.登录并通过选择正确的国家来登录',
    'Ref: The currently selected country is:': '当前选择的国家是：',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': '注册',
    'Confirm password': '确认密码',
    'Email is not valid': '电子邮件无效',
    'passwords do not match': '密码不匹配。',
    'Ref: Password strength':
      '您的密码必须至少8个字符长，至少包含一个数字，一个大写，一个小写和一个特殊字符。',
    'Sign up': '注册',
    'Continue as guest': '以访客身份继续',
    'Already have an account? Login': '已经有一个帐户？登录',
    'Ref: Registration success title': '感谢您注册',
    'Ref: Registration success content': '您的账户将很快被创建。您将收到一封确认创建的电子邮件。',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': '请登录/注册',
    'Ref: Password strength':
      '您的密码必须至少8个字符长，至少包含一个数字，一个大写字母，一个小写字母和一个特殊字符。',
    Stamps: '邮票',
    'Order history': '订单历史',
    'to earn points': '赚取积分',
    'to see order history': '查看订单历史记录',
    'to place an order': '下单',
    'Sign up': '注册',
    Login: '登录',
    'Email is not valid': '电子邮件无效',
    'passwords do not match': '密码不匹配。',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': '谢谢你',
    'Ref: Body':
      '你几乎已经完成！您很快将收到有关提供的地址的验证电子邮件。打开此电子邮件，然后单击链接以激活您的帐户。',
    'Ref: Enter': '继续',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': '发生了错误',
    'Ref: Generic Error Body': '请稍后再试或联系您的服务台。',
    'Ref: Missing IndexDb support':
      '抱歉，您无法以隐身模式或没有IndexDB支持访问该应用程序。请与另一个浏览器重试。',
    'Ref: Resource not found': '您尝试访问的内容不存在。',
    'Ref: Permission denied - SITE_HIDDEN': '您尝试访问的内容属于非公开站点。',
    'Ref: Permission denied - CONTRACT_DIFFERENT': '您要访问的内容未授权您的帐户。',
    'Ref: TabTitle': '错误',
    'Ref: Site link invalid': '此链接无效，如果问题持续存在，请选择另一个站点或联系帮助台。',
    'Ref: Access Denied': '拒绝访问。用户权限不足。',
  },
  DefaultErrorPage: {
    'Ref: Oops!': '哎呀！ {robot_emoji}',
    'Ref: Error title': '出了些问题！',
    'Ref: Error body': '如果问题持续存在，请尝试刷新此页面或联系帮助台。',
    'Ref: Error ID:': '错误ID：{errorId}',
    'Ref: Back to home': '返回主页',
  },
  Child: { 'Ref: Route Not Found': '您要查找的服务在此地点不可用。请稍后再试或联系您的服务台。' },
  AccountValidation: {
    'Ref: Password changed message':
      '恭喜，您的新密码已成功保存，您现在可以从您的帐户访问我们的所有服务。',
    Welcome: '欢迎',
    'Ref: Welcome message':
      '您现在已经成功地注册了新的Bite应用程序，并可以继续发现和享受我们所有的服务。',
  },
  PasswordChange: {
    'Change Password': '更改密码',
    Welcome: '欢迎',
    'Ref: Password message': '您的密码需要更改，因为它不够安全',
  },
  AccountPasswordChange: {
    'Change your password here': '在这里更改密码',
    'Your old password': '您的旧密码',
    'Type in your new password': '输入新密码',
    'Re-type in your new password': '重新输入您的新密码',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': '您的密码已被更改' },
  AccountPasswordChangeFailure: {
    'Ref: Error message': '发生错误，因此您的更改没有保存。请再试一次。',
  },
  PasswordForgotten: {
    'Ref: Page title': '重设密码',
    'Ref: Provide email':
      '请在{app_name}上注册时提供使用的电子邮件地址。电子邮件将发送给您以重置密码。',
    'Ref: Something happened': '发生了一些事。请稍后再试。',
  },
  ResetEmailSent: {
    'Ref: New email sent': '我们发送了一封新电子邮件。请等待30秒再次重新安排。',
    'Go to login page': '转到登录页面',
    'Resend email': '重发电子邮件',
    'Ref: Email sent title': '密码重置电子邮件已发送给您',
    'Ref: TabTitle': '重设密码',
    'Ref: Email sent msg':
      '重置链接已发送到您的地址{email_address}。请等待30秒，然后再尝试重新发送。',
  },
  ResetPassword: { 'Enter your new password': '输入新密码' },
  ResetPasswordSuccess: {
    'Ref: Password changed': '你的密码已成功更改',
    'Go to login page': '转到登录页面',
  },
  ResetPasswordFailure: {
    'Password reset fail': '密码重置失败',
    'Ref: Error': '发生错误，请重试',
    'Reset your password': '请重置您的密码',
    'Go to login page': '转到登录页面',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': '隐私政策',
    'Ref: Privacy Policy Body':
      '通过访问此平台，Sodexo SA和本地Sodexo实体将处理您的个人数据，以至少管理您的帐户并提供通过平台提供的服务和优惠。如果您想要有关处理个人数据，将访问谁的更多信息以及如何行使您对个人数据的权利，请在下面咨询下面的隐私政策，并在您的帐户中随时可用。',
    'Ref: Continue': '继续',
    'Ref: Read Privacy Policy': '阅读隐私政策',
  },
  AppUpdatePage: { 'Ref: GoToStore': '转到商店' },
  SharePage: { 'Ref: Select a site': '选择一个地点' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.': '要查看隐私政策，请选择您的国家。',
  },
};
export default labels;
