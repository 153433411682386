import { Switch, Route } from 'react-router-dom';

import Survey from '../components/Survey';
import SurveysList from '../components/SurveysList';
import { pagePaths } from '../config';

import Success from './Success';

const Surveys = () => {
  return (
    <div>
      <Switch>
        <Route path={pagePaths['Success']} children={<Success />} />
        <Route path={pagePaths['Survey']} children={<Survey />} />
        <Route path={pagePaths['Module']} children={<SurveysList />} />
      </Switch>
    </div>
  );
};

export default Surveys;
