const labels = {
  All: {
    'status: all': 'Wszystkie',
    'status: open': 'Otwarte',
    'status: pending': 'W oczekiwaniu',
    'status: closed': 'Zamknięte',
    Location: 'Lokalizacja',
    Subject: 'Temat',
    Description: 'Twój szczegółowy wniosek',
    'Ref: description field label': 'Opis',
    'Ref: Title': 'Tytuł',
    phone_number: 'Twój numer telefonu',
    request_category: 'Kategoria',
    Attachments: 'Załączniki',
    'request service': 'Poproś o usługę',
    'New service request': 'Utwórz kolejne żądanie',
    'Go to home': 'Przejdź do domu',
    'Back to this service request': 'Powrót do tego żądania usługi',
    'Back to service requests': 'Powrót do żądań serwisowych',
    Feedback: 'Komentarze',
    'Ref: Request category': 'Kategoria',
    Cleaning: 'Czyszczenie',
    'Laundry services': 'Usługi pralni',
    'Grounds maintenance': 'Podstawowa konserwacja',
    'Soft services': 'Usługi miękkie',
    'Hard services': 'Trudne usługi',
    'Pest control services': 'Usługi kontroli szkodników',
    'Waste management': 'Gospodarowanie odpadami',
    'Asset Lifecycle Services': 'Usługi cyklu życia aktywów',
    'There are no updates for this request': 'Nie ma aktualizacji tego żądania',
    'Transportation Services': 'Usługi transportowe',
    'Ref: you pronoun': '(ty)',
    'Ref: SR raised for': 'Osoba, której dotyczy zgłoszenie',
    'Ref: Select a location': 'Wybierz lokalizację',
    'Ref: Your request': 'Twoja prośba',
    'Ref: Summary': 'Streszczenie',
    'Ref: site': 'Strona',
  },
  Comment: {
    'Ref: Page title': 'Update',
    'Ref: please update us with details':
      'Więcej informacji do przekazania? Komentarz? Im więcej wiemy o Twoim wniosku, tym lepiej możemy Ci pomóc.',
  },
  Confirm: {
    'Ref: Page title': 'Potwierdź swoją prośbę',
    'Ref: next': 'Prześlij',
    summary: 'Podsumowanie',
    'Ref: Save as my preferred location': 'Zapisz jako moją preferowaną lokalizację',
  },
  Describe: {
    'Ref: Page title': 'Opisz swoją prośbę',
    'Ref: Required fields: header': 'Proszę podać dodatkowe informacje',
    'Ref: Required fields': 'W polu szczegółów należy podać następujące informacje:',
    'Ref: Required field: title': 'Tytuł jest wymagany.',
    'Ref: Required field: description': 'Opis jest wymagany.',
    'Ref: Required field: phone': 'Numer telefonu jest wymagany.',
    'Ref: Required field: category': 'Wymagana jest kategoria.',
    'Ref: Invalid field: category': 'Podana kategoria jest nieprawidłowa.',
    'Ref: Request template field label': 'Popularne prośby',
    'Ref: Request template field placeholder': 'Pusty arkusz',
    your_request: 'Twoja prośba',
    'Ref: Description message':
      'Im bardziej szczegółowa będzie Twoja prośba, tym lepiej będziemy mogli Cię wesprzeć.',
    phone_number: 'Twój numer telefonu (skorzystaj z profesjonalnego telefonu, jeśli go masz)',
    'Ref: Affected person': 'Osoba, której dotyczy zgłoszenie',
    'Ref: No affected person results': 'Brak osób spełniających żądane kryteria',
    'Ref: Notification title': 'Zmieniłeś lokalizację',
    'Ref: Notification info': 'Jesteś teraz w {nazwa strony}',
    'Ref: Add photos': 'Dodaj zdjęcia',
    'Ref: Your location': 'Twoja lokalizacja',
  },
  Details: {
    'Ref: Page title': 'Szczegóły żądania',
    Details: 'Szczegóły',
    Updates: 'Aktualizacje',
    'Update us': 'Poinformuj nas',
    'Ref: timeline': 'Oś czasu',
    'Ref: attachments': 'Załączniki',
    'Ref: location': 'Lokalizacja',
    'Ref: createdOn': 'Utworzony na',
    'Ref: reference': 'Odniesienie',
    'Feedback was submitted': 'Wysłana informacja zwrotna',
    'Ref: sent': 'Wysłane',
    'Ref: received': 'Otrzymane',
    'Ref: inProgress': 'W trakcie realizacji',
    'Ref: completed': 'Zakończono',
    'Ref: conversation': 'Rozmowa',
    'Ref: Priority': 'Priorytet',
    'Ref: Target start date': 'Data rozpoczęcia celu',
    'Ref: Target completion date': 'Data zakończenia celu',
    'Ref: Priority fields placeholder message':
      'Zostanie on zaludniony po zatwierdzeniu przez pomocy technicznej',
  },
  Failed: {
    'Ref: Body':
      'Wystąpił błąd i Twoje zgłoszenie serwisowe nie zostało utworzone. Jeśli ten błąd nadal występuje, skontaktuj się z działem pomocy technicznej.',
    'Ref: Tabtitle': 'Żądanie usługi nie udało się utworzyć',
  },
  Home: { 'Ref: Page title': 'Upraszanie' },
  RequestFeedback: {
    'Ref: Page title': 'Opinie',
    'Ref: Feedback headline': 'Proszę podzielić się swoimi opiniami',
    'Ref: Feedback title': 'Opinie o SR #{ref}',
    'Ref: Question: general sentiment':
      'Ogólnie rzecz biorąc, w jakim stopniu jesteś zadowolony z tego, jak Sodexo obsłużyło Twoje zgłoszenie serwisowe?',
    'Ref: Question: answer quality': 'Jak oceniasz jakość otrzymanej usługi?',
    'Ref: Question: staff interaction': 'Jak oceniasz swoje kontakty z naszym personelem?',
    'Ref: Question: efficiency': 'Jak oceniasz naszą efektywność?',
    'Ref: Question: open': 'Komentarz',
    'Ref: Open question placeholder': 'Co zrobiliśmy dobrze? Gdzie możemy się poprawić?',
    no_comment: 'Brak komentarza',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Dziękujemy za poświęcenie czasu na pozostawienie komentarza, Twoja opinia jest dla nas ważna, abyśmy mogli poprawić Twoje doświadczenie.',
  },
  Locate: {
    'Ref: Page title': 'Wybierz lokalizację',
    'Ref: Top bar title': 'Zlokalizuj swoje żądanie',
    'Ref: Select this location': 'Wybierz tę lokalizację',
    drill: 'przeglądaj',
    'Ref: Scanner button': 'Lokalizacja z QR? Zeskanuj ją',
    'Ref: Scanner header': 'Proszę zeskanować swoją lokalizację QR',
    'Sorry, this location could not be found.':
      'Przepraszamy, nie udało się znaleźć tej lokalizacji.',
    'Close scanner': 'Zamknij skaner',
    'Ref: Switch site component intro sentence': 'Żądasz usługi w miejscu {site name}.',
    'Ref: search location by keywords': 'Wyszukaj lokalizację',
    'Ref: Enter Location name': 'Wprowadź nazwę lokalizacji',
    'Ref: Enter manual location': 'Wprowadź lokalizację ręcznie',
    'Ref: Location name': 'Nazwa lokalizacji',
    'Ref: No results':
      'Nie mogliśmy znaleźć Twojej lokalizacji. Może chcesz wprowadzić ją ręcznie.',
    'Ref: Up one level': 'W górę o jeden poziom',
    'Ref: Navigate': 'Przejdź na stronę .',
    'Ref: Select': 'Wybierz',
    'Ref: Selected Location': 'Wybrana lokalizacja',
    'Ref: Preferred Location': 'Preferowana lokalizacja',
    'Ref: Building': 'Budynek',
    'Ref: Floor': 'Piętro',
    'Ref: Aisle': 'Nazwa',
    'Ref: Room': 'Pokój',
    'Ref: Notification title': 'Zmieniłeś lokalizację',
    'Ref: Notification info': 'Jesteś teraz w {nazwa strony}',
    'Ref: Scanner error header': 'Błąd skanowania.',
    'Ref: Scan QR Code': 'Zeskanuj kod QR',
    'Ref: Error body': 'Ten kod QR nie jest prawidłowy.',
    'Ref: Loading Locations Warning':
      'Poczekaj, kiedy przygotowujemy Twoje doświadczenie na pierwszą prośbę na tej stronie.',
    'Ref: Search Result is limited to:':
      'Wynik wyszukiwania jest ograniczony do {limit}. Proszę udoskonalić swoje kryteria wyszukiwania.',
    'Ref: Open the location search field': 'Otwórz pole wyszukiwania lokalizacji',
  },
  Success: {
    'Ref: Body':
      'Otrzymaliśmy Twoją prośbę i właśnie się nią zajmujemy. Wkrótce się z Tobą skontaktujemy. Wyświetlenie zgłoszenia serwisowego w aplikacji może potrwać do 20 minut.',
    'Ref: Tabtitle': 'Utworzone żądanie usługi',
    'Ref: Download app title': 'Uprość swoje zgłoszenia serwisowe',
    'Ref: Download app subtitle': 'Pobierz naszą aplikację i podnieś swoje wrażenia',
    'Ref: Download app button': 'Pobierz aplikację',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'To jest Twoje centrum wsparcia w miejscu pracy',
    'Ref: Tutorial: tabname': 'Upraszanie',
    'Ref: Tutorial: body': 'Jesteśmy tutaj, aby Ci pomóc. Zaloguj się o jedno żądanie na wpis.',
    "What's new: title": 'Łatwe znajdowanie lokalizacji',
    "What's new: body":
      'Możesz teraz zeskanować QR lokalizacji, przeszukać poziomy lokalizacji lub po prostu wybrać jedną z naszych inteligentnych sugestii.',
  },
  Widget: {
    'Ref: Service request': 'Zgłoszenie serwisowe',
    'Ref: Placeholder body': 'Na razie brak prośby o usługę',
    'Ref: New request button': 'Nowa prośba',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Zmieniłeś lokalizację',
    'Ref: Notification info': 'Jesteś teraz w {site name}',
    'Ref: Welcome': 'Powitanie! {wave_emoji}',
    'Ref: Create service request text': 'Zauważyłeś coś, co chcesz zgłosić?',
    'Create a service request': 'Utwórz zlecenie serwisowe',
    'Ref: Share feedback': 'Podziel się opinią',
    'Ref: Share text': 'Chcesz się z nami czymś podzielić?',
    'Go to home': 'Przejdź do domu',
    'Ref: Error body':
      'Zeskanowany kod QR już nie działa. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
  },
  QrLandingPageError: {
    'Ref: Error title': 'Wystąpił błąd',
    'Ref: Error body':
      'Zeskanowany kod QR już nie działa. Spróbuj ponownie później lub skontaktuj się z pomocą techniczną.',
    'Go to home': 'Przejdź do domu',
  },
  ManualDescribe: {
    'Ref: Page title': 'Opisz swoją prośbę',
    'Ref: Required fields: header': 'Proszę podać dodatkowe informacje',
    'Ref: Required fields': 'W polu szczegółów należy podać następujące informacje:',
    'Ref: Required field: title': 'Wymagane jest podsumowanie.',
    'Ref: Required field: description': 'Opis jest wymagany.',
    'Ref: Required field: category': 'Wymagana jest kategoria.',
    'Ref: Required field: buildingText': 'Budynek jest wymagany.',
    'Ref: Invalid field: category': 'Podana kategoria jest nieważna.',
    'Ref: Your request': 'Twój wniosek',
    'Ref: Description message':
      'Im bardziej szczegółowe jest Twoje zapytanie, tym lepiej możemy Cię wesprzeć.',
    'Ref: Do you have a location QR code': 'Czy masz kod QR lokalizacji?',
    'Ref: Scan QR Code': 'Zeskanuj kod QR',
    'Ref: Please select category': 'Proszę wybrać kategorię',
    'Ref: Please select site': 'Proszę wybrać miejsce',
    'Ref: building': 'Budynek',
    'Ref: room': 'Pokój',
    'Ref: Scan error': 'Błąd skanowania',
    'Ref: The QR code is not valid': 'Kod QR jest nieprawidłowy.',
    'Ref: Add location with QR Scanner': 'Dodaj lokalizację ze skanerem QR',
    'Ref: Description': 'Opis',
    'Ref: Forbidden error':
      'Przykro nam, ten kod QR nie pasuje do Twojej zarejestrowanej lokalizacji.',
    'Ref: Invalid error': 'Przykro nam, wygląda na to, że ten kod QR nie jest już ważny.',
  },
};
export default labels;
