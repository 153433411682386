import classNames from 'classnames';
import { FC, useId, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { TooltipProps } from './Tooltip.types';

import styles from './Tooltip.module.css';

const Tooltip: FC<TooltipProps> = ({
  content,
  place,
  children,
  className,
  customStyles,
  noArrow,
  'tab-index': tabIndex = 0,
  'aria-label': ariaLabel = '',
  tooltipId,
  'data-testid': dataTestId = 'tooltip',
  delayShow,
}) => {
  const id = useId();

  const tooltipContent = useMemo(() => {
    if (typeof content === 'string') {
      return content;
    }

    return ReactDOMServer.renderToString(content);
  }, [content]);

  return (
    <>
      {/* eslint-disable */}
      <div
        data-tooltip-id={tooltipId ? tooltipId : id}
        data-tooltip-place={place}
        aria-describedby={tooltipId ? tooltipId : id}
        data-tooltip-content={tooltipContent}
        className={classNames(className)}
        data-testid={dataTestId}
        tabIndex={tabIndex}
        aria-label={ariaLabel ? ariaLabel : undefined}
      >
        {children}
      </div>
      {/* TODO, can be changed to react portal and change anchorID dynamically rendering only one tooltip element for whole page, ref https://react-tooltip.com/docs/examples/state or https://github.com/ReactTooltip/react-tooltip/pull/845 */}
      {!tooltipId && (
        <ReactTooltip
          id={id}
          place={place}
          clickable
          className={classNames(styles.tooltip, customStyles)}
          noArrow={noArrow}
          delayShow={delayShow}
        >
          {tooltipContent}
        </ReactTooltip>
      )}
    </>
  );
};

export default Tooltip;
