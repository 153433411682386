import { useCallback, useEffect, useState } from 'react';

import { apiPath } from '../../../config';
import { CacheService } from '../../../services/CacheService';
import { OfflineQueueService } from '../../../services/OfflineQueueService';
import { endpoints } from '../api';
import { AuditSurveyResponse } from '../api/api.types';
import { Audit, AuditInvite } from '../types';

const useLocalData = (id?: string) => {
  const [audit, setAudit] = useState<AuditSurveyResponse>();
  const [auditInvites, setAuditsInvites] = useState<AuditInvite[]>([]);

  const getLocalAudit = useCallback(async () => {
    if (!id) return;

    const cache = CacheService.getInstance();
    const offlineQueue = OfflineQueueService.getInstance();

    const queuedItem = await offlineQueue.getValue(id);
    const queuedItems = await offlineQueue.getAll();

    /**
     * AUDIT INVITE SYNCHRONIZED
     *
     * When the audit invite was not created while offline it means it is already synchronized with the server,
     * so we can't rely on the offlineQueue data to build the object.
     * In that case, we go for the cached audit invites list and retrieve the information we need from there
     */
    if (!queuedItem || queuedItem?.method === 'patch') {
      const auditInvites = await cache.getValue(apiPath + endpoints.getAuditInvites);
      const invite = auditInvites?.find(({ id: inviteId }: AuditInvite) => id === inviteId) || {};

      const audits = await cache.getValue(apiPath + endpoints.getAudits);
      const audit = audits?.find(({ id: auditId }: Audit) => invite.auditId === auditId) || {};

      if (audit.id) {
        const auditResponse = queuedItems?.find(
          ({ v: { data: responseData } }) => responseData.inviteId === invite.id
        );

        const completedProps = auditResponse
          ? {
              state: auditResponse ? 1 : 0,
              response: {
                submitDate: auditResponse?.v.data.responseDate,
                responses: auditResponse?.v.data.responses,
              },
            }
          : {
              state: invite.state,
              response: invite.response,
            };

        return setAudit({
          inviteId: id,
          auditId: audit.id,
          auditName: audit.name,
          workOrderId: invite.workOrderId,
          workOrderNumber: invite.workOrderNumber,
          auditStream: audit.auditStreamName,
          questions: audit.questions,
          locationId: queuedItem?.data.location?.locationId,
          assetId: queuedItem?.data.location?.assetId,
          assetName: queuedItem?.data.location?.assetName,
          ...completedProps,
        });
      }
      return;
    }

    /**
     * AUDIT INVITE UNSYNCHRONIZED
     *
     * When the audit invite was created while offline its information is stored in the offline queue,
     * so we can leverage on this information to obtain the audit id.
     * With the audit id we can query the cached audits list to get the relevant data and build the object.
     */
    const { auditId } = queuedItem.data || {};

    if (!auditId) return;

    const audits = await cache.getValue(apiPath + endpoints.getAudits);
    const audit = audits?.find(({ id }: Audit) => id === auditId) || {};

    const auditResponse = queuedItems?.find(
      ({ k, v: { data: responseData } }) => responseData.inviteId === id
    );

    setAudit({
      inviteId: id,
      workOrderId: audit.workOrderId,
      workOrderNumber: audit.workOrderNumber,
      auditId: audit.id,
      auditName: audit.name,
      auditStream: audit.auditStreamName,
      questions: audit.questions,
      state: auditResponse ? 1 : 0,
      locationId: auditResponse?.v.data?.locationId,
      assetId: auditResponse?.v.data?.assetId,
      assetName: auditResponse?.v.data?.assetName,
      response: {
        submitDate: auditResponse?.v.data.responseDate,
        responses: auditResponse?.v.data.responses,
      },
    });
  }, [id]);

  const getQueuedAudits = useCallback(async () => {
    const cache = CacheService.getInstance();
    const offlineQueue = OfflineQueueService.getInstance();

    const queuedItems = await offlineQueue.getAll();

    if (!queuedItems) return;

    const queuedAudits = queuedItems.filter(({ v: { url, method } }) => {
      if (method === 'post' && url.includes(endpoints.createAudit)) return true;
      return false;
    });

    const audits = await cache.getValue(apiPath + endpoints.getAudits);
    const locations = await cache.getValue(apiPath + endpoints.getLocations);

    const queuedAuditInvites = queuedAudits.map(({ k, v: { data } }) => {
      const audit = audits?.find(({ id }: Audit) => id === data.auditId) || {};
      const location = locations?.find(({ id }: Audit) => id === data.locationId) || {};

      const auditResponse = queuedItems.find(
        ({ v: { data: responseData } }) => responseData.inviteId === k
      );
      return {
        auditId: data.auditId,
        auditName: audit.name,
        auditStreamType: audit.auditStreamName,
        dueDate: data.dueDate,
        id: k,
        state: auditResponse ? 1 : 0,
        workOrderLocation: location.name,
        locationId: location.id,
        workOrderNumber: '',
        isUnsynchronised: true,
        'data-testid': `audit-invite-${data.auditId}`,
      };
    });

    setAuditsInvites(queuedAuditInvites);
  }, []);

  const refreshLocalData = useCallback(() => {
    getLocalAudit();
    getQueuedAudits();
  }, [getLocalAudit, getQueuedAudits]);

  useEffect(() => {
    refreshLocalData();
  }, [refreshLocalData]);

  return { audit, auditInvites, refreshLocalData };
};

export default useLocalData;
