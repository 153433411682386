const labels = {
  StaysList: {
    'Ref: Page title': '留下来',
    'Ref: Past': '过去的',
    'Current and future stays': '当前和未来的住宿',
    'filter label: period': '时期',
    'Ref: Search': '搜索',
  },
  StayDetails: {
    'Ref: Page title': '你的住宿详情',
    'Arrival date': '到达日期',
    'Departure date': '出发日期',
    'Room number': '房间',
    'Reservation number': '预定编号',
    CheckIn: '办理入住',
    CheckOut: '办理退房',
    CheckIn_1: '调查{lodgeName}，今天到达？ （预留号码{resNumber}）',
    CheckedIn_1: '您已入住{lodgeName}，房间{roomNumber}。',
    CheckOut_1: '{lodgeName}预定编号{resNumber}，现在退房？ 您的房间钥匙将被停用。',
    CheckedOut_1: '{lodgeName}预定编号{resNumber}已退房。',
  },
  StaysSuccess: {
    'Ref: Title': '谢谢您的住宿！',
    'Ref: Body': '您的结帐已成功处理。',
    'Ref: Go to home': '回家',
    'Ref: Provide a feedback': '提供反馈',
  },
};
export default labels;
