import { configureStore } from '@reduxjs/toolkit';
import { get } from 'idb-keyval';
import md5 from 'md5';
import { Action, Middleware, Reducer, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';

import coreReducer from '../modules/Core/reducers/coreReducer';
import sitesReducer from '../modules/Sites/reducers/sitesReducer';
import sharedReducer from '../reducers/sharedReducer';
import baseApi from '../services/api/baseApi';
import dspApi from '../services/api/dspApi';
import { State } from '../types/state.types';

import clearSchedulers from './middlewares/clearSchedulers';
import { StoreType } from './store.types';

const staticReducers = {
  Core: coreReducer,
  Shared: sharedReducer,
  Sites: sitesReducer,
};

export let store: StoreType;

/** Wrap the reducer to inject cached data */
function reducerWrapper(reducer: Reducer, initialState?: State) {
  return (state: State, action: Action): State => {
    const applicableState = state || initialState;
    return reducer(applicableState, action);
  };
}

export default function setupStore(storeInitialState = {}) {
  const middlewares = [thunkMiddleware, clearSchedulers];
  if (window.Worker) {
    const persistDataLocallyMiddleware =
      require('./middlewares/persistDataLocallyMiddleware').default;
    middlewares.push(persistDataLocallyMiddleware);
  }
  const enhancer = applyMiddleware(...middlewares);

  const injectReducer = (key: string, newSliceReducer: any) => {
    store.injectedReducers[key] = newSliceReducer;
    store.replaceReducer(createReducer(store.injectedReducers));
  };

  const injectReducerFromModuleId = async (key: string) => {
    let cacheVersion = null;
    try {
      cacheVersion = require('../modules/' + key + '/config').cacheVersion;
    } catch (error) {}

    if (!cacheVersion) {
      return;
    }

    const userContactId = store.getState().Core?.user?.contactId;

    const cache = await get(key);

    const logoutSurvivingCacheKey = md5(key + '|' + userContactId);
    const logoutSurvivingCache = await get(logoutSurvivingCacheKey);

    let reducerLoadedState = store.getState()[key];

    let reducer = null;
    let defaultState = null;

    try {
      reducer = require('../modules/' + key + '/redux/reducer').default;
      defaultState = require('../modules/' + key + '/redux/reducer').defaultState;
    } catch (e) {
      try {
        reducer = require('../modules/' + key + '/reducers').reducer;
        defaultState = require('../modules/' + key + '/reducers').defaultState;
      } catch (e) {
        // no reducer found
        return;
      }
    }

    if (defaultState) {
      reducerLoadedState = {
        ...defaultState,
        ...reducerLoadedState,
      };
    }

    /**
     * We ONLY EVER use cached data if the cache version is matching.
     * Otherwise the data structure may have changed and the app will error out
     */
    if (cacheVersion !== undefined && cache?.version === cacheVersion) {
      reducerLoadedState = {
        ...reducerLoadedState,
        ...cache.data,
      };
    }

    /** Idem for the logout-surviving cache */
    if (cacheVersion !== undefined && logoutSurvivingCache?.version === cacheVersion) {
      reducerLoadedState = {
        ...reducerLoadedState,
        ...logoutSurvivingCache.data,
      };
    }

    injectReducer(key, reducerWrapper(reducer, reducerLoadedState));
  };

  const ejectReducer = (key: string) => {
    !store.injectedReducers.hasOwnProperty(key) || delete store.injectedReducers[key];
  };

  store = {
    ...configureStore({
      reducer: createReducer(),
      preloadedState: storeInitialState,
      enhancers: (defaultEnhancers: any) => [...defaultEnhancers, enhancer],
      middleware: (
        getDefaultMiddleware: (args: {
          immutableCheck: boolean;
          serializableCheck: boolean;
        }) => Middleware[]
      ) =>
        getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })
          .concat(baseApi.middleware)
          .concat(dspApi.middleware),
    }),
    injectedReducers: {},
    injectReducer,
    injectReducerFromModuleId,
    ejectReducer,
  };

  //add shared slice if not exists
  if (!store.getState()['Shared']) injectReducer('Shared', sharedReducer);

  return store;
}

function createReducer(injectedReducers = {}) {
  return combineReducers({
    ...staticReducers,
    ...injectedReducers,
    [baseApi.reducerPath]: baseApi.reducer,
    [dspApi.reducerPath]: dspApi.reducer,
  });
}
