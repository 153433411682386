import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { QRCodeReaderResponse } from '../../../components/molecules/QrCode/reader.types';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { State } from '../../../types/state.types';
import {
  useGetPublicSitesQuery,
  useLazyGetSiteByCodeQuery,
  useSetInitialSiteAndContractMutation,
} from '../../Sites/api/api';
import LocationListPage from '../../Sites/components/LocationListPage/LocationListPage';
import { ISite } from '../../Sites/types/sites.types';
import { pagePaths } from '../config';
import { useCoreTranslation } from '../hooks/useCoreTranslation';

export enum CodeErrors {
  UNAUTHORIZED = 'Ref: Unauthorized error',
  FORBIDDEN = 'Ref: Forbidden',
  NOT_FOUND = 'Ref: Not found',
  GENERIC_ERROR = 'Ref: Generic error',
}

const AccountOnboardingLocation = () => {
  const history = useHistory();
  const [getSiteByCode, { isFetching: isGetSiteLocked }] = useLazyGetSiteByCodeQuery();

  const { user } = useSelector((state: State) => state.Core);
  const contractId = user?.contract?.id;

  const dispatch = useDispatch();

  const [codeError, setCodeError] = useState<CodeErrors | undefined>();
  const { data: sites = [], isFetching: isSitesLocked } = useGetPublicSitesQuery({});
  const [setInitialSiteAndContract, { isLoading: isOnboarding }] =
    useSetInitialSiteAndContractMutation();

  const checkIfOnboarded = useCallback(() => {
    if (!!contractId && !isOnboarding) history.replace(pagePaths.Home);
  }, [history, contractId, isOnboarding]);

  const { label } = useCoreTranslation(__filename);

  const siteIdFromShare = localStorage.getItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);

  useEffect(() => {
    if (siteIdFromShare) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);
      history.replace(`${pagePaths.Share.replace(':siteCode', siteIdFromShare)}`);
    }
  }, [history, siteIdFromShare]);

  useEffect(() => {
    checkIfOnboarded();
  }, [checkIfOnboarded]);

  const handleSiteSelection = useCallback(
    (site: ISite) => {
      if (site.id) {
        setInitialSiteAndContract({ dispatch, siteId: site.id });
      }
    },
    [dispatch, setInitialSiteAndContract]
  );

  const handleValidateCode = useCallback(
    async (siteCode: string, closeModal: Function) => {
      const { data: { site } = {}, error } = await getSiteByCode({ code: siteCode });

      if (site) {
        closeModal();
        await setInitialSiteAndContract({ dispatch, siteId: site.id });
        return;
      }

      const { status } = (error || {}) as { status: number };
      switch (status) {
        case 401:
          setCodeError(CodeErrors.UNAUTHORIZED);
          break;
        case 403:
          setCodeError(CodeErrors.FORBIDDEN);
          break;
        case 404:
          setCodeError(CodeErrors.NOT_FOUND);
          break;
        default:
          setCodeError(CodeErrors.GENERIC_ERROR);
      }
    },
    [dispatch, getSiteByCode, setInitialSiteAndContract]
  );

  const handleScanCode = useCallback(
    async ({ response }: QRCodeReaderResponse, openErrorModal: () => void) => {
      if (response) {
        const { data: { site } = {}, error } = await getSiteByCode({ code: response });
        if (site) {
          await setInitialSiteAndContract({ dispatch, siteId: site.id });
          return;
        }

        const { status } = (error || {}) as { status: number };
        switch (status) {
          case 401:
            setCodeError(CodeErrors.UNAUTHORIZED);
            openErrorModal();
            break;
          case 403:
            setCodeError(CodeErrors.FORBIDDEN);
            openErrorModal();
            break;
          case 404:
            setCodeError(CodeErrors.NOT_FOUND);
            openErrorModal();
            break;
          default:
            setCodeError(CodeErrors.GENERIC_ERROR);
        }
      }
    },
    [dispatch, getSiteByCode, setInitialSiteAndContract]
  );

  if (isSitesLocked || isGetSiteLocked || isOnboarding || siteIdFromShare) {
    return <LoadingPage />;
  }

  return (
    <LocationListPage
      withNavBar={false}
      hideAllWidgets={true}
      title={label('Ref: Page title')}
      sites={sites}
      siteCodeError={codeError}
      pagePaths={pagePaths}
      label={label}
      layoutOptions={{ withStepper: true, withNavBar: false }}
      isSiteCodeLocked={isGetSiteLocked}
      onSiteSelection={handleSiteSelection}
      onValidateSiteCode={handleValidateCode}
      onScanSiteCode={handleScanCode}
      handleCodeError={setCodeError}
      enableUserLocation
    />
  );
};

export default AccountOnboardingLocation;
