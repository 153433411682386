const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Permintaan Layanan - Meja Bantuan',
    'Ref: Forbidden heading': 'Terlarang',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Anda memberikan permintaan layanan di Situs {siteName}.',
    'Ref: Dates': 'tanggal',
    'Ref: SRL list refresh': 'Daftar penyegaran',
    'Ref: Export': 'ekspor',
    'Ref: Empty/not filled': 'Kosong / tidak diisi',
    'Ref: Name': 'Nama',
    'Ref: Date': 'Tanggal',
    'Ref: Category': 'Kategori',
    'Ref: User': 'Pengguna',
    'Ref: Location': 'Lokasi',
  },
};
export default labels;
