const mockResponse = {
  getGeographies: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        geolocationCode: 'FR',
        geolocations: [
          {
            name: 'Algeria',
            code: 'DZ',
            regionCode: 'EU',
          },
          {
            name: 'Argentina',
            code: 'AR',
            regionCode: 'EU',
          },
          {
            name: 'Australia / Pacific',
            code: 'AU',
            regionCode: 'EU',
          },
          {
            name: 'Austria',
            code: 'AT',
            regionCode: 'EU',
          },
          {
            name: 'Bahrain',
            code: 'BH',
            regionCode: 'EU',
          },
          {
            name: 'Belgium',
            code: 'BE',
            regionCode: 'EU',
          },
          {
            name: 'Brazil',
            code: 'BR',
            regionCode: 'EU',
          },
          {
            name: 'Canada',
            code: 'CA',
            regionCode: 'EU',
          },
          {
            name: 'Chile',
            code: 'CL',
            regionCode: 'EU',
          },
          {
            name: 'China',
            code: 'CN',
            regionCode: 'EU',
          },
          {
            name: 'Colombia',
            code: 'CO',
            regionCode: 'EU',
          },
          {
            name: 'Costa Rica',
            code: 'CR',
            regionCode: 'EU',
          },
          {
            name: 'Czech Republic',
            code: 'CZ',
            regionCode: 'EU',
          },
          {
            name: 'Denmark',
            code: 'DK',
            regionCode: 'EU',
          },
          {
            name: 'Egypt',
            code: 'EG',
            regionCode: 'EU',
          },
          {
            name: 'Finland',
            code: 'FI',
            regionCode: 'EU',
          },
          {
            name: 'France',
            code: 'FR',
            regionCode: 'EU',
          },
          {
            name: 'Germany',
            code: 'DE',
            regionCode: 'EU',
          },
          {
            name: 'Hong Kong',
            code: 'HK',
            regionCode: 'EU',
          },
          {
            name: 'Hungary',
            code: 'HU',
            regionCode: 'EU',
          },
          {
            name: 'India',
            code: 'IN',
            regionCode: 'EU',
          },
          {
            name: 'Indonesia',
            code: 'ID',
            regionCode: 'EU',
          },
          {
            name: 'Ireland',
            code: 'IE',
            regionCode: 'EU',
          },
          {
            name: 'Israel',
            code: 'IL',
            regionCode: 'EU',
          },
          {
            name: 'Italy',
            code: 'IT',
            regionCode: 'EU',
          },
          {
            name: 'Japan',
            code: 'JP',
            regionCode: 'EU',
          },
          {
            name: 'Jordan',
            code: 'JO',
            regionCode: 'EU',
          },
          {
            name: 'Kuwait',
            code: 'KW',
            regionCode: 'EU',
          },
          {
            name: 'Lebanon',
            code: 'LB',
            regionCode: 'EU',
          },
          {
            name: 'Malaysia',
            code: 'MY',
            regionCode: 'EU',
          },
          {
            name: 'México',
            code: 'MX',
            regionCode: 'EU',
          },
          {
            name: 'Netherlands',
            code: 'NL',
            regionCode: 'EU',
          },
          {
            name: 'New Zealand',
            code: 'NZ',
            regionCode: 'EU',
          },
          {
            name: 'Norway',
            code: 'NO',
            regionCode: 'EU',
          },
          {
            name: 'Oman',
            code: 'OM',
            regionCode: 'EU',
          },
          {
            name: 'Panama',
            code: 'PA',
            regionCode: 'EU',
          },
          {
            name: 'Peru',
            code: 'PE',
            regionCode: 'EU',
          },
          {
            name: 'Philippines',
            code: 'PH',
            regionCode: 'EU',
          },
          {
            name: 'Poland',
            code: 'PL',
            regionCode: 'EU',
          },
          {
            name: 'Portugal',
            code: 'PT',
            regionCode: 'EU',
          },
          {
            name: 'Qatar',
            code: 'QA',
            regionCode: 'EU',
          },
          {
            name: 'Romania',
            code: 'RO',
            regionCode: 'EU',
          },
          {
            name: 'Saudi Arabia',
            code: 'SA',
            regionCode: 'EU',
          },
          {
            name: 'Singapore',
            code: 'SG',
            regionCode: 'EU',
          },
          {
            name: 'South Africa',
            code: 'ZA',
            regionCode: 'EU',
          },
          {
            name: 'South Korea',
            code: 'KR',
            regionCode: 'EU',
          },
          {
            name: 'Spain',
            code: 'ES',
            regionCode: 'EU',
          },
          {
            name: 'Sweden',
            code: 'SE',
            regionCode: 'EU',
          },
          {
            name: 'Switzerland',
            code: 'CH',
            regionCode: 'EU',
          },
          {
            name: 'Taiwan',
            code: 'TW',
            regionCode: 'EU',
          },
          {
            name: 'Thailand',
            code: 'TH',
            regionCode: 'EU',
          },
          {
            name: 'Turkey',
            code: 'TR',
            regionCode: 'EU',
          },
          {
            name: 'United Arab Emirates',
            code: 'AE',
            regionCode: 'EU',
          },
          {
            name: 'United Kingdom',
            code: 'UK',
            regionCode: 'EU',
          },
          {
            name: 'United States of America',
            code: 'NA',
            regionCode: 'EU',
          },
          {
            name: 'Vietnam',
            code: 'VN',
            regionCode: 'EU',
          },
        ],
      }),
  },
};

export default mockResponse;
