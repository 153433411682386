import { renderHook, act } from '@testing-library/react';
import moment from 'moment';

import { DATE_FORMAT } from '../../../../constants';
import { cacheSelectedFilters } from '../../helpers/menuSelector.helper';

import useMenuSelectorFilter from './useMenuSelectorFilter';

jest.mock('../../helpers/menuSelector.helper', () => ({
  ...jest.requireActual('../../helpers/menuSelector.helper'),
  cacheSelectedFilters: jest.fn(),
}));

describe('useMenuSelectorFilter', () => {
  describe('when no menuDateFromUrl set and no defaultFiltersFromCache set', () => {
    let hookRunResult: any;

    beforeEach(() => {
      act(() => {
        const { result } = renderHook(() => useMenuSelectorFilter(undefined, null));
        hookRunResult = result;
      });
    });

    it('should set menu and filter date for today', () => {
      const expected = moment().format(DATE_FORMAT);
      expect(hookRunResult.current.menuDate).toBe(expected);
      expect(hookRunResult.current.filtering).toEqual({
        filter_date: {
          [expected]: true,
        },
      });

      const mockCacheSelectedFilters = cacheSelectedFilters as jest.Mock;
      expect(mockCacheSelectedFilters).not.toHaveBeenCalled();
    });
  });

  describe('when no menuDateFromUrl set and defaultFiltersFromCache set', () => {
    let hookRunResult: any;
    const dateInCache = moment().add(1, 'days').format(DATE_FORMAT);

    beforeEach(() => {
      act(() => {
        const { result } = renderHook(() =>
          useMenuSelectorFilter(undefined, {
            filter_date: {
              [dateInCache]: true,
            },
          })
        );

        hookRunResult = result;
      });
    });

    it('should set menu and filter date from date from cache', () => {
      expect(hookRunResult.current.menuDate).toBe(dateInCache);
      expect(hookRunResult.current.filtering).toEqual({
        filter_date: {
          [dateInCache]: true,
        },
      });
      const mockCacheSelectedFilters = cacheSelectedFilters as jest.Mock;
      expect(mockCacheSelectedFilters).not.toHaveBeenCalled();
    });
  });

  describe('when menuDateFromUrl set', () => {
    let hookRunResult: any;
    const dateInCache = moment().add(1, 'days').format(DATE_FORMAT);
    const menuDateInUrl = moment().add(2, 'days').format(DATE_FORMAT);

    beforeEach(() => {
      act(() => {
        const { result } = renderHook(() =>
          useMenuSelectorFilter(menuDateInUrl, {
            filter_date: {
              [dateInCache]: true,
            },
          })
        );

        hookRunResult = result;
      });
    });

    it('should set menu, filter and cache to menuDateInUrl', () => {
      expect(hookRunResult.current.menuDate).toBe(menuDateInUrl);
      expect(hookRunResult.current.filtering).toEqual({
        filter_date: {
          [menuDateInUrl]: true,
        },
      });

      const mockCacheSelectedFilters = cacheSelectedFilters as jest.Mock;
      expect(mockCacheSelectedFilters).toHaveBeenCalled();
    });
  });
});
