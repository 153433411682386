import { TestingProps } from '../../../types';

export enum TITLE_SIZE {
  HEADLINEL = 'headlineL',
  HEADLINES = 'headlineS',
  HEADLINEXS = 'headlineXS',
  SUBTITLES = 'subtitleS',
  BODYMDEFAULT = 'bodyMDefault',
  BODYMBOLD = 'bodyMBold',
  BODYSBOLD = 'bodySBold',
  BODYSDEFAULT = 'bodySDefault',
}

export enum TITLE_TAG {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  SPAN = 'span',
  PARAGRAPH = 'p',
}

export type TitleProps = {
  tag?: TITLE_TAG;
  size?: TITLE_SIZE;
  id?: string;
  className?: string;
  suffix?: React.ReactNode;
  children?: React.ReactNode;
  small?: string;
} & TestingProps;
