import { RegisterOptions, FieldValues, useController } from 'react-hook-form';

import { INPUT_TYPE } from '../../../../constants';
import { TranslationProps } from '../../../../localization/localization.types';
import Input from '../HookInput';
import { PasswordInputProps } from '../Input.types';

import PasswordValidation from './PasswordValidation';

export const PASSWORD_RULES: Record<string, RegExp> = {
  min8Characters: /^(.*).{8,}$/,
  min1Number: /^(?=.*\d).{1,}$/,
  min1Letter: /^(?=.*[A-Za-z]).{1,}$/,
  min1SpecialChar: /^(?=.*?[#?!@$%^&*-._)(='"[\]{}|~`]).{1,}$/,
};

// Combined rules above into one
export const PASSWORD_RULE = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[#?!@$%^&*-._)(='"[\]{}|~`]).{8,}$/;

const PasswordInput = <TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  labelFunc,
  extendedValidation = true,
  'data-testid': dataTestId,
  ...rest
}: PasswordInputProps<TFieldValues> & {
  labelFunc?: TranslationProps['label'];
}) => {
  // TODO need to change either label prop in Input or switch to i18next to avoid conflict
  const labelFix = (str: string) => {
    if (labelFunc) {
      return labelFunc(str);
    }
    return str;
  };

  const rules: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> = {};

  if ('required' in rest) {
    rules.required = extendedValidation === true ? '' : labelFix('Password is required');
  }

  rules.pattern = {
    value: PASSWORD_RULE,
    message: extendedValidation === true ? '' : labelFix('Ref: Input password error strength'),
  };

  const {
    field: { value },
    fieldState: { isDirty },
  } = useController({
    name: name,
    control,
    rules,
  });

  return (
    <>
      <Input
        data-testid={`${dataTestId}-password`}
        inputType={INPUT_TYPE.PASSWORD}
        rules={rules}
        name={name}
        control={control}
        {...rest}
      />
      {extendedValidation && (
        <PasswordValidation
          data-testid={`${dataTestId}-password-validation`}
          rules={PASSWORD_RULES}
          value={value}
          label={labelFix}
          triggerValidation={isDirty}
        />
      )}
    </>
  );
};

export default PasswordInput;
