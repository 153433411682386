import classNames from 'classnames';

import { Skeleton, SkeletonSquare } from '../../atoms/Skeleton';

import { TileSkeletonProps, TILE_VARIANT } from './Tile.types';

import styles from './Tile.module.css';

export const TileSkeleton: React.FC<TileSkeletonProps> = ({
  variant = TILE_VARIANT.STANDARD,
  withoutImage = false,
  withoutActions = false,
  withoutChildren = true,
  className,
  'data-testid': dataTestId = 'tile-skeleton',
  ...props
}) => (
  <div className={styles.container} data-testid={dataTestId}>
    <div
      className={classNames(className, {
        [styles.component]: true,
        [styles.skeleton]: true,
        [styles.withoutImage]: withoutImage,
        [styles.variantBig]: variant === TILE_VARIANT.BIG,
        [styles.withChildren]: !withoutChildren,
      })}
      {...props}
    >
      {withoutImage ? null : (
        <div className={classNames(styles.image, styles.imageSkeleton)}>
          <SkeletonSquare />
        </div>
      )}
      <div
        className={classNames({
          [styles.details]: true,
          [styles.withoutImage]: withoutImage,
          [styles.withoutActions]: withoutActions,
        })}
      >
        <div>
          <h6 className={styles.title}>
            <Skeleton width="50%" />
          </h6>
        </div>
        <div>
          <span className={styles.description}>
            <Skeleton width="75%" />
          </span>
        </div>
      </div>

      {withoutActions || !withoutChildren ? null : (
        <div
          className={classNames({ [styles.actions]: true, [styles.withoutImage]: withoutImage })}
        >
          <SkeletonSquare key="first" />
          <SkeletonSquare key="second" />
        </div>
      )}

      {withoutChildren ? null : (
        <div
          className={classNames({
            [styles.childrenContainer]: true,
            [styles.withoutImage]: withoutImage,
          })}
        >
          <Skeleton width="75px" />
        </div>
      )}
    </div>
  </div>
);
