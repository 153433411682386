const labels = {
  StaysList: {
    'Ref: Page title': 'Bleibt',
    'Ref: Past': 'Vergangenheit',
    'Current and future stays': 'Aktuelle und zukünftige Aufenthalte',
    'filter label: period': 'Zeitraum',
    'Ref: Search': 'Suche',
  },
  StayDetails: {
    'Ref: Page title': 'Ihr Aufenthalt Details',
    'Arrival date': 'Ankunftsdatum',
    'Departure date': 'Abreisedatum',
    'Room number': 'Zimmer',
    'Reservation number': 'Reservierungsnummer',
    CheckIn: 'Einchecken',
    CheckOut: 'Auschecken',
    CheckIn_1: 'Einchecken in {lodgeName}, heute ankommen?(Reservierungsnummer {resNumber})?',
    CheckedIn_1: 'Sie sind in {lodgeName} Zimmer {roomNumber} eingecheckt.',
    CheckOut_1:
      'Sie möchten {lodgeName} Reservierungsnummer {resNumber} jetzt auschecken? Ihr Zimmerschlüssel wird deaktiviert.',
    CheckedOut_1: '{lodgeName} Reservierungsnummer {resNumber} hat ausgeglichen.',
  },
  StaysSuccess: {
    'Ref: Title': 'Vielen Dank für Ihren Aufenthalt!',
    'Ref: Body': 'Ihre Kasse wurde erfolgreich verarbeitet.',
    'Ref: Go to home': 'Zur Startseite',
    'Ref: Provide a feedback': 'Ein Feedback geben',
  },
};
export default labels;
