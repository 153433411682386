import { useSelector } from 'react-redux';

import { SERVICE } from '../../../modules/config';
import { State } from '../../../types/state.types';

export const useIsSetupOptionEnabled = (name: string, serviceName: SERVICE): boolean => {
  const isOptionEnabled = useSelector((state: State) => {
    const service = state.Core.services.list.find(
      (el: { name: string }) => el.name === serviceName
    );

    const setupOption = service?.setupOptions?.find((el: { name: string }) => el.name === name);
    return setupOption ? setupOption.value : null;
  });

  return isOptionEnabled === 'true';
};
