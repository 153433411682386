import { modulesConfig, serviceTypes } from '../../config';

const moduleId = 'ServiceRequestLight';
const cacheVersion = '0';

const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

const pagePaths = {
  Module: modulePath,
  Create: `${modulePath}/create`,
  Summary: `${modulePath}/summary`,
  Details: `${modulePath}/:id/details`,
  Tutorials: `${modulePath}/tutorials`,
};
export { moduleId, cacheVersion, pagePaths };
