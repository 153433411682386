const mockResponse = {
  createRequestLight: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
  getServiceRequestLightList: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37e',
          referenceNumber: '0612156',
          createdOn: '2020-04-20T09:34:46Z',
          title: 'Broken glass on the 1. floor',
          summary: 'Broken glass on the 1. floor',
          category: 'Cleaning',
          raisedFor: 'Michel Hofman',
          phoneNumber: '+33792374728',
          room: 'Kitchen',
          building: 'Brolga',
          description: 'Found broken glass in the kitchen, please clean it up.',
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37f',
          referenceNumber: '0612156',
          createdOn: '2020-04-20T09:34:46Z',
          title: 'Soap in ladies bathroom',
          summary: 'Soap in ladies bathroom',
          category: 'Soft services',
          raisedFor: 'Anna Stone',
          phoneNumber: '+33792374728',
          room: 'Bathroom',
          building: 'Brolga',
          description: 'Please fill in the liquid soap in ladies bathroom on the 2. floor.',
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37g',
          referenceNumber: '0612156',
          createdOn: '2020-04-20T09:34:46Z',
          title: 'Broken dishwasher',
          summary: 'Broken dishwasher',
          category: 'Hard services',
          raisedFor: 'Joe Hardy',
          phoneNumber: '+33792374728',
          room: 'Kitchen',
          building: 'Brolga',
          description: 'Please, fix not working dishwasher on the 3. floor.',
        },
      ]),
  },
};

export default mockResponse;
