const labels = {
  ContentDetails: {
    'Ref: Page title': 'Chi tiết nội dung',
    'See the event': 'Xem sự kiện này',
    'Take the survey': 'làm khảo sát',
    Date: 'Ngày',
    Category: 'Loại',
    'Ref: Content site different title': 'Nội dung này không thuộc về trang web hiện tại của bạn',
    'Ref: Content site different description':
      'Bạn đang xem một nội dung không thuộc về trang web hiện tại của bạn. Bạn sẽ không thể nhìn thấy nó trong danh sách nội dung.',
  },
  ContentList: {
    'Ref: Page title': 'Newsfeed',
    'featured (as in featured content)': 'Đặc sắc',
    'Ref: category - all': 'Tất cả các',
    'Ref: category - promotional': 'Quảng cáo',
    'Ref: category - featured': 'Đặc sắc',
    'Ref: category - other': 'Khác',
  },
  Widget: {
    'Ref: Carousel title': 'Những bài báo thời sự',
    'Ref: Carousel title promo': 'Tin tức hàng đầu',
    'No info yet, come back later': 'Chưa có thông tin nào, hãy quay lại sau 😉',
    'Ref: Content': 'Nội dung',
    'Ref: Widget Title': 'Newsfeed',
    'Ref: MyVillage Carousel title promo': 'Khuyến mãi',
  },
};
export default labels;
