import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { MoreVerticalIcon } from '../../../assets/icons';
import useToggle from '../../../helpers/hooks/useToggle';
import Button from '../../atoms/Button';

import { ButtonPopoverListProps, POPOVER_POSITION } from './Tile.types';

import styles from './Tile.module.css';

export const ButtonPopoverList: React.FC<ButtonPopoverListProps> = ({
  list = [],
  srOnlyText = 'more actions',
  popoverPosition = POPOVER_POSITION.default,
  'data-testid': dataTestId = 'button-popover-list',
}) => {
  const { state: isOpen, toggleOn, toggleOff } = useToggle(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: Event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        toggleOff();
      }
    },
    [toggleOff]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div
      className={classNames(styles.buttonPopoverContainer, {
        [styles.buttonPopoverActive]: isOpen,
      })}
      ref={wrapperRef}
    >
      <Button
        key="more"
        look="tertiary"
        srOnlyText={srOnlyText}
        data-testid={dataTestId}
        active={isOpen}
        onClick={isOpen ? toggleOff : toggleOn}
      >
        <MoreVerticalIcon />
      </Button>
      {isOpen && (
        <div
          className={classNames(styles.popoverContainer, {
            [styles.popoverContainerBelow]: popoverPosition === POPOVER_POSITION.bottom,
          })}
        >
          {list.map(({ name, icon, onClick }, i) => (
            <Button
              className={classNames({
                [styles.popoverBellowButton]: popoverPosition === POPOVER_POSITION.bottom,
              })}
              key={`${name}-${i}`}
              look="tertiary"
              affix={() => <>{icon}</>}
              data-testid={`${dataTestId}-${i}`}
              onClick={(e) => {
                toggleOff();
                onClick && onClick(e);
              }}
            >
              {name}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
