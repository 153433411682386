const labels = {
  All: {
    dailyMenu: 'napi menü',
    anytime: 'Bármikor',
    breakfast: 'Reggeli',
    morningBreak: 'reggeli szünet',
    lunch: 'Ebéd',
    afternoonBreak: 'délutáni szünet',
    dinner: 'Vacsora',
    supper: 'Vacsora',
    nightMeal: 'Éjszakai étkezés',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menük',
    'Ref: View details': 'Részletek megtekintése',
    'Ref: Facility details': 'Létesitmény részletek',
  },
  OpeningHours: { everyday: 'minden nap', 'open all day': 'Egész nap nyitva' },
  ProductsList: {
    'Ref: Page title': 'Menü',
    'Ref: Facility details': 'Létesitmény részletek',
    'Ref: Search products list': 'Keresési elemek',
    'Ref: filter label: date': 'Dátum',
    'Ref: filter label: menus': 'Menü',
    'Ref: filter label: moment': 'Pillanat',
    'Ref: filter label: category': 'Kategória',
    'Ref: filter label: dishes': 'Edények',
    'Ref: filter label: highlight': 'Jelölje ki vagy rejti el a szűrőket edényekkel',
    All: 'Összes',
  },
  ProductDetails: { 'Ref: Page title': 'termék leírás' },
  Widget: {
    'Ref: Carousel title': 'Menük',
    'No info yet, come back later': 'Még nincs információ, gyere vissza később 😉',
    'Ref: menus': 'Menük',
    'Ref: see all': 'Lásd az összeset',
  },
};
export default labels;
