const labels = {
  Success: {
    'Ref: Body': '感謝您完成此調查，這將有助於改善我們的服務。',
    'Ref: Tabtitle': '調查完成了',
    'Take another survey': '進行另一項調查',
  },
  Survey: {
    'Ref: Page title': '調查細節',
    'Please answer all mandatory questions as identify by an asterisk':
      '請回答所有帶型號的必填問題：*',
    'Ref: Take another survey': '進行另一項調查',
    'Ref: Go to Home': '返回主頁',
  },
  SurveysList: {
    'Ref: Page title': '調查',
    'Ref: Available until': '直到可用',
    'Ref: You are currently viewing surveys for the site {site name}.':
      '您當前正在查看{site name}的調查。',
  },
};
export default labels;
