const labels = {
  All: {
    'starts at': 'inizia a',
    'ends at': 'finisce a',
    location: 'Posizione',
    'coming date': 'Data di arrivo',
    'Ref: Currently event not preferred. Click to make preferred':
      "Attualmente l'evento non preferito. Fare clic per fare preferita",
    'Ref: Currently event preferred. Click to make not preferred':
      "Attualmente l'evento preferito. Fare clic per fare non preferire",
  },
  EventDetails: {
    details: 'Particolari',
    'Ref: Page title': "dettagli dell'evento",
    'Event dates': "Date dell'evento",
    'See online:': 'Vedi online:',
    'See all dates': 'Vedi tutte le date',
    'Remove from my selection': 'Rimuovi dalla mia selezione',
    'Add to my selection': 'Aggiungi al mio preferito',
    'Ref: explain calendar usage':
      "Le date dell'evento sono evidenziate nel calendario sottostante. Non perdere gli eventi che ti piacciono! Fai clic su una data di eventi nel calendario per scaricare un invito. Quindi apri il file scaricato per aggiungere l'evento al calendario Preferito.",
    'Ref: Favorite': 'Preferito',
    'Ref:Feedback': 'Feedback',
    'Ref: Event site different title': 'Questo evento non appartiene al tuo sito attuale',
    'Ref: Event site different description':
      "Stai visualizzando un evento che non appartiene al tuo sito attuale. Non sarai in grado di vederlo nell'elenco degli eventi.",
    'Ref: See all dates': 'Vedi tutte le date',
    'Ref: From': 'Da',
    'Ref: to': 'A',
    'Ref: Coming date': 'Data in arrivo',
    'Ref: Add to calendar': 'Aggiungi al calendario',
  },
  EventsList: {
    'Ref: Page title': 'eventi',
    'All dates': 'Tutte le date',
    'All events': 'Tutti gli eventi',
    'Ref: Search': 'Ricerca',
    'My selection': 'Il mio preferito',
    'filter label: date': 'Data',
    'filter label: coming events': 'eventi in arrivo',
    'filter label: past events': 'eventi passati',
    'filter label: interest': 'preferenza',
  },
  Widget: {
    'Ref: Carousel title': 'eventi',
    'No info yet, come back later': 'Ancora nessuna informazione, torna più tardi 😉',
  },
};
export default labels;
