import classNames from 'classnames';

import { DismissButtonProps } from './DimissButton.types';

import { CrossIcon } from '@/assets/icons';

import styles from './DismissButton.module.css';

const DismissButton = ({
  children,
  collapsed,
  hideNotification,
  label,
  onDismiss,
  dismissButtonRef,
  'data-testid': testId,
}: DismissButtonProps) => {
  const handleDismiss = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (collapsed) return;
    hideNotification?.();
    onDismiss?.(e);
  };
  return (
    <div className={classNames(styles.dismissWrapper)}>
      <div className={classNames(styles.dismissContainer)}>
        {children}
        <button
          data-testid={testId}
          aria-label={label('close')}
          onClick={handleDismiss}
          className={classNames(styles.dismiss)}
          ref={dismissButtonRef}
          data-cy="notification-dismiss"
        >
          <CrossIcon />
        </button>
      </div>
    </div>
  );
};
export default DismissButton;
