import { IonButton } from '@ionic/react';
import classNames from 'classnames';
import React, { useEffect, useId } from 'react';

import Loader from '../../../components/atoms/Loader/Loader';
import { SIZE } from '../../../constants';

import { BADGE_PLACEMENT, BADGE_POSITION, ButtonProps, BUTTON_LOOK } from './Button.types';

import useAnalytics from '@/helpers/hooks/Analytics/useAnalytics';

import styles from './Button.module.css';

const BUTTON_DEFAULT_SIZE = SIZE.MEDIUM;

const Button = ({
  look = BUTTON_LOOK.PRIMARY,
  children,
  affix,
  suffix,
  badge,
  badgePosition = BADGE_POSITION.LEFT,
  badgePlacement = BADGE_PLACEMENT.OUTER,
  loading = false,
  size = BUTTON_DEFAULT_SIZE,
  contentCenterAlign = false,
  disabled,
  isClickDisabled = false,
  alignTop,
  isMultiline = true,
  isColumn = false,
  srOnlyText,
  // TODO merge Inherit style and className into one
  inheritStyle,
  className,
  fullWidth = false,
  'data-testid': testId,
  onClick,
  active,
  id,
  analyticsKey,
  ...rest
}: ButtonProps) => {
  const { trackButtonClickEvent } = useAnalytics();
  const iconLook = `icon_${look}`;
  const renderElement = (
    prop:
      | string
      | React.ComponentType<{
          className?: string;
        }>
  ) => {
    if (typeof prop === 'string') {
      return prop;
    } else {
      const Icon = prop;
      return <Icon className={classNames(styles.icon, styles[iconLook])} />;
    }
  };

  const onBtnClick = (event: React.MouseEvent<HTMLIonButtonElement>) => {
    if (disabled && !isClickDisabled) return;
    trackButtonClickEvent(id ?? '', { 'analytics-key': analyticsKey ?? '', 'test-id': testId });
    if (onClick) onClick(event);
  };

  const buttonId = useId();

  // We need to change button aria-disabled manually in shadow dom because ionic is not reflecting these changes
  useEffect(() => {
    document
      .querySelector('#' + CSS.escape(id ?? buttonId))
      ?.shadowRoot?.querySelector('button')
      ?.setAttribute('aria-disabled', disabled ? 'true' : 'false');
    if (rest['aria-label']) {
      document
        .querySelector('#' + CSS.escape(id ?? buttonId))
        ?.shadowRoot?.querySelector('button')
        ?.setAttribute('aria-label', rest['aria-label']);
    }
  }, [disabled, buttonId, id, rest]);
  return (
    <div
      className={classNames({
        [styles.wrapper]: true,
        button: true,
        [styles.fullWidth]: !isMultiline || fullWidth,
        [styles.buttonInline]: !!badge,
      })}
      data-testid={`${testId}-wrapper`}
    >
      <IonButton
        id={id ?? buttonId}
        aria-disabled={disabled}
        color={loading ? 'medium' : undefined}
        fill={loading ? 'solid' : undefined}
        className={classNames('bodySBold', styles[look], inheritStyle, className, {
          [styles.loadingButton]: loading,
          [styles.disabled]: !!disabled,
          [styles[`${size}Button`]]: size !== BUTTON_DEFAULT_SIZE,
          [styles.fullWidth]: !isMultiline || fullWidth,
          [styles.active]: active,
        })}
        data-testid={`${testId}`}
        onClick={onBtnClick}
        placeholder=""
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        {...rest}
      >
        {loading ? (
          <Loader data-testid={testId} />
        ) : (
          <span
            className={classNames(
              styles.elements,
              {
                [styles.spaced]: !contentCenterAlign && (affix || suffix),
                [styles.centered]:
                  contentCenterAlign || (affix === undefined && suffix === undefined),
                [styles.alignTop]: alignTop,
                [styles.textMultiline]: isMultiline,
                [styles.column]: isColumn,
              },
              'buttonContent'
            )}
            data-testid={`${testId}-content`}
          >
            {affix && (
              <span className={styles.affix}>
                {renderElement(affix)}

                {badge && badgePlacement === BADGE_PLACEMENT.INNER && (
                  <p
                    className={classNames({
                      [styles.badge]: true,
                      [styles.badgeInner]: true,
                      [styles.badgePrimary]: look === BUTTON_LOOK.PRIMARY,
                      [styles.badgeRight]: badgePosition === BADGE_POSITION.RIGHT,
                    })}
                    data-testid={`${testId}-badge-inner`}
                  >
                    {badge}
                  </p>
                )}
              </span>
            )}
            {children && (
              <span
                className={classNames({
                  [styles.textEllipsis]: !isMultiline,
                })}
                data-testid={`${testId}-children`}
              >
                {children}
              </span>
            )}
            {suffix && <span className={styles.suffix}>{renderElement(suffix)}</span>}
          </span>
        )}
        {srOnlyText && <span className="sr-only">{srOnlyText}</span>}
      </IonButton>
      {badge && badgePlacement === BADGE_PLACEMENT.OUTER && (
        <p
          className={classNames({
            [styles.badge]: true,
            [styles.badgePrimary]: look === BUTTON_LOOK.PRIMARY,
            [styles.badgeRight]: badgePosition === BADGE_POSITION.RIGHT,
          })}
          data-testid={`${testId}-outer-badge`}
        >
          {badge}
        </p>
      )}
    </div>
  );
};
Button.displayName = 'Button';

export default Button;
