const labels = {
  StaysList: {
    'Ref: Page title': '滞在',
    'Ref: Past': '過去',
    'Current and future stays': '現在の宿泊予定',
    'filter label: period': '期間',
    'Ref: Search': '探す',
  },
  StayDetails: {
    'Ref: Page title': '詳細',
    'Arrival date': '到着日 ',
    'Departure date': '出発日 ',
    'Room number': '部屋',
    'Reservation number': '予約番号 ',
    CheckIn: 'チェックイン',
    CheckOut: 'チェックアウト',
    CheckIn_1: '{lodgeName}にチェックインしますか？ （予約番号{resNumber}）',
    CheckedIn_1: 'あなたの部屋は{lodgeName}の{roomNumber}です。',
    CheckOut_1: 'チェックアウトすると{lodgeName}：{resNumber}の鍵が無効になります。',
    CheckedOut_1: '{lodgeName}：{resNumber}をチェックアウトしました。',
  },
  StaysSuccess: {
    'Ref: Title': '滞在していただきありがとうございます！',
    'Ref: Body': 'チェックアウトは正常に処理されました。',
    'Ref: Go to home': '家に行く',
    'Ref: Provide a feedback': 'フィードバックを提供します',
  },
};
export default labels;
