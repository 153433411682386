const labels = {
  ContactForm: { 'Ref: Page title': 'Contate-Nos', 'Ref: Category field value': 'Contate-Nos' },
  ContactList: {
    'Ref: Page title': 'Contatos',
    'call (action to place a call)': 'ligar',
    'write (action to write a message)': 'escrever',
    'write (action to write an e-mail)': 'o email',
    'Ref: Hint message':
      'Esteja ciente de que alguns sites podem não ter recepção. Se for esse for o caso, localize um dos telefones fixos disponíveis no site.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Ocorreu um erro e sua mensagem não foi enviada. Se este erro persistir, entre em contato com seu central de ajuda.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Sua mensagem foi enviada. Obrigado por entrar em contato conosco.',
  },
};
export default labels;
