import classNames from 'classnames';
import { useHistory } from 'react-router';

import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import ErrorPage from '../../../../components/templates/ErrorPage';
import { pagePaths } from '../../config';

import { UnsupportedQuestionTypeProps } from './UnsupportedQuestionType.types';

import styles from './UnsupportedQuestionType.module.css';

const UnsupportedQuestionType = ({ title, label }: UnsupportedQuestionTypeProps) => {
  const history = useHistory();

  return (
    <ErrorPage title={title}>
      <h1 className={classNames(styles.title)} data-testid="unsupported-question-type-title">
        {label('Ref: Not found title')}
      </h1>
      <Title size={TITLE_SIZE.HEADLINEXS}>{label('Ref: Unsupported elements')}</Title>
      <ActionsBar className={styles.actionButton}>
        <Button data-testid="audit-unsupported-question-type-seconday-action" look={BUTTON_LOOK.SECONDARY} onClick={() => history.push(pagePaths.Module)}>
          {label('Ref: Back to Audit list')}
        </Button>
      </ActionsBar>
    </ErrorPage>
  );
};

export default UnsupportedQuestionType;
