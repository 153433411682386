const labels = {
  SiteDetails: {
    'Select site': 'Selecione este site',
    'Ref: Page title': 'Detalhes do site',
    'Ref: Skip map': 'Ir para o conteúdo principal',
    'Ref: Unavailable site': 'Visualização do site inesável para o seu usuário',
    'Ref: Sorry, no description yet': 'Desculpe, ainda não temos descrição.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Coordenadas do site não encontradas',
    'Ref: Page title': 'Sites',
    'Recently visited': 'Visitou recentemente',
    'All other sites': 'Todos os outros sites.',
    'Sites in your country': 'Sites em seu país',
    'Search results': 'Procurar Resultados',
    'Select site': 'Selecione um site',
    'Ref: Select site with QR Scanner': 'Selecione Site com QR Scanner',
    'Ref: Select site with code': 'Selecione Site com código',
    'Ref: Validate': 'Validar',
    'Ref: Enter site code': 'Digite o código do site',
    'Ref: Scanner error header': 'Site não encontrado',
    'Ref: Generic error': 'Algo deu errado',
    'Ref: Change site': 'Alterar o site',
    'Ref: Change site description':
      'Existem itens no carrinho! Alterar o site resultará na limpeza do carrinho.',
    'Ref: Cancel': 'Cancelar',
    'Ref: Not found': 'Este código QR não foi reconhecido',
    'Ref: Unauthorized error': 'Solicitação não autorizada devido ao nível de restrição do local',
    'Ref: Forbidden':
      'Desculpe, você não tem as permissões certas para acessar este site. Para organizar o acesso, entre em contato com o Pilbara Service Center em 1800 992 777',
    "Ref: We didn't find the site.": 'Não encontramos o site.',
    'Ref: We recommend some solutions:': 'Recomendamos algumas soluções:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Tente adicionar seu site com o scanner QR ou com a ajuda de botões abaixo',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Faça logon e faça o login selecionando o país correto',
    'Ref: The currently selected country is:': 'O país atualmente selecionado é:',
  },
  SwitchSite: { 'Not on {site name}?': 'Não no {site name}?' },
  SiteTile: { Details: 'Detalhes', Select: 'Selecione.' },
};
export default labels;
