const labels = {
  All: {
    'starts at': 'begint om',
    'ends at': 'eindigt om',
    location: 'Locatie',
    'coming date': 'aankomsttijd',
    'Ref: Currently event not preferred. Click to make preferred':
      'Momenteel evenement niet de voorkeur. Klik om de voorkeur te maken',
    'Ref: Currently event preferred. Click to make not preferred':
      'Momenteel voorkeur evenement. Klik om niet de voorkeur te maken',
  },
  EventDetails: {
    details: 'Details',
    'Ref: Page title': 'Evenementdetails',
    'Event dates': 'Evenement datums',
    'See online:': 'Zie online:',
    'See all dates': 'Zie alle datums',
    'Remove from my selection': 'Verwijderen uit mijn selectie',
    'Add to my selection': 'Voeg toe aan mijn voorkeuren',
    'Ref: explain calendar usage':
      'Evenementendata worden gemarkeerd in de onderstaande kalender. Mis de evenementen die je leuk vindt niet! Klik op een evenement in de kalender om een ​​uitnodiging te downloaden. Open vervolgens het gedownloade bestand om het evenement toe te voegen aan de gewenste kalender.',
    'Ref: Favorite': 'Favoriet',
    'Ref:Feedback': 'Feedback',
    'Ref: Event site different title': 'Dit evenement behoort niet tot uw huidige site',
    'Ref: Event site different description':
      'U bekijkt een evenement dat niet tot uw huidige site behoort. U kunt het niet zien in de lijst met evenementen.',
    'Ref: See all dates': 'Zie alle datums',
    'Ref: From': 'Van',
    'Ref: to': 'tot',
    'Ref: Coming date': 'Komende datum',
    'Ref: Add to calendar': 'Toevoegen aan kalender',
  },
  EventsList: {
    'Ref: Page title': 'Evenementen',
    'All dates': 'Alle datums',
    'All events': 'Alle evenementen',
    'Ref: Search': 'Zoekopdracht',
    'My selection': 'Mijn voorkeur',
    'filter label: date': 'Datum',
    'filter label: coming events': 'Komende evenementen',
    'filter label: past events': 'Voorbije verlenden',
    'filter label: interest': 'voorkeur',
  },
  Widget: {
    'Ref: Carousel title': 'Evenementen',
    'No info yet, come back later': 'Nog geen info, kom later terug 😉',
  },
};
export default labels;
