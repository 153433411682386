const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Żądanie usługi - pomocnik',
    'Ref: Forbidden heading': 'Zabroniony',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Administrujesz żądania serwisowe na stronie {siteName}.',
    'Ref: Dates': 'Daktyle',
    'Ref: SRL list refresh': 'Odśwież listę',
    'Ref: Export': 'eksport',
    'Ref: Empty/not filled': 'Puste / nie wypełnione',
    'Ref: Name': 'Nazwa',
    'Ref: Date': 'Data',
    'Ref: Category': 'Kategoria',
    'Ref: User': 'Użytkownik',
    'Ref: Location': 'Lokalizacja',
  },
};
export default labels;
