import { CameraDevice } from 'html5-qrcode/camera/core';

export const selectPreferedCamera = (availableCameras: CameraDevice[]) => {
  if (availableCameras.length === 1) return availableCameras[0];

  // when more than one camera, select the back camera
  let backCamera: CameraDevice | undefined;

  backCamera = availableCameras.find((x) => x.label?.toLocaleLowerCase() === 'back camera');
  if (backCamera) return backCamera;

  backCamera = availableCameras.find((x) => x.label?.toLocaleLowerCase().includes('facing back'));
  if (backCamera) return backCamera;

  backCamera = availableCameras.find((x) => x.label?.toLocaleLowerCase().includes('back'));
  if (backCamera) return backCamera;

  return availableCameras.length > 0 ? availableCameras[0] : undefined;
};
