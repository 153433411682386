import { cleanup, fireEvent, screen } from '@testing-library/react';
import { act } from 'react';

import renderComponent from '../../../../helpers/tests/renderComponent';

import WelcomePage from './WelcomePage';

import { ISite } from '@/modules/Sites/types/sites.types';

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => ({ id: '1', name: 'Test Site' } as ISite),
}));

const mockDispatch = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockDispatch,
}));

const mockData = { isLoading: false };
const mockUseUpdateUserContextMutation = jest.fn();

jest.mock('../../api/account/userContextApi', () => ({
  useUpdateUserContextMutation: () => [mockUseUpdateUserContextMutation, mockData],
}));

jest.mock('../../../../helpers/misc', () => ({
  ...jest.requireActual('../../../../helpers/misc'),
  getCurrentLanguageCode: jest.fn(() => 'en-GB'),
}));

describe('WelcomePage', () => {
  renderComponent(WelcomePage);

  afterAll(() => cleanup());
  it('renders without crashing', () => {
    const component = screen.getByTestId('default-page');
    expect(component).toBeInTheDocument();
  });
  it('displays the correct page title', () => {
    const title = screen.getByText('Ref: Welcome to');
    expect(title).toBeInTheDocument();
  });

  it('displays the language tile list', () => {
    const languageTileList = screen.getByTestId('language-tile-list');
    expect(languageTileList).toBeInTheDocument();
  });

  it('redirects to the orders module page when "Begin" button is clicked', async () => {
    await act(() => fireEvent.click(screen.getByText('Ref: Begin')));

    expect(mockUseUpdateUserContextMutation).toHaveBeenCalled();
    expect(mockDispatch).toHaveBeenCalledTimes(1);
    expect(window.location.pathname).toEqual('/order');
  });
});
