const labels = {
  All: {
    'status: all': 'Tutti',
    'status: open': 'Aperto',
    'status: pending': 'in attesa di',
    'status: closed': 'Chiuso',
    Location: 'Posizione',
    Subject: 'Soggetto',
    Description: 'La tua richiesta dettagliata',
    'Ref: description field label': 'Descrizione',
    'Ref: Title': 'Titolo',
    phone_number: 'Il tuo numero di telefono',
    request_category: 'richiesta categoria',
    Attachments: 'allegati',
    'request service': 'Richiedi servizio',
    'New service request': "Crea un'altra richiesta",
    'Go to home': "torna all'inizio",
    'Back to this service request': 'Torna a questa richiesta di servizio',
    'Back to service requests': 'Torna alle richieste di servizio',
    Feedback: 'Risposta',
    'Ref: Request category': 'Categoria',
    Cleaning: 'Pulizia',
    'Laundry services': 'Servizi di lavanderia',
    'Grounds maintenance': 'Manutenzione dei motivi',
    'Soft services': 'Servizi soft',
    'Hard services': 'Servizi duri',
    'Pest control services': 'Servizi di controllo dei parassiti',
    'Waste management': 'Gestione dei rifiuti',
    'Asset Lifecycle Services': 'Asset Lifecycle Services.',
    'There are no updates for this request': 'Non ci sono aggiornamenti per questa richiesta',
    'Transportation Services': 'Servizio di trasporto',
    'Ref: you pronoun': '(lei)',
    'Ref: SR raised for': 'Persona interessata da questa richiesta',
    'Ref: Select a location': 'Seleziona una posizione',
    'Ref: Your request': 'La tua richiesta',
    'Ref: Summary': 'Riepilogo',
    'Ref: site': 'Luogo',
  },
  Comment: {
    'Ref: Page title': 'aggiornaci',
    'Ref: please update us with details':
      'Maggiori informazioni da comunicarci? Un commento? Quanto più sappiamo circa la tua richiesta, tanto meglio  possiamo supportarti.',
  },
  Confirm: {
    'Ref: Page title': 'Conferma la tua richiesta',
    'Ref: next': 'Invia',
    summary: 'Sommario',
    'Ref: Save as my preferred location': 'Salva come la mia posizione preferita',
  },
  Describe: {
    'Ref: Page title': 'Descrivi la tua richiesta',
    'Ref: Required fields: header': 'Si prega di fornire ulteriori informazioni',
    'Ref: Required fields':
      "Le seguenti informazioni dovrebbero essere fornite all'interno del campo Dettagli:",
    'Ref: Required field: title': 'È richiesto un titolo.',
    'Ref: Required field: description': 'è necessaria una descrizione.',
    'Ref: Required field: phone': 'È richiesto un numero di telefono.',
    'Ref: Required field: category': 'È richiesta una categoria.',
    'Ref: Invalid field: category': 'La categoria fornita non è valida.',
    'Ref: Request template field label': 'Richieste popolari',
    'Ref: Request template field placeholder': 'Foglio bianco',
    your_request: 'La tua richiesta',
    'Ref: Description message': 'Più sei dettagliato, meglio possiamo supportarti.',
    phone_number: 'Il tuo numero di telefono (usa il tuo telefono professionale se ne hai uno)',
    'Ref: Affected person': 'Persona interessata da questa richiesta',
    'Ref: No affected person results': 'Nessuna persona corrisponde ai criteri richiesti',
    'Ref: Notification title': 'Hai cambiato posizione',
    'Ref: Notification info': 'Ora sei al {site name}',
    'Ref: Add photos': 'Aggiungi foto',
    'Ref: Your location': 'La tua posizione',
  },
  Details: {
    'Ref: Page title': 'Dettagli della richiesta',
    Details: 'Dettagli',
    Updates: 'Aggiornamenti',
    'Update us': 'aggiornarci',
    'Ref: timeline': 'Sequenza temporale',
    'Ref: attachments': 'Allegati',
    'Ref: location': 'Posizione',
    'Ref: createdOn': 'Creato',
    'Ref: reference': 'Riferimento',
    'Feedback was submitted': 'I commenti inviati',
    'Ref: sent': 'Inviato',
    'Ref: received': 'Ricevuto',
    'Ref: inProgress': 'In corso',
    'Ref: completed': 'Completato',
    'Ref: conversation': 'Conversazione',
    'Ref: Priority': 'Priorità',
    'Ref: Target start date': 'Data di inizio target',
    'Ref: Target completion date': "Data di completamento dell'obiettivo",
    'Ref: Priority fields placeholder message':
      'Sarà popolato una volta convalidato da sportello di assistenza',
  },
  Failed: {
    'Ref: Body':
      'Si è verificato un errore e la richiesta di assistenza non è stata creata. Se questo errore persiste, contattare la tua assistenza.',
    'Ref: Tabtitle': 'La richiesta di servizio non è stata creata',
  },
  Home: { 'Ref: Page title': 'Richieste' },
  RequestFeedback: {
    'Ref: Page title': 'Risposta',
    'Ref: Feedback headline': 'Si prega di condividere i tuoi feedback',
    'Ref: Feedback title': 'Feedback su SR # {Ref}',
    'Ref: Question: general sentiment':
      'Nel complesso, quanto sei soddisfatto di come ha gestito il tuo Sodexo richiesta di servizio?',
    'Ref: Question: answer quality': 'Come giudica la qualità del servizio che hai ricevuto?',
    'Ref: Question: staff interaction': 'Come giudichi le interazioni con il nostro staff?',
    'Ref: Question: efficiency': 'Come giudichi la nostra efficienza?',
    'Ref: Question: open': 'Commento',
    'Ref: Open question placeholder': 'Cosa abbiamo fatto bene? Cosa possiamo migliorare?',
    no_comment: 'Nessun Commento',
  },
  FeedbackSuccess: {
    'Ref: Body':
      'Grazie per aver dedicato del tempo lasciando un commento, le tue risposte sono importanti per noi per aumentare la tua soddisfazione.',
  },
  Locate: {
    'Ref: Page title': 'Individuare la richiesta',
    'Ref: Top bar title': 'Individua la tua richiesta',
    'Ref: Select this location': 'Seleziona questa posizione',
    drill: 'navigare',
    'Ref: Scanner button': 'Posizione con un QR? Scansione',
    'Ref: Scanner header': 'Si prega di eseguire la scansione della posizione QR',
    'Sorry, this location could not be found.':
      'Siamo spiacenti, questa posizione non è stata trovata.',
    'Close scanner': 'Chiudere lo scanner',
    'Ref: Switch site component intro sentence':
      'Stai richiedendo il servizio sul sito {site name}.',
    'Ref: search location by keywords': 'Cerca una posizione',
    'Ref: Enter Location name': 'Inserisci il nome della posizione',
    'Ref: Enter manual location': 'Inserisci la posizione manualmente',
    'Ref: Location name': 'Nome del luogo',
    'Ref: No results':
      'Non siamo riusciti a trovare la tua posizione. Potresti voler inserirlo manualmente.',
    'Ref: Up one level': 'Su di un livello',
    'Ref: Navigate': 'Navigare',
    'Ref: Select': 'Selezionare',
    'Ref: Selected Location': 'Posizione selezionata',
    'Ref: Preferred Location': 'Posizione preferita',
    'Ref: Building': 'Costruzione',
    'Ref: Floor': 'Pavimento',
    'Ref: Aisle': 'Corridoio',
    'Ref: Room': 'Camera',
    'Ref: Notification title': 'Hai cambiato posizione',
    'Ref: Notification info': 'Ora sei al {site name}',
    'Ref: Scanner error header': 'Errore di scansione',
    'Ref: Scan QR Code': 'Scansiona il codice QR',
    'Ref: Error body': 'Questo codice QR non è un codice valido',
    'Ref: Loading Locations Warning':
      'Aspetta mentre stiamo preparando la tua esperienza per una prima richiesta su questo sito.',
    'Ref: Search Result is limited to:':
      'Il risultato della ricerca è limitato a {limit}. Si prega di perfezionare i criteri di ricerca.',
    'Ref: Open the location search field': 'Apri il campo di ricerca della posizione',
  },
  Success: {
    'Ref: Body':
      'La tua richiesta di assistenza è stata inviata e viene elaborata. Mostrerà in questa applicazione a breve. Riceverai una nota quando è risolta. Nel frattempo, puoi seguire il suo stato e aggiungere commenti attraverso questa applicazione.',
    'Ref: Tabtitle': 'Richiesta di servizio creata',
    'Ref: Download app title': 'Semplifica le tue richieste di servizio',
    'Ref: Download app subtitle': 'Scarica la nostra app e migliora la tua esperienza',
    'Ref: Download app button': "Scarica l'applicazione",
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Questo è il tuo hub di supporto sul posto di lavoro',
    'Ref: Tutorial: tabname': 'Richieste',
    'Ref: Tutorial: body': 'Siamo qui per aiutarvi. Registra una richiesta per voce.',
    "What's new: title": 'Trova la tua posizione facilmente',
    "What's new: body":
      'Ora è possibile eseguire la scansione di una posizione QR, perforare i livelli di localizzazione o semplicemente selezionare dai nostri suggerimenti intelligenti.',
  },
  Widget: {
    'Ref: Service request': 'Richiesta servizio',
    'Ref: Placeholder body': 'Nessuna richiesta di servizio per il momento',
    'Ref: New request button': 'Nuova richiesta',
  },
  QrLandingPage: {
    'Ref: Notification title': 'Hai cambiato posizione',
    'Ref: Notification info': 'Ora sei in {site name}',
    'Ref: Welcome': 'Benvenuto! {onda_emoji}',
    'Ref: Create service request text': 'Hai notato qualcosa che vuoi segnalare?',
    'Create a service request': 'Crea una richiesta di servizio',
    'Ref: Share feedback': 'Condividi un feedback',
    'Ref: Share text': 'Vorresti condividere qualcosa con noi?',
    'Go to home': "torna all'inizio",
    'Ref: Error body':
      "Il codice QR che hai scansionato non funziona più. Riprova più tardi o contatta l'assistenza.",
  },
  QrLandingPageError: {
    'Ref: Error title': "C'è stato un errore",
    'Ref: Error body':
      "Il codice QR che hai scansionato non funziona più. Riprova più tardi o contatta l'assistenza.",
    'Go to home': "torna all'inizio",
  },
  ManualDescribe: {
    'Ref: Page title': 'Descrivi la tua richiesta',
    'Ref: Required fields: header': 'Si prega di fornire ulteriori informazioni',
    'Ref: Required fields':
      'Le seguenti informazioni dovrebbero essere fornite nel campo Dettagli:',
    'Ref: Required field: title': 'È richiesto un riepilogo.',
    'Ref: Required field: description': 'È richiesta una descrizione.',
    'Ref: Required field: category': 'È richiesta una categoria.',
    'Ref: Required field: buildingText': 'È richiesto un edificio.',
    'Ref: Invalid field: category': 'La categoria a condizione non è valida.',
    'Ref: Your request': 'La tua richiesta',
    'Ref: Description message': 'Più sei dettagliato, meglio possiamo supportarti.',
    'Ref: Do you have a location QR code': 'Hai un codice QR di posizione?',
    'Ref: Scan QR Code': 'Scansiona il codice QR',
    'Ref: Please select category': 'Seleziona la categoria',
    'Ref: Please select site': 'Seleziona il sito',
    'Ref: building': 'Costruzione',
    'Ref: room': 'Camera',
    'Ref: Scan error': 'Errore di scansione',
    'Ref: The QR code is not valid': 'Il codice QR non è valido',
    'Ref: Add location with QR Scanner': 'Aggiungi posizione con scanner QR',
    'Ref: Description': 'Descrizione',
    'Ref: Forbidden error':
      'Siamo spiacenti, questo codice QR non corrisponde alla tua posizione registrata.',
    'Ref: Invalid error': 'Siamo spiacenti, sembra che questo codice QR non sia più valido.',
  },
};
export default labels;
