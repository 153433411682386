import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import Input from '../../atoms/Input';

import { AlertComponentProps, AlertPortalProps, AlertProps, StateInput } from './Alert.types';
import { AlertButton } from './AlertButton';

import styles from './Alert.module.css';

const ALERT_PORTAL_ID = 'alert-portal';

const clickStopBubbling: React.MouseEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
};

const clickStopBubblingKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
};

const AlertComponent: FC<AlertComponentProps> = ({
  onDismiss,
  header,
  message,
  buttons = ['OK'],
  inputs = [],
  disableCloseOnClickAway = false,
  className,
  'data-testid': dataTestId = 'alert',
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [stateInput, setStateInput] = useState<StateInput>(
    inputs.reduce<StateInput>((acc, input) => {
      acc[input.name] = '';
      return acc;
    }, {})
  );

  const handleClickOutside = useCallback(
    (event: Event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        !disableCloseOnClickAway
      ) {
        onDismiss && onDismiss();
      }
    },
    [onDismiss, disableCloseOnClickAway]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const onInputChange = (name: string) => (val: string) => {
    setStateInput({ ...stateInput, [name]: val });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className={classNames(styles.container, className)}
      ref={wrapperRef}
      data-testid={dataTestId}
      onClick={clickStopBubbling}
      onKeyDown={clickStopBubblingKeyDown}
      role="alertdialog"
      aria-label="Alert Dialog"
    >
      <div className={styles.content}>
        {header ? <div className={styles.title}>{header}</div> : null}
        {message ? <div className={styles.message}>{message}</div> : null}
        {inputs ? (
          <div className={styles.inputs}>
            {inputs.map((input) => (
              <Input
                key={input.name}
                name={input.name}
                type={input.type}
                autocomplete={input.autocomplete}
                placeholder={input.placeholder ?? input.name}
                onInputChange={onInputChange(input.name)}
                value={stateInput[input.name]}
                data-testid={`${dataTestId}-${input.name}`}
                {...input.attributes}
              />
            ))}
          </div>
        ) : null}
      </div>
      <div className={styles.buttons}>
        <AlertButton
          button={buttons[0]}
          onDismiss={onDismiss}
          data-testid={dataTestId}
          stateInput={stateInput}
        />
        {buttons.length > 1 ? (
          <AlertButton
            button={buttons[1]}
            onDismiss={onDismiss}
            data-testid={dataTestId}
            stateInput={stateInput}
          />
        ) : null}
      </div>
    </div>
  );
};

export const AlertPortal: FC<AlertPortalProps> = ({ id = ALERT_PORTAL_ID }) => <div id={id} />;

export const Alert: FC<AlertProps> = ({ portalId = ALERT_PORTAL_ID, isOpen, ...props }) => {
  const portalElement = document.getElementById(portalId);

  return isOpen && portalElement
    ? createPortal(
        <div className={styles.backdrop}>
          <AlertComponent {...props} />
        </div>,
        portalElement
      )
    : null;
};
