import classNames from 'classnames';
import { FC } from 'react';

import { WidgetHeaderProps } from '../Widget.types';

import { BackgroundWidgetDefaultIllustration } from '@/assets/illustrations';

import styles from './Header.module.css';

const WidgetHeader: FC<WidgetHeaderProps> = ({
  children,
  className,
  illustration = <BackgroundWidgetDefaultIllustration />,
  'data-testid': dataTestId = 'widget-header',
}) => {
  return (
    <header className={classNames(styles.header, className)} data-testid={dataTestId}>
      <div className={styles.image} data-testid={`${dataTestId}-illustration`}>
        {illustration}
      </div>
      {children}
    </header>
  );
};
WidgetHeader.displayName = 'Widget.Header';

export default WidgetHeader;
