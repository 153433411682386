import Textarea from '../../../../../components/atoms/Textarea';

import { MultiLineTextProps } from './MultiLineText.types';

const MultiLineText = ({ id, value, ...props }: MultiLineTextProps) => {
  return (
    <Textarea
      {...props}
      name={id}
      value={value}
      data-testid={'audit-question-component-multi-line-text'}
    ></Textarea>
  );
};

export default MultiLineText;
