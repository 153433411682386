import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';

function useKeyboardInfo() {
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);

  useEffect(() => {
    if (isPlatform('capacitor')) {
      Keyboard.addListener('keyboardDidShow', (info) => {
        setKeyboardOpen(true);
      });

      Keyboard.addListener('keyboardDidHide', () => {
        setKeyboardOpen(false);
      });
    }
    return () => {
      const clearKeyboardListeners = async () => {
        Keyboard.removeAllListeners();
      };
      if (isPlatform('capacitor')) clearKeyboardListeners();
    };
  }, []);

  return isKeyboardOpen;
}

export default useKeyboardInfo;
