import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import isLoggedIn from '../../../helpers/isLoggedIn';
import { useGetAppUpdateQuery } from '../api/appUpdate/appUpdateApi';
import { AppUpdateRequest } from '../api/appUpdate/appUpdateApi.type';
import { AppUpdateFlags } from '../components/AppUpdatePage/AppUpdatePage.types';
import { pagePaths } from '../config';

type checkAppVersionProps = {
  site?: { id: string; name: string };
  contract?: { id: string; name: string };
};

const useCheckAppVersion = (props: checkAppVersionProps) => {
  const { contract, site } = props;
  const [siteId, setSiteId] = useState<string | undefined>();
  const [contractId, setContractId] = useState<string | undefined>();
  const [nativeAppVersion, setNativeAppVersion] = useState<string | null>();
  const appId = process.env.REACT_APP_MOBILE_PACKAGE_NAME ?? '';
  const isLoggedInUser = isLoggedIn();
  const history = useHistory();

  const getNativeAppInfo = useCallback(async () => {
    if (Capacitor.isNativePlatform()) {
      const appInfo = await CapacitorApp.getInfo();
      setNativeAppVersion(appInfo.version);
    }
  }, []);
  const requestParams: AppUpdateRequest = {
    appId:
      appId.lastIndexOf('.dev') > -1
        ? appId.substring(0, appId.lastIndexOf('.dev'))
        : appId,
    appVersion: nativeAppVersion ?? '',
    platform: Capacitor.getPlatform(),
  };
  if (contractId) requestParams.contractId = contractId;
  if (siteId) requestParams.siteId = siteId;

  const { data: appUpdateResponse } = useGetAppUpdateQuery(requestParams, {
    skip: !(!!nativeAppVersion && isLoggedInUser),
  });

  useEffect(() => {
    getNativeAppInfo();
    if (contract?.id) setContractId(contract.id);
    if (site?.id) setSiteId(site.id);
  }, [site, contract, getNativeAppInfo, isLoggedInUser]);

  useEffect(() => {
    if (
      appUpdateResponse?.type === AppUpdateFlags.FORCE ||
      appUpdateResponse?.type === AppUpdateFlags.SOFT
    ) {
      history.push(pagePaths.AppUpdate);
    }
  }, [appUpdateResponse, history]);
};

export default useCheckAppVersion;
