import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { REACTIONS } from '../../../types/reactions.types';
import contentApi, {
  useDeleteContentReactionMutation,
  useUpdateContentReactionMutation,
} from '../api';
import { IContent } from '../types';

const useToggleReaction = () => {
  const dispatch = useDispatch();

  const [submitContentReaction] = useUpdateContentReactionMutation();
  const [deleteContentReaction] = useDeleteContentReactionMutation();

  const toggleReaction = useCallback(
    async (reaction: REACTIONS, item: IContent, siteId: string, languageCode: string) => {
      const request =
        reaction === item.reactions?.me ? deleteContentReaction : submitContentReaction;

      const response = await request({
        id: item.id,
        reactionType: reaction,
        siteId,
        languageCode,
      });

      if ('data' in response) {
        dispatch(
          contentApi.util.updateQueryData('getHome', { languageCode, siteId }, (draft) => {
            return draft.map((content: IContent) =>
              content.id === item.id ? { ...content, reactions: response.data } : content
            );
          })
        );
        dispatch(
          contentApi.util.updateQueryData('getContent', { id: item.id, siteId }, (draft) => {
            return draft ? { ...draft, reactions: response.data } : draft;
          })
        );
      }
    },
    [deleteContentReaction, dispatch, submitContentReaction]
  );

  return [toggleReaction];
};

export default useToggleReaction;
