import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import { pagePaths } from '../../config';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';

import { CoffeeProgressIllustration } from '@/assets/illustrations';
import { useTutorials } from '@/components/hooks/useTutorials';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import NoticePage from '@/components/templates/NoticePage/NoticePage';
import { saveSRTutorial } from '@/modules/ServiceRequest/helpers/helpers';

export const whatsNewLastUpdate = new Date('2022-04-20T10:00:00Z');

const Tutorials = () => {
  const { label } = useServiceRequestTranslation(__filename);
  const location = useLocation();
  const history = useHistory();
  const tutorials = useMemo(
    () => [
      {
        icon: <CoffeeProgressIllustration height={344} width={344} />,
        title: label('Ref: Tutorial: title'),
        body: label('Ref: Tutorial: body'),
      },
    ],
    [label]
  );

  const redirectTo = new URLSearchParams(location.search).get('redirectTo') || pagePaths['Module'];

  const { index, actions } = useTutorials({
    content: tutorials,
    viewTutorials: () => saveSRTutorial({ lastDisplayed: new Date() }),
    redirectTo,
    history,
    label,
  });

  if (!tutorials[index]) {
    return <LoadingPage />;
  }

  return (
    <NoticePage
      img={tutorials[index].icon}
      title={tutorials[index].title}
      content={tutorials[index].body}
      actions={actions}
      tabTitle={label('Ref: Tutorial: tabname')}
    />
  );
};
export default Tutorials;
