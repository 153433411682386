import FacilityContact from '../../../components/ecosystems/Facility/FacilityContact/FacilityContact';
import FacilityDescription from '../../../components/ecosystems/Facility/FacilityDescription/FacilityDescription';
import OpeningHours from '../../../components/ecosystems/Facility/OpeningHours/OpeningHours';
import Modal from '../../../components/organisms/Modal/Modal';
import { FacilityDetailsProps } from '../types/facilityDetails.types';

const FacilityDetails = ({
  label,
  isFacilityModalOpen,
  onFacilityModalDismiss,
  facility,
}: FacilityDetailsProps) => {
  return (
    <Modal
      title={label('Ref: Facility details')}
      isOpen={isFacilityModalOpen}
      id="facility details"
      onDismiss={onFacilityModalDismiss}
      data-testid="facility-details-modal"
    >
      <>
        <OpeningHours facility={facility} label={label} />
        <FacilityContact facility={facility} label={label} />
        <FacilityDescription facility={facility} label={label} />
      </>
    </Modal>
  );
};

export default FacilityDetails;
