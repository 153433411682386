const labels = {
  All: {
    'status: all': '所有',
    'status: open': '待解決',
    'status: pending': '待定',
    'status: closed': '已解決',
    Location: '位置',
    Subject: '標題',
    Description: '您的詳細要求',
    'Ref: description field label': '描述',
    'Ref: Title': '標題',
    phone_number: '您的電話號碼',
    request_category: '請求類別',
    Attachments: '附件',
    'request service': '請求服務',
    'New service request': '創建另一個請求',
    'Go to home': '返回主頁',
    'Back to this service request': '返回此服務請求',
    'Back to service requests': '返回主頁',
    Feedback: '反饋',
    'Ref: Request category': '類別',
    Cleaning: '清潔',
    'Laundry services': '洗衣服務',
    'Grounds maintenance': '地面維護',
    'Soft services': '軟服務',
    'Hard services': '硬服務',
    'Pest control services': '蟲控服務',
    'Waste management': '廢物管理',
    'Asset Lifecycle Services': '資產生命週期服務',
    'There are no updates for this request': '此請求沒有更新',
    'Transportation Services': '運輸服務',
    'Ref: you pronoun': '（你）',
    'Ref: SR raised for': '受到影響的人',
    'Ref: Select a location': '選擇一個位置',
    'Ref: Your request': '您的申請',
    'Ref: Summary': '概括',
    'Ref: site': '地點',
  },
  Comment: {
    'Ref: Page title': '提交反饋',
    'Ref: please update us with details':
      '您是否還有其他的想法要與我們分享？我們對您需求的瞭解越多，就越能更好地為您提供支持。',
  },
  Confirm: {
    'Ref: Page title': '確認您的請求',
    'Ref: next': '提交',
    summary: '概要',
    'Ref: Save as my preferred location': '保存為我的首選位置',
  },
  Describe: {
    'Ref: Page title': '描述您的請求',
    'Ref: Required fields: header': '請提供更多信息',
    'Ref: Required fields': '應在詳細信息字段中提供以下信息：',
    'Ref: Required field: title': '需要一個標題。',
    'Ref: Required field: description': '請輸入描述。',
    'Ref: Required field: phone': '請輸入電話號碼。',
    'Ref: Required field: category': '請選擇分類。',
    'Ref: Invalid field: category': '提供的類別無效。',
    'Ref: Request template field label': '熱門要求',
    'Ref: Request template field placeholder': '空白',
    your_request: '您的申請',
    'Ref: Description message': '請提盡可能提供詳細的描述,以便我們為您能更好的提供服務。',
    phone_number: '您的連絡電話（如您有公司總機號碼，請盡可能提供）',
    'Ref: Affected person': '受到影響的人',
    'Ref: No affected person results': '沒有符合請求條件的人',
    'Ref: Notification title': '您已經更改了位置',
    'Ref: Notification info': '您現在位於 {site name}',
    'Ref: Add photos': '添加照片',
    'Ref: Your location': '你的地點',
  },
  Details: {
    'Ref: Page title': '請求詳細信息',
    Details: '詳情',
    Updates: '更新',
    'Update us': '告知我們',
    'Ref: timeline': '時間線',
    'Ref: attachments': '附件',
    'Ref: location': '地點',
    'Ref: createdOn': '創建於',
    'Ref: reference': '參考',
    'Feedback was submitted': '評價已提交',
    'Ref: sent': '發送',
    'Ref: received': '已收到',
    'Ref: inProgress': '進行中',
    'Ref: completed': '已完成',
    'Ref: conversation': '對話',
    'Ref: Priority': '優先事項',
    'Ref: Target start date': '目標開始日期',
    'Ref: Target completion date': '目標完成日期',
    'Ref: Priority fields placeholder message': '將在服務台處理後顯示',
  },
  Failed: {
    'Ref: Body': '發生錯誤，未能成功創建服務請求。如果此錯誤仍然存​​在，請聯繫您的服務台。',
    'Ref: Tabtitle': '服務請求無法創建',
  },
  Home: { 'Ref: Page title': '要求' },
  RequestFeedback: {
    'Ref: Page title': '備註',
    'Ref: Feedback headline': '請分享您的反饋',
    'Ref: Feedback title': '關於SR＃{REF}的反饋',
    'Ref: Question: general sentiment': '您如何評價我們的總體表現？',
    'Ref: Question: answer quality': '您如何評價對您的請求回應的品質？',
    'Ref: Question: staff interaction': '您如何評價與我們員工的互動？',
    'Ref: Question: efficiency': '您如何評價我們的執行速度？',
    'Ref: Question: open': '評論',
    'Ref: Open question placeholder': '有哪些表現好的地方？ 有什麼要改進的地方？',
    no_comment: '沒有意見',
  },
  FeedbackSuccess: {
    'Ref: Body': '感謝您抽出寶貴時間發表評論，您的回饋意見對於我們改善您的體驗至關重要。',
  },
  Locate: {
    'Ref: Page title': '定位您的請求',
    'Ref: Top bar title': '定位您的請求',
    'Ref: Select this location': '選擇此位置',
    drill: '瀏覽',
    'Ref: Scanner button': '位置上貼有二維碼？請掃描它',
    'Ref: Scanner header': '請掃描位置的二維碼',
    'Sorry, this location could not be found.': '對不起，找不到這個位置。',
    'Close scanner': '關閉掃描儀',
    'Ref: Switch site component intro sentence': '您在地點{site name}上請求服務。',
    'Ref: search location by keywords': '搜索位置',
    'Ref: Enter Location name': '輸入位置名稱',
    'Ref: Enter manual location': '手動輸入位置',
    'Ref: Location name': '地點名稱',
    'Ref: No results': '我們找不到您的位置。您可能想要手動輸入它。',
    'Ref: Up one level': '上一級',
    'Ref: Navigate': '導航',
    'Ref: Select': '選擇',
    'Ref: Selected Location': '選定位置',
    'Ref: Preferred Location': '首選地點',
    'Ref: Building': '建築',
    'Ref: Floor': '樓層',
    'Ref: Aisle': '走道',
    'Ref: Room': '房間',
    'Ref: Notification title': '您已經更改了位置',
    'Ref: Notification info': '您現在位於 {site name}',
    'Ref: Scanner error header': '掃描錯誤',
    'Ref: Scan QR Code': '掃描二維碼',
    'Ref: Error body': '此QR代碼不是有效的代碼',
    'Ref: Loading Locations Warning': '請稍後，我們正在為您在此站點的首次請求進行準備。',
    'Ref: Search Result is limited to:': '搜索結果僅限於{limit}。請完善您的搜索標準。',
    'Ref: Open the location search field': '打開位置搜索字段',
  },
  Success: {
    'Ref: Body':
      '您的服務請求已提交並正在處理。它將很快顯示在當前應用中。您將在問題解決時收到一個提醒。與此同時，您可以通過此應用查詢其狀態並向其添加備註。',
    'Ref: Tabtitle': '創建的服務請求',
    'Ref: Download app title': '簡化您的服務請求',
    'Ref: Download app subtitle': '下載我們的應用程式並提升您的體驗',
    'Ref: Download app button': '下載應用程式',
  },
  Tutorials: {
    'Ref: Tutorial: title': '這是您的工作場所支持中心',
    'Ref: Tutorial: tabname': '要求',
    'Ref: Tutorial: body': '請為不同的請求創建獨立的記錄，我們隨時為您提供幫助。',
    "What's new: title": '輕鬆找到您的位置',
    "What's new: body": '掃描二維碼以查找您的確切位置，或從我們的建議中進行選擇。',
  },
  Widget: {
    'Ref: Service request': '服務請求',
    'Ref: Placeholder body': '目前沒有服務請求',
    'Ref: New request button': '新要求',
  },
  QrLandingPage: {
    'Ref: Notification title': '您已經更改了位置',
    'Ref: Notification info': '您現在位於{site name}',
    'Ref: Welcome': '歡迎！ {波浪表情}',
    'Ref: Create service request text': '注意到您想要報告的事情了嗎？',
    'Create a service request': '創建服務請求',
    'Ref: Share feedback': '分享回饋',
    'Ref: Share text': '想與我們分享一些東西嗎？',
    'Go to home': '返回主頁',
    'Ref: Error body': '您掃描的二維碼不再有效。請稍後重試或聯絡支援人員。',
  },
  QrLandingPageError: {
    'Ref: Error title': '出錯了',
    'Ref: Error body': '您掃描的二維碼不再有效。請稍後重試或聯絡支援人員。',
    'Go to home': '返回主頁',
  },
  ManualDescribe: {
    'Ref: Page title': '描述您的要求',
    'Ref: Required fields: header': '請提供其他信息',
    'Ref: Required fields': '詳細信息字段中應提供以下信息：',
    'Ref: Required field: title': '需要摘要。',
    'Ref: Required field: description': '請輸入描述。',
    'Ref: Required field: category': '請選擇類別。',
    'Ref: Required field: buildingText': '請選擇建築物。',
    'Ref: Invalid field: category': '所選的類別無效。',
    'Ref: Your request': '你的申請',
    'Ref: Description message': '您提供的詳細信息將能幫助我們為您提供更好的支持。',
    'Ref: Do you have a location QR code': '您有位置QR碼嗎？',
    'Ref: Scan QR Code': '掃描二維碼',
    'Ref: Please select category': '請選擇類別',
    'Ref: Please select site': '請選擇地點',
    'Ref: building': '建築',
    'Ref: room': '房間',
    'Ref: Scan error': '掃描錯誤',
    'Ref: The QR code is not valid': 'QR碼無效',
    'Ref: Add location with QR Scanner': '使用QR掃描儀添加位置',
    'Ref: Description': '描述',
    'Ref: Forbidden error': '很抱歉，此QR碼與您的註冊位置不符。',
    'Ref: Invalid error': '很抱歉，看來此QR碼不再有效。',
  },
};
export default labels;
