import { ionFireEvent } from '@ionic/react-test-utils';
import { act, cleanup, fireEvent, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import { renderedComponent } from '../../../../../helpers/tests/renderComponent';
import AuditCreationForm from '../AuditCreationForm';

jest.setTimeout(30000);

jest.mock('react-select', () => ({ 'data-testid': dataTestId, options, value, onChange }: any) => {
  function handleChange(event: any) {
    const option = options.find((option: any) => option.value === event.currentTarget.value);
    onChange(option);
  }
  return (
    <select
      id={dataTestId}
      name={dataTestId}
      data-testid={dataTestId}
      value={value || ''}
      onChange={handleChange}
    >
      {options.map(({ label, value }: any) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
});

const locations = [
  {
    id: 'ccd68a51-4a9f-e611-80f4-c4346bc52710',
    name: 'Anchorage',
  },
  {
    id: '47754440-a7d2-e911-a812-000d3a6aa0b3',
    name: 'Angelo River',
  },
  {
    id: 'fd87220c-8829-e611-80ec-a45d36fc5a4c',
    name: 'Boolgeeda Aerodrome',
  },
];
const streams = [
  {
    id: '6ca91178-cf11-ec11-b6e6-002248113240',
    name: 'Accommodation',
  },
  {
    id: '43507d85-cf11-ec11-b6e6-002248113240',
    name: 'Aerodrome',
  },
  {
    id: '75c3038d-cf11-ec11-b6e6-002248113240',
    name: 'Building Maintenance',
  },
  {
    id: 'e283eccf-7a11-ec11-b6e6-0022481131f6',
    name: 'Catering',
  },
  {
    id: '186f56a6-cf11-ec11-b6e6-002248113240',
    name: 'Cleaning',
  },
  {
    id: '0a33ffd3-cf11-ec11-b6e6-002248113240',
    name: 'Grounds Maintenance',
  },
  {
    id: '7c85a406-d011-ec11-b6e6-002248113240',
    name: 'Property Management',
  },
  {
    id: '8ab2ba18-d011-ec11-b6e6-002248113240',
    name: 'Town Services',
  },
  {
    id: 'c2afe21e-d011-ec11-b6e6-002248113240',
    name: 'Transportation',
  },
];
const audits = [
  {
    id: 'f1c07d77-8fd3-ed11-a7c7-00224893bab8',
    name: 'L2 - Cleaning Jamie',
    auditStreamId: '186f56a6-cf11-ec11-b6e6-002248113240',
    auditStreamName: 'Cleaning',
    questions: [],
  },
  {
    id: 'bdf38a02-7911-ec11-b6e6-0022481131f6',
    name: 'L2-Accommodation Rooms/Central Facilities',
    auditStreamId: '75c3038d-cf11-ec11-b6e6-002248113240',
    auditStreamName: 'Building Maintenance',
    questions: [],
  },
];
const assets = [
  {
    id: '8ae5b344-6355-e611-80f4-a45d36fc5a4c',
    name: ' Rivergum Drive - Store room 2',
    masterAssetName: null,
    locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
    assetLevel: 'Level 1',
  },
  {
    id: '8ce5b344-6355-e611-80f4-a45d36fc5a4c',
    name: ' Rivergum Drive - Store room external ',
    masterAssetName: null,
    locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
    assetLevel: 'Level 1',
  },
  {
    id: '1a6135ce-6355-e611-80f4-a45d36fc5a4c',
    name: ' Supermarket Shop Complex PAN  Cool Room 3',
    masterAssetName: 'Supermarket Shop complex PAN',
    locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
    assetLevel: 'Level 2',
  },
  {
    id: '006135ce-6355-e611-80f4-a45d36fc5a4c',
    name: ' Supermarket shop Complex PAN  Cool Rooms 2',
    masterAssetName: 'Supermarket Shop complex PAN',
    locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
    assetLevel: 'Level 2',
  },
];

const mockCreateAuditMutation = jest.fn();
jest.mock('../../../api', () => ({
  useGetAuditLocationsQuery: () => ({
    data: locations,
    isFetching: false,
  }),
  useGetStreamsQuery: () => ({
    data: streams,
    isFetching: false,
  }),
  useGetAuditsQuery: () => ({
    data: audits,
    isFetching: false,
  }),
  useGetAssetsQuery: () => ({
    data: assets,
    isFetching: false,
  }),
  useCreateAuditMutation: () => [mockCreateAuditMutation, { isLoading: false, isSuccess: false }],
}));

const defaultProps = {
  label: (s: string) => s,
};

describe('AuditCreationForm', () => {
  afterAll(() => cleanup());
  let locationsSelect: HTMLElement;
  let streamsSelect: HTMLElement;
  let auditsSelect: HTMLElement;
  let assetsSelect: HTMLElement;
  let assetsNameTextarea: HTMLElement;
  let jointAuditCheckbox: HTMLElement;
  let jointAuditParticipantsTextarea: HTMLElement;

  describe('Render', () => {
    beforeEach(async () => {
      await act(async () => {
        renderedComponent(AuditCreationForm, defaultProps);
      });

      locationsSelect = screen.getByTestId('locations-select');
      streamsSelect = screen.getByTestId('streams-select');
      auditsSelect = screen.getByTestId('audits-select');
      assetsSelect = screen.getByTestId('assets-select');
      assetsNameTextarea = screen.getByTestId('asset-name-textarea');
      jointAuditCheckbox = screen.getByTestId('joint-audit-checkbox-input');
      jointAuditParticipantsTextarea = screen.getByTestId('joint-audit-participants-textarea');
    });

    it('Should have all form elements', () => {
      expect(locationsSelect).toBeTruthy();
      expect(streamsSelect).toBeTruthy();
      expect(auditsSelect).toBeTruthy();
      expect(assetsSelect).toBeTruthy();
      expect(assetsNameTextarea).toBeTruthy();
      expect(jointAuditCheckbox).toBeTruthy();
      expect(jointAuditParticipantsTextarea).toBeTruthy();
      expect(jointAuditParticipantsTextarea).toBeDisabled();
    });

    it('Should enable Joint Audit Participants input', async () => {
      await act(async () => {
        await userEvent.click(jointAuditCheckbox);
      });

      expect(jointAuditParticipantsTextarea).toBeTruthy();
      expect(jointAuditParticipantsTextarea.getAttribute('disabled')).toBe('false');
    });
  });

  describe('Submit', () => {
    beforeEach(async () => {
      await act(async () => {
        renderedComponent(AuditCreationForm, defaultProps);
      });

      locationsSelect = screen.getByTestId('locations-select');
      streamsSelect = screen.getByTestId('streams-select');
      auditsSelect = screen.getByTestId('audits-select');
      assetsSelect = screen.getByTestId('assets-select');
      assetsNameTextarea = screen.getByTestId('asset-name-textarea');
      jointAuditCheckbox = screen.getByTestId('joint-audit-checkbox-input');
      jointAuditParticipantsTextarea = screen.getByTestId('joint-audit-participants-textarea');
    });

    it('should show error notification when form is not correctly filled', async () => {
      const submitButton = screen.getByTestId('button-action-primary');
      await act(async () => {
        const longAssetName = 'a'.repeat(101);
        ionFireEvent.ionChange(assetsNameTextarea, longAssetName);
        fireEvent.submit(submitButton);
      });

      expect(screen.getByTestId('asset-name-textarea-message')).toBeInTheDocument();
    });

    it('should show error notification when assetName length is not correct', async () => {
      const submitButton = screen.getByTestId('button-action-primary');
      await act(async () => {
        fireEvent.submit(submitButton);
      });

      const errorNotification = screen.queryByTestId('error-notification-top-content');
      expect(errorNotification).toBeTruthy();
    });

    it('should show error notification when jointAuditParticipants is not filled', async () => {
      const submitButton = screen.getByTestId('button-action-primary');

      await act(async () => {
        await userEvent.selectOptions(locationsSelect, [locations[2].id]);
        await userEvent.selectOptions(streamsSelect, [streams[1].id]);
        await userEvent.selectOptions(auditsSelect, [audits[1].id]);
        await userEvent.click(jointAuditCheckbox);

        fireEvent.submit(submitButton);
      });

      const errorNotification = screen.queryByTestId('error-notification-top-content');
      expect(errorNotification).toBeTruthy();
    });

    it('should submit the form when only required select inputs are filled', async () => {
      const submitButton = screen.getByTestId('button-action-primary');

      await act(async () => {
        await userEvent.selectOptions(locationsSelect, [locations[2].id]);
        await userEvent.selectOptions(streamsSelect, [streams[1].id]);
        await userEvent.selectOptions(auditsSelect, [audits[1].id]);

        fireEvent.submit(submitButton);
      });

      const body = {
        locationId: 'fd87220c-8829-e611-80ec-a45d36fc5a4c',
        auditId: 'bdf38a02-7911-ec11-b6e6-0022481131f6',
        assetId: undefined,
        assetName: undefined,
        isJointAudit: false,
        jointAuditParticipants: undefined,
      };

      expect(mockCreateAuditMutation).toHaveBeenCalledWith(expect.objectContaining(body));
    });

    it('should submit the form with all fields filled', async () => {
      const submitButton = screen.getByTestId('button-action-primary');

      await act(async () => {
        await userEvent.selectOptions(locationsSelect, [locations[2].id]);
        await userEvent.selectOptions(streamsSelect, [streams[1].id]);
        await userEvent.selectOptions(auditsSelect, [audits[1].id]);
        await userEvent.selectOptions(assetsSelect, [assets[2].id]);
        ionFireEvent.ionChange(assetsNameTextarea, 'assetName');

        await userEvent.click(jointAuditCheckbox);
        ionFireEvent.ionChange(jointAuditParticipantsTextarea, 'Birkan, Claudio');

        fireEvent.submit(submitButton);
      });

      const body = {
        locationId: 'fd87220c-8829-e611-80ec-a45d36fc5a4c',
        auditId: 'bdf38a02-7911-ec11-b6e6-0022481131f6',
        assetId: '1a6135ce-6355-e611-80f4-a45d36fc5a4c',
        assetName: 'assetName',
        isJointAudit: true,
        jointAuditParticipants: 'Birkan, Claudio',
      };

      expect(mockCreateAuditMutation).toHaveBeenCalledWith(expect.objectContaining(body));
    });
  });
});
