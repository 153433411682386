import { ContentIcon, SurveyIcon } from '../../../assets';
import { EventScheduleIcon, Tool } from '../../../assets/icons';
import { pagePaths as pagePathContent } from '../../../modules/Content/config';
import { Service } from '../../../modules/Core/types/State.types';
import { pagePaths as pagePathEvents } from '../../../modules/Events/config';
import { pagePaths as pagePathSurveys } from '../../../modules/Surveys/config';

export const getServiceIcon = (service: Service | { name: String }) => {
  if (service?.name) {
    switch (service?.name) {
      case 'Content':
        return <ContentIcon />;
      case 'Event':
        return <EventScheduleIcon height={20} />;
      case 'Survey':
        return <SurveyIcon />;
      case 'ServiceRequests':
        return <Tool height={20} />;
      default:
    }
  }
};

export const getUrl = (
  service: Service | { name: String },
  eventId: string | undefined,
  surveyId: string | undefined,
  contentId: string | undefined
) => {
  let link: string = '';
  if (service?.name) {
    switch (service?.name) {
      case 'Content':
        link = contentId ? pagePathContent['ContentDetails'].replace(':id', contentId) : '';
        break;
      case 'Event':
        link = eventId ? pagePathEvents['EventDetails'].replace(':id', eventId) : '';
        break;
      case 'Survey':
        link = surveyId ? pagePathSurveys['Survey'].replace(':id', surveyId) : '';
        break;
      default:
    }
  }
  return link;
};
