import classNames from 'classnames';
import { FC } from 'react';

import Option from './Option';
import { ReactionsOptionsProps } from './ReactionOptions.types';

import styles from './ReactionOptions.module.css';

const ReactionOptions: FC<ReactionsOptionsProps> = ({
  reactions,
  className,
  tooltipId,
  handleReactionClick,
  'data-testid': dataTestId = 'reaction-options',
}) => {
  return (
    <div className={classNames(styles.options, className)} data-testid={dataTestId} tabIndex={-1}>
      {reactions.map((reaction) => {
        return (
          <Option
            tooltipId={tooltipId}
            key={reaction.type}
            reaction={reaction}
            handleReactionClick={() => handleReactionClick(reaction.type)}
          />
        );
      })}
    </div>
  );
};

export default ReactionOptions;
