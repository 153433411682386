const labels = {
  SiteDetails: {
    'Select site': 'サイトを選択してください',
    'Ref: Page title': 'サイトの詳細',
    'Ref: Skip map': 'メインコンテンツにスキップします',
    'Ref: Unavailable site': 'サイトプレビューは、ユーザーに適用できません',
    'Ref: Sorry, no description yet': '申し訳ありませんが、まだ説明がありません。',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'サイトが見つかりません',
    'Ref: Page title': 'サイト',
    'Recently visited': '最近の訪問',
    'All other sites': '他のすべてのサイト',
    'Sites in your country': 'あなたの国のサイト',
    'Search results': '検索結果',
    'Select site': 'サイトを選ぶ',
    'Ref: Select site with QR Scanner': 'QRスキャナーを備えたサイトを選択します',
    'Ref: Select site with code': 'コード付きのサイトを選択します',
    'Ref: Validate': '検証',
    'Ref: Enter site code': 'サイトコードを入力します',
    'Ref: Scanner error header': 'サイトが見つかりません',
    'Ref: Generic error': '問題が発生しました',
    'Ref: Change site': 'サイトを変更します',
    'Ref: Change site description':
      'カートにアイテムがあります！サイトを変更すると、カートがクリアされます。',
    'Ref: Cancel': 'キャンセル',
    'Ref: Not found': 'このQRコードは認識できません',
    'Ref: Unauthorized error': 'サイト制限レベルによる不正な要求',
    'Ref: Forbidden':
      '申し訳ありませんが、このサイトにアクセスするための適切な許可がありません。アクセスを手配するには、1800 992 777のピラバラサービスセンターにお問い合わせください',
    "Ref: We didn't find the site.": 'サイトは見つかりませんでした。',
    'Ref: We recommend some solutions:': 'いくつかの解決策をお勧めします：',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. QRスキャナーまたは以下のボタンの助けを借りてサイトを追加してみてください',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2.正しい国を選択してログアウトしてログインします',
    'Ref: The currently selected country is:': '現在選択されている国は次のとおりです。',
  },
  SwitchSite: { 'Not on {site name}?': '{site name}はありませんか？' },
  SiteTile: { Details: '詳細', Select: '選択する' },
};
export default labels;
