import { CheckIcon, RefreshIcon } from '../../../../assets/icons';

import Status from './../../../../components/molecules/Status';
import { StayStatusProps, STAY_STATUS } from './StayStatus.types';

import styles from './StayStatus.module.css';

const statusStyling = {
  [STAY_STATUS.CHECKED_IN]: {
    icon: <RefreshIcon />,
    className: styles.status_icon_checkedin,
  },
  [STAY_STATUS.CHECKED_OUT]: {
    icon: <RefreshIcon />,
    className: styles.status_icon_checkedout,
  },
  [STAY_STATUS.CONFIRMED]: {
    icon: <CheckIcon />,
    className: styles.status_icon_confirmed,
  },
};

// TODO: status will be translated later
const StayStatus = ({ status }: StayStatusProps) => {
  if (!Object.values(STAY_STATUS).includes(status as STAY_STATUS)) {
    return null;
  }

  const { icon, className } = statusStyling[status];
  return (
    <Status
      icon={icon}
      status={status}
      inheritStyle={className}
      data-testid="accommodation-status"
      data-cy="accommodation-status"
    />
  );
};

export default StayStatus;
