import { ComponentMeta, ComponentStory } from '@storybook/react';

import ReduxStoreDecorator from '../../../../storybook/helpers/ReduxStoreDecorator';
import RouterDecorator from '../../../../storybook/helpers/RouterDecorator';
import { storyTitle } from '../../../../storybook/helpers/titleHelper';

import LocationListPage from './LocationListPage';
import { LOCATION_LIST_TYPE } from './LocationListPage.types';

const sites = [
  {
    id: '1',
    name: 'Site 1',
    locationtype: 'Site',
    geoCoordinates: { longitude: -59.1301414, latitude: 0 },
  },
  {
    id: '2',
    name: 'Site 2',
    locationtype: 'Site',
    geoCoordinates: { longitude: 51.6930989, latitude: -0.4935158 },
  },
];

export default {
  title: storyTitle('LocationListPage', 'templates'),
  component: LocationListPage,
  decorators: [ReduxStoreDecorator, RouterDecorator],
} as ComponentMeta<typeof LocationListPage>;

const Template: ComponentStory<typeof LocationListPage> = (args) => <LocationListPage {...args} />;

export const Default = Template.bind({});
Default.args = {
  title: 'Location List Page',
  label: (string) => string,
  withNavBar: false,
  sites,
  pagePaths: { SiteDetails: '/:id' },
};

export const MapView = Template.bind({});
MapView.args = {
  title: 'Location List Page',
  label: (string) => string,
  withNavBar: false,
  sites,
  isMapEnabled: true,
  defaultDisplayType: LOCATION_LIST_TYPE.MAP,
  pagePaths: { SiteDetails: '/:id' },
};

export const WithVisitedSite = Template.bind({});
WithVisitedSite.args = {
  title: 'Location List Page',
  label: (string) => string,
  withNavBar: false,
  sites,
  isMapEnabled: true,
  visited: [
    {
      id: '2',
      name: 'Site 2',
      locationtype: 'Site',
      geoCoordinates: { longitude: 51.6930989, latitude: -0.4935158 },
    },
  ],
  pagePaths: { SiteDetails: '/:id' },
};

export const SitesWithImages = Template.bind({});
SitesWithImages.args = {
  title: 'Location List Page',
  label: (string) => string,
  withNavBar: false,
  sites,
  isMapEnabled: true,
  pagePaths: { SiteDetails: '/:id' },
};
