import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { History } from 'history';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { IN_APP_BROWSER_FRAMELESS } from '../../../constants';
import { State } from '../../../types/state.types';
import { useSetUserRegionCodeMutation } from '../api/account/accountApi';
import { themeKeys } from '../helpers/helpers';

const useHandleUserRegionCode = (
  loginValue: string,
  currentGeoCode: string,
  currentRegionCode: string,
  setLoginError: Function,
  history: History
) => {
  const [setUserRegionCode] = useSetUserRegionCodeMutation();
  const theme = useSelector((state: State) => state.Core.context.theme?.settings);
  // TODO see src/index.js applyTheme for more info
  const defaultThemeSettings = JSON.parse(process.env.REACT_APP_DEFAULT_THEME_JSON || '[]');
  // Get theme color from Json, or fallback to original
  const fallbackPrimaryColor =
    defaultThemeSettings.find((el: { key: string; value: string }) =>
      themeKeys.primaryColor.includes(el.key)
    )?.value || '#283897';
  // Get theme color from context or fallback to json
  const primaryColor =
    theme?.find((el) => themeKeys.primaryColor.includes(el.key))?.value || fallbackPrimaryColor;

  const handleUserRegionCode = useCallback(
    async (gatesUrl: string, useSystemBrowser: boolean) => {
      const isMobile = Capacitor.isNativePlatform();
      const callbackUrl = `${process.env.REACT_APP_HOST}/access/idp?username=${loginValue}&geocode=${currentGeoCode}`;
      const relativeUrl = `/access/idp?username=${loginValue}&geocode=${currentGeoCode}`;
      const response = await setUserRegionCode({
        username: loginValue,
        regionCode: currentRegionCode || '',
        callbackUrl,
        isMobile,
      });

      if (!('error' in response) || !response.error) {
        const ssoUrl = `${gatesUrl}&username=${loginValue}`;

        if (useSystemBrowser) {
          await Browser.open({
            url: ssoUrl,
            windowName: '_self',
            toolbarColor: primaryColor,
            presentationStyle: 'popover',
          });

          return;
        }

        const browserTarget = isMobile ? '_blank' : '_self';
        const browserRef = InAppBrowser.create(ssoUrl, browserTarget, IN_APP_BROWSER_FRAMELESS);

        browserRef.on('loadstop')?.subscribe({
          next: (evt) => {
            if (evt.url.indexOf('token-idp') > -1) {
              browserRef.close();
              history.replace(relativeUrl);
            }
          },
        });
      } else {
        setLoginError(true);
      }
    },
    [
      loginValue,
      currentGeoCode,
      setUserRegionCode,
      currentRegionCode,
      primaryColor,
      history,
      setLoginError,
    ]
  );

  return handleUserRegionCode;
};

export default useHandleUserRegionCode;
