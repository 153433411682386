import React from 'react';

import { openUrl } from '../../../helpers/misc';

const handleAnchorClick =
  (to: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    openUrl(to);
  };

export { handleAnchorClick };
