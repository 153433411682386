import { Route, Switch } from 'react-router-dom';

import { pagePaths } from '../config';

import ContactForm from './ContactForm';
import ContactList from './ContactList';
import FormSubmissionFailure from './FormSubmissionFailure';
import FormSubmissionSuccess from './FormSubmissionSuccess';

const Routes = () => {
  return (
    <Switch>
      <Route path={pagePaths.FormSubmissionSuccess} children={<FormSubmissionSuccess />} />
      <Route path={pagePaths.FormSubmissionFailure} children={<FormSubmissionFailure />} />
      <Route path={pagePaths.ContactForm} children={<ContactForm />} />
      <Route path={pagePaths.Module} children={<ContactList />} />
    </Switch>
  );
};

export default Routes;
