const labels = {
  SiteDetails: {
    'Select site': 'Válassza ki ezt a webhelyet',
    'Ref: Page title': 'Helyszíni részletek',
    'Ref: Skip map': 'Hagyja ki a fő tartalmat',
    'Ref: Unavailable site': 'A webhely előnézete elkerülhetetlen a felhasználó számára',
    'Ref: Sorry, no description yet': 'Sajnálom, még nincs leírása.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'A helyszíni koordináták nem találtak',
    'Ref: Page title': 'Oldalak',
    'Recently visited': 'Nemrégiben meglátogatott',
    'All other sites': 'Minden más webhely',
    'Sites in your country': 'Webhelyek az országodban',
    'Search results': 'Keresési eredmények',
    'Select site': 'Válasszon telephelyet',
    'Ref: Select site with QR Scanner': 'Válassza ki a webhelyet a QR szkennerrel',
    'Ref: Select site with code': 'Válassza ki a webhelyet a kóddal',
    'Ref: Validate': 'Érvényesít',
    'Ref: Enter site code': 'Írja be a webhely kódját',
    'Ref: Scanner error header': 'A webhely nem található',
    'Ref: Generic error': 'Valami elromlott',
    'Ref: Change site': 'Változtassa meg az oldalt',
    'Ref: Change site description':
      'Vannak tárgyak a kosárban! A helyszín megváltoztatása a kosár tisztításához vezet.',
    'Ref: Cancel': 'Megszünteti',
    'Ref: Not found': 'Ezt a QR -kódot nem ismerték fel',
    'Ref: Unauthorized error': 'Jogosulatlan kérés a helyszín korlátozási szintje miatt',
    'Ref: Forbidden':
      'Sajnálom, nincs megfelelő engedélye a webhely eléréséhez. A hozzáférés megszervezéséhez kérjük, vegye fel a kapcsolatot a Pilbara Szolgáltatási Központtal az 1800 992 777 telefonszámon',
    "Ref: We didn't find the site.": 'Nem találtuk meg az oldalt.',
    'Ref: We recommend some solutions:': 'Javasolunk néhány megoldást:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Próbálja meg hozzáadni webhelyét QR szkennerrel vagy az alábbi gombok segítségével',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Jelentkezzen ki és jelentkezzen be a megfelelő ország kiválasztásával',
    'Ref: The currently selected country is:': 'Az jelenleg kiválasztott ország:',
  },
  SwitchSite: { 'Not on {site name}?': 'Nem a  {site name}?' },
  SiteTile: { Details: 'Részletek', Select: 'Kiválaszt' },
};
export default labels;
