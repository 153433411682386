import { getAttachmentDataFromBase64, getImageSrcFromId } from '@/helpers/images/images.helper';
import { Attachment } from '@/types/common.types';

export const getAttachments = async (attachments: Attachment[], callback?: Function) => {
  if (!attachments) return;

  const attachmentsMap = attachments?.map(async (image) => {
    const base64Image =
      image.id && image.id !== '#' ? await getImageSrcFromId(image.id) : image.content;
    if (!base64Image) return;

    return await getAttachmentDataFromBase64(
      base64Image.substring(base64Image.indexOf(',') + 1),
      image.fileName
    );
  });
  const result = await Promise.all(attachmentsMap);

  if (callback) {
    return callback(result);
  }

  return result;
};
