import { INPUT_TYPE } from '../../../constants';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import { Alert } from '../Alert';

import { EditAlertProps } from './EditAlert.types';

const EditAlert = ({
  id,
  name,
  value,
  inputType = INPUT_TYPE.TEXT,
  validate,
  handleSubmit,
  alertIsOpen,
  autocomplete,
  hideAlert,
  message,
  confirmButtonLabel,
  cancelButtonLabel,
}: EditAlertProps) => {
  const { label } = useCustomTranslation();
  const validateAndSubmit = (value: any) => {
    ((validate && validate(value)) || !validate) && handleSubmit(value);
  };

  return (
    <Alert
      isOpen={alertIsOpen}
      onDismiss={hideAlert}
      data-testid="alert"
      className={`cy-alert-${id}`}
      header={name}
      message={message}
      inputs={
        !message
          ? [
              {
                name: name,
                type: inputType,
                placeholder: value,
                autocomplete: autocomplete,
              },
            ]
          : undefined
      }
      buttons={[
        {
          text: cancelButtonLabel || label('cancel', { textTransform: 'capitalize' }),
          role: 'cancel',
          className: `secondary cy-alert-button-cancel-${id}`,
          'data-testid': 'edit-alert-cancel-button',
        },
        {
          text: confirmButtonLabel || label('confirm', { textTransform: 'capitalize' }),
          handler: (data) => {
            message ? handleSubmit() : validateAndSubmit(data[name]);
          },
          className: `cy-alert-button-confirm-${id}`,
          'data-testid': 'edit-alert-confirm-button',
        },
      ]}
    />
  );
};

export default EditAlert;
