import classNames from 'classnames';
import { FC, useState } from 'react';

import ReactionIcon from '../../../atoms/ReactionIcon';

import { ReactionOptionProps } from './Option.types';

import { REACTIONS_STYLING } from '@/types/reactions.types';

import styles from './Option.module.css';

const ReactionOption: FC<ReactionOptionProps> = ({
  reaction,
  className,
  tooltipId,
  handleReactionClick,
  'data-testid': dataTestId = 'reaction-option',
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const buttonStyle = isHover
    ? {
        backgroundColor: REACTIONS_STYLING[reaction.type].backgroundColor,
      }
    : {};

  return (
    <button
      aria-label={reaction.label}
      className={classNames(styles.option, className)}
      onClick={() => {
        handleReactionClick(reaction.type);
      }}
      data-testid={`${dataTestId}-${reaction.type}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={buttonStyle}
    >
      <ReactionIcon
        tooltipId={tooltipId}
        reaction={reaction.type}
        data-testid="reactions-button-icon"
        content={reaction.label}
      />
    </button>
  );
};

export default ReactionOption;
