import * as msTeamsApp from '@microsoft/teams-js';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { State } from '@/types/state.types';

const useIsRunningInTeams = () => {
  const msTeamsContext = useSelector((state: State) => state.Core?.msTeamsContext ?? null);

  const isRunningInTeams = useMemo(
    () => msTeamsContext?.TeamsContext?.app.host.name === msTeamsApp.HostName.teams,
    [msTeamsContext]
  );

  return { isRunningInTeams, msTeamsContext };
};

export default useIsRunningInTeams;
