const labels = {
  All: {
    'starts at': 'beginnt bei',
    'ends at': 'endet bei',
    location: 'Ort',
    'coming date': 'nächstes Datum',
    'Ref: Currently event not preferred. Click to make preferred':
      'Derzeit Ereignis nicht bevorzugt. Klicken Sie hier, um bevorzugt zu werden',
    'Ref: Currently event preferred. Click to make not preferred':
      'Derzeit bevorzugt. Klicken Sie hier, um nicht bevorzugt zu machen',
  },
  EventDetails: {
    details: 'Einzelheiten',
    'Ref: Page title': 'Veranstaltungsdetails',
    'Event dates': 'Veranstaltungsdaten',
    'See online:': 'Siehe online:',
    'See all dates': 'Alle Daten sehen',
    'Remove from my selection': 'Meine Auswahl entfernen',
    'Add to my selection': 'Zu meinen Favoriten hinzufügen',
    'Ref: explain calendar usage':
      'Die Veranstaltungstermine sind im unten stehenden Kalender markiert. Verpassen Sie nicht die Veranstaltungen, die Ihnen gefallen! Klicken Sie auf ein Veranstaltungsdatum in Kalendar, um eine Einladung herunterzuladen. Öffnen Sie dann die heruntergeladene Datei, um die Veranstaltung zu Ihrem voreingestellten Kalender hinzuzufügen.',
    'Ref: Favorite': 'Favorit',
    'Ref:Feedback': 'Feedback',
    'Ref: Event site different title': 'Dieses Ereignis gehört nicht zu Ihrer aktuellen Website',
    'Ref: Event site different description':
      'Sie sehen sich ein Ereignis an, das nicht zu Ihrer aktuellen Website gehört. Sie werden es nicht in der Liste der Ereignisse sehen können.',
    'Ref: See all dates': 'Siehe alle Daten',
    'Ref: From': 'Aus',
    'Ref: to': 'Zu',
    'Ref: Coming date': 'Kommendatum',
    'Ref: Add to calendar': 'Zum Kalender hinzufügen',
  },
  EventsList: {
    'Ref: Page title': 'Veranstaltungen',
    'All dates': 'Alle Daten',
    'All events': 'Alle Veranstaltungen',
    'Ref: Search': 'Suche',
    'My selection': 'Meine Favoriten',
    'filter label: date': 'Datum',
    'filter label: coming events': 'kommende Ereignisse',
    'filter label: past events': 'vergangene Ereignisse',
    'filter label: interest': 'Bevorzugung',
  },
  Widget: {
    'Ref: Carousel title': 'Veranstaltungen',
    'No info yet, come back later': 'Noch keine Informationen, komm später zurück 😉',
  },
};
export default labels;
