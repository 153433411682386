import React, { FC } from 'react';

import { REACTIONS_STYLING } from '../../../types/reactions.types';
import Button, { BUTTON_LOOK } from '../Button';

import { ReactionButtonProps } from './ReactionButton.types';

const ReactionButton: FC<ReactionButtonProps> = ({
  userReaction,
  total = 0,
  mainButtonValues,
  hideLabel = false,
  onClick,
  'data-testid': testId,
  ...rest
}) => {
  const ReactionIcon = userReaction ? REACTIONS_STYLING[userReaction].icon : mainButtonValues.icon;
  const handleOnClick = (e: React.MouseEvent<HTMLIonButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      style={
        userReaction
          ? {
              backgroundColor: REACTIONS_STYLING[userReaction].backgroundColor,
            }
          : undefined
      }
      look={BUTTON_LOOK.TERTIARY}
      onClick={handleOnClick}
      contentCenterAlign
      affix={() => <ReactionIcon />}
      data-testid={testId}
      className="styledButton"
      aria-label={mainButtonValues.label}
      {...rest}
    >
      {!hideLabel && (userReaction ? total : mainButtonValues.label)}
    </Button>
  );
};

export default ReactionButton;
