const mockResponse = {
  getAccommodationRequestDetails: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37d',
        referenceNumber: '#0612152',
        title: 'Five Star Stay at Koksijde Air Base',
        status: 'In Progress',
        arrivalDateTime: '2023-12-31T17:25:35Z',
        departureDateTime: '2024-01-02T10:00:00',
        description: 'I need the 5 (Five) star room\nwith a view to the Launch Pad',
        phoneNumber: '+32 2 442 34 49',
        site: {
          id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
          name: 'FMG Hamilton site',
        },
        raisedFor: {
          firstName: 'Michel',
          lastName: 'Hofman',
        },
        messages: [
          {
            description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
            createdBy: 'Michel Hofman',
            isCreatedByUser: true,
            createdOn: '2023-08-31T10:25:35Z',
          },
          {
            description: 'Aye Aye, General! The Base is at your service!',
            createdBy: 'Sodexo',
            isCreatedByUser: false,
            createdOn: '2023-09-01T10:25:35Z',
          },
        ],
        images: [],
      }),
  },
  getAccommodationRequestUpcomingList: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37c',
          referenceNumber: '#0612154',
          title: 'Five Start Stay at Koksijde Air Base',
          status: 'Completed',
          arrivalDateTime: '2023-10-10T10:25:35Z',
          site: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
            name: 'FMG Hamilton site',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-31T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service! Test3',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37b',
          referenceNumber: '#0612153',
          title: 'Five Start Stay at Koksijde Test Base',
          status: 'Refused',
          arrivalDateTime: '2023-11-03T10:25:35Z',
          site: {
            id: '62b9d04a-23c6-e811-a966-000d3a2bcac2',
            name: 'El Dorado village',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-31T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service! Test2',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37e',
          referenceNumber: '#0612156',
          title: 'Five Start Stay at Koksijde Ground Base',
          status: 'In Progress',
          arrivalDateTime: '2023-11-15T10:25:35Z',
          site: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
            name: 'FMG Hamilton site',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-31T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service!',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37d',
          referenceNumber: '#0612155',
          title: 'Five Start Stay at Koksijde Water Base',
          status: 'Pending',
          arrivalDateTime: '2023-12-24T10:25:35Z',
          site: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
            name: 'FMG Hamilton site',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-15T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service!',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37a',
          referenceNumber: '#0612152',
          title: 'Five Start Stay at Koksijde Missle Base',
          status: 'Completed',
          arrivalDateTime: '2023-12-29T10:25:35Z',
          site: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
            name: 'FMG Hamilton site',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-31T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service! Test1',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
      ]),
  },
  getAccommodationRequestPastList: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37b',
          referenceNumber: '#0612153',
          title: 'Five Star Stay at Koksijde Test Base',
          status: 'Refused',
          arrivalDateTime: '2023-10-02T10:25:35Z',
          site: {
            id: '62b9d04a-23c6-e811-a966-000d3a2bcac2',
            name: 'El Dorado village',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-31T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service! Test2',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37a',
          referenceNumber: '#0612152',
          title: 'Five Star Stay at Koksijde Missle Base',
          status: 'Completed',
          arrivalDateTime: '2023-09-29T10:25:35Z',
          site: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
            name: 'FMG Hamilton site',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-31T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service! Test1',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37d',
          referenceNumber: '#0612155',
          title: 'Five Star Stay at Koksijde Water Base',
          status: 'Pending',
          arrivalDateTime: '2023-09-04T10:25:35Z',
          site: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
            name: 'FMG Hamilton site',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-15T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service!',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
        {
          id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37c',
          referenceNumber: '#0612154',
          title: 'Five Star Stay at Koksijde Air Base',
          status: 'Completed',
          arrivalDateTime: '2023-07-10T10:25:35Z',
          site: {
            id: '1242ac4a-f57b-e911-a97e-000d3a2ts32',
            name: 'FMG Hamilton site',
          },
          messages: [
            {
              description: 'Please no flights after 23:00, I have an early start next day. Thanks!',
              createdBy: 'Michel Hofman',
              isCreatedByUser: true,
              createdOn: '2023-08-31T10:25:35Z',
            },
            {
              description: 'Aye Aye, General! The Base is at your service! Test3',
              createdBy: 'Sodexo',
              isCreatedByUser: false,
              createdOn: '2023-09-01T10:25:35Z',
            },
          ],
        },
      ]),
  },
  getAccommodationSitesList: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: '3a301679-bd7c-e911-a97e-000d3a2bcd97',
          name: 'FMG Camp 145',
        },
        {
          id: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
          name: 'FMG Hamilton',
        },
      ]),
  },
};

export default mockResponse;
