import MM from '../../../services/ModulesManager';
import { ApiType } from '../../Core/api';
import { mainDetailPagePath } from '../config';
import { ContentPageDetail } from '../types';

import getChildrenPageUrl from './getChildrenPageUrl';

const getParentPageUrl = (navItemName: string, page: ContentPageDetail) => {
  const { parent } = page;

  if (!parent) {
    return undefined;
  }

  const mainPageId = (
    MM<ApiType>().getApi(
      'Core',
      'getContentPageIdByNavItemName'
    ) as ApiType['getContentPageIdByNavItemName']
  )(navItemName);

  if (mainPageId === parent.id) {
    return mainDetailPagePath.replace(':navItemName', navItemName);
  }

  return getChildrenPageUrl(navItemName, parent);
};

export default getParentPageUrl;
