const labels = {
  ContentDetails: {
    'Ref: Page title': 'Išsami informacija apie turinį',
    'See the event': 'Pamatyti įvykį',
    'Take the survey': 'Dalyvaukite apklausoje',
    Date: 'Data',
    Category: 'Kategorija',
    'Ref: Content site different title': 'Šis turinys nepriklauso jūsų dabartinei svetainei',
    'Ref: Content site different description':
      'Jūs žiūrite turinį, kuris nepriklauso jūsų dabartinei svetainei. Negalėsite to pamatyti turinio sąraše.',
  },
  ContentList: {
    'Ref: Page title': 'Naujienų srautas',
    'featured (as in featured content)': 'Teminiai',
    'Ref: category - all': 'Viskas',
    'Ref: category - promotional': 'Reklaminė',
    'Ref: category - featured': 'Rodomas',
    'Ref: category - other': 'Kita',
  },
  Widget: {
    'Ref: Carousel title': 'Naujienos ir straipsniai',
    'Ref: Carousel title promo': 'Geriausios naujienos',
    'No info yet, come back later': 'Dar nėra informacijos, grįžk vėliau 😉',
    'Ref: Content': 'Turinys',
    'Ref: Widget Title': 'Naujienų srautas',
    'Ref: MyVillage Carousel title promo': 'Akcijos',
  },
};
export default labels;
