import moment from 'moment';
import { useCallback } from 'react';

import MultiCardSelect from '../../../../components/atoms/MultiCardSelect/MultiCardSelect';
import { MultiCardSelectParams } from '../../../../components/atoms/MultiCardSelect/MultiCardSelect.types';
import DateTime from '../../../../components/molecules/DateTime';
import { PICKER_TYPE } from '../../../../components/molecules/DateTime/DateTime.types';
import { scheduleTypeValues } from '../../types';

import { ScheduleFieldsProps } from './ScheduleFields.types';

import styles from './ScheduleFields.module.css';

const ScheduleFields = ({
  scheduleDate = new Date(),
  scheduleType,
  setScheduleDate,
  setScheduleType,
  label,
}: ScheduleFieldsProps) => {
  const onChange = useCallback(
    (option: MultiCardSelectParams) => {
      setScheduleType(scheduleTypeValues[option.value as keyof typeof scheduleTypeValues]);
    },
    [setScheduleType]
  );

  const onChangeDate = useCallback(
    (value: any) => {
      const date = new Date(scheduleDate);
      date.setSeconds(0);

      if (value.date) {
        date.setDate(new Date(value.date).getDate());
        date.setMonth(new Date(value.date).getMonth());
        date.setFullYear(new Date(value.date).getFullYear());
      }

      if (value.time) {
        const [hours, minutes] = value.time.split(':');
        date.setHours(hours);
        date.setMinutes(minutes);
      }

      setScheduleDate(date);
    },
    [scheduleDate, setScheduleDate]
  );

  const scheduleTypeOptions = [
    {
      label: label('leave at'),
      value: scheduleTypeValues.leave,
    },
    {
      label: label('arrive at'),
      value: scheduleTypeValues.arrive,
    },
  ];

  return (
    <div>
      <MultiCardSelect
        data-testid="scheduled-fields"
        optionClassName={'bodySBold'}
        options={scheduleTypeOptions}
        isMultiple={false}
        onChange={onChange}
        label={label}
        values={[scheduleType]}
        isBoldLabel={false}
        preventUnselect={true}
        columns={2}
        iconOnTheLeft={true}
      />

      <div className={styles.dateTimeInputs}>
        <DateTime
          id={'scheduled-fields-datetime-from'}
          value={scheduleDate}
          format="DD/MM/YYYY"
          placeholder="DD/MM/YYYY"
          className={styles.dateInput}
          minDate={moment().toDate()}
          onChange={(date) => onChangeDate({ date })}
          data-testid="date-select"
        />
        <span className={styles.dateTimeInputsDivider}>{label('at')}</span>
        <DateTime
          id={'scheduled-fields-datetime-to'}
          value={scheduleDate}
          className={styles.dateInput}
          type={PICKER_TYPE.TIME}
          onChange={(time) => onChangeDate({ time })}
          data-testid="time-select"
        />
      </div>
    </div>
  );
};

export default ScheduleFields;
