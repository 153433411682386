const labels = {
  ContactForm: {
    'Ref: Page title': 'Neem contact met ons op',
    'Ref: Category field value': 'Neem contact met ons op',
  },
  ContactList: {
    'Ref: Page title': 'Contacten',
    'call (action to place a call)': 'telefoongesprek',
    'write (action to write a message)': 'schrijven',
    'write (action to write an e-mail)': 'e-mail',
    'Ref: Hint message':
      'Houd er rekening mee dat sommige sites mogelijk geen ontvangst hebben. Als dit het geval is, zoek dan een van de vaste telefoons die beschikbaar zijn op de site.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'Er is een fout opgetreden en uw bericht is niet ingediend. Als deze fout aanhoudt, neem dan contact op met uw help desk.',
  },
  FormSubmissionSuccess: {
    'Ref: submitted success': 'Uw bericht is verzonden. Bedankt dat u contact met ons opneemt.',
  },
};
export default labels;
