import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { formatDate } from '../../../../helpers/dateTime';
import { useCreateAuditSurveyResponseMutation, useGetAuditSurveyQuery } from '../../api';
import { AuditSurveyResponse } from '../../api/api.types';
import NotFound from '../../components/NotFound';
import { QuestionTypes } from '../../components/QuestionComponent/QuestionComponent.types';
import Questions from '../../components/Questions';
import { TitleSkeleton } from '../../components/Skeletons';
import SubmitButton from '../../components/SubmitButton';
import Success from '../../components/Success';
import UnsupportedQuestionType from '../../components/UnsupportedQuestionType';
import { useLocalData } from '../../hooks';
import { useAuditsTranslation } from '../../hooks/useAuditsTranslation';
import { AuditStateCacheService } from '../../services/AuditStateCacheService';
import { AuditStatus } from '../../types';

import { FormAttachmentsValues, FormValues } from './AuditResponseForm.types';

import { StatusCheckCircleIcon } from '@/assets/icons';
import { AuditBIllustration } from '@/assets/illustrations';
import Title, { TITLE_SIZE } from '@/components/atoms/Title';
import Form from '@/components/molecules/Form';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import useLanguage from '@/modules/Core/hooks/useLanguage';

const AuditResponseForm = () => {
  const { label } = useAuditsTranslation(__filename);
  const { id } = useParams<{ id: string }>();
  const [isSupported, setIsSupported] = useState<boolean>(true);

  const [isRetrievingCache, setIsRetrievingCache] = useState<boolean>(false);

  const auditStateService = useMemo(() => AuditStateCacheService.getInstance(), []);

  const localData = useLocalData(id);

  const { data, error = {}, isFetching } = useGetAuditSurveyQuery(id);
  const [createAuditSurveyResponse, { isLoading: isSubmitting, isSuccess: isSubmitted }] =
    useCreateAuditSurveyResponseMutation();

  const { status } = error as { status: number };

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    mode: 'onSubmit',
  });

  const {
    auditId,
    inviteId,
    workOrderId,
    auditName,
    workOrderNumber,
    auditStream,
    questions = [],
    response,
  } = (data || localData?.audit || {}) as AuditSurveyResponse;

  const state = localData?.audit?.response?.responses ? 1 : data?.state;
  const { responses, submitDate: responseDate } = localData?.audit?.response || response || {};
  const { currentLanguageCode } = useLanguage();

  const isComplete = state === AuditStatus.COMPLETED;
  const values = watch();

  const pageTitle = label('Ref: Page title');

  const onSubmit = useCallback(
    async (formData: FormValues) => {
      if (!auditId || !auditName) return;

      const {
        responses,
        responseComments,
        responseAttachments,
      }: {
        responses: FormValues;
        responseComments: FormValues;
        responseAttachments: FormAttachmentsValues;
      } = Object.entries(formData).reduce(
        (acc, [key, value]) => {
          if (key.includes('comment'))
            return { ...acc, responseComments: { ...acc.responseComments, [key]: value } };
          if (key.includes('attachments'))
            return { ...acc, responseAttachments: { ...acc.responseAttachments, [key]: value } };

          return { ...acc, responses: { ...acc.responses, [key]: value } };
        },
        { responses: {}, responseComments: {}, responseAttachments: {} }
      );

      const body = {
        responseDate: moment().format(),
        workOrderId,
        auditId,
        inviteId: inviteId || id,
        auditName,
        responses: Object.entries(responses).map(([key, value]) => {
          const question = questions.find(({ id }) => id === key);
          const [, comment] =
            Object.entries(responseComments).find(
              ([commentKey]) => commentKey.replace('comment_', '') === key
            ) || [];
          const [, attachments] =
            Object.entries(responseAttachments).find(
              ([commentKey]) => commentKey.replace('attachments_', '') === key
            ) || [];

          let requestValue = value;
          if (typeof value === 'object') {
            requestValue = JSON.stringify(value);
          }
          if (typeof value === 'number') {
            requestValue = (value as number).toString();
          }

          return {
            questionId: key,
            response: requestValue,
            additionalComment: comment,
            attachments: attachments?.map(({ file, data }) => ({
              name: file.name,
              mimeType: file.type,
              documentBody: data,
              size: file.size,
            })),
            order: question?.order || 0,
          };
        }),
      };

      try {
        await createAuditSurveyResponse(body);
      } catch (err) {
        console.error(err);
      }
    },
    [auditId, auditName, createAuditSurveyResponse, id, inviteId, questions, workOrderId]
  );

  const questionTypes = useMemo(
    () => [...new Set(questions.map(({ questionType }) => questionType))],
    [questions]
  );
  const availableQuestionTypes = Object.values(QuestionTypes);

  useEffect(() => {
    if (isComplete || isSubmitted) return;

    const isClean = Object.values(values).filter((v) => v).length === 0;

    if (!isClean) {
      auditStateService.setValue(id, values);
    }
  }, [values, auditStateService, id, isComplete, isSubmitted]);

  useEffect(() => {
    (async () => {
      if (isComplete) return;

      setIsRetrievingCache(true);
      const draft = await auditStateService.getValue(id);
      if (draft) {
        Object.entries(draft).forEach(([k, v]) => {
          if (v) {
            setValue(k, v);
          }
        });
      }

      setIsRetrievingCache(false);
    })();
  }, [auditStateService, id, isComplete, setValue]);

  useEffect(() => {
    const isSupportedType = questionTypes.every((type) => {
      if (availableQuestionTypes.includes(type)) {
        return true;
      }
      return false;
    });
    setIsSupported(isSupportedType);
  }, [availableQuestionTypes, questionTypes]);

  useEffect(() => {
    if (isSubmitted) {
      auditStateService.remove(id);
    }
  }, [auditStateService, id, isSubmitted]);

  if (!isSupported) {
    return <UnsupportedQuestionType title={pageTitle} label={label} />;
  }

  if (status === 404 && !isFetching && !localData?.audit) {
    return <NotFound title={pageTitle} label={label} />;
  }

  if (isSubmitted) {
    return <Success name={auditName} label={label} />;
  }

  return (
    <SimpleFormPage title={pageTitle} tabTitle={auditName}>
      <Container>
        <Column.Main>
          {isComplete && !isFetching && (
            <Notification
              look={NOTIFICATION_LOOK.SUCCESS}
              icon={<StatusCheckCircleIcon width={20} height={20} />}
              title={`${label('Ref: form complete')} ${formatDate(
                responseDate ? new Date(responseDate) : new Date(),
                currentLanguageCode
              )}`}
              data-testid="complete-notification"
            />
          )}

          {Object.keys(errors).length > 0 && (
            <Notification
              look={NOTIFICATION_LOOK.ERROR}
              dismissable
              title={label('Ref: form error')}
              data-testid="error-notification"
            />
          )}

          {isFetching ? (
            <TitleSkeleton />
          ) : (
            <div>
              <Title size={TITLE_SIZE.BODYMBOLD} className={classNames('mb-S')}>
                {workOrderNumber}
                {`${auditStream ? ` • ${auditStream}` : ''}`}
              </Title>
              <Title size={TITLE_SIZE.HEADLINES}>
                {auditName}
                {questions.length
                  ? ` • ${questions.length} ${
                      questions.length > 1 ? label('Ref: questions') : label('Ref: question')
                    }`
                  : ''}
              </Title>
            </div>
          )}

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Questions
              items={questions}
              responses={responses}
              isLoading={isFetching || isRetrievingCache}
              label={label}
              control={control}
              errors={errors}
              isComplete={isComplete}
            />
            {!isComplete && <SubmitButton label={label} isLoading={isSubmitting} />}
          </Form>
        </Column.Main>
        <Column.Complementary>
          <AuditBIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default AuditResponseForm;
