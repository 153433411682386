const labels = {
  All: {
    'starts at': 'начинается в',
    'ends at': 'заканчивается',
    location: 'Расположение',
    'coming date': 'Доступность даты',
    'Ref: Currently event not preferred. Click to make preferred':
      'В настоящее время событие не предпочтительнее. Нажмите, чтобы сделать предпочтительным',
    'Ref: Currently event preferred. Click to make not preferred':
      'В настоящее время мероприятие предпочтительнее. Нажмите, чтобы сделать не предпочтительным',
  },
  EventDetails: {
    details: 'Подробности',
    'Ref: Page title': 'Детали события',
    'Event dates': 'Даты события',
    'See online:': 'Смотрите онлайн:',
    'See all dates': 'Смотрите все даты',
    'Remove from my selection': 'Удалить из моего выбора',
    'Add to my selection': 'Добавить в мой предпочтительный',
    'Ref: explain calendar usage':
      'Даты события выделены в календаре ниже. Не пропустите события, которые вам нравятся! Нажмите на дату события в календаре, чтобы загрузить приглашение. Затем откройте загруженный файл, чтобы добавить событие на ваш доверенный календарь.',
    'Ref: Favorite': 'Любимый',
    'Ref:Feedback': 'Обратная связь',
    'Ref: Event site different title': 'Это событие не принадлежит вашему текущему сайту',
    'Ref: Event site different description':
      'Вы просматриваете событие, которое не принадлежит вашему текущему сайту. Вы не сможете увидеть это в списке событий.',
    'Ref: See all dates': 'Смотрите все даты',
    'Ref: From': 'От',
    'Ref: to': 'к',
    'Ref: Coming date': 'Приходящая дата',
    'Ref: Add to calendar': 'Добавить в календарь',
  },
  EventsList: {
    'Ref: Page title': 'События',
    'All dates': 'Все даты',
    'All events': 'Все события',
    'Ref: Search': 'Поиск',
    'My selection': 'Мой предпочтительный',
    'filter label: date': 'Свидание',
    'filter label: coming events': 'Предстоящие события',
    'filter label: past events': 'прошлые события',
    'filter label: interest': 'предпочтение',
  },
  Widget: {
    'Ref: Carousel title': 'События',
    'No info yet, come back later': 'Пока нет информации, вернитесь позже 😉',
  },
};
export default labels;
