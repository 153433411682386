const labels = {
  All: {
    dailyMenu: 'Menu del giorno',
    anytime: 'in qualsiasi momento',
    breakfast: 'Prima colazione',
    morningBreak: 'pausa mattutina',
    lunch: 'Pranzo',
    afternoonBreak: 'Pomeriggio Break.',
    dinner: 'Cena',
    supper: 'Cena',
    nightMeal: 'pasto notturno',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menu',
    'Ref: View details': 'Visualizza dettagli',
    'Ref: Facility details': 'Dettaglio Struttura',
  },
  OpeningHours: { everyday: 'ogni giorno', 'open all day': 'Aperto tutto il giorno' },
  ProductsList: {
    'Ref: Page title': 'Menù',
    'Ref: Facility details': 'Dettaglio Struttura',
    'Ref: Search products list': 'Articoli di ricerca',
    'Ref: filter label: date': 'Data',
    'Ref: filter label: menus': 'Menù',
    'Ref: filter label: moment': 'Momento',
    'Ref: filter label: category': 'Categoria',
    'Ref: filter label: dishes': 'Piatti',
    'Ref: filter label: highlight': 'Evidenzia o nascondi filtri con piatti',
    All: 'Tutto',
  },
  ProductDetails: { 'Ref: Page title': 'Dettagli del prodotto' },
  Widget: {
    'Ref: Carousel title': 'Menu',
    'No info yet, come back later': 'Ancora nessuna informazione, torna più tardi 😉',
    'Ref: menus': 'Menu',
    'Ref: see all': 'Vedi tutto',
  },
};
export default labels;
