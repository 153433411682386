import { Calendar as NativeCalendar } from '@awesome-cordova-plugins/calendar';
import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { CalendarTileProperties } from 'react-calendar';

import { findNextEventDate, findNextEventDateString } from '../../helpers/eventDates.helper';
import { useEventTranslation } from '../../hooks/useEventTranslation';
import EventDatesModal from '../EventDatesModal';
import EventReactions from '../EventReactions';

import { hasEventForDate, hasEventForMonth } from './Details.helper';
import { EventDetailsProps } from './Details.types';

import { Calendar2Icon, ClockIcon, LocationFillIcon } from '@/assets/icons';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import { MarkDownContent } from '@/components/atoms/RenderContent';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Calendar from '@/components/molecules/Calendar';
import Card from '@/components/molecules/Card/Card';
import ContentTitle from '@/components/molecules/ContentTitle/ContentTitle';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import List from '@/components/organisms/List/List';
import { ListItemProps } from '@/components/organisms/List/List.types';
import Modal from '@/components/organisms/Modal';
import ContentDetailsPage from '@/components/templates/ContentDetailsPage';
import useToggle from '@/helpers/hooks/useToggle';
import { download } from '@/helpers/misc';
import useLanguage from '@/modules/Core/hooks/useLanguage';

import styles from './Details.module.css';

// TODO migrate to modules
const ics = require('ics');

const EventDetails = ({ eventDetails, onDifferentSite }: EventDetailsProps) => {
  const { currentLanguageCode } = useLanguage();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const { toggleOff: closeForDownload } = useToggle(false);
  const { state: showAllDates, toggleOn: allDatesOn, toggleOff: allDatesOff } = useToggle(false);
  const { label } = useEventTranslation('EventDetails');

  const {
    state: isSeeAllDatesModalOpen,
    toggleOn: openSeeAllDatesModal,
    toggleOff: closeSeeAllDatesModal,
  } = useToggle(false);

  const startTimeString = moment.utc(eventDetails.startDate).format('LT');
  const endTimeString = moment.utc(eventDetails.endDate).format('LT');
  const eventDatesString = eventDetails?.eventDates;

  const onChangeCalendarDate = (eventDate: any) => {
    setSelectedDate(eventDate);
  };

  const downloadEvent = async () => {
    if (!selectedDate) {
      return;
    }

    // adjustments for timings
    // bug https://azeuvs1aze987.visualstudio.com/IFM/_workitems/edit/51843
    // issue TLDR; when selecting the last date of the event - start time of the event set to midnight
    const selectedDateString = moment.utc(selectedDate).format('YYYY-MM-DD');

    const startTime = eventDetails?.startDate.substring(eventDetails?.startDate.indexOf('T'));
    const endTime = eventDetails?.endDate.substring(eventDetails?.endDate.indexOf('T'));

    const eventEndDateObj = moment.utc(`${selectedDateString}${endTime}`);
    const eventStartDateObj = moment.utc(`${selectedDateString}${startTime}`);
    const durationInMinutes = eventEndDateObj.diff(eventStartDateObj, 'minutes');
    const adjustedSelectedDate = eventStartDateObj.toDate();

    const title = eventDetails?.name;
    const description =
      eventDetails?.description +
      '\n' +
      label('See online:', { end: 'whitespace' }) +
      window.location.href;
    const location = eventDetails?.location;

    if (Capacitor.isNativePlatform()) {
      const startDate = adjustedSelectedDate;
      const endDate = eventEndDateObj.toDate();
      // Create event
      await (async () => {
        await NativeCalendar.createEventInteractively(
          title,
          location,
          description,
          startDate,
          endDate
        );

        closeForDownload();
      })();
    } else {
      // Download event
      const categories = eventDetails?.preferences.map((category: any) => category?.name);
      const downloadableEvent = {
        start: [
          adjustedSelectedDate.getUTCFullYear(),
          adjustedSelectedDate.getUTCMonth() + 1,
          adjustedSelectedDate.getUTCDate(),
          adjustedSelectedDate.getUTCHours(),
          adjustedSelectedDate.getUTCMinutes(),
        ],
        startInputType: 'local',
        title: eventDetails?.name,
        description:
          eventDetails?.description +
          '\n' +
          label('See online:', { end: 'whitespace' }) +
          window.location.href,
        location: eventDetails?.location,
        url: window.location.href,
        categories: categories,
        busyStatus: 'BUSY',
        duration: {
          minutes: durationInMinutes,
        },
      };
      ics.createEvent(downloadableEvent, async (error: any, value: any) => {
        if (error) {
          console.log(error);
          return;
        }
        await download(value, eventDetails?.name + '.ics', 'text/calendar');
      });
    }
  };

  const closeAllDatesCalendarModal = () => {
    allDatesOff();
    openSeeAllDatesModal();
  };

  const seeAllDatesKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      openSeeAllDatesModal();
    }
  };

  const nextEventDateFormatted = findNextEventDateString(eventDatesString, currentLanguageCode);
  const nextEventDate = findNextEventDate(eventDatesString, currentLanguageCode);

  const createCard = (cardItems: ListItemProps[]) => {
    return cardItems.length > 0 ? (
      <Card>
        <List data-testid="event-details-card-list" items={cardItems} />
      </Card>
    ) : null;
  };

  const getTileDisabledStatus = useCallback(
    ({ date, view }: CalendarTileProperties): boolean => {
      if (view === 'year') {
        return !Boolean(hasEventForMonth(date, eventDatesString));
      } else {
        return !Boolean(hasEventForDate(date, eventDatesString));
      }
    },
    [eventDatesString]
  );

  const getTileClassName = useCallback(
    ({ date }: CalendarTileProperties): string =>
      classNames({
        highlighted:
          Boolean(hasEventForDate(date, eventDatesString)) && eventDetails?.isUserInterested,
      }),
    [eventDatesString, eventDetails?.isUserInterested]
  );

  const externalLink: ListItemProps[] = [];
  const eventDate: ListItemProps[] = [];

  if (eventDetails?.link?.url)
    externalLink.push({
      id: 'externalLink',
      'data-testid': 'external-link-item',
      label: eventDetails?.link?.text ? (
        <Title
          size={TITLE_SIZE.BODYSBOLD}
          className={classNames(styles.eventLink, 'underlinedText')}
        >
          {eventDetails?.link?.text}
        </Title>
      ) : (
        <Title size={TITLE_SIZE.BODYSBOLD} className="underlinedItalicText">
          {label('Ref: External link')}
        </Title>
      ),
      externalPath: eventDetails?.link.url,
    });

  if (nextEventDateFormatted) {
    eventDate.push(
      {
        id: 'eventDate',
        'data-testid': 'event-date-item',
        label: <>{nextEventDateFormatted}</>,
        icons: [
          {
            icon: Calendar2Icon,
          },
        ],
        value: (
          <span
            role="button"
            tabIndex={0}
            aria-label={label('Ref: See all dates')}
            onClick={openSeeAllDatesModal}
            onKeyDown={seeAllDatesKeyDown}
            className={styles.seeAllDatesCard}
          >
            <Title size={TITLE_SIZE.BODYSBOLD} className="underlinedText">
              {label('Ref: See all dates')}
            </Title>
          </span>
        ),
      },
      {
        id: 'eventTime',
        'data-testid': 'event-time-item',
        label: (
          <>
            {startTimeString}{' '}
            <span className={styles.timeSeparator}>
              {label('to (followed by a date, or date and time)')}
            </span>{' '}
            {endTimeString}
          </>
        ),
        icons: [
          {
            icon: ClockIcon,
          },
        ],
      }
    );
  }

  const description = (
    <>
      {(eventDetails?.description || eventDetails?.markdownDecoded) && (
        <Card>
          {eventDetails?.description && (
            <div className={classNames(styles.descriptionContainer)}>
              {eventDetails?.description}
            </div>
          )}
          {eventDetails?.markdownDecoded && (
            <div className={styles.markdownWrapper}>
              <MarkDownContent
                data-testid="event-detail-markdown-content"
                language={currentLanguageCode}
              >
                {eventDetails?.markdown!}
              </MarkDownContent>
            </div>
          )}
        </Card>
      )}
    </>
  );

  const allDatesCalendarModal = (
    <Modal
      title={label('Ref: See all dates', { textTransform: 'capitalize' })}
      isOpen={showAllDates}
      id="calendar_modal"
      onDismiss={closeAllDatesCalendarModal}
      crossButtonDismiss={false}
      leftButtonGoBack
      footer={
        <Button
          className={styles.calendarModalButton}
          look={BUTTON_LOOK.PRIMARY}
          disabled={!selectedDate}
          data-cy={'download-to-calendar'}
          onClick={downloadEvent}
          data-testid="event-detail-download"
        >
          {label('Ref: Add to calendar')}
        </Button>
      }
    >
      <Calendar
        minDate={nextEventDate}
        locale={currentLanguageCode}
        onChange={onChangeCalendarDate}
        minDetail="year"
        maxDetail="month"
        tileDisabled={getTileDisabledStatus}
        tileClassName={getTileClassName}
      />
    </Modal>
  );

  const getTitle = useCallback(
    (className: string) => (
      <ContentTitle
        data-testid="content-tile"
        title={
          <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
            {eventDetails?.name}
          </Title>
        }
        subtitle={eventDetails?.location}
        subtitleIcon={LocationFillIcon}
        className={className}
      />
    ),
    [eventDetails?.location, eventDetails?.name]
  );

  return (
    <ContentDetailsPage.WithNotification
      title={eventDetails.name ?? ''}
      header={{
        imageSrc: eventDetails?.mainImage,
        imageAlt: eventDetails?.name,
        children: getTitle(styles.titleSide),
      }}
      actions={<EventReactions eventDetails={eventDetails} />}
      sideChildren={
        <>
          {createCard(eventDate)}
          {
            <EventDatesModal
              eventDetails={eventDetails}
              languageCode={currentLanguageCode}
              nextDate={nextEventDate}
              isSeeAllDatesModalOpen={isSeeAllDatesModalOpen}
              setSelectedDate={setSelectedDate}
              closeSeeAllDatesModal={closeSeeAllDatesModal}
              downloadEvent={downloadEvent}
              allDatesOn={allDatesOn}
            />
          }
        </>
      }
      notificationContent={
        onDifferentSite && (
          <Notification
            title={label('Ref: Event site different title')}
            look={NOTIFICATION_LOOK.INFO}
          >
            {label('Ref: Event site different description')}
          </Notification>
        )
      }
      tabTitle={eventDetails?.name}
    >
      {getTitle(styles.titleMain)}
      {description}
      {createCard(externalLink)}
      {allDatesCalendarModal}
    </ContentDetailsPage.WithNotification>
  );
};

export default EventDetails;
