import { coreConfig } from '../../apiConfig';
import mockResponse from '../../mocks/accountApi';

import { persistLocallyUserSpecific } from '@/modules/Core/actions';
import {
  GetUserContextArgs,
  GetUserContextRes,
  UpdateUserContextArgs,
} from '@/modules/Core/api/account/accountApi.types';
import { store } from '@/store/index';
import { User } from '@/types/user.types';

const { api, contextUrl } = coreConfig();
const userContextApi = api.enhanceEndpoints({ addTagTypes: ['userContext'] }).injectEndpoints({
  endpoints: (build) => ({
    updateUserContext: build.mutation<{}, UpdateUserContextArgs>({
      query: (args) => ({
        url: contextUrl,
        method: 'post',
        data: { ...args },
        mockData: mockResponse.updateUserContext,
      }),
      async onQueryStarted({ ...patch }, { dispatch }) {
        dispatch(
          userContextApi.util.updateQueryData('getAccountUserContext', {}, (draft) => {
            const user = draft;
            if (patch.mobile) user.mobile = patch.mobile;
            if (patch.preferredLocation) user.preferredLocation = patch.preferredLocation;

            return user;
          })
        );
      },
      invalidatesTags: ['userContext'],
    }),
    // this method is just to read user context data with no further actions
    // the method from actions.ts is used to get user site and services.
    // it's renamed so it can;t be confused with the one from actions.ts
    getAccountUserContext: build.query<User, GetUserContextArgs>({
      query: () => ({
        url: contextUrl,
        method: 'get',
        mockData: mockResponse.getUserContext,
      }),
      transformResponse: (response: GetUserContextRes) => {
        store.dispatch({
          type: 'USER_CONTEXT_RETURNED',
          user: {
            qrCode: response.qrCode,
            contactId: response.contactId,
            firstName: response.firstName,
            lastName: response.lastName,
            email: response.email,
            mobile: response.mobile,
            preferredLanguage: response.preferredLanguage,
            communicationPreferences: {
              allowContent: response.allowContent,
              allowOffers: response.allowOffers,
            },
            preferredLocation: response.preferredLocation,
            contract: response.contract,
            theme: response.theme,
          },
          context: {
            site: response.site,
          },
          access: {
            shouldSelectSiteContext: !response.site,
          },
          persistLocally: persistLocallyUserSpecific,
        });
        return {
          qrCode: response.qrCode,
          contactId: response.contactId,
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          mobile: response.mobile,
          preferredLanguage: response.preferredLanguage,
          communicationPreferences: {
            allowContent: response.allowContent,
            allowOffers: response.allowOffers,
          },
          username: response.username ?? '',
          preferredLocation: response.preferredLocation,
          contract: response.contract,
          theme: response.theme,
        };
      },
      providesTags: ['userContext'],
    }),
  }),
  overrideExisting: true,
});

export const { useUpdateUserContextMutation, useGetAccountUserContextQuery } = userContextApi;
export default userContextApi;
