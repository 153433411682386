const labels = {
  SiteDetails: {
    'Select site': 'בחר אתר זה',
    'Ref: Page title': 'פרטי אתר',
    'Ref: Skip map': 'דלג לתוכן הראשי',
    'Ref: Unavailable site': 'תצוגה מקדימה של האתר לא ניתנת לניתוח עבור המשתמש שלך',
    'Ref: Sorry, no description yet': 'סליחה, עדיין אין לנו תיאור.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'קואורדינטות אתר לא נמצאו',
    'Ref: Page title': 'אתרים',
    'Recently visited': 'ביקר לאחרונה',
    'All other sites': 'כל שאר האתרים',
    'Sites in your country': 'אתרים במדינה שלך',
    'Search results': 'תוצאות חיפוש',
    'Select site': 'בחר אתר',
    'Ref: Select site with QR Scanner': 'בחר אתר עם סורק QR',
    'Ref: Select site with code': 'בחר אתר עם קוד',
    'Ref: Validate': 'אימות',
    'Ref: Enter site code': 'הזן קוד אתר',
    'Ref: Scanner error header': 'האתר לא נמצא',
    'Ref: Generic error': 'משהו השתבש',
    'Ref: Change site': 'שנה אתר',
    'Ref: Change site description': 'יש פריטים בעגלה! שינוי האתר יביא לפינוי העגלה.',
    'Ref: Cancel': 'לְבַטֵל',
    'Ref: Not found': 'קוד QR זה לא מזוהה',
    'Ref: Unauthorized error': 'בקשה לא מורשית עקב רמת הגבלת האתר',
    'Ref: Forbidden':
      'סליחה, אין לך הרשאות נכונות לגשת לאתר זה. לתיקון גישה, אנא צרו קשר עם מרכז השירות של פילבארה בטלפון 1800 992 777',
    "Ref: We didn't find the site.": 'לא מצאנו את האתר.',
    'Ref: We recommend some solutions:': 'אנו ממליצים על כמה פתרונות:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. נסה להוסיף את האתר שלך עם סורק QR או בעזרת הכפתורים למטה',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. התנתק והתחבר חזרה על ידי בחירת המדינה הנכונה',
    'Ref: The currently selected country is:': 'המדינה שנבחרה כיום היא:',
  },
  SwitchSite: { 'Not on {site name}?': 'לא על {site name}?' },
  SiteTile: { Details: 'פרטים', Select: 'בחר' },
};
export default labels;
