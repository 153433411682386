const labels = {
  ContentDetails: {
    'Ref: Page title': 'Inhoudsdetails',
    'See the event': 'Zie het evenement',
    'Take the survey': 'De enquete invullen',
    Date: 'Datum',
    Category: 'Categorie',
    'Ref: Content site different title': 'Deze inhoud behoort niet tot uw huidige site',
    'Ref: Content site different description':
      'U bekijkt een inhoud die niet tot uw huidige site behoort. U kunt het niet zien in de lijst met inhoud.',
  },
  ContentList: {
    'Ref: Page title': 'Nieuwsfeed',
    'featured (as in featured content)': 'Uitgelicht',
    'Ref: category - all': 'Allemaal',
    'Ref: category - promotional': 'Promotioneel',
    'Ref: category - featured': 'Uitgelaten',
    'Ref: category - other': 'Ander',
  },
  Widget: {
    'Ref: Carousel title': 'Nieuws en artikelen',
    'Ref: Carousel title promo': 'Top Nieuws',
    'No info yet, come back later': 'Nog geen info, kom later terug 😉',
    'Ref: Content': 'Inhoud',
    'Ref: Widget Title': 'Nieuwsfeed',
    'Ref: MyVillage Carousel title promo': 'Promoties',
  },
};
export default labels;
