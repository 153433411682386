const labels = {
  All: {
    Region: 'Région ou pays',
    'Ref: Geography': 'Veuillez sélectionner votre pays ou votre région',
    'Ref: Country': 'Pays',
    'Ref: Country tooltip': "L'emplacement correspond au pays du site.",
    'Ref: Please select your country': "S'il vous plaît sélectionnez votre pays",
    'First name': 'Prénom',
    'Last name': 'Nom de famille',
    'Ref: Full name': 'Nom et prénom',
    'Mobile number': 'Numéro de portable',
    'Mobile phone': 'Téléphone mobile',
    Email: 'Adresse e-mail',
    Password: 'Mot de passe',
    logout: 'Se déconnecter',
    'Not a new user? Log in': 'Pas un nouvel utilisateur? Connexion',
    'do not register, login': "Ne pas s'inscrire, se connecter",
    'Ref: I agree': "Je suis d'accord",
    Register: "S'inscrire",
    'Ref: My language': 'Ma langue',
    'Ref: Kiosk Fail Title': "Oops! Quelque chose s'est mal passé!",
    'Ref: Kiosk Fail Description':
      "Malheureusement, votre commande n'a pas réussi. Veuillez réessayer ou demander à un membre du personnel de vous aider.",
    'Ref: Kiosk Fail Back': 'retour à la page précédente',
    'Ref: Kiosk Fail Action': 'Effacer la session et commander à nouveau',
    Validate: 'Valider',
    'Ref: error, incorrect credentials':
      "Ces informations d'identification ne correspondent pas à la zone géographique choisie.",
    'Ref: Forgot password?': 'Mot de passe oublié?',
    'Ref: Hello': 'Bonjour {wave_emoji}, veuillez vous connecter pour continuer',
    'Ref: Form title': 'Veuillez vous inscrire pour continuer',
    'Already have an account? Login': 'Vous avez déjà un compte? Connexion',
  },
  AccountPage: {
    'Ref: My Information': 'Mon information',
    'Ref: Settings': 'Réglages',
    'Ref: Email': 'E-mail',
    'Ref: Current site': 'Site actuel',
    'Ref: Choose site': 'Choisissez un site',
    'Ref: Personal interests': 'Intérêts personnels',
    'Ref: Food preferences': 'Préférences alimentaires',
    'Ref: Password': 'Mot de passe',
    'Ref: Page title': 'Compte',
    'Ref: Language': 'Langue',
    'Ref: Communication preferences': 'Préférences de communication',
    'Ref: Terms & conditions': 'Termes et conditions',
    'Ref: Privacy policy': 'Politique de confidentialité',
    'Ref: Delete my account': 'Supprimer mon compte',
    'Ref: Confirm deletion': 'Êtes-vous sûr de vouloir supprimer votre compte?',
    'Ref: Request deletion':
      'Êtes-vous sûr que vous souhaitez demander la suppression de votre compte?',
    'Ref: Deletion description': 'Votre demande sera traitée conformément à votre règlement local.',
    Placeholder: 'Écrivez "Supprimer"',
    'Ref: Delete description': 'Vos données seront supprimées. Cette action est irréversible.',
    delete: 'effacer',
    'Ref: Terms of sale': 'Conditions de vente',
    'Ref: Imprint': 'Imprimer',
    'Ref: Cookie Settings': 'Cookies',
    'Ref: Support': 'Soutien',
    'Ref: Register or login': 'Enregistrer ou se connecter',
    'Ref: Filtering Preferences': 'Préférences de filtrage',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Êtes-vous sûr de supprimer votre compte?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Écrivez "{word}" dans le champ de saisie et confirmez avec le bas ci-dessous. Cette action est irréversible.',
    'Ref: Write "{word}"': 'Écrire "{word}"',
    'Ref: Delete my account': 'Supprimer mon compte',
    'Ref: Words are not matching': 'Les mots ne correspondent pas',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Choisissez votre emplacement pour commencer',
    'Ref: Choose location':
      'Trouvez rapidement un emplacement en activant la localisation, en scannant un code QR ou en recherchant par nom',
    'Ref: next step': 'Allons-y',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Inscription',
    'Ref: identify your location': 'Veuillez identifier votre emplacement.',
    'Ref: option 1 - scan QR': "Option 1 : Scannez le code QR d'enregistrement fourni",
    'Ref: option 2 - enter code': 'Option 2 : saisissez manuellement votre code de localisation',
    'Ref: button to open qr code scanner modal': "J'ai un QR : scannons-le",
    'Ref: button to open site code input modal': 'Sélectionnez le site avec le code',
    'Ref: getting help with site identifier - support desk':
      "Si vous ne savez pas comment procéder, veuillez contacter votre service d'assistance.",
    'Ref: getting help with site identifier - support form':
      'Vous pouvez également nous envoyer une note.',
    'Location code': 'Code de localisation',
    'Ref: location code field placeholder': 'Votre code de localisation ici',
    'Ref: Scanner header': 'Scanner un code QR',
    'Ref: site code input header': 'Ajouter le site avec du code',
    'Ref: not valid code': "Ce code n'est pas valide dans la géographie sélectionnée",
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Inscription',
    'Ref: collected info': 'Validez votre demande',
    'Ref: additional info': 'Vos informations',
    'Confirm password': 'Confirmation de votre nouveau mot de passe',
    company: 'Quelle est votre entreprise ?',
    location: 'Emplacement',
    username: "Nom d'utilisateur",
    register: "S'inscrire",
    'Ref: password strength':
      'Votre mot de passe doit comporter au moins 8 caractères, contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial.',
    'passwords do not match': 'Les mots de passe ne correspondent pas.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Préférences de communication',
    'Ref: Things happen while you are away':
      'Les choses se produisent pendant que vous êtes absent',
    'Ref: Would you like to receive update about offers and promotions?':
      'Souhaitez-vous recevoir des mises à jour sur les offres et les promotions?',
    'Ref: Would you like to hear about content we think you may like?':
      'Aimeriez-vous entendre parler de contenu que nous pensons que vous pourriez aimer?',
    Yes: 'Oui',
    No: 'Non',
    apply: 'appliquer',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Détails du compte' },
  DeletionFailure: {
    'Ref: Body':
      "Une erreur s'est produite et votre demande de suppression du compte n'a pas été soumise. Si cette erreur persiste, veuillez contacter le service support.",
    'Go to account': 'Se rendre compte',
  },
  GenericFailurePage: {
    'Ref: Title': "Quelque chose s'est mal passé",
    'Ref: Body':
      "Désolé, quelque chose s'est mal passé. Si ce problème persiste, veuillez contacter votre support sur site.",
  },
  HomePage: {
    notifications: 'notifications',
    basket: 'Panier',
    'Ref: Tabtitle': 'Accueil',
    'contact us': 'Nous contacter',
    'Ref: You are logged in as a guest user': "Vous êtes connecté en tant qu'utilisateur invité",
    'Ref: Good morning': 'Bonjour',
    'Ref: Good afternoon': 'Bon après-midi',
    'Ref: Good evening before midnight': 'Bonsoir',
    'Ref: Good evening after midnight': 'Bonsoir',
    'Ref: Search': 'Recherche',
    'Ref: products button aria': 'Trouvé {results_form} dans les produits',
    'Ref: menus button aria': 'Trouvé {results_form} dans les menus',
    'Ref: facilities button aria': 'Found {results_form} dans les installations',
    'Ref: result': 'résultat',
    'Ref: results': 'résultats',
    'Ref: Menus': 'Menus',
    'Ref: No results title': 'Oops!',
    'Ref: No results body': 'Aucun résultat correspondant à votre recherche',
    'Ref: Products': 'Des produits',
    'Ref: Facilities': 'Installations',
    'Ref: See more results': 'Voir plus de résultats de recherche',
    'Ref: Accessibility instructions':
      'Pour commencer à utiliser la recherche, vous devez cliquer dessus ou appuyer sur Entrée tout en le faisant concentrer.',
    'Ref: See more': 'Voir plus',
    'Ref: singular form of products': 'produit',
    'Ref: singular form of menus': 'menu',
    'Ref: singular form of facilities': 'facilité',
    'Ref: Search results for': 'Résultats de recherche pour',
  },
  LanguagePage: {
    apply: 'appliquer',
    'My language': 'Ma langue',
    'Select in which language you would like to read our app':
      'Sélectionnez dans quelle langue vous souhaitez lire notre application',
    'Ref: Page title': 'Langue',
  },
  LegalDocPage: {
    Acknowledge: 'Reconnaître',
    'Ref: Legal changed notification title': 'Les «{legal_doc_type}» ont été modifiés',
    'Ref: Legal changed notification content':
      "Veuillez les consulter avant de continuer à utiliser l'application.",
  },
  LegalDocsPage: {
    'Ref: Page title': "Conditions d'utilisations",
    'Read privacy policy': 'Lire la politique de confidentialité',
    'Ref: Acknowledge legal docs': "J'accepte ces termes et conditions.",
    'Ref: Read and understood': "J'ai lu et compris ",
    'Ref: No legal docs available - notice title': 'Bientôt disponible',
    'Ref: No legal docs available - notice body':
      'Désolé, il semble que cette application ne soit pas disponible actuellement dans votre zone géographique.',
    'Ref: The privacy Policy': 'La politique de confidentialité',
    'Ref: Legal changed notification title': 'Les «{legal_doc_type}» ont été modifiés',
    'Ref: Legal changed notification content':
      "Veuillez les consulter avant de continuer à utiliser l'application.",
  },
  LoginPage: {
    'Ref: Page title': 'Bienvenue sur {app name} ',
    'Ref: Page subtitle': 'Connectez-vous pour continuer',
    'Ref: Email field placeholder': 'Votre e-mail',
    'Ref: Email field note': 'Nous ne partagerons jamais votre e-mail avec qui que ce soit.',
    'Ref: Password field title': 'Votre mot de passe',
    register: "S'inscrire",
    'I forgot my password.': 'Mot de passe oublié?',
    'I have trouble logging in': 'Problèmes de connexion?',
    'start registration': "commencer l'enregistrement",
    'Ref: Register': "S'inscrire",
    'Ref: Login': 'Connexion',
    'Ref: Connection issue?': 'Problème de connexion?',
    'Ref: Connection issue message':
      "Si vous avez un problème pour vous inscrire ou vous connecter, veuillez contacter le personnel habituel sur place ou le service d'assistance.",
  },
  WelcomePage: {
    'Ref: Welcome to': 'Bonjour et bienvenue sur {site_name}',
    'Ref: Begin': 'Commencer',
  },
  NotificationsList: {
    'Ref: No notifications': "Vous n'avez pas de notifications pour le moment",
    'Ref: This week': 'Cette semaine',
    'Ref: Earlier': 'Plus tôt',
    'Ref: Page title': 'Notifications',
    'Ref: See more': 'Voir plus',
    'Ref: Mark all read': 'Marquer tout comme lu',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Voir toutes les notifications' },
  ServicesMenuPage: { 'Ref: Page title': 'Services', navigate: 'naviguer' },
  DeletionSuccess: {
    'Ref: Title': 'La suppression de votre compte a été demandée',
    'Ref: Content': 'Nous le gérerons sous peu. Vous pouvez perdre votre accès à tout moment.',
    'Log out': 'Se déconnecter',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Nous allons revenir vers vous',
    'Ref: Body':
      'Nous sommes désolés que vous rencontriez des problèmes. Nous vous contacterons très bientôt.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Problèmes de connexion?',
    'Ref: Email field placeholder': 'Votre adresse email',
    'Ref: Mobile phone field placeholder': 'Numéro de téléphone',
    Company: 'Société',
    'Ref: Company field placeholder': "Nom de l'entreprise",
    'Ref: Company field note':
      "Si vous êtes consultant, il s'agit de l'entreprise pour laquelle vous travaillez actuellement, et non de votre employeur.",
    Location: 'Ville ou emplacement',
    'Ref: Location field placeholder': 'Emplacement de votre lieu de travail',
    'Ref: Location field note':
      "Si en dehors d'une zone habitée, n'hésitez pas à utiliser le nom par lequel la zone est communément désignée.",
    Comment: 'Commentaire',
    'Ref: Comment field placeholder': "Que s'est-il passé ?",
    'Ref: Who is your employer?': 'Qui est votre employeur?',
    'Ref: What village are you staying at?': 'Dans quel ville êtes-vous?',
    'Ref: Comment field note': 'Toute information pouvant nous aider à vous aider !',
    'Ref: Feedback Content - geography': 'Localisation',
    'Ref: Feedback Content - fullName': 'Nom',
    'Ref: Feedback Content - email': 'E-mail',
    'Ref: Feedback Content - phone': 'Téléphoner',
    'Ref: Feedback Content - company': 'Compagnie',
    'Ref: Feedback Content - city': "Nom de la ville / de l'emplacement",
    'Ref: Feedback Content - whatVillage': 'Ville',
    'Ref: Feedback Content - employer': 'Employeur',
    'Ref: Feedback Content - comment': "Qu'est ce qui ne s'est pas bien passé",
  },
  AccountStartPage: {
    'Ref: Header': 'Choisissez votre emplacement pour commencer',
    'Ref: Body':
      'Trouvez rapidement un emplacement en activant la localisation, en scannant un code QR ou en recherchant par nom',
    'Ref: LetsGo button': 'Allons-y',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Inscription' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Sélectionnez le site avec le scanner QR',
    'Ref: Page title': 'Choisissez votre emplacement',
    'Ref: Select site with code': 'Sélectionnez le site avec le code',
    'Ref: Enter site code': 'Entrez le code du site',
    'Ref: Scanner error header': 'Site introuvable',
    'Ref: Not found': "Ce code n'est pas valide dans la zone géographie sélectionnée",
    'Ref: Generic error': "Quelque chose s'est mal passé",
    'All other sites': 'Tous les autres sites',
    'Sites in your country': 'Sites de votre pays',
    'Ref: Unauthorized error': 'Demande non autorisée due au niveau de restriction du site',
    'Ref: Forbidden':
      "Désolé, vous n'avez pas les bonnes autorisations pour accéder à ce site. Pour organiser l'accès, veuillez contacter le centre de service Pilbara au 1800 992 777",
    "Ref: We didn't find the site.": "Nous n'avons pas trouvé le site.",
    'Ref: We recommend some solutions:': 'Nous recommandons certaines solutions:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      "1. Essayez d'ajouter votre site avec le scanner QR ou à l'aide de boutons ci-dessous",
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Connectez-vous et reconnectez-vous en sélectionnant le bon pays',
    'Ref: The currently selected country is:': 'Le pays actuellement sélectionné est:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Inscription',
    'Confirm password': 'Confirmez le mot de passe',
    'Email is not valid': "L'email n'est pas valide",
    'passwords do not match': 'Les mots de passe ne correspondent pas.',
    'Ref: Password strength':
      'Votre mot de passe doit comporter au moins 8 caractères, contenir au moins un numéro, une majuscule, une minuscule et un caractère spécial.',
    'Sign up': "S'inscrire",
    'Continue as guest': "Continuer en tant qu'invité",
    'Already have an account? Login': 'Vous avez déjà un compte? Connexion',
    'Ref: Registration success title': 'Merci de votre inscription',
    'Ref: Registration success content':
      'Votre compte sera créé bientôt. Vous recevrez un e-mail confirmant la création.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Veuillez vous connecter / vous inscrire',
    'Ref: Password strength':
      'Votre mot de passe doit comporter au moins 8 caractères, contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial.',
    Stamps: 'Timbres',
    'Order history': 'Historique des commandes',
    'to earn points': 'pour gagner des points',
    'to see order history': "Pour voir l'historique des commandes",
    'to place an order': 'passer une commande',
    'Sign up': "S'inscrire",
    Login: 'Connexion',
    'Email is not valid': "L'email n'est pas valide",
    'passwords do not match': 'Les mots de passe ne correspondent pas.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Merci',
    'Ref: Body':
      "Vous avez presque fini! Vous recevrez bientôt un e-mail de vérification sur l'adresse fournie. Ouvrez cet e-mail et cliquez sur le lien pour activer votre compte.",
    'Ref: Enter': 'Continuer',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Une erreur est survenue',
    'Ref: Generic Error Body': 'Veuillez réessayer plus tard ou contacter votre aide.',
    'Ref: Missing IndexDb support':
      "Désolé, vous ne pouvez pas accéder à l'application en mode incognito ou sans support IndexDB. Veuillez réessayer avec un autre navigateur.",
    'Ref: Resource not found': "Le contenu auquel vous essayez d'accéder n'existe pas.",
    'Ref: Permission denied - SITE_HIDDEN':
      "Le contenu auquel vous essayez d'accéder appartient à un site privé.",
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      "Le contenu auquel vous essayez d'accéder n'est pas autorisé pour votre compte.",
    'Ref: TabTitle': 'Erreur',
    'Ref: Site link invalid':
      "Ce lien n'est pas valide, sélectionnez un autre site ou contactez le service d'assistance si le problème persiste.",
    'Ref: Access Denied': 'Accès refusé. Autorisations utilisateur insuffisantes.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Oops! {robot_emoji}',
    'Ref: Error title': "Quelque chose s'est mal passé!",
    'Ref: Error body':
      "Essayez de rafraîchir cette page ou contactez le service d'assistance si le problème persiste.",
    'Ref: Error ID:': "ID d'erreur: {errorId}",
    'Ref: Back to home': "De retour à l'accueil",
  },
  Child: {
    'Ref: Route Not Found':
      "Le service que vous recherchez n'est pas disponible sur ce site. Veuillez réessayer plus tard ou contacter votre aide.",
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Félicitations, votre nouveau mot de passe a été enregistré avec succès, vous pouvez désormais accéder à tous nos services à partir de votre compte.',
    Welcome: 'Accueillir',
    'Ref: Welcome message':
      'Vous vous êtes maintenant inscrit avec succès dans la nouvelle application de morsure et pouvez continuer à découvrir et à profiter de tous nos services.',
  },
  PasswordChange: {
    'Change Password': 'Changer le mot de passe',
    Welcome: 'Accueillir',
    'Ref: Password message':
      "Votre mot de passe doit être modifié car il n'est pas suffisamment sécurisé",
  },
  AccountPasswordChange: {
    'Change your password here': 'Changez votre mot de passe ici',
    'Your old password': 'Votre ancien mot de passe',
    'Type in your new password': 'Tapez votre nouveau mot de passe',
    'Re-type in your new password': 'RETOURS dans votre nouveau mot de passe',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'votre mot de passe a été changé',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      "Une erreur s'est produite, donc votre modification n'a pas été enregistrée. Veuillez réessayer.",
  },
  PasswordForgotten: {
    'Ref: Page title': 'réinitialiser le mot de passe',
    'Ref: Provide email':
      "Veuillez fournir l'adresse e-mail utilisée lors de l'inscription sur {app_name}. Un e-mail vous sera envoyé pour réinitialiser votre mot de passe.",
    'Ref: Something happened': 'Quelque chose est arrivé. Veuillez réessayer plus tard.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Nous avons envoyé un nouvel e-mail. Veuillez patienter 30 secondes pour renvoyer à nouveau.',
    'Go to login page': 'Aller à la page de connexion',
    'Resend email': "Ré-envoyer l'email",
    'Ref: Email sent title': 'Un e-mail de réinitialisation de mot de passe vous a été envoyé',
    'Ref: TabTitle': 'réinitialiser le mot de passe',
    'Ref: Email sent msg':
      "Le lien de réinitialisation a été envoyé à votre adresse {email_address}. Veuillez patienter 30 secondes avant d'essayer de renvoyer.",
  },
  ResetPassword: { 'Enter your new password': 'Entrez votre nouveau mot de passe' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Votre mot de passe a été changé avec succès',
    'Go to login page': 'Aller à la page de connexion',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Réinitialiser le mot de passe échec',
    'Ref: Error': "Une erreur s'est produite. Veuillez réessayer",
    'Reset your password': 'réinitialisez votre mot de passe',
    'Go to login page': 'Aller à la page de connexion',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'politique de confidentialité',
    'Ref: Privacy Policy Body':
      "En accédant à cette plate-forme, Sodexo SA et l'entité Sodexo locale traiteront vos données personnelles afin de gérer au moins votre compte et de fournir les services et les offres disponibles via la plate-forme. Si vous souhaitez plus d'informations concernant le traitement de vos données personnelles, qui y accédera et comment exercer les droits que vous avez sur vos données personnelles, veuillez consulter la politique de confidentialité ci-dessous et disponible à tout moment de votre compte.",
    'Ref: Continue': 'Continuer',
    'Ref: Read Privacy Policy': 'Lire la politique de confidentialité',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Se rendre sur le store' },
  SharePage: { 'Ref: Select a site': 'Sélectionnez un site' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Pour afficher la politique de confidentialité, veuillez sélectionner votre pays.',
  },
};
export default labels;
