import classNames from 'classnames';
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { TOOLTIP_VARIANTS } from '@/components/molecules/TooltipWrapper/config';

import styles from './TooltipWrapper.module.css';
const TooltipWrapper = () => {
  return (
    <>
      <ReactTooltip id={TOOLTIP_VARIANTS.DEFAULT} className={styles.tooltip} />
      <ReactTooltip
        id={TOOLTIP_VARIANTS.SIDEBAR_TOOLTIP}
        className={classNames(styles.tooltip, styles.sidebarTooltip)}
      />
    </>
  );
};

export default TooltipWrapper;
