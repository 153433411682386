const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': '서비스 요청 - 헬프데스크',
    'Ref: Forbidden heading': '금지됨',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      '사이트 {siteName}에서 서비스 요청을 관리하고 있습니다.',
    'Ref: Dates': '날짜',
    'Ref: SRL list refresh': '새로 고침 목록',
    'Ref: Export': '내보내다',
    'Ref: Empty/not filled': '빈/채워지지 않습니다',
    'Ref: Name': '이름',
    'Ref: Date': '날짜',
    'Ref: Category': '범주',
    'Ref: User': '사용자',
    'Ref: Location': '위치',
  },
};
export default labels;
