const labels = {
  SiteDetails: {
    'Select site': 'Bu tesisi seçin',
    'Ref: Page title': 'Tesis Detayları',
    'Ref: Skip map': 'Ana içeriğe geç',
    'Ref: Unavailable site': 'Kullanıcınız için tesis önizlemesi kullanılamıyor',
    'Ref: Sorry, no description yet': 'Üzgünüm, henüz açıklamamız yok.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Tesiskoordinatları bulunamadı',
    'Ref: Page title': 'Tesisler',
    'Recently visited': 'Yakın zamanda ziyaret edildi',
    'All other sites': 'Diğer tüm tesisler',
    'Sites in your country': 'Ülkenizdeki siteler',
    'Search results': 'Arama sonuçları',
    'Select site': 'Tesis Seçiniz',
    'Ref: Select site with QR Scanner': 'QR tarayıcı ile site seçin',
    'Ref: Select site with code': "Kodlu Site'yi seçin",
    'Ref: Validate': 'Doğrulama',
    'Ref: Enter site code': 'Tesis kodunu girin',
    'Ref: Scanner error header': 'Tesis bulunamadı',
    'Ref: Generic error': 'Bir şeyler ters gitti',
    'Ref: Change site': 'Tesisi değiştir',
    'Ref: Change site description':
      'Sepette ürün var! Tesisin değiştirilmesi sepetin temizlenmesiyle sonuçlanacaktır.',
    'Ref: Cancel': 'İptal',
    'Ref: Not found': 'Bu QR kodu tanınmadı',
    'Ref: Unauthorized error': 'Tesis kısıtlama seviyesi nedeniyle yetkisiz talep',
    'Ref: Forbidden':
      'Üzgünüm, bu siteye erişmek için doğru izinleriniz yok. Erişim ayarlamak için lütfen 1800 992 777 numaralı telefondan Pilbara Servis Merkezi ile iletişime geçin.',
    "Ref: We didn't find the site.": 'Siteyi bulamadık.',
    'Ref: We recommend some solutions:': 'Bazı çözümler öneriyoruz:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Sitenizi QR tarayıcı ile veya aşağıdaki düğmeler yardımıyla eklemeye çalışın',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Doğru ülkeyi seçerek oturum açın ve tekrar giriş yapın',
    'Ref: The currently selected country is:': 'Şu anda seçilen ülke:',
  },
  SwitchSite: { 'Not on {site name}?': 'Değil mi  {site name}?' },
  SiteTile: { Details: 'Detaylar', Select: 'Seçiniz' },
};
export default labels;
