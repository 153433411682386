import classNames from 'classnames';
import { FC } from 'react';

import { DefaultComponentProps } from '../../../../types';

import styles from './Body.module.css';

const WidgetBody: FC<DefaultComponentProps> = ({
  children,
  className,
  'data-testid': dataTestId = 'widget-body',
}) => {
  return (
    <div className={classNames(styles.body, className)} data-testid={dataTestId}>
      {children}
    </div>
  );
};
WidgetBody.displayName = 'Widget.Body';

export default WidgetBody;
