import { shouldUseMockData } from '../../../config';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { parseJwt } from '../../../helpers/parse';

export const getTokenExp = (token: string): number => {
  //returns the expiry timestamp in milliseconds
  if (shouldUseMockData) return Date.now() + 3600000;
  const accessTokenPayload = parseJwt(token);
  return accessTokenPayload.exp * 1000;
};

export const saveTokens = (json: { access_token: string; refresh_token: string }) => {
  if (json.access_token && json.access_token.length <= 4000 && getTokenExp(json.access_token)) {
    // console.log('saving tokens? --> yes')
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, json.access_token);
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.ACCESS_TOKEN_VALID_UNTIL,
      getTokenExp(json.access_token).toString()
    );

    if (json.refresh_token)
      localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, json.refresh_token);
    else localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  } else {
    //sth went wrong, remove everything to avoid getting the user stuck
    removeTokens();
  }
};

export const removeTokens = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_VALID_UNTIL);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.SHARED_SITE_CODE);
};

export const getTokenPayload = () => {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const accessTokenPayload = parseJwt(accessToken!);
  return {
    roles: accessTokenPayload.role,
    contractId: accessTokenPayload.ccid,
    registrationSiteId: accessTokenPayload.rsid,
    userName: accessTokenPayload.sub,
    tokenValidTill: accessTokenPayload.exp * 1000,
    isSSO: accessTokenPayload.sso,
  };
};

enum USER_ROLES {
  HELPDESK = 'Consumer App User - Helpdesk',
}

const userHasRole = (role: USER_ROLES): boolean => {
  const { roles } = getTokenPayload();

  if (Array.isArray(roles)) {
    return (
      roles?.find((r: string) => r.toLowerCase().includes(role.toString().toLowerCase())) !==
      undefined
    );
  }

  return roles?.toLowerCase() === role.toLowerCase();
};

export const isHelpdeskUser = () => userHasRole(USER_ROLES.HELPDESK);
