import classNames from 'classnames';
import { FC } from 'react';

import { MagnifierIcon } from '../../../assets/icons';
import useToggle from '../../../helpers/hooks/useToggle';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Modal from '../Modal';

import { PictureProps } from './Pictures.type';

import styles from './Picture.module.css';

const Picture: FC<PictureProps> = ({
  src,
  title,
  width,
  height,
  className,
  'data-cy': dataCy = 'picture',
  'data-testid': dataTestId = 'picture',
}) => {
  const { label } = useCustomTranslation();
  const { state: isOpen, toggleOn: onRequestOpen, toggleOff: onRequestClose } = useToggle(false);
  return (
    <>
      <button
        aria-label={title}
        className={classNames(styles.wrapper, className)}
        style={{ width, height }}
        onClick={onRequestOpen}
        data-cy={dataCy}
        data-testid={dataTestId}
      >
        <img
          src={src}
          draggable={false}
          alt={title}
          className={styles.image}
          style={{ width, height }}
        />
        <span className={styles.zoomBtn}>
          <div className={styles.zoomBtnContentWrapper}>
            <MagnifierIcon /> <span className={styles.zoomBtnText}>{label('Ref: Zoom')}</span>
          </div>
          <div className={styles.legend} />
        </span>
      </button>
      <Modal
        aria-label={title}
        isOpen={isOpen}
        title={title}
        onDismiss={onRequestClose}
        contentClassName={styles.modalContent}
        data-testid="picture-modal"
      >
        <img src={src} draggable={false} alt={title} />
      </Modal>
    </>
  );
};

export default Picture;
