const labels = {
  All: {
    Region: 'Régió vagy ország',
    'Ref: Geography': 'Kérjük, válassza ki országát vagy régióját',
    'Ref: Country': 'Ország',
    'Ref: Country tooltip': 'A hely a webhely országának felel meg.',
    'Ref: Please select your country': 'Kérem válassza ki az országát',
    'First name': 'Keresztnév',
    'Last name': 'Vezetéknév',
    'Ref: Full name': 'Teljes név',
    'Mobile number': 'Mobil szám',
    'Mobile phone': 'Mobiltelefon',
    Email: 'Email cím',
    Password: 'Jelszó',
    logout: 'Kijelentkezés',
    'Not a new user? Log in': 'Nem új felhasználó? Belépés',
    'do not register, login': 'Nem új felhasználó? Belépés',
    'Ref: I agree': 'Egyetértek',
    Register: 'Regisztráció',
    'Ref: My language': 'A nyelvem',
    'Ref: Kiosk Fail Title': 'Hoppá! Valami elromlott!',
    'Ref: Kiosk Fail Description':
      'Sajnos a megrendelése nem volt sikeres. Kérjük, próbálkozzon újra, vagy kérje meg egy alkalmazottat, hogy segítsen Önnek.',
    'Ref: Kiosk Fail Back': 'Vissza az előző oldalra',
    'Ref: Kiosk Fail Action': 'Törölje a munkamenetet, és rendeljen újra',
    Validate: 'Érvényesít',
    'Ref: error, incorrect credentials':
      'Ezek a hitelesítő adatok nem felelnek meg a kiválasztott földrajzban.',
    'Ref: Forgot password?': 'Elfelejtette a jelszót?',
    'Ref: Hello': 'Helló {wave_emoji}, kérjük, jelentkezzen be a Folytatáshoz',
    'Ref: Form title': 'Kérjük, iratkozzon fel a folytatáshoz',
    'Already have an account? Login': 'Már van fiókja? Belépés',
  },
  AccountPage: {
    'Ref: My Information': 'Az én információm',
    'Ref: Settings': 'Beállítások',
    'Ref: Email': 'Email',
    'Ref: Current site': 'Aktuális hely',
    'Ref: Choose site': 'Válasszon egy webhelyet',
    'Ref: Personal interests': 'Személyes érdeklődés',
    'Ref: Food preferences': 'Élelmiszer -preferenciák',
    'Ref: Password': 'Jelszó',
    'Ref: Page title': 'Beszámoló',
    'Ref: Language': 'Nyelv',
    'Ref: Communication preferences': 'Kommunikációs preferenciák',
    'Ref: Terms & conditions': 'Felhasználási feltételek',
    'Ref: Privacy policy': 'Adatvédelmi irányelvek',
    'Ref: Delete my account': 'A fiókom törlése',
    'Ref: Confirm deletion': 'Biztosan törölni akarja a fiókját?',
    'Ref: Request deletion': 'Biztosan szeretné kérni a fiók törlését?',
    'Ref: Deletion description': 'Kérését a helyi rendelet szerint kezeljük.',
    Placeholder: 'Írja be a "Törlés"',
    'Ref: Delete description': 'Adatait töröljük. Ez a művelet visszafordíthatatlan.',
    delete: 'töröl',
    'Ref: Terms of sale': 'Értékesítési feltételek',
    'Ref: Imprint': 'Lenyomat',
    'Ref: Cookie Settings': 'Cookie -beállítások',
    'Ref: Support': 'Támogatás',
    'Ref: Register or login': 'Regisztráljon vagy jelentkezzen be',
    'Ref: Filtering Preferences': 'Preferenciák szűrési preferenciái',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Biztosan törli a fiókját?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Írja be a "{word}" -t a bemeneti mezőbe, és erősítse meg az alsó alsó részét. Ez a művelet visszafordíthatatlan.',
    'Ref: Write "{word}"': 'Írja be a "{word}" -t',
    'Ref: Delete my account': 'A fiókom törlése',
    'Ref: Words are not matching': 'A szavak nem egyeznek',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Válassza ki a helyét a kezdéshez',
    'Ref: Choose location':
      'Keressen gyorsan egy helyet a lokalizáció aktiválásával, a QR -kód beolvasásával vagy a név szerinti kereséssel',
    'Ref: next step': 'Gyerünk',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Regisztráljon',
    'Ref: identify your location': 'Kérjük, azonosítsa a helyét.',
    'Ref: option 1 - scan QR': '1. lehetőség: Szkennelje be a regisztrációs QR-kódot',
    'Ref: option 2 - enter code': '2. lehetőség: Manuálisan adja meg a helykódot',
    'Ref: button to open qr code scanner modal': 'Van egy QR: Vizsgáljuk meg',
    'Ref: button to open site code input modal': 'Válassza ki a webhelyet a kóddal',
    'Ref: getting help with site identifier - support desk':
      'Ha nem világos, hogyan kell eljárni, forduljon a támogató asztalához.',
    'Ref: getting help with site identifier - support form': 'Küldhet nekünk egy jegyzetet is.',
    'Location code': 'Helyi kód',
    'Ref: location code field placeholder': 'Itt helye kódja',
    'Ref: Scanner header': 'QR-kód szkennelése',
    'Ref: site code input header': 'Adjon hozzá webhelyet kóddal',
    'Ref: not valid code': 'Ez a kód nem érvényes a kiválasztott földrajzban',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Bejegyzés',
    'Ref: collected info': 'Összefoglalás',
    'Ref: additional info': 'Az Ön információi',
    'Confirm password': '*Jelszó megerősítése',
    company: 'Ki az Ön munkáltatója?',
    location: 'Helyszin',
    username: 'felhasználónév',
    register: 'Regisztráljon',
    'Ref: password strength':
      'A jelszónak legalább 8 karakter hosszúnak kell lennie es tartalmaznia kell legalább egy számot, egy nagybetűt, egy kisbetűt és egy speciális karaktert.',
    'passwords do not match': 'A jelszavak nem egyeznek.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Kommunikációs preferenciák',
    'Ref: Things happen while you are away': 'A dolgok történnek, amíg távol vagy',
    'Ref: Would you like to receive update about offers and promotions?':
      'Szeretne frissítéseket kapni az ajánlatokról és promóciókról?',
    'Ref: Would you like to hear about content we think you may like?':
      'Szeretné hallani a tartalmat, amelyről azt gondoljuk, hogy tetszik?',
    Yes: 'Igen',
    No: 'Nem',
    apply: 'alkalmaz',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Számlaadatok' },
  DeletionFailure: {
    'Ref: Body':
      'Hiba történt, és a fiók törlésére vonatkozó kérését nem nyújtották be. Ha ez a hiba továbbra is fennáll, kérjük, vegye fel a kapcsolatot az ügyfélszolgálatával.',
    'Go to account': 'Elindul a számlára',
  },
  GenericFailurePage: {
    'Ref: Title': 'Valami elromlott',
    'Ref: Body':
      'Elnézést, valami nem ment jól. Ha ez a probléma továbbra is fennáll, forduljon a helyszíni támogatáshoz.',
  },
  HomePage: {
    notifications: 'értesítések',
    basket: 'Kosár',
    'Ref: Tabtitle': 'Otthon',
    'contact us': 'Lépjen kapcsolatba velünk',
    'Ref: You are logged in as a guest user': 'Jelentkezzen be vendégfelhasználóként',
    'Ref: Good morning': 'Jó reggelt kívánok',
    'Ref: Good afternoon': 'Jó napot',
    'Ref: Good evening before midnight': 'Jó estét',
    'Ref: Good evening after midnight': 'Jó estét',
    'Ref: Search': 'Keresés',
    'Ref: products button aria': 'Talált {results_form} a termékeken belül',
    'Ref: menus button aria': 'Talált {results_form} a menükben',
    'Ref: facilities button aria': 'Talált {result_form} a létesítményekben',
    'Ref: result': 'eredmény',
    'Ref: results': 'eredmények',
    'Ref: Menus': 'Menük',
    'Ref: No results title': 'Hoppá!',
    'Ref: No results body': 'Nincs olyan eredmény, amely megfelel a keresésnek',
    'Ref: Products': 'Termékek',
    'Ref: Facilities': 'Felszerelés',
    'Ref: See more results': 'Lásd még a keresési eredményeket',
    'Ref: Accessibility instructions':
      'A keresés használatához kattintson rá, vagy nyomja meg az Enter billentyűt, miközben koncentrált.',
    'Ref: See more': 'Többet látni',
    'Ref: singular form of products': 'termék',
    'Ref: singular form of menus': 'menü',
    'Ref: singular form of facilities': 'létesítmény',
    'Ref: Search results for': 'Keresés eredménye',
  },
  LanguagePage: {
    apply: 'alkalmaz',
    'My language': 'A nyelvem',
    'Select in which language you would like to read our app':
      'Válassza ki, melyik nyelven szeretné olvasni az alkalmazásunkat',
    'Ref: Page title': 'Nyelv',
  },
  LegalDocPage: {
    Acknowledge: 'elismerni',
    'Ref: Legal changed notification title': 'A „{legal_doc_type}” módosult',
    'Ref: Legal changed notification content':
      'Kérjük, olvassa el őket, mielőtt folytatná az alkalmazás használatát.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Felhasználási feltételek',
    'Read privacy policy': 'Olvassa el az adatvédelmi irányelvet',
    'Ref: Acknowledge legal docs': 'Elfogadom ezeket a feltételeket.',
    'Ref: Read and understood': 'Olvastam és megértettem ',
    'Ref: No legal docs available - notice title': 'Hamarosan',
    'Ref: No legal docs available - notice body':
      'Sajnáljuk, úgy tűnik, hogy ez az alkalmazás jelenleg nem érhető el a földrajzában.',
    'Ref: The privacy Policy': 'az adatvédelmi irányelv',
    'Ref: Legal changed notification title': 'A „{legal_doc_type}” módosult',
    'Ref: Legal changed notification content':
      'Kérjük, olvassa el őket, mielőtt folytatná az alkalmazás használatát.',
  },
  LoginPage: {
    'Ref: Page title': 'Üdvözöljük {app name} ',
    'Ref: Page subtitle': 'Jelentkezzen be a folytatáshoz',
    'Ref: Email field placeholder': 'Az email címed',
    'Ref: Email field note': 'Soha nem osztjuk meg az e-maileket senkivel.',
    'Ref: Password field title': 'A jelszavad',
    register: 'Regisztráljon',
    'I forgot my password.': 'Elfelejtette a jelszót?',
    'I have trouble logging in': 'Problémája támadt a belépéssel?',
    'start registration': 'Regisztráció kezdete',
    'Ref: Register': 'Regisztráció',
    'Ref: Login': 'Belépés',
    'Ref: Connection issue?': 'Kapcsolatkibocsátás?',
    'Ref: Connection issue message':
      'Ha bármilyen problémája van a regisztrációhoz vagy a bejelentkezéshez, kérjük, vegye fel a kapcsolatot a szokásos helyszíni személyzettel vagy az ügyfélszolgálathoz.',
  },
  WelcomePage: {
    'Ref: Welcome to': 'Helló, és üdvözöljük a {site_name} oldalon',
    'Ref: Begin': 'Kezdődik',
  },
  NotificationsList: {
    'Ref: No notifications': 'Jelenleg nincs értesítése',
    'Ref: This week': 'Ezen a héten',
    'Ref: Earlier': 'Korábban',
    'Ref: Page title': 'Értesítések',
    'Ref: See more': 'Többet látni',
    'Ref: Mark all read': 'Jelölje meg az összes olvasást',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Lásd az összes értesítést' },
  ServicesMenuPage: { 'Ref: Page title': 'Szolgáltatások', navigate: 'hajózik' },
  DeletionSuccess: {
    'Ref: Title': 'A fiók törlését kértük',
    'Ref: Content': 'Rövidesen kezeljük. Bármikor elveszítheti hozzáférését.',
    'Log out': 'Kijelentkezés',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Felvesszük Önnel a kapcsolatot',
    'Ref: Body': 'Sajnáljuk, hogy problémái vannak. Hamarosan kapcsolatba lépünk Önnel.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Problémája támadt a belépéssel?',
    'Ref: Email field placeholder': 'Az Ön e-mail címe',
    'Ref: Mobile phone field placeholder': 'Mobil szám',
    Company: 'Vállalat',
    'Ref: Company field placeholder': 'Cégnév',
    'Ref: Company field note':
      'Ha tanácsadó vagy, ez a vállalat, amelyre jelenleg dolgozik, nem a munkáltatója.',
    Location: 'Város vagy hely',
    'Ref: Location field placeholder': 'A munkahely helye',
    'Ref: Location field note':
      'Ha egy lakott területen kívül van, szabadon használhatja azt a nevet, amellyel a területet általában említik.',
    Comment: 'Megjegyzés',
    'Ref: Comment field placeholder': 'Mi romlott el?',
    'Ref: Who is your employer?': 'Ki a munkáltatója?',
    'Ref: What village are you staying at?': 'Melyik faluban maradsz?',
    'Ref: Comment field note': 'Minden olyan információ, amely segíthet nekünk segíteni!',
    'Ref: Feedback Content - geography': 'Földrajz',
    'Ref: Feedback Content - fullName': 'Név',
    'Ref: Feedback Content - email': 'Email',
    'Ref: Feedback Content - phone': 'Telefon',
    'Ref: Feedback Content - company': 'Vállalat',
    'Ref: Feedback Content - city': 'Város / hely neve',
    'Ref: Feedback Content - whatVillage': 'Falu',
    'Ref: Feedback Content - employer': 'Munkáltató',
    'Ref: Feedback Content - comment': 'Mi romlott el',
  },
  AccountStartPage: {
    'Ref: Header': 'Válassza ki a helyét a kezdéshez',
    'Ref: Body':
      'Keressen gyorsan egy helyet a lokalizáció aktiválásával, a QR -kód beolvasásával vagy a név szerinti kereséssel',
    'Ref: LetsGo button': 'Gyerünk',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Bejegyzés' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Válassza ki a webhelyet a QR szkennerrel',
    'Ref: Page title': 'Válassza ki a tartózkodási helyét',
    'Ref: Select site with code': 'Válassza ki a webhelyet a kóddal',
    'Ref: Enter site code': 'Írja be a webhely kódját',
    'Ref: Scanner error header': 'A webhely nem található',
    'Ref: Not found': 'Ez a kód nem érvényes a kiválasztott földrajzban',
    'Ref: Generic error': 'Valami elromlott',
    'All other sites': 'Minden más webhely',
    'Sites in your country': 'Webhelyek az országodban',
    'Ref: Unauthorized error': 'Jogosulatlan kérés a helyszín korlátozási szintje miatt',
    'Ref: Forbidden':
      'Sajnálom, nincs megfelelő engedélye a webhely eléréséhez. A hozzáférés megszervezéséhez kérjük, vegye fel a kapcsolatot a Pilbara Szolgáltatási Központtal az 1800 992 777 telefonszámon',
    "Ref: We didn't find the site.": 'Nem találtuk meg az oldalt.',
    'Ref: We recommend some solutions:': 'Javasolunk néhány megoldást:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Próbálja meg hozzáadni webhelyét QR szkennerrel vagy az alábbi gombok segítségével',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Jelentkezzen ki és jelentkezzen be a megfelelő ország kiválasztásával',
    'Ref: The currently selected country is:': 'Az jelenleg kiválasztott ország:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Bejegyzés',
    'Confirm password': 'Jelszó megerősítése',
    'Email is not valid': 'az e-mail nem érvényes',
    'passwords do not match': 'A jelszavak nem egyeznek.',
    'Ref: Password strength':
      'A jelszavának legalább 8 karakter hosszúnak kell lennie, legalább egy számot, egy nagybetűt, egy kisbetűt és egy speciális karaktert tartalmaznia kell.',
    'Sign up': 'Regisztrálj',
    'Continue as guest': 'Folytatás vendégként',
    'Already have an account? Login': 'Már van fiókja? Belépés',
    'Ref: Registration success title': 'Köszönjük a regisztrációt',
    'Ref: Registration success content':
      'Fiókját hamarosan létrehozzák. Kap egy e -mailt, amely megerősíti a létrehozást.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Kérjük, jelentkezzen be/iratkozzon fel',
    'Ref: Password strength':
      'A jelszónak legalább 8 karakter hosszúnak kell lennie es tartalmaznia kell legalább egy számot, egy nagybetűt, egy kisbetűt és egy speciális karaktert.',
    Stamps: 'Bélyeg',
    'Order history': 'Megrendelés előzményei',
    'to earn points': 'hogy pontokat szerezzen',
    'to see order history': 'A rendelés előzményeinek megtekintéséhez',
    'to place an order': 'rendelést leadni',
    'Sign up': 'Regisztrálj',
    Login: 'Belépés',
    'Email is not valid': 'az e-mail nem érvényes',
    'passwords do not match': 'A jelszavak nem egyeznek.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Köszönöm',
    'Ref: Body':
      'Már majdnem kész! Hamarosan megkap egy ellenőrző e -mailt a megadott címen. Nyissa meg ezt az e -mailt, és kattintson a linkre a fiók aktiválásához.',
    'Ref: Enter': 'Folytatni',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Hiba történt',
    'Ref: Generic Error Body':
      'Kérjük, próbálkozzon újra később, vagy vegye fel a kapcsolatot az ügyfélszolgálatával.',
    'Ref: Missing IndexDb support':
      'Sajnáljuk, hogy nem férhet hozzá az alkalmazás incognito módban vagy indexdb támogatás nélkül. Kérjük, próbálkozzon újra egy másik böngészővel.',
    'Ref: Resource not found': 'Nem létezik olyan tartalom, amelyhez hozzáférni próbál.',
    'Ref: Permission denied - SITE_HIDDEN':
      'A hozzáférésű tartalom egy privát webhelyhez tartozik.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'A hozzáférésű tartalom nem engedélyezett a fiókjához.',
    'Ref: TabTitle': 'Hiba',
    'Ref: Site link invalid':
      'Ez a link érvénytelen, válasszon egy másik webhelyet, vagy vegye fel a kapcsolatot a ügyfélszolgálatl, ha a probléma továbbra is fennáll.',
    'Ref: Access Denied': 'Hozzáférés megtagadva. Nincs elegendő felhasználói engedély.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Hoppá! {robot_emoji}',
    'Ref: Error title': 'Valami elromlott!',
    'Ref: Error body':
      'Próbálja meg frissíteni ezt az oldalt, vagy vegye fel a kapcsolatot a ügyfélszolgálat, ha a probléma továbbra is fennáll.',
    'Ref: Error ID:': 'Hiba azonosítója: {errorId}',
    'Ref: Back to home': 'Vissza a főoldalra',
  },
  Child: {
    'Ref: Route Not Found':
      'A keresett szolgáltatás nem érhető el ezen a webhelyen. Kérjük, próbálkozzon újra később, vagy vegye fel a kapcsolatot az ügyfélszolgálatával.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Gratulálunk, az új jelszavát sikeresen mentett, most minden szolgáltatásunkhoz hozzáférhet a fiókjáról.',
    Welcome: 'Üdvözöljük',
    'Ref: Welcome message':
      'Most sikeresen regisztrált az új harapás alkalmazásba, és továbbra is felfedezheti és élvezheti az összes szolgáltatásunkat.',
  },
  PasswordChange: {
    'Change Password': 'Jelszó módosítása',
    Welcome: 'Üdvözöljük',
    'Ref: Password message': 'Jelszavát meg kell változtatni, mert nem elég biztonságos',
  },
  AccountPasswordChange: {
    'Change your password here': 'Változtassa meg a jelszavát itt',
    'Your old password': 'A régi jelszavad',
    'Type in your new password': 'Írja be az új jelszavát',
    'Re-type in your new password': 'Type az új jelszavában',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'A jelszavad módosítva lett' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Hiba történt, ezért a változását nem mentették meg. Kérlek próbáld újra.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Jelszó visszaállítása',
    'Ref: Provide email':
      'Kérjük, adja meg az e -mail címet, amikor regisztrálja a {app_name} -et. E -mailt küldünk Önnek a jelszó visszaállításához.',
    'Ref: Something happened': 'Valami történt. Kérlek, próbáld újra később.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Küldtünk egy új e -mailt. Kérjük, várjon 30 másodpercet, hogy újra újrainduljon.',
    'Go to login page': 'Lépjen a Bejelentkezés oldalra',
    'Resend email': 'E-mail újraküldése',
    'Ref: Email sent title': 'Jelszó -visszaállítás e -mailt küldtek Önnek',
    'Ref: TabTitle': 'Jelszó visszaállítása',
    'Ref: Email sent msg':
      'A reset linket elküldtük a {email_address} címre. Kérjük, várjon 30 másodpercet, mielőtt megpróbálja újra elhelyezni.',
  },
  ResetPassword: { 'Enter your new password': 'Írja be új jelszavát' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'A jelszavát sikeresen megváltozott',
    'Go to login page': 'Lépjen a Bejelentkezés oldalra',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Jelszó -visszaállítás sikertelen',
    'Ref: Error': 'Hiba történt, kérjük, próbálkozzon újra',
    'Reset your password': 'Állítsd vissza a jelszavad',
    'Go to login page': 'Lépjen a Bejelentkezés oldalra',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Adatvédelmi irányelvek',
    'Ref: Privacy Policy Body':
      'A platform elérésével a Sodexo SA és a helyi Sodexo entitás feldolgozza az Ön személyes adatait annak érdekében, hogy legalább kezelje fiókját, és a platformon keresztül rendelkezésre álló szolgáltatásokat és ajánlatokat nyújtsa. Ha további információt szeretne az Ön személyes adatainak feldolgozásáról, ki férhet hozzá, és hogyan gyakorolja a személyes adatainál meglévő jogokat, kérjük, olvassa el az alábbi adatvédelmi irányelvet, és bármikor elérhető a fiókjában.',
    'Ref: Continue': 'Folytatni',
    'Ref: Read Privacy Policy': 'Olvassa el az adatvédelmi irányelveket',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Elindul' },
  SharePage: { 'Ref: Select a site': 'Válasszon egy webhelyet' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Az adatvédelmi irányelvek megtekintéséhez válassza ki az országát.',
  },
};
export default labels;
