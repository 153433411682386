import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { ImageDefaultNoFoodImg } from '../../../assets/images';
import Picture from '../Picture';

import { ContentDetailsHeaderProps } from './ContentDetailsHeader.types';

import styles from './ContentDetailsHeader.module.css';

const ContentDetailsHeader: FC<ContentDetailsHeaderProps> = ({
  children,
  className,
  imageNode,
  imageSrc,
  imageAlt,
  'data-testid': dataTestId = 'content-header',
}) => {
  let headerImage: ReactNode;
  const hasResponsiveImg = imageSrc !== undefined;

  if (imageNode) {
    headerImage = imageNode;
  } else if (imageSrc) {
    headerImage = <Picture src={imageSrc} title={imageAlt} className={styles.image} />;
  } else {
    headerImage = <ImageDefaultNoFoodImg className={classNames('br-md', styles.image)} />;
  }

  return (
    <header
      className={classNames(
        { [styles.header]: !hasResponsiveImg, [styles.headerResponsive]: hasResponsiveImg },
        className
      )}
      data-testid={dataTestId}
    >
      <div className={styles.imageWrapper}>{headerImage}</div>
      <div className={styles.content}>{children}</div>
    </header>
  );
};
ContentDetailsHeader.displayName = 'ContentDetailsHeader';

export default ContentDetailsHeader;
