import { useSelector } from 'react-redux';

import { languages } from '../config';
import { SharedState } from '../types/State.types';

const useLanguage = () => {
  const languageState = useSelector((state: { Shared: SharedState }) => state.Shared?.language);

  const currentLanguage = languages.find((el) => el.code === languageState.currentLanguageCode);

  return { currentLanguage, ...languageState };
};

export default useLanguage;
