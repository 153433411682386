const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Serviceaanvraag - Helpdesk',
    'Ref: Forbidden heading': 'Verboden',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'U beheert servicevzoeken op de site {siteName}.',
    'Ref: Dates': 'Datums',
    'Ref: SRL list refresh': 'Ververs lijst',
    'Ref: Export': 'exporteren',
    'Ref: Empty/not filled': 'Leeg / niet gevuld',
    'Ref: Name': 'Naam',
    'Ref: Date': 'Datum',
    'Ref: Category': 'Categorie',
    'Ref: User': 'Gebruiker',
    'Ref: Location': 'Plaats',
  },
};
export default labels;
