const labels = {
  All: {
    Region: '지역 또는 국가',
    'Ref: Geography': '국가 또는 지역을 선택하십시오',
    'Ref: Country': '국가',
    'Ref: Country tooltip': '위치는 사이트 국가에 해당합니다.',
    'Ref: Please select your country': '국가를 선택하십시오',
    'First name': '이름',
    'Last name': '성',
    'Ref: Full name': '전체 이름',
    'Mobile number': '휴대폰 번호',
    'Mobile phone': '휴대 전화',
    Email: '이메일 주소',
    Password: '비밀번호',
    logout: '로그 아웃',
    'Not a new user? Log in': '새로운 사용자가 아닌가? 로그인',
    'do not register, login': '새로운 사용자가 아닌가? 로그인',
    'Ref: I agree': '동의한다',
    Register: '등록하다',
    'Ref: My language': '내 언어',
    'Ref: Kiosk Fail Title': '죄송합니다! 뭔가 잘못되었습니다!',
    'Ref: Kiosk Fail Description':
      '불행히도, 당신의 주문은 성공하지 못했습니다. 다시 시도하거나 직원에게 도움을 요청하십시오.',
    'Ref: Kiosk Fail Back': '이전 페이지로 돌아갑니다',
    'Ref: Kiosk Fail Action': '세션을 지우고 다시 주문하십시오',
    Validate: '확인',
    'Ref: error, incorrect credentials': '이러한 자격 증명은 선택한 지리에서 일치하지 않습니다.',
    'Ref: Forgot password?': '비밀번호를 잊으 셨나요?',
    'Ref: Hello': '안녕하세요 {wave_emoji}, 계속하려면 로그인하십시오',
    'Ref: Form title': '계속해서 가입하십시오',
    'Already have an account? Login': '이미 계정이 있습니까? 로그인',
  },
  AccountPage: {
    'Ref: My Information': '내 정보',
    'Ref: Settings': '설정',
    'Ref: Email': '이메일',
    'Ref: Current site': '현재 사이트',
    'Ref: Choose site': '사이트를 선택하십시오',
    'Ref: Personal interests': '개인 관심사들',
    'Ref: Food preferences': '음식 선호도',
    'Ref: Password': '비밀번호',
    'Ref: Page title': '계정',
    'Ref: Language': '언어',
    'Ref: Communication preferences': '커뮤니케이션 선호도',
    'Ref: Terms & conditions': '이용 약관',
    'Ref: Privacy policy': '개인 정보 정책',
    'Ref: Delete my account': '계정을 삭제하다',
    'Ref: Confirm deletion': '계정을 삭제 하시겠습니까?',
    'Ref: Request deletion': '계정 삭제를 요청 하시겠습니까?',
    'Ref: Deletion description': '귀하의 요청은 지역 규정에 따라 처리됩니다.',
    Placeholder: '"삭제"쓰기',
    'Ref: Delete description': '데이터가 삭제됩니다. 이 조치는 돌이킬 수 없습니다.',
    delete: '삭제',
    'Ref: Terms of sale': '판매 조건',
    'Ref: Imprint': '날인',
    'Ref: Cookie Settings': '쿠키 설정',
    'Ref: Support': '지원하다',
    'Ref: Register or login': '등록 또는 로그인',
    'Ref: Filtering Preferences': '필터링 기본 설정',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': '계정을 삭제 하시겠습니까?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      '입력 필드에 "{word}"를 작성하고 아래 하단으로 확인하십시오. 이 조치는 돌이킬 수 없습니다.',
    'Ref: Write "{word}"': '"{word}"를 씁니다.',
    'Ref: Delete my account': '계정을 삭제하다',
    'Ref: Words are not matching': '단어가 일치하지 않습니다',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': '처음으로 위치를 선택했습니다',
    'Ref: Choose location':
      '현지화를 활성화하거나 QR 코드를 스캔하거나 이름으로 검색하여 위치를 신속하게 찾으십시오.',
    'Ref: next step': '갑시다',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Kirjaudu',
    'Ref: identify your location': '귀하의 위치를 ​​확인하십시오.',
    'Ref: option 1 - scan QR': '옵션 1 : 등록 QR 코드를 스캔합니다',
    'Ref: option 2 - enter code': '옵션 2 : 수동으로 위치 코드를 입력하십시오',
    'Ref: button to open qr code scanner modal': '나는 QR을 가지고있다 : 스캔하자',
    'Ref: button to open site code input modal': '코드로 사이트를 선택하십시오',
    'Ref: getting help with site identifier - support desk':
      '진행 방법을 지우지 않으면 지원 책상에 문의하십시오.',
    'Ref: getting help with site identifier - support form':
      '당신은 또한 메모를 보낼 수도 있습니다.',
    'Location code': '위치 코드',
    'Ref: location code field placeholder': '귀하의 위치 코드는 여기입니다',
    'Ref: Scanner header': 'QR-koodin skannaaminen',
    'Ref: site code input header': '코드로 사이트를 추가하십시오',
    'Ref: not valid code': '이 코드는 선택한 지리에서 유효하지 않습니다',
  },
  AccountRegistrationForm: {
    'Ref: Page title': '등록',
    'Ref: collected info': 'Yhteenveto',
    'Ref: additional info': '당신의 정보',
    'Confirm password': '*Vahvista salasana',
    company: 'Kuka on työnantajasi?',
    location: 'Sijainti',
    username: '사용자 이름',
    register: 'Kirjaudu',
    'Ref: password strength':
      'Salasanan on oltava vähintään 8 merkkiä pitkä, vähintään yksi numero, yksi iso, yksi pieni ja yksi erikoismerkki.',
    'passwords do not match': 'Salasanat eivät täsmää.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': '커뮤니케이션 선호도',
    'Ref: Things happen while you are away': '당신이 멀리있는 동안 일이 일어납니다',
    'Ref: Would you like to receive update about offers and promotions?':
      '제안 및 프로모션에 대한 업데이트를 받으시겠습니까?',
    'Ref: Would you like to hear about content we think you may like?':
      '우리가 좋아하는 콘텐츠에 대해 듣고 싶습니까?',
    Yes: '예',
    No: '아니',
    apply: '적용하다',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': '계정 세부 사항' },
  DeletionFailure: {
    'Ref: Body':
      '오류가 발생했으며 계정 삭제 요청이 제출되지 않았습니다. 이 오류가 지속되면 안내 데스크에 문의하십시오.',
    'Go to account': '계정으로 이동하십시오',
  },
  GenericFailurePage: {
    'Ref: Title': '무언가가 잘못되었습니다',
    'Ref: Body': '죄송합니다. 뭔가 잘못되었습니다. 이 문제가 지속되면 현장 지원에 문의하십시오.',
  },
  HomePage: {
    notifications: '알림',
    basket: 'Kori',
    'Ref: Tabtitle': '집',
    'contact us': 'Ota meihin yhteyttä',
    'Ref: You are logged in as a guest user': '게스트 사용자로 로그인됩니다',
    'Ref: Good morning': '좋은 아침',
    'Ref: Good afternoon': '안녕하세요',
    'Ref: Good evening before midnight': '좋은 저녁이에요',
    'Ref: Good evening after midnight': '좋은 저녁이에요',
    'Ref: Search': '찾다',
    'Ref: products button aria': '제품 내에서 {results_form}를 발견했습니다',
    'Ref: menus button aria': '메뉴 내에서 {results_form}를 발견했습니다',
    'Ref: facilities button aria': '시설 내에서 {results_form}을 발견했습니다',
    'Ref: result': '결과',
    'Ref: results': '결과',
    'Ref: Menus': '메뉴',
    'Ref: No results title': '죄송합니다!',
    'Ref: No results body': '검색과 일치하는 결과가 없습니다',
    'Ref: Products': '제품',
    'Ref: Facilities': '시설',
    'Ref: See more results': '더 많은 검색 결과를 참조하십시오',
    'Ref: Accessibility instructions':
      '검색을 시작하려면 검색을 클릭하거나 초점을 맞추는 동안 Enter를 누르십시오.',
    'Ref: See more': '더보기',
    'Ref: singular form of products': '제품',
    'Ref: singular form of menus': '메뉴',
    'Ref: singular form of facilities': '시설',
    'Ref: Search results for': '에 대한 검색 결과',
  },
  LanguagePage: {
    apply: '적용하다',
    'My language': '내 언어',
    'Select in which language you would like to read our app':
      '우리 앱을 읽고 싶은 언어를 선택하십시오.',
    'Ref: Page title': 'Kieli',
  },
  LegalDocPage: {
    Acknowledge: '인정하다',
    'Ref: Legal changed notification title': '"{legal_doc_type}"가 수정되었습니다',
    'Ref: Legal changed notification content': '앱을 계속 사용하기 전에 검토하십시오.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'käyttöehdot',
    'Read privacy policy': '개인 정보 보호 정책을 읽으십시오',
    'Ref: Acknowledge legal docs': '나는이 이용 약관을 받아들입니다.',
    'Ref: Read and understood': '나는 읽고 이해했다 ',
    'Ref: No legal docs available - notice title': '곧 제공합니다',
    'Ref: No legal docs available - notice body':
      '죄송합니다.이 응용 프로그램이 현재 지리학에서 사용할 수없는 것 같습니다.',
    'Ref: The privacy Policy': '개인 정보 보호 정책',
    'Ref: Legal changed notification title': '"{legal_doc_type}"가 수정되었습니다',
    'Ref: Legal changed notification content': '앱을 계속 사용하기 전에 검토하십시오.',
  },
  LoginPage: {
    'Ref: Page title': 'Tervetuloa {app name}',
    'Ref: Page subtitle': '계속하려면 로그인하십시오',
    'Ref: Email field placeholder': '귀하의 이메일',
    'Ref: Email field note': '우리는 당신의 이메일을 누구와 공유하지 않을 것입니다.',
    'Ref: Password field title': '너의 비밀번호',
    register: 'Kirjaudu',
    'I forgot my password.': '비밀번호를 잊으 셨나요?',
    'I have trouble logging in': 'Onko sinulla vaikeuksia kirjautua sisään?',
    'start registration': '등록을 시작하십시오',
    'Ref: Register': '등록하다',
    'Ref: Login': '로그인',
    'Ref: Connection issue?': '연결 문제?',
    'Ref: Connection issue message':
      '등록하거나 로그인해야 할 문제가 있으면 일반적인 현장 직원 또는 헬프 데스크에 문의하십시오.',
  },
  WelcomePage: {
    'Ref: Welcome to': '안녕하세요 {site_name}에 오신 것을 환영합니다.',
    'Ref: Begin': '시작하다',
  },
  NotificationsList: {
    'Ref: No notifications': '현재 알림이 없습니다',
    'Ref: This week': '이번 주',
    'Ref: Earlier': '더 일찍',
    'Ref: Page title': '알림',
    'Ref: See more': '더보기',
    'Ref: Mark all read': '모든 읽기를 표시하십시오',
  },
  NotificationsWidget: { 'Ref: See all notifications': '모든 알림을 참조하십시오' },
  ServicesMenuPage: { 'Ref: Page title': '서비스', navigate: '탐색' },
  DeletionSuccess: {
    'Ref: Title': '귀하의 계정 삭제가 요청되었습니다',
    'Ref: Content': '우리는 곧 그것을 처리 할 것입니다. 언제든지 액세스를 잃을 수 있습니다.',
    'Log out': '로그 아웃',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Palaamme asiaan',
    'Ref: Body': '문제가 발생해 죄송합니다. 우리는 곧 연락 드리겠습니다.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Onko sinulla vaikeuksia kirjautua sisään?',
    'Ref: Email field placeholder': 'Sähköpostiosoitteesi',
    'Ref: Mobile phone field placeholder': 'Matkapuhelinnumero',
    Company: '회사',
    'Ref: Company field placeholder': '회사 이름',
    'Ref: Company field note':
      '컨설턴트 인 경우, 이것은 현재 귀하가 현재 일하고있는 회사입니다. 고용주가 아닙니다.',
    Location: '도시 또는 위치',
    'Ref: Location field placeholder': '직장의 위치',
    'Ref: Location field note':
      '거주지의 외부에있는 경우, 영역이 일반적으로 언급되는 이름을 자유롭게 사용할 수 있습니다.',
    Comment: 'Kommentti',
    'Ref: Comment field placeholder': 'Mikä meni vikaan?',
    'Ref: Who is your employer?': '당신의 고용주는 누구입니까?',
    'Ref: What village are you staying at?': '당신은 어떤 마을에 머물고 있습니까?',
    'Ref: Comment field note': '우리가 당신을 도울 수있는 모든 정보!',
    'Ref: Feedback Content - geography': '지리학',
    'Ref: Feedback Content - fullName': '이름',
    'Ref: Feedback Content - email': '이메일',
    'Ref: Feedback Content - phone': '핸드폰',
    'Ref: Feedback Content - company': '회사',
    'Ref: Feedback Content - city': '도시 / 위치 이름',
    'Ref: Feedback Content - whatVillage': '마을',
    'Ref: Feedback Content - employer': '고용주',
    'Ref: Feedback Content - comment': '무엇이 잘못되었는지',
  },
  AccountStartPage: {
    'Ref: Header': '처음으로 위치를 선택했습니다',
    'Ref: Body':
      '현지화를 활성화하거나 QR 코드를 스캔하거나 이름으로 검색하여 위치를 신속하게 찾으십시오.',
    'Ref: LetsGo button': '갑시다',
  },
  AccountOnboardingInformat: { 'Ref: Page title': '등록' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'QR 스캐너로 사이트를 선택하십시오',
    'Ref: Page title': '위치를 선택하십시오',
    'Ref: Select site with code': '코드로 사이트를 선택하십시오',
    'Ref: Enter site code': '사이트 코드를 입력하십시오',
    'Ref: Scanner error header': '사이트를 찾을 수 없습니다',
    'Ref: Not found': '이 코드는 선택한 지리에서 유효하지 않습니다',
    'Ref: Generic error': '뭔가 잘못되었습니다',
    'All other sites': '다른 모든 사이트',
    'Sites in your country': '귀하의 나라의 사이트',
    'Ref: Unauthorized error': '사이트 제한 수준으로 인한 무단 요청',
    'Ref: Forbidden':
      '죄송합니다.이 사이트에 액세스 할 수있는 적절한 권한이 없습니다. 액세스를 주선하려면 1800 992 777의 Pilbara 서비스 센터에 문의하십시오.',
    "Ref: We didn't find the site.": '우리는 사이트를 찾지 못했습니다.',
    'Ref: We recommend some solutions:': '몇 가지 솔루션을 권장합니다.',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. QR 스캐너 또는 아래 버튼의 도움으로 사이트를 추가하십시오.',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. 올바른 국가를 선택하여 로그 아웃하고 로그인하십시오.',
    'Ref: The currently selected country is:': '현재 선택된 국가는 다음과 같습니다.',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': '등록',
    'Confirm password': '비밀번호 확인',
    'Email is not valid': '이메일은 유효하지 않습니다',
    'passwords do not match': 'Salasanat eivät täsmää.',
    'Ref: Password strength':
      '비밀번호는 길이가 8 자 이상이어야하며, 최소 1 개의 숫자, 대문자 1 개, 소문자 1 개 및 특수 문자가 포함되어 있어야합니다.',
    'Sign up': '가입하기',
    'Continue as guest': '손님으로 계속하십시오',
    'Already have an account? Login': '이미 계정이 있습니까? 로그인',
    'Ref: Registration success title': '등록 해주셔서 감사합니다',
    'Ref: Registration success content':
      '귀하의 계정은 곧 생성됩니다. 생성을 확인하는 이메일을 받게됩니다.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': '로그인/가입하십시오',
    'Ref: Password strength':
      'Salasanan on oltava vähintään 8 merkkiä pitkä, vähintään yksi numero, yksi iso, yksi pieni ja yksi erikoismerkki.',
    Stamps: '우표',
    'Order history': '주문 내역',
    'to earn points': '포인트를 얻으려면',
    'to see order history': '주문 기록을 보려면',
    'to place an order': '주문을합니다',
    'Sign up': '가입하기',
    Login: '로그인',
    'Email is not valid': '이메일은 유효하지 않습니다',
    'passwords do not match': 'Salasanat eivät täsmää.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': '감사합니다',
    'Ref: Body':
      '당신은 거의 끝났습니다! 제공된 주소에 대한 확인 이메일을 곧 받게됩니다. 이 이메일을 열고 링크를 클릭하여 계정을 활성화하십시오.',
    'Ref: Enter': '계속하다',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': '오류가 발생했습니다',
    'Ref: Generic Error Body': '나중에 다시 시도하거나 안내 데스크에 문의하십시오.',
    'Ref: Missing IndexDb support':
      '죄송합니다. 시크릿 모드 또는 IndexDB 지원없이 응용 프로그램에 액세스 할 수 없습니다. 다른 브라우저로 다시 시도하십시오.',
    'Ref: Resource not found': '액세스하려는 콘텐츠는 존재하지 않습니다.',
    'Ref: Permission denied - SITE_HIDDEN': '액세스하려는 콘텐츠는 개인 사이트에 속합니다.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      '액세스하려는 콘텐츠는 귀하의 계정에 승인되지 않았습니다.',
    'Ref: TabTitle': '오류',
    'Ref: Site link invalid':
      '이 링크는 유효하지 않고 다른 사이트를 선택하거나 문제가 지속되면 안내 데스크에 문의하십시오.',
    'Ref: Access Denied': '접근 불가. 사용자 권한이 부족합니다.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': '죄송합니다! {robot_emoji}',
    'Ref: Error title': '뭔가 잘못되었습니다!',
    'Ref: Error body': '문제가 지속되면이 페이지를 새로 고치거나 안내 데스크에 문의하십시오.',
    'Ref: Error ID:': '오류 ID : {errorId}',
    'Ref: Back to home': '홈으로',
  },
  Child: {
    'Ref: Route Not Found':
      '귀하가 찾고있는 서비스는이 사이트에서 사용할 수 없습니다. 나중에 다시 시도하거나 안내 데스크에 문의하십시오.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      '축하합니다. 새 비밀번호가 성공적으로 저장되었으며 이제 계정에서 모든 서비스에 액세스 할 수 있습니다.',
    Welcome: '어서 오십시오',
    'Ref: Welcome message':
      '이제 새로운 Bite Application에 성공적으로 등록했으며 모든 서비스를 계속 발견하고 즐길 수 있습니다.',
  },
  PasswordChange: {
    'Change Password': '비밀번호를 변경하십시오',
    Welcome: '어서 오십시오',
    'Ref: Password message': '충분히 안전하지 않기 때문에 비밀번호를 변경해야합니다.',
  },
  AccountPasswordChange: {
    'Change your password here': '여기에서 비밀번호를 변경하십시오',
    'Your old password': '이전 비밀번호',
    'Type in your new password': '새 비밀번호를 입력하십시오',
    'Re-type in your new password': '새 비밀번호를 다시 유형하십시오',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': '당신의 비밀번호가 변경되었습니다',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message': '오류가 발생하여 변경이 저장되지 않았습니다. 다시 시도하십시오.',
  },
  PasswordForgotten: {
    'Ref: Page title': '암호를 재설정',
    'Ref: Provide email':
      '{app_name}에 등록 할 때 사용되는 이메일 주소를 제공하십시오. 비밀번호를 재설정하기 위해 이메일이 전송됩니다.',
    'Ref: Something happened': '무슨 일이 일어났습니다. 나중에 다시 시도 해주십시오.',
  },
  ResetEmailSent: {
    'Ref: New email sent': '우리는 새 이메일을 보냈습니다. 다시 30 초 동안 기다리십시오.',
    'Go to login page': '로그인 페이지로 이동하십시오',
    'Resend email': '이메일을 재현하십시오',
    'Ref: Email sent title': '비밀번호 재설정 이메일이 전송되었습니다',
    'Ref: TabTitle': '암호를 재설정',
    'Ref: Email sent msg':
      '재설정 링크가 주소 {email_address}으로 전송되었습니다. 재판매를 시도하기 전에 30 초를 기다리십시오.',
  },
  ResetPassword: { 'Enter your new password': '새 비밀번호를 입력하십시오' },
  ResetPasswordSuccess: {
    'Ref: Password changed': '비밀번호가 성공적으로 변경되었습니다',
    'Go to login page': '로그인 페이지로 이동하십시오',
  },
  ResetPasswordFailure: {
    'Password reset fail': '비밀번호 재설정 실패',
    'Ref: Error': '오류가 발생했습니다. 다시 시도하십시오',
    'Reset your password': '비밀번호를 재설정',
    'Go to login page': '로그인 페이지로 이동하십시오',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': '개인 정보 정책',
    'Ref: Privacy Policy Body':
      '이 플랫폼에 액세스함으로써 Sodexo SA와 Local Sodexo Entity는 최소한 계정을 관리하고 플랫폼을 통해 제공되는 서비스 및 제안을 제공하기 위해 개인 데이터를 처리합니다. 개인 데이터 처리, 이에 액세스 할 사람 및 개인 데이터에 대한 권리를 행사하는 방법에 대한 자세한 정보를 원한다면 아래의 개인 정보 보호 정책을 문의하고 언제든지 계정에서 사용할 수 있습니다.',
    'Ref: Continue': '계속하다',
    'Ref: Read Privacy Policy': '개인 정보 보호 정책을 읽으십시오',
  },
  AppUpdatePage: { 'Ref: GoToStore': '가게로 가다' },
  SharePage: { 'Ref: Select a site': '사이트를 선택하십시오' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      '개인 정보 보호 정책을 보려면 귀하의 국가를 선택하십시오.',
  },
};
export default labels;
