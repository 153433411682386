const labels = {
  All: {
    Feedback: 'Feedback',
    'Ref: Switch site component intro sentence':
      'Din feedback kommer att kopplas till {site name}.',
    Category: 'Kategori',
    Subject: 'Ämne',
    Comment: 'Kommentar',
    'Date of your experience': 'Datum för din upplevelse *',
    'Ref: Hint': 'Har du ett problem eller jobb som behöver fixas?',
    'Ref: Log Request': 'Logga ett ärende',
    'Ref: Describe issue': 'Snälla berätta om din upplevelse',
    Date: 'Datum',
    'Ref: Share your opinion header': 'Dela din åsikt om din upplevelse på jobbet',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurang / kafé',
    'Desk / workstation area': 'Skrivbord / arbetsstationsområde',
    'Meeting room facilities': 'Mötesrum',
    'Coffee/tea facilities (pantry)': 'Kaffe-/teanläggningar (skafferi)',
    'Restrooms / washrooms': 'Toaletter / tvättrum',
    'Reception / Security': 'Mottagning / säkerhet',
    'Conference center': 'Konferenscentrum',
    'Fitness center': 'Gym',
    Mailroom: 'Postrum',
    'Bicycle room': 'Cykelrum',
    'Shuttle services': 'Pendeltrafik',
    Lockers: 'Skåp',
    Helpdesk: 'Kundtjänst',
  },
  Create: {
    'Tell us!': 'Berätta för oss!',
    'Ref: All Ears': 'Vi är alla öron!',
    'Ref: Provide Description': 'Vänligen ange en beskrivning',
    'Ref: Fill-in-the-blank sentence': 'Jag vill tillhandahålla en {0} när det gäller {1}',
    'Ref: Link text to service request module': 'Ett ärende eller ett problem? Logga in det här!',
    'Complaint type': 'Klagomål {emoji}',
    'Feedback type': 'Feedback {emoji}',
    'Compliment type': 'Komplimang {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Ett fel inträffade och din feedback lämnades inte in. Om det här felet kvarstår, vänligen kontakta din kundtjänst.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Din feedback lämnades in. Vår kundtjänst kommer att läsa det noggrant. Om det är lämpligt kommer vi tillbaka till dig.',
    'New feedback': 'Ny feedback',
  },
  CreateFlow: {
    Description: 'Beskrivning',
    'Ref: Provide Description': 'Ange ett datum och beskrivning',
    'Ref: I would like to provide a': 'Jag skulle vilja tillhandahålla en',
    'Ref: in regards to': 'när det gäller',
    'Ref: &': '&',
    'Ref: at': 'på',
    'Ref: on the': 'på',
  },
};
export default labels;
