import momentjs from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Time, InformationIcon } from '../../../../assets/icons';
import { MenuIllustration } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import { FilterComponent, useFilters } from '../../../../components/atoms/Filters';
import SearchBar from '../../../../components/atoms/SearchBar/SearchBar';
import Title from '../../../../components/atoms/Title/Title';
import { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title/Title.types';
import { ContentList } from '../../../../components/molecules/ContentList';
import SecondaryActions from '../../../../components/molecules/SecondaryActions/SecondaryActions';
import { TILE_VARIANT } from '../../../../components/molecules/Tile';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import { useFiltering } from '../../../../components/templates/ListPage';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { DATE_FORMAT } from '../../../../constants';
import { formatDate, toLocalDate } from '../../../../helpers/dateTime';
import useToggle from '../../../../helpers/hooks/useToggle';
import { TestingPropsV2 } from '../../../../types';
import useMenusVisits from '../../hooks/useMenusVisits/useMenusVisits';
import { useOrderTranslation } from '../../hooks/useOrderTranslation';
import useScrollPosition from '../../hooks/useScrollPosition';
import { ProductListItem, ProductListFiltering } from '../../types/productList.types';
import FacilityDetails from '../FacilityDetails';
import ProductTile from '../ProductTile/ProductTile';

import {
  buildConfig,
  buildFilteringState,
  buildSections,
  cacheSelectedFilters,
  getDefaultFiltersFromCache,
  mapToListModel,
} from './productList.helper';
import { getFilters } from './productListFilters.helper';

import useUserStepsInsightsLogging from '@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging';
import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';
import { useGetFacilitiesListQuery } from '@/modules/Facilities/api/api';
import { useGetMenusQuery } from '@/modules/Order/api/menuApi';
import { UserSteps } from '@/types/userSteps.types';

import styles from './ProductsList.module.css';

const ProductsList = () => {
  const { contentRef, saveScrollPosition } = useScrollPosition();
  const { label } = useOrderTranslation(__filename);

  const {
    facilityId,
    menuId,
    date: selectedDate,
  } = useParams<{
    facilityId: string;
    menuId: string;
    date: string;
  }>();

  const site = useSite({ throwWhenNoActiveSite: true })!;

  const { data: menus, isFetching: menusLoading } = useGetMenusQuery({
    siteId: site?.id,
    menuDate: selectedDate,
  });
  const { data: facilitiesResponse, isFetching: facilitiesLoading } = useGetFacilitiesListQuery({
    siteId: site?.id,
  });

  const { currentLanguageCode: languageCode } = useLanguage();

  const facility = facilitiesResponse?.facilities?.find((fac) => fac.id === facilityId);
  const selectedMenu = menus?.find((x) => x.id === menuId);

  const moments = useMemo(() => {
    const momentsArray: string[] = [];
    selectedMenu?.menuItems.forEach((menuItem) => {
      if (!momentsArray.find((x) => x === menuItem.mealName) && menuItem.mealName !== null) {
        momentsArray.push(menuItem.mealName);
      }
    });
    return momentsArray;
  }, [selectedMenu?.menuItems]);

  const { logUserSteps } = useUserStepsInsightsLogging();

  const { increaseMenuVisits } = useMenusVisits(site.id);

  const {
    state: isFacilityModalOpen,
    toggleOn: openFacilityDetails,
    toggleOff: closeFacilityDetailsModal,
  } = useToggle(false);

  useEffect(() => {
    if (selectedMenu?.id) increaseMenuVisits(selectedMenu.id);
  }, [selectedMenu?.id, increaseMenuVisits]);

  useEffect(() => {
    if (!!facilityId && !!facility?.title) {
      logUserSteps({
        event: UserSteps.ProductList,
        facilityId,
        facilityName: facility?.title,
      });
    }
  }, [facility?.title, facilityId, logUserSteps]);

  const defaultFilters = useMemo(() => {
    const cachedFilters = selectedMenu?.name
      ? getDefaultFiltersFromCache(site.id, selectedMenu?.name, selectedDate)
      : undefined;
    if (!!cachedFilters) {
      return cachedFilters;
    }
  }, [selectedDate, selectedMenu?.name, site]);

  const [selectedMoment, setSelectedMoment] = useState(moments[0]);

  const listItems: ProductListItem[] = useMemo(
    () =>
      selectedMenu
        ? mapToListModel({
            menu: selectedMenu,
            selectedMoment,
          })
        : [],
    [selectedMenu, selectedMoment]
  );
  const sections = useMemo(() => (listItems?.length ? buildSections(listItems) : []), [listItems]);

  const listConfig = useMemo(
    () => (sections?.length ? buildConfig(sections?.length) : {}),
    [sections?.length]
  );

  const [basefiltering, setBaseFiltering] = useState<ProductListFiltering | null>(null);

  const listFilters = useMemo(
    () => ({
      filters: getFilters({
        label,
        menuItemsList: listItems,
        sections,
        moments,
        setSelectedMoment,
      }),
      initialFiltering: basefiltering ?? buildFilteringState({ moments, defaultFilters }),
      handleFilteringChange: (newFiltering: ProductListFiltering) => {
        setBaseFiltering(newFiltering);
        cacheSelectedFilters(newFiltering, site.id, selectedMenu?.name ?? '', selectedDate);
      },
    }),
    [
      basefiltering,
      defaultFilters,
      label,
      listItems,
      moments,
      sections,
      selectedDate,
      selectedMenu?.name,
      site,
    ]
  );

  const search = useMemo(
    () => ({
      searchableKeys: ['title'],
      placeholder: label('Ref: Search products list'),
    }),
    [label]
  );

  const displaySelectedDate = () => {
    if (selectedDate === momentjs().format(DATE_FORMAT))
      return label('Ref: today', { textTransform: 'capitalize' });
    if (selectedDate === momentjs().add(1, 'days').format(DATE_FORMAT))
      return label('Ref: tomorrow', { textTransform: 'capitalize' });
    return formatDate(toLocalDate(selectedDate), languageCode);
  };

  const listItemsWithTestId = useMemo(() => {
    return listItems.map((item) => ({
      ...item,
      'data-testid': `product-list-item-${item.id}`,
    }));
  }, [listItems]);

  const { filterItems, searchString, filtering, handleSearchChange, handleFilteringChange } =
    useFiltering({ items: listItemsWithTestId, filter: listFilters, search });

  const { visibleFilters, handleFilterChange, handleCalendarChange } = useFilters({
    filters: listFilters.filters,
    filtering,
    handleChange: handleFilteringChange,
    label,
    languageCode,
    facilityId: selectedMenu?.facilityId,
    testId: 'product-list-filters',
  });

  const filterMoment = useMemo(() => {
    const momentFilter = visibleFilters.find((obj) => {
      return obj.id === 'filter_moment';
    });

    //If none of the moment is saved in filters then we always pick first moment (if multiple exist)

    const hasFilteredMoment: boolean =
      !!filtering['filter_moment'] && Object.keys(filtering['filter_moment']).length > 0;

    return momentFilter ? (
      <FilterComponent
        key={momentFilter.id}
        filter={momentFilter}
        filtering={
          hasFilteredMoment
            ? filtering
            : { ...filtering, filter_moment: { [moments[0]?.toLowerCase()]: true } }
        }
        label={label}
        languageCode={languageCode}
        handleChange={handleFilterChange}
        handleCalendarChange={handleCalendarChange}
        data-testid="product-list-filter-moment"
        className={styles.dayParts}
      />
    ) : null;
  }, [
    filtering,
    handleCalendarChange,
    handleFilterChange,
    label,
    languageCode,
    moments,
    visibleFilters,
  ]);

  const filterCategory = useMemo(() => {
    const categoryFilter = visibleFilters.find((obj) => {
      return obj.id === 'filter_category';
    });

    return categoryFilter ? (
      <FilterComponent
        key={categoryFilter.id}
        filter={categoryFilter}
        filtering={filtering}
        label={label}
        languageCode={languageCode}
        handleChange={handleFilterChange}
        handleCalendarChange={handleCalendarChange}
        data-testid="product-list-filter-category"
      />
    ) : null;
  }, [filtering, handleCalendarChange, handleFilterChange, label, languageCode, visibleFilters]);

  if (menusLoading || facilitiesLoading || !selectedMenu)
    return <LoadingPage data-testid="loading-page-products-list" />;

  const facilityTitle = (
    <div className={styles.facilityTitle}>
      <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
        {facility?.title}
      </Title>
    </div>
  );

  const listTopContent = (
    <div>
      <div className={styles.pageHeader}>
        <div className={styles.facility}>
          <div>{facilityTitle}</div>
        </div>
        <div className={styles.moment} data-testid={'page-header-moment'}>
          <p className={styles.dateString}>
            <span className={styles.dateIcon}>
              <Time />
            </span>
            {displaySelectedDate()}
          </p>
        </div>
      </div>
    </div>
  );

  const secondaryActions = facility ? (
    <SecondaryActions>
      <div>
        <Button
          data-testid="product-list-open-facility-details"
          look={BUTTON_LOOK.TERTIARY}
          onClick={openFacilityDetails}
          affix={InformationIcon}
        >
          {label('Ref: Facility details')}
        </Button>
        <FacilityDetails
          label={label}
          isFacilityModalOpen={isFacilityModalOpen}
          onFacilityModalDismiss={closeFacilityDetailsModal}
          facility={facility}
        />
      </div>
    </SecondaryActions>
  ) : null;

  const filtersAndSearchBar =
    listFilters.filters || search ? (
      <SearchBar
        {...search}
        withCardWrapper
        collapsible={filterCategory ? true : false}
        right={filterCategory ? [filterCategory] : []}
        searchString={searchString}
        handleChange={handleSearchChange}
        data-testid="products-list-search-bar"
      />
    ) : null;

  return (
    <SimpleFormPage title={label('Ref: Page title')} contentRef={contentRef}>
      <Container>
        <Column.Main>
          {listTopContent}
          {filterMoment}
          {secondaryActions}
          {filtersAndSearchBar}
          <ContentList
            items={filterItems}
            config={listConfig}
            sections={sections}
            twoTilesColumns
            noResults={{ title: label('Your selection did not return any result.') }}
            itemRenderer={(item: ProductListItem & TestingPropsV2) => {
              const props = {
                ...item,
                facilityId: facilityId,
                date: selectedDate,
                menu: selectedMenu,
                saveScrollPosition,
                isKiosk: false,
                isGuest: false,
                canAddToFavorite: true,
              };
              return <ProductTile key={item.id} {...props} />;
            }}
            tileVariant={TILE_VARIANT.BIG}
          />
        </Column.Main>
        <Column.Complementary>
          <MenuIllustration />
        </Column.Complementary>
      </Container>
    </SimpleFormPage>
  );
};

export default ProductsList;
