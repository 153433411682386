import { screen, cleanup, act, fireEvent } from '@testing-library/react';

import renderComponent from '../../../../helpers/tests/renderComponent';

import GeographySelector from './GeographySelector';

const props = {
  geographies: [
    {
      code: 'BE',
      name: 'Belgium',
      regionCode: 'BE',
    },
    {
      code: 'CA',
      name: 'Canada',
      regionCode: 'CA',
    },
    {
      code: 'EU',
      name: 'Europe',
      regionCode: 'EU',
    },
  ],
  handleGeographySubmit: jest.fn(),
};

describe('GeographySelector', () => {
  beforeEach(() => {
    Object.defineProperty(window, 'localStorage', {
      value: {
        getItem: jest.fn(() => 'EU'),
        setItem: jest.fn(() => null),
      },
      writable: true,
    });
  });

  afterAll(() => cleanup());

  describe('on initial render', () => {
    renderComponent(GeographySelector, props);

    beforeEach(() => {
      expect(window.localStorage.getItem).toHaveBeenCalledWith('currentGeoCode');
    });

    it('should display geography selector', () => {
      expect(screen.getByTestId('geography-selector')).toBeTruthy();
    });

    it('should display current geography', () => {
      expect(screen.getByText('Europe')).toBeTruthy();
    });
  });

  describe('on clicking geography selector', () => {
    renderComponent(GeographySelector, props);

    beforeEach(async () => {
      expect(window.localStorage.getItem).toHaveBeenCalledWith('currentGeoCode');

      await act(async () => {
        fireEvent.click(screen.getByTestId('geography-selector-country-selection-action'));
      });
    });

    it('modal should open', async () => {
      expect(screen.getByTestId('geography-modal')).toBeTruthy();
    });

    it('modal should contain a list of geographies', async () => {
      expect(screen.getByText(props.geographies[0].name)).toBeTruthy();
      expect(screen.getByText(props.geographies[1].name)).toBeTruthy();
      expect(screen.getAllByText(props.geographies[2].name)).toBeTruthy();
    });

    it('modal should contain a button', async () => {
      expect(screen.getByText('Validate')).toBeTruthy();
    });
  });

  describe('on selecting geography and submiting', () => {
    renderComponent(GeographySelector, props);

    beforeEach(async () => {
      expect(window.localStorage.getItem).toHaveBeenCalledWith('currentGeoCode');

      await act(async () => {
        fireEvent.click(screen.getByTestId('geography-selector-country-selection-action'));
      });
      await act(async () => {
        fireEvent.click(screen.getByText(props.geographies[0].name));
      });
      await act(async () => {
        fireEvent.click(screen.getByText('Validate'));
      });
    });

    it('handleGeographySubmit should have been called', async () => {
      expect(props.handleGeographySubmit).toHaveBeenCalledWith(props.geographies[0].code);
      expect(window.localStorage.setItem).toHaveBeenCalledWith(
        'currentGeoCode',
        props.geographies[0].code
      );
    });
  });
});
