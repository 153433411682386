import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import history from './history';

const reactPlugin = new ReactPlugin();
const isNonProduction = process.env.NODE_ENV === 'development';
const appInsightsKey = process.env.REACT_APP_INSIGHTS_KEY;

const domainsExcludedFromAi = ['maps.googleapis.com', 'fonts.googleapis.com'];
let appInsights: ApplicationInsights | undefined;

if (appInsightsKey) {
  appInsights = new ApplicationInsights({
    config: {
      enableDebug: isNonProduction,
      loggingLevelConsole: isNonProduction ? 2 : 0,
      loggingLevelTelemetry: isNonProduction ? 2 : 0,
      samplingPercentage: 100,
      enableAjaxErrorStatusText: true,
      enableUnhandledPromiseRejectionTracking: true,
      enableCorsCorrelation: true,
      disableExceptionTracking: false,
      instrumentationKey: appInsightsKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history },
      },
      correlationHeaderExcludedDomains: domainsExcludedFromAi,
    },
  });
  // @ts-ignore
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };
