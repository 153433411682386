import Tunnel from '../../../../components/Tunnel';
import AccountOnboardingLocation from '../../components/AccountOnboardingLocation';
import AccountStartPage from '../../components/AccountStartPage';
import { pagePaths } from '../../config';

const OnboardingTunnel = () => {
  return (
    <Tunnel
      steps={[
        {
          id: 'start',
          path: `${pagePaths.Onboarding}/start`,
          component: AccountStartPage,
          getApplicability: () => true,
          getNextStepId: () => 'location',
        },
        {
          id: 'location',
          path: `${pagePaths.Onboarding}/location`,
          component: AccountOnboardingLocation,
          getApplicability: () => true,
          getNextStepId: () => undefined,
        },
      ]}
      startStepId="start"
      hideStepper
      exitToPath=""
    />
  );
};

export default OnboardingTunnel;
