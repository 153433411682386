const labels = {
  SiteDetails: {
    'Select site': 'Wybierz tę lokalizację',
    'Ref: Page title': 'Szczegóły lokalizacji',
    'Ref: Skip map': 'Przejdź do głównej zawartości',
    'Ref: Unavailable site': 'Podgląd lokalizacji nie jest dostępny',
    'Ref: Sorry, no description yet': 'Przepraszamy, nie mamy jeszcze opisu.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Nie znaleziono współrzędnych lokalizacji',
    'Ref: Page title': 'Lokalizacje',
    'Recently visited': 'Niedawno odwiedzane',
    'All other sites': 'Wszystkie inne lokalizacje',
    'Sites in your country': 'Lokalizacje w Twoim kraju',
    'Search results': 'Wyniki wyszukiwania',
    'Select site': 'Wybierz lokalizację',
    'Ref: Select site with QR Scanner': 'Zeskanuj kod QR lokalizacji',
    'Ref: Select site with code': 'Wybierz lokalizację wprowadzając kod',
    'Ref: Validate': 'Zatwierdzanie',
    'Ref: Enter site code': 'Wprowadź kod lokalizacji',
    'Ref: Scanner error header': 'Nie znaleziono lokalizacji',
    'Ref: Generic error': 'Coś poszło nie tak',
    'Ref: Change site': 'Zmień lokalizację',
    'Ref: Change site description':
      'W koszyku są produkty! Zmiana lokalizacji spowoduje wyczyszczenie koszyka.',
    'Ref: Cancel': 'Anuluj',
    'Ref: Not found': 'Ten kod QR nie został rozpoznany',
    'Ref: Unauthorized error':
      'Nieautoryzowane żądanie ze względu na poziom ograniczenia lokalizacji',
    'Ref: Forbidden':
      'Przepraszam, nie masz odpowiednich uprawnień do dostępu do tej lokalizacji. Aby zorganizować dostęp, skontaktuj się z Pilbara Service Center pod numerem 1800 992 777',
    "Ref: We didn't find the site.": 'Nie znaleźliśmy lokalizacji.',
    'Ref: We recommend some solutions:': 'Polecamy niektóre rozwiązania:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Spróbuj dodać lokalizację ze skanerem QR lub za pomocą przycisków poniżej',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Wyloguj się i zaloguj się, wybierając odpowiedni kraj',
    'Ref: The currently selected country is:': 'Obecnie wybrany kraj to:',
  },
  SwitchSite: { 'Not on {site name}?': 'Nie na {site name}?' },
  SiteTile: { Details: 'Szczegóły', Select: 'Wybierz' },
};
export default labels;
