import { coreConfig } from '../../apiConfig';
import mockResponse from '../../mocks/passwordApi';
import { Email } from '../account/accountApi.types';

import { ResetEmailArgs } from './passwordApi.types';

const { api } = coreConfig();
const passwordApi = api.enhanceEndpoints({ addTagTypes: ['password'] }).injectEndpoints({
  endpoints: (build) => ({
    sendForgottenEmail: build.mutation<void, Email>({
      query: (args) => ({
        url: '/v1/account/password/send-reset-email',
        method: 'post',
        data: { email: args.email, code: process.env.REACT_APP_APP_NAME },
        mockData: mockResponse.sendForgottenEmail,
      }),
    }),
    resetEmail: build.mutation<void, ResetEmailArgs>({
      query: (args) => ({
        url: '/v1/account/password/reset',
        method: 'put',
        data: {
          id: args.id,
          securityCode: args.securityCode,
          password: args.password,
          code: process.env.REACT_APP_APP_NAME,
        },
        mockData: mockResponse.resetEmail,
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useSendForgottenEmailMutation, useResetEmailMutation } = passwordApi;
export default passwordApi;
