import classNames from 'classnames';
import { decode } from 'html-entities';
import { useCallback, useEffect, useState } from 'react';
import nl2br from 'react-nl2br';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { useGetServiceRequestQuery, useGetServiceRequestsQuery } from '../../api/index';
import { getDetailsSectionsConfiguration, pagePaths, statusMap } from '../../config';
import { getLocationDisplayName, renderAffectedPersonName } from '../../helpers/helpers';
import { useServiceRequestSetupOption } from '../../hooks/useServiceRequestSetupOption';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';
import {
  CurrentServiceRequest,
  CurrentStatus,
  Image,
  Message,
  TimelineMarker,
} from '../../types/types';
import ServiceRequestStatus from '../Status/Status';

import { generateTimeline } from './Details.helper';
import { StatusMap } from './Details.types';

import Button from '@/components/atoms/Button';
import { ChatMessageProps } from '@/components/atoms/ChatMessage/ChatMessage';
import Timeline from '@/components/atoms/Timeline/Timeline';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ActionsBar from '@/components/organisms/ActionsBarV2';
import Attachments from '@/components/organisms/Attachments/Attachments';
import { AttachmentData } from '@/components/organisms/Attachments/Attachments.types';
import Chat from '@/components/organisms/Chat/Chat';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import List from '@/components/organisms/List/List';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import { getAttachments } from '@/helpers/attachments';
import { formatDateTime } from '@/helpers/dateTime';
import { isMyVillage } from '@/modules/Core/helpers/helpers';
import { SERVICE_REQUEST_STATUS } from '@/modules/ServiceRequest/components/Status/Status.types';
import { State } from '@/types/state.types';

import styles from './Details.module.css';

const isMessageSentBySodexo = (message: Message) => {
  return !message.isCreatedByUser && message.createdBy.toLowerCase().includes('sodexo');
};
const Details = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { label } = useServiceRequestTranslation(__filename);
  let { id: currentServiceRequestId = '' } = useParams<{ id: string }>();
  const [attachments, setAttachments] = useState<AttachmentData[]>([]);
  const [currentServiceRequest, setCurrentServiceRequest] =
    useState<CurrentServiceRequest | undefined>();
  const [messages, setMessages] = useState<ChatMessageProps[]>();
  const currentUser = useSelector((state: State) => state.Core?.user);
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);
  const [backFromDetailToHome, setBackFromDetailToHome] = useState<boolean>(false);
  useEffect(
    function checkPrevPage() {
      const searchParams = new URLSearchParams(search);
      if (searchParams.has('fromWidget')) {
        setBackFromDetailToHome(true);
      }
    },
    [setBackFromDetailToHome, search]
  );

  const isLocallyCreatedSR = currentServiceRequestId.match(/LOCAL-/) !== null;
  const { data: serviceRequest } = useGetServiceRequestQuery(
    {
      id: currentServiceRequestId,
      emailId: currentUser?.email,
    },
    {
      skip: isLocallyCreatedSR,
    }
  );
  const { data: serviceRequests } = useGetServiceRequestsQuery({}, { skip: !isLocallyCreatedSR });
  useEffect(() => {
    if (serviceRequest) {
      setCurrentServiceRequest(serviceRequest as unknown as CurrentServiceRequest);
    }

    if (serviceRequests && isLocallyCreatedSR) {
      const request = serviceRequests.find((r) => r.id === currentServiceRequestId);
      if (request) setCurrentServiceRequest(request as unknown as CurrentServiceRequest);
    }
  }, [serviceRequest, currentServiceRequestId, serviceRequests, isLocallyCreatedSR]);

  const getMessages = useCallback(async () => {
    if (!currentServiceRequest?.messages) return;
    const messagesArray = currentServiceRequest.messages
      .filter((candidateMessage: Message) => candidateMessage.description)
      .map(async (candidateMessage: Message) => {
        return {
          author: candidateMessage.createdBy,
          message: candidateMessage.description,
          date: formatDateTime(new Date(candidateMessage.createdOn), currentLanguageCode),
          'data-testid': `service-request-details-message`,
          actions: candidateMessage?.attachments && candidateMessage.attachments.length > 0 && (
            <div className={classNames(styles.wrapper)}>
              <Attachments
                readOnly
                attachments={await getAttachments(candidateMessage?.attachments)}
                closeButtonNotVisible
                displaySize={false}
                data-testid={`attachments-candidate-response`}
              />
            </div>
          ),
          inheritStyle: isMessageSentBySodexo(candidateMessage)
            ? 'toUserChatMessage'
            : 'fromUserChatMessage',
        };
      });

    const messages = await Promise.all(messagesArray);
    setMessages(messages);
  }, [currentServiceRequest, currentLanguageCode]);

  const enableAffectedPerson = useServiceRequestSetupOption('enableAffectedPerson');

  const hidePriorityFields = useServiceRequestSetupOption('hidePriorityFields');

  const hidePhoneNumberField = useServiceRequestSetupOption('hidePhoneNumber');

  const hidePhoneNumber = isMyVillage() || hidePhoneNumberField;

  useEffect(() => {
    getAttachments(currentServiceRequest?.images as Image[], setAttachments);
    getMessages();
  }, [currentServiceRequest, setAttachments, getMessages]);

  if (!currentServiceRequest && !currentServiceRequestId.match(/LOCAL-/)) {
    return <LoadingPage />;
  }

  const sections = getDetailsSectionsConfiguration();

  const availableStatusMappings: StatusMap = statusMap;
  const status = availableStatusMappings[
    currentServiceRequest?.currentStatus?.toLowerCase() ?? CurrentStatus.Local
  ] as SERVICE_REQUEST_STATUS;

  let keyValuePairs: {
    id: string;
    label: string;
    value: any;
    wrapLine?: boolean;
    'data-testid': string;
  }[] = [
    {
      id: 'reference',
      label: label('Ref: reference'),
      value: currentServiceRequest?.referenceNumber,
      'data-testid': 'service-request-details-item-reference-number',
    },
    {
      id: 'createdOn',
      label: label('Ref: createdOn'),
      value: currentServiceRequest?.createdOn
        ? formatDateTime(new Date(currentServiceRequest?.createdOn), currentLanguageCode)
        : '',
      'data-testid': 'service-request-details-item-created-on',
    },
  ];

  if (!hidePriorityFields) {
    const startAndCompletionDates = [];

    if (currentServiceRequest?.targetStartDateDate) {
      startAndCompletionDates.push({
        id: 'targetStartDate',
        label: label('Ref: Target start date'),
        'data-testid': 'service-request-details-item-target-start-date',
        value: formatDateTime(
          new Date(currentServiceRequest.targetStartDateDate),
          currentLanguageCode
        ),
      });
    }

    if (currentServiceRequest?.targetEndDateDate) {
      startAndCompletionDates.push({
        id: 'targetCompletionDate',
        label: label('Ref: Target completion date'),
        'data-testid': 'service-request-details-item-target-end-date',
        value: formatDateTime(
          new Date(currentServiceRequest.targetEndDateDate),
          currentLanguageCode
        ),
      });
    }

    keyValuePairs.push(
      {
        id: 'priority',
        label: label('Ref: Priority'),
        'data-testid': 'service-request-details-item-priority',
        value: currentServiceRequest?.priority
          ? currentServiceRequest.priority
          : label('Ref: Priority fields placeholder message'),
      },
      ...startAndCompletionDates
    );
  }

  const location = currentServiceRequest ? getLocationDisplayName(currentServiceRequest) : '';
  if (location && !isMyVillage()) {
    keyValuePairs.push({
      id: 'location',
      label: label('Ref: location'),
      value: location,
      wrapLine: true,
      'data-testid': 'service-request-details-item-location',
    });
  }

  if (!!currentServiceRequest?.site && isMyVillage()) {
    keyValuePairs.push({
      id: 'site',
      label: label('Ref: site'),
      value: currentServiceRequest.site.name,
      wrapLine: true,
      'data-testid': 'service-request-details-item-site',
    });
  }

  if (enableAffectedPerson) {
    const isCurrentUser = currentUser?.contactId === currentServiceRequest?.raisedFor?.contactId;
    const personRaisedFor = isCurrentUser ? currentUser : currentServiceRequest?.raisedFor;

    keyValuePairs.push({
      id: 'raisedFor',
      label: label('Ref: SR raised for'),
      'data-testid': 'service-request-details-item-raised-for',
      value: renderAffectedPersonName(
        personRaisedFor?.firstName || '',
        personRaisedFor?.lastName || '',
        '',
        label,
        isCurrentUser
      ),
    });
  }

  if (currentUser.mobile && !hidePhoneNumber) {
    keyValuePairs.push({
      id: 'phoneNumber',
      label: label('phone_number'),
      'data-testid': 'service-request-details-item-phone-number',
      value: currentUser.mobile,
    });
  }

  keyValuePairs.push({
    id: 'description',
    label: label('Ref: description field label'),
    'data-testid': 'service-request-details-item-description',
    value: nl2br(decode(currentServiceRequest?.description)),
    wrapLine: true,
  });

  if (currentServiceRequest?.images?.length) {
    keyValuePairs.push({
      id: 'attachments',
      label: label('Ref: attachments'),
      'data-testid': 'service-request-details-item-attachments',
      value: (
        <Attachments
          readOnly
          attachments={attachments}
          closeButtonNotVisible
          displaySize={false}
          data-testid={`attachments-service-request-item-details-description-field`}
        />
      ),
      wrapLine: true,
    });
  }

  const isClosed = status === SERVICE_REQUEST_STATUS.CLOSED;
  const actionFeedbackInactive = isClosed && currentServiceRequest?.isFeedbackSubmitted;
  const actionFeedback = isClosed && !actionFeedbackInactive && sections.feedback;
  const actionUpdateUs = !isClosed && !currentServiceRequestId.match(/LOCAL-/) && sections.updateUs;

  const goToFeedback = (): void =>
    history.push(pagePaths['Feedback'].replace(':id', currentServiceRequestId));

  const goToUpdateUs = (): void =>
    history.push(pagePaths['Comment'].replace(':id', currentServiceRequestId));

  const actions = [];
  if (actionFeedbackInactive) {
    actions.push(
      <Button
        disabled
        key={'actionFeedbackInactive_button'}
        data-testid="service-request-submitted-feedback-action"
      >
        {label('Feedback was submitted')}
      </Button>
    );
  }

  if (actionFeedback) {
    actions.push(
      <Button
        onClick={goToFeedback}
        key={'actionFeedback_button'}
        data-testid="service-request-feedback-action"
      >
        {label('Feedback')}
      </Button>
    );
  }

  if (actionUpdateUs) {
    actions.push(
      <Button
        onClick={goToUpdateUs}
        key={'actionUpdateUs_button'}
        data-testid="service-request-update-action"
      >
        {label('Update us')}
      </Button>
    );
  }

  const displayTitle = decode(currentServiceRequest?.displayTitle);
  const title = (
    <>
      <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES} className="mb-S">
        {displayTitle}
      </Title>
      <div className="mb-S">
        <ServiceRequestStatus status={status} displayStatus={label(`status: ${status}`)} />
      </div>
    </>
  );

  const timelineItems =
    sections.timeline && currentServiceRequest ? generateTimeline(currentServiceRequest) : [];

  const backLinkPath = backFromDetailToHome ? '/home' : pagePaths.Home;

  return (
    <SimpleFormPage
      title={displayTitle ?? ''}
      tabTitle={displayTitle}
      backButtonCustomPath={backLinkPath}
    >
      <Container>
        <Column.Side>
          <div>
            {title}
            <Card>
              <List data-testid="service-request-details-card-list" items={keyValuePairs} />
            </Card>
          </div>
        </Column.Side>
        <Column.Main>
          {sections.conversation && (
            <div>
              <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES} className="mb-M">
                {label('Ref: conversation')}
              </Title>
              <Chat
                messages={messages}
                title={label('There are no updates for this request')}
                data-testid="service-request-chat"
              />
            </div>
          )}

          {sections.timeline && (
            <div>
              <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES} className="mb-M">
                {label('Ref: timeline')}
              </Title>
              <Card>
                <Timeline
                  items={timelineItems.map(({ name, date, isProcessed }: TimelineMarker) => ({
                    name: label(`Ref: ${name}`),
                    ...(date && { date: formatDateTime(new Date(date), currentLanguageCode) }),
                    isComplete: isProcessed,
                  }))}
                />
              </Card>
            </div>
          )}
        </Column.Main>
        {actions.length > 0 ? <ActionsBar inMainColumn>{actions}</ActionsBar> : null}
      </Container>
    </SimpleFormPage>
  );
};

export default Details;
