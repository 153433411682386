const languageMarkUpStart = '[lang:';
const languageMarkUpEnd = '[/lang:';

type LanguagePart = { lang: string; content: string };

export const getLanguageBody = (body: string, lang: string) => {
  if (body?.indexOf(languageMarkUpStart) < 0) return body;
  const languagePartialTexts = body.split(languageMarkUpStart);
  const languageParts: LanguagePart[] = [];
  let firstLanguageCode: string = '';
  let isLanguageCovered = false;

  for (let bodyPart of languagePartialTexts) {
    if (bodyPart.length) {
      if (bodyPart.indexOf(languageMarkUpEnd) > 0) {
        const bodyElements = bodyPart.split(/](.*)/s);
        const languageCode = bodyElements[0];
        if (!firstLanguageCode.length && languageCode) firstLanguageCode = languageCode;
        if (languageCode === lang) isLanguageCovered = true;

        const content = bodyElements[1].split(languageMarkUpEnd)[0];
        if (content.length) languageParts.push({ lang: languageCode, content: content });

        const splittedWithEndMarkup = bodyPart.split(languageMarkUpEnd);
        const noLanguageContent = splittedWithEndMarkup[1]?.split(']')[1];
        if (noLanguageContent) {
          languageParts.push({ lang: '', content: noLanguageContent });
        }
      } else {
        languageParts.push({ lang: '', content: bodyPart });
      }
    }
  }

  return getLanguageContentOrDefault({
    languageParts,
    selectedLanguage: lang,
    firstLanguageCode,
    isLanguageCovered,
  });
};

const getLanguageContentOrDefault = ({
  languageParts,
  selectedLanguage,
  firstLanguageCode,
  isLanguageCovered,
}: {
  languageParts: LanguagePart[];
  selectedLanguage: string;
  firstLanguageCode: string;
  isLanguageCovered: boolean;
}) => {
  let languagePartsForCode: LanguagePart[];
  if (!isLanguageCovered) {
    languagePartsForCode = languageParts.filter(
      (x) => x.lang === '' || x.lang === firstLanguageCode
    );
  } else {
    languagePartsForCode = languageParts.filter(
      (x) => x.lang === '' || x.lang === selectedLanguage
    );
  }

  return languagePartsForCode.map((x) => x.content).join('');
};
