import { DayPart, Menu, MenuItem } from '../../components/ProductsList/ProductsList.types';

export const mapItemsPerDaypart = (menu: Menu) => {
  if (!menu) return;

  const menuListResult = menu.dayparts.reduce((acc, { name, sections }: DayPart) => {
    const items = sections.reduce((acc, section) => {
      return [...acc, ...section.items];
    }, [] as MenuItem[]);

    return {
      ...acc,
      [name?.toLowerCase()]: items,
    };
  }, {});

  return menuListResult;
};
