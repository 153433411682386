import classNames from 'classnames';
import { useEffect, useCallback, useState } from 'react';

import { PlusSignIcon, MinusSignIcon } from '../../../assets/icons';
import useStateWithProps from '../../../helpers/hooks/useStateWithProps';
import Button from '../Button/Button';

import { QuantitySetterProps } from './QuantitySetter.types';

import styles from './QuantitySetter.module.css';

const QuantitySetter = ({
  value: valueProp,
  min,
  max,
  onChange,
  increment,
  decrement,
  displayInline,
  displayBig,
  showOnlyParentValue,
  disableDecrement,
  disableIncrement,
  preventCallback,
  showDecrementAtMinQuantity = false,
  itemName,
  increaseLabel,
  decreaseLabel,
  onAddOrQuantityButtonClick,
  'data-testid': testId,
}: QuantitySetterProps) => {
  const parsedValue = typeof valueProp === 'string' ? parseInt(valueProp) : valueProp;

  const [minDisabled, setMinDisabled] = useState(false);
  const [maxDisabled, setMaxDisabled] = useState(false);

  const [quantity = 0, setQuantity] = useStateWithProps(parsedValue);

  const isQuantityPositive = quantity > (min || 0);

  const validateOperations = useCallback(
    (value: number) => {
      setMaxDisabled(false);

      setMinDisabled(!(value > (min ?? 0)));
      if (max !== undefined) {
        const canIncrement = value < max;
        setMaxDisabled(!canIncrement);
      }
    },
    [min, max]
  );

  const handleDecrement = useCallback(
    (event: React.MouseEvent<HTMLIonButtonElement>) => {
      if (minDisabled || disableDecrement) return;
      if (preventCallback) return;
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      if (onChange) {
        onChange(event, { quantity: newQuantity });
      }
      if (decrement) decrement();
    },
    [minDisabled, disableDecrement, preventCallback, quantity, onChange, decrement, setQuantity]
  );
  const handleIncrement = useCallback(
    (event: React.MouseEvent<HTMLIonButtonElement>) => {
      if (maxDisabled || disableIncrement) return;
      if (preventCallback) return;
      const newQuantity = quantity + 1;

      setQuantity(quantity + 1);
      if (onChange) onChange(event, { quantity: newQuantity });
      if (increment) increment();
    },
    [maxDisabled, disableIncrement, preventCallback, quantity, onChange, increment, setQuantity]
  );

  useEffect(() => {
    validateOperations(quantity);
  }, [quantity, validateOperations]);

  const decrementButtonAndQuantity = (
    <>
      <Button
        className={classNames(styles.button, { [styles.greyOut]: disableDecrement })}
        onClick={(event) => {
          onAddOrQuantityButtonClick && onAddOrQuantityButtonClick();
          handleDecrement(event);
        }}
        {...(minDisabled || disableDecrement ? { disabled: true } : {})}
        aria-label={`${itemName} - ${decreaseLabel}`}
        data-testid={`${testId}-decrement-button`}
      >
        <MinusSignIcon />
      </Button>
      <p className={styles.quantityValue} data-testid={`${testId}-value`}>
        {showOnlyParentValue ? parsedValue : quantity}
      </p>
    </>
  );

  const incrementButton = (
    <Button
      className={classNames(styles.button, { [styles.greyOut]: disableIncrement })}
      onClick={(event) => {
        onAddOrQuantityButtonClick && onAddOrQuantityButtonClick();
        handleIncrement(event);
      }}
      {...(maxDisabled || disableIncrement ? { disabled: true } : {})}
      aria-label={`${itemName} - ${increaseLabel}`}
      data-testid={`${testId}-increment-button`}
    >
      <PlusSignIcon />
    </Button>
  );

  return (
    <div
      className={classNames(styles.quantityWrapper, {
        [styles.quantityWrapperInline]: displayInline,
        [styles.quantityWrapperBig]: displayBig,
      })}
      data-testid={`${testId}-wrapper`}
    >
      {showDecrementAtMinQuantity
        ? isQuantityPositive && decrementButtonAndQuantity
        : decrementButtonAndQuantity}
      {incrementButton}
    </div>
  );
};

export default QuantitySetter;
