import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { usePrefetch } from '../../api/index';
import Home from '../../components/Home';
import { pagePaths } from '../../config';
import Comment from '../Comment/Comment';
import ComplainSuccess from '../ComplainSuccess/ComplainSuccess';
import CreateTunnel from '../CreateTunnel/CreateTunnel';
import Details from '../Details';
import FeedbackSuccess from '../FeedbackSuccess/FeedbackSuccess';
import QrCreateTunnel from '../QrCreateTunnel/QrCreateTunnel';
import RequestFeedback from '../RequestFeedback';
import Tutorials from '../Tutorials/Tutorials';

import { Filtering } from '@/components/atoms/Filters/Filters.types';
import { isMyVillage } from '@/modules/Core/helpers/helpers';
import { State } from '@/types/state.types';

import './ServiceRequest.css';

const ServiceRequest = () => {
  const site = useSelector((state: State) => state.Core.context.site);
  const [srListSearchString, setSrListSearchString] = useState('');
  const [srListFiltering, setSrListFiltering] = useState<Filtering>({});
  const prefetchLocations = usePrefetch('getLocations');

  useEffect(() => {
    if (site?.id && !isMyVillage()) {
      prefetchLocations({
        siteId: site.id,
      });
    }
  }, [site, prefetchLocations]);

  const handleSRListSearchChange = (searchString: string) => {
    setSrListSearchString(searchString);
  };

  const handleSRListFilteringChange = (filtering: Filtering) => {
    setSrListFiltering(filtering);
  };

  return (
    <div>
      <Switch>
        <Route path={pagePaths['Create']} children={<CreateTunnel />} />
        <Route path={pagePaths['QrCreate']} children={<QrCreateTunnel />} />
        <Route path={pagePaths['Details']} children={<Details />} />
        <Route path={pagePaths['Comment']} children={<Comment />} />
        <Route path={pagePaths['Feedback']} children={<RequestFeedback />} />
        <Route path={pagePaths['FeedbackSuccess']} children={<FeedbackSuccess />} />
        <Route path={pagePaths['ComplainSuccess']} children={<ComplainSuccess />} />
        <Route path={pagePaths['Tutorials']} children={<Tutorials />} />
        <Route
          path={pagePaths['Module']}
          children={
            <Home
              parentSearch={{
                searchString: srListSearchString,
                handleSearchChange: handleSRListSearchChange,
              }}
              parentFilter={{
                filtering: srListFiltering,
                handleFilteringChange: handleSRListFilteringChange,
              }}
            />
          }
        />
      </Switch>
    </div>
  );
};
export default ServiceRequest;
