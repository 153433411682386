import classNames from 'classnames';
import React from 'react';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Loader from '../../atoms/Loader/Loader';

import { LoadingPageProps } from './LoadingPage.types';

import styles from './LoadingPage.module.css';

const LoadingPage = React.memo(({ srLoadingText, 'data-testid': dataTestId }: LoadingPageProps) => {
  const { label } = useCustomTranslation();
  return (
    <div
      className={classNames(styles.loadingPage, 'loading')}
      aria-live="polite"
      data-testid={dataTestId}
    >
      <span className="sr-only">
        {srLoadingText ?? label('Ref: Loading page content, please wait')}
      </span>
      <Loader data-testid="loading-page" />
    </div>
  );
});

export default LoadingPage;
