import { screen, cleanup, render, act } from '@testing-library/react';

import { addProviders } from '../../../../../helpers/tests/addProviders';
import { defaultState as coreDefaultState } from '../../../../../modules/Core/reducers/coreReducer';
import * as hooks from '../../../api';
import ProductDetails from '../ProductDetails';

const menus = [
  {
    id: '42982bcf-6ae1-ec11-b5cf-0003ff857a05',
    name: 'Brockman 4 Concept + Crib',
    dayparts: [
      {
        name: 'CRIB',
        sections: [
          {
            items: [
              {
                id: '673be8e8-6ae1-ec11-bb3d-00224892f217',
                name: 'Mediterranean Veg Cheese and Pesto Focaccia',
                description: 'This is the product description',
              },
            ],
          },
        ],
      },
    ],
  },
];

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: () => ({
    id: '673be8e8-6ae1-ec11-bb3d-00224892f217',
    facilityId: 'facilityId',
    date: '2022-10-18',
  }),
}));

jest.mock('../../../api', () => ({
  useGetMenusQuery: jest.fn(),
}));

describe('ProductDetails', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    describe('when there is no item data', () => {
      let loader: HTMLElement;

      beforeEach(async () => {
        (hooks.useGetMenusQuery as jest.Mock).mockReturnValue({
          data: { menus: [] },
          isFetching: true,
        });

        await act(async () => {
          render(addProviders(<ProductDetails />));
        });
      });

      beforeEach(() => {
        loader = screen.getAllByText(/Loading/i)[0];
      });

      it('should display loading screen', () => {
        expect(loader).toBeTruthy();
      });
    });

    describe('when there is item data', () => {
      let title: HTMLElement, description: HTMLElement;

      beforeEach(async () => {
        (hooks.useGetMenusQuery as jest.Mock).mockReturnValue({
          data: { menus },
          isFetching: false,
        });

        await act(async () => {
          render(addProviders(<ProductDetails />, { state: { Core: coreDefaultState } }));
        });
      });

      beforeEach(() => {
        title = screen.getByText(/Mediterranean/i);
        description = screen.getByText(/description/i);
      });

      it('should display item data', () => {
        expect(title).toBeTruthy();
        expect(description).toBeTruthy();
      });
    });
  });
});
