const labels = {
  All: {
    Feedback: '反馈',
    'Ref: Switch site component intro sentence': '您的反馈是针对{site name}。',
    Category: '类别',
    Subject: '标题',
    Comment: '备注',
    'Date of your experience': '您所反馈问题的日期 *',
    'Ref: Hint': '您有需要解决的问题或工作吗？',
    'Ref: Log Request': '创建服务请求',
    'Ref: Describe issue': '请告诉我们您的经历',
    Date: '日期',
    'Ref: Share your opinion header': '请分享您的看法',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': '餐厅 /咖啡馆',
    'Desk / workstation area': '办公桌 /工作站区域',
    'Meeting room facilities': '会议室设施',
    'Coffee/tea facilities (pantry)': '咖啡/茶设施（储藏室）',
    'Restrooms / washrooms': '洗手间 /洗手间',
    'Reception / Security': '接收 /安全',
    'Conference center': '会议中心',
    'Fitness center': '健身中心',
    Mailroom: '邮件室',
    'Bicycle room': '自行车室',
    'Shuttle services': '穿梭服务',
    Lockers: '储物柜',
    Helpdesk: '服务台',
  },
  Create: {
    'Tell us!': '期待您的反馈！',
    'Ref: All Ears': '我们洗耳恭听！',
    'Ref: Provide Description': '请描述您所碰到的问题',
    'Ref: Fill-in-the-blank sentence': '我想针对 {1} 进行 {0}',
    'Ref: Link text to service request module': '请求或问题？ 都可以通过这里提交！',
    'Complaint type': '投诉{emoji}',
    'Feedback type': '反馈{emoji}',
    'Compliment type': '表扬{emoji}',
  },
  CreateFailure: {
    'Ref: Body': '发生错误，您的反馈未提交成功。如果此错误仍然存​​在，请联系您的服务台。',
  },
  CreateSuccess: {
    'Ref: Body': '您的反馈已提交。我们的现场支持团队将认真对待。我们会尽快在适当的时候回复您。',
    'New feedback': '新反馈',
  },
  CreateFlow: {
    Description: '描述',
    'Ref: Provide Description': '请提供日期和说明',
    'Ref: I would like to provide a': '我想提供一个',
    'Ref: in regards to': '关于',
    'Ref: &': '和',
    'Ref: at': '在',
    'Ref: on the': '在',
  },
};
export default labels;
