const labels = {
  All: {
    'Ref: See item': 'Katso kohde',
    'Ref: Print': 'Tulosta kuitti',
    'Ref: Email': 'Vastaanota kuitti sähköpostitse',
    'Ref: Receipt Modal message': 'Kuinka haluaisit saada kuitin?',
    'Ref: Loyalty Reward': 'Uskollisuuspalkinto',
    'Ref: Equation for stamps per item': '1 osto = 1 leima',
    'Ref: Paid by employer': 'Työnantajan maksama summa',
    'Ref: Paid by user': 'Maksamasi summa',
  },
  ProductsList: {
    'Ref: Page title': 'Tuotelista',
    'Ref: Facility details': 'Laitoksen yksityiskohdat',
    'Ref: Search products list': 'Etsi kohdetta',
    'Ref: filter label: menus': 'Valikko',
    'Ref: filter label: moment': 'Hetki',
    'Ref: filter label: category': 'Kategoria',
    'Ref: all': 'Kaikki',
    'Ref: today': 'Tänään',
    'Ref: tomorrow': 'Huomenna',
    'Ref: see more': 'Katso lisää',
    'Ref: see less': 'Katso vähemmän',
    'Ref: reset': 'Nollata',
    'Ref: View in cart': 'Näkymä ostoskorissa',
    'Ref: You have changed location': 'Olet vaihtanut sijaintia',
    'Ref: You are now at': 'Olet nyt',
    'Ref: Menu card': 'Valikkokortti',
    'Ref: Table': 'Taulukonumero',
    'Service unavailable': 'Palvelu ei saatavilla',
    'Ref: Ordering unavailable':
      'Varovasti! Tilaaminen tällä sivustolla ei ole tällä hetkellä käytettävissä, yritä myöhemmin.',
    'Ref: Filters': 'Suodattimet (allergeenit ja muut)',
  },
  Order: { 'Ref: Page title': 'Tilaus' },
  MenuSelector: {
    'Ref: Menu Page title': 'Valikot',
    'Ref: today': 'Tänään',
    'Ref: tomorrow': 'Huomenna',
    'Ref: Search products list': 'Etsi kohdetta',
    'Ref: You have changed location': 'Olet vaihtanut sijaintia',
    'Ref: You are now at': 'Olet nyt',
  },
  ProductDetails: {
    'Ref: Page title': 'tuotteen yksityiskohdat',
    'Ref: Serving Size': 'Annoskoko',
    'Ref: Nutrition': 'Ravitsemustietoa',
    'Ref: Nutrition adults': 'Aikuiset tarvitsevat noin 2000 kcal päivässä',
    'Ref: Nutrition Calories': 'Kaloreita',
    'Ref: Nutrition Calcium': 'Kalsium',
    'Ref: Nutrition Fat': 'Rasva',
    'Ref: Nutrition Saturated Fat': 'Tyydyttynyt rasva',
    'Ref: Nutrition Carbohydrates': 'Hiilihydraatit',
    'Ref: Nutrition Sugar': 'Sokeri',
    'Ref: Nutrition Protein': 'Proteiini',
    'Ref: Nutrition Sodium': 'Natrium',
    'Ref: Nutrition cholesterol': 'Kolesteroli',
    'Ref: Nutrition dietaryFiber': 'Ravintokuitu',
    'Ref: Nutrition iron': 'Rauta',
    'Ref: Nutrition potassium': 'Kalium',
    'Ref: Nutrition vitaminA': 'A-vitamiini',
    'Ref: Nutrition vitaminC': 'C-vitamiini',
    'Ref: Additives': 'Lisäaineet',
    'Ref: Emission': 'Hiilidioksidipäästöt',
    'Ref: This product generates': 'Tämä tuote tuottaa',
    'Ref: of CO2': 'CO2: n',
    'Ref: emissions, which equals a': 'päästöt, mikä vastaa',
    'Ref: car trip': 'Automatka',
    'Ref: Ingredients': 'Resepti-ainesosa',
    'Ref: Allergens': 'Allergeenit',
    'Ref: Always there': 'Aina siellä',
    'Ref: Allergens selected': 'Suodatin valitut allergeenit:',
    'Ref: minimum required': 'Vaadittu vähimmäismäärä',
    'Ref: you have to add at least': 'Sinun on lisättävä ainakin',
    'Ref: ingredients': 'ainesosat',
    'Ref: Ingredient': 'ainesosa',
    'Ref: Free': 'Vapaa',
    'Ref: Based on customization': 'Räätälöinnin perusteella',
    'Ref: Additives eggs': 'munat',
    'Ref: Additives fish': 'kalastaa',
    'Ref: Additives milk': 'maito',
    'Ref: Additives nuts': 'pähkinä',
    'Ref: Additives wheat': 'vehnä',
    'Ref: Additives peanuts': 'maapähkinö',
    'Ref: Additives soy beans': 'soijapavut',
    'Ref: Additives sesame seeds': 'seesaminsiemeniä',
    'Ref: Review': 'Arvostelu',
    'Ref: Per portion': 'Per annos',
    'Ref: Per 100g': 'Per 100 g',
    'Ref: Allergen selected': 'Allergeeni, joka on valittu hakasuodattimessa',
    'Ref: Fitbit Log': 'Loki Fitbit',
    'Ref: age verification required on delivery': 'Ikävarmennus vaaditaan toimituksessa',
    'Ref: alcohol content notification':
      'Asettamalla ja tilaamalla jokin näistä esineistä ilmoitat, että olet 18 -vuotias tai yli. Alkoholin väärinkäyttö on vaarallista terveydellesi.',
    'Ref: default': 'Laiminlyönti',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {vvvv} ansaitut, jos tilaat tämän tuotteen',
    'Ref: Loyalty Card Description':
      '{stamp_form} lisätään seuraavaan uskollisuuteen {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Lietaalisuusleimat -ohjelman avulla voit kerätä postimerkkejä palkintoja varten.',
    'Ref: Loyalty Modal Description':
      'Kun ostat <b> {product_name}, 1 leima </b> lisätään <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'ja',
    'Ref: Stamp': 'Leima',
    'Ref: Stamps': 'Leimat',
    'Ref: Program': 'Ohjelmoida',
    'Ref: Programs': 'Ohjelmat',
    'Ref: Loyalty Stamps': 'Kanta -asiakasleimat',
    'Ref: See Loyalty Stamps': 'Katso kanta -asiakasleimat',
    Favorite: 'Suosikki',
    'Ref: In favorites': 'Tuote on jo suosikkiluettelossa. Napsauta poistaaksesi sen.',
    'Ref: Not in favorites': 'Tuote ei ole vielä suosikkiluettelossa. Napsauta lisätäksesi sen.',
    'Ref: Added to favorites': 'Tuote on lisätty suosikkiluetteloon.',
    'Ref: Removed from favorites': 'Tuote on poistettu suosioluettelosta.',
    'Ref: From': 'Alkaen',
  },
  OrderHistory: { 'Ref: Orders': 'Määräys', 'Ref: Page Title': 'Tilaukseni' },
  MenuTile: { 'Ref: Scan&Go': 'Skannata ja mennä', 'Ref: View only': 'Vain näkymä' },
  ProductTile: {
    'Ref: Allergens': 'Allergeenit',
    'Ref: Presence of allergen': 'Allergeenin läsnäolo',
    'Ref: Scan': 'Skannata',
    'Ref: From': 'Alkaen',
    'Ref: Already in product favorite list. Click to remove it.':
      'Jo tuote -suosikkiluettelossa. Napsauta poistaaksesi sen.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'Tällä hetkellä ei lisätty tuotteiden suosikkiluetteloon. Napsauta lisätäksesi sen.',
    'Ref: Free': 'Vapaa',
  },
  MenusWidget: {
    'Ref: no menu': 'Ei tällä hetkellä valikkoa',
    'Ref: Modal title': 'Päivän valikko',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Noukkia',
    'Ref: Eat-in': 'Syöminen',
    'Ref: Scan&Go': 'Skannata ja mennä',
    'Ref: Delivery': 'Toimitus',
    'Ref: Total': 'Kaikki yhteensä',
    'Ref: How was facility name service': 'Kuinka {facility_name} ´S -palvelu oli?',
    'Ref: How do you rate the service': 'Kuinka arvioit palvelun?',
  },
};
export default labels;
