import { useMemo } from 'react';
import nl2br from 'react-nl2br';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { APP_ERRORS } from '../../../../constants';
import { SERVICE } from '../../../config';
import GenericErrorPage from '../../../Core/components/GenericErrorPage';
import { checkIfServiceAvailable } from '../../../services';
import { useGetContentQuery } from '../../api';
import { contentConfig, pagePaths, moduleId } from '../../config';
import useTrackContentViews from '../../helpers/trackContentViews.helper';
import { useToggleReaction } from '../../hooks';
import { useContentTranslation } from '../../hooks/useContentTranslation';
import { IContent } from '../../types';

import { Calendar2Icon, ThumbsUpIcon } from '@/assets/icons';
import { ShareIcon } from '@/assets/index';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import { MarkDownContent } from '@/components/atoms/RenderContent';
import Title, { TITLE_TAG, TITLE_SIZE } from '@/components/atoms/Title';
import Card from '@/components/molecules/Card/Card';
import ContentTitle from '@/components/molecules/ContentTitle/ContentTitle';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import ReactionsSummary from '@/components/molecules/ReactionsSummary/ReactionsSummary';
import SecondaryActions from '@/components/molecules/SecondaryActions/SecondaryActions';
import List from '@/components/organisms/List/List';
import { ListItemProps } from '@/components/organisms/List/List.types';
import Reactions from '@/components/organisms/Reactions';
import ContentDetailsPage from '@/components/templates/ContentDetailsPage';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import { formatDate } from '@/helpers/dateTime';
import useResourcePermissions from '@/helpers/hooks/useResourcePermissions';
import { useSetupOption } from '@/helpers/hooks/useSetupOption/useSetupOption';
import useShareModal from '@/helpers/hooks/useShareModal';
import { isExternalUrl } from '@/helpers/misc';
import { reactionsWithLabels } from '@/helpers/reactions.helpers';
import { getEventDetailsLink } from '@/modules/Events/helpers/eventDates.helper';
import { getSurveyDetailsLink } from '@/modules/Surveys/helpers/surveys.helper';
import { PermissionErrors } from '@/types/errors.types';
import { State } from '@/types/state.types';

import styles from './ContentDetails.module.css';
const ContentDetails = () => {
  const isEventsModuleConfigured = checkIfServiceAvailable(SERVICE.EVENT);
  const isSurveysModuleConfigured = checkIfServiceAvailable(SERVICE.SURVEY);
  const { label } = useContentTranslation(__filename);
  const displayContentCategories = useSetupOption('displayContentCategories', SERVICE.CONTENT);
  const site = useSelector((state: State) => state.Core.context.site || { id: '', name: '' });
  const contract = useSelector((state: State) => state.Core.user.contract);
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);

  const history = useHistory();
  const params = useParams<Pick<IContent, 'id'>>();

  const { isReactionsEnabled, isCategoryEnabled } = contentConfig();

  const {
    data: details = {},
    error,
    isLoading,
  }: any = useGetContentQuery({
    id: params.id,
    siteId: site.id,
  });

  const { mainImage } = details;

  const { onDifferentSite, notFound, permissionDenied } = useResourcePermissions({
    responseStatus: error?.status,
    responseData: details,
    ok: true,
  });

  const { shareOptionsModal, triggerShareModal } = useShareModal({ label });

  const [toggleReaction] = useToggleReaction();

  useTrackContentViews({
    title: details?.title,
    site,
    contract: { id: contract?.id || '', name: contract?.name || '' },
    language: currentLanguageCode,
    moduleId,
  });

  const reactions = useMemo(() => {
    return reactionsWithLabels(label, details?.reactions?.reactions);
  }, [details?.reactions?.reactions, label]);

  if (notFound) {
    return <GenericErrorPage errors={[APP_ERRORS.NOT_FOUND]} />;
  }

  if (permissionDenied) {
    return <GenericErrorPage errors={[permissionDenied as PermissionErrors]} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  const handleShare = () =>
    triggerShareModal(details.title, pagePaths.ContentDetails.replace(':id', details.id));

  const title = (
    <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
      {details.title}
    </Title>
  );

  const externalLinks: ListItemProps[] = [];
  const contentDate: ListItemProps[] = [];
  let category: string = '';

  if ((displayContentCategories.isActive && details.type) || (isCategoryEnabled && details.type)) {
    category = details.type;
  }

  if (details?.link?.url && isExternalUrl({ to: details?.link?.url })) {
    externalLinks.push({
      id: 'externalLink',
      'data-testid': `external-${details?.link?.url}`,
      label: details?.link?.text ? (
        <Title size={TITLE_SIZE.BODYSBOLD} className="underlinedText">
          {details?.link?.text}
        </Title>
      ) : (
        <Title size={TITLE_SIZE.BODYSBOLD} className="underlinedItalicText">
          {label('Ref: External link')}
        </Title>
      ),
      externalPath: details.link.url,
    });
  }

  const createCard = (cardItems: ListItemProps[]) => {
    return cardItems.length > 0 ? (
      <Card>
        <List data-testid="content-details-list" items={cardItems} />
      </Card>
    ) : (
      <></>
    );
  };

  const body = (
    <>
      {(details?.markdownDecoded || details.details) && (
        <Card>
          {details.details && nl2br(details.details)}
          {details?.markdownDecoded && (
            <div className={styles.markdownWrapper}>
              <MarkDownContent
                data-testid="content-details-markdown-content"
                language={currentLanguageCode}
              >
                {details?.markdown!}
              </MarkDownContent>
            </div>
          )}
        </Card>
      )}
    </>
  );

  let actionLabel;
  let url: string | undefined;
  if (details.linkedEntityId) {
    switch (details.linkedEntity) {
      case 'Event':
        actionLabel = label('See the event');
        url = isEventsModuleConfigured ? getEventDetailsLink(details.linkedEntityId) : undefined;
        break;
      case 'Survey':
        actionLabel = label('Take the survey');
        url = isSurveysModuleConfigured ? getSurveyDetailsLink(details.linkedEntityId) : undefined;
        break;
      default:
    }
  }

  if (details.publishedDate) {
    contentDate.push({
      id: 'date',
      label: <>{formatDate(new Date(details.publishedDate), currentLanguageCode)}</>,
      'data-testid': `content-details-date-item-${url}`,
      icons: [
        {
          icon: Calendar2Icon,
        },
      ],
      value: url ? (
        <span
          onClick={() => history.push(url || '')}
          onKeyDown={(event) => handleKeyDown(event, url || '')}
          role="button"
          tabIndex={0}
        >
          <Title size={TITLE_SIZE.BODYSBOLD} className="underlinedText">
            {actionLabel}
          </Title>
        </span>
      ) : (
        ''
      ),
    });
  }

  const CustomReactions = () => (
    <div className={styles.reactionsWrapper}>
      {isReactionsEnabled && (
        <ReactionsSummary {...details.reactions} className={styles.reactionsSummary} />
      )}
      <SecondaryActions>
        {isReactionsEnabled && (
          <Reactions.Detail
            key={`${details.id}_${details.reactions.me}`}
            userReaction={details.reactions.me}
            reactions={reactions}
            handleReactionClick={(reaction) =>
              toggleReaction(reaction, details, site.id, currentLanguageCode)
            }
            mainButtonValues={{
              icon: ThumbsUpIcon,
              label: label('Ref: Reaction - LIKE'),
            }}
          />
        )}
        <Button
          look={BUTTON_LOOK.TERTIARY}
          onClick={handleShare}
          affix={ShareIcon}
          data-testid="share-button"
          key={`share-button-${details.id}`}
        >
          {label('Ref: Share')}
        </Button>
      </SecondaryActions>
      {shareOptionsModal}
    </div>
  );

  const handleKeyDown = (event: React.KeyboardEvent, url: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      history.push(url);
    }
  };

  return (
    <ContentDetailsPage.WithNotification
      title={details.title ?? ''}
      header={{
        imageSrc: mainImage,
        imageAlt: details.title,
        children: (
          <ContentTitle
            data-testid="content-tile"
            title={title}
            subtitle={category}
            className={styles.titleSide}
          />
        ),
      }}
      actions={CustomReactions()}
      notificationContent={
        onDifferentSite && (
          <Notification
            title={label('Ref: Content site different title')}
            look={NOTIFICATION_LOOK.INFO}
          >
            {label('Ref: Content site different description')}
          </Notification>
        )
      }
      tabTitle={details.title}
    >
      <ContentTitle
        data-testid="content-tile"
        title={title}
        subtitle={category}
        className={styles.titleMain}
      />
      {createCard(externalLinks)}
      {body}
      {createCard(contentDate)}
    </ContentDetailsPage.WithNotification>
  );
};

export default ContentDetails;
