const labels = {
  All: {
    'starts at': 'Mulai dari.',
    'ends at': 'berakhir di',
    location: 'Lokasi',
    'coming date': 'Datang berkencan',
    'Ref: Currently event not preferred. Click to make preferred':
      'Saat ini acara tidak disukai. Klik untuk disukai',
    'Ref: Currently event preferred. Click to make not preferred':
      'Saat ini acara lebih disukai. Klik untuk tidak disukai',
  },
  EventDetails: {
    details: 'Detail',
    'Ref: Page title': 'detail acara',
    'Event dates': 'Tanggal acara',
    'See online:': 'Lihat Online:',
    'See all dates': 'Lihat semua tanggal',
    'Remove from my selection': 'Hapus dari pilihan saya',
    'Add to my selection': 'Tambahkan ke pilihan saya',
    'Ref: explain calendar usage':
      'Tanggal acara disorot di kalender di bawah ini. Jangan lewatkan peristiwa yang Anda suka! Klik tanggal acara di kalender untuk mengunduh undangan. Kemudian buka file yang diunduh untuk menambahkan acara ke kalender yang Anda sukai.',
    'Ref: Favorite': 'Favorit',
    'Ref:Feedback': 'Masukan',
    'Ref: Event site different title': 'Acara ini bukan milik situs Anda saat ini',
    'Ref: Event site different description':
      'Anda melihat acara yang bukan milik situs Anda saat ini. Anda tidak akan dapat melihatnya dalam daftar acara.',
    'Ref: See all dates': 'Lihat semua tanggal',
    'Ref: From': 'Dari',
    'Ref: to': 'ke',
    'Ref: Coming date': 'Tanggal yang akan datang',
    'Ref: Add to calendar': 'Tambahkan ke Kalender',
  },
  EventsList: {
    'Ref: Page title': 'Acara',
    'All dates': 'Semua tanggal',
    'All events': 'Semua acara',
    'Ref: Search': 'Mencari',
    'My selection': 'Saya disukai',
    'filter label: date': 'Tanggal',
    'filter label: coming events': 'Acara yang akan datang',
    'filter label: past events': 'acara masa lalu',
    'filter label: interest': 'Pilihan',
  },
  Widget: {
    'Ref: Carousel title': 'Acara',
    'No info yet, come back later': 'Belum ada info, kembali lagi 😉',
  },
};
export default labels;
