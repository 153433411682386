const labels = {
  All: {
    'Ref: See item': 'アイテムを参照してください',
    'Ref: Print': '領収書を印刷します',
    'Ref: Email': '電子メールで領収書を受け取ります',
    'Ref: Receipt Modal message': '領収書をどのように入手しますか？',
    'Ref: Loyalty Reward': 'ロイヤルティ報酬',
    'Ref: Equation for stamps per item': '1購入= 1スタンプ',
    'Ref: Paid by employer': '雇用主が支払った金額',
    'Ref: Paid by user': 'あなたが支払った金額',
  },
  ProductsList: {
    'Ref: Page title': '製品リスト',
    'Ref: Facility details': '施設の詳細',
    'Ref: Search products list': 'アイテムを検索',
    'Ref: filter label: menus': 'メニュー',
    'Ref: filter label: moment': '時刻',
    'Ref: filter label: category': 'カテゴリー',
    'Ref: all': '全て',
    'Ref: today': '今日',
    'Ref: tomorrow': '明日',
    'Ref: see more': '更に表示',
    'Ref: see less': '表示しない',
    'Ref: reset': 'リセットする',
    'Ref: View in cart': 'カートを見る',
    'Ref: You have changed location': '場所を変更しました',
    'Ref: You are now at': '現在ここにいます',
    'Ref: Menu card': 'メニューカード',
    'Ref: Table': 'テーブル番号',
    'Service unavailable': 'サービスは利用できません',
    'Ref: Ordering unavailable': '現在利用できません。時間を空けてお試しください。',
    'Ref: Filters': 'フィルター（アレルゲン以上）',
  },
  Order: { 'Ref: Page title': '注文' },
  MenuSelector: {
    'Ref: Menu Page title': 'メニュー',
    'Ref: today': '今日',
    'Ref: tomorrow': '明日',
    'Ref: Search products list': '商品を検索',
    'Ref: You have changed location': '場所を変更しました',
    'Ref: You are now at': '現在ここにいます',
  },
  ProductDetails: {
    'Ref: Page title': '製品詳細',
    'Ref: Serving Size': 'サービングのサイズ',
    'Ref: Nutrition': '栄養成分表示',
    'Ref: Nutrition adults': '大人は1日約2000kcal必要です。',
    'Ref: Nutrition Calories': 'カロリー',
    'Ref: Nutrition Calcium': 'カルシウム',
    'Ref: Nutrition Fat': '脂肪',
    'Ref: Nutrition Saturated Fat': '飽和脂肪酸',
    'Ref: Nutrition Carbohydrates': '炭水化物',
    'Ref: Nutrition Sugar': '糖質',
    'Ref: Nutrition Protein': 'タンパク質',
    'Ref: Nutrition Sodium': 'ナトリウム',
    'Ref: Nutrition cholesterol': 'コレステロール',
    'Ref: Nutrition dietaryFiber': '食物繊維',
    'Ref: Nutrition iron': '鉄',
    'Ref: Nutrition potassium': 'カリウム',
    'Ref: Nutrition vitaminA': 'ビタミンA',
    'Ref: Nutrition vitaminC': 'ビタミンC',
    'Ref: Additives': '添加物',
    'Ref: Emission': 'CO2排出量',
    'Ref: This product generates': 'この商品はCO2を生成します',
    'Ref: of CO2': 'CO2の',
    'Ref: emissions, which equals a': 'Aに等しい排出量',
    'Ref: car trip': '車に換算',
    'Ref: Ingredients': 'レシピ成分',
    'Ref: Allergens': 'アレルギー',
    'Ref: Always there': '常にそこに',
    'Ref: Allergens selected': 'フィルター上で選択されたアレルゲン：',
    'Ref: minimum required': '最低限必要です',
    'Ref: you have to add at least': '追加する必要があります',
    'Ref: ingredients': '材料',
    'Ref: Ingredient': '成分',
    'Ref: Free': '無料',
    'Ref: Based on customization': 'カスタマイズに基づいています',
    'Ref: Additives eggs': '卵',
    'Ref: Additives fish': '魚',
    'Ref: Additives milk': 'ミルク',
    'Ref: Additives nuts': 'ナッツ',
    'Ref: Additives wheat': '小麦',
    'Ref: Additives peanuts': 'ピーナッツ',
    'Ref: Additives soy beans': '大豆',
    'Ref: Additives sesame seeds': 'ゴマ',
    'Ref: Review': 'レビュー',
    'Ref: Per portion': '部分ごと',
    'Ref: Per 100g': '100gあたり',
    'Ref: Allergen selected': '検索フィルターで選択されたアレルゲン',
    'Ref: Fitbit Log': 'Fitbitにログを記録します',
    'Ref: age verification required on delivery': '配達時に必要な年齢検証',
    'Ref: alcohol content notification':
      'これらのアイテムのいずれかを配置して注文することにより、18歳以上であることを宣言します。アルコール乱用はあなたの健康にとって危険です。',
    'Ref: default': 'デフォルト',
    'Ref: Loyalty Card Title Stamp': '{no_stamps} {stamp_form}この製品を注文すると獲得しました',
    'Ref: Loyalty Card Description':
      '{stamp_form}は、次のロイヤルティに追加されます{program_form}：<b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Loyalty Stampsプログラムを使用すると、報酬のためにスタンプを収集できます。',
    'Ref: Loyalty Modal Description':
      '<b> {product_name}を購入すると、1つのスタンプ</b>が<b> {scheme_names} </b> {program_form}に追加されます。',
    'Ref: and': 'と',
    'Ref: Stamp': 'スタンプ',
    'Ref: Stamps': 'スタンプ',
    'Ref: Program': 'プログラム',
    'Ref: Programs': 'プログラム',
    'Ref: Loyalty Stamps': 'ロイヤルティスタンプ',
    'Ref: See Loyalty Stamps': 'ロイヤルティスタンプを参照してください',
    Favorite: 'お気に入り',
    'Ref: In favorites': '製品はすでにお気に入りのリストにあります。クリックして削除します。',
    'Ref: Not in favorites': '製品はまだお気に入りのリストにありません。クリックして追加します。',
    'Ref: Added to favorites': '製品はお気に入りリストに追加されています。',
    'Ref: Removed from favorites': '製品はお気に入りのリストから削除されました。',
    'Ref: From': 'から',
  },
  OrderHistory: { 'Ref: Orders': '注文', 'Ref: Page Title': '私の注文' },
  MenuTile: { 'Ref: Scan&Go': 'スキャン＆ゴー', 'Ref: View only': '表示のみ' },
  ProductTile: {
    'Ref: Allergens': 'アレルゲン',
    'Ref: Presence of allergen': 'アレルゲンの存在',
    'Ref: Scan': 'スキャン',
    'Ref: From': 'から',
    'Ref: Already in product favorite list. Click to remove it.':
      'すでに製品のお気に入りのリストに載っています。クリックして削除します。',
    'Ref: Currently not added to product favorite list. Click to add it.':
      '現在、製品のお気に入りリストに追加されていません。クリックして追加します。',
    'Ref: Free': '無料',
  },
  MenusWidget: {
    'Ref: no menu': '今のところメニューはありません',
    'Ref: Modal title': 'その日のメニュー',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': '選び出す',
    'Ref: Eat-in': 'イートイン',
    'Ref: Scan&Go': 'スキャン＆ゴー',
    'Ref: Delivery': '配達',
    'Ref: Total': '合計',
    'Ref: How was facility name service': '{facility_name}サービスはどうでしたか？',
    'Ref: How do you rate the service': 'サービスをどのように評価しますか？',
  },
};
export default labels;
