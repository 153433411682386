import { Skeleton } from '../../../../../components/atoms/Skeleton';

const TitleSkeleton = () => (
  <div data-testid="skeleton-title">
    <div>
      <Skeleton width="25%" />
    </div>
    <div>
      <Skeleton width="50%" />
    </div>
    <div>
      <Skeleton width="50%" />
    </div>
  </div>
);

export default TitleSkeleton;
