const labels = {
  TimeTable: {
    'leave at': 'Vertrekken om',
    'arrive at': 'Aankomen om',
    at: 'Om',
    'Ref: list placeholder': 'Vul het formulier in om de uren te zien',
    'Ref: Page title': 'Bussen',
    'Ref: Departure': 'Vertrek',
    'Ref: Arrivals': 'Aankomsten',
    'Ref: minutes': 'minuten',
    'Ref: no items placeholder': 'Sorry, er zijn op dit moment geen bussen beschikbaar.',
  },
};
export default labels;
