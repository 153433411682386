const labels = {
  TimeTable: {
    'leave at': 'Odejít na',
    'arrive at': 'Dorazí na',
    at: 'v',
    'Ref: list placeholder': 'Vyplňte formulář a podívejte se na hodiny',
    'Ref: Page title': 'Autobusy',
    'Ref: Departure': 'Odchod',
    'Ref: Arrivals': 'Příjezdy',
    'Ref: minutes': 'minut',
    'Ref: no items placeholder': 'Omlouvám se, v tuto chvíli nejsou k dispozici žádné autobusy.',
  },
};
export default labels;
