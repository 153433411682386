import Radio from '../../../../../components/atoms/Radio';
import RadioGroup from '../../../../../components/molecules/RadioGroup';

import { SingleChoiceProps } from './SingleChoice.types';

const SingleChoice = ({ id, choices, value, ...props }: SingleChoiceProps) => {
  return (
    <RadioGroup
      {...props}
      name={id}
      value={value}
      onClick={(e: React.FormEvent<HTMLInputElement>, v?: string) => {}}
    >
      {choices.map((choice: string) => (
        <RadioGroup.Controller
          key={`${id}_${choice}`}
          label={choice}
          value={choice}
          controlId={`${id}_${choice}`}
          control={<Radio data-testid={`single-choice-radio-button`} />}
        />
      ))}
    </RadioGroup>
  );
};

export default SingleChoice;
