const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Hizmet İsteği - Yardım Masası',
    'Ref: Forbidden heading': 'Yasaklı',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      '{siteName} sitesinde hizmet isteklerini yönetiyorsunuz.',
    'Ref: Dates': 'Tarih',
    'Ref: SRL list refresh': 'Yenileme Listesi',
    'Ref: Export': 'ihracat',
    'Ref: Empty/not filled': 'Boş / Doldurulmamış',
    'Ref: Name': 'İsim',
    'Ref: Date': 'Tarih',
    'Ref: Category': 'Kategori',
    'Ref: User': 'Kullanıcı',
    'Ref: Location': 'Konum',
  },
};
export default labels;
