import classNames from 'classnames';

import { RowLayoutProps } from './RowLayout.types';

import styles from './RowLayout.module.css';

const RowLayout = ({ children, image, 'data-testid': testId }: RowLayoutProps) => {
  return (
    <div className={classNames(styles.layoutWrapper)} data-testid={`${testId}-wrapper`}>
      {image && (
        <div className={classNames(styles.imageRow)} data-testid={`${testId}-image-row`}>
          {image}
        </div>
      )}
      <div className={classNames(styles.row)} data-testid={`${testId}-children`}>
        {children}
      </div>
    </div>
  );
};

export default RowLayout;
