import { getFeedbackModuleConfig } from '../config';
import mockResponse from '../mockResponse';

import {
  GetAreaTypesSuccessResponse,
  GetFeedbackTypesSuccessResponse,
  GetServiceTypesSuccessResponse,
  GetSubjectTypesSuccessResponse,
  CreateFeedbackArgs,
} from './api.types';

const { api, version } = getFeedbackModuleConfig();

const feedbackApi = api.enhanceEndpoints({ addTagTypes: ['feedback'] }).injectEndpoints({
  endpoints: (build) => ({
    getFeedbackTypes: build.query<GetFeedbackTypesSuccessResponse, void>({
      query: () => ({
        url: `/${version}/feedback/feedbacktypes`,
        method: 'get',
        mockData: mockResponse.getFeedbackTypes,
      }),
      providesTags: ['feedback'],
    }),
    getServiceTypes: build.query<GetServiceTypesSuccessResponse, void>({
      query: () => ({
        url: `/${version}/feedback/servicetypes`,
        method: 'get',
        mockData: mockResponse.getServiceTypes,
      }),
      providesTags: ['feedback'],
    }),
    getAreaTypes: build.query<GetAreaTypesSuccessResponse, void>({
      query: () => ({
        url: `/${version}/feedback/areatypes`,
        method: 'get',
        mockData: mockResponse.getAreaTypes,
      }),
      providesTags: ['feedback'],
    }),
    getSubjectTypes: build.query<GetSubjectTypesSuccessResponse, void>({
      query: () => ({
        url: `/${version}/feedback/subjecttypes`,
        method: 'get',
        mockData: mockResponse.getSubjectTypes,
      }),
      providesTags: ['feedback'],
    }),
    createFeedback: build.mutation<void, CreateFeedbackArgs>({
      query: ({ withAccessToken, ...args }: CreateFeedbackArgs) => ({
        url: `/${version}/feedback`,
        method: 'post',
        data: { ...args },
        mockData: mockResponse.createFeedback,
        opts: { withoutAccessToken: !withAccessToken },
      }),
      invalidatesTags: ['feedback'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetFeedbackTypesQuery,
  useLazyGetServiceTypesQuery,
  useLazyGetAreaTypesQuery,
  useLazyGetSubjectTypesQuery,
  useCreateFeedbackMutation,
} = feedbackApi;

export default feedbackApi;
