import { Time } from '../../../../assets/icons';
import { TranslationProps } from '../../../../localization/localization.types';
import { CardWithH2TitleAndIcon } from '../../../../storybook/molecules/Card.stories';
import { TITLE_SIZE, TITLE_TAG } from '../../../atoms/Title';
import List from '../../../organisms/List/List';

import { getOpeningHoursItems } from './openingHours.helper';
import { OpenHoursFacility } from './openingHours.types';

import styles from './openHours.module.css';

const OpeningHours = ({ facility, label }: { facility: OpenHoursFacility } & TranslationProps) => {
  if (facility?.openingHours?.length) {
    const openingHoursItems = getOpeningHoursItems(facility.openingHours, label);

    return (
      <CardWithH2TitleAndIcon
        icon={{ icon: Time, className: styles.icon }}
        title={{
          tag: TITLE_TAG.H2,
          children: label('opening hours'),
          size: TITLE_SIZE.HEADLINEXS,
        }}
      >
        <List items={openingHoursItems} data-testid="openining-hours-list" />
      </CardWithH2TitleAndIcon>
    );
  }
  return null;
};

export default OpeningHours;
