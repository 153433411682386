import { MAX_VISITED_SITES_TO_DISPLAY } from '../config';
import { ActionType } from '../types/actions.types';
import { IRootState } from '../types/reducers.types';
import { ISite } from '../types/sites.types';

const defaultState: IRootState['Sites'] = {
  list: [],
  visited: [],
  loading: false,
  locks: {
    siteUpdateLock: false,
  },
};

const addSiteToVisitedWithLimit = (
  state: typeof defaultState,
  site: ISite,
  limit = MAX_VISITED_SITES_TO_DISPLAY
) => {
  const { visited } = state;
  const lastVisitedSitesWithoutAddedSite = visited.filter(
    (visitedSite) => visitedSite.id !== site.id
  );
  const newVisited = [site, ...lastVisitedSitesWithoutAddedSite];
  return {
    ...state,
    visited: newVisited.slice(0, limit),
  };
};

const sitesReducer = (state = defaultState, action: ActionType) => {
  switch (action.type) {
    case 'RESET_STATE':
      return defaultState;
    case 'SITES_UPDATING':
      return {
        ...state,
        locks: {
          ...state.locks,
          siteUpdateLock: true,
        },
      };
    case 'SITES_FETCHING':
      return {
        ...state,
        loading: true,
      };

    case 'SITE_FETCHING_SUCCESS':
      return {
        ...state,
        list: action.sites,
        loading: false,
      };
    case 'SITES_UPDATING_SUCCESS':
      return {
        ...state,
        locks: {
          ...state.locks,
          siteUpdateLock: false,
        },
      };
    case 'SITES_FETCHED':
      return {
        ...state,
        loading: false,
      };
    case 'ADD_LAST_VISITED_SITE':
      return addSiteToVisitedWithLimit(state, action.site);
    default:
      return state;
  }
};

export default sitesReducer;
