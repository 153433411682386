import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';
import { FormProvider, useForm } from 'react-hook-form';

import { AccommodationRequestFormFields } from '../../../types';
import CreateThankYou from '../CreateThankYou';

import renderComponent from '@/helpers/tests/renderComponent';
import { pagePaths } from '@/modules/AccommodationRequest/config';

const CreateThankYouTest = () => {
  const formMethods = useForm<AccommodationRequestFormFields>();
  return (
    <FormProvider {...formMethods}>
      <CreateThankYou />
    </FormProvider>
  );
};

describe('CreateThankYou', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let title: HTMLElement,
      content: HTMLElement,
      homeButton: HTMLElement,
      anotherRequestButton: HTMLElement;
    renderComponent(CreateThankYouTest);

    beforeEach(() => {
      title = screen.getByTestId('accomodation-request-success-title');
      content = screen.getByTestId('accomodation-request-success-text');
      homeButton = screen.getByTestId('button-action-primary');
      anotherRequestButton = screen.getByTestId('button-action-secondary');
    });

    it('should have displayed Thank you page components', () => {
      expect(title).toBeTruthy();
      expect(content).toBeTruthy();
      expect(homeButton).toBeTruthy();
      expect(anotherRequestButton).toBeTruthy();

      expect(screen.getByText('Ref: Thank you')).toBeTruthy();
      expect(screen.getByText('Ref: Success text')).toBeTruthy();
      expect(screen.getByText('Ref: Go to home')).toBeTruthy();
      expect(screen.getByText('Ref: Create another request')).toBeTruthy();
    });
  });

  describe('on "Go to home" button click', () => {
    let homeButton: HTMLElement;
    renderComponent(CreateThankYouTest);

    beforeEach(() => {
      homeButton = screen.getByTestId('button-action-primary');
    });

    it('should have redirected to homepage', () => {
      const history = createMemoryHistory();
      expect(homeButton).toBeTruthy();
      expect(screen.getByText('Go to home')).toBeTruthy();

      fireEvent.click(homeButton);
      expect(history.location.pathname).toBe('/');
    });
  });

  describe('on "Create another request" button click', () => {
    let anotherRequestButton: HTMLElement;
    const history = createMemoryHistory();
    renderComponent(CreateThankYouTest, undefined, undefined, history);

    beforeEach(() => {
      anotherRequestButton = screen.getByText(/Create another request/i);
    });

    it('should have redirected to CreateForm page', () => {
      expect(anotherRequestButton).toBeTruthy();

      fireEvent.click(anotherRequestButton);
      expect(history.location.pathname).toBe(pagePaths.CreateForm);
    });
  });
});
