import classNames from 'classnames';

import { ContactItemControlProps } from './ContactItemControl.types';

import Title, { TITLE_SIZE } from '@/components/atoms/Title';

import styles from './ContactItemControl.module.css';

const ContactItemControl = ({ text, title, icon: Icon }: ContactItemControlProps) => (
  <>
    <Icon title={title} role="button" />
    <Title size={TITLE_SIZE.BODYSDEFAULT} className={classNames(styles.value)}>
      {text}
    </Title>
  </>
);

export default ContactItemControl;
