const labels = {
  StaysList: {
    'Ref: Page title': '남아 있습니다',
    'Ref: Past': '과거',
    'Current and future stays': '현재와 ​​미래의 체류',
    'filter label: period': '기간',
    'Ref: Search': '검색',
  },
  StayDetails: {
    'Ref: Page title': '당신의 체류 자료',
    'Arrival date': '도착 일',
    'Departure date': '출발일',
    'Room number': '방',
    'Reservation number': '예약 번호',
    CheckIn: '체크인',
    CheckOut: '체크 아웃',
    CheckIn_1: '오늘 도착한 {lodgeName}에 체크인하십시오. (예약 번호 {resNumber})',
    CheckedIn_1: '당신은 {lodgeName}, room {roomNumber}에 체크됩니다.',
    CheckOut_1: '지금 {lodgeName} 예약 번호 {Reswumber}을 확인하십시오. 방 열쇠가 비활성화됩니다.',
    CheckedOut_1: '{lodgeName} 예약 번호 {resNumber}가 체크 아웃되었습니다.',
  },
  StaysSuccess: {
    'Ref: Title': '당신의 숙박에 감사드립니다!',
    'Ref: Body': '체크 아웃이 성공적으로 처리되었습니다.',
    'Ref: Go to home': '집에 가십시오',
    'Ref: Provide a feedback': '피드백을 제공하십시오',
  },
};
export default labels;
