import MultiChoice from './MultiChoice';
import MultiLineText from './MultiLineText';
import QuestionComponent from './QuestionComponent';
import SingleChoice from './SingleChoice';
import SingleLineText from './SingleLineText';
import StarRating from './StarRating';

export type { QuestionComponentProps, QuestionTypes } from './QuestionComponent.types';

export { SingleChoice, StarRating, MultiChoice, SingleLineText, MultiLineText };
export default QuestionComponent;
