const labels = {
  SiteDetails: {
    'Select site': 'Dorthin gehen',
    'Ref: Page title': 'Standortdetails',
    'Ref: Skip map': 'Überspringen Sie zum Hauptinhalt',
    'Ref: Unavailable site': 'Standortvorschau für Ihren Benutzer nicht zuverlässig',
    'Ref: Sorry, no description yet': 'Entschuldigung, wir haben noch keine Beschreibung.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Standortkoordinaten nicht gefunden',
    'Ref: Page title': 'Standorte',
    'Recently visited': 'Kürzlich besucht',
    'All other sites': 'Alle anderen Standorte',
    'Sites in your country': 'Standorte in Ihrem Land',
    'Search results': 'Suchergebnisse',
    'Select site': 'Standort wählen',
    'Ref: Select site with QR Scanner': 'Wählen Sie Site mit QR -Scanner aus',
    'Ref: Select site with code': 'Site mit Code auswählen',
    'Ref: Validate': 'Bestätigen',
    'Ref: Enter site code': 'Standortcode eingeben',
    'Ref: Scanner error header': 'Standort nicht gefunden',
    'Ref: Generic error': 'Etwas ist schief gelaufen',
    'Ref: Change site': 'Standort ändern',
    'Ref: Change site description':
      'Artikel im Warenkob vorhanden! Das Ändern der Website führt zum Löschen des Warenkorbs.',
    'Ref: Cancel': 'löschen',
    'Ref: Not found': 'Dieser QR -Code wurde nicht erkannt',
    'Ref: Unauthorized error':
      'Nicht autorisierte Anfrage aufgrund der Einschränkung der Standortbeschränkung',
    'Ref: Forbidden':
      'Entschuldigung, Sie haben nicht die richtigen Berechtigungen, auf diese Website zuzugreifen. Um Zugang zu arrangieren, wenden Sie sich bitte an das Pilbara Service Center unter 1800 992 777',
    "Ref: We didn't find the site.": 'Wir haben die Seite nicht gefunden.',
    'Ref: We recommend some solutions:': 'Wir empfehlen einige Lösungen:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Versuchen Sie, Ihre Website mit QR -Scanner oder mit Hilfe von Schaltflächen unten hinzuzufügen',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Melden Sie sich an und melden Sie sich wieder an, indem Sie das richtige Land auswählen',
    'Ref: The currently selected country is:': 'Das derzeit ausgewählte Land ist:',
  },
  SwitchSite: { 'Not on {site name}?': 'Nicht auf {site name}?' },
  SiteTile: { Details: 'Einzelheiten', Select: 'Auswählen' },
};
export default labels;
