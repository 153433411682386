const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Żądanie zakwaterowania',
    'Ref: Describe your request': 'Opisz swoją prośbę',
    'Ref: Confirm your request': 'Potwierdź swoją prośbę',
    'Ref: Your request': 'Twoja prośba',
    'Ref: Summary': 'Streszczenie',
    'Ref: Next': 'Następny',
    'Ref: Submit': 'Składać',
    'Ref: Me': 'Ja',
    'Ref: Arrival date': 'Data przybycia',
    'Ref: Arrival time': 'Czas przybycia',
    'Ref: Departure date': 'Data odjazdu',
    'Ref: Departure time': 'Godzina odjazdu',
    'Ref: request raised for': 'Osoba dotknięta tym prośbą',
    'Ref: phoneNumber': 'Numer telefonu',
    'Ref: arrivalDate': 'Przyjazd',
    'Ref: departureDate': 'Wyjazd',
    'Ref: description': 'Komentarz',
    'Ref: open': 'otwarty',
    'Ref: pending': 'Aż do',
    'Ref: refused': 'Odrzucony',
    'Ref: closed': 'Zamknięte',
    'Ref: all': 'Wszystko',
    'Ref: Arrival': 'Przyjazd',
    'Ref: Validate': 'Uprawomocnić',
    'Ref: site': 'Lokalizacja strony',
    'Ref: Update us': 'Zaktualizuj nas',
    'Ref: email of guest': 'E-mail gościa',
    'Ref: GuestAccommodationRequestTitle': 'Prośba gościa',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Nowa prośba',
    'Ref: Status': 'Status',
    'Ref: Date': 'Data',
    'Ref: Coming arrivals': 'Przybycie przybyli',
    'Ref: Past arrivals': 'Przeszłe przyjazdy',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Numer telefonu gości',
    'Ref: Your phone number': 'Twój numer telefonu',
    'Ref: The phone number': 'Numer telefonu',
    'Ref: PhoneNumber error msg': 'Numer telefonu jest nieprawidłowy',
    'Ref: Comment error msg': 'Maksymalna liczba znaków to 250',
    'Ref: Guest first name': 'Imię gościa',
    'Ref: Guest last name': 'Nazwisko gościa',
    'Ref: request for': 'Bo kim jest ta prośba?',
    'Ref: request for me': 'Ja',
    'Ref: request for guest': 'Ktoś inny',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Szczegóły żądania zakwaterowania',
    'Ref: Timeline': 'Oś czasu',
    'Ref: No updates placeholder': 'Nie ma aktualizacji tego żądania',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Dziękuję',
    'Ref: Success text':
      'Otrzymaliśmy Twoją prośbę i teraz o nią patrzymy. Wkrótce do Ciebie wrócimy.',
    'Ref: Go to home': 'Idź do domu',
    'Ref: Create another request': 'Utwórz kolejne żądanie',
  },
  CreateError: {
    'Ref: Create error title': 'Ups!',
    'Ref: Create error text': 'Wystąpił błąd. Proszę spróbować ponownie.',
    'Ref: Retry': 'Spróbuj ponownie',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Zaktualizuj nas o dowolnym nowym elemencie o Twojej prośbie, abyśmy mogli najlepiej Cię wspierać.',
  },
};
export default labels;
