import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MarkDownContent } from '../../../../components/atoms/RenderContent';
import Card from '../../../../components/molecules/Card/Card';
import ContentDetailsPage from '../../../../components/templates/ContentDetailsPage/ContentDetailsPage';
import { getImageSrcFromId } from '../../../../helpers/images/images.helper';
import { contentPageConfig } from '../../config';
import getParentPageUrl from '../../helpers/getParentPageUrl';
import { useContentPageTranslation } from '../../hooks/useContentPageTranslation';
import { ContentPageDetailProps } from '../../types';
import ChildrenPagesList from '../ChildrenPagesList/ChildrenPagesList';
import ContentPageTitle from '../ContentPageTitle/ContentPageTitle';
import MapAddress from '../MapAddress/MapAddress';

import { State } from '@/types/state.types';

import styles from './DetailPage.module.css';

const { showChildrenPagesList } = contentPageConfig();

const DetailPage = ({ contentPage, navItemName }: ContentPageDetailProps) => {
  const { label } = useContentPageTranslation('ContentDetails');
  const { currentLanguageCode } = useSelector((state: State) => state.Shared.language);

  const [contentImage, setContentImage] = useState<string | undefined>();
  const [mapImage, setMapImage] = useState<string | undefined>();

  useEffect(() => {
    const getContentImage = async () => {
      if (contentPage.contentImageId) {
        const contentImageId = await getImageSrcFromId(contentPage.contentImageId);
        setContentImage(contentImageId);
        return;
      }

      setContentImage(undefined);
    };

    getContentImage();
  }, [contentPage.contentImageId]);

  useEffect(() => {
    const getMapImage = async () => {
      if (contentPage.mapImageId) {
        const mapImageId = await getImageSrcFromId(contentPage.mapImageId);
        setMapImage(mapImageId);
        return;
      }

      setMapImage(undefined);
    };

    getMapImage();
  }, [contentPage.mapImageId]);

  return (
    <ContentDetailsPage.WithSideBottomSection
      {...(!contentPage.parent
        ? {
            hideBackButton: true,
            title: label(navItemName),
          }
        : {
            backButtonCustomPath: getParentPageUrl(navItemName, contentPage),
            title: contentPage.parent.name,
          })}
      header={{
        imageSrc: contentImage,
        imageAlt: contentPage.name,
        children: <ContentPageTitle contentPage={contentPage} className={styles.titleSide} />,
      }}
      withNavBar
      sideBottomSection={
        <MapAddress key="MapAddress" contentPage={contentPage} mapImage={mapImage} label={label} />
      }
    >
      <ContentPageTitle contentPage={contentPage} className={styles.titleMain} />
      {contentPage.markdown && (
        <Card>
          <MarkDownContent
            data-testid="content-page-details-markdown-content"
            language={currentLanguageCode}
          >
            {contentPage.markdown}
          </MarkDownContent>
        </Card>
      )}

      {showChildrenPagesList ? <ChildrenPagesList contentPageId={contentPage.id} /> : null}
    </ContentDetailsPage.WithSideBottomSection>
  );
};

export default DetailPage;
