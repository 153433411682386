import { useEffect, useState, useCallback } from 'react';

import { getImageSrcFromId } from '../../../helpers/images/images.helper';
import { useGetSitesQuery } from '../../Sites/api/api';
import { ISite } from '../../Sites/types/sites.types';

const useSiteWelcomeImage = (
  site: ISite
): {
  welcomeWidgetImage: string | undefined;
} => {
  const [siteWelcomeImage, setSiteWelcomeImage] = useState<string>();
  const { data: allSites = [] } = useGetSitesQuery({});

  const fetchWelcomeImage = useCallback(async () => {
    if (site && allSites.length !== 0) {
      const siteWithImage = allSites.find((s) => s.id === (site as any).id);
      if (siteWithImage && siteWithImage.images && siteWithImage.images.length > 1) {
        const img = await getImageSrcFromId(siteWithImage.images?.[1]);
        setSiteWelcomeImage(img);
      }
    }
  }, [site, allSites]);

  useEffect(() => {
    fetchWelcomeImage();
  }, [site, allSites, fetchWelcomeImage]);

  return {
    welcomeWidgetImage: siteWelcomeImage,
  };
};

export default useSiteWelcomeImage;
