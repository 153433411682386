import { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface NavigationHistoryContextType {
  navigationHistory: string[];
  lastPath: string;
}

const NavigationHistoryContext = createContext<NavigationHistoryContextType>({
  navigationHistory: [],
  lastPath: '',
});

export const NavigationHistoryProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory();
  const location = useLocation();

  const [navigationHistory, setNavigationHistory] = useState<string[]>([location.pathname]);

  useEffect(() => {
    return history.listen((location, action) => {
      const typedLocation = location as { pathname: string; state: { fromBackButton?: boolean } };
      setNavigationHistory((prevHistory) => {
        const lastPath = prevHistory[prevHistory.length - 1];

        if (typedLocation?.state?.fromBackButton) {
          return [...prevHistory.slice(0, -1)];
        }

        if (action === 'REPLACE') {
          return [...prevHistory.slice(0, -1), typedLocation.pathname];
        }

        if (lastPath !== typedLocation.pathname) {
          return [...prevHistory, typedLocation.pathname];
        }

        return prevHistory;
      });
    });
  }, [history]);

  const lastPath =
    navigationHistory.length > 1 ? navigationHistory[navigationHistory.length - 2] : '';

  const contextValue = useMemo(
    () => ({ navigationHistory, lastPath }),
    [navigationHistory, lastPath]
  );

  return (
    <NavigationHistoryContext.Provider value={contextValue}>
      {children}
    </NavigationHistoryContext.Provider>
  );
};

export const useNavigationHistory = () => useContext(NavigationHistoryContext);
