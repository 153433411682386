import { get } from 'idb-keyval';

import { SUPPORTED_FILE_EXTENSION_MIMETYPES } from '../../constants';
import { AttachmentData } from '../../types/common.types';
import { getImageSrcFromInfo } from '../misc';

import { fetchImagesFromServer } from './images.api';

import { ImageResponse, ImageResult } from '@/helpers/images/image.types';
import { persistDataWorkerAct } from '@/store/middlewares/persistDataLocallyMiddleware';

export const getImageSrcFromId = async (id: string): Promise<string> => {
  const result = await getImagesSrcFromIds([id]);
  return result[0] ? result[0].content : '';
};

export const getImagesSrcFromIds = async (ids: string[]): Promise<ImageResult[]> => {
  const getImagesFromCachePromises = ids.map(async (id) => ({
    id,
    image: await get(generateImageCacheKey(id)),
  }));
  const getImagesFromCacheResolved = await Promise.all(getImagesFromCachePromises);

  const imageIdsToFetchFromServer: string[] = [];
  const imagesFromCache: ImageResult[] = [];
  getImagesFromCacheResolved.forEach((imageFromCacheResult) => {
    if (imageFromCacheResult.image) {
      imagesFromCache.push({
        id: imageFromCacheResult.id,
        content: imageFromCacheResult.image.src,
      });
    } else {
      imageIdsToFetchFromServer.push(imageFromCacheResult.id);
    }
  });

  let fetchedImages: ImageResult[] = [];
  if (imageIdsToFetchFromServer.length > 0) {
    const imagesResponse: ImageResponse[] = await fetchImagesFromServer(imageIdsToFetchFromServer);

    fetchedImages = imagesResponse.map((imageResponse) => {
      const imageSrc: string = getImageSrcFromInfo(imageResponse);

      persistDataWorkerAct({
        info: {
          key: generateImageCacheKey(imageResponse.id),
          val: {
            timestamp: Date.now(),
            src: imageSrc,
          },
        },
      });

      return {
        id: imageResponse.id,
        content: imageSrc,
      };
    });
  }

  const decodedImages = [...imagesFromCache, ...fetchedImages].map((image) => {
    if (image.content.includes('image/svg')) {
      image.content = decodeImageSrc(image.content);
    }

    if (image.content.includes('data:application/octet-stream;base64,')) {
      image.content = decodeOctetStreamImage(image.content);
    }

    return image;
  });

  return decodedImages;
};

const generateImageCacheKey = (id: string) => `image_${id}`;

function decodeImageSrc(imageSrc: string) {
  const codedSvg = imageSrc.substring(imageSrc.indexOf(',') + 1);
  return atob(codedSvg);
}

function decodeOctetStreamImage(imageSrc: string) {
  // This verification is need to be able to decode files wrongy uploaded during the Pilot fase.
  // with time , approx 6 months the code from line 42 to 51 can be removed
  const tempDecodedImage = atob(imageSrc.substring('data:application/octet-stream;base64,'.length));
  const dataAnnotationIndex = tempDecodedImage.indexOf('data:');
  if (dataAnnotationIndex > -1) {
    const mimeType = tempDecodedImage.substring(
      'data:'.length,
      tempDecodedImage.indexOf(';base64')
    );
    return tempDecodedImage.substring(`data:${mimeType};base64,`.length);
  }

  return imageSrc.substring('data:application/octet-stream;base64,'.length);
}

export const getAttachmentDataFromBase64 = async (
  fileData: string,
  fileName: string
): Promise<AttachmentData> => {
  const fileExtension = fileName.substring(
    fileName.lastIndexOf('.') + 1
  ) as keyof typeof SUPPORTED_FILE_EXTENSION_MIMETYPES;
  let mimeType = 'application/octet-stream';

  if (fileExtension in SUPPORTED_FILE_EXTENSION_MIMETYPES) {
    mimeType = SUPPORTED_FILE_EXTENSION_MIMETYPES[fileExtension];
  }

  return new Promise(function (resolve) {
    const file = new File([fileData], fileName, { type: mimeType });
    const reader = new FileReader();
    reader.onload = function () {
      const attachmentData = {
        file: file,
        data: `data:${mimeType};base64,${fileData}`,
      };
      resolve(attachmentData);
    };
    reader.readAsDataURL(file);
  });
};
