import '@testing-library/jest-dom/extend-expect';
import { screen } from '@testing-library/react';

function expectInputError(inputName: string, expectedMessage?: string) {
  const element = screen.getByTestId(inputName);
  expect(element).toBeInTheDocument();
  expect(element).toHaveTextContent(expectedMessage || 'Ref: Input required');
}

export default expectInputError;
