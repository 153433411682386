import { ionFireEvent as fireEvent } from '@ionic/react-test-utils';
import { act, cleanup, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import { APP_NAME } from '../../../../../constants';
import renderComponent from '../../../../../helpers/tests/renderComponent';
import { QRCodeResponse } from '../../../api/api.types';
import { ServiceRequestType, Site } from '../../../types/apiResponse.types';
import ManualDescribe from '../ManualDescribe';

jest.setTimeout(30000);

jest.mock('../../../../../components/molecules/QrCode/QrCodeReader', () => () => {
  return <div>QrCodeReader</div>;
});

jest.mock('@capacitor/camera', () => ({
  Camera: {
    checkPermissions: async () => true,
  },
}));

jest.mock('react-select', () => ({ 'data-testid': dataTestId, options, value, onChange }: any) => {
  function handleChange(event: any) {
    const option = options.find((option: any) => option.value === event.currentTarget.value);
    onChange(option);
  }
  return (
    <select
      id={dataTestId}
      name={dataTestId}
      data-testid={dataTestId}
      value={value || ''}
      onChange={handleChange}
    >
      {options.map(({ label, value }: any) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
});

const mockDetail: { data: Site[]; isLoading: boolean } = {
  data: [],
  isLoading: false,
};

const mockTypesDetail: { data: ServiceRequestType[]; isLoading: boolean } = {
  data: [],
  isLoading: false,
};

const mockAssetInformation: { data: QRCodeResponse } = {
  data: {
    siteId: 'superSiteId',
    roomName: 'Room 002',
    buildingName: 'Main Building',
  },
};

jest.mock('../../../api/index', () => ({
  useGetServiceRequestsSitesQuery: () => mockDetail,
  useGetServiceRequestsTypesQuery: () => mockTypesDetail,
  useGetAssetInformationQuery: () => mockAssetInformation,
}));

describe('ManualDescribe', () => {
  const env = global.process.env;

  beforeAll(() => {
    global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYVILLAGE };
  });

  afterAll(() => cleanup());

  describe('on initial render', () => {
    let titleInput: any,
      siteSelectWrapper: any,
      categorySelectWrapper: any,
      buildingInput: any,
      roomInput: any,
      descriptionInput: any;

    renderComponent(ManualDescribe, { setDisabledFields: jest.fn() });

    beforeEach(() => {
      titleInput = screen.getByTestId('service-request-manual-describe-summary-input-field');
      siteSelectWrapper = screen.getByTestId('site-select-wrapper');
      categorySelectWrapper = screen.getByTestId('category-select-wrapper');
      buildingInput = screen.getByTestId('service-request-manual-describe-building-input-field');
      roomInput = screen.getByTestId('service-request-manual-describe-room-input-field');
      descriptionInput = screen.getByTestId('description-input');
    });

    it('should have correct input fields', () => {
      expect(titleInput).toBeTruthy();
      expect(siteSelectWrapper).toBeTruthy();
      expect(categorySelectWrapper).toBeTruthy();
      expect(buildingInput).toBeTruthy();
      expect(roomInput).toBeTruthy();
      expect(descriptionInput).toBeTruthy();
    });
  });

  describe('on submiting the form without required fields', () => {
    let button: any, alert: any;

    const createRequest = jest.fn();
    const getServiceRequestTypes = jest.fn();

    renderComponent(ManualDescribe, {
      createRequest,
      getServiceRequestTypes,
      setDisabledFields: jest.fn(),
    });

    beforeEach(() => {
      button = screen.getByTestId('button-action-primary');
      fireEvent.click(button);

      alert = screen.getAllByText(/Required field/i);
    });

    it('should open alert modal', () => {
      expect(createRequest).not.toHaveBeenCalled();
      expect(alert).toBeTruthy();
    });
  });

  describe('on submiting the form with required fields', () => {
    let titleInput: any,
      siteSelect: any,
      categorySelect: any,
      buildingInput: any,
      roomInput: any,
      descriptionInput: any,
      button: any;

    const handleChange = jest.fn();
    const getServiceRequestTypes = jest.fn();
    const goToNext = jest.fn();

    renderComponent(ManualDescribe, {
      disabledFields: true,
      handleChange,
      getServiceRequestTypes,
      goToNext,
      setDisabledFields: jest.fn(),
    });

    beforeEach(async () => {
      mockDetail.data = [
        {
          id: 'superSiteId',
          name: 'Super Site',
          locationtype: 'Site',
          additionalInfo: {
            markdown: '',
            html: '',
          },
          address: {
            line1: 'Sixth St',
            line2: '',
            line3: '',
            city: 'Marble Bar',
            postalCode: '6760',
            stateOrProvince: 'Western Australia',
            country: 'Australia',
          },
          geoCoordinates: {
            longitude: 118.401077,
            latitude: -21.2860432,
          },
          images: [],
          siteMapImage: '',
          selfRegistrationDuration: 30,
          currency: {
            currencyName: 'Australian Dollar',
            currencySymbol: '$',
            isoCode: 'AUD',
            exchangeRate: 1.4,
            precision: 2,
          },
          siteAccessRestrictionLevelId: 1,
        },
      ];
      mockTypesDetail.data = [{ id: 'cleaningId', name: 'Cleaning' }];
      titleInput = screen.getByTestId('service-request-manual-describe-summary-input-field');
      siteSelect = screen.getByTestId('site-select');
      categorySelect = screen.getByTestId('category-select');
      buildingInput = screen.getByTestId('service-request-manual-describe-building-input-field');
      roomInput = screen.getByTestId('service-request-manual-describe-room-input-field');
      descriptionInput = screen.getByTestId('description-input');

      await act(async () => {
        await userEvent.type(titleInput, 'I need something');
        await userEvent.selectOptions(siteSelect, ['superSiteId']);
        await userEvent.selectOptions(categorySelect, ['cleaningId']);
        await userEvent.type(buildingInput, 'Main Building');
        await userEvent.type(roomInput, 'Room 002');
        fireEvent.ionChange(descriptionInput, 'Some cleaning is needed');
      });
    });

    beforeEach(() => {
      button = screen.getByTestId('button-action-primary');
      fireEvent.click(button);
    });

    it('should submit form', () => {
      expect(handleChange).toHaveBeenLastCalledWith({
        title: 'I need something',
        site: 'superSiteId',
        buildingText: 'Main Building',
        roomText: 'Room 002',
        description: 'Some cleaning is needed',
        category: 'cleaningId',
        attachments: [],
        myLocation: { title: 'Super Site / Main Building / Room 002' },
      });
      expect(goToNext).toHaveBeenCalled();
    });
  });
});
