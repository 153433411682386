const labels = {
  All: {
    Feedback: 'Atsiliepimas',
    'Ref: Switch site component intro sentence':
      'Jūsų atsiliepimai bus susieti su svetaine {site name}.',
    Category: 'Kategorija',
    Subject: 'tema',
    Comment: 'komentaras',
    'Date of your experience': 'Jūsų patirties data *',
    'Ref: Hint': 'Ar turite problemą ar darbą, kuriam reikia nustatyti?',
    'Ref: Log Request': 'Prisijungti prašymą',
    'Ref: Describe issue': 'Prašau papasakoti apie savo patirtį',
    Date: 'Data',
    'Ref: Share your opinion header': 'Pasidalinkite savo nuomone apie savo patirtį darbe',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restoranas / kavinė',
    'Desk / workstation area': 'Stalo / darbo vietos zona',
    'Meeting room facilities': 'Susitikimų kambario patalpos',
    'Coffee/tea facilities (pantry)': 'Kavos/arbatos įrenginiai (sandėliukas)',
    'Restrooms / washrooms': 'Tualetai / prausyklos',
    'Reception / Security': 'Priėmimas / saugumas',
    'Conference center': 'Konferencijų centras',
    'Fitness center': 'Fitneso centras',
    Mailroom: 'Pašto kambarys',
    'Bicycle room': 'Dviračių kambarys',
    'Shuttle services': 'Maršrutinių autobusų paslaugos',
    Lockers: 'Spintelės',
    Helpdesk: 'Pagalba',
  },
  Create: {
    'Tell us!': 'Pasakyk mums!',
    'Ref: All Ears': 'Mes visi esame ausys!',
    'Ref: Provide Description': 'Pateikite aprašymas',
    'Ref: Fill-in-the-blank sentence': 'Noriu pateikti {0}, atsižvelgiant į {1}',
    'Ref: Link text to service request module': 'Užklausą ar problemą? Prisijunkite čia!',
    'Complaint type': 'Skundas {jaustukas}',
    'Feedback type': 'Grįžtamasis ryšys {jaustukas}',
    'Compliment type': 'Komplimentas {jaustukas}',
  },
  CreateFailure: {
    'Ref: Body':
      'Įvyko klaida ir jūsų atsiliepimai nebuvo pateikta. Jei ši klaida išlieka, kreipkitės į savo pagalbos tarnybą.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Jūsų atsiliepimai buvo pateikta. Mūsų darbo vietos paramos komanda jį atidžiai perskaitys. Jei reikia, mes grįšime pas jus.',
    'New feedback': 'Naujas atsiliepimas',
  },
  CreateFlow: {
    Description: 'apibūdinimas',
    'Ref: Provide Description': 'Pateikite datą ir aprašymą',
    'Ref: I would like to provide a': 'Norėčiau pateikti a',
    'Ref: in regards to': 'Kalbant apie',
    'Ref: &': 'Ir',
    'Ref: at': 'at',
    'Ref: on the': 'ant',
  },
};
export default labels;
