import { useCallback, useMemo } from 'react';

import { LocationIcon } from '../../../../assets';
import Select, { SelectOption } from '../../../../components/atoms/Select';
import { Skeleton } from '../../../../components/atoms/Skeleton';
import { useGetDestinationsQuery, useGetOriginsQuery } from '../../api';
import { SelectedStop } from '../../routes/TimeTable/TimeTable.types';

import { ItineraryFieldsProps } from './ItineraryFields.types';

import styles from './ItineraryFields.module.css';

const ItineraryFields = ({
  selectedOrigin,
  selectedDestination,
  setSelectedOrigin,
  setSelectedDestination,
  label,
}: ItineraryFieldsProps) => {
  const { data: originsData = [], isLoading: isOriginsLoading } = useGetOriginsQuery();
  const { data: destinationsData = [], isFetching: isDestinationsLoading } =
    useGetDestinationsQuery(selectedOrigin?.value, { skip: !Boolean(selectedOrigin) });

  const origins = useMemo(
    () => originsData.map(({ id, name }) => ({ value: id, label: name })),
    [originsData]
  );

  const destinations = useMemo(
    () => destinationsData.map(({ id, name }) => ({ value: id, label: name })),
    [destinationsData]
  );

  const onChangeOrigin = useCallback(
    ({ value, label }: SelectedStop) => {
      setSelectedOrigin({ value, label });
      setSelectedDestination(null);
    },
    [setSelectedDestination, setSelectedOrigin]
  );

  const onChangeDestination = useCallback(
    ({ value, label }: SelectedStop) => {
      setSelectedDestination({ value, label });
    },
    [setSelectedDestination]
  );

  const noOptionsMessage = () => (
    <div className={styles.optionsFetchingPlaceholder}>
      <Skeleton width="60%" />
    </div>
  );

  const customFilter = (option: SelectOption, query: string) =>
    option.label.toLowerCase().includes(query.toLowerCase());

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <div className={styles.iconOrigin} />
        <div className={styles.iconPath}>
          <div className={styles.iconPathDot} />
          <div className={styles.iconPathDot} />
          <div className={styles.iconPathDot} />
        </div>
        <LocationIcon className={styles.iconDestination} width={20} height={20} />
      </div>
      <div className={styles.inputs}>
        <Select
          placeholder={label('from (followed by a location)', { textTransform: 'capitalize' })}
          value={selectedOrigin}
          options={origins}
          filterOption={customFilter}
          onChange={onChangeOrigin}
          {...(isOriginsLoading && { noOptionsMessage })}
          data-testid="origins-select"
        />
        <Select
          placeholder={label('to (followed by a location)', { textTransform: 'capitalize' })}
          value={selectedDestination}
          filterOption={customFilter}
          onChange={onChangeDestination}
          {...(isDestinationsLoading
            ? { options: [], noOptionsMessage }
            : { options: destinations })}
          data-testid="destinations-select"
        />
      </div>
    </div>
  );
};

export default ItineraryFields;
