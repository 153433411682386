import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { REACTIONS } from '../../../types/reactions.types';
import eventsApi, { useDeleteReactionMutation, useUpdateReactionMutation } from '../api/api';
import { EventType } from '../types';

const useToggleReaction = () => {
  const dispatch = useDispatch();

  const [submitReaction] = useUpdateReactionMutation();
  const [deleteReaction] = useDeleteReactionMutation();

  const toggleReaction = useCallback(
    async (reaction: REACTIONS, item: EventType, siteId: string) => {
      const request = reaction === item.reactions?.me ? deleteReaction : submitReaction;

      const response = await request({
        id: item.id,
        reactionType: reaction,
        siteId,
      });

      if ('data' in response) {
        dispatch(
          eventsApi.util.updateQueryData('getEvents', { siteId }, (draft) => {
            return draft.map((event: EventType) =>
              event.id === item.id ? { ...event, reactions: response.data } : event
            );
          })
        );
        dispatch(
          eventsApi.util.updateQueryData('getEvent', { id: item.id, siteId }, (draft) => {
            return draft
              ? {
                  ...draft,
                  eventDetails: {
                    ...draft.eventDetails,
                    reactions: response.data,
                  },
                }
              : draft;
          })
        );
      }
    },
    [deleteReaction, dispatch, submitReaction]
  );

  return [toggleReaction];
};

export default useToggleReaction;
