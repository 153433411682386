import { getCustomSetupOption } from '../../../helpers/getSetupOption';
import { isServiceType } from '../../../helpers/misc';
import { TranslationProps } from '../../../localization/localization.types';
import { SERVICE, servicesHiddenInNav, serviceTypes } from '../../../modules/config';
import { Service } from '../../../modules/Core/types/State.types';
import { ServiceDefinition } from '../../../modules/defaultServices';

const getServicePath = (service: ServiceDefinition): string => {
  const { value: url } = getCustomSetupOption('externalUrl', service.setupOptions);
  const { value: emmbededURL } = getCustomSetupOption('emmbededURL', service.setupOptions);
  const { value: embeddedVendorCode } = getCustomSetupOption(
    'embeddedVendorCode',
    service.setupOptions
  );
  if (emmbededURL) {
    const serviceUrl = `/externalContent?url=${encodeURIComponent(
      btoa(emmbededURL)
    )}&title=${encodeURI(service.navigation.name)}`;

    if (embeddedVendorCode) {
      return `${serviceUrl}&code=${embeddedVendorCode}`;
    }
    return serviceUrl;
  } else if (url) {
    return url;
  }

  let path = serviceTypes[service.name].path;
  if (isServiceType(service, SERVICE.CONTENT_PAGE)) {
    path += '/' + encodeURI(service.navigation.name);
  }

  return path;
};

const getItemLabel = (service: ServiceDefinition, label: TranslationProps['label']) => {
  if (isServiceType(service, [SERVICE.CONTENT_PAGE, SERVICE.EXTERNAL_WEBSITE])) {
    return label(service.navigation.name);
  }

  return label(serviceTypes[service.name].label, {
    textTransform: 'capitalize',
  });
};

const getServicesWithNav = (services: Service[]) => {
  const servicesWithNav = services
    .filter(
      (service: Service) =>
        service.navigation &&
        service.navigation.isEnabled &&
        !servicesHiddenInNav.includes(service.name as SERVICE)
    )
    .filter((service: Service) =>
      Object.keys(serviceTypes).find(
        (el) =>
          el === service.name &&
          !getCustomSetupOption('navItemHidden', service.setupOptions).isActive
      )
    );

  //sort by nav item order index
  servicesWithNav.sort((a, b) => a.navigation.orderIndex - b.navigation.orderIndex);

  return servicesWithNav;
};

export { getServicePath, getItemLabel, getServicesWithNav };
