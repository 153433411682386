import { SSO_PROVIDER } from '../../../constants';
import { IdentityContextType } from '../types';

const getSSOReturnUrl = (email: string, geoCode: string) =>
  `/access/idp?username=${email}&geocode=${geoCode}`;

const getSSOCallbackUrl = (email: string, geoCode: string) =>
  `${process.env.REACT_APP_HOST}${getSSOReturnUrl(email, geoCode)}`;

const isSSOUser = (identityContext: IdentityContextType) =>
  identityContext.provider === SSO_PROVIDER.GATES;

export { getSSOCallbackUrl, getSSOReturnUrl, isSSOUser };
