import { ionFireEvent as fireEvent } from '@ionic/react-test-utils';
import { screen, cleanup, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

import { APP_NAME } from '../../../../constants';
import expectInputError from '../../../../helpers/tests/expectInputError';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { changePassword } from '../../actions';

import AccountPasswordChange from './AccountPasswordChange';

jest.mock('../../actions', () => ({
  ...jest.requireActual('../../actions'),
  changePassword: jest.fn(),
}));

const mockDispatch = jest.fn((action) => action());
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockDispatch,
}));

describe('AccountPasswordChange', () => {
  const env = global.process.env;

  describe('when is MyWay', () => {
    beforeAll(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYWAY };
    });

    afterAll(() => cleanup());

    describe('on initial render', () => {
      let currentPassword: any, password: any, confirmPassword: any;

      renderComponent(AccountPasswordChange);

      beforeEach(() => {
        currentPassword = screen.getByTestId('account-current-password-hook-input-field');
        password = screen.getByTestId('account-new-password-hook-input-field');
        confirmPassword = screen.getByTestId('account-confirm-password-hook-input-field');
      });

      it('should have correct input fields', () => {
        expect(currentPassword).toBeTruthy();
        expect(password).toBeTruthy();
        expect(confirmPassword).toBeTruthy();
      });
    });

    describe('on submit form without required fields', () => {
      let button: any;

      renderComponent(AccountPasswordChange);

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');
      });

      it('should require fields', async () => {
        await act(async () => {
          fireEvent.click(button);
        });

        expectInputError('account-current-password-hook-input-status-message');
        expectInputError('account-confirm-password-hook-input-status-message');

        expect(mockDispatch).not.toHaveBeenCalled();
      });
    });

    describe('on submit form with wrong old password', () => {
      let currentPassword: any, password: any, confirmPassword: any, button: any;

      renderComponent(AccountPasswordChange);

      beforeEach(async () => {
        currentPassword = screen.getByTestId('account-current-password-hook-input-field');
        password = screen.getByTestId('account-new-password-hook-input-field');
        confirmPassword = screen.getByTestId('account-confirm-password-hook-input-field');

        await userEvent.type(currentPassword, 'Password1!');
        await userEvent.type(password, 'Password2!');
        await userEvent.type(confirmPassword, 'Password2!');
      });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');

        mockDispatch.mockReturnValue(
          Promise.resolve({
            ok: false,
            responseData: {
              Detail: 'Old Password is not valid',
              ExceptionMessage: 'Error updating password',
              ExceptionType: 'UpdatePasswordError',
              Message: 'Invalid Old password',
            },
          })
        );
        await act(async () => {
          fireEvent.click(button);
        });
      });

      it('should request password change', () => {
        expect(mockDispatch).toHaveBeenCalledWith(
          changePassword({
            password: 'Some2!',
            old_password: 'Some1!',
          })
        );
        expect(screen.getByText(/Old password does not match/i)).toBeTruthy();
      });
    });

    describe('on submit form with all fields', () => {
      let currentPassword: any, password: any, confirmPassword: any, button: any;

      renderComponent(AccountPasswordChange);

      beforeEach(async () => {
        currentPassword = screen.getByTestId('account-current-password-hook-input-field');
        password = screen.getByTestId('account-new-password-hook-input-field');
        confirmPassword = screen.getByTestId('account-confirm-password-hook-input-field');

        await userEvent.type(currentPassword, 'Password1!');
        await userEvent.type(password, 'Password2!');
        await userEvent.type(confirmPassword, 'Password2!');
      });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');

        mockDispatch.mockReturnValue(Promise.resolve({ ok: true, responseData: {} }));

        await act(async () => {
          fireEvent.click(button);
        });
      });

      it('should request password change', () => {
        expect(mockDispatch).toHaveBeenCalledWith(
          changePassword({
            password: 'Some2!',
            old_password: 'Some1!',
          })
        );
      });
    });
  });

  describe('when is MyVillage', () => {
    beforeAll(() => {
      global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYVILLAGE };
    });

    afterAll(() => cleanup());

    describe('on initial render', () => {
      let password: any, confirmPassword: any;

      renderComponent(AccountPasswordChange);

      beforeEach(() => {
        password = screen.getByTestId('account-new-password-hook-input-field');
        confirmPassword = screen.getByTestId('account-confirm-password-hook-input-field');
      });

      it('should have correct input fields', () => {
        expect(password).toBeTruthy();
        expect(confirmPassword).toBeTruthy();
      });
    });

    describe('on submit form without required fields', () => {
      let button: any;

      renderComponent(AccountPasswordChange);

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');
      });

      it('should require fields', async () => {
        await act(async () => {
          fireEvent.click(button);
        });

        expectInputError('account-confirm-password-hook-input-status-message');

        expect(mockDispatch).not.toHaveBeenCalled();
      });
    });

    describe('on submit form with all fields', () => {
      let password: any, confirmPassword: any, button: any;

      renderComponent(AccountPasswordChange);

      beforeEach(async () => {
        password = screen.getByTestId('account-new-password-hook-input-field');
        confirmPassword = screen.getByTestId('account-confirm-password-hook-input-field');

        await userEvent.type(password, 'Password2!');
        await userEvent.type(confirmPassword, 'Password2!');
      });

      beforeEach(async () => {
        button = screen.getByTestId('button-action-primary');

        mockDispatch.mockReturnValue(Promise.resolve({ ok: true, responseData: {} }));

        await act(async () => {
          fireEvent.click(button);
        });
      });

      it('should request password change', () => {
        expect(mockDispatch).toHaveBeenCalledWith(
          changePassword({
            password: 'Password2!',
          })
        );
      });
    });
  });
});
