import { IonPage, IonContent } from '@ionic/react';
import classNames from 'classnames';
import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';

import { HIDE_NAVIGATION_ROUTES } from '../../../constants';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import BottomBar from '../Layout/BottomBar';
import SideBarWrapper from '../SideBarWrapper/SideBarWrapper';
import useSidebarContext from '../SideBarWrapper/useSidebarContext';

import { DefaultPageProps } from './defaultPage.types';

import styles from './DefaultPage.module.css';

//#draft
//this is the page that provides the app structure for small screens
const DefaultPage = (props: DefaultPageProps) => {
  const {
    titleBar,
    bottomBar,
    withNavBar = true,
    children,
    stylingOptions = {},
    contentRef,
    className,
    onScroll,
    'data-testid': dataTestId = 'default-page',
  } = props;
  const { label } = useCustomTranslation();
  const { location } = useHistory();
  const { isSideBarCollapsed, setIsNavigationVisible, isNavigationVisible } = useSidebarContext();
  //destructure styling options
  const { pageBackground } = stylingOptions || {};
  const mainRef = useRef<HTMLDivElement>(null);

  const hideNavigationRoutes = HIDE_NAVIGATION_ROUTES.some((path) =>
    location.pathname.startsWith(path)
  );

  const isSideBarHidden = !withNavBar || hideNavigationRoutes;

  useEffect(
    () => setIsNavigationVisible(!isSideBarHidden),
    [isSideBarHidden, setIsNavigationVisible]
  );

  const navBar = isSideBarHidden ? null : <SideBarWrapper />;

  const onSkip = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    mainRef.current?.scrollIntoView();
    mainRef.current?.focus();
  };

  return (
    <>
      <IonPage id="Page" data-testid={dataTestId} className={className}>
        <a id="skip-nav" className={styles.skipToContent} href="#main-content" onClick={onSkip}>
          {label('Ref: Skip to main content')}
        </a>
        {titleBar}
        <IonContent
          fullscreen={false}
          id="Content"
          scrollEvents={true}
          className={pageBackground ? pageBackground : 'background_neutral'}
          ref={contentRef}
          onIonScroll={onScroll || undefined}
        >
          <div
            ref={mainRef}
            id="main-content"
            className={classNames(styles.pageContent, {
              [styles.withSideBarFull]: isNavigationVisible && !isSideBarCollapsed,
              [styles.withSideBarCollapsed]: isNavigationVisible && isSideBarCollapsed,
            })}
          >
            {children}
          </div>
          {!!bottomBar?.actions?.length && <BottomBar bottomBar={bottomBar} />}
        </IonContent>
      </IonPage>
      {navBar}
    </>
  );
};

export default DefaultPage;
