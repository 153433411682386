import { isValidElement } from 'react';

import { renderLocationItem, renderLocationSections } from '../LocateLocationItem';

import { LocationTileProps } from '@/modules/ServiceRequest/components/LocationItem/LocationItem.types';

const label = (key: string) => key;
const location = {
  id: '1',
  name: 'Paris',
  title: 'Paris,France',
  level: 2,
  hasChildren: true,
};
const locationWithoutChildren = {
  ...location,
  hasChildren: undefined,
};
const primaryAction = jest.fn();
const secondaryAction = jest.fn();

const mockEvent = {
  stopPropagation: jest.fn(),
} as unknown as React.MouseEvent<HTMLIonButtonElement | HTMLIonCardElement>;

describe('renderLocationItem', () => {
  it('render selectable location with children', () => {
    const result = renderLocationItem(location, 2, primaryAction, secondaryAction, label);
    expect(result.id).toBe('1');
    expect(result.title).toBe('Paris');
    expect(result.belowTitle).toBe('France');
    expect(result.actions?.length).toBe(2);
    expect(
      isValidElement(result.actions?.[0]?.customAction) &&
        result.actions?.[0]?.customAction.props?.children
    ).toContain('Ref: Select');
    expect(
      isValidElement(result.actions?.[1]?.customAction) &&
        result.actions?.[1]?.customAction.props?.children
    ).toContain('Ref: Navigate');

    if (result.onClick) result.onClick(mockEvent);
    expect(primaryAction).toHaveBeenCalledWith(location);
  });

  it('render selectable location without children', () => {
    const result = renderLocationItem(
      locationWithoutChildren,
      2,
      primaryAction,
      secondaryAction,
      label
    );

    expect(result.actions?.length).toBe(1);
    expect(
      isValidElement(result.actions?.[0]?.customAction) &&
        result.actions?.[0]?.customAction.props?.children
    ).toContain('Ref: Select');
    expect(result.onClick).toBeDefined();

    if (result.onClick) result.onClick(mockEvent);
    expect(primaryAction).toHaveBeenCalledWith(locationWithoutChildren);
  });

  it('render not selectable location with children', () => {
    const result = renderLocationItem(location, 3, primaryAction, secondaryAction, label);

    expect(result.actions?.length).toBe(1);
    expect(
      isValidElement(result.actions?.[0]?.customAction) &&
        result.actions?.[0]?.customAction.props?.children
    ).toContain('Ref: Navigate');
    expect(result.onClick).toBeDefined();

    if (result.onClick) result.onClick(mockEvent);
    expect(secondaryAction).toHaveBeenCalledWith(location);
  });

  it('render not selectable location without children', () => {
    const result = renderLocationItem(
      locationWithoutChildren,
      3,
      primaryAction,
      secondaryAction,
      label
    );

    expect(result.actions?.length).toBe(1);
    expect(
      isValidElement(result.actions?.[0]?.customAction) &&
        result.actions?.[0]?.customAction.props?.children
    ).toContain('Ref: Select');
    expect(result.onClick).toBeDefined();

    if (result.onClick) result.onClick(mockEvent);
    expect(primaryAction).toHaveBeenCalledWith(locationWithoutChildren);
  });
});

const items: LocationTileProps[] = [
  {
    id: '1',
    title: 'location 1',
    belowTitle: 'desc location 1',
    actions: [],
    onClick: () => {},
    level: 1,
  },
  {
    id: '2',
    title: 'location 2',
    belowTitle: 'desc location 2',
    actions: [],
    onClick: () => {},
    level: 2,
  },
  {
    id: '3',
    title: 'location 3',
    belowTitle: 'desc location 3',
    actions: [],
    onClick: () => {},
    level: 3,
  },
  {
    id: '4',
    title: 'location 4',
    belowTitle: 'desc location 4',
    actions: [],
    onClick: () => {},
    level: 4,
  },
  {
    id: '5',
    title: 'location 5',
    belowTitle: 'desc location 5',
    actions: [],
    onClick: () => {},
    level: 1,
  },
  {
    id: '6',
    title: 'location 6',
    belowTitle: 'desc location 6',
    actions: [],
    onClick: () => {},
    level: 2,
  },
];

describe('renderLocationSections', () => {
  it('render all sections at first step', () => {
    const myLocation = {
      id: '1',
      name: 'location 1',
      title: 'desc location 1',
      belowTitle: 'desc location 1',
      onClick: primaryAction,
      level: 1,
      actions: [],
    };
    const preferredLocation = {
      id: '2',
      name: 'location 2',
      title: 'desc location 2',
      belowTitle: 'desc location 2',
      onClick: primaryAction,
      level: 2,
      actions: [],
    };

    const result = renderLocationSections(label, myLocation, preferredLocation, '', []);
    expect(result.length).toBe(3);
    //selected location
    expect(result[0].title).toBe('Ref: Selected Location');
    expect(result[0].filter(items).length).toBe(1);
    expect(result[0].filter(items)[0].id).toBe('1');

    //prefered location
    expect(result[1].title).toBe('Ref: Preferred Location');
    expect(result[1].filter(items).length).toBe(1);
    expect(result[1].filter(items)[0].id).toBe('2');

    //building location
    expect(result[2].title).toBe('Ref: Building');
    expect(result[2].filter(items).length).toBe(1);
    expect(result[2].filter(items)[0].id).toBe('5');
  });
  it('render all sections without selected location', () => {
    const preferredLocation = {
      id: '2',
      name: 'location 2',
      title: 'desc location 2',
      belowTitle: 'desc location 2',
      actions: [],
      onClick: primaryAction,
      level: 2,
    };

    const result = renderLocationSections(label, undefined, preferredLocation, '', []);
    expect(result.length).toBe(2);

    //prefered location
    expect(result[0].title).toBe('Ref: Preferred Location');
    expect(result[0].filter(items).length).toBe(1);
    expect(result[0].filter(items)[0].id).toBe('2');

    expect(result[1].title).toBe('Ref: Building');
    expect(result[1].filter(items).length).toBe(2);
    expect(result[1].filter(items)[0].id).toBe('1');
    expect(result[1].filter(items)[1].id).toBe('5');
  });

  it('render all sections without selected and prefered location', () => {
    const result = renderLocationSections(label, undefined, undefined, '', []);
    expect(result.length).toBe(1);

    //prefered location
    expect(result[0].title).toBe('Ref: Building');
    expect(result[0].filter(items).length).toBe(2);
    expect(result[0].filter(items)[0].id).toBe('1');
    expect(result[0].filter(items)[1].id).toBe('5');
  });

  it('render all sections when searching', () => {
    const myLocation = {
      id: '1',
      name: 'location 1',
      title: 'desc location 1',
      belowTitle: 'desc location 1',
      actions: [],
      onClick: primaryAction,
      level: 1,
    };
    const preferredLocation = {
      id: '2',
      name: 'location 2',
      title: 'desc location 2',
      belowTitle: 'desc location 2',
      actions: [],
      onClick: primaryAction,
      level: 2,
    };

    const result = renderLocationSections(label, myLocation, preferredLocation, 'test', []);
    expect(result.length).toBe(6);
    //selected location
    expect(result[0].title).toBe('Ref: Selected Location');

    //prefered location
    expect(result[1].title).toBe('Ref: Preferred Location');

    //building location
    expect(result[2].title).toBe('Ref: Building');

    //floor location
    expect(result[3].title).toBe('Ref: Floor');

    //aisle location
    expect(result[4].title).toBe('Ref: Aisle');

    //room location
    expect(result[5].title).toBe('Ref: Room');
  });
});
