const labels = {
  SiteDetails: {
    'Select site': 'เลือกไซต์นี้',
    'Ref: Page title': 'รายละเอียดเว็บไซต์',
    'Ref: Skip map': 'ข้ามไปยังเนื้อหาหลัก',
    'Ref: Unavailable site': 'ตัวอย่างไซต์ไม่สามารถใช้ได้สำหรับผู้ใช้ของคุณ',
    'Ref: Sorry, no description yet': 'ขออภัยเรายังไม่มีคำอธิบาย',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'ไม่พบพิกัดไซต์',
    'Ref: Page title': 'ไซต์',
    'Recently visited': 'เยี่ยมชมเมื่อเร็ว ๆ นี้',
    'All other sites': 'เว็บไซต์อื่น ๆ ทั้งหมด',
    'Sites in your country': 'เว็บไซต์ในประเทศของคุณ',
    'Search results': 'ผลการค้นหา',
    'Select site': 'เลือกเว็บไซต์',
    'Ref: Select site with QR Scanner': 'เลือกไซต์ด้วยสแกนเนอร์ QR',
    'Ref: Select site with code': 'เลือกไซต์ด้วยรหัส',
    'Ref: Validate': 'ตรวจสอบความถูกต้อง',
    'Ref: Enter site code': 'ป้อนรหัสไซต์',
    'Ref: Scanner error header': 'ไม่พบไซต์',
    'Ref: Generic error': 'บางอย่างผิดพลาด',
    'Ref: Change site': 'เปลี่ยนไซต์',
    'Ref: Change site description': 'มีรายการในรถเข็น! การเปลี่ยนไซต์จะส่งผลให้การล้างรถเข็น',
    'Ref: Cancel': 'ยกเลิก',
    'Ref: Not found': 'รหัส QR นี้ยังไม่ได้รับการยอมรับ',
    'Ref: Unauthorized error': 'คำขอที่ไม่ได้รับอนุญาตเนื่องจากระดับการ จำกัด ไซต์',
    'Ref: Forbidden':
      'ขออภัยคุณไม่มีสิทธิ์ในการเข้าถึงเว็บไซต์นี้ หากต้องการจัดการการเข้าถึงโปรดติดต่อศูนย์บริการ Pilbara ที่ 1800 992 777',
    "Ref: We didn't find the site.": 'เราไม่พบไซต์',
    'Ref: We recommend some solutions:': 'เราขอแนะนำวิธีแก้ปัญหาบางอย่าง:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. ลองเพิ่มไซต์ของคุณด้วยเครื่องสแกน QR หรือด้วยความช่วยเหลือของปุ่มด้านล่าง',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. ออกจากระบบและเข้าสู่ระบบโดยเลือกประเทศที่ถูกต้อง',
    'Ref: The currently selected country is:': 'ประเทศที่เลือกในปัจจุบันคือ:',
  },
  SwitchSite: { 'Not on {site name}?': 'ไม่ได้อยู่ที่ {site name}?' },
  SiteTile: { Details: 'รายละเอียด', Select: 'เลือก' },
};
export default labels;
