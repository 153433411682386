const labels = {
  ContentDetails: {
    'Ref: Page title': 'Podrobnosti o obsahu',
    'See the event': 'Vidět událost',
    'Take the survey': 'Zprávy a články',
    Date: 'Datum',
    Category: 'Kategorie',
    'Ref: Content site different title': 'Tento obsah nepatří k vašemu současnému webu',
    'Ref: Content site different description':
      'Prohlížíte si obsah, který nepatří k vašemu současnému webu. Nebudete to vidět v seznamu obsahu.',
  },
  ContentList: {
    'Ref: Page title': 'Newsfeed',
    'featured (as in featured content)': 'Zprávy a články',
    'Ref: category - all': 'Všechno',
    'Ref: category - promotional': 'Propagační',
    'Ref: category - featured': 'Představoval',
    'Ref: category - other': 'jiný',
  },
  Widget: {
    'Ref: Carousel title': 'Zprávy a články',
    'Ref: Carousel title promo': 'Nejlepší zprávy',
    'No info yet, come back later': 'Zatím žádné informace, vrať se později 😉',
    'Ref: Content': 'Obsah',
    'Ref: Widget Title': 'Newsfeed',
    'Ref: MyVillage Carousel title promo': 'Propagace',
  },
};
export default labels;
