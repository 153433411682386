const labels = {
  StaysList: {
    'Ref: Page title': 'Tetap',
    'Ref: Past': 'Masa lalu',
    'Current and future stays': 'TINGGAL SAAT INI DAN MASA DEPAN',
    'filter label: period': 'Periode',
    'Ref: Search': 'Mencari',
  },
  StayDetails: {
    'Ref: Page title': 'Detail tinggal Anda',
    'Arrival date': 'Tanggal kedatangan',
    'Departure date': 'Tanggal keberangkatan',
    'Room number': 'Kamar',
    'Reservation number': 'Nomor pendaftaran',
    CheckIn: 'Mendaftar',
    CheckOut: 'Periksa',
    CheckIn_1: 'Periksa ke {lodgeName}, tiba hari ini? (Nomor reservasi {resNumber})',
    CheckedIn_1: 'Anda diperiksa ke {lodgeName}, kamar {roomNumber}.',
    CheckOut_1:
      'Lihat {lodgeName} nomor reservasi {resNumber} sekarang? Kunci kamar Anda akan dinonaktifkan.',
    CheckedOut_1: '{lodgeName} nomor reservasi {resNumber} telah memeriksa.',
  },
  StaysSuccess: {
    'Ref: Title': 'Terima kasih atas tinggal Anda!',
    'Ref: Body': 'Checkout Anda telah berhasil diproses.',
    'Ref: Go to home': 'Pergi ke rumah',
    'Ref: Provide a feedback': 'Berikan umpan balik',
  },
};
export default labels;
