import { FC } from 'react';

import { LikeIcon, LoveIcon, InterestingIcon, CongrateIcon } from '../assets/icons';
import { SVGProps } from '../assets/svgFactory';

export enum REACTIONS {
  LIKE = 'LIKE',
  LOVE = 'LOVE',
  INTERESTING = 'INTERESTING',
  CONGRATE = 'CONGRATE',
}

export type StyleReactionType = {
  icon: FC<SVGProps>;
  backgroundColor: string;
};

export type ReactionType = {
  type: REACTIONS;
  label: string;
  count?: number;
};

export type ReactionHandlerType = {
  handleReactionClick: (reaction: REACTIONS) => void;
};

export const REACTIONS_STYLING: Record<REACTIONS, StyleReactionType> = {
  [REACTIONS.LIKE]: {
    icon: LikeIcon,
    backgroundColor: 'rgba(var(--like-color), 0.12)',
  },
  [REACTIONS.LOVE]: {
    icon: LoveIcon,
    backgroundColor: 'rgba(var(--love-color), 0.12)',
  },
  [REACTIONS.INTERESTING]: {
    icon: InterestingIcon,
    backgroundColor: 'rgba(var(--interesting-color), 0.12)',
  },
  [REACTIONS.CONGRATE]: {
    icon: CongrateIcon,
    backgroundColor: 'rgba(var(--congrate-color), 0.12)',
  },
};

export type ReactionsAPIType = {
  reactions: ReactionType[];
  total: number;
  me: REACTIONS | null;
};

export type POSTReactionRequest = { id: string; reactionType: REACTIONS | null };

export type DELETEReactionRequest = { id: string };
