import classNames from 'classnames';
import React, { FC, MouseEvent, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { CheckboxGroupContextProps } from '../CheckboxGroup.types';
import useCheckboxGroup from '../useCheckboxGroup';

import { ControllerProps } from './Controller.types';

import styles from './Controller.module.css';

const Controller: FC<ControllerProps> = ({
  label,
  value,
  controlId,
  control,
  'data-testid': dataTestId = 'checkbox-group-controller',
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const checkboxGroup = useCheckboxGroup();
  const { disabled, onClick, value: groupValue, name } = checkboxGroup as CheckboxGroupContextProps;

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (inputRef?.current) {
      inputRef?.current.click();
    }
  };

  const controlWithProps = useMemo(() => {
    return React.cloneElement(control, {
      id: controlId,
      inputRef,
      checked: groupValue?.includes(value),
      value,
      name: `${name}_${uuid()}`,
      disabled,
      onClick,
    });
  }, [control, controlId, disabled, groupValue, name, onClick, value]);

  return (
    <div className={classNames(styles.option)} data-testid={dataTestId}>
      <div
        className={classNames(styles.labelWrapper)}
        onClick={handleClick}
        aria-hidden="true"
        role="presentation"
      >
        {label && (
          <label className={classNames('bodySBold')} htmlFor={controlId}>
            {label}
          </label>
        )}
      </div>
      <div className={classNames(styles.input)}>{controlWithProps}</div>
    </div>
  );
};

export default Controller;
