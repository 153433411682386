import Checkbox from '../../Checkbox';

import { SingleCheckboxFilterProps } from './SingleCheckboxFilterProps.types';

import styles from './SingleCheckboxFilter.module.css';

const SingleCheckboxFilter = ({
  id,
  value,
  checked,
  label,
  onClick,
  'data-testid': testId,
}: SingleCheckboxFilterProps) => {
  return (
    <label data-testid={`single-checkbox-${id}`} htmlFor={id} className={styles.singleCheckbox}>
      {label}
      <Checkbox id={id} value={value} checked={checked} data-testid={testId} onClick={onClick} />
    </label>
  );
};

export default SingleCheckboxFilter;
