import classNames from 'classnames';
import React from 'react';

import { ChevronRightIcon, EditOnIcon, ExternalLink } from '../../../assets/icons';
import useToggle from '../../../helpers/hooks/useToggle';
import Checkbox from '../../atoms/Checkbox';
import Link from '../../atoms/Link';
import QuantitySetter from '../../atoms/QuantitySetter/QuantitySetter';
import Radio from '../../atoms/Radio/Radio';
import EditAlert from '../../molecules/EditAlert/EditAlert';

import LabelWrapper from './LabelWrapper';
import {
  CONTROL_TYPE,
  isQuantitySetterProps,
  ListItemIconProps,
  ListItemOnClickProps,
  ListItemProps,
  ListItemWrapperProps,
} from './List.types';

import styles from './List.module.css';

const ListItemWrapper = ({
  path,
  externalPath,
  showAlert,
  onClick,
  disabled,
  children,
  role,
  className,
  'data-testid': testId,
}: ListItemWrapperProps) => {
  const linkClassNames = classNames(styles.aLink, {
    [styles.disabled]: disabled,
  });
  const nonLinkWrapperProps = {
    ...(showAlert && {
      onClick: showAlert,
    }),
  };

  const structuredNonLink = () => {
    if (!onClick) {
      if (showAlert) {
        return <button className={styles.itemContainer}>{children}</button>;
      }
      return <span className={styles.itemContainer}>{children}</span>;
    }

    return (
      <button
        onClick={onClick}
        className={classNames(styles.itemContainer, {
          [styles.disabled]: disabled,
        })}
        data-testid={`${testId}-button`}
        {...(disabled ? { 'aria-disabled': true } : {})}
      >
        {children}
      </button>
    );
  };

  if (path) {
    return (
      <li
        className={classNames(styles.itemLink, className)}
        role={role}
        data-testid={`${testId}-path`}
      >
        <Link data-testid={`${testId}-path`} to={path} className={linkClassNames}>
          {children}
        </Link>
      </li>
    );
  }

  if (externalPath) {
    return (
      <li
        className={classNames(styles.itemLink, className)}
        role={role}
        data-testid={`${testId}-external-path`}
      >
        <Link data-testid={`${testId}-external-path`} to={externalPath} className={linkClassNames}>
          {children}
        </Link>
      </li>
    );
  }

  return (
    <li
      {...nonLinkWrapperProps}
      className={classNames(styles.item, className)}
      role={role}
      data-testid={`${testId}-non-link`}
    >
      {structuredNonLink()}
    </li>
  );
};

const RenderControl = React.memo(
  ({
    id,
    control,
    disabled,
    onClick,
    'data-testid': testId,
  }: Required<
    Pick<ListItemProps, 'control' | 'disabled'> & {
      onClick: (
        event: React.MouseEvent<HTMLInputElement> | React.MouseEvent<HTMLIonButtonElement>,
        props: ListItemOnClickProps
      ) => void;
      id: string;
    }
  > & { 'data-testid'?: string }) => {
    const props = control.props;

    switch (control.type) {
      case CONTROL_TYPE.CHECKBOX: {
        if (isQuantitySetterProps(props)) break;
        return (
          <Checkbox
            {...props}
            id={id}
            disabled={disabled}
            onClick={onClick}
            data-testid={`${testId}-checkbox`}
          />
        );
      }
      case CONTROL_TYPE.RADIO: {
        if (isQuantitySetterProps(props)) break;
        return <Radio {...props} id={id} disabled={disabled} onClick={onClick} />;
      }
      case CONTROL_TYPE.QUANTITY: {
        if (!isQuantitySetterProps(props)) break;
        return <QuantitySetter {...props} onChange={onClick} />;
      }
      default:
        return null;
    }

    return null;
  }
);

const ListItem = ({
  id,
  className,
  name,
  label,
  secondaryLabel,
  value,
  control,
  disabled = false,
  icons,
  onClick,
  path,
  externalPath,
  wrapLine = false,
  labelClassName,
  valueClassName,
  edit,
  action,
  hasRole = false,
  'data-testid': testId,
  ...props
}: ListItemProps) => {
  const { state: alertIsOpen, toggleOff: hideAlert, toggleOn: showAlert } = useToggle();

  const handleOnClick = (props: ListItemOnClickProps) => {
    if (onClick) {
      onClick(id, props);
    }
  };

  return (
    <ListItemWrapper
      externalPath={externalPath}
      path={path}
      {...(edit && { showAlert: showAlert })}
      disabled={disabled}
      onClick={action}
      className={className}
      role={hasRole ? 'presentation' : undefined}
      data-testid={testId}
    >
      <LabelWrapper hasLabel={!!control} id={id}>
        <span
          className={classNames(styles.labelValueWrapper, { [styles.wrapLine]: wrapLine })}
          data-testid={`${testId}-wrapper`}
        >
          <div
            data-testid={`${testId}-label`}
            className={classNames(labelClassName, styles.label)}
            {...props}
          >
            <span className={classNames(styles.labelWrapper)}>
              {icons
                ?.filter((icon) => !icon.afterLabel && !icon.endIcon)
                .map((i: ListItemIconProps, _: number) => {
                  const Icon = i.icon;
                  return <Icon key={_} className={styles.icon} />;
                })}
              {label && (
                <span className={classNames(styles.labelTextWrapper)}>
                  {label} {secondaryLabel && <span>{secondaryLabel}</span>}
                </span>
              )}

              {icons && (
                <span className={classNames(styles.iconsWrapper)}>
                  {icons
                    ?.filter((icon) => icon.afterLabel && !icon.endIcon)
                    .map((i: ListItemIconProps, _: number) => {
                      const Icon = i.icon;
                      return <Icon key={_} className={styles.icon} />;
                    })}
                </span>
              )}
            </span>
          </div>
          <span className={classNames(styles.valueWrapper)}>
            <span
              className={classNames(valueClassName, styles.value)}
              data-testid={`${testId}-value`}
            >
              {value}
            </span>
          </span>
        </span>
        {control && (
          <div className={classNames(styles.control)}>
            <RenderControl
              id={id}
              control={control}
              disabled={disabled}
              onClick={(
                e: React.MouseEvent<HTMLInputElement> | React.MouseEvent<HTMLIonButtonElement>,
                props: ListItemOnClickProps
              ) => handleOnClick(props)}
              data-testid={testId}
            />
          </div>
        )}
        {path && (
          <div className={classNames(styles.actionIcon)} data-testid={`${testId}-chevron`}>
            <ChevronRightIcon />
          </div>
        )}
        {externalPath && (
          <div
            className={classNames(styles.actionIcon, styles.externalLinkIcon)}
            data-testid={`${testId}-external-link-icon`}
          >
            <ExternalLink />
          </div>
        )}
        {edit && (
          <>
            <div className={classNames(styles.actionIcon)} data-testid={`${testId}-edit-icon`}>
              <EditOnIcon />
            </div>
            <EditAlert
              id={id}
              name={name ?? id}
              value={value?.toString()}
              alertIsOpen={alertIsOpen}
              hideAlert={hideAlert}
              data-testid={`${testId}-edit-alert`}
              {...edit}
            />
          </>
        )}
        {action && (
          <div className={classNames(styles.actionIcon)} data-testid={`${testId}-actions`}>
            {!icons?.filter((icon) => icon.endIcon).length && <EditOnIcon />}
            {icons
              ?.filter((icon) => icon.endIcon)
              .map((i: ListItemIconProps, _: number) => {
                const Icon = i.icon;
                return (
                  <Icon data-testid={`${testId}-actions-icon`} key={_} className={styles.icon} />
                );
              })}
          </div>
        )}
      </LabelWrapper>
    </ListItemWrapper>
  );
};

export default ListItem;
