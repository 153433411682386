const labels = {
  ContentDetails: {
    'Ref: Page title': '内容细节',
    'See the event': '查看活动',
    'Take the survey': '参加调查',
    Date: '日期',
    Category: '类别',
    'Ref: Content site different title': '此内容不属于您当前的网站',
    'Ref: Content site different description':
      '您正在查看不属于当前网站的内容。您将无法在内容列表中看到它。',
  },
  ContentList: {
    'Ref: Page title': '新闻源',
    'featured (as in featured content)': '精选',
    'Ref: category - all': '全部',
    'Ref: category - promotional': '促销',
    'Ref: category - featured': '特色',
    'Ref: category - other': '其他',
  },
  Widget: {
    'Ref: Carousel title': '新闻&文章',
    'Ref: Carousel title promo': '最新消息',
    'No info yet, come back later': '暂无信息，请稍后再来😉',
    'Ref: Content': '内容',
    'Ref: Widget Title': '新闻源',
    'Ref: MyVillage Carousel title promo': '晋升',
  },
};
export default labels;
