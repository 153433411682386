import React from 'react';

export enum BAR_TYPES {
  ACTIONS_BAR = 'actionsBar',
  CUSTOM_BAR = 'customBar',
}

export type BottomBarAction = {
  label: string | React.ReactElement;
  action: Function;
  isActive?: boolean;
};

type BottomBarNext = {
  label: string;
  action: Function;
};

export type BottomBarType = {
  type: BAR_TYPES;
  actions?: BottomBarAction[];
  topContent?: React.ReactElement; //to optionally add some content above the actions
  next?: BottomBarNext;

  children?: React.ReactNode;
};
