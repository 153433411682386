import classNames from 'classnames';
import { createRef, useEffect, useRef, useState } from 'react';

import { SIZE } from '../../../constants';
import textTransform, { TRANSFORM_TYPE } from '../../../helpers/textTransform';
import Button, { BUTTON_LOOK } from '../Button';
import Tooltip, { TOOLTIP_POSITION } from '../Tooltip';

import { FilterOption, Filtering, NormalFilter } from './Filters.types';
import { getFilterApplicableOptions } from './helpers';

import { ChevronLeftIcon, ChevronRightIcon } from '@/assets/icons';

import styles from './ExpandedFilter.module.css';

import { TestingPropsV2 } from 'src/types';

export const ExpandedFilter = ({
  filter,
  filtering,
  'data-testid': testId,
  onChange,
  label,
  sectionRefs,
  activeSectionIndex,
}: {
  filter: NormalFilter;
  filtering: Filtering;
  onChange: (filterId: string, value: string) => void;
  label: (text: string) => string;
  sectionRefs?: React.MutableRefObject<React.RefObject<HTMLSpanElement>[]>;
  activeSectionIndex?: number;
} & TestingPropsV2) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<React.RefObject<HTMLDivElement>[]>([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>(activeSectionIndex as number);
  const [fromButtonScroll, setFromButtonScroll] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const useScrollFiltering = sectionRefs && sectionRefs.current && sectionRefs.current.length > 0;
  const { id } = filter;

  const applicableOptions = getFilterApplicableOptions(filter, filtering);
  if (buttonRefs.current.length !== applicableOptions.length) {
    // Assign a ref for each button if not already assigned
    buttonRefs.current = applicableOptions.map((_, i) => buttonRefs.current[i] || createRef());
  }

  const scrollIntoCenter = (buttonIndex: number) => {
    const container = containerRef.current;
    const buttonRef = buttonRefs.current[buttonIndex];

    if (container && buttonRef?.current) {
      // Force reflow
      void container.offsetHeight;

      const button = buttonRef.current;
      const containerRect = container.getBoundingClientRect();
      const buttonRect = button.getBoundingClientRect();

      // Calculate the scroll position needed to bring the button to the center
      const scrollLeftNeeded = buttonRect.left + (buttonRect.width / 2) - (containerRect.width / 2) - containerRect.left + container.scrollLeft;

      container.scrollTo({ left: scrollLeftNeeded, behavior: 'smooth' });
    }
  };

  const getTabBtnLook = (index: number, option: FilterOption) => {
    if(useScrollFiltering) {
      return index === activeButtonIndex ? BUTTON_LOOK.PRIMARY : BUTTON_LOOK.SECONDARY_INLINE
    } else {
      return filtering[id]?.[option.value] ? BUTTON_LOOK.PRIMARY : BUTTON_LOOK.SECONDARY_INLINE
    }
  }

  const handleSectionClick = (id: string, value: string, sectionIndex: number) => {
    if(onChange && !useScrollFiltering) onChange(id, value);
    if(sectionIndex && useScrollFiltering) handleScrollToSection(sectionIndex);
  };
  
  const handleScrollToSection = (sectionIndex: number) => {
    setFromButtonScroll(true);
    if (sectionRefs) {
      const sectionRef = sectionRefs.current[sectionIndex];
      if (sectionRef && sectionRef.current) {
        const scrollOffset = 90; 
  
        // Create a container div and adjust its padding or margin
        const container = document.createElement('span');
        // Add a padding offset
        container.style.paddingTop = `${scrollOffset}px`; 
  
        if (sectionRef.current.parentElement) {
          // Insert the container as a sibling after the target section
          sectionRef.current.parentElement.insertBefore(container, sectionRef.current.nextSibling);
  
          // Scroll the container into view
          container.scrollIntoView({ behavior: 'smooth'});
  
          // Clean up by removing the container
          if(container.parentNode){
            container.parentNode.removeChild(container);
          }
        }
      }
    }
    setActiveButtonIndex(sectionIndex);
    setTimeout(() => {
        setFromButtonScroll(false);
        scrollIntoCenter(sectionIndex);
    }, 750); 
  
  };
  
  
  const checkArrows = () => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollLeft, scrollWidth, clientWidth } = container;
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 10);
  };

  const scroll = (direction: 'left' | 'right') => {
    const container = containerRef.current;
    if (!container) return;

    const scrollAmount = direction === 'left' ? -150 : 150;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  useEffect(() => {
    // Set the timeout and keep a reference to it
    const timeoutId = setTimeout(() => {
      checkArrows();
    }, 200);

    // Clear the timeout in the cleanup function
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      checkArrows();
    };

    const handleScroll = () => {
      checkArrows();
    };

    const refCurrent = containerRef.current;

    window.addEventListener('resize', handleResize);
    refCurrent?.addEventListener('scroll', handleScroll);

    // Initial check to set arrow visibility
    checkArrows();

    return () => {
      window.removeEventListener('resize', handleResize);
      refCurrent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
  if (!fromButtonScroll) {
      setActiveButtonIndex(activeSectionIndex as number);
      // Use requestAnimationFrame to ensure scrollIntoCenter is called after DOM updates
      requestAnimationFrame(() => {
        setTimeout(() => {
          setFromButtonScroll(false);
          scrollIntoCenter(activeSectionIndex as number);
        }, 100);     
    });
  }
}, [activeSectionIndex, fromButtonScroll]);

  useEffect(() => {
    setShowTooltip(prev => !prev);
  }, [activeButtonIndex])

  useEffect(() => {
    if(applicableOptions[0].default && useScrollFiltering){
      applicableOptions.shift();
    }
  }, [applicableOptions, useScrollFiltering])
  
  return (
    <div className={styles.expandedFilterWithButtons}>
      <Button
      affix={() => <ChevronLeftIcon />}
      look={BUTTON_LOOK.TERTIARY}
      size={SIZE.SMALL}
      onClick={() => scroll('left')}
      data-testid={`${testId}-categories-left-button`}
      data-cy={'categories-left-button'}
      className={classNames(styles.leftArrow, { [styles.leftArrowEnabled]: showLeftArrow })}
      aria-label={label("scrollLeftButton")}
      /> 
  <div className={classNames(styles.expandedFilterButtonContainer)} ref={containerRef}>    
    {applicableOptions.map((option, index) =>
      option.label.length > 15 && showTooltip ? (
        <div ref={buttonRefs.current[index]}>
        <Tooltip key={option.value} content={option.label} place={TOOLTIP_POSITION.Top}>
          <Button
            look={getTabBtnLook(index, option)}
            data-cy={'filter-option-expanded'}
            data-testid={`${testId}-option-${filter.id}-${option.value}`}
            onClick={() => handleSectionClick(filter.id, option.value, index)}
            size={SIZE.SMALL}
            className={classNames(styles.expandedFilterButton)}
          >
            {textTransform(option.label, TRANSFORM_TYPE.CAPITALIZE)}
          </Button>
        </Tooltip>
        </div>
      ) : (
        <div ref={buttonRefs.current[index]}>
        <Button
          key={option.value}
          look={getTabBtnLook(index, option)}
          data-cy={'filter-option-expanded'}
          data-testid={`${testId}-option-${filter.id}-${option.value}`}
          onClick={() => handleSectionClick(filter.id, option.value, index)}
          size={SIZE.SMALL}
          className={classNames(styles.expandedFilterButton)}
        >
          {textTransform(option.label, TRANSFORM_TYPE.CAPITALIZE)}
        </Button>
        </div>
      )
    )}
  </div>
  <Button
      affix={() => <ChevronRightIcon />}
      look={BUTTON_LOOK.TERTIARY}
      size={SIZE.SMALL}
      onClick={() => scroll('right')}
      data-testid={`${testId}-categories-right-button`}
      data-cy={'categories-right-button'}
      className={classNames(styles.rightArrow, { [styles.rightArrowEnabled]: showRightArrow })}
      aria-label={label("scrollRightButton")}
  />
    </div> 
    
  );
};
