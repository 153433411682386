import classNames from 'classnames';
import { FC, useState } from 'react';

import { ImageWithFallbackProps } from './ImageWithFallback.types';

const ImageWithFallback: FC<ImageWithFallbackProps> = ({
  imgInfo,
  imgElement,
  className,
  'data-testid': dataTestId,
}) => {
  const [onErrorImage, setOnErrorImage] = useState<React.ReactNode>();

  if (!onErrorImage && imgInfo) {
    return (
      <img
        data-testid={`${dataTestId}-image-with-fallback`}
        className={classNames(className)}
        src={imgInfo.src}
        alt={imgInfo.alt}
        style={{
          backgroundColor: imgInfo.bgColor ? imgInfo.bgColor : '',
          padding: imgInfo.padding ?? '',
        }}
        onError={(e) => {
          // on error, hide the thumbnail unless custom error function is defined
          // and fallback (imgElement) not provided
          if (imgInfo.onError) imgInfo.onError(e);
          else if (imgElement) {
            setOnErrorImage(imgElement);
          } else {
            const target = e.target as any;
            if (target?.parentNode?.style) {
              target.parentNode.style.display = 'none';
            }
          }
        }}
      />
    );
  }

  return <>{onErrorImage ?? imgElement}</>;
};

export default ImageWithFallback;
