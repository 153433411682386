import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { TranslationProps } from '../../../localization/localization.types';
import Accordion from '../Accordion/Accordion';
import Checkbox from '../Checkbox';
import { CheckboxOnClickEvent, CheckboxOnClickProps } from '../Checkbox/Checkbox.types';
import Title, { TITLE_SIZE } from '../Title';

import { GroupOption, Groups } from './CheckboxGroupFilter.types';
import { FilterOption } from './Filters.types';

import styles from './CheckboxGroupFilter.module.css';

const CheckboxGroupFilter = ({
  values,
  options,
  label: labelFunc,
  onChange,
  'data-testid': testId,
}: {
  values: string[];
  options: FilterOption[];
  onChange: (value: string, checked: boolean, newSelection: string[]) => void;
  'data-testid': string;
} & TranslationProps) => {
  const [selection, setSelection] = useState<string[]>(values);

  const handleClick = useCallback(
    (_: CheckboxOnClickEvent, { value, checked }: CheckboxOnClickProps) => {
      const newSelection = checked
        ? [...selection, value ?? '']
        : selection.filter((sel) => sel !== value);

      setSelection(newSelection);

      if (onChange) {
        onChange(value ?? '', !!checked, newSelection);
      }
    },
    [onChange, selection]
  );

  const groups = options?.reduce((acc, option) => {
    const { group, value, label } = option;

    if (group) {
      if (acc[group]) acc[group].push({ label, value });
      else acc[group] = [{ label, value }];
    }

    return acc;
  }, {} as Groups);

  const sections = Object.entries(groups).map(([groupName, groupOtions]) => ({
    id: groupName,
    'data-testid': `checkbox-group-${testId}-${groupName}`,
    title: labelFunc(groupName),
    content: (
      <>
        {groupOtions.map(({ label: inputLabel, value }: GroupOption, index: number) => (
          <div className={classNames(styles.checkboxWrapper)}>
            <label className={classNames(styles.titleWrapper)} htmlFor={`${groupName}_${index}`}>
              <Title size={TITLE_SIZE.BODYSBOLD}>{inputLabel}</Title>
            </label>
            <Checkbox
              id={`${groupName}_${index}`}
              key={value}
              value={value}
              name={groupName}
              checked={values?.includes(value)}
              data-testid={`checkbox-group-${testId}-${groupName}-${index}`}
              onClick={handleClick}
            />
          </div>
        ))}
      </>
    ),
  }));

  return (
    <Accordion
      defaultOpenedSections={sections.map(({ id }) => id)}
      allowMultipleSectionOpen
      inheritStyles={styles.accordion}
      titleCustomClass="bodySBold"
      sections={sections}
      data-testid={`checkbox-group-${testId}`}
    />
  );
};

export default CheckboxGroupFilter;
