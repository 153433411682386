const labels = {
  All: {
    dailyMenu: 'ไม่ทราบ',
    anytime: 'ทุกที่ทุกเวลา',
    breakfast: 'อาหารเช้า',
    morningBreak: 'ยามเช้า',
    lunch: 'อาหารกลางวัน',
    afternoonBreak: 'ช่วงบ่าย',
    dinner: 'อาหารเย็น',
    supper: 'อาหารมื้อเย็น',
    nightMeal: 'มื้อค่ำ',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'เมนู',
    'Ref: View details': 'ดูรายละเอียด',
    'Ref: Facility details': 'รายละเอียดสิ่งอำนวยความสะดวก',
  },
  OpeningHours: { everyday: 'ทุกวัน', 'open all day': 'เปิดทั้งวัน' },
  ProductsList: {
    'Ref: Page title': 'เมนู',
    'Ref: Facility details': 'รายละเอียดสิ่งอำนวยความสะดวก',
    'Ref: Search products list': 'รายการค้นหา',
    'Ref: filter label: date': 'วันที่',
    'Ref: filter label: menus': 'เมนู',
    'Ref: filter label: moment': 'ช่วงเวลา',
    'Ref: filter label: category': 'หมวดหมู่',
    'Ref: filter label: dishes': 'จาน',
    'Ref: filter label: highlight': 'เน้นหรือซ่อนตัวกรองด้วยอาหาร',
    All: 'ทั้งหมด',
  },
  ProductDetails: { 'Ref: Page title': 'รายละเอียดสินค้า' },
  Widget: {
    'Ref: Carousel title': 'เมนู',
    'No info yet, come back later': 'ยังไม่มีข้อมูลกลับมาทีหลัง😉',
    'Ref: menus': 'เมนู',
    'Ref: see all': 'ดูทั้งหมด',
  },
};
export default labels;
