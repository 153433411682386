import classNames from 'classnames';
import { useState } from 'react';

import { getCurrentLanguageCode } from '../../../helpers/misc';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Radio from '../../atoms/Radio';
import Title, { TITLE_SIZE } from '../../atoms/Title';
import LangSelector from '../LangSelector/LangSelector';

import { modalLanguages } from './LanguageTile.helper';
import { LanguageTileProps } from './LanguageTile.types';

import styles from './LanguageTile.module.css';

const LanguageTile = ({
  image,
  lang,
  code,
  selected,
  onChange,
  open,
  setModalOpen,
}: LanguageTileProps) => {
  const { label } = useCustomTranslation();
  const isLanguageFromModal = modalLanguages.some(
    (language) => language.code === getCurrentLanguageCode()
  );
  const languageFromModal = modalLanguages.find((el) => el.code === getCurrentLanguageCode())
    ?.name as string;
  const [otherSelectedLanguage, setOtherSelectedLanguage] = useState<string>(
    isLanguageFromModal ? languageFromModal : ''
  );

  const onChangeModal = (code: string, language: string) => {
    setOtherSelectedLanguage(language);
    onChange(code, 'Other');
  };

  const isOtherLanguageSelected = () => lang === 'Other';

  const handleChange = () => {
    if (isOtherLanguageSelected()) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
    onChange(code, lang);
  };

  return (
    <>
      <div
        className={classNames({ [styles.tileContainer]: true, [styles.active]: selected })}
        data-testid="language-tile"
        aria-label={lang}
      >
        <div className={styles.contentContainer}>
          {image}
          <Title size={TITLE_SIZE.BODYSDEFAULT}>
            {lang}
            {isOtherLanguageSelected() && otherSelectedLanguage && ` (${otherSelectedLanguage})`}
          </Title>
        </div>
        <Radio
          checked={selected}
          data-testid={`language-radio-${code}`}
          inputLabel={code}
          labelHidden
          onClick={handleChange}
        />
      </div>
      {isOtherLanguageSelected() && (
        <LangSelector
          label={label}
          open={open}
          setModalOpen={setModalOpen}
          handleLanguageSubmit={(modalCode, modalLanguage) =>
            onChangeModal(modalCode, modalLanguage)
          }
        />
      )}
    </>
  );
};

export default LanguageTile;
