import { cleanup, screen } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import TimeTable from '../TimeTable';

jest.mock('../../../api', () => ({
  useGetOriginsQuery: () => ({ data: [] }),
  useGetDestinationsQuery: () => ({ data: [] }),
  useGetSchedulesQuery: () => ({ data: [] }),
}));

describe('TimeTable', () => {
  afterAll(() => cleanup());

  describe('Initial render', () => {
    renderComponent(TimeTable);

    it('Should render page correctly', () => {
      const heading = screen.queryByRole('heading');
      expect(heading).toBeTruthy();
    });
  });
});
