import { Storage, Drivers } from '@ionic/storage';

export class AuditStateCacheService {
  public storage: Storage;
  static instance: AuditStateCacheService | undefined;
  public debug = false;

  constructor(debug = false) {
    this.storage = new Storage({
      name: 'audit_state_cache',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage, Drivers.IndexedDB],
    });

    this.storage.create();
    this.debug = debug;
  }

  log(text: string, ...args: Array<unknown>) {
    if (this.debug) {
      console.log.call(this, text, ...args);
    }
  }

  async getValue(key: string) {
    const value = await this.storage.get(key);
    if (!value) return;

    return value.value;
  }

  async setValue(key: string, value: unknown) {
    const storeValue = { value };
    return this.storage.set(key, storeValue);
  }

  async remove(key: string) {
    this.log(`[CACHE] Removing ${key}`);
    return this.storage.remove(key);
  }

  async keys() {
    return this.storage.keys();
  }

  async flush() {
    return this.storage.clear();
  }

  static getInstance(): AuditStateCacheService {
    if (!this.instance) {
      this.instance = new AuditStateCacheService();
    }

    return this.instance;
  }
}
