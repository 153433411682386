const mockResponse = {
  getAuditInvites: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: '97b0a59e-9501-4edf-abd9-24978540971b',
          auditName: 'L2-Cleaning Laundry',
          auditStreamType: 'Accomodation',
          workOrderNumber: '3080296',
          locationId: '47754440-a7d2-e911-a812-000d3a6aa0b3',
          assetName: 'Some asset name',
          assetId: '8ae5b344-6355-e611-80f4-a45d36fc5a4c',
          workOrderLocation: 'Angelo River',
          dueDate: '2023-04-01T00:00:00+00:00',
          state: 0,
        },
        {
          auditName: 'L2-Care and Maintenance Property-Grounds',
          auditStreamType: 'Accomodation',
          dueDate: '2023-03-19T00:00:00+00:00',
          id: 'cad567fb-c47a-4660-b957-838e4fb95b1d',
          state: 0,
          workOrderLocation: 'Hope Downs 1 Village',
          locationId: '1234',
          workOrderNumber: '4191958',
        },
        {
          auditName: 'L2-Care and Maintenance Property-Grounds',
          auditStreamType: 'Grounds Maintenance',
          dueDate: '2023-03-29T00:00:00+00:00',
          id: '1bc24ac9-3252-4fc9-a151-b79ea96308a8',
          state: 1,
          workOrderLocation: 'Hope Downs 1 Village',
          locationId: '1234',
          workOrderNumber: '4193066',
        },
      ]),
  },
  getAuditSurvey: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        state: 1,
        workOrderId: '3465031b-7911-ec11-b6e6-0022481131s4',
        inviteId: 'cad567fb-c47a-4660-b957-838e4fb95b1d',
        auditId: '0769031b-7911-ec11-b6e6-0022481131f6',
        auditName: 'L2-Care and Maintenance Property-Grounds',
        auditStream: 'Grounds Maintenance',
        workOrderNumber: '4191958',
        locationId: '47754440-a7d2-e911-a812-000d3a6aa0b3',
        assetName: 'Some asset name',
        assetId: '8ae5b344-6355-e611-80f4-a45d36fc5a4c',
        questions: [
          {
            id: '0f684f1b-7911-ec11-b6e6-002248113967',
            text: 'How do you rate it?',
            questionType: 5,
            choices: [],
            order: 700,
            isRequired: true,
            isCommentRequired: true,
            isPhotoRequired: false,
            maximumRating: 5,
            response: {},
          },
          {
            id: '0f684f1b-7911-ec11-b6e6-002248113963',
            text: 'Are there control measures in place for pest, feral animals and diseases?',
            questionType: 1,
            choices: ['Yes', 'No', 'N/A'],
            order: 700,
            isRequired: true,
            isCommentRequired: false,
            isPhotoRequired: false,
            minimumRating: 0,
            maximumRating: 0,
          },
          {
            choices: [],
            id: '3cf9211e-ff61-ec11-8943-00224812933d',
            isCommentRequired: false,
            isPhotoRequired: false,
            isRequired: true,
            maximumRating: 0,
            minimumRating: 0,
            order: 1100,
            questionType: 3,
            text: 'Please make comment on any items of non conformance or areas for improvement.',
          },
          {
            choices: [],
            id: '3cf9211e-ff61-ec11-8943-00224812953z',
            isCommentRequired: false,
            isPhotoRequired: false,
            isRequired: true,
            maximumRating: 0,
            minimumRating: 0,
            order: 1100,
            questionType: 4,
            text: 'Please make longer comment on any items of non conformance or areas for improvement.',
          },
          {
            id: '12684f1b-7911-ec11-b6e6-002248113963',
            text: 'Is the natural vegetation in a healthy natural environmental state?',
            questionType: 2,
            choices: ['Yes', 'No', 'N/A'],
            order: 550,
            isRequired: true,
            isCommentRequired: false,
            isPhotoRequired: false,
            minimumRating: 0,
            maximumRating: 0,
          },
          {
            id: '13684f1b-7911-ec11-b6e6-002248113963',
            text: 'Is the natural vegetation free from rubbish and undesirable vegetation?',
            questionType: 1,
            choices: ['Yes', 'No', 'N/A'],
            order: 650,
            isRequired: true,
            isCommentRequired: false,
            isPhotoRequired: false,
            minimumRating: 0,
            maximumRating: 0,
          },
        ],
        response: {
          submitDate: '2023-06-15',
          responses: [
            {
              id: 'ec0ed6d7-290c-ee11-8f6e-00224893b11d',
              questionId: '12684f1b-7911-ec11-b6e6-002248113963',
              response: ['Yes'],
              additionalComment: 'Additional comment that may or may not have useful info!',
              attachments: [
                {
                  id: 'd2caafb1-70da-47e2-ba48-efd66565cde1',
                  name: 'd2caafb1-70da-47e2-ba48-efd66565cde1_w996_r0.9975262832405689_fpx44.98_fpy48.86.jpg',
                  size: 91563,
                },
              ],
            },
          ],
        },
      }),
  },
  getAudits: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: 'f1c07d77-8fd3-ed11-a7c7-00224893bab8',
          name: 'L2 - Cleaning Jamie',
          auditStreamId: '186f56a6-cf11-ec11-b6e6-002248113240',
          auditStreamName: 'Cleaning',
          questions: [
            {
              id: 'fcb6ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: "are Jamie's facility, furnishings, fixtures, and fittings are free from excessive dust?",
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 850,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '00b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Are operating and safety instructions available for all Jamie?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 950,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '01b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Is a cleaning schedule available and records maintained?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 650,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '02b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Is the Jamies condition of use displayed prominently?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 550,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '03b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Are ceilings, high areas, and surfaces free from cobwebs and other marks?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 800,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '04b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Are all floors, walls, windows and mirrors clean?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 750,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '05b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Please make comment on any items of non conformance or areas for improvement',
              questionType: 4,
              choices: [],
              order: 1050,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '06b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Is Jamie in good working condition, maintained and fit for purpose?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1000,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '07b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Internal, external walls, doors, windows, frames and skirting are free from marks, and excessive dirt build-up?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 700,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '08b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Is there a record of Jamie attendance and inductions maintained and available?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 600,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '09b7ff79-8fd3-ed11-a7c7-00224893bdeb',
              text: 'Exterior of air conditioning unit is clean and free from mould and excessive dirt?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 900,
              isRequired: false,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'da5f47a1-8fd3-ed11-a7c7-00224893bdeb',
              text: 'How important is it to have a clean Jamie?',
              questionType: 5,
              choices: [],
              order: 575,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 5,
            },
            {
              id: 'd24de7c7-8fd3-ed11-a7c7-00224893bdeb',
              text: 'In a few words (no swearing) what do you think about Jamie? should we keep him? (short text answer)',
              questionType: 3,
              choices: [],
              order: 587.5,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'e3bf5eef-8fd3-ed11-a7c7-00224893bdeb',
              text: 'In a few words (no swearing) what do you think about Jamie? should we keep him? (LOOOOONG text answer)',
              questionType: 4,
              choices: [],
              order: 593.75,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'e4bf5eef-8fd3-ed11-a7c7-00224893bdeb',
              text: 'What should we do with Jamie?   (multiple answer_',
              questionType: 2,
              choices: [
                'Keep',
                'Remove',
                'Replace',
                'Tar and Feather',
                'Laugh at\\n',
                'All the above',
              ],
              order: 596.875,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
          ],
        },
        {
          id: 'bdf38a02-7911-ec11-b6e6-0022481131f6',
          name: 'L2-Accommodation Rooms/Central Facilities',
          auditStreamId: '75c3038d-cf11-ec11-b6e6-002248113240',
          auditStreamName: 'Building Maintenance',
          questions: [
            {
              id: 'f7239e08-7911-ec11-b6e6-002248113963',
              text: 'Walls and ceilings meet minimum condition (excessive damage or paint wear)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2150,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'f9239e08-7911-ec11-b6e6-002248113963',
              text: 'Exhaust fan operable?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2200,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'fa239e08-7911-ec11-b6e6-002248113963',
              text: 'Cupboards/Joinery is fixed and operable?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1950,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'fb239e08-7911-ec11-b6e6-002248113963',
              text: 'Smoke alarm indicator light is on (blinking or solid)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 900,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'fc239e08-7911-ec11-b6e6-002248113963',
              text: 'Tapware is secure, operate freely, with no leaks or water hammer?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2300,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'fd239e08-7911-ec11-b6e6-002248113963',
              text: 'Flyscreens intact?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3200,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'fe239e08-7911-ec11-b6e6-002248113963',
              text: 'Bedside light is operable?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1750,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: 'ff239e08-7911-ec11-b6e6-002248113963',
              text: 'Confirm there are no unauthorised modifications?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3600,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '00249e08-7911-ec11-b6e6-002248113963',
              text: 'Bathroom vanity meets minimum condition (no moisture or water leaks inside)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2700,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '01249e08-7911-ec11-b6e6-002248113963',
              text: 'Air conditioner temperature set to 23 degrees?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1400,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '04249e08-7911-ec11-b6e6-002248113963',
              text: 'Fridge operable?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 700,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '06249e08-7911-ec11-b6e6-002248113963',
              text: 'All lights are securely fixed and operable (no flickering)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1650,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '08249e08-7911-ec11-b6e6-002248113963',
              text: 'External walls / cladding intact and free from damage?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3400,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '09249e08-7911-ec11-b6e6-002248113963',
              text: 'All power points are securely fixed and switches do not jamb or require excessive force to operate?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1550,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '0a249e08-7911-ec11-b6e6-002248113963',
              text: 'Air conditioner operable and remote control working?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1000,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '0b249e08-7911-ec11-b6e6-002248113963',
              text: 'Room numbering intact?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3500,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '0c249e08-7911-ec11-b6e6-002248113963',
              text: 'Air conditioner is free from vibration and noise?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1200,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '0d249e08-7911-ec11-b6e6-002248113963',
              text: 'Hot Water Unit is operable (hot water dispensing, no leaks)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2600,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '0e249e08-7911-ec11-b6e6-002248113963',
              text: 'Water drains freely from basins, sinks, baths, showers, troughs?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2350,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '10249e08-7911-ec11-b6e6-002248113963',
              text: 'Toilet operational, no leaks and seat is not loose?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2550,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '11249e08-7911-ec11-b6e6-002248113963',
              text: 'Village Entertainment System working? (free to air and Foxtel)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1500,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '12249e08-7911-ec11-b6e6-002248113963',
              text: 'Fire system log books are current (where applicable)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 825,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '13249e08-7911-ec11-b6e6-002248113963',
              text: 'Desk and chair functional?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 750,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '14249e08-7911-ec11-b6e6-002248113963',
              text: 'Bed, mattress, legs are fit for purpose and operational?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 800,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '15249e08-7911-ec11-b6e6-002248113963',
              text: 'Cyclone screens attached where provided?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3300,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '16249e08-7911-ec11-b6e6-002248113963',
              text: 'Room is free from mould or moisture?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3650,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '17249e08-7911-ec11-b6e6-002248113963',
              text: 'Bathroom fittings are securely fixed (towel rail, toilet paper holder, soap dispensers)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2800,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '18249e08-7911-ec11-b6e6-002248113963',
              text: 'Door stops intact where required?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3100,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '1a249e08-7911-ec11-b6e6-002248113963',
              text: 'Air conditioner free from dirt, ingress and/or mould (filter and vents)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1100,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '1b249e08-7911-ec11-b6e6-002248113963',
              text: 'Electrical equipment leads test and tag is current?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 550,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '1d249e08-7911-ec11-b6e6-002248113963',
              text: 'TV operable and remote control working?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 650,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '1f249e08-7911-ec11-b6e6-002248113963',
              text: 'Emergency or exit lighting operational?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 950,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '20249e08-7911-ec11-b6e6-002248113963',
              text: 'Windows, doors and locks operate freely?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3000,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '21249e08-7911-ec11-b6e6-002248113963',
              text: 'Fire extinguisher within required test date (6 monthly)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 850,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '22249e08-7911-ec11-b6e6-002248113963',
              text: 'Confirm there is no evidence of pests within or within immediate surroundings?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 3750,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '23249e08-7911-ec11-b6e6-002248113963',
              text: 'Pre fab Shower unit floor meets minimum condition, no excessive movement, cracking?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2500,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '29249e08-7911-ec11-b6e6-002248113963',
              text: 'Bathroom silicone seals are intact and free from mould?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2900,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '2c249e08-7911-ec11-b6e6-002248113963',
              text: 'Shower outlets operate without restriction from calcium or other?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2450,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '2e249e08-7911-ec11-b6e6-002248113963',
              text: 'Floorcoverings meets minimum condition (no excessive wear and tear)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 2050,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '30249e08-7911-ec11-b6e6-002248113963',
              text: 'Window treatments operate freely (Blinds, shutters and curtains)?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1850,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
            {
              id: '32249e08-7911-ec11-b6e6-002248113963',
              text: 'Air conditioner isolator is functional and lockable?',
              questionType: 1,
              choices: ['Yes', 'No', 'N/A'],
              order: 1300,
              isRequired: true,
              isCommentRequired: false,
              isPhotoRequired: false,
              maximumRating: 0,
            },
          ],
        },
      ]),
  },
  getLocations: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: 'ccd68a51-4a9f-e611-80f4-c4346bc52710',
          name: 'Anchorage',
        },
        {
          id: '47754440-a7d2-e911-a812-000d3a6aa0b3',
          name: 'Angelo River',
        },
        {
          id: 'fd87220c-8829-e611-80ec-a45d36fc5a4c',
          name: 'Boolgeeda Aerodrome',
        },
      ]),
  },
  getStreams: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: '6ca91178-cf11-ec11-b6e6-002248113240',
          name: 'Accommodation',
        },
        {
          id: '43507d85-cf11-ec11-b6e6-002248113240',
          name: 'Aerodrome',
        },
        {
          id: '75c3038d-cf11-ec11-b6e6-002248113240',
          name: 'Building Maintenance',
        },
        {
          id: 'e283eccf-7a11-ec11-b6e6-0022481131f6',
          name: 'Catering',
        },
        {
          id: '186f56a6-cf11-ec11-b6e6-002248113240',
          name: 'Cleaning',
        },
        {
          id: '0a33ffd3-cf11-ec11-b6e6-002248113240',
          name: 'Grounds Maintenance',
        },
        {
          id: '7c85a406-d011-ec11-b6e6-002248113240',
          name: 'Property Management',
        },
        {
          id: '8ab2ba18-d011-ec11-b6e6-002248113240',
          name: 'Town Services',
        },
        {
          id: 'c2afe21e-d011-ec11-b6e6-002248113240',
          name: 'Transportation',
        },
      ]),
  },
  getAssets: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve([
        {
          id: '8ae5b344-6355-e611-80f4-a45d36fc5a4c',
          name: ' Rivergum Drive - Store room 2',
          masterAssetName: null,
          locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
          assetLevel: 'Level 1',
        },
        {
          id: '8ce5b344-6355-e611-80f4-a45d36fc5a4c',
          name: ' Rivergum Drive - Store room external ',
          masterAssetName: null,
          locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
          assetLevel: 'Level 1',
        },
        {
          id: '1a6135ce-6355-e611-80f4-a45d36fc5a4c',
          name: ' Supermarket Shop Complex PAN  Cool Room 3',
          masterAssetName: 'Supermarket Shop complex PAN',
          locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
          assetLevel: 'Level 2',
        },
        {
          id: '006135ce-6355-e611-80f4-a45d36fc5a4c',
          name: ' Supermarket shop Complex PAN  Cool Rooms 2',
          masterAssetName: 'Supermarket Shop complex PAN',
          locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
          assetLevel: 'Level 2',
        },
      ]),
  },
  createAuditSurveyResponse: {
    ok: true,
    status: 200,
    json: () => Promise.resolve(),
  },
  createAudit: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        id: '15ffeee0-8bcd-4ef3-9c63-668e08ffef3d',
      }),
  },
  createAndRespondAudit: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        id: '15ffeee0-8bcd-4ef3-9c63-668e08ffef3d',
      }),
  },
  updateAuditInviteState: {
    ok: true,
    status: 204,
    json: () => Promise.resolve(),
  },
};

export default mockResponse;
