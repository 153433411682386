const labels = {
  ContentDetails: {
    'Ref: Page title': 'Szczegóły treści',
    'See the event': 'Zobacz wydarzenie',
    'Take the survey': 'Weź udział w ankiecie',
    Date: 'Data',
    Category: 'Kategoria',
    'Ref: Content site different title': 'Ta treść nie należy do Twojej obecnej lokalizacji',
    'Ref: Content site different description':
      'Przeglądasz treść, która nie należy do Twojej obecnej lokalizacji. Nie będziesz mógł go zobaczyć na liście treści.',
  },
  ContentList: {
    'Ref: Page title': 'Aktualności',
    'featured (as in featured content)': 'Polecane',
    'Ref: category - all': 'Wszystkie',
    'Ref: category - promotional': 'Promocyjne',
    'Ref: category - featured': 'Wyróżnione',
    'Ref: category - other': 'Inne',
  },
  Widget: {
    'Ref: Carousel title': 'Wiadomości i artykuły',
    'Ref: Carousel title promo': 'Najlepsze wiadomości',
    'No info yet, come back later': 'Nie ma jeszcze informacji, wróć później 😉',
    'Ref: Content': 'Treść',
    'Ref: Widget Title': 'Aktualności',
    'Ref: MyVillage Carousel title promo': 'Promocje',
  },
};
export default labels;
