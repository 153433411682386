const labels = {
  Success: {
    'Ref: Body':
      'Kiitos tämän kyselyn täyttämisestä, palautteesi on arvokas, kyselyt auttavat parantamaan palveluitamme.',
    'Ref: Tabtitle': 'Kyselyn valmis',
    'Take another survey': 'Tee uusi kysely',
  },
  Survey: {
    'Ref: Page title': 'Tutkimustiedot',
    'Please answer all mandatory questions as identify by an asterisk':
      'Pakolliset kysymykset tähdellä: *',
    'Ref: Take another survey': 'Tee uusi kysely',
    'Ref: Go to Home': 'Palaa alkuun',
  },
  SurveysList: {
    'Ref: Page title': 'Kyselyt',
    'Ref: Available until': 'Saatavana asti',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Tarkastelet tällä hetkellä sivuston {site name} tutkimuksia.',
  },
};
export default labels;
