const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Auditorias',
    'Ref: status0': 'Abrir',
    'Ref: status1': 'Concluído',
    'Ref: status2': 'Cancelado',
    'Ref: connected': 'Conectado',
    'Ref: not connected': 'Não conectado',
    'Ref: synchronise data': 'Sincronize dados',
    'Ref: sync success': 'Dados sincronizados com sucesso',
    'Ref: Create new': 'Crie um novo',
    'Ref: Audit unsynchronised': 'Não sincronizado',
    'Ref: Audit synchronised': 'Sincronizado',
    'Ref: Unsynced error title': 'ocorreu um erro',
    'Ref: Unsynced error description':
      'Parece ser a primeira vez que acessará o módulo de auditorias. Conecte -se à rede e "sincronizar" para começar a auditoria.',
    'Ref: Search all audits': 'Todas as auditorias',
    'Ref: Search active audits': 'Abrir',
    'Ref: Search completed audits': 'Concluído',
    'Ref: sync error': 'A sincronização falhou',
    'Ref: sync error details': 'Novamente novamente para se reconectar',
    'Ref: no search audit results':
      'Infelizmente, não há auditorias correspondentes à sua pesquisa',
    'Ref: no audit results': 'Você não tem auditorias disponíveis, crie novo para começar',
    'Ref: Search canceled audits': 'Cancelado',
    'Ref: Audit cancelled successfully': 'Auditoria cancelada com sucesso',
    'Ref: Cancel audit invite': 'Cancelar',
    'Ref: Error audit cancel': 'A auditoria não foi cancelada',
    'Ref: Cancel audit confirmation': 'Tem certeza de que deseja cancelar a auditoria?',
    'Ref: Confirm': 'confirme',
    'Ref: Cancel audit': 'Cancelar auditoria',
    'Ref: Change the location': 'Altere a localização',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Detalhes da auditoria',
    'Ref: questions': 'questões',
    'Ref: question': 'pergunta',
    'Ref: Additional Comments': 'Descrição',
    'Ref: Not found title': 'Opa!',
    'Ref: Not found text': 'A auditoria que você está tentando acessar não existe.',
    'Ref: Back to Audit list': 'Voltar para a lista de auditoria',
    'Ref: Success title': 'Obrigado!',
    'Ref: Success text': 'Você completou a auditoria',
    'Ref: submit': 'Enviar',
    'Ref: add attachment': 'Adicione um anexo',
    'Ref: Unsupported elements':
      'Desculpe, parece que essa auditoria tem conteúdo que o aplicativo ainda não suporta. Vamos trabalhar nisso.',
    'Ref: form error': 'Nem todas as perguntas foram totalmente respondidas',
    'Ref: form complete': 'Concluído desde então',
    'Ref: attachment preview error': 'Você precisa estar online para ver este anexo',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Crie um novo',
    'Ref: Location': 'Localização',
    'Ref: Audit Stream Type': 'Fluxo',
    'Ref: Audit': 'Auditoria',
    'Ref: Asset': 'Ativo',
    'Ref: Asset Name': 'Nome do ativo',
    'Ref: Is joint audit?': 'É a auditoria conjunta?',
    'Ref: Who was present?': 'Quem estava presente?',
    'Ref: submit': 'Enviar',
    'Ref: Select the Location': 'Selecione o local',
    'Ref: Select the Audit Stream Type': 'Selecione o tipo de fluxo de auditoria',
    'Ref: Select the Audit': 'Selecione a auditoria',
    'Ref: Select the Asset': 'Selecione o ativo',
    'Ref: form error': 'Nem todos os campos necessários foram totalmente inseridos',
    'Ref: Audit error': 'Auditoria anexada',
    'Ref: Joint Audit Participants error': 'Participantes da auditoria conjunta',
    'Ref: Audit created': 'Auditoria criada',
    'Ref: Audit field length error': 'O comprimento não deve exceder 100 caracteres',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Altere a localização',
    'Ref: Location changed': 'Localização alterada',
    'Ref: Location': 'Localização',
    'Ref: Select the Location': 'Selecione o local',
    'Ref: Asset': 'Ativo',
    'Ref: Select the Asset': 'Selecione o ativo',
    'Ref: Asset Name': 'Nome do ativo',
    'Ref: submit': 'Enviar',
    'Ref: Audit field length error': 'O comprimento não deve exceder 100 caracteres',
  },
};
export default labels;
