import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import renderComponent from '../../../../../helpers/tests/renderComponent';

import DeletionFailure from './DeletionFailure';

const mockHistory = createMemoryHistory();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
}));

describe('DeletionFailure', () => {
  afterAll(() => cleanup());

  describe('on initial render', () => {
    let primaryButton: HTMLElement;

    renderComponent(DeletionFailure);

    it('should display', () => {
      primaryButton = screen.getByTestId('button-action-primary');

      expect(screen.getByText('sorry (standalone)')).toBeTruthy();
      expect(screen.getByText('Ref: Body')).toBeTruthy();
      expect(primaryButton).toBeTruthy();
      expect(screen.getByText('Go to account')).toBeTruthy();
    });
  });

  describe('after clicking primary button', () => {
    let primaryButton: HTMLElement;

    renderComponent(DeletionFailure);

    it('should redirect to account', () => {
      primaryButton = screen.getByTestId('button-action-primary');
      fireEvent.click(primaryButton);
      expect(mockHistory.location.pathname).toBe('/account');
    });
  });
});
