const labels = {
  ContentDetails: {
    'Ref: Page title': 'コンテンツの詳細',
    'See the event': 'イベントを見る',
    'Take the survey': 'アンケートを行う',
    Date: '日付',
    Category: 'カテゴリー',
    'Ref: Content site different title': 'このコンテンツは現在のサイトにはありません',
    'Ref: Content site different description':
      '現在のサイトにはないコンテンツを表示しています。コンテンツのリストに表示されることはありません。',
  },
  ContentList: {
    'Ref: Page title': 'ニュースフィード',
    'featured (as in featured content)': '特徴',
    'Ref: category - all': '全て',
    'Ref: category - promotional': 'プロモーション',
    'Ref: category - featured': '特徴',
    'Ref: category - other': 'その他',
  },
  Widget: {
    'Ref: Carousel title': 'ニュース記事',
    'Ref: Carousel title promo': 'トップニュース',
    'No info yet, come back later': 'まだ情報がありません。時間を空けてアクセスしてください。',
    'Ref: Content': 'コンテンツ',
    'Ref: Widget Title': 'ニュースフィード',
    'Ref: MyVillage Carousel title promo': 'プロモーション',
  },
};
export default labels;
