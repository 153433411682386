import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { RegisterPushNotificationsArgs } from '../api/notifications/pushNotificationApi.Types';

export type PushNotificationDeviceRegistration = {
  isSuccess: boolean;
  error?: any;
} & RegisterPushNotificationsArgs;

export function savePushNotificationRegistrationData(data: PushNotificationDeviceRegistration) {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.PUSH_NOTIFICATION_REGISTRATION_DATA,
    JSON.stringify({ data, at: new Date().toISOString() })
  );
}

export function deletePushNotificationRegistrationData() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.PUSH_NOTIFICATION_REGISTRATION_DATA);
}

export function getPushNotificationRegistrationData() {
  const data = localStorage.getItem(LOCAL_STORAGE_KEYS.PUSH_NOTIFICATION_REGISTRATION_DATA);
  if (!!data) return JSON.parse(data);
}
