const labels = {
  All: {
    Feedback: '反饋',
    'Ref: Switch site component intro sentence': '您的反饋是針對{site name}。',
    Category: '類別',
    Subject: '標題',
    Comment: '備註',
    'Date of your experience': '您所反饋問題的日期 *',
    'Ref: Hint': '您有需要解決的問題或工作嗎？',
    'Ref: Log Request': '創建服務請求',
    'Ref: Describe issue': '請告訴我們您的經歷',
    Date: '日期',
    'Ref: Share your opinion header': '請分享您的看法',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': '餐廳 /咖啡館',
    'Desk / workstation area': '辦公桌 /工作站區域',
    'Meeting room facilities': '會議室設施',
    'Coffee/tea facilities (pantry)': '咖啡/茶設施（儲藏室）',
    'Restrooms / washrooms': '洗手間 /洗手間',
    'Reception / Security': '接收 /安全',
    'Conference center': '會議中心',
    'Fitness center': '健身中心',
    Mailroom: '郵件室',
    'Bicycle room': '自行車室',
    'Shuttle services': '穿梭服務',
    Lockers: '儲物櫃',
    Helpdesk: '服務台',
  },
  Create: {
    'Tell us!': '期待您的反饋！',
    'Ref: All Ears': '我们洗耳恭听！',
    'Ref: Provide Description': '請描述您所碰到的問題',
    'Ref: Fill-in-the-blank sentence': '我想針對 {1} 進行 {0}',
    'Ref: Link text to service request module': '請求或問題？ 都可以通過這裡提交！',
    'Complaint type': '投訴{emoji}',
    'Feedback type': '反饋{emoji}',
    'Compliment type': '表揚{emoji}',
  },
  CreateFailure: {
    'Ref: Body': '發生錯誤，您的反饋未提交成功。如果此錯誤仍然存​​在，請聯繫您的服務台。',
  },
  CreateSuccess: {
    'Ref: Body': '您的反饋已提交。我們的現場支持團隊將認真對待。我們會尽快在適當的時候回复您。',
    'New feedback': '新反饋',
  },
  CreateFlow: {
    Description: '描述',
    'Ref: Provide Description': '請提供日期和說明',
    'Ref: I would like to provide a': '我想提供一個',
    'Ref: in regards to': '關於',
    'Ref: &': '和',
    'Ref: at': '在',
    'Ref: on the': '在',
  },
};
export default labels;
