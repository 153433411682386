const labels = {
  TimeTable: {
    'leave at': 'Оставить на',
    'arrive at': 'Прибыть в',
    at: 'в',
    'Ref: list placeholder': 'Заполните форму, чтобы увидеть часы',
    'Ref: Page title': 'Автобусы',
    'Ref: Departure': 'Отправление',
    'Ref: Arrivals': 'Прибытие',
    'Ref: minutes': 'минуты',
    'Ref: no items placeholder': 'Извините, в настоящее время нет автобусов.',
  },
};
export default labels;
