import { useCallback, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { AuditBIllustration } from '../../../assets/illustrations';
import Button from '../../../components/atoms/Button';
import Title, { TITLE_TAG, TITLE_SIZE } from '../../../components/atoms/Title';
import ActionsBar from '../../../components/organisms/ActionsBarV2';
import Column from '../../../components/organisms/Column';
import Container from '../../../components/organisms/Container';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import PageWithIframe from '../../../components/templates/PageWithIframe/PageWithIframe';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import { FormValidationAlert } from '../../../components/validateForm';
import { pagePaths as corePagePaths } from '../../Core/config';
import { useGetSurveyQuery, usePostSurveyDataMutation } from '../api/api';
import { pagePaths } from '../config';
import { QUESTION_TYPES, shortAnswerMaxLengh, longAnswerMaxLengh } from '../config';
import { useSurveyTranslation } from '../hooks/useSurveyTranslation';
import {
  Question,
  QuestionParam,
  Responses,
  Survey as SurveyDetail,
  ResponseSingleValue,
  ResponseMultipleValue,
} from '../types/survey.types';

import SurveyQuestion from './SurveyQuestion';

import useSite from '@/modules/Core/hooks/useSite';

// myVillage supported only
const Survey = () => {
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const { label } = useSurveyTranslation(__filename);
  const history = useHistory();
  const params = useParams<Pick<SurveyDetail, 'id'>>();

  const contentRef = useRef();

  const [responses, setResponses] = useState<Responses>({});
  const [showFormValidationAlert, setShowFormValidationAlert] = useState(false);

  const location = useLocation<{ surveyUrl: string }>();

  const { data: survey, isLoading } = useGetSurveyQuery({
    id: params.id,
    siteId: site.id,
  });

  const [postSurvey, { isLoading: isPosting }] = usePostSurveyDataMutation();

  const onSetResponse = useCallback(
    (questionId: string, value: ResponseSingleValue | ResponseMultipleValue[]) => {
      setResponses({
        ...responses,
        [questionId]: value as ResponseSingleValue,
      });
    },
    [responses]
  );

  const onSetResponseMultiple = useCallback(
    (questionId: string, selectedItems: ResponseSingleValue | ResponseMultipleValue[]) => {
      const responsesObj = (selectedItems as unknown as Question[]).reduce(
        (acc: { [id: string]: boolean }, selectedItem: Question) => {
          return {
            ...acc,
            [selectedItem.id]: true,
          };
        },
        {}
      );

      setResponses({
        ...responses,
        [questionId]: { ...responsesObj },
      });
    },
    [responses]
  );

  const surveyUrl = survey?.surveyUrl || location.state?.surveyUrl;

  if (surveyUrl) {
    return <PageWithIframe title={label('Ref: Page title')} iFrameUrl={surveyUrl || ''} />;
  }

  if (!survey?.questions || isPosting) {
    return <LoadingPage />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  const hasAnyResponse = Object.entries(responses).length > 0;

  const isFormValid = survey.questions?.reduce((isValid: boolean, question: Question) => {
    if (!isValid) {
      return false;
    }

    //checks that apply even on non-mandatory questions
    const { questionType } = question;
    if ([QUESTION_TYPES.SHORT_ANSWER, QUESTION_TYPES.LONG_ANSWER].includes(questionType)) {
      const answerMaxLengh =
        questionType === QUESTION_TYPES.SHORT_ANSWER ? shortAnswerMaxLengh : longAnswerMaxLengh;
      const answer = (responses[question.id] ?? '') as string;
      if (answer.length > answerMaxLengh) {
        return false;
      }
    }

    if (!question.required) {
      return true;
    }

    //specific case for mandatory multiple choices
    if (
      question.questionType === QUESTION_TYPES.MULTIPLE_CHOICES &&
      !Object.keys(responses[question.id] || {}).length
    ) {
      return false;
    }

    // in any case: we need the response to be provided for mandatory questions
    return !(responses[question.id] === undefined || responses[question.id] === '');
  }, true);

  const questions = survey.questions?.map((question: Question) => (
    <SurveyQuestion
      key={question.id}
      id={question.id}
      questionText={question.questionText}
      required={question.required}
      helpText={question.helpText}
      type={question.questionType}
      answers={question.answers}
      response={responses[question.id]}
      setResponse={
        question.questionType === QUESTION_TYPES.MULTIPLE_CHOICES
          ? onSetResponseMultiple
          : onSetResponse
      } //only pass the setResponseMultiple function for multiple options select
    />
  ));
  const handleSubmit = async () => {
    if (isFormValid) {
      //post survey response
      const surveyParam = {
        id: survey.id,
        name: survey.name,
      };

      const questionResponsesParam: QuestionParam[] = [];

      for (const [questionId, response] of Object.entries(responses)) {
        if (!response) {
          continue;
        }

        const question = survey.questions?.find((el) => el.id === questionId);

        if (!question) {
          return;
        }

        //all the below is quite specific to the back solution and shouldn't go into the component in theory but we are going to switch solution and won't be using this front anyway
        const questionResponse: QuestionParam = {
          question: {
            id: questionId,
            name: question.questionText,
            type: question.questionType,
          },
        };

        if (question.questionType === QUESTION_TYPES.SINGLE_CHOICE) {
          //single selectable option
          const answerName = question.answers.find((el) => el.id === response)?.name;

          if (answerName) {
            questionResponse.answer = {
              id: response as string,
              name: answerName,
            };
            questionResponse.response = answerName;
          }
        } else if (question.questionType === QUESTION_TYPES.MULTIPLE_CHOICES) {
          questionResponse.response = Object.keys(response)
            .map((aId) => question.answers.find((el) => el.id === aId)?.name)
            .join(':'); //current practice in the D365 entity
        } else {
          questionResponse.response = response;
        }

        questionResponsesParam.push(questionResponse);
      }

      const response = await postSurvey({
        survey: surveyParam,
        questionResponses: questionResponsesParam,
      });

      if ('data' in response) {
        history.push(pagePaths.Success);
      } else {
        history.push(corePagePaths.GenericFailurePage);
      }
    } else {
      setShowFormValidationAlert(true);
    }
  };

  return (
    <SimpleFormPage
      title={label('Ref: Page title')}
      contentRef={contentRef}
      hasBackLink={true}
      actions={[]}
      actionsBarTopContent={null}
      tabTitle={survey.name}
    >
      <Container>
        <Column.Main>
          <Title tag={TITLE_TAG.H1} size={TITLE_SIZE.HEADLINES}>
            {survey.name}
          </Title>
          {questions}
          <FormValidationAlert
            missingInfo={['any']}
            msgCodes={{
              any: label('Please answer all mandatory questions as identify by an asterisk'),
            }}
            show={showFormValidationAlert}
            onDidDismiss={() => setShowFormValidationAlert(false)}
            label={label}
            data-cy="form-alert"
          />
        </Column.Main>
        <Column.Complementary>
          <AuditBIllustration />
        </Column.Complementary>
        <ActionsBar>
          <Button
            data-testid="survey-submit"
            disabled={!isFormValid || !hasAnyResponse}
            onClick={handleSubmit}
          >
            {label('submit', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default Survey;
