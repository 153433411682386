import classNames from 'classnames';
import React from 'react';

import SearchBar from '../../atoms/SearchBar/SearchBar';

import { SearchBarListPageProps } from './ListPage.types';

import styles from './ListPage.module.css';

export const SearchBarListPage: React.FC<SearchBarListPageProps> = ({
  search,
  hasAtLeastOneFilterVisible,
  searchString,
  handleSearchChange,
  withCardWrapper,
  filterButton,
  children,
  'data-testid': testId = 'list-page-search-bar',
}) => (
  <div
    id="list_filters_and_search_container"
    className={classNames(styles.searchAndFilter, {
      [styles.onlyFilters]: !search,
      [styles.hasVisibleFilter]: hasAtLeastOneFilterVisible,
    })}
    data-cy="list-filters-and-search-container"
    data-testid={`${testId}-filters-and-search-container`}
  >
    {children}
    {!search ? filterButton : null}
    {search && (
      <div
        className={classNames(styles.searchFilterWrapper)}
        data-testid={`${testId}-search-filter-wrapper`}
      >
        <SearchBar
          {...search}
          withCardWrapper={withCardWrapper}
          right={[filterButton, search.right].filter(Boolean)}
          searchString={search?.searchString || searchString}
          handleChange={handleSearchChange}
          data-testid={`${testId}-search-filter-bar`}
        />
      </div>
    )}
  </div>
);
