import { cleanup, screen, act, fireEvent } from '@testing-library/react';
import momentjs from 'moment';

import { APP_NAME } from '../../../../constants';
import renderComponent, { renderedComponent } from '../../../../helpers/tests/renderComponent';

import NotificationsList from './NotificationsList';

import { GetNotificationsResponse } from '@/modules/Core/api/notifications/notificationsApi.types';

const NOTIFICATIONS_ITEMS = [
  {
    id: 'some-id-1',
    title: 'Some title here',
    message: 'Some message here',
    serviceId: 'some-id-2',
    notificationDate: momentjs().toISOString(),
    linkedEntity: 'Event',
    eventId: 'some-id-3',
    contentId: 'some-id-4',
    acknowledgedOn: '',
    acknowledged: false,
  },
  {
    id: 'some-id-5',
    title: 'Some title 2 here',
    message: 'Some message 2 here',
    serviceId: 'some-id-6',
    notificationDate: momentjs().toISOString(),
    linkedEntity: 'Event',
    eventId: 'some-id-7',
    contentId: 'some-id-8',
    acknowledgedOn: '',
    acknowledged: false,
  },
];

const mock: { data: GetNotificationsResponse; isLoading: boolean } = {
  data: {
    items: NOTIFICATIONS_ITEMS,
    total: 2,
    pageIndex: 0,
    pageSize: 0,
    unreadNotificationCount: 0,
  },
  isLoading: false,
};

const mockGetNotifications = jest.fn();

const getNotifications = async () => {
  mockGetNotifications();
  return mock;
};

jest.mock('../../api/notifications/notificationsApi', () => ({
  useLazyGetNotificationsQuery: () => [getNotifications],
  useCloseNotificationMutation: () => {
    return [];
  },
}));

describe('NotificationsList', () => {
  afterAll(() => cleanup());

  const props = {};

  it('renders without crashing', async () => {
    await act(async () => {
      renderedComponent(NotificationsList, {
        ...props,
      });
    });
    const element = screen.getByTestId('notification-section-title');
    expect(element).toBeInTheDocument();
  });

  it('getNotifications is called on component load', async () => {
    await act(async () => {
      renderedComponent(NotificationsList, {
        ...props,
      });
    });
    expect(mockGetNotifications).toHaveBeenCalled();
  });

  it('show notifications list items', async () => {
    await act(async () => {
      renderedComponent(NotificationsList, {
        ...props,
      });
    });

    expect(screen.getByText(NOTIFICATIONS_ITEMS[0].title)).toBeVisible();
    expect(screen.getByText(NOTIFICATIONS_ITEMS[1].title)).toBeVisible();

    expect(screen.getAllByTestId('tile')).toHaveLength(NOTIFICATIONS_ITEMS.length);
  });

  describe('show notifications list items in different groups (this week/earlier)', () => {
    beforeAll(() => {
      mock.data.items = NOTIFICATIONS_ITEMS.map((item, index) =>
        index === 0
          ? item
          : { ...item, notificationDate: momentjs().subtract(2, 'weeks').toISOString() }
      );
    });

    it('show notifications', async () => {
      await act(async () => {
        renderedComponent(NotificationsList, {
          ...props,
        });
      });

      expect(screen.getByText('Ref: This week')).toBeVisible();
      expect(screen.getByText('Ref: Earlier')).toBeVisible();
    });
  });

  describe('Click at "See more button" to load more items', () => {
    describe('When is MyWay', () => {
      beforeAll(() => {
        const env = global.process.env;
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYWAY };
        mock.data.total = NOTIFICATIONS_ITEMS.length + 1;
      });

      renderComponent(NotificationsList, {
        ...props,
      });

      it('get notifications was called on component mounting', () => {
        expect(mockGetNotifications).toHaveBeenCalledTimes(2);
      });

      it('get notifications was called  on Click at the "load more" button', async () => {
        await act(async () => {
          fireEvent.click(screen.getByText('Ref: See more'));
        });

        expect(mockGetNotifications).toHaveBeenCalledTimes(3);
      });
    });
  });

  describe('"See more button" is disabled when all notifications are displayed', () => {
    describe('When is MyWay', () => {
      beforeAll(() => {
        const env = global.process.env;
        global.process.env = { ...env, REACT_APP_APP_NAME: APP_NAME.MYWAY };
        mock.data.total = NOTIFICATIONS_ITEMS.length;
      });

      const notifications = {
        items: NOTIFICATIONS_ITEMS,
        pageIndex: 0,
        total: NOTIFICATIONS_ITEMS.length,
      };

      const mockGetNotifications = jest.fn();

      it('See more button is disabled', async () => {
        await act(async () => {
          renderedComponent(NotificationsList, {
            ...props,
            getNotifications: mockGetNotifications,
            notifications,
          });
        });

        expect(screen.getByTestId('see-more-notifications-button')).toHaveAttribute(
          'aria-disabled',
          'true'
        );
      });
    });
  });
});
