const mockResponse = {
  getServiceRequestLightHelpdeskList: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        data: [
          {
            id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37e',
            serviceRequestNumber: '0612156',
            createDateTime: '2020-04-20T09:34:46Z',
            title: 'Broken glass on the 1. floor',
            category: 'Cleaning',
            affectedPersonFullName: 'Michel Hofman',
            roomText: 'Kitchen',
            buildingText: 'Brolga',
          },
          {
            id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37f',
            serviceRequestNumber: '0612156',
            createDateTime: '2020-04-20T09:34:46Z',
            title: 'Soap in ladies bathroom',
            category: 'Soft services',
            affectedPersonFullName: 'Anna Stone',
            roomText: 'Bathroom',
            buildingText: 'Brolga',
          },
          {
            id: 'b1fd459c-39e1-4f6d-bdd9-ceff0252e37g',
            serviceRequestNumber: '0612156',
            createDateTime: '2020-04-20T09:34:46Z',
            title: 'Broken dishwasher',
            category: 'Hard services',
            affectedPersonFullName: 'Joe Hardy',
            roomText: 'Kitchen',
            buildingText: 'Brolga',
          },
        ],
        metadata: {
          pageSize: 3,
          pageNumber: 1,
          hasNextPage: false,
        },
      }),
  },
};

export default mockResponse;
