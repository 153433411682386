const labels = {
  All: {
    'Ref: AccommodationRequestTitle': '住宿请求',
    'Ref: Describe your request': '描述您的要求',
    'Ref: Confirm your request': '确认您的要求',
    'Ref: Your request': '你的申请',
    'Ref: Summary': '概括',
    'Ref: Next': '下一个',
    'Ref: Submit': '提交',
    'Ref: Me': '我',
    'Ref: Arrival date': '到达日期',
    'Ref: Arrival time': '到达时间',
    'Ref: Departure date': '出发日期',
    'Ref: Departure time': '出发时间',
    'Ref: request raised for': '受此要求影响的人',
    'Ref: phoneNumber': '电话号码',
    'Ref: arrivalDate': '到达',
    'Ref: departureDate': '离开',
    'Ref: description': '评论',
    'Ref: open': '打开',
    'Ref: pending': '待办的',
    'Ref: refused': '拒绝',
    'Ref: closed': '关闭',
    'Ref: all': '全部',
    'Ref: Arrival': '到达',
    'Ref: Validate': '证实',
    'Ref: site': '地点坐标',
    'Ref: Update us': '更新我们',
    'Ref: email of guest': '客人的电子邮件',
    'Ref: GuestAccommodationRequestTitle': '宾客要求',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': '新要求',
    'Ref: Status': '地位',
    'Ref: Date': '日期',
    'Ref: Coming arrivals': '即将到来',
    'Ref: Past arrivals': '过去的到来',
  },
  CreateForm: {
    'Ref: Guest phone number': '电话号码',
    'Ref: Your phone number': '你的电话号码',
    'Ref: The phone number': '电话号码',
    'Ref: PhoneNumber error msg': '电话号码无效',
    'Ref: Comment error msg': '最大字符数为250',
    'Ref: Guest first name': '来宾的名字',
    'Ref: Guest last name': '来宾姓',
    'Ref: request for': '对于谁是这个请求？',
    'Ref: request for me': '我',
    'Ref: request for guest': '其他人',
  },
  Details: {
    'Ref: AccommodationRequestDetails': '住宿请求详细信息',
    'Ref: Timeline': '时间线',
    'Ref: No updates placeholder': '此请求没有更新',
  },
  CreateThankYou: {
    'Ref: Thank you': '谢谢',
    'Ref: Success text': '我们已经收到您的要求，现在正在调查。我们将尽快与您联系。',
    'Ref: Go to home': '回家',
    'Ref: Create another request': '创建另一个请求',
  },
  CreateError: {
    'Ref: Create error title': '哎呀！',
    'Ref: Create error text': '发生了错误。请再试一次。',
    'Ref: Retry': '再试一次',
  },
  UpdateUs: {
    'Ref: Update us msg': '请向我们更新有关您的请求的任何新元素，以便我们最好地支持您。',
  },
};
export default labels;
