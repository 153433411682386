const labels = {
  TimeTable: {
    'leave at': 'ปล่อยให้',
    'arrive at': 'มาถึงที่',
    at: 'ที่',
    'Ref: list placeholder': 'กรอกแบบฟอร์มเพื่อดูชั่วโมง',
    'Ref: Page title': 'รถบัส',
    'Ref: Departure': 'การออกเดินทาง',
    'Ref: Arrivals': 'การมาถึง',
    'Ref: minutes': 'นาที',
    'Ref: no items placeholder': 'ขออภัยไม่มีรถโดยสารในขณะนี้',
  },
};
export default labels;
