const labels = {
  All: {
    dailyMenu: 'Dagelijks menu',
    anytime: 'Elk moment',
    breakfast: 'Ontbijt',
    morningBreak: 'voormiddagpauze',
    lunch: 'Lunch',
    afternoonBreak: 'namiddagpauze',
    dinner: 'Diner',
    supper: 'Avondmaal',
    nightMeal: 'Night maaltijd',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': "Menu's",
    'Ref: View details': 'Bekijk details',
    'Ref: Facility details': 'Details van de diensten',
  },
  OpeningHours: { everyday: 'elke dag', 'open all day': 'De hele dag geopend' },
  ProductsList: {
    'Ref: Page title': 'Menu',
    'Ref: Facility details': 'Details van de diensten',
    'Ref: Search products list': 'Zoekopdrachten',
    'Ref: filter label: date': 'Datum',
    'Ref: filter label: menus': 'Menu',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Categorie',
    'Ref: filter label: dishes': 'Gerechten',
    'Ref: filter label: highlight': 'Markeer of verberg filters met gerechten',
    All: 'Allemaal',
  },
  ProductDetails: { 'Ref: Page title': 'Productdetails' },
  Widget: {
    'Ref: Carousel title': "Menu's",
    'No info yet, come back later': 'Nog geen info, kom later terug 😉',
    'Ref: menus': "Menu's",
    'Ref: see all': 'Alles zien',
  },
};
export default labels;
