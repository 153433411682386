const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Aanvraag vor logement',
    'Ref: Describe your request': 'Beschrijf uw aanvraag',
    'Ref: Confirm your request': 'Bevestig uw aanvraag',
    'Ref: Your request': 'Uw aanvraag',
    'Ref: Summary': 'Samenvatting',
    'Ref: Next': 'Volgende',
    'Ref: Submit': 'Indienen',
    'Ref: Me': 'Mij',
    'Ref: Arrival date': 'Datum aankomst',
    'Ref: Arrival time': 'Uur aankomst',
    'Ref: Departure date': 'Datum vertrek',
    'Ref: Departure time': 'Uur vertrek',
    'Ref: request raised for': 'De persoon voor wie deze aanvraag is bestemd',
    'Ref: phoneNumber': 'Telefoonnummer',
    'Ref: arrivalDate': 'Aankomst',
    'Ref: departureDate': 'Vertrek',
    'Ref: description': 'Opmerking',
    'Ref: open': 'Open',
    'Ref: pending': 'In behandeling',
    'Ref: refused': 'Geweigerd',
    'Ref: closed': 'Gesloten',
    'Ref: all': 'Alle',
    'Ref: Arrival': 'Aankomst',
    'Ref: Validate': 'Bevestigen',
    'Ref: site': 'Site locatie',
    'Ref: Update us': 'Werk ons ​​bij',
    'Ref: email of guest': 'E-mail van gast',
    'Ref: GuestAccommodationRequestTitle': 'Gastverzoek',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'Nieuwe aanvraag',
    'Ref: Status': 'Toestand',
    'Ref: Date': 'Datum',
    'Ref: Coming arrivals': 'Komende aankomsten',
    'Ref: Past arrivals': 'Verleden aankomsten',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Telefoonnummer van gasten',
    'Ref: Your phone number': 'Uw telefoon nummer',
    'Ref: The phone number': 'Het telefoonnummer',
    'Ref: PhoneNumber error msg': 'Het telefoonnummer is niet geldig',
    'Ref: Comment error msg': 'Maximaal aantal tekens is 250',
    'Ref: Guest first name': 'Gast Voornaam',
    'Ref: Guest last name': 'Achternaam van de gast',
    'Ref: request for': 'Voor wie is dit verzoek?',
    'Ref: request for me': 'Mij',
    'Ref: request for guest': 'Iemand anders',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Details van de aanvraag voor logement',
    'Ref: Timeline': 'Tijdlijn',
    'Ref: No updates placeholder': 'Er zijn geen updates voor dit verzoek',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Bedankt',
    'Ref: Success text':
      'We hebben uw aanvraag ontvangen en verwerken die nu. We antwoorden u zo snel mogelijk.',
    'Ref: Go to home': 'Ga naar de startpagina',
    'Ref: Create another request': 'Maak een nieuwe aanvraag',
  },
  CreateError: {
    'Ref: Create error title': 'Oeps!',
    'Ref: Create error text': 'Er is een fout opgetreden. Probeer het alstublieft nog eens.',
    'Ref: Retry': 'Probeer het nog eens',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Werk ons ​​bij met elk nieuw element over uw verzoek, zodat we u het beste kunnen ondersteunen.',
  },
};
export default labels;
