import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useState } from 'react';

import DateTime from '../../../../components/molecules/DateTime';
import { PICKER_TYPE } from '../../../../components/molecules/DateTime/DateTime.types';
import { getDateWithoutTime, getTomorrowDate } from '../../helpers/helper';

import { DateTypes, ScheduleFieldsProps, dateTypeValues } from './DateTimeFields.types';

import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Title, { TITLE_SIZE } from '@/components/atoms/Title';

import styles from './DateTimeFields.module.css';

const ScheduleFields = ({
  value: { arrivalDate, departureDate },
  onChange,
  label,
}: ScheduleFieldsProps) => {
  const [closeModal, setCloseModal] = useState(false);
  const [minTime, setMinTime] = useState(new Date());

  const onChangeDate = useCallback(
    (value: any, dateType: DateTypes) => {
      const date = new Date(dateType === dateTypeValues.arrival ? arrivalDate : departureDate);
      date.setSeconds(0);

      if (value.date) {
        date.setDate(new Date(value.date).getDate());
        date.setMonth(new Date(value.date).getMonth());
        date.setFullYear(new Date(value.date).getFullYear());
      }

      if (value.time) {
        const [hours, minutes] = value.time.split(':');
        date.setHours(hours);
        date.setMinutes(minutes);
      }

      if (dateType === dateTypeValues.arrival) {
        onChange({ arrivalDate: date, departureDate });
        // if newly set date is today, then set the minumum time to actual time(hh:mm) and also set input values to minimum available hh:mm for today
        if (moment(date).isSame(new Date(), 'day')) {
          setMinTime(new Date());
          if (value.date) {
            if (moment(date).isBefore(moment(new Date()))) {
              //if previously set time was smaller then current time
              onChange({ arrivalDate: new Date(), departureDate });
            }
          }
          // if date is bigger then today then set time to 0 and this way allow user to select any time since 00:00 to 23:59
        } else {
          let tomorrowMinDate = getTomorrowDate(new Date());
          tomorrowMinDate.setHours(0, 0);
          setMinTime(tomorrowMinDate);
        }
        if (getDateWithoutTime(date) >= getDateWithoutTime(departureDate)) {
          const newDepartureDate = getTomorrowDate(date);
          newDepartureDate.setHours(12, 0);
          onChange({ arrivalDate: date, departureDate: newDepartureDate });
        }
      } else {
        onChange({ arrivalDate: arrivalDate, departureDate: date });
      }
    },
    [arrivalDate, departureDate, onChange]
  );

  return (
    <>
      <div className={classNames(styles.dateTimeInputs, 'mb-M')}>
        <div>
          <Title className="mb-S" size={TITLE_SIZE.BODYSBOLD}>
            {label('Ref: Arrival date')}
          </Title>
          <DateTime
            id={'arrivalDate'}
            formattedValue
            value={arrivalDate}
            className={styles.dateInput}
            minDate={new Date()}
            onChange={(date) => onChangeDate({ date }, dateTypeValues.arrival)}
            data-testid="date-select"
            modalTitle={label('Ref: Arrival date')}
          />
        </div>
        <div>
          <Title className="mb-S" size={TITLE_SIZE.BODYSBOLD}>
            {label('Ref: Arrival time')}
          </Title>
          <DateTime
            id={'arrivalTime'}
            value={arrivalDate}
            className={styles.dateInput}
            close={closeModal}
            onDismiss={() => setCloseModal(false)}
            minDate={minTime}
            type={PICKER_TYPE.TIME}
            onChange={(time) => onChangeDate({ time }, dateTypeValues.arrival)}
            data-testid="time-select"
            modalTitle={label('Ref: Arrival time')}
            footer={
              <Button
                onClick={() => setCloseModal(true)}
                inheritStyle={styles.actionButton}
                look={BUTTON_LOOK.PRIMARY}
                data-testid="arrival-time-modal-button"
              >
                {label('Ref: Validate')}
              </Button>
            }
          />
        </div>
      </div>
      <div className={styles.dateTimeInputs}>
        <div>
          <Title className="mb-S" size={TITLE_SIZE.BODYSBOLD}>
            {label('Ref: Departure date')}
          </Title>
          <DateTime
            id={'departureDate'}
            formattedValue
            value={departureDate}
            className={styles.dateInput}
            minDate={getTomorrowDate(arrivalDate)}
            onChange={(date) => onChangeDate({ date }, dateTypeValues.departure)}
            data-testid="date-select"
            modalTitle={label('Ref: Departure date')}
          />
        </div>
        <div>
          <Title className="mb-S" size={TITLE_SIZE.BODYSBOLD}>
            {label('Ref: Departure time')}
          </Title>
          <DateTime
            id={'departureTime'}
            value={departureDate}
            className={styles.dateInput}
            close={closeModal}
            onDismiss={() => setCloseModal(false)}
            type={PICKER_TYPE.TIME}
            onChange={(time) => onChangeDate({ time }, dateTypeValues.departure)}
            data-testid="time-select"
            modalTitle={label('Ref: Departure time')}
            footer={
              <Button
                inheritStyle={styles.actionButton}
                look={BUTTON_LOOK.PRIMARY}
                onClick={() => setCloseModal(true)}
                data-testid="departure-time-modal-button"
              >
                {label('Ref: Validate')}
              </Button>
            }
          />
        </div>
      </div>
    </>
  );
};

export default ScheduleFields;
