import classNames from 'classnames';

import { breakPointsInPixels } from '../../../config';
import ConditionalWrapper from '../../atoms/ConditionalWrapper/ConditionalWrapper';
import Link from '../../atoms/Link';
import Tooltip, { TOOLTIP_POSITION } from '../../atoms/Tooltip';

import { SidebarSiteProps } from './SideBarSite.types';
import SideBarSiteSkeleton from './SideBarSiteSkeleton';

import style from './SideBarSite.module.css';

const SideBarSite = ({
  collapsed,
  title,
  icon,
  sectionTitle,
  linkTo,
  isLoading,
  active,
  onClick,
  'data-testid': dataTestId = 'sidebar-site',
}: SidebarSiteProps) => {
  const displayTooltip = window.matchMedia(
    '(min-width: ' + breakPointsInPixels['M'] + 'px)'
  ).matches;

  return (
    <div
      className={style.SideBarSiteWrapper}
      data-testid={dataTestId}
      onClick={() => onClick && onClick()}
      onKeyDown={() => onClick && onClick()}
      role="button"
      tabIndex={0}
    >
      {isLoading ? (
        <SideBarSiteSkeleton collapsed={collapsed} />
      ) : (
        <Link
          data-testid={dataTestId}
          to={linkTo}
          srOnlyText={'Open ' + title}
          {...(active ? { 'aria-current': 'page' } : {})}
        >
          <div className={classNames(style.SideBarSite, { [style.active]: active })}>
            {collapsed ? (
              <ConditionalWrapper
                condition={displayTooltip}
                wrapperIfTrue={(children) => (
                  <Tooltip
                    content={title}
                    place={TOOLTIP_POSITION.Right}
                    customStyles={style.customTooltip}
                  >
                    {children}
                  </Tooltip>
                )}
              >
                <div className={style.iconWrapper}>
                  <div className={style.icon}>{icon}</div>
                </div>
              </ConditionalWrapper>
            ) : (
              <>
                <div className={style.iconWrapper}>
                  <div className={style.icon}>{icon}</div>
                </div>
                <div>
                  <div className={style.title}>
                    <span>{sectionTitle}</span>
                  </div>
                  <div className={style.siteName}>
                    <span>{title}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        </Link>
      )}
    </div>
  );
};

export default SideBarSite;
