import classNames from 'classnames';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import Modal from '../../organisms/Modal';
import Button, { BUTTON_LOOK } from '../Button';

import { FilterComponent } from './FilterComponent';
import { ModalFiltersProps } from './Filters.types';

import styles from './Filters.module.css';

const ModalFilters: React.FC<ModalFiltersProps> = ({
  filters,
  setModalState,
  isModalOpen,
  onCommitFilter,
  onResetModalFilters,
  onDismissModal,
  filtering,
  uncommitedFiltering,
  languageCode = 'en-US',
  handleChange,
  handleCalendarChange,
  'data-testid': testId,
}) => {
  const { label } = useCustomTranslation();
  const onSubmit = () => {
    onCommitFilter();
    setModalState(false);
  };

  const onDismiss = () => {
    onDismissModal();
    setModalState(false);
  };

  if (filters.length === 0) {
    return null;
  }

  return (
    <Modal
      isOpen={!!isModalOpen}
      id="filters_modal"
      onDismiss={onDismiss}
      title={label('Filters', { textTransform: 'capitalize' })}
      data-testid={`${testId}-modal`}
      contentClassName={styles.modalContent}
      footer={
        <div className={classNames(styles.actionsWrapper)}>
          <Button
            look={BUTTON_LOOK.PRIMARY}
            onClick={onSubmit}
            srOnlyText={label('Confirm Filters')}
            data-testid={`${testId}-confirm-filters`}
          >
            {label('confirm', { textTransform: 'capitalize' })}
          </Button>
          <Button
            look={BUTTON_LOOK.TERTIARY}
            onClick={onResetModalFilters}
            srOnlyText={label('Reset Filters')}
            data-testid={`${testId}-reset-filters`}
          >
            {label('reset', { textTransform: 'capitalize' })}
          </Button>
        </div>
      }
    >
      <Modal.ColumnLayout>
        {filters.map((filter) => (
          <FilterComponent
            key={filter.id}
            filter={filter}
            filtering={{ ...filtering, ...uncommitedFiltering }}
            label={label}
            languageCode={languageCode}
            handleChange={handleChange}
            handleCalendarChange={handleCalendarChange}
            data-testid={`${testId}-${filter.id}-filter`}
          />
        ))}
      </Modal.ColumnLayout>
    </Modal>
  );
};

export default ModalFilters;
