import { Service } from '../types/State.types';

import { SERVICE } from '@/modules/config';

export const hasNavigationEnabled = (availableServices: Service[], serviceType: SERVICE) => {
  const isNavigationEnabled = availableServices.some(
    (x) => !!x.navigation && x.navigation.isEnabled && x.name === serviceType
  );

  return isNavigationEnabled;
};
