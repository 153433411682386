import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import ContentDetails from './ContentDetails';
import ContentList from './ContentList';

const Routes = ({ children }: { children: React.ReactNode }) => {
  if (children) return <>{children}</>;

  return (
    <Switch>
      <Route path={pagePaths.ContentDetails} children={<ContentDetails />} />
      <Route path={pagePaths.Module} children={<ContentList />} />
    </Switch>
  );
};

export default Routes;
