import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { useController } from 'react-hook-form';

import { EditionImageIcon } from '../../../../assets/icons';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Attachments from '../../../../components/organisms/Attachments/Attachments';
import useConnection from '../../../../helpers/hooks/useConnection';
import { getImageSrcFromId } from '../../../../helpers/images/images.helper';
import { AttachmentData } from '../../../../types/common.types';
import { maxFileNum, maxFileSize, maxFileNameLength } from '../../config';

import { QuestionAttachmentsProps } from './QuestionAttachments.types';

import toast from '@/components/atoms/ModalToast';

import styles from './QuestionAttachments.module.css';

const QuestionAttachments: FC<QuestionAttachmentsProps> = ({
  name,
  attachments,
  rules,
  control,
  isComplete,
  isDisabled,
  label,
}) => {
  const isConnected = useConnection();

  const defaultValue = attachments
    ?.map(({ id, name, size, mimeType }) => ({
      id,
      file: { name, size, type: mimeType },
    }))
    .filter(({ file: { type } }) => type);

  const {
    field: { value = [], onChange },
  } = useController({ control, name, rules, defaultValue });

  const onAttachFile = (items: AttachmentData[]) => {
    onChange(items);
  };

  const handleAttachmentClick = useCallback(() => {
    toast.error(label('Ref: attachment preview error'), {
      toastId: 'attachment-preview-error-toast',
      position: 'top-center',
      closeOnClick: true,
      draggable: false,
      closeButton: false,
    });
  }, [label]);

  return (
    <Attachments
      attachments={value}
      maxFileSize={maxFileSize}
      maxFilesNum={maxFileNum}
      maxFileNameLength={maxFileNameLength}
      imagePlaceholder={<EditionImageIcon />}
      attach={onAttachFile}
      readOnly={isDisabled}
      closeButtonNotVisible={isDisabled}
      getImage={getImageSrcFromId}
      data-testid="question-attachments"
      {...(isComplete && {
        handleAttachmentClick: isConnected ? undefined : handleAttachmentClick,
      })}
      imageOptimizationOptions={{
        maxImageSizeInBytes: maxFileSize,
        maxWidthOrHeight: 2560,
        startingQuality: 90,
        minimumQuality: 50,
        qualitySteps: 10,
      }}
    >
      <Button
        data-testid="question-attachment-add"
        className={classNames(styles.attachmentsButton)}
        look={BUTTON_LOOK.SECONDARY}
      >
        {label('Ref: add attachment')}
      </Button>
    </Attachments>
  );
};

export default QuestionAttachments;
