import { useController } from 'react-hook-form';

import CheckboxGroup from './CheckboxGroup';
import { ControlledCheckboxGroupProps, CheckboxGroupWrapperProps } from './CheckboxGroup.types';

const CheckboxGroupWrapper = ({ control, rules = {}, ...props }: CheckboxGroupWrapperProps) =>
  control ? (
    <ControlledCheckboxGroup control={control} rules={rules} {...props} />
  ) : (
    <CheckboxGroup {...props} />
  );

const ControlledCheckboxGroup = ({ control, rules, ...props }: ControlledCheckboxGroupProps) => {
  const {
    field: { value, onChange },
  } = useController({ control, name: props.name, rules, defaultValue: props.value });

  return <CheckboxGroup {...props} value={value} onClick={(e, v) => onChange(v, e)} />;
};

export default CheckboxGroupWrapper;
