const labels = {
  All: {
    dailyMenu: '매일 메뉴',
    anytime: '언제든지',
    breakfast: '아침 밥',
    morningBreak: '아침 휴식',
    lunch: '점심',
    afternoonBreak: '오후의 휴식',
    dinner: '저녁',
    supper: '저녁',
    nightMeal: '밤 식사',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': '메뉴',
    'Ref: View details': '세부 정보보기',
    'Ref: Facility details': '시설 세부 사항',
  },
  OpeningHours: { everyday: '매일', 'open all day': '하루 종일 개점' },
  ProductsList: {
    'Ref: Page title': '메뉴',
    'Ref: Facility details': '시설 세부 사항',
    'Ref: Search products list': '검색 항목 검색',
    'Ref: filter label: date': '날짜',
    'Ref: filter label: menus': '메뉴',
    'Ref: filter label: moment': '순간',
    'Ref: filter label: category': '범주',
    'Ref: filter label: dishes': '그릇',
    'Ref: filter label: highlight': '접시로 필터를 강조 표시하거나 숨 깁니다',
    All: '모두',
  },
  ProductDetails: { 'Ref: Page title': '제품 세부 정보' },
  Widget: {
    'Ref: Carousel title': '메뉴',
    'No info yet, come back later': '아직 정보가없고 나중에 다시 오십시오 😉',
    'Ref: menus': '메뉴',
    'Ref: see all': '모두보기',
  },
};
export default labels;
