import classNames from 'classnames';

import { StepperItemProps, StepperProps } from './Stepper.types';
import StepperItem from './StepperItem';

import styles from './Stepper.module.css';

const Stepper = ({ steps, horizontalOnly }: StepperProps) => {
  const currentStep = steps.find((element) => element.current === true);
  const currentStepIndex = currentStep ? steps.indexOf(currentStep) : steps.length;

  return (
    <ul className={classNames(styles.wrapper, { [styles.horizontalWrapper]: horizontalOnly })}>
      {steps.map((step: StepperItemProps, i: number) => {
        const index = i + 1;
        return (
          <StepperItem
            key={index}
            {...step}
            index={index}
            completed={i < currentStepIndex}
            horizontalOnly={horizontalOnly}
          />
        );
      })}
    </ul>
  );
};

export default Stepper;
