const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Solicitud de servicio - Mesa de ayuda',
    'Ref: Forbidden heading': 'Prohibido',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Está administrando solicitudes de servicio en el sitio {siteName}.',
    'Ref: Dates': 'fechas',
    'Ref: SRL list refresh': 'Actualizar lista',
    'Ref: Export': 'exportar',
    'Ref: Empty/not filled': 'Vacío / no lleno',
    'Ref: Name': 'Nombre',
    'Ref: Date': 'Fecha',
    'Ref: Category': 'Categoría',
    'Ref: User': 'Usuario',
    'Ref: Location': 'Ubicación',
  },
};
export default labels;
