const labels = {
  TimeTable: {
    'leave at': 'Dejar en',
    'arrive at': 'Llegar a',
    at: 'a',
    'Ref: list placeholder': 'Complete el formulario para ver las horas',
    'Ref: Page title': 'Autobús',
    'Ref: Departure': 'Partida',
    'Ref: Arrivals': 'Llegadas',
    'Ref: minutes': 'minutos',
    'Ref: no items placeholder': 'Lo siento, no hay autobuses disponibles en este momento.',
  },
};
export default labels;
