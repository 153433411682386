const labels = {
  All: {
    Feedback: 'Feedback',
    'Ref: Switch site component intro sentence':
      'Ihr Feedback wird mit dem Standort {site name} verknüpft.',
    Category: 'Kategorie',
    Subject: 'Thema',
    Comment: 'Kommentar',
    'Date of your experience': 'Datum Ihrer Erfahrung *',
    'Ref: Hint': 'Haben Sie ein Problem oder eine Aufgabe, die gelöst werden muss?',
    'Ref: Log Request': 'Melden Sie Ihre Anfrage',
    'Ref: Describe issue': 'Bitte erzählen Sie uns von Ihren Erfahrungen',
    Date: 'Datum',
    'Ref: Share your opinion header':
      'Teilen Sie Ihre Meinung über Ihre Erfahrungen bei der Arbeit mit',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurant / Café',
    'Desk / workstation area': 'Schreibtisch- / Arbeitsstationbereich',
    'Meeting room facilities': 'Einrichtungen für Besprechungsräume',
    'Coffee/tea facilities (pantry)': 'Kaffee/Tee -Einrichtungen (Speisekammer)',
    'Restrooms / washrooms': 'Toiletten / Waschräume',
    'Reception / Security': 'Empfang / Sicherheit',
    'Conference center': 'Konferenzzentrum',
    'Fitness center': 'Fitness Center',
    Mailroom: 'Mailroom',
    'Bicycle room': 'Fahrradraum',
    'Shuttle services': 'Shuttle -Dienstleistungen',
    Lockers: 'Schließfächer',
    Helpdesk: 'Beratungsstelle',
  },
  Create: {
    'Tell us!': 'Erzählen Sie uns davon!',
    'Ref: All Ears': 'Wir hören zu!',
    'Ref: Provide Description': 'Bitte fügen Sie eine Beschreibung hinzu',
    'Ref: Fill-in-the-blank sentence': 'Ich möchte ein {0} in Bezug auf {1} bereitstellen',
    'Ref: Link text to service request module': 'Eine Anfrage oder ein Problem? Melde es hier an!',
    'Complaint type': 'Beschwerde {emoji}',
    'Feedback type': 'Feedback {emoji}',
    'Compliment type': 'Kompliment {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Ein Fehler ist aufgetreten und Ihr Feedback wurde nicht eingereicht. Wenn dieser Fehler weiterhin besteht, wenden Sie sich bitte an Ihren Beratungsstelle.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Ihr Feedback wurde eingereicht. Unser Workplace-Support-Team wird es sorgfältig lesen. Gegebenenfalls werden wir zu Ihnen zurückkommen.',
    'New feedback': 'Neues Feedback',
  },
  CreateFlow: {
    Description: 'Beschreibung',
    'Ref: Provide Description': 'Bitte geben Sie ein Datum und eine Beschreibung an',
    'Ref: I would like to provide a': 'Ich möchte eine bereitstellen',
    'Ref: in regards to': 'in Bezug auf',
    'Ref: &': '&',
    'Ref: at': 'bei',
    'Ref: on the': 'am',
  },
};
export default labels;
