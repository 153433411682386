import { MemoryRouter } from 'react-router-dom';

import { DecoratorFunction } from './DecoratorFunction.type';

const RouterDecorator: DecoratorFunction = (Story) => (
  <MemoryRouter>
    <Story />
  </MemoryRouter>
);

export default RouterDecorator;
