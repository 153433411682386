import { getFilters } from '../productListFilters.helper';

import { Filter } from '@/components/atoms/Filters/Filters.types';
import { ProductListItem, Section } from '@/modules/Order/types/productList.types';

const mockLabel = (label: string) => label;

const setSelectedMoment = jest.fn((moment: string) => {});
const filter = jest.fn((items: ProductListItem[]) => items);

const menuItemsList: ProductListItem[] = [
  {
    id: '1_2',
    menuItemId: '2',
    title: '',
    description: '',
    price: 12,
    category: '',
    subCategory: undefined,
    moment: 'Any',
    genericCategory: '',
  },
  {
    id: '2_2',
    menuItemId: '3',
    title: '',
    description: '',
    price: 13,
    category: '',
    subCategory: undefined,
    moment: 'Any',
    genericCategory: '',
  },
];

const sections: Section[] = [
  { title: 'First Section', filter },
  { title: 'Second Section', filter },
];

describe('getFilters', () => {
  describe('getFilters with multiple moments and multiple sections', () => {
    let result: Filter[];
    beforeAll(() => {
      result = getFilters({
        label: mockLabel,
        menuItemsList,
        sections,
        moments: ['anytyme', 'now'],
        setSelectedMoment,
      });
    });

    it('should return proper number of filters ', () => {
      expect(result.length).toBe(2);
    });

    it('should return proper filter for Moment filtering', () => {
      expect(result[0].name).toBe('Ref: filter label: moment');
      expect(result[0].displayType).toBe('DROPDOWN');
      expect(result[0].id).toBe('filter_moment');
      expect(result[0].multiple).toBe(false);
      expect(result[0].options).toStrictEqual([
        {
          label: 'anytyme',
          value: 'anytyme',
        },
        {
          label: 'now',
          value: 'now',
        },
      ]);
      expect(result[0].position).toBe('NOT_IN_MODAL');
      expect(result[0].text).toBe(undefined);
      expect(result[0].withTitle).toBe(false);
      expect(result[0].wrapperClassName).toBe(undefined);
    });

    it('should return proper filter for Category filtering', () => {
      expect(result[1].name).toBe('Ref: filter label: category');
      expect(result[1].id).toBe('filter_category');
      expect(result[1].multiple).toBe(false);
      expect(result[1].options).toStrictEqual([
        {
          default: true,
          label: 'Ref: all',
          value: 'all',
        },
        {
          label: 'First Section',
          value: 'First Section',
        },
        {
          label: 'Second Section',
          value: 'Second Section',
        },
      ]);
      expect(result[1].position).toBe('NOT_IN_MODAL');
      expect(result[1].text).toBe(undefined);
      expect(result[1].withTitle).toBe(false);
      expect(result[1].wrapperClassName).toBe(undefined);
      expect(result[1].displayType).toBe('EXPANDED');
    });
  });

  describe('getFilters - requesting with 1 moment and 1 section', () => {
    it('should return cart modifiers result position as INVISIBLE for moment & no options for category filter', () => {
      const result = getFilters({
        label: mockLabel,
        menuItemsList,
        sections: [{ title: 'First Section', filter }],
        moments: ['anytyme'],
        setSelectedMoment,
      });

      expect(result[0].position).toBe('INVISIBLE');
      expect(result[1].options).toStrictEqual([]);
    });
  });
});
