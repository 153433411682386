import classNames from 'classnames';
import moment, { DurationInputArg2 } from 'moment';
import React, { useRef, useState, ChangeEvent, FocusEvent, FC } from 'react';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';

import { TIME_FORMAT } from './../../../constants';
import { DEFAULT_VALUE, MAX_TIME, MIN_TIME } from './contants';
import TimeInput from './TimeInput';
import TimePeriod from './TimePeriod';
import { TimePeriodEnum } from './TimePeriod/TimePeriod.types';
import { ChangeDirection, TimePickerProps } from './TimePicker.types';

import { checkIf24HourClockForLanguage } from '@/helpers/dateTime';

import styles from './TimePicker.module.css';

const TimePicker: FC<TimePickerProps> = ({
  id,
  value,
  minTime,
  className,
  onChange,
  currentLanguageCode,
  'data-testid': dataTestId = 'time-picker',
}) => {
  const { label } = useCustomTranslation();

  const hoursRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const minutesRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const is24HourFormat = checkIf24HourClockForLanguage(currentLanguageCode);
  const hourDisplayFormat = is24HourFormat ? 'HH' : 'hh';
  const minuteDisplayFormat = 'mm';

  const [h = DEFAULT_VALUE, m = DEFAULT_VALUE] = (value || MIN_TIME).split(':');
  const minTimeParsed = moment(minTime || MIN_TIME, TIME_FORMAT);
  const maxTimeParsed = moment(MAX_TIME, TIME_FORMAT);

  const valueHours = validateHours(parseInt(h, 10), maxTimeParsed, minTimeParsed);
  const valueMinutes = validateMinutes(parseInt(m, 10), valueHours, maxTimeParsed, minTimeParsed);

  const [hours, setHours] = useState<number>(valueHours);
  const [minutes, setMinutes] = useState<number>(valueMinutes);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    getTimePeriod(valueHours, is24HourFormat)
  );

  const [inputHours, setInputHours] = useState<string>(
    moment(`${valueHours}:${valueMinutes}`, TIME_FORMAT).format(hourDisplayFormat)
  );
  const [inputMinutes, setInputMinutes] = useState<string>(
    moment(`${valueHours}:${valueMinutes}`, TIME_FORMAT).format(minuteDisplayFormat)
  );

  const allowMoreHours = hours < maxTimeParsed.hour();
  const allowLessHours = moment(`${hours}:${minutes}`, TIME_FORMAT)
    .add(-1, 'hour')
    .add(1, 'second')
    .isAfter(minTimeParsed);

  const allowMoreMinutes = moment(`${hours}:${minutes}`, TIME_FORMAT)
    .add(1, 'minute')
    .add(-1, 'second')
    .isBefore(maxTimeParsed);

  const allowLessMinutes = moment(`${hours}:${minutes}`, TIME_FORMAT)
    .add(-1, 'minute')
    .add(1, 'second')
    .isAfter(minTimeParsed);

  const allowAmSelection = minTimeParsed.isBefore(moment('12:00', TIME_FORMAT));
  const allowPmSelection = maxTimeParsed.isAfter(moment('12:00', TIME_FORMAT));

  const onChangeHours = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: v } = e.target;
    if (!v) {
      setHours(0);
      setInputHours('');
      return;
    }

    const h = parseInt(v, 10);
    if (h === 0 || h) {
      setHours(h);
      setInputHours(h.toString());
    }

    if (e.target.value.length === 2) minutesRef.current.focus();
  };

  const onChangeMinutes = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: v } = e.target;
    if (!v) {
      setHours(0);
      setInputHours('');
      return;
    }

    const m = parseInt(v, 10);
    if (m === 0 || m) {
      setMinutes(m);
      setInputMinutes(m.toString());
    }

    if (e.target.value.length === 2) minutesRef.current.blur();
  };

  const onMoreHours = () => {
    increaseDecreaseTime(ChangeDirection.Increment, 'hour', 1);
  };

  const onLessHours = () => {
    increaseDecreaseTime(ChangeDirection.Decrement, 'hour', 1);
  };

  const onMoreMinutes = () => {
    increaseDecreaseTime(ChangeDirection.Increment, 'minute', 1);
  };

  const onLessMinutes = () => {
    increaseDecreaseTime(ChangeDirection.Decrement, 'minute', 1);
  };

  const onFocusHours = () => setInputHours('');
  const onFocusMinutes = () => setInputMinutes('');

  const onBlurHours = (e: FocusEvent<HTMLInputElement>) => {
    const inputHour = parseInt(e.target?.value, 10);
    const h24Clock =
      selectedTimePeriod === TimePeriodEnum.PM ? Math.min(23, inputHour + 12) : inputHour;

    const h = setValueHour(h24Clock, minutes);
    handleChange([h, minutes]);
  };

  const onBlurMinutes = (e: FocusEvent<HTMLInputElement>) => {
    const m = setValueMinute(hours, parseInt(e.target?.value, 10));
    handleChange([hours, m]);
  };

  const onTimePeriodChanged = (period: TimePeriodEnum) => {
    if (period === TimePeriodEnum.AM) {
      increaseDecreaseTime(ChangeDirection.Decrement, 'hour', 12);
    }

    if (period === TimePeriodEnum.PM) {
      increaseDecreaseTime(ChangeDirection.Increment, 'hour', 12);
    }
  };

  const increaseDecreaseTime = (
    directiom: ChangeDirection,
    unit: DurationInputArg2,
    amount: number
  ) => {
    const changeIncrement = directiom === ChangeDirection.Increment ? amount : -amount;

    const newTime = moment(`${hours}:${minutes}`, TIME_FORMAT).add(changeIncrement, unit);

    const m = setValueMinute(newTime.hours(), newTime.minute());
    setValueHour(newTime.hour(), m);

    handleChange([newTime.hour(), newTime.minute()]);
  };

  const setValueHour = (h: number, m: number) => {
    const num = validateHours(h, maxTimeParsed, minTimeParsed);
    setHours(num);
    setInputHours(moment(`${num}:${m}`, TIME_FORMAT).format(hourDisplayFormat));

    setSelectedTimePeriod(getTimePeriod(num, is24HourFormat));

    return num;
  };

  const setValueMinute = (h: number, m: number) => {
    const num = validateMinutes(m, h, maxTimeParsed, minTimeParsed);
    setMinutes(num);
    setInputMinutes(moment(`${h}:${num}`, TIME_FORMAT).format(minuteDisplayFormat));

    const timePeriod = getTimePeriod(h, is24HourFormat);
    setSelectedTimePeriod(timePeriod);

    return num;
  };

  const handleChange = (timeValues: number[]) => {
    const selectedTime = moment(`${timeValues[0]}:${timeValues[1]}`, TIME_FORMAT).format(
      TIME_FORMAT
    );
    return onChange && onChange(selectedTime);
  };

  return (
    <div className={classNames(styles.wrapper, className)} data-testid={dataTestId}>
      <label htmlFor={`${id}-hours`} className={classNames(styles.srOnly)}>
        {label('Hours')}
      </label>
      <TimeInput
        id={`${id}-hours`}
        inputRef={hoursRef}
        allowLess={allowLessHours}
        allowMore={allowMoreHours}
        value={inputHours}
        onChange={onChangeHours}
        onFocus={onFocusHours}
        onBlur={onBlurHours}
        onMore={onMoreHours}
        onLess={onLessHours}
        label={label}
        data-testid={`${dataTestId}-hours`}
      />
      <div className={classNames(styles.divider)}>:</div>
      <label htmlFor={`${id}-minutes`} className={classNames(styles.srOnly)}>
        {label('Minutes')}
      </label>
      <TimeInput
        id={`${id}-minutes`}
        inputRef={minutesRef}
        allowLess={allowLessMinutes}
        allowMore={allowMoreMinutes}
        value={inputMinutes}
        onChange={onChangeMinutes}
        onFocus={onFocusMinutes}
        onBlur={onBlurMinutes}
        onMore={onMoreMinutes}
        onLess={onLessMinutes}
        label={label}
        data-testid={`${dataTestId}-minutes`}
      />
      {!is24HourFormat ? (
        <TimePeriod
          allowAmSelection={allowAmSelection}
          allowPmSelection={allowPmSelection}
          timePeriod={selectedTimePeriod}
          onTimePeriodChanged={onTimePeriodChanged}
          data-testid={`${dataTestId}-period`}
        />
      ) : null}
    </div>
  );
};

const validateHours = (hours: number, maxTime: moment.Moment, minTime: moment.Moment) => {
  if (hours > maxTime.hour()) return maxTime.hour();
  if (isNaN(hours) || hours < minTime.hour()) return minTime.hour();

  return hours;
};

const validateMinutes = (
  minutes: number,
  hour: number,
  maxTime: moment.Moment,
  minTime: moment.Moment
) => {
  if (hour === maxTime.hour() && minutes > maxTime.minutes()) return maxTime.minutes();
  if (hour === minTime.hour() && (isNaN(minutes) || minutes < minTime.minute()))
    return minTime.minute();

  return minutes;
};

const getTimePeriod = (hours: number, is24hClock: boolean) => {
  if (is24hClock || hours < 12) return TimePeriodEnum.AM;

  return TimePeriodEnum.PM;
};

export default TimePicker;
