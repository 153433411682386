import { screen, cleanup } from '@testing-library/react';

// import { APP_DISPLAY_NAME } from '../../../../constants';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultState as mockCoreDefaultState } from '../../../../modules/Core/reducers/coreReducer';
import { defaultState as mockSharedDefaultState } from '../../../../reducers/sharedReducer';

import AlwaysOnSurveys from './AlwaysOnSurveys';

const mockDispatch = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useSelector: (callback: any) =>
    callback({
      Shared: mockSharedDefaultState,
      Core: {
        ...mockCoreDefaultState,
        context: { site: { id: '1fb04838-744d-4584-bf8b-db8b8eb8398e', name: 'Microosft Place' } },
      },
    }),
  useDispatch: () => mockDispatch,
}));

describe('AlwaysOnSurveys', () => {
  const alwaysOnSurveysProps = {
    surveyReceiverEmail: 'test@email.com',
    surveys: [
      {
        isActive: true,
        value:
          'https://sodexo-diy-surveys.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=8MK2CD&Ch=Lien&Zones=Restaurant%20/%20Café&Loc=Microsoft%20Dublin-Place%20Ireland&Mail=imene.merabet@sodexo.com',
        option: {
          name: 'InmomentSurvey',
          value:
            'https://sodexo-diy-surveys.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=8MK2CD&Ch=Lien&Zones=Restaurant%20/%20Café&Loc={$0}&Mail={$1}',
          code: 'InmomentSurvey',
          isServiceType: true,
          isServicePointer: false,
        },
      },
      {
        isActive: true,
        value:
          'https://sodexo-diy-surveys.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=8MK2CD&Ch=Lien&Zones=Desk%20/%20workstation%20area&Loc=Microsoft%20Dublin-Place%20Ireland&Mail=imene.merabet@sodexo.com',
        option: {
          name: 'InmomentSurvey',
          value:
            'https://sodexo-diy-surveys.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=8MK2CD&Ch=Lien&Zones=Desk%20/%20workstation%20area&Loc={$0}&Mail={$1}',
          code: 'InmomentSurvey',
          isServiceType: true,
          isServicePointer: false,
        },
      },
      {
        isActive: true,
        value:
          'https://sodexo-diy-surveys.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=8MK2CD&Ch=Lien&Zones=Meeting%20room%20facilities&Loc=Microsoft%20Dublin-Place%20Ireland&Mail=imene.merabet@sodexo.com',
        option: {
          name: 'InmomentSurvey',
          value:
            'https://sodexo-diy-surveys.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=8MK2CD&Ch=Lien&Zones=Meeting%20room%20facilities&Loc={$0}&Mail={$1}',
          code: 'InmomentSurvey',
          isServiceType: true,
          isServicePointer: false,
        },
      },
    ],
  };

  afterAll(() => cleanup());

  describe('Detail fields are displayed', () => {
    renderComponent(AlwaysOnSurveys, alwaysOnSurveysProps);

    it('should render Location list item data', async () => {
      expect(screen.getByText('Restaurant / Café')).toBeTruthy();
      expect(screen.getByText('Meeting room facilities')).toBeTruthy();
      expect(screen.getByText('Desk / Workstation area')).toBeTruthy();
    });
  });
});
