const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': '您的申請',
    'Ref: Summary': '概括',
    'Ref: Request category': '請求類別',
    'Ref: Person affected': '受此要求影響的人',
    'Ref: Your phone number': '電話號碼',
    'Ref: Description': '描述',
    'Ref: Service Requests': '服務請求',
    'Ref: Request Service': '請求服務',
    'Ref: Success title': '您的要求已創建',
    'Ref: Error title': '哎呀！',
    'Ref: Error subtitle': '發生了錯誤。請再試一次。',
    'Ref: Try again': '再試一次',
    'Ref: you pronoun': '（你）',
  },
  Tutorials: {
    'Ref: Tutorial: title': '這是您的工作場所支持中心',
    'Ref: Tutorial: tabname': '要求',
    'Ref: Tutorial: body': '請為不同的請求創建獨立的記錄，我們隨時為您提供幫助。',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.': '您正在網站{siteName}上要求服務。',
    'Ref: Building': '建築',
    'Ref: Room': '房間',
    'Ref: Description help text': '您的詳細信息越多，我們就越能為您提供支持。',
    'Ref: Next': '下一個',
    'Ref: Your request': '你的申請',
    'Ref: PhoneNumber error msg': '電話號碼無效',
    'Ref: Type at least 3 characters to search': '輸入至少 3 個字元進行搜尋',
    'Ref: No affected person results': '沒有人匹配要求的標準',
    'Ref: Required': '* 必需的',
  },
  Summary: {
    'Ref: Location': '地點',
    'Ref: Attachment': '附件',
    'Ref: Submit': '提交',
    'Ref: No affected person results': '沒有符合請求條件的人',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': '服務請求',
    'Ref: createdOn': '創建於',
    'Ref: summary': '概括',
    'Ref: category': '請求類別',
    'Ref: request raised for': '受此要求影響的人',
    'Ref: phoneNumber': '電話號碼',
    'Ref: location': '電話號碼',
    'Ref: description': '描述',
    'Ref: attachments': '附件',
  },
};
export default labels;
