import { useGetAllLegalDocumentsQuery } from '../api/legalDocuments/legalDocumentsApi';

import useLanguage from './useLanguage';

import useGeoCode from '@/helpers/hooks/useGeoCode';
import useLoginStatus from '@/helpers/hooks/useLoginStatus';

const LEGAL_DOCS_POLL_INTERVAL = 1000 * 60 * 60 * 12;

export const useLegalDocsCheckForUpdates = () => {
  const { isLoggedIn } = useLoginStatus();
  const { currentLanguageCode: languageCode } = useLanguage();
  const { currentGeoCode: geoCode } = useGeoCode();

  useGetAllLegalDocumentsQuery(
    { geoCode, languageCode },
    { skip: !isLoggedIn, pollingInterval: LEGAL_DOCS_POLL_INTERVAL }
  );
};
