import { APP_NAME } from '../../../constants';
import { getAppSpecificData } from '../../../helpers/misc';
import { modulesConfig, serviceTypes } from '../../config';

const moduleId = 'Contacts';
const cacheVersion = '0';

const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

const pagePaths = {
  Module: modulePath,
  ContactForm: modulePath + '/form',
  FormSubmissionSuccess: modulePath + '/success',
  FormSubmissionFailure: modulePath + '/failure',
};

const contactsConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      shouldDisplayFeedbackButton: false,
    },
    default: {
      shouldDisplayFeedbackButton: true,
    },
  };

  return getAppSpecificData(configuration);
};

export { moduleId, cacheVersion, pagePaths, contactsConfig };
