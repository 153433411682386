const labels = {
  All: {
    'starts at': 'commence à',
    'ends at': 'fini à',
    location: 'Emplacement',
    'coming date': 'date à venir',
    'Ref: Currently event not preferred. Click to make preferred':
      "Actuellement l'événement n'est pas préféré. Cliquez pour faire préférer",
    'Ref: Currently event preferred. Click to make not preferred':
      "Actuellement préféré de l'événement. Cliquez pour faire non préféré",
  },
  EventDetails: {
    details: 'Détails',
    'Ref: Page title': "Détails de l'évènement",
    'Event dates': "Dates de l'événement",
    'See online:': 'Voir en ligne :',
    'See all dates': 'Voir toutes les dates',
    'Remove from my selection': 'Supprimer de ma sélection',
    'Add to my selection': 'Ajouter à mes favoris',
    'Ref: explain calendar usage':
      "Les dates des événements sont mises en évidence dans le calendrier ci-dessous. Ne manquez pas les événements que vous aimez ! Cliquez sur une date d'événement dans le calendrier pour télécharger une invitation. Ouvrez ensuite le fichier téléchargé pour ajouter l'événement à votre calendrier préféré.",
    'Ref: Favorite': 'Préféré',
    'Ref:Feedback': "Retour d'information",
    'Ref: Event site different title': "Cet événement n'appartient pas à votre site actuel",
    'Ref: Event site different description':
      "Vous consultez un événement qui n'appartient pas à votre site actuel. Vous ne pourrez pas le voir dans la liste des événements.",
    'Ref: See all dates': 'Voir toutes les dates',
    'Ref: From': 'Depuis',
    'Ref: to': 'pour',
    'Ref: Coming date': 'Date à venir',
    'Ref: Add to calendar': 'Ajouter au calendrier',
  },
  EventsList: {
    'Ref: Page title': 'Évènements',
    'All dates': 'Toutes les dates',
    'All events': 'Tous les évènements',
    'Ref: Search': 'Recherche',
    'My selection': 'Mon préféré',
    'filter label: date': 'Date',
    'filter label: coming events': 'événements à venir',
    'filter label: past events': 'événements passés',
    'filter label: interest': 'préférence',
  },
  Widget: {
    'Ref: Carousel title': 'Événements',
    'No info yet, come back later': 'Aucune information encore, revenez plus tard 😉',
  },
};
export default labels;
