/* eslint-disable no-restricted-imports */
import { svgFactory } from '../svgFactory';

import { ReactComponent as ImageDefaultBuildingSvg } from './image-default-building.svg';
import { ReactComponent as ImageDefaultFoodNoRetailSvg } from './image-default-food-no-retail.svg';
import { ReactComponent as ImageDefaultFoodRetailSvg } from './image-default-food-retail.svg';
import { ReactComponent as ImageDefaultFoodSvg } from './image-default-food.svg';
import { ReactComponent as ImageDefaultNoFoodNoRetailSvg } from './image-default-no-food-no-retail.svg';
import { ReactComponent as ImageDefaultNoFoodRetailSvg } from './image-default-no-food-retail.svg';
import { ReactComponent as ImageDefaultNoFoodSvg } from './image-default-no-food.svg';
import { ReactComponent as ImageDefaultSvg } from './image-default.svg';
import { ReactComponent as ImageErrorUnicornSvg } from './image-error-unicorn.svg';
import { ReactComponent as ImageServiceRequestSvg } from './image-service-request-base.svg';

export const ImageDefault = svgFactory(ImageDefaultSvg);
export const ImageDefaultFoodNoRetailImg = svgFactory(ImageDefaultFoodNoRetailSvg);
export const ImageDefaultFoodRetailImg = svgFactory(ImageDefaultFoodRetailSvg);
export const ImageDefaultFoodImg = svgFactory(ImageDefaultFoodSvg);
export const ImageDefaultNoFoodNoRetailImg = svgFactory(ImageDefaultNoFoodNoRetailSvg);
export const ImageDefaultNoFoodRetailImg = svgFactory(ImageDefaultNoFoodRetailSvg);
export const ImageDefaultNoFoodImg = svgFactory(ImageDefaultNoFoodSvg);
export const ImageDefaultBuilding = svgFactory(ImageDefaultBuildingSvg);
export const ImageErrorUnicorn = svgFactory(ImageErrorUnicornSvg);
export const ImageServiceRequest = svgFactory(ImageServiceRequestSvg);
