import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LOCAL_STORAGE_KEYS } from '../../constants';
import { State } from '../../types/state.types';

function useGeoCode(): {
  defaultGeoCode: string;
  currentGeoCode: string;
  currentRegionCode: string;
} {
  const geographies = useSelector((state: State) => state.Shared.geographies);
  const defaultGeoCode = geographies?.defaultGeoCode;
  const cachedGeoCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_GEO_CODE);
  const currentGeoCode = cachedGeoCode ?? defaultGeoCode;
  const currentRegionCode =
    geographies.list.find((geo) => geo.code === currentGeoCode)?.regionCode || '';

  useEffect(() => {
    const regionCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE);
    if (regionCode !== currentRegionCode)
      localStorage.setItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE, currentRegionCode);
  }, [currentRegionCode]);

  return { defaultGeoCode, currentGeoCode, currentRegionCode };
}

export default useGeoCode;
