const mockResponse = {
  getSites: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        sites: [
          {
            id: '4aa09563-a78d-e911-a978-000d3a2bcac2',
            name: 'Amsterdam Headquarter',
            locationtype: 'Site',
            additionalInfo: {
              markdown: '#TEST\n##Test\nTESTING',
              html: '<h1 id="h1-test"><a name="TEST" class="reference-link"></a><span class="header-link octicon octicon-link"></span>TEST</h1><h2 id="h2-test"><a name="Test" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Test</h2><p>TESTING</p>',
            },
            address: {
              line1: 'a',
              line2: '',
              line3: '',
              city: '',
              postalCode: '',
              stateOrProvince: '',
              country: '',
            },
            geoCoordinates: null,
            images: [],
            siteMapImage: '1681df4a-4359-ea11-a811-000d3a25c5d6',
            selfRegistrationDuration: 4,
            currency: {
              currencyName: 'US Dollar',
              currencySymbol: '$',
              isoCode: 'USD',
              exchangeRate: 1.0,
              precision: 2,
            },
          },
          {
            id: '62b9d04a-23c6-e811-a966-000d3a2bcac2',
            name: 'Budapest Office',
            locationtype: 'Site',
            additionalInfo: {
              markdown:
                '# Welcome\nWe\'d like to welcome you to our new office in Budapest. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.\n\n## Getting to the office\nWe\'d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.\n\n**Tramway**\nNext tramway stop of line 6 is "stock exchange". From there the office is in sight.. 2 min walk time.\n\n**Bus**\nNext bus stop of line A55 is "stock exchange road". 5 minutes walk time.\n\n**Car**\nIn case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.\n\n## *Hotels*\nWe have agreed rates with several hotels in Budapest. We would recommend the following ones:\n\nhttps://boutiquehotelbudapest.com/\n\nhttps://www.accorhotels.com/en/hotel-3560-novotel-budapest-centrum/index.shtml',
              html: '<h1 id="h1-welcome"><a name="Welcome" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Welcome</h1><p>We’d like to welcome you to our new office in Budapest. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.</p>\n<h2 id="h2-getting-to-the-office"><a name="Getting to the office" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Getting to the office</h2><p>We’d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.</p>\n<p><strong>Tramway</strong><br>Next tramway stop of line 6 is “stock exchange”. From there the office is in sight.. 2 min walk time.</p>\n<p><strong>Bus</strong><br>Next bus stop of line A55 is “stock exchange road”. 5 minutes walk time.</p>\n<p><strong>Car</strong><br>In case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.</p>\n<h2 id="h2--em-hotels-em-"><a name="<em>Hotels</em>" class="reference-link"></a><span class="header-link octicon octicon-link"></span><em>Hotels</em></h2><p>We have agreed rates with several hotels in Budapest. We would recommend the following ones:</p>\n<p><a href="https://boutiquehotelbudapest.com/">https://boutiquehotelbudapest.com/</a></p>\n<p><a href="https://www.accorhotels.com/en/hotel-3560-novotel-budapest-centrum/index.shtml">https://www.accorhotels.com/en/hotel-3560-novotel-budapest-centrum/index.shtml</a></p>',
            },
            address: {
              line1: '',
              line2: 'Graphisoft park 3',
              line3: '',
              city: 'Budapest',
              postalCode: '1031',
              stateOrProvince: 'Budapest',
              country: 'Hungary',
            },
            geoCoordinates: {
              longitude: 19.04614,
              latitude: 47.57145,
            },
            images: ['d8bb4f73-23e0-e911-a812-000d3a25c7c6'],
            siteMapImage: 'f500bc84-e5b9-ea11-a812-000d3a25caae',
            selfRegistrationDuration: 4,
            currency: {
              currencyName: 'US Dollar',
              currencySymbol: '$',
              isoCode: 'USD',
              exchangeRate: 1.0,
              precision: 2,
            },
          },
          {
            additionalInfo: {
              html: '<h1 id="h1-welcome"><a name="Welcome" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Welcome</h1><p>We’d like to welcome you to our new office in FMG Hamilton. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.</p>\n<h2 id="h2-getting-to-the-office"><a name="Getting to the office" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Getting to the office</h2><p>We’d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.</p>\n<p><strong>Tramway</strong><br>Next tramway stop of line 6 is “stock exchange”. From there the office is in sight within 2 min walk time.</p>\n<p><strong>Bus</strong><br>Next bus stop of line A55 is “stock exchange road”. 5 minutes walk time.</p>\n<blockquote>\n<blockquote>\n<p><strong>Car</strong><br>In case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.</p>\n</blockquote>\n</blockquote>',
              markdown:
                '# Welcome\nWe\'d like to welcome you to our new office in FMG Hamilton.It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.\n\n## Getting to the office\nWe\'d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.\n\n**Tramway**\nNext tramway stop of line 6 is "stock exchange". From there the office is in sight within 2 min walk time.\n\n**Bus**\nNext bus stop of line A55 is "stock exchange road". 5 minutes walk time.\n> > \n**Car**\nIn case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.',
            },
            address: {
              city: 'Hamilton City',
              country: 'Canada',
              line1: '128 Seddon Road',
              line2: '',
              line3: '',
              postalCode: '128',
              stateOrProvince: 'Hamilton',
            },
            currency: {
              currencyName: 'Euro',
              currencySymbol: '€',
              exchangeRate: 1,
              isoCode: 'EUR',
              precision: 2,
            },
            geoCoordinates: { longitude: 175.267548, latitude: -37.780777 },
            id: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
            images: [],
            locationtype: 'Site',
            name: 'FMG Hamilton',
            selfRegistrationDuration: 200,
            siteMapImage: '',
          },
        ],
      }),
  },
  selectSite: {
    ok: true,
    status: 202,
    json: () => Promise.resolve(),
  },
  getPublicSites: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        sites: [
          {
            contractId: '9ee44da8-f47b-e911-a97d-000d3a2bcc69',
            id: '3a301679-bd7c-e911-a97e-000d3a2bcd97',
            name: 'FMG Camp 145',
            locationtype: 'Site',
            additionalInfo: {
              markdown:
                "# Welcome\nWe'd like to welcome you to our new office in FMG Hamilton. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.\n\n## Getting to the office\nWe'd like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.",
              html: '<h1 id="h1-welcome"><a name="Welcome" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Welcome</h1><p>We’d like to welcome you to our new office in FMG Hamilton. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.</p>\n<h2 id="h2-getting-to-the-office"><a name="Getting to the office" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Getting to the office</h2><p>We’d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.</p>',
            },
            address: {
              line1: 'Marble Bar',
              line2: 'WA 6760',
              line3: 'FMG Rail Camp 145',
              city: '',
              postalCode: '195908',
              stateOrProvince: 'Western Australia',
              country: 'Australia',
            },
            geoCoordinates: { longitude: 119.757683, latitude: -21.1755238 },
            images: [],
            siteMapImage: '',
            selfRegistrationDuration: 30,
            currency: {
              currencyName: 'Australian Dollar',
              currencySymbol: '$',
              isoCode: 'AUD',
              exchangeRate: 1.4,
              precision: 2,
            },
            siteAccessRestrictionLevelId: null,
          },
          {
            contractId: '9ee44da8-f47b-e911-a97d-000d3a2bcc69',
            id: '51bca46a-8f7d-e911-a97e-000d3a2bcd97',
            name: 'FMG Cloudbreak',
            locationtype: 'Site',
            additionalInfo: null,
            address: null,
            geoCoordinates: null,
            images: [],
            siteMapImage: '',
            selfRegistrationDuration: 365,
            currency: {
              currencyName: 'Australian Dollar',
              currencySymbol: '$',
              isoCode: 'AUD',
              exchangeRate: 1.4,
              precision: 2,
            },
            siteAccessRestrictionLevelId: null,
          },
          {
            contractId: '9ee44da8-f47b-e911-a97d-000d3a2bcc69',
            id: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547',
            name: 'FMG Hamilton',
            locationtype: 'Site',
            additionalInfo: {
              markdown:
                '# Welcome\nWe\'d like to welcome you to our new office in FMG Hamilton. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.\n\n## Getting to the office\nWe\'d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.\n\n**Tramway**\nNext tramway stop of line 6 is "stock exchange". From there the office is in sight within 2 min walk time.\n\n**Bus**\nNext bus stop of line A55 is "stock exchange road". 5 minutes walk time.\n> > \n**Car**\nIn case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.',
              html: '<h1 id="h1-welcome"><a name="Welcome" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Welcome</h1><p>We’d like to welcome you to our new office in FMG Hamilton. It has been designed to support your wellbeing, improve collaboration and help you to be productive. We hope you like it.</p>\n<h2 id="h2-getting-to-the-office"><a name="Getting to the office" class="reference-link"></a><span class="header-link octicon octicon-link"></span>Getting to the office</h2><p>We’d like to drive a sustainable mindset in our new office. Therefore, we recommend to come using public transportation.</p>\n<p><strong>Tramway</strong><br>Next tramway stop of line 6 is “stock exchange”. From there the office is in sight within 2 min walk time.</p>\n<p><strong>Bus</strong><br>Next bus stop of line A55 is “stock exchange road”. 5 minutes walk time.</p>\n<blockquote>\n<blockquote>\n<p><strong>Car</strong><br>In case you need to come with the car you can use the car park opposite of the office building. Access code is 1234A.</p>\n</blockquote>\n</blockquote>',
            },
            address: {
              line1: '128 Seddon Road',
              line2: '',
              line3: '',
              city: 'Hamilton City',
              postalCode: '128',
              stateOrProvince: 'Hamilton',
              country: 'Canada',
            },
            geoCoordinates: { longitude: 175.267548, latitude: -37.780777 },
            images: ['4793a6ee-742c-ec11-b6e5-000d3aae39c5'],
            siteMapImage: '',
            selfRegistrationDuration: 200,
            currency: {
              currencyName: 'Euro',
              currencySymbol: '€',
              isoCode: 'EUR',
              exchangeRate: 1.0,
              precision: 2,
            },
            siteAccessRestrictionLevelId: null,
          },
          {
            contractId: '9ee44da8-f47b-e911-a97d-000d3a2bcc69',
            id: '58a80313-02dc-ec11-bb3d-000d3abafb33',
            name: 'FMG University Test Site',
            locationtype: 'Site',
            additionalInfo: null,
            address: null,
            geoCoordinates: null,
            images: [],
            siteMapImage: '',
            selfRegistrationDuration: null,
            currency: {
              currencyName: 'US Dollar',
              currencySymbol: '$',
              isoCode: 'USD',
              exchangeRate: 1.0,
              precision: 2,
            },
            siteAccessRestrictionLevelId: null,
          },
          {
            contractId: '9ee44da8-f47b-e911-a97d-000d3a2bcc69',
            id: 'f8bd45b5-e9d5-ec11-a7b5-6045bd92f4ac',
            name: 'FMG Western Australia',
            locationtype: 'Site',
            additionalInfo: null,
            address: null,
            geoCoordinates: null,
            images: [],
            siteMapImage: '',
            selfRegistrationDuration: null,
            currency: {
              currencyName: 'US Dollar',
              currencySymbol: '$',
              isoCode: 'USD',
              exchangeRate: 1.0,
              precision: 2,
            },
            siteAccessRestrictionLevelId: null,
          },
          {
            contractId: 'a05e35fd-652e-e811-a952-000d3a2bc5c1',
            id: '293be3fb-6d2e-e811-a952-000d3a2bcd97',
            name: 'Sanofi Bridgewater',
            locationtype: 'Site',
            additionalInfo: {
              markdown: 'This is about sanofi bridgewater site .\nHello People!',
              html: '<p>This is about sanofi bridgewater site .<br>Hello People!</p>',
            },
            address: {
              line1: '2nd main road,',
              line2: '4th cross',
              line3: '2nd stage',
              city: 'Bengaluru',
              postalCode: '560066',
              stateOrProvince: 'Karnataka',
              country: '',
            },
            geoCoordinates: null,
            images: [],
            siteMapImage: '',
            selfRegistrationDuration: 365,
            currency: {
              currencyName: 'US Dollar',
              currencySymbol: '$',
              isoCode: 'USD',
              exchangeRate: 1.0,
              precision: 2,
            },
            siteAccessRestrictionLevelId: null,
          },
        ],
      }),
  },
  getSiteByCode: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        contract: {
          id: '',
          name: 'Test contract',
        },
        site: {
          id: '',
          name: 'Test site',
        },
      }),
  },
  setInitialSiteAndContract: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
};

export default mockResponse;
