const labels = {
  All: {
    'starts at': 'prasideda',
    'ends at': 'baigiasi',
    location: 'vieta',
    'coming date': 'ateinanti data',
    'Ref: Currently event not preferred. Click to make preferred':
      'Šiuo metu nėra pirmenybė. Spustelėkite, kad būtų pageidaujama',
    'Ref: Currently event preferred. Click to make not preferred':
      'Šiuo metu pirmenybė teikiama renginiui. Spustelėkite, jei norite, kad nepriimtumėte',
  },
  EventDetails: {
    details: 'Detalės',
    'Ref: Page title': 'Informacija apie įvykį',
    'Event dates': 'Įvykių datos',
    'See online:': 'Žiūrėti internete:',
    'See all dates': 'Žiūrėti visas datas',
    'Remove from my selection': 'Pašalinti iš mano pasirinkimo',
    'Add to my selection': 'Pridėti į pageidaujamą',
    'Ref: explain calendar usage':
      'Įvykių datos yra pažymėtos žemiau esančiame kalendoriuje. Nepraleiskite norimų įvykių! Spustelėkite įvykio datą kalendoriuje, kad galėtumėte atsisiųsti kvietimą. Tada atidarykite atsisiųstą failą, jei norite pridėti įvykį į savo preffikuotą kalendorių.',
    'Ref: Favorite': 'Mėgstamiausia',
    'Ref:Feedback': 'Atsiliepimas',
    'Ref: Event site different title': 'Šis įvykis nepriklauso jūsų dabartinei svetainei',
    'Ref: Event site different description':
      'Jūs žiūrite įvykį, kuris nepriklauso jūsų dabartinei svetainei. Negalėsite to pamatyti įvykių sąraše.',
    'Ref: See all dates': 'Pamatyti visas datas',
    'Ref: From': 'Nuo',
    'Ref: to': 'į',
    'Ref: Coming date': 'Atėjimo data',
    'Ref: Add to calendar': 'Pridėkite prie kalendoriaus',
  },
  EventsList: {
    'Ref: Page title': 'Renginiai',
    'All dates': 'Visos datos',
    'All events': 'Visi įvykiai',
    'Ref: Search': 'Paieška',
    'My selection': 'Mano pageidaujama',
    'filter label: date': 'Data',
    'filter label: coming events': 'Ateinantys įvykiai',
    'filter label: past events': 'praeities įvykiai',
    'filter label: interest': 'pirmenybė',
  },
  Widget: {
    'Ref: Carousel title': 'Renginiai',
    'No info yet, come back later': 'Dar nėra informacijos, grįžk vėliau 😉',
  },
};
export default labels;
