import { WIDGET_TYPE } from '../../../constants';
import { WidgetType } from '../../../types';

import ContentWidget from './ContentWidget';
import PromotionsWidget from './PromotionsWidget';

export const widgets: WidgetType[] = [
  { id: 'ContentWidget', type: WIDGET_TYPE.NORMAL, component: ContentWidget },
  { id: 'PromotionsWidget', type: WIDGET_TYPE.MINI, component: PromotionsWidget },
];
