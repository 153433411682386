import { screen, cleanup, fireEvent, act } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';

import { APP_DISPLAY_NAME } from '../../../../constants';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { pagePaths } from '../../config';

import AccountDelete from './AccountDelete';

const deleteAccountFn = jest.fn();

const mockHistory = createMemoryHistory();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
}));

const mockDispatch = jest.fn();
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockDispatch,
}));

let httpStatusCode = 200;
const mockDeleteAccount = async () => {
  deleteAccountFn();
  return httpStatusCode < 210
    ? {
        data: {
          httpStatusCode: httpStatusCode,
        },
      }
    : {
        error: {
          httpStatusCode: httpStatusCode,
        },
      };
};

jest.mock('../../api/account/accountApi', () => ({
  useDeleteAccountMutation: () => {
    return [mockDeleteAccount];
  },
}));

const DELETE_WORD = 'delete';

describe('AccountDelete', () => {
  const env = global.process.env;

  afterAll(() => cleanup());

  describe('On deleting account', () => {
    beforeAll(() => {
      global.process.env = {
        ...env,
        REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY,
        REACT_APP_PORTAL_ENV_CODE: 'prod',
      };
    });
    describe('Type wrong confirmation word', () => {
      let deleteAccount: HTMLElement;
      let input: HTMLElement;
      renderComponent(AccountDelete);
      beforeEach(async () => {
        deleteAccount = screen.getByTestId('button-action-primary');
        await act(async () => {
          fireEvent.click(deleteAccount);
        });

        input = screen.getByTestId('delete-account-confirm-text-hook-input-field');
      });

      it('Should not activate confirm button', async () => {
        await userEvent.type(input, 'WRONG WORD');
        expect(screen.getByTestId('button-action-primary')).toBeTruthy();
      });
    });

    describe('Type correct confirmation word', () => {
      let deleteAccount: HTMLElement;
      let input: HTMLElement;
      renderComponent(AccountDelete);
      beforeEach(async () => {
        deleteAccount = screen.getByTestId('button-action-primary');
        await act(async () => {
          fireEvent.click(deleteAccount);
        });

        input = screen.getByTestId('delete-account-confirm-text-hook-input-field');
      });

      it('Should activate confirm button', async () => {
        await userEvent.type(input, DELETE_WORD);
        expect(screen.getByTestId('button-action-primary')).toBeTruthy();
      });

      describe('Removing account', () => {
        beforeEach(async () => {
          await userEvent.type(input, DELETE_WORD);
        });

        it('removing account with 200 server response code', async () => {
          httpStatusCode = 200;

          await act(async () => {
            const form = screen.getByTestId('form');
            fireEvent.submit(form);
          });

          expect(deleteAccountFn).toHaveBeenCalled();
          expect(mockDispatch).toHaveBeenCalled();
          expect(mockHistory.location.pathname).toBe(pagePaths.Home);
        });

        it('removing account with 202 server response code', async () => {
          httpStatusCode = 202;

          await act(async () => {
            const form = screen.getByTestId('form');
            fireEvent.submit(form);
          });

          expect(deleteAccountFn).toHaveBeenCalled();
        });

        it('removing account with another server response code', async () => {
          httpStatusCode = 422;

          await act(async () => {
            const form = screen.getByTestId('form');
            fireEvent.submit(form);
          });

          expect(deleteAccountFn).toHaveBeenCalled();
          expect(mockHistory.location.pathname).toBe(pagePaths.AccountDeletionFailure);
        });
      });
    });
  });
});
