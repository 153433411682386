import classNames from 'classnames';

import Button from '../../../../components/atoms/Button';
import Loader from '../../../../components/atoms/Loader';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';

import { SubmitButtonProps } from './SubmitButton.types';

import styles from './SubmitButton.module.css';

const SubmitButton = ({ isLoading, isValidForm = true, label }: SubmitButtonProps) => {
  return (
    <ActionsBar className={classNames(styles.wrapper)}>
      <Button
        data-testid="audit-submit"
        className={classNames(styles.button)}
        type="submit"
        disabled={isLoading || !isValidForm}
      >
        {isLoading ? <Loader data-testid="submit-button"/> : label('Ref: submit')}
      </Button>
    </ActionsBar>
  );
};

export default SubmitButton;
