import dspApi from '../../../../services/api/dspApi';
import mockResponse from '../../mocks/api';

import { GetVendorTokenPostArgs, GetVendorTokenResponse } from './vendorApi.types';

const vendorApi = dspApi.enhanceEndpoints({ addTagTypes: ['vendor'] }).injectEndpoints({
  endpoints: (build) => ({
    /**
     * POST /v1/vendor/{vendorCode}/token
     */
    postVendorCode: build.mutation<GetVendorTokenResponse, GetVendorTokenPostArgs>({
      query: ({ vendorCode, userId, email, firstName, lastName }) => ({
        url: `/v1/vendor/${vendorCode}/token`,
        method: 'post',
        data: { id: userId, email, firstName, lastName },
        mockData: mockResponse.wiboToken,
      }),
      invalidatesTags: ['vendor'],
    }),
  }),
  overrideExisting: true,
});

export const { usePostVendorCodeMutation } = vendorApi;
export default vendorApi;
