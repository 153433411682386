import { CachedAttachment, FormFields, SrlTutorial } from '@/modules/ServiceRequestLight/types';
import { CacheService } from '@/services/CacheService';
const tutorials_key = 'service_request_light_tutorial';
const service_request_light_key = 'service_request_light';

export const saveTutorial = (tutorial: SrlTutorial) => {
  localStorage.setItem(tutorials_key, JSON.stringify(tutorial));
};

export const getTutorial = () => {
  const cachedString = localStorage.getItem(tutorials_key);
  return cachedString ? JSON.parse(cachedString) : {};
};

export const removeSRLTutorial = () => {
  localStorage.removeItem(tutorials_key);
};

export const saveServiceRequestLight = async (requestLight: FormFields) => {
  if (requestLight.attachments?.length > 0) {
    await CacheService.getInstance().setValue(
      service_request_light_key,
      stringify({
        ...requestLight,
        attachments: requestLight.attachments.map((a) => ({
          data: a.data,
          name: a.file.name,
          size: a.file.size,
          type: a.file.type,
        })),
      })
    );
  } else {
    await CacheService.getInstance().setValue(service_request_light_key, stringify(requestLight));
  }
};

export const getServiceRequestLight = async () => {
  const cachedString = await CacheService.getInstance().getValue(service_request_light_key);
  const srlData = cachedString ? JSON.parse(cachedString) : {};
  if (srlData.attachments) {
    return {
      ...srlData,
      attachments: srlData.attachments.map((a: CachedAttachment) => ({
        data: a.data,
        file: {
          name: a.name,
          size: a.size,
          type: a.type,
        },
      })),
    };
  }
  return srlData;
};

export const removeServiceRequestLight = async () => {
  await CacheService.getInstance().remove(service_request_light_key);
};

function stringify(obj: any) {
  let cache = [] as any;
  let str = JSON.stringify(obj, function (key, value) {
    if (typeof value === 'object' && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
}
