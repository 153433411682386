import { noPrefix } from '../hooks/useCustomTranslation';

export default function generateTranslationKeys(
  translationKeyPrefix: string,
  translationKey: string
) {
  // skip prefix
  if (translationKeyPrefix === noPrefix) return [translationKey];

  // check key "ProductList.key1" in namespace "Order"
  const primaryTranslationKey = `${translationKeyPrefix}.${translationKey}`;

  // check key "All.key1" in namespace "Order"
  const secondaryTranslationKey = `All.${translationKey}`;

  // check key "key1" in fallback namespace translation file src/languages/<language>
  const fallbackTranslationKey = translationKey;

  return [primaryTranslationKey, secondaryTranslationKey, fallbackTranslationKey];
}
