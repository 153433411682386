const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'あなたの要求',
    'Ref: Summary': 'まとめ',
    'Ref: Request category': 'リクエストカテゴリ',
    'Ref: Person affected': 'このリクエストの影響を受けた人',
    'Ref: Your phone number': '電話番号',
    'Ref: Description': '説明',
    'Ref: Service Requests': 'サービスリクエスト',
    'Ref: Request Service': 'リクエストサービス',
    'Ref: Success title': 'あなたのリクエストが作成されました',
    'Ref: Error title': 'おっとっと！',
    'Ref: Error subtitle': 'エラーが発生しました。もう一度お試しください。',
    'Ref: Try again': 'もう一度やり直してください',
    'Ref: you pronoun': '（様）',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'サポートハブ',
    'Ref: Tutorial: tabname': 'リクエスト',
    'Ref: Tutorial: body':
      '私たちはあなたを助けるためにここにいます。エントリごとに1つのリクエストを記録してください。',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'サイト{siteName}でサービスを要求しています。',
    'Ref: Building': '建物',
    'Ref: Room': '部屋',
    'Ref: Description help text':
      'あなたがより詳細になればなるほど、私たちはあなたをより良くサポートすることができます。',
    'Ref: Next': '次',
    'Ref: Your request': 'あなたの要求',
    'Ref: PhoneNumber error msg': '電話番号は無効です',
    'Ref: Type at least 3 characters to search': '検索するには少なくとも 3 文字を入力してください',
    'Ref: No affected person results': '要求された基準を一致させる人はいません',
    'Ref: Required': '* 必須',
  },
  Summary: {
    'Ref: Location': '位置',
    'Ref: Attachment': '添付ファイル',
    'Ref: Submit': '提出する',
    'Ref: No affected person results': '該当者がいません',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'サービス要求',
    'Ref: createdOn': 'に作成されました',
    'Ref: summary': 'まとめ',
    'Ref: category': 'リクエストカテゴリ',
    'Ref: request raised for': 'このリクエストの影響を受けた人',
    'Ref: phoneNumber': '電話番号',
    'Ref: location': '電話番号',
    'Ref: description': '説明',
    'Ref: attachments': '添付ファイル',
  },
};
export default labels;
