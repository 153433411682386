import { FileSharer } from '@byteowls/capacitor-filesharer';

const SUPPORTED_IMAGE_MIMETYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/bmp',
];

const SUPPORTED_IMAGE_EXTENSIONS = ['.png', '.jpg', '.jpeg', '.gif', '.bmp'];

export const hasImageExtension = (fileName: string) =>
  SUPPORTED_IMAGE_EXTENSIONS.some((extension) => fileName.includes(extension));

export const isImage = (file: File) => {
  const mimeType = (file as Blob).type;
  return !!(SUPPORTED_IMAGE_MIMETYPES.includes(mimeType) || hasImageExtension(file.name));
};

export const getFileTypeByBase64 = (base64String: string): { ext: string; mime: string } | null => {
  const signatures = {
    JVBERi0: { ext: 'pdf', mime: 'application/pdf' },
    iVBORw0KGgo: { ext: 'png', mime: 'image/png' },
    '/9j/': { ext: 'jpg', mime: 'image/jpeg' },
    'PCFET0NUWVBFIGh0bWw+': { ext: 'html', mime: 'text/html' },
    PCFET0NUWVBFIGh0bWw: { ext: 'html', mime: 'text/html' },
    PCFET0NUWVBFI: { ext: 'html', mime: 'text/html' },
    DQo8aHRtbD4NCiAgICA8: { ext: 'html', mime: 'text/html' },
    'PGh0bWw+': { ext: 'html', mime: 'text/html' },
    PGh0bWw: { ext: 'html', mime: 'text/html' },
    DQo8: { ext: 'html', mime: 'text/html' },
    DQo: { ext: 'html', mime: 'text/html' },
  };

  for (var s in signatures) {
    if (base64String.indexOf(s) === 0) {
      return signatures[s as keyof typeof signatures];
    }
  }

  return null;
};

export const downloadBase64asFile = (
  base64Content: string,
  filename: string,
  type: string | null = null
): void => {
  const base64Parts = base64Content.split(',');
  const contentIndex = base64Parts.length <= 1 ? 0 : 1;
  const base64Data = base64Parts[contentIndex].trim();
  const fileType = getFileTypeByBase64(base64Data);

  FileSharer.share({
    filename,
    base64Data,
    contentType: type ?? fileType?.mime ?? 'application/octet-stream',
  });
};
