import { useMemo } from 'react';

import { ShareIcon, StarFilledIcon, StarOutlinedIcon } from '../../../../assets';
import { ThumbsUpIcon } from '../../../../assets/icons';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import ReactionsSummary from '../../../../components/molecules/ReactionsSummary/ReactionsSummary';
import SecondaryActions from '../../../../components/molecules/SecondaryActions/SecondaryActions';
import Reactions from '../../../../components/organisms/Reactions';
import useShareModal from '../../../../helpers/hooks/useShareModal';
import { reactionsWithLabels } from '../../../../helpers/reactions.helpers';
import { useToggleInterestMutation } from '../../api';
import { eventsConfig } from '../../config';
import { useToggleReaction } from '../../hooks';
import { useEventTranslation } from '../../hooks/useEventTranslation';

import { EventReactionsProps } from './EventReactions.types';

import useSite from '@/modules/Core/hooks/useSite';

import styles from './EventReactions.module.css';

const EventReactions = ({ eventDetails }: EventReactionsProps) => {
  const { label } = useEventTranslation('EventDetails');
  const { isInterestEnabled, isReactionsEnabled } = eventsConfig();
  const [toggleInterest] = useToggleInterestMutation();
  const [toggleReaction] = useToggleReaction();
  const { id: siteId } = useSite({ throwWhenNoActiveSite: true })!;
  const { shareOptionsModal, triggerShareModal } = useShareModal({ label });

  const reactions = useMemo(() => {
    return reactionsWithLabels(label, eventDetails?.reactions?.reactions);
  }, [eventDetails?.reactions?.reactions, label]);

  const eventReactions = eventDetails?.reactions
    ? eventDetails.reactions
    : { reactions: [], total: 0, me: null };

  return (
    <div className={styles.reactionsWrapper}>
      {isReactionsEnabled && (
        <ReactionsSummary {...eventReactions} className={styles.reactionsSummary} />
      )}
      <SecondaryActions className={styles.noRadius}>
        {isReactionsEnabled && (
          <Reactions.Detail
            key={`${eventDetails?.id}_${eventDetails?.reactions?.me}`}
            userReaction={eventDetails?.reactions.me}
            reactions={reactions}
            handleReactionClick={(reaction) => toggleReaction(reaction, eventDetails, siteId ?? '')}
            mainButtonValues={{
              icon: ThumbsUpIcon,
              label: label('Ref: Reaction - LIKE'),
            }}
          />
        )}
        <Button
          look={BUTTON_LOOK.TERTIARY}
          onClick={() =>
            triggerShareModal(eventDetails?.name, `/events/${eventDetails?.id}/details`)
          }
          contentCenterAlign={true}
          affix={() => <ShareIcon />}
          data-testid="share-button"
        >
          {label('Ref: Share', { textTransform: 'capitalize' })}
        </Button>
        {isInterestEnabled && (
          <Button
            look={BUTTON_LOOK.TERTIARY}
            onClick={() =>
              toggleInterest({
                eventId: eventDetails.id,
                isUserInterested: eventDetails.isUserInterested,
                siteId,
              })
            }
            contentCenterAlign={true}
            affix={() =>
              eventDetails?.isUserInterested ? <StarFilledIcon /> : <StarOutlinedIcon />
            }
            data-testid="bookmark-button"
            id="events-bookmark-button"
            analyticsKey="events-bookmark-button"
          >
            {label('Ref: Favorite')}
          </Button>
        )}
      </SecondaryActions>
      {shareOptionsModal}
    </div>
  );
};

export default EventReactions;
