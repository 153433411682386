import { axiosBaseQuery } from '../../../axios/axiosBaseQuery';
import { dspApiPath } from '../../../config';
import mockResponse from '../mockResponse';
import { ContactType } from '../types/apiResponse.types';

import serviceRequestApi from './api';
import { cleanLocations } from './api.helper';
import {
  CommentServiceRequestParams,
  GetLocationsParams,
  GetServiceRequestSiteContactsParams,
} from './api.types';

import { LocationProps, ServiceRequestProps } from '@/modules/ServiceRequest/types/types';
import dspApi from '@/services/api/dspApi';

const dspServiceRequestApi = dspApi
  .enhanceEndpoints({ addTagTypes: ['service_requests', 'service_requests_locations'] })
  .injectEndpoints({
    endpoints: (build) => ({
      /**
       * PUT /v1/servicerequests/{id}/comment - add SR Comment
       * IFM Only
       */
      serviceRequestComment: build.mutation<ServiceRequestProps[], CommentServiceRequestParams>({
        query: ({ serviceRequestId, commentBody, images }) => ({
          url: `/v1/servicerequests/${serviceRequestId}/comment`,
          method: 'put',
          data: {
            serviceRequestId,
            comment: commentBody,
            attachments: images,
          },
        }),
        async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
          const nowDateTime = new Date();
          const patchDetailsResult = dispatch(
            serviceRequestApi.util.updateQueryData(
              'getServiceRequest',
              { id: patch.serviceRequestId, emailId: patch.emailId },
              (draft) => {
                draft.messages?.unshift({
                  description: patch.commentBody,
                  createdBy: patch.userName,
                  isCreatedByUser: true,
                  createdOn: new Date(nowDateTime.setMilliseconds(0)).toJSON(),
                  attachments: patch.images.map((image) => ({ ...image, id: '#' })),
                });
              }
            )
          );

          try {
            await queryFulfilled;
          } catch {
            queryFulfilled.catch(patchDetailsResult.undo);
          }
        },
        // We stopped using invalidateTags as there is a delay on BE side to update the SR comments. Instead we are using the onQueryStarted to update the SR details. Ref PR: https://azeuvs1aze987.visualstudio.com/IFM/_git/MyWay-React/pullrequest/12787
      }),
      /**
       * GET '/v1/servicerequests' - get service requests contacts
       */
      getSiteContacts: build.query<ContactType[], GetServiceRequestSiteContactsParams>({
        queryFn: async ({ search, pageSize }) => {
          const baseQuery = axiosBaseQuery({ baseUrl: dspApiPath });
          const contactsResponse = await baseQuery({
            url: '/v1/servicerequests/contacts',
            method: 'get',
            params: {
              search,
              pageSize,
            },
            mockData: mockResponse.getSiteContacts,
          });

          const { data: { data } = {} } = contactsResponse;

          if (!data) return { data: [] };

          return {
            data,
          };
        },
        providesTags: ['service_requests'],
      }),
      /**
       * GET /v1/servicerequests/locationsearch - search locations
       * IFM Only
       */
      getLocations: build.query<LocationProps[], GetLocationsParams>({
        queryFn: async ({ siteId }: GetLocationsParams) => {
          const baseQuery = axiosBaseQuery({ baseUrl: dspApiPath });
          const locationsResponse = await baseQuery({
            url: '/v1/servicerequests/locations',
            method: 'get',
            params: {
              siteId,
            },
            mockData: mockResponse.searchLocations,
          });

          const { data: locations = [] } = locationsResponse;

          if (!locations) return { data: [] };
          const searchableLocations = locations?.reduce(cleanLocations, []) || [];
          return {
            data: searchableLocations,
          };
        },
        keepUnusedDataFor: 3600 * 12, // cache locations data for 12 hours
        providesTags: (result, error, { siteId }) => [
          { type: 'service_requests_locations', siteId },
        ],
      }),
    }),

    overrideExisting: true,
  });

export const {
  useGetLocationsQuery,
  useLazyGetLocationsQuery,
  usePrefetch,
  useServiceRequestCommentMutation,
  useGetSiteContactsQuery,
} = dspServiceRequestApi;
export default dspServiceRequestApi;
