import { useEffect } from 'react';

import { getAppDisplayName } from '../../../helpers/misc';

const useDynamicTabName = (title: string | undefined, withoutAppName?: boolean | undefined) => {
  useEffect(() => {
    if (!withoutAppName ?? false)
      document.title = title ? `${title} - ${getAppDisplayName()}` : getAppDisplayName();
    else document.title = `${title}`;
  }, [title, withoutAppName]);
};

export default useDynamicTabName;
