import { Storage, Drivers } from '@ionic/storage';

type QueueItem = {
  k: string;
  v: QueueItemValue;
};

type QueueItemValue = {
  url: string;
  method: string;
  data: any;
};

export class OfflineQueueService {
  public storage: Storage;
  static instance: OfflineQueueService | undefined;
  public debug = false;

  constructor(debug = false) {
    this.storage = new Storage({
      name: 'offline_queue',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    });

    this.storage.create();
    this.debug = debug;
  }

  async getAll(): Promise<QueueItem[]> {
    const items: any[] = [];

    return new Promise((resolve) => {
      this.storage
        .forEach((v: any, k: any) => {
          items.push({ k, v });
        })
        .then(() => {
          resolve(items);
        });
    });
  }

  async getValue(key: string): Promise<QueueItemValue> {
    const item = await this.storage.get(key);
    return item;
  }

  async setValue(key: string, value: any) {
    return this.storage.set(key, value);
  }

  async remove(key: string) {
    return this.storage.remove(key);
  }

  async flush() {
    return this.storage.clear();
  }

  static getInstance(): OfflineQueueService {
    if (!this.instance) {
      this.instance = new OfflineQueueService();
    }

    return this.instance;
  }
}
