import { Network } from '@capacitor/network';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';

import { apiHost, dspApiPath } from '../config';
import { ONE_YEAR_TIMESTAMP, LOCAL_STORAGE_KEYS } from '../constants';
import { saveTokens } from '../modules/Core/helpers/tokensHelpers';
import { CacheService } from '../services/CacheService';
import { OfflineQueueService } from '../services/OfflineQueueService';
import { store } from '../store';

import { refreshAccessTokenAxios } from './tokenRefreshApi';

const enum ApiType {
  Ifm,
  Bite,
  DspApi,
}

export const accessTokenInterceptor = async (config: AxiosRequestConfig) => {
  const { connected } = await Network.getStatus();
  if (!connected) return config;
  const withoutAccessToken = config?.opts?.withoutAccessToken;
  if (withoutAccessToken) {
    return config;
  }

  let accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  const accessTokenValidTill = +(
    localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_VALID_UNTIL) ?? ''
  );
  const validityLimit = accessTokenValidTill - 5000; //for slow networks, keep 5s of lag
  const isAccessTokenValid = accessToken && validityLimit >= Date.now();

  if (!isAccessTokenValid) {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    if (refreshToken) {
      const tokenResponse = await refreshAccessTokenAxios(refreshToken!);
      saveTokens(tokenResponse);
      accessToken = tokenResponse.access_token;
    }
  }

  if (accessToken) {
    // @ts-expect-error // https://github.com/axios/axios/issues/5034
    config.headers = {
      ...config?.headers,
      authorization: `Bearer ${accessToken}`,
    };
  }

  return config;
};

export const commonHeadersInterceptor = (config: AxiosRequestConfig) => {
  const currentLanguage = store.getState().Shared.language.currentLanguageCode;

  // @ts-expect-error // https://github.com/axios/axios/issues/5034
  config.headers = {
    ...config?.headers,
    'accept-language': currentLanguage,
  };

  const currentRegionCode = localStorage.getItem(LOCAL_STORAGE_KEYS.CURRENT_REGION_CODE);
  if (currentRegionCode) {
    // @ts-expect-error // https://github.com/axios/axios/issues/5034
    config.headers = {
      ...config?.headers,
      RegionCode: currentRegionCode,
    };
  }

  const apiType = determineApiType(config.url);
  switch (apiType) {
    case ApiType.Ifm:
      config = {
        ...config,
        // @ts-expect-error // https://github.com/axios/axios/issues/5034
        headers: {
          ...config.headers,
          'ocp-apim-subscription-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
        params: {
          ...config.params,
          'subscription-key': process.env.REACT_APP_SUBSCRIPTION_KEY,
        },
      };
      break;
    case ApiType.DspApi:
      config = {
        ...config,
        // @ts-expect-error // https://github.com/axios/axios/issues/5034
        headers: {
          ...config.headers,
          'ocp-apim-subscription-key': process.env.REACT_APP_DSP_SUBSCRIPTION_KEY,
        },
        params: {
          ...config.params,
          'subscription-key': process.env.REACT_APP_DSP_SUBSCRIPTION_KEY,
        },
      };
      break;
  }

  return config;
};

export const serializeBodyInterceptor = (config: AxiosRequestConfig) => {
  // @ts-expect-error // https://github.com/axios/axios/issues/5034
  const contentType = config.headers?.getContentType();

  if (contentType?.indexOf('application/x-www-form-urlencoded') !== -1 && config.data) {
    // https://axios-http.com/docs/urlencoded
    const params = new URLSearchParams();

    Object.keys(config.data).forEach((key) => {
      params.append(key, config.data[key]);
    });

    config.data = params;
  }

  return config;
};

export const offlineRequestInterceptor = async (config: AxiosRequestConfig) => {
  const { url, method, opts = {} } = config;
  const { connected } = await Network.getStatus();

  if (opts.offlineMode && !connected && url) {
    if (method === 'get') {
      const adapter = function (config: AxiosRequestConfig) {
        return new Promise<any>(async (resolve) => {
          const cache = CacheService.getInstance();

          const res = {
            data: await cache.getValue(url),
            status: 200,
            statusText: 'OK',
            headers: {
              ...config?.headers,
            },
            config,
            request: {},
          };

          return resolve(res);
        });
      };

      return {
        ...config,
        adapter,
      };
    }

    if (method === 'post' || method === 'patch') {
      const offlineQueue = OfflineQueueService.getInstance();

      let id = config.url?.substring(config.url?.lastIndexOf('/') + 1);
      const uuidPattern =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      if (!id || !uuidPattern.test(id)) {
        id = uuid().toString();
      }
      await offlineQueue.setValue(id, {
        url: config.url,
        method: config.method,
        data: config.data,
      });

      const adapter = function (config: AxiosRequestConfig) {
        return new Promise<any>((resolve) => {
          const res = {
            data: { id },
            status: 200,
            statusText: 'OK',
            headers: {
              ...config.headers,
            },
            config,
            request: {},
          };

          return resolve(res);
        });
      };

      return {
        ...config,
        adapter,
      };
    }
  }

  return config;
};

export const cacheResponseInterceptor = async (response: AxiosResponse) => {
  const { config } = response;
  const { url, opts = {}, method } = config;

  if (opts.offlineMode && method === 'get') {
    const cache = CacheService.getInstance();
    if (url) cache.setValue(url, response.data, ONE_YEAR_TIMESTAMP);
  }

  return response;
};

const determineApiType = (url?: string) => {
  if (dspApiPath && url?.toLowerCase().startsWith(dspApiPath)) return ApiType.DspApi;

  if (apiHost && url?.toLowerCase().startsWith(apiHost)) return ApiType.Ifm;

  throw new Error(`Url: ${url}, not supported`);
};
