const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Audits',
    'Ref: status0': 'Offen',
    'Ref: status1': 'Vollendet',
    'Ref: status2': 'Abgesagt',
    'Ref: connected': 'In Verbindung gebracht',
    'Ref: not connected': 'Nicht verbunden',
    'Ref: synchronise data': 'Daten synchronisieren',
    'Ref: sync success': 'Daten erfolgreich synchronisiert',
    'Ref: Create new': 'Erstelle neu',
    'Ref: Audit unsynchronised': 'Unsynchronisiert',
    'Ref: Audit synchronised': 'Synchronisiert',
    'Ref: Unsynced error title': 'Ein Fehler ist aufgetreten',
    'Ref: Unsynced error description':
      'Es scheint Ihr erstes Mal auf das Audits -Modul zugreifen zu können. Bitte stellen Sie eine Verbindung zum Netzwerk her und "synchronisieren", um mit der Prüfung zu beginnen.',
    'Ref: Search all audits': 'Alle Audits',
    'Ref: Search active audits': 'Offen',
    'Ref: Search completed audits': 'Vollendet',
    'Ref: sync error': 'Die Synchronisation schlug fehl',
    'Ref: sync error details': 'Wiederholen, um sie wieder zu verbinden',
    'Ref: no search audit results': 'Leider gibt es keine Audits, die Ihrer Suche entsprechen',
    'Ref: no audit results': 'Sie haben keine verfügbaren Audits, erstellen neue, um loszulegen',
    'Ref: Search canceled audits': 'Abgesagt',
    'Ref: Audit cancelled successfully': 'Audit erfolgreich storniert',
    'Ref: Cancel audit invite': 'Stornieren',
    'Ref: Error audit cancel': 'Audit konnte nicht storniert werden',
    'Ref: Cancel audit confirmation': 'Sind Sie sicher, dass Sie das Audit stornieren möchten?',
    'Ref: Confirm': 'Bestätigen',
    'Ref: Cancel audit': 'Audit abbrechen',
    'Ref: Change the location': 'Ändern Sie den Standort',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Prüfdetails',
    'Ref: questions': 'Fragen',
    'Ref: question': 'Frage',
    'Ref: Additional Comments': 'Beschreibung',
    'Ref: Not found title': 'Hoppla!',
    'Ref: Not found text': 'Die Prüfung, auf die Sie zugreifen möchten, gibt es nicht.',
    'Ref: Back to Audit list': 'Zurück zur Prüfungsliste',
    'Ref: Success title': 'Danke schön!',
    'Ref: Success text': 'Sie haben das Audit abgeschlossen',
    'Ref: submit': 'Einreichen',
    'Ref: add attachment': 'Fügen Sie einen Anhang hinzu',
    'Ref: Unsupported elements':
      'Entschuldigung, dieses Audit hat Inhalte, die die Anwendung noch nicht unterstützt. Wir werden daran arbeiten.',
    'Ref: form error': 'Nicht alle Fragen wurden vollständig beantwortet',
    'Ref: form complete': 'Seitdem fertiggestellt',
    'Ref: attachment preview error': 'Sie müssen online sein, um diesen Anhang zu sehen',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Erstelle neu',
    'Ref: Location': 'Standort',
    'Ref: Audit Stream Type': 'Strom',
    'Ref: Audit': 'Prüfung',
    'Ref: Asset': 'Anlage',
    'Ref: Asset Name': 'Vermögensname',
    'Ref: Is joint audit?': 'Ist gemeinsame Prüfung?',
    'Ref: Who was present?': 'Wer war anwesend?',
    'Ref: submit': 'Einreichen',
    'Ref: Select the Location': 'Wählen Sie den Ort aus',
    'Ref: Select the Audit Stream Type': 'Wählen Sie den Audit -Stream -Typ aus',
    'Ref: Select the Audit': 'Wählen Sie die Prüfung aus',
    'Ref: Select the Asset': 'Wählen Sie den Vermögenswert aus',
    'Ref: form error': 'Nicht alle erforderlichen Felder wurden vollständig eingegeben',
    'Ref: Audit error': 'Beigefügte Prüfung',
    'Ref: Joint Audit Participants error': 'Gemeinsame Prüfungsteilnehmer',
    'Ref: Audit created': 'Audit erstellt',
    'Ref: Audit field length error': 'Die Länge sollte 100 Zeichen nicht überschreiten',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Ändern Sie den Standort',
    'Ref: Location changed': 'Standort geändert',
    'Ref: Location': 'Standort',
    'Ref: Select the Location': 'Wählen Sie den Ort aus',
    'Ref: Asset': 'Anlage',
    'Ref: Select the Asset': 'Wählen Sie den Vermögenswert aus',
    'Ref: Asset Name': 'Vermögensname',
    'Ref: submit': 'Einreichen',
    'Ref: Audit field length error': 'Die Länge sollte 100 Zeichen nicht überschreiten',
  },
};
export default labels;
