const labels = {
  ContentDetails: {
    'Ref: Page title': 'Detalhes do conteúdo',
    'See the event': 'Veja o evento',
    'Take the survey': 'Participar da pesquisa',
    Date: 'Encontro',
    Category: 'Categoria',
    'Ref: Content site different title': 'Este conteúdo não pertence ao seu site atual',
    'Ref: Content site different description':
      'Você está visualizando um conteúdo que não pertence ao seu site atual. Você não poderá vê -lo na lista de conteúdo.',
  },
  ContentList: {
    'Ref: Page title': 'Notícias',
    'featured (as in featured content)': 'Destaque',
    'Ref: category - all': 'Tudo',
    'Ref: category - promotional': 'Promocional',
    'Ref: category - featured': 'Destaque',
    'Ref: category - other': 'Outro',
  },
  Widget: {
    'Ref: Carousel title': 'Notícias e Artigos',
    'Ref: Carousel title promo': 'Manchetes',
    'No info yet, come back later': 'Ainda não há informação, volte mais tarde 😉',
    'Ref: Content': 'Contente',
    'Ref: Widget Title': 'Notícias',
    'Ref: MyVillage Carousel title promo': 'Promoções',
  },
};
export default labels;
