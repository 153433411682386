import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import QrCode from './QrCode';

const MyBus = () => {
  return (
    <div>
      <Switch>
        <Route exact path={pagePaths.Module} children={<QrCode />} />
      </Switch>
    </div>
  );
};

export default MyBus;
