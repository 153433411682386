import mockData from '../mocks/menuApi';

import { Menu } from './menuApi.types';

import dspApi from '@/services/api/dspApi';

const menuApi = dspApi
  .enhanceEndpoints({ addTagTypes: ['menu'] })

  .injectEndpoints({
    endpoints: (builder) => ({
      getMenus: builder.query<Menu[], { siteId: string; menuDate: string }>({
        query: (params) => ({
          url: '/v1/menus',
          method: 'get',
          params,
          mockData: mockData.getMenus,
        }),
      }),
    }),
    overrideExisting: true,
  });

export const { useGetMenusQuery } = menuApi;

export default menuApi;
