import classNames from 'classnames';
import { FC } from 'react';

import { TitleBarIconProps } from '../TitleBar.types';

import styles from './TitleBarIcon.module.css';

const TitleBarIcon: FC<TitleBarIconProps> = ({
  icon,
  filled,
  position,
  keyText,
  'data-testid': dataTestId = 'titlebar-icon-',
}: TitleBarIconProps) => {
  const Icon = icon;
  return (
    <Icon
      className={classNames(styles.icon, { [styles.filled]: filled })}
      data-cy={'title-bar-icon-' + position + '-' + keyText}
      data-testid={dataTestId + position + '-' + keyText}
    />
  );
};

export default TitleBarIcon;
