import { ionFireEvent } from '@ionic/react-test-utils';
import { act, fireEvent, screen } from '@testing-library/react';

import AuditEditLocationForm from '../AuditEditLocationForm';

import { renderedComponent } from '@/helpers/tests/renderComponent';
import { useGetAuditSurveyQuery, useUpdateAuditInviteStateMutation } from '@/modules/Audits/api';
import { OfflineQueueService } from '@/services/OfflineQueueService';

const defaultProps = {
  label: (s: string) => s,
};

const auditInviteDetails = {
  workOrderId: '1ss567fb-c47a-4660-b957-838efdb95bda',
  inviteId: 'cad567fb-c47a-4660-b957-838e4fb95b1d',
  auditId: '0769031b-7911-ec11-b6e6-0022481131f6',
  auditName: 'L2-Care and Maintenance Property-Grounds',
  auditStream: 'Grounds Maintenance',
  workOrderNumber: '4191958',
};

const locations = [
  {
    id: 'ccd68a51-4a9f-e611-80f4-c4346bc52710',
    name: 'Anchorage',
  },
  {
    id: '47754440-a7d2-e911-a812-000d3a6aa0b3',
    name: 'Angelo River',
  },
  {
    id: 'fd87220c-8829-e611-80ec-a45d36fc5a4c',
    name: 'Boolgeeda Aerodrome',
  },
];

const assets = [
  {
    id: '8ae5b344-6355-e611-80f4-a45d36fc5a4c',
    name: ' Rivergum Drive - Store room 2',
    masterAssetName: null,
    locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
    assetLevel: 'Level 1',
  },
  {
    id: '8ce5b344-6355-e611-80f4-a45d36fc5a4c',
    name: ' Rivergum Drive - Store room external ',
    masterAssetName: null,
    locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
    assetLevel: 'Level 1',
  },
];

const updateAuditInviteState = jest.fn();

jest.mock('react-select', () => ({ 'data-testid': dataTestId, options, value, onChange }: any) => {
  function handleChange(event: any) {
    const option = options.find((option: any) => option.value === event.currentTarget.value);
    onChange(option);
  }
  return (
    <select
      id={dataTestId}
      name={dataTestId}
      data-testid={dataTestId}
      value={value || ''}
      onChange={handleChange}
    >
      {options.map(({ label, value }: any) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  );
});

jest.mock('../../../../../services/OfflineQueueService');

jest.mock('../../../api', () => ({
  ...jest.requireActual('../../../api'),
  useGetAssetsQuery: () => ({
    data: assets,
    isFetching: false,
  }),
  useGetAuditLocationsQuery: () => ({
    data: locations,
    isFetching: false,
  }),
  useGetAuditSurveyQuery: jest.fn(),
  useUpdateAuditInviteStateMutation: jest.fn(),
}));

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: () => ({ id: auditInviteDetails.inviteId }),
}));

describe('AuditEditLocationForm', () => {
  let offlineQueueMock: any;

  updateAuditInviteState.mockImplementation(
    () =>
      new Promise((resolve, reject) => {
        resolve({ data: null, error: undefined, isError: false });
      })
  );

  beforeEach(async () => {
    offlineQueueMock = {
      getValue: jest.fn(),
      getAll: jest.fn(),
    };

    (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

    (useGetAuditSurveyQuery as jest.Mock).mockReturnValue({
      data: auditInviteDetails,
      isFetching: false,
    });

    (useUpdateAuditInviteStateMutation as jest.Mock).mockReturnValue([updateAuditInviteState, {}]);
  });

  describe('Render', () => {
    it('Should correctly render all form elements', async () => {
      await act(async () => {
        renderedComponent(AuditEditLocationForm, defaultProps);
      });

      const locationSelect = screen.getByTestId('locations-select');
      const assetSelect = screen.getByTestId('assets-select');
      const assetNameTextarea = screen.getByTestId('asset-name-textarea');
      const submitButton = screen.getByTestId('button-action-primary');

      expect(locationSelect).toBeInTheDocument();
      expect(assetSelect).toBeInTheDocument();
      expect(assetNameTextarea).toBeInTheDocument();
      expect(submitButton).toBeInTheDocument();
    });

    it('Should display skeletons when data is being fetched', async () => {
      (useGetAuditSurveyQuery as jest.Mock).mockReturnValue({
        data: {},
        isFetching: true,
      });

      await act(async () => {
        renderedComponent(AuditEditLocationForm, defaultProps);
      });

      expect(screen.getByTestId('skeleton-form')).toBeInTheDocument();
    });
  });

  describe('Form Interaction', () => {
    it('Should enable the asset select input when a location is selected', async () => {
      await act(async () => {
        renderedComponent(AuditEditLocationForm, defaultProps);
      });

      const locationSelect = screen.getByTestId('locations-select');
      const assetSelect = screen.getByTestId('assets-select');

      fireEvent.change(locationSelect, {
        target: { value: '47754440-a7d2-e911-a812-000d3a6aa0b3' },
      });

      expect(assetSelect).not.toBeDisabled();
    });

    it('Should enable the submit button only when the form is valid', async () => {
      await act(async () => {
        renderedComponent(AuditEditLocationForm, defaultProps);
      });

      const locationSelect = screen.getByTestId('locations-select');

      const submitButton: any = screen.getByTestId('button-action-primary');

      expect(submitButton['aria-disabled']).toBe(true);

      fireEvent.change(locationSelect, {
        target: { value: '47754440-a7d2-e911-a812-000d3a6aa0b3' },
      });

      expect(submitButton['aria-disabled']).toBe(false);
    });
  });

  describe('Form Submission', () => {
    it('Should show a length error message for the asset name upon form submission', async () => {
      await act(async () => {
        renderedComponent(AuditEditLocationForm, defaultProps);
      });

      const longAssetName = 'a'.repeat(101);
      const assetNameTextarea = screen.getByTestId('asset-name-textarea');

      await act(async () => {
        ionFireEvent.ionChange(assetNameTextarea, longAssetName);
        fireEvent.submit(screen.getByTestId('button-action-primary'));
      });

      expect(screen.getByTestId('asset-name-textarea-message')).toBeInTheDocument();
    });

    it('Should show a success notification upon successful form submission', async () => {
      await act(async () => {
        renderedComponent(AuditEditLocationForm, defaultProps);
      });

      await act(async () => {
        fireEvent.change(screen.getByTestId('locations-select'), {
          target: { value: '47754440-a7d2-e911-a812-000d3a6aa0b3' },
        });
      });

      await act(async () => {
        fireEvent.submit(screen.getByTestId('button-action-primary'));
      });

      expect(screen.getByRole('alert')).toBeInTheDocument();
      expect(updateAuditInviteState).toHaveBeenCalledWith({
        id: auditInviteDetails.inviteId,
        location: {
          assetId: '',
          assetName: '',
          locationId: '47754440-a7d2-e911-a812-000d3a6aa0b3',
          workOrderId: auditInviteDetails.workOrderId,
          workOrderLocation: 'Angelo River',
        },
      });
    });

    it('Should update local queue item when the Audit Invite is queued for creation', async () => {
      offlineQueueMock = {
        getValue: () => ({ method: 'post' }),
        getAll: jest.fn(),
        setValue: jest.fn(),
      };

      (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

      await act(async () => {
        renderedComponent(AuditEditLocationForm, defaultProps);
      });

      await act(async () => {
        fireEvent.change(screen.getByTestId('locations-select'), {
          target: { value: '47754440-a7d2-e911-a812-000d3a6aa0b3' },
        });
      });

      await act(async () => {
        fireEvent.submit(screen.getByTestId('button-action-primary'));
      });

      expect(screen.getByRole('alert')).toBeInTheDocument();
      expect(offlineQueueMock.setValue).toHaveBeenCalledWith(auditInviteDetails.inviteId, {
        data: {
          assetId: '',
          assetName: '',
          locationId: '47754440-a7d2-e911-a812-000d3a6aa0b3',
          workOrderId: auditInviteDetails.workOrderId,
          workOrderLocation: 'Angelo River',
        },
        method: 'post',
      });
    });

    it('Should remove any existing local queued update when submiting the Audit invite to the server', async () => {
      offlineQueueMock = {
        getValue: () => ({ method: 'patch' }),
        getAll: jest.fn(),
        setValue: jest.fn(),
        remove: jest.fn(),
      };

      (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

      await act(async () => {
        renderedComponent(AuditEditLocationForm, defaultProps);
      });

      await act(async () => {
        fireEvent.change(screen.getByTestId('locations-select'), {
          target: { value: '47754440-a7d2-e911-a812-000d3a6aa0b3' },
        });
      });

      await act(async () => {
        fireEvent.submit(screen.getByTestId('button-action-primary'));
      });

      expect(screen.getByRole('alert')).toBeInTheDocument();
      expect(offlineQueueMock.remove).toHaveBeenCalledWith(auditInviteDetails.inviteId);
    });
  });
});
