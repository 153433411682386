import classNames from 'classnames';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams, useLocation } from 'react-router-dom';

import { LOCAL_STORAGE_KEYS } from '../../../../constants';
import { EMOJIS } from '../../../../constants';
import { clearSiteIdBeforeChange } from '../../actions';
import { useLazyValidateQrCodeQuery, usePrefetch } from '../../api/index';
import { pagePaths } from '../../config';
import { useServiceRequestSetupOption } from '../../hooks/useServiceRequestSetupOption';
import { useServiceRequestTranslation } from '../../hooks/useServiceRequestTranslation';

import { DeviceWoman, WomanDesk2 } from '@/assets/illustrations';
import { ImageServiceRequest } from '@/assets/images';
import Button, { BUTTON_LOOK } from '@/components/atoms/Button';
import Title, { TITLE_SIZE, TITLE_TAG } from '@/components/atoms/Title';
import Notification, { NOTIFICATION_LOOK } from '@/components/molecules/Notification';
import ActionCard from '@/components/organisms/ActionCard';
import Column from '@/components/organisms/Column';
import Container from '@/components/organisms/Container';
import LoadingPage from '@/components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '@/components/templates/SimpleFormPage/SimpleFormPage';
import useLoginStatus from '@/helpers/hooks/useLoginStatus';
import userContextApi from '@/modules/Core/api/account/userContextApi';
import { pagePaths as corePagePaths } from '@/modules/Core/config';
import { State } from '@/types/state.types';

import styles from './QrLandingPage.module.css';

const QrLandingPage = () => {
  const { label } = useServiceRequestTranslation(__filename);
  const { isLoggedIn } = useLoginStatus();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [checkQrInfo] = useLazyValidateQrCodeQuery();
  const siteName = useSelector((state: State) => state.Core?.context.site?.name);
  const currentSiteId = useSelector((state: State) => state.Core?.context.site?.id ?? '');

  let { siteId, locationId } = useParams<{ siteId: string; locationId: string }>();
  const prefetchLocations = usePrefetch('getLocations');
  const [decodedSurveyLink, setDecodedSurveyLink] = useState<string | undefined>();
  const [isSiteChanged, setIsSiteChanged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [prevParams, setPrevParams] = useState<string>('');
  const queryParams = new URLSearchParams(location.search);
  const surveyLink = queryParams.get('surveyLink') ?? '';
  const redirected = queryParams.get('redirected') ?? false;

  const redirectToErrorPage = useCallback(() => {
    history?.push({
      pathname: '/error',
      state: { from: location, errorBody: label('Ref: Error body') },
    });
  }, [history, label, location]);

  const getQrInfo = useCallback(async () => {
    const { data, isSuccess } = await checkQrInfo({
      siteId,
      locationId,
      currentSiteId,
      dispatch,
      useErrorBoundary: false,
    });

    const qrInfoValid = isSuccess && data ? data.qrInfoValid : false;
    const siteChanged = isSuccess && data ? data.isSiteChanged : false;
    setIsSiteChanged(siteChanged);
    dispatch(userContextApi.util.invalidateTags(['userContext']));

    if (!qrInfoValid) {
      redirectToErrorPage();
    }
    if (redirected && qrInfoValid) {
      history?.push(
        `${pagePaths['QrCreate']}?isSiteChanged=${siteChanged}`
          .replace(':siteId', siteId)
          .replace(':locationId', locationId)
      );
    }
    setIsLoading(false);
  }, [
    checkQrInfo,
    currentSiteId,
    dispatch,
    redirectToErrorPage,
    locationId,
    siteId,
    history,
    redirected,
  ]);

  useEffect(() => {
    const newSiteLocationQrParams = siteId + locationId;
    if (isLoggedIn && newSiteLocationQrParams !== prevParams && currentSiteId !== '') {
      setPrevParams(newSiteLocationQrParams);
      getQrInfo().catch((err) => console.error(err));
    }

    if (!isLoggedIn) {
      setIsLoading(false);
    }
  }, [siteId, locationId, currentSiteId, isLoggedIn, getQrInfo, prevParams]);

  useEffect(() => {
    if (surveyLink) setDecodedSurveyLink(atob(surveyLink));
  }, [surveyLink]);

  useEffect(() => {
    if (siteId) {
      prefetchLocations({
        siteId: siteId,
      });
    }
  }, [siteId, prefetchLocations]);

  // Handle the case when user did not complete registration
  // then scans a QR code and needs to complete registration
  useEffect(() => {
    if (isLoggedIn && currentSiteId === '') {
      history.push('/home');
    }
  }, [isLoggedIn, currentSiteId, history]);

  const isSurveyLinkValid = useMemo(
    () => decodedSurveyLink?.includes('sodexo-diy-surveys.mcxplatform.de'),
    [decodedSurveyLink]
  );

  const dispatchclearSiteIdBeforeChange = useCallback(
    () => dispatch(clearSiteIdBeforeChange),
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatchclearSiteIdBeforeChange && dispatchclearSiteIdBeforeChange();
    };
  }, [dispatchclearSiteIdBeforeChange]);

  const disableShareFeedbackViaQr = useServiceRequestSetupOption('disableShareFeedbackViaQr');

  const requestRedirectionFunc = () => {
    if (isLoggedIn) {
      history?.push(
        pagePaths['QrCreate'].replace(':siteId', siteId).replace(':locationId', locationId)
      );
    } else {
      const fromUrl = pagePaths['QrLandingPage']
        .replace(':siteId', siteId)
        .replace(':locationId', locationId);
      localStorage.setItem(LOCAL_STORAGE_KEYS.RETURN_URL, fromUrl);
      history?.replace(corePagePaths.Login, {
        from: { pathname: fromUrl, search: 'redirected=true' },
      });
    }
  };

  const surveyRedirectionFunc = () => {
    decodedSurveyLink ? window.open(decodedSurveyLink) : redirectToErrorPage();
  };

  const renderOnError = useCallback(() => {
    if (decodedSurveyLink && !isSurveyLinkValid) {
      redirectToErrorPage();
    }
  }, [decodedSurveyLink, redirectToErrorPage, isSurveyLinkValid]);

  renderOnError();

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <SimpleFormPage hasBackLink={false} withNavBar={false} hideAllWidgets>
      <Container.Centered>
        <Column.Main className={styles.mainColumn}>
          <div className={styles.mainContainer}>
            {isSiteChanged && siteName ? (
              <Notification
                look={NOTIFICATION_LOOK.SUCCESS}
                title={label('Ref: Notification title')}
                inheritStyle={classNames('mb-L')}
                dismissable
              >
                {label('Ref: Notification info', {
                  replace: { 'site name': siteName },
                })}
              </Notification>
            ) : null}
            <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
              {label('Ref: Welcome', {
                replace: { wave_emoji: String.fromCodePoint(EMOJIS.wave) },
              })}
            </Title>
            <ActionCard
              data-testid="create-service-request"
              onActionButtonClick={requestRedirectionFunc}
              text={label('Ref: Create service request text')}
              buttonText={label('Create a service request')}
              image={<WomanDesk2 />}
            />
            {!disableShareFeedbackViaQr && decodedSurveyLink ? (
              <ActionCard
                data-testid="share-feedback"
                onActionButtonClick={surveyRedirectionFunc}
                text={label('Ref: Share text')}
                buttonText={label('Ref: Share feedback')}
                image={<DeviceWoman />}
                reversedLayout
              />
            ) : null}
            <Button
              data-testid="go-to-home-action"
              look={BUTTON_LOOK.TERTIARY}
              aria-label={label('Go to home')}
              onClick={() => history?.push('/')}
            >
              {label('Go to home')}
            </Button>
          </div>
        </Column.Main>
        <Column.Complementary className={styles.sideImage}>
          <ImageServiceRequest />
        </Column.Complementary>
      </Container.Centered>
    </SimpleFormPage>
  );
};

export default QrLandingPage;
