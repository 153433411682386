import { act, cleanup, fireEvent, screen } from '@testing-library/react';
import { FormProvider, useForm } from 'react-hook-form';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import { FormFields } from '../../../types';
import Summary from '../Summary';

import { renderedComponent } from '@/helpers/tests/renderComponent';
import { defaultState as coreDefaultState } from '@/modules/Core/reducers/coreReducer';
import { useCreateRequestMutation } from '@/modules/ServiceRequestLight/api';

jest.mock('@/helpers/getSetupOption', () => ({
  __esModule: true,
  default: () => ({ isActive: true }),
}));

jest.mock('@/modules/ServiceRequestLight/api/api.ts', () => ({
  ...jest.requireActual('@/modules/ServiceRequestLight/api/api.ts'),
  useCreateRequestMutation: jest.fn(),
}));

const mockedState = {
  Core: {
    ...coreDefaultState,
    services: {
      list: [
        {
          name: 'ServiceRequestsLight',
          setupOptions: [
            {
              name: 'enableAffectedPerson',
              value: 'true',
              code: 'enableAffectedPerson',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'enableCategories',
              value: 'true',
              code: 'enableCategories',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
        },
      ],
    },
  },
};

const formValues = {
  title: 'Summary here',
  siteId: '',
  raisedForId: '',
  category: 'Category here',
  affectedPerson: {
    label: 'Jake Hass',
    value: '',
  },
  phoneNumber: '123456789',
  buildingText: '12',
  roomText: '33',
  description: 'Some description here',
  attachments: [],
};

const createValues = {
  title: 'Summary here',
  siteId: '',
  raisedForId: '',
  category: 'Category here',
  phoneNumber: '123456789',
  buildingText: '12',
  roomText: '33',
  description: 'Some description here',
  serviceHelpdeskEmail: '',
  applicationName: 'MyWay',
  siteLanguageIsoCode: '',
  attachments: [],
};

const CreateComponentTest = () => {
  const formMethods = useForm<FormFields>({
    mode: 'all',
    defaultValues: formValues,
  });
  return (
    <FormProvider {...formMethods}>
      <Summary />
    </FormProvider>
  );
};

describe('Summary', () => {
  let createRequestMock: jest.Mock;
  const env = global.process.env;
  afterAll(() => cleanup());

  beforeEach(async () => {
    global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
    createRequestMock = jest.fn().mockReturnValue({ error: {} });

    (useCreateRequestMutation as jest.Mock).mockReturnValue([
      createRequestMock,
      { isLoading: false },
    ]);

    await act(async () => {
      renderedComponent(CreateComponentTest, {}, mockedState);
    });
  });

  describe('Render', () => {
    it('should render', () => {
      const element = screen.queryByTestId('service-request-light-summary');
      expect(element).toBeTruthy();
    });

    it('should display form values', () => {
      expect(screen.getByText('Summary here')).toBeTruthy();
      expect(screen.getByText('Category here')).toBeTruthy();
      expect(screen.getByText('Jake Hass')).toBeTruthy();
      expect(screen.getByText('123456789')).toBeTruthy();
      expect(screen.getByText('12 / 33')).toBeTruthy();
      expect(screen.getByText('Some description here')).toBeTruthy();
    });
  });

  describe('Submit', () => {
    it('should create request', async () => {
      const button = screen.getByTestId('button-action-primary');

      await act(async () => {
        fireEvent.click(button);
      });

      expect(createRequestMock).toHaveBeenCalledWith(createValues);
    });
  });
});
