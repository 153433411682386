const labels = {
  All: {
    Feedback: 'ความคิดเห็น',
    'Ref: Switch site component intro sentence':
      'ความคิดเห็นของคุณจะถูกเชื่อมโยงกับไซต์ {site name}',
    Category: 'หมวดหมู่',
    Subject: 'ชื่อเรื่อง',
    Comment: 'ความคิดเห็น',
    'Date of your experience': 'วันที่ประสบการณ์ของคุณ *',
    'Ref: Hint': 'คุณมีปัญหาหรืองานที่ต้องการแก้ไขหรือไม่?',
    'Ref: Log Request': 'บันทึกคำขอ',
    'Ref: Describe issue': 'โปรดบอกเราเกี่ยวกับประสบการณ์ของคุณ',
    Date: 'วันที่',
    'Ref: Share your opinion header': 'แบ่งปันความคิดเห็นของคุณเกี่ยวกับประสบการณ์ของคุณในที่ทำงาน',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'ร้านอาหาร / คาเฟ่',
    'Desk / workstation area': 'พื้นที่โต๊ะ / เวิร์กสเตชัน',
    'Meeting room facilities': 'สิ่งอำนวยความสะดวกในห้องประชุม',
    'Coffee/tea facilities (pantry)': 'สิ่งอำนวยความสะดวกกาแฟ/ชา (ครัว)',
    'Restrooms / washrooms': 'ห้องน้ำ / ห้องน้ำ',
    'Reception / Security': 'แผนกต้อนรับ / ความปลอดภัย',
    'Conference center': 'ศูนย์การประชุม',
    'Fitness center': 'ศูนย์ออกกำลังกาย',
    Mailroom: 'ห้องจดหมาย',
    'Bicycle room': 'ห้องจักรยาน',
    'Shuttle services': 'บริการรถรับส่ง',
    Lockers: 'ตู้เก็บของ',
    Helpdesk: 'ผู้ช่วยเหลือ',
  },
  Create: {
    'Tell us!': 'บอกเรา!',
    'Ref: All Ears': 'เราทุกคนเป็นหู!',
    'Ref: Provide Description': 'โปรดระบุคำอธิบาย',
    'Ref: Fill-in-the-blank sentence': 'ฉันต้องการให้ {0} เกี่ยวกับ {1}',
    'Ref: Link text to service request module': 'คำขอหรือปัญหา? เข้าสู่ระบบที่นี่!',
    'Complaint type': 'การร้องเรียน {emoji}',
    'Feedback type': 'ข้อเสนอแนะ {emoji}',
    'Compliment type': 'คำชม {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'ข้อผิดพลาดที่เกิดขึ้นและไม่ได้ส่งข้อเสนอแนะของคุณ หากข้อผิดพลาดนี้ยังคงมีอยู่โปรดติดต่อฝ่ายช่วยเหลือของคุณ',
  },
  CreateSuccess: {
    'Ref: Body':
      'ส่งข้อเสนอแนะของคุณแล้ว ทีมสนับสนุนที่ทำงานของเราจะอ่านอย่างระมัดระวัง หากเหมาะสมเราจะกลับมาหาคุณ',
    'New feedback': 'ข้อเสนอแนะใหม่',
  },
  CreateFlow: {
    Description: 'คำอธิบาย',
    'Ref: Provide Description': 'โปรดระบุวันที่และคำอธิบาย',
    'Ref: I would like to provide a': 'ฉันต้องการให้ไฟล์',
    'Ref: in regards to': 'ในเรื่องที่เกี่ยวกับ',
    'Ref: &': '&',
    'Ref: at': 'ที่',
    'Ref: on the': 'บน',
  },
};
export default labels;
