import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { orderMenuPrefix, pagePaths, redirectMenuPrefix } from '../config';

const useAlterRedirect = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((x) => {
      const dineInOutletId = new URLSearchParams(x.search).get('dineInOutletId');
      if (x.pathname.match(redirectMenuPrefix) && !!dineInOutletId && history.action === 'POP') {
        history.replace(pagePaths.TableCheckInOutlet.replace(':outletId', dineInOutletId));
      }
      if (
        (x.pathname.match(orderMenuPrefix) || x.pathname.match(redirectMenuPrefix)) &&
        history.action === 'POP'
      ) {
        history.replace(pagePaths.Module);
      }
    });

    return () => unlisten();
  }, [history]);
};

export default useAlterRedirect;
