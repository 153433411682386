import classNames from 'classnames';
import { FC } from 'react';

import { DefaultComponentProps } from '../../../../types';

import styles from './Footer.module.css';

const WidgetFooter: FC<DefaultComponentProps> = ({
  children,
  className,
  'data-testid': dataTestId = 'widget-footer',
}) => {
  return (
    <footer className={classNames(styles.footer, className)} data-testid={dataTestId}>
      {children}
    </footer>
  );
};
WidgetFooter.displayName = 'Widget.Footer';
export default WidgetFooter;
