import React, { RefObject } from 'react';

import { TestingProps } from '../../../types';
import { ButtonProps } from '../../atoms/Button';

export enum NOTIFICATION_LOOK {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  MESSAGE = 'message',
  DETAILS = 'details',
}

export type Notification = {
  id: string;
  objectId?: string;
  contentId: string;
  contentType?: string;
  eventId: string;
  serviceId: string;
  surveyId?: string;
  title: string;
  linkUrl?: string;
  message: string;
  notificationDate: string;
  linkedEntity: string;
  surveyUrl?: string;
  acknowledged: boolean;
  acknowledgedOn: string;
};

export type NotificationProps = {
  look?: NOTIFICATION_LOOK;
  title: string;
  // Icon is applicable only if look is set to info, otherwise default Error / Success icons are used
  icon?: React.ReactNode;
  children?: React.ReactNode;
  isCollapsable?: boolean;
  actions?: NotificationActionProps[];
  img?: React.ReactNode;
  inheritStyle?: string;
  dismissable?: boolean;
  onDismiss?: React.MouseEventHandler<HTMLButtonElement> | void;
  dismissButtonRef?: RefObject<HTMLButtonElement>;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  TestingProps;

// Look and size are set within Notification comopnent
export type NotificationActionProps = Omit<ButtonProps, 'size'>;

export type NotificationIconType = Required<Pick<NotificationProps, 'look'>> &
  Pick<NotificationProps, 'icon'>;

export type NotificationTileProps = Notification & TestingProps;
