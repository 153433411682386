import { screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { act } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { Menu } from '../api/menuApi.types';
import { FacilityMenuItem } from '../types/menuSelector.types';

import MenuSelector from './MenuSelector';

import { renderedComponent } from '@/helpers/tests/renderComponent';

const menus: Menu[] = [
  {
    id: '3329',
    facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
    name: 'Bite Everyday',
    date: new Date().toISOString().split('T')[0],
    menuItems: [],
  },
  {
    id: '3330',
    facilityId: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
    name: 'Bite Weekends',
    date: new Date().toISOString().split('T')[0],
    menuItems: [],
  },
];

const facilityMenus: FacilityMenuItem[] = [
  {
    id: '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea',
    title: 'Mess Menu Facility',
    name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston',
    sequence: 0,
    type: 1,
    menu: menus[0],
  },
  {
    id: '5399e8af-c9ba-4b52-9f9a-3a4499a8948f',
    title: 'Mess Menu Facility 2',
    name: 'Mess Menu Facility - Sanofi Pharmaceutical - Sanofi Allston 2',
    sequence: 0,
    type: 1,
    menu: menus[1],
  },
];

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: jest.fn(),
}));

const mockUseFacilityMenuList = jest.fn();
jest.mock('../hooks/useFacilityMenuList', () => ({
  __esModule: true,
  default: () => mockUseFacilityMenuList(),
}));

const siteId = '123';
jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => ({
    id: siteId,
  }),
}));

// history
const mockPush = jest.fn();
const mockHistory = createMemoryHistory();
mockHistory.push = mockPush;

describe('MenuSelector', () => {
  beforeEach(() => {
    jest.resetAllMocks();
    jest.clearAllMocks();

    const mockDispatch = jest.fn();
    (useDispatch as jest.Mock).mockReturnValue(mockDispatch);
    (useParams as jest.Mock).mockImplementation(() => ({}));

    mockUseFacilityMenuList.mockReturnValue({
      facilityMenus: facilityMenus,
      isLoading: false,
    });
  });

  it('renders without crashing', () => {
    const { container } = renderedComponent(MenuSelector, {}, {}, createBrowserHistory());

    expect(container).toBeTruthy();
  });

  it('the user should be able to view a list of menus based on the selected site and date', async () => {
    renderedComponent(MenuSelector, {}, {}, createBrowserHistory());

    await waitFor(() => {
      expect(screen.getByText('Bite Everyday')).toBeInTheDocument();
      expect(screen.getByText('Bite Weekends')).toBeInTheDocument();
    });
  });

  it('user should be able to search for menus by the facility title.', async () => {
    await act(async () => {
      renderedComponent(MenuSelector, {}, {}, mockHistory || createBrowserHistory());
    });
    const input = await screen.findByTestId(
      'menu-selector-list-search-bar-list-page-search-filter-bar-input-field'
    );

    await act(async () => {
      userEvent.clear(input);
      await userEvent.type(input, 'Mess Menu Facility 2', { delay: 1 });
    });

    await waitFor(() => {
      expect(screen.queryByText('Bite Everyday')).not.toBeInTheDocument();
      expect(screen.queryByText('Bite Weekends')).toBeInTheDocument();
    });
  });

  it('user should be able to search for menus by the facility title - none of menus displayed when non-existing facility name typed', async () => {
    await act(async () => {
      renderedComponent(MenuSelector, {}, {}, mockHistory || createBrowserHistory());
    });
    const input = await screen.findByTestId(
      'menu-selector-list-search-bar-list-page-search-filter-bar-input-field'
    );

    await act(async () => {
      userEvent.clear(input);
      await userEvent.type(input, 'Mess Menu Facility 3', { delay: 1 });
    });

    await waitFor(() => {
      expect(screen.queryByText('Bite Everyday')).not.toBeInTheDocument();
      expect(screen.queryByText('Bite Weekends')).not.toBeInTheDocument();
    });
  });
});

afterEach(() => {
  jest.restoreAllMocks();
  jest.resetAllMocks();
  jest.clearAllMocks();
});
