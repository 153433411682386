const labels = {
  All: {
    Region: 'Region or country',
    'Ref: Geography': 'Please select your country or region',
    'Ref: Country': 'Country',
    'Ref: Country tooltip': 'The location corresponds to the site country.',
    'Ref: Please select your country': 'Please select your country',
    'First name': 'First Name',
    'Last name': 'Last Name',
    'Ref: Full name': 'Full name',
    'Mobile number': 'Mobile number',
    'Mobile phone': 'Mobile phone',
    Email: 'Email address',
    Password: 'Password',
    logout: 'Log out',
    'Not a new user? Log in': 'Not a new user? Login',
    'do not register, login': 'Not a new user? Login',
    'Ref: I agree': 'I agree',
    Register: 'Register',
    'Ref: My language': 'My language',
    'Ref: Kiosk Fail Title': 'Oops! Something went wrong!',
    'Ref: Kiosk Fail Description':
      'Unfortunately, your order was not successful. Please try again or ask a staff member to help you.',
    'Ref: Kiosk Fail Back': 'Back to the previous page',
    'Ref: Kiosk Fail Action': 'Clear the session and order again',
    Validate: 'Validate',
    'Ref: error, incorrect credentials': 'These credentials do not match in the chosen geography.',
    'Ref: Forgot password?': 'Forgot password?',
    'Ref: Hello': 'Hello {wave_emoji}, please login to continue',
    'Ref: Form title': 'Please sign up to continue',
    'Already have an account? Login': 'Already have an account? Login',
  },
  AccountPage: {
    'Ref: My Information': 'My Information',
    'Ref: Settings': 'Settings',
    'Ref: Email': 'Email',
    'Ref: Current site': 'Current site',
    'Ref: Choose site': 'Choose a site',
    'Ref: Personal interests': 'Personal interests',
    'Ref: Food preferences': 'Food preferences',
    'Ref: Password': 'Password',
    'Ref: Page title': 'Account',
    'Ref: Language': 'Language',
    'Ref: Communication preferences': 'Communication preferences',
    'Ref: Terms & conditions': 'Terms & conditions',
    'Ref: Privacy policy': 'Privacy policy',
    'Ref: Delete my account': 'Delete my account',
    'Ref: Confirm deletion': 'Are you sure you want to delete your account?',
    'Ref: Request deletion': 'Are you sure you want to request the deletion of your account?',
    'Ref: Deletion description': 'Your request will be handled according to your local regulation.',
    Placeholder: 'Write "delete"',
    'Ref: Delete description': 'Your data will be deleted. This action is irreversible.',
    delete: 'delete',
    'Ref: Terms of sale': 'Terms of sale',
    'Ref: Imprint': 'Imprint',
    'Ref: Cookie Settings': 'Cookie Settings',
    'Ref: Support': 'Support',
    'Ref: Register or login': 'Register or login',
    'Ref: Filtering Preferences': 'Filtering Preferences',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Are you sure to delete your account?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Write "{word}" in input field and confirm with the bottom below. This action is irreversible.',
    'Ref: Write "{word}"': 'Write "{word}"',
    'Ref: Delete my account': 'Delete my account',
    'Ref: Words are not matching': 'Words are not matching',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Choose your location for start',
    'Ref: Choose location':
      'Find quickly a location by activating the localization, scanning a QR code or searching by name',
    'Ref: next step': "Let's go",
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Sign Up',
    'Ref: identify your location': 'Please identify your location.',
    'Ref: option 1 - scan QR': 'Option 1: Scan the registration QR code provided',
    'Ref: option 2 - enter code': 'Option 2: Manually enter your location code',
    'Ref: button to open qr code scanner modal': "I have a QR: Let's scan it",
    'Ref: button to open site code input modal': 'Select site with code',
    'Ref: getting help with site identifier - support desk':
      'If you are not clear on how to proceed, please contact your support desk.',
    'Ref: getting help with site identifier - support form': 'You can also send us a note.',
    'Location code': 'Location code',
    'Ref: location code field placeholder': 'Your location code here',
    'Ref: Scanner header': 'Scanning a QR code',
    'Ref: site code input header': 'Add site with code',
    'Ref: not valid code': 'This code is not valid in the selected geography',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Registration',
    'Ref: collected info': 'Summary',
    'Ref: additional info': 'Your information',
    'Confirm password': '*Confirm Password',
    company: 'Who is your employer?',
    location: 'Location',
    username: 'username',
    register: 'Sign Up',
    'Ref: password strength':
      'Your password must be at least 8 characters long, contain at least one number, one uppercase, one lowercase and one special character.',
    'passwords do not match': 'Passwords do not match.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Communication Preferences',
    'Ref: Things happen while you are away': 'Things happen while you are away',
    'Ref: Would you like to receive update about offers and promotions?':
      'Would you like to receive updates about offers and promotions?',
    'Ref: Would you like to hear about content we think you may like?':
      'Would you like to hear about content we think you may like?',
    Yes: 'Yes',
    No: 'No',
    apply: 'apply',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Account details' },
  DeletionFailure: {
    'Ref: Body':
      'An error has occured and your request for account deletion was not submitted. If this error persists, please contact your helpdesk.',
    'Go to account': 'Go to account',
  },
  GenericFailurePage: {
    'Ref: Title': 'Something went wrong',
    'Ref: Body':
      'Sorry, something went wrong. If this issue persists, please contact your on-site support.',
  },
  HomePage: {
    notifications: 'notifications',
    basket: 'Basket',
    'Ref: Tabtitle': 'Home',
    'contact us': 'Contact Us',
    'Ref: You are logged in as a guest user': 'You are logged in as a guest user',
    'Ref: Good morning': 'Good morning',
    'Ref: Good afternoon': 'Good afternoon',
    'Ref: Good evening before midnight': 'Good evening',
    'Ref: Good evening after midnight': 'Good evening',
    'Ref: Search': 'Search',
    'Ref: products button aria': 'Found {results_form} within Products',
    'Ref: menus button aria': 'Found {results_form} within Menus',
    'Ref: facilities button aria': 'Found {results_form} within Facilities',
    'Ref: result': 'result',
    'Ref: results': 'results',
    'Ref: Menus': 'Menus',
    'Ref: No results title': 'Oops!',
    'Ref: No results body': 'No results matching your search',
    'Ref: Products': 'Products',
    'Ref: Facilities': 'Facilities',
    'Ref: See more results': 'See more search results',
    'Ref: Accessibility instructions':
      'To start using the search, you must click on it or press Enter while having it focused.',
    'Ref: See more': 'See more',
    'Ref: singular form of products': 'product',
    'Ref: singular form of menus': 'menu',
    'Ref: singular form of facilities': 'facility',
    'Ref: Search results for': 'Search results for',
  },
  LanguagePage: {
    apply: 'apply',
    'My language': 'My language',
    'Select in which language you would like to read our app':
      'Select in which language you would like to read our app',
    'Ref: Page title': 'Language',
  },
  LegalDocPage: {
    Acknowledge: 'Acknowledge',
    'Ref: Legal changed notification title': 'The “{legal_doc_type}” have been modified',
    'Ref: Legal changed notification content':
      'Please review them before continuing using the App.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Terms & Conditions',
    'Read privacy policy': 'Read the Privacy Policy',
    'Ref: Acknowledge legal docs': 'I accept these Terms and Conditions.',
    'Ref: Read and understood': 'I have read and understood ',
    'Ref: No legal docs available - notice title': 'Coming soon',
    'Ref: No legal docs available - notice body':
      'Sorry, it seems that this application is not currently available in your geography.',
    'Ref: The privacy Policy': 'the privacy policy',
    'Ref: Legal changed notification title': 'The “{legal_doc_type}” have been modified',
    'Ref: Legal changed notification content':
      'Please review them before continuing using the App.',
  },
  LoginPage: {
    'Ref: Page title': 'Welcome to {app name} ',
    'Ref: Page subtitle': 'Sign in to continue',
    'Ref: Email field placeholder': 'Your email',
    'Ref: Email field note': "We'll never share your email with anybody.",
    'Ref: Password field title': 'Your password',
    register: 'Sign Up',
    'I forgot my password.': 'Forgot Password?',
    'I have trouble logging in': "I'm having trouble logging in",
    'start registration': 'start registration',
    'Ref: Register': 'Register',
    'Ref: Login': 'Login',
    'Ref: Connection issue?': 'Connection issue?',
    'Ref: Connection issue message':
      'If you have any issue to register or login, please contact usual onsite staff or the helpdesk.',
  },
  WelcomePage: { 'Ref: Welcome to': 'Hello and welcome to {site_name}', 'Ref: Begin': 'Begin' },
  NotificationsList: {
    'Ref: No notifications': 'You have no notifications for the moment',
    'Ref: This week': 'This week',
    'Ref: Earlier': 'Earlier',
    'Ref: Page title': 'Notifications',
    'Ref: See more': 'See more',
    'Ref: Mark all read': 'Mark all read',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'See all notifications' },
  ServicesMenuPage: { 'Ref: Page title': 'Services', navigate: 'navigate' },
  DeletionSuccess: {
    'Ref: Title': 'The deletion of your account was requested',
    'Ref: Content': 'We will handle it shortly. You may lose your access at any time.',
    'Log out': 'Log out',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': "We'll get back to you",
    'Ref: Body': "We're sorry you're facing issues. We will contact you very soon.",
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Having trouble logging in?',
    'Ref: Email field placeholder': 'Your email address',
    'Ref: Mobile phone field placeholder': 'Mobile Number',
    Company: 'Company',
    'Ref: Company field placeholder': 'Company name',
    'Ref: Company field note':
      'If you are a consultant, this is the company for which you are currently working, not your employer.',
    Location: 'City or location',
    'Ref: Location field placeholder': 'Location of your workplace',
    'Ref: Location field note':
      'If outside of an inhabited area, feel free to use the name by which the area is commonly referred to.',
    Comment: 'Comment',
    'Ref: Comment field placeholder': 'What went wrong?',
    'Ref: Who is your employer?': 'Who is your employer?',
    'Ref: What village are you staying at?': 'What village are you staying in?',
    'Ref: Comment field note': 'Any information that can help us help you!',
    'Ref: Feedback Content - geography': 'Geography',
    'Ref: Feedback Content - fullName': 'Name',
    'Ref: Feedback Content - email': 'E-mail',
    'Ref: Feedback Content - phone': 'Phone',
    'Ref: Feedback Content - company': 'Company',
    'Ref: Feedback Content - city': 'City / location name',
    'Ref: Feedback Content - whatVillage': 'Village',
    'Ref: Feedback Content - employer': 'Employer',
    'Ref: Feedback Content - comment': 'What went wrong',
  },
  AccountStartPage: {
    'Ref: Header': 'Choose your location for start',
    'Ref: Body':
      'Find quickly a location by activating the localisation, scanning a QR code or searching by name',
    'Ref: LetsGo button': "Let's go",
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Registration' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Select site with QR Scanner',
    'Ref: Page title': 'Choose your location',
    'Ref: Select site with code': 'Select site with code',
    'Ref: Enter site code': 'Enter site code',
    'Ref: Scanner error header': 'Site not found',
    'Ref: Not found': 'This code is not valid in the selected geography',
    'Ref: Generic error': 'Something went wrong',
    'All other sites': 'All other sites',
    'Sites in your country': 'Sites in your country',
    'Ref: Unauthorized error': 'Unauthorized request due to site restriction level',
    'Ref: Forbidden':
      "Sorry, you don't have the right permissions to access this site. To arrange access, please contact the Pilbara Service Centre on 1800 992 777",
    "Ref: We didn't find the site.": "We didn't find the site.",
    'Ref: We recommend some solutions:': 'We recommend some solutions:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Try to add your site with QR Scanner or with the help of buttons below',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Log out and log back in by selecting the correct country',
    'Ref: The currently selected country is:': 'The current selected country is:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Registration',
    'Confirm password': 'Confirm Password',
    'Email is not valid': 'Email is not valid',
    'passwords do not match': 'Passwords do not match.',
    'Ref: Password strength':
      'Your password must be at least 8 characters long, contain at least one number, one uppercase, one lowercase and one special character.',
    'Sign up': 'Sign up',
    'Continue as guest': 'Continue as guest',
    'Already have an account? Login': 'Already have an account? Login',
    'Ref: Registration success title': 'Thank you for registering',
    'Ref: Registration success content':
      'Your account will be created soon. You will receive an email confirming the creation.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Please login/sign up',
    'Ref: Password strength':
      'Your password must be at least 8 characters long, contain at least one number, one uppercase, one lowercase and one special character.',
    Stamps: 'Stamps',
    'Order history': 'Order history',
    'to earn points': 'to earn points',
    'to see order history': 'to see order history',
    'to place an order': 'to place an order',
    'Sign up': 'Sign up',
    Login: 'Login',
    'Email is not valid': 'Email is not valid',
    'passwords do not match': 'Passwords do not match.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Thank You',
    'Ref: Body':
      'You’re almost done! You will soon receive a verification email on the address provided. Open this email and click the link to activate your account.',
    'Ref: Enter': 'Continue',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'An error has occured',
    'Ref: Generic Error Body': 'Please try again later or contact your helpdesk.',
    'Ref: Missing IndexDb support':
      "Sorry, you can't access the application in incognito mode or without IndexDb support. Please retry with another browser.",
    'Ref: Resource not found': 'Content you are trying to access does not exist.',
    'Ref: Permission denied - SITE_HIDDEN':
      'Content you are trying to access belongs to a private site.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      "Content you are trying to access isn't authorized for your account.",
    'Ref: TabTitle': 'Error',
    'Ref: Site link invalid':
      'This link is invalid, select another site or contact the helpdesk if the problem persists.',
    'Ref: Access Denied': 'Access Denied. Insufficient user permissions.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Oops! {robot_emoji}',
    'Ref: Error title': 'Something went wrong!',
    'Ref: Error body': 'Try to refresh this page or to contact helpdesk if the problem persists.',
    'Ref: Error ID:': 'Error ID: {errorId}',
    'Ref: Back to home': 'Back to home',
  },
  Child: {
    'Ref: Route Not Found':
      'The service you are looking for is not available on this site. Please try again later or contact your helpdesk.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Congratulations, your new password has been successfully saved, you can now access all of our services from your account.',
    Welcome: 'Welcome',
    'Ref: Welcome message':
      'You have now successfully registered to the new Bite application, and can continue to discover and enjoy all of our services.',
  },
  PasswordChange: {
    'Change Password': 'Change Password',
    Welcome: 'Welcome',
    'Ref: Password message': 'Your password needs to be changed because it is not secure enough',
  },
  AccountPasswordChange: {
    'Change your password here': 'Change your password here',
    'Your old password': 'Your old password',
    'Type in your new password': 'Type in your new password',
    'Re-type in your new password': 'Re-type in your new password',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'Your password has been changed',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message': 'An error occurred hence your change was not saved. Please try again.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Reset Password',
    'Ref: Provide email':
      'Please provide the email address used when registering on {app_name}. An email will be sent to you to reset your password.',
    'Ref: Something happened': 'Something happened. Please try again later.',
  },
  ResetEmailSent: {
    'Ref: New email sent': 'We have sent a new email. Please wait 30 seconds to resend again.',
    'Go to login page': 'Go to login page',
    'Resend email': 'Resend email',
    'Ref: Email sent title': 'A password reset email has been sent to you',
    'Ref: TabTitle': 'Reset Password',
    'Ref: Email sent msg':
      'The reset link was sent to your address {email_address}. Please wait 30 seconds before trying to resend.',
  },
  ResetPassword: { 'Enter your new password': 'Enter your new password' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Your password has been successfully changed',
    'Go to login page': 'Go to login page',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Password reset fail',
    'Ref: Error': 'An error occured, please try again',
    'Reset your password': 'Reset your password',
    'Go to login page': 'Go to login page',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Privacy Policy',
    'Ref: Privacy Policy Body':
      'By accessing this platform, Sodexo SA and the local Sodexo entity will process your personal data in order to at least manage your account and to provide the services and offers available through the platform. If you want more information regarding the processing of your personal data, who will access it, and how to exercise the rights you have on your personal data, please consult the Privacy Policy below and available at any time in your account.',
    'Ref: Continue': 'Continue',
    'Ref: Read Privacy Policy': 'Read Privacy Policy',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Go To Store' },
  SharePage: { 'Ref: Select a site': 'Select a site' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'To view Privacy Policy please select your country.',
  },
};
export default labels;
