import { act, cleanup, fireEvent, screen } from '@testing-library/react';

import { renderedComponent } from '../../../../helpers/tests/renderComponent';

import EventDatesModal from './EventDatesModal';

const props = {
  label: (s: string) => s,
  eventDetails: {
    id: '',
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    publishedDate: '',
    eventDates: ['', ''],
    location: '',
    link: {
      url: '',
      text: '',
    },
    preferences: [],
    sites: [],
    images: [],
    isUserInterested: false,
    reactions: {
      reactions: [],
      total: 0,
      me: null,
    },
    mainImage: null,
  },
  languageCode: 'en',
  nextDate: new Date(),
  isSeeAllDatesModalOpen: true,
  setSelectedDate: jest.fn(),
  closeSeeAllDatesModal: jest.fn(),
  allDatesOn: jest.fn(),
  downloadEvent: jest.fn(),
};

describe('EventDatesModal', () => {
  afterAll(() => cleanup());

  it("shouldn't render when visible is false", async () => {
    await act(async () => {
      renderedComponent(EventDatesModal, { ...props, isSeeAllDatesModalOpen: false });
    });

    const modal = screen.queryByTestId('eventDatesModal');
    expect(modal).not.toBeInTheDocument();
  });

  it('should render when visible is true', async () => {
    await act(async () => {
      renderedComponent(EventDatesModal, props);
    });

    const modal = screen.queryByTestId('eventDatesModal');
    expect(modal).toBeInTheDocument();
  });

  it('Should request download event', async () => {
    const downloadEvent = jest.fn(() => ({ catch: jest.fn() }));
    await act(async () => {
      renderedComponent(EventDatesModal, { ...props, downloadEvent });
    });

    expect(downloadEvent).not.toHaveBeenCalled();

    jest.useFakeTimers();

    const downloadButton = screen.getByTestId('all-dates-list-next-date-list-item-button');
    fireEvent.click(downloadButton);

    jest.runAllTimers();
    expect(downloadEvent).toHaveBeenCalled();
    jest.useRealTimers();
  });

  it('Should to open calendar modal', async () => {
    const allDatesOn = jest.fn();
    const closeSeeAllDatesModal = jest.fn();
    await act(async () => {
      renderedComponent(EventDatesModal, { ...props, allDatesOn, closeSeeAllDatesModal });
    });

    const downloadButton = screen.getByTestId('all-dates-list-all-dates-list-item-button');

    expect(allDatesOn).not.toHaveBeenCalled();
    expect(closeSeeAllDatesModal).not.toHaveBeenCalled();

    fireEvent.click(downloadButton);

    expect(allDatesOn).toHaveBeenCalled();
    expect(closeSeeAllDatesModal).toHaveBeenCalled();
  });

  it('Should request close Dates modal', async () => {
    const closeSeeAllDatesModal = jest.fn();
    await act(async () => {
      renderedComponent(EventDatesModal, { ...props, closeSeeAllDatesModal });
    });

    const closeModalButton = screen.getByLabelText('Close');

    expect(closeSeeAllDatesModal).not.toHaveBeenCalled();

    fireEvent.click(closeModalButton);

    expect(closeSeeAllDatesModal).toHaveBeenCalled();
  });

  it('Should simulate download event error', async () => {
    const consoleErrorMock = jest.spyOn(console, 'error').mockImplementation(() => {});

    const downloadEvent = jest.fn(() => Promise.reject());
    await act(async () => {
      renderedComponent(EventDatesModal, { ...props, downloadEvent });
    });

    expect(downloadEvent).not.toHaveBeenCalled();

    jest.useFakeTimers();

    const downloadButton = screen.getByTestId('all-dates-list-next-date-list-item-button');
    fireEvent.click(downloadButton);

    jest.runAllTimers();

    expect(downloadEvent).toHaveBeenCalled();

    jest.useRealTimers();
    consoleErrorMock.mockClear();
  });
});
