import { AppTrackingTransparency } from 'ios-tracking-transparency';
import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isIosMobile } from '../../../helpers/misc';
import { State } from '../../../types/state.types';
import { setDataTracking } from '../actions';

const useDataTracking = () => {
  const dispatch = useDispatch();
  const useDataTracking = useSelector((state: State) => state.Core.user?.useDataTracking);

  const askUseAnalytics = useCallback(() => {
    if (isIosMobile()) {
      (async () => {
        try {
          if (!useDataTracking) {
            let currentConsentStatus = await AppTrackingTransparency.trackingAuthorizationStatus();
            if (
              currentConsentStatus.status === 'notDetermined' ||
              currentConsentStatus.status === 'null'
            ) {
              currentConsentStatus = await AppTrackingTransparency.requestTrackingAuthorization();
            }
            const trackingAllowed = currentConsentStatus.status === 'authorized';
            dispatch(await dispatch(setDataTracking({ dataTracking: trackingAllowed })));
            if (!trackingAllowed) {
              // remove appinsights cookies
              document.cookie = 'ai_user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
              document.cookie = 'ai_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

              // hide cookie consent popup
              document.querySelector('#onetrust-banner-sdk')?.setAttribute('style', 'display:none');
            }
          }
        } catch (e) {
          console.warn('Tracking authorization failed', e);
        }
      })();
    }
  }, [dispatch, useDataTracking]);

  useEffect(() => {
    askUseAnalytics();
  }, [askUseAnalytics]);
};
export default useDataTracking;
