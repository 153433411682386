const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Lista de auditorias',
    'Ref: status0': 'Abrir',
    'Ref: status1': 'Concluído',
    'Ref: status2': 'Cancelado',
    'Ref: connected': 'Conectado',
    'Ref: not connected': 'Não conectado',
    'Ref: synchronise data': 'Sincronize dados',
    'Ref: sync success': 'Dados sincronizados com sucesso',
    'Ref: Create new': 'Crie um novo',
  },
  AuditResponseForm: {},
  AuditCreationForm: {
    'Ref: Page title': 'Crie um novo',
    'Ref: Location': 'Localização',
    'Ref: Audit Stream Type': 'Fluxo',
    'Ref: Audit': 'Auditoria',
    'Ref: Asset': 'Ativo',
    'Ref: Asset Name': 'Nome do ativo',
    'Ref: Is joint audit?': 'É a auditoria conjunta?',
    'Ref: Who was present?': 'Quem estava presente?',
    'Ref: submit': 'Enviar',
    'Ref: Select the Location': 'Selecione o local',
    'Ref: Select the Audit Stream Type': 'Selecione o tipo de fluxo de auditoria',
    'Ref: Select the Audit': 'Selecione a auditoria',
    'Ref: Select the Asset': 'Selecione o ativo',
    'Ref: form error': 'Nem todos os campos necessários foram totalmente inseridos',
    'Ref: Audit error': 'Auditoria anexada',
    'Ref: Joint Audit Participants error': 'Participantes da auditoria conjunta',
  },
};
export default labels;
