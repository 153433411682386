const labels = {
  TimeTable: {
    'leave at': '떠나십시오',
    'arrive at': '도착',
    at: '~에',
    'Ref: list placeholder': '시간을 보려면 양식을 작성하십시오',
    'Ref: Page title': '버스를',
    'Ref: Departure': '출발',
    'Ref: Arrivals': '도착',
    'Ref: minutes': '분',
    'Ref: no items placeholder': '죄송합니다. 현재 버스가 없습니다.',
  },
};
export default labels;
