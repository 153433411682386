import moment from 'moment';

import { LOCAL_STORAGE_KEYS } from '../constants';

const isLoggedIn = () => {
  let result = false;
  const accessTokenValidTill = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_VALID_UNTIL);

  const validityLimit = Number(accessTokenValidTill) - 5000; //for slow networks, keep 5s of lag
  const accessTokenDate = moment(validityLimit);

  if (accessTokenDate.isValid()) {
    result = accessTokenDate.isSameOrAfter(Date.now()) || !window.navigator.onLine;
  }

  return result;
};

export default isLoggedIn;
