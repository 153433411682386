import { renderHook } from '@testing-library/react';

import { APP_DISPLAY_NAME } from '../../../../constants';
import * as misc from '../../../../helpers/misc';
import useDynamicTabName from '../useDynamicTabName';

const appName = 'MyWay';
const title = 'Home';
const firstExpected = `${title} - ${appName}`;

describe('useDynamicTabName', () => {
  beforeEach(() => {
    jest.spyOn(misc, 'getAppDisplayName').mockReturnValue(APP_DISPLAY_NAME.MYWAY);
  });

  it('return Title with AppName', async () => {
    renderHook(() => useDynamicTabName(title));
    expect(document.title).toEqual(firstExpected);
  });

  it('return Title without AppName', async () => {
    renderHook(() => useDynamicTabName(title, true));
    expect(document.title).toEqual(title);
  });

  it('for empty input, return AppName', async () => {
    renderHook(() => useDynamicTabName(''));
    expect(document.title).toEqual(appName);
  });

  it('for undefined input, return AppName', async () => {
    renderHook(() => useDynamicTabName(undefined));
    expect(document.title).toEqual(appName);
  });
});
