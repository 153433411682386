import { renderHook } from '@testing-library/react';

import { WIDGET_TYPE } from '../../../../constants';
import { Service } from '../../types/State.types';
import { useWidgets } from '../useWidgets';

const mockWidget2 = {
  id: 'widget2',
  type: WIDGET_TYPE.NORMAL,
  component: () => <div>widget2</div>,
};

// Kept as an example for mocking mini widgets
// const mockMiniWidget1 = {
//   id: 'miniWidget1',
//   type: WIDGET_TYPE.MINI,
//   component: () => <div>miniWidget1</div>,
// };

jest.mock('../../../config', () => ({
  serviceTypes: {
    FirstTestService: {
      defaultModule: 'Shop',
    },
    SecondTestService: {
      defaultModule: 'Events',
    },
    'Food.Order': {
      defaultModule: 'Food.Order',
    },
    'Food.Menu': {
      defaultModule: 'Food.Menu',
    },
  },
  SERVICE: {
    FOOD_MENU: 'Food.Menu',
    FOOD_ORDER: 'Food.Order',
    FACILITY: 'Facilities',
  },
}));

const services: Service[] = [
  {
    id: '1',
    name: 'FirstTestService',
    setupOptions: [],
    contentPageId: null,
    navigation: { imageId: null, imageName: null, name: 'name', isEnabled: false, orderIndex: 0 },
  },
  {
    id: '1',
    name: 'SecondTestService',
    setupOptions: [],
    contentPageId: null,
    navigation: { imageId: null, imageName: null, name: 'name', isEnabled: false, orderIndex: 0 },
  },
  {
    id: '1',
    name: 'Food.Order',
    setupOptions: [],
    contentPageId: null,
    navigation: { imageId: null, imageName: null, name: 'name', isEnabled: false, orderIndex: 0 },
  },
];

jest.mock('../../../Events/widgets', () => ({
  widgets: [mockWidget2],
}));

describe('useWidgets', () => {
  it('return 1 widget', async () => {
    const render = renderHook(() => useWidgets({ services: services, isGuest: false }));

    const { widgets } = render.result.current;
    expect(widgets).toEqual([mockWidget2]);
  });
});
