const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'Accommodation Request',
    'Ref: Describe your request': 'Describe your request',
    'Ref: Confirm your request': 'Confirm your request',
    'Ref: Your request': 'Your request',
    'Ref: Summary': 'Summary',
    'Ref: Next': 'Next',
    'Ref: Submit': 'Submit',
    'Ref: Me': 'Me',
    'Ref: Arrival date': 'Arrival date',
    'Ref: Arrival time': 'Arrival time',
    'Ref: Departure date': 'Departure date',
    'Ref: Departure time': 'Departure time',
    'Ref: request raised for': 'The person affected by this request',
    'Ref: phoneNumber': 'Phone number',
    'Ref: arrivalDate': 'Arrival',
    'Ref: departureDate': 'Departure',
    'Ref: description': 'Comment',
    'Ref: open': 'Open',
    'Ref: pending': 'Pending',
    'Ref: refused': 'Refused',
    'Ref: closed': 'Closed',
    'Ref: all': 'All',
    'Ref: Arrival': 'Arrival',
    'Ref: Validate': 'Validate',
    'Ref: site': 'Site location',
    'Ref: Update us': 'Update us',
    'Ref: email of guest': 'Email of guest',
    'Ref: GuestAccommodationRequestTitle': 'Guest Request',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'New request',
    'Ref: Status': 'Status',
    'Ref: Date': 'Date',
    'Ref: Coming arrivals': 'Coming arrivals',
    'Ref: Past arrivals': 'Past arrivals',
  },
  CreateForm: {
    'Ref: Guest phone number': 'Phone number of guest',
    'Ref: Your phone number': 'Your phone number',
    'Ref: The phone number': 'The phone number',
    'Ref: PhoneNumber error msg': 'The phone number is not valid',
    'Ref: Comment error msg': 'Maximum number of characters is 250',
    'Ref: Guest first name': 'Guest first name',
    'Ref: Guest last name': 'Guest last name',
    'Ref: request for': 'For who is this request?',
    'Ref: request for me': 'Me',
    'Ref: request for guest': 'Someone else',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'Accommodation Request Details',
    'Ref: Timeline': 'Timeline',
    'Ref: No updates placeholder': 'There are no updates for this request',
  },
  CreateThankYou: {
    'Ref: Thank you': 'Thank you',
    'Ref: Success text':
      'We have received your request and are looking into it now. We will get back to you soon.',
    'Ref: Go to home': 'Go to home',
    'Ref: Create another request': 'Create another request',
  },
  CreateError: {
    'Ref: Create error title': 'Oops!',
    'Ref: Create error text': 'An error has occurred, please try again.',
    'Ref: Retry': 'Try again',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'Please update us with any new element about your request so we can best support you.',
  },
};
export default labels;
