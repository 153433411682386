import classNames from 'classnames';
import prettyBites from 'pretty-bytes';

import { CrossIcon, DocumentIcon } from '../../../assets/icons';
import { isImage } from '../../../helpers/file';
import Loader from '../Loader';

import { AttachmentProps, AttachmentInfo } from './Attachment.types';

import styles from './Attachment.module.css';

const Attachment = ({
  id,
  isLoading,
  displaySize = true,
  file,
  fileData,
  title,
  maxFileNameLength,
  imagePlaceholder,
  label,
  removeFile,
  onAttachmentClick,
  "data-testid": testId,
}: AttachmentProps) => {
  const isFileImage = isImage(file);

  const getPreviewTitle = () => {
    const fileName = title || file.name;
    if (fileName.length > maxFileNameLength) {
      const extension = fileName.split('.').pop();
      return `${fileName.substring(0, maxFileNameLength)}… .${extension}`;
    }

    return fileName;
  };

  const onClickThumb = (event: React.MouseEvent<HTMLElement>) => {
    if (onAttachmentClick) {
      onAttachmentClick({
        id,
        title: title || file.name,
        previewTitle: getPreviewTitle(),
        fileData,
        file,
      });
      event.preventDefault();
    }
  };

  return (
    <div className={classNames(styles.attachment)} data-testid={testId}>
      <div className={classNames(styles.imageContainer)} data-testid={`${testId}-container`}>
        {isLoading ? (
          <div className={styles.loader} data-testid={`${testId}-container-loader`}>
            <Loader data-testid={testId}/>
          </div>
        ) : (
          <>
            {removeFile ? (
              <button
                aria-label={label('Delete')}
                className={classNames(styles.removeButton)}
                onClick={() => removeFile(file)}
                data-testid={`${testId}-container-remove-btn`}
              >
                <CrossIcon />
              </button>
            ) : null}
            <button
              className={styles.openModalButton}
              onClick={onClickThumb}
              data-testid={`${testId}-container-thumb-btn`}
            >
              {isFileImage && fileData ? (
                <img
                  className={classNames(styles.attachmentImage)}
                  src={fileData}
                  alt={getPreviewTitle() || file.name}
                  data-testid={`${testId}-container-img_preview`}
                />
              ) : (
                <div className={classNames(styles.attachmentIcon)} data-testid={`${testId}-container-img-placeholder`}>
                  {imagePlaceholder || <DocumentIcon />}
                </div>
              )}
            </button>
          </>
        )}
      </div>
      <h3 id={file.name} className={classNames('bodySDefault', 'ellipsis')} data-testid={`${testId}_file_name`}>
        {file.name}
      </h3>
      {displaySize && <p className={classNames('captionDefault')} data-testid={`${testId}_file_size`}>{prettyBites(file.size)}</p>}
    </div>
  );
};

export type { AttachmentInfo };

export default Attachment;
