import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';

import { makeActionCreator, makeActionCreatorV2, fetchJsonActionCreator } from '../../actions';
import { refreshAccessTokenAxios } from '../../axios/tokenRefreshApi';
import { apiPath, shouldUseMockData } from '../../config';
import { defaultThemeSettings } from '../../styles/theme.helper';
import { AuditStateCacheService } from '../Audits/services/AuditStateCacheService';
import { serviceConfiguration, defaultServices, ServiceDefinition } from '../defaultServices';

import appUpdateApi from './api/appUpdate/appUpdateApi';
import { unregisterPushNotification } from './api/notifications/pushNotificationsApi';
import { coreConfig } from './apiConfig';
import { moduleId, coreCacheVersion, sharedCacheVersion, languages } from './config';
import { applyTheme, removeTheme } from './helpers/helpers';
import { saveTokens, removeTokens } from './helpers/tokensHelpers';
import mockResponse from './mockResponse';
import {
  ApplyLanguageAction,
  GetAvailableServicesSuccessResponse,
  GetThemeSuccessResponse,
  GetUserContextCustomArgs,
  GetUserContextSuccessResponse,
  UpdateUserContextCustomArgs,
} from './types/actions.types';

import notificationsApi from '@/modules/Core/api/notifications/notificationsApi';
import { removeSRTutorial } from '@/modules/ServiceRequest/helpers/helpers';
import { removeSRLTutorial } from '@/modules/ServiceRequestLight/helpers/helpers';
import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';
import { CacheService } from '@/services/CacheService';
import ModulesManager from '@/services/ModulesManager';
import { OfflineQueueService } from '@/services/OfflineQueueService';
import { PreRequestParams } from '@/types/actionCreator.types';

export const persistLocallyUserSpecific = { moduleId, cacheVersion: coreCacheVersion };
const persistLocallyNonUserSpecific = {
  moduleId: 'Shared',
  cacheVersion: sharedCacheVersion,
  userSpecific: false,
};
const persistCoreLocallyNonUserSpecific = {
  moduleId,
  cacheVersion: coreCacheVersion,
  userSpecific: false,
};

export const setFirebaseTokenStatus = makeActionCreator(
  'FIREBASE_TOKEN_SET_STATUS',
  'isTokenExisting'
);

export const setDataTracking = makeActionCreatorV2(
  'SET_DATA_TRACKING',
  { dataTracking: 'dataTracking' },
  moduleId
);

export const setMsTeamsContext = makeActionCreatorV2(
  'SET_MS_TEAMS_CONTEXT',
  { TeamsContext: 'TeamsContext', shouldRetryInit: 'shouldRetryInit' },
  moduleId
);

export const setGeography = makeActionCreatorV2('GEOGRAPHY_SET', { geoCode: 'geoCode' }, moduleId);
export const setIsSsoUser = makeActionCreatorV2('IS_SSO_SET', { isSSOUser: 'isSSOUser' }, moduleId);

const takeServicesFromConfiguration = (services: ServiceDefinition[]) =>
  serviceConfiguration ? services.filter((as) => serviceConfiguration?.includes(as.name)) : [];

const filterServicesBasedOnConfiguration = (services: ServiceDefinition[]) =>
  serviceConfiguration
    ? services.filter((as) => serviceConfiguration?.includes(as.name))
    : services;

const availableDefaultServices = takeServicesFromConfiguration(defaultServices);

export const setUsername = makeActionCreator('USERNAME_SET', 'username');

export const register = (() => {
  let lockAction = { type: 'REGISTRATION_POSTING' };
  let urlConstructor = () => apiPath.concat('/v4/registrations/users');

  let argConfig = {
    email: { toBody: true },
    firstName: { toBody: true },
    lastName: { toBody: true },
    password: { toBody: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  let then = {
    202: async (_: {}, dispatch: Function) => {
      await dispatch({ type: 'REGISTRATION_POSTED' });
    },
    other: async (dispatch: Function) => {
      console.log('Error registering user');
      await dispatch({ type: 'REGISTRATION_POSTED' });
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.register;
  }

  return fetchJsonActionCreator({
    lockAction,
    argConfig,
    preRequest,
    then,
    mock,
    withAccessToken: false,
  });
})();

export const refreshAccessToken = (refreshToken: string) => async (dispatch: Function) => {
  try {
    await dispatch({ type: 'TOKEN_REFRESH_START' });
    const tokenResponse = await refreshAccessTokenAxios(refreshToken);
    saveTokens(tokenResponse);

    return tokenResponse;
  } catch (err) {
    removeTokens();
    console.log('Error refreshing access token', err);
  } finally {
    await dispatch({ type: 'TOKEN_REFRESH_END' });
  }

  return undefined;
};

const { baseUrl, contextUrl, themeUrl, availableServicesUrl, availableServicesArgConfig } =
  coreConfig();
export const getUserContext = (() => {
  const lockAction = { type: 'USER_CONTEXT_FETCHING' };
  const urlConstructor = () => baseUrl.concat(contextUrl);

  const argConfig = {
    currentLanguageCode: { toThen: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (
      json: GetUserContextSuccessResponse,
      dispatch: Function,
      thenCustomArg: GetUserContextCustomArgs
    ) => {
      const { currentLanguageCode } = thenCustomArg;
      await dispatch({ type: 'USER_CONTEXT_FETCHED' });
      await dispatch({
        type: 'USER_CONTEXT_RETURNED',
        user: {
          qrCode: json.qrCode,
          contactId: json.contactId,
          firstName: json.firstName,
          lastName: json.lastName,
          email: json.email,
          mobile: json.mobile,
          preferredLanguage: json.preferredLanguage,
          communicationPreferences: {
            allowContent: json.allowContent,
            allowOffers: json.allowOffers,
          },
          preferredLocation: json.preferredLocation,
          contract: json.contract,
          theme: json.theme,
        },
        context: {
          site: json.site,
        },
        access: {
          shouldSelectSiteContext: !json.site,
        },
        persistLocally: persistLocallyUserSpecific,
      });

      await dispatch(getDefaultServices(availableDefaultServices));
      if (json.site?.id) await dispatch(getAvailableServices({ siteId: json.site.id }));
      await dispatch(notificationsApi.util.invalidateTags(['notifications']));
      await dispatch(appUpdateApi.util.invalidateTags(['app_version']));
      // Removing old site theme
      removeTheme();

      //if a theme is returned, fetch details
      const themeResult = json.theme ? await dispatch(getTheme()) : null;

      if (!themeResult || !themeResult.ok) {
        applyTheme();
      } else {
        applyTheme(themeResult.responseData.settings);
      }
      //update the context as per the current language if need be
      const currentLanguage = languages.find((el) => el.code === currentLanguageCode);
      if (currentLanguage && json.preferredLanguage?.id !== currentLanguage.backLanguageId)
        await dispatch(updateUserContext({ preferredLanguageId: currentLanguage.backLanguageId }));
    },
    other: async (dispatch: Function) => {
      await dispatch({ type: 'USER_CONTEXT_FETCHED' });
      console.log('Error fetching user context');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getUserContext;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const updateUserContext = (() => {
  const lockAction = { type: 'USER_CONTEXT_UPDATING' };
  const urlConstructor = () => baseUrl.concat(contextUrl);

  const argConfig = {
    mobile: {
      toBody: true,
      toThen: true,
    },
    preferredLanguageCode: {
      toBody: true,
      toThen: true,
    },
    preferredLocationId: {
      toBody: true,
    },
    preferredLocation: {
      toThen: true,
    },
    allowContent: {
      toBody: true,
      toThen: true,
    },
    allowOffers: {
      toBody: true,
      toThen: true,
    },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };

  const then = {
    202: async (_: {}, dispatch: Function, thenCustomArg: UpdateUserContextCustomArgs) => {
      await dispatch({
        type: 'USER_CONTEXT_UPDATE_ACCEPTED',
        mobile: thenCustomArg.mobile,
        preferredLanguageId: thenCustomArg.preferredLanguageId,
        preferredLocation: thenCustomArg.preferredLocation,
        communicationPreferences: {
          allowContent: thenCustomArg.allowContent,
          allowOffers: thenCustomArg.allowOffers,
        },
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: async (dispatch: Function) => {
      await dispatch({ type: 'USER_CONTEXT_UPDATE_FINISHED' });
      console.log('Error attempting to update user context');
    },
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.updateUserContext;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, lockAction, then, mock });
})();

export const clearSiteContext = makeActionCreator('CLEAR_SITE_CONTEXT');

export const logout = () => async (dispatch: Function) => {
  const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
  if (isPushNotificationsAvailable) {
    const [, deviceUuidId] = await Promise.all([
      PushNotifications.removeAllListeners(),
      Device.getId(),
    ]);
    await unregisterPushNotification({
      installationId: deviceUuidId.identifier,
      appId: process.env.REACT_APP_NOTIFICATION_APP_ID!,
    });
  }

  removeTokens();
  await CacheService.getInstance().flush();
  await AuditStateCacheService.getInstance().flush();
  await OfflineQueueService.getInstance().flush();
  defaultThemeSettings.length > 0 ? applyTheme(defaultThemeSettings) : removeTheme();
  await dispatch({ type: 'RESET_STATE' });
  await dispatch(baseApi.util.resetApiState());
  await dispatch(dspApi.util.resetApiState());
  removeSRLTutorial();
  removeSRTutorial();
};

export const clearErrors = { type: 'CLEAR_ERRORS' };

export const firstLoginCompleted = makeActionCreator(
  'FIRST_LOGIN_COMPLETED',
  'persistLocally'
)(persistLocallyUserSpecific);

//#todo remove this and rely on Sodexo Connect
export const resetPassword = (() => {
  const urlConstructor = () => apiPath.concat('/v2/support/passwordreset');
  let argConfig = {
    email: { toQueryString: true },
    languageCode: { toQueryString: true },
  };

  let preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'GET',
    },
  };
  let then = {
    200: async () => {
      //do nothing: redirection to the returned url is done chaining the promise
    },
    other: async () => console.log('Error getting psw reset url'),
  };

  const mock = shouldUseMockData ? mockResponse.resetPassword : null;

  return fetchJsonActionCreator({ argConfig, preRequest, then, mock });
})();

export const changePassword = (() => {
  const urlConstructor = () => apiPath.concat('/v2/user/password');
  const argConfig = {
    password: { toBody: true },
    old_password: { toBody: true },
  };

  const preRequest: PreRequestParams = {
    urlConstructor,
    init: {
      method: 'POST',
    },
  };
  const then = {
    204: async () => {},
    other: async () => console.log('Error changing password'),
  };

  const mock = shouldUseMockData ? mockResponse.changePassword : null;

  return fetchJsonActionCreator({ argConfig, preRequest, then, mock });
})();

const applyLang = makeActionCreatorV2(
  'APPLY_LANGUAGE',
  { code: 'code', persistLocally: persistLocallyNonUserSpecific },
  moduleId
);

export const applyLanguage = (args: ApplyLanguageAction) => {
  return (dispatch: Function) => {
    document.documentElement.lang = args.code.slice(0, 2);
    return dispatch(applyLang.call(this, args));
  };
};

export const resetLanguage = makeActionCreatorV2(
  'RESET_LANGUAGE',
  { code: 'code', persistLocally: persistLocallyNonUserSpecific },
  moduleId
);

export const getDefaultServices = makeActionCreator(
  'AVAILABLE_SERVICES_DEFINED',
  'services',
  'persistLocally'
);

export const getAvailableServices = (() => {
  const urlConstructor = () => baseUrl.concat(availableServicesUrl);

  const preRequest: PreRequestParams = {
    urlConstructor: urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: GetAvailableServicesSuccessResponse, dispatch: Function) => {
      const unionServices = [
        ...filterServicesBasedOnConfiguration(json.availableServices),
        ...availableDefaultServices.filter(
          (ds) => !json.availableServices.some((as) => as.name === ds.name)
        ),
      ];

      await ModulesManager().setupFromServices(unionServices);

      await dispatch({
        type: 'AVAILABLE_SERVICES_DEFINED',
        services: unionServices,
        persistLocally: persistLocallyUserSpecific,
      });
    },
    other: () => console.log('Error updating available services'),
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getAvailableServices;
  }

  return fetchJsonActionCreator({ argConfig: availableServicesArgConfig, preRequest, then, mock });
})();

export const getTheme = (() => {
  const urlConstructor = () => baseUrl.concat(themeUrl);

  const argConfig = {};

  const preRequest: PreRequestParams = {
    urlConstructor: urlConstructor,
    init: {
      method: 'GET',
    },
  };

  const then = {
    200: async (json: GetThemeSuccessResponse, dispatch: Function) => {
      await dispatch({
        type: 'THEME_RETURNED',
        id: json.id,
        name: json.name,
        settings: json.settings,
        persistLocally: persistCoreLocallyNonUserSpecific,
      });
    },
    other: async () => console.log('Error fetching theme'),
  };

  let mock = null;
  if (shouldUseMockData) {
    mock = mockResponse.getTheme;
  }

  return fetchJsonActionCreator({ argConfig, preRequest, then, mock });
})();
