import classNames from 'classnames';

import { TileListProps, TILE_VARIANT } from './Tile.types';

import styles from './Tile.module.css';

export const TileList: React.FC<TileListProps> = ({
  variant = TILE_VARIANT.STANDARD,
  className,
  children,
  'data-testid': dataTestId = 'tile-list',
  twoTilesColumn,
  ...props
}) => (
  <div className={classNames(styles.container, className)} {...props} data-testid={dataTestId}>
    <div
      className={classNames({
        [styles.listContainer]: true,
        [styles.listBig]: variant === TILE_VARIANT.BIG,
        [styles.withTwoColumns]: twoTilesColumn,
      })}
    >
      {children}
    </div>
  </div>
);
