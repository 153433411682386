import { ionFireEvent } from '@ionic/react-test-utils';
import { cleanup, fireEvent, screen } from '@testing-library/react';
import moment from 'moment';
import { act } from 'react';

import { renderedComponent } from '../../../../../helpers/tests/renderComponent';
import { useCreateAuditSurveyResponseMutation, useGetAuditSurveyQuery } from '../../../api';
import AuditResponseForm from '../AuditResponseForm';

import { AuditStateCacheService } from '@/modules/Audits/services/AuditStateCacheService';
import { OfflineQueueService } from '@/services/OfflineQueueService';

const auditSurvey = {
  workOrderId: '1ss567fb-c47a-4660-b957-838efdb95bda',
  inviteId: 'cad567fb-c47a-4660-b957-838e4fb95b1d',
  auditId: '0769031b-7911-ec11-b6e6-0022481131f6',
  auditName: 'L2-Care and Maintenance Property-Grounds',
  auditStream: 'Grounds Maintenance',
  workOrderNumber: '4191958',
  questions: [
    {
      id: '0f684f1b-7911-ec11-b6e6-002248113963',
      text: 'Are there control measures in place for pest, feral animals and diseases?',
      questionType: 1,
      order: 700,
      choices: ['Yes', 'No', 'N/A'],
      isRequired: true,
      isCommentRequired: false,
      isPhotoRequired: false,
      minimumRating: 0,
      maximumRating: 0,
    },
    {
      id: '11684f1b-7911-ec11-b6e6-002248113963',
      text: 'Are the weeds under control?',
      questionType: 1,
      order: 750,
      choices: ['Yes', 'No', 'N/A'],
      isRequired: true,
      isCommentRequired: false,
      isPhotoRequired: false,
      minimumRating: 0,
      maximumRating: 0,
    },
    {
      id: '45384f1b-7911-ec11-b6e6-002248113963',
      text: 'Is there a process for the replacement of room access keys/cards when required?',
      questionType: 3,
      order: 800,
      isRequired: false,
      isCommentRequired: false,
      isPhotoRequired: false,
      minimumRating: 0,
      maximumRating: 0,
    },
  ],
};

const mockCreateAuditResponse = jest.fn();

jest.mock('../../../api', () => ({
  ...jest.requireActual('../../../api'),
  useGetAuditSurveyQuery: jest.fn(),
  useCreateAuditSurveyResponseMutation: jest.fn(),
}));

jest.mock('@/services/OfflineQueueService', () => {
  const actual = jest.requireActual('@/services/OfflineQueueService');
  return {
    ...actual,
    OfflineQueueService: {
      getInstance: jest.fn().mockReturnValue({
        getValue: jest.fn(),
        setValue: jest.fn(),
        remove: jest.fn(),
      }),
    },
  };
});

jest.mock('@/modules/Audits/services/AuditStateCacheService', () => {
  const actual = jest.requireActual('@/modules/Audits/services/AuditStateCacheService');
  return {
    ...actual,
    AuditStateCacheService: {
      getInstance: jest.fn().mockReturnValue({
        getValue: jest.fn(),
        setValue: jest.fn(),
        remove: jest.fn(),
      }),
    },
  };
});

jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useParams: () => ({ id: auditSurvey.auditId }),
}));

describe('AuditResponseForm', () => {
  afterAll(() => cleanup());

  describe('Render', () => {
    beforeEach(() => {
      (AuditStateCacheService.getInstance as jest.Mock).mockReturnValue({
        getValue: () => {},
        setValue: () => {},
        remove: () => {},
      });

      (OfflineQueueService.getInstance as jest.Mock).mockReturnValue({
        getAll: () => [],
        getValue: () => {},
      });

      (useCreateAuditSurveyResponseMutation as jest.Mock).mockReturnValue([
        mockCreateAuditResponse,
        { isLoading: false, isSuccess: false },
      ]);
    });

    describe('When there is audit data', () => {
      beforeEach(async () => {
        (useGetAuditSurveyQuery as jest.Mock).mockReturnValue({
          data: auditSurvey,
          isFetching: false,
        });
        await act(async () => {
          renderedComponent(AuditResponseForm);
        });
      });

      it('should render title and questions', () => {
        const radioGroups = screen.queryAllByTestId('radio-group-controller');

        const title = screen.queryByText(/L2-Care and Maintenance Property-Grounds/i);

        expect(radioGroups.length).toEqual(6);
        expect(title).toBeTruthy();
      });
    });

    describe('When is fetching', () => {
      beforeEach(async () => {
        (useGetAuditSurveyQuery as jest.Mock).mockReturnValue({ data: {}, isFetching: true });
        await act(async () => {
          renderedComponent(AuditResponseForm);
        });
      });

      it('should render loading skeletons', () => {
        const skeletonTitle = screen.getByTestId('skeleton-title');
        const skeletonQuestion = screen.queryAllByTestId('skeleton-question');

        expect(skeletonTitle).toBeTruthy();
        expect(skeletonQuestion.length).toEqual(10);
      });
    });

    describe('On submit', () => {
      beforeEach(async () => {
        (useGetAuditSurveyQuery as jest.Mock).mockReturnValue({
          data: auditSurvey,
          isFetching: false,
        });
        await act(async () => {
          renderedComponent(AuditResponseForm);
        });
      });

      it('should show error notification when form is not correctly filled', async () => {
        const submitButton = screen.getByTestId('button-action-primary');
        await act(async () => {
          fireEvent.submit(submitButton);
        });

        const errorNotification = screen.queryByTestId('error-notification-top-content');
        expect(errorNotification).toBeTruthy();
      });

      it('should submit the form', async () => {
        // Simulate user interactions for each question
        const radioInputs = screen
          .getAllByRole('radio')
          .filter((input) => (input as HTMLInputElement).value === 'Yes');

        radioInputs.forEach((radioInput) => {
          if (radioInput) {
            fireEvent.click(radioInput);
          }
        });

        const additionalComments = screen.getAllByTestId('additional-comment-textarea');

        ionFireEvent.ionChange(additionalComments[0], 'Some comment');

        const submitButton = screen.getByTestId('button-action-primary');
        await act(async () => {
          fireEvent.submit(submitButton);
        });

        const responsesList = [
          {
            questionId: auditSurvey.questions[0].id,
            response: 'Yes',
            order: auditSurvey.questions[0].order,
            additionalComment: 'Some comment',
            attachments: undefined,
          },
          {
            questionId: auditSurvey.questions[1].id,
            response: 'Yes',
            order: auditSurvey.questions[1].order,
            additionalComment: undefined,
            attachments: undefined,
          },
          {
            additionalComment: undefined,
            attachments: undefined,
            order: 800,
            questionId: '45384f1b-7911-ec11-b6e6-002248113963',
            response: undefined,
          },
        ];
        const body = {
          workOrderId: auditSurvey.workOrderId,
          auditId: auditSurvey.auditId,
          inviteId: auditSurvey.inviteId,
          auditName: auditSurvey.auditName,
          responses: responsesList,
          responseDate: moment().format(),
        };

        expect(mockCreateAuditResponse).toHaveBeenCalledWith(body);
      });
    });

    describe('When question type is unsupported', () => {
      beforeEach(async () => {
        (OfflineQueueService.getInstance as jest.Mock).mockReturnValue({
          getAll: () => [],
          getValue: () => {},
        });

        (useGetAuditSurveyQuery as jest.Mock).mockReturnValue({
          data: {
            ...auditSurvey,
            questions: [
              ...auditSurvey.questions,
              {
                id: 'unsupported-question-id',
                text: 'Unsupported Question',
                questionType: 999,
              },
            ],
          },
          isFetching: false,
        });

        await act(async () => {
          renderedComponent(AuditResponseForm);
        });
      });

      it('should render UnsupportedQuestionType component', () => {
        const unsupportedComponent = screen.queryByTestId('unsupported-question-type-title');
        expect(unsupportedComponent).toBeTruthy();
      });
    });

    describe('When status is 404', () => {
      beforeEach(async () => {
        (OfflineQueueService.getInstance as jest.Mock).mockReturnValue({
          getAll: () => [],
          getValue: () => {},
        });

        (useGetAuditSurveyQuery as jest.Mock).mockReturnValue({
          data: null,
          error: { status: 404 },
          isFetching: false,
        });
        await act(async () => {
          renderedComponent(AuditResponseForm);
        });
      });

      it('should render NotFound component', () => {
        const notFoundComponent = screen.queryByTestId('not-found-title');
        expect(notFoundComponent).toBeTruthy();
      });
    });
  });

  describe('Cache', () => {
    const mockCacheValue = {
      '0f684f1b-7911-ec11-b6e6-002248113963': 'Yes',
      '11684f1b-7911-ec11-b6e6-002248113963': 'No',
      '45384f1b-7911-ec11-b6e6-002248113963': 'Hello world!',
    };

    beforeEach(() => {
      (OfflineQueueService.getInstance as jest.Mock).mockReturnValue({
        getAll: () => [],
        getValue: () => {},
      });

      (useGetAuditSurveyQuery as jest.Mock).mockReturnValue({
        data: auditSurvey,
        isFetching: false,
      });

      (useCreateAuditSurveyResponseMutation as jest.Mock).mockReturnValue([
        mockCreateAuditResponse,
        { isLoading: false, isSuccess: false },
      ]);
    });

    it('should check the cache on component render', async () => {
      const mockGetValue = jest.fn();

      (AuditStateCacheService.getInstance as jest.Mock).mockReturnValueOnce({
        ...AuditStateCacheService.getInstance(),
        getValue: mockGetValue,
      });

      await act(async () => {
        renderedComponent(AuditResponseForm);
      });

      expect(mockGetValue).toHaveBeenCalledWith(auditSurvey.auditId);
    });

    it('should populate the form with cache data if available', async () => {
      (AuditStateCacheService.getInstance as jest.Mock).mockReturnValueOnce({
        ...AuditStateCacheService.getInstance(),
        getValue: jest.fn().mockReturnValueOnce(mockCacheValue),
        setValue: () => {},
      });

      await act(async () => {
        renderedComponent(AuditResponseForm);
      });

      expect(
        screen.getByDisplayValue(mockCacheValue['45384f1b-7911-ec11-b6e6-002248113963'])
      ).toBeInTheDocument();
    });

    it('should remove cache if the audit is complete', async () => {
      const mockRemove = jest.fn();
      (AuditStateCacheService.getInstance as jest.Mock).mockReturnValueOnce({
        ...AuditStateCacheService.getInstance(),
        remove: mockRemove,
        getValue: () => {},
      });

      (useCreateAuditSurveyResponseMutation as jest.Mock).mockReturnValue([
        mockCreateAuditResponse,
        { isLoading: false, isSuccess: true },
      ]);

      await act(async () => {
        renderedComponent(AuditResponseForm);
      });

      expect(mockRemove).toHaveBeenCalledWith(auditSurvey.auditId);
    });
  });
});
