const labels = {
  All: {
    Feedback: 'Bình luận',
    'Ref: Switch site component intro sentence':
      'Phản hồi của bạn sẽ được liên kết với trang web {site name}.',
    Category: 'Danh mục',
    Subject: 'Chủ đề',
    Comment: 'Bình luận',
    'Date of your experience': 'Ngày trải nghiệm của bạn *',
    'Ref: Hint': 'Bạn có một vấn đề hoặc công việc cần sửa chữa?',
    'Ref: Log Request': 'Đăng nhập một yêu cầu',
    'Ref: Describe issue': 'Vui lòng cho chúng tôi biết về kinh nghiệm của bạn',
    Date: 'Ngày',
    'Ref: Share your opinion header':
      'Chia sẻ ý kiến ​​của bạn về kinh nghiệm của bạn trong công việc',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Nhà hàng / Café',
    'Desk / workstation area': 'Bàn / Khu vực máy trạm',
    'Meeting room facilities': 'Phòng họp cơ sở',
    'Coffee/tea facilities (pantry)': 'Tiện nghi cà phê/trà (phòng đựng thức ăn)',
    'Restrooms / washrooms': 'Phòng vệ sinh / phòng vệ sinh',
    'Reception / Security': 'Tiếp nhận / bảo mật',
    'Conference center': 'Trung tâm Hội nghị',
    'Fitness center': 'Trung tâm thể dục',
    Mailroom: 'Phòng thư',
    'Bicycle room': 'Phòng xe đạp',
    'Shuttle services': 'Dịch vụ đưa đón',
    Lockers: 'Tủ khóa',
    Helpdesk: 'Bàn trợ giúp',
  },
  Create: {
    'Tell us!': 'Nói với chúng tôi!',
    'Ref: All Ears': 'Chúng ta đều là đôi tai!',
    'Ref: Provide Description': 'Vui lòng cung cấp một mô tả',
    'Ref: Fill-in-the-blank sentence': 'Tôi muốn cung cấp {0} liên quan đến {1}',
    'Ref: Link text to service request module': 'Một yêu cầu hoặc một vấn đề? Đăng nhập tại đây!',
    'Complaint type': 'Khiếu nại {Emoji}',
    'Feedback type': 'Phản hồi {Emoji}',
    'Compliment type': 'Khen ngợi {biểu tượng cảm xúc}',
  },
  CreateFailure: {
    'Ref: Body':
      'Xảy ra lỗi và phản hồi của bạn đã không được gửi. Nếu lỗi này vẫn còn, xin vui lòng liên hệ với bộ phận trợ giúp của bạn.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Phản hồi của bạn đã được gửi. Nhóm hỗ trợ nơi làm việc của chúng tôi sẽ đọc kỹ. Nếu thích hợp, chúng tôi sẽ quay lại với bạn.',
    'New feedback': 'Phản hồi mới',
  },
  CreateFlow: {
    Description: 'Sự mô tả',
    'Ref: Provide Description': 'Vui lòng cung cấp một ngày và mô tả',
    'Ref: I would like to provide a': 'Tôi muốn cung cấp một',
    'Ref: in regards to': 'có liên quan đến',
    'Ref: &': 'Không có giá trị',
    'Ref: at': 'tại',
    'Ref: on the': 'trên',
  },
};
export default labels;
