const labels = {
  SiteDetails: {
    'Select site': 'Select this site',
    'Ref: Page title': 'Site Details',
    'Ref: Skip map': 'Skip to main content',
    'Ref: Unavailable site': 'Site preview unavilable for your user',
    'Ref: Sorry, no description yet': "Sorry, we don't have description yet.",
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Site coordinates not found',
    'Ref: Page title': 'Sites',
    'Recently visited': 'Recently visited',
    'All other sites': 'All other sites',
    'Sites in your country': 'Sites in your country',
    'Search results': 'Search results',
    'Select site': 'Select Site',
    'Ref: Select site with QR Scanner': 'Select site with QR Scanner',
    'Ref: Select site with code': 'Select site with code',
    'Ref: Validate': 'Validate',
    'Ref: Enter site code': 'Enter site code',
    'Ref: Scanner error header': 'Site not found',
    'Ref: Generic error': 'Something went wrong',
    'Ref: Change site': 'Change site',
    'Ref: Change site description':
      'There are items in the cart! Changing the site will result in clearing the cart.',
    'Ref: Cancel': 'Cancel',
    'Ref: Not found': 'This QR code has not been recognized',
    'Ref: Unauthorized error': 'Unauthorized request due to site restriction level',
    'Ref: Forbidden':
      "Sorry, you don't have the right permissions to access this site. To arrange access, please contact the Pilbara Service Centre on 1800 992 777",
    "Ref: We didn't find the site.": "We didn't find the site.",
    'Ref: We recommend some solutions:': 'We recommend some solutions:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Try to add your site with QR Scanner or with the help of buttons below',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Log out and log back in by selecting the correct country',
    'Ref: The currently selected country is:': 'The current selected country is:',
  },
  SwitchSite: { 'Not on {site name}?': 'Not on {site name}?' },
  SiteTile: { Details: 'Details', Select: 'Select' },
};
export default labels;
