const labels = {
  ContentDetails: {
    'Ref: Page title': 'รายละเอียดเนื้อหา',
    'See the event': 'ดูเหตุการณ์',
    'Take the survey': 'ทำแบบสำรวจ ',
    Date: 'วันที่',
    Category: 'หมวดหมู่',
    'Ref: Content site different title': 'เนื้อหานี้ไม่ได้เป็นของเว็บไซต์ปัจจุบันของคุณ',
    'Ref: Content site different description':
      'คุณกำลังดูเนื้อหาที่ไม่ได้เป็นของเว็บไซต์ปัจจุบันของคุณ คุณจะไม่สามารถดูได้ในรายการเนื้อหา',
  },
  ContentList: {
    'Ref: Page title': 'ข่าวใหม่',
    'featured (as in featured content)': 'แนะนำ',
    'Ref: category - all': 'ทั้งหมด',
    'Ref: category - promotional': 'เกี่ยวกับการส่งเสริมการขาย',
    'Ref: category - featured': 'ที่โดดเด่น',
    'Ref: category - other': 'อื่น',
  },
  Widget: {
    'Ref: Carousel title': 'ข่าวและบทความ',
    'Ref: Carousel title promo': 'ข่าวยอดนิยม',
    'No info yet, come back later': 'ยังไม่มีข้อมูลกลับมาทีหลัง😉',
    'Ref: Content': 'เนื้อหา',
    'Ref: Widget Title': 'ข่าวใหม่',
    'Ref: MyVillage Carousel title promo': 'โปรโมชั่น',
  },
};
export default labels;
