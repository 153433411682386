import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';

import { IN_APP_BROWSER_WITH_LOCATION } from '../../../constants';
import history from '../../../history';
import { modulePath as accomodationPath } from '../../Accomodation/config';
import { modulePath as auditsPath } from '../../Audits/config';
import { pagePaths as pagePathContent } from '../../Content/config';
import { pagePaths as pagePathEvents } from '../../Events/config';
import { modulePath as surveysPath } from '../../Surveys/config';

const entityRoutes: { [entity: string]: { path: string; objectIdRequired: boolean } } = {
  Audit: { path: auditsPath, objectIdRequired: false },
  Survey: { path: surveysPath, objectIdRequired: false },
  stay: { path: accomodationPath + '/:id/details', objectIdRequired: true },
  Article: { path: pagePathContent.ContentDetails, objectIdRequired: true },
  Event: { path: pagePathEvents.EventDetails, objectIdRequired: true },
};

const redirectToModule = (notification: any) => {
  const { regardingEntity, objectId, payloadUrl } = extractDataFromNotification(notification);

  if (regardingEntity) {
    const entityRouteInfo = entityRoutes[regardingEntity];
    if (entityRouteInfo) {
      let entityRoute = entityRouteInfo.path;
      if (objectId) {
        entityRoute = entityRoute.replace(':id', objectId);
      }

      if (!entityRouteInfo.objectIdRequired || (entityRouteInfo.objectIdRequired && !!objectId))
        history.push(entityRoute);
    }
  }

  if (regardingEntity === 'Inmoment-Survey' && payloadUrl) {
    InAppBrowser.create(payloadUrl, '_blank', IN_APP_BROWSER_WITH_LOCATION);
  }
};

const extractDataFromNotification = (notification: any) => {
  const { data, extra } = notification?.notification;
  const payload = data || extra;

  const regardingEntity = payload?.regardingEntity || payload?.aps?.alert.regardingEntity || '';
  const objectId = payload?.objectId || payload?.aps?.alert.objectId;
  const payloadUrl = payload?.url || payload?.aps?.alert.url;

  return {
    regardingEntity,
    objectId,
    payloadUrl,
  };
};

export default redirectToModule;
