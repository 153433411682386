const labels = {
  AuditInviteList: {
    'Ref: Page title': '审核',
    'Ref: status0': '打开',
    'Ref: status1': '完全的',
    'Ref: status2': '取消',
    'Ref: connected': '连接的',
    'Ref: not connected': '未连接',
    'Ref: synchronise data': '同步数据',
    'Ref: sync success': '数据成功地同步',
    'Ref: Create new': '创建新的',
    'Ref: Audit unsynchronised': '不同步',
    'Ref: Audit synchronised': '同步',
    'Ref: Unsynced error title': '发生了错误',
    'Ref: Unsynced error description':
      '这似乎是您第一次访问审核模块。请连接到网络并“同步”以开始审核。',
    'Ref: Search all audits': '所有审核',
    'Ref: Search active audits': '打开',
    'Ref: Search completed audits': '完全的',
    'Ref: sync error': '同步失败',
    'Ref: sync error details': '重试重新连接',
    'Ref: no search audit results': '不幸的是，没有与您的搜索相对应的审核',
    'Ref: no audit results': '您没有可用的审核，创建新的开始',
    'Ref: Search canceled audits': '取消',
    'Ref: Audit cancelled successfully': '审计成功取消了',
    'Ref: Cancel audit invite': '取消',
    'Ref: Error audit cancel': '审核未能取消',
    'Ref: Cancel audit confirmation': '您确定要取消审核吗？',
    'Ref: Confirm': '确认',
    'Ref: Cancel audit': '取消审核',
    'Ref: Change the location': '更改位置',
  },
  AuditResponseForm: {
    'Ref: Page title': '审核细节',
    'Ref: questions': '问题',
    'Ref: question': '问题',
    'Ref: Additional Comments': '描述',
    'Ref: Not found title': '哎呀！',
    'Ref: Not found text': '您要访问的审核不存在。',
    'Ref: Back to Audit list': '返回审核列表',
    'Ref: Success title': '谢谢你！',
    'Ref: Success text': '您已经完成了审核',
    'Ref: submit': '提交',
    'Ref: add attachment': '添加附件',
    'Ref: Unsupported elements': '抱歉，该审核似乎具有该应用程序不支持的内容。我们将努力。',
    'Ref: form error': '并非所有问题都已完全回答',
    'Ref: form complete': '从那以后完成',
    'Ref: attachment preview error': '您需要在线查看此附件',
  },
  AuditCreationForm: {
    'Ref: Page title': '创建新的',
    'Ref: Location': '地点',
    'Ref: Audit Stream Type': '溪流',
    'Ref: Audit': '审计',
    'Ref: Asset': '资产',
    'Ref: Asset Name': '资产名称',
    'Ref: Is joint audit?': '联合审计吗？',
    'Ref: Who was present?': '谁在场？',
    'Ref: submit': '提交',
    'Ref: Select the Location': '选择位置',
    'Ref: Select the Audit Stream Type': '选择审核流类型',
    'Ref: Select the Audit': '选择审核',
    'Ref: Select the Asset': '选择资产',
    'Ref: form error': '并非所有必需的字段都已经完全输入',
    'Ref: Audit error': '附件审核',
    'Ref: Joint Audit Participants error': '联合审计参与者',
    'Ref: Audit created': '创建了审核',
    'Ref: Audit field length error': '长度不应超过100个字符',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': '更改位置',
    'Ref: Location changed': '位置更改',
    'Ref: Location': '地点',
    'Ref: Select the Location': '选择位置',
    'Ref: Asset': '资产',
    'Ref: Select the Asset': '选择资产',
    'Ref: Asset Name': '资产名称',
    'Ref: submit': '提交',
    'Ref: Audit field length error': '长度不应超过100个字符',
  },
};
export default labels;
