import { modulesConfig, serviceTypes } from '../../config';

const moduleId = 'AccommodationRequest';
const cacheVersion = '0';

const modulePath = serviceTypes[modulesConfig[moduleId].serviceType].path;

export const statusMap: { [status: string]: string } = {
  'in progress': 'open',
  open: 'open',
  closed: 'closed',
  pending: 'pending',
  completed: 'closed',
  refused: 'refused',
  local: 'pending',
};

const pagePaths = {
  Module: modulePath,
  CreateForm: `${modulePath}/create-form`,
  CreateSummary: `${modulePath}/create-summary`,
  CreateThankYou: `${modulePath}/create-thank-you`,
  CreateError: `${modulePath}/create-failed`,
  Details: `${modulePath}/:id/details`,
  UpdateUs: `${modulePath}/:id/comment`,
};

const enableGuestRequestSetupOption = 'enableGuestRequest';

export { moduleId, cacheVersion, pagePaths, enableGuestRequestSetupOption };
