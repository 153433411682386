import { Comment } from '@/modules/AccommodationRequest/api/api.types';

export enum ACCOMMODATION_REQUEST_STATUS {
  CLOSED = 'closed',
  OPEN = 'open',
}

export type statusType = { icon: React.ReactElement; className: string };

export type AccommodationRequestListItem = {
  id: string;
  referenceNumber: string;
  title: string;
  status: string;
  arrivalDateTime: string;
  comments?: Comment[];
  siteName: string;
  linkPath: string;
  addendum: JSX.Element;
};
