const labels = {
  All: {
    Region: 'Wilayah atau negara',
    'Ref: Geography': 'Pilih negara atau wilayah Anda',
    'Ref: Country': 'Negara',
    'Ref: Country tooltip': 'Lokasi sesuai dengan negara situs.',
    'Ref: Please select your country': 'Pilih negara Anda',
    'First name': 'Nama depan',
    'Last name': 'Nama keluarga',
    'Ref: Full name': 'Nama lengkap',
    'Mobile number': 'Nomor handphone',
    'Mobile phone': 'Telepon genggam',
    Email: 'Alamat email',
    Password: 'Kata sandi',
    logout: 'Keluar',
    'Not a new user? Log in': 'Bukan pengguna baru? Gabung',
    'do not register, login': 'Bukan pengguna baru? Gabung',
    'Ref: I agree': 'saya setuju',
    Register: 'Daftar',
    'Ref: My language': 'Bahasaku',
    'Ref: Kiosk Fail Title': 'Ups! Ada yang salah!',
    'Ref: Kiosk Fail Description':
      'Sayangnya, pesanan Anda tidak berhasil. Silakan coba lagi atau minta anggota staf untuk membantu Anda.',
    'Ref: Kiosk Fail Back': 'kembali ke halaman sebelumnya',
    'Ref: Kiosk Fail Action': 'Hapus sesi dan pesan lagi',
    Validate: 'Mengesahkan',
    'Ref: error, incorrect credentials': 'Kredensial ini tidak cocok dengan geografi yang dipilih.',
    'Ref: Forgot password?': 'Tidak ingat kata sandi?',
    'Ref: Hello': 'Halo {wave_emoji}, silakan masuk untuk melanjutkan',
    'Ref: Form title': 'Silakan mendaftar untuk melanjutkan',
    'Already have an account? Login': 'Sudah memiliki akun? Gabung',
  },
  AccountPage: {
    'Ref: My Information': 'Informasi saya',
    'Ref: Settings': 'Pengaturan',
    'Ref: Email': 'Surel',
    'Ref: Current site': 'Situs saat ini',
    'Ref: Choose site': 'Pilih situs',
    'Ref: Personal interests': 'Kepentingan pribadi',
    'Ref: Food preferences': 'Preferensi makanan',
    'Ref: Password': 'Kata sandi',
    'Ref: Page title': 'Akun',
    'Ref: Language': 'Bahasa',
    'Ref: Communication preferences': 'Preferensi komunikasi',
    'Ref: Terms & conditions': 'Syarat & Ketentuan',
    'Ref: Privacy policy': 'Kebijakan pribadi',
    'Ref: Delete my account': 'Hapus akun Saya',
    'Ref: Confirm deletion': 'Apakah Anda yakin ingin menghapus akun Anda?',
    'Ref: Request deletion': 'Apakah Anda yakin ingin meminta penghapusan akun Anda?',
    'Ref: Deletion description':
      'Permintaan Anda akan ditangani sesuai dengan peraturan lokal Anda.',
    Placeholder: 'Tulis "Hapus"',
    'Ref: Delete description': 'Data Anda akan dihapus. Tindakan ini tidak dapat diubah.',
    delete: 'menghapus',
    'Ref: Terms of sale': 'Ketentuan Penjualan',
    'Ref: Imprint': 'Jejak',
    'Ref: Cookie Settings': 'Pengaturan Cookie',
    'Ref: Support': 'Mendukung',
    'Ref: Register or login': 'Mendaftar atau login',
    'Ref: Filtering Preferences': 'Preferensi penyaringan',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Apakah Anda pasti akan menghapus akun Anda?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Tulis "{word}" di bidang input dan konfirmasi dengan bagian bawah di bawah ini. Tindakan ini tidak dapat diubah.',
    'Ref: Write "{word}"': 'Tulis "{word}"',
    'Ref: Delete my account': 'Hapus akun Saya',
    'Ref: Words are not matching': 'Kata -kata tidak cocok',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Pilih lokasi Anda untuk memulai',
    'Ref: Choose location':
      'Temukan lokasi dengan cepat dengan mengaktifkan lokalisasi, memindai kode QR atau mencari dengan nama',
    'Ref: next step': 'Ayo pergi',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Daftar',
    'Ref: identify your location': 'Harap identifikasi lokasi Anda.',
    'Ref: option 1 - scan QR': 'Opsi 1: Pindai Registrasi Kode QR yang disediakan',
    'Ref: option 2 - enter code': 'Opsi 2: Masukkan kode lokasi Anda secara manual',
    'Ref: button to open qr code scanner modal': 'Saya memiliki QR: Mari kita pindai',
    'Ref: button to open site code input modal': 'Pilih Situs dengan Kode',
    'Ref: getting help with site identifier - support desk':
      'Jika Anda tidak jelas tentang cara melanjutkan, silakan hubungi meja dukungan Anda.',
    'Ref: getting help with site identifier - support form':
      'Anda juga dapat mengirimi kami catatan.',
    'Location code': 'Kode Lokasi',
    'Ref: location code field placeholder': 'Kode lokasi Anda di sini',
    'Ref: Scanner header': 'Memindai kode QR',
    'Ref: site code input header': 'Tambahkan situs dengan kode',
    'Ref: not valid code': 'Kode ini tidak valid dalam geografi yang dipilih',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Registrasi',
    'Ref: collected info': 'Ringkasan',
    'Ref: additional info': 'Informasi Anda',
    'Confirm password': '*Konfirmasi sandi',
    company: 'Siapa majikan Anda?',
    location: 'Lokasi',
    username: 'nama belakang',
    register: 'Daftar',
    'Ref: password strength':
      'Kata sandi Anda harus terdiri dari minimal 8 karakter, mengandung setidaknya satu angka, satu huruf besar, satu huruf kecil dan satu karakter khusus.',
    'passwords do not match': 'Sandi tidak cocok.',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Preferensi komunikasi',
    'Ref: Things happen while you are away': 'Hal -hal terjadi saat Anda pergi',
    'Ref: Would you like to receive update about offers and promotions?':
      'Apakah Anda ingin menerima pembaruan tentang penawaran dan promosi?',
    'Ref: Would you like to hear about content we think you may like?':
      'Apakah Anda ingin mendengar tentang konten yang kami pikir mungkin Anda sukai?',
    Yes: 'Ya',
    No: 'Tidak',
    apply: 'berlaku',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Detail akun' },
  DeletionFailure: {
    'Ref: Body':
      'Kesalahan telah terjadi dan permintaan Anda untuk penghapusan akun tidak dikirimkan. Jika kesalahan ini berlanjut, silakan hubungi Meja Bantuan Anda.',
    'Go to account': 'Pergi ke akun',
  },
  GenericFailurePage: {
    'Ref: Title': 'Ada yang salah',
    'Ref: Body':
      'Maaf, ada yang salah. Jika masalah ini berlanjut, silakan hubungi dukungan di tempat Anda.',
  },
  HomePage: {
    notifications: 'Pemberitahuan',
    basket: 'Gerobak',
    'Ref: Tabtitle': 'Beranda',
    'contact us': 'Hubungi kami',
    'Ref: You are logged in as a guest user': 'Anda masuk sebagai pengguna tamu',
    'Ref: Good morning': 'Selamat pagi',
    'Ref: Good afternoon': 'Selamat sore',
    'Ref: Good evening before midnight': 'Selamat malam',
    'Ref: Good evening after midnight': 'Selamat malam',
    'Ref: Search': 'Mencari',
    'Ref: products button aria': 'Ditemukan {results_form} dalam produk',
    'Ref: menus button aria': 'Menemukan {results_form} dalam menu',
    'Ref: facilities button aria': 'Ditemukan {result_form} di dalam fasilitas',
    'Ref: result': 'hasil',
    'Ref: results': 'Hasil',
    'Ref: Menus': 'Menu',
    'Ref: No results title': 'Ups!',
    'Ref: No results body': 'Tidak ada hasil yang sesuai dengan pencarian Anda',
    'Ref: Products': 'Produk',
    'Ref: Facilities': 'Fasilitas',
    'Ref: See more results': 'Lihat lebih banyak hasil pencarian',
    'Ref: Accessibility instructions':
      'Untuk mulai menggunakan pencarian, Anda harus mengkliknya atau tekan Enter sambil memfokuskannya.',
    'Ref: See more': 'Lihat lebih banyak',
    'Ref: singular form of products': 'produk',
    'Ref: singular form of menus': 'menu',
    'Ref: singular form of facilities': 'fasilitas',
    'Ref: Search results for': 'Hasil pencarian',
  },
  LanguagePage: {
    apply: 'berlaku',
    'My language': 'Bahasaku',
    'Select in which language you would like to read our app':
      'Pilih bahasa mana Anda ingin membaca aplikasi kami',
    'Ref: Page title': 'Bahasa',
  },
  LegalDocPage: {
    Acknowledge: 'Mengakui',
    'Ref: Legal changed notification title': '"{legal_doc_type}" telah dimodifikasi',
    'Ref: Legal changed notification content':
      'Harap tinjau mereka sebelum melanjutkan menggunakan aplikasi.',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Syarat & Ketentuan',
    'Read privacy policy': 'Baca Kebijakan Privasi',
    'Ref: Acknowledge legal docs': 'Saya menerima syarat dan ketentuan ini.',
    'Ref: Read and understood': 'Saya telah membaca dan memahami ',
    'Ref: No legal docs available - notice title': 'Segera hadir',
    'Ref: No legal docs available - notice body':
      'Maaf, tampaknya aplikasi ini saat ini tidak tersedia dalam geografi Anda.',
    'Ref: The privacy Policy': 'Kebijakan Privasi',
    'Ref: Legal changed notification title': '"{legal_doc_type}" telah dimodifikasi',
    'Ref: Legal changed notification content':
      'Harap tinjau mereka sebelum melanjutkan menggunakan aplikasi.',
  },
  LoginPage: {
    'Ref: Page title': 'Selamat datang di {app name}  Aplikasi',
    'Ref: Page subtitle': 'Masuk untuk melanjutkan',
    'Ref: Email field placeholder': 'Email mu',
    'Ref: Email field note': 'Kami tidak akan pernah membagikan email Anda dengan siapa pun.',
    'Ref: Password field title': 'Kata sandi Anda',
    register: 'Daftar',
    'I forgot my password.': 'Tidak ingat kata sandi?',
    'I have trouble logging in': 'Mengalami masalah saat masuk?',
    'start registration': 'Mulai pendaftaran',
    'Ref: Register': 'Daftar',
    'Ref: Login': 'Gabung',
    'Ref: Connection issue?': 'Masalah koneksi?',
    'Ref: Connection issue message':
      'Jika Anda memiliki masalah untuk mendaftar atau masuk, silakan hubungi staf di tempat yang biasa atau meja bantuan.',
  },
  WelcomePage: {
    'Ref: Welcome to': 'Halo dan selamat datang di {site_name}',
    'Ref: Begin': 'Mulai',
  },
  NotificationsList: {
    'Ref: No notifications': 'Anda tidak memiliki pemberitahuan untuk saat ini',
    'Ref: This week': 'Minggu ini',
    'Ref: Earlier': 'Lebih awal',
    'Ref: Page title': 'Pemberitahuan',
    'Ref: See more': 'Lihat lebih banyak',
    'Ref: Mark all read': 'Tandai semua baca',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Lihat semua pemberitahuan' },
  ServicesMenuPage: { 'Ref: Page title': 'Jasa', navigate: 'navigasi' },
  DeletionSuccess: {
    'Ref: Title': 'Penghapusan akun Anda diminta',
    'Ref: Content': 'Kami akan segera menanganinya. Anda mungkin kehilangan akses Anda kapan saja.',
    'Log out': 'Keluar',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': 'Kami akan membalas Anda',
    'Ref: Body': 'Maaf Anda menghadapi masalah. Kami akan segera menghubungi Anda.',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Mengalami masalah saat masuk?',
    'Ref: Email field placeholder': 'Alamat email anda',
    'Ref: Mobile phone field placeholder': 'Nomor telepon',
    Company: 'Perusahaan',
    'Ref: Company field placeholder': 'Nama Perusahaan',
    'Ref: Company field note':
      'Jika Anda seorang konsultan, ini adalah perusahaan yang sedang Anda kerjakan, bukan majikan Anda.',
    Location: 'Kota atau lokasi',
    'Ref: Location field placeholder': 'Lokasi tempat kerja Anda',
    'Ref: Location field note':
      'Jika di luar area yang dihuni, jangan ragu untuk menggunakan nama yang dimungkinkan oleh daerah tersebut.',
    Comment: 'Komentar',
    'Ref: Comment field placeholder': 'Apa yang salah?',
    'Ref: Who is your employer?': 'Siapa majikan Anda?',
    'Ref: What village are you staying at?': 'Anda tinggal di desa apa?',
    'Ref: Comment field note': 'Informasi apa pun yang dapat membantu kami membantu Anda!',
    'Ref: Feedback Content - geography': 'Geografi',
    'Ref: Feedback Content - fullName': 'Nama',
    'Ref: Feedback Content - email': 'Surel',
    'Ref: Feedback Content - phone': 'Telepon',
    'Ref: Feedback Content - company': 'Perusahaan',
    'Ref: Feedback Content - city': 'Nama Kota / Lokasi',
    'Ref: Feedback Content - whatVillage': 'Desa',
    'Ref: Feedback Content - employer': 'Pemberi pekerjaan',
    'Ref: Feedback Content - comment': 'Apa yang salah',
  },
  AccountStartPage: {
    'Ref: Header': 'Pilih lokasi Anda untuk memulai',
    'Ref: Body':
      'Temukan lokasi dengan cepat dengan mengaktifkan lokalisasi, memindai kode QR atau mencari dengan nama',
    'Ref: LetsGo button': 'Ayo pergi',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Registrasi' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Pilih situs dengan pemindai QR',
    'Ref: Page title': 'Pilih lokasi Anda',
    'Ref: Select site with code': 'Pilih Situs dengan Kode',
    'Ref: Enter site code': 'Masukkan kode situs',
    'Ref: Scanner error header': 'Situs tidak ditemukan',
    'Ref: Not found': 'Kode ini tidak valid dalam geografi yang dipilih',
    'Ref: Generic error': 'Ada yang salah',
    'All other sites': 'Semua situs lainnya',
    'Sites in your country': 'Situs di negara Anda',
    'Ref: Unauthorized error': 'Permintaan tidak sah karena tingkat pembatasan lokasi',
    'Ref: Forbidden':
      'Maaf, Anda tidak memiliki izin yang tepat untuk mengakses situs ini. Untuk mengatur akses, silakan hubungi Pilbara Service Center di 1800 992 777',
    "Ref: We didn't find the site.": 'Kami tidak menemukan situsnya.',
    'Ref: We recommend some solutions:': 'Kami merekomendasikan beberapa solusi:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Coba tambahkan situs Anda dengan pemindai QR atau dengan bantuan tombol di bawah ini',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Masuk dan masuk kembali dengan memilih negara yang benar',
    'Ref: The currently selected country is:': 'Negara yang saat ini dipilih adalah:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Registrasi',
    'Confirm password': 'konfirmasi sandi',
    'Email is not valid': 'Email tidak valid',
    'passwords do not match': 'Sandi tidak cocok.',
    'Ref: Password strength':
      'Kata sandi Anda harus setidaknya 8 karakter, berisi setidaknya satu angka, satu huruf besar, satu huruf kecil dan satu karakter khusus.',
    'Sign up': 'Daftar',
    'Continue as guest': 'Lanjutkan sebagai tamu',
    'Already have an account? Login': 'Sudah memiliki akun? Gabung',
    'Ref: Registration success title': 'Terima kasih telah mendaftar',
    'Ref: Registration success content':
      'Akun Anda akan segera dibuat. Anda akan menerima email yang mengkonfirmasi kreasi.',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Harap Masuk/Mendaftar',
    'Ref: Password strength':
      'Kata sandi Anda harus terdiri dari minimal 8 karakter, mengandung setidaknya satu angka, satu huruf besar, satu huruf kecil dan satu karakter khusus.',
    Stamps: 'Perangko',
    'Order history': 'Sejarah pesanan',
    'to earn points': 'untuk mendapatkan poin',
    'to see order history': 'untuk melihat riwayat pesanan',
    'to place an order': 'untuk memesan',
    'Sign up': 'Daftar',
    Login: 'Gabung',
    'Email is not valid': 'Email tidak valid',
    'passwords do not match': 'Sandi tidak cocok.',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Terima kasih',
    'Ref: Body':
      'Kamu hampir selesai! Anda akan segera menerima email verifikasi di alamat yang disediakan. Buka email ini dan klik tautan untuk mengaktifkan akun Anda.',
    'Ref: Enter': 'Melanjutkan',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Kesalahan telah terjadi',
    'Ref: Generic Error Body': 'Silakan coba lagi nanti atau hubungi meja bantuan Anda.',
    'Ref: Missing IndexDb support':
      'Maaf, Anda tidak dapat mengakses aplikasi dalam mode penyamaran atau tanpa dukungan IndexDB. Harap coba lagi dengan browser lain.',
    'Ref: Resource not found': 'Konten yang Anda coba akses tidak ada.',
    'Ref: Permission denied - SITE_HIDDEN': 'Konten yang Anda coba akses milik situs pribadi.',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'Konten yang Anda coba akses tidak diizinkan untuk akun Anda.',
    'Ref: TabTitle': 'Kesalahan',
    'Ref: Site link invalid':
      'Tautan ini tidak valid, pilih situs lain atau hubungi meja bantuan jika masalahnya tetap ada.',
    'Ref: Access Denied': 'Akses ditolak. Izin pengguna tidak memadai.',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Ups! {robot_emoji}',
    'Ref: Error title': 'Ada yang salah!',
    'Ref: Error body':
      'Cobalah untuk menyegarkan halaman ini atau untuk menghubungi meja bantuan jika masalahnya tetap ada.',
    'Ref: Error ID:': 'ID kesalahan: {errorId}',
    'Ref: Back to home': 'Kembali ke rumah',
  },
  Child: {
    'Ref: Route Not Found':
      'Layanan yang Anda cari tidak tersedia di situs ini. Silakan coba lagi nanti atau hubungi meja bantuan Anda.',
  },
  AccountValidation: {
    'Ref: Password changed message':
      'Selamat, kata sandi baru Anda telah berhasil disimpan, Anda sekarang dapat mengakses semua layanan kami dari akun Anda.',
    Welcome: 'Selamat datang',
    'Ref: Welcome message':
      'Anda sekarang telah berhasil mendaftar ke aplikasi Bite baru, dan dapat terus menemukan dan menikmati semua layanan kami.',
  },
  PasswordChange: {
    'Change Password': 'Ganti kata sandi',
    Welcome: 'Selamat datang',
    'Ref: Password message': 'Kata sandi Anda perlu diubah karena tidak cukup aman',
  },
  AccountPasswordChange: {
    'Change your password here': 'Ubah kata sandi Anda di sini',
    'Your old password': 'Kata sandi lama Anda',
    'Type in your new password': 'Ketikkan kata sandi baru Anda',
    'Re-type in your new password': 'Jenis Kembali dalam Kata Sandi Baru Anda',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'Kata sandi Anda telah diubah',
  },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'Terjadi kesalahan sehingga perubahan Anda tidak disimpan. Tolong coba lagi.',
  },
  PasswordForgotten: {
    'Ref: Page title': 'Setel ulang kata sandi',
    'Ref: Provide email':
      'Harap berikan alamat email yang digunakan saat mendaftar di {app_name}. Email akan dikirimkan kepada Anda untuk mengatur ulang kata sandi Anda.',
    'Ref: Something happened': 'Sesuatu telah terjadi. Silakan coba lagi nanti.',
  },
  ResetEmailSent: {
    'Ref: New email sent':
      'Kami telah mengirim email baru. Harap tunggu 30 detik untuk dikirim kembali.',
    'Go to login page': 'Pergi ke Halaman Login',
    'Resend email': 'Kirim ulang email',
    'Ref: Email sent title': 'Email reset kata sandi telah dikirimkan kepada Anda',
    'Ref: TabTitle': 'Setel ulang kata sandi',
    'Ref: Email sent msg':
      'Tautan reset dikirim ke alamat Anda {email_address}. Harap tunggu 30 detik sebelum mencoba mengirim kembali.',
  },
  ResetPassword: { 'Enter your new password': 'Masukkan Kata Sandi Baru Anda' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'kata sandi Anda berhasil diubah',
    'Go to login page': 'Pergi ke Halaman Login',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Reset Kata Sandi Gagal',
    'Ref: Error': 'Terjadi kesalahan, coba lagi',
    'Reset your password': 'Mereset password Anda',
    'Go to login page': 'Pergi ke Halaman Login',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Kebijakan pribadi',
    'Ref: Privacy Policy Body':
      'Dengan mengakses platform ini, Sodexo SA dan entitas Sodexo lokal akan memproses data pribadi Anda untuk setidaknya mengelola akun Anda dan untuk menyediakan layanan dan penawaran yang tersedia melalui platform. Jika Anda ingin informasi lebih lanjut mengenai pemrosesan data pribadi Anda, siapa yang akan mengaksesnya, dan cara menggunakan hak yang Anda miliki pada data pribadi Anda, silakan berkonsultasi dengan kebijakan privasi di bawah ini dan tersedia kapan saja di akun Anda.',
    'Ref: Continue': 'Melanjutkan',
    'Ref: Read Privacy Policy': 'Baca Kebijakan Privasi',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Pergi ke toko' },
  SharePage: { 'Ref: Select a site': 'Pilih situs' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Untuk melihat kebijakan privasi, pilih negara Anda.',
  },
};
export default labels;
