const labels = {
  StaysList: {
    'Ref: Page title': 'Gorset',
    'Ref: Past': 'Przeszłość',
    'Current and future stays': 'Aktualne i przyszłe pobyty',
    'filter label: period': 'Okres',
    'Ref: Search': 'Szukaj',
  },
  StayDetails: {
    'Ref: Page title': 'Szczegóły Twojego pobytu',
    'Arrival date': 'Data przybycia',
    'Departure date': 'Data odjazdu',
    'Room number': 'Pokój',
    'Reservation number': 'Numer rezerwacji',
    CheckIn: 'Zameldować się',
    CheckOut: 'Wymeldować się',
    CheckIn_1: 'Zamelduj się w {lodgeName}, przyjeżdżasz dzisiaj? (Numer rezerwacji {resNumber})',
    CheckedIn_1: 'Jesteś zameldowany w {lodgeName}, pokój {roomNumber}.',
    CheckOut_1:
      'Wymelduj się {lodgeName} Numer rezerwacji {resnumumber} teraz? Twój klucz do pokoju zostanie dezaktywowany.',
    CheckedOut_1: '{lodgeName} Numer rezerwacji {resNumber} został sprawdzony.',
  },
  StaysSuccess: {
    'Ref: Title': 'Dziękuję za pobyt!',
    'Ref: Body': 'Twoja kasa została pomyślnie przetworzona.',
    'Ref: Go to home': 'Idź do domu',
    'Ref: Provide a feedback': 'Przekaż informacje zwrotne',
  },
};
export default labels;
