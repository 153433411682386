import { IonButton, IonItem } from '@ionic/react';
import classnames from 'classnames';
import { useCallback, useEffect, useState, useRef } from 'react';

import { Ellipsis } from '../../../assets/icons';
import useStateWithProps from '../../../helpers/hooks/useStateWithProps';
import useToggle from '../../../helpers/hooks/useToggle';

import { MultiCardSelectProps } from './MultiCardSelect.types';

import styles from './MultiCardSelect.module.css';

const MultiCardSelect = ({
  options,
  onChange,
  isMultiple = true,
  values = [],
  isBoldLabel = true,
  columns = 3,
  columnWidth,
  nbItemsInReducedView = 5,
  labelSeeMore = 'See more',
  labelSeeLess = 'See less',
  selectOnLoad = false,
  preventUnselect = false,
  iconOnTheLeft = false,
  name = '',
  optionClassName,
  "data-testid": testId,
}: MultiCardSelectProps) => {
  const [focusedItem, setFocusedItem] = useState<string>();
  const { state: isMaximizedView, toggle: toggleView } = useToggle(false);
  const [selected, setSelected] = useStateWithProps<string[]>(values);
  const optionsInReducedView = options.slice(0, nbItemsInReducedView - 1);
  const optionsInMaximizedView = options;
  const hasMoreToShow = optionsInReducedView.length < optionsInMaximizedView.length;

  const displayedOptions = isMaximizedView ? optionsInMaximizedView : optionsInReducedView;
  const seeLabel = isMaximizedView ? labelSeeLess : labelSeeMore;

  const onOptionClick = useCallback(
    (value: string) => {
      const isSelectedPresent = selected.includes(value);
      const multipleSelected = isSelectedPresent
        ? selected.filter((s) => s !== value)
        : [...selected, value];
      const singleSelected = isSelectedPresent && !preventUnselect ? [] : [value];

      if (preventUnselect && !isMultiple && !singleSelected?.length) return;

      const newSelected = isMultiple ? multipleSelected : singleSelected;

      setSelected(newSelected);
      onChange({
        value,
        isSelected: !isSelectedPresent,
        allSelected: newSelected,
        prevValue: singleSelected ? singleSelected[0] : undefined,
      });
    },
    [isMultiple, onChange, preventUnselect, selected, setSelected]
  );

  useEffect(() => {
    selectOnLoad &&
      values.forEach((value) => {
        onOptionClick(value);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function selectDefaultOptionIfJustOneOption() {
      const isEmptyValue = !selected.length || (selected.length === 1 && selected[0] === '');
      const haveToSelectFirstValueToPreventUnselected =
        preventUnselect && options.length === 1 && isEmptyValue;

      if (haveToSelectFirstValueToPreventUnselected) {
        onOptionClick(options[0].value);
      }
    },
    [onOptionClick, options, preventUnselect, selected]
  );

  const firstNewCardRef = useRef<HTMLIonItemElement>(null);
  const moreButtonRef = useRef<HTMLIonItemElement>(null);

  useEffect(() => {
    if (isMaximizedView && firstNewCardRef.current) {
      // focus first new button after click on see more
      setTimeout(() => firstNewCardRef.current?.focus(), 0);
    } else if (!isMaximizedView && moreButtonRef.current) {
      // focus see more after click on see less
      setTimeout(() => moreButtonRef.current?.focus(), 0);
    }
  }, [isMaximizedView]);

  return (
    <div
      data-testid={`${testId}-multicard-wrapper`}
      className={classnames(styles.wrapper)}
      style={{
        gridTemplateColumns: `repeat(${columns}, ${columnWidth ?? '1fr'})`,
      }}
    >
      {(displayedOptions || []).map((option, i) => {
        return (
          <div
            data-testid={`${testId}-multicard-displayed-options`}
            key={option.value}
            className={classnames(styles.buttonContainer, {
              [styles.focused]: focusedItem === option.value,
            })}
          >
            <IonItem
              className={classnames(['ion-no-padding', styles.ionItem])}
              tabIndex={0}
              {...(i === nbItemsInReducedView - 1 ? { ref: firstNewCardRef } : {})}
            >
              <IonButton
                size="small"
                expand="full"
                shape="round"
                onClick={() => onOptionClick(option.value)}
                onFocus={() => setFocusedItem(option.value)}
                onBlur={() => setFocusedItem(undefined)}
                className={classnames(styles.item, {
                  [styles.isSelected]: selected.includes(option.value),
                })}
                aria-pressed={selected.includes(option.value)}
                data-testid={`multi-card-select-item-${option['data-testid']}`}
              >
                <div
                  className={
                    iconOnTheLeft ? classnames(styles.contentRow) : classnames(styles.contentColumn)
                  }
                  key={option.value}
                >
                  {option.icon && (
                    <div aria-hidden="true" className={styles.iconWrapper}>
                      {option.icon}
                    </div>
                  )}
                  <div
                    className={classnames(
                      optionClassName,
                      isBoldLabel ? styles.labelWrapper : styles.labelWrapperNotBold
                    )}
                  >
                    {option.label}
                  </div>
                  <p className="sr-only">{name}</p>
                </div>
              </IonButton>
            </IonItem>
          </div>
        );
      })}
      {hasMoreToShow ? (
        <div data-testid={`${testId}-multicard-more`} className={styles.buttonWrapper} key={`isReduced-${isMaximizedView}`}>
          <IonItem
            className={classnames(['ion-no-padding', styles.ionItem])}
            tabIndex={0}
            ref={moreButtonRef}
          >
            <IonButton
              size="small"
              expand="full"
              shape="round"
              onClick={toggleView}
              className={classnames(styles.item)}
            >
              <div className={classnames(styles.content)}>
                {!isMaximizedView && (
                  <div className={styles.iconWrapper}>
                    <Ellipsis />
                  </div>
                )}
                <div className={styles.labelWrapper}>{seeLabel}</div>
                <p className="sr-only">{name}</p>
              </div>
            </IonButton>
          </IonItem>
        </div>
      ) : null}
    </div>
  );
};

export default MultiCardSelect;
