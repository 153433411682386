const labels = {
  StaysList: {
    'Ref: Page title': 'Corsé',
    'Ref: Past': 'Pasado',
    'Current and future stays': 'Estancias actuales y futuras',
    'filter label: period': 'Período',
    'Ref: Search': 'Búsqueda',
  },
  StayDetails: {
    'Ref: Page title': 'Detalles de tu estancia',
    'Arrival date': 'Fecha de llegada',
    'Departure date': 'Fecha de salida',
    'Room number': 'Habitación',
    'Reservation number': 'Número de reserva',
    CheckIn: 'Registrarse',
    CheckOut: 'Verificar',
    CheckIn_1: 'Check in {lodgeName}, llegando hoy? (Número de reserva {resNumber})',
    CheckedIn_1: 'Se registra en {lodgeName}, Habitación {roomNumber}.',
    CheckOut_1:
      'Hacer el Check out {lodgeName} Número de reserva {resNumber} ahora? La llave de su habitación se desactivará.',
    CheckedOut_1: '{lodgeName} Número de reserva {resNumber} se ha hecho el check out',
  },
  StaysSuccess: {
    'Ref: Title': '¡Gracias por tu estancia!',
    'Ref: Body': 'Su pago se ha procesado con éxito.',
    'Ref: Go to home': 'Ir a casa',
    'Ref: Provide a feedback': 'Proporcionar comentarios',
  },
};
export default labels;
