import { MenusActions } from '../types/menusActions.types';
import { MenusState } from '../types/menusState.types';

const defaultState = {
  foodFacilities: [],
  menus: [],
  locks: {},
};

const menusReducer = (state: MenusState = defaultState, action: MenusActions): MenusState => {
  switch (action.type) {
    case 'RESET_STATE':
      return defaultState;
    case 'GET_FOOD_FACILITIES_REQUEST':
      return {
        ...state,
        locks: {
          ...state.locks,
          getFoodFacilities: true,
        },
      };
    case 'GET_FOOD_FACILITIES_SUCCESS':
      return {
        ...state,
        foodFacilities: action.foodFacilities,
        locks: {
          ...state.locks,
          getFoodFacilities: false,
        },
      };
    case 'GET_FOOD_FACILITIES_FAILURE':
      return {
        ...state,
        locks: {
          ...state.locks,
          getFoodFacilities: false,
        },
      };
    case 'FETCH_PRODUCTS_REQUEST':
      return {
        ...state,
        locks: {
          getOffering: true,
        },
      };
    case 'FETCH_PRODUCTS_SUCCESS':
      return {
        ...state,
        menus: action.menus,
        locks: {
          getOffering: false,
        },
      };
    case 'FETCH_PRODUCTS_FAILURE':
      return {
        ...state,
        locks: {
          getOffering: false,
        },
      };
    default:
      return state;
  }
};

export default menusReducer;
