import MM from '../../services/ModulesManager';
import { store } from '../../store';

import { Service } from './types/State.types';

const api = {
  getActiveSiteId: () => {
    const state = store.getState();
    const site = state.Core.context.site;

    if (site) return site.id;

    return undefined;
  },

  getActiveSiteCurrency: () => {
    const state = store.getState();
    const site = state.Core.context.site;
    return site?.currency;
  },

  createPriceFormatter: () => {
    const state = store.getState();
    const site = state.Core.context.site;

    if (!site?.currency?.isoCode) return;

    const formatterOptions = {
      style: 'currency',
      currency: site.currency.isoCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    try {
      return new Intl.NumberFormat(state.Shared.language.currentLanguageCode, formatterOptions);
    } catch (e) {
      return new Intl.NumberFormat('en', formatterOptions);
    }
  },

  getActiveSite: () => {
    const state = store.getState();
    const site = state.Core.context.site;

    if (site) return site;
  },

  getComponent: (moduleId: string, componentName: string) => {
    let component;

    try {
      component = require('../' + moduleId + '/containers/' + componentName);
      return component;
    } catch (e) {
      try {
        component = require('../' + moduleId + '/components/' + componentName);
        return component;
      } catch (err) {
        console.log('component ' + componentName + ' not found.');
        return component;
      }
    }
  },

  getSetupOptions: (id: string, idType = 'moduleId') => {
    //if idType is not moduleId, default to serviceType
    let serviceType = id;
    if (idType === 'moduleId') {
      const module = MM().getModuleById(id);
      if (!module) return [];

      serviceType = module.serviceType;
    }

    try {
      return (
        store.getState().Core.services.list.find((el: Service) => el.name === serviceType)
          .setupOptions || []
      );
    } catch (e) {
      return [];
    }
  },

  getContentPageIdByNavItemName: (navItemName: string) => {
    const state = store.getState();
    const service = state.Core.services.list.find((el: Service) => {
      return el.name === 'ContentPage' && el.navigation?.name === navItemName;
    });

    if (!service) return undefined;

    return service.contentPageId;
  },

  getAvailableSiteServices: () => {
    const state = store.getState();
    return state.Core.services.list;
  },
};

export default api;

export type ApiType = typeof api;
export type ApiKeys = keyof ApiType;
