const labels = {
  All: {
    'Ref: See item': 'Viz položka',
    'Ref: Print': 'Vytiskněte potvrzení',
    'Ref: Email': 'Obdržíte potvrzení e-mailem',
    'Ref: Receipt Modal message': 'Jak byste chtěli získat potvrzení?',
    'Ref: Loyalty Reward': 'Věrnostní odměna',
    'Ref: Equation for stamps per item': '1 nákup = 1 razítko',
    'Ref: Paid by employer': 'Částka zaplacená vašim zaměstnavatelem',
    'Ref: Paid by user': 'Částka zaplacená vami',
  },
  ProductsList: {
    'Ref: Page title': 'Seznam produktů',
    'Ref: Facility details': 'Podrobnosti zařízení',
    'Ref: Search products list': 'Vyhledávání položek',
    'Ref: filter label: menus': 'Jídelní lístek',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Kategorie',
    'Ref: all': 'Všechno',
    'Ref: today': 'Dnes',
    'Ref: tomorrow': 'Zítra',
    'Ref: see more': 'Vidět víc',
    'Ref: see less': 'Vidět méně.',
    'Ref: reset': 'Resetovat',
    'Ref: View in cart': 'Zobrazit v košíku',
    'Ref: You have changed location': 'Změnili jste umístění',
    'Ref: You are now at': 'Nyní jste na',
    'Ref: Menu card': 'Karta nabídky',
    'Ref: Table': 'Číslo tabulky',
    'Service unavailable': 'Služba je nedostupná',
    'Ref: Ordering unavailable':
      'Opatrně! Objednávání na tomto webu není v tuto chvíli k dispozici, zkuste to později.',
    'Ref: Filters': 'Filtry (alergeny a další)',
  },
  Order: { 'Ref: Page title': 'Objednat' },
  MenuSelector: {
    'Ref: Menu Page title': 'Menu',
    'Ref: today': 'Dnes',
    'Ref: tomorrow': 'Zítra',
    'Ref: Search products list': 'Vyhledávání položek',
    'Ref: You have changed location': 'Změnili jste umístění',
    'Ref: You are now at': 'Nyní jste na',
  },
  ProductDetails: {
    'Ref: Page title': 'Detaily produktu',
    'Ref: Serving Size': 'Velikost porce',
    'Ref: Nutrition': 'Nutriční informace',
    'Ref: Nutrition adults': 'Dospělí potřebují kolem roku 2000 kcal denně',
    'Ref: Nutrition Calories': 'Kalorie',
    'Ref: Nutrition Calcium': 'Vápník',
    'Ref: Nutrition Fat': 'Tlustý',
    'Ref: Nutrition Saturated Fat': 'Nasycený tuk',
    'Ref: Nutrition Carbohydrates': 'Sacharidy',
    'Ref: Nutrition Sugar': 'Cukr',
    'Ref: Nutrition Protein': 'Protein',
    'Ref: Nutrition Sodium': 'Sodík',
    'Ref: Nutrition cholesterol': 'Cholesterol.',
    'Ref: Nutrition dietaryFiber': 'Vláknina',
    'Ref: Nutrition iron': 'Žehlička',
    'Ref: Nutrition potassium': 'Draslík',
    'Ref: Nutrition vitaminA': 'Vitamin A.',
    'Ref: Nutrition vitaminC': 'Vitamín C',
    'Ref: Additives': 'Aditiva',
    'Ref: Emission': 'emise CO2',
    'Ref: This product generates': 'Tento výrobek generuje',
    'Ref: of CO2': 'CO2.',
    'Ref: emissions, which equals a': 'emise, které se rovná',
    'Ref: car trip': 'výlet do auta',
    'Ref: Ingredients': 'Receptová ingredience',
    'Ref: Allergens': 'Alergens',
    'Ref: Always there': 'Vždy tam',
    'Ref: Allergens selected': 'Alergeny vybrané na filtru:',
    'Ref: minimum required': 'minimální požadované',
    'Ref: you have to add at least': 'Musíte přidat alespoň',
    'Ref: ingredients': 'ingredience',
    'Ref: Ingredient': 'přísada',
    'Ref: Free': 'Volný, uvolnit',
    'Ref: Based on customization': 'Na základě vašeho přizpůsobení',
    'Ref: Additives eggs': 'vejce',
    'Ref: Additives fish': 'Ryba',
    'Ref: Additives milk': 'mléko',
    'Ref: Additives nuts': 'ořechy',
    'Ref: Additives wheat': 'pšenice',
    'Ref: Additives peanuts': 'arašídy',
    'Ref: Additives soy beans': 'sójové fazole',
    'Ref: Additives sesame seeds': 'sezamová semínka',
    'Ref: Review': 'Posouzení',
    'Ref: Per portion': 'Na část',
    'Ref: Per 100g': 'Na 100 g',
    'Ref: Allergen selected': 'Alergen vybraný na vyhledávacím filtru',
    'Ref: Fitbit Log': 'Přihlaste se k Fitbit',
    'Ref: age verification required on delivery': 'Při dodání je nutné ověření věku',
    'Ref: alcohol content notification':
      'Umístěním a objednáním na jednu z těchto položek prohlašujete, že jste ve věku 18 a více let. Zneužívání alkoholu je pro vaše zdraví nebezpečné.',
    'Ref: default': 'Výchozí',
    'Ref: Loyalty Card Title Stamp':
      '{no_stamps} {stamp_form} vydělal, pokud si objednáte tento produkt',
    'Ref: Loyalty Card Description':
      '{stamp_form} bude přidán k následující loajalitě {program_form}: <b> {scheme_names} </b>',
    'Ref: About Loyalty stamps':
      'Program věrnostních známek umožňuje shromažďovat známky pro odměny.',
    'Ref: Loyalty Modal Description':
      'Když si koupíte <b> {product_name}, bude 1 razítko </b> přidáno do <b> {scheme_names} </b> {program_form}',
    'Ref: and': 'a',
    'Ref: Stamp': 'Razítko',
    'Ref: Stamps': 'Známky',
    'Ref: Program': 'Program',
    'Ref: Programs': 'Programy',
    'Ref: Loyalty Stamps': 'Věrnostní známky',
    'Ref: See Loyalty Stamps': 'Viz věrnostní známky',
    Favorite: 'Oblíbený',
    'Ref: In favorites': 'Produkt je již v seznamu oblíbených. Kliknutím jej odstraníte.',
    'Ref: Not in favorites': 'Produkt ještě není v seznamu oblíbených. Kliknutím přidejte jej.',
    'Ref: Added to favorites': 'Produkt byl přidán do seznamu oblíbených.',
    'Ref: Removed from favorites': 'Produkt byl odstraněn ze seznamu oblíbených.',
    'Ref: From': 'Z',
  },
  OrderHistory: { 'Ref: Orders': 'Objednávky', 'Ref: Page Title': 'Mé objednávky' },
  MenuTile: { 'Ref: Scan&Go': 'Scan & Go', 'Ref: View only': 'Zobrazit pouze' },
  ProductTile: {
    'Ref: Allergens': 'Alergeny',
    'Ref: Presence of allergen': 'Přítomnost alergenu',
    'Ref: Scan': 'Skenovat',
    'Ref: From': 'Z',
    'Ref: Already in product favorite list. Click to remove it.':
      'Již v seznamu oblíbených produktů. Kliknutím jej odstraníte.',
    'Ref: Currently not added to product favorite list. Click to add it.':
      'V současné době není přidáno do seznamu oblíbených produktů. Kliknutím přidejte jej.',
    'Ref: Free': 'Volný, uvolnit',
  },
  MenusWidget: {
    'Ref: no menu': 'Žádné menu pro tuto chvíli',
    'Ref: Modal title': 'Nabídka pro den',
  },
  TransactionalFeedbackModal: {
    'Ref: Pickup': 'Vyzvednout',
    'Ref: Eat-in': 'Jíst',
    'Ref: Scan&Go': 'Scan & Go',
    'Ref: Delivery': 'dodávka',
    'Ref: Total': 'Celkový',
    'Ref: How was facility name service': 'Jaká byla služba {facility_name}?',
    'Ref: How do you rate the service': 'Jak hodnotíte službu?',
  },
};
export default labels;
