import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { isIosMobile } from '../../../helpers/misc';
import { State } from '../../../types/state.types';

import getServiceName from '@/helpers/getServiceName.helper';

type UseTrackSiteVisitProps = {
  contactId: string;
  site?: { id: string; name: string };
  contract?: { id: string; name: string };
  language: string;
};

const useTrackSiteVisit = (props: UseTrackSiteVisitProps) => {
  const { contactId, site, contract, language } = props;

  const useDataTracking = useSelector((state: State) => state.Core.user?.useDataTracking);
  const location = useLocation();
  const appInsights = useAppInsightsContext();

  const trackSiteVisit = useCallback(() => {
    if (site && location) {
      let eventLog = {
        properties: {
          siteId: site.id,
          siteName: site.name,
          contactId: contactId,
          contractId: contract?.id,
          contractName: contract?.name,
          language: language,
          appName: process.env.REACT_APP_APP_NAME,
          appVersion: process.env.REACT_APP_BUILD_NUMBER,
          appPackage: process.env.REACT_APP_MOBILE_PACKAGE_NAME,
          module: getServiceName(location?.pathname || ''),
        },
      };
      appInsights.trackPageView(eventLog);
    }
  }, [contactId, site, contract, language, location, appInsights]);

  const canUseAnalytics = useMemo((): boolean => {
    if (isIosMobile()) {
      return useDataTracking || false;
    } else {
      return true;
    }
  }, [useDataTracking]);

  useEffect(() => {
    if (canUseAnalytics) {
      trackSiteVisit();
    }
  }, [canUseAnalytics, trackSiteVisit]);
};
export default useTrackSiteVisit;

export { getServiceName };
