const labels = {
  All: {
    'starts at': 'מתחיל ב',
    'ends at': 'מסתיים ב',
    location: 'מקום',
    'coming date': 'תאריך תאריך',
    'Ref: Currently event not preferred. Click to make preferred':
      'נכון לעכשיו האירוע לא מועדף. לחץ כדי להפוך את העדיף',
    'Ref: Currently event preferred. Click to make not preferred':
      'כרגע האירוע מועדף. לחץ כדי להפוך את לא להעדיף',
  },
  EventDetails: {
    details: 'פרטים',
    'Ref: Page title': 'פרטי האירוע',
    'Event dates': 'תאריכי אירוע',
    'See online:': 'ראה באינטרנט:',
    'See all dates': 'ראה את כל התאריכים',
    'Remove from my selection': 'הסר מבחר שלי',
    'Add to my selection': 'הוסף למועדפים שלי',
    'Ref: explain calendar usage':
      'תאריכי האירועים מודגשים בלוח השנה שלהלן. אל תחמיצו את האירועים שאתה אוהב! לחץ על תאריך אירוע בלוחנדר כדי להוריד הזמנה. לאחר מכן פתח את הקובץ שהורדת כדי להוסיף את האירוע ללוח השנה המתוכנן שלך.',
    'Ref: Favorite': 'אהוב',
    'Ref:Feedback': 'מָשׁוֹב',
    'Ref: Event site different title': 'אירוע זה אינו שייך לאתר הנוכחי שלך',
    'Ref: Event site different description':
      'אתה צופה באירוע שאינו שייך לאתר הנוכחי שלך. לא תוכל לראות את זה ברשימת האירועים.',
    'Ref: See all dates': 'ראה את כל התאריכים',
    'Ref: From': 'מ',
    'Ref: to': 'ל',
    'Ref: Coming date': 'תאריך הקרוב',
    'Ref: Add to calendar': 'הוסף ללוח השנה',
  },
  EventsList: {
    'Ref: Page title': 'אירועים',
    'All dates': 'כל התאריכים',
    'All events': 'כל האירועים',
    'Ref: Search': 'לחפש',
    'My selection': 'המועדף שלי',
    'filter label: date': 'תַאֲרִיך',
    'filter label: coming events': 'אירועים מגיעים',
    'filter label: past events': 'אירועי העבר',
    'filter label: interest': 'הַעֲדָפָה',
  },
  Widget: {
    'Ref: Carousel title': 'אירועים',
    'No info yet, come back later': 'אין עדיין מידע, חזור אחר כך 😉',
  },
};
export default labels;
