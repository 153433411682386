import Title, { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import ContentTitle from '../../../../components/molecules/ContentTitle/ContentTitle';
import { ContentPageDetail } from '../../types';

export default function ContentPageTitle({
  contentPage,
  className,
}: {
  contentPage: ContentPageDetail;
  className?: string;
}) {
  return (
    <ContentTitle
      data-testid="content-tile"
      title={
        <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINES}>
          {contentPage.name}
        </Title>
      }
      className={className}
    />
  );
}
