const labels = {
  All: {
    Feedback: 'フィードバック',
    'Ref: Switch site component intro sentence':
      'フィードバックはサイト{site name}にリンクされます。',
    Category: 'カテゴリー',
    Subject: '件名',
    Comment: 'コメント',
    'Date of your experience': '発生日 *',
    'Ref: Hint': 'あなたは何か問題がありますか？',
    'Ref: Log Request': 'リクエストを記録する',
    'Ref: Describe issue': 'あなたが受けたことについて教えてください',
    Date: '日にち',
    'Ref: Share your opinion header':
      '職場でのあなたの経験についてのあなたの意見を共有してください',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'レストラン /カフェ',
    'Desk / workstation area': 'デスク /ワークステーションエリア',
    'Meeting room facilities': '会議室の設備',
    'Coffee/tea facilities (pantry)': 'コーヒー/紅茶施設（パントリー）',
    'Restrooms / washrooms': 'トイレ /洗面所',
    'Reception / Security': 'レセプション /セキュリティ',
    'Conference center': 'カンファレンスセンター',
    'Fitness center': 'フィットネスセンター',
    Mailroom: 'メールルーム',
    'Bicycle room': '自転車室',
    'Shuttle services': 'シャトルサービス',
    Lockers: 'ロッカー',
    Helpdesk: 'ヘルプデスク',
  },
  Create: {
    'Tell us!': '教えてください！',
    'Ref: All Ears': '私たちはすべて声に耳を傾けます！',
    'Ref: Provide Description': '概要を入力',
    'Ref: Fill-in-the-blank sentence': '{1}に関して{0}を提供したい',
    'Ref: Link text to service request module': 'リクエスト内容または問題をご記入ください。',
    'Complaint type': '苦情{絵文字}',
    'Feedback type': 'フィードバック{絵文字}',
    'Compliment type': '賛辞{絵文字}',
  },
  CreateFailure: {
    'Ref: Body':
      'エラーが発生し、フィードバックは送信されませんでした。このエラーが解決しない場合は、ヘルプデスクに連絡してください。',
  },
  CreateSuccess: {
    'Ref: Body':
      'あなたのフィードバックが提出されました。サポートチームで拝見し、必要に応じご連絡いたします。',
    'New feedback': '新しいフィードバック',
  },
  CreateFlow: {
    Description: '説明',
    'Ref: Provide Description': '日付と説明を教えてください',
    'Ref: I would like to provide a': 'aを提供したいのですが',
    'Ref: in regards to': 'に関して',
    'Ref: &': '＆',
    'Ref: at': 'で',
    'Ref: on the': 'に',
  },
};
export default labels;
