import { useHistory } from 'react-router';

import NoticePage from '../../../../components/templates/NoticePage/NoticePage';
import { pagePaths } from '../../config';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';

import { NOTICEPAGE_TYPE } from '@/components/templates/NoticePage/NoticePage.types';

const AccountPasswordChangeFailure = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);

  return (
    <NoticePage
      type={NOTICEPAGE_TYPE.ERROR}
      withNavBar={false}
      title={label('Something went wrong', { textTransform: 'capitalize' })}
      content={label('Ref: Error message', {
        textTransform: 'capitalize',
      })}
      actions={[
        {
          label: label('Change password', { textTransform: 'capitalize' }),
          action: () => history.push(pagePaths.AccountPasswordChange),
        },
        {
          label: label('Go to home', { textTransform: 'capitalize' }),
          action: () => history.push('/'),
        },
      ]}
    />
  );
};

export default AccountPasswordChangeFailure;
