const labels = {
  All: {
    Region: '地域または国',
    'Ref: Geography': 'あなたの国または地域を選択してください',
    'Ref: Country': '国',
    'Ref: Country tooltip': '場所はサイトの国に対応しています。',
    'Ref: Please select your country': 'あなたの国を選択してください',
    'First name': '名前',
    'Last name': '苗字',
    'Ref: Full name': 'フルネーム',
    'Mobile number': '携帯番号',
    'Mobile phone': '携帯電話',
    Email: '電子メールアドレス',
    Password: 'パスワード',
    logout: 'ログアウト',
    'Not a new user? Log in': '登録済みですか？ログイン',
    'do not register, login': '登録しない。ログイン',
    'Ref: I agree': '同意します',
    Register: '登録',
    'Ref: My language': '私の言語',
    'Ref: Kiosk Fail Title': 'おっとっと！何かがうまくいかなかった！',
    'Ref: Kiosk Fail Description':
      '残念ながら、ご注文は成功しませんでした。もう一度やり直すか、スタッフにあなたを助けるように頼んでください。',
    'Ref: Kiosk Fail Back': '前のページに戻ります',
    'Ref: Kiosk Fail Action': 'セッションをクリアし、もう一度注文してください',
    Validate: '検証',
    'Ref: error, incorrect credentials': 'これらの資格情報は、選択された場所と一致しません。',
    'Ref: Forgot password?': 'パスワードをお忘れですか？',
    'Ref: Hello': 'こんにちは{wave_emoji}、ログインして続行してください',
    'Ref: Form title': '続行するためにサインアップしてください',
    'Already have an account? Login': 'すでにアカウントをお持ちですか？ログイン',
  },
  AccountPage: {
    'Ref: My Information': '登録情報',
    'Ref: Settings': '設定',
    'Ref: Email': 'Eメール',
    'Ref: Current site': '現在のサイト',
    'Ref: Choose site': 'サイトを選択',
    'Ref: Personal interests': '関心事',
    'Ref: Food preferences': '好きな食べ物',
    'Ref: Password': 'パスワード',
    'Ref: Page title': 'アカウント',
    'Ref: Language': '言語',
    'Ref: Communication preferences': 'コミュニケーション設定',
    'Ref: Terms & conditions': '利用規約',
    'Ref: Privacy policy': 'プライバシーポリシー',
    'Ref: Delete my account': 'アカウントを削除します',
    'Ref: Confirm deletion': 'アカウントを削除しますか？',
    'Ref: Request deletion': 'アカウントの削除をリクエストしたいですか？',
    'Ref: Deletion description': '削除は所属の規制に従って処理されます。',
    Placeholder: 'プレースホルダー',
    'Ref: Delete description': 'データは削除されます。このアクションは取り消せません。',
    delete: '削除',
    'Ref: Terms of sale': '販売条件',
    'Ref: Imprint': 'インプリント',
    'Ref: Cookie Settings': 'クッキー設定',
    'Ref: Support': 'サポート',
    'Ref: Register or login': '登録またはログインします',
    'Ref: Filtering Preferences': 'フィルタリング設定',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'アカウントを削除しますか？',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      '入力フィールドに「{word}」を書き、内容を確認します。このアクションは戻せません。',
    'Ref: Write "{word}"': '「{word}」と書く',
    'Ref: Delete my account': 'アカウントを削除します',
    'Ref: Words are not matching': '言葉は一致していません',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'あなたの場所を選択',
    'Ref: Choose location':
      'サイトを有効にしたり、QRコードをスキャン、もしくは名前で検索することですぐに場所を見つけられます。',
    'Ref: next step': '次へ',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'サインアップ',
    'Ref: identify your location': 'あなたの場所を特定してください。',
    'Ref: option 1 - scan QR': 'オプション1：登録QRコードをスキャンします',
    'Ref: option 2 - enter code': 'オプション2：あなたの場所のコードを入力してください',
    'Ref: button to open qr code scanner modal': 'QRコードがある：それをスキャンしましょう',
    'Ref: button to open site code input modal': 'コード付きのサイトを選択します',
    'Ref: getting help with site identifier - support desk':
      '続ける方法が不明な場合、サポートデスクにお問い合わせください。',
    'Ref: getting help with site identifier - support form':
      'あなたは私達にメモを送ることができます。',
    'Location code': 'ロケーションコード',
    'Ref: location code field placeholder': 'あなたの場所はここにあります',
    'Ref: Scanner header': 'QRコードを読み込みます',
    'Ref: site code input header': 'コード付きのサイトを追加します',
    'Ref: not valid code': 'このコードは、選択した場所では無効です',
  },
  AccountRegistrationForm: {
    'Ref: Page title': '登録',
    'Ref: collected info': '概要',
    'Ref: additional info': 'あなたの情報',
    'Confirm password': 'パスワードを確認',
    company: '会社名',
    location: '場所',
    username: 'ユーザーネーム',
    register: 'サインアップ',
    'Ref: password strength':
      'パスワードは8文字以上で、数字、大文字、小文字、特殊文字をそれぞれ1つ以上含む必要があります。',
    'passwords do not match': 'パスワードが一致しません',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'コミュニケーションの好みについて',
    'Ref: Things happen while you are away': 'あなたに通知が届きます',
    'Ref: Would you like to receive update about offers and promotions?':
      'オファーやプロモーションに関する最新情報を受け取りますか？',
    'Ref: Would you like to hear about content we think you may like?':
      'あなたの好みに合った情報を知りたいですか？',
    Yes: 'はい',
    No: 'いいえ',
    apply: '申し込み',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'アカウント詳細' },
  DeletionFailure: {
    'Ref: Body':
      'エラーが発生し、アカウント削除ができませんでした。このエラーが続いている場合は、ヘルプデスクにお問い合わせください。',
    'Go to account': 'アカウントに移動します',
  },
  GenericFailurePage: {
    'Ref: Title': '何かしら問題が発生しました。',
    'Ref: Body':
      '申し訳ありませんが、何かしら問題が発生しました。この問題が解決しない場合、ヘルプデスクご連絡ください。',
  },
  HomePage: {
    notifications: '通知',
    basket: 'カート',
    'Ref: Tabtitle': 'ホーム',
    'contact us': 'お問い合わせ',
    'Ref: You are logged in as a guest user': 'ゲストユーザーとしてログインしています',
    'Ref: Good morning': 'おはようございます',
    'Ref: Good afternoon': 'こんにちは',
    'Ref: Good evening before midnight': 'こんばんは',
    'Ref: Good evening after midnight': 'こんばんは',
    'Ref: Search': '検索',
    'Ref: products button aria': '製品内で{results_form}が見つかりました。',
    'Ref: menus button aria': 'メニュー内で{results_form}が見つかりました。',
    'Ref: facilities button aria': '施設内で{result_form}が見つかりました',
    'Ref: result': '結果',
    'Ref: results': '結果',
    'Ref: Menus': 'メニュー',
    'Ref: No results title': 'おっとっと！',
    'Ref: No results body': '検索に一致する結果はありません',
    'Ref: Products': '製品',
    'Ref: Facilities': '設備',
    'Ref: See more results': 'より多くの検索結果を参照してください',
    'Ref: Accessibility instructions':
      '検索の使用を開始するには、それをクリックするか、焦点を合わせている間にEnterを押す必要があります。',
    'Ref: See more': '続きを見る',
    'Ref: singular form of products': '製品',
    'Ref: singular form of menus': 'メニュー',
    'Ref: singular form of facilities': '施設',
    'Ref: Search results for': 'の検索結果',
  },
  LanguagePage: {
    apply: '申し込み',
    'My language': '言語',
    'Select in which language you would like to read our app': '表示する言語を選択してください',
    'Ref: Page title': '言語',
  },
  LegalDocPage: {
    Acknowledge: '認証する',
    'Ref: Legal changed notification title': '「{legal_doc_type}」が変更されました',
    'Ref: Legal changed notification content':
      'アプリの使用を続ける前に、利用規約を確認してください。',
  },
  LegalDocsPage: {
    'Ref: Page title': '利用規約',
    'Read privacy policy': 'プライバシーポリシーを読む',
    'Ref: Acknowledge legal docs': '私は利用規約を受け入れます。',
    'Ref: Read and understood': '利用規約を理解しました ',
    'Ref: No legal docs available - notice title': '近日公開',
    'Ref: No legal docs available - notice body':
      'すみません、このアプリケーションは現在あなたの場所では利用できないようです。',
    'Ref: The privacy Policy': 'プライバシーポリシー',
    'Ref: Legal changed notification title': '「{legal_doc_type}」が変更されました',
    'Ref: Legal changed notification content':
      'アプリの使用を続ける前に、それらを確認してください。',
  },
  LoginPage: {
    'Ref: Page title': 'ようこそ！ {app name}  へ！',
    'Ref: Page subtitle': '続行するにはサインインしてください',
    'Ref: Email field placeholder': 'あなたのEメール',
    'Ref: Email field note': '私たちはあなたのEメールを無用に共有しません。',
    'Ref: Password field title': 'あなたのパスワード',
    register: 'サインアップ',
    'I forgot my password.': 'パスワードをお忘れですか？',
    'I have trouble logging in': 'ログインに問題がありますか？',
    'start registration': '登録を開始します',
    'Ref: Register': '登録',
    'Ref: Login': 'ログイン',
    'Ref: Connection issue?': '接続の問題？',
    'Ref: Connection issue message':
      '登録またはログインに問題がある場合は、通常のオンサイトスタッフまたはヘルプデスクにお問い合わせください。',
  },
  WelcomePage: { 'Ref: Welcome to': 'こんにちは、{site_name}へようこそ', 'Ref: Begin': '始める' },
  NotificationsList: {
    'Ref: No notifications': '今のところ通知はありません',
    'Ref: This week': '今週',
    'Ref: Earlier': 'ついさっき',
    'Ref: Page title': '通知',
    'Ref: See more': '続きを見る',
    'Ref: Mark all read': 'すべての読み物をマークします',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'すべての通知を参照してください' },
  ServicesMenuPage: { 'Ref: Page title': 'サービス', navigate: 'ナビゲート' },
  DeletionSuccess: {
    'Ref: Title': 'アカウントの削除が要求されました',
    'Ref: Content': 'まもなく処理します。',
    'Log out': 'ログアウト',
  },
  TroubleLoggingInFormSuccessPage: {
    'Ref: Title': '折り返しご連絡いたします',
    'Ref: Body': '申し訳ありません。後ほどご連絡いたします。',
  },
  TroubleLoggingInPage: {
    'Ref: Page title': 'ログインに問題がありますか？',
    'Ref: Email field placeholder': '登録したメールアドレス',
    'Ref: Mobile phone field placeholder': '電話番号',
    Company: '会社',
    'Ref: Company field placeholder': '会社名',
    'Ref: Company field note': 'あなたが外部業者であれば、現在所属している会社を入力します。',
    Location: '都市や場所',
    'Ref: Location field placeholder': 'あなたの職場の場所',
    'Ref: Location field note': '住所がわからなければその地域の一般的な名称をご記入ください。',
    Comment: 'コメント',
    'Ref: Comment field placeholder': 'どのような問題ですか？',
    'Ref: Who is your employer?': 'あなたの雇用主は誰ですか？',
    'Ref: What village are you staying at?': 'どこに滞在していますか？',
    'Ref: Comment field note': '参考となる情報があればご記入ください。',
    'Ref: Feedback Content - geography': '地域',
    'Ref: Feedback Content - fullName': '名前',
    'Ref: Feedback Content - email': 'Eメール',
    'Ref: Feedback Content - phone': '電話',
    'Ref: Feedback Content - company': '会社名',
    'Ref: Feedback Content - city': '市区町村',
    'Ref: Feedback Content - whatVillage': '村',
    'Ref: Feedback Content - employer': '雇用者',
    'Ref: Feedback Content - comment': '問題点',
  },
  AccountStartPage: {
    'Ref: Header': '開始のためにあなたの場所を選びました',
    'Ref: Body':
      'ローカリゼーションをアクティブにしたり、QRコードをスキャンしたり、名前で検索したりして、すぐに場所を見つけます',
    'Ref: LetsGo button': '進む',
  },
  AccountOnboardingInformat: { 'Ref: Page title': '登録' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'QRスキャナーを備えたサイトを選択します',
    'Ref: Page title': '場所を選択してください',
    'Ref: Select site with code': 'コード付きのサイトを選択します',
    'Ref: Enter site code': 'サイトコードを入力します',
    'Ref: Scanner error header': 'サイトが見つかりません',
    'Ref: Not found': 'このコードは、選択した地域では無効です',
    'Ref: Generic error': '何かしら問題が発生しました',
    'All other sites': '他のすべてのサイト',
    'Sites in your country': 'あなたの国のサイト',
    'Ref: Unauthorized error': 'サイト制限レベルによる不正な要求',
    'Ref: Forbidden':
      '申し訳ありませんが、このサイトにアクセスするための適切な許可がありません。アクセスを手配するには、1800 992 777のピラバラサービスセンターにお問い合わせください',
    "Ref: We didn't find the site.": 'サイトは見つかりませんでした。',
    'Ref: We recommend some solutions:': 'いくつかの解決策をお勧めします：',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. QRスキャナーまたは以下のボタンの助けを借りてサイトを追加してみてください',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2.正しい国を選択してログアウトしてログインします',
    'Ref: The currently selected country is:': '現在選択されている国は次のとおりです。',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': '登録',
    'Confirm password': 'パスワードを認証する',
    'Email is not valid': '電子メールは無効です',
    'passwords do not match': 'パスワードが一致しません',
    'Ref: Password strength':
      'パスワードは少なくとも8文字の長さで、少なくとも1つの数字、1つの大文字、1つの小文字、1つの特別な文字が含まれている必要があります。',
    'Sign up': 'サインアップ',
    'Continue as guest': 'ゲストとして続けます',
    'Already have an account? Login': 'すでにアカウントをお持ちですか？ログイン',
    'Ref: Registration success title': '登録ありがとうございます',
    'Ref: Registration success content': 'アカウントの作成を確認するメールが届きます。',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'ログイン/サインアップしてください',
    'Ref: Password strength':
      'パスワードは8文字以上で、数字、大文字、小文字、特殊文字をそれぞれ1つ以上含む必要があります。',
    Stamps: 'スタンプ',
    'Order history': '注文履歴',
    'to earn points': 'ポイント獲得',
    'to see order history': '注文履歴',
    'to place an order': '注文するには',
    'Sign up': 'サインアップ',
    Login: 'ログイン',
    'Email is not valid': '電子メールは無効です',
    'passwords do not match': 'パスワードが一致しません',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'ありがとうございました',
    'Ref: Body':
      '入力したメールアドレス宛に確認メールが届きます。このメールを開き、リンクをクリックしてアカウントをアクティブにします。',
    'Ref: Enter': '継続する',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'エラーが発生しました',
    'Ref: Generic Error Body': '後でもう一度やり直すか、ヘルプデスクに連絡してください。',
    'Ref: Missing IndexDb support':
      '申し訳ありませんが、IncognitoモードまたはIndexDBサポートなしでアプリケーションにアクセスすることはできません。別のブラウザで再試行してください。',
    'Ref: Resource not found': 'アクセスしようとしているコンテンツは存在しません。',
    'Ref: Permission denied - SITE_HIDDEN':
      'アクセスしようとしているコンテンツは、プライベートサイトに属します。',
    'Ref: Permission denied - CONTRACT_DIFFERENT':
      'アクセスしようとしているコンテンツは、アカウントに対して許可されていません。',
    'Ref: TabTitle': 'エラー',
    'Ref: Site link invalid':
      'このリンクは無効です。別のサイトを選択するか、問題が続く場合はヘルプデスクに連絡します。',
    'Ref: Access Denied': 'アクセスが拒否されました。ユーザー権限が不十分です。',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'おっとっと！ {robot_emoji}',
    'Ref: Error title': '何かがうまくいかなかった！',
    'Ref: Error body':
      '問題が続いている場合は、このページを更新するか、ヘルプデスクに連絡してください。',
    'Ref: Error ID:': 'エラーID：{errorId}',
    'Ref: Back to home': '家に帰る',
  },
  Child: {
    'Ref: Route Not Found':
      'あなたが探しているサービスはこのサイトでは利用できません。後でもう一度やり直すか、ヘルプデスクに連絡してください。',
  },
  AccountValidation: {
    'Ref: Password changed message':
      '新しいパスワードが正常に保存されました。アカ​​ウントからすべてのサービスにアクセスできるようになりました。',
    Welcome: 'いらっしゃいませ',
    'Ref: Welcome message': '登録が完了し、すべてのサービスをお楽しみいただけます。',
  },
  PasswordChange: {
    'Change Password': 'パスワードを変更する',
    Welcome: 'いらっしゃいませ',
    'Ref: Password message': 'パスワードは十分に安全ではないため、変更する必要があります',
  },
  AccountPasswordChange: {
    'Change your password here': 'ここでパスワードを変更します',
    'Your old password': '古いパスワード',
    'Type in your new password': '新しいパスワードを入力します',
    'Re-type in your new password': '新しいパスワードの再入力',
  },
  AccountPasswordChangeSuccess: { 'Your password has been changed': 'パスワードは変更されました' },
  AccountPasswordChangeFailure: {
    'Ref: Error message':
      'エラーが発生したため、変更は保存されませんでした。もう一度やり直してください。',
  },
  PasswordForgotten: {
    'Ref: Page title': 'パスワードを再設定する',
    'Ref: Provide email':
      '{app_name}に登録するときに使用されるメールアドレスを提供してください。パスワードをリセットするためにメールが送信されます。',
    'Ref: Something happened': '何か問題が発生しました。後でもう一度やり直してください。',
  },
  ResetEmailSent: {
    'Ref: New email sent': '新しいメールを送信しました。再送信するには30秒待ってください。',
    'Go to login page': 'ログインページに移動します',
    'Resend email': 'メールを再送',
    'Ref: Email sent title': 'パスワードリセットメールが送信されました',
    'Ref: TabTitle': 'パスワードを再設定する',
    'Ref: Email sent msg':
      'リセットリンクはアドレス{email_address}に送信されました。再送信するには30秒お待ちください。',
  },
  ResetPassword: { 'Enter your new password': '新しいパスワードを入力します' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'パスワードが正常に変更されました',
    'Go to login page': 'ログインページに移動します',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'パスワードリセットに失敗します',
    'Ref: Error': 'エラーが発生しました。もう一度やり直してください',
    'Reset your password': 'あなたのパスワードをリセット',
    'Go to login page': 'ログインページに移動します',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'プライバシーポリシー',
    'Ref: Privacy Policy Body':
      'このプラットフォームにアクセスすることにより、Sodexo SAとローカルSodexoエンティティは、少なくともアカウントを管理し、プラットフォームを介して利用可能なサービスとオファーを提供するために、個人データを処理します。個人データの処理、誰がそれにアクセスするか、および個人データの権利を行使する方法に関する詳細情報が必要な場合は、以下のプライバシーポリシーをご覧ください。',
    'Ref: Continue': '続く',
    'Ref: Read Privacy Policy': 'プライバシーポリシーをお読みください',
  },
  AppUpdatePage: { 'Ref: GoToStore': '店に行く' },
  SharePage: { 'Ref: Select a site': 'サイトを選択します' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'プライバシーポリシーを表示するには、お客様の国を選択してください。',
  },
};
export default labels;
