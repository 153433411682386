const labels = {
  Success: {
    'Ref: Body':
      'Terima kasih telah menyelesaikan survei ini, waktu Anda sangat berharga karena survei ini membantu meningkatkan layanan kami.',
    'Ref: Tabtitle': 'Survei selesai',
    'Take another survey': 'Ambil survei lain',
  },
  Survey: {
    'Ref: Page title': 'Detail Survei',
    'Please answer all mandatory questions as identify by an asterisk':
      'Harap jawab semua pertanyaan wajib sebagai identitas oleh tanda bintang: *',
    'Ref: Take another survey': 'Ambil survei lain',
    'Ref: Go to Home': 'Pergi ke rumah',
  },
  SurveysList: {
    'Ref: Page title': 'Survei',
    'Ref: Available until': 'Tersedia sampai',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Anda sedang melihat survei untuk situs {site name}.',
  },
};
export default labels;
