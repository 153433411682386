import { TestingPropsV2 } from '../../../types';
import { Menu } from '../api/menuApi.types';

import { ImageInfo } from '@/modules/Facilities/types/types';

export type MenuListProps = {
  facilityMenuPath?: (
    menuId: string,
    facilityId: string,
    selectedDate: string,
    isMenuModule: boolean
  ) => string;
  menuType?: MenuType;
};

export type FacilityMenuItem = {
  id: string;
  title: string;
  name: string;
  sequence: number;
  image?: ImageInfo;
  type: FacilityType;
  menu: Menu;
};

export enum FacilityType {
  Undefined,
  FoodRetail,
  FoodNonRetail,
  NonFoodNonRetail,
  NonFoodRetail,
  ConferenceCatering,
}

export type MenuListFiltering = {
  filter_date?: FilteringItem;
};

export type FilteringItem = {
  [key: string]: boolean;
};

export type MenuSelectorListItem = {
  id: string;
  facilityId: string;
  facilityTitle: string;
  menu: Menu;
  menuImageSource?: string;
} & TestingPropsV2;

export type MenuTileProps = {
  selectedDate: string;
  focusOnRender?: boolean;
  facilityMenuPath?: (
    menuId: string,
    facilityId: string,
    selectedDate: string,
    isMenuModule: boolean
  ) => string;
} & MenuSelectorListItem;

export enum MenuType {
  All,
  Orderable,
  NonOrderable,
}

export interface IDateFilter {
  menu: Menu;
  id: string;
}

export type MenuSelectorRouteParams = {
  menuId?: string;
  date?: string;
};
