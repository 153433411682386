import classNames from 'classnames';

import { sanitizeHtml } from '../../../helpers/misc';

import { RenderContentProps } from './RenderContent.types';

import styles from './RenderContent.module.css';

const HtmlContent = ({
  children,
  'data-cy': dataCy = 'html-content',
  'data-testid': testId,
}: RenderContentProps) => {
  return (
    <>
      <base target="_blank" />
      <div
        className={classNames(styles.renderContent)}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(children) }}
        data-cy={dataCy}
        data-testid={testId}
      />
      <base target="_self" />
    </>
  );
};

export default HtmlContent;
