import { FacilityDataItem } from '../../Facilities/types/types';
import { Menu, MenuItem } from '../api/menuApi.types';

export const defaultMenuItem: MenuItem = {
  name: '',
  mealName: '',
  description: '',
  priceWithTax: 12,
  menuItemId: '1',
  genericCategory: '',
};

export const defaultFacilityMenu: Menu = {
  id: '238',
  facilityId: '5fdf3f6a-b3d2-ec11-bea1-0003ff4cc777',
  name: 'Aspretto Cafe',
  date: '2022-11-22T00:00:00',
  menuItems: [],
};

export const defaultFacilityDataItem: FacilityDataItem = {
  title: 'My facility',
  name: 'facility name',
  id: 'facility1',
  sequence: 1,
  images: [],
  facilityType: { id: 'test', name: 'Food - Retail' },
  viewCounts: 1,
  externalUrl: null,
};
