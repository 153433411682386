const labels = {
  All: {
    dailyMenu: 'Denní menu',
    anytime: 'Kdykoliv.',
    breakfast: 'Snídaně',
    morningBreak: 'Ranní přestávka',
    lunch: 'Oběd',
    afternoonBreak: 'Odpolední přestávka',
    dinner: 'Večeře',
    supper: 'Večeře',
    nightMeal: 'Noční jídlo',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Nabídky',
    'Ref: View details': 'Zobrazit podrobnosti',
    'Ref: Facility details': 'Detaily zařízení',
  },
  OpeningHours: { everyday: 'každý den', 'open all day': 'otevřený celý den' },
  ProductsList: {
    'Ref: Page title': 'Jídelní lístek',
    'Ref: Facility details': 'Detaily zařízení',
    'Ref: Search products list': 'Vyhledávání položek',
    'Ref: filter label: date': 'datum',
    'Ref: filter label: menus': 'Jídelní lístek',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Kategorie',
    'Ref: filter label: dishes': 'Nádobí',
    'Ref: filter label: highlight': 'Zvýrazněte nebo skryjte filtry s nádobími',
    All: 'Všechno',
  },
  ProductDetails: { 'Ref: Page title': 'Detaily produktu' },
  Widget: {
    'Ref: Carousel title': 'Nabídky',
    'No info yet, come back later': 'Zatím žádné informace, vrať se později 😉',
    'Ref: menus': 'Nabídky',
    'Ref: see all': 'Vidět vše',
  },
};
export default labels;
