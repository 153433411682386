const labels = {
  TimeTable: {
    'leave at': 'Sair em',
    'arrive at': 'Chegar a',
    at: 'no',
    'Ref: list placeholder': 'Preencha o formulário para ver as horas',
    'Ref: Page title': 'Ônibus',
    'Ref: Departure': 'Partida',
    'Ref: Arrivals': 'Chegadas',
    'Ref: minutes': 'minutos',
    'Ref: no items placeholder': 'Desculpe, não há ônibus disponíveis neste momento.',
  },
};
export default labels;
