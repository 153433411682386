const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Kiểm toán',
    'Ref: status0': 'Mở',
    'Ref: status1': 'Hoàn thành',
    'Ref: status2': 'Bị hủy bỏ',
    'Ref: connected': 'Kết nối',
    'Ref: not connected': 'Không kết nối',
    'Ref: synchronise data': 'Đồng bộ hóa dữ liệu',
    'Ref: sync success': 'Dữ liệu được đồng bộ hóa thành công',
    'Ref: Create new': 'Tạo mới',
    'Ref: Audit unsynchronised': 'Không đồng bộ hóa',
    'Ref: Audit synchronised': 'Đồng bộ hóa',
    'Ref: Unsynced error title': 'một lỗi đã xảy ra',
    'Ref: Unsynced error description':
      'Nó dường như là lần đầu tiên bạn truy cập mô -đun kiểm toán. Vui lòng kết nối với mạng và đồng bộ hóa trên mạng để bắt đầu kiểm toán.',
    'Ref: Search all audits': 'Tất cả kiểm toán',
    'Ref: Search active audits': 'Mở',
    'Ref: Search completed audits': 'Hoàn thành',
    'Ref: sync error': 'Đồng bộ hóa thất bại',
    'Ref: sync error details': 'Thử lại để kết nối lại',
    'Ref: no search audit results':
      'Thật không may, không có kiểm toán tương ứng với tìm kiếm của bạn',
    'Ref: no audit results': 'Bạn không có kiểm toán có sẵn, tạo mới để bắt đầu',
    'Ref: Search canceled audits': 'Bị hủy bỏ',
    'Ref: Audit cancelled successfully': 'Kiểm toán bị hủy thành công',
    'Ref: Cancel audit invite': 'Hủy bỏ',
    'Ref: Error audit cancel': 'Kiểm toán không bị hủy',
    'Ref: Cancel audit confirmation': 'Bạn có chắc là bạn muốn hủy bỏ kiểm toán?',
    'Ref: Confirm': 'Xác nhận',
    'Ref: Cancel audit': 'Hủy kiểm toán',
    'Ref: Change the location': 'Thay đổi vị trí',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Chi tiết kiểm toán',
    'Ref: questions': 'câu hỏi',
    'Ref: question': 'câu hỏi',
    'Ref: Additional Comments': 'Sự mô tả',
    'Ref: Not found title': 'Ối!',
    'Ref: Not found text': 'Việc kiểm toán bạn đang cố gắng truy cập không tồn tại.',
    'Ref: Back to Audit list': 'Quay lại danh sách kiểm toán',
    'Ref: Success title': 'Cảm ơn!',
    'Ref: Success text': 'Bạn đã hoàn thành kiểm toán',
    'Ref: submit': 'Nộp',
    'Ref: add attachment': 'Thêm một tệp đính kèm',
    'Ref: Unsupported elements':
      'Xin lỗi, có vẻ như kiểm toán này có nội dung mà ứng dụng chưa hỗ trợ. Chúng tôi sẽ làm việc trên nó.',
    'Ref: form error': 'Không phải tất cả các câu hỏi đã được trả lời đầy đủ',
    'Ref: form complete': 'Hoàn thành kể từ đó',
    'Ref: attachment preview error': 'Bạn cần phải trực tuyến để xem tệp đính kèm này',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Tạo mới',
    'Ref: Location': 'Vị trí',
    'Ref: Audit Stream Type': 'Suối',
    'Ref: Audit': 'Kiểm toán',
    'Ref: Asset': 'Tài sản',
    'Ref: Asset Name': 'Tên tài sản',
    'Ref: Is joint audit?': 'Là kiểm toán chung?',
    'Ref: Who was present?': 'Ai đã có mặt?',
    'Ref: submit': 'Nộp',
    'Ref: Select the Location': 'Chọn vị trí',
    'Ref: Select the Audit Stream Type': 'Chọn loại luồng kiểm toán',
    'Ref: Select the Audit': 'Chọn kiểm toán',
    'Ref: Select the Asset': 'Chọn tài sản',
    'Ref: form error': 'Không phải tất cả các trường bắt buộc đã được nhập đầy đủ',
    'Ref: Audit error': 'Kiểm toán đính kèm',
    'Ref: Joint Audit Participants error': 'Người tham gia kiểm toán chung',
    'Ref: Audit created': 'Kiểm toán được tạo ra',
    'Ref: Audit field length error': 'Độ dài không được vượt quá 100 ký tự',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Thay đổi vị trí',
    'Ref: Location changed': 'Vị trí đã thay đổi',
    'Ref: Location': 'Vị trí',
    'Ref: Select the Location': 'Chọn vị trí',
    'Ref: Asset': 'Tài sản',
    'Ref: Select the Asset': 'Chọn tài sản',
    'Ref: Asset Name': 'Tên tài sản',
    'Ref: submit': 'Nộp',
    'Ref: Audit field length error': 'Độ dài không được vượt quá 100 ký tự',
  },
};
export default labels;
