const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Ваш запрос',
    'Ref: Summary': 'Краткое содержание',
    'Ref: Request category': 'Категория запроса',
    'Ref: Person affected': 'Лицо, затронутое этим запросом',
    'Ref: Your phone number': 'Номер телефона',
    'Ref: Description': 'Описание',
    'Ref: Service Requests': 'Запросы на обслуживание',
    'Ref: Request Service': 'Запросить услугу',
    'Ref: Success title': 'Ваш запрос был создан',
    'Ref: Error title': 'Уп!',
    'Ref: Error subtitle': 'Произошла ошибка, попробуйте еще раз.',
    'Ref: Try again': 'Попробуйте еще раз',
    'Ref: you pronoun': '(ты)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Это ваш концентратор поддержки на рабочем месте',
    'Ref: Tutorial: tabname': 'Запросы',
    'Ref: Tutorial: body':
      'Мы здесь чтобы помочь вам. Пожалуйста, зарегистрируйте один запрос за запись.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Вы запрашиваете службу на сайте {siteName}.',
    'Ref: Building': 'Здание',
    'Ref: Room': 'Комната',
    'Ref: Description help text': 'Чем более подробно вы, тем лучше мы можем поддержать вас.',
    'Ref: Next': 'Следующий',
    'Ref: Your request': 'Ваш запрос',
    'Ref: PhoneNumber error msg': 'Номер телефона не действителен',
    'Ref: Type at least 3 characters to search': 'Введите не менее 3 символов для поиска',
    'Ref: No affected person results': 'Ни один подходящий человек запрашивался критерии',
    'Ref: Required': '* Необходимый',
  },
  Summary: {
    'Ref: Location': 'Расположение',
    'Ref: Attachment': 'Привязанности',
    'Ref: Submit': 'Представлять на рассмотрение',
    'Ref: No affected person results': 'Ни один подходящий человек запрашивался критерии',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Запрос на обслуживание',
    'Ref: createdOn': 'Создано на',
    'Ref: summary': 'Краткое содержание',
    'Ref: category': 'Категория запроса',
    'Ref: request raised for': 'Лицо, затронутое этим запросом',
    'Ref: phoneNumber': 'Номер телефона',
    'Ref: location': 'Номер телефона',
    'Ref: description': 'Описание',
    'Ref: attachments': 'Привязанности',
  },
};
export default labels;
