const labels = {
  Success: {
    'Ref: Body':
      "Merci d'avoir répondu à ce sondage, votre temps est précieux car ces sondages aident à améliorer nos services.",
    'Ref: Tabtitle': 'Enquête terminée',
    'Take another survey': 'Saisissez une autre enquête',
  },
  Survey: {
    'Ref: Page title': "Détails de l'enquête",
    'Please answer all mandatory questions as identify by an asterisk':
      'Veuillez répondre à toutes les questions obligatoires identifiées par un astérisque : *',
    'Ref: Take another survey': 'Saisissez une autre enquête',
    'Ref: Go to Home': 'Aller à la maison',
  },
  SurveysList: {
    'Ref: Page title': 'Enquête',
    'Ref: Available until': "Disponible jusqu'au",
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Vous consultez actuellement des enquêtes pour le site {site name}.',
  },
};
export default labels;
