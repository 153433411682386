import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';

import { breakPointsInPixels } from '../../../config';
import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';
import { SideBarItemTypes } from '../../atoms/SideBarItem/SideBarItem.types';
import SideBar from '../../organisms/SideBar';

import ItemIcon from './ItemIcon';
import { getItemLabel, getServicePath, getServicesWithNav } from './SideBarWrapper.helper';
import useSidebarContext from './useSidebarContext';

import { HomeIcon } from '@/assets/icons';
import { TOOLTIP_VARIANTS } from '@/components/molecules/TooltipWrapper';
import { getCustomSetupOption } from '@/helpers/getSetupOption';
import { useIsSetupOptionEnabled } from '@/helpers/hooks/useIsSetupOptionEnabled/useIsSetupOptionEnabled';
import { getImagesSrcFromIds } from '@/helpers/images/images.helper';
import { getAppDisplayName, isServiceType } from '@/helpers/misc';
import { enableGuestRequestSetupOption } from '@/modules/AccommodationRequest/config';
import { SERVICE, serviceTypes } from '@/modules/config';
import { pagePaths } from '@/modules/Content/config';
import { isHelpdeskUser } from '@/modules/Core/helpers/tokensHelpers';
import { Service } from '@/modules/Core/types/State.types';
import { State } from '@/types/state.types';

import styles from './SideBarWrapper.module.css';

const appName = getAppDisplayName();

const SideBarWrapper = () => {
  const { label } = useCustomTranslation();
  const { isSideBarCollapsed, setIsSideBarCollapsed } = useSidebarContext();
  const location = useLocation();
  const [iconsFetchInitialized, setIconsFetchInitialized] = useState<boolean>(false);
  const [navIcons, setNavIcons] = useState<Record<string, string>>({});
  const siteName = useSelector((state: State) => state.Core?.context?.site?.name ?? '');
  const services = useSelector((state: State) => state.Core.services ?? { list: [] });
  const servicesWithNav = useMemo(() => getServicesWithNav(services.list), [services]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const accommodationEnableGuestRequest = useIsSetupOptionEnabled(
    enableGuestRequestSetupOption,
    SERVICE.ACCOMMODATION_REQUEST
  );

  // On resize check sideBar version if mobile or desktop needs to be displayed
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const isPathExcluded = (excludeSubPaths: string[] | undefined, currentPath: string) => {
    if (!excludeSubPaths?.length) return false;

    return (
      excludeSubPaths.find((excludedSubPath) => currentPath.indexOf(excludedSubPath) !== -1) !==
      undefined
    );
  };

  const servicesItems = useMemo(() => {
    const getItemIcon = (service: Service) => {
      return (
        <ItemIcon service={service} customIcons={navIcons} className={styles.CustomServiceIcon} />
      );
    };

    const items: SideBarItemTypes[] = [];
    if (servicesWithNav && label !== undefined) {
      servicesWithNav.forEach((service) => {
        let isActive;

        const serviceType = serviceTypes[service.name];
        const serviceRequestServices = [SERVICE.SERVICE_REQUEST, SERVICE.SERVICE_REQUEST_LIGHT];
        const itemPath = serviceType.path;
        const itemExcludeSubPath = serviceType.excludeSubPaths;

        if (isServiceType(service, SERVICE.CONTENT)) {
          isActive =
            !!matchPath(location.pathname, pagePaths.Module) ||
            !!matchPath(location.pathname, pagePaths.ContentDetails);
        } else if (isServiceType(service, SERVICE.CONTENT_PAGE)) {
          const escapedNavigationItemName = encodeURIComponent(service.navigation.name);

          const contentPageUrlMatcher = new RegExp(
            `^${itemPath}\\/${escapedNavigationItemName}(\\/|$)`
          );

          isActive =
            location.pathname === itemPath ||
            (location.pathname.startsWith(itemPath + '/') &&
              !isPathExcluded(itemExcludeSubPath, location.pathname) &&
              contentPageUrlMatcher.test(encodeURI(location.pathname)));
        } else {
          const { value: emmbededURL } = getCustomSetupOption('emmbededURL', service.setupOptions);

          isActive =
            !isPathExcluded(itemExcludeSubPath, location.pathname) &&
            location.pathname.startsWith(itemPath) &&
            (!new URLSearchParams(location.search).get('url') ||
              (!!emmbededURL &&
                new URLSearchParams(location.search).get('url') === btoa(emmbededURL)));
        }

        if (
          isServiceType(service, SERVICE.SERVICE_REQUEST_LIGHT_HELPDESK) &&
          !isServiceType(service, serviceRequestServices) &&
          !isHelpdeskUser()
        ) {
          return;
        }

        let title;
        if (
          isServiceType(service, SERVICE.ACCOMMODATION_REQUEST) &&
          accommodationEnableGuestRequest
        )
          title = label('Ref: Guest Accomodation Request');
        else title = getItemLabel(service, label);

        items.push({
          icon: getItemIcon(service),
          title,
          linkTo: getServicePath(service),
          active: isActive,
        });
      });
    }
    return items;
  }, [servicesWithNav, label, navIcons, location, accommodationEnableGuestRequest]);

  useEffect(() => {
    const fetchServiceIcons = async () => {
      if (!servicesWithNav.length || iconsFetchInitialized) return;

      const imageIds = servicesWithNav
        ?.filter((serviceWithNav) => !!serviceWithNav.navigation?.imageId)
        .map((serviceWithNav) => serviceWithNav.navigation.imageId!);

      const images = await getImagesSrcFromIds(imageIds);
      const navIcons: Record<string, string> = {};

      servicesWithNav.forEach((service) => {
        if (service?.navigation?.imageId) {
          const icon = images.find((img) => img.id === service?.navigation?.imageId);
          if (icon) navIcons[service.navigation.name] = icon.content;
        }
      });

      setNavIcons(navIcons);
      setIconsFetchInitialized(true);
    };

    fetchServiceIcons();
  }, [servicesWithNav, iconsFetchInitialized]);

  const args = useMemo(
    () => ({
      site: {
        name: siteName,
        title: label('Ref: My site'),
        linkTo: '/sites',
        active: location.pathname.endsWith('sites'),
      },
      homeSection: {
        icon: <HomeIcon />,
        title: label('home'),
        linkTo: '/home',
        active: location.pathname.endsWith('home'),
      },
      recentSection: {
        sectionTitle: label('Ref: Recent'),
        items: [],
      },
      servicesSection: {
        sectionTitle: label('Ref: Services'),
        items: servicesItems,
      },
      closed: isSideBarCollapsed,
      isLoading: servicesItems.length === 0 ? false : !iconsFetchInitialized,
    }),
    [siteName, servicesItems, iconsFetchInitialized, location.pathname, isSideBarCollapsed, label]
  );

  const sideBar = useMemo(() => {
    if (
      windowWidth &&
      window.matchMedia('(min-width: ' + breakPointsInPixels['M'] + 'px)').matches
    ) {
      return (
        <SideBar.Desktop
          {...args}
          title={appName}
          isSideBarCollapsed={isSideBarCollapsed}
          onSideBarCollapsed={setIsSideBarCollapsed}
          label={label}
          tooltipId={TOOLTIP_VARIANTS.SIDEBAR_TOOLTIP}
        />
      );
    } else {
      return (
        <SideBar.Mobile
          {...args}
          title={appName}
          tooltipId={TOOLTIP_VARIANTS.SIDEBAR_TOOLTIP}
          label={label}
          closeSidebar={() => setIsSideBarCollapsed(true)}
        />
      );
    }
  }, [windowWidth, args, label, setIsSideBarCollapsed, isSideBarCollapsed]);
  return <>{sideBar}</>;
};
export default SideBarWrapper;
