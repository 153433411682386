import classNames from 'classnames';
import { QRCodeCanvas } from 'qrcode.react';

import Title, { TITLE_SIZE } from '../../../components/atoms/Title';
import Layout from '../../../components/templates/Layout/Layout';
import { LayoutBehaviorType } from '../../../components/templates/Layout/Layout.types';
import SimpleFormPage from '../../../components/templates/SimpleFormPage/SimpleFormPage';
import { useMyBusTranslation } from '../hooks/useMyBusTranslation';

import useLoginStatus from '@/helpers/hooks/useLoginStatus';

import styles from './QrCode.module.css';

const QrCode = () => {
  const { label } = useMyBusTranslation(__filename);
  const { user } = useLoginStatus();

  return (
    <SimpleFormPage
      title={label('Ref: Page title')}
      hasBackLink={false}
      actions={[]}
      actionsBarTopContent={null}
    >
      <Layout layoutBehaviorType={LayoutBehaviorType.CenteredPage}>
        <div className={classNames(styles.pageContent)}>
          <div className={classNames('mb-L')}>
            {user.qrCode && (
              <QRCodeCanvas value={user.qrCode} bgColor="transparent" size={200} role="qr-code" />
            )}
          </div>
          <Title size={TITLE_SIZE.HEADLINES} className={classNames('mb-M')}>
            {`${label('Ref: Hi')} ${user.firstName} ${user.lastName}`}
          </Title>

          <div className={classNames(styles.pageContentBody)} data-testid="page-content">
            {label('Ref: Page content body')}
          </div>
        </div>
      </Layout>
    </SimpleFormPage>
  );
};

export default QrCode;
