/* eslint-disable no-restricted-imports */
import { svgFactory } from '../svgFactory';

import { ReactComponent as AccountSvg } from './Account.svg';
import { ReactComponent as AuditSvg } from './Audit.svg';
import { ReactComponent as AuditBSvg } from './AuditB.svg';
import { ReactComponent as BackgroundWidgetAccomodationSvg } from './BackgroundWidgetAccomodation.svg';
import { ReactComponent as BackgroundWidgetAuditSvg } from './BackgroundWidgetAudit.svg';
import { ReactComponent as BackgroundWidgetBusSvg } from './BackgroundWidgetBus.svg';
import { ReactComponent as BackgroundWidgetContentSvg } from './BackgroundWidgetContent.svg';
import { ReactComponent as BackgroundWidgetDefaultSvg } from './BackgroundWidgetDefault.svg';
import { ReactComponent as BackgroundWidgetEventSvg } from './BackgroundWidgetEvent.svg';
import { ReactComponent as BackgroundWidgetFreeCoffeeSvg } from './BackgroundWidgetFreeCoffee.svg';
import { ReactComponent as BackgroundWidgetMapsSvg } from './BackgroundWidgetMaps.svg';
import { ReactComponent as BackgroundWidgetMenusSvg } from './BackgroundWidgetMenus.svg';
import { ReactComponent as BackgroundWidgetOrderHistorySvg } from './BackgroundWidgetOrderHistory.svg';
import { ReactComponent as BackgroundWidgetServiceRequestSvg } from './BackgroundWidgetServiceRequest.svg';
import { ReactComponent as BackgroundWidgetStampsSvg } from './BackgroundWidgetStamps.svg';
import { ReactComponent as BackgroundWidgetSuggestionSvg } from './BackgroundWidgetSuggestion.svg';
import { ReactComponent as BowlSvg } from './Bowl.svg';
import { ReactComponent as BreakfastSvg } from './Breakfast.svg';
import { ReactComponent as BurgerSvg } from './Burger.svg';
import { ReactComponent as BurritoSvg } from './Burrito.svg';
import { ReactComponent as CafeteriaSvg } from './Cafeteria.svg';
import { ReactComponent as CaffeeSvg } from './Caffee.svg';
import { ReactComponent as CaramelSvg } from './Caramel.svg';
import { ReactComponent as ChickenSvg } from './Chicken.svg';
import { ReactComponent as ChocolateSvg } from './Chocolate.svg';
import { ReactComponent as CoffeeCheckSvg } from './CoffeeCheck.svg';
import { ReactComponent as CoffeeNeutralSvg } from './CoffeeNeutral.svg';
import { ReactComponent as CoffeeNOTCheckSvg } from './CoffeeNOTCheck.svg';
import { ReactComponent as CoffeeProgressSvg } from './CoffeeProgress.svg';
import { ReactComponent as CoffeeProgress2Svg } from './CoffeeProgress2.svg';
import { ReactComponent as CokeSvg } from './Coke.svg';
import { ReactComponent as CoverSvg } from './Cover.svg';
import { ReactComponent as DefaultFailSvg } from './DefaultFail.svg';
import { ReactComponent as DefaultSuccessSvg } from './DefaultSuccess.svg';
import { ReactComponent as DeviceErrorSvg } from './DeviceError.svg';
import { ReactComponent as DeviceWomanSvg } from './DeviceWoman.svg';
import { ReactComponent as DiscountIllustration } from './Discount.svg';
import { ReactComponent as DishSvg } from './Dish.svg';
import { ReactComponent as DrinkSvg } from './Drink.svg';
import { ReactComponent as EggSvg } from './Egg.svg';
import { ReactComponent as ErrorCircleSvg } from './ErrorRedCircle.svg';
import { ReactComponent as EventSvg } from './Event.svg';
import { ReactComponent as FacilitiesSvg } from './Facilities.svg';
import { ReactComponent as FacilitySvg } from './Facility.svg';
import { ReactComponent as FeedbackSvg } from './Feedback.svg';
import { ReactComponent as FriesSvg } from './Fries.svg';
import { ReactComponent as FruitsSvg } from './Fruits.svg';
import { ReactComponent as GiftSvg } from './Gift.svg';
import { ReactComponent as HelpdeskSvg } from './Helpdesk.svg';
import { ReactComponent as KebabSvg } from './Kebab.svg';
import { ReactComponent as LetterDeliveredSvg } from './LetterDelivered.svg';
import { ReactComponent as LoyaltyCoffeeSvg } from './LoyaltyCoffee.svg';
import { ReactComponent as LoyaltyStampsCardSvg } from './LoyaltyStampsCard.svg';
import { ReactComponent as LoyaltyStampsRewardsSvg } from './LoyaltyStampsRewards.svg';
import { ReactComponent as ManSitSvg } from './ManSit.svg';
import { ReactComponent as ManSit2Svg } from './ManSit2.svg';
import { ReactComponent as ManSit2newSvg } from './ManSit2new.svg';
import { ReactComponent as ManWithFoodSvg } from './ManWithFood.svg';
import { ReactComponent as MapSvg } from './Map.svg';
import { ReactComponent as MenuSvg } from './Menu.svg';
import { ReactComponent as MerchantSvg } from './Merchant.svg';
import { ReactComponent as NoodleSvg } from './Noodle.svg';
import { ReactComponent as NotificationsSvg } from './Notifications.svg';
import { ReactComponent as OtherSvg } from './Other.svg';
import { ReactComponent as PastaSvg } from './Pasta.svg';
import { ReactComponent as PizzaSvg } from './Pizza.svg';
import { ReactComponent as QuesadillaSvg } from './Quesadilla.svg';
import { ReactComponent as ReceptionSvg } from './Reception.svg';
import { ReactComponent as SaladSvg } from './Salad.svg';
import { ReactComponent as SandwishSvg } from './Sandwish.svg';
import { ReactComponent as ServiceRequestSvg } from './ServiceRequest.svg';
import { ReactComponent as SiteSvg } from './Site.svg';
import { ReactComponent as SnacksSvg } from './Snacks.svg';
import { ReactComponent as SoupSvg } from './Soup.svg';
import { ReactComponent as StampsSvg } from './Stamps.svg';
import { ReactComponent as TeaSvg } from './Tea.svg';
import { ReactComponent as TermsAndConditionsSvg } from './TermsAndConditions.svg';
import { ReactComponent as ThankYouDeliverySvg } from './ThankYouDelivery.svg';
import { ReactComponent as ThankYouLocalSvg } from './ThankYouLocal.svg';
import { ReactComponent as ThankYouPickupSvg } from './ThankYouPickup.svg';
import { ReactComponent as ThankYouScanAndGoSvg } from './ThankYouScanAndGo.svg';
import { ReactComponent as TurkeySvg } from './Turkey.svg';
import { ReactComponent as VegetableSvg } from './Vegetable.svg';
import { ReactComponent as WomanSvg } from './Woman.svg';
import { ReactComponent as Woman2Svg } from './Woman2.svg';
import { ReactComponent as WomanDeskSvg } from './WomanDesk.svg';
import { ReactComponent as WomanDesk2Svg } from './WomanDesk2.svg';
import { ReactComponent as WrapSvg } from './Wrap.svg';

export const AccountIllustration = svgFactory(AccountSvg);
export const AuditBIllustration = svgFactory(AuditBSvg);
export const AuditIllustration = svgFactory(AuditSvg);
export const BackgroundWidgetAccomodationIllustration = svgFactory(BackgroundWidgetAccomodationSvg);
export const BackgroundWidgetAuditIllustration = svgFactory(BackgroundWidgetAuditSvg);
export const BackgroundWidgetBusIllustration = svgFactory(BackgroundWidgetBusSvg);
export const BackgroundWidgetContentIllustration = svgFactory(BackgroundWidgetContentSvg);
export const BackgroundWidgetDefaultIllustration = svgFactory(BackgroundWidgetDefaultSvg);
export const BackgroundWidgetEventIllustration = svgFactory(BackgroundWidgetEventSvg);
export const BackgroundWidgetFreeCoffeeIllustration = svgFactory(BackgroundWidgetFreeCoffeeSvg);
export const BackgroundWidgetMapsIllustration = svgFactory(BackgroundWidgetMapsSvg);
export const BackgroundWidgetMenusIllustration = svgFactory(BackgroundWidgetMenusSvg);
export const BackgroundWidgetOrderHistoryIllustration = svgFactory(BackgroundWidgetOrderHistorySvg);
export const BackgroundWidgetServiceRequestIllustration = svgFactory(
  BackgroundWidgetServiceRequestSvg
);
export const BackgroundWidgetStampsIllustration = svgFactory(BackgroundWidgetStampsSvg);
export const BackgroundWidgetSuggestionIllustration = svgFactory(BackgroundWidgetSuggestionSvg);
export const BowlIllustration = svgFactory(BowlSvg);
export const BreakfastIllustration = svgFactory(BreakfastSvg);
export const BurgerIllustration = svgFactory(BurgerSvg);
export const BurritoIllustration = svgFactory(BurritoSvg);
export const CafeteriaIllustration = svgFactory(CafeteriaSvg);
export const CaramelIllustration = svgFactory(CaramelSvg);
export const ChickenIllustration = svgFactory(ChickenSvg);
export const ChocolateIllustration = svgFactory(ChocolateSvg);
export const CoffeeIllustration = svgFactory(CaffeeSvg);
export const CoffeeCheckImage = svgFactory(CoffeeCheckSvg);
export const CoffeeNotCheckImage = svgFactory(CoffeeNOTCheckSvg);
export const CoffeeNeutralImage = svgFactory(CoffeeNeutralSvg);
export const CoffeeProgressIllustration = svgFactory(CoffeeProgressSvg);
export const CoffeeProgress2Illustration = svgFactory(CoffeeProgress2Svg);
export const CokeIllustration = svgFactory(CokeSvg);
export const CoverIllustration = svgFactory(CoverSvg);
export const Discount = svgFactory(DiscountIllustration);
export const DishIllustration = svgFactory(DishSvg);
export const DefaultFailIllustration = svgFactory(DefaultFailSvg);
export const DefaultSuccessIllustration = svgFactory(DefaultSuccessSvg);
export const DeviceErrorIllustration = svgFactory(DeviceErrorSvg);
export const DeviceWoman = svgFactory(DeviceWomanSvg);
export const DrinkIllustration = svgFactory(DrinkSvg);
export const EggIllustration = svgFactory(EggSvg);
export const ErrorCircle = svgFactory(ErrorCircleSvg);
export const EventIllustration = svgFactory(EventSvg);
export const FacilitiesIllustration = svgFactory(FacilitiesSvg);
export const FacilityIllustration = svgFactory(FacilitySvg);
export const FeedbackIllustration = svgFactory(FeedbackSvg);
export const FriesIllustration = svgFactory(FriesSvg);
export const FruitsIllustration = svgFactory(FruitsSvg);
export const GiftIllustration = svgFactory(GiftSvg);
export const HelpdeskIllustration = svgFactory(HelpdeskSvg);
export const KebabIllustration = svgFactory(KebabSvg);
export const LetterDelivered = svgFactory(LetterDeliveredSvg);
export const LoyaltyCoffeeIllustration = svgFactory(LoyaltyCoffeeSvg);
export const LoyaltyStampsCard = svgFactory(LoyaltyStampsCardSvg);
export const LoyaltyStampsRewards = svgFactory(LoyaltyStampsRewardsSvg);
export const MapIllustration = svgFactory(MapSvg);
export const MenuIllustration = svgFactory(MenuSvg);
export const ManSitIllustration = svgFactory(ManSitSvg);
export const ManSit2oldIllustration = svgFactory(ManSit2Svg);
export const ManSit2Illustration = svgFactory(ManSit2newSvg);
export const ManWithFoodIllustration = svgFactory(ManWithFoodSvg);
export const Merchant = svgFactory(MerchantSvg);
export const NoodleIllustration = svgFactory(NoodleSvg);
export const NotificationsIllustration = svgFactory(NotificationsSvg);
export const OtherIllustration = svgFactory(OtherSvg);
export const PastaIllustration = svgFactory(PastaSvg);
export const PizzaIllustration = svgFactory(PizzaSvg);
export const QuesadillaIllustration = svgFactory(QuesadillaSvg);
export const ReceptionIllustration = svgFactory(ReceptionSvg);
export const SaladIllustration = svgFactory(SaladSvg);
export const SandwishIllustration = svgFactory(SandwishSvg);
export const ServiceRequestIllustration = svgFactory(ServiceRequestSvg);
export const SiteIllustration = svgFactory(SiteSvg);
export const SnacksIllustration = svgFactory(SnacksSvg);
export const SoupIllustration = svgFactory(SoupSvg);
export const StampsIllustration = svgFactory(StampsSvg);
export const TeaIllustration = svgFactory(TeaSvg);
export const TermsAndConditionsIllustration = svgFactory(TermsAndConditionsSvg);
export const ThankYouLocalIllustration = svgFactory(ThankYouLocalSvg);
export const ThankYouPickupIllustration = svgFactory(ThankYouPickupSvg);
export const ThankYouDeliveryIllustration = svgFactory(ThankYouDeliverySvg);
export const ThankYouScanAndGoIllustration = svgFactory(ThankYouScanAndGoSvg);
export const TurkeyIllustration = svgFactory(TurkeySvg);
export const VegetableIllustration = svgFactory(VegetableSvg);
export const WrapIllustration = svgFactory(WrapSvg);
export const WomanIllustration = svgFactory(WomanSvg);
export const Woman2Illustration = svgFactory(Woman2Svg);
export const WomanDesk = svgFactory(WomanDeskSvg);
export const WomanDesk2 = svgFactory(WomanDesk2Svg);
