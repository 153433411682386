const labels = {
  All: {
    'status: all': '所有',
    'status: open': '待解决',
    'status: pending': '待定',
    'status: closed': '已解决',
    Location: '位置',
    Subject: '标题',
    Description: '您的详细要求',
    'Ref: description field label': '描述',
    'Ref: Title': '标题',
    phone_number: '您的电话号码',
    request_category: '请求类别',
    Attachments: '附件',
    'request service': '请求服务',
    'New service request': '创建另一个请求',
    'Go to home': '返回主页',
    'Back to this service request': '返回此服务请求',
    'Back to service requests': '返回主页',
    Feedback: '反馈',
    'Ref: Request category': '类别',
    Cleaning: '清洁',
    'Laundry services': '洗衣服务',
    'Grounds maintenance': '地面维护',
    'Soft services': '软服务',
    'Hard services': '硬服务',
    'Pest control services': '虫控服务',
    'Waste management': '废物管理',
    'Asset Lifecycle Services': '资产生命周期服务',
    'There are no updates for this request': '此请求没有更新',
    'Transportation Services': '运输服务',
    'Ref: you pronoun': '（你）',
    'Ref: SR raised for': '受到影响的人',
    'Ref: Select a location': '选择一个位置',
    'Ref: Your request': '您的申请',
    'Ref: Summary': '概括',
    'Ref: site': '地点',
  },
  Comment: {
    'Ref: Page title': '提交反馈',
    'Ref: please update us with details':
      '您是否还有其他的想法需要与我们分享？我们对您的需求了解的越多，就越能更好的为您提供支持。',
  },
  Confirm: {
    'Ref: Page title': '确认您的请求',
    'Ref: next': '提交',
    summary: '概要',
    'Ref: Save as my preferred location': '保存为我的首选位置',
  },
  Describe: {
    'Ref: Page title': '描述您的请求',
    'Ref: Required fields: header': '请提供更多信息',
    'Ref: Required fields': '应在详细信息字段中提供以下信息：',
    'Ref: Required field: title': '需要一个标题。',
    'Ref: Required field: description': '请输入描述。',
    'Ref: Required field: phone': '请输入电话号码。',
    'Ref: Required field: category': '请选择分类。',
    'Ref: Invalid field: category': '提供的类别无效。',
    'Ref: Request template field label': '热门要求',
    'Ref: Request template field placeholder': '空白',
    your_request: '您的申请',
    'Ref: Description message': '请提尽可能提供详细的描述,以便我们为您能更好的提供服务。',
    phone_number: '您的联系电话（如您有公司座机号码，请尽可能提供）',
    'Ref: Affected person': '受到影响的人',
    'Ref: No affected person results': '没有符合请求条件的人',
    'Ref: Notification title': '您已经更改了位置',
    'Ref: Notification info': '您现在位于 {site name}',
    'Ref: Add photos': '添加照片',
    'Ref: Your location': '你的地点',
  },
  Details: {
    'Ref: Page title': '请求详细信息',
    Details: '详情',
    Updates: '更新',
    'Update us': '告知我们',
    'Ref: timeline': '时间线',
    'Ref: attachments': '附件',
    'Ref: location': '地点',
    'Ref: createdOn': '创建于',
    'Ref: reference': '参考',
    'Feedback was submitted': '评价已提交',
    'Ref: sent': '发送',
    'Ref: received': '已收到',
    'Ref: inProgress': '进行中',
    'Ref: completed': '已完成',
    'Ref: conversation': '对话',
    'Ref: Priority': '优先事项',
    'Ref: Target start date': '目标开始日期',
    'Ref: Target completion date': '目标完成日期',
    'Ref: Priority fields placeholder message': '将在服务台处理后显示',
  },
  Failed: {
    'Ref: Body': '发生错误，未能成功创建服务请求。如果此错误仍然存​​在，请联系您的服务台。',
    'Ref: Tabtitle': '服务请求无法创建',
  },
  Home: { 'Ref: Page title': '要求' },
  RequestFeedback: {
    'Ref: Page title': '备注',
    'Ref: Feedback headline': '请分享您的反馈',
    'Ref: Feedback title': '关于SR＃{REF}的反馈',
    'Ref: Question: general sentiment': '您如何评价我们的总体表现？',
    'Ref: Question: answer quality': '您如何评价对您的请求回应的质量？',
    'Ref: Question: staff interaction': '您如何评价与我们员工的互动？',
    'Ref: Question: efficiency': '您如何评价我们的执行速度？',
    'Ref: Question: open': '评论',
    'Ref: Open question placeholder': '有哪些表现好的地方？ 有什么要改进的地方？',
    no_comment: '没有意见',
  },
  FeedbackSuccess: {
    'Ref: Body': '感谢您抽出宝贵时间发表评论，您的反馈意见对于我们改善您的体验至关重要。',
  },
  Locate: {
    'Ref: Page title': '定位您的请求',
    'Ref: Top bar title': '定位您的请求',
    'Ref: Select this location': '选择此位置',
    drill: '浏览',
    'Ref: Scanner button': '位置上面有二维码？请扫描它',
    'Ref: Scanner header': '请扫描位置的二维码',
    'Sorry, this location could not be found.': '对不起，找不到这个位置。',
    'Close scanner': '关闭扫描仪',
    'Ref: Switch site component intro sentence': '您在地点{site name}上请求服务。',
    'Ref: search location by keywords': '搜索位置',
    'Ref: Enter Location name': '输入位置名称',
    'Ref: Enter manual location': '手动输入位置',
    'Ref: Location name': '地点名称',
    'Ref: No results': '我们找不到您的位置。您可能想要手动输入它。',
    'Ref: Up one level': '上一级',
    'Ref: Navigate': '导航',
    'Ref: Select': '选择',
    'Ref: Selected Location': '选定位置',
    'Ref: Preferred Location': '首选地点',
    'Ref: Building': '建筑',
    'Ref: Floor': '楼层',
    'Ref: Aisle': '走道',
    'Ref: Room': '房间',
    'Ref: Notification title': '您已经更改了位置',
    'Ref: Notification info': '您现在位于 {site name}',
    'Ref: Scanner error header': '扫描错误',
    'Ref: Scan QR Code': '扫描二维码',
    'Ref: Error body': '此QR代码不是有效的代码',
    'Ref: Loading Locations Warning': '请稍后，我们正在为您在此站点的首次请求进行准备。',
    'Ref: Search Result is limited to:': '搜索结果仅限于{limit}。请完善您的搜索标准。',
    'Ref: Open the location search field': '打开位置搜索字段',
  },
  Success: {
    'Ref: Body':
      '您的服务请求已提交并正在处理。它将很快显示在当前应用中。您将在问题解决时收到一个提醒。与此同时，您可以通过此应用查询其状态并向其添加备注。',
    'Ref: Tabtitle': '创建的服务请求',
    'Ref: Download app title': '简化您的服务请求',
    'Ref: Download app subtitle': '下载我们的应用程序并提升您的体验',
    'Ref: Download app button': '下载应用程序',
  },
  Tutorials: {
    'Ref: Tutorial: title': '这是您的工作场所支持中心',
    'Ref: Tutorial: tabname': '要求',
    'Ref: Tutorial: body': '请为不同的请求创建独立的记录，我们随时为您提供帮助。',
    "What's new: title": '轻松找到您的位置',
    "What's new: body": '扫描二维码以查找您的确切位置，或从我们的建议中进行选择。',
  },
  Widget: {
    'Ref: Service request': '服务请求',
    'Ref: Placeholder body': '目前没有服务请求',
    'Ref: New request button': '新要求',
  },
  QrLandingPage: {
    'Ref: Notification title': '您已经更改了位置',
    'Ref: Notification info': '您现在位于{site name}',
    'Ref: Welcome': '欢迎！ {波浪表情}',
    'Ref: Create service request text': '注意到您想要报告的事情了吗？',
    'Create a service request': '创建服务请求',
    'Ref: Share feedback': '分享反馈',
    'Ref: Share text': '想与我们分享一些东西吗？',
    'Go to home': '返回主页',
    'Ref: Error body': '您扫描的二维码不再有效。请稍后重试或联系支持人员。',
  },
  QrLandingPageError: {
    'Ref: Error title': '出错了',
    'Ref: Error body': '您扫描的二维码不再有效。请稍后重试或联系支持人员。',
    'Go to home': '返回主页',
  },
  ManualDescribe: {
    'Ref: Page title': '描述您的要求',
    'Ref: Required fields: header': '请提供其他信息',
    'Ref: Required fields': '详细信息字段中应提供以下信息：',
    'Ref: Required field: title': '需要摘要。',
    'Ref: Required field: description': '请输入描述。',
    'Ref: Required field: category': '请选择类别。',
    'Ref: Required field: buildingText': '请选择建筑物。',
    'Ref: Invalid field: category': '所选的类别无效。',
    'Ref: Your request': '你的申请',
    'Ref: Description message': '您提供的详细信息将能帮助我们为您提供更好的支持。',
    'Ref: Do you have a location QR code': '您有位置QR码吗？',
    'Ref: Scan QR Code': '扫描二维码',
    'Ref: Please select category': '请选择类别',
    'Ref: Please select site': '请选择地点',
    'Ref: building': '建筑',
    'Ref: room': '房间',
    'Ref: Scan error': '扫描错误',
    'Ref: The QR code is not valid': 'QR码无效',
    'Ref: Add location with QR Scanner': '使用二维码添加位置',
    'Ref: Description': '描述',
    'Ref: Forbidden error': '很抱歉，此QR码与您的注册位置不符。',
    'Ref: Invalid error': '很抱歉，看来此QR码不再有效。',
  },
};
export default labels;
