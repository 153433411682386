import classnames from 'classnames';
import { FC, ReactElement } from 'react';

import { WidgetHeroProps } from './WidgetHero.types';

import styles from './WidgetHero.module.css';

const WidgetHero: FC<WidgetHeroProps> = ({
  background,
  message,
  username,
  className,
  'data-testid': dataTestId = 'wrapper-hero',
}): ReactElement => {
  const bgStyle = background
    ? {
        backgroundImage: `linear-gradient(to right, var(--ion-color-primary-darker), rgba(255, 255, 255, 0.0)), url('${background}')`,
      }
    : undefined;

  return (
    <div
      className={classnames(styles.wrapper, { [styles.bgColor]: !background }, className)}
      style={bgStyle}
      data-testid={dataTestId}
    >
      <p className={styles.message} data-testid="hero-message">
        {message}
      </p>
      <p className={styles.username} data-testid="hero-username">
        {username}
      </p>
    </div>
  );
};

export default WidgetHero;
