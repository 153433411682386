import React from 'react';

import { Alert } from './molecules/Alert';

//rules are functions without param that return a boolean e.g. { email: () => do sth }
export const validateForm = (rules) => {
  const missingInfo = [];

  for (const fieldId of Object.keys(rules)) {
    if (!rules[fieldId]()) missingInfo.push(fieldId);
  }

  return {
    isValid: !missingInfo.length,
    missingInfo,
  };
};

export const FormValidationAlert = ({ show, missingInfo, msgCodes, onDidDismiss, label }) => {
  let popupMessage = '<p>';
  for (const missingPiece of missingInfo) {
    const missingPieceLabel = label(missingPiece);
    const msgCode = msgCodes?.[missingPiece]; //may exist or not. if not, use default label
    popupMessage +=
      label(msgCode || 'Ref: Required field', { replace: { ref: missingPieceLabel } }) + '<br />';
  }
  popupMessage += '</p>';
  return (
    <Alert
      isOpen={show && !!missingInfo.length}
      onDismiss={onDidDismiss}
      cssClass="popup-warning"
      header={label('Ref: Popup header for missing required fields in a form')}
      message={<div dangerouslySetInnerHTML={{ __html: popupMessage }}></div>}
      buttons={[label('ok', { textTransform: 'capitalize' })]}
    />
  );
};
