const labels = {
  ContactForm: { 'Ref: Page title': 'צור קשר', 'Ref: Category field value': 'צור קשר' },
  ContactList: {
    'Ref: Page title': 'אנשי קשר',
    'call (action to place a call)': 'שִׂיחָה',
    'write (action to write a message)': 'לִכתוֹב',
    'write (action to write an e-mail)': 'אימייל',
    'Ref: Hint message':
      'אנא שימו לב כי לאתרים מסוימים אין קבלת פנים. אם זה המקרה אנא אתר את אחד הטלפונים הקוויים הזמינים סביב האתר.',
  },
  FormSubmissionFailure: {
    'Ref: Message body':
      'אירעה שגיאה וההודעה שלך לא הוגשה. אם שגיאה זו נמשכת, צור קשר עם התמיכה שלך.',
  },
  FormSubmissionSuccess: { 'Ref: submitted success': 'הודעתך נשלחה. תודה שיצרת איתנו קשר.' },
};
export default labels;
