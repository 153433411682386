import classNames from 'classnames';
import React from 'react';

import useKeyboardInfo from '../../../helpers/hooks/useKeyboardInfo';
import useWindowSize from '../../../helpers/hooks/useWindowSize';
import { isIosMobile } from '../../../helpers/misc';
import Card from '../../molecules/Card/Card';
import PureBottomBar from '../../organisms/BottomBar/BottomBar';

import styles from './Layout.module.css';

export enum BAR_POSITION {
  STATIC = 'static', // always below content
  DYNAMIC = 'dynamic', // changes based on viewport (default)
}

const BottomBar = React.memo(({ bottomBar, bottomBarRef }: any) => {
  const Wrapper = !!bottomBar ? Card : 'div';
  const isKeyboardOpen = useKeyboardInfo();
  const { height } = useWindowSize();
  const minHeightForFixedStyle = 400;

  return (
    <div
      className={classNames(styles.bottomBar, {
        [styles.fixedBarWrapper]:
          (!bottomBar?.position || bottomBar.position === BAR_POSITION.DYNAMIC) &&
          !isKeyboardOpen &&
          height > minHeightForFixedStyle,
        [styles.IosMobile]: isIosMobile(),
      })}
      ref={bottomBarRef}
    >
      {bottomBar && (
        <Wrapper>
          {bottomBar.label && (
            <div className={classNames('bodySBold', 'mb-S')}>{bottomBar.label}</div>
          )}
          <PureBottomBar {...bottomBar} />
        </Wrapper>
      )}
    </div>
  );
});

export default BottomBar;
