import { ComponentStory, ComponentMeta } from '@storybook/react';

import { InformationIcon } from '../../assets';
import { WithLabel } from '../../components/atoms/Input/Input.stories';
import { InputProps } from '../../components/atoms/Input/Input.types';
import { TITLE_TAG, TITLE_SIZE } from '../../components/atoms/Title';
import Card from '../../components/molecules/Card/Card';
import ReduxStoreDecorator from '../helpers/ReduxStoreDecorator';
import { storyTitle } from '../helpers/titleHelper';

export default {
  title: storyTitle('Card', 'molecules'),
  decorators: [ReduxStoreDecorator],
  component: Card,
  args: {
    children: <WithLabel {...(WithLabel.args as InputProps)} />,
  },
} as ComponentMeta<typeof Card>;

const Template: ComponentStory<typeof Card> = (args) => <Card {...args} />;

export const CardWithInputAsChildrenExample = Template.bind({});
CardWithInputAsChildrenExample.args = {};

export const CardWithOverTitle = Template.bind({});
CardWithOverTitle.args = {
  overTitle: { tag: TITLE_TAG.H2, children: 'OverTitle - Optional', size: TITLE_SIZE.HEADLINES },
};

export const CardWithH2Title = Template.bind({});
CardWithH2Title.args = {
  title: { tag: TITLE_TAG.H2, children: 'Title', size: TITLE_SIZE.HEADLINES },
};

export const CardWithH3Title = Template.bind({});
CardWithH3Title.args = {
  title: { tag: TITLE_TAG.H3, children: 'Title', size: TITLE_SIZE.HEADLINEXS },
};

export const CardWithH2TitleAndIcon = Template.bind({});
CardWithH2TitleAndIcon.args = {
  ...CardWithH2Title.args,
  icon: { icon: InformationIcon },
};

export const CardWithH3TitleAndIcon = Template.bind({});
CardWithH3TitleAndIcon.args = {
  ...CardWithH3Title.args,
  icon: { icon: InformationIcon },
};

export const CardWithText = Template.bind({});
CardWithText.args = {
  title: { tag: TITLE_TAG.H3, suffix: '$ 7,50', size: TITLE_SIZE.HEADLINEXS },
};

export const CardWithAllProps = Template.bind({});
CardWithAllProps.args = {
  ...CardWithOverTitle.args,
  icon: { icon: InformationIcon },
  title: {
    tag: TITLE_TAG.H3,
    children: 'Title',
    suffix: '$ 7,50',
    size: TITLE_SIZE.HEADLINEXS,
  },
};
