import { app as msTeamsApp } from '@microsoft/teams-js';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setMsTeamsContext } from '../../../modules/Core/actions';

import { State } from '@/types/state.types';

const useMsTeamsContext = () => {
  const dispatch = useDispatch();
  const msTeamsContext = useSelector((state: State) => state.Core?.msTeamsContext ?? null);

  useEffect(() => {
    (async () => {
      try {
        if (!msTeamsContext?.TeamsContext && (msTeamsContext?.shouldRetryInit ?? true)) {
          await msTeamsApp.initialize();
          const context = await msTeamsApp.getContext();
          await dispatch(setMsTeamsContext({ TeamsContext: context, shouldRetryInit: false }));
        }
      } catch (_e) {
        const exception = _e as Error;
        const isMsTeamsAppNotDetected = !exception?.message.includes('No Parent window found.');
        await dispatch(
          setMsTeamsContext({ TeamsContext: null, shouldRetryInit: isMsTeamsAppNotDetected })
        );
      }
    })();
  }, [dispatch, msTeamsContext]);
};

export default useMsTeamsContext;
