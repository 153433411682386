const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Yêu cầu dịch vụ - Bộ phận trợ giúp',
    'Ref: Forbidden heading': 'Cấm',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Bạn đang quản lý các yêu cầu dịch vụ trên trang web {siteName}.',
    'Ref: Dates': 'ngày',
    'Ref: SRL list refresh': 'Làm mới danh sách',
    'Ref: Export': 'xuất khẩu',
    'Ref: Empty/not filled': 'Trống / không lấp đầy',
    'Ref: Name': 'Tên',
    'Ref: Date': 'Ngày',
    'Ref: Category': 'Loại',
    'Ref: User': 'Người dùng',
    'Ref: Location': 'Vị trí',
  },
};
export default labels;
