const labels = {
  SiteDetails: {
    'Select site': 'Chọn trang web này',
    'Ref: Page title': 'Chi tiết trang web',
    'Ref: Skip map': 'Bỏ qua nội dung chính',
    'Ref: Unavailable site': 'Xem trước trang web không thể được cung cấp cho người dùng của bạn',
    'Ref: Sorry, no description yet': 'Xin lỗi, chúng tôi chưa có mô tả.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Không tìm thấy tọa độ trang web',
    'Ref: Page title': 'Trang web',
    'Recently visited': 'Đã viếng thăm gần đây',
    'All other sites': 'Tất cả các trang web khác',
    'Sites in your country': 'Các trang web ở quốc gia của bạn',
    'Search results': 'Kết quả tìm kiếm',
    'Select site': 'Chọn trang web',
    'Ref: Select site with QR Scanner': 'Chọn Trang web với máy quét QR',
    'Ref: Select site with code': 'Chọn trang web có mã',
    'Ref: Validate': 'Xác nhận',
    'Ref: Enter site code': 'Nhập mã trang web',
    'Ref: Scanner error header': 'Trang web không tìm thấy',
    'Ref: Generic error': 'Có gì đó đã sai',
    'Ref: Change site': 'Thay đổi trang web',
    'Ref: Change site description':
      'Có các mặt hàng trong giỏ hàng! Thay đổi trang web sẽ dẫn đến việc xóa giỏ hàng.',
    'Ref: Cancel': 'Hủy bỏ',
    'Ref: Not found': 'Mã QR này chưa được công nhận',
    'Ref: Unauthorized error': 'Yêu cầu trái phép do mức độ hạn chế trang web',
    'Ref: Forbidden':
      'Xin lỗi, bạn không có quyền đúng để truy cập trang web này. Để sắp xếp quyền truy cập, vui lòng liên hệ với Trung tâm dịch vụ Pilbara theo số 1800 992 777',
    "Ref: We didn't find the site.": 'Chúng tôi không tìm thấy trang web.',
    'Ref: We recommend some solutions:': 'Chúng tôi đề xuất một số giải pháp:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      '1. Cố gắng thêm trang web của bạn bằng máy quét QR hoặc với sự trợ giúp của các nút bên dưới',
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Đăng xuất và đăng nhập lại bằng cách chọn đúng quốc gia',
    'Ref: The currently selected country is:': 'Quốc gia hiện được chọn là:',
  },
  SwitchSite: { 'Not on {site name}?': 'Không phải trên {site name}?' },
  SiteTile: { Details: 'Chi tiết', Select: 'Lựa chọn' },
};
export default labels;
