const labels = {
  All: {
    dailyMenu: 'Tagesmenü',
    anytime: 'Jederzeit',
    breakfast: 'Frühstück',
    morningBreak: 'MorgenFrühstück',
    lunch: 'Mittagessen',
    afternoonBreak: 'Nachmittagspause ',
    dinner: 'Abendessen',
    supper: 'Abendessen',
    nightMeal: 'Nachtessen',
  },
  FoodFacilitiesList: {
    'Ref: Menu Page title': 'Menüs',
    'Ref: View details': 'Details anzeigen',
    'Ref: Facility details': 'Detail der Anlage',
  },
  OpeningHours: { everyday: 'jeden Tag', 'open all day': 'Ganztags geöffnet' },
  ProductsList: {
    'Ref: Page title': 'Speisekarte',
    'Ref: Facility details': 'Detail der Anlage',
    'Ref: Search products list': 'Suchteile',
    'Ref: filter label: date': 'Datum',
    'Ref: filter label: menus': 'Speisekarte',
    'Ref: filter label: moment': 'Moment',
    'Ref: filter label: category': 'Kategorie',
    'Ref: filter label: dishes': 'Teller',
    'Ref: filter label: highlight': 'Filter mit Gerichten hervorheben oder verstecken',
    All: 'Alle',
  },
  ProductDetails: { 'Ref: Page title': 'Produktdetails' },
  Widget: {
    'Ref: Carousel title': 'Menüs',
    'No info yet, come back later': 'Noch keine Informationen, komm später zurück 😉',
    'Ref: menus': 'Menüs',
    'Ref: see all': 'Alles sehen',
  },
};
export default labels;
