const labels = {
  All: {
    Feedback: 'Comentários',
    'Ref: Switch site component intro sentence': 'Seu feedback será vinculado ao site {site name}.',
    Category: 'Categoria',
    Subject: 'Tópico',
    Comment: 'Comentário',
    'Date of your experience': 'Data da sua experiência *',
    'Ref: Hint': 'Você tem um problema ou trabalho que precisa ser fixo?',
    'Ref: Log Request': 'Registrar uma solicitação',
    'Ref: Describe issue': 'Por favor, conte -nos sobre sua experiência',
    Date: 'Encontro',
    'Ref: Share your opinion header': 'Compartilhe sua opinião sobre sua experiência no trabalho',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Restaurante / Café',
    'Desk / workstation area': 'Área de mesa / estação de trabalho',
    'Meeting room facilities': 'Instalações da sala de reuniões',
    'Coffee/tea facilities (pantry)': 'Instalações de café/chá (despensa)',
    'Restrooms / washrooms': 'Banheiros / banheiros',
    'Reception / Security': 'Recepção / segurança',
    'Conference center': 'Centro de Conferência',
    'Fitness center': 'Academia',
    Mailroom: 'Sala de correio',
    'Bicycle room': 'Sala de bicicleta',
    'Shuttle services': 'Serviços de transporte',
    Lockers: 'Armários',
    Helpdesk: 'Central de Ajuda',
  },
  Create: {
    'Tell us!': 'Nos digam!',
    'Ref: All Ears': 'Somos todos ouvidos!',
    'Ref: Provide Description': 'Por favor, forneça uma descrição',
    'Ref: Fill-in-the-blank sentence': 'Eu quero fornecer um {0} em relação a {1}',
    'Ref: Link text to service request module': 'Um pedido ou um problema? Logá-lo aqui!',
    'Complaint type': 'Reclamação {emoji}',
    'Feedback type': 'Feedback {emoji}',
    'Compliment type': 'Elogio {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Ocorreu um erro e seu feedback não foi enviado. Se este erro persistir, entre em contato com seu central de ajuda.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Seu feedback foi submetido. Nossa equipe de suporte no local de trabalho irá ler atentamente. Se apropriado, vamos voltar para você.',
    'New feedback': 'Novos comentários',
  },
  CreateFlow: {
    Description: 'Descrição',
    'Ref: Provide Description': 'Por favor, forneça uma data e descrição',
    'Ref: I would like to provide a': 'Eu gostaria de fornecer um',
    'Ref: in regards to': 'em relação a',
    'Ref: &': '&',
    'Ref: at': 'no',
    'Ref: on the': 'no',
  },
};
export default labels;
