import { Capacitor } from '@capacitor/core';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ItemsListIcon, LocationOffIcon, MapIcon, QrIcon } from '../../../../assets/icons';
import { SiteIllustration } from '../../../../assets/illustrations';
import Button, { BUTTON_LOOK } from '../../../../components/atoms/Button';
import Input from '../../../../components/atoms/Input/Input';
import { MapCoordinates } from '../../../../components/atoms/Map/Map.types';
import {
  siteActiveGeneratedIcon,
  siteInactiveGeneratedIcon,
} from '../../../../components/atoms/Map/mapIconsGenerator';
import useGeolocation from '../../../../components/hooks/useGeolocation';
import { Alert } from '../../../../components/molecules/Alert';
import MapWithMarkerDetails from '../../../../components/molecules/MapWithMarkerDetails/MapWithMarkerDetails';
import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import QrCodeReader from '../../../../components/molecules/QrCode/QrCodeReader';
import { TileSkeleton } from '../../../../components/molecules/Tile';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Modal from '../../../../components/organisms/Modal';
import { SectionTileList } from '../../../../components/organisms/TilesList/SectionList';
import ListPage from '../../../../components/templates/ListPage/ListPage';
import { ListPageSearch } from '../../../../components/templates/ListPage/ListPage.types';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import { SIZE } from '../../../../constants';
import { getFlagClassName } from '../../../../helpers/getFlagClassName';
import useToggle from '../../../../helpers/hooks/useToggle';
import { DEFAULT_COORDINATES, MAP_ZOOM_COVERAGE_IN_MILES } from '../../config';
import { ISite } from '../../types/sites.types';
import SiteTile from '../SiteTile/SiteTile';

import {
  getMarkersFromSites,
  getSitesByCurrentCountry,
  sortSitesByDistance,
  sortSitesByLastVisited,
  useCurrentGeography,
} from './LocationListPage.helper';
import { ILocationListPageProps, LOCATION_LIST_TYPE } from './LocationListPage.types';

import inheritstyles from '../../../Core/components/GeographySelector/GeographySelector.module.css';
import styles from './LocationListPage.module.css';

const LocationListPage = ({
  title,
  withNavBar,
  hideAllWidgets,
  hasBackLink,
  sites = [],
  visited = [],
  isLocked,
  isSiteCodeLocked,
  loading,
  label,
  siteCodeError,
  onSiteSelection,
  onValidateSiteCode,
  onScanSiteCode,
  handleCodeError,
  defaultDisplayType,
  isMapEnabled,
  enableUserLocation = true,
  className,
}: ILocationListPageProps) => {
  const initialListType = defaultDisplayType === LOCATION_LIST_TYPE.MAP;
  const {
    state: isShowingMap,
    toggleOn: showMap,
    toggleOff: showList,
  } = useToggle(initialListType);

  const {
    state: isSiteCodeModalOpen,
    toggleOn: openSiteCodeModal,
    toggleOff: closeSiteCodeModal,
  } = useToggle(false);

  const {
    state: isScanModalOpen,
    toggleOn: openScanModal,
    toggleOff: closeScanModal,
  } = useToggle(false);

  const {
    state: isScanErrorOpen,
    toggleOn: openScanErrorModal,
    toggleOff: closeScanErrorModal,
  } = useToggle(false);

  const [siteCode, setSiteCode] = useState('');
  const [searchedQueryText, setSearchedQueryText] = useState('');
  const currentGeo = useCurrentGeography();
  const { geoLocation, isGeoLoading } = useGeolocation({
    getUserLocationOnComponentMount: enableUserLocation,
  });

  const handleEmptySitesList = useCallback(() => {
    if (!sites.length && !isLocked && Capacitor.getPlatform() !== 'web') openScanModal();
    else closeScanModal();
  }, [closeScanModal, isLocked, openScanModal, sites.length]);

  useEffect(() => {
    handleEmptySitesList();
  }, [handleEmptySitesList]);

  const inactiveMarkerIcon = siteInactiveGeneratedIcon();
  const activeMarkerIcon = siteActiveGeneratedIcon();

  const handleSiteSelection = useCallback(
    (selectedItem: ISite) => {
      if (onSiteSelection) onSiteSelection(selectedItem);
    },
    [onSiteSelection]
  );

  const handleSearchChange = (query: string) => {
    setSearchedQueryText(query);
  };

  const search: ListPageSearch = {
    searchableKeys: ['name', 'strAddress'],
    handleSearchChange,
  };

  const getSections = useCallback(() => {
    const isSearchingSite = Boolean(searchedQueryText);
    const visitedSitesIds = visited.map((site: ISite) => site.id);

    if (isSearchingSite) {
      return [
        {
          title: label('Search results'),
          key: label('Search results'),
          filter: (items: ISite[]) => items,
        },
      ];
    }
    return [
      {
        title: label('Recently visited'),
        key: label('Recently visited'),
        filter: (sites: ISite[]) => {
          const recentSites = sites.filter((site) => visitedSitesIds.includes(site.id));
          return sortSitesByLastVisited(recentSites, visited);
        },
      },
      {
        title: label('Sites in your country'),
        key: label('Sites in your country'),
        filter: (sites: ISite[]) => {
          return getSitesByCurrentCountry(sites, currentGeo);
        },
      },
      {
        title: label('All other sites'),
        key: label('All other sites'),
        filter: (sites: ISite[]) => {
          const restOfSites = sites.filter((site) => !visitedSitesIds.includes(site.id));
          const sitesInCurrentCountry = getSitesByCurrentCountry(sites, currentGeo);
          return restOfSites.filter((site) => !sitesInCurrentCountry.includes(site));
        },
      },
    ];
  }, [label, searchedQueryText, currentGeo, visited]);

  const sortedSites = useMemo(() => {
    return sortSitesByDistance(sites, geoLocation).map((site) => {
      const addressParts = [
        site?.address?.line1,
        site?.address?.line2,
        site?.address?.line3,
        site?.address?.city,
        site?.address?.postalCode,
        site?.address?.stateOrProvince,
        site?.address?.country,
      ].filter(Boolean);
      const strAddress = addressParts.join(',');
      return { ...site, strAddress };
    });
  }, [geoLocation, sites]);

  const mapRender = (filteredItems: any[]) => {
    const filteredMarkers = getMarkersFromSites({
      sites: filteredItems.map(({ data }) => data),
      inactiveMarkerIcon,
      activeMarkerIcon,
    });
    const filteredMarkersWithItemDetails = filteredMarkers.map((marker) => ({
      ...marker,
      data: filteredItems.find((item) => item.data.id === marker.id),
    }));

    return (
      <MapWithMarkerDetails
        inheritStyle={styles.map}
        markers={filteredMarkersWithItemDetails}
        activeMarkerIcon={activeMarkerIcon}
        geolocation={geoLocation}
        center={
          (geoLocation as MapCoordinates) ||
          filteredMarkersWithItemDetails[0]?.coordinates ||
          DEFAULT_COORDINATES
        }
        milesToShowOnMap={MAP_ZOOM_COVERAGE_IN_MILES}
        positionCoordinates={geoLocation as MapCoordinates}
        label={label}
      />
    );
  };

  const handleSiteCodeModalOpen = useCallback(() => {
    handleCodeError && handleCodeError(undefined);
    openSiteCodeModal();
  }, [handleCodeError, openSiteCodeModal]);

  const actions = useMemo(
    () => (
      <ActionsBar>
        {onScanSiteCode && (
          <>
            <QrCodeReader
              label={label}
              buttonLabel={label('Ref: Select site with QR Scanner')}
              isScanOpen={isScanModalOpen}
              title={label('Ref: Select site with QR Scanner')}
              buttonProps={{
                look: BUTTON_LOOK.PRIMARY,
                inheritStyle: classNames('d-block'),
                affix: QrIcon,
                contentCenterAlign: true,
                'data-testid': 'location-list-qr-code',
              }}
              onQrCodeScan={(response) => onScanSiteCode(response, openScanErrorModal)}
            />

            <Alert
              isOpen={isScanErrorOpen}
              onDismiss={closeScanErrorModal}
              className="popup-warning"
              header={label('Ref: Scanner error header')}
              message={label(siteCodeError || '')}
              buttons={[label('Ref: ok')]}
              data-testid="location-list-qr-code-error"
            />
          </>
        )}
        <Button
          look={BUTTON_LOOK.SECONDARY}
          inheritStyle={classNames(styles.actionButton)}
          onClick={handleSiteCodeModalOpen}
          data-testid="location-list-site-code"
        >
          {label('Ref: Select site with code')}
        </Button>
      </ActionsBar>
    ),
    [
      closeScanErrorModal,
      handleSiteCodeModalOpen,
      isScanErrorOpen,
      isScanModalOpen,
      label,
      onScanSiteCode,
      openScanErrorModal,
      siteCodeError,
    ]
  );

  const aside = useMemo(
    () => (
      <Column.Complementary>
        <SiteIllustration />
      </Column.Complementary>
    ),
    []
  );

  const sitesWithTestingId = useMemo(
    () =>
      sortedSites.map((site) => ({
        ...site,
        'data-testid': `site-${site.id}`,
      })),
    [sortedSites]
  );

  if (isLocked) return <LoadingPage />;

  const listRenderer = (filteredItems: any[]) => {
    if (filteredItems.length === 0) {
      return (
        <div className={classNames(styles.noResultWrapper)}>
          <LocationOffIcon className={classNames(styles.locationOffWrapper)} />
          <p className={classNames('bodySBold', 'mb-S')}>
            {label("Ref: We didn't find the site.")}
          </p>
          <p className={classNames('bodySBold', 'mb-S')}>
            {label('Ref: We recommend some solutions:')}
          </p>
          <p className="bodySDefault">
            {label(
              'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below'
            )}
            <br />
            {label('Ref: 2. Log out and log back in by selecting the correct country')}
          </p>
          <hr className={classNames(styles.grayDivider)} />
          <p className="bodySDefault">{label('Ref: The currently selected country is:')}</p>
          <div className={classNames(styles.flagCountryWrapper)}>
            <span
              className={classNames(inheritstyles.flags, getFlagClassName(currentGeo?.code))}
            ></span>
            <p className="bodySDefault">{currentGeo?.name}</p>
          </div>
        </div>
      );
    }

    return (
      <SectionTileList
        sections={getSections()}
        config={{ collapsibleSections: true }}
        items={filteredItems}
        itemRenderer={(item) => {
          return !isShowingMap ? (
            <SiteTile
              key={`${item.id}`}
              site={item}
              onSiteSelection={handleSiteSelection}
              data-testId={`site-tile-${item.id}`}
            />
          ) : (
            <></>
          );
        }}
      />
    );
  };

  return (
    <>
      <ListPage
        data-testid="sites-location-list-page"
        title={title}
        withNavBar={withNavBar}
        hideAllWidgets={hideAllWidgets}
        hasBackLink={hasBackLink}
        isLoading={loading}
        config={{ collapsibleSections: true }}
        search={!isShowingMap && sortedSites.length > 4 ? search : undefined}
        items={sitesWithTestingId}
        customListRenderer={isShowingMap ? mapRender : listRenderer}
        aside={aside}
        actions={actions}
        listTopContent={
          isMapEnabled && (
            <div className={styles.toggleView}>
              <Button
                look={isShowingMap ? BUTTON_LOOK.SECONDARY_INLINE : BUTTON_LOOK.PRIMARY}
                onClick={showList}
                size={SIZE.SMALL}
                contentCenterAlign
                affix={ItemsListIcon}
                data-testid="location-list-show-list"
              >
                {label('list', { textTransform: 'capitalize' })}
              </Button>
              <Button
                look={!isShowingMap ? BUTTON_LOOK.SECONDARY_INLINE : BUTTON_LOOK.PRIMARY}
                onClick={showMap}
                size={SIZE.SMALL}
                loading={isGeoLoading}
                contentCenterAlign
                affix={MapIcon}
                data-testid="location-list-show-map"
              >
                {label('map', { textTransform: 'capitalize' })}
              </Button>
            </div>
          )
        }
      >
        {loading &&
          Array.apply(null, Array(10)).map((_, i) => <TileSkeleton key={i} withoutActions />)}
      </ListPage>
      <Modal
        isOpen={isSiteCodeModalOpen}
        onDismiss={() => {
          setSiteCode('');
          closeSiteCodeModal();
        }}
        title={label('Ref: Select site with code')}
        footer={
          <Button
            inheritStyle={classNames(styles.actionButton)}
            look={BUTTON_LOOK.PRIMARY}
            disabled={!siteCode}
            loading={isSiteCodeLocked}
            onClick={() => onValidateSiteCode && onValidateSiteCode(siteCode, closeSiteCodeModal)}
            data-testid="location-list-site-code-validate"
          >
            {label('Validate')}
          </Button>
        }
      >
        {siteCodeError && (
          <Notification
            look={NOTIFICATION_LOOK.ERROR}
            title={label(siteCodeError)}
            inheritStyle={classNames(styles.errorMessage, 'mb-M')}
          />
        )}

        <Modal.ColumnLayout className={className}>
          <Input
            data-testid="location-page-site-code"
            inputLabel={label('Ref: Enter site code')}
            value={siteCode}
            onInputChange={(val) => {
              setSiteCode(val);
              handleCodeError && handleCodeError(undefined);
            }}
          />
        </Modal.ColumnLayout>
      </Modal>
    </>
  );
};

export default LocationListPage;
