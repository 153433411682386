const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'Palvelupyyntö - Helpdesk',
    'Ref: Forbidden heading': 'Kielletty',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'Hallinnoit palvelupyynnöt sivustolla {siteName}.',
    'Ref: Dates': 'Päivämäärät',
    'Ref: SRL list refresh': 'Päivitä lista',
    'Ref: Export': 'viedä',
    'Ref: Empty/not filled': 'Tyhjä / ei täytetty',
    'Ref: Name': 'Nimi',
    'Ref: Date': 'Päivämäärä',
    'Ref: Category': 'Kategoria',
    'Ref: User': 'Käyttäjä',
    'Ref: Location': 'Sijainti',
  },
};
export default labels;
