const labels = {
  All: {
    'starts at': 'začíná v',
    'ends at': 'končí',
    location: 'Umístění',
    'coming date': 'Datum příjmu',
    'Ref: Currently event not preferred. Click to make preferred':
      'V současné době není preferována událost. Kliknutím proveďte preferenční',
    'Ref: Currently event preferred. Click to make not preferred':
      'V současné době upřednostňovala událost. Kliknutím nebudete upřednostňováni',
  },
  EventDetails: {
    details: 'Podrobnosti',
    'Ref: Page title': 'Podrobnosti o události',
    'Event dates': 'Datum akce',
    'See online:': 'Zobrazit online:',
    'See all dates': 'Zobrazit všechny daty',
    'Remove from my selection': 'Odstranit z mého výběru',
    'Add to my selection': 'Přidat do mé preferované',
    'Ref: explain calendar usage':
      'Data událostí jsou zvýrazněna v následujícím kalendáři. Nenechte si ujít události, které se vám líbí! Kliknutím na datum události v kalendátku stáhněte pozvánku. Poté otevřete stažený soubor, abyste přidali událost do preferovaného kalendáře.',
    'Ref: Favorite': 'Oblíbený',
    'Ref:Feedback': 'Zpětná vazba',
    'Ref: Event site different title': 'Tato událost nepatří k vašemu současnému webu',
    'Ref: Event site different description':
      'Prohlížíte si událost, která nepatří k vašemu současnému webu. Nebudete to vidět v seznamu událostí.',
    'Ref: See all dates': 'Podívejte se na všechna data',
    'Ref: From': 'Z',
    'Ref: to': 'na',
    'Ref: Coming date': 'Příchozí datum',
    'Ref: Add to calendar': 'Přidat do kalendáře',
  },
  EventsList: {
    'Ref: Page title': 'Události',
    'All dates': 'Všechna data',
    'All events': 'Všechny akce',
    'Ref: Search': 'Vyhledávání',
    'My selection': 'Moje preferované',
    'filter label: date': 'datum',
    'filter label: coming events': 'Nacházející události',
    'filter label: past events': 'Minulé události',
    'filter label: interest': 'přednost',
  },
  Widget: {
    'Ref: Carousel title': 'Události',
    'No info yet, come back later': 'Zatím žádné informace, vrať se později 😉',
  },
};
export default labels;
