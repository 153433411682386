import { screen, cleanup, fireEvent } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import renderComponent from '../../../../../helpers/tests/renderComponent';
import { defaultState as coreDefaultState } from '../../../../Core/reducers/coreReducer';
import StaysSuccess from '../StaysSuccess';

describe('StaysSuccess', () => {
  const env = global.process.env;
  beforeAll(() => {
    global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
  });

  afterAll(() => cleanup());

  describe('on initial render', () => {
    let title: HTMLElement, content: HTMLElement, homeButton: HTMLElement;

    renderComponent(StaysSuccess);

    beforeEach(() => {
      title = screen.getByTestId('stays-success-notice-title');
      content = screen.getByTestId('stays-success-notice-content');
      homeButton = screen.getByTestId('button-action-primary');
    });

    it('should have displayed success components', () => {
      expect(title).toBeTruthy();
      expect(content).toBeTruthy();
      expect(homeButton).toBeTruthy();

      expect(screen.getByText('Thank you for your staying!')).toBeTruthy();
      expect(screen.getByText('Your checkout has been successfully processed.')).toBeTruthy();
      expect(screen.getByText('Go to home')).toBeTruthy();
    });
  });

  describe('on "Go to home" button click', () => {
    let homeButton: HTMLElement;
    renderComponent(StaysSuccess);

    beforeEach(() => {
      homeButton = screen.getByTestId('button-action-primary');
    });

    it('should have redirected to homepage', () => {
      const history = createMemoryHistory();
      expect(homeButton).toBeTruthy();
      expect(screen.getByText('Go to home')).toBeTruthy();

      fireEvent.click(homeButton);
      expect(history.location.pathname).toBe('/');
    });
  });

  describe('when stayFeedbackSurveyUrl is not set - default state', () => {
    renderComponent(
      StaysSuccess,
      {},
      {
        Core: {
          ...coreDefaultState,
          services: {
            list: [
              {
                name: 'Stay',
                setupOptions: [],
              },
            ],
          },
        },
      }
    );

    it('should not display "Provide a feedback" button', () => {
      const feedbackButton = screen.queryByText('Provide a feedback');

      expect(feedbackButton).toBeFalsy();
    });
  });

  describe('when stayFeedbackSurveyUrl is set', () => {
    renderComponent(
      StaysSuccess,
      {},
      {
        Core: {
          ...coreDefaultState,
          services: {
            list: [
              {
                name: 'Stay',
                setupOptions: [
                  {
                    name: 'stayFeedbackSurveyUrl',
                    value:
                      'https://sodexo-diy-surveys.mcxplatform.de/cgi-bin/qwebcorporate.dll?idx=EVDB63',
                    code: 'stayFeedbackSurveyUrl',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                ],
              },
            ],
          },
        },
      }
    );

    it('should display "Provide a feedback" button', () => {
      const feedbackButton = screen.queryByText('Provide a feedback');
      expect(feedbackButton).toBeTruthy();
    });
  });
});
