import { renderHook, act, waitFor } from '@testing-library/react';

import { CacheService } from '../../../../services/CacheService';
import { OfflineQueueService } from '../../../../services/OfflineQueueService';
import {
  useLazyGetAssetsQuery,
  useLazyGetAuditInvitesQuery,
  useLazyGetAuditsQuery,
  useLazyGetAuditLocationsQuery,
  useLazyGetStreamsQuery,
  useCreateAuditMutation,
  endpoints,
  useCreateAuditSurveyResponseMutation,
  useCreateAndRespondAuditMutation,
  useUpdateAuditInviteStateMutation,
} from '../../api';
import { useSync } from '../../hooks';

const getAuditInvitesMock = jest.fn();
const getLocationsMock = jest.fn();
const getStreamsMock = jest.fn();
const getAuditsMock = jest.fn();
const getAssetsMock = jest.fn();
const createAuditMock = jest.fn();
const createAuditSurveyResponse = jest.fn();
const createAndRespondAudit = jest.fn();
const updateAuditInviteState = jest.fn();

jest.mock('../../api', () => ({
  ...jest.requireActual('../../api'),
  useLazyGetAuditInvitesQuery: jest.fn(),
  useLazyGetAuditLocationsQuery: jest.fn(),
  useLazyGetStreamsQuery: jest.fn(),
  useLazyGetAuditsQuery: jest.fn(),
  useLazyGetAssetsQuery: jest.fn(),
  useCreateAuditMutation: jest.fn(),
  useCreateAuditSurveyResponseMutation: jest.fn(),
  useCreateAndRespondAuditMutation: jest.fn(),
  useUpdateAuditInviteStateMutation: jest.fn(),
}));

jest.mock('../../../../services/OfflineQueueService');
jest.mock('../../../../services/CacheService');

const resultSuccess = {
  data: [],
  error: undefined,
  isError: false,
};

const resultError = {
  data: undefined,
  error: { status: 500, data: 'Error!' },
  isError: true,
};

describe('useSync', () => {
  let offlineQueueMock: any;
  let cacheMock: any;

  beforeEach(() => {
    offlineQueueMock = {
      getAll: () => [
        {
          k: 'key1',
          v: {
            url: 'https://url.com/' + endpoints.createAudit,
            method: 'post',
            data: {},
          },
        },
        {
          k: 'key2',
          v: {
            url: 'https://url.com/' + endpoints.updateAuditInvite,
            method: 'patch',
            data: {},
          },
        }
      ],
      remove: (key: string) =>
        new Promise((resolve, reject) => {
          resolve(key + ' removed');
        }),
    };

    (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

    getAuditInvitesMock.mockReturnValue(resultSuccess);
    getLocationsMock.mockReturnValue(resultSuccess);
    getStreamsMock.mockReturnValue(resultSuccess);
    getAuditsMock.mockReturnValue(resultSuccess);
    getAssetsMock.mockReturnValue(resultSuccess);
    createAuditMock.mockImplementation(
      () =>
        new Promise((resolve, reject) => {
          resolve({ data: null, error: undefined, isError: false });
        })
    );
    createAuditSurveyResponse.mockImplementation(
      () =>
        new Promise((resolve, reject) => {
          resolve({ data: null, error: undefined, isError: false });
        })
    );
    createAndRespondAudit.mockImplementation(
      () =>
        new Promise((resolve, reject) => {
          resolve({ data: null, error: undefined, isError: false });
        })
    );
    updateAuditInviteState.mockImplementation(
      () =>
        new Promise((resolve, reject) => {
          resolve({ data: null, error: undefined, isError: false });
        })
    );

    (useCreateAuditSurveyResponseMutation as jest.Mock).mockReturnValue([
      createAuditSurveyResponse,
      {},
    ]);
    (useCreateAndRespondAuditMutation as jest.Mock).mockReturnValue([createAndRespondAudit, {}]);
    (useLazyGetAuditInvitesQuery as jest.Mock).mockReturnValue([getAuditInvitesMock, { data: [] }]);
    (useLazyGetAuditLocationsQuery as jest.Mock).mockReturnValue([getLocationsMock, { data: [] }]);
    (useLazyGetStreamsQuery as jest.Mock).mockReturnValue([getStreamsMock, { data: [] }]);
    (useLazyGetAuditsQuery as jest.Mock).mockReturnValue([getAuditsMock, { data: [] }]);
    (useLazyGetAssetsQuery as jest.Mock).mockReturnValue([getAssetsMock, { data: [] }]);
    (useCreateAuditMutation as jest.Mock).mockReturnValue([createAuditMock, {}]);
    (useUpdateAuditInviteStateMutation as jest.Mock).mockReturnValue([updateAuditInviteState, {}]);
  });

  afterEach(() => {
    jest.clearAllMocks();
    jest.restoreAllMocks();
  });

  it('should fetch and post data successfully', async () => {
    const successLabel = 'Data synced successfully';
    const errorLabel = 'Error syncing data';

    const { result } = renderHook(() => useSync({ successLabel, errorLabel }));

    //Call the hook function
    act(() => {
      result.current.onFetchData();
    });

    // Check that the loading state is initially set to true
    expect(result.current.isLoading).toBe(true);

    await waitFor(() => {
      expect(result.current.isLoading).toBe(false);
      expect(getAuditInvitesMock).toHaveBeenCalledTimes(1);
      expect(getLocationsMock).toHaveBeenCalledTimes(1);
      expect(getStreamsMock).toHaveBeenCalledTimes(1);
      expect(getAuditsMock).toHaveBeenCalledTimes(1);
      expect(getAssetsMock).toHaveBeenCalledTimes(1);
      expect(createAuditMock).toHaveBeenCalledTimes(1);
      expect(updateAuditInviteState).toHaveBeenCalledTimes(1);
    });
  });

  it('should fail and output error in console when any of api calls fails', async () => {
    const successLabel = 'Data synced successfully';
    const errorLabel = 'Error syncing data';
    const consoleErrorSpy = jest.spyOn(console, 'error');

    getAuditInvitesMock.mockReturnValue(resultError);

    const { result } = renderHook(() => useSync({ successLabel, errorLabel }));

    //Call the hook function
    act(() => {
      result.current.onFetchData();
    });

    // Check that the loading state is initially set to true
    expect(result.current.isLoading).toBe(true);

    await waitFor(() => {
      expect(consoleErrorSpy).toHaveBeenCalledTimes(1);
      expect(consoleErrorSpy).toHaveBeenCalledWith([
        { data: [], error: undefined, isError: false },
        { data: [], error: undefined, isError: false },
        { data: [], error: undefined, isError: false },
        { data: [], error: undefined, isError: false },
        { data: null, error: undefined, isError: false },
        { data: null, error: undefined, isError: false },
        {
          data: undefined,
          error: { status: 500, data: 'Error!' },
          isError: true,
        },
      ]);
    });
  });

  it('should merge a related Create and Respond requests into one request', async () => {
    const successLabel = 'Data synced successfully';
    const errorLabel = 'Error syncing data';

    offlineQueueMock = {
      getAll: () => [
        {
          k: 'key1',
          v: {
            url: 'https://url.com/' + endpoints.createAudit,
            method: 'post',
            data: {
              auditId: 'audit-id',
              locationId: 'location-id',
              assetId: 'asset-id',
              assetName: 'asset-name',
              isJointAudit: true,
              jointAuditParticipants: 'Me, myself and I',
            },
          },
        },
        {
          k: 'key2',
          v: {
            url: 'https://url.com/' + endpoints.createAuditSurveyResponse,
            method: 'post',
            data: { inviteId: 'key1', auditName: 'audit-name', responses: [] },
          },
        },
      ],
      remove: (key: string) =>
        new Promise((resolve, reject) => {
          resolve(key + ' removed');
        }),
    };

    (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

    const { result } = renderHook(() => useSync({ successLabel, errorLabel }));

    // Call the hook function
    await act(async () => {
      result.current.onFetchData();
    });

    await waitFor(() => {
      expect(createAndRespondAudit).toHaveBeenCalledTimes(1);
      expect(createAndRespondAudit).toHaveBeenCalledWith({
        assetId: 'asset-id',
        assetName: 'asset-name',
        auditId: 'audit-id',
        auditName: 'audit-name',
        isJointAudit: true,
        jointAuditParticipants: 'Me, myself and I',
        locationId: 'location-id',
        responses: [],
      });
    });
  });

  describe('Check sync status', () => {
    it('should be fully synced when theres no items in offline queue and audits data is stored in cache', async () => {
      const successLabel = 'Data synced successfully';
      const errorLabel = 'Error syncing data';

      offlineQueueMock = { getAll: () => [] };
      (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

      // Mock audits cache
      cacheMock = {
        getValue: () => [
          {
            auditStreamId: '186f56a6-cf11-ec11-b6e6-002248113240',
            auditStreamName: 'Cleaning',
            id: 'f1c07d77-8fd3-ed11-a7c7-00224893bab8',
            name: 'L2 - Cleaning Jamie',
            questions: [
              { id: 'fcb6ff79-8fd3-ed11-a7c7-00224893bdeb' },
              { id: '00b7ff79-8fd3-ed11-a7c7-00224893bdeb' },
            ],
          },
        ],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock locations cache
      cacheMock = {
        getValue: () => [{ id: 'ccd68a51-4a9f-e611-80f4-c4346bc52710', name: 'Anchorage' }],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock streams cache
      cacheMock = {
        getValue: () => [{ id: '6ca91178-cf11-ec11-b6e6-002248113240', name: 'Accommodation' }],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock assets cache
      cacheMock = {
        getValue: () => [
          {
            assetLevel: 'Level 1',
            id: '8ae5b344-6355-e611-80f4-a45d36fc5a4c',
            locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
            masterAssetName: null,
            name: ' Rivergum Drive - Store room 2',
          },
        ],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      const { result } = renderHook(() => useSync({ successLabel, errorLabel }));

      let value: any;

      // Call the hook function
      await act(async () => {
        value = await result.current.checkSyncStatus();
      });

      expect(value).toMatchObject({ fetch: true, post: true });
    });

    it('should not be synced if there are items in offline queue', async () => {
      const successLabel = 'Data synced successfully';
      const errorLabel = 'Error syncing data';

      offlineQueueMock = {
        getAll: () => [
          {
            k: 'key1',
            v: {
              url: 'https://url.com/' + endpoints.createAudit,
              method: 'post',
              data: {},
            },
          },
        ],
      };
      (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

      // Mock audits cache
      cacheMock = {
        getValue: () => [
          {
            auditStreamId: '186f56a6-cf11-ec11-b6e6-002248113240',
            auditStreamName: 'Cleaning',
            id: 'f1c07d77-8fd3-ed11-a7c7-00224893bab8',
            name: 'L2 - Cleaning Jamie',
            questions: [
              { id: 'fcb6ff79-8fd3-ed11-a7c7-00224893bdeb' },
              { id: '00b7ff79-8fd3-ed11-a7c7-00224893bdeb' },
            ],
          },
        ],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock locations cache
      cacheMock = {
        getValue: () => [{ id: 'ccd68a51-4a9f-e611-80f4-c4346bc52710', name: 'Anchorage' }],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock streams cache
      cacheMock = {
        getValue: () => [{ id: '6ca91178-cf11-ec11-b6e6-002248113240', name: 'Accommodation' }],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock assets cache
      cacheMock = {
        getValue: () => [
          {
            assetLevel: 'Level 1',
            id: '8ae5b344-6355-e611-80f4-a45d36fc5a4c',
            locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
            masterAssetName: null,
            name: ' Rivergum Drive - Store room 2',
          },
        ],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      const { result } = renderHook(() => useSync({ successLabel, errorLabel }));

      let value: any;

      // Call the hook function
      await act(async () => {
        value = await result.current.checkSyncStatus();
      });

      expect(value).toMatchObject({ fetch: true, post: false });
    });

    it('should not be synced if not all the audit data is cached', async () => {
      const successLabel = 'Data synced successfully';
      const errorLabel = 'Error syncing data';

      offlineQueueMock = { getAll: () => [] };
      (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

      // Mock audits cache
      cacheMock = { getValue: () => undefined };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock locations cache
      cacheMock = {
        getValue: () => [{ id: 'ccd68a51-4a9f-e611-80f4-c4346bc52710', name: 'Anchorage' }],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock streams cache
      cacheMock = {
        getValue: () => [{ id: '6ca91178-cf11-ec11-b6e6-002248113240', name: 'Accommodation' }],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock assets cache
      cacheMock = {
        getValue: () => [
          {
            assetLevel: 'Level 1',
            id: '8ae5b344-6355-e611-80f4-a45d36fc5a4c',
            locationId: '2988220c-8829-e611-80ec-a45d36fc5a4c',
            masterAssetName: null,
            name: ' Rivergum Drive - Store room 2',
          },
        ],
      };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      const { result } = renderHook(() => useSync({ successLabel, errorLabel }));

      let value: any;

      // Call the hook function
      await act(async () => {
        value = await result.current.checkSyncStatus();
      });

      expect(value).toMatchObject({ fetch: false, post: true });
    });

    it('should not be synced if there are items in offline queue and audit data is not cached', async () => {
      const successLabel = 'Data synced successfully';
      const errorLabel = 'Error syncing data';

      offlineQueueMock = {
        getAll: () => [
          {
            k: 'key1',
            v: {
              url: 'https://url.com/' + endpoints.createAudit,
              method: 'post',
              data: {},
            },
          },
        ],
      };
      (OfflineQueueService.getInstance as jest.Mock).mockReturnValue(offlineQueueMock);

      // Mock audits cache
      cacheMock = { getValue: () => undefined };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock locations cache
      cacheMock = { getValue: () => undefined };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock streams cache
      cacheMock = { getValue: () => undefined };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      // Mock assets cache
      cacheMock = { getValue: () => undefined };
      (CacheService.getInstance as jest.Mock).mockReturnValueOnce(cacheMock);

      const { result } = renderHook(() => useSync({ successLabel, errorLabel }));

      let value: any;

      // Call the hook function
      await act(async () => {
        value = await result.current.checkSyncStatus();
      });

      expect(value).toMatchObject({ fetch: false, post: false });
    });
  });
});
