const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Twoja prośba',
    'Ref: Summary': 'Streszczenie',
    'Ref: Request category': 'Kategoria żądania',
    'Ref: Person affected': 'Osoba dotknięta tym prośbą',
    'Ref: Your phone number': 'Numer telefonu',
    'Ref: Description': 'Opis',
    'Ref: Service Requests': 'Żądania serwisowe',
    'Ref: Request Service': 'Żądanie usługi',
    'Ref: Success title': 'Twoja prośba została utworzona',
    'Ref: Error title': 'Ups!',
    'Ref: Error subtitle': 'Wystąpił błąd. Proszę spróbować ponownie.',
    'Ref: Try again': 'Spróbuj ponownie',
    'Ref: you pronoun': '(ty)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'To jest Twoje centrum wsparcia w miejscu pracy',
    'Ref: Tutorial: tabname': 'Upraszanie',
    'Ref: Tutorial: body': 'Jesteśmy tutaj, aby Ci pomóc. Zaloguj się o jedno żądanie na wpis.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Prosisz o usługę na stronie {siteName}.',
    'Ref: Building': 'Budynek',
    'Ref: Room': 'Pokój',
    'Ref: Description help text': 'Im bardziej jesteś szczegółowo, tym lepiej możemy cię wspierać.',
    'Ref: Next': 'Następny',
    'Ref: Your request': 'Twoja prośba',
    'Ref: PhoneNumber error msg': 'Numer telefonu jest nieprawidłowy',
    'Ref: Type at least 3 characters to search': 'Wpisz co najmniej 3 znaki, aby wyszukać',
    'Ref: No affected person results': 'Żadna osoba dopasowana do kryteriów',
    'Ref: Required': '* Wymagany',
  },
  Summary: {
    'Ref: Location': 'Lokalizacja',
    'Ref: Attachment': 'Załączniki',
    'Ref: Submit': 'Składać',
    'Ref: No affected person results': 'Brak osób spełniających żądane kryteria',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Zgłoszenie serwisowe',
    'Ref: createdOn': 'Utworzone na',
    'Ref: summary': 'Streszczenie',
    'Ref: category': 'Kategoria żądania',
    'Ref: request raised for': 'Osoba dotknięta tym prośbą',
    'Ref: phoneNumber': 'Numer telefonu',
    'Ref: location': 'Numer telefonu',
    'Ref: description': 'Opis',
    'Ref: attachments': 'Załączniki',
  },
};
export default labels;
