import { APP_NAME } from '../../constants';
import { serviceTypes } from '../config';

import { FeedbackConfig, FilterOptions, FilterValues } from './types/config.types';
import { FeedbackFlowActions } from './types/index';

import { getAppSpecificData } from '@/helpers/misc';
import { SentimentRatingType } from '@/modules/ServiceRequest/types/types';
import baseApi from '@/services/api/baseApi';
import dspApi from '@/services/api/dspApi';

export const moduleId = 'Feedback';
export const cacheVersion = '0';

export const modulePath = serviceTypes.Feedback.path;

export const pagePaths = {
  Module: modulePath,
  Create: modulePath + '/create',
  Form: modulePath + '/form',
  Success: modulePath + '/success',
  Failure: modulePath + '/failure',
};

//note: 'Trouble logging in': 224960003 is not added because it's not selectable by the user
export const feedbackTypes: Record<string, string> = {
  'Compliment type': '224960000',
  'Feedback type': '224960002',
  'Complaint type': '224960001',
};

export const sentimentToFeedback: Record<string, string> = {
  224960000: 'Compliment type',
  224960001: 'Compliment type',
  224960002: 'Feedback type',
  224960003: 'Feedback type',
  224960004: 'Complaint type',
};

export const sentimentTypes: SentimentRatingType[] = [
  { id: '224960004', name: 'Poor' },
  { id: '224960003', name: 'Fair' },
  { id: '224960002', name: 'Good' },
  { id: '224960001', name: 'Very good' },
  { id: '224960000', name: 'Excellent' },
];

export const getFeedbackModuleConfig = () => {
  const configuration = {
    [APP_NAME.MYVILLAGE]: {
      api: baseApi,
      version: 'v2',
      dynamicFlow: {
        isActive: true,
        steps: [
          {
            id: 'feedbackTypeId',
            prefix: 'Ref: I would like to provide a',
            getOptions: async (actions: FeedbackFlowActions) => {
              const result = actions?.getFeedbackTypes && (await actions.getFeedbackTypes());
              if (result?.isSuccess) return result?.data?.feedbackTypes ?? [];
              return [];
            },
          },
          {
            id: 'serviceTypeId',
            prefix: 'Ref: in regards to',
            getOptions: async (actions: FeedbackFlowActions) => {
              const result = actions?.getServiceTypes && (await actions.getServiceTypes());
              if (result?.isSuccess) return result?.data?.serviceRequestTypes ?? [];
              return [];
            },
          },
          {
            id: 'areaTypeId',
            prefix: 'Ref: &',
            getOptions: async (actions: FeedbackFlowActions) => {
              const result = actions?.getAreaTypes && (await actions.getAreaTypes());
              if (result?.isSuccess) return result?.data?.areaTypes ?? [];
              return [];
            },
            filter: (values: FilterValues, options: FilterOptions) => {
              const serviceTypeId = values.serviceTypeId?.value;
              if (!options || !serviceTypeId) return options ?? [];
              return options.filter(({ data }) => serviceTypeId === data?.serviceTypeId);
            },
          },
          {
            id: 'subjectTypeId',
            prefix: 'Ref: &',
            getOptions: async (actions: FeedbackFlowActions) => {
              const result = actions?.getSubjectTypes && (await actions.getSubjectTypes());
              if (result?.isSuccess) return result?.data?.subjectTypes ?? [];
              return [];
            },
            filter: (values: FilterValues, options: FilterOptions) => {
              const areaTypeId = values.areaTypeId?.value;
              if (!options || !areaTypeId) return options;
              return options.filter(({ data }) => data.areaTypeId === areaTypeId);
            },
          },
          {
            id: 'siteId',
            prefix: 'Ref: at',
            getOptions: async (actions: FeedbackFlowActions) => {
              if (actions.getSites) return await actions.getSites();
              return [];
            },
          },
          {
            id: 'date',
            prefix: 'Ref: on the',
            noOptions: true,
          },
        ],
      },
    },
    default: {
      api: dspApi,
      version: 'v1',
      dynamicFlow: {
        isActive: false,
        steps: [],
      },
    },
  };

  return getAppSpecificData<FeedbackConfig>(configuration);
};
