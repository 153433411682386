import { TextareaChangeEventDetail } from '@ionic/core';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { HelpdeskIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import FormLine from '../../../../components/atoms/FormLine/FormLine';
import Input from '../../../../components/atoms/Input/Input';
import Textarea from '../../../../components/atoms/Textarea/Textarea';
import Card from '../../../../components/molecules/Card/Card';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { FormValidationAlert, validateForm } from '../../../../components/validateForm';
import { INPUT_TYPE } from '../../../../constants';
import { useCreateFeedbackMutation } from '../../api/api';
import { feedbackTypes } from '../../config';
import { useFeedbackTranslation } from '../../hooks/useFeedbackTranslation';

import { FormProps } from './Form.types';

import useSite from '@/modules/Core/hooks/useSite';

const getFormValidationRules = (subject: string, comment: string, feedbackDate: string) => {
  return {
    Subject: () => !!subject.length,
    Comment: () => !!comment.length,
    Date: () => !!feedbackDate,
  };
};

const Form = ({ isLocked, pageTitle, category, subject, successPath, failurePath }: FormProps) => {
  const [createFeedback] = useCreateFeedbackMutation();
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const history = useHistory();
  const { label } = useFeedbackTranslation(__filename);

  const [feedback, setFeedback] = useState({
    category: category ?? '',
    subject: subject ?? '',
    comment: '',
    feedbackDate: new Date().toISOString(),
  });
  const [submitting, setSubmitting] = useState(false);
  const [showFormValidationAlert, setShowFormValidationAlert] = useState(false);

  const { isValid: isFormValid, missingInfo: formMissingInfo } = validateForm(
    getFormValidationRules(feedback.subject, feedback.comment, feedback.feedbackDate)
  );

  const handleSubjectChange = (val: string) => {
    setFeedback((prev) => ({ ...prev, subject: val }));
  };

  const handleCommentChange = (e: CustomEvent<TextareaChangeEventDetail>) => {
    setFeedback((prev) => ({ ...prev, comment: e.detail.value || '' }));
  };

  if (isLocked) return <LoadingPage />;

  const handleSubmit = isFormValid
    ? async () => {
        setSubmitting(true);
        const type = 'Feedback type';
        let title = feedback.subject;
        if (feedback.category) title = feedback.category + ' - ' + title;
        const result = await createFeedback({
          feedbackType: {
            id: feedbackTypes[type].toString(),
            name: type,
          },
          title,
          comment: feedback.comment,
          feedbackDate: feedback.feedbackDate,
          siteId: site.id,
          withAccessToken: true,
        });
        setSubmitting(false);
        if (result && 'error' in result) {
          history.push(failurePath);
        } else {
          history.push(successPath);
        }
      }
    : () => setShowFormValidationAlert(true);

  return (
    <SimpleFormPage title={pageTitle ? pageTitle : label('Feedback')} withNavBar={false}>
      <Container>
        <Column.Main>
          <Card>
            <FormLine data-testid="feedback-form-subject">
              <Input
                data-testid="feedback-form-subject"
                id="subject"
                inputLabel={label('Subject')}
                inputType={INPUT_TYPE.TEXT}
                placeholder=""
                value={feedback.subject}
                onInputChange={handleSubjectChange}
              />
            </FormLine>
            <Textarea
              id="comment"
              label={label('Comment')}
              placeholder=""
              value={feedback.comment}
              rows={5}
              onChange={handleCommentChange}
            />
          </Card>
          <FormValidationAlert
            missingInfo={formMissingInfo}
            show={showFormValidationAlert}
            label={label}
            msgCodes={{}}
            onDidDismiss={() => setShowFormValidationAlert(false)}
          />
        </Column.Main>
        <Column.Complementary>
          <HelpdeskIllustration />
        </Column.Complementary>
        <ActionsBar>
          <Button data-testid="feedback-form-submit" onClick={handleSubmit} loading={submitting}>
            {label('submit', { textTransform: 'capitalize' })}
          </Button>
        </ActionsBar>
      </Container>
    </SimpleFormPage>
  );
};

export default Form;
