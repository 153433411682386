import { cleanup, screen, waitFor } from '@testing-library/react';

import { APP_DISPLAY_NAME } from '../../../../../constants';
import { SERVICE } from '../../../../config';
import { LocationProps } from '../../../types/types';
import Locate from '../Locate';

import renderComponent from '@/helpers/tests/renderComponent';

const searchableLocations: LocationProps[] = [];

jest.mock('@/components/molecules/QrCode/QrCodeReader', () => () => (
  <div data-testid="qr-scanner" />
));

jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () => ({ id: '1', name: 'Test Site' }),
}));

jest.mock('@/modules/Core/api/account/userContextApi', () => ({
  useGetAccountUserContextQuery: () => ({
    data: { username: 'apptest@myway.com', mobile: '333333333' },
  }),
}));

jest.mock('../../../api/index', () => ({
  useGetLocationsQuery: () => ({
    data: searchableLocations,
    isLoading: false,
  }),
  useLazyValidateQrCodeQuery: () => {
    const validateQrCode = () => {};
    return [validateQrCode];
  },
}));

const mockStore = {
  Core: {
    user: { username: 'apptest@myway.com', mobile: '333333333' },
    context: { site: { id: 'cba4f2b6-f47b-e911-a97d-000d3a2bc547', name: 'FMG Hamilton' } },
    notifications: {
      unreadNotificationCount: 0,
      items: [],
    },
    services: {
      list: [
        {
          name: SERVICE.SERVICE_REQUEST,
          setupOptions: [
            {
              name: 'enableAffectedPerson',
              value: 'true',
              code: 'enableAffectedPerson',
              isServiceType: true,
              isServicePointer: false,
            },
            {
              name: 'enableCategories',
              value: 'true',
              code: 'enableCategories',
              isServiceType: true,
              isServicePointer: false,
            },
          ],
        },
      ],
    },
    access: { shouldSelectSiteContext: false },
  },
  Shared: {
    language: {
      currentLanguageCode: 'en',
    },
  },
  ServiceRequest: {
    qrInfo: { isSiteChanged: false, scanProcessing: false, scanError: false, scanQRCode: false },
  },
};

describe('Service Request Locate', () => {
  const env = global.process.env;
  afterAll(() => cleanup());
  // TODO more tests
  beforeAll(() => {
    global.process.env = { ...env, REACT_APP_APP_NAME: APP_DISPLAY_NAME.MYWAY };
  });
  describe('With posibility to scan QR code of location', () => {
    renderComponent(
      Locate,
      {},
      {
        ...mockStore,
        Core: {
          ...mockStore.Core,
          services: {
            ...mockStore.Core.services,
            list: [
              {
                name: SERVICE.SERVICE_REQUEST,
                setupOptions: [
                  {
                    name: 'enableAffectedPerson',
                    value: 'true',
                    code: 'enableAffectedPerson',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                  {
                    name: 'enableCategories',
                    value: 'true',
                    code: 'enableCategories',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                  {
                    name: 'canScanLocationQr',
                    value: 'true',
                    code: 'canScanLocationQr',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                ],
              },
            ],
          },
        },
      }
    );

    it('should display Scan QR code button', () => {
      expect(screen.getByTestId('qr-scanner')).toBeTruthy();
    });
  });

  describe('With disabled manual location', () => {
    renderComponent(
      Locate,
      {},
      {
        ...mockStore,
        Core: {
          ...mockStore.Core,
          services: {
            ...mockStore.Core.services,

            list: [
              {
                name: SERVICE.SERVICE_REQUEST,
                setupOptions: [
                  {
                    name: 'enableAffectedPerson',
                    value: 'true',
                    code: 'enableAffectedPerson',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                  {
                    name: 'enableCategories',
                    value: 'true',
                    code: 'enableCategories',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                  {
                    name: 'disableManualLocation',
                    value: 'true',
                    code: 'disableManualLocation',
                    isServiceType: true,
                    isServicePointer: false,
                  },
                ],
              },
            ],
          },
        },
      }
    );

    it('should not display manual location CTA', () => {
      const manualLocationCTA = screen.queryByText('Ref: Enter manual location');
      expect(manualLocationCTA).toBeFalsy();
    });
  });

  describe('With enabled manual location', () => {
    renderComponent(Locate, {}, mockStore);

    it('should display manual location CTA', async () => {
      await waitFor(() => {
        expect(screen.queryByText('Enter location manually')).toBeTruthy();
      });
    });
  });
});
