import { Menu, MenuItem } from '../api/menuApi.types';

export const getMenuItemById = (
  menuItemId: number | string,
  menus: (Menu | undefined)[]
): MenuItem | undefined => {
  const validMenus = menus?.filter((item) => !!item);

  if (validMenus) {
    for (const menu of validMenus) {
      const menuItem = menu.menuItems.find((item) => item.menuItemId === menuItemId);
      if (menuItem) {
        return menuItem;
      }
    }
  }
  return undefined;
};
