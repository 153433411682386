import {
  Filter,
  FilterPosition,
  FilterType,
} from '../../../../components/atoms/Filters/Filters.types';
import { TranslationProps } from '../../../../localization/localization.types';
import { ProductListItem, Section } from '../../types/productList.types';

import { BUTTON_LOOK } from '@/components/atoms/Button';

const allOptionsLabel = 'all';

const getCategoriesOptions = (sections: Section[], label: TranslationProps['label']) => {
  const isOnlyOneChoice = sections.length === 1;
  if (isOnlyOneChoice) {
    return [];
  }
  const options = sections.map((section) => ({
    value: section.title,
    label: label(section.title, { textTransform: 'capitalize' }),
  }));

  return [
    {
      value: allOptionsLabel,
      label: label('Ref: all', { textTransform: 'capitalize' }),
      default: true,
    },
    ...options,
  ];
};

export const getFilters = ({
  label,
  menuItemsList,
  sections,
  moments,
  setSelectedMoment,
}: {
  menuItemsList: ProductListItem[];
  sections: Section[];
  moments: string[];
  setSelectedMoment: (moment: string) => void;
} & TranslationProps): Filter[] => {
  const getMomentPosition = () => {
    if (moments.length < 2) return FilterPosition.INVISIBLE;
    return FilterPosition.NOT_IN_MODAL;
  };

  const filters: Filter[] = [
    {
      id: 'filter_moment',
      position: getMomentPosition(),
      name: label('Ref: filter label: moment', { textTransform: 'capitalize' }),
      withTitle: false,
      options: moments.map((moment) => ({
        value: moment.toLowerCase(),
        label: label(moment, { textTransform: 'capitalize' }),
      })),
      displayType: FilterType.DROPDOWN,
      look: BUTTON_LOOK.TERTIARY,
      multiple: false,
      apply: (selectedValues: string[]) => {
        let selectedValue: string;
        if (!selectedValues?.length) {
          selectedValue = moments[0];
        } else {
          selectedValue = selectedValues[0];
        }
        setSelectedMoment(selectedValue?.toLowerCase());

        return menuItemsList
          .filter((item) => item.moment?.toLowerCase() === selectedValue.toLowerCase())
          .map((item) => item.id);
      },
    },
    {
      id: 'filter_category',
      position: FilterPosition.NOT_IN_MODAL,
      name: label('Ref: filter label: category', { textTransform: 'capitalize' }),
      withTitle: false,
      options: getCategoriesOptions(sections, label),
      displayType: FilterType.EXPANDED,
      multiple: false,
      apply: (selectedValues: string[]) => {
        if (!selectedValues?.length || selectedValues[0] === allOptionsLabel)
          return menuItemsList.map((item) => item.id);
        const selectedValue = selectedValues[0]?.toLowerCase();
        const filterMenuItems = menuItemsList.filter(
          (item) => item.category?.toLowerCase() === selectedValue
        );

        return filterMenuItems.map((item) => item.id);
      },
    },
  ];

  return filters;
};
