import classNames from 'classnames';
import { useHistory, useParams } from 'react-router';

import Title, { TITLE_SIZE } from '../../../../components/atoms/Title';
import Layout from '../../../../components/templates/Layout/Layout';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { useGetMenusQuery } from '../../api/index';
import { pagePaths } from '../../config';
import { useMenusTranslation } from '../../hooks/useMenusTranslation';

import { getMenuItem } from './ProductDetails.helper';

import styles from './ProductDetails.module.css';

const ProductDetails = () => {
  const { label } = useMenusTranslation(__filename);
  const history = useHistory();
  const params = useParams<{ id: string; facilityId: string; date: string }>();
  const { id, facilityId, date } = params;

  const { isFetching, data } = useGetMenusQuery({ facilityId, date });
  const { menus } = data || { menus: [] };

  const menuItem = getMenuItem(menus, id);

  if (!menuItem && !isFetching) history.push(pagePaths.Module);
  if (!menuItem || isFetching) return <LoadingPage />;

  return (
    <SimpleFormPage title={label('Ref: Page title')} hasBackLink withNavBar={false}>
      <Layout>
        <Title size={TITLE_SIZE.HEADLINES} className={classNames('mb-S')}>
          {menuItem.name}
        </Title>
        <Title size={TITLE_SIZE.HEADLINEXS} className={classNames('mb-L')}>
          {menuItem.priceInText}
        </Title>

        <p className={classNames(styles.description, 'bodySDefault')}>{menuItem.description}</p>
      </Layout>
    </SimpleFormPage>
  );
};

export default ProductDetails;
