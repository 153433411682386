import classNames from 'classnames';
import { Base64 } from 'js-base64';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { DownloadIcon } from '../../../../assets/icons';
import { TermsAndConditionsIllustration } from '../../../../assets/illustrations';
import Button from '../../../../components/atoms/Button';
import { HtmlContent } from '../../../../components/atoms/RenderContent';
import { TITLE_SIZE, TITLE_TAG } from '../../../../components/atoms/Title';
import Card from '../../../../components/molecules/Card/Card';
import Notification, { NOTIFICATION_LOOK } from '../../../../components/molecules/Notification';
import ActionsBar from '../../../../components/organisms/ActionsBarV2';
import Column from '../../../../components/organisms/Column';
import Container from '../../../../components/organisms/Container';
import LoadingPage from '../../../../components/templates/LoadingPage/LoadingPage';
import SimpleFormPage from '../../../../components/templates/SimpleFormPage/SimpleFormPage';
import { SIZE } from '../../../../constants';
import useLoginStatus from '../../../../helpers/hooks/useLoginStatus';
import { saveHtmlAsPdf, updateRelativePathUrl } from '../../../../helpers/misc';
import {
  useAcknowledgeLegalDocMutation,
  useLazyGetOneLegalDocumentQuery,
} from '../../api/legalDocuments/legalDocumentsApi';
import { legalDocumentsConfig, legalDocTypeNamesToCodeMap } from '../../config';
import { getLegalDocLabel } from '../../helpers/helpers';
import { useCoreTranslation } from '../../hooks/useCoreTranslation';
import { useLegalDocAndAcknowledgments } from '../../hooks/useLegalDocAndAcknowledgments';

import { getLegalDocument } from './LegalDocPage.helpers';

import styles from './LegalDocPage.module.css';

const LegalDocPage = () => {
  const history = useHistory();
  const { label } = useCoreTranslation(__filename);
  const { isLoggedIn } = useLoginStatus();
  const { version, document_type: type } = useParams<{ document_type: string; version: string }>();

  const {
    termsOfUseToAcknowledge,
    privacyPolicyToAcknowledge,
    isLoading: checkAcknowledgmentLoading,
    legalDocuments,
    acknowledgements,
  } = useLegalDocAndAcknowledgments();

  const [getOneLegalDocument, { data: legalDocument }] = useLazyGetOneLegalDocumentQuery();
  const [acknowledgeLegalDoc] = useAcknowledgeLegalDocMutation();

  const { documentId, shouldAcknowledge, isAcknowledgeAfterLegalDocModification } =
    getLegalDocument({
      docsList: legalDocuments,
      acknowledgements: acknowledgements,
      type,
      termsOfUseToAcknowledge,
      privacyPolicyToAcknowledge,
      version,
    });

  useEffect(() => {
    if (!isLoggedIn) {
      history.replace('/');
      return;
    }

    let applicableDocumentId = documentId;
    if (!applicableDocumentId && version === 'current') {
      const typeCode = legalDocTypeNamesToCodeMap[type];
      const allLegalDocsResult = legalDocuments ?? [];

      applicableDocumentId =
        allLegalDocsResult.find((document: any) => document.type?.value === typeCode)?.id || '';
    }

    if (applicableDocumentId) getOneLegalDocument({ id: applicableDocumentId });
  }, [legalDocuments, documentId, isLoggedIn, type, version, getOneLegalDocument, history]);

  const { shouldDisplayDownloadButton } = legalDocumentsConfig();

  if (!legalDocument || checkAcknowledgmentLoading) {
    return <LoadingPage />;
  }

  const notificationContent = (
    <Notification
      look={NOTIFICATION_LOOK.INFO}
      dismissable
      title={label('Ref: Legal changed notification title', {
        replace: { legal_doc_type: getLegalDocLabel(type, label) },
      })}
      inheritStyle={classNames(styles.notificationWrapper)}
    >
      {label('Ref: Legal changed notification content')}
    </Notification>
  );

  return (
    <SimpleFormPage
      hasBackLink={true}
      hideAccountWidget={shouldAcknowledge}
      title={getLegalDocLabel(type, label)}
    >
      <Container>
        <Column.Main>
          {isAcknowledgeAfterLegalDocModification && notificationContent}
          <Card
            overTitle={{
              tag: TITLE_TAG.H1,
              size: TITLE_SIZE.HEADLINES,
              children: getLegalDocLabel(type, label),
              className: styles.legalTitle,
              ...(shouldDisplayDownloadButton
                ? {
                    suffix: (
                      <Button
                        data-testid="legal-docs-save-as-pdf"
                        look="tertiary"
                        onClick={() => saveHtmlAsPdf(Base64.decode(legalDocument.data))}
                        size={SIZE.SMALL}
                        inheritStyle={styles.titleSuffix}
                      >
                        <DownloadIcon className={classNames(styles.titleIcon)} />
                      </Button>
                    ),
                  }
                : {}),
            }}
          >
            <div
              id="legalDocumentContainer"
              data-cy="legal-document-container"
              className={classNames(styles.wrapper)}
            >
              <HtmlContent data-testid="legal-doc-page-html-content">
                {updateRelativePathUrl(Base64.decode(legalDocument.data))}
              </HtmlContent>
            </div>
          </Card>
        </Column.Main>
        <Column.Complementary>
          <TermsAndConditionsIllustration />
        </Column.Complementary>
        {shouldAcknowledge ? (
          <ActionsBar>
            <Button
              data-testid="legal-docs-acknowledge"
              onClick={() => {
                acknowledgeLegalDoc({
                  documentId: documentId,
                });
                history.replace('/');
              }}
            >
              {label('Acknowledge')}
            </Button>
          </ActionsBar>
        ) : null}
      </Container>
    </SimpleFormPage>
  );
};

export default LegalDocPage;
