export default function isPath(path: string): boolean {
  if (path === '/' || path === '\\') {
    return false;
  }

  if (path.indexOf('/') !== -1 && path.indexOf('\\') !== -1) {
    return false;
  }

  const filenameWithExtensionRegex = /[^\\/]+\.[^.]+$/;
  if (!filenameWithExtensionRegex.test(path)) {
    return false;
  }

  const filePathRegex = /[\\/]/;
  return filePathRegex.test(path);
}
