import { changeLanguage } from 'i18next';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SharedState } from '../../modules/Core/types/State.types';

export const useLanguageUpdateListener = () => {
  const languageCode = useSelector(
    (state: { Shared: SharedState }) => state.Shared.language?.currentLanguageCode
  );

  useEffect(() => {
    if (languageCode) changeLanguage(languageCode);
  }, [languageCode]);
};
