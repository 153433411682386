const labels = {
  Success: {
    'Ref: Body':
      'Cảm ơn bạn đã hoàn thành khảo sát này, thời gian của bạn rất có giá trị vì những khảo sát này giúp cải thiện dịch vụ của chúng tôi.',
    'Ref: Tabtitle': 'Hoàn thành khảo sát',
    'Take another survey': 'Lấy một cuộc khảo sát khác',
  },
  Survey: {
    'Ref: Page title': 'Chi tiết khảo sát',
    'Please answer all mandatory questions as identify by an asterisk':
      'Vui lòng trả lời tất cả các câu hỏi bắt buộc dưới dạng dấu hoa thị: *',
    'Ref: Take another survey': 'Lấy một cuộc khảo sát khác',
    'Ref: Go to Home': 'Đi về nhà',
  },
  SurveysList: {
    'Ref: Page title': 'Khảo sát',
    'Ref: Available until': 'Có sẵn cho đến khi',
    'Ref: You are currently viewing surveys for the site {site name}.':
      'Bạn hiện đang xem khảo sát cho trang web {site name}.',
  },
};
export default labels;
