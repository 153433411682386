import classNames from 'classnames';

import { replaceSizeWithViewBoxInSVG } from '../../../helpers/image';
import { serviceTypes } from '../../../modules/config';

import { ItemIconProps } from './SideBarWrapper.types';

const ItemIcon = ({ service, customIcons, className }: ItemIconProps) => {
  const itemIcon = customIcons[service.navigation.name];

  if (itemIcon) {
    if (typeof itemIcon === 'string' && itemIcon.includes('data:image')) {
      return <img src={itemIcon} alt={service.navigation.name} />;
    }

    return (
      <div
        className={classNames(className)}
        dangerouslySetInnerHTML={{ __html: replaceSizeWithViewBoxInSVG(itemIcon, 20) }}
      />
    );
  }

  if (serviceTypes[service.name].icon) {
    return serviceTypes[service.name].icon || <></>;
  } else {
    return <></>;
  }
};

export default ItemIcon;
