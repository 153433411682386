import { render } from '@testing-library/react';

import {
  buildConfig,
  buildFilteringState,
  getProductIllustration,
  mapToListModel,
} from '../productList.helper';

import { defaultFacilityMenu, defaultMenuItem } from '@/modules/Order/__mocks/mock';
import { Menu, MenuItem } from '@/modules/Order/api/menuApi.types';

const menuId = '1';
const menuItemOne: MenuItem = {
  ...defaultMenuItem,
  mealName: 'Any',
};
const menuItemTwo: MenuItem = { ...defaultMenuItem, mealName: 'Other' };
const menuItemNotOrderable: MenuItem = {
  ...defaultMenuItem,
  mealName: 'Other',
};

const menu: Menu = {
  ...defaultFacilityMenu,
  id: menuId,
  menuItems: [menuItemOne, menuItemTwo, menuItemNotOrderable],
};

const defaultFilters = {
  filter_allergens: { Milk: true, Eggs: true },
  filter_dishes: { vegan: true },
  filter_calories: { '466': true },
  filter_allergens_highlight: { hide: true },
  filter_favorites: { favorites: false },
  filter_moment: { Any: true },
  filter_category: { Some: true },
};

describe('mapToListModel', () => {
  it('map first item from selected moment and skip the other one', () => {
    const result = mapToListModel({
      menu: menu,
      selectedMoment: 'Any',
    });
    expect(result[0].description).toEqual(menuItemOne.description);
    expect(result.length).toEqual(1);
  });

  it('should map items when no selected moment provided', () => {
    const result = mapToListModel({
      menu: menu,
      selectedMoment: undefined,
    });
    expect(result.length).toEqual(3);
  });
});

describe('buildConfig', () => {
  it('should return filtering data (numOfSections = 0)', () => {
    const result = buildConfig(0);
    expect(result).toEqual({ displayedItemsNum: 0 });
  });

  it('should return filtering data (numOfSections = 4)', () => {
    const result = buildConfig(7);
    expect(result).toEqual({
      collapsibleSections: false,
      disableInfiniteScroll: true,
      displayedItemsNum: 0,
      singleExpandedSection: true,
    });
  });
});

describe('buildFilteringState', () => {
  it('should return filtering data', () => {
    const result = buildFilteringState({
      moments: ['Any', 'Other'],
      defaultFilters: undefined,
    });

    expect(result.filter_category).toEqual(undefined);
    expect(result.filter_date).toEqual(undefined);
    expect(result.filter_menu).toEqual(undefined);
    expect(result.filter_moment).toEqual({ Any: true });
  });

  it('should return filters when defaultFilters provided', () => {
    const result = buildFilteringState({
      moments: ['Any', 'Other'],
      defaultFilters: defaultFilters,
    });
    expect(result.filter_category).toEqual({ Some: true });
    expect(result.filter_date).toEqual(undefined);
    expect(result.filter_menu).toEqual(undefined);
    expect(result.filter_moment).toEqual({ Any: true });
  });
});

describe('getProductIllustration for all categories', () => {
  const categories = [
    'Burger',
    'Pizza',
    'Kebab',
    'Pasta',
    'Noodle',
    'Soup',
    'Drink',
    'Vegetable',
    'Salad',
    'Snacks',
    'Chicken',
    'Breakfast',
    'Fries',
    'Wrap',
    'Bowl',
    'Chocolate',
    'Quesadilla',
    'Caramel',
    'Turkey',
    'Burrito',
    'Tea',
    'Dish',
    'Coke',
    'Other',
  ];

  categories.forEach((category) => {
    it(`should return the correct illustration for ${category}`, () => {
      const { getByText } = render(getProductIllustration(category));
      expect(getByText(`${category}.svg`)).toBeInTheDocument();
    });
  });
});
