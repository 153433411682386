import { cleanup, fireEvent, screen } from '@testing-library/react';
import { createMemoryHistory } from 'history';

import { TileProps, TILE_VARIANT } from '../../../../components/molecules/Tile';
import renderComponent from '../../../../helpers/tests/renderComponent';
import { defaultSite } from '../../../Sites/__mocks/mocks';
import { defaultFacilityMenu } from '../../__mocks/mock';
import { ProductTileProps } from '../../types/productList.types';

import ProductTile from './ProductTile';
let tilePropsMock: TileProps;
jest.mock('../../../../components/molecules/Tile', () => {
  const actual = jest.requireActual('../../../../components/molecules/Tile');
  return {
    ...actual,
    Tile: (props: any) => {
      tilePropsMock = props;
      return <actual.Tile {...props} />;
    },
  };
});

jest.mock('../../helpers//order.helper', () => ({
  ...jest.requireActual('../../helpers//order.helper'),
  getPrice: (price: any) => price.toString(),
}));

const languageCode = 'pl-PL';
jest.mock('@/modules/Core/hooks/useLanguage', () => ({
  __esModule: true,
  default: () => ({
    currentLanguageCode: languageCode,
  }),
}));

describe('ProductTile component', () => {
  afterAll(() => cleanup());
  const menuItemIdInCart = '123';

  const props: ProductTileProps = {
    id: '123',
    menuItemId: menuItemIdInCart,
    title: 'title',
    description: 'description',
    price: 10,
    date: '2022-11-29',
    menu: defaultFacilityMenu,
    site: defaultSite,
    dark: true,
    variant: TILE_VARIANT.BIG,
    oneLine: true,
    genericCategory: 'Fruit',
  };

  describe('on render', () => {
    renderComponent(ProductTile, {
      ...props,
      showAllergensWarning: true,
      isVegan: true,
      isVegetarian: true,
    });
    it('should have basic props', () => {
      expect(tilePropsMock.id).toBe(props.id);
      expect(tilePropsMock.title).toBe(props.title);
      expect(tilePropsMock.description).toBe(props.description);
      expect(tilePropsMock.dark).toBe(true);
      expect(tilePropsMock.variant).toBe(TILE_VARIANT.BIG);
      expect(tilePropsMock.oneLineTile).toBe(true);
      expect(tilePropsMock.oneLineDescription).toBe(true);
      const price = screen.getByText('10');
      expect(price).toBeTruthy();
    });
  });

  describe('onClick redirect url', () => {
    const history = createMemoryHistory();
    renderComponent(
      ProductTile,
      {
        ...props,
      },
      undefined,
      history
    );
    it('should have correct url', () => {
      fireEvent.click(screen.getByTestId(`ProductTile-${props.id}`));
      expect(history.location.pathname).toContain(`${props.date}/${props.menuItemId}`);
    });
  });
});
