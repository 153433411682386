const labels = {
  All: {
    'Ref: AccommodationRequestTitle': 'בקשת לינה',
    'Ref: Describe your request': 'תאר את בקשתך',
    'Ref: Confirm your request': 'אשר את בקשתך',
    'Ref: Your request': 'בקשתך',
    'Ref: Summary': 'סיכום',
    'Ref: Next': 'הַבָּא',
    'Ref: Submit': 'שלח',
    'Ref: Me': 'לִי',
    'Ref: Arrival date': 'תאריך הגעה',
    'Ref: Arrival time': 'זמן הגעה',
    'Ref: Departure date': 'תאריך עזיבה',
    'Ref: Departure time': 'זמן יציאה',
    'Ref: request raised for': 'האדם שנפגע מבקשה זו',
    'Ref: phoneNumber': 'מספר טלפון',
    'Ref: arrivalDate': 'הַגָעָה',
    'Ref: departureDate': 'יְצִיאָה',
    'Ref: description': 'תגובה',
    'Ref: open': 'לִפְתוֹחַ',
    'Ref: pending': 'ממתין ל',
    'Ref: refused': 'סירב',
    'Ref: closed': 'סָגוּר',
    'Ref: all': 'את כל',
    'Ref: Arrival': 'הַגָעָה',
    'Ref: Validate': 'לְאַמֵת',
    'Ref: site': 'מיקום האתר',
    'Ref: Update us': 'עדכן אותנו',
    'Ref: email of guest': 'אימייל של אורח',
    'Ref: GuestAccommodationRequestTitle': 'בקשת אורח',
  },
  AccommodationRequest: {
    'Ref: NewAccommodationRequest': 'בקשה חדשה',
    'Ref: Status': 'סטָטוּס',
    'Ref: Date': 'תַאֲרִיך',
    'Ref: Coming arrivals': 'כניסות מגיעות',
    'Ref: Past arrivals': 'כניסות עבר',
  },
  CreateForm: {
    'Ref: Guest phone number': 'מספר טלפון של אורח',
    'Ref: Your phone number': 'מספר הטלפון שלך',
    'Ref: The phone number': 'מספר הטלפון',
    'Ref: PhoneNumber error msg': 'מספר הטלפון אינו תקף',
    'Ref: Comment error msg': 'המספר המרבי של התווים הוא 250',
    'Ref: Guest first name': 'שם פרטי אורח',
    'Ref: Guest last name': 'שם משפחה של אורח',
    'Ref: request for': 'למי זו הבקשה הזו?',
    'Ref: request for me': 'לִי',
    'Ref: request for guest': 'מישהו אחר',
  },
  Details: {
    'Ref: AccommodationRequestDetails': 'פרטי בקשת לינה',
    'Ref: Timeline': 'ציר זמן',
    'Ref: No updates placeholder': 'אין עדכונים לבקשה זו',
  },
  CreateThankYou: {
    'Ref: Thank you': 'תודה',
    'Ref: Success text': 'קיבלנו את בקשתך ובוחנים אותה עכשיו. אנחנו נחזור אליך בקרוב.',
    'Ref: Go to home': 'לך הביתה',
    'Ref: Create another request': 'צור בקשה נוספת',
  },
  CreateError: {
    'Ref: Create error title': 'אופס!',
    'Ref: Create error text': 'אירעה שגיאה. אנא נסו שוב.',
    'Ref: Retry': 'נסה שוב',
  },
  UpdateUs: {
    'Ref: Update us msg':
      'אנא עדכן אותנו בכל אלמנט חדש לגבי בקשתך כדי שנוכל לתמוך בך בצורה הטובה ביותר.',
  },
};
export default labels;
