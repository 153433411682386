const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Audits',
    'Ref: status0': 'Open',
    'Ref: status1': 'Completed',
    'Ref: status2': 'Cancelled',
    'Ref: connected': 'Connected',
    'Ref: not connected': 'Not connected',
    'Ref: synchronise data': 'Synchronise data',
    'Ref: sync success': 'Data synchronised successfully',
    'Ref: Create new': 'Create New',
    'Ref: Audit unsynchronised': 'Unsynchronised',
    'Ref: Audit synchronised': 'Synchronised',
    'Ref: Unsynced error title': 'An error has occurred',
    'Ref: Unsynced error description':
      'It appears to be your first time accessing the audits module. Please connect to network and “synchronise” to begin auditing.',
    'Ref: Search all audits': 'All audits',
    'Ref: Search active audits': 'Open',
    'Ref: Search completed audits': 'Completed',
    'Ref: sync error': 'Synchronization failed',
    'Ref: sync error details': 'Retry to reconnect',
    'Ref: no search audit results':
      'Unfortunately, there are no audits corresponding to your search',
    'Ref: no audit results': 'You have no available audits, create new to get started',
    'Ref: Search canceled audits': 'Cancelled',
    'Ref: Audit cancelled successfully': 'Audit cancelled successfully',
    'Ref: Cancel audit invite': 'Cancel',
    'Ref: Error audit cancel': 'Audit failed to be cancelled',
    'Ref: Cancel audit confirmation': 'Are you sure you want to cancel the audit?',
    'Ref: Confirm': 'Confirm',
    'Ref: Cancel audit': 'Cancel Audit',
    'Ref: Change the location': 'Change the location',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Audit Details',
    'Ref: questions': 'questions',
    'Ref: question': 'question',
    'Ref: Additional Comments': 'Description',
    'Ref: Not found title': 'Oops!',
    'Ref: Not found text': 'The audit you are trying to access doesn’t exist.',
    'Ref: Back to Audit list': 'Back to the Audit list',
    'Ref: Success title': 'Thank you!',
    'Ref: Success text': 'You have completed the audit',
    'Ref: submit': 'Submit',
    'Ref: add attachment': 'Add an attachment',
    'Ref: Unsupported elements':
      "Sorry, it seems this Audit has content that the application does not support yet. We'll work on it.",
    'Ref: form error': 'Not all questions have been fully answered',
    'Ref: form complete': 'Completed since',
    'Ref: attachment preview error': 'You need to be online to see this attachment',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Create New',
    'Ref: Location': 'Location',
    'Ref: Audit Stream Type': 'Stream',
    'Ref: Audit': 'Audit',
    'Ref: Asset': 'Asset',
    'Ref: Asset Name': 'Asset Name',
    'Ref: Is joint audit?': 'Is joint audit?',
    'Ref: Who was present?': 'Who was present?',
    'Ref: submit': 'Submit',
    'Ref: Select the Location': 'Select the Location',
    'Ref: Select the Audit Stream Type': 'Select the Audit Stream Type',
    'Ref: Select the Audit': 'Select the Audit',
    'Ref: Select the Asset': 'Select the Asset',
    'Ref: form error': 'Not all required fields have been fully entered',
    'Ref: Audit error': 'Attached Audit',
    'Ref: Joint Audit Participants error': 'Joint Audit Participants',
    'Ref: Audit created': 'Audit created',
    'Ref: Audit field length error': 'Length should not exceed 100 characters',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Change the location',
    'Ref: Location changed': 'Location changed',
    'Ref: Location': 'Location',
    'Ref: Select the Location': 'Select the Location',
    'Ref: Asset': 'Asset',
    'Ref: Select the Asset': 'Select the Asset',
    'Ref: Asset Name': 'Asset Name',
    'Ref: submit': 'Submit',
    'Ref: Audit field length error': 'Length should not exceed 100 characters',
  },
};
export default labels;
