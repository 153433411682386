const labels = {
  All: {
    'starts at': 'bắt đầu tại',
    'ends at': 'kết thúc tại',
    location: 'Vị trí',
    'coming date': 'Đến ngày',
    'Ref: Currently event not preferred. Click to make preferred':
      'Hiện tại sự kiện không được ưa thích. Bấm để thực hiện ưu tiên',
    'Ref: Currently event preferred. Click to make not preferred':
      'Hiện đang được ưa thích sự kiện. Bấm để không ưu tiên',
  },
  EventDetails: {
    details: 'Thông tin chi tiết',
    'Ref: Page title': 'chi tiết sự kiện',
    'Event dates': 'Ngày diễn ra sự kiện',
    'See online:': 'Xem trực tuyến:',
    'See all dates': 'Xem tất cả các ngày.',
    'Remove from my selection': 'Xóa khỏi lựa chọn của tôi',
    'Add to my selection': 'Thêm vào ưa thích của tôi',
    'Ref: explain calendar usage':
      'Ngày sự kiện được tô sáng trong lịch bên dưới. Đừng bỏ lỡ các sự kiện bạn thích! Nhấp vào một ngày sự kiện trong lịch để tải lời mời. Sau đó mở tệp đã tải xuống để thêm sự kiện vào lịch được ưu tiên của bạn.',
    'Ref: Favorite': 'Yêu thích',
    'Ref:Feedback': 'Nhận xét',
    'Ref: Event site different title': 'Sự kiện này không thuộc về trang web hiện tại của bạn',
    'Ref: Event site different description':
      'Bạn đang xem một sự kiện không thuộc về trang web hiện tại của bạn. Bạn sẽ không thể nhìn thấy nó trong danh sách các sự kiện.',
    'Ref: See all dates': 'Xem tất cả các ngày',
    'Ref: From': 'Từ',
    'Ref: to': 'ĐẾN',
    'Ref: Coming date': 'Ngày sắp đến',
    'Ref: Add to calendar': 'Thêm vào lịch',
  },
  EventsList: {
    'Ref: Page title': 'Sự kiện',
    'All dates': 'Tất cả các ngày',
    'All events': 'Tất cả các sự kiện',
    'Ref: Search': 'Tìm kiếm',
    'My selection': 'Ưa thích của tôi',
    'filter label: date': 'Ngày',
    'filter label: coming events': 'Sự kiện sắp tới',
    'filter label: past events': 'Những sự kiện đã qua',
    'filter label: interest': 'sự ưa thích',
  },
  Widget: {
    'Ref: Carousel title': 'Sự kiện',
    'No info yet, come back later': 'Chưa có thông tin nào, hãy quay lại sau 😉',
  },
};
export default labels;
