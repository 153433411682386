const labels = {
  All: {
    Region: 'Region',
    'Ref: Geography': 'Ref: Geography',
    'Ref: Country': 'Ref: Country',
    'Ref: Country tooltip': 'Ref: Country tooltip',
    'Ref: Please select your country': 'Ref: Please select your country',
    'First name': 'First name',
    'Last name': 'Last name',
    'Ref: Full name': 'Ref: Full name',
    'Mobile number': 'Mobile number',
    'Mobile phone': 'Mobile phone',
    Email: 'Email',
    Password: 'Password',
    logout: 'logout',
    'Not a new user? Log in': 'Not a new user? Log in',
    'do not register, login': 'do not register, login',
    'Ref: I agree': 'Ref: I agree',
    Register: 'Register',
    'Ref: My language': 'Ref: My language',
    'Ref: Kiosk Fail Title': 'Ref: Kiosk Fail Title',
    'Ref: Kiosk Fail Description': 'Ref: Kiosk Fail Description',
    'Ref: Kiosk Fail Back': 'Ref: Fail Order Back',
    'Ref: Kiosk Fail Action': 'Ref: Kiosk Fail Action',
    Validate: 'Validate',
    'Ref: error, incorrect credentials': 'Ref: error, incorrect credentials',
    'Ref: Forgot password?': 'Ref: Forgot password?',
    'Ref: Hello': 'Ref: Hello',
    'Ref: Form title': 'Ref: Form title',
    'Already have an account? Login': 'Already have an account? Login',
  },
  AccountPage: {
    'Ref: My Information': 'Ref: My Information',
    'Ref: Settings': 'Ref: Settings',
    'Ref: Email': 'Ref: Email',
    'Ref: Current site': 'Ref: Current site',
    'Ref: Choose site': 'Ref: Choose site',
    'Ref: Personal interests': 'Ref: Personal interests',
    'Ref: Food preferences': 'Ref: Food preferences',
    'Ref: Password': 'Ref: Password',
    'Ref: Page title': 'Ref: Page title',
    'Ref: Language': 'Ref: Language',
    'Ref: Communication preferences': 'Ref: Communication preferences',
    'Ref: Terms & conditions': 'Ref: Terms & conditions',
    'Ref: Privacy policy': 'Ref: Privacy policy',
    'Ref: Delete my account': 'Ref: Delete my account',
    'Ref: Confirm deletion': 'Ref: Confirm deletion',
    'Ref: Request deletion': 'Ref: Request deletion',
    'Ref: Deletion description': 'Ref: Deletion description',
    Placeholder: 'Placeholder',
    'Ref: Delete description': 'Ref: Delete description',
    delete: 'delete',
    'Ref: Terms of sale': 'Ref: Terms of sale',
    'Ref: Imprint': 'Ref: Imprint',
    'Ref: Cookie Settings': 'Ref: Cookie Settings',
    'Ref: Support': 'Ref: Support',
    'Ref: Register or login': 'Ref: Register or login',
    'Ref: Filtering Preferences': 'Ref: Filtering Preferences',
  },
  AccountDelete: {
    'Ref: Are you sure to delete your account?': 'Ref: Are you sure to delete your account?',
    'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.':
      'Ref: Write "{word}" in input field and confirm with the bottom below. This action is irreversible.',
    'Ref: Write "{word}"': 'Ref: Write "{word}"',
    'Ref: Delete my account': 'Ref: Delete my account',
    'Ref: Words are not matching': 'Ref: Words are not matching',
  },
  AccountRegistrationStart: {
    'Ref: Choose location label': 'Ref: Choose location label',
    'Ref: Choose location': 'Ref: Choose location',
    'Ref: next step': 'Ref: next step',
  },
  AccountRegistrationScanner: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: identify your location': 'Ref: identify your location',
    'Ref: option 1 - scan QR': 'Ref: option 1 - scan QR',
    'Ref: option 2 - enter code': 'Ref: option 2 - enter code',
    'Ref: button to open qr code scanner modal': 'Ref: button to open qr code scanner modal',
    'Ref: button to open site code input modal': 'Ref: button to open site code input modal',
    'Ref: getting help with site identifier - support desk':
      'Ref: getting help with site identifier - support desk',
    'Ref: getting help with site identifier - support form':
      'Ref: getting help with site identifier - support form',
    'Location code': 'Location code',
    'Ref: location code field placeholder': 'Ref: location code field placeholder',
    'Ref: Scanner header': 'Ref: Scanner header',
    'Ref: site code input header': 'Ref: site code input header',
    'Ref: not valid code': 'Ref: not valid code',
  },
  AccountRegistrationForm: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: collected info': 'Ref: collected info',
    'Ref: additional info': 'Ref: additional info',
    'Confirm password': 'Confirm password',
    company: 'company',
    location: 'location',
    username: 'username',
    register: 'register',
    'Ref: password strength': 'Ref: password strength',
    'passwords do not match': 'passwords do not match',
  },
  CommunicationPreferencesPage: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Things happen while you are away': 'Ref: Things happen while you are away',
    'Ref: Would you like to receive update about offers and promotions?':
      'Ref: Would you like to receive update about offers and promotions?',
    'Ref: Would you like to hear about content we think you may like?':
      'Ref: Would you like to hear about content we think you may like?',
    Yes: 'Yes',
    No: 'No',
    apply: 'apply',
  },
  ConfirmAccountDetailsPage: { 'Ref: Page title': 'Ref: Page title' },
  DeletionFailure: { 'Ref: Body': 'Ref: Body', 'Go to account': 'Go to account' },
  GenericFailurePage: { 'Ref: Title': 'Ref: Title', 'Ref: Body': 'Ref: Body' },
  HomePage: {
    notifications: 'notifications',
    basket: 'basket',
    'Ref: Tabtitle': 'Ref: Tabtitle',
    'contact us': 'contact us',
    'Ref: You are logged in as a guest user': 'Ref: You are logged in as a guest user',
    'Ref: Good morning': 'Ref: Good morning',
    'Ref: Good afternoon': 'Ref: Good afternoon',
    'Ref: Good evening before midnight': 'Ref: Good evening before midnight',
    'Ref: Good evening after midnight': 'Ref: Good evening after midnight',
    'Ref: Search': 'Ref: Search',
    'Ref: products button aria': 'Ref: products button aria',
    'Ref: menus button aria': 'Ref: menus button aria',
    'Ref: facilities button aria': 'Ref: facilities button aria',
    'Ref: result': 'Ref: result',
    'Ref: results': 'Ref: results',
    'Ref: Menus': 'Ref: Menus',
    'Ref: No results title': 'Ref: No results title',
    'Ref: No results body': 'Ref: No results body',
    'Ref: Products': 'Ref: Products',
    'Ref: Facilities': 'Ref: Facilities',
    'Ref: See more results': 'Ref: See more results',
    'Ref: Accessibility instructions': 'Ref: Accessibility instructions',
    'Ref: See more': 'Ref: See more',
    'Ref: singular form of products': 'Ref: singular form of products',
    'Ref: singular form of menus': 'Ref: singular form of menus',
    'Ref: singular form of facilities': 'Ref: singular form of facilities',
    'Ref: Search results for': 'Ref: Search results for',
  },
  LanguagePage: {
    apply: 'apply',
    'My language': 'My language',
    'Select in which language you would like to read our app':
      'Select in which language you would like to read our app',
    'Ref: Page title': 'Ref: Page title',
  },
  LegalDocPage: {
    Acknowledge: 'Acknowledge',
    'Ref: Legal changed notification title': 'Ref: Legal changed notification title',
    'Ref: Legal changed notification content': 'Ref: Legal changed notification content',
  },
  LegalDocsPage: {
    'Ref: Page title': 'Ref: Page title',
    'Read privacy policy': 'Read privacy policy',
    'Ref: Acknowledge legal docs': 'Ref: Acknowledge legal docs',
    'Ref: Read and understood': 'Ref: Read and understood',
    'Ref: No legal docs available - notice title': 'Ref: No legal docs available - notice title',
    'Ref: No legal docs available - notice body': 'Ref: No legal docs available - notice body',
    'Ref: The privacy Policy': 'Ref: The privacy Policy',
    'Ref: Legal changed notification title': 'Ref: Legal changed notification title',
    'Ref: Legal changed notification content': 'Ref: Legal changed notification content',
  },
  LoginPage: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Page subtitle': 'Ref: Page subtitle',
    'Ref: Email field placeholder': 'Ref: Email field placeholder',
    'Ref: Email field note': 'Ref: Email field note',
    'Ref: Password field title': 'Ref: Password field title',
    register: 'register',
    'I forgot my password.': 'I forgot my password.',
    'I have trouble logging in': 'I have trouble logging in',
    'start registration': 'start registration',
    'Ref: Register': 'Ref: Register',
    'Ref: Login': 'Ref: Login',
    'Ref: Connection issue?': 'Ref: Connection issue?',
    'Ref: Connection issue message': 'Ref: Connection issue message',
  },
  WelcomePage: { 'Ref: Welcome to': 'Ref: Welcome to', 'Ref: Begin': 'Ref: Begin' },
  NotificationsList: {
    'Ref: No notifications': 'Ref: No notifications',
    'Ref: This week': 'Ref: This week',
    'Ref: Earlier': 'Ref: Earlier',
    'Ref: Page title': 'Ref: Page title',
    'Ref: See more': 'Ref: See more',
    'Ref: Mark all read': 'Ref: Mark all read',
  },
  NotificationsWidget: { 'Ref: See all notifications': 'Ref: See all notifications' },
  ServicesMenuPage: { 'Ref: Page title': 'Ref: Page title', navigate: 'navigate' },
  DeletionSuccess: {
    'Ref: Title': 'Ref: Title',
    'Ref: Content': 'Ref: Content',
    'Log out': 'Log out',
  },
  TroubleLoggingInFormSuccessPage: { 'Ref: Title': 'Ref: Title', 'Ref: Body': 'Ref: Body' },
  TroubleLoggingInPage: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Email field placeholder': 'Ref: Email field placeholder',
    'Ref: Mobile phone field placeholder': 'Ref: Mobile phone field placeholder',
    Company: 'Company',
    'Ref: Company field placeholder': 'Ref: Company field placeholder',
    'Ref: Company field note': 'Ref: Company field note',
    Location: 'Location',
    'Ref: Location field placeholder': 'Ref: Location field placeholder',
    'Ref: Location field note': 'Ref: Location field note',
    Comment: 'Comment',
    'Ref: Comment field placeholder': 'Ref: Comment field placeholder',
    'Ref: Who is your employer?': 'Ref: Who is your employer?',
    'Ref: What village are you staying at?': 'Ref: What village are you staying at?',
    'Ref: Comment field note': 'Ref: Comment field note',
    'Ref: Feedback Content - geography': 'Ref: Feedback Content - geography',
    'Ref: Feedback Content - fullName': 'Ref: Feedback Content - fullName',
    'Ref: Feedback Content - email': 'Ref: Feedback Content - email',
    'Ref: Feedback Content - phone': 'Ref: Feedback Content - phone',
    'Ref: Feedback Content - company': 'Ref: Feedback Content - company',
    'Ref: Feedback Content - city': 'Ref: Feedback Content - city',
    'Ref: Feedback Content - whatVillage': 'Ref: Feedback Content - whatVillage',
    'Ref: Feedback Content - employer': 'Ref: Feedback Content - employer',
    'Ref: Feedback Content - comment': 'Ref: Feedback Content - comment',
  },
  AccountStartPage: {
    'Ref: Header': 'Ref: Header',
    'Ref: Body': 'Ref: Body',
    'Ref: LetsGo button': 'Ref: LetsGo button',
  },
  AccountOnboardingInformat: { 'Ref: Page title': 'Ref: Page title' },
  AccountOnboardingLocation: {
    'Ref: Select site with QR Scanner': 'Ref: Select site with QR Scanner',
    'Ref: Page title': 'Ref: Page title',
    'Ref: Select site with code': 'Ref: Select site with code',
    'Ref: Enter site code': 'Ref: Enter site code',
    'Ref: Scanner error header': 'Ref: Scanner error header',
    'Ref: Not found': 'Ref: Not found',
    'Ref: Generic error': 'Ref: Generic error',
    'All other sites': 'All other sites',
    'Sites in your country': 'Sites in your country',
    'Ref: Unauthorized error': 'Ref: Unauthorized error',
    'Ref: Forbidden': 'Ref: Forbidden',
    "Ref: We didn't find the site.": "Ref: We didn't find the site.",
    'Ref: We recommend some solutions:': 'Ref: We recommend some solutions:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below',
    'Ref: 2. Log out and log back in by selecting the correct country':
      'Ref: 2. Log out and log back in by selecting the correct country',
    'Ref: The currently selected country is:': 'Ref: The currently selected country is:',
  },
  OnboardingAccountInformation: {
    'Ref: Page title': 'Ref: Page title',
    'Confirm password': 'Confirm password',
    'Email is not valid': 'Email is not valid',
    'passwords do not match': 'passwords do not match',
    'Ref: Password strength': 'Ref: Password strength',
    'Sign up': 'Sign up',
    'Continue as guest': 'Continue as guest',
    'Already have an account? Login': 'Already have an account? Login',
    'Ref: Registration success title': 'Ref: Registration success title',
    'Ref: Registration success content': 'Ref: Registration success content',
  },
  GuestRegistrationForm: {
    'Ref: Page Headline': 'Ref: Page Headline',
    'Ref: Password strength': 'Ref: Password strength',
    Stamps: 'Stamps',
    'Order history': 'Order history',
    'to earn points': 'to earn points',
    'to see order history': 'to see order history',
    'to place an order': 'to place an order',
    'Sign up': 'Sign up',
    Login: 'Login',
    'Email is not valid': 'Email is not valid',
    'passwords do not match': 'passwords do not match',
  },
  GuestRegistrationSuccess: {
    'Ref: Header': 'Ref: Header',
    'Ref: Body': 'Ref: Body',
    'Ref: Enter': 'Ref: Enter',
  },
  GenericErrorPage: {
    'Ref: Generic Error Title': 'Ref: Generic Error Title',
    'Ref: Generic Error Body': 'Ref: Generic Error Body',
    'Ref: Missing IndexDb support': 'Ref: Missing IndexDb support',
    'Ref: Resource not found': 'Ref: Resource not found',
    'Ref: Permission denied - SITE_HIDDEN': 'Ref: Permission denied - SITE_HIDDEN',
    'Ref: Permission denied - CONTRACT_DIFFERENT': 'Ref: Permission denied - CONTRACT_DIFFERENT',
    'Ref: TabTitle': 'Ref: TabTitle',
    'Ref: Site link invalid': 'Ref: Site link invalid',
    'Ref: Access Denied': 'Ref: Access Denied',
  },
  DefaultErrorPage: {
    'Ref: Oops!': 'Ref: Oops!',
    'Ref: Error title': 'Ref: Error title',
    'Ref: Error body': 'Ref: Error body',
    'Ref: Error ID:': 'Ref: Error ID:',
    'Ref: Back to home': 'Ref: Back to home',
  },
  Child: { 'Ref: Route Not Found': 'Ref: Route Not Found' },
  AccountValidation: {
    'Ref: Password changed message': 'Ref: Password changed message',
    Welcome: 'Welcome',
    'Ref: Welcome message': 'Ref: Welcome message',
  },
  PasswordChange: {
    'Change Password': 'Change Password',
    Welcome: 'Welcome',
    'Ref: Password message': 'Ref: Password message',
  },
  AccountPasswordChange: {
    'Change your password here': 'Change your password here',
    'Your old password': 'Your old password',
    'Type in your new password': 'Type in your new password',
    'Re-type in your new password': 'Re-type in your new password',
  },
  AccountPasswordChangeSuccess: {
    'Your password has been changed': 'Your password has been changed',
  },
  AccountPasswordChangeFailure: { 'Ref: Error message': 'Ref: Error message' },
  PasswordForgotten: {
    'Ref: Page title': 'Ref: Page title',
    'Ref: Provide email': 'Ref: Provide email',
    'Ref: Something happened': 'Ref: Something happened',
  },
  ResetEmailSent: {
    'Ref: New email sent': 'Ref: New email sent',
    'Go to login page': 'Go to login page',
    'Resend email': 'Resend email',
    'Ref: Email sent title': 'Ref: Email sent title',
    'Ref: TabTitle': 'Ref: TabTitle',
    'Ref: Email sent msg': 'Ref: Email sent msg',
  },
  ResetPassword: { 'Enter your new password': 'Enter your new password' },
  ResetPasswordSuccess: {
    'Ref: Password changed': 'Ref: Password changed',
    'Go to login page': 'Go to login page',
  },
  ResetPasswordFailure: {
    'Password reset fail': 'Password reset fail',
    'Ref: Error': 'Ref: Error',
    'Reset your password': 'Reset your password',
    'Go to login page': 'Go to login page',
  },
  PrivacyPolicyIntroductionPage: {
    'Ref: Privacy Policy Title': 'Ref: Privacy Policy Title',
    'Ref: Privacy Policy Body': 'Ref: Privacy Policy Body',
    'Ref: Continue': 'Ref: Continue',
    'Ref: Read Privacy Policy': 'Ref: Read Privacy Policy',
  },
  AppUpdatePage: { 'Ref: GoToStore': 'Ref: GoToStore' },
  SharePage: { 'Ref: Select a site': 'Ref: Select a site' },
  PublicLegalDocPage: {
    'Ref: To view Privacy Policy please select your country.':
      'Ref: To view Privacy Policy please select your country.',
  },
};
export default labels;
