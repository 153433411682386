import { useSelector } from 'react-redux';

import AccountWidget from '../../../modules/Core/components/TitleBarWidgets/AccountWidget';
import NotificationsWidget from '../../../modules/Core/components/TitleBarWidgets/NotificationsWidget';
import { BAR_TYPES } from '../../organisms/BottomBar/BottomBar.types';
import TitleBar from '../../organisms/TitleBar';
import { TITLEBAR_CAPTION_TYPE } from '../../organisms/TitleBar/TitleBar.types';
import DefaultPage from '../DefaultPage/DefaultPage';

import { SimpleFormPageProps } from './SimpleFormPage.types';

import { State } from '@/types/state.types';

// TODO: SimpleFormPage will be replaced by DefaultPage when all pages are migrated to newLayout
const SimpleFormPage = ({
  hasBackLink = true,
  backButtonCustomPath,
  onBackButtonClick,
  title,
  actions,
  actionsBarTopContent,
  children,
  stylingOptions,
  bottomBar,
  centerVertically,
  hideAccountWidget,
  hideNotificationsWidget,
  hideAllWidgets,
  withTitleBar = true,
  tabTitle,
  ...rest
}: SimpleFormPageProps) => {
  const { shouldSelectSiteContext } = useSelector((state: State) => state.Core.access);

  return (
    <DefaultPage
      titleBar={
        withTitleBar && (
          <TitleBar>
            <TitleBar.Navigation
              caption={
                title !== undefined && title !== null
                  ? {
                      title,
                      hideBackButton: !hasBackLink,
                      captionType: TITLEBAR_CAPTION_TYPE.DISPLAY_PAGE_TITLE,
                      backButtonCustomPath,
                      onBeforeBack: onBackButtonClick,
                    }
                  : undefined
              }
              tabTitle={tabTitle}
            />
            {!hideAllWidgets && (
              <TitleBar.Widgets>
                <NotificationsWidget hide={shouldSelectSiteContext || hideNotificationsWidget} />
                <AccountWidget hide={hideAccountWidget} />
              </TitleBar.Widgets>
            )}
          </TitleBar>
        )
      }
      stylingOptions={stylingOptions}
      bottomBar={{
        type: BAR_TYPES.ACTIONS_BAR,
        actions,
        topContent: actionsBarTopContent,
      }}
      {...rest}
    >
      {children}
    </DefaultPage>
  );
};

export default SimpleFormPage;
