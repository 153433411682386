import classNames from 'classnames';
import { FC } from 'react';

import { NavArrowRightIcon } from '../../../../assets/icons';
import Link from '../../../atoms/Link';
import { WidgetLinkProps } from '../Widget.types';

import styles from './Link.module.css';

const WidgetLink: FC<WidgetLinkProps> = ({
  children,
  className,
  'data-testid': dataTestId = 'widget-link',
  ...rest
}) => {
  return (
    <Link {...rest} className={classNames(styles.link, className)} data-testid={dataTestId}>
      <span>{children}</span>
      <NavArrowRightIcon />
    </Link>
  );
};
WidgetLink.displayName = 'Widget.Link';

export default WidgetLink;
