import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';

import AccountWidget from './AccountWidget';

describe('AccountWidget component', () => {
  describe('Render with no props - hide prop is false', () => {
    afterAll(() => cleanup());

    renderComponent(AccountWidget, {});

    it('Should display widget', () => {
      const component = screen.getByTestId('title-bar-icon-Account');
      expect(component).toBeTruthy();
    });
  });

  describe('Render with props - hide prop is true', () => {
    afterAll(() => cleanup());

    renderComponent(AccountWidget, { hide: true });

    it('Should not display widget', () => {
      const component = screen.queryByTestId('title-bar-icon-Account');
      expect(component).toBeFalsy();
    });
  });
});
