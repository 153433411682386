const labels = {
  StaysList: {
    'Ref: Page title': 'Marad',
    'Ref: Past': 'Múlt',
    'Current and future stays': 'Jelenlegi és jövőbeli tartózkodás',
    'filter label: period': 'Időszak',
    'Ref: Search': 'Keresés',
  },
  StayDetails: {
    'Ref: Page title': 'Tartózkodásának részletei',
    'Arrival date': 'Érkezési dátum',
    'Departure date': 'Indulási időpont',
    'Room number': 'Szoba',
    'Reservation number': 'Foglalási szám',
    CheckIn: 'Becsekkolás',
    CheckOut: 'Kijelentkezik',
    CheckIn_1: 'Ellenőrizze a {lodgeName} -t, aki ma érkezik? (Foglalási szám {resNumber})',
    CheckedIn_1: 'A {lodgeName}, a {roomNumber} szobába kerül.',
    CheckOut_1:
      'Nézze meg {lodgeName} Foglalási szám {resNumber} most? A szobate kulcs ki van kapcsolva.',
    CheckedOut_1: '{lodgeName} Foglalási szám {resNumber} kijelentkezett.',
  },
  StaysSuccess: {
    'Ref: Title': 'Köszönöm a tartózkodást!',
    'Ref: Body': 'A pénztár sikeresen feldolgozott.',
    'Ref: Go to home': 'Menj haza',
    'Ref: Provide a feedback': 'Adjon visszajelzést',
  },
};
export default labels;
