import { cleanup, screen, waitFor, within } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMemoryHistory } from 'history';
import moment from 'moment';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import ProductsList from '../ProductsList';

import { Facility } from '@/modules/Facilities/types/types';
import { Menu } from '@/modules/Order/api/menuApi.types';
import { ISite } from '@/modules/Sites/types/sites.types';

jest.mock('../../../hooks/useScrollPosition', () => ({
  __esModule: true,
  default: () => ({
    contentRef: { current: null },
    saveScrollPosition: () => {},
  }),
}));

const mockHistory = createMemoryHistory();

const mockParams = jest.fn();
jest.mock('react-router', () => ({
  ...jest.requireActual('react-router'),
  useHistory: () => mockHistory,
  useParams: () => mockParams(),
}));

const siteId = '71a8dc31-1337-e811-a955-000d3a2bcac2';
jest.mock('@/modules/Core/hooks/useSite', () => ({
  __esModule: true,
  default: () =>
    ({
      id: siteId,
      currency: {
        isoCode: 'EUR',
      },
    } as ISite),
}));

const mockLogUserSteps = jest.fn();
jest.mock('@/helpers/hooks/useUserStepsInsightsLogging/useUserStepsInsightsLogging', () => ({
  __esModule: true,
  default: () => ({
    logUserSteps: mockLogUserSteps,
  }),
}));

const mockUseMenuVisits = jest.fn();
jest.mock('../../../hooks/useMenusVisits/useMenusVisits', () => ({
  __esModule: true,
  default: () => ({
    menusVisits: [{ menuId: menuId, visits: 3 }],
    increaseMenuVisits: mockUseMenuVisits,
  }),
}));

const menuId = '3329';
const facilityId = '0889e7eb-dfcc-eb11-ba5e-0003ff4c9eea';

export const facility: Facility = {
  title: 'EMEA AT Facility',
  email: 'foodretail@facility.com',
  phone: '123456789',
  name: 'EMEA AT Facility - Sanofi Pharmaceutical - Sanofi Ridgefield',
  description: 'FoodRetail FacilityDescription',
  id: facilityId,
  sequence: 3,
  access: '',
  longitude: '',
  latitude: '',
  images: ['f1514dbe-a545-ee11-be6e-6045bd8c50c5'],
  openingHours: [
    {
      closeTime: '',
      openTime: '',
      isOpenAllDay: true,
      day: ['Friday', 'Monday', 'Thursday', 'Tuesday', 'Wednesday'],
    },
  ],
  isFoodFacilityType: true,
  facilityType: {
    id: '555580001',
    name: 'Food - Retail',
  },
  menuNote: 'MenuTestNote',
  externalUrl: '',
  viewCounts: 0,
};

const menu: Menu = {
  id: menuId,
  facilityId: facilityId,
  name: 'Bite Everyday',
  date: new Date().toISOString().split('T')[0],
  menuItems: [
    {
      name: 'Bite apple',
      mealName: 'Breakfast',
      description: 'Bite apple description',
      priceWithTax: 4.8,
      menuItemId: '17201679',
      category: 'Fruits',
      genericCategory: 'Fruit',
    },
    {
      name: 'Bite wine',
      mealName: 'Dinner',
      description: 'Bite wine description',
      priceWithTax: 42,
      menuItemId: '17201688',
      category: 'Wines',
      genericCategory: 'Other',
    },
    {
      name: 'Bite burger',
      mealName: 'Dinner',
      description: 'Bite burger description',
      priceWithTax: 7.2,
      menuItemId: '17201688',
      category: 'Burger',
      genericCategory: 'Other',
    },
  ],
};

export let useFilteringData = {
  filterItems: [menu.menuItems],
  hasFilters: true,
  selectedNum: 0,
  searchString: '',
  filtering: {},
  handleSearchChange: () => jest.fn(),
  handleFilteringChange: () => jest.fn(),
};

const mockGetDefaultFiltersFromCache = jest.fn();
jest.mock('../productList.helper', () => ({
  ...jest.requireActual('../productList.helper'),
  GetDefaultFiltersFromCache: () => mockGetDefaultFiltersFromCache,
}));

const mockUseFiltering = jest.fn();
jest.mock('../../../../../components/templates/ListPage', () => ({
  ...jest.requireActual('../../../../../components/templates/ListPage'),
  useFiltering: () => mockUseFiltering(),
}));

jest.mock('@/modules/Core/hooks/useLanguage', () => ({
  __esModule: true,
  default: () => ({
    currentLanguageCode: 'en-US',
  }),
}));

export const today = moment().format('YYYY-MM-DD');

const mockMenus = jest.fn();
const mockMenusLoading = jest.fn();
jest.mock('@/modules/Order/api/menuApi', () => ({
  useGetMenusQuery: () => ({
    data: mockMenus(),
    isFetching: mockMenusLoading(),
  }),
}));

const mockFacilities = jest.fn();
const mockFacilitiesLoading = jest.fn();
jest.mock('@/modules/Facilities/api/api', () => ({
  useGetFacilitiesListQuery: () => ({
    data: { facilities: mockFacilities() },
    isFetching: mockFacilitiesLoading(),
  }),
}));

describe('ProductsList component', () => {
  afterAll(async () => cleanup());
  describe('with all available data in state ', () => {
    beforeAll(() => {
      // Object.defineProperty to bypass the read-only restriction
      Object.defineProperty(global.navigator, 'clipboard', {
        value: {
          writeText: jest.fn(),
        },
        writable: true,
      });
    });

    beforeEach(async () => {
      mockParams.mockReturnValue({
        facilityId: facilityId,
        date: today,
        menuId: menuId,
      });

      mockFacilities.mockReturnValue([facility]);
      mockFacilitiesLoading.mockReturnValue(false);

      mockMenus.mockReturnValue([menu]);
      mockMenusLoading.mockReturnValue(false);

      mockUseFiltering.mockReturnValue(useFilteringData);
    });

    describe('products list common functionalities', () => {
      renderComponent(ProductsList, {}, {});

      it('should correctly display main components of the page', () => {
        expect(screen.queryAllByText('EMEA AT Facility')).toBeTruthy();
        expect(screen.getByText('Today')).toBeTruthy();
        expect(screen.queryAllByText('products-list-search-bar')).toBeTruthy();
        expect(screen.queryAllByTestId('titlebar-navigation-back-btn')).toBeTruthy();
      });

      it('should display available items from selectedMenu and Filter element', () => {
        expect(screen.queryAllByText('Bite apple')).toBeTruthy();
        expect(screen.queryAllByText('Bite wine')).toBeTruthy();
      });

      it('should display available moments texts from menu', () => {
        expect(screen.queryAllByText('Fruits')).toBeTruthy();
        expect(screen.queryAllByText('Wines')).toBeTruthy();
      });

      it('should log user step', () => {
        expect(mockLogUserSteps).toHaveBeenCalled();
      });

      it('should call menuVisits', () => {
        expect(mockUseMenuVisits).toHaveBeenCalled();
      });

      it('should properly open the Facility Detail modal', async () => {
        const button = screen.getByTestId('product-list-open-facility-details');
        await userEvent.click(button);

        await waitFor(() => {
          expect(screen.getByText('Monday, Tuesday, Wednesday, Thursday, Friday')).toBeTruthy();
          expect(screen.getByText('Open all day')).toBeTruthy();
          expect(screen.getByText('123456789')).toBeTruthy();
          expect(screen.getByText('foodretail@facility.com')).toBeTruthy();
          expect(screen.getByText('FoodRetail FacilityDescription')).toBeTruthy();
        });

        const closeButton = screen.getByTestId('facility-details-modal-button-close');
        await userEvent.click(closeButton);
      });
    });

    describe('is loading page displayed', () => {
      describe('loading menus', () => {
        beforeEach(async () => {
          mockMenus.mockReturnValue(undefined);
          mockMenusLoading.mockReturnValue(true);
        });

        renderComponent(ProductsList);
        it('should display loading page when menus is loading ', () => {
          expect(screen.getByTestId('loading-page-products-list')).toBeTruthy();
        });
      });

      describe('loading facilities', () => {
        beforeEach(async () => {
          mockFacilities.mockReturnValue(undefined);
          mockFacilitiesLoading.mockReturnValue(true);
        });

        renderComponent(ProductsList);
        it('should display loading page when menus is loading ', () => {
          expect(screen.getByTestId('loading-page-products-list')).toBeTruthy();
        });
      });
    });

    describe('when selected date changes', () => {
      describe('when selected day is tomorrow', () => {
        beforeEach(async () => {
          mockParams.mockReturnValue({
            facilityId: facilityId,
            date: moment().add(1, 'days').format('YYYY-MM-DD'),
            menuId: menuId,
            barcode: undefined,
          });
        });

        renderComponent(ProductsList, {});

        it('should display text Tomorrow', () => {
          const moment = screen.getByTestId('page-header-moment');
          expect(within(moment).getByText('Tomorrow')).toBeTruthy();
        });
      });

      describe('when selected day 5 days ahead', () => {
        beforeEach(async () => {
          mockParams.mockReturnValue({
            facilityId: facilityId,
            date: moment().add(5, 'days').format('YYYY-MM-DD'),
            menuId: menuId,
            barcode: undefined,
          });
        });
        const customDate = moment().add(5, 'days').format('ddd, MM/DD/YYYY');
        renderComponent(ProductsList, {});

        it('should show Date in expected format', () => {
          const moment = screen.getByTestId('page-header-moment');
          expect(within(moment).getByText(customDate)).toBeTruthy();
        });
      });
    });

    describe('filters', () => {
      describe('when there are filters in cache', () => {
        const response = JSON.stringify({
          filter_moment: {},
          filter_category: {},
        });

        let getItemSpy: jest.SpyInstance<string | null, [string]>;

        beforeEach(() => {
          getItemSpy = jest
            .spyOn(Storage.prototype, 'getItem')
            .mockImplementation((key) =>
              key ===
              'Order_productList_filters_71a8dc31-1337-e811-a955-000d3a2bcac2_Bite Everyday_2024-02-13'
                ? JSON.stringify(response)
                : null
            );
        });

        afterEach(() => {
          jest.restoreAllMocks();
        });

        renderComponent(ProductsList, {});

        it('should display correct text for multiple filters on filterButton', () => {
          expect(getItemSpy).toHaveBeenCalledWith(
            `Order_productList_filters_71a8dc31-1337-e811-a955-000d3a2bcac2_Bite Everyday_${today}`
          );
          expect(response).toEqual(response);
        });
      });
    });
  });
});
