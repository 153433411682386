import { useEffect } from 'react';
import { useLocation } from 'react-router';

import useAnalytics from './useAnalytics';

export const useTrackPageView = ({
  isGlobal,
  facilityId,
  skip = false,
}: {
  isGlobal: boolean;
  facilityId?: string;
  skip?: boolean;
}) => {
  const location = useLocation();
  const { trackPageViewEvent, cancelScheduledTrackPageEvenIfPossible } = useAnalytics();

  useEffect(() => {
    if (!skip) {
      trackPageViewEvent(location.pathname, isGlobal, facilityId);
    }

    return () => {
      cancelScheduledTrackPageEvenIfPossible();
    };
  }, [
    isGlobal,
    facilityId,
    skip,
    trackPageViewEvent,
    cancelScheduledTrackPageEvenIfPossible,
    location.pathname,
  ]);
};
