import classNames from 'classnames';
import { FC } from 'react';

import { SIZE } from '../../../../constants';
import Button, { BUTTON_LOOK } from '../../Button';

import { TimePeriodEnum, TimePeriodProps } from './TimePeriod.types';

import styles from './TimePeriod.module.css';

const TimePeriod: FC<TimePeriodProps> = ({
  allowAmSelection,
  allowPmSelection,
  timePeriod,
  onTimePeriodChanged,
  'data-testid': dataTestId = 'time-period',
}) => {
  const isAm = timePeriod === 'AM';
  const isPm = timePeriod === 'PM';

  return (
    <div className={classNames(styles.timePeriod)} data-testid={dataTestId}>
      <Button
        disabled={!allowAmSelection}
        className={classNames(styles.itemMarginBottom)}
        data-testid="period-am"
        size={SIZE.SMALL}
        look={isAm ? BUTTON_LOOK.PRIMARY : BUTTON_LOOK.SECONDARY_INLINE}
        onClick={() => onTimePeriodChanged(TimePeriodEnum.AM)}
      >
        AM
      </Button>
      <Button
        disabled={!allowPmSelection}
        data-testid="period-pm"
        size={SIZE.SMALL}
        look={isPm ? BUTTON_LOOK.PRIMARY : BUTTON_LOOK.SECONDARY_INLINE}
        onClick={() => onTimePeriodChanged(TimePeriodEnum.PM)}
      >
        PM
      </Button>
    </div>
  );
};

export default TimePeriod;
