import { cleanup, screen } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';
import { IContentType } from '../../../types';
import ContentTile from '../ContentTile';
import { ContentTileProps } from '../ContentTile.types';

jest.mock('../../../api/api', () => ({
  useUpdateContentReactionMutation: () => [() => true],
  useDeleteContentReactionMutation: () => [() => true],
}));

const props: ContentTileProps = {
  label: jest.fn().mockReturnValue('some label'),
  item: {
    id: 'id',
    details: 'Details',
    type: IContentType.NORMAL,
    publishedDate: '2023-01-03',
    expiryDate: '2023-01-04',
    title: 'Some title',
    images: [],
    imgInfo: {
      src: '...',
      alt: 'Some title',
    },
    preferences: [],
    link: {
      url: '/foo/bar',
      text: 'Link text',
    },
    linkedEntity: '',
    linkedEntityId: '',
    linkedEntityUrl: '',
    languageCode: '',
    matchUserPreferenceCount: 0,
    score: '',
    reactions: {
      me: null,
      reactions: [],
      total: 0,
    },
  },
  triggerShareModal: jest.fn(),
};

describe('ContentTile', () => {
  afterAll(() => cleanup());

  describe('default render', () => {
    renderComponent(ContentTile, props);

    it('should render a Tile', () => {
      expect(screen.getByTestId('tile')).toBeTruthy();
    });

    it('should display title on Tile', () => {
      expect(screen.getByTestId('tile')).toHaveTextContent(props.item.title);
    });

    it('should display details on Tile', () => {
      expect(screen.getByTestId('tile')).toHaveTextContent(props.item.details);
    });

    it('should display image on Tile', () => {
      expect(screen.getByTestId('content-tile-image-with-fallback')).toBeTruthy();
    });
  });
});
