const mockResponse = {
  createFeedback: {
    ok: true,
    status: 202,
    json: () => Promise.resolve(),
  },
  getFeedbackTypes: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        feedbackTypes: [
          { id: '763990000', name: 'Feedback' },
          { id: '763990001', name: 'Compliment' },
          { id: '763990002', name: 'Complaint' },
        ],
      }),
  },
  getServiceTypes: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        serviceRequestTypes: [
          { id: '32083a54-5908-e611-80db-c4346bc5b718', name: 'Village Services' },
          { id: '34083a54-5908-e611-80db-c4346bc5b718', name: 'Food & Dining' },
          { id: '36083a54-5908-e611-80db-c4346bc5b718', name: 'Aerodrome' },
          { id: '38083a54-5908-e611-80db-c4346bc5b718', name: 'Village Common Area Cleaning' },
          { id: '3a083a54-5908-e611-80db-c4346bc5b718', name: 'Grounds & Gardens' },
          { id: '3c083a54-5908-e611-80db-c4346bc5b718', name: 'Property Management' },
          { id: '3e083a54-5908-e611-80db-c4346bc5b718', name: 'Town Services' },
          { id: '40083a54-5908-e611-80db-c4346bc5b718', name: 'Buses & Transport Services' },
          { id: '42083a54-5908-e611-80db-c4346bc5b718', name: 'Building Maintenance' },
        ],
      }),
  },
  getAreaTypes: {
    ok: true,
    status: 200,
    json: () =>
      Promise.resolve({
        areaTypes: [
          {
            serviceTypeId: '42083a54-5908-e611-80db-c4346bc5b718',
            id: '9d450a9d-4ac4-eb11-bacc-000d3ad14684',
            name: 'Electricity Consumption Enquiry',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '0dfc1855-a8a5-ec11-983f-00224811d35c',
            name: 'Community Engagement',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '1fbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Accommodation Site safety',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '21bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'ATM/EFTPOS Services',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '23bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Check In / Out Services',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '25bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'First Aid Response',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '27bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Internet/TV Services',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '29bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Manchester and laundering',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '2bbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Postal Services',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '2dbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Reception Services',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '2fbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Retail Services',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '31bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Room Cleaning',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '33bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Room features',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '35bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Sporting, recreation and lifestyle',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '37bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Tavern Services',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '3abcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Village Information',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '3cbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Waste management',
          },
          {
            serviceTypeId: '32083a54-5908-e611-80db-c4346bc5b718',
            id: '3ebcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Wildlife management',
          },
          {
            serviceTypeId: '36083a54-5908-e611-80db-c4346bc5b718',
            id: '40bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Check-in experience',
          },
          {
            serviceTypeId: '36083a54-5908-e611-80db-c4346bc5b718',
            id: '42bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Flight delay',
          },
          {
            serviceTypeId: '36083a54-5908-e611-80db-c4346bc5b718',
            id: '44bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Retail Services  ',
          },
          {
            serviceTypeId: '42083a54-5908-e611-80db-c4346bc5b718',
            id: '46bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Late / No attendance',
          },
          {
            serviceTypeId: '42083a54-5908-e611-80db-c4346bc5b718',
            id: '48bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Repeat breakdown',
          },
          {
            serviceTypeId: '42083a54-5908-e611-80db-c4346bc5b718',
            id: '4abcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Work Quality',
          },
          {
            serviceTypeId: '34083a54-5908-e611-80db-c4346bc5b718',
            id: '4cbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'After-hours',
          },
          {
            serviceTypeId: '34083a54-5908-e611-80db-c4346bc5b718',
            id: '4ebcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Breakfast',
          },
          {
            serviceTypeId: '34083a54-5908-e611-80db-c4346bc5b718',
            id: '50bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Crib',
          },
          {
            serviceTypeId: '34083a54-5908-e611-80db-c4346bc5b718',
            id: '52bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Dinner',
          },
          {
            serviceTypeId: '34083a54-5908-e611-80db-c4346bc5b718',
            id: '54bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Functions',
          },
          {
            serviceTypeId: '34083a54-5908-e611-80db-c4346bc5b718',
            id: '56bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Meal deliveries',
          },
          {
            serviceTypeId: '38083a54-5908-e611-80db-c4346bc5b718',
            id: '58bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Commercial Property',
          },
          {
            serviceTypeId: '38083a54-5908-e611-80db-c4346bc5b718',
            id: '5abcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Residential Property',
          },
          {
            serviceTypeId: '38083a54-5908-e611-80db-c4346bc5b718',
            id: '5cbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Town Assets',
          },
          {
            serviceTypeId: '38083a54-5908-e611-80db-c4346bc5b718',
            id: '5ebcf515-88c5-e811-819c-e0071b681aa1',
            name: 'External Areas',
          },
          {
            serviceTypeId: '38083a54-5908-e611-80db-c4346bc5b718',
            id: '60bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Recreation and Communal Areas',
          },
          {
            serviceTypeId: '38083a54-5908-e611-80db-c4346bc5b718',
            id: '62bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Wet Areas',
          },
          {
            serviceTypeId: '3a083a54-5908-e611-80db-c4346bc5b718',
            id: '64bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Footpaths and Driveways',
          },
          {
            serviceTypeId: '3a083a54-5908-e611-80db-c4346bc5b718',
            id: '66bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Garden areas',
          },
          {
            serviceTypeId: '3a083a54-5908-e611-80db-c4346bc5b718',
            id: '68bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'General',
          },
          {
            serviceTypeId: '3a083a54-5908-e611-80db-c4346bc5b718',
            id: '6abcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Grassed Areas',
          },
          {
            serviceTypeId: '3a083a54-5908-e611-80db-c4346bc5b718',
            id: '6cbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Outdoor Equipment',
          },
          {
            serviceTypeId: '3a083a54-5908-e611-80db-c4346bc5b718',
            id: '6ebcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Swimming pools',
          },
          {
            serviceTypeId: '3c083a54-5908-e611-80db-c4346bc5b718',
            id: '70bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Allocated Property',
          },
          {
            serviceTypeId: '3c083a54-5908-e611-80db-c4346bc5b718',
            id: '72bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Services',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '74bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Aquatic Facility',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '76bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Cafe and Takeaway Food Outlet',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '78bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Drive-in cinema',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '7abcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Fitness Centre',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '7cbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Liquor Store',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '7ebcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Lottery Outlet',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '80bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Other Retail Services',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '83bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Postal/Banking Agency',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '85bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Service Station',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '87bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Supermarket',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '89bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Tavern and Bistro',
          },
          {
            serviceTypeId: '3e083a54-5908-e611-80db-c4346bc5b718',
            id: '8bbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Waste management',
          },
          {
            serviceTypeId: '40083a54-5908-e611-80db-c4346bc5b718',
            id: '8dbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Vehicle Standards',
          },
          {
            serviceTypeId: '40083a54-5908-e611-80db-c4346bc5b718',
            id: '8fbcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Booking',
          },
          {
            serviceTypeId: '40083a54-5908-e611-80db-c4346bc5b718',
            id: '91bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Driver Standards',
          },
          {
            serviceTypeId: '40083a54-5908-e611-80db-c4346bc5b718',
            id: '93bcf515-88c5-e811-819c-e0071b681aa1',
            name: 'Service',
          },
        ],
      }),
  },
  getSubjectTypes: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
};

export default mockResponse;
