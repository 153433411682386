const labels = {
  All: {
    'Ref: ServiceRequestLightHelpdeskTitle': 'בקשת שירות - HelpDesk',
    'Ref: Forbidden heading': 'אסור',
  },
  List: {
    'Ref: You are administrating service requests on the site {siteName}.':
      'אתה מנהל בקשות שירות באתר {siteName}.',
    'Ref: Dates': 'תאריכים',
    'Ref: SRL list refresh': 'רשימת רענון',
    'Ref: Export': 'יְצוּא',
    'Ref: Empty/not filled': 'ריק/לא מלא',
    'Ref: Name': 'שֵׁם',
    'Ref: Date': 'תַאֲרִיך',
    'Ref: Category': 'קטגוריה',
    'Ref: User': 'מִשׁתַמֵשׁ',
    'Ref: Location': 'מקום',
  },
};
export default labels;
