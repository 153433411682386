import classNames from 'classnames';

import Title, { TITLE_SIZE, TITLE_TAG } from '../../atoms/Title';
import InfoMessage from '../InfoMessage/InfoMessage';

import { WidgetPlaceholderProps } from './WidgetPlaceholder.types';

import styles from './WidgetPlaceholder.module.css';

const WidgetPlaceholder = ({
  icon,
  title,
  button,
  overTitle,
  'data-cy': dataCy = 'widget-placeholder',
  'data-testid': dataTestId = 'widget-placeholder',
}: WidgetPlaceholderProps) => {
  return (
    <div
      className={classNames(styles.widgetPlaceholderWrapper, 'widgetPlaceholderWrapper')}
      data-cy={dataCy}
      data-testid={dataTestId}
    >
      {overTitle && (
        <div className={classNames('mb-M')} data-cy="widget-placeholder-overtitle">
          <Title tag={TITLE_TAG.H2} size={TITLE_SIZE.HEADLINEXS} {...overTitle} />
        </div>
      )}
      <div
        className={classNames(styles.widgetPlaceholder)}
        data-cy="widget-placeholder-infomessage"
      >
        <InfoMessage icon={icon} title={title} button={button}></InfoMessage>
      </div>
    </div>
  );
};

export default WidgetPlaceholder;
