const labels = {
  ContentDetails: {
    'Ref: Page title': 'Tartalom részletei',
    'See the event': 'Lásd az eseményt',
    'Take the survey': 'Kérdőív kitöltése',
    Date: 'Dátum',
    Category: 'Kategória',
    'Ref: Content site different title': 'Ez a tartalom nem tartozik az Ön jelenlegi webhelyéhez',
    'Ref: Content site different description':
      'Olyan tartalmat néz meg, amely nem tartozik a jelenlegi webhelyéhez. Nem fogja látni a tartalom listájában.',
  },
  ContentList: {
    'Ref: Page title': 'Hírcsatorna',
    'featured (as in featured content)': 'Kiemelt',
    'Ref: category - all': 'Összes',
    'Ref: category - promotional': 'Promóciós',
    'Ref: category - featured': 'Kiemelt',
    'Ref: category - other': 'Egyéb',
  },
  Widget: {
    'Ref: Carousel title': 'Hírek és cikkek',
    'Ref: Carousel title promo': 'Legnépszerűbb hír',
    'No info yet, come back later': 'Még nincs információ, gyere vissza később 😉',
    'Ref: Content': 'Tartalom',
    'Ref: Widget Title': 'Hírcsatorna',
    'Ref: MyVillage Carousel title promo': 'Előléptetés',
  },
};
export default labels;
