import { Switch, Route } from 'react-router-dom';

import { pagePaths } from '../config';

import AccountDelete from './AccountDelete';
import AccountPage from './AccountPage';
import {
  AccountPasswordChange,
  AccountPasswordChangeFailure,
  AccountPasswordChangeSuccess,
} from './AccountPasswordChange';
import LanguagePage from './LanguagePage';

const Account = () => (
  <Switch>
    <Route path={pagePaths['Language']} children={<LanguagePage />} />
    <Route
      path={pagePaths['AccountPasswordChangeSuccess']}
      children={<AccountPasswordChangeSuccess />}
    />
    <Route
      path={pagePaths['AccountPasswordChangeFailure']}
      children={<AccountPasswordChangeFailure />}
    />
    <Route path={pagePaths['AccountPasswordChange']} children={<AccountPasswordChange />} />
    <Route path={pagePaths['AccountDelete']} children={<AccountDelete />} />
    <Route path={pagePaths['Account']} children={<AccountPage />} />
  </Switch>
);

export default Account;
