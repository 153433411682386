const labels = {
  All: {
    Feedback: 'Riscontro',
    'Ref: Switch site component intro sentence':
      'I tuoi commenti saranno collegati al sito {site name}.',
    Category: 'Categoria',
    Subject: 'Soggetto',
    Comment: 'Commento',
    'Date of your experience': 'Data della tua esperienza *',
    'Ref: Hint': 'Hai un problema o un attività che necessita di riparazione?',
    'Ref: Log Request': 'Effettua una richiesta',
    'Ref: Describe issue': 'Per favore parlaci della tua esperienza',
    Date: 'Data',
    'Ref: Share your opinion header': 'Condividi la tua opinione sulla tua esperienza sul lavoro',
  },
  AlwaysOnSurveys: {
    'Restaurant / Café': 'Ristorante / caffè',
    'Desk / workstation area': 'Area di scrivania / workstation',
    'Meeting room facilities': 'Strutture per sala riunioni',
    'Coffee/tea facilities (pantry)': 'Strutture per caffè/tè (dispensa)',
    'Restrooms / washrooms': 'I bagni / bagni',
    'Reception / Security': 'Ricevimento / sicurezza',
    'Conference center': 'Centro conferenze',
    'Fitness center': 'Centro fitness',
    Mailroom: 'Mailroom',
    'Bicycle room': 'Sala bicicletta',
    'Shuttle services': 'Servizi navetta',
    Lockers: 'Armadietti',
    Helpdesk: 'Sportello di assistenza',
  },
  Create: {
    'Tell us!': 'Dicci!',
    'Ref: All Ears': "Siamo tutt'orecchi!",
    'Ref: Provide Description': 'Si prega di fornire una descrizione',
    'Ref: Fill-in-the-blank sentence': 'Voglio fornire un {0} per quanto riguarda {1}',
    'Ref: Link text to service request module': 'Una richiesta o un problema? Riportalo qui!',
    'Complaint type': 'Lamentela {emoji}',
    'Feedback type': 'Feedback {emoji}',
    'Compliment type': 'Complimento {emoji}',
  },
  CreateFailure: {
    'Ref: Body':
      'Si è verificato un errore e il tuo Riscontro non è stato inviato. Se questo errore persiste, contattare la tua assistenza.',
  },
  CreateSuccess: {
    'Ref: Body':
      'Il tuo riscontro è stato inviato. Il nostro team di supporto sul posto di lavoro leggerà attentamente. Se appropriato, torneremo a te.',
    'New feedback': 'Nuovo riscontro.',
  },
  CreateFlow: {
    Description: 'Descrizione',
    'Ref: Provide Description': 'Si prega di fornire una data e una descrizione',
    'Ref: I would like to provide a': 'Vorrei fornire un',
    'Ref: in regards to': 'riguardo a',
    'Ref: &': 'E',
    'Ref: at': 'a',
    'Ref: on the': 'sul',
  },
};
export default labels;
