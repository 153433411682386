import { screen } from '@testing-library/react';

import renderComponent from '../../../helpers/tests/renderComponent';
import { defaultState as coreDefaultState } from '../../Core/reducers/coreReducer';

import QrCode from './QrCode';

describe('QrCode', () => {
  describe('on initial render', () => {
    let qrCode: any, name: any, content: any;

    renderComponent(
      QrCode,
      {},
      {
        Core: {
          ...coreDefaultState,
          user: { firstName: 'Harry', lastName: 'Potter', qrCode: '1234567' },
        },
      }
    );

    beforeEach(() => {
      qrCode = screen.getByRole('qr-code');
      name = screen.getByText(/Harry Potter/i);
      content = screen.getByTestId('page-content');
    });

    it('should have correct content', () => {
      expect(qrCode).toBeTruthy();
      expect(name).toBeTruthy();
      expect(content).toBeTruthy();
    });
  });
});
