const labels = {
  All: {
    'Ref: ServiceRequestLightTitle': 'Permintaanmu',
    'Ref: Summary': 'Ringkasan',
    'Ref: Request category': 'Permintaan kategori',
    'Ref: Person affected': 'Orang yang terkena dampak permintaan ini',
    'Ref: Your phone number': 'Nomor telepon',
    'Ref: Description': 'Keterangan',
    'Ref: Service Requests': 'Permintaan layanan',
    'Ref: Request Service': 'Meminta layanan',
    'Ref: Success title': 'Permintaan Anda telah dibuat',
    'Ref: Error title': 'Ups!',
    'Ref: Error subtitle': 'Terjadi kesalahan, silakan coba lagi.',
    'Ref: Try again': 'Coba lagi',
    'Ref: you pronoun': '(Anda)',
  },
  Tutorials: {
    'Ref: Tutorial: title': 'Ini adalah hub dukungan tempat kerja Anda',
    'Ref: Tutorial: tabname': 'Permintaan',
    'Ref: Tutorial: body':
      'Kami di sini untuk membantu Anda. Harap logam satu permintaan per entri.',
  },
  Create: {
    'Ref: You are requesting service on the site {siteName}.':
      'Anda meminta layanan di Situs {siteName}.',
    'Ref: Building': 'Bangunan',
    'Ref: Room': 'Ruang',
    'Ref: Description help text': 'Semakin detail Anda, semakin baik kami dapat mendukung Anda.',
    'Ref: Next': 'Berikutnya',
    'Ref: Your request': 'Permintaanmu',
    'Ref: PhoneNumber error msg': 'Nomor telepon tidak valid',
    'Ref: Type at least 3 characters to search': 'Ketikkan minimal 3 karakter untuk dicari',
    'Ref: No affected person results': 'Tidak ada orang yang cocok dengan kriteria yang diminta',
    'Ref: Required': '* Diperlukan',
  },
  Summary: {
    'Ref: Location': 'Lokasi',
    'Ref: Attachment': 'Lampiran',
    'Ref: Submit': 'Kirim',
    'Ref: No affected person results': 'Tidak ada orang yang cocok dengan kriteria yang diminta',
  },
  Details: {
    'Ref: ServiceRequestLightDetails': 'Permintaan layanan',
    'Ref: createdOn': 'Dibuat pada',
    'Ref: summary': 'Ringkasan',
    'Ref: category': 'Permintaan kategori',
    'Ref: request raised for': 'Orang yang terkena dampak permintaan ini',
    'Ref: phoneNumber': 'Nomor telepon',
    'Ref: location': 'Nomor telepon',
    'Ref: description': 'Keterangan',
    'Ref: attachments': 'Lampiran',
  },
};
export default labels;
