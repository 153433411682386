const mockResponse = {
  sendForgottenEmail: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
  resetEmail: {
    ok: true,
    status: 200,
    json: () => Promise.resolve({}),
  },
};

export default mockResponse;
