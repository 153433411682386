import classNames from 'classnames';
import { FC } from 'react';

import { DefaultComponentProps } from '../../../../types';

import styles from './Title.module.css';

const WidgetTitle: FC<DefaultComponentProps> = ({
  children,
  className,
  'data-testid': dataTestId = 'widget-title',
}) => {
  return (
    <div className={classNames(styles.title, className)} data-testid={dataTestId}>
      {children}
    </div>
  );
};
WidgetTitle.displayName = 'Widget.Title';

export default WidgetTitle;
