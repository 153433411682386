const labels = {
  AuditInviteList: {
    'Ref: Page title': 'Auditorías',
    'Ref: status0': 'Abierto',
    'Ref: status1': 'Terminado',
    'Ref: status2': 'Cancelado',
    'Ref: connected': 'Conectado',
    'Ref: not connected': 'No conectado',
    'Ref: synchronise data': 'Sincronizar datos',
    'Ref: sync success': 'Datos sincronizados con éxito',
    'Ref: Create new': 'Crear nuevo',
    'Ref: Audit unsynchronised': 'No sincronizado',
    'Ref: Audit synchronised': 'Sincronizado',
    'Ref: Unsynced error title': 'Se ha producido un error',
    'Ref: Unsynced error description':
      'Parece ser la primera vez que accede al módulo de auditorías. Conéctese a la red y "sincronice" para comenzar a auditar.',
    'Ref: Search all audits': 'Todas las auditorías',
    'Ref: Search active audits': 'Abierto',
    'Ref: Search completed audits': 'Terminado',
    'Ref: sync error': 'La sincronización falló',
    'Ref: sync error details': 'Vuelva a intentar volver a conectar',
    'Ref: no search audit results':
      'Desafortunadamente, no hay auditorías correspondientes a su búsqueda',
    'Ref: no audit results': 'No tiene auditorías disponibles, cree nuevo para comenzar',
    'Ref: Search canceled audits': 'Cancelado',
    'Ref: Audit cancelled successfully': 'Auditoría cancelada correctamente',
    'Ref: Cancel audit invite': 'Cancelar',
    'Ref: Error audit cancel': 'La auditoría no se canceló',
    'Ref: Cancel audit confirmation': '¿Estás seguro de que quieres cancelar la auditoría?',
    'Ref: Confirm': 'Confirmar',
    'Ref: Cancel audit': 'Cancelar auditoría',
    'Ref: Change the location': 'Cambiar la ubicación',
  },
  AuditResponseForm: {
    'Ref: Page title': 'Detalles de auditoría',
    'Ref: questions': 'preguntas',
    'Ref: question': 'pregunta',
    'Ref: Additional Comments': 'Descripción',
    'Ref: Not found title': '¡Ups!',
    'Ref: Not found text': 'La auditoría a la que está intentando acceder no existe.',
    'Ref: Back to Audit list': 'Volver a la lista de auditorías',
    'Ref: Success title': '¡Gracias!',
    'Ref: Success text': 'Has completado la auditoría',
    'Ref: submit': 'Entregar',
    'Ref: add attachment': 'Agregar un archivo adjunto',
    'Ref: Unsupported elements':
      'Lo sentimos, parece que esta auditoría tiene contenido que la aplicación aún no admite. Trabajaremos en ello.',
    'Ref: form error': 'No todas las preguntas han sido respondidas por completo',
    'Ref: form complete': 'Completado desde',
    'Ref: attachment preview error': 'Debe estar en línea para ver este archivo adjunto',
  },
  AuditCreationForm: {
    'Ref: Page title': 'Crear nuevo',
    'Ref: Location': 'Ubicación',
    'Ref: Audit Stream Type': 'Arroyo',
    'Ref: Audit': 'Auditoría',
    'Ref: Asset': 'Activo',
    'Ref: Asset Name': 'Nombre de activo',
    'Ref: Is joint audit?': '¿Es la auditoría conjunta?',
    'Ref: Who was present?': '¿Quién estaba presente?',
    'Ref: submit': 'Entregar',
    'Ref: Select the Location': 'Seleccione la ubicación',
    'Ref: Select the Audit Stream Type': 'Seleccione el tipo de transmisión de auditoría',
    'Ref: Select the Audit': 'Seleccione la auditoría',
    'Ref: Select the Asset': 'Seleccione el activo',
    'Ref: form error': 'No todos los campos requeridos se han ingresado completamente',
    'Ref: Audit error': 'Auditoría adjunta',
    'Ref: Joint Audit Participants error': 'Participantes de auditoría conjunta',
    'Ref: Audit created': 'Auditoría creada',
    'Ref: Audit field length error': 'La longitud no debe exceder los 100 caracteres',
  },
  AuditEditLocationForm: {
    'Ref: Change the location': 'Cambiar la ubicación',
    'Ref: Location changed': 'La ubicación cambió',
    'Ref: Location': 'Ubicación',
    'Ref: Select the Location': 'Seleccione la ubicación',
    'Ref: Asset': 'Activo',
    'Ref: Select the Asset': 'Seleccione el activo',
    'Ref: Asset Name': 'Nombre de activo',
    'Ref: submit': 'Entregar',
    'Ref: Audit field length error': 'La longitud no debe exceder los 100 caracteres',
  },
};
export default labels;
