import classNames from 'classnames';

import { useCustomTranslation } from '../../../localization/hooks/useCustomTranslation';

import { FilterComponent } from './FilterComponent';
import { FiltersProps } from './Filters.types';
import ModalFilters from './ModalFilters';
import { useFilters } from './useFilters';

import styles from './Filters.module.css';

const Filters: React.FC<FiltersProps> = ({
  filters,
  filtering,
  handleChange,
  setModalState,
  languageCode = 'en-US',
  hideTitle,
  isModalOpen,
  'data-testid': testId,
}: FiltersProps) => {
  const {
    visibleFilters,
    invisibleFilters,
    modalFilters,
    selectedFilters,
    onCommitFilter,
    onDismissModal,
    resetModalFilters,
    handleFilterChange,
    handleCalendarChange,
  } = useFilters({
    filters,
    filtering,
    handleChange,
    languageCode,
    testId,
  });
  const { label } = useCustomTranslation();
  return (
    <div>
      {!hideTitle ? <h2>{label('Filters')}</h2> : null}
      <div className={classNames(styles.allFilters)}>
        {visibleFilters.map((filter) => (
          <FilterComponent
            key={filter.id}
            filter={filter}
            filtering={filtering}
            label={label}
            languageCode={languageCode}
            handleChange={handleFilterChange}
            handleCalendarChange={handleCalendarChange}
            data-testid={`${testId}-${filter.id}`}
            className={filter.wrapperClassName}
          />
        ))}
        <ModalFilters
          filters={modalFilters}
          setModalState={setModalState}
          isModalOpen={isModalOpen}
          uncommitedFiltering={selectedFilters}
          filtering={filtering}
          languageCode={languageCode}
          onCommitFilter={onCommitFilter}
          onDismissModal={onDismissModal}
          onResetModalFilters={resetModalFilters}
          handleChange={handleFilterChange}
          handleCalendarChange={handleCalendarChange}
          data-testid={`${testId}-modal`}
        />
      </div>
      <div className={styles.invisible}>
        {invisibleFilters.map((filter) => (
          <FilterComponent
            key={filter.id}
            filter={filter}
            filtering={filtering}
            label={label}
            languageCode={languageCode}
            handleChange={handleFilterChange}
            handleCalendarChange={handleCalendarChange}
            data-testid={`${testId}-${filter.id}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Filters;
