import { screen, cleanup } from '@testing-library/react';

import renderComponent from '../../../../../helpers/tests/renderComponent';

import AccountInformation from './AccountInformation';

jest.mock('../../../helpers/onboardingHelpers', () => {
  return {
    getUserIdentity: jest.fn(),
    isSSOUser: jest.fn(),
    invokeSSORedirect: jest.fn(),
  };
});

const mockDispatch = jest.fn((action) => action());
jest.mock('react-redux', () => ({
  ...jest.requireActual('react-redux'),
  useDispatch: () => mockDispatch,
}));

describe('AccountInformation', () => {
  afterEach(() => cleanup());

  describe('on first render', () => {
    renderComponent(AccountInformation);
    beforeEach(() => {
      expect(screen.getByTestId('form')).toBeTruthy();
      expect(screen.getByTestId('form-submit')).toBeTruthy();
    });

    it('should have displayed geography, email inputs', () => {
      expect(screen.getByTestId('geography-selector')).toBeTruthy();
      expect(screen.getByTestId('account-info-email-hook-input-field')).toBeTruthy();
    });
  });
});
