import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { NavArrowRightIcon } from '../../../assets/icons';
import { AuditIllustration } from '../../../assets/illustrations';
import { ImageDefaultNoFoodImg } from '../../../assets/images';
import { Tile } from '../../../components/molecules/Tile';
import Column from '../../../components/organisms/Column';
import ListPage from '../../../components/templates/ListPage/ListPage';
import LoadingPage from '../../../components/templates/LoadingPage/LoadingPage';
import { formatDate } from '../../../helpers/dateTime';
import { openUrl, addParamsToSurveyUrl } from '../../../helpers/misc';
import { ApiType } from '../../../modules/Sites/api';
import MM from '../../../services/ModulesManager';
import { State } from '../../../types/state.types';
import { IContentType } from '../../Content/types';
import { useGetSurveysQuery } from '../api/api';
import { pagePaths } from '../config';
import { useSurveyTranslation } from '../hooks/useSurveyTranslation';
import { Survey, SurveyItem } from '../types/surveysList.types';

import useLanguage from '@/modules/Core/hooks/useLanguage';
import useSite from '@/modules/Core/hooks/useSite';

import styles from './SurveyList.module.css';

const SurveysList = () => {
  const site = useSite({ throwWhenNoActiveSite: true })!;
  const user = useSelector((state: State) => state.Core.user);
  const language = useLanguage();
  const history = useHistory();
  const { label } = useSurveyTranslation(__filename);

  const { data: surveys = [], isLoading } = useGetSurveysQuery({
    siteId: site.id,
    contractId: user.contract?.id,
    languageCode: language.currentLanguageCode,
  });

  const now = moment();

  const handleOnItemPress = (linkPath: string, type: string | undefined) => {
    if (type === IContentType.INMOMENT_SURVEY) {
      const url = addParamsToSurveyUrl(linkPath, { site, user });
      openUrl(url);
    } else {
      history?.push(linkPath);
    }
  };

  let items = surveys
    .filter(
      (survey: Survey) =>
        moment(survey.publishedDate) <= now &&
        (moment(survey.expiryDate) >= now || !survey.expiryDate)
    )
    .sort(
      (a: Survey, b: Survey) => (moment(b.publishedDate) as any) - (moment(a.publishedDate) as any)
    )
    .map((survey: Survey) => {
      let item = {
        id: survey.id,
        title: survey.name,
        type: survey.type,
        imgInfo: survey.imgInfo,
        description: survey.description,
        linkPath:
          survey.type === IContentType.INMOMENT_SURVEY
            ? survey.url
            : pagePaths['Survey'].replace(':id', survey.id),
        actions: [
          {
            name: label('Ref: Navigation arrow - Continue'),
            icon: <NavArrowRightIcon />,
            onClick: () => handleOnItemPress(item.linkPath, item.type),
            'data-testid': `survey-action-${survey.id}`,
          },
        ],
        available: '', // Default value
      } as SurveyItem;

      if (survey.expiryDate)
        item.available = (
          <>
            <b>{label('Ref: Available until')}:</b>{' '}
            {formatDate(new Date(survey.expiryDate), language.currentLanguageCode, {
              month: 'short',
            })}
            <span className={styles.new_line}>{item.description}</span>
          </>
        );

      return item;
    });

  const SwitchSite = (
    MM<ApiType>().getApi('Sites', 'getSwitchSiteComponent') as ApiType['getSwitchSiteComponent']
  )();

  const itemsWithTestingId = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        'data-testid': `survey-item-${item.id}`,
      })),
    [items]
  );

  if (isLoading) return <LoadingPage />;

  return (
    <ListPage
      hasBackLink={false}
      data-testid="survey-list-page"
      title={label('Ref: Page title')}
      items={itemsWithTestingId}
      aside={
        <Column.Complementary>
          <AuditIllustration />
        </Column.Complementary>
      }
      listTopContent={
        <SwitchSite
          sentence={label('Ref: You are currently viewing surveys for the site {site name}.')}
          redirectTo={pagePaths.Module}
        />
      }
      itemRenderer={(item: SurveyItem) => {
        return (
          <Tile
            id={item.id}
            key={`SurveysTile-${item.id}`}
            title={item.title}
            description={item.available}
            onClick={() => handleOnItemPress(item.linkPath, item.type)}
            actions={item.actions}
            image={
              item.imgInfo ? (
                <img src={item.imgInfo.src} alt={item.imgInfo.alt} />
              ) : (
                <ImageDefaultNoFoodImg />
              )
            }
          />
        );
      }}
    />
  );
};

export default SurveysList;
