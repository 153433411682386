const labels = {
  SiteDetails: {
    'Select site': 'Seleziona questo sito',
    'Ref: Page title': 'Dettagli del sito',
    'Ref: Skip map': 'Passa ai contenuti principali',
    'Ref: Unavailable site': 'Anteprima del sito Idecibile per il tuo utente',
    'Ref: Sorry, no description yet': 'Scusa, non abbiamo ancora una descrizione.',
  },
  SitesList: {
    'Ref: Site coordinates not found': 'Coordinate del sito non trovate',
    'Ref: Page title': 'Siti',
    'Recently visited': 'Recentemente visitato',
    'All other sites': 'Tutti gli altri siti.',
    'Sites in your country': 'Siti nel tuo paese',
    'Search results': 'Risultati di ricerca',
    'Select site': 'Selezionare sito',
    'Ref: Select site with QR Scanner': 'Seleziona il sito con scanner QR',
    'Ref: Select site with code': 'Seleziona il sito con codice',
    'Ref: Validate': 'Convalidare',
    'Ref: Enter site code': 'Immettere il codice del sito',
    'Ref: Scanner error header': 'Sito non trovato',
    'Ref: Generic error': 'Qualcosa è andato storto',
    'Ref: Change site': 'Cambia sito',
    'Ref: Change site description':
      'Ci sono oggetti nel carrello! La modifica del sito comporterà la cancellazione del carrello.',
    'Ref: Cancel': 'Annulla',
    'Ref: Not found': 'Questo codice QR non è stato riconosciuto',
    'Ref: Unauthorized error':
      'Richiesta non autorizzata a causa del livello di restrizione del sito',
    'Ref: Forbidden':
      "Mi dispiace, non hai le autorizzazioni giuste per accedere a questo sito. Per organizzare l'accesso, contattare il Pilbara Service Center il 1800 992 777",
    "Ref: We didn't find the site.": 'Non abbiamo trovato il sito.',
    'Ref: We recommend some solutions:': 'Raccomandiamo alcune soluzioni:',
    'Ref: 1. Try to add your site with QR Scanner or with the help of buttons below':
      "1. Prova ad aggiungere il tuo sito con scanner QR o con l'aiuto di pulsanti sotto",
    'Ref: 2. Log out and log back in by selecting the correct country':
      '2. Accedi e accedere selezionando il paese corretto',
    'Ref: The currently selected country is:': 'Il paese attualmente selezionato è:',
  },
  SwitchSite: { 'Not on {site name}?': 'Non su {site name}?' },
  SiteTile: { Details: 'Dettagli', Select: 'Selezionare' },
};
export default labels;
